import React, { useRef, useState } from 'react'
import { filterState } from '../utilities/filterState'

export function useListState (defaultValue = [], addNewValue = () => ({})) {
  const id = useRef(0)
  const [state, setState] = useState(
    defaultValue.map((item) => ({
      key: id.current++,
      data: item
    }))
  )

  function add (value) {
    setState((state) => {
      return [
        ...state,
        {
          key: id.current++,
          data: addNewValue()
        }
      ]
    })
  }

  function each (renderer) {
    return state.map((item, index) => {
      let stateHook = filterState([state, setState], `${index}.data`)
      return (
        <React.Fragment key={item.key}>
          {renderer({
            removeItem: () => {
              setState((state) => {
                return state.filter(
                  (filterItem) => item.key !== filterItem.key
                )
              })
            },
            getItemState: (filterKey) =>
              filterKey ? filterState(stateHook, filterKey) : stateHook
          })}
        </React.Fragment>
      )
    })
  }

  return [state.map((item) => item.data), each, add]
}
