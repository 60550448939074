{
  "Total": {
    "ageData": {
      "2017": {
        "total": "153,337",
        "16to19": "5,074",
        "20to24": "14,132",
        "25to34": "34,439",
        "35to44": "31,892",
        "45to54": "32,503",
        "55to64": "26,064",
        "65+": "9,234",
        "medianAge": "26,064"
      }
    },
    "title": "Total",
    "depth": 1,
    "parent": "",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "153,337",
        "women": "46.9",
        "white": "78.4",
        "black": "12.1",
        "asian": "6.2",
        "hispanicOrLatino": "16.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "113,272",
        "median": "$860",
        "maleWorkers": "62,980",
        "maleMedian": "$941",
        "femaleWorkers": "50,291",
        "femaleMedian": "$770"
      }
    }
  },
  "Management, professional, and related occupations": {
    "ageData": {
      "2017": {
        "total": "60,901",
        "16to19": "435",
        "20to24": "3,275",
        "25to34": "14,130",
        "35to44": "14,136",
        "45to54": "13,892",
        "55to64": "11,020",
        "65+": "4,013",
        "medianAge": "11,020"
      }
    },
    "title": "Management, professional, and related occupations",
    "depth": 1,
    "parent": "",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "60,901",
        "women": "51.6",
        "white": "80.0",
        "black": "9.4",
        "asian": "8.1",
        "hispanicOrLatino": "9.6"
      }
    },
    "wageData": {
      "2017": {
        "workers": "47,207",
        "median": "1,224",
        "maleWorkers": "22,815",
        "maleMedian": "1,442",
        "femaleWorkers": "24,393",
        "femaleMedian": "1,052"
      }
    }
  },
  "Management, business, and financial operations occupations": {
    "ageData": {
      "2017": {
        "total": "25,379",
        "16to19": "104",
        "20to24": "977",
        "25to34": "5,124",
        "35to44": "5,863",
        "45to54": "6,389",
        "55to64": "5,071",
        "65+": "1,851",
        "medianAge": "5,071"
      }
    },
    "title": "Management, business, and financial operations occupations",
    "depth": 2,
    "parent": "Management, professional, and related occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "25,379",
        "women": "44.0",
        "white": "83.0",
        "black": "8.2",
        "asian": "6.4",
        "hispanicOrLatino": "10.0"
      }
    },
    "wageData": {
      "2017": {
        "workers": "19,414",
        "median": "1,327",
        "maleWorkers": "10,415",
        "maleMedian": "1,526",
        "femaleWorkers": "8,999",
        "femaleMedian": "1,134"
      }
    }
  },
  "Management occupations": {
    "ageData": {
      "2017": {
        "total": "17,804",
        "16to19": "75",
        "20to24": "554",
        "25to34": "3,149",
        "35to44": "4,203",
        "45to54": "4,751",
        "55to64": "3,704",
        "65+": "1,369",
        "medianAge": "3,704"
      }
    },
    "title": "Management occupations",
    "depth": 3,
    "parent": "Management, business, and financial operations occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "17,804",
        "women": "39.8",
        "white": "84.0",
        "black": "7.6",
        "asian": "5.9",
        "hispanicOrLatino": "9.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "13,169",
        "median": "1,392",
        "maleWorkers": "7,568",
        "maleMedian": "1,573",
        "femaleWorkers": "5,600",
        "femaleMedian": "1,173"
      }
    }
  },
  "Chief executives": {
    "ageData": {
      "2017": {
        "total": "1,639",
        "16to19": "2",
        "20to24": "4",
        "25to34": "145",
        "35to44": "328",
        "45to54": "505",
        "55to64": "455",
        "65+": "201",
        "medianAge": "455"
      }
    },
    "title": "Chief executives",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "1,639",
        "women": "28.0",
        "white": "90.0",
        "black": "3.8",
        "asian": "4.6",
        "hispanicOrLatino": "4.7"
      }
    },
    "wageData": {
      "2017": {
        "workers": "1,136",
        "median": "2,296",
        "maleWorkers": "823",
        "maleMedian": "2,415",
        "femaleWorkers": "313",
        "femaleMedian": "1,920"
      }
    }
  },
  "General and operations managers": {
    "ageData": {
      "2017": {
        "total": "1,005",
        "16to19": "1",
        "20to24": "28",
        "25to34": "227",
        "35to44": "279",
        "45to54": "254",
        "55to64": "184",
        "65+": "32",
        "medianAge": "184"
      }
    },
    "title": "General and operations managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "1,005",
        "women": "34.1",
        "white": "85.0",
        "black": "8.3",
        "asian": "4.5",
        "hispanicOrLatino": "11.4"
      }
    },
    "wageData": {
      "2017": {
        "workers": "920",
        "median": "1,328",
        "maleWorkers": "598",
        "maleMedian": "1,488",
        "femaleWorkers": "321",
        "femaleMedian": "1,134"
      }
    }
  },
  "Legislators": {
    "ageData": {
      "2017": {
        "total": "18",
        "16to19": "0",
        "20to24": "1",
        "25to34": "2",
        "35to44": "2",
        "45to54": "4",
        "55to64": "5",
        "65+": "4",
        "medianAge": "5"
      }
    },
    "title": "Legislators",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "18",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "14",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "9",
        "femaleMedian": "-"
      }
    }
  },
  "Advertising and promotions managers": {
    "ageData": {
      "2017": {
        "total": "61",
        "16to19": "0",
        "20to24": "3",
        "25to34": "14",
        "35to44": "20",
        "45to54": "12",
        "55to64": "11",
        "65+": "1",
        "medianAge": "11"
      }
    },
    "title": "Advertising and promotions managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "61",
        "women": "52.7",
        "white": "84.5",
        "black": "8.0",
        "asian": "7.5",
        "hispanicOrLatino": "7.4"
      }
    },
    "wageData": {
      "2017": {
        "workers": "53",
        "median": "1,330",
        "maleWorkers": "24",
        "maleMedian": "-",
        "femaleWorkers": "29",
        "femaleMedian": "-"
      }
    }
  },
  "Marketing and sales managers": {
    "ageData": {
      "2017": {
        "total": "1,078",
        "16to19": "5",
        "20to24": "51",
        "25to34": "267",
        "35to44": "296",
        "45to54": "272",
        "55to64": "155",
        "65+": "33",
        "medianAge": "155"
      }
    },
    "title": "Marketing and sales managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "1,078",
        "women": "45.3",
        "white": "86.4",
        "black": "6.0",
        "asian": "6.1",
        "hispanicOrLatino": "10.2"
      }
    },
    "wageData": {
      "2017": {
        "workers": "994",
        "median": "1,509",
        "maleWorkers": "566",
        "maleMedian": "1,747",
        "femaleWorkers": "428",
        "femaleMedian": "1,288"
      }
    }
  },
  "Public relations and fundraising managers": {
    "ageData": {
      "2017": {
        "total": "73",
        "16to19": "0",
        "20to24": "2",
        "25to34": "23",
        "35to44": "17",
        "45to54": "17",
        "55to64": "12",
        "65+": "1",
        "medianAge": "12"
      }
    },
    "title": "Public relations and fundraising managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "73",
        "women": "63.5",
        "white": "87.9",
        "black": "7.8",
        "asian": "3.2",
        "hispanicOrLatino": "3.7"
      }
    },
    "wageData": {
      "2017": {
        "workers": "71",
        "median": "1,318",
        "maleWorkers": "24",
        "maleMedian": "-",
        "femaleWorkers": "47",
        "femaleMedian": "-"
      }
    }
  },
  "Administrative services managers": {
    "ageData": {
      "2017": {
        "total": "151",
        "16to19": "0",
        "20to24": "1",
        "25to34": "25",
        "35to44": "32",
        "45to54": "42",
        "55to64": "43",
        "65+": "8",
        "medianAge": "43"
      }
    },
    "title": "Administrative services managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "151",
        "women": "40.1",
        "white": "83.5",
        "black": "9.4",
        "asian": "3.2",
        "hispanicOrLatino": "10.3"
      }
    },
    "wageData": {
      "2017": {
        "workers": "147",
        "median": "1,233",
        "maleWorkers": "89",
        "maleMedian": "1,629",
        "femaleWorkers": "57",
        "femaleMedian": "1,013"
      }
    }
  },
  "Computer and information systems managers": {
    "ageData": {
      "2017": {
        "total": "630",
        "16to19": "0",
        "20to24": "13",
        "25to34": "95",
        "35to44": "192",
        "45to54": "192",
        "55to64": "124",
        "65+": "14",
        "medianAge": "124"
      }
    },
    "title": "Computer and information systems managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "630",
        "women": "28.6",
        "white": "78.0",
        "black": "6.6",
        "asian": "13.0",
        "hispanicOrLatino": "6.6"
      }
    },
    "wageData": {
      "2017": {
        "workers": "594",
        "median": "1,843",
        "maleWorkers": "428",
        "maleMedian": "1,897",
        "femaleWorkers": "165",
        "femaleMedian": "1,629"
      }
    }
  },
  "Financial managers": {
    "ageData": {
      "2017": {
        "total": "1,167",
        "16to19": "1",
        "20to24": "34",
        "25to34": "273",
        "35to44": "302",
        "45to54": "297",
        "55to64": "210",
        "65+": "51",
        "medianAge": "210"
      }
    },
    "title": "Financial managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "1,167",
        "women": "55.7",
        "white": "83.5",
        "black": "6.7",
        "asian": "8.2",
        "hispanicOrLatino": "11.2"
      }
    },
    "wageData": {
      "2017": {
        "workers": "1,111",
        "median": "1,412",
        "maleWorkers": "500",
        "maleMedian": "1,719",
        "femaleWorkers": "611",
        "femaleMedian": "1,222"
      }
    }
  },
  "Compensation and benefits managers": {
    "ageData": {
      "2017": {
        "total": "16",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "4",
        "45to54": "5",
        "55to64": "4",
        "65+": "1",
        "medianAge": "4"
      }
    },
    "title": "Compensation and benefits managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "16",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "16",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "Human resources managers": {
    "ageData": {
      "2017": {
        "total": "327",
        "16to19": "1",
        "20to24": "5",
        "25to34": "65",
        "35to44": "92",
        "45to54": "96",
        "55to64": "54",
        "65+": "15",
        "medianAge": "54"
      }
    },
    "title": "Human resources managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "327",
        "women": "70.8",
        "white": "81.7",
        "black": "12.3",
        "asian": "4.3",
        "hispanicOrLatino": "7.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "304",
        "median": "1,448",
        "maleWorkers": "92",
        "maleMedian": "1,748",
        "femaleWorkers": "211",
        "femaleMedian": "1,280"
      }
    }
  },
  "Training and development managers": {
    "ageData": {
      "2017": {
        "total": "63",
        "16to19": "0",
        "20to24": "3",
        "25to34": "10",
        "35to44": "14",
        "45to54": "17",
        "55to64": "17",
        "65+": "1",
        "medianAge": "17"
      }
    },
    "title": "Training and development managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "63",
        "women": "59.8",
        "white": "89.2",
        "black": "5.6",
        "asian": "2.2",
        "hispanicOrLatino": "9.0"
      }
    },
    "wageData": {
      "2017": {
        "workers": "58",
        "median": "1,396",
        "maleWorkers": "23",
        "maleMedian": "-",
        "femaleWorkers": "35",
        "femaleMedian": "-"
      }
    }
  },
  "Industrial production managers": {
    "ageData": {
      "2017": {
        "total": "280",
        "16to19": "0",
        "20to24": "9",
        "25to34": "38",
        "35to44": "67",
        "45to54": "91",
        "55to64": "62",
        "65+": "13",
        "medianAge": "62"
      }
    },
    "title": "Industrial production managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "280",
        "women": "25.7",
        "white": "89.5",
        "black": "3.8",
        "asian": "5.9",
        "hispanicOrLatino": "9.3"
      }
    },
    "wageData": {
      "2017": {
        "workers": "265",
        "median": "1,486",
        "maleWorkers": "202",
        "maleMedian": "1,526",
        "femaleWorkers": "63",
        "femaleMedian": "1,312"
      }
    }
  },
  "Purchasing managers": {
    "ageData": {
      "2017": {
        "total": "205",
        "16to19": "0",
        "20to24": "13",
        "25to34": "41",
        "35to44": "38",
        "45to54": "61",
        "55to64": "41",
        "65+": "11",
        "medianAge": "41"
      }
    },
    "title": "Purchasing managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "205",
        "women": "52.3",
        "white": "84.3",
        "black": "10.6",
        "asian": "3.3",
        "hispanicOrLatino": "9.1"
      }
    },
    "wageData": {
      "2017": {
        "workers": "194",
        "median": "1,271",
        "maleWorkers": "90",
        "maleMedian": "1,420",
        "femaleWorkers": "104",
        "femaleMedian": "1,138"
      }
    }
  },
  "Transportation, storage, and distribution managers": {
    "ageData": {
      "2017": {
        "total": "291",
        "16to19": "1",
        "20to24": "9",
        "25to34": "58",
        "35to44": "61",
        "45to54": "87",
        "55to64": "63",
        "65+": "12",
        "medianAge": "63"
      }
    },
    "title": "Transportation, storage, and distribution managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "291",
        "women": "19.2",
        "white": "79.7",
        "black": "10.7",
        "asian": "5.3",
        "hispanicOrLatino": "13.8"
      }
    },
    "wageData": {
      "2017": {
        "workers": "274",
        "median": "1,039",
        "maleWorkers": "225",
        "maleMedian": "1,061",
        "femaleWorkers": "49",
        "femaleMedian": "-"
      }
    }
  },
  "Farmers, ranchers, and other agricultural managers": {
    "ageData": {
      "2017": {
        "total": "1,018",
        "16to19": "6",
        "20to24": "24",
        "25to34": "103",
        "35to44": "145",
        "45to54": "190",
        "55to64": "257",
        "65+": "293",
        "medianAge": "257"
      }
    },
    "title": "Farmers, ranchers, and other agricultural managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "1,018",
        "women": "25.2",
        "white": "95.4",
        "black": "0.8",
        "asian": "1.4",
        "hispanicOrLatino": "6.1"
      }
    },
    "wageData": {
      "2017": {
        "workers": "121",
        "median": "1,001",
        "maleWorkers": "100",
        "maleMedian": "963",
        "femaleWorkers": "21",
        "femaleMedian": "-"
      }
    }
  },
  "Construction managers": {
    "ageData": {
      "2017": {
        "total": "1,081",
        "16to19": "4",
        "20to24": "27",
        "25to34": "167",
        "35to44": "256",
        "45to54": "320",
        "55to64": "233",
        "65+": "75",
        "medianAge": "233"
      }
    },
    "title": "Construction managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "1,081",
        "women": "7.4",
        "white": "90.2",
        "black": "4.9",
        "asian": "2.3",
        "hispanicOrLatino": "12.4"
      }
    },
    "wageData": {
      "2017": {
        "workers": "582",
        "median": "1,428",
        "maleWorkers": "526",
        "maleMedian": "1,439",
        "femaleWorkers": "56",
        "femaleMedian": "1,243"
      }
    }
  },
  "Education administrators": {
    "ageData": {
      "2017": {
        "total": "918",
        "16to19": "5",
        "20to24": "21",
        "25to34": "146",
        "35to44": "246",
        "45to54": "256",
        "55to64": "183",
        "65+": "60",
        "medianAge": "183"
      }
    },
    "title": "Education administrators",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "918",
        "women": "64.2",
        "white": "81.8",
        "black": "12.9",
        "asian": "2.1",
        "hispanicOrLatino": "10.3"
      }
    },
    "wageData": {
      "2017": {
        "workers": "801",
        "median": "1,367",
        "maleWorkers": "304",
        "maleMedian": "1,561",
        "femaleWorkers": "497",
        "femaleMedian": "1,243"
      }
    }
  },
  "Architectural and engineering managers": {
    "ageData": {
      "2017": {
        "total": "129",
        "16to19": "0",
        "20to24": "2",
        "25to34": "12",
        "35to44": "35",
        "45to54": "39",
        "55to64": "38",
        "65+": "4",
        "medianAge": "38"
      }
    },
    "title": "Architectural and engineering managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "129",
        "women": "8.7",
        "white": "79.7",
        "black": "8.3",
        "asian": "11.2",
        "hispanicOrLatino": "6.2"
      }
    },
    "wageData": {
      "2017": {
        "workers": "128",
        "median": "1,999",
        "maleWorkers": "119",
        "maleMedian": "2,020",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Food service managers": {
    "ageData": {
      "2017": {
        "total": "1,210",
        "16to19": "38",
        "20to24": "139",
        "25to34": "336",
        "35to44": "227",
        "45to54": "245",
        "55to64": "180",
        "65+": "44",
        "medianAge": "180"
      }
    },
    "title": "Food service managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "1,210",
        "women": "46.3",
        "white": "74.7",
        "black": "9.5",
        "asian": "11.7",
        "hispanicOrLatino": "16.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "816",
        "median": "753",
        "maleWorkers": "421",
        "maleMedian": "894",
        "femaleWorkers": "394",
        "femaleMedian": "674"
      }
    }
  },
  "Funeral service managers": {
    "ageData": {
      "2017": {
        "total": "15",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "2",
        "45to54": "4",
        "55to64": "4",
        "65+": "5",
        "medianAge": "4"
      }
    },
    "title": "Funeral service managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "15",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "9",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Gaming managers": {
    "ageData": {
      "2017": {
        "total": "19",
        "16to19": "0",
        "20to24": "0",
        "25to34": "5",
        "35to44": "4",
        "45to54": "5",
        "55to64": "4",
        "65+": "1",
        "medianAge": "4"
      }
    },
    "title": "Gaming managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "19",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "23",
        "median": "-",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Lodging managers": {
    "ageData": {
      "2017": {
        "total": "156",
        "16to19": "1",
        "20to24": "7",
        "25to34": "35",
        "35to44": "34",
        "45to54": "39",
        "55to64": "29",
        "65+": "10",
        "medianAge": "29"
      }
    },
    "title": "Lodging managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "156",
        "women": "48.8",
        "white": "72.9",
        "black": "11.2",
        "asian": "12.5",
        "hispanicOrLatino": "12.0"
      }
    },
    "wageData": {
      "2017": {
        "workers": "116",
        "median": "882",
        "maleWorkers": "54",
        "maleMedian": "982",
        "femaleWorkers": "62",
        "femaleMedian": "844"
      }
    }
  },
  "Medical and health services managers": {
    "ageData": {
      "2017": {
        "total": "671",
        "16to19": "2",
        "20to24": "13",
        "25to34": "117",
        "35to44": "171",
        "45to54": "194",
        "55to64": "142",
        "65+": "33",
        "medianAge": "142"
      }
    },
    "title": "Medical and health services managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "671",
        "women": "69.8",
        "white": "77.9",
        "black": "14.0",
        "asian": "5.7",
        "hispanicOrLatino": "9.1"
      }
    },
    "wageData": {
      "2017": {
        "workers": "610",
        "median": "1,319",
        "maleWorkers": "177",
        "maleMedian": "1,490",
        "femaleWorkers": "433",
        "femaleMedian": "1,177"
      }
    }
  },
  "Natural sciences managers": {
    "ageData": {
      "2017": {
        "total": "16",
        "16to19": "0",
        "20to24": "0",
        "25to34": "5",
        "35to44": "3",
        "45to54": "4",
        "55to64": "3",
        "65+": "2",
        "medianAge": "3"
      }
    },
    "title": "Natural sciences managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "16",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "17",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Postmasters and mail superintendents": {
    "ageData": {
      "2017": {
        "total": "27",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "2",
        "45to54": "11",
        "55to64": "11",
        "65+": "1",
        "medianAge": "11"
      }
    },
    "title": "Postmasters and mail superintendents",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "27",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "23",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Property, real estate, and community association managers": {
    "ageData": {
      "2017": {
        "total": "743",
        "16to19": "0",
        "20to24": "20",
        "25to34": "122",
        "35to44": "146",
        "45to54": "193",
        "55to64": "166",
        "65+": "96",
        "medianAge": "166"
      }
    },
    "title": "Property, real estate, and community association managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "743",
        "women": "48.6",
        "white": "81.8",
        "black": "11.6",
        "asian": "3.5",
        "hispanicOrLatino": "14.4"
      }
    },
    "wageData": {
      "2017": {
        "workers": "421",
        "median": "957",
        "maleWorkers": "181",
        "maleMedian": "1,163",
        "femaleWorkers": "241",
        "femaleMedian": "873"
      }
    }
  },
  "Social and community service managers": {
    "ageData": {
      "2017": {
        "total": "390",
        "16to19": "0",
        "20to24": "14",
        "25to34": "79",
        "35to44": "93",
        "45to54": "90",
        "55to64": "79",
        "65+": "34",
        "medianAge": "79"
      }
    },
    "title": "Social and community service managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "390",
        "women": "70.2",
        "white": "78.1",
        "black": "13.7",
        "asian": "4.0",
        "hispanicOrLatino": "8.6"
      }
    },
    "wageData": {
      "2017": {
        "workers": "337",
        "median": "1,136",
        "maleWorkers": "101",
        "maleMedian": "1,281",
        "femaleWorkers": "236",
        "femaleMedian": "1,052"
      }
    }
  },
  "Emergency management directors": {
    "ageData": {
      "2017": {
        "total": "11",
        "16to19": "0",
        "20to24": "1",
        "25to34": "3",
        "35to44": "1",
        "45to54": "2",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Emergency management directors",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "11",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "10",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Managers, all other": {
    "ageData": {
      "2017": {
        "total": "4,398",
        "16to19": "7",
        "20to24": "110",
        "25to34": "731",
        "35to44": "1,095",
        "45to54": "1,206",
        "55to64": "935",
        "65+": "314",
        "medianAge": "935"
      }
    },
    "title": "Managers, all other",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "4,398",
        "women": "35.8",
        "white": "83.2",
        "black": "7.4",
        "asian": "7.0",
        "hispanicOrLatino": "9.4"
      }
    },
    "wageData": {
      "2017": {
        "workers": "3,004",
        "median": "1,488",
        "maleWorkers": "1,841",
        "maleMedian": "1,629",
        "femaleWorkers": "1,163",
        "femaleMedian": "1,251"
      }
    }
  },
  "Business and financial operations occupations": {
    "ageData": {
      "2017": {
        "total": "7,574",
        "16to19": "29",
        "20to24": "423",
        "25to34": "1,975",
        "35to44": "1,660",
        "45to54": "1,639",
        "55to64": "1,367",
        "65+": "482",
        "medianAge": "1,367"
      }
    },
    "title": "Business and financial operations occupations",
    "depth": 3,
    "parent": "Management, business, and financial operations occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "7,574",
        "women": "54.0",
        "white": "80.7",
        "black": "9.4",
        "asian": "7.5",
        "hispanicOrLatino": "10.2"
      }
    },
    "wageData": {
      "2017": {
        "workers": "6,245",
        "median": "1,174",
        "maleWorkers": "2,847",
        "maleMedian": "1,390",
        "femaleWorkers": "3,398",
        "femaleMedian": "1,055"
      }
    }
  },
  "Agents and business managers of artists, performers, and athletes": {
    "ageData": {
      "2017": {
        "total": "50",
        "16to19": "0",
        "20to24": "7",
        "25to34": "14",
        "35to44": "9",
        "45to54": "11",
        "55to64": "6",
        "65+": "4",
        "medianAge": "6"
      }
    },
    "title": "Agents and business managers of artists, performers, and athletes",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "50",
        "women": "44.4",
        "white": "77.3",
        "black": "15.9",
        "asian": "6.1",
        "hispanicOrLatino": "4.0"
      }
    },
    "wageData": {
      "2017": {
        "workers": "23",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "15",
        "femaleMedian": "-"
      }
    }
  },
  "Buyers and purchasing agents, farm products": {
    "ageData": {
      "2017": {
        "total": "11",
        "16to19": "1",
        "20to24": "0",
        "25to34": "3",
        "35to44": "2",
        "45to54": "2",
        "55to64": "1",
        "65+": "1",
        "medianAge": "1"
      }
    },
    "title": "Buyers and purchasing agents, farm products",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "11",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "10",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Wholesale and retail buyers, except farm products": {
    "ageData": {
      "2017": {
        "total": "194",
        "16to19": "2",
        "20to24": "13",
        "25to34": "59",
        "35to44": "40",
        "45to54": "36",
        "55to64": "29",
        "65+": "15",
        "medianAge": "29"
      }
    },
    "title": "Wholesale and retail buyers, except farm products",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "194",
        "women": "55.8",
        "white": "84.2",
        "black": "7.7",
        "asian": "6.2",
        "hispanicOrLatino": "9.0"
      }
    },
    "wageData": {
      "2017": {
        "workers": "154",
        "median": "886",
        "maleWorkers": "67",
        "maleMedian": "882",
        "femaleWorkers": "87",
        "femaleMedian": "888"
      }
    }
  },
  "Purchasing agents, except wholesale, retail, and farm products": {
    "ageData": {
      "2017": {
        "total": "270",
        "16to19": "2",
        "20to24": "11",
        "25to34": "52",
        "35to44": "55",
        "45to54": "70",
        "55to64": "63",
        "65+": "17",
        "medianAge": "63"
      }
    },
    "title": "Purchasing agents, except wholesale, retail, and farm products",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "270",
        "women": "44.1",
        "white": "82.7",
        "black": "9.4",
        "asian": "6.1",
        "hispanicOrLatino": "11.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "240",
        "median": "1,132",
        "maleWorkers": "143",
        "maleMedian": "1,144",
        "femaleWorkers": "96",
        "femaleMedian": "1,085"
      }
    }
  },
  "Claims adjusters, appraisers, examiners, and investigators": {
    "ageData": {
      "2017": {
        "total": "350",
        "16to19": "1",
        "20to24": "15",
        "25to34": "99",
        "35to44": "79",
        "45to54": "82",
        "55to64": "60",
        "65+": "15",
        "medianAge": "60"
      }
    },
    "title": "Claims adjusters, appraisers, examiners, and investigators",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "350",
        "women": "61.7",
        "white": "77.0",
        "black": "18.2",
        "asian": "3.0",
        "hispanicOrLatino": "11.4"
      }
    },
    "wageData": {
      "2017": {
        "workers": "346",
        "median": "1,002",
        "maleWorkers": "128",
        "maleMedian": "1,108",
        "femaleWorkers": "218",
        "femaleMedian": "925"
      }
    }
  },
  "Compliance officers": {
    "ageData": {
      "2017": {
        "total": "260",
        "16to19": "1",
        "20to24": "2",
        "25to34": "60",
        "35to44": "68",
        "45to54": "54",
        "55to64": "65",
        "65+": "10",
        "medianAge": "65"
      }
    },
    "title": "Compliance officers",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "260",
        "women": "57.6",
        "white": "80.1",
        "black": "11.9",
        "asian": "5.9",
        "hispanicOrLatino": "9.0"
      }
    },
    "wageData": {
      "2017": {
        "workers": "240",
        "median": "1,391",
        "maleWorkers": "106",
        "maleMedian": "1,501",
        "femaleWorkers": "134",
        "femaleMedian": "1,296"
      }
    }
  },
  "Cost estimators": {
    "ageData": {
      "2017": {
        "total": "118",
        "16to19": "0",
        "20to24": "5",
        "25to34": "27",
        "35to44": "26",
        "45to54": "25",
        "55to64": "27",
        "65+": "9",
        "medianAge": "27"
      }
    },
    "title": "Cost estimators",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "118",
        "women": "12.9",
        "white": "94.4",
        "black": "3.1",
        "asian": "2.2",
        "hispanicOrLatino": "8.5"
      }
    },
    "wageData": {
      "2017": {
        "workers": "113",
        "median": "1,139",
        "maleWorkers": "100",
        "maleMedian": "1,157",
        "femaleWorkers": "13",
        "femaleMedian": "-"
      }
    }
  },
  "Human resources workers": {
    "ageData": {
      "2017": {
        "total": "736",
        "16to19": "5",
        "20to24": "50",
        "25to34": "219",
        "35to44": "185",
        "45to54": "141",
        "55to64": "114",
        "65+": "22",
        "medianAge": "114"
      }
    },
    "title": "Human resources workers",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "736",
        "women": "72.1",
        "white": "80.2",
        "black": "11.8",
        "asian": "5.7",
        "hispanicOrLatino": "13.8"
      }
    },
    "wageData": {
      "2017": {
        "workers": "664",
        "median": "1,086",
        "maleWorkers": "182",
        "maleMedian": "1,236",
        "femaleWorkers": "482",
        "femaleMedian": "1,035"
      }
    }
  },
  "Compensation, benefits, and job analysis specialists": {
    "ageData": {
      "2017": {
        "total": "59",
        "16to19": "0",
        "20to24": "2",
        "25to34": "12",
        "35to44": "17",
        "45to54": "11",
        "55to64": "12",
        "65+": "5",
        "medianAge": "12"
      }
    },
    "title": "Compensation, benefits, and job analysis specialists",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "59",
        "women": "72.3",
        "white": "72.2",
        "black": "21.5",
        "asian": "2.3",
        "hispanicOrLatino": "15.7"
      }
    },
    "wageData": {
      "2017": {
        "workers": "60",
        "median": "1,042",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "39",
        "femaleMedian": "-"
      }
    }
  },
  "Training and development specialists": {
    "ageData": {
      "2017": {
        "total": "133",
        "16to19": "1",
        "20to24": "11",
        "25to34": "36",
        "35to44": "28",
        "45to54": "26",
        "55to64": "26",
        "65+": "5",
        "medianAge": "26"
      }
    },
    "title": "Training and development specialists",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "133",
        "women": "47.1",
        "white": "81.6",
        "black": "12.4",
        "asian": "1.1",
        "hispanicOrLatino": "18.2"
      }
    },
    "wageData": {
      "2017": {
        "workers": "114",
        "median": "1,099",
        "maleWorkers": "59",
        "maleMedian": "1,255",
        "femaleWorkers": "55",
        "femaleMedian": "937"
      }
    }
  },
  "Logisticians": {
    "ageData": {
      "2017": {
        "total": "112",
        "16to19": "0",
        "20to24": "7",
        "25to34": "35",
        "35to44": "25",
        "45to54": "24",
        "55to64": "18",
        "65+": "3",
        "medianAge": "18"
      }
    },
    "title": "Logisticians",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "112",
        "women": "34.8",
        "white": "70.9",
        "black": "17.1",
        "asian": "7.4",
        "hispanicOrLatino": "11.7"
      }
    },
    "wageData": {
      "2017": {
        "workers": "101",
        "median": "1,230",
        "maleWorkers": "67",
        "maleMedian": "1,358",
        "femaleWorkers": "33",
        "femaleMedian": "-"
      }
    }
  },
  "Management analysts": {
    "ageData": {
      "2017": {
        "total": "937",
        "16to19": "1",
        "20to24": "37",
        "25to34": "208",
        "35to44": "203",
        "45to54": "204",
        "55to64": "195",
        "65+": "87",
        "medianAge": "195"
      }
    },
    "title": "Management analysts",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "937",
        "women": "42.3",
        "white": "77.2",
        "black": "9.1",
        "asian": "11.2",
        "hispanicOrLatino": "7.4"
      }
    },
    "wageData": {
      "2017": {
        "workers": "606",
        "median": "1,499",
        "maleWorkers": "351",
        "maleMedian": "1,651",
        "femaleWorkers": "255",
        "femaleMedian": "1,315"
      }
    }
  },
  "Meeting, convention, and event planners": {
    "ageData": {
      "2017": {
        "total": "137",
        "16to19": "2",
        "20to24": "13",
        "25to34": "53",
        "35to44": "17",
        "45to54": "24",
        "55to64": "20",
        "65+": "7",
        "medianAge": "20"
      }
    },
    "title": "Meeting, convention, and event planners",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "137",
        "women": "85.1",
        "white": "91.6",
        "black": "4.7",
        "asian": "1.4",
        "hispanicOrLatino": "9.0"
      }
    },
    "wageData": {
      "2017": {
        "workers": "102",
        "median": "1,080",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "89",
        "femaleMedian": "1,064"
      }
    }
  },
  "Fundraisers": {
    "ageData": {
      "2017": {
        "total": "84",
        "16to19": "3",
        "20to24": "6",
        "25to34": "24",
        "35to44": "20",
        "45to54": "10",
        "55to64": "17",
        "65+": "5",
        "medianAge": "17"
      }
    },
    "title": "Fundraisers",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "84",
        "women": "77.3",
        "white": "88.4",
        "black": "8.8",
        "asian": "0.2",
        "hispanicOrLatino": "2.6"
      }
    },
    "wageData": {
      "2017": {
        "workers": "72",
        "median": "1,152",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "55",
        "femaleMedian": "1,152"
      }
    }
  },
  "Market research analysts and marketing specialists": {
    "ageData": {
      "2017": {
        "total": "344",
        "16to19": "6",
        "20to24": "39",
        "25to34": "120",
        "35to44": "71",
        "45to54": "65",
        "55to64": "34",
        "65+": "9",
        "medianAge": "34"
      }
    },
    "title": "Market research analysts and marketing specialists",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "344",
        "women": "60.8",
        "white": "84.2",
        "black": "5.4",
        "asian": "7.7",
        "hispanicOrLatino": "9.0"
      }
    },
    "wageData": {
      "2017": {
        "workers": "258",
        "median": "1,174",
        "maleWorkers": "110",
        "maleMedian": "1,357",
        "femaleWorkers": "149",
        "femaleMedian": "1,061"
      }
    }
  },
  "Business operations specialists, all other": {
    "ageData": {
      "2017": {
        "total": "273",
        "16to19": "0",
        "20to24": "25",
        "25to34": "61",
        "35to44": "75",
        "45to54": "58",
        "55to64": "44",
        "65+": "10",
        "medianAge": "44"
      }
    },
    "title": "Business operations specialists, all other",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "273",
        "women": "52.5",
        "white": "79.2",
        "black": "9.1",
        "asian": "9.3",
        "hispanicOrLatino": "13.6"
      }
    },
    "wageData": {
      "2017": {
        "workers": "250",
        "median": "1,136",
        "maleWorkers": "118",
        "maleMedian": "1,361",
        "femaleWorkers": "132",
        "femaleMedian": "1,013"
      }
    }
  },
  "Accountants and auditors": {
    "ageData": {
      "2017": {
        "total": "1,804",
        "16to19": "2",
        "20to24": "95",
        "25to34": "454",
        "35to44": "358",
        "45to54": "408",
        "55to64": "341",
        "65+": "146",
        "medianAge": "341"
      }
    },
    "title": "Accountants and auditors",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "1,804",
        "women": "60.3",
        "white": "79.5",
        "black": "8.2",
        "asian": "9.7",
        "hispanicOrLatino": "9.6"
      }
    },
    "wageData": {
      "2017": {
        "workers": "1,512",
        "median": "1,173",
        "maleWorkers": "622",
        "maleMedian": "1,389",
        "femaleWorkers": "890",
        "femaleMedian": "1,065"
      }
    }
  },
  "Appraisers and assessors of real estate": {
    "ageData": {
      "2017": {
        "total": "97",
        "16to19": "0",
        "20to24": "2",
        "25to34": "15",
        "35to44": "22",
        "45to54": "28",
        "55to64": "20",
        "65+": "11",
        "medianAge": "20"
      }
    },
    "title": "Appraisers and assessors of real estate",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "97",
        "women": "37.4",
        "white": "88.8",
        "black": "9.0",
        "asian": "1.8",
        "hispanicOrLatino": "11.0"
      }
    },
    "wageData": {
      "2017": {
        "workers": "55",
        "median": "1,074",
        "maleWorkers": "35",
        "maleMedian": "-",
        "femaleWorkers": "20",
        "femaleMedian": "-"
      }
    }
  },
  "Budget analysts": {
    "ageData": {
      "2017": {
        "total": "53",
        "16to19": "0",
        "20to24": "1",
        "25to34": "10",
        "35to44": "11",
        "45to54": "16",
        "55to64": "13",
        "65+": "2",
        "medianAge": "13"
      }
    },
    "title": "Budget analysts",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "53",
        "women": "46.8",
        "white": "71.1",
        "black": "12.5",
        "asian": "9.7",
        "hispanicOrLatino": "11.1"
      }
    },
    "wageData": {
      "2017": {
        "workers": "54",
        "median": "1,377",
        "maleWorkers": "29",
        "maleMedian": "-",
        "femaleWorkers": "26",
        "femaleMedian": "-"
      }
    }
  },
  "Credit analysts": {
    "ageData": {
      "2017": {
        "total": "29",
        "16to19": "0",
        "20to24": "4",
        "25to34": "8",
        "35to44": "9",
        "45to54": "4",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Credit analysts",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "29",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "27",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "15",
        "femaleMedian": "-"
      }
    }
  },
  "Financial analysts": {
    "ageData": {
      "2017": {
        "total": "309",
        "16to19": "0",
        "20to24": "24",
        "25to34": "114",
        "35to44": "59",
        "45to54": "63",
        "55to64": "35",
        "65+": "14",
        "medianAge": "35"
      }
    },
    "title": "Financial analysts",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "309",
        "women": "36.5",
        "white": "75.6",
        "black": "8.8",
        "asian": "12.5",
        "hispanicOrLatino": "6.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "264",
        "median": "1,530",
        "maleWorkers": "156",
        "maleMedian": "1,590",
        "femaleWorkers": "108",
        "femaleMedian": "1,369"
      }
    }
  },
  "Personal financial advisors": {
    "ageData": {
      "2017": {
        "total": "525",
        "16to19": "0",
        "20to24": "22",
        "25to34": "131",
        "35to44": "129",
        "45to54": "101",
        "55to64": "102",
        "65+": "39",
        "medianAge": "102"
      }
    },
    "title": "Personal financial advisors",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "525",
        "women": "32.0",
        "white": "87.0",
        "black": "4.8",
        "asian": "6.6",
        "hispanicOrLatino": "8.3"
      }
    },
    "wageData": {
      "2017": {
        "workers": "398",
        "median": "1,425",
        "maleWorkers": "267",
        "maleMedian": "1,662",
        "femaleWorkers": "131",
        "femaleMedian": "979"
      }
    }
  },
  "Insurance underwriters": {
    "ageData": {
      "2017": {
        "total": "104",
        "16to19": "0",
        "20to24": "4",
        "25to34": "28",
        "35to44": "27",
        "45to54": "27",
        "55to64": "16",
        "65+": "2",
        "medianAge": "16"
      }
    },
    "title": "Insurance underwriters",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "104",
        "women": "63.8",
        "white": "85.5",
        "black": "8.8",
        "asian": "5.4",
        "hispanicOrLatino": "8.1"
      }
    },
    "wageData": {
      "2017": {
        "workers": "92",
        "median": "1,153",
        "maleWorkers": "35",
        "maleMedian": "-",
        "femaleWorkers": "57",
        "femaleMedian": "1,115"
      }
    }
  },
  "Financial examiners": {
    "ageData": {
      "2017": {
        "total": "15",
        "16to19": "0",
        "20to24": "0",
        "25to34": "6",
        "35to44": "2",
        "45to54": "3",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Financial examiners",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "15",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "15",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Credit counselors and loan officers": {
    "ageData": {
      "2017": {
        "total": "353",
        "16to19": "3",
        "20to24": "17",
        "25to34": "88",
        "35to44": "92",
        "45to54": "84",
        "55to64": "55",
        "65+": "13",
        "medianAge": "55"
      }
    },
    "title": "Credit counselors and loan officers",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "353",
        "women": "56.5",
        "white": "83.8",
        "black": "8.5",
        "asian": "4.2",
        "hispanicOrLatino": "14.2"
      }
    },
    "wageData": {
      "2017": {
        "workers": "320",
        "median": "1,070",
        "maleWorkers": "127",
        "maleMedian": "1,332",
        "femaleWorkers": "193",
        "femaleMedian": "958"
      }
    }
  },
  "Tax examiners and collectors, and revenue agents": {
    "ageData": {
      "2017": {
        "total": "65",
        "16to19": "0",
        "20to24": "3",
        "25to34": "16",
        "35to44": "6",
        "45to54": "19",
        "55to64": "15",
        "65+": "5",
        "medianAge": "15"
      }
    },
    "title": "Tax examiners and collectors, and revenue agents",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "65",
        "women": "58.4",
        "white": "77.2",
        "black": "11.1",
        "asian": "9.8",
        "hispanicOrLatino": "14.8"
      }
    },
    "wageData": {
      "2017": {
        "workers": "62",
        "median": "1,078",
        "maleWorkers": "28",
        "maleMedian": "-",
        "femaleWorkers": "33",
        "femaleMedian": "-"
      }
    }
  },
  "Tax preparers": {
    "ageData": {
      "2017": {
        "total": "107",
        "16to19": "0",
        "20to24": "4",
        "25to34": "12",
        "35to44": "16",
        "45to54": "29",
        "55to64": "25",
        "65+": "19",
        "medianAge": "25"
      }
    },
    "title": "Tax preparers",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "107",
        "women": "63.1",
        "white": "78.3",
        "black": "17.2",
        "asian": "4.0",
        "hispanicOrLatino": "14.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "56",
        "median": "791",
        "maleWorkers": "18",
        "maleMedian": "-",
        "femaleWorkers": "39",
        "femaleMedian": "-"
      }
    }
  },
  "Financial specialists, all other": {
    "ageData": {
      "2017": {
        "total": "45",
        "16to19": "0",
        "20to24": "4",
        "25to34": "11",
        "35to44": "9",
        "45to54": "11",
        "55to64": "7",
        "65+": "4",
        "medianAge": "7"
      }
    },
    "title": "Financial specialists, all other",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "45",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "36",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "24",
        "femaleMedian": "-"
      }
    }
  },
  "Professional and related occupations": {
    "ageData": {
      "2017": {
        "total": "35,522",
        "16to19": "331",
        "20to24": "2,299",
        "25to34": "9,006",
        "35to44": "8,273",
        "45to54": "7,503",
        "55to64": "5,949",
        "65+": "2,162",
        "medianAge": "5,949"
      }
    },
    "title": "Professional and related occupations",
    "depth": 2,
    "parent": "Management, professional, and related occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "35,522",
        "women": "57.0",
        "white": "77.9",
        "black": "10.3",
        "asian": "9.3",
        "hispanicOrLatino": "9.3"
      }
    },
    "wageData": {
      "2017": {
        "workers": "27,794",
        "median": "1,160",
        "maleWorkers": "12,400",
        "maleMedian": "1,391",
        "femaleWorkers": "15,394",
        "femaleMedian": "1,014"
      }
    }
  },
  "Computer and mathematical occupations": {
    "ageData": {
      "2017": {
        "total": "4,804",
        "16to19": "28",
        "20to24": "284",
        "25to34": "1,408",
        "35to44": "1,286",
        "45to54": "1,020",
        "55to64": "642",
        "65+": "135",
        "medianAge": "642"
      }
    },
    "title": "Computer and mathematical occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "4,804",
        "women": "25.5",
        "white": "67.8",
        "black": "8.7",
        "asian": "20.8",
        "hispanicOrLatino": "7.3"
      }
    },
    "wageData": {
      "2017": {
        "workers": "4,350",
        "median": "1,465",
        "maleWorkers": "3,240",
        "maleMedian": "1,552",
        "femaleWorkers": "1,110",
        "femaleMedian": "1,235"
      }
    }
  },
  "Computer and information research scientists": {
    "ageData": {
      "2017": {
        "total": "24",
        "16to19": "0",
        "20to24": "1",
        "25to34": "10",
        "35to44": "6",
        "45to54": "4",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Computer and information research scientists",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "24",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "23",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "9",
        "femaleMedian": "-"
      }
    }
  },
  "Computer systems analysts": {
    "ageData": {
      "2017": {
        "total": "554",
        "16to19": "0",
        "20to24": "34",
        "25to34": "137",
        "35to44": "132",
        "45to54": "136",
        "55to64": "96",
        "65+": "19",
        "medianAge": "96"
      }
    },
    "title": "Computer systems analysts",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "554",
        "women": "38.9",
        "white": "70.9",
        "black": "9.7",
        "asian": "16.6",
        "hispanicOrLatino": "7.2"
      }
    },
    "wageData": {
      "2017": {
        "workers": "522",
        "median": "1,409",
        "maleWorkers": "311",
        "maleMedian": "1,495",
        "femaleWorkers": "211",
        "femaleMedian": "1,270"
      }
    }
  },
  "Information security analysts": {
    "ageData": {
      "2017": {
        "total": "105",
        "16to19": "0",
        "20to24": "6",
        "25to34": "24",
        "35to44": "32",
        "45to54": "26",
        "55to64": "16",
        "65+": "3",
        "medianAge": "16"
      }
    },
    "title": "Information security analysts",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "105",
        "women": "20.2",
        "white": "68.5",
        "black": "15.6",
        "asian": "12.6",
        "hispanicOrLatino": "4.6"
      }
    },
    "wageData": {
      "2017": {
        "workers": "94",
        "median": "1,725",
        "maleWorkers": "74",
        "maleMedian": "1,773",
        "femaleWorkers": "20",
        "femaleMedian": "-"
      }
    }
  },
  "Computer programmers": {
    "ageData": {
      "2017": {
        "total": "473",
        "16to19": "4",
        "20to24": "27",
        "25to34": "125",
        "35to44": "131",
        "45to54": "113",
        "55to64": "62",
        "65+": "12",
        "medianAge": "62"
      }
    },
    "title": "Computer programmers",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "473",
        "women": "21.2",
        "white": "65.9",
        "black": "7.1",
        "asian": "25.4",
        "hispanicOrLatino": "5.3"
      }
    },
    "wageData": {
      "2017": {
        "workers": "423",
        "median": "1,483",
        "maleWorkers": "333",
        "maleMedian": "1,539",
        "femaleWorkers": "89",
        "femaleMedian": "1,363"
      }
    }
  },
  "Software developers, applications and systems software": {
    "ageData": {
      "2017": {
        "total": "1,536",
        "16to19": "5",
        "20to24": "80",
        "25to34": "526",
        "35to44": "433",
        "45to54": "277",
        "55to64": "175",
        "65+": "40",
        "medianAge": "175"
      }
    },
    "title": "Software developers, applications and systems software",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "1,536",
        "women": "18.7",
        "white": "59.3",
        "black": "5.6",
        "asian": "32.4",
        "hispanicOrLatino": "6.2"
      }
    },
    "wageData": {
      "2017": {
        "workers": "1,439",
        "median": "1,792",
        "maleWorkers": "1,174",
        "maleMedian": "1,863",
        "femaleWorkers": "265",
        "femaleMedian": "1,543"
      }
    }
  },
  "Web developers": {
    "ageData": {
      "2017": {
        "total": "204",
        "16to19": "3",
        "20to24": "15",
        "25to34": "65",
        "35to44": "62",
        "45to54": "36",
        "55to64": "19",
        "65+": "4",
        "medianAge": "19"
      }
    },
    "title": "Web developers",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "204",
        "women": "32.3",
        "white": "82.0",
        "black": "4.5",
        "asian": "9.8",
        "hispanicOrLatino": "5.8"
      }
    },
    "wageData": {
      "2017": {
        "workers": "144",
        "median": "1,235",
        "maleWorkers": "98",
        "maleMedian": "1,342",
        "femaleWorkers": "46",
        "femaleMedian": "-"
      }
    }
  },
  "Computer support specialists": {
    "ageData": {
      "2017": {
        "total": "532",
        "16to19": "7",
        "20to24": "42",
        "25to34": "164",
        "35to44": "123",
        "45to54": "102",
        "55to64": "81",
        "65+": "13",
        "medianAge": "81"
      }
    },
    "title": "Computer support specialists",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "532",
        "women": "27.1",
        "white": "70.7",
        "black": "14.6",
        "asian": "11.3",
        "hispanicOrLatino": "10.3"
      }
    },
    "wageData": {
      "2017": {
        "workers": "443",
        "median": "1,068",
        "maleWorkers": "319",
        "maleMedian": "1,134",
        "femaleWorkers": "124",
        "femaleMedian": "919"
      }
    }
  },
  "Database administrators": {
    "ageData": {
      "2017": {
        "total": "96",
        "16to19": "0",
        "20to24": "5",
        "25to34": "31",
        "35to44": "17",
        "45to54": "26",
        "55to64": "16",
        "65+": "2",
        "medianAge": "16"
      }
    },
    "title": "Database administrators",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "96",
        "women": "40.6",
        "white": "70.6",
        "black": "10.1",
        "asian": "18.1",
        "hispanicOrLatino": "6.2"
      }
    },
    "wageData": {
      "2017": {
        "workers": "93",
        "median": "1,381",
        "maleWorkers": "56",
        "maleMedian": "1,745",
        "femaleWorkers": "37",
        "femaleMedian": "-"
      }
    }
  },
  "Network and computer systems administrators": {
    "ageData": {
      "2017": {
        "total": "210",
        "16to19": "4",
        "20to24": "11",
        "25to34": "40",
        "35to44": "62",
        "45to54": "55",
        "55to64": "29",
        "65+": "9",
        "medianAge": "29"
      }
    },
    "title": "Network and computer systems administrators",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "210",
        "women": "23.5",
        "white": "79.5",
        "black": "6.3",
        "asian": "11.8",
        "hispanicOrLatino": "10.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "193",
        "median": "1,395",
        "maleWorkers": "155",
        "maleMedian": "1,396",
        "femaleWorkers": "38",
        "femaleMedian": "-"
      }
    }
  },
  "Computer network architects": {
    "ageData": {
      "2017": {
        "total": "106",
        "16to19": "0",
        "20to24": "4",
        "25to34": "23",
        "35to44": "37",
        "45to54": "28",
        "55to64": "10",
        "65+": "4",
        "medianAge": "10"
      }
    },
    "title": "Computer network architects",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "106",
        "women": "4.2",
        "white": "73.1",
        "black": "10.6",
        "asian": "15.2",
        "hispanicOrLatino": "3.1"
      }
    },
    "wageData": {
      "2017": {
        "workers": "100",
        "median": "1,636",
        "maleWorkers": "97",
        "maleMedian": "1,659",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Computer occupations, all other": {
    "ageData": {
      "2017": {
        "total": "693",
        "16to19": "5",
        "20to24": "45",
        "25to34": "189",
        "35to44": "184",
        "45to54": "159",
        "55to64": "90",
        "65+": "20",
        "medianAge": "90"
      }
    },
    "title": "Computer occupations, all other",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "693",
        "women": "22.6",
        "white": "73.5",
        "black": "11.4",
        "asian": "11.9",
        "hispanicOrLatino": "9.7"
      }
    },
    "wageData": {
      "2017": {
        "workers": "627",
        "median": "1,194",
        "maleWorkers": "484",
        "maleMedian": "1,250",
        "femaleWorkers": "142",
        "femaleMedian": "1,059"
      }
    }
  },
  "Actuaries": {
    "ageData": {
      "2017": {
        "total": "29",
        "16to19": "0",
        "20to24": "2",
        "25to34": "14",
        "35to44": "4",
        "45to54": "7",
        "55to64": "1",
        "65+": "1",
        "medianAge": "1"
      }
    },
    "title": "Actuaries",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "29",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "23",
        "median": "-",
        "maleWorkers": "16",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Mathematicians": {
    "ageData": {
      "2017": {
        "total": "3",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "2",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Mathematicians",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Operations research analysts": {
    "ageData": {
      "2017": {
        "total": "147",
        "16to19": "0",
        "20to24": "3",
        "25to34": "27",
        "35to44": "37",
        "45to54": "42",
        "55to64": "32",
        "65+": "7",
        "medianAge": "32"
      }
    },
    "title": "Operations research analysts",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "147",
        "women": "51.5",
        "white": "70.9",
        "black": "12.4",
        "asian": "15.3",
        "hispanicOrLatino": "7.3"
      }
    },
    "wageData": {
      "2017": {
        "workers": "146",
        "median": "1,396",
        "maleWorkers": "71",
        "maleMedian": "1,570",
        "femaleWorkers": "74",
        "femaleMedian": "1,205"
      }
    }
  },
  "Statisticians": {
    "ageData": {
      "2017": {
        "total": "87",
        "16to19": "0",
        "20to24": "9",
        "25to34": "33",
        "35to44": "24",
        "45to54": "8",
        "55to64": "11",
        "65+": "2",
        "medianAge": "11"
      }
    },
    "title": "Statisticians",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "87",
        "women": "48.8",
        "white": "63.1",
        "black": "9.0",
        "asian": "22.4",
        "hispanicOrLatino": "6.4"
      }
    },
    "wageData": {
      "2017": {
        "workers": "77",
        "median": "1,433",
        "maleWorkers": "36",
        "maleMedian": "-",
        "femaleWorkers": "41",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous mathematical science occupations": {
    "ageData": {
      "2017": {
        "total": "3",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "1",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Miscellaneous mathematical science occupations",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Architecture and engineering occupations": {
    "ageData": {
      "2017": {
        "total": "3,224",
        "16to19": "7",
        "20to24": "221",
        "25to34": "814",
        "35to44": "723",
        "45to54": "690",
        "55to64": "609",
        "65+": "160",
        "medianAge": "609"
      }
    },
    "title": "Architecture and engineering occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "3,224",
        "women": "16.2",
        "white": "79.3",
        "black": "5.6",
        "asian": "12.7",
        "hispanicOrLatino": "8.7"
      }
    },
    "wageData": {
      "2017": {
        "workers": "2,936",
        "median": "1,478",
        "maleWorkers": "2,494",
        "maleMedian": "1,518",
        "femaleWorkers": "442",
        "femaleMedian": "1,307"
      }
    }
  },
  "Architects, except naval": {
    "ageData": {
      "2017": {
        "total": "253",
        "16to19": "0",
        "20to24": "5",
        "25to34": "66",
        "35to44": "55",
        "45to54": "52",
        "55to64": "53",
        "65+": "21",
        "medianAge": "53"
      }
    },
    "title": "Architects, except naval",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "253",
        "women": "28.6",
        "white": "82.5",
        "black": "2.1",
        "asian": "14.0",
        "hispanicOrLatino": "7.7"
      }
    },
    "wageData": {
      "2017": {
        "workers": "196",
        "median": "1,453",
        "maleWorkers": "150",
        "maleMedian": "1,650",
        "femaleWorkers": "46",
        "femaleMedian": "-"
      }
    }
  },
  "Surveyors, cartographers, and photogrammetrists": {
    "ageData": {
      "2017": {
        "total": "43",
        "16to19": "0",
        "20to24": "2",
        "25to34": "7",
        "35to44": "17",
        "45to54": "9",
        "55to64": "7",
        "65+": "2",
        "medianAge": "7"
      }
    },
    "title": "Surveyors, cartographers, and photogrammetrists",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "43",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "30",
        "median": "-",
        "maleWorkers": "25",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Aerospace engineers": {
    "ageData": {
      "2017": {
        "total": "148",
        "16to19": "1",
        "20to24": "7",
        "25to34": "34",
        "35to44": "28",
        "45to54": "33",
        "55to64": "40",
        "65+": "7",
        "medianAge": "40"
      }
    },
    "title": "Aerospace engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "148",
        "women": "8.9",
        "white": "79.1",
        "black": "8.1",
        "asian": "11.4",
        "hispanicOrLatino": "5.5"
      }
    },
    "wageData": {
      "2017": {
        "workers": "137",
        "median": "1,892",
        "maleWorkers": "128",
        "maleMedian": "1,891",
        "femaleWorkers": "9",
        "femaleMedian": "-"
      }
    }
  },
  "Agricultural engineers": {
    "ageData": {
      "2017": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "1",
        "45to54": "0",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Agricultural engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Biomedical engineers": {
    "ageData": {
      "2017": {
        "total": "17",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "8",
        "45to54": "3",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Biomedical engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "17",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "16",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Chemical engineers": {
    "ageData": {
      "2017": {
        "total": "77",
        "16to19": "0",
        "20to24": "6",
        "25to34": "20",
        "35to44": "10",
        "45to54": "20",
        "55to64": "17",
        "65+": "4",
        "medianAge": "17"
      }
    },
    "title": "Chemical engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "77",
        "women": "16.9",
        "white": "91.0",
        "black": "1.7",
        "asian": "7.0",
        "hispanicOrLatino": "4.1"
      }
    },
    "wageData": {
      "2017": {
        "workers": "78",
        "median": "1,626",
        "maleWorkers": "66",
        "maleMedian": "1,631",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Civil engineers": {
    "ageData": {
      "2017": {
        "total": "461",
        "16to19": "0",
        "20to24": "37",
        "25to34": "118",
        "35to44": "111",
        "45to54": "89",
        "55to64": "82",
        "65+": "24",
        "medianAge": "82"
      }
    },
    "title": "Civil engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "461",
        "women": "14.4",
        "white": "82.5",
        "black": "6.4",
        "asian": "7.1",
        "hispanicOrLatino": "10.7"
      }
    },
    "wageData": {
      "2017": {
        "workers": "393",
        "median": "1,494",
        "maleWorkers": "332",
        "maleMedian": "1,524",
        "femaleWorkers": "61",
        "femaleMedian": "1,343"
      }
    }
  },
  "Computer hardware engineers": {
    "ageData": {
      "2017": {
        "total": "80",
        "16to19": "0",
        "20to24": "6",
        "25to34": "18",
        "35to44": "26",
        "45to54": "20",
        "55to64": "8",
        "65+": "2",
        "medianAge": "8"
      }
    },
    "title": "Computer hardware engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "80",
        "women": "17.2",
        "white": "55.4",
        "black": "5.8",
        "asian": "38.3",
        "hispanicOrLatino": "7.8"
      }
    },
    "wageData": {
      "2017": {
        "workers": "73",
        "median": "1,771",
        "maleWorkers": "60",
        "maleMedian": "1,793",
        "femaleWorkers": "13",
        "femaleMedian": "-"
      }
    }
  },
  "Electrical and electronics engineers": {
    "ageData": {
      "2017": {
        "total": "284",
        "16to19": "1",
        "20to24": "14",
        "25to34": "63",
        "35to44": "67",
        "45to54": "73",
        "55to64": "58",
        "65+": "9",
        "medianAge": "58"
      }
    },
    "title": "Electrical and electronics engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "284",
        "women": "12.3",
        "white": "74.6",
        "black": "3.5",
        "asian": "19.5",
        "hispanicOrLatino": "9.0"
      }
    },
    "wageData": {
      "2017": {
        "workers": "276",
        "median": "1,803",
        "maleWorkers": "245",
        "maleMedian": "1,772",
        "femaleWorkers": "30",
        "femaleMedian": "-"
      }
    }
  },
  "Environmental engineers": {
    "ageData": {
      "2017": {
        "total": "38",
        "16to19": "0",
        "20to24": "2",
        "25to34": "7",
        "35to44": "6",
        "45to54": "10",
        "55to64": "10",
        "65+": "3",
        "medianAge": "10"
      }
    },
    "title": "Environmental engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "38",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "32",
        "median": "-",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "Industrial engineers, including health and safety": {
    "ageData": {
      "2017": {
        "total": "221",
        "16to19": "0",
        "20to24": "16",
        "25to34": "65",
        "35to44": "44",
        "45to54": "47",
        "55to64": "43",
        "65+": "6",
        "medianAge": "43"
      }
    },
    "title": "Industrial engineers, including health and safety",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "221",
        "women": "22.6",
        "white": "80.8",
        "black": "5.3",
        "asian": "11.6",
        "hispanicOrLatino": "6.2"
      }
    },
    "wageData": {
      "2017": {
        "workers": "213",
        "median": "1,496",
        "maleWorkers": "168",
        "maleMedian": "1,520",
        "femaleWorkers": "46",
        "femaleMedian": "-"
      }
    }
  },
  "Marine engineers and naval architects": {
    "ageData": {
      "2017": {
        "total": "12",
        "16to19": "0",
        "20to24": "0",
        "25to34": "5",
        "35to44": "2",
        "45to54": "1",
        "55to64": "3",
        "65+": "2",
        "medianAge": "3"
      }
    },
    "title": "Marine engineers and naval architects",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "12",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Materials engineers": {
    "ageData": {
      "2017": {
        "total": "32",
        "16to19": "0",
        "20to24": "1",
        "25to34": "4",
        "35to44": "7",
        "45to54": "10",
        "55to64": "8",
        "65+": "1",
        "medianAge": "8"
      }
    },
    "title": "Materials engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "32",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "32",
        "median": "-",
        "maleWorkers": "28",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Mechanical engineers": {
    "ageData": {
      "2017": {
        "total": "344",
        "16to19": "1",
        "20to24": "36",
        "25to34": "92",
        "35to44": "83",
        "45to54": "70",
        "55to64": "49",
        "65+": "14",
        "medianAge": "49"
      }
    },
    "title": "Mechanical engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "344",
        "women": "9.2",
        "white": "84.2",
        "black": "3.6",
        "asian": "11.1",
        "hispanicOrLatino": "6.0"
      }
    },
    "wageData": {
      "2017": {
        "workers": "337",
        "median": "1,534",
        "maleWorkers": "311",
        "maleMedian": "1,533",
        "femaleWorkers": "26",
        "femaleMedian": "-"
      }
    }
  },
  "Mining and geological engineers, including mining safety engineers": {
    "ageData": {
      "2017": {
        "total": "11",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "2",
        "45to54": "1",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Mining and geological engineers, including mining safety engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "11",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Nuclear engineers": {
    "ageData": {
      "2017": {
        "total": "8",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "4",
        "45to54": "2",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Nuclear engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "8",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Petroleum engineers": {
    "ageData": {
      "2017": {
        "total": "24",
        "16to19": "0",
        "20to24": "2",
        "25to34": "8",
        "35to44": "6",
        "45to54": "4",
        "55to64": "4",
        "65+": "1",
        "medianAge": "4"
      }
    },
    "title": "Petroleum engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "24",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "23",
        "median": "-",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Engineers, all other": {
    "ageData": {
      "2017": {
        "total": "582",
        "16to19": "1",
        "20to24": "30",
        "25to34": "164",
        "35to44": "124",
        "45to54": "127",
        "55to64": "110",
        "65+": "28",
        "medianAge": "110"
      }
    },
    "title": "Engineers, all other",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "582",
        "women": "14.1",
        "white": "73.1",
        "black": "6.9",
        "asian": "17.8",
        "hispanicOrLatino": "9.4"
      }
    },
    "wageData": {
      "2017": {
        "workers": "546",
        "median": "1,561",
        "maleWorkers": "473",
        "maleMedian": "1,571",
        "femaleWorkers": "73",
        "femaleMedian": "1,436"
      }
    }
  },
  "Drafters": {
    "ageData": {
      "2017": {
        "total": "137",
        "16to19": "1",
        "20to24": "16",
        "25to34": "35",
        "35to44": "25",
        "45to54": "31",
        "55to64": "23",
        "65+": "6",
        "medianAge": "23"
      }
    },
    "title": "Drafters",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "137",
        "women": "20.8",
        "white": "89.9",
        "black": "6.2",
        "asian": "2.4",
        "hispanicOrLatino": "16.5"
      }
    },
    "wageData": {
      "2017": {
        "workers": "122",
        "median": "1,126",
        "maleWorkers": "100",
        "maleMedian": "1,149",
        "femaleWorkers": "21",
        "femaleMedian": "-"
      }
    }
  },
  "Engineering technicians, except drafters": {
    "ageData": {
      "2017": {
        "total": "375",
        "16to19": "2",
        "20to24": "34",
        "25to34": "82",
        "35to44": "81",
        "45to54": "76",
        "55to64": "76",
        "65+": "24",
        "medianAge": "76"
      }
    },
    "title": "Engineering technicians, except drafters",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "375",
        "women": "20.0",
        "white": "76.9",
        "black": "8.9",
        "asian": "10.7",
        "hispanicOrLatino": "10.3"
      }
    },
    "wageData": {
      "2017": {
        "workers": "339",
        "median": "1,053",
        "maleWorkers": "269",
        "maleMedian": "1,102",
        "femaleWorkers": "70",
        "femaleMedian": "884"
      }
    }
  },
  "Surveying and mapping technicians": {
    "ageData": {
      "2017": {
        "total": "73",
        "16to19": "0",
        "20to24": "7",
        "25to34": "19",
        "35to44": "15",
        "45to54": "16",
        "55to64": "13",
        "65+": "3",
        "medianAge": "13"
      }
    },
    "title": "Surveying and mapping technicians",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "73",
        "women": "4.6",
        "white": "90.3",
        "black": "2.1",
        "asian": "1.7",
        "hispanicOrLatino": "10.1"
      }
    },
    "wageData": {
      "2017": {
        "workers": "63",
        "median": "1,109",
        "maleWorkers": "61",
        "maleMedian": "1,114",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Life, physical, and social science occupations": {
    "ageData": {
      "2017": {
        "total": "1,431",
        "16to19": "5",
        "20to24": "103",
        "25to34": "399",
        "35to44": "335",
        "45to54": "262",
        "55to64": "241",
        "65+": "86",
        "medianAge": "241"
      }
    },
    "title": "Life, physical, and social science occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "1,431",
        "women": "47.4",
        "white": "76.4",
        "black": "5.7",
        "asian": "15.8",
        "hispanicOrLatino": "9.0"
      }
    },
    "wageData": {
      "2017": {
        "workers": "1,238",
        "median": "1,286",
        "maleWorkers": "674",
        "maleMedian": "1,361",
        "femaleWorkers": "564",
        "femaleMedian": "1,183"
      }
    }
  },
  "Agricultural and food scientists": {
    "ageData": {
      "2017": {
        "total": "32",
        "16to19": "1",
        "20to24": "4",
        "25to34": "10",
        "35to44": "4",
        "45to54": "5",
        "55to64": "5",
        "65+": "3",
        "medianAge": "5"
      }
    },
    "title": "Agricultural and food scientists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "32",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "25",
        "median": "-",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Biological scientists": {
    "ageData": {
      "2017": {
        "total": "98",
        "16to19": "0",
        "20to24": "6",
        "25to34": "23",
        "35to44": "38",
        "45to54": "17",
        "55to64": "11",
        "65+": "4",
        "medianAge": "11"
      }
    },
    "title": "Biological scientists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "98",
        "women": "54.4",
        "white": "81.2",
        "black": "3.1",
        "asian": "13.1",
        "hispanicOrLatino": "7.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "82",
        "median": "1,140",
        "maleWorkers": "41",
        "maleMedian": "-",
        "femaleWorkers": "41",
        "femaleMedian": "-"
      }
    }
  },
  "Conservation scientists and foresters": {
    "ageData": {
      "2017": {
        "total": "29",
        "16to19": "0",
        "20to24": "2",
        "25to34": "7",
        "35to44": "7",
        "45to54": "7",
        "55to64": "5",
        "65+": "1",
        "medianAge": "5"
      }
    },
    "title": "Conservation scientists and foresters",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "29",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "26",
        "median": "-",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "Medical scientists": {
    "ageData": {
      "2017": {
        "total": "161",
        "16to19": "1",
        "20to24": "12",
        "25to34": "53",
        "35to44": "35",
        "45to54": "33",
        "55to64": "21",
        "65+": "8",
        "medianAge": "21"
      }
    },
    "title": "Medical scientists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "161",
        "women": "52.1",
        "white": "66.4",
        "black": "6.6",
        "asian": "26.2",
        "hispanicOrLatino": "6.7"
      }
    },
    "wageData": {
      "2017": {
        "workers": "162",
        "median": "1,378",
        "maleWorkers": "82",
        "maleMedian": "1,383",
        "femaleWorkers": "80",
        "femaleMedian": "1,270"
      }
    }
  },
  "Life scientists, all other": {
    "ageData": {
      "2017": {
        "total": "3",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Life scientists, all other",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Astronomers and physicists": {
    "ageData": {
      "2017": {
        "total": "18",
        "16to19": "0",
        "20to24": "0",
        "25to34": "4",
        "35to44": "4",
        "45to54": "4",
        "55to64": "4",
        "65+": "1",
        "medianAge": "4"
      }
    },
    "title": "Astronomers and physicists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "18",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "16",
        "median": "-",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Atmospheric and space scientists": {
    "ageData": {
      "2017": {
        "total": "7",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "1",
        "45to54": "2",
        "55to64": "1",
        "65+": "1",
        "medianAge": "1"
      }
    },
    "title": "Atmospheric and space scientists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Chemists and materials scientists": {
    "ageData": {
      "2017": {
        "total": "105",
        "16to19": "0",
        "20to24": "3",
        "25to34": "34",
        "35to44": "25",
        "45to54": "21",
        "55to64": "19",
        "65+": "4",
        "medianAge": "19"
      }
    },
    "title": "Chemists and materials scientists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "105",
        "women": "38.3",
        "white": "66.7",
        "black": "11.3",
        "asian": "20.8",
        "hispanicOrLatino": "5.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "98",
        "median": "1,425",
        "maleWorkers": "56",
        "maleMedian": "1,451",
        "femaleWorkers": "41",
        "femaleMedian": "-"
      }
    }
  },
  "Environmental scientists and geoscientists": {
    "ageData": {
      "2017": {
        "total": "84",
        "16to19": "0",
        "20to24": "5",
        "25to34": "19",
        "35to44": "23",
        "45to54": "16",
        "55to64": "19",
        "65+": "3",
        "medianAge": "19"
      }
    },
    "title": "Environmental scientists and geoscientists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "84",
        "women": "35.8",
        "white": "90.8",
        "black": "5.0",
        "asian": "0.7",
        "hispanicOrLatino": "4.8"
      }
    },
    "wageData": {
      "2017": {
        "workers": "80",
        "median": "1,639",
        "maleWorkers": "51",
        "maleMedian": "1,746",
        "femaleWorkers": "30",
        "femaleMedian": "-"
      }
    }
  },
  "Physical scientists, all other": {
    "ageData": {
      "2017": {
        "total": "301",
        "16to19": "0",
        "20to24": "18",
        "25to34": "105",
        "35to44": "81",
        "45to54": "44",
        "55to64": "44",
        "65+": "9",
        "medianAge": "44"
      }
    },
    "title": "Physical scientists, all other",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "301",
        "women": "47.3",
        "white": "61.2",
        "black": "4.0",
        "asian": "32.1",
        "hispanicOrLatino": "7.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "270",
        "median": "1,529",
        "maleWorkers": "147",
        "maleMedian": "1,565",
        "femaleWorkers": "122",
        "femaleMedian": "1,409"
      }
    }
  },
  "Economists": {
    "ageData": {
      "2017": {
        "total": "28",
        "16to19": "0",
        "20to24": "1",
        "25to34": "8",
        "35to44": "5",
        "45to54": "8",
        "55to64": "6",
        "65+": "1",
        "medianAge": "6"
      }
    },
    "title": "Economists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "28",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "29",
        "median": "-",
        "maleWorkers": "18",
        "maleMedian": "-",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Survey researchers": {
    "ageData": {
      "2017": {
        "total": "3",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "1",
        "45to54": "0",
        "55to64": "0",
        "65+": "1",
        "medianAge": "0"
      }
    },
    "title": "Survey researchers",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Psychologists": {
    "ageData": {
      "2017": {
        "total": "187",
        "16to19": "0",
        "20to24": "1",
        "25to34": "26",
        "35to44": "41",
        "45to54": "43",
        "55to64": "40",
        "65+": "35",
        "medianAge": "40"
      }
    },
    "title": "Psychologists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "187",
        "women": "68.9",
        "white": "89.5",
        "black": "6.5",
        "asian": "3.6",
        "hispanicOrLatino": "8.5"
      }
    },
    "wageData": {
      "2017": {
        "workers": "106",
        "median": "1,454",
        "maleWorkers": "35",
        "maleMedian": "-",
        "femaleWorkers": "71",
        "femaleMedian": "1,437"
      }
    }
  },
  "Sociologists": {
    "ageData": {
      "2017": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "2",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Sociologists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Urban and regional planners": {
    "ageData": {
      "2017": {
        "total": "32",
        "16to19": "0",
        "20to24": "3",
        "25to34": "3",
        "35to44": "13",
        "45to54": "9",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Urban and regional planners",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "32",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "34",
        "median": "-",
        "maleWorkers": "18",
        "maleMedian": "-",
        "femaleWorkers": "16",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous social scientists and related workers": {
    "ageData": {
      "2017": {
        "total": "41",
        "16to19": "0",
        "20to24": "4",
        "25to34": "10",
        "35to44": "5",
        "45to54": "10",
        "55to64": "8",
        "65+": "5",
        "medianAge": "8"
      }
    },
    "title": "Miscellaneous social scientists and related workers",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "41",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "27",
        "median": "-",
        "maleWorkers": "18",
        "maleMedian": "-",
        "femaleWorkers": "9",
        "femaleMedian": "-"
      }
    }
  },
  "Agricultural and food science technicians": {
    "ageData": {
      "2017": {
        "total": "32",
        "16to19": "0",
        "20to24": "2",
        "25to34": "8",
        "35to44": "6",
        "45to54": "7",
        "55to64": "9",
        "65+": "0",
        "medianAge": "9"
      }
    },
    "title": "Agricultural and food science technicians",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "32",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "30",
        "median": "-",
        "maleWorkers": "20",
        "maleMedian": "-",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Biological technicians": {
    "ageData": {
      "2017": {
        "total": "23",
        "16to19": "0",
        "20to24": "1",
        "25to34": "9",
        "35to44": "6",
        "45to54": "4",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Biological technicians",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "23",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "23",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "15",
        "femaleMedian": "-"
      }
    }
  },
  "Chemical technicians": {
    "ageData": {
      "2017": {
        "total": "57",
        "16to19": "0",
        "20to24": "10",
        "25to34": "15",
        "35to44": "4",
        "45to54": "10",
        "55to64": "18",
        "65+": "0",
        "medianAge": "18"
      }
    },
    "title": "Chemical technicians",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "57",
        "women": "27.4",
        "white": "79.8",
        "black": "7.3",
        "asian": "7.8",
        "hispanicOrLatino": "14.3"
      }
    },
    "wageData": {
      "2017": {
        "workers": "62",
        "median": "1,013",
        "maleWorkers": "45",
        "maleMedian": "-",
        "femaleWorkers": "16",
        "femaleMedian": "-"
      }
    }
  },
  "Geological and petroleum technicians": {
    "ageData": {
      "2017": {
        "total": "13",
        "16to19": "0",
        "20to24": "1",
        "25to34": "6",
        "35to44": "1",
        "45to54": "4",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Geological and petroleum technicians",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "13",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Nuclear technicians": {
    "ageData": {
      "2017": {
        "total": "7",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "4",
        "45to54": "1",
        "55to64": "1",
        "65+": "1",
        "medianAge": "1"
      }
    },
    "title": "Nuclear technicians",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Social science research assistants": {
    "ageData": {
      "2017": {
        "total": "4",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "2",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Social science research assistants",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous life, physical, and social science technicians": {
    "ageData": {
      "2017": {
        "total": "164",
        "16to19": "4",
        "20to24": "32",
        "25to34": "51",
        "35to44": "28",
        "45to54": "20",
        "55to64": "22",
        "65+": "7",
        "medianAge": "22"
      }
    },
    "title": "Miscellaneous life, physical, and social science technicians",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "164",
        "women": "47.7",
        "white": "80.2",
        "black": "5.7",
        "asian": "11.8",
        "hispanicOrLatino": "13.4"
      }
    },
    "wageData": {
      "2017": {
        "workers": "132",
        "median": "826",
        "maleWorkers": "70",
        "maleMedian": "970",
        "femaleWorkers": "63",
        "femaleMedian": "766"
      }
    }
  },
  "Community and social service occupations": {
    "ageData": {
      "2017": {
        "total": "2,635",
        "16to19": "31",
        "20to24": "161",
        "25to34": "627",
        "35to44": "565",
        "45to54": "553",
        "55to64": "496",
        "65+": "203",
        "medianAge": "496"
      }
    },
    "title": "Community and social service occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "2,635",
        "women": "66.1",
        "white": "73.1",
        "black": "19.9",
        "asian": "3.8",
        "hispanicOrLatino": "12.4"
      }
    },
    "wageData": {
      "2017": {
        "workers": "2,165",
        "median": "900",
        "maleWorkers": "747",
        "maleMedian": "976",
        "femaleWorkers": "1,418",
        "femaleMedian": "857"
      }
    }
  },
  "Counselors": {
    "ageData": {
      "2017": {
        "total": "853",
        "16to19": "17",
        "20to24": "72",
        "25to34": "232",
        "35to44": "183",
        "45to54": "178",
        "55to64": "119",
        "65+": "52",
        "medianAge": "119"
      }
    },
    "title": "Counselors",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "853",
        "women": "72.3",
        "white": "72.3",
        "black": "21.4",
        "asian": "2.9",
        "hispanicOrLatino": "13.0"
      }
    },
    "wageData": {
      "2017": {
        "workers": "650",
        "median": "897",
        "maleWorkers": "178",
        "maleMedian": "901",
        "femaleWorkers": "472",
        "femaleMedian": "894"
      }
    }
  },
  "Social workers": {
    "ageData": {
      "2017": {
        "total": "802",
        "16to19": "4",
        "20to24": "40",
        "25to34": "205",
        "35to44": "189",
        "45to54": "188",
        "55to64": "136",
        "65+": "40",
        "medianAge": "136"
      }
    },
    "title": "Social workers",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "802",
        "women": "82.5",
        "white": "69.5",
        "black": "23.5",
        "asian": "4.0",
        "hispanicOrLatino": "14.0"
      }
    },
    "wageData": {
      "2017": {
        "workers": "714",
        "median": "906",
        "maleWorkers": "122",
        "maleMedian": "935",
        "femaleWorkers": "591",
        "femaleMedian": "900"
      }
    }
  },
  "Probation officers and correctional treatment specialists": {
    "ageData": {
      "2017": {
        "total": "99",
        "16to19": "0",
        "20to24": "2",
        "25to34": "32",
        "35to44": "32",
        "45to54": "19",
        "55to64": "11",
        "65+": "3",
        "medianAge": "11"
      }
    },
    "title": "Probation officers and correctional treatment specialists",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "99",
        "women": "63.5",
        "white": "63.8",
        "black": "26.7",
        "asian": "5.3",
        "hispanicOrLatino": "11.1"
      }
    },
    "wageData": {
      "2017": {
        "workers": "90",
        "median": "881",
        "maleWorkers": "36",
        "maleMedian": "-",
        "femaleWorkers": "55",
        "femaleMedian": "858"
      }
    }
  },
  "Social and human service assistants": {
    "ageData": {
      "2017": {
        "total": "232",
        "16to19": "5",
        "20to24": "23",
        "25to34": "51",
        "35to44": "52",
        "45to54": "47",
        "55to64": "40",
        "65+": "13",
        "medianAge": "40"
      }
    },
    "title": "Social and human service assistants",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "232",
        "women": "75.3",
        "white": "68.4",
        "black": "22.8",
        "asian": "3.1",
        "hispanicOrLatino": "19.0"
      }
    },
    "wageData": {
      "2017": {
        "workers": "189",
        "median": "739",
        "maleWorkers": "48",
        "maleMedian": "-",
        "femaleWorkers": "141",
        "femaleMedian": "701"
      }
    }
  },
  "Miscellaneous community and social service specialists, including health educators and community health workers": {
    "ageData": {
      "2017": {
        "total": "76",
        "16to19": "1",
        "20to24": "6",
        "25to34": "25",
        "35to44": "14",
        "45to54": "13",
        "55to64": "14",
        "65+": "3",
        "medianAge": "14"
      }
    },
    "title": "Miscellaneous community and social service specialists, including health educators and community health workers",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "76",
        "women": "74.8",
        "white": "68.9",
        "black": "19.6",
        "asian": "7.0",
        "hispanicOrLatino": "17.3"
      }
    },
    "wageData": {
      "2017": {
        "workers": "68",
        "median": "829",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "51",
        "femaleMedian": "791"
      }
    }
  },
  "Clergy": {
    "ageData": {
      "2017": {
        "total": "406",
        "16to19": "1",
        "20to24": "10",
        "25to34": "53",
        "35to44": "69",
        "45to54": "78",
        "55to64": "126",
        "65+": "69",
        "medianAge": "126"
      }
    },
    "title": "Clergy",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "406",
        "women": "17.0",
        "white": "81.3",
        "black": "12.1",
        "asian": "4.6",
        "hispanicOrLatino": "6.1"
      }
    },
    "wageData": {
      "2017": {
        "workers": "342",
        "median": "1,058",
        "maleWorkers": "297",
        "maleMedian": "1,073",
        "femaleWorkers": "46",
        "femaleMedian": "-"
      }
    }
  },
  "Directors, religious activities and education": {
    "ageData": {
      "2017": {
        "total": "81",
        "16to19": "2",
        "20to24": "4",
        "25to34": "14",
        "35to44": "12",
        "45to54": "15",
        "55to64": "26",
        "65+": "9",
        "medianAge": "26"
      }
    },
    "title": "Directors, religious activities and education",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "81",
        "women": "54.1",
        "white": "88.9",
        "black": "7.1",
        "asian": "3.6",
        "hispanicOrLatino": "6.0"
      }
    },
    "wageData": {
      "2017": {
        "workers": "61",
        "median": "844",
        "maleWorkers": "36",
        "maleMedian": "-",
        "femaleWorkers": "25",
        "femaleMedian": "-"
      }
    }
  },
  "Religious workers, all other": {
    "ageData": {
      "2017": {
        "total": "85",
        "16to19": "2",
        "20to24": "4",
        "25to34": "15",
        "35to44": "14",
        "45to54": "14",
        "55to64": "24",
        "65+": "13",
        "medianAge": "24"
      }
    },
    "title": "Religious workers, all other",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "85",
        "women": "65.3",
        "white": "86.7",
        "black": "5.0",
        "asian": "5.2",
        "hispanicOrLatino": "5.0"
      }
    },
    "wageData": {
      "2017": {
        "workers": "50",
        "median": "831",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "37",
        "femaleMedian": "-"
      }
    }
  },
  "Legal occupations": {
    "ageData": {
      "2017": {
        "total": "1,827",
        "16to19": "2",
        "20to24": "43",
        "25to34": "387",
        "35to44": "441",
        "45to54": "395",
        "55to64": "351",
        "65+": "208",
        "medianAge": "351"
      }
    },
    "title": "Legal occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "1,827",
        "women": "52.8",
        "white": "86.6",
        "black": "6.9",
        "asian": "4.3",
        "hispanicOrLatino": "8.6"
      }
    },
    "wageData": {
      "2017": {
        "workers": "1,379",
        "median": "1,443",
        "maleWorkers": "568",
        "maleMedian": "1,896",
        "femaleWorkers": "811",
        "femaleMedian": "1,192"
      }
    }
  },
  "Lawyers": {
    "ageData": {
      "2017": {
        "total": "1,137",
        "16to19": "0",
        "20to24": "4",
        "25to34": "225",
        "35to44": "304",
        "45to54": "247",
        "55to64": "208",
        "65+": "150",
        "medianAge": "208"
      }
    },
    "title": "Lawyers",
    "depth": 4,
    "parent": "Legal occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "1,137",
        "women": "37.4",
        "white": "88.6",
        "black": "5.6",
        "asian": "4.4",
        "hispanicOrLatino": "4.8"
      }
    },
    "wageData": {
      "2017": {
        "workers": "781",
        "median": "1,901",
        "maleWorkers": "444",
        "maleMedian": "2,105",
        "femaleWorkers": "337",
        "femaleMedian": "1,753"
      }
    }
  },
  "Judicial law clerks": {
    "ageData": {
      "2017": {
        "total": "14",
        "16to19": "0",
        "20to24": "0",
        "25to34": "10",
        "35to44": "1",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Judicial law clerks",
    "depth": 4,
    "parent": "Legal occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "14",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Judges, magistrates, and other judicial workers": {
    "ageData": {
      "2017": {
        "total": "66",
        "16to19": "0",
        "20to24": "0",
        "25to34": "4",
        "35to44": "8",
        "45to54": "17",
        "55to64": "22",
        "65+": "14",
        "medianAge": "22"
      }
    },
    "title": "Judges, magistrates, and other judicial workers",
    "depth": 4,
    "parent": "Legal occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "66",
        "women": "28.1",
        "white": "87.3",
        "black": "12.7",
        "asian": "0.0",
        "hispanicOrLatino": "7.0"
      }
    },
    "wageData": {
      "2017": {
        "workers": "59",
        "median": "1,742",
        "maleWorkers": "42",
        "maleMedian": "-",
        "femaleWorkers": "17",
        "femaleMedian": "-"
      }
    }
  },
  "Paralegals and legal assistants": {
    "ageData": {
      "2017": {
        "total": "424",
        "16to19": "1",
        "20to24": "32",
        "25to34": "106",
        "35to44": "90",
        "45to54": "88",
        "55to64": "75",
        "65+": "32",
        "medianAge": "75"
      }
    },
    "title": "Paralegals and legal assistants",
    "depth": 4,
    "parent": "Legal occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "424",
        "women": "86.3",
        "white": "82.9",
        "black": "7.7",
        "asian": "4.8",
        "hispanicOrLatino": "17.5"
      }
    },
    "wageData": {
      "2017": {
        "workers": "370",
        "median": "945",
        "maleWorkers": "49",
        "maleMedian": "-",
        "femaleWorkers": "321",
        "femaleMedian": "934"
      }
    }
  },
  "Miscellaneous legal support workers": {
    "ageData": {
      "2017": {
        "total": "186",
        "16to19": "0",
        "20to24": "6",
        "25to34": "42",
        "35to44": "38",
        "45to54": "42",
        "55to64": "45",
        "65+": "13",
        "medianAge": "45"
      }
    },
    "title": "Miscellaneous legal support workers",
    "depth": 4,
    "parent": "Legal occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "186",
        "women": "80.2",
        "white": "83.7",
        "black": "10.8",
        "asian": "3.0",
        "hispanicOrLatino": "12.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "156",
        "median": "957",
        "maleWorkers": "26",
        "maleMedian": "-",
        "femaleWorkers": "130",
        "femaleMedian": "904"
      }
    }
  },
  "Education, training, and library occupations": {
    "ageData": {
      "2017": {
        "total": "9,215",
        "16to19": "122",
        "20to24": "664",
        "25to34": "2,114",
        "35to44": "2,159",
        "45to54": "2,024",
        "55to64": "1,530",
        "65+": "601",
        "medianAge": "1,530"
      }
    },
    "title": "Education, training, and library occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "9,215",
        "women": "73.0",
        "white": "82.0",
        "black": "10.7",
        "asian": "4.9",
        "hispanicOrLatino": "10.5"
      }
    },
    "wageData": {
      "2017": {
        "workers": "6,978",
        "median": "1,002",
        "maleWorkers": "1,939",
        "maleMedian": "1,202",
        "femaleWorkers": "5,039",
        "femaleMedian": "935"
      }
    }
  },
  "Postsecondary teachers": {
    "ageData": {
      "2017": {
        "total": "1,423",
        "16to19": "8",
        "20to24": "117",
        "25to34": "322",
        "35to44": "281",
        "45to54": "274",
        "55to64": "268",
        "65+": "154",
        "medianAge": "268"
      }
    },
    "title": "Postsecondary teachers",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "1,423",
        "women": "47.0",
        "white": "77.7",
        "black": "8.4",
        "asian": "11.7",
        "hispanicOrLatino": "6.4"
      }
    },
    "wageData": {
      "2017": {
        "workers": "985",
        "median": "1,312",
        "maleWorkers": "551",
        "maleMedian": "1,414",
        "femaleWorkers": "434",
        "femaleMedian": "1,208"
      }
    }
  },
  "Preschool and kindergarten teachers": {
    "ageData": {
      "2017": {
        "total": "712",
        "16to19": "14",
        "20to24": "87",
        "25to34": "198",
        "35to44": "160",
        "45to54": "144",
        "55to64": "88",
        "65+": "23",
        "medianAge": "88"
      }
    },
    "title": "Preschool and kindergarten teachers",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "712",
        "women": "97.7",
        "white": "77.1",
        "black": "16.2",
        "asian": "3.4",
        "hispanicOrLatino": "14.1"
      }
    },
    "wageData": {
      "2017": {
        "workers": "530",
        "median": "657",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "518",
        "femaleMedian": "654"
      }
    }
  },
  "Elementary and middle school teachers": {
    "ageData": {
      "2017": {
        "total": "3,268",
        "16to19": "7",
        "20to24": "151",
        "25to34": "768",
        "35to44": "887",
        "45to54": "787",
        "55to64": "514",
        "65+": "155",
        "medianAge": "514"
      }
    },
    "title": "Elementary and middle school teachers",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "3,268",
        "women": "79.3",
        "white": "85.2",
        "black": "10.1",
        "asian": "2.9",
        "hispanicOrLatino": "10.1"
      }
    },
    "wageData": {
      "2017": {
        "workers": "2,838",
        "median": "1,023",
        "maleWorkers": "614",
        "maleMedian": "1,139",
        "femaleWorkers": "2,224",
        "femaleMedian": "987"
      }
    }
  },
  "Secondary school teachers": {
    "ageData": {
      "2017": {
        "total": "1,039",
        "16to19": "3",
        "20to24": "32",
        "25to34": "265",
        "35to44": "274",
        "45to54": "245",
        "55to64": "165",
        "65+": "56",
        "medianAge": "165"
      }
    },
    "title": "Secondary school teachers",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "1,039",
        "women": "58.5",
        "white": "86.2",
        "black": "8.6",
        "asian": "2.8",
        "hispanicOrLatino": "9.4"
      }
    },
    "wageData": {
      "2017": {
        "workers": "917",
        "median": "1,139",
        "maleWorkers": "392",
        "maleMedian": "1,226",
        "femaleWorkers": "524",
        "femaleMedian": "1,091"
      }
    }
  },
  "Special education teachers": {
    "ageData": {
      "2017": {
        "total": "422",
        "16to19": "1",
        "20to24": "19",
        "25to34": "92",
        "35to44": "114",
        "45to54": "99",
        "55to64": "78",
        "65+": "18",
        "medianAge": "78"
      }
    },
    "title": "Special education teachers",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "422",
        "women": "85.6",
        "white": "86.2",
        "black": "10.0",
        "asian": "2.4",
        "hispanicOrLatino": "7.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "386",
        "median": "1,024",
        "maleWorkers": "54",
        "maleMedian": "1,051",
        "femaleWorkers": "332",
        "femaleMedian": "1,017"
      }
    }
  },
  "Other teachers and instructors": {
    "ageData": {
      "2017": {
        "total": "907",
        "16to19": "48",
        "20to24": "112",
        "25to34": "204",
        "35to44": "165",
        "45to54": "160",
        "55to64": "133",
        "65+": "84",
        "medianAge": "133"
      }
    },
    "title": "Other teachers and instructors",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "907",
        "women": "65.4",
        "white": "78.8",
        "black": "11.1",
        "asian": "6.3",
        "hispanicOrLatino": "11.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "419",
        "median": "897",
        "maleWorkers": "155",
        "maleMedian": "1,128",
        "femaleWorkers": "265",
        "femaleMedian": "820"
      }
    }
  },
  "Archivists, curators, and museum technicians": {
    "ageData": {
      "2017": {
        "total": "59",
        "16to19": "0",
        "20to24": "3",
        "25to34": "17",
        "35to44": "9",
        "45to54": "12",
        "55to64": "9",
        "65+": "8",
        "medianAge": "9"
      }
    },
    "title": "Archivists, curators, and museum technicians",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "59",
        "women": "61.4",
        "white": "80.2",
        "black": "6.4",
        "asian": "7.8",
        "hispanicOrLatino": "1.7"
      }
    },
    "wageData": {
      "2017": {
        "workers": "42",
        "median": "-",
        "maleWorkers": "18",
        "maleMedian": "-",
        "femaleWorkers": "24",
        "femaleMedian": "-"
      }
    }
  },
  "Librarians": {
    "ageData": {
      "2017": {
        "total": "194",
        "16to19": "2",
        "20to24": "8",
        "25to34": "33",
        "35to44": "48",
        "45to54": "34",
        "55to64": "42",
        "65+": "28",
        "medianAge": "42"
      }
    },
    "title": "Librarians",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "194",
        "women": "79.5",
        "white": "86.3",
        "black": "6.4",
        "asian": "5.2",
        "hispanicOrLatino": "10.4"
      }
    },
    "wageData": {
      "2017": {
        "workers": "135",
        "median": "1,009",
        "maleWorkers": "31",
        "maleMedian": "-",
        "femaleWorkers": "104",
        "femaleMedian": "972"
      }
    }
  },
  "Library technicians": {
    "ageData": {
      "2017": {
        "total": "40",
        "16to19": "5",
        "20to24": "11",
        "25to34": "1",
        "35to44": "5",
        "45to54": "5",
        "55to64": "9",
        "65+": "4",
        "medianAge": "9"
      }
    },
    "title": "Library technicians",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "40",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "16",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "14",
        "femaleMedian": "-"
      }
    }
  },
  "Teacher assistants": {
    "ageData": {
      "2017": {
        "total": "989",
        "16to19": "32",
        "20to24": "105",
        "25to34": "187",
        "35to44": "183",
        "45to54": "232",
        "55to64": "190",
        "65+": "59",
        "medianAge": "190"
      }
    },
    "title": "Teacher assistants",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "989",
        "women": "88.5",
        "white": "78.0",
        "black": "14.6",
        "asian": "4.2",
        "hispanicOrLatino": "17.3"
      }
    },
    "wageData": {
      "2017": {
        "workers": "590",
        "median": "550",
        "maleWorkers": "67",
        "maleMedian": "642",
        "femaleWorkers": "523",
        "femaleMedian": "537"
      }
    }
  },
  "Other education, training, and library workers": {
    "ageData": {
      "2017": {
        "total": "161",
        "16to19": "2",
        "20to24": "20",
        "25to34": "27",
        "35to44": "33",
        "45to54": "33",
        "55to64": "34",
        "65+": "11",
        "medianAge": "34"
      }
    },
    "title": "Other education, training, and library workers",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "161",
        "women": "67.7",
        "white": "78.5",
        "black": "12.6",
        "asian": "5.3",
        "hispanicOrLatino": "6.0"
      }
    },
    "wageData": {
      "2017": {
        "workers": "120",
        "median": "1,049",
        "maleWorkers": "44",
        "maleMedian": "-",
        "femaleWorkers": "77",
        "femaleMedian": "956"
      }
    }
  },
  "Arts, design, entertainment, sports, and media occupations": {
    "ageData": {
      "2017": {
        "total": "3,246",
        "16to19": "98",
        "20to24": "297",
        "25to34": "863",
        "35to44": "680",
        "45to54": "572",
        "55to64": "489",
        "65+": "247",
        "medianAge": "489"
      }
    },
    "title": "Arts, design, entertainment, sports, and media occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "3,246",
        "women": "46.9",
        "white": "83.9",
        "black": "7.3",
        "asian": "5.3",
        "hispanicOrLatino": "10.2"
      }
    },
    "wageData": {
      "2017": {
        "workers": "1,778",
        "median": "1,066",
        "maleWorkers": "1,013",
        "maleMedian": "1,161",
        "femaleWorkers": "765",
        "femaleMedian": "943"
      }
    }
  },
  "Artists and related workers": {
    "ageData": {
      "2017": {
        "total": "236",
        "16to19": "4",
        "20to24": "15",
        "25to34": "62",
        "35to44": "39",
        "45to54": "37",
        "55to64": "42",
        "65+": "38",
        "medianAge": "42"
      }
    },
    "title": "Artists and related workers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "236",
        "women": "51.9",
        "white": "89.6",
        "black": "3.5",
        "asian": "4.5",
        "hispanicOrLatino": "10.8"
      }
    },
    "wageData": {
      "2017": {
        "workers": "70",
        "median": "1,209",
        "maleWorkers": "43",
        "maleMedian": "-",
        "femaleWorkers": "27",
        "femaleMedian": "-"
      }
    }
  },
  "Designers": {
    "ageData": {
      "2017": {
        "total": "908",
        "16to19": "10",
        "20to24": "73",
        "25to34": "256",
        "35to44": "205",
        "45to54": "168",
        "55to64": "146",
        "65+": "50",
        "medianAge": "146"
      }
    },
    "title": "Designers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "908",
        "women": "52.8",
        "white": "84.2",
        "black": "5.1",
        "asian": "7.6",
        "hispanicOrLatino": "12.0"
      }
    },
    "wageData": {
      "2017": {
        "workers": "600",
        "median": "1,012",
        "maleWorkers": "327",
        "maleMedian": "1,172",
        "femaleWorkers": "273",
        "femaleMedian": "857"
      }
    }
  },
  "Actors": {
    "ageData": {
      "2017": {
        "total": "40",
        "16to19": "1",
        "20to24": "6",
        "25to34": "16",
        "35to44": "7",
        "45to54": "4",
        "55to64": "3",
        "65+": "3",
        "medianAge": "3"
      }
    },
    "title": "Actors",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "40",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "17",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Producers and directors": {
    "ageData": {
      "2017": {
        "total": "180",
        "16to19": "0",
        "20to24": "13",
        "25to34": "47",
        "35to44": "55",
        "45to54": "29",
        "55to64": "27",
        "65+": "8",
        "medianAge": "27"
      }
    },
    "title": "Producers and directors",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "180",
        "women": "29.8",
        "white": "87.8",
        "black": "5.2",
        "asian": "4.6",
        "hispanicOrLatino": "8.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "131",
        "median": "1,489",
        "maleWorkers": "89",
        "maleMedian": "1,708",
        "femaleWorkers": "43",
        "femaleMedian": "-"
      }
    }
  },
  "Athletes, coaches, umpires, and related workers": {
    "ageData": {
      "2017": {
        "total": "345",
        "16to19": "56",
        "20to24": "57",
        "25to34": "90",
        "35to44": "49",
        "45to54": "47",
        "55to64": "30",
        "65+": "17",
        "medianAge": "30"
      }
    },
    "title": "Athletes, coaches, umpires, and related workers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "345",
        "women": "36.4",
        "white": "88.2",
        "black": "5.0",
        "asian": "3.0",
        "hispanicOrLatino": "8.5"
      }
    },
    "wageData": {
      "2017": {
        "workers": "149",
        "median": "898",
        "maleWorkers": "104",
        "maleMedian": "1,031",
        "femaleWorkers": "45",
        "femaleMedian": "-"
      }
    }
  },
  "Dancers and choreographers": {
    "ageData": {
      "2017": {
        "total": "21",
        "16to19": "1",
        "20to24": "7",
        "25to34": "6",
        "35to44": "5",
        "45to54": "0",
        "55to64": "1",
        "65+": "1",
        "medianAge": "1"
      }
    },
    "title": "Dancers and choreographers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "21",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "9",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Musicians, singers, and related workers": {
    "ageData": {
      "2017": {
        "total": "188",
        "16to19": "6",
        "20to24": "14",
        "25to34": "48",
        "35to44": "36",
        "45to54": "28",
        "55to64": "28",
        "65+": "28",
        "medianAge": "28"
      }
    },
    "title": "Musicians, singers, and related workers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "188",
        "women": "37.7",
        "white": "75.6",
        "black": "17.3",
        "asian": "4.3",
        "hispanicOrLatino": "6.7"
      }
    },
    "wageData": {
      "2017": {
        "workers": "41",
        "median": "-",
        "maleWorkers": "29",
        "maleMedian": "-",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Entertainers and performers, sports and related workers, all other": {
    "ageData": {
      "2017": {
        "total": "67",
        "16to19": "2",
        "20to24": "8",
        "25to34": "15",
        "35to44": "16",
        "45to54": "13",
        "55to64": "7",
        "65+": "5",
        "medianAge": "7"
      }
    },
    "title": "Entertainers and performers, sports and related workers, all other",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "67",
        "women": "48.1",
        "white": "74.1",
        "black": "9.2",
        "asian": "9.9",
        "hispanicOrLatino": "7.5"
      }
    },
    "wageData": {
      "2017": {
        "workers": "24",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "13",
        "femaleMedian": "-"
      }
    }
  },
  "Announcers": {
    "ageData": {
      "2017": {
        "total": "50",
        "16to19": "0",
        "20to24": "5",
        "25to34": "17",
        "35to44": "10",
        "45to54": "8",
        "55to64": "5",
        "65+": "5",
        "medianAge": "5"
      }
    },
    "title": "Announcers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "50",
        "women": "23.1",
        "white": "77.5",
        "black": "12.0",
        "asian": "7.1",
        "hispanicOrLatino": "8.5"
      }
    },
    "wageData": {
      "2017": {
        "workers": "24",
        "median": "-",
        "maleWorkers": "18",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "News analysts, reporters and correspondents": {
    "ageData": {
      "2017": {
        "total": "84",
        "16to19": "1",
        "20to24": "19",
        "25to34": "23",
        "35to44": "17",
        "45to54": "10",
        "55to64": "8",
        "65+": "6",
        "medianAge": "8"
      }
    },
    "title": "News analysts, reporters and correspondents",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "84",
        "women": "55.3",
        "white": "73.0",
        "black": "13.4",
        "asian": "7.9",
        "hispanicOrLatino": "16.4"
      }
    },
    "wageData": {
      "2017": {
        "workers": "72",
        "median": "945",
        "maleWorkers": "30",
        "maleMedian": "-",
        "femaleWorkers": "42",
        "femaleMedian": "-"
      }
    }
  },
  "Public relations specialists": {
    "ageData": {
      "2017": {
        "total": "118",
        "16to19": "0",
        "20to24": "14",
        "25to34": "32",
        "35to44": "28",
        "45to54": "20",
        "55to64": "15",
        "65+": "9",
        "medianAge": "15"
      }
    },
    "title": "Public relations specialists",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "118",
        "women": "60.9",
        "white": "87.9",
        "black": "8.3",
        "asian": "2.6",
        "hispanicOrLatino": "5.7"
      }
    },
    "wageData": {
      "2017": {
        "workers": "92",
        "median": "1,339",
        "maleWorkers": "35",
        "maleMedian": "-",
        "femaleWorkers": "57",
        "femaleMedian": "1,203"
      }
    }
  },
  "Editors": {
    "ageData": {
      "2017": {
        "total": "174",
        "16to19": "2",
        "20to24": "9",
        "25to34": "51",
        "35to44": "36",
        "45to54": "40",
        "55to64": "27",
        "65+": "10",
        "medianAge": "27"
      }
    },
    "title": "Editors",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "174",
        "women": "54.1",
        "white": "90.9",
        "black": "3.8",
        "asian": "2.9",
        "hispanicOrLatino": "4.1"
      }
    },
    "wageData": {
      "2017": {
        "workers": "124",
        "median": "1,084",
        "maleWorkers": "56",
        "maleMedian": "1,123",
        "femaleWorkers": "68",
        "femaleMedian": "992"
      }
    }
  },
  "Technical writers": {
    "ageData": {
      "2017": {
        "total": "76",
        "16to19": "0",
        "20to24": "2",
        "25to34": "11",
        "35to44": "19",
        "45to54": "23",
        "55to64": "15",
        "65+": "6",
        "medianAge": "15"
      }
    },
    "title": "Technical writers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "76",
        "women": "60.2",
        "white": "84.6",
        "black": "6.7",
        "asian": "5.6",
        "hispanicOrLatino": "1.2"
      }
    },
    "wageData": {
      "2017": {
        "workers": "67",
        "median": "1,222",
        "maleWorkers": "30",
        "maleMedian": "-",
        "femaleWorkers": "37",
        "femaleMedian": "-"
      }
    }
  },
  "Writers and authors": {
    "ageData": {
      "2017": {
        "total": "226",
        "16to19": "1",
        "20to24": "9",
        "25to34": "57",
        "35to44": "36",
        "45to54": "50",
        "55to64": "42",
        "65+": "31",
        "medianAge": "42"
      }
    },
    "title": "Writers and authors",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "226",
        "women": "60.9",
        "white": "86.3",
        "black": "6.3",
        "asian": "3.2",
        "hispanicOrLatino": "4.6"
      }
    },
    "wageData": {
      "2017": {
        "workers": "106",
        "median": "1,137",
        "maleWorkers": "50",
        "maleMedian": "1,171",
        "femaleWorkers": "57",
        "femaleMedian": "1,045"
      }
    }
  },
  "Miscellaneous media and communication workers": {
    "ageData": {
      "2017": {
        "total": "112",
        "16to19": "2",
        "20to24": "8",
        "25to34": "23",
        "35to44": "27",
        "45to54": "22",
        "55to64": "19",
        "65+": "10",
        "medianAge": "19"
      }
    },
    "title": "Miscellaneous media and communication workers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "112",
        "women": "64.2",
        "white": "70.6",
        "black": "9.5",
        "asian": "14.3",
        "hispanicOrLatino": "32.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "53",
        "median": "809",
        "maleWorkers": "25",
        "maleMedian": "-",
        "femaleWorkers": "28",
        "femaleMedian": "-"
      }
    }
  },
  "Broadcast and sound engineering technicians and radio operators": {
    "ageData": {
      "2017": {
        "total": "119",
        "16to19": "2",
        "20to24": "11",
        "25to34": "21",
        "35to44": "29",
        "45to54": "28",
        "55to64": "22",
        "65+": "6",
        "medianAge": "22"
      }
    },
    "title": "Broadcast and sound engineering technicians and radio operators",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "119",
        "women": "14.4",
        "white": "78.2",
        "black": "14.0",
        "asian": "3.7",
        "hispanicOrLatino": "16.8"
      }
    },
    "wageData": {
      "2017": {
        "workers": "81",
        "median": "1,150",
        "maleWorkers": "71",
        "maleMedian": "1,160",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Photographers": {
    "ageData": {
      "2017": {
        "total": "214",
        "16to19": "7",
        "20to24": "15",
        "25to34": "58",
        "35to44": "48",
        "45to54": "34",
        "55to64": "42",
        "65+": "11",
        "medianAge": "42"
      }
    },
    "title": "Photographers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "214",
        "women": "46.0",
        "white": "84.4",
        "black": "9.1",
        "asian": "3.0",
        "hispanicOrLatino": "8.7"
      }
    },
    "wageData": {
      "2017": {
        "workers": "66",
        "median": "931",
        "maleWorkers": "40",
        "maleMedian": "-",
        "femaleWorkers": "25",
        "femaleMedian": "-"
      }
    }
  },
  "Television, video, and motion picture camera operators and editors": {
    "ageData": {
      "2017": {
        "total": "82",
        "16to19": "2",
        "20to24": "11",
        "25to34": "28",
        "35to44": "19",
        "45to54": "9",
        "55to64": "9",
        "65+": "4",
        "medianAge": "9"
      }
    },
    "title": "Television, video, and motion picture camera operators and editors",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "82",
        "women": "12.2",
        "white": "86.1",
        "black": "7.2",
        "asian": "1.9",
        "hispanicOrLatino": "7.6"
      }
    },
    "wageData": {
      "2017": {
        "workers": "46",
        "median": "-",
        "maleWorkers": "38",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Media and communication equipment workers, all other": {
    "ageData": {
      "2017": {
        "total": "5",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "0",
        "45to54": "3",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Media and communication equipment workers, all other",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Healthcare practitioners and technical occupations": {
    "ageData": {
      "2017": {
        "total": "9,141",
        "16to19": "38",
        "20to24": "526",
        "25to34": "2,394",
        "35to44": "2,084",
        "45to54": "1,986",
        "55to64": "1,591",
        "65+": "523",
        "medianAge": "1,591"
      }
    },
    "title": "Healthcare practitioners and technical occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "9,141",
        "women": "75.0",
        "white": "76.5",
        "black": "11.9",
        "asian": "9.4",
        "hispanicOrLatino": "8.2"
      }
    },
    "wageData": {
      "2017": {
        "workers": "6,970",
        "median": "1,124",
        "maleWorkers": "1,724",
        "maleMedian": "1,341",
        "femaleWorkers": "5,246",
        "femaleMedian": "1,068"
      }
    }
  },
  "Chiropractors": {
    "ageData": {
      "2017": {
        "total": "51",
        "16to19": "0",
        "20to24": "0",
        "25to34": "7",
        "35to44": "10",
        "45to54": "12",
        "55to64": "15",
        "65+": "7",
        "medianAge": "15"
      }
    },
    "title": "Chiropractors",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "51",
        "women": "21.1",
        "white": "97.4",
        "black": "0.1",
        "asian": "1.7",
        "hispanicOrLatino": "5.1"
      }
    },
    "wageData": {
      "2017": {
        "workers": "11",
        "median": "-",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Dentists": {
    "ageData": {
      "2017": {
        "total": "159",
        "16to19": "0",
        "20to24": "0",
        "25to34": "31",
        "35to44": "26",
        "45to54": "32",
        "55to64": "45",
        "65+": "26",
        "medianAge": "45"
      }
    },
    "title": "Dentists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "159",
        "women": "35.8",
        "white": "81.6",
        "black": "3.7",
        "asian": "13.5",
        "hispanicOrLatino": "4.7"
      }
    },
    "wageData": {
      "2017": {
        "workers": "41",
        "median": "-",
        "maleWorkers": "16",
        "maleMedian": "-",
        "femaleWorkers": "25",
        "femaleMedian": "-"
      }
    }
  },
  "Dietitians and nutritionists": {
    "ageData": {
      "2017": {
        "total": "114",
        "16to19": "0",
        "20to24": "5",
        "25to34": "30",
        "35to44": "21",
        "45to54": "26",
        "55to64": "24",
        "65+": "9",
        "medianAge": "24"
      }
    },
    "title": "Dietitians and nutritionists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "114",
        "women": "94.1",
        "white": "79.7",
        "black": "12.8",
        "asian": "5.6",
        "hispanicOrLatino": "9.0"
      }
    },
    "wageData": {
      "2017": {
        "workers": "88",
        "median": "991",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "80",
        "femaleMedian": "996"
      }
    }
  },
  "Optometrists": {
    "ageData": {
      "2017": {
        "total": "51",
        "16to19": "0",
        "20to24": "0",
        "25to34": "9",
        "35to44": "7",
        "45to54": "23",
        "55to64": "8",
        "65+": "5",
        "medianAge": "8"
      }
    },
    "title": "Optometrists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "51",
        "women": "50.2",
        "white": "91.9",
        "black": "0.0",
        "asian": "7.9",
        "hispanicOrLatino": "3.2"
      }
    },
    "wageData": {
      "2017": {
        "workers": "19",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "13",
        "femaleMedian": "-"
      }
    }
  },
  "Pharmacists": {
    "ageData": {
      "2017": {
        "total": "342",
        "16to19": "0",
        "20to24": "19",
        "25to34": "119",
        "35to44": "66",
        "45to54": "55",
        "55to64": "58",
        "65+": "24",
        "medianAge": "58"
      }
    },
    "title": "Pharmacists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "342",
        "women": "57.5",
        "white": "68.5",
        "black": "8.2",
        "asian": "21.7",
        "hispanicOrLatino": "4.5"
      }
    },
    "wageData": {
      "2017": {
        "workers": "254",
        "median": "1,923",
        "maleWorkers": "107",
        "maleMedian": "2,228",
        "femaleWorkers": "147",
        "femaleMedian": "1,834"
      }
    }
  },
  "Physicians and surgeons": {
    "ageData": {
      "2017": {
        "total": "1,079",
        "16to19": "0",
        "20to24": "4",
        "25to34": "254",
        "35to44": "249",
        "45to54": "247",
        "55to64": "203",
        "65+": "121",
        "medianAge": "203"
      }
    },
    "title": "Physicians and surgeons",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "1,079",
        "women": "40.0",
        "white": "72.0",
        "black": "8.2",
        "asian": "18.1",
        "hispanicOrLatino": "6.8"
      }
    },
    "wageData": {
      "2017": {
        "workers": "815",
        "median": "1,918",
        "maleWorkers": "463",
        "maleMedian": "2,277",
        "femaleWorkers": "352",
        "femaleMedian": "1,759"
      }
    }
  },
  "Physician assistants": {
    "ageData": {
      "2017": {
        "total": "116",
        "16to19": "0",
        "20to24": "2",
        "25to34": "54",
        "35to44": "26",
        "45to54": "19",
        "55to64": "11",
        "65+": "4",
        "medianAge": "11"
      }
    },
    "title": "Physician assistants",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "116",
        "women": "70.9",
        "white": "86.0",
        "black": "4.7",
        "asian": "7.9",
        "hispanicOrLatino": "5.1"
      }
    },
    "wageData": {
      "2017": {
        "workers": "97",
        "median": "1,747",
        "maleWorkers": "31",
        "maleMedian": "-",
        "femaleWorkers": "66",
        "femaleMedian": "1,640"
      }
    }
  },
  "Podiatrists": {
    "ageData": {
      "2017": {
        "total": "12",
        "16to19": "0",
        "20to24": "1",
        "25to34": "0",
        "35to44": "5",
        "45to54": "3",
        "55to64": "1",
        "65+": "2",
        "medianAge": "1"
      }
    },
    "title": "Podiatrists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "12",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Audiologists": {
    "ageData": {
      "2017": {
        "total": "15",
        "16to19": "0",
        "20to24": "1",
        "25to34": "5",
        "35to44": "2",
        "45to54": "5",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Audiologists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "15",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Occupational therapists": {
    "ageData": {
      "2017": {
        "total": "122",
        "16to19": "0",
        "20to24": "3",
        "25to34": "34",
        "35to44": "41",
        "45to54": "27",
        "55to64": "16",
        "65+": "2",
        "medianAge": "16"
      }
    },
    "title": "Occupational therapists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "122",
        "women": "87.6",
        "white": "82.9",
        "black": "8.7",
        "asian": "6.4",
        "hispanicOrLatino": "6.2"
      }
    },
    "wageData": {
      "2017": {
        "workers": "74",
        "median": "1,301",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "62",
        "femaleMedian": "1,273"
      }
    }
  },
  "Physical therapists": {
    "ageData": {
      "2017": {
        "total": "258",
        "16to19": "1",
        "20to24": "11",
        "25to34": "84",
        "35to44": "70",
        "45to54": "54",
        "55to64": "26",
        "65+": "12",
        "medianAge": "26"
      }
    },
    "title": "Physical therapists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "258",
        "women": "68.2",
        "white": "78.2",
        "black": "5.3",
        "asian": "14.7",
        "hispanicOrLatino": "5.0"
      }
    },
    "wageData": {
      "2017": {
        "workers": "182",
        "median": "1,317",
        "maleWorkers": "64",
        "maleMedian": "1,341",
        "femaleWorkers": "118",
        "femaleMedian": "1,297"
      }
    }
  },
  "Radiation therapists": {
    "ageData": {
      "2017": {
        "total": "10",
        "16to19": "0",
        "20to24": "0",
        "25to34": "5",
        "35to44": "4",
        "45to54": "0",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Radiation therapists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "10",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "11",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Recreational therapists": {
    "ageData": {
      "2017": {
        "total": "7",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "1",
        "45to54": "3",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Recreational therapists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Respiratory therapists": {
    "ageData": {
      "2017": {
        "total": "100",
        "16to19": "0",
        "20to24": "2",
        "25to34": "28",
        "35to44": "25",
        "45to54": "19",
        "55to64": "17",
        "65+": "10",
        "medianAge": "17"
      }
    },
    "title": "Respiratory therapists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "100",
        "women": "72.4",
        "white": "73.3",
        "black": "16.6",
        "asian": "10.0",
        "hispanicOrLatino": "8.8"
      }
    },
    "wageData": {
      "2017": {
        "workers": "83",
        "median": "961",
        "maleWorkers": "27",
        "maleMedian": "-",
        "femaleWorkers": "56",
        "femaleMedian": "904"
      }
    }
  },
  "Speech-language pathologists": {
    "ageData": {
      "2017": {
        "total": "141",
        "16to19": "1",
        "20to24": "5",
        "25to34": "43",
        "35to44": "45",
        "45to54": "23",
        "55to64": "16",
        "65+": "8",
        "medianAge": "16"
      }
    },
    "title": "Speech-language pathologists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "141",
        "women": "98.0",
        "white": "93.0",
        "black": "2.8",
        "asian": "2.7",
        "hispanicOrLatino": "10.5"
      }
    },
    "wageData": {
      "2017": {
        "workers": "106",
        "median": "1,213",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "102",
        "femaleMedian": "1,214"
      }
    }
  },
  "Exercise physiologists": {
    "ageData": {
      "2017": {
        "total": "5",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "1",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Exercise physiologists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Therapists, all other": {
    "ageData": {
      "2017": {
        "total": "221",
        "16to19": "1",
        "20to24": "9",
        "25to34": "73",
        "35to44": "53",
        "45to54": "43",
        "55to64": "28",
        "65+": "15",
        "medianAge": "28"
      }
    },
    "title": "Therapists, all other",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "221",
        "women": "83.3",
        "white": "81.8",
        "black": "12.5",
        "asian": "3.3",
        "hispanicOrLatino": "10.2"
      }
    },
    "wageData": {
      "2017": {
        "workers": "142",
        "median": "983",
        "maleWorkers": "29",
        "maleMedian": "-",
        "femaleWorkers": "114",
        "femaleMedian": "947"
      }
    }
  },
  "Veterinarians": {
    "ageData": {
      "2017": {
        "total": "89",
        "16to19": "1",
        "20to24": "2",
        "25to34": "22",
        "35to44": "18",
        "45to54": "22",
        "55to64": "15",
        "65+": "10",
        "medianAge": "15"
      }
    },
    "title": "Veterinarians",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "89",
        "women": "62.5",
        "white": "91.2",
        "black": "2.1",
        "asian": "3.7",
        "hispanicOrLatino": "3.6"
      }
    },
    "wageData": {
      "2017": {
        "workers": "60",
        "median": "1,840",
        "maleWorkers": "20",
        "maleMedian": "-",
        "femaleWorkers": "40",
        "femaleMedian": "-"
      }
    }
  },
  "Registered nurses": {
    "ageData": {
      "2017": {
        "total": "3,159",
        "16to19": "2",
        "20to24": "159",
        "25to34": "801",
        "35to44": "707",
        "45to54": "735",
        "55to64": "617",
        "65+": "137",
        "medianAge": "617"
      }
    },
    "title": "Registered nurses",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "3,159",
        "women": "89.9",
        "white": "76.7",
        "black": "12.3",
        "asian": "8.7",
        "hispanicOrLatino": "6.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "2,536",
        "median": "1,150",
        "maleWorkers": "283",
        "maleMedian": "1,260",
        "femaleWorkers": "2,253",
        "femaleMedian": "1,143"
      }
    }
  },
  "Nurse anesthetists": {
    "ageData": {
      "2017": {
        "total": "35",
        "16to19": "0",
        "20to24": "0",
        "25to34": "8",
        "35to44": "9",
        "45to54": "8",
        "55to64": "9",
        "65+": "2",
        "medianAge": "9"
      }
    },
    "title": "Nurse anesthetists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "35",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "30",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "17",
        "femaleMedian": "-"
      }
    }
  },
  "Nurse midwives": {
    "ageData": {
      "2017": {
        "total": "7",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "4",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Nurse midwives",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Nurse practitioners": {
    "ageData": {
      "2017": {
        "total": "175",
        "16to19": "0",
        "20to24": "1",
        "25to34": "35",
        "35to44": "50",
        "45to54": "42",
        "55to64": "36",
        "65+": "12",
        "medianAge": "36"
      }
    },
    "title": "Nurse practitioners",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "175",
        "women": "92.2",
        "white": "84.7",
        "black": "10.6",
        "asian": "4.0",
        "hispanicOrLatino": "3.4"
      }
    },
    "wageData": {
      "2017": {
        "workers": "141",
        "median": "1,841",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "129",
        "femaleMedian": "1,832"
      }
    }
  },
  "Health diagnosing and treating practitioners, all other": {
    "ageData": {
      "2017": {
        "total": "39",
        "16to19": "0",
        "20to24": "1",
        "25to34": "6",
        "35to44": "9",
        "45to54": "12",
        "55to64": "9",
        "65+": "3",
        "medianAge": "9"
      }
    },
    "title": "Health diagnosing and treating practitioners, all other",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "39",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "15",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Clinical laboratory technologists and technicians": {
    "ageData": {
      "2017": {
        "total": "376",
        "16to19": "3",
        "20to24": "42",
        "25to34": "98",
        "35to44": "71",
        "45to54": "85",
        "55to64": "58",
        "65+": "19",
        "medianAge": "58"
      }
    },
    "title": "Clinical laboratory technologists and technicians",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "376",
        "women": "69.3",
        "white": "71.2",
        "black": "17.1",
        "asian": "9.4",
        "hispanicOrLatino": "11.0"
      }
    },
    "wageData": {
      "2017": {
        "workers": "297",
        "median": "890",
        "maleWorkers": "94",
        "maleMedian": "1,061",
        "femaleWorkers": "203",
        "femaleMedian": "845"
      }
    }
  },
  "Dental hygienists": {
    "ageData": {
      "2017": {
        "total": "168",
        "16to19": "0",
        "20to24": "10",
        "25to34": "46",
        "35to44": "34",
        "45to54": "36",
        "55to64": "35",
        "65+": "7",
        "medianAge": "35"
      }
    },
    "title": "Dental hygienists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "168",
        "women": "94.9",
        "white": "88.6",
        "black": "7.0",
        "asian": "2.4",
        "hispanicOrLatino": "11.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "78",
        "median": "1,033",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "73",
        "femaleMedian": "1,028"
      }
    }
  },
  "Diagnostic related technologists and technicians": {
    "ageData": {
      "2017": {
        "total": "319",
        "16to19": "0",
        "20to24": "19",
        "25to34": "79",
        "35to44": "76",
        "45to54": "80",
        "55to64": "56",
        "65+": "9",
        "medianAge": "56"
      }
    },
    "title": "Diagnostic related technologists and technicians",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "319",
        "women": "70.8",
        "white": "84.3",
        "black": "8.2",
        "asian": "6.4",
        "hispanicOrLatino": "12.5"
      }
    },
    "wageData": {
      "2017": {
        "workers": "271",
        "median": "1,162",
        "maleWorkers": "85",
        "maleMedian": "1,404",
        "femaleWorkers": "186",
        "femaleMedian": "1,041"
      }
    }
  },
  "Emergency medical technicians and paramedics": {
    "ageData": {
      "2017": {
        "total": "223",
        "16to19": "4",
        "20to24": "40",
        "25to34": "71",
        "35to44": "53",
        "45to54": "31",
        "55to64": "20",
        "65+": "4",
        "medianAge": "20"
      }
    },
    "title": "Emergency medical technicians and paramedics",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "223",
        "women": "30.6",
        "white": "83.9",
        "black": "9.7",
        "asian": "3.1",
        "hispanicOrLatino": "8.4"
      }
    },
    "wageData": {
      "2017": {
        "workers": "203",
        "median": "984",
        "maleWorkers": "145",
        "maleMedian": "1,119",
        "femaleWorkers": "58",
        "femaleMedian": "733"
      }
    }
  },
  "Health practitioner support technologists and technicians": {
    "ageData": {
      "2017": {
        "total": "632",
        "16to19": "13",
        "20to24": "108",
        "25to34": "190",
        "35to44": "152",
        "45to54": "100",
        "55to64": "58",
        "65+": "11",
        "medianAge": "58"
      }
    },
    "title": "Health practitioner support technologists and technicians",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "632",
        "women": "79.4",
        "white": "75.6",
        "black": "13.5",
        "asian": "7.3",
        "hispanicOrLatino": "14.3"
      }
    },
    "wageData": {
      "2017": {
        "workers": "469",
        "median": "710",
        "maleWorkers": "98",
        "maleMedian": "858",
        "femaleWorkers": "371",
        "femaleMedian": "678"
      }
    }
  },
  "Licensed practical and licensed vocational nurses": {
    "ageData": {
      "2017": {
        "total": "630",
        "16to19": "5",
        "20to24": "50",
        "25to34": "148",
        "35to44": "145",
        "45to54": "141",
        "55to64": "105",
        "65+": "37",
        "medianAge": "105"
      }
    },
    "title": "Licensed practical and licensed vocational nurses",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "630",
        "women": "89.5",
        "white": "64.0",
        "black": "28.4",
        "asian": "5.4",
        "hispanicOrLatino": "12.4"
      }
    },
    "wageData": {
      "2017": {
        "workers": "501",
        "median": "815",
        "maleWorkers": "62",
        "maleMedian": "817",
        "femaleWorkers": "438",
        "femaleMedian": "814"
      }
    }
  },
  "Medical records and health information technicians": {
    "ageData": {
      "2017": {
        "total": "167",
        "16to19": "1",
        "20to24": "7",
        "25to34": "30",
        "35to44": "34",
        "45to54": "45",
        "55to64": "42",
        "65+": "7",
        "medianAge": "42"
      }
    },
    "title": "Medical records and health information technicians",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "167",
        "women": "91.7",
        "white": "74.6",
        "black": "13.2",
        "asian": "6.9",
        "hispanicOrLatino": "9.8"
      }
    },
    "wageData": {
      "2017": {
        "workers": "151",
        "median": "763",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "140",
        "femaleMedian": "761"
      }
    }
  },
  "Opticians, dispensing": {
    "ageData": {
      "2017": {
        "total": "48",
        "16to19": "1",
        "20to24": "4",
        "25to34": "11",
        "35to44": "5",
        "45to54": "10",
        "55to64": "12",
        "65+": "6",
        "medianAge": "12"
      }
    },
    "title": "Opticians, dispensing",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "48",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "43",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "30",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous health technologists and technicians": {
    "ageData": {
      "2017": {
        "total": "160",
        "16to19": "3",
        "20to24": "12",
        "25to34": "41",
        "35to44": "42",
        "45to54": "24",
        "55to64": "29",
        "65+": "8",
        "medianAge": "29"
      }
    },
    "title": "Miscellaneous health technologists and technicians",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "160",
        "women": "66.0",
        "white": "66.8",
        "black": "18.5",
        "asian": "10.4",
        "hispanicOrLatino": "10.0"
      }
    },
    "wageData": {
      "2017": {
        "workers": "143",
        "median": "811",
        "maleWorkers": "48",
        "maleMedian": "-",
        "femaleWorkers": "95",
        "femaleMedian": "746"
      }
    }
  },
  "Other healthcare practitioners and technical occupations": {
    "ageData": {
      "2017": {
        "total": "110",
        "16to19": "2",
        "20to24": "10",
        "25to34": "27",
        "35to44": "24",
        "45to54": "24",
        "55to64": "18",
        "65+": "5",
        "medianAge": "18"
      }
    },
    "title": "Other healthcare practitioners and technical occupations",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "110",
        "women": "46.5",
        "white": "84.0",
        "black": "9.0",
        "asian": "6.5",
        "hispanicOrLatino": "9.4"
      }
    },
    "wageData": {
      "2017": {
        "workers": "77",
        "median": "1,183",
        "maleWorkers": "49",
        "maleMedian": "-",
        "femaleWorkers": "28",
        "femaleMedian": "-"
      }
    }
  },
  "Service occupations": {
    "ageData": {
      "2017": {
        "total": "26,751",
        "16to19": "2,091",
        "20to24": "3,818",
        "25to34": "6,066",
        "35to44": "4,935",
        "45to54": "4,782",
        "55to64": "3,675",
        "65+": "1,385",
        "medianAge": "3,675"
      }
    },
    "title": "Service occupations",
    "depth": 1,
    "parent": "",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "26,751",
        "women": "56.6",
        "white": "72.6",
        "black": "16.8",
        "asian": "5.9",
        "hispanicOrLatino": "24.0"
      }
    },
    "wageData": {
      "2017": {
        "workers": "16,044",
        "median": "544",
        "maleWorkers": "7,985",
        "maleMedian": "608",
        "femaleWorkers": "8,059",
        "femaleMedian": "501"
      }
    }
  },
  "Healthcare support occupations": {
    "ageData": {
      "2017": {
        "total": "3,506",
        "16to19": "91",
        "20to24": "513",
        "25to34": "916",
        "35to44": "698",
        "45to54": "657",
        "55to64": "472",
        "65+": "160",
        "medianAge": "472"
      }
    },
    "title": "Healthcare support occupations",
    "depth": 2,
    "parent": "Service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "3,506",
        "women": "87.1",
        "white": "65.6",
        "black": "25.1",
        "asian": "5.2",
        "hispanicOrLatino": "18.8"
      }
    },
    "wageData": {
      "2017": {
        "workers": "2,500",
        "median": "542",
        "maleWorkers": "320",
        "maleMedian": "597",
        "femaleWorkers": "2,180",
        "femaleMedian": "533"
      }
    }
  },
  "Nursing, psychiatric, and home health aides": {
    "ageData": {
      "2017": {
        "total": "1,936",
        "16to19": "56",
        "20to24": "260",
        "25to34": "441",
        "35to44": "381",
        "45to54": "393",
        "55to64": "294",
        "65+": "111",
        "medianAge": "294"
      }
    },
    "title": "Nursing, psychiatric, and home health aides",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "1,936",
        "women": "88.6",
        "white": "56.4",
        "black": "33.9",
        "asian": "5.6",
        "hispanicOrLatino": "16.7"
      }
    },
    "wageData": {
      "2017": {
        "workers": "1,387",
        "median": "500",
        "maleWorkers": "164",
        "maleMedian": "583",
        "femaleWorkers": "1,223",
        "femaleMedian": "493"
      }
    }
  },
  "Occupational therapy assistants and aides": {
    "ageData": {
      "2017": {
        "total": "27",
        "16to19": "0",
        "20to24": "2",
        "25to34": "8",
        "35to44": "5",
        "45to54": "7",
        "55to64": "5",
        "65+": "0",
        "medianAge": "5"
      }
    },
    "title": "Occupational therapy assistants and aides",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "27",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "Physical therapist assistants and aides": {
    "ageData": {
      "2017": {
        "total": "83",
        "16to19": "0",
        "20to24": "19",
        "25to34": "22",
        "35to44": "14",
        "45to54": "17",
        "55to64": "8",
        "65+": "3",
        "medianAge": "8"
      }
    },
    "title": "Physical therapist assistants and aides",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "83",
        "women": "67.2",
        "white": "88.0",
        "black": "5.2",
        "asian": "5.6",
        "hispanicOrLatino": "11.0"
      }
    },
    "wageData": {
      "2017": {
        "workers": "47",
        "median": "-",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "33",
        "femaleMedian": "-"
      }
    }
  },
  "Massage therapists": {
    "ageData": {
      "2017": {
        "total": "172",
        "16to19": "0",
        "20to24": "17",
        "25to34": "42",
        "35to44": "34",
        "45to54": "38",
        "55to64": "32",
        "65+": "9",
        "medianAge": "32"
      }
    },
    "title": "Massage therapists",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "172",
        "women": "82.7",
        "white": "83.7",
        "black": "4.6",
        "asian": "6.7",
        "hispanicOrLatino": "12.4"
      }
    },
    "wageData": {
      "2017": {
        "workers": "51",
        "median": "632",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "36",
        "femaleMedian": "-"
      }
    }
  },
  "Dental assistants": {
    "ageData": {
      "2017": {
        "total": "299",
        "16to19": "9",
        "20to24": "43",
        "25to34": "93",
        "35to44": "57",
        "45to54": "53",
        "55to64": "34",
        "65+": "9",
        "medianAge": "34"
      }
    },
    "title": "Dental assistants",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "299",
        "women": "95.9",
        "white": "82.9",
        "black": "7.9",
        "asian": "5.0",
        "hispanicOrLatino": "29.1"
      }
    },
    "wageData": {
      "2017": {
        "workers": "215",
        "median": "608",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "203",
        "femaleMedian": "602"
      }
    }
  },
  "Medical assistants": {
    "ageData": {
      "2017": {
        "total": "539",
        "16to19": "4",
        "20to24": "73",
        "25to34": "186",
        "35to44": "137",
        "45to54": "83",
        "55to64": "42",
        "65+": "15",
        "medianAge": "42"
      }
    },
    "title": "Medical assistants",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "539",
        "women": "91.6",
        "white": "76.2",
        "black": "15.0",
        "asian": "4.0",
        "hispanicOrLatino": "27.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "457",
        "median": "594",
        "maleWorkers": "39",
        "maleMedian": "-",
        "femaleWorkers": "418",
        "femaleMedian": "597"
      }
    }
  },
  "Medical transcriptionists": {
    "ageData": {
      "2017": {
        "total": "36",
        "16to19": "1",
        "20to24": "10",
        "25to34": "7",
        "35to44": "3",
        "45to54": "4",
        "55to64": "8",
        "65+": "3",
        "medianAge": "8"
      }
    },
    "title": "Medical transcriptionists",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "36",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "21",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "18",
        "femaleMedian": "-"
      }
    }
  },
  "Pharmacy aides": {
    "ageData": {
      "2017": {
        "total": "39",
        "16to19": "2",
        "20to24": "11",
        "25to34": "11",
        "35to44": "7",
        "45to54": "4",
        "55to64": "4",
        "65+": "1",
        "medianAge": "4"
      }
    },
    "title": "Pharmacy aides",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "39",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "25",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "14",
        "femaleMedian": "-"
      }
    }
  },
  "Veterinary assistants and laboratory animal caretakers": {
    "ageData": {
      "2017": {
        "total": "61",
        "16to19": "7",
        "20to24": "20",
        "25to34": "16",
        "35to44": "5",
        "45to54": "8",
        "55to64": "4",
        "65+": "1",
        "medianAge": "4"
      }
    },
    "title": "Veterinary assistants and laboratory animal caretakers",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "61",
        "women": "84.2",
        "white": "86.4",
        "black": "2.3",
        "asian": "6.0",
        "hispanicOrLatino": "8.4"
      }
    },
    "wageData": {
      "2017": {
        "workers": "37",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "33",
        "femaleMedian": "-"
      }
    }
  },
  "Phlebotomists": {
    "ageData": {
      "2017": {
        "total": "112",
        "16to19": "2",
        "20to24": "21",
        "25to34": "35",
        "35to44": "23",
        "45to54": "19",
        "55to64": "10",
        "65+": "3",
        "medianAge": "10"
      }
    },
    "title": "Phlebotomists",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "112",
        "women": "83.8",
        "white": "64.7",
        "black": "27.6",
        "asian": "5.9",
        "hispanicOrLatino": "17.2"
      }
    },
    "wageData": {
      "2017": {
        "workers": "97",
        "median": "635",
        "maleWorkers": "16",
        "maleMedian": "-",
        "femaleWorkers": "82",
        "femaleMedian": "639"
      }
    }
  },
  "Miscellaneous healthcare support occupations, including medical equipment preparers": {
    "ageData": {
      "2017": {
        "total": "200",
        "16to19": "10",
        "20to24": "37",
        "25to34": "55",
        "35to44": "33",
        "45to54": "31",
        "55to64": "30",
        "65+": "5",
        "medianAge": "30"
      }
    },
    "title": "Miscellaneous healthcare support occupations, including medical equipment preparers",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "200",
        "women": "70.5",
        "white": "60.3",
        "black": "31.4",
        "asian": "4.1",
        "hispanicOrLatino": "16.1"
      }
    },
    "wageData": {
      "2017": {
        "workers": "150",
        "median": "587",
        "maleWorkers": "40",
        "maleMedian": "-",
        "femaleWorkers": "110",
        "femaleMedian": "603"
      }
    }
  },
  "Protective service occupations": {
    "ageData": {
      "2017": {
        "total": "3,113",
        "16to19": "98",
        "20to24": "265",
        "25to34": "805",
        "35to44": "718",
        "45to54": "666",
        "55to64": "396",
        "65+": "165",
        "medianAge": "396"
      }
    },
    "title": "Protective service occupations",
    "depth": 2,
    "parent": "Service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "3,113",
        "women": "22.3",
        "white": "72.6",
        "black": "20.5",
        "asian": "2.9",
        "hispanicOrLatino": "12.8"
      }
    },
    "wageData": {
      "2017": {
        "workers": "2,739",
        "median": "852",
        "maleWorkers": "2,204",
        "maleMedian": "894",
        "femaleWorkers": "535",
        "femaleMedian": "690"
      }
    }
  },
  "First-line supervisors of correctional officers": {
    "ageData": {
      "2017": {
        "total": "52",
        "16to19": "0",
        "20to24": "0",
        "25to34": "8",
        "35to44": "12",
        "45to54": "20",
        "55to64": "10",
        "65+": "2",
        "medianAge": "10"
      }
    },
    "title": "First-line supervisors of correctional officers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "52",
        "women": "29.9",
        "white": "77.7",
        "black": "13.1",
        "asian": "3.8",
        "hispanicOrLatino": "10.1"
      }
    },
    "wageData": {
      "2017": {
        "workers": "50",
        "median": "899",
        "maleWorkers": "38",
        "maleMedian": "-",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "First-line supervisors of police and detectives": {
    "ageData": {
      "2017": {
        "total": "95",
        "16to19": "0",
        "20to24": "0",
        "25to34": "11",
        "35to44": "31",
        "45to54": "36",
        "55to64": "14",
        "65+": "3",
        "medianAge": "14"
      }
    },
    "title": "First-line supervisors of police and detectives",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "95",
        "women": "12.5",
        "white": "81.1",
        "black": "14.1",
        "asian": "0.9",
        "hispanicOrLatino": "7.1"
      }
    },
    "wageData": {
      "2017": {
        "workers": "94",
        "median": "1,348",
        "maleWorkers": "81",
        "maleMedian": "1,347",
        "femaleWorkers": "13",
        "femaleMedian": "-"
      }
    }
  },
  "First-line supervisors of fire fighting and prevention workers": {
    "ageData": {
      "2017": {
        "total": "44",
        "16to19": "0",
        "20to24": "1",
        "25to34": "2",
        "35to44": "12",
        "45to54": "20",
        "55to64": "7",
        "65+": "2",
        "medianAge": "7"
      }
    },
    "title": "First-line supervisors of fire fighting and prevention workers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "44",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "43",
        "median": "-",
        "maleWorkers": "42",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "First-line supervisors of protective service workers, all other": {
    "ageData": {
      "2017": {
        "total": "72",
        "16to19": "0",
        "20to24": "2",
        "25to34": "11",
        "35to44": "16",
        "45to54": "21",
        "55to64": "16",
        "65+": "6",
        "medianAge": "16"
      }
    },
    "title": "First-line supervisors of protective service workers, all other",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "72",
        "women": "22.0",
        "white": "74.7",
        "black": "15.0",
        "asian": "5.4",
        "hispanicOrLatino": "10.0"
      }
    },
    "wageData": {
      "2017": {
        "workers": "69",
        "median": "953",
        "maleWorkers": "58",
        "maleMedian": "938",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "Firefighters": {
    "ageData": {
      "2017": {
        "total": "283",
        "16to19": "1",
        "20to24": "18",
        "25to34": "103",
        "35to44": "87",
        "45to54": "48",
        "55to64": "25",
        "65+": "1",
        "medianAge": "25"
      }
    },
    "title": "Firefighters",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "283",
        "women": "3.5",
        "white": "88.5",
        "black": "7.5",
        "asian": "1.0",
        "hispanicOrLatino": "7.7"
      }
    },
    "wageData": {
      "2017": {
        "workers": "272",
        "median": "1,123",
        "maleWorkers": "264",
        "maleMedian": "1,136",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Fire inspectors": {
    "ageData": {
      "2017": {
        "total": "27",
        "16to19": "0",
        "20to24": "2",
        "25to34": "3",
        "35to44": "6",
        "45to54": "8",
        "55to64": "5",
        "65+": "4",
        "medianAge": "5"
      }
    },
    "title": "Fire inspectors",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "27",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "22",
        "median": "-",
        "maleWorkers": "22",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Bailiffs, correctional officers, and jailers": {
    "ageData": {
      "2017": {
        "total": "408",
        "16to19": "2",
        "20to24": "34",
        "25to34": "136",
        "35to44": "88",
        "45to54": "86",
        "55to64": "43",
        "65+": "18",
        "medianAge": "43"
      }
    },
    "title": "Bailiffs, correctional officers, and jailers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "408",
        "women": "28.5",
        "white": "67.4",
        "black": "29.0",
        "asian": "1.8",
        "hispanicOrLatino": "13.5"
      }
    },
    "wageData": {
      "2017": {
        "workers": "390",
        "median": "771",
        "maleWorkers": "284",
        "maleMedian": "825",
        "femaleWorkers": "106",
        "femaleMedian": "669"
      }
    }
  },
  "Detectives and criminal investigators": {
    "ageData": {
      "2017": {
        "total": "136",
        "16to19": "1",
        "20to24": "3",
        "25to34": "28",
        "35to44": "43",
        "45to54": "43",
        "55to64": "16",
        "65+": "2",
        "medianAge": "16"
      }
    },
    "title": "Detectives and criminal investigators",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "136",
        "women": "23.2",
        "white": "87.1",
        "black": "11.6",
        "asian": "0.9",
        "hispanicOrLatino": "14.1"
      }
    },
    "wageData": {
      "2017": {
        "workers": "142",
        "median": "1,256",
        "maleWorkers": "110",
        "maleMedian": "1,291",
        "femaleWorkers": "32",
        "femaleMedian": "-"
      }
    }
  },
  "Fish and game wardens": {
    "ageData": {
      "2017": {
        "total": "4",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "2",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Fish and game wardens",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Parking enforcement workers": {
    "ageData": {
      "2017": {
        "total": "6",
        "16to19": "0",
        "20to24": "1",
        "25to34": "0",
        "35to44": "2",
        "45to54": "3",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Parking enforcement workers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "6",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Police and sheriff's patrol officers": {
    "ageData": {
      "2017": {
        "total": "704",
        "16to19": "1",
        "20to24": "35",
        "25to34": "211",
        "35to44": "212",
        "45to54": "167",
        "55to64": "54",
        "65+": "24",
        "medianAge": "54"
      }
    },
    "title": "Police and sheriff's patrol officers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "704",
        "women": "13.6",
        "white": "79.8",
        "black": "13.9",
        "asian": "2.4",
        "hispanicOrLatino": "10.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "688",
        "median": "1,015",
        "maleWorkers": "595",
        "maleMedian": "1,024",
        "femaleWorkers": "94",
        "femaleMedian": "985"
      }
    }
  },
  "Transit and railroad police": {
    "ageData": {
      "2017": {
        "total": "7",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "2",
        "45to54": "2",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Transit and railroad police",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Animal control workers": {
    "ageData": {
      "2017": {
        "total": "15",
        "16to19": "0",
        "20to24": "0",
        "25to34": "5",
        "35to44": "2",
        "45to54": "5",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Animal control workers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "15",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Private detectives and investigators": {
    "ageData": {
      "2017": {
        "total": "94",
        "16to19": "1",
        "20to24": "3",
        "25to34": "15",
        "35to44": "29",
        "45to54": "20",
        "55to64": "20",
        "65+": "7",
        "medianAge": "20"
      }
    },
    "title": "Private detectives and investigators",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "94",
        "women": "48.8",
        "white": "79.0",
        "black": "13.8",
        "asian": "4.6",
        "hispanicOrLatino": "11.3"
      }
    },
    "wageData": {
      "2017": {
        "workers": "86",
        "median": "963",
        "maleWorkers": "42",
        "maleMedian": "-",
        "femaleWorkers": "44",
        "femaleMedian": "-"
      }
    }
  },
  "Security guards and gaming surveillance officers": {
    "ageData": {
      "2017": {
        "total": "920",
        "16to19": "13",
        "20to24": "122",
        "25to34": "242",
        "35to44": "152",
        "45to54": "159",
        "55to64": "153",
        "65+": "79",
        "medianAge": "153"
      }
    },
    "title": "Security guards and gaming surveillance officers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "920",
        "women": "24.3",
        "white": "57.5",
        "black": "32.3",
        "asian": "4.4",
        "hispanicOrLatino": "17.0"
      }
    },
    "wageData": {
      "2017": {
        "workers": "740",
        "median": "596",
        "maleWorkers": "570",
        "maleMedian": "616",
        "femaleWorkers": "170",
        "femaleMedian": "548"
      }
    }
  },
  "Crossing guards": {
    "ageData": {
      "2017": {
        "total": "63",
        "16to19": "2",
        "20to24": "2",
        "25to34": "6",
        "35to44": "11",
        "45to54": "8",
        "55to64": "20",
        "65+": "13",
        "medianAge": "20"
      }
    },
    "title": "Crossing guards",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "63",
        "women": "52.2",
        "white": "69.4",
        "black": "21.5",
        "asian": "4.7",
        "hispanicOrLatino": "20.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "23",
        "median": "-",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Transportation security screeners": {
    "ageData": {
      "2017": {
        "total": "46",
        "16to19": "0",
        "20to24": "9",
        "25to34": "13",
        "35to44": "6",
        "45to54": "10",
        "55to64": "7",
        "65+": "1",
        "medianAge": "7"
      }
    },
    "title": "Transportation security screeners",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "46",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "43",
        "median": "-",
        "maleWorkers": "32",
        "maleMedian": "-",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "Lifeguards and other recreational, and all other protective service workers": {
    "ageData": {
      "2017": {
        "total": "136",
        "16to19": "77",
        "20to24": "33",
        "25to34": "8",
        "35to44": "5",
        "45to54": "7",
        "55to64": "3",
        "65+": "3",
        "medianAge": "3"
      }
    },
    "title": "Lifeguards and other recreational, and all other protective service workers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "136",
        "women": "49.6",
        "white": "88.5",
        "black": "4.1",
        "asian": "2.5",
        "hispanicOrLatino": "9.4"
      }
    },
    "wageData": {
      "2017": {
        "workers": "49",
        "median": "-",
        "maleWorkers": "30",
        "maleMedian": "-",
        "femaleWorkers": "19",
        "femaleMedian": "-"
      }
    }
  },
  "Food preparation and serving related occupations": {
    "ageData": {
      "2017": {
        "total": "8,305",
        "16to19": "1,346",
        "20to24": "1,765",
        "25to34": "1,975",
        "35to44": "1,204",
        "45to54": "1,028",
        "55to64": "749",
        "65+": "239",
        "medianAge": "749"
      }
    },
    "title": "Food preparation and serving related occupations",
    "depth": 2,
    "parent": "Service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "8,305",
        "women": "53.8",
        "white": "74.4",
        "black": "13.9",
        "asian": "6.2",
        "hispanicOrLatino": "25.5"
      }
    },
    "wageData": {
      "2017": {
        "workers": "4,465",
        "median": "484",
        "maleWorkers": "2,359",
        "maleMedian": "502",
        "femaleWorkers": "2,106",
        "femaleMedian": "459"
      }
    }
  },
  "Chefs and head cooks": {
    "ageData": {
      "2017": {
        "total": "465",
        "16to19": "5",
        "20to24": "40",
        "25to34": "149",
        "35to44": "116",
        "45to54": "79",
        "55to64": "63",
        "65+": "14",
        "medianAge": "63"
      }
    },
    "title": "Chefs and head cooks",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "465",
        "women": "19.7",
        "white": "65.0",
        "black": "16.4",
        "asian": "13.9",
        "hispanicOrLatino": "21.0"
      }
    },
    "wageData": {
      "2017": {
        "workers": "382",
        "median": "634",
        "maleWorkers": "310",
        "maleMedian": "655",
        "femaleWorkers": "72",
        "femaleMedian": "514"
      }
    }
  },
  "First-line supervisors of food preparation and serving workers": {
    "ageData": {
      "2017": {
        "total": "519",
        "16to19": "29",
        "20to24": "81",
        "25to34": "158",
        "35to44": "83",
        "45to54": "88",
        "55to64": "65",
        "65+": "15",
        "medianAge": "65"
      }
    },
    "title": "First-line supervisors of food preparation and serving workers",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "519",
        "women": "54.8",
        "white": "74.8",
        "black": "17.0",
        "asian": "3.7",
        "hispanicOrLatino": "18.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "427",
        "median": "537",
        "maleWorkers": "200",
        "maleMedian": "613",
        "femaleWorkers": "227",
        "femaleMedian": "500"
      }
    }
  },
  "Cooks": {
    "ageData": {
      "2017": {
        "total": "2,079",
        "16to19": "236",
        "20to24": "328",
        "25to34": "482",
        "35to44": "415",
        "45to54": "331",
        "55to64": "224",
        "65+": "63",
        "medianAge": "224"
      }
    },
    "title": "Cooks",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "2,079",
        "women": "39.3",
        "white": "73.2",
        "black": "16.8",
        "asian": "5.1",
        "hispanicOrLatino": "35.4"
      }
    },
    "wageData": {
      "2017": {
        "workers": "1,365",
        "median": "466",
        "maleWorkers": "859",
        "maleMedian": "481",
        "femaleWorkers": "506",
        "femaleMedian": "436"
      }
    }
  },
  "Food preparation workers": {
    "ageData": {
      "2017": {
        "total": "1,068",
        "16to19": "192",
        "20to24": "254",
        "25to34": "217",
        "35to44": "116",
        "45to54": "131",
        "55to64": "109",
        "65+": "49",
        "medianAge": "109"
      }
    },
    "title": "Food preparation workers",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "1,068",
        "women": "58.9",
        "white": "72.2",
        "black": "14.5",
        "asian": "6.5",
        "hispanicOrLatino": "27.4"
      }
    },
    "wageData": {
      "2017": {
        "workers": "510",
        "median": "425",
        "maleWorkers": "242",
        "maleMedian": "449",
        "femaleWorkers": "269",
        "femaleMedian": "410"
      }
    }
  },
  "Bartenders": {
    "ageData": {
      "2017": {
        "total": "417",
        "16to19": "8",
        "20to24": "78",
        "25to34": "172",
        "35to44": "72",
        "45to54": "49",
        "55to64": "27",
        "65+": "11",
        "medianAge": "27"
      }
    },
    "title": "Bartenders",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "417",
        "women": "57.3",
        "white": "85.5",
        "black": "7.2",
        "asian": "2.8",
        "hispanicOrLatino": "14.1"
      }
    },
    "wageData": {
      "2017": {
        "workers": "210",
        "median": "554",
        "maleWorkers": "98",
        "maleMedian": "600",
        "femaleWorkers": "112",
        "femaleMedian": "515"
      }
    }
  },
  "Combined food preparation and serving workers, including fast food": {
    "ageData": {
      "2017": {
        "total": "372",
        "16to19": "95",
        "20to24": "89",
        "25to34": "67",
        "35to44": "38",
        "45to54": "39",
        "55to64": "29",
        "65+": "13",
        "medianAge": "29"
      }
    },
    "title": "Combined food preparation and serving workers, including fast food",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "372",
        "women": "60.0",
        "white": "70.0",
        "black": "17.3",
        "asian": "4.4",
        "hispanicOrLatino": "22.8"
      }
    },
    "wageData": {
      "2017": {
        "workers": "175",
        "median": "407",
        "maleWorkers": "75",
        "maleMedian": "415",
        "femaleWorkers": "100",
        "femaleMedian": "401"
      }
    }
  },
  "Counter attendants, cafeteria, food concession, and coffee shop": {
    "ageData": {
      "2017": {
        "total": "225",
        "16to19": "105",
        "20to24": "52",
        "25to34": "26",
        "35to44": "12",
        "45to54": "15",
        "55to64": "13",
        "65+": "3",
        "medianAge": "13"
      }
    },
    "title": "Counter attendants, cafeteria, food concession, and coffee shop",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "225",
        "women": "62.1",
        "white": "72.0",
        "black": "16.9",
        "asian": "5.0",
        "hispanicOrLatino": "21.3"
      }
    },
    "wageData": {
      "2017": {
        "workers": "65",
        "median": "460",
        "maleWorkers": "29",
        "maleMedian": "-",
        "femaleWorkers": "36",
        "femaleMedian": "-"
      }
    }
  },
  "Waiters and waitresses": {
    "ageData": {
      "2017": {
        "total": "2,016",
        "16to19": "324",
        "20to24": "597",
        "25to34": "540",
        "35to44": "233",
        "45to54": "176",
        "55to64": "112",
        "65+": "34",
        "medianAge": "112"
      }
    },
    "title": "Waiters and waitresses",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "2,016",
        "women": "69.9",
        "white": "78.2",
        "black": "9.2",
        "asian": "7.6",
        "hispanicOrLatino": "20.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "911",
        "median": "493",
        "maleWorkers": "319",
        "maleMedian": "533",
        "femaleWorkers": "592",
        "femaleMedian": "475"
      }
    }
  },
  "Food servers, nonrestaurant": {
    "ageData": {
      "2017": {
        "total": "207",
        "16to19": "41",
        "20to24": "40",
        "25to34": "36",
        "35to44": "25",
        "45to54": "31",
        "55to64": "27",
        "65+": "7",
        "medianAge": "27"
      }
    },
    "title": "Food servers, nonrestaurant",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "207",
        "women": "69.9",
        "white": "69.9",
        "black": "18.5",
        "asian": "7.0",
        "hispanicOrLatino": "21.8"
      }
    },
    "wageData": {
      "2017": {
        "workers": "88",
        "median": "473",
        "maleWorkers": "28",
        "maleMedian": "-",
        "femaleWorkers": "59",
        "femaleMedian": "468"
      }
    }
  },
  "Dining room and cafeteria attendants and bartender helpers": {
    "ageData": {
      "2017": {
        "total": "334",
        "16to19": "94",
        "20to24": "65",
        "25to34": "44",
        "35to44": "44",
        "45to54": "36",
        "55to64": "38",
        "65+": "13",
        "medianAge": "38"
      }
    },
    "title": "Dining room and cafeteria attendants and bartender helpers",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "334",
        "women": "45.9",
        "white": "76.4",
        "black": "9.9",
        "asian": "7.8",
        "hispanicOrLatino": "27.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "134",
        "median": "458",
        "maleWorkers": "79",
        "maleMedian": "444",
        "femaleWorkers": "55",
        "femaleMedian": "481"
      }
    }
  },
  "Dishwashers": {
    "ageData": {
      "2017": {
        "total": "274",
        "16to19": "67",
        "20to24": "52",
        "25to34": "45",
        "35to44": "37",
        "45to54": "41",
        "55to64": "26",
        "65+": "7",
        "medianAge": "26"
      }
    },
    "title": "Dishwashers",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "274",
        "women": "19.5",
        "white": "70.9",
        "black": "19.9",
        "asian": "2.9",
        "hispanicOrLatino": "31.2"
      }
    },
    "wageData": {
      "2017": {
        "workers": "131",
        "median": "401",
        "maleWorkers": "111",
        "maleMedian": "408",
        "femaleWorkers": "19",
        "femaleMedian": "-"
      }
    }
  },
  "Hosts and hostesses, restaurant, lounge, and coffee shop": {
    "ageData": {
      "2017": {
        "total": "324",
        "16to19": "149",
        "20to24": "88",
        "25to34": "38",
        "35to44": "13",
        "45to54": "10",
        "55to64": "17",
        "65+": "10",
        "medianAge": "17"
      }
    },
    "title": "Hosts and hostesses, restaurant, lounge, and coffee shop",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "324",
        "women": "85.9",
        "white": "74.8",
        "black": "12.9",
        "asian": "3.3",
        "hispanicOrLatino": "17.1"
      }
    },
    "wageData": {
      "2017": {
        "workers": "67",
        "median": "401",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "58",
        "femaleMedian": "400"
      }
    }
  },
  "Food preparation and serving related workers, all other": {
    "ageData": {
      "2017": {
        "total": "4",
        "16to19": "0",
        "20to24": "2",
        "25to34": "1",
        "35to44": "0",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Food preparation and serving related workers, all other",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Building and grounds cleaning and maintenance occupations": {
    "ageData": {
      "2017": {
        "total": "5,888",
        "16to19": "200",
        "20to24": "451",
        "25to34": "1,099",
        "35to44": "1,236",
        "45to54": "1,338",
        "55to64": "1,183",
        "65+": "381",
        "medianAge": "1,183"
      }
    },
    "title": "Building and grounds cleaning and maintenance occupations",
    "depth": 2,
    "parent": "Service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "5,888",
        "women": "40.7",
        "white": "77.4",
        "black": "14.9",
        "asian": "3.3",
        "hispanicOrLatino": "38.0"
      }
    },
    "wageData": {
      "2017": {
        "workers": "3,641",
        "median": "522",
        "maleWorkers": "2,373",
        "maleMedian": "573",
        "femaleWorkers": "1,268",
        "femaleMedian": "463"
      }
    }
  },
  "First-line supervisors of housekeeping and janitorial workers": {
    "ageData": {
      "2017": {
        "total": "326",
        "16to19": "1",
        "20to24": "11",
        "25to34": "48",
        "35to44": "75",
        "45to54": "104",
        "55to64": "68",
        "65+": "20",
        "medianAge": "68"
      }
    },
    "title": "First-line supervisors of housekeeping and janitorial workers",
    "depth": 3,
    "parent": "Building and grounds cleaning and maintenance occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "326",
        "women": "41.1",
        "white": "79.9",
        "black": "12.0",
        "asian": "4.6",
        "hispanicOrLatino": "28.5"
      }
    },
    "wageData": {
      "2017": {
        "workers": "190",
        "median": "661",
        "maleWorkers": "113",
        "maleMedian": "749",
        "femaleWorkers": "77",
        "femaleMedian": "515"
      }
    }
  },
  "First-line supervisors of landscaping, lawn service, and groundskeeping workers": {
    "ageData": {
      "2017": {
        "total": "255",
        "16to19": "1",
        "20to24": "16",
        "25to34": "48",
        "35to44": "67",
        "45to54": "60",
        "55to64": "50",
        "65+": "13",
        "medianAge": "50"
      }
    },
    "title": "First-line supervisors of landscaping, lawn service, and groundskeeping workers",
    "depth": 3,
    "parent": "Building and grounds cleaning and maintenance occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "255",
        "women": "8.0",
        "white": "91.6",
        "black": "5.4",
        "asian": "1.2",
        "hispanicOrLatino": "21.5"
      }
    },
    "wageData": {
      "2017": {
        "workers": "111",
        "median": "723",
        "maleWorkers": "102",
        "maleMedian": "760",
        "femaleWorkers": "9",
        "femaleMedian": "-"
      }
    }
  },
  "Janitors and building cleaners": {
    "ageData": {
      "2017": {
        "total": "2,307",
        "16to19": "87",
        "20to24": "176",
        "25to34": "364",
        "35to44": "402",
        "45to54": "526",
        "55to64": "562",
        "65+": "191",
        "medianAge": "562"
      }
    },
    "title": "Janitors and building cleaners",
    "depth": 3,
    "parent": "Building and grounds cleaning and maintenance occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "2,307",
        "women": "35.2",
        "white": "72.5",
        "black": "18.6",
        "asian": "3.8",
        "hispanicOrLatino": "31.7"
      }
    },
    "wageData": {
      "2017": {
        "workers": "1,595",
        "median": "539",
        "maleWorkers": "1,136",
        "maleMedian": "574",
        "femaleWorkers": "459",
        "femaleMedian": "481"
      }
    }
  },
  "Maids and housekeeping cleaners": {
    "ageData": {
      "2017": {
        "total": "1,527",
        "16to19": "34",
        "20to24": "91",
        "25to34": "253",
        "35to44": "397",
        "45to54": "359",
        "55to64": "302",
        "65+": "91",
        "medianAge": "302"
      }
    },
    "title": "Maids and housekeeping cleaners",
    "depth": 3,
    "parent": "Building and grounds cleaning and maintenance occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "1,527",
        "women": "88.0",
        "white": "74.3",
        "black": "17.9",
        "asian": "3.9",
        "hispanicOrLatino": "49.4"
      }
    },
    "wageData": {
      "2017": {
        "workers": "815",
        "median": "450",
        "maleWorkers": "128",
        "maleMedian": "508",
        "femaleWorkers": "687",
        "femaleMedian": "439"
      }
    }
  },
  "Pest control workers": {
    "ageData": {
      "2017": {
        "total": "108",
        "16to19": "1",
        "20to24": "12",
        "25to34": "32",
        "35to44": "19",
        "45to54": "23",
        "55to64": "21",
        "65+": "2",
        "medianAge": "21"
      }
    },
    "title": "Pest control workers",
    "depth": 3,
    "parent": "Building and grounds cleaning and maintenance occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "108",
        "women": "6.5",
        "white": "82.0",
        "black": "11.4",
        "asian": "1.4",
        "hispanicOrLatino": "13.3"
      }
    },
    "wageData": {
      "2017": {
        "workers": "94",
        "median": "616",
        "maleWorkers": "89",
        "maleMedian": "617",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Grounds maintenance workers": {
    "ageData": {
      "2017": {
        "total": "1,365",
        "16to19": "78",
        "20to24": "147",
        "25to34": "355",
        "35to44": "276",
        "45to54": "266",
        "55to64": "179",
        "65+": "64",
        "medianAge": "179"
      }
    },
    "title": "Grounds maintenance workers",
    "depth": 3,
    "parent": "Building and grounds cleaning and maintenance occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "1,365",
        "women": "5.9",
        "white": "85.6",
        "black": "7.9",
        "asian": "2.0",
        "hispanicOrLatino": "43.1"
      }
    },
    "wageData": {
      "2017": {
        "workers": "837",
        "median": "525",
        "maleWorkers": "805",
        "maleMedian": "526",
        "femaleWorkers": "31",
        "femaleMedian": "-"
      }
    }
  },
  "Personal care and service occupations": {
    "ageData": {
      "2017": {
        "total": "5,939",
        "16to19": "357",
        "20to24": "824",
        "25to34": "1,271",
        "35to44": "1,079",
        "45to54": "1,094",
        "55to64": "876",
        "65+": "439",
        "medianAge": "876"
      }
    },
    "title": "Personal care and service occupations",
    "depth": 2,
    "parent": "Service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "5,939",
        "women": "76.1",
        "white": "69.5",
        "black": "16.1",
        "asian": "10.2",
        "hispanicOrLatino": "17.1"
      }
    },
    "wageData": {
      "2017": {
        "workers": "2,699",
        "median": "520",
        "maleWorkers": "728",
        "maleMedian": "612",
        "femaleWorkers": "1,971",
        "femaleMedian": "503"
      }
    }
  },
  "First-line supervisors of gaming workers": {
    "ageData": {
      "2017": {
        "total": "208",
        "16to19": "6",
        "20to24": "17",
        "25to34": "55",
        "35to44": "47",
        "45to54": "45",
        "55to64": "21",
        "65+": "17",
        "medianAge": "21"
      }
    },
    "title": "First-line supervisors of gaming workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "208",
        "women": "44.3",
        "white": "82.4",
        "black": "7.7",
        "asian": "4.7",
        "hispanicOrLatino": "6.8"
      }
    },
    "wageData": {
      "2017": {
        "workers": "126",
        "median": "797",
        "maleWorkers": "73",
        "maleMedian": "837",
        "femaleWorkers": "53",
        "femaleMedian": "763"
      }
    }
  },
  "First-line supervisors of personal service workers": {
    "ageData": {
      "2017": {
        "total": "232",
        "16to19": "0",
        "20to24": "10",
        "25to34": "49",
        "35to44": "68",
        "45to54": "57",
        "55to64": "34",
        "65+": "15",
        "medianAge": "34"
      }
    },
    "title": "First-line supervisors of personal service workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "232",
        "women": "66.2",
        "white": "63.8",
        "black": "15.4",
        "asian": "18.8",
        "hispanicOrLatino": "11.1"
      }
    },
    "wageData": {
      "2017": {
        "workers": "76",
        "median": "619",
        "maleWorkers": "31",
        "maleMedian": "-",
        "femaleWorkers": "45",
        "femaleMedian": "-"
      }
    }
  },
  "Animal trainers": {
    "ageData": {
      "2017": {
        "total": "49",
        "16to19": "1",
        "20to24": "7",
        "25to34": "12",
        "35to44": "8",
        "45to54": "9",
        "55to64": "8",
        "65+": "3",
        "medianAge": "8"
      }
    },
    "title": "Animal trainers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "49",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "23",
        "median": "-",
        "maleWorkers": "16",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Nonfarm animal caretakers": {
    "ageData": {
      "2017": {
        "total": "263",
        "16to19": "29",
        "20to24": "67",
        "25to34": "52",
        "35to44": "44",
        "45to54": "31",
        "55to64": "27",
        "65+": "12",
        "medianAge": "27"
      }
    },
    "title": "Nonfarm animal caretakers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "263",
        "women": "78.4",
        "white": "90.8",
        "black": "2.8",
        "asian": "1.6",
        "hispanicOrLatino": "13.6"
      }
    },
    "wageData": {
      "2017": {
        "workers": "99",
        "median": "506",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "78",
        "femaleMedian": "504"
      }
    }
  },
  "Gaming services workers": {
    "ageData": {
      "2017": {
        "total": "90",
        "16to19": "4",
        "20to24": "4",
        "25to34": "21",
        "35to44": "19",
        "45to54": "19",
        "55to64": "15",
        "65+": "8",
        "medianAge": "15"
      }
    },
    "title": "Gaming services workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "90",
        "women": "46.7",
        "white": "45.1",
        "black": "8.9",
        "asian": "34.0",
        "hispanicOrLatino": "10.2"
      }
    },
    "wageData": {
      "2017": {
        "workers": "66",
        "median": "692",
        "maleWorkers": "37",
        "maleMedian": "-",
        "femaleWorkers": "28",
        "femaleMedian": "-"
      }
    }
  },
  "Motion picture projectionists": {
    "ageData": {
      "2017": {
        "total": "1",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Motion picture projectionists",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "0",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Ushers, lobby attendants, and ticket takers": {
    "ageData": {
      "2017": {
        "total": "37",
        "16to19": "14",
        "20to24": "7",
        "25to34": "5",
        "35to44": "2",
        "45to54": "2",
        "55to64": "3",
        "65+": "5",
        "medianAge": "3"
      }
    },
    "title": "Ushers, lobby attendants, and ticket takers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "37",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous entertainment attendants and related workers": {
    "ageData": {
      "2017": {
        "total": "224",
        "16to19": "60",
        "20to24": "54",
        "25to34": "37",
        "35to44": "14",
        "45to54": "17",
        "55to64": "20",
        "65+": "21",
        "medianAge": "20"
      }
    },
    "title": "Miscellaneous entertainment attendants and related workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "224",
        "women": "42.9",
        "white": "75.9",
        "black": "16.1",
        "asian": "1.8",
        "hispanicOrLatino": "18.6"
      }
    },
    "wageData": {
      "2017": {
        "workers": "74",
        "median": "517",
        "maleWorkers": "39",
        "maleMedian": "-",
        "femaleWorkers": "36",
        "femaleMedian": "-"
      }
    }
  },
  "Embalmers and funeral attendants": {
    "ageData": {
      "2017": {
        "total": "17",
        "16to19": "1",
        "20to24": "2",
        "25to34": "0",
        "35to44": "1",
        "45to54": "4",
        "55to64": "4",
        "65+": "6",
        "medianAge": "4"
      }
    },
    "title": "Embalmers and funeral attendants",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "17",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "15",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Morticians, undertakers, and funeral directors": {
    "ageData": {
      "2017": {
        "total": "42",
        "16to19": "0",
        "20to24": "0",
        "25to34": "5",
        "35to44": "9",
        "45to54": "8",
        "55to64": "12",
        "65+": "8",
        "medianAge": "12"
      }
    },
    "title": "Morticians, undertakers, and funeral directors",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "42",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "22",
        "median": "-",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Barbers": {
    "ageData": {
      "2017": {
        "total": "135",
        "16to19": "0",
        "20to24": "9",
        "25to34": "42",
        "35to44": "32",
        "45to54": "21",
        "55to64": "15",
        "65+": "17",
        "medianAge": "15"
      }
    },
    "title": "Barbers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "135",
        "women": "15.6",
        "white": "50.7",
        "black": "38.1",
        "asian": "5.1",
        "hispanicOrLatino": "24.1"
      }
    },
    "wageData": {
      "2017": {
        "workers": "54",
        "median": "590",
        "maleWorkers": "47",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Hairdressers, hairstylists, and cosmetologists": {
    "ageData": {
      "2017": {
        "total": "806",
        "16to19": "14",
        "20to24": "79",
        "25to34": "231",
        "35to44": "156",
        "45to54": "162",
        "55to64": "98",
        "65+": "67",
        "medianAge": "98"
      }
    },
    "title": "Hairdressers, hairstylists, and cosmetologists",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "806",
        "women": "92.6",
        "white": "76.7",
        "black": "13.5",
        "asian": "5.6",
        "hispanicOrLatino": "16.3"
      }
    },
    "wageData": {
      "2017": {
        "workers": "324",
        "median": "528",
        "maleWorkers": "22",
        "maleMedian": "-",
        "femaleWorkers": "302",
        "femaleMedian": "521"
      }
    }
  },
  "Miscellaneous personal appearance workers": {
    "ageData": {
      "2017": {
        "total": "397",
        "16to19": "5",
        "20to24": "39",
        "25to34": "76",
        "35to44": "115",
        "45to54": "104",
        "55to64": "47",
        "65+": "12",
        "medianAge": "47"
      }
    },
    "title": "Miscellaneous personal appearance workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "397",
        "women": "81.8",
        "white": "27.2",
        "black": "8.3",
        "asian": "62.3",
        "hispanicOrLatino": "9.1"
      }
    },
    "wageData": {
      "2017": {
        "workers": "235",
        "median": "567",
        "maleWorkers": "48",
        "maleMedian": "-",
        "femaleWorkers": "187",
        "femaleMedian": "538"
      }
    }
  },
  "Baggage porters, bellhops, and concierges": {
    "ageData": {
      "2017": {
        "total": "89",
        "16to19": "2",
        "20to24": "18",
        "25to34": "23",
        "35to44": "13",
        "45to54": "13",
        "55to64": "12",
        "65+": "7",
        "medianAge": "12"
      }
    },
    "title": "Baggage porters, bellhops, and concierges",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "89",
        "women": "31.1",
        "white": "60.1",
        "black": "24.9",
        "asian": "8.3",
        "hispanicOrLatino": "27.5"
      }
    },
    "wageData": {
      "2017": {
        "workers": "70",
        "median": "547",
        "maleWorkers": "56",
        "maleMedian": "543",
        "femaleWorkers": "14",
        "femaleMedian": "-"
      }
    }
  },
  "Tour and travel guides": {
    "ageData": {
      "2017": {
        "total": "68",
        "16to19": "6",
        "20to24": "15",
        "25to34": "11",
        "35to44": "8",
        "45to54": "4",
        "55to64": "14",
        "65+": "10",
        "medianAge": "14"
      }
    },
    "title": "Tour and travel guides",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "68",
        "women": "46.4",
        "white": "81.2",
        "black": "4.5",
        "asian": "5.3",
        "hispanicOrLatino": "8.8"
      }
    },
    "wageData": {
      "2017": {
        "workers": "21",
        "median": "-",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Childcare workers": {
    "ageData": {
      "2017": {
        "total": "1,225",
        "16to19": "131",
        "20to24": "222",
        "25to34": "240",
        "35to44": "180",
        "45to54": "209",
        "55to64": "182",
        "65+": "60",
        "medianAge": "182"
      }
    },
    "title": "Childcare workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "1,225",
        "women": "93.7",
        "white": "77.3",
        "black": "15.4",
        "asian": "4.3",
        "hispanicOrLatino": "22.3"
      }
    },
    "wageData": {
      "2017": {
        "workers": "448",
        "median": "482",
        "maleWorkers": "35",
        "maleMedian": "-",
        "femaleWorkers": "413",
        "femaleMedian": "474"
      }
    }
  },
  "Personal care aides": {
    "ageData": {
      "2017": {
        "total": "1,365",
        "16to19": "38",
        "20to24": "148",
        "25to34": "253",
        "35to44": "238",
        "45to54": "275",
        "55to64": "274",
        "65+": "140",
        "medianAge": "274"
      }
    },
    "title": "Personal care aides",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "1,365",
        "women": "83.7",
        "white": "62.0",
        "black": "24.8",
        "asian": "9.1",
        "hispanicOrLatino": "20.7"
      }
    },
    "wageData": {
      "2017": {
        "workers": "735",
        "median": "486",
        "maleWorkers": "127",
        "maleMedian": "520",
        "femaleWorkers": "608",
        "femaleMedian": "479"
      }
    }
  },
  "Recreation and fitness workers": {
    "ageData": {
      "2017": {
        "total": "480",
        "16to19": "38",
        "20to24": "82",
        "25to34": "104",
        "35to44": "91",
        "45to54": "82",
        "55to64": "60",
        "65+": "23",
        "medianAge": "60"
      }
    },
    "title": "Recreation and fitness workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "480",
        "women": "62.3",
        "white": "80.1",
        "black": "11.4",
        "asian": "3.5",
        "hispanicOrLatino": "9.6"
      }
    },
    "wageData": {
      "2017": {
        "workers": "200",
        "median": "616",
        "maleWorkers": "82",
        "maleMedian": "726",
        "femaleWorkers": "118",
        "femaleMedian": "565"
      }
    }
  },
  "Residential advisors": {
    "ageData": {
      "2017": {
        "total": "44",
        "16to19": "1",
        "20to24": "15",
        "25to34": "12",
        "35to44": "2",
        "45to54": "9",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Residential advisors",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "44",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "27",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "17",
        "femaleMedian": "-"
      }
    }
  },
  "Personal care and service workers, all other": {
    "ageData": {
      "2017": {
        "total": "167",
        "16to19": "7",
        "20to24": "31",
        "25to34": "42",
        "35to44": "33",
        "45to54": "19",
        "55to64": "24",
        "65+": "11",
        "medianAge": "24"
      }
    },
    "title": "Personal care and service workers, all other",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "167",
        "women": "60.1",
        "white": "74.6",
        "black": "14.6",
        "asian": "4.9",
        "hispanicOrLatino": "17.8"
      }
    },
    "wageData": {
      "2017": {
        "workers": "76",
        "median": "570",
        "maleWorkers": "37",
        "maleMedian": "-",
        "femaleWorkers": "40",
        "femaleMedian": "-"
      }
    }
  },
  "Sales and office occupations": {
    "ageData": {
      "2017": {
        "total": "33,566",
        "16to19": "1,715",
        "20to24": "4,071",
        "25to34": "7,186",
        "35to44": "5,974",
        "45to54": "6,582",
        "55to64": "5,761",
        "65+": "2,277",
        "medianAge": "5,761"
      }
    },
    "title": "Sales and office occupations",
    "depth": 1,
    "parent": "",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "33,566",
        "women": "61.3",
        "white": "78.5",
        "black": "13.0",
        "asian": "5.0",
        "hispanicOrLatino": "15.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "23,686",
        "median": "718",
        "maleWorkers": "9,481",
        "maleMedian": "834",
        "femaleWorkers": "14,205",
        "femaleMedian": "672"
      }
    }
  },
  "Sales and related occupations": {
    "ageData": {
      "2017": {
        "total": "15,815",
        "16to19": "1,167",
        "20to24": "2,074",
        "25to34": "3,292",
        "35to44": "2,723",
        "45to54": "2,879",
        "55to64": "2,507",
        "65+": "1,174",
        "medianAge": "2,507"
      }
    },
    "title": "Sales and related occupations",
    "depth": 2,
    "parent": "Sales and office occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "15,815",
        "women": "49.1",
        "white": "79.9",
        "black": "11.1",
        "asian": "5.6",
        "hispanicOrLatino": "15.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "9,953",
        "median": "763",
        "maleWorkers": "5,598",
        "maleMedian": "934",
        "femaleWorkers": "4,355",
        "femaleMedian": "609"
      }
    }
  },
  "First-line supervisors of retail sales workers": {
    "ageData": {
      "2017": {
        "total": "3,272",
        "16to19": "35",
        "20to24": "256",
        "25to34": "823",
        "35to44": "699",
        "45to54": "677",
        "55to64": "568",
        "65+": "214",
        "medianAge": "568"
      }
    },
    "title": "First-line supervisors of retail sales workers",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "3,272",
        "women": "43.4",
        "white": "81.6",
        "black": "9.4",
        "asian": "6.2",
        "hispanicOrLatino": "15.0"
      }
    },
    "wageData": {
      "2017": {
        "workers": "2,388",
        "median": "764",
        "maleWorkers": "1,376",
        "maleMedian": "891",
        "femaleWorkers": "1,013",
        "femaleMedian": "639"
      }
    }
  },
  "First-line supervisors of non-retail sales workers": {
    "ageData": {
      "2017": {
        "total": "1,220",
        "16to19": "5",
        "20to24": "43",
        "25to34": "212",
        "35to44": "260",
        "45to54": "325",
        "55to64": "265",
        "65+": "109",
        "medianAge": "265"
      }
    },
    "title": "First-line supervisors of non-retail sales workers",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "1,220",
        "women": "30.0",
        "white": "84.6",
        "black": "6.8",
        "asian": "5.4",
        "hispanicOrLatino": "13.3"
      }
    },
    "wageData": {
      "2017": {
        "workers": "815",
        "median": "1,062",
        "maleWorkers": "541",
        "maleMedian": "1,151",
        "femaleWorkers": "274",
        "femaleMedian": "965"
      }
    }
  },
  "Cashiers": {
    "ageData": {
      "2017": {
        "total": "3,253",
        "16to19": "777",
        "20to24": "798",
        "25to34": "590",
        "35to44": "335",
        "45to54": "304",
        "55to64": "285",
        "65+": "163",
        "medianAge": "285"
      }
    },
    "title": "Cashiers",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "3,253",
        "women": "72.7",
        "white": "70.2",
        "black": "17.9",
        "asian": "6.4",
        "hispanicOrLatino": "22.3"
      }
    },
    "wageData": {
      "2017": {
        "workers": "1,360",
        "median": "438",
        "maleWorkers": "378",
        "maleMedian": "493",
        "femaleWorkers": "982",
        "femaleMedian": "422"
      }
    }
  },
  "Counter and rental clerks": {
    "ageData": {
      "2017": {
        "total": "109",
        "16to19": "11",
        "20to24": "14",
        "25to34": "27",
        "35to44": "18",
        "45to54": "20",
        "55to64": "13",
        "65+": "6",
        "medianAge": "13"
      }
    },
    "title": "Counter and rental clerks",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "109",
        "women": "48.9",
        "white": "78.4",
        "black": "12.7",
        "asian": "7.0",
        "hispanicOrLatino": "18.6"
      }
    },
    "wageData": {
      "2017": {
        "workers": "78",
        "median": "607",
        "maleWorkers": "48",
        "maleMedian": "-",
        "femaleWorkers": "31",
        "femaleMedian": "-"
      }
    }
  },
  "Parts salespersons": {
    "ageData": {
      "2017": {
        "total": "130",
        "16to19": "5",
        "20to24": "13",
        "25to34": "28",
        "35to44": "24",
        "45to54": "25",
        "55to64": "26",
        "65+": "8",
        "medianAge": "26"
      }
    },
    "title": "Parts salespersons",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "130",
        "women": "7.2",
        "white": "88.8",
        "black": "4.5",
        "asian": "2.5",
        "hispanicOrLatino": "21.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "115",
        "median": "786",
        "maleWorkers": "108",
        "maleMedian": "800",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Retail salespersons": {
    "ageData": {
      "2017": {
        "total": "3,235",
        "16to19": "292",
        "20to24": "649",
        "25to34": "651",
        "35to44": "455",
        "45to54": "488",
        "55to64": "463",
        "65+": "237",
        "medianAge": "463"
      }
    },
    "title": "Retail salespersons",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "3,235",
        "women": "48.2",
        "white": "77.6",
        "black": "12.8",
        "asian": "5.7",
        "hispanicOrLatino": "17.2"
      }
    },
    "wageData": {
      "2017": {
        "workers": "1,896",
        "median": "615",
        "maleWorkers": "1,160",
        "maleMedian": "704",
        "femaleWorkers": "736",
        "femaleMedian": "523"
      }
    }
  },
  "Advertising sales agents": {
    "ageData": {
      "2017": {
        "total": "241",
        "16to19": "3",
        "20to24": "30",
        "25to34": "77",
        "35to44": "52",
        "45to54": "41",
        "55to64": "29",
        "65+": "9",
        "medianAge": "29"
      }
    },
    "title": "Advertising sales agents",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "241",
        "women": "50.4",
        "white": "80.7",
        "black": "14.8",
        "asian": "3.9",
        "hispanicOrLatino": "10.6"
      }
    },
    "wageData": {
      "2017": {
        "workers": "193",
        "median": "887",
        "maleWorkers": "87",
        "maleMedian": "895",
        "femaleWorkers": "106",
        "femaleMedian": "882"
      }
    }
  },
  "Insurance sales agents": {
    "ageData": {
      "2017": {
        "total": "624",
        "16to19": "4",
        "20to24": "33",
        "25to34": "123",
        "35to44": "131",
        "45to54": "142",
        "55to64": "127",
        "65+": "65",
        "medianAge": "127"
      }
    },
    "title": "Insurance sales agents",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "624",
        "women": "51.8",
        "white": "81.3",
        "black": "10.0",
        "asian": "5.9",
        "hispanicOrLatino": "11.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "440",
        "median": "897",
        "maleWorkers": "198",
        "maleMedian": "1,058",
        "femaleWorkers": "242",
        "femaleMedian": "795"
      }
    }
  },
  "Securities, commodities, and financial services sales agents": {
    "ageData": {
      "2017": {
        "total": "262",
        "16to19": "2",
        "20to24": "10",
        "25to34": "59",
        "35to44": "65",
        "45to54": "68",
        "55to64": "43",
        "65+": "15",
        "medianAge": "43"
      }
    },
    "title": "Securities, commodities, and financial services sales agents",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "262",
        "women": "34.0",
        "white": "85.8",
        "black": "6.0",
        "asian": "5.1",
        "hispanicOrLatino": "10.3"
      }
    },
    "wageData": {
      "2017": {
        "workers": "231",
        "median": "1,332",
        "maleWorkers": "154",
        "maleMedian": "1,416",
        "femaleWorkers": "77",
        "femaleMedian": "911"
      }
    }
  },
  "Travel agents": {
    "ageData": {
      "2017": {
        "total": "89",
        "16to19": "0",
        "20to24": "6",
        "25to34": "17",
        "35to44": "16",
        "45to54": "15",
        "55to64": "17",
        "65+": "17",
        "medianAge": "17"
      }
    },
    "title": "Travel agents",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "89",
        "women": "82.7",
        "white": "81.9",
        "black": "11.2",
        "asian": "6.4",
        "hispanicOrLatino": "13.0"
      }
    },
    "wageData": {
      "2017": {
        "workers": "57",
        "median": "665",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "47",
        "femaleMedian": "-"
      }
    }
  },
  "Sales representatives, services, all other": {
    "ageData": {
      "2017": {
        "total": "525",
        "16to19": "4",
        "20to24": "52",
        "25to34": "148",
        "35to44": "108",
        "45to54": "102",
        "55to64": "87",
        "65+": "23",
        "medianAge": "87"
      }
    },
    "title": "Sales representatives, services, all other",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "525",
        "women": "27.1",
        "white": "88.5",
        "black": "6.4",
        "asian": "3.2",
        "hispanicOrLatino": "12.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "458",
        "median": "1,147",
        "maleWorkers": "339",
        "maleMedian": "1,332",
        "femaleWorkers": "120",
        "femaleMedian": "902"
      }
    }
  },
  "Sales representatives, wholesale and manufacturing": {
    "ageData": {
      "2017": {
        "total": "1,264",
        "16to19": "11",
        "20to24": "76",
        "25to34": "265",
        "35to44": "257",
        "45to54": "300",
        "55to64": "271",
        "65+": "84",
        "medianAge": "271"
      }
    },
    "title": "Sales representatives, wholesale and manufacturing",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "1,264",
        "women": "28.0",
        "white": "89.9",
        "black": "4.6",
        "asian": "3.7",
        "hispanicOrLatino": "10.1"
      }
    },
    "wageData": {
      "2017": {
        "workers": "1,105",
        "median": "1,157",
        "maleWorkers": "796",
        "maleMedian": "1,222",
        "femaleWorkers": "308",
        "femaleMedian": "956"
      }
    }
  },
  "Models, demonstrators, and product promoters": {
    "ageData": {
      "2017": {
        "total": "61",
        "16to19": "2",
        "20to24": "9",
        "25to34": "8",
        "35to44": "9",
        "45to54": "10",
        "55to64": "11",
        "65+": "12",
        "medianAge": "11"
      }
    },
    "title": "Models, demonstrators, and product promoters",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "61",
        "women": "78.4",
        "white": "82.4",
        "black": "6.0",
        "asian": "8.7",
        "hispanicOrLatino": "12.8"
      }
    },
    "wageData": {
      "2017": {
        "workers": "16",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Real estate brokers and sales agents": {
    "ageData": {
      "2017": {
        "total": "997",
        "16to19": "4",
        "20to24": "30",
        "25to34": "145",
        "35to44": "194",
        "45to54": "240",
        "55to64": "216",
        "65+": "169",
        "medianAge": "216"
      }
    },
    "title": "Real estate brokers and sales agents",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "997",
        "women": "57.1",
        "white": "85.7",
        "black": "7.2",
        "asian": "4.8",
        "hispanicOrLatino": "11.5"
      }
    },
    "wageData": {
      "2017": {
        "workers": "488",
        "median": "953",
        "maleWorkers": "219",
        "maleMedian": "1,159",
        "femaleWorkers": "269",
        "femaleMedian": "818"
      }
    }
  },
  "Sales engineers": {
    "ageData": {
      "2017": {
        "total": "35",
        "16to19": "0",
        "20to24": "1",
        "25to34": "7",
        "35to44": "6",
        "45to54": "13",
        "55to64": "6",
        "65+": "2",
        "medianAge": "6"
      }
    },
    "title": "Sales engineers",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "35",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "39",
        "median": "-",
        "maleWorkers": "36",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Telemarketers": {
    "ageData": {
      "2017": {
        "total": "58",
        "16to19": "3",
        "20to24": "9",
        "25to34": "20",
        "35to44": "9",
        "45to54": "8",
        "55to64": "6",
        "65+": "3",
        "medianAge": "6"
      }
    },
    "title": "Telemarketers",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "58",
        "women": "69.4",
        "white": "61.3",
        "black": "24.7",
        "asian": "7.0",
        "hispanicOrLatino": "25.8"
      }
    },
    "wageData": {
      "2017": {
        "workers": "37",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "27",
        "femaleMedian": "-"
      }
    }
  },
  "Door-to-door sales workers, news and street vendors, and related workers": {
    "ageData": {
      "2017": {
        "total": "166",
        "16to19": "4",
        "20to24": "11",
        "25to34": "35",
        "35to44": "30",
        "45to54": "43",
        "55to64": "26",
        "65+": "18",
        "medianAge": "26"
      }
    },
    "title": "Door-to-door sales workers, news and street vendors, and related workers",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "166",
        "women": "58.4",
        "white": "82.9",
        "black": "11.0",
        "asian": "5.4",
        "hispanicOrLatino": "22.0"
      }
    },
    "wageData": {
      "2017": {
        "workers": "41",
        "median": "-",
        "maleWorkers": "27",
        "maleMedian": "-",
        "femaleWorkers": "15",
        "femaleMedian": "-"
      }
    }
  },
  "Sales and related workers, all other": {
    "ageData": {
      "2017": {
        "total": "276",
        "16to19": "4",
        "20to24": "34",
        "25to34": "58",
        "35to44": "56",
        "45to54": "58",
        "55to64": "48",
        "65+": "18",
        "medianAge": "48"
      }
    },
    "title": "Sales and related workers, all other",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "276",
        "women": "50.3",
        "white": "84.2",
        "black": "9.5",
        "asian": "4.9",
        "hispanicOrLatino": "8.1"
      }
    },
    "wageData": {
      "2017": {
        "workers": "195",
        "median": "971",
        "maleWorkers": "107",
        "maleMedian": "1,147",
        "femaleWorkers": "89",
        "femaleMedian": "799"
      }
    }
  },
  "Office and administrative support occupations": {
    "ageData": {
      "2017": {
        "total": "17,751",
        "16to19": "548",
        "20to24": "1,997",
        "25to34": "3,895",
        "35to44": "3,251",
        "45to54": "3,703",
        "55to64": "3,254",
        "65+": "1,104",
        "medianAge": "3,254"
      }
    },
    "title": "Office and administrative support occupations",
    "depth": 2,
    "parent": "Sales and office occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "17,751",
        "women": "72.2",
        "white": "77.3",
        "black": "14.6",
        "asian": "4.5",
        "hispanicOrLatino": "15.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "13,733",
        "median": "701",
        "maleWorkers": "3,884",
        "maleMedian": "735",
        "femaleWorkers": "9,850",
        "femaleMedian": "691"
      }
    }
  },
  "First-line supervisors of office and administrative support workers": {
    "ageData": {
      "2017": {
        "total": "1,425",
        "16to19": "3",
        "20to24": "58",
        "25to34": "300",
        "35to44": "326",
        "45to54": "378",
        "55to64": "279",
        "65+": "80",
        "medianAge": "279"
      }
    },
    "title": "First-line supervisors of office and administrative support workers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "1,425",
        "women": "70.1",
        "white": "80.3",
        "black": "13.4",
        "asian": "4.0",
        "hispanicOrLatino": "11.6"
      }
    },
    "wageData": {
      "2017": {
        "workers": "1,261",
        "median": "867",
        "maleWorkers": "408",
        "maleMedian": "987",
        "femaleWorkers": "854",
        "femaleMedian": "819"
      }
    }
  },
  "Switchboard operators, including answering service": {
    "ageData": {
      "2017": {
        "total": "23",
        "16to19": "1",
        "20to24": "0",
        "25to34": "6",
        "35to44": "5",
        "45to54": "3",
        "55to64": "2",
        "65+": "5",
        "medianAge": "2"
      }
    },
    "title": "Switchboard operators, including answering service",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "23",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "16",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Telephone operators": {
    "ageData": {
      "2017": {
        "total": "47",
        "16to19": "0",
        "20to24": "12",
        "25to34": "11",
        "35to44": "9",
        "45to54": "7",
        "55to64": "5",
        "65+": "3",
        "medianAge": "5"
      }
    },
    "title": "Telephone operators",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "47",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "35",
        "median": "-",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "27",
        "femaleMedian": "-"
      }
    }
  },
  "Communications equipment operators, all other": {
    "ageData": {
      "2017": {
        "total": "9",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "1",
        "45to54": "4",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Communications equipment operators, all other",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "9",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Bill and account collectors": {
    "ageData": {
      "2017": {
        "total": "139",
        "16to19": "3",
        "20to24": "19",
        "25to34": "35",
        "35to44": "33",
        "45to54": "25",
        "55to64": "15",
        "65+": "10",
        "medianAge": "15"
      }
    },
    "title": "Bill and account collectors",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "139",
        "women": "71.3",
        "white": "70.7",
        "black": "22.9",
        "asian": "3.1",
        "hispanicOrLatino": "18.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "130",
        "median": "674",
        "maleWorkers": "38",
        "maleMedian": "-",
        "femaleWorkers": "92",
        "femaleMedian": "655"
      }
    }
  },
  "Billing and posting clerks": {
    "ageData": {
      "2017": {
        "total": "473",
        "16to19": "4",
        "20to24": "31",
        "25to34": "110",
        "35to44": "102",
        "45to54": "115",
        "55to64": "92",
        "65+": "19",
        "medianAge": "92"
      }
    },
    "title": "Billing and posting clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "473",
        "women": "85.9",
        "white": "79.4",
        "black": "13.6",
        "asian": "3.8",
        "hispanicOrLatino": "13.1"
      }
    },
    "wageData": {
      "2017": {
        "workers": "405",
        "median": "696",
        "maleWorkers": "58",
        "maleMedian": "718",
        "femaleWorkers": "347",
        "femaleMedian": "691"
      }
    }
  },
  "Bookkeeping, accounting, and auditing clerks": {
    "ageData": {
      "2017": {
        "total": "1,089",
        "16to19": "5",
        "20to24": "59",
        "25to34": "146",
        "35to44": "204",
        "45to54": "273",
        "55to64": "268",
        "65+": "135",
        "medianAge": "268"
      }
    },
    "title": "Bookkeeping, accounting, and auditing clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "1,089",
        "women": "88.7",
        "white": "87.4",
        "black": "6.9",
        "asian": "3.7",
        "hispanicOrLatino": "11.3"
      }
    },
    "wageData": {
      "2017": {
        "workers": "718",
        "median": "719",
        "maleWorkers": "92",
        "maleMedian": "743",
        "femaleWorkers": "625",
        "femaleMedian": "716"
      }
    }
  },
  "Gaming cage workers": {
    "ageData": {
      "2017": {
        "total": "14",
        "16to19": "0",
        "20to24": "1",
        "25to34": "3",
        "35to44": "4",
        "45to54": "6",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Gaming cage workers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "14",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "11",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "Payroll and timekeeping clerks": {
    "ageData": {
      "2017": {
        "total": "129",
        "16to19": "0",
        "20to24": "6",
        "25to34": "25",
        "35to44": "27",
        "45to54": "31",
        "55to64": "31",
        "65+": "9",
        "medianAge": "31"
      }
    },
    "title": "Payroll and timekeeping clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "129",
        "women": "89.5",
        "white": "75.6",
        "black": "14.3",
        "asian": "4.2",
        "hispanicOrLatino": "17.7"
      }
    },
    "wageData": {
      "2017": {
        "workers": "103",
        "median": "765",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "89",
        "femaleMedian": "767"
      }
    }
  },
  "Procurement clerks": {
    "ageData": {
      "2017": {
        "total": "30",
        "16to19": "0",
        "20to24": "2",
        "25to34": "7",
        "35to44": "6",
        "45to54": "4",
        "55to64": "9",
        "65+": "2",
        "medianAge": "9"
      }
    },
    "title": "Procurement clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "30",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "29",
        "median": "-",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "15",
        "femaleMedian": "-"
      }
    }
  },
  "Tellers": {
    "ageData": {
      "2017": {
        "total": "306",
        "16to19": "13",
        "20to24": "73",
        "25to34": "96",
        "35to44": "41",
        "45to54": "30",
        "55to64": "41",
        "65+": "13",
        "medianAge": "41"
      }
    },
    "title": "Tellers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "306",
        "women": "85.2",
        "white": "79.9",
        "black": "12.6",
        "asian": "5.0",
        "hispanicOrLatino": "16.4"
      }
    },
    "wageData": {
      "2017": {
        "workers": "209",
        "median": "572",
        "maleWorkers": "25",
        "maleMedian": "-",
        "femaleWorkers": "184",
        "femaleMedian": "562"
      }
    }
  },
  "Financial clerks, all other": {
    "ageData": {
      "2017": {
        "total": "82",
        "16to19": "0",
        "20to24": "7",
        "25to34": "18",
        "35to44": "24",
        "45to54": "17",
        "55to64": "12",
        "65+": "4",
        "medianAge": "12"
      }
    },
    "title": "Financial clerks, all other",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "82",
        "women": "60.8",
        "white": "78.3",
        "black": "16.0",
        "asian": "3.9",
        "hispanicOrLatino": "17.8"
      }
    },
    "wageData": {
      "2017": {
        "workers": "74",
        "median": "902",
        "maleWorkers": "26",
        "maleMedian": "-",
        "femaleWorkers": "48",
        "femaleMedian": "-"
      }
    }
  },
  "Brokerage clerks": {
    "ageData": {
      "2017": {
        "total": "7",
        "16to19": "0",
        "20to24": "2",
        "25to34": "0",
        "35to44": "1",
        "45to54": "0",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Brokerage clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Correspondence clerks": {
    "ageData": {
      "2017": {
        "total": "7",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "3",
        "45to54": "0",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Correspondence clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Court, municipal, and license clerks": {
    "ageData": {
      "2017": {
        "total": "90",
        "16to19": "1",
        "20to24": "1",
        "25to34": "13",
        "35to44": "20",
        "45to54": "24",
        "55to64": "25",
        "65+": "5",
        "medianAge": "25"
      }
    },
    "title": "Court, municipal, and license clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "90",
        "women": "75.2",
        "white": "75.8",
        "black": "15.6",
        "asian": "2.0",
        "hispanicOrLatino": "16.6"
      }
    },
    "wageData": {
      "2017": {
        "workers": "79",
        "median": "738",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "62",
        "femaleMedian": "718"
      }
    }
  },
  "Credit authorizers, checkers, and clerks": {
    "ageData": {
      "2017": {
        "total": "55",
        "16to19": "1",
        "20to24": "6",
        "25to34": "14",
        "35to44": "10",
        "45to54": "13",
        "55to64": "8",
        "65+": "2",
        "medianAge": "8"
      }
    },
    "title": "Credit authorizers, checkers, and clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "55",
        "women": "63.8",
        "white": "90.5",
        "black": "3.2",
        "asian": "5.7",
        "hispanicOrLatino": "17.3"
      }
    },
    "wageData": {
      "2017": {
        "workers": "50",
        "median": "807",
        "maleWorkers": "20",
        "maleMedian": "-",
        "femaleWorkers": "31",
        "femaleMedian": "-"
      }
    }
  },
  "Customer service representatives": {
    "ageData": {
      "2017": {
        "total": "2,494",
        "16to19": "163",
        "20to24": "401",
        "25to34": "667",
        "35to44": "413",
        "45to54": "423",
        "55to64": "319",
        "65+": "108",
        "medianAge": "319"
      }
    },
    "title": "Customer service representatives",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "2,494",
        "women": "65.1",
        "white": "73.2",
        "black": "17.2",
        "asian": "5.4",
        "hispanicOrLatino": "17.4"
      }
    },
    "wageData": {
      "2017": {
        "workers": "1,881",
        "median": "654",
        "maleWorkers": "646",
        "maleMedian": "712",
        "femaleWorkers": "1,234",
        "femaleMedian": "637"
      }
    }
  },
  "Eligibility interviewers, government programs": {
    "ageData": {
      "2017": {
        "total": "62",
        "16to19": "0",
        "20to24": "2",
        "25to34": "13",
        "35to44": "15",
        "45to54": "19",
        "55to64": "13",
        "65+": "1",
        "medianAge": "13"
      }
    },
    "title": "Eligibility interviewers, government programs",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "62",
        "women": "76.7",
        "white": "58.8",
        "black": "26.8",
        "asian": "10.0",
        "hispanicOrLatino": "21.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "66",
        "median": "849",
        "maleWorkers": "16",
        "maleMedian": "-",
        "femaleWorkers": "50",
        "femaleMedian": "779"
      }
    }
  },
  "File clerks": {
    "ageData": {
      "2017": {
        "total": "182",
        "16to19": "16",
        "20to24": "21",
        "25to34": "34",
        "35to44": "34",
        "45to54": "30",
        "55to64": "34",
        "65+": "13",
        "medianAge": "34"
      }
    },
    "title": "File clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "182",
        "women": "81.4",
        "white": "77.2",
        "black": "13.6",
        "asian": "6.9",
        "hispanicOrLatino": "17.8"
      }
    },
    "wageData": {
      "2017": {
        "workers": "135",
        "median": "704",
        "maleWorkers": "26",
        "maleMedian": "-",
        "femaleWorkers": "109",
        "femaleMedian": "706"
      }
    }
  },
  "Hotel, motel, and resort desk clerks": {
    "ageData": {
      "2017": {
        "total": "125",
        "16to19": "8",
        "20to24": "29",
        "25to34": "36",
        "35to44": "20",
        "45to54": "12",
        "55to64": "13",
        "65+": "6",
        "medianAge": "13"
      }
    },
    "title": "Hotel, motel, and resort desk clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "125",
        "women": "60.9",
        "white": "64.8",
        "black": "21.5",
        "asian": "7.8",
        "hispanicOrLatino": "15.7"
      }
    },
    "wageData": {
      "2017": {
        "workers": "84",
        "median": "486",
        "maleWorkers": "35",
        "maleMedian": "-",
        "femaleWorkers": "49",
        "femaleMedian": "-"
      }
    }
  },
  "Interviewers, except eligibility and loan": {
    "ageData": {
      "2017": {
        "total": "142",
        "16to19": "1",
        "20to24": "21",
        "25to34": "31",
        "35to44": "30",
        "45to54": "23",
        "55to64": "26",
        "65+": "10",
        "medianAge": "26"
      }
    },
    "title": "Interviewers, except eligibility and loan",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "142",
        "women": "85.0",
        "white": "71.9",
        "black": "18.0",
        "asian": "2.8",
        "hispanicOrLatino": "21.2"
      }
    },
    "wageData": {
      "2017": {
        "workers": "106",
        "median": "671",
        "maleWorkers": "16",
        "maleMedian": "-",
        "femaleWorkers": "89",
        "femaleMedian": "683"
      }
    }
  },
  "Library assistants, clerical": {
    "ageData": {
      "2017": {
        "total": "96",
        "16to19": "5",
        "20to24": "15",
        "25to34": "14",
        "35to44": "15",
        "45to54": "13",
        "55to64": "22",
        "65+": "12",
        "medianAge": "22"
      }
    },
    "title": "Library assistants, clerical",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "96",
        "women": "80.1",
        "white": "81.3",
        "black": "8.6",
        "asian": "5.1",
        "hispanicOrLatino": "10.4"
      }
    },
    "wageData": {
      "2017": {
        "workers": "45",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "37",
        "femaleMedian": "-"
      }
    }
  },
  "Loan interviewers and clerks": {
    "ageData": {
      "2017": {
        "total": "120",
        "16to19": "0",
        "20to24": "7",
        "25to34": "34",
        "35to44": "26",
        "45to54": "22",
        "55to64": "28",
        "65+": "3",
        "medianAge": "28"
      }
    },
    "title": "Loan interviewers and clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "120",
        "women": "80.4",
        "white": "77.6",
        "black": "15.3",
        "asian": "5.1",
        "hispanicOrLatino": "14.6"
      }
    },
    "wageData": {
      "2017": {
        "workers": "114",
        "median": "742",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "95",
        "femaleMedian": "725"
      }
    }
  },
  "New accounts clerks": {
    "ageData": {
      "2017": {
        "total": "32",
        "16to19": "0",
        "20to24": "2",
        "25to34": "10",
        "35to44": "10",
        "45to54": "2",
        "55to64": "7",
        "65+": "1",
        "medianAge": "7"
      }
    },
    "title": "New accounts clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "32",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "36",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "28",
        "femaleMedian": "-"
      }
    }
  },
  "Order clerks": {
    "ageData": {
      "2017": {
        "total": "107",
        "16to19": "3",
        "20to24": "12",
        "25to34": "27",
        "35to44": "24",
        "45to54": "23",
        "55to64": "15",
        "65+": "4",
        "medianAge": "15"
      }
    },
    "title": "Order clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "107",
        "women": "54.9",
        "white": "66.2",
        "black": "18.8",
        "asian": "8.0",
        "hispanicOrLatino": "14.4"
      }
    },
    "wageData": {
      "2017": {
        "workers": "80",
        "median": "629",
        "maleWorkers": "38",
        "maleMedian": "-",
        "femaleWorkers": "42",
        "femaleMedian": "-"
      }
    }
  },
  "Human resources assistants, except payroll and timekeeping": {
    "ageData": {
      "2017": {
        "total": "45",
        "16to19": "1",
        "20to24": "2",
        "25to34": "14",
        "35to44": "13",
        "45to54": "12",
        "55to64": "4",
        "65+": "1",
        "medianAge": "4"
      }
    },
    "title": "Human resources assistants, except payroll and timekeeping",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "45",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "45",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "37",
        "femaleMedian": "-"
      }
    }
  },
  "Receptionists and information clerks": {
    "ageData": {
      "2017": {
        "total": "1,267",
        "16to19": "60",
        "20to24": "224",
        "25to34": "313",
        "35to44": "185",
        "45to54": "196",
        "55to64": "199",
        "65+": "91",
        "medianAge": "199"
      }
    },
    "title": "Receptionists and information clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "1,267",
        "women": "91.0",
        "white": "79.9",
        "black": "12.8",
        "asian": "3.5",
        "hispanicOrLatino": "19.7"
      }
    },
    "wageData": {
      "2017": {
        "workers": "892",
        "median": "602",
        "maleWorkers": "66",
        "maleMedian": "652",
        "femaleWorkers": "826",
        "femaleMedian": "599"
      }
    }
  },
  "Reservation and transportation ticket agents and travel clerks": {
    "ageData": {
      "2017": {
        "total": "127",
        "16to19": "1",
        "20to24": "15",
        "25to34": "29",
        "35to44": "23",
        "45to54": "30",
        "55to64": "23",
        "65+": "6",
        "medianAge": "23"
      }
    },
    "title": "Reservation and transportation ticket agents and travel clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "127",
        "women": "58.0",
        "white": "60.8",
        "black": "25.3",
        "asian": "5.9",
        "hispanicOrLatino": "15.1"
      }
    },
    "wageData": {
      "2017": {
        "workers": "99",
        "median": "739",
        "maleWorkers": "43",
        "maleMedian": "-",
        "femaleWorkers": "56",
        "femaleMedian": "691"
      }
    }
  },
  "Information and record clerks, all other": {
    "ageData": {
      "2017": {
        "total": "110",
        "16to19": "2",
        "20to24": "13",
        "25to34": "27",
        "35to44": "21",
        "45to54": "18",
        "55to64": "23",
        "65+": "6",
        "medianAge": "23"
      }
    },
    "title": "Information and record clerks, all other",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "110",
        "women": "78.1",
        "white": "75.5",
        "black": "10.1",
        "asian": "5.2",
        "hispanicOrLatino": "15.0"
      }
    },
    "wageData": {
      "2017": {
        "workers": "92",
        "median": "691",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "74",
        "femaleMedian": "683"
      }
    }
  },
  "Cargo and freight agents": {
    "ageData": {
      "2017": {
        "total": "16",
        "16to19": "0",
        "20to24": "1",
        "25to34": "6",
        "35to44": "5",
        "45to54": "2",
        "55to64": "1",
        "65+": "1",
        "medianAge": "1"
      }
    },
    "title": "Cargo and freight agents",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "16",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "14",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Couriers and messengers": {
    "ageData": {
      "2017": {
        "total": "239",
        "16to19": "4",
        "20to24": "19",
        "25to34": "60",
        "35to44": "38",
        "45to54": "62",
        "55to64": "37",
        "65+": "19",
        "medianAge": "37"
      }
    },
    "title": "Couriers and messengers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "239",
        "women": "19.4",
        "white": "67.3",
        "black": "25.5",
        "asian": "5.5",
        "hispanicOrLatino": "18.1"
      }
    },
    "wageData": {
      "2017": {
        "workers": "155",
        "median": "725",
        "maleWorkers": "131",
        "maleMedian": "751",
        "femaleWorkers": "24",
        "femaleMedian": "-"
      }
    }
  },
  "Dispatchers": {
    "ageData": {
      "2017": {
        "total": "292",
        "16to19": "2",
        "20to24": "29",
        "25to34": "80",
        "35to44": "67",
        "45to54": "62",
        "55to64": "41",
        "65+": "10",
        "medianAge": "41"
      }
    },
    "title": "Dispatchers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "292",
        "women": "57.4",
        "white": "81.7",
        "black": "11.6",
        "asian": "2.0",
        "hispanicOrLatino": "16.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "255",
        "median": "710",
        "maleWorkers": "110",
        "maleMedian": "753",
        "femaleWorkers": "145",
        "femaleMedian": "691"
      }
    }
  },
  "Meter readers, utilities": {
    "ageData": {
      "2017": {
        "total": "18",
        "16to19": "1",
        "20to24": "1",
        "25to34": "5",
        "35to44": "3",
        "45to54": "2",
        "55to64": "4",
        "65+": "2",
        "medianAge": "4"
      }
    },
    "title": "Meter readers, utilities",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "18",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "14",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Postal service clerks": {
    "ageData": {
      "2017": {
        "total": "111",
        "16to19": "0",
        "20to24": "2",
        "25to34": "17",
        "35to44": "18",
        "45to54": "31",
        "55to64": "40",
        "65+": "3",
        "medianAge": "40"
      }
    },
    "title": "Postal service clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "111",
        "women": "55.3",
        "white": "70.8",
        "black": "22.4",
        "asian": "4.8",
        "hispanicOrLatino": "15.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "104",
        "median": "940",
        "maleWorkers": "48",
        "maleMedian": "-",
        "femaleWorkers": "56",
        "femaleMedian": "925"
      }
    }
  },
  "Postal service mail carriers": {
    "ageData": {
      "2017": {
        "total": "306",
        "16to19": "0",
        "20to24": "18",
        "25to34": "67",
        "35to44": "52",
        "45to54": "83",
        "55to64": "75",
        "65+": "11",
        "medianAge": "75"
      }
    },
    "title": "Postal service mail carriers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "306",
        "women": "40.8",
        "white": "67.4",
        "black": "22.2",
        "asian": "5.6",
        "hispanicOrLatino": "14.4"
      }
    },
    "wageData": {
      "2017": {
        "workers": "266",
        "median": "944",
        "maleWorkers": "158",
        "maleMedian": "965",
        "femaleWorkers": "108",
        "femaleMedian": "856"
      }
    }
  },
  "Postal service mail sorters, processors, and processing machine operators": {
    "ageData": {
      "2017": {
        "total": "50",
        "16to19": "0",
        "20to24": "6",
        "25to34": "8",
        "35to44": "10",
        "45to54": "13",
        "55to64": "11",
        "65+": "3",
        "medianAge": "11"
      }
    },
    "title": "Postal service mail sorters, processors, and processing machine operators",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "50",
        "women": "56.7",
        "white": "51.0",
        "black": "38.4",
        "asian": "7.9",
        "hispanicOrLatino": "3.8"
      }
    },
    "wageData": {
      "2017": {
        "workers": "49",
        "median": "-",
        "maleWorkers": "26",
        "maleMedian": "-",
        "femaleWorkers": "23",
        "femaleMedian": "-"
      }
    }
  },
  "Production, planning, and expediting clerks": {
    "ageData": {
      "2017": {
        "total": "270",
        "16to19": "2",
        "20to24": "23",
        "25to34": "64",
        "35to44": "56",
        "45to54": "76",
        "55to64": "37",
        "65+": "13",
        "medianAge": "37"
      }
    },
    "title": "Production, planning, and expediting clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "270",
        "women": "53.9",
        "white": "81.6",
        "black": "7.8",
        "asian": "6.7",
        "hispanicOrLatino": "9.7"
      }
    },
    "wageData": {
      "2017": {
        "workers": "238",
        "median": "911",
        "maleWorkers": "118",
        "maleMedian": "1,043",
        "femaleWorkers": "120",
        "femaleMedian": "798"
      }
    }
  },
  "Shipping, receiving, and traffic clerks": {
    "ageData": {
      "2017": {
        "total": "623",
        "16to19": "9",
        "20to24": "79",
        "25to34": "147",
        "35to44": "141",
        "45to54": "113",
        "55to64": "114",
        "65+": "20",
        "medianAge": "114"
      }
    },
    "title": "Shipping, receiving, and traffic clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "623",
        "women": "31.3",
        "white": "72.2",
        "black": "20.4",
        "asian": "3.7",
        "hispanicOrLatino": "27.0"
      }
    },
    "wageData": {
      "2017": {
        "workers": "544",
        "median": "611",
        "maleWorkers": "379",
        "maleMedian": "616",
        "femaleWorkers": "165",
        "femaleMedian": "596"
      }
    }
  },
  "Stock clerks and order fillers": {
    "ageData": {
      "2017": {
        "total": "1,525",
        "16to19": "160",
        "20to24": "328",
        "25to34": "346",
        "35to44": "212",
        "45to54": "227",
        "55to64": "181",
        "65+": "72",
        "medianAge": "181"
      }
    },
    "title": "Stock clerks and order fillers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "1,525",
        "women": "37.9",
        "white": "74.0",
        "black": "18.4",
        "asian": "4.2",
        "hispanicOrLatino": "20.6"
      }
    },
    "wageData": {
      "2017": {
        "workers": "982",
        "median": "559",
        "maleWorkers": "643",
        "maleMedian": "571",
        "femaleWorkers": "339",
        "femaleMedian": "538"
      }
    }
  },
  "Weighers, measurers, checkers, and samplers, recordkeeping": {
    "ageData": {
      "2017": {
        "total": "52",
        "16to19": "3",
        "20to24": "4",
        "25to34": "13",
        "35to44": "12",
        "45to54": "6",
        "55to64": "10",
        "65+": "4",
        "medianAge": "10"
      }
    },
    "title": "Weighers, measurers, checkers, and samplers, recordkeeping",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "52",
        "women": "46.8",
        "white": "85.9",
        "black": "7.4",
        "asian": "3.5",
        "hispanicOrLatino": "29.0"
      }
    },
    "wageData": {
      "2017": {
        "workers": "43",
        "median": "-",
        "maleWorkers": "22",
        "maleMedian": "-",
        "femaleWorkers": "21",
        "femaleMedian": "-"
      }
    }
  },
  "Secretaries and administrative assistants": {
    "ageData": {
      "2017": {
        "total": "2,769",
        "16to19": "15",
        "20to24": "161",
        "25to34": "478",
        "35to44": "497",
        "45to54": "712",
        "55to64": "685",
        "65+": "220",
        "medianAge": "685"
      }
    },
    "title": "Secretaries and administrative assistants",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "2,769",
        "women": "95.0",
        "white": "83.7",
        "black": "9.3",
        "asian": "3.6",
        "hispanicOrLatino": "12.4"
      }
    },
    "wageData": {
      "2017": {
        "workers": "2,165",
        "median": "739",
        "maleWorkers": "119",
        "maleMedian": "852",
        "femaleWorkers": "2,046",
        "femaleMedian": "735"
      }
    }
  },
  "Computer operators": {
    "ageData": {
      "2017": {
        "total": "73",
        "16to19": "1",
        "20to24": "4",
        "25to34": "14",
        "35to44": "12",
        "45to54": "14",
        "55to64": "20",
        "65+": "9",
        "medianAge": "20"
      }
    },
    "title": "Computer operators",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "73",
        "women": "60.2",
        "white": "74.8",
        "black": "14.0",
        "asian": "6.3",
        "hispanicOrLatino": "7.4"
      }
    },
    "wageData": {
      "2017": {
        "workers": "63",
        "median": "809",
        "maleWorkers": "27",
        "maleMedian": "-",
        "femaleWorkers": "35",
        "femaleMedian": "-"
      }
    }
  },
  "Data entry keyers": {
    "ageData": {
      "2017": {
        "total": "267",
        "16to19": "5",
        "20to24": "37",
        "25to34": "68",
        "35to44": "46",
        "45to54": "47",
        "55to64": "52",
        "65+": "12",
        "medianAge": "52"
      }
    },
    "title": "Data entry keyers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "267",
        "women": "79.6",
        "white": "72.4",
        "black": "16.5",
        "asian": "8.5",
        "hispanicOrLatino": "15.2"
      }
    },
    "wageData": {
      "2017": {
        "workers": "224",
        "median": "650",
        "maleWorkers": "48",
        "maleMedian": "-",
        "femaleWorkers": "176",
        "femaleMedian": "640"
      }
    }
  },
  "Word processors and typists": {
    "ageData": {
      "2017": {
        "total": "94",
        "16to19": "1",
        "20to24": "8",
        "25to34": "16",
        "35to44": "18",
        "45to54": "14",
        "55to64": "28",
        "65+": "8",
        "medianAge": "28"
      }
    },
    "title": "Word processors and typists",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "94",
        "women": "89.8",
        "white": "69.7",
        "black": "21.1",
        "asian": "6.8",
        "hispanicOrLatino": "13.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "80",
        "median": "683",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "72",
        "femaleMedian": "667"
      }
    }
  },
  "Desktop publishers": {
    "ageData": {
      "2017": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Desktop publishers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Insurance claims and policy processing clerks": {
    "ageData": {
      "2017": {
        "total": "237",
        "16to19": "0",
        "20to24": "19",
        "25to34": "63",
        "35to44": "54",
        "45to54": "52",
        "55to64": "39",
        "65+": "9",
        "medianAge": "39"
      }
    },
    "title": "Insurance claims and policy processing clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "237",
        "women": "84.3",
        "white": "70.8",
        "black": "20.3",
        "asian": "4.2",
        "hispanicOrLatino": "14.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "223",
        "median": "714",
        "maleWorkers": "34",
        "maleMedian": "-",
        "femaleWorkers": "189",
        "femaleMedian": "711"
      }
    }
  },
  "Mail clerks and mail machine operators, except postal service": {
    "ageData": {
      "2017": {
        "total": "61",
        "16to19": "4",
        "20to24": "7",
        "25to34": "9",
        "35to44": "11",
        "45to54": "17",
        "55to64": "10",
        "65+": "4",
        "medianAge": "10"
      }
    },
    "title": "Mail clerks and mail machine operators, except postal service",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "61",
        "women": "36.8",
        "white": "67.4",
        "black": "24.4",
        "asian": "3.0",
        "hispanicOrLatino": "11.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "46",
        "median": "-",
        "maleWorkers": "30",
        "maleMedian": "-",
        "femaleWorkers": "16",
        "femaleMedian": "-"
      }
    }
  },
  "Office clerks, general": {
    "ageData": {
      "2017": {
        "total": "1,271",
        "16to19": "42",
        "20to24": "152",
        "25to34": "249",
        "35to44": "221",
        "45to54": "259",
        "55to64": "253",
        "65+": "95",
        "medianAge": "253"
      }
    },
    "title": "Office clerks, general",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "1,271",
        "women": "83.1",
        "white": "75.1",
        "black": "14.8",
        "asian": "6.3",
        "hispanicOrLatino": "18.4"
      }
    },
    "wageData": {
      "2017": {
        "workers": "935",
        "median": "682",
        "maleWorkers": "142",
        "maleMedian": "780",
        "femaleWorkers": "793",
        "femaleMedian": "670"
      }
    }
  },
  "Office machine operators, except computer": {
    "ageData": {
      "2017": {
        "total": "33",
        "16to19": "3",
        "20to24": "4",
        "25to34": "4",
        "35to44": "5",
        "45to54": "7",
        "55to64": "5",
        "65+": "4",
        "medianAge": "5"
      }
    },
    "title": "Office machine operators, except computer",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "33",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "27",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "17",
        "femaleMedian": "-"
      }
    }
  },
  "Proofreaders and copy markers": {
    "ageData": {
      "2017": {
        "total": "10",
        "16to19": "0",
        "20to24": "1",
        "25to34": "2",
        "35to44": "0",
        "45to54": "4",
        "55to64": "2",
        "65+": "2",
        "medianAge": "2"
      }
    },
    "title": "Proofreaders and copy markers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "10",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Statistical assistants": {
    "ageData": {
      "2017": {
        "total": "21",
        "16to19": "0",
        "20to24": "1",
        "25to34": "5",
        "35to44": "4",
        "45to54": "5",
        "55to64": "4",
        "65+": "1",
        "medianAge": "4"
      }
    },
    "title": "Statistical assistants",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "21",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "17",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Office and administrative support workers, all other": {
    "ageData": {
      "2017": {
        "total": "576",
        "16to19": "3",
        "20to24": "51",
        "25to34": "138",
        "35to44": "121",
        "45to54": "142",
        "55to64": "88",
        "65+": "33",
        "medianAge": "88"
      }
    },
    "title": "Office and administrative support workers, all other",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "576",
        "women": "75.2",
        "white": "77.6",
        "black": "15.1",
        "asian": "3.7",
        "hispanicOrLatino": "12.5"
      }
    },
    "wageData": {
      "2017": {
        "workers": "473",
        "median": "758",
        "maleWorkers": "122",
        "maleMedian": "768",
        "femaleWorkers": "352",
        "femaleMedian": "756"
      }
    }
  },
  "Natural resources, construction, and maintenance occupations": {
    "ageData": {
      "2017": {
        "total": "14,193",
        "16to19": "337",
        "20to24": "1,231",
        "25to34": "3,336",
        "35to44": "3,322",
        "45to54": "3,152",
        "55to64": "2,274",
        "65+": "540",
        "medianAge": "2,274"
      }
    },
    "title": "Natural resources, construction, and maintenance occupations",
    "depth": 1,
    "parent": "",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "14,193",
        "women": "5.1",
        "white": "86.9",
        "black": "7.3",
        "asian": "2.2",
        "hispanicOrLatino": "30.7"
      }
    },
    "wageData": {
      "2017": {
        "workers": "11,509",
        "median": "801",
        "maleWorkers": "10,994",
        "maleMedian": "809",
        "femaleWorkers": "515",
        "femaleMedian": "579"
      }
    }
  },
  "Farming, fishing, and forestry occupations": {
    "ageData": {
      "2017": {
        "total": "1,184",
        "16to19": "80",
        "20to24": "141",
        "25to34": "263",
        "35to44": "240",
        "45to54": "227",
        "55to64": "162",
        "65+": "71",
        "medianAge": "162"
      }
    },
    "title": "Farming, fishing, and forestry occupations",
    "depth": 2,
    "parent": "Natural resources, construction, and maintenance occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "1,184",
        "women": "23.4",
        "white": "89.3",
        "black": "4.9",
        "asian": "1.7",
        "hispanicOrLatino": "44.3"
      }
    },
    "wageData": {
      "2017": {
        "workers": "962",
        "median": "539",
        "maleWorkers": "756",
        "maleMedian": "585",
        "femaleWorkers": "206",
        "femaleMedian": "471"
      }
    }
  },
  "First-line supervisors of farming, fishing, and forestry workers": {
    "ageData": {
      "2017": {
        "total": "62",
        "16to19": "0",
        "20to24": "5",
        "25to34": "8",
        "35to44": "14",
        "45to54": "16",
        "55to64": "11",
        "65+": "7",
        "medianAge": "11"
      }
    },
    "title": "First-line supervisors of farming, fishing, and forestry workers",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "62",
        "women": "8.7",
        "white": "87.2",
        "black": "6.6",
        "asian": "4.4",
        "hispanicOrLatino": "27.1"
      }
    },
    "wageData": {
      "2017": {
        "workers": "51",
        "median": "711",
        "maleWorkers": "47",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Agricultural inspectors": {
    "ageData": {
      "2017": {
        "total": "18",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "3",
        "45to54": "9",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Agricultural inspectors",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "18",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "14",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Animal breeders": {
    "ageData": {
      "2017": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Animal breeders",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Graders and sorters, agricultural products": {
    "ageData": {
      "2017": {
        "total": "110",
        "16to19": "4",
        "20to24": "11",
        "25to34": "24",
        "35to44": "25",
        "45to54": "23",
        "55to64": "17",
        "65+": "5",
        "medianAge": "17"
      }
    },
    "title": "Graders and sorters, agricultural products",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "110",
        "women": "67.1",
        "white": "80.4",
        "black": "12.4",
        "asian": "4.2",
        "hispanicOrLatino": "48.8"
      }
    },
    "wageData": {
      "2017": {
        "workers": "99",
        "median": "500",
        "maleWorkers": "32",
        "maleMedian": "-",
        "femaleWorkers": "67",
        "femaleMedian": "487"
      }
    }
  },
  "Miscellaneous agricultural workers": {
    "ageData": {
      "2017": {
        "total": "854",
        "16to19": "71",
        "20to24": "107",
        "25to34": "197",
        "35to44": "177",
        "45to54": "156",
        "55to64": "100",
        "65+": "46",
        "medianAge": "100"
      }
    },
    "title": "Miscellaneous agricultural workers",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "854",
        "women": "21.5",
        "white": "91.7",
        "black": "3.3",
        "asian": "1.3",
        "hispanicOrLatino": "51.1"
      }
    },
    "wageData": {
      "2017": {
        "workers": "701",
        "median": "517",
        "maleWorkers": "574",
        "maleMedian": "553",
        "femaleWorkers": "127",
        "femaleMedian": "453"
      }
    }
  },
  "Fishers and related fishing workers": {
    "ageData": {
      "2017": {
        "total": "44",
        "16to19": "2",
        "20to24": "5",
        "25to34": "13",
        "35to44": "7",
        "45to54": "7",
        "55to64": "7",
        "65+": "3",
        "medianAge": "7"
      }
    },
    "title": "Fishers and related fishing workers",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "44",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "21",
        "median": "-",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Hunters and trappers": {
    "ageData": {
      "2017": {
        "total": "1",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Hunters and trappers",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Forest and conservation workers": {
    "ageData": {
      "2017": {
        "total": "27",
        "16to19": "1",
        "20to24": "5",
        "25to34": "6",
        "35to44": "2",
        "45to54": "3",
        "55to64": "9",
        "65+": "2",
        "medianAge": "9"
      }
    },
    "title": "Forest and conservation workers",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "27",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "25",
        "median": "-",
        "maleWorkers": "23",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Logging workers": {
    "ageData": {
      "2017": {
        "total": "66",
        "16to19": "2",
        "20to24": "7",
        "25to34": "13",
        "35to44": "13",
        "45to54": "13",
        "55to64": "13",
        "65+": "6",
        "medianAge": "13"
      }
    },
    "title": "Logging workers",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "66",
        "women": "2.0",
        "white": "82.9",
        "black": "10.3",
        "asian": "0.0",
        "hispanicOrLatino": "9.1"
      }
    },
    "wageData": {
      "2017": {
        "workers": "47",
        "median": "-",
        "maleWorkers": "46",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Construction and extraction occupations": {
    "ageData": {
      "2017": {
        "total": "8,031",
        "16to19": "176",
        "20to24": "701",
        "25to34": "1,914",
        "35to44": "1,997",
        "45to54": "1,760",
        "55to64": "1,220",
        "65+": "263",
        "medianAge": "1,220"
      }
    },
    "title": "Construction and extraction occupations",
    "depth": 2,
    "parent": "Natural resources, construction, and maintenance occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "8,031",
        "women": "3.0",
        "white": "87.8",
        "black": "6.9",
        "asian": "1.6",
        "hispanicOrLatino": "36.0"
      }
    },
    "wageData": {
      "2017": {
        "workers": "6,147",
        "median": "796",
        "maleWorkers": "5,987",
        "maleMedian": "796",
        "femaleWorkers": "160",
        "femaleMedian": "802"
      }
    }
  },
  "First-line supervisors of construction trades and extraction workers": {
    "ageData": {
      "2017": {
        "total": "639",
        "16to19": "2",
        "20to24": "12",
        "25to34": "122",
        "35to44": "178",
        "45to54": "181",
        "55to64": "117",
        "65+": "27",
        "medianAge": "117"
      }
    },
    "title": "First-line supervisors of construction trades and extraction workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "639",
        "women": "3.0",
        "white": "89.8",
        "black": "5.4",
        "asian": "1.2",
        "hispanicOrLatino": "24.5"
      }
    },
    "wageData": {
      "2017": {
        "workers": "520",
        "median": "1,158",
        "maleWorkers": "506",
        "maleMedian": "1,166",
        "femaleWorkers": "14",
        "femaleMedian": "-"
      }
    }
  },
  "Boilermakers": {
    "ageData": {
      "2017": {
        "total": "15",
        "16to19": "0",
        "20to24": "1",
        "25to34": "2",
        "35to44": "4",
        "45to54": "5",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Boilermakers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "15",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "15",
        "median": "-",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Brickmasons, blockmasons, and stonemasons": {
    "ageData": {
      "2017": {
        "total": "166",
        "16to19": "2",
        "20to24": "13",
        "25to34": "43",
        "35to44": "39",
        "45to54": "48",
        "55to64": "20",
        "65+": "2",
        "medianAge": "20"
      }
    },
    "title": "Brickmasons, blockmasons, and stonemasons",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "166",
        "women": "0.7",
        "white": "84.6",
        "black": "7.1",
        "asian": "1.2",
        "hispanicOrLatino": "45.1"
      }
    },
    "wageData": {
      "2017": {
        "workers": "121",
        "median": "787",
        "maleWorkers": "121",
        "maleMedian": "787",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Carpenters": {
    "ageData": {
      "2017": {
        "total": "1,351",
        "16to19": "26",
        "20to24": "99",
        "25to34": "317",
        "35to44": "361",
        "45to54": "290",
        "55to64": "211",
        "65+": "47",
        "medianAge": "211"
      }
    },
    "title": "Carpenters",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "1,351",
        "women": "2.2",
        "white": "89.3",
        "black": "5.3",
        "asian": "1.7",
        "hispanicOrLatino": "35.4"
      }
    },
    "wageData": {
      "2017": {
        "workers": "870",
        "median": "789",
        "maleWorkers": "851",
        "maleMedian": "789",
        "femaleWorkers": "19",
        "femaleMedian": "-"
      }
    }
  },
  "Carpet, floor, and tile installers and finishers": {
    "ageData": {
      "2017": {
        "total": "183",
        "16to19": "2",
        "20to24": "16",
        "25to34": "45",
        "35to44": "51",
        "45to54": "38",
        "55to64": "28",
        "65+": "3",
        "medianAge": "28"
      }
    },
    "title": "Carpet, floor, and tile installers and finishers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "183",
        "women": "2.4",
        "white": "87.2",
        "black": "6.9",
        "asian": "1.3",
        "hispanicOrLatino": "47.4"
      }
    },
    "wageData": {
      "2017": {
        "workers": "115",
        "median": "631",
        "maleWorkers": "114",
        "maleMedian": "628",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Cement masons, concrete finishers, and terrazzo workers": {
    "ageData": {
      "2017": {
        "total": "54",
        "16to19": "1",
        "20to24": "8",
        "25to34": "10",
        "35to44": "17",
        "45to54": "10",
        "55to64": "8",
        "65+": "1",
        "medianAge": "8"
      }
    },
    "title": "Cement masons, concrete finishers, and terrazzo workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "54",
        "women": "1.0",
        "white": "90.4",
        "black": "8.5",
        "asian": "0.0",
        "hispanicOrLatino": "46.7"
      }
    },
    "wageData": {
      "2017": {
        "workers": "47",
        "median": "-",
        "maleWorkers": "45",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Construction laborers": {
    "ageData": {
      "2017": {
        "total": "1,946",
        "16to19": "82",
        "20to24": "216",
        "25to34": "480",
        "35to44": "446",
        "45to54": "403",
        "55to64": "256",
        "65+": "65",
        "medianAge": "256"
      }
    },
    "title": "Construction laborers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "1,946",
        "women": "3.3",
        "white": "85.9",
        "black": "8.6",
        "asian": "1.7",
        "hispanicOrLatino": "46.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "1,443",
        "median": "670",
        "maleWorkers": "1,400",
        "maleMedian": "667",
        "femaleWorkers": "43",
        "femaleMedian": "-"
      }
    }
  },
  "Paving, surfacing, and tamping equipment operators": {
    "ageData": {
      "2017": {
        "total": "15",
        "16to19": "0",
        "20to24": "4",
        "25to34": "1",
        "35to44": "3",
        "45to54": "1",
        "55to64": "5",
        "65+": "1",
        "medianAge": "5"
      }
    },
    "title": "Paving, surfacing, and tamping equipment operators",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "15",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Pile-driver operators": {
    "ageData": {
      "2017": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Pile-driver operators",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Operating engineers and other construction equipment operators": {
    "ageData": {
      "2017": {
        "total": "358",
        "16to19": "2",
        "20to24": "24",
        "25to34": "94",
        "35to44": "81",
        "45to54": "75",
        "55to64": "66",
        "65+": "14",
        "medianAge": "66"
      }
    },
    "title": "Operating engineers and other construction equipment operators",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "358",
        "women": "1.5",
        "white": "90.4",
        "black": "5.2",
        "asian": "0.8",
        "hispanicOrLatino": "19.3"
      }
    },
    "wageData": {
      "2017": {
        "workers": "324",
        "median": "868",
        "maleWorkers": "318",
        "maleMedian": "868",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Drywall installers, ceiling tile installers, and tapers": {
    "ageData": {
      "2017": {
        "total": "156",
        "16to19": "5",
        "20to24": "16",
        "25to34": "36",
        "35to44": "46",
        "45to54": "26",
        "55to64": "23",
        "65+": "5",
        "medianAge": "23"
      }
    },
    "title": "Drywall installers, ceiling tile installers, and tapers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "156",
        "women": "2.7",
        "white": "91.7",
        "black": "3.8",
        "asian": "0.6",
        "hispanicOrLatino": "63.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "114",
        "median": "634",
        "maleWorkers": "112",
        "maleMedian": "628",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Electricians": {
    "ageData": {
      "2017": {
        "total": "857",
        "16to19": "13",
        "20to24": "77",
        "25to34": "205",
        "35to44": "200",
        "45to54": "195",
        "55to64": "143",
        "65+": "24",
        "medianAge": "143"
      }
    },
    "title": "Electricians",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "857",
        "women": "2.5",
        "white": "87.9",
        "black": "6.7",
        "asian": "2.5",
        "hispanicOrLatino": "19.1"
      }
    },
    "wageData": {
      "2017": {
        "workers": "726",
        "median": "979",
        "maleWorkers": "711",
        "maleMedian": "974",
        "femaleWorkers": "15",
        "femaleMedian": "-"
      }
    }
  },
  "Glaziers": {
    "ageData": {
      "2017": {
        "total": "44",
        "16to19": "1",
        "20to24": "5",
        "25to34": "12",
        "35to44": "9",
        "45to54": "9",
        "55to64": "8",
        "65+": "2",
        "medianAge": "8"
      }
    },
    "title": "Glaziers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "44",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "39",
        "median": "-",
        "maleWorkers": "39",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Insulation workers": {
    "ageData": {
      "2017": {
        "total": "58",
        "16to19": "0",
        "20to24": "7",
        "25to34": "20",
        "35to44": "14",
        "45to54": "12",
        "55to64": "5",
        "65+": "0",
        "medianAge": "5"
      }
    },
    "title": "Insulation workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "58",
        "women": "3.1",
        "white": "82.1",
        "black": "9.6",
        "asian": "0.0",
        "hispanicOrLatino": "42.6"
      }
    },
    "wageData": {
      "2017": {
        "workers": "52",
        "median": "768",
        "maleWorkers": "49",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Painters, construction and maintenance": {
    "ageData": {
      "2017": {
        "total": "540",
        "16to19": "11",
        "20to24": "49",
        "25to34": "126",
        "35to44": "147",
        "45to54": "109",
        "55to64": "85",
        "65+": "13",
        "medianAge": "85"
      }
    },
    "title": "Painters, construction and maintenance",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "540",
        "women": "6.6",
        "white": "89.7",
        "black": "5.9",
        "asian": "1.5",
        "hispanicOrLatino": "53.4"
      }
    },
    "wageData": {
      "2017": {
        "workers": "347",
        "median": "683",
        "maleWorkers": "328",
        "maleMedian": "689",
        "femaleWorkers": "20",
        "femaleMedian": "-"
      }
    }
  },
  "Paperhangers": {
    "ageData": {
      "2017": {
        "total": "4",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "2",
        "55to64": "0",
        "65+": "1",
        "medianAge": "0"
      }
    },
    "title": "Paperhangers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Pipelayers, plumbers, pipefitters, and steamfitters": {
    "ageData": {
      "2017": {
        "total": "600",
        "16to19": "9",
        "20to24": "56",
        "25to34": "147",
        "35to44": "148",
        "45to54": "142",
        "55to64": "87",
        "65+": "12",
        "medianAge": "87"
      }
    },
    "title": "Pipelayers, plumbers, pipefitters, and steamfitters",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "600",
        "women": "2.2",
        "white": "86.6",
        "black": "9.0",
        "asian": "1.5",
        "hispanicOrLatino": "27.1"
      }
    },
    "wageData": {
      "2017": {
        "workers": "497",
        "median": "866",
        "maleWorkers": "489",
        "maleMedian": "865",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Plasterers and stucco masons": {
    "ageData": {
      "2017": {
        "total": "27",
        "16to19": "0",
        "20to24": "3",
        "25to34": "8",
        "35to44": "6",
        "45to54": "6",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Plasterers and stucco masons",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "27",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "24",
        "median": "-",
        "maleWorkers": "24",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Reinforcing iron and rebar workers": {
    "ageData": {
      "2017": {
        "total": "12",
        "16to19": "0",
        "20to24": "2",
        "25to34": "2",
        "35to44": "3",
        "45to54": "3",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Reinforcing iron and rebar workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "12",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Roofers": {
    "ageData": {
      "2017": {
        "total": "220",
        "16to19": "6",
        "20to24": "25",
        "25to34": "61",
        "35to44": "65",
        "45to54": "44",
        "55to64": "14",
        "65+": "5",
        "medianAge": "14"
      }
    },
    "title": "Roofers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "220",
        "women": "0.6",
        "white": "86.6",
        "black": "6.9",
        "asian": "2.5",
        "hispanicOrLatino": "56.1"
      }
    },
    "wageData": {
      "2017": {
        "workers": "174",
        "median": "621",
        "maleWorkers": "174",
        "maleMedian": "621",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Sheet metal workers": {
    "ageData": {
      "2017": {
        "total": "137",
        "16to19": "2",
        "20to24": "7",
        "25to34": "35",
        "35to44": "32",
        "45to54": "30",
        "55to64": "26",
        "65+": "5",
        "medianAge": "26"
      }
    },
    "title": "Sheet metal workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "137",
        "women": "2.6",
        "white": "91.4",
        "black": "4.3",
        "asian": "1.6",
        "hispanicOrLatino": "18.7"
      }
    },
    "wageData": {
      "2017": {
        "workers": "136",
        "median": "865",
        "maleWorkers": "132",
        "maleMedian": "866",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Structural iron and steel workers": {
    "ageData": {
      "2017": {
        "total": "43",
        "16to19": "0",
        "20to24": "3",
        "25to34": "8",
        "35to44": "14",
        "45to54": "10",
        "55to64": "7",
        "65+": "0",
        "medianAge": "7"
      }
    },
    "title": "Structural iron and steel workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "43",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "43",
        "median": "-",
        "maleWorkers": "41",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Solar photovoltaic installers": {
    "ageData": {
      "2017": {
        "total": "11",
        "16to19": "0",
        "20to24": "1",
        "25to34": "5",
        "35to44": "2",
        "45to54": "1",
        "55to64": "1",
        "65+": "1",
        "medianAge": "1"
      }
    },
    "title": "Solar photovoltaic installers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "11",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Helpers, construction trades": {
    "ageData": {
      "2017": {
        "total": "69",
        "16to19": "6",
        "20to24": "17",
        "25to34": "18",
        "35to44": "12",
        "45to54": "9",
        "55to64": "7",
        "65+": "0",
        "medianAge": "7"
      }
    },
    "title": "Helpers, construction trades",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "69",
        "women": "9.4",
        "white": "88.2",
        "black": "3.8",
        "asian": "2.1",
        "hispanicOrLatino": "38.3"
      }
    },
    "wageData": {
      "2017": {
        "workers": "57",
        "median": "621",
        "maleWorkers": "53",
        "maleMedian": "627",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Construction and building inspectors": {
    "ageData": {
      "2017": {
        "total": "89",
        "16to19": "0",
        "20to24": "1",
        "25to34": "8",
        "35to44": "13",
        "45to54": "27",
        "55to64": "26",
        "65+": "13",
        "medianAge": "26"
      }
    },
    "title": "Construction and building inspectors",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "89",
        "women": "10.2",
        "white": "85.5",
        "black": "9.8",
        "asian": "2.2",
        "hispanicOrLatino": "11.2"
      }
    },
    "wageData": {
      "2017": {
        "workers": "63",
        "median": "974",
        "maleWorkers": "60",
        "maleMedian": "1,068",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Elevator installers and repairers": {
    "ageData": {
      "2017": {
        "total": "40",
        "16to19": "0",
        "20to24": "0",
        "25to34": "9",
        "35to44": "9",
        "45to54": "8",
        "55to64": "9",
        "65+": "4",
        "medianAge": "9"
      }
    },
    "title": "Elevator installers and repairers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "40",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "38",
        "median": "-",
        "maleWorkers": "38",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Fence erectors": {
    "ageData": {
      "2017": {
        "total": "34",
        "16to19": "2",
        "20to24": "4",
        "25to34": "7",
        "35to44": "8",
        "45to54": "6",
        "55to64": "5",
        "65+": "2",
        "medianAge": "5"
      }
    },
    "title": "Fence erectors",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "34",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "26",
        "median": "-",
        "maleWorkers": "25",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Hazardous materials removal workers": {
    "ageData": {
      "2017": {
        "total": "30",
        "16to19": "0",
        "20to24": "1",
        "25to34": "7",
        "35to44": "9",
        "45to54": "5",
        "55to64": "7",
        "65+": "1",
        "medianAge": "7"
      }
    },
    "title": "Hazardous materials removal workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "30",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "27",
        "median": "-",
        "maleWorkers": "20",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Highway maintenance workers": {
    "ageData": {
      "2017": {
        "total": "101",
        "16to19": "2",
        "20to24": "8",
        "25to34": "17",
        "35to44": "24",
        "45to54": "26",
        "55to64": "21",
        "65+": "3",
        "medianAge": "21"
      }
    },
    "title": "Highway maintenance workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "101",
        "women": "2.7",
        "white": "79.4",
        "black": "13.7",
        "asian": "0.1",
        "hispanicOrLatino": "15.5"
      }
    },
    "wageData": {
      "2017": {
        "workers": "91",
        "median": "761",
        "maleWorkers": "88",
        "maleMedian": "770",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Rail-track laying and maintenance equipment operators": {
    "ageData": {
      "2017": {
        "total": "17",
        "16to19": "0",
        "20to24": "4",
        "25to34": "4",
        "35to44": "5",
        "45to54": "1",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Rail-track laying and maintenance equipment operators",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "17",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "10",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Septic tank servicers and sewer pipe cleaners": {
    "ageData": {
      "2017": {
        "total": "10",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "3",
        "45to54": "3",
        "55to64": "0",
        "65+": "1",
        "medianAge": "0"
      }
    },
    "title": "Septic tank servicers and sewer pipe cleaners",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "10",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous construction and related workers": {
    "ageData": {
      "2017": {
        "total": "24",
        "16to19": "0",
        "20to24": "1",
        "25to34": "7",
        "35to44": "6",
        "45to54": "4",
        "55to64": "4",
        "65+": "1",
        "medianAge": "4"
      }
    },
    "title": "Miscellaneous construction and related workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "24",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "15",
        "median": "-",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Derrick, rotary drill, and service unit operators, oil, gas, and mining": {
    "ageData": {
      "2017": {
        "total": "30",
        "16to19": "0",
        "20to24": "3",
        "25to34": "15",
        "35to44": "8",
        "45to54": "3",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Derrick, rotary drill, and service unit operators, oil, gas, and mining",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "30",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "27",
        "median": "-",
        "maleWorkers": "27",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Earth drillers, except oil and gas": {
    "ageData": {
      "2017": {
        "total": "23",
        "16to19": "0",
        "20to24": "3",
        "25to34": "4",
        "35to44": "5",
        "45to54": "3",
        "55to64": "6",
        "65+": "2",
        "medianAge": "6"
      }
    },
    "title": "Earth drillers, except oil and gas",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "23",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "17",
        "median": "-",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Explosives workers, ordnance handling experts, and blasters": {
    "ageData": {
      "2017": {
        "total": "10",
        "16to19": "1",
        "20to24": "1",
        "25to34": "1",
        "35to44": "2",
        "45to54": "1",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Explosives workers, ordnance handling experts, and blasters",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "10",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Mining machine operators": {
    "ageData": {
      "2017": {
        "total": "51",
        "16to19": "0",
        "20to24": "2",
        "25to34": "15",
        "35to44": "14",
        "45to54": "12",
        "55to64": "7",
        "65+": "2",
        "medianAge": "7"
      }
    },
    "title": "Mining machine operators",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "51",
        "women": "1.1",
        "white": "85.8",
        "black": "7.8",
        "asian": "2.7",
        "hispanicOrLatino": "23.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "49",
        "median": "-",
        "maleWorkers": "49",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Roof bolters, mining": {
    "ageData": {
      "2017": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "1",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Roof bolters, mining",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Roustabouts, oil and gas": {
    "ageData": {
      "2017": {
        "total": "4",
        "16to19": "0",
        "20to24": "1",
        "25to34": "2",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Roustabouts, oil and gas",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Helpers--extraction workers": {
    "ageData": {
      "2017": {
        "total": "4",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "1",
        "45to54": "0",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Helpers--extraction workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Other extraction workers": {
    "ageData": {
      "2017": {
        "total": "54",
        "16to19": "1",
        "20to24": "7",
        "25to34": "17",
        "35to44": "11",
        "45to54": "9",
        "55to64": "8",
        "65+": "2",
        "medianAge": "8"
      }
    },
    "title": "Other extraction workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "54",
        "women": "2.9",
        "white": "93.1",
        "black": "2.0",
        "asian": "0.0",
        "hispanicOrLatino": "35.7"
      }
    },
    "wageData": {
      "2017": {
        "workers": "54",
        "median": "900",
        "maleWorkers": "52",
        "maleMedian": "907",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Installation, maintenance, and repair occupations": {
    "ageData": {
      "2017": {
        "total": "4,977",
        "16to19": "80",
        "20to24": "389",
        "25to34": "1,159",
        "35to44": "1,085",
        "45to54": "1,166",
        "55to64": "892",
        "65+": "206",
        "medianAge": "892"
      }
    },
    "title": "Installation, maintenance, and repair occupations",
    "depth": 2,
    "parent": "Natural resources, construction, and maintenance occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "4,977",
        "women": "4.0",
        "white": "85.0",
        "black": "8.6",
        "asian": "3.3",
        "hispanicOrLatino": "19.0"
      }
    },
    "wageData": {
      "2017": {
        "workers": "4,400",
        "median": "878",
        "maleWorkers": "4,251",
        "maleMedian": "883",
        "femaleWorkers": "149",
        "femaleMedian": "736"
      }
    }
  },
  "First-line supervisors of mechanics, installers, and repairers": {
    "ageData": {
      "2017": {
        "total": "253",
        "16to19": "0",
        "20to24": "2",
        "25to34": "40",
        "35to44": "58",
        "45to54": "78",
        "55to64": "66",
        "65+": "10",
        "medianAge": "66"
      }
    },
    "title": "First-line supervisors of mechanics, installers, and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "253",
        "women": "8.4",
        "white": "84.0",
        "black": "9.4",
        "asian": "4.1",
        "hispanicOrLatino": "12.8"
      }
    },
    "wageData": {
      "2017": {
        "workers": "244",
        "median": "1,092",
        "maleWorkers": "225",
        "maleMedian": "1,089",
        "femaleWorkers": "19",
        "femaleMedian": "-"
      }
    }
  },
  "Computer, automated teller, and office machine repairers": {
    "ageData": {
      "2017": {
        "total": "212",
        "16to19": "2",
        "20to24": "15",
        "25to34": "57",
        "35to44": "48",
        "45to54": "55",
        "55to64": "29",
        "65+": "6",
        "medianAge": "29"
      }
    },
    "title": "Computer, automated teller, and office machine repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "212",
        "women": "11.3",
        "white": "73.8",
        "black": "12.7",
        "asian": "10.2",
        "hispanicOrLatino": "17.1"
      }
    },
    "wageData": {
      "2017": {
        "workers": "173",
        "median": "819",
        "maleWorkers": "156",
        "maleMedian": "833",
        "femaleWorkers": "17",
        "femaleMedian": "-"
      }
    }
  },
  "Radio and telecommunications equipment installers and repairers": {
    "ageData": {
      "2017": {
        "total": "134",
        "16to19": "2",
        "20to24": "7",
        "25to34": "31",
        "35to44": "44",
        "45to54": "25",
        "55to64": "21",
        "65+": "3",
        "medianAge": "21"
      }
    },
    "title": "Radio and telecommunications equipment installers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "134",
        "women": "8.1",
        "white": "85.3",
        "black": "7.3",
        "asian": "6.1",
        "hispanicOrLatino": "19.7"
      }
    },
    "wageData": {
      "2017": {
        "workers": "121",
        "median": "1,010",
        "maleWorkers": "113",
        "maleMedian": "1,069",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Avionics technicians": {
    "ageData": {
      "2017": {
        "total": "9",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "3",
        "45to54": "2",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Avionics technicians",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "9",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Electric motor, power tool, and related repairers": {
    "ageData": {
      "2017": {
        "total": "28",
        "16to19": "0",
        "20to24": "1",
        "25to34": "6",
        "35to44": "5",
        "45to54": "9",
        "55to64": "6",
        "65+": "1",
        "medianAge": "6"
      }
    },
    "title": "Electric motor, power tool, and related repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "28",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "30",
        "median": "-",
        "maleWorkers": "30",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Electrical and electronics installers and repairers, transportation equipment": {
    "ageData": {
      "2017": {
        "total": "1",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "1",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Electrical and electronics installers and repairers, transportation equipment",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Electrical and electronics repairers, industrial and utility": {
    "ageData": {
      "2017": {
        "total": "12",
        "16to19": "0",
        "20to24": "0",
        "25to34": "5",
        "35to44": "3",
        "45to54": "1",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Electrical and electronics repairers, industrial and utility",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "12",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "14",
        "median": "-",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Electronic equipment installers and repairers, motor vehicles": {
    "ageData": {
      "2017": {
        "total": "11",
        "16to19": "0",
        "20to24": "2",
        "25to34": "1",
        "35to44": "2",
        "45to54": "3",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Electronic equipment installers and repairers, motor vehicles",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "11",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "11",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Electronic home entertainment equipment installers and repairers": {
    "ageData": {
      "2017": {
        "total": "50",
        "16to19": "1",
        "20to24": "7",
        "25to34": "13",
        "35to44": "14",
        "45to54": "10",
        "55to64": "3",
        "65+": "3",
        "medianAge": "3"
      }
    },
    "title": "Electronic home entertainment equipment installers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "50",
        "women": "2.8",
        "white": "81.9",
        "black": "14.2",
        "asian": "3.7",
        "hispanicOrLatino": "26.5"
      }
    },
    "wageData": {
      "2017": {
        "workers": "38",
        "median": "-",
        "maleWorkers": "38",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Security and fire alarm systems installers": {
    "ageData": {
      "2017": {
        "total": "77",
        "16to19": "1",
        "20to24": "10",
        "25to34": "20",
        "35to44": "16",
        "45to54": "17",
        "55to64": "11",
        "65+": "3",
        "medianAge": "11"
      }
    },
    "title": "Security and fire alarm systems installers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "77",
        "women": "2.6",
        "white": "78.4",
        "black": "11.0",
        "asian": "5.8",
        "hispanicOrLatino": "24.1"
      }
    },
    "wageData": {
      "2017": {
        "workers": "71",
        "median": "945",
        "maleWorkers": "68",
        "maleMedian": "943",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Aircraft mechanics and service technicians": {
    "ageData": {
      "2017": {
        "total": "150",
        "16to19": "1",
        "20to24": "9",
        "25to34": "34",
        "35to44": "24",
        "45to54": "41",
        "55to64": "38",
        "65+": "4",
        "medianAge": "38"
      }
    },
    "title": "Aircraft mechanics and service technicians",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "150",
        "women": "5.2",
        "white": "83.0",
        "black": "5.6",
        "asian": "7.8",
        "hispanicOrLatino": "13.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "153",
        "median": "1,169",
        "maleWorkers": "146",
        "maleMedian": "1,175",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Automotive body and related repairers": {
    "ageData": {
      "2017": {
        "total": "140",
        "16to19": "3",
        "20to24": "13",
        "25to34": "36",
        "35to44": "27",
        "45to54": "35",
        "55to64": "18",
        "65+": "7",
        "medianAge": "18"
      }
    },
    "title": "Automotive body and related repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "140",
        "women": "1.1",
        "white": "86.0",
        "black": "6.1",
        "asian": "2.9",
        "hispanicOrLatino": "31.6"
      }
    },
    "wageData": {
      "2017": {
        "workers": "124",
        "median": "821",
        "maleWorkers": "122",
        "maleMedian": "832",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Automotive glass installers and repairers": {
    "ageData": {
      "2017": {
        "total": "22",
        "16to19": "0",
        "20to24": "2",
        "25to34": "7",
        "35to44": "5",
        "45to54": "3",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Automotive glass installers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "22",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "21",
        "median": "-",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Automotive service technicians and mechanics": {
    "ageData": {
      "2017": {
        "total": "902",
        "16to19": "21",
        "20to24": "97",
        "25to34": "248",
        "35to44": "189",
        "45to54": "196",
        "55to64": "126",
        "65+": "25",
        "medianAge": "126"
      }
    },
    "title": "Automotive service technicians and mechanics",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "902",
        "women": "2.4",
        "white": "84.1",
        "black": "8.7",
        "asian": "3.8",
        "hispanicOrLatino": "23.2"
      }
    },
    "wageData": {
      "2017": {
        "workers": "752",
        "median": "761",
        "maleWorkers": "737",
        "maleMedian": "764",
        "femaleWorkers": "15",
        "femaleMedian": "-"
      }
    }
  },
  "Bus and truck mechanics and diesel engine specialists": {
    "ageData": {
      "2017": {
        "total": "401",
        "16to19": "10",
        "20to24": "43",
        "25to34": "93",
        "35to44": "83",
        "45to54": "88",
        "55to64": "70",
        "65+": "15",
        "medianAge": "70"
      }
    },
    "title": "Bus and truck mechanics and diesel engine specialists",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "401",
        "women": "1.8",
        "white": "85.6",
        "black": "9.4",
        "asian": "1.3",
        "hispanicOrLatino": "18.1"
      }
    },
    "wageData": {
      "2017": {
        "workers": "367",
        "median": "887",
        "maleWorkers": "362",
        "maleMedian": "887",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Heavy vehicle and mobile equipment service technicians and mechanics": {
    "ageData": {
      "2017": {
        "total": "201",
        "16to19": "2",
        "20to24": "19",
        "25to34": "36",
        "35to44": "45",
        "45to54": "51",
        "55to64": "36",
        "65+": "11",
        "medianAge": "36"
      }
    },
    "title": "Heavy vehicle and mobile equipment service technicians and mechanics",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "201",
        "women": "2.6",
        "white": "91.8",
        "black": "4.5",
        "asian": "1.4",
        "hispanicOrLatino": "11.5"
      }
    },
    "wageData": {
      "2017": {
        "workers": "177",
        "median": "993",
        "maleWorkers": "173",
        "maleMedian": "1,005",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Small engine mechanics": {
    "ageData": {
      "2017": {
        "total": "44",
        "16to19": "2",
        "20to24": "2",
        "25to34": "8",
        "35to44": "8",
        "45to54": "13",
        "55to64": "7",
        "65+": "4",
        "medianAge": "7"
      }
    },
    "title": "Small engine mechanics",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "44",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "26",
        "median": "-",
        "maleWorkers": "26",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous vehicle and mobile equipment mechanics, installers, and repairers": {
    "ageData": {
      "2017": {
        "total": "83",
        "16to19": "6",
        "20to24": "13",
        "25to34": "27",
        "35to44": "16",
        "45to54": "13",
        "55to64": "6",
        "65+": "2",
        "medianAge": "6"
      }
    },
    "title": "Miscellaneous vehicle and mobile equipment mechanics, installers, and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "83",
        "women": "1.8",
        "white": "85.6",
        "black": "7.5",
        "asian": "4.4",
        "hispanicOrLatino": "32.2"
      }
    },
    "wageData": {
      "2017": {
        "workers": "64",
        "median": "613",
        "maleWorkers": "64",
        "maleMedian": "613",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Control and valve installers and repairers": {
    "ageData": {
      "2017": {
        "total": "26",
        "16to19": "0",
        "20to24": "2",
        "25to34": "5",
        "35to44": "7",
        "45to54": "6",
        "55to64": "5",
        "65+": "1",
        "medianAge": "5"
      }
    },
    "title": "Control and valve installers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "26",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "25",
        "median": "-",
        "maleWorkers": "25",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Heating, air conditioning, and refrigeration mechanics and installers": {
    "ageData": {
      "2017": {
        "total": "448",
        "16to19": "9",
        "20to24": "38",
        "25to34": "114",
        "35to44": "90",
        "45to54": "106",
        "55to64": "72",
        "65+": "19",
        "medianAge": "72"
      }
    },
    "title": "Heating, air conditioning, and refrigeration mechanics and installers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "448",
        "women": "2.0",
        "white": "90.6",
        "black": "5.4",
        "asian": "2.4",
        "hispanicOrLatino": "17.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "376",
        "median": "830",
        "maleWorkers": "369",
        "maleMedian": "832",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Home appliance repairers": {
    "ageData": {
      "2017": {
        "total": "41",
        "16to19": "0",
        "20to24": "4",
        "25to34": "10",
        "35to44": "9",
        "45to54": "4",
        "55to64": "10",
        "65+": "5",
        "medianAge": "10"
      }
    },
    "title": "Home appliance repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "41",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "26",
        "median": "-",
        "maleWorkers": "26",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Industrial and refractory machinery mechanics": {
    "ageData": {
      "2017": {
        "total": "424",
        "16to19": "4",
        "20to24": "23",
        "25to34": "88",
        "35to44": "78",
        "45to54": "120",
        "55to64": "98",
        "65+": "13",
        "medianAge": "98"
      }
    },
    "title": "Industrial and refractory machinery mechanics",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "424",
        "women": "3.9",
        "white": "85.8",
        "black": "7.8",
        "asian": "3.4",
        "hispanicOrLatino": "16.5"
      }
    },
    "wageData": {
      "2017": {
        "workers": "395",
        "median": "923",
        "maleWorkers": "379",
        "maleMedian": "932",
        "femaleWorkers": "16",
        "femaleMedian": "-"
      }
    }
  },
  "Maintenance and repair workers, general": {
    "ageData": {
      "2017": {
        "total": "528",
        "16to19": "5",
        "20to24": "29",
        "25to34": "101",
        "35to44": "115",
        "45to54": "126",
        "55to64": "122",
        "65+": "30",
        "medianAge": "122"
      }
    },
    "title": "Maintenance and repair workers, general",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "528",
        "women": "4.5",
        "white": "85.9",
        "black": "8.9",
        "asian": "2.1",
        "hispanicOrLatino": "19.7"
      }
    },
    "wageData": {
      "2017": {
        "workers": "511",
        "median": "828",
        "maleWorkers": "490",
        "maleMedian": "834",
        "femaleWorkers": "20",
        "femaleMedian": "-"
      }
    }
  },
  "Maintenance workers, machinery": {
    "ageData": {
      "2017": {
        "total": "27",
        "16to19": "0",
        "20to24": "0",
        "25to34": "4",
        "35to44": "8",
        "45to54": "7",
        "55to64": "7",
        "65+": "1",
        "medianAge": "7"
      }
    },
    "title": "Maintenance workers, machinery",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "27",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "25",
        "median": "-",
        "maleWorkers": "25",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Millwrights": {
    "ageData": {
      "2017": {
        "total": "40",
        "16to19": "1",
        "20to24": "1",
        "25to34": "6",
        "35to44": "14",
        "45to54": "9",
        "55to64": "8",
        "65+": "1",
        "medianAge": "8"
      }
    },
    "title": "Millwrights",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "40",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "40",
        "median": "-",
        "maleWorkers": "39",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Electrical power-line installers and repairers": {
    "ageData": {
      "2017": {
        "total": "129",
        "16to19": "2",
        "20to24": "10",
        "25to34": "28",
        "35to44": "37",
        "45to54": "28",
        "55to64": "21",
        "65+": "5",
        "medianAge": "21"
      }
    },
    "title": "Electrical power-line installers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "129",
        "women": "0.6",
        "white": "89.8",
        "black": "7.8",
        "asian": "0.0",
        "hispanicOrLatino": "11.6"
      }
    },
    "wageData": {
      "2017": {
        "workers": "132",
        "median": "1,191",
        "maleWorkers": "132",
        "maleMedian": "1,191",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Telecommunications line installers and repairers": {
    "ageData": {
      "2017": {
        "total": "189",
        "16to19": "1",
        "20to24": "14",
        "25to34": "48",
        "35to44": "55",
        "45to54": "43",
        "55to64": "22",
        "65+": "6",
        "medianAge": "22"
      }
    },
    "title": "Telecommunications line installers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "189",
        "women": "2.7",
        "white": "78.3",
        "black": "18.4",
        "asian": "1.7",
        "hispanicOrLatino": "19.1"
      }
    },
    "wageData": {
      "2017": {
        "workers": "187",
        "median": "879",
        "maleWorkers": "181",
        "maleMedian": "876",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Precision instrument and equipment repairers": {
    "ageData": {
      "2017": {
        "total": "63",
        "16to19": "1",
        "20to24": "1",
        "25to34": "16",
        "35to44": "10",
        "45to54": "12",
        "55to64": "16",
        "65+": "6",
        "medianAge": "16"
      }
    },
    "title": "Precision instrument and equipment repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "63",
        "women": "14.6",
        "white": "80.9",
        "black": "14.7",
        "asian": "1.4",
        "hispanicOrLatino": "10.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "44",
        "median": "-",
        "maleWorkers": "34",
        "maleMedian": "-",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Wind turbine service technicians": {
    "ageData": {
      "2017": {
        "total": "5",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "2",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Wind turbine service technicians",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Coin, vending, and amusement machine servicers and repairers": {
    "ageData": {
      "2017": {
        "total": "34",
        "16to19": "0",
        "20to24": "1",
        "25to34": "9",
        "35to44": "10",
        "45to54": "7",
        "55to64": "4",
        "65+": "3",
        "medianAge": "4"
      }
    },
    "title": "Coin, vending, and amusement machine servicers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "34",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "24",
        "median": "-",
        "maleWorkers": "23",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Commercial divers": {
    "ageData": {
      "2017": {
        "total": "0",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Commercial divers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "0",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "0",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Locksmiths and safe repairers": {
    "ageData": {
      "2017": {
        "total": "35",
        "16to19": "0",
        "20to24": "3",
        "25to34": "7",
        "35to44": "7",
        "45to54": "7",
        "55to64": "7",
        "65+": "5",
        "medianAge": "7"
      }
    },
    "title": "Locksmiths and safe repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "35",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "20",
        "median": "-",
        "maleWorkers": "18",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Manufactured building and mobile home installers": {
    "ageData": {
      "2017": {
        "total": "4",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "1",
        "45to54": "1",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Manufactured building and mobile home installers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Riggers": {
    "ageData": {
      "2017": {
        "total": "14",
        "16to19": "0",
        "20to24": "1",
        "25to34": "6",
        "35to44": "3",
        "45to54": "2",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Riggers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "14",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Signal and track switch repairers": {
    "ageData": {
      "2017": {
        "total": "9",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "4",
        "45to54": "0",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Signal and track switch repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "9",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "11",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Helpers--installation, maintenance, and repair workers": {
    "ageData": {
      "2017": {
        "total": "18",
        "16to19": "1",
        "20to24": "2",
        "25to34": "3",
        "35to44": "5",
        "45to54": "2",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Helpers--installation, maintenance, and repair workers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "18",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "15",
        "median": "-",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Other installation, maintenance, and repair workers": {
    "ageData": {
      "2017": {
        "total": "215",
        "16to19": "3",
        "20to24": "19",
        "25to34": "46",
        "35to44": "43",
        "45to54": "45",
        "55to64": "45",
        "65+": "14",
        "medianAge": "45"
      }
    },
    "title": "Other installation, maintenance, and repair workers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "215",
        "women": "5.9",
        "white": "86.7",
        "black": "6.4",
        "asian": "2.3",
        "hispanicOrLatino": "23.5"
      }
    },
    "wageData": {
      "2017": {
        "workers": "156",
        "median": "774",
        "maleWorkers": "150",
        "maleMedian": "772",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Production, transportation, and material moving occupations": {
    "ageData": {
      "2017": {
        "total": "17,927",
        "16to19": "497",
        "20to24": "1,736",
        "25to34": "3,721",
        "35to44": "3,525",
        "45to54": "4,094",
        "55to64": "3,335",
        "65+": "1,019",
        "medianAge": "3,335"
      }
    },
    "title": "Production, transportation, and material moving occupations",
    "depth": 1,
    "parent": "",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "17,927",
        "women": "22.8",
        "white": "74.2",
        "black": "16.6",
        "asian": "5.3",
        "hispanicOrLatino": "22.2"
      }
    },
    "wageData": {
      "2017": {
        "workers": "14,825",
        "median": "692",
        "maleWorkers": "11,705",
        "maleMedian": "736",
        "femaleWorkers": "3,119",
        "femaleMedian": "545"
      }
    }
  },
  "Production occupations": {
    "ageData": {
      "2017": {
        "total": "8,482",
        "16to19": "155",
        "20to24": "783",
        "25to34": "1,841",
        "35to44": "1,737",
        "45to54": "1,976",
        "55to64": "1,608",
        "65+": "382",
        "medianAge": "1,608"
      }
    },
    "title": "Production occupations",
    "depth": 2,
    "parent": "Production, transportation, and material moving occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "8,482",
        "women": "28.9",
        "white": "76.8",
        "black": "13.3",
        "asian": "6.2",
        "hispanicOrLatino": "22.8"
      }
    },
    "wageData": {
      "2017": {
        "workers": "7,589",
        "median": "701",
        "maleWorkers": "5,545",
        "maleMedian": "769",
        "femaleWorkers": "2,044",
        "femaleMedian": "564"
      }
    }
  },
  "First-line supervisors of production and operating workers": {
    "ageData": {
      "2017": {
        "total": "768",
        "16to19": "4",
        "20to24": "23",
        "25to34": "133",
        "35to44": "179",
        "45to54": "226",
        "55to64": "173",
        "65+": "30",
        "medianAge": "173"
      }
    },
    "title": "First-line supervisors of production and operating workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "768",
        "women": "19.1",
        "white": "82.2",
        "black": "10.4",
        "asian": "3.7",
        "hispanicOrLatino": "16.7"
      }
    },
    "wageData": {
      "2017": {
        "workers": "722",
        "median": "966",
        "maleWorkers": "588",
        "maleMedian": "1,007",
        "femaleWorkers": "133",
        "femaleMedian": "716"
      }
    }
  },
  "Aircraft structure, surfaces, rigging, and systems assemblers": {
    "ageData": {
      "2017": {
        "total": "1",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Aircraft structure, surfaces, rigging, and systems assemblers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Electrical, electronics, and electromechanical assemblers": {
    "ageData": {
      "2017": {
        "total": "128",
        "16to19": "2",
        "20to24": "16",
        "25to34": "26",
        "35to44": "23",
        "45to54": "31",
        "55to64": "22",
        "65+": "7",
        "medianAge": "22"
      }
    },
    "title": "Electrical, electronics, and electromechanical assemblers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "128",
        "women": "42.1",
        "white": "63.5",
        "black": "15.3",
        "asian": "17.2",
        "hispanicOrLatino": "18.2"
      }
    },
    "wageData": {
      "2017": {
        "workers": "123",
        "median": "624",
        "maleWorkers": "73",
        "maleMedian": "682",
        "femaleWorkers": "50",
        "femaleMedian": "587"
      }
    }
  },
  "Engine and other machine assemblers": {
    "ageData": {
      "2017": {
        "total": "13",
        "16to19": "0",
        "20to24": "2",
        "25to34": "7",
        "35to44": "0",
        "45to54": "2",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Engine and other machine assemblers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "13",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "14",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Structural metal fabricators and fitters": {
    "ageData": {
      "2017": {
        "total": "30",
        "16to19": "1",
        "20to24": "2",
        "25to34": "8",
        "35to44": "7",
        "45to54": "6",
        "55to64": "5",
        "65+": "1",
        "medianAge": "5"
      }
    },
    "title": "Structural metal fabricators and fitters",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "30",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "25",
        "median": "-",
        "maleWorkers": "24",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous assemblers and fabricators": {
    "ageData": {
      "2017": {
        "total": "1,033",
        "16to19": "20",
        "20to24": "119",
        "25to34": "221",
        "35to44": "212",
        "45to54": "239",
        "55to64": "178",
        "65+": "45",
        "medianAge": "178"
      }
    },
    "title": "Miscellaneous assemblers and fabricators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "1,033",
        "women": "37.7",
        "white": "68.0",
        "black": "20.8",
        "asian": "8.0",
        "hispanicOrLatino": "18.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "957",
        "median": "630",
        "maleWorkers": "595",
        "maleMedian": "700",
        "femaleWorkers": "362",
        "femaleMedian": "559"
      }
    }
  },
  "Bakers": {
    "ageData": {
      "2017": {
        "total": "215",
        "16to19": "9",
        "20to24": "25",
        "25to34": "40",
        "35to44": "45",
        "45to54": "41",
        "55to64": "46",
        "65+": "8",
        "medianAge": "46"
      }
    },
    "title": "Bakers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "215",
        "women": "60.1",
        "white": "71.3",
        "black": "15.6",
        "asian": "8.5",
        "hispanicOrLatino": "31.5"
      }
    },
    "wageData": {
      "2017": {
        "workers": "135",
        "median": "472",
        "maleWorkers": "67",
        "maleMedian": "488",
        "femaleWorkers": "68",
        "femaleMedian": "464"
      }
    }
  },
  "Butchers and other meat, poultry, and fish processing workers": {
    "ageData": {
      "2017": {
        "total": "269",
        "16to19": "10",
        "20to24": "34",
        "25to34": "64",
        "35to44": "55",
        "45to54": "58",
        "55to64": "32",
        "65+": "17",
        "medianAge": "32"
      }
    },
    "title": "Butchers and other meat, poultry, and fish processing workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "269",
        "women": "24.3",
        "white": "68.5",
        "black": "19.8",
        "asian": "7.2",
        "hispanicOrLatino": "35.3"
      }
    },
    "wageData": {
      "2017": {
        "workers": "259",
        "median": "587",
        "maleWorkers": "196",
        "maleMedian": "621",
        "femaleWorkers": "63",
        "femaleMedian": "487"
      }
    }
  },
  "Food and tobacco roasting, baking, and drying machine operators and tenders": {
    "ageData": {
      "2017": {
        "total": "12",
        "16to19": "0",
        "20to24": "3",
        "25to34": "1",
        "35to44": "2",
        "45to54": "2",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Food and tobacco roasting, baking, and drying machine operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "12",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Food batchmakers": {
    "ageData": {
      "2017": {
        "total": "89",
        "16to19": "8",
        "20to24": "15",
        "25to34": "14",
        "35to44": "18",
        "45to54": "10",
        "55to64": "17",
        "65+": "5",
        "medianAge": "17"
      }
    },
    "title": "Food batchmakers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "89",
        "women": "58.1",
        "white": "77.2",
        "black": "12.4",
        "asian": "6.6",
        "hispanicOrLatino": "26.2"
      }
    },
    "wageData": {
      "2017": {
        "workers": "70",
        "median": "575",
        "maleWorkers": "32",
        "maleMedian": "-",
        "femaleWorkers": "38",
        "femaleMedian": "-"
      }
    }
  },
  "Food cooking machine operators and tenders": {
    "ageData": {
      "2017": {
        "total": "15",
        "16to19": "2",
        "20to24": "0",
        "25to34": "5",
        "35to44": "2",
        "45to54": "3",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Food cooking machine operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "15",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Food processing workers, all other": {
    "ageData": {
      "2017": {
        "total": "175",
        "16to19": "4",
        "20to24": "19",
        "25to34": "41",
        "35to44": "37",
        "45to54": "43",
        "55to64": "22",
        "65+": "11",
        "medianAge": "22"
      }
    },
    "title": "Food processing workers, all other",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "175",
        "women": "32.5",
        "white": "62.8",
        "black": "24.1",
        "asian": "7.0",
        "hispanicOrLatino": "37.3"
      }
    },
    "wageData": {
      "2017": {
        "workers": "171",
        "median": "676",
        "maleWorkers": "119",
        "maleMedian": "701",
        "femaleWorkers": "52",
        "femaleMedian": "545"
      }
    }
  },
  "Computer control programmers and operators": {
    "ageData": {
      "2017": {
        "total": "99",
        "16to19": "1",
        "20to24": "6",
        "25to34": "26",
        "35to44": "19",
        "45to54": "23",
        "55to64": "20",
        "65+": "3",
        "medianAge": "20"
      }
    },
    "title": "Computer control programmers and operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "99",
        "women": "9.8",
        "white": "82.8",
        "black": "9.8",
        "asian": "5.6",
        "hispanicOrLatino": "10.7"
      }
    },
    "wageData": {
      "2017": {
        "workers": "100",
        "median": "868",
        "maleWorkers": "88",
        "maleMedian": "912",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Extruding and drawing machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2017": {
        "total": "19",
        "16to19": "0",
        "20to24": "3",
        "25to34": "5",
        "35to44": "6",
        "45to54": "3",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Extruding and drawing machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "19",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "18",
        "median": "-",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Forging machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2017": {
        "total": "8",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "2",
        "45to54": "2",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Forging machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "8",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "9",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Rolling machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2017": {
        "total": "8",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "4",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Rolling machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "8",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "9",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Cutting, punching, and press machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2017": {
        "total": "80",
        "16to19": "2",
        "20to24": "7",
        "25to34": "22",
        "35to44": "12",
        "45to54": "17",
        "55to64": "15",
        "65+": "5",
        "medianAge": "15"
      }
    },
    "title": "Cutting, punching, and press machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "80",
        "women": "11.0",
        "white": "82.6",
        "black": "10.0",
        "asian": "4.7",
        "hispanicOrLatino": "12.5"
      }
    },
    "wageData": {
      "2017": {
        "workers": "84",
        "median": "665",
        "maleWorkers": "74",
        "maleMedian": "688",
        "femaleWorkers": "9",
        "femaleMedian": "-"
      }
    }
  },
  "Drilling and boring machine tool setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2017": {
        "total": "4",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "1",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Drilling and boring machine tool setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Grinding, lapping, polishing, and buffing machine tool setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2017": {
        "total": "38",
        "16to19": "1",
        "20to24": "3",
        "25to34": "5",
        "35to44": "7",
        "45to54": "11",
        "55to64": "10",
        "65+": "1",
        "medianAge": "10"
      }
    },
    "title": "Grinding, lapping, polishing, and buffing machine tool setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "38",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "40",
        "median": "-",
        "maleWorkers": "35",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Lathe and turning machine tool setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2017": {
        "total": "14",
        "16to19": "1",
        "20to24": "0",
        "25to34": "2",
        "35to44": "2",
        "45to54": "3",
        "55to64": "4",
        "65+": "2",
        "medianAge": "4"
      }
    },
    "title": "Lathe and turning machine tool setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "14",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "14",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Milling and planing machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2017": {
        "total": "5",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "1",
        "45to54": "2",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Milling and planing machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Machinists": {
    "ageData": {
      "2017": {
        "total": "366",
        "16to19": "3",
        "20to24": "28",
        "25to34": "68",
        "35to44": "68",
        "45to54": "93",
        "55to64": "93",
        "65+": "13",
        "medianAge": "93"
      }
    },
    "title": "Machinists",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "366",
        "women": "5.0",
        "white": "86.1",
        "black": "4.4",
        "asian": "6.3",
        "hispanicOrLatino": "13.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "343",
        "median": "831",
        "maleWorkers": "326",
        "maleMedian": "834",
        "femaleWorkers": "16",
        "femaleMedian": "-"
      }
    }
  },
  "Metal furnace operators, tenders, pourers, and casters": {
    "ageData": {
      "2017": {
        "total": "19",
        "16to19": "0",
        "20to24": "4",
        "25to34": "3",
        "35to44": "5",
        "45to54": "1",
        "55to64": "5",
        "65+": "0",
        "medianAge": "5"
      }
    },
    "title": "Metal furnace operators, tenders, pourers, and casters",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "19",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "23",
        "median": "-",
        "maleWorkers": "20",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Model makers and patternmakers, metal and plastic": {
    "ageData": {
      "2017": {
        "total": "5",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "1",
        "55to64": "1",
        "65+": "3",
        "medianAge": "1"
      }
    },
    "title": "Model makers and patternmakers, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Molders and molding machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2017": {
        "total": "45",
        "16to19": "4",
        "20to24": "6",
        "25to34": "6",
        "35to44": "11",
        "45to54": "8",
        "55to64": "8",
        "65+": "2",
        "medianAge": "8"
      }
    },
    "title": "Molders and molding machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "45",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "43",
        "median": "-",
        "maleWorkers": "38",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Multiple machine tool setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2017": {
        "total": "5",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "1",
        "45to54": "2",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Multiple machine tool setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Tool and die makers": {
    "ageData": {
      "2017": {
        "total": "62",
        "16to19": "1",
        "20to24": "2",
        "25to34": "10",
        "35to44": "7",
        "45to54": "15",
        "55to64": "26",
        "65+": "2",
        "medianAge": "26"
      }
    },
    "title": "Tool and die makers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "62",
        "women": "3.2",
        "white": "97.6",
        "black": "1.1",
        "asian": "0.7",
        "hispanicOrLatino": "6.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "59",
        "median": "1,007",
        "maleWorkers": "56",
        "maleMedian": "1,021",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Welding, soldering, and brazing workers": {
    "ageData": {
      "2017": {
        "total": "600",
        "16to19": "9",
        "20to24": "80",
        "25to34": "156",
        "35to44": "129",
        "45to54": "130",
        "55to64": "85",
        "65+": "11",
        "medianAge": "85"
      }
    },
    "title": "Welding, soldering, and brazing workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "600",
        "women": "4.5",
        "white": "87.4",
        "black": "7.5",
        "asian": "1.1",
        "hispanicOrLatino": "24.3"
      }
    },
    "wageData": {
      "2017": {
        "workers": "541",
        "median": "812",
        "maleWorkers": "517",
        "maleMedian": "821",
        "femaleWorkers": "24",
        "femaleMedian": "-"
      }
    }
  },
  "Heat treating equipment setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2017": {
        "total": "3",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "0",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Heat treating equipment setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Layout workers, metal and plastic": {
    "ageData": {
      "2017": {
        "total": "12",
        "16to19": "0",
        "20to24": "2",
        "25to34": "4",
        "35to44": "3",
        "45to54": "2",
        "55to64": "0",
        "65+": "1",
        "medianAge": "0"
      }
    },
    "title": "Layout workers, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "12",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "11",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Plating and coating machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2017": {
        "total": "17",
        "16to19": "0",
        "20to24": "3",
        "25to34": "2",
        "35to44": "4",
        "45to54": "3",
        "55to64": "5",
        "65+": "0",
        "medianAge": "5"
      }
    },
    "title": "Plating and coating machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "17",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "16",
        "median": "-",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Tool grinders, filers, and sharpeners": {
    "ageData": {
      "2017": {
        "total": "6",
        "16to19": "0",
        "20to24": "1",
        "25to34": "2",
        "35to44": "0",
        "45to54": "1",
        "55to64": "1",
        "65+": "1",
        "medianAge": "1"
      }
    },
    "title": "Tool grinders, filers, and sharpeners",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "6",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Metal workers and plastic workers, all other": {
    "ageData": {
      "2017": {
        "total": "388",
        "16to19": "3",
        "20to24": "21",
        "25to34": "95",
        "35to44": "79",
        "45to54": "91",
        "55to64": "83",
        "65+": "15",
        "medianAge": "83"
      }
    },
    "title": "Metal workers and plastic workers, all other",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "388",
        "women": "20.5",
        "white": "71.1",
        "black": "14.9",
        "asian": "9.5",
        "hispanicOrLatino": "23.1"
      }
    },
    "wageData": {
      "2017": {
        "workers": "381",
        "median": "654",
        "maleWorkers": "303",
        "maleMedian": "679",
        "femaleWorkers": "78",
        "femaleMedian": "574"
      }
    }
  },
  "Prepress technicians and workers": {
    "ageData": {
      "2017": {
        "total": "24",
        "16to19": "0",
        "20to24": "2",
        "25to34": "4",
        "35to44": "5",
        "45to54": "8",
        "55to64": "4",
        "65+": "1",
        "medianAge": "4"
      }
    },
    "title": "Prepress technicians and workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "24",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Printing press operators": {
    "ageData": {
      "2017": {
        "total": "173",
        "16to19": "4",
        "20to24": "14",
        "25to34": "29",
        "35to44": "39",
        "45to54": "45",
        "55to64": "35",
        "65+": "6",
        "medianAge": "35"
      }
    },
    "title": "Printing press operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "173",
        "women": "17.7",
        "white": "85.0",
        "black": "6.6",
        "asian": "5.8",
        "hispanicOrLatino": "22.2"
      }
    },
    "wageData": {
      "2017": {
        "workers": "151",
        "median": "758",
        "maleWorkers": "126",
        "maleMedian": "789",
        "femaleWorkers": "26",
        "femaleMedian": "-"
      }
    }
  },
  "Print binding and finishing workers": {
    "ageData": {
      "2017": {
        "total": "11",
        "16to19": "0",
        "20to24": "2",
        "25to34": "1",
        "35to44": "3",
        "45to54": "1",
        "55to64": "3",
        "65+": "2",
        "medianAge": "3"
      }
    },
    "title": "Print binding and finishing workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "11",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Laundry and dry-cleaning workers": {
    "ageData": {
      "2017": {
        "total": "151",
        "16to19": "4",
        "20to24": "12",
        "25to34": "21",
        "35to44": "36",
        "45to54": "36",
        "55to64": "33",
        "65+": "9",
        "medianAge": "33"
      }
    },
    "title": "Laundry and dry-cleaning workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "151",
        "women": "68.0",
        "white": "66.1",
        "black": "18.5",
        "asian": "8.0",
        "hispanicOrLatino": "38.7"
      }
    },
    "wageData": {
      "2017": {
        "workers": "106",
        "median": "450",
        "maleWorkers": "27",
        "maleMedian": "-",
        "femaleWorkers": "79",
        "femaleMedian": "443"
      }
    }
  },
  "Pressers, textile, garment, and related materials": {
    "ageData": {
      "2017": {
        "total": "30",
        "16to19": "0",
        "20to24": "2",
        "25to34": "3",
        "35to44": "11",
        "45to54": "12",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Pressers, textile, garment, and related materials",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "30",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "19",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "9",
        "femaleMedian": "-"
      }
    }
  },
  "Sewing machine operators": {
    "ageData": {
      "2017": {
        "total": "197",
        "16to19": "3",
        "20to24": "15",
        "25to34": "31",
        "35to44": "40",
        "45to54": "49",
        "55to64": "48",
        "65+": "11",
        "medianAge": "48"
      }
    },
    "title": "Sewing machine operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "197",
        "women": "73.2",
        "white": "72.0",
        "black": "8.0",
        "asian": "16.0",
        "hispanicOrLatino": "42.2"
      }
    },
    "wageData": {
      "2017": {
        "workers": "157",
        "median": "519",
        "maleWorkers": "49",
        "maleMedian": "-",
        "femaleWorkers": "108",
        "femaleMedian": "521"
      }
    }
  },
  "Shoe and leather workers and repairers": {
    "ageData": {
      "2017": {
        "total": "5",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "0",
        "45to54": "0",
        "55to64": "1",
        "65+": "2",
        "medianAge": "1"
      }
    },
    "title": "Shoe and leather workers and repairers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Shoe machine operators and tenders": {
    "ageData": {
      "2017": {
        "total": "5",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "2",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Shoe machine operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Tailors, dressmakers, and sewers": {
    "ageData": {
      "2017": {
        "total": "77",
        "16to19": "2",
        "20to24": "2",
        "25to34": "10",
        "35to44": "15",
        "45to54": "17",
        "55to64": "17",
        "65+": "14",
        "medianAge": "17"
      }
    },
    "title": "Tailors, dressmakers, and sewers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "77",
        "women": "75.9",
        "white": "79.1",
        "black": "9.5",
        "asian": "8.9",
        "hispanicOrLatino": "29.6"
      }
    },
    "wageData": {
      "2017": {
        "workers": "31",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "21",
        "femaleMedian": "-"
      }
    }
  },
  "Textile bleaching and dyeing machine operators and tenders": {
    "ageData": {
      "2017": {
        "total": "1",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Textile bleaching and dyeing machine operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "0",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Textile cutting machine setters, operators, and tenders": {
    "ageData": {
      "2017": {
        "total": "8",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "3",
        "45to54": "3",
        "55to64": "0",
        "65+": "2",
        "medianAge": "0"
      }
    },
    "title": "Textile cutting machine setters, operators, and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "8",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Textile knitting and weaving machine setters, operators, and tenders": {
    "ageData": {
      "2017": {
        "total": "19",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "2",
        "45to54": "7",
        "55to64": "6",
        "65+": "2",
        "medianAge": "6"
      }
    },
    "title": "Textile knitting and weaving machine setters, operators, and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "19",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "17",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "9",
        "femaleMedian": "-"
      }
    }
  },
  "Textile winding, twisting, and drawing out machine setters, operators, and tenders": {
    "ageData": {
      "2017": {
        "total": "16",
        "16to19": "1",
        "20to24": "1",
        "25to34": "1",
        "35to44": "7",
        "45to54": "3",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Textile winding, twisting, and drawing out machine setters, operators, and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "16",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Extruding and forming machine setters, operators, and tenders, synthetic and glass fibers": {
    "ageData": {
      "2017": {
        "total": "0",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Extruding and forming machine setters, operators, and tenders, synthetic and glass fibers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "0",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "0",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Fabric and apparel patternmakers": {
    "ageData": {
      "2017": {
        "total": "3",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "0",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Fabric and apparel patternmakers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Upholsterers": {
    "ageData": {
      "2017": {
        "total": "31",
        "16to19": "0",
        "20to24": "2",
        "25to34": "4",
        "35to44": "7",
        "45to54": "11",
        "55to64": "4",
        "65+": "3",
        "medianAge": "4"
      }
    },
    "title": "Upholsterers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "31",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "22",
        "median": "-",
        "maleWorkers": "18",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Textile, apparel, and furnishings workers, all other": {
    "ageData": {
      "2017": {
        "total": "18",
        "16to19": "1",
        "20to24": "2",
        "25to34": "3",
        "35to44": "2",
        "45to54": "5",
        "55to64": "3",
        "65+": "3",
        "medianAge": "3"
      }
    },
    "title": "Textile, apparel, and furnishings workers, all other",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "18",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "19",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "9",
        "femaleMedian": "-"
      }
    }
  },
  "Cabinetmakers and bench carpenters": {
    "ageData": {
      "2017": {
        "total": "49",
        "16to19": "0",
        "20to24": "2",
        "25to34": "13",
        "35to44": "12",
        "45to54": "7",
        "55to64": "11",
        "65+": "4",
        "medianAge": "11"
      }
    },
    "title": "Cabinetmakers and bench carpenters",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "49",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "38",
        "median": "-",
        "maleWorkers": "36",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Furniture finishers": {
    "ageData": {
      "2017": {
        "total": "15",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "2",
        "45to54": "4",
        "55to64": "3",
        "65+": "3",
        "medianAge": "3"
      }
    },
    "title": "Furniture finishers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "15",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Model makers and patternmakers, wood": {
    "ageData": {
      "2017": {
        "total": "1",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Model makers and patternmakers, wood",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Sawing machine setters, operators, and tenders, wood": {
    "ageData": {
      "2017": {
        "total": "27",
        "16to19": "1",
        "20to24": "3",
        "25to34": "8",
        "35to44": "6",
        "45to54": "5",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Sawing machine setters, operators, and tenders, wood",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "27",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "26",
        "median": "-",
        "maleWorkers": "26",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Woodworking machine setters, operators, and tenders, except sawing": {
    "ageData": {
      "2017": {
        "total": "19",
        "16to19": "0",
        "20to24": "4",
        "25to34": "5",
        "35to44": "2",
        "45to54": "2",
        "55to64": "6",
        "65+": "1",
        "medianAge": "6"
      }
    },
    "title": "Woodworking machine setters, operators, and tenders, except sawing",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "19",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "16",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Woodworkers, all other": {
    "ageData": {
      "2017": {
        "total": "28",
        "16to19": "1",
        "20to24": "2",
        "25to34": "3",
        "35to44": "6",
        "45to54": "5",
        "55to64": "8",
        "65+": "4",
        "medianAge": "8"
      }
    },
    "title": "Woodworkers, all other",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "28",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "19",
        "median": "-",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Power plant operators, distributors, and dispatchers": {
    "ageData": {
      "2017": {
        "total": "38",
        "16to19": "0",
        "20to24": "2",
        "25to34": "7",
        "35to44": "8",
        "45to54": "12",
        "55to64": "9",
        "65+": "0",
        "medianAge": "9"
      }
    },
    "title": "Power plant operators, distributors, and dispatchers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "38",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "47",
        "median": "-",
        "maleWorkers": "45",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Stationary engineers and boiler operators": {
    "ageData": {
      "2017": {
        "total": "76",
        "16to19": "0",
        "20to24": "2",
        "25to34": "11",
        "35to44": "15",
        "45to54": "24",
        "55to64": "20",
        "65+": "5",
        "medianAge": "20"
      }
    },
    "title": "Stationary engineers and boiler operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "76",
        "women": "3.3",
        "white": "84.8",
        "black": "8.2",
        "asian": "5.5",
        "hispanicOrLatino": "13.0"
      }
    },
    "wageData": {
      "2017": {
        "workers": "76",
        "median": "1,088",
        "maleWorkers": "72",
        "maleMedian": "1,142",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Water and wastewater treatment plant and system operators": {
    "ageData": {
      "2017": {
        "total": "107",
        "16to19": "0",
        "20to24": "5",
        "25to34": "24",
        "35to44": "22",
        "45to54": "26",
        "55to64": "25",
        "65+": "5",
        "medianAge": "25"
      }
    },
    "title": "Water and wastewater treatment plant and system operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "107",
        "women": "3.8",
        "white": "90.2",
        "black": "6.3",
        "asian": "1.0",
        "hispanicOrLatino": "11.5"
      }
    },
    "wageData": {
      "2017": {
        "workers": "107",
        "median": "907",
        "maleWorkers": "102",
        "maleMedian": "910",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous plant and system operators": {
    "ageData": {
      "2017": {
        "total": "42",
        "16to19": "0",
        "20to24": "3",
        "25to34": "13",
        "35to44": "9",
        "45to54": "9",
        "55to64": "8",
        "65+": "0",
        "medianAge": "8"
      }
    },
    "title": "Miscellaneous plant and system operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "42",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "41",
        "median": "-",
        "maleWorkers": "34",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Chemical processing machine setters, operators, and tenders": {
    "ageData": {
      "2017": {
        "total": "55",
        "16to19": "1",
        "20to24": "5",
        "25to34": "18",
        "35to44": "6",
        "45to54": "14",
        "55to64": "11",
        "65+": "0",
        "medianAge": "11"
      }
    },
    "title": "Chemical processing machine setters, operators, and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "55",
        "women": "5.7",
        "white": "79.3",
        "black": "15.7",
        "asian": "3.0",
        "hispanicOrLatino": "15.8"
      }
    },
    "wageData": {
      "2017": {
        "workers": "58",
        "median": "877",
        "maleWorkers": "55",
        "maleMedian": "879",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Crushing, grinding, polishing, mixing, and blending workers": {
    "ageData": {
      "2017": {
        "total": "72",
        "16to19": "2",
        "20to24": "7",
        "25to34": "21",
        "35to44": "10",
        "45to54": "19",
        "55to64": "11",
        "65+": "2",
        "medianAge": "11"
      }
    },
    "title": "Crushing, grinding, polishing, mixing, and blending workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "72",
        "women": "14.0",
        "white": "75.4",
        "black": "15.3",
        "asian": "2.2",
        "hispanicOrLatino": "15.0"
      }
    },
    "wageData": {
      "2017": {
        "workers": "70",
        "median": "771",
        "maleWorkers": "62",
        "maleMedian": "795",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Cutting workers": {
    "ageData": {
      "2017": {
        "total": "62",
        "16to19": "2",
        "20to24": "9",
        "25to34": "14",
        "35to44": "8",
        "45to54": "12",
        "55to64": "13",
        "65+": "3",
        "medianAge": "13"
      }
    },
    "title": "Cutting workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "62",
        "women": "25.3",
        "white": "78.7",
        "black": "12.4",
        "asian": "5.2",
        "hispanicOrLatino": "30.1"
      }
    },
    "wageData": {
      "2017": {
        "workers": "51",
        "median": "521",
        "maleWorkers": "40",
        "maleMedian": "-",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Extruding, forming, pressing, and compacting machine setters, operators, and tenders": {
    "ageData": {
      "2017": {
        "total": "21",
        "16to19": "1",
        "20to24": "1",
        "25to34": "4",
        "35to44": "3",
        "45to54": "10",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Extruding, forming, pressing, and compacting machine setters, operators, and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "21",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Furnace, kiln, oven, drier, and kettle operators and tenders": {
    "ageData": {
      "2017": {
        "total": "10",
        "16to19": "1",
        "20to24": "0",
        "25to34": "3",
        "35to44": "2",
        "45to54": "3",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Furnace, kiln, oven, drier, and kettle operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "10",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Inspectors, testers, sorters, samplers, and weighers": {
    "ageData": {
      "2017": {
        "total": "793",
        "16to19": "7",
        "20to24": "64",
        "25to34": "195",
        "35to44": "152",
        "45to54": "173",
        "55to64": "157",
        "65+": "45",
        "medianAge": "157"
      }
    },
    "title": "Inspectors, testers, sorters, samplers, and weighers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "793",
        "women": "37.9",
        "white": "74.7",
        "black": "13.6",
        "asian": "8.8",
        "hispanicOrLatino": "18.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "717",
        "median": "765",
        "maleWorkers": "443",
        "maleMedian": "853",
        "femaleWorkers": "275",
        "femaleMedian": "637"
      }
    }
  },
  "Jewelers and precious stone and metal workers": {
    "ageData": {
      "2017": {
        "total": "43",
        "16to19": "0",
        "20to24": "4",
        "25to34": "6",
        "35to44": "9",
        "45to54": "7",
        "55to64": "13",
        "65+": "3",
        "medianAge": "13"
      }
    },
    "title": "Jewelers and precious stone and metal workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "43",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "24",
        "median": "-",
        "maleWorkers": "20",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Medical, dental, and ophthalmic laboratory technicians": {
    "ageData": {
      "2017": {
        "total": "70",
        "16to19": "1",
        "20to24": "8",
        "25to34": "17",
        "35to44": "14",
        "45to54": "17",
        "55to64": "7",
        "65+": "6",
        "medianAge": "7"
      }
    },
    "title": "Medical, dental, and ophthalmic laboratory technicians",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "70",
        "women": "63.1",
        "white": "73.3",
        "black": "14.6",
        "asian": "9.7",
        "hispanicOrLatino": "14.4"
      }
    },
    "wageData": {
      "2017": {
        "workers": "57",
        "median": "598",
        "maleWorkers": "16",
        "maleMedian": "-",
        "femaleWorkers": "40",
        "femaleMedian": "-"
      }
    }
  },
  "Packaging and filling machine operators and tenders": {
    "ageData": {
      "2017": {
        "total": "292",
        "16to19": "6",
        "20to24": "27",
        "25to34": "75",
        "35to44": "55",
        "45to54": "68",
        "55to64": "49",
        "65+": "13",
        "medianAge": "49"
      }
    },
    "title": "Packaging and filling machine operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "292",
        "women": "52.8",
        "white": "68.5",
        "black": "17.6",
        "asian": "9.2",
        "hispanicOrLatino": "41.8"
      }
    },
    "wageData": {
      "2017": {
        "workers": "275",
        "median": "553",
        "maleWorkers": "128",
        "maleMedian": "598",
        "femaleWorkers": "147",
        "femaleMedian": "516"
      }
    }
  },
  "Painting workers": {
    "ageData": {
      "2017": {
        "total": "157",
        "16to19": "5",
        "20to24": "16",
        "25to34": "36",
        "35to44": "40",
        "45to54": "31",
        "55to64": "26",
        "65+": "3",
        "medianAge": "26"
      }
    },
    "title": "Painting workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "157",
        "women": "12.6",
        "white": "82.1",
        "black": "9.6",
        "asian": "2.5",
        "hispanicOrLatino": "32.9"
      }
    },
    "wageData": {
      "2017": {
        "workers": "131",
        "median": "693",
        "maleWorkers": "117",
        "maleMedian": "705",
        "femaleWorkers": "14",
        "femaleMedian": "-"
      }
    }
  },
  "Photographic process workers and processing machine operators": {
    "ageData": {
      "2017": {
        "total": "31",
        "16to19": "2",
        "20to24": "4",
        "25to34": "6",
        "35to44": "6",
        "45to54": "9",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Photographic process workers and processing machine operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "31",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "23",
        "median": "-",
        "maleWorkers": "16",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Semiconductor processors": {
    "ageData": {
      "2017": {
        "total": "3",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "0",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Semiconductor processors",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Adhesive bonding machine operators and tenders": {
    "ageData": {
      "2017": {
        "total": "6",
        "16to19": "0",
        "20to24": "3",
        "25to34": "0",
        "35to44": "0",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Adhesive bonding machine operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "6",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Cleaning, washing, and metal pickling equipment operators and tenders": {
    "ageData": {
      "2017": {
        "total": "11",
        "16to19": "0",
        "20to24": "2",
        "25to34": "1",
        "35to44": "0",
        "45to54": "3",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Cleaning, washing, and metal pickling equipment operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "11",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "10",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Cooling and freezing equipment operators and tenders": {
    "ageData": {
      "2017": {
        "total": "3",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Cooling and freezing equipment operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Etchers and engravers": {
    "ageData": {
      "2017": {
        "total": "11",
        "16to19": "0",
        "20to24": "1",
        "25to34": "3",
        "35to44": "1",
        "45to54": "1",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Etchers and engravers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "11",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Molders, shapers, and casters, except metal and plastic": {
    "ageData": {
      "2017": {
        "total": "35",
        "16to19": "0",
        "20to24": "4",
        "25to34": "9",
        "35to44": "10",
        "45to54": "6",
        "55to64": "5",
        "65+": "0",
        "medianAge": "5"
      }
    },
    "title": "Molders, shapers, and casters, except metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "35",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "25",
        "median": "-",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Paper goods machine setters, operators, and tenders": {
    "ageData": {
      "2017": {
        "total": "29",
        "16to19": "0",
        "20to24": "4",
        "25to34": "12",
        "35to44": "3",
        "45to54": "7",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Paper goods machine setters, operators, and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "29",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "28",
        "median": "-",
        "maleWorkers": "23",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Tire builders": {
    "ageData": {
      "2017": {
        "total": "10",
        "16to19": "0",
        "20to24": "0",
        "25to34": "5",
        "35to44": "3",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Tire builders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "10",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "10",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Helpers--production workers": {
    "ageData": {
      "2017": {
        "total": "50",
        "16to19": "5",
        "20to24": "10",
        "25to34": "12",
        "35to44": "5",
        "45to54": "11",
        "55to64": "6",
        "65+": "1",
        "medianAge": "6"
      }
    },
    "title": "Helpers--production workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "50",
        "women": "37.1",
        "white": "73.5",
        "black": "8.8",
        "asian": "11.9",
        "hispanicOrLatino": "31.2"
      }
    },
    "wageData": {
      "2017": {
        "workers": "30",
        "median": "-",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "Production workers, all other": {
    "ageData": {
      "2017": {
        "total": "995",
        "16to19": "19",
        "20to24": "107",
        "25to34": "225",
        "35to44": "212",
        "45to54": "218",
        "55to64": "176",
        "65+": "39",
        "medianAge": "176"
      }
    },
    "title": "Production workers, all other",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "995",
        "women": "29.5",
        "white": "76.5",
        "black": "15.7",
        "asian": "4.4",
        "hispanicOrLatino": "25.4"
      }
    },
    "wageData": {
      "2017": {
        "workers": "890",
        "median": "663",
        "maleWorkers": "653",
        "maleMedian": "716",
        "femaleWorkers": "238",
        "femaleMedian": "570"
      }
    }
  },
  "Transportation and material moving occupations": {
    "ageData": {
      "2017": {
        "total": "9,445",
        "16to19": "342",
        "20to24": "954",
        "25to34": "1,880",
        "35to44": "1,788",
        "45to54": "2,118",
        "55to64": "1,727",
        "65+": "637",
        "medianAge": "1,727"
      }
    },
    "title": "Transportation and material moving occupations",
    "depth": 2,
    "parent": "Production, transportation, and material moving occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "9,445",
        "women": "17.3",
        "white": "71.9",
        "black": "19.5",
        "asian": "4.5",
        "hispanicOrLatino": "21.7"
      }
    },
    "wageData": {
      "2017": {
        "workers": "7,236",
        "median": "681",
        "maleWorkers": "6,160",
        "maleMedian": "710",
        "femaleWorkers": "1,075",
        "femaleMedian": "514"
      }
    }
  },
  "Supervisors of transportation and material moving workers": {
    "ageData": {
      "2017": {
        "total": "205",
        "16to19": "2",
        "20to24": "7",
        "25to34": "44",
        "35to44": "57",
        "45to54": "49",
        "55to64": "36",
        "65+": "10",
        "medianAge": "36"
      }
    },
    "title": "Supervisors of transportation and material moving workers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "205",
        "women": "19.5",
        "white": "74.0",
        "black": "18.4",
        "asian": "4.0",
        "hispanicOrLatino": "17.4"
      }
    },
    "wageData": {
      "2017": {
        "workers": "192",
        "median": "881",
        "maleWorkers": "160",
        "maleMedian": "919",
        "femaleWorkers": "31",
        "femaleMedian": "-"
      }
    }
  },
  "Aircraft pilots and flight engineers": {
    "ageData": {
      "2017": {
        "total": "120",
        "16to19": "0",
        "20to24": "2",
        "25to34": "19",
        "35to44": "24",
        "45to54": "40",
        "55to64": "30",
        "65+": "4",
        "medianAge": "30"
      }
    },
    "title": "Aircraft pilots and flight engineers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "120",
        "women": "6.2",
        "white": "94.9",
        "black": "1.8",
        "asian": "1.5",
        "hispanicOrLatino": "8.0"
      }
    },
    "wageData": {
      "2017": {
        "workers": "112",
        "median": "1,736",
        "maleWorkers": "105",
        "maleMedian": "1,750",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Air traffic controllers and airfield operations specialists": {
    "ageData": {
      "2017": {
        "total": "17",
        "16to19": "0",
        "20to24": "0",
        "25to34": "7",
        "35to44": "3",
        "45to54": "3",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Air traffic controllers and airfield operations specialists",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "17",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "16",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Flight attendants": {
    "ageData": {
      "2017": {
        "total": "98",
        "16to19": "0",
        "20to24": "2",
        "25to34": "18",
        "35to44": "18",
        "45to54": "29",
        "55to64": "24",
        "65+": "7",
        "medianAge": "24"
      }
    },
    "title": "Flight attendants",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "98",
        "women": "72.9",
        "white": "71.6",
        "black": "15.1",
        "asian": "10.1",
        "hispanicOrLatino": "18.4"
      }
    },
    "wageData": {
      "2017": {
        "workers": "71",
        "median": "860",
        "maleWorkers": "20",
        "maleMedian": "-",
        "femaleWorkers": "51",
        "femaleMedian": "873"
      }
    }
  },
  "Ambulance drivers and attendants, except emergency medical technicians": {
    "ageData": {
      "2017": {
        "total": "11",
        "16to19": "0",
        "20to24": "4",
        "25to34": "2",
        "35to44": "0",
        "45to54": "0",
        "55to64": "2",
        "65+": "2",
        "medianAge": "2"
      }
    },
    "title": "Ambulance drivers and attendants, except emergency medical technicians",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "11",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Bus drivers": {
    "ageData": {
      "2017": {
        "total": "578",
        "16to19": "1",
        "20to24": "20",
        "25to34": "62",
        "35to44": "82",
        "45to54": "155",
        "55to64": "161",
        "65+": "97",
        "medianAge": "161"
      }
    },
    "title": "Bus drivers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "578",
        "women": "47.8",
        "white": "65.9",
        "black": "26.7",
        "asian": "4.8",
        "hispanicOrLatino": "12.6"
      }
    },
    "wageData": {
      "2017": {
        "workers": "356",
        "median": "605",
        "maleWorkers": "209",
        "maleMedian": "671",
        "femaleWorkers": "148",
        "femaleMedian": "517"
      }
    }
  },
  "Driver/sales workers and truck drivers": {
    "ageData": {
      "2017": {
        "total": "3,506",
        "16to19": "47",
        "20to24": "232",
        "25to34": "601",
        "35to44": "736",
        "45to54": "916",
        "55to64": "721",
        "65+": "251",
        "medianAge": "721"
      }
    },
    "title": "Driver/sales workers and truck drivers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "3,506",
        "women": "6.2",
        "white": "76.3",
        "black": "16.6",
        "asian": "3.2",
        "hispanicOrLatino": "20.8"
      }
    },
    "wageData": {
      "2017": {
        "workers": "2,828",
        "median": "797",
        "maleWorkers": "2,689",
        "maleMedian": "807",
        "femaleWorkers": "139",
        "femaleMedian": "589"
      }
    }
  },
  "Taxi drivers and chauffeurs": {
    "ageData": {
      "2017": {
        "total": "674",
        "16to19": "5",
        "20to24": "30",
        "25to34": "136",
        "35to44": "135",
        "45to54": "158",
        "55to64": "133",
        "65+": "76",
        "medianAge": "133"
      }
    },
    "title": "Taxi drivers and chauffeurs",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "674",
        "women": "18.9",
        "white": "55.0",
        "black": "28.4",
        "asian": "12.8",
        "hispanicOrLatino": "17.0"
      }
    },
    "wageData": {
      "2017": {
        "workers": "362",
        "median": "604",
        "maleWorkers": "308",
        "maleMedian": "623",
        "femaleWorkers": "54",
        "femaleMedian": "453"
      }
    }
  },
  "Motor vehicle operators, all other": {
    "ageData": {
      "2017": {
        "total": "76",
        "16to19": "2",
        "20to24": "10",
        "25to34": "12",
        "35to44": "5",
        "45to54": "10",
        "55to64": "17",
        "65+": "20",
        "medianAge": "17"
      }
    },
    "title": "Motor vehicle operators, all other",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "76",
        "women": "17.5",
        "white": "72.6",
        "black": "19.4",
        "asian": "4.8",
        "hispanicOrLatino": "14.7"
      }
    },
    "wageData": {
      "2017": {
        "workers": "33",
        "median": "-",
        "maleWorkers": "28",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Locomotive engineers and operators": {
    "ageData": {
      "2017": {
        "total": "42",
        "16to19": "0",
        "20to24": "1",
        "25to34": "9",
        "35to44": "11",
        "45to54": "11",
        "55to64": "10",
        "65+": "0",
        "medianAge": "10"
      }
    },
    "title": "Locomotive engineers and operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "42",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "43",
        "median": "-",
        "maleWorkers": "36",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Railroad brake, signal, and switch operators": {
    "ageData": {
      "2017": {
        "total": "4",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "2",
        "45to54": "1",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Railroad brake, signal, and switch operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Railroad conductors and yardmasters": {
    "ageData": {
      "2017": {
        "total": "50",
        "16to19": "0",
        "20to24": "2",
        "25to34": "14",
        "35to44": "13",
        "45to54": "14",
        "55to64": "5",
        "65+": "0",
        "medianAge": "5"
      }
    },
    "title": "Railroad conductors and yardmasters",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "50",
        "women": "5.2",
        "white": "73.8",
        "black": "17.6",
        "asian": "5.3",
        "hispanicOrLatino": "8.4"
      }
    },
    "wageData": {
      "2017": {
        "workers": "42",
        "median": "-",
        "maleWorkers": "39",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Subway, streetcar, and other rail transportation workers": {
    "ageData": {
      "2017": {
        "total": "15",
        "16to19": "0",
        "20to24": "1",
        "25to34": "4",
        "35to44": "2",
        "45to54": "4",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Subway, streetcar, and other rail transportation workers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "15",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "9",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Sailors and marine oilers": {
    "ageData": {
      "2017": {
        "total": "19",
        "16to19": "0",
        "20to24": "3",
        "25to34": "4",
        "35to44": "6",
        "45to54": "3",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Sailors and marine oilers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "19",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "19",
        "median": "-",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Ship and boat captains and operators": {
    "ageData": {
      "2017": {
        "total": "35",
        "16to19": "0",
        "20to24": "1",
        "25to34": "9",
        "35to44": "8",
        "45to54": "9",
        "55to64": "5",
        "65+": "3",
        "medianAge": "5"
      }
    },
    "title": "Ship and boat captains and operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "35",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "29",
        "median": "-",
        "maleWorkers": "29",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Ship engineers": {
    "ageData": {
      "2017": {
        "total": "2",
        "16to19": "0",
        "20to24": "1",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Ship engineers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Bridge and lock tenders": {
    "ageData": {
      "2017": {
        "total": "5",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "1",
        "45to54": "2",
        "55to64": "0",
        "65+": "2",
        "medianAge": "0"
      }
    },
    "title": "Bridge and lock tenders",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Parking lot attendants": {
    "ageData": {
      "2017": {
        "total": "95",
        "16to19": "8",
        "20to24": "21",
        "25to34": "19",
        "35to44": "17",
        "45to54": "14",
        "55to64": "8",
        "65+": "8",
        "medianAge": "8"
      }
    },
    "title": "Parking lot attendants",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "95",
        "women": "16.7",
        "white": "67.4",
        "black": "23.3",
        "asian": "6.7",
        "hispanicOrLatino": "38.4"
      }
    },
    "wageData": {
      "2017": {
        "workers": "65",
        "median": "560",
        "maleWorkers": "49",
        "maleMedian": "-",
        "femaleWorkers": "16",
        "femaleMedian": "-"
      }
    }
  },
  "Automotive and watercraft service attendants": {
    "ageData": {
      "2017": {
        "total": "94",
        "16to19": "15",
        "20to24": "16",
        "25to34": "19",
        "35to44": "15",
        "45to54": "15",
        "55to64": "12",
        "65+": "2",
        "medianAge": "12"
      }
    },
    "title": "Automotive and watercraft service attendants",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "94",
        "women": "8.8",
        "white": "73.6",
        "black": "9.6",
        "asian": "8.9",
        "hispanicOrLatino": "21.1"
      }
    },
    "wageData": {
      "2017": {
        "workers": "80",
        "median": "514",
        "maleWorkers": "73",
        "maleMedian": "535",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Transportation inspectors": {
    "ageData": {
      "2017": {
        "total": "41",
        "16to19": "0",
        "20to24": "0",
        "25to34": "8",
        "35to44": "4",
        "45to54": "12",
        "55to64": "14",
        "65+": "2",
        "medianAge": "14"
      }
    },
    "title": "Transportation inspectors",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "41",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "41",
        "median": "-",
        "maleWorkers": "33",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Transportation attendants, except flight attendants": {
    "ageData": {
      "2017": {
        "total": "35",
        "16to19": "0",
        "20to24": "2",
        "25to34": "8",
        "35to44": "6",
        "45to54": "6",
        "55to64": "7",
        "65+": "7",
        "medianAge": "7"
      }
    },
    "title": "Transportation attendants, except flight attendants",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "35",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "21",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Other transportation workers": {
    "ageData": {
      "2017": {
        "total": "24",
        "16to19": "1",
        "20to24": "2",
        "25to34": "5",
        "35to44": "3",
        "45to54": "9",
        "55to64": "5",
        "65+": "0",
        "medianAge": "5"
      }
    },
    "title": "Other transportation workers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "24",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "20",
        "median": "-",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Conveyor operators and tenders": {
    "ageData": {
      "2017": {
        "total": "1",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Conveyor operators and tenders",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Crane and tower operators": {
    "ageData": {
      "2017": {
        "total": "53",
        "16to19": "0",
        "20to24": "4",
        "25to34": "17",
        "35to44": "9",
        "45to54": "12",
        "55to64": "5",
        "65+": "6",
        "medianAge": "5"
      }
    },
    "title": "Crane and tower operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "53",
        "women": "4.1",
        "white": "76.8",
        "black": "20.9",
        "asian": "0.1",
        "hispanicOrLatino": "10.5"
      }
    },
    "wageData": {
      "2017": {
        "workers": "52",
        "median": "902",
        "maleWorkers": "51",
        "maleMedian": "898",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Dredge, excavating, and loading machine operators": {
    "ageData": {
      "2017": {
        "total": "30",
        "16to19": "0",
        "20to24": "2",
        "25to34": "7",
        "35to44": "7",
        "45to54": "6",
        "55to64": "4",
        "65+": "3",
        "medianAge": "4"
      }
    },
    "title": "Dredge, excavating, and loading machine operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "30",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "29",
        "median": "-",
        "maleWorkers": "28",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Hoist and winch operators": {
    "ageData": {
      "2017": {
        "total": "7",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "1",
        "45to54": "0",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Hoist and winch operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Industrial truck and tractor operators": {
    "ageData": {
      "2017": {
        "total": "611",
        "16to19": "6",
        "20to24": "68",
        "25to34": "151",
        "35to44": "112",
        "45to54": "142",
        "55to64": "119",
        "65+": "13",
        "medianAge": "119"
      }
    },
    "title": "Industrial truck and tractor operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "611",
        "women": "8.2",
        "white": "69.4",
        "black": "25.0",
        "asian": "1.8",
        "hispanicOrLatino": "25.7"
      }
    },
    "wageData": {
      "2017": {
        "workers": "584",
        "median": "624",
        "maleWorkers": "538",
        "maleMedian": "632",
        "femaleWorkers": "46",
        "femaleMedian": "-"
      }
    }
  },
  "Cleaners of vehicles and equipment": {
    "ageData": {
      "2017": {
        "total": "331",
        "16to19": "42",
        "20to24": "72",
        "25to34": "74",
        "35to44": "49",
        "45to54": "45",
        "55to64": "42",
        "65+": "9",
        "medianAge": "42"
      }
    },
    "title": "Cleaners of vehicles and equipment",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "331",
        "women": "14.0",
        "white": "69.7",
        "black": "20.7",
        "asian": "3.0",
        "hispanicOrLatino": "33.3"
      }
    },
    "wageData": {
      "2017": {
        "workers": "237",
        "median": "530",
        "maleWorkers": "197",
        "maleMedian": "531",
        "femaleWorkers": "40",
        "femaleMedian": "-"
      }
    }
  },
  "Laborers and freight, stock, and material movers, hand": {
    "ageData": {
      "2017": {
        "total": "1,930",
        "16to19": "146",
        "20to24": "360",
        "25to34": "479",
        "35to44": "314",
        "45to54": "309",
        "55to64": "256",
        "65+": "67",
        "medianAge": "256"
      }
    },
    "title": "Laborers and freight, stock, and material movers, hand",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "1,930",
        "women": "19.9",
        "white": "70.2",
        "black": "20.2",
        "asian": "3.8",
        "hispanicOrLatino": "22.2"
      }
    },
    "wageData": {
      "2017": {
        "workers": "1,450",
        "median": "578",
        "maleWorkers": "1,196",
        "maleMedian": "595",
        "femaleWorkers": "254",
        "femaleMedian": "500"
      }
    }
  },
  "Machine feeders and offbearers": {
    "ageData": {
      "2017": {
        "total": "31",
        "16to19": "2",
        "20to24": "5",
        "25to34": "6",
        "35to44": "5",
        "45to54": "5",
        "55to64": "6",
        "65+": "2",
        "medianAge": "6"
      }
    },
    "title": "Machine feeders and offbearers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "31",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "29",
        "median": "-",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "15",
        "femaleMedian": "-"
      }
    }
  },
  "Packers and packagers, hand": {
    "ageData": {
      "2017": {
        "total": "539",
        "16to19": "60",
        "20to24": "70",
        "25to34": "112",
        "35to44": "106",
        "45to54": "98",
        "55to64": "68",
        "65+": "24",
        "medianAge": "68"
      }
    },
    "title": "Packers and packagers, hand",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "539",
        "women": "52.7",
        "white": "69.9",
        "black": "18.9",
        "asian": "8.4",
        "hispanicOrLatino": "41.8"
      }
    },
    "wageData": {
      "2017": {
        "workers": "368",
        "median": "480",
        "maleWorkers": "158",
        "maleMedian": "500",
        "femaleWorkers": "210",
        "femaleMedian": "462"
      }
    }
  },
  "Pumping station operators": {
    "ageData": {
      "2017": {
        "total": "21",
        "16to19": "0",
        "20to24": "1",
        "25to34": "6",
        "35to44": "5",
        "45to54": "4",
        "55to64": "3",
        "65+": "2",
        "medianAge": "3"
      }
    },
    "title": "Pumping station operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "21",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "19",
        "median": "-",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Refuse and recyclable material collectors": {
    "ageData": {
      "2017": {
        "total": "94",
        "16to19": "3",
        "20to24": "7",
        "25to34": "18",
        "35to44": "20",
        "45to54": "25",
        "55to64": "12",
        "65+": "9",
        "medianAge": "12"
      }
    },
    "title": "Refuse and recyclable material collectors",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "94",
        "women": "11.7",
        "white": "70.2",
        "black": "26.8",
        "asian": "2.1",
        "hispanicOrLatino": "24.2"
      }
    },
    "wageData": {
      "2017": {
        "workers": "56",
        "median": "522",
        "maleWorkers": "49",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Mine shuttle car operators": {
    "ageData": {
      "2017": {
        "total": "0",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Mine shuttle car operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "0",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "0",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Tank car, truck, and ship loaders": {
    "ageData": {
      "2017": {
        "total": "6",
        "16to19": "0",
        "20to24": "2",
        "25to34": "0",
        "35to44": "2",
        "45to54": "0",
        "55to64": "0",
        "65+": "1",
        "medianAge": "0"
      }
    },
    "title": "Tank car, truck, and ship loaders",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "6",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Material moving workers, all other": {
    "ageData": {
      "2017": {
        "total": "40",
        "16to19": "0",
        "20to24": "5",
        "25to34": "5",
        "35to44": "8",
        "45to54": "9",
        "55to64": "7",
        "65+": "6",
        "medianAge": "7"
      }
    },
    "title": "Material moving workers, all other",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2017": {
        "total": "40",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2017": {
        "workers": "41",
        "median": "-",
        "maleWorkers": "34",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  }
}