import React from 'react'

import fs from 'fs'
import { Parser } from './jsxer'

const mainPageHtml = fs.readFileSync('src/pages/Home/content.html', 'utf8')

export const Home = () => {
  return <Parser html={mainPageHtml} />
}
