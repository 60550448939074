{
  "Total": {
    "ageData": {
      "2014": {
        "total": "146,305",
        "16to19": "4,548",
        "20to24": "13,894",
        "25to34": "31,975",
        "35to44": "30,966",
        "45to54": "32,556",
        "55to64": "24,395",
        "65+": "7,971",
        "medianAge": "24,395"
      }
    },
    "title": "Total",
    "depth": 1,
    "parent": "",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "146,305",
        "women": "46.9",
        "white": "11.4",
        "black": "5.7",
        "asian": "16.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "106,526",
        "median": "$791",
        "maleWorkers": "59,450",
        "maleMedian": "$871",
        "femaleWorkers": "47,076",
        "femaleMedian": "$719"
      }
    }
  },
  "Management, professional, and related occupations": {
    "ageData": {
      "2014": {
        "total": "56,050",
        "16to19": "323",
        "20to24": "3,052",
        "25to34": "12,587",
        "35to44": "13,278",
        "45to54": "13,205",
        "55to64": "10,164",
        "65+": "3,441",
        "medianAge": "10,164"
      }
    },
    "title": "Management, professional, and related occupations",
    "depth": 1,
    "parent": "",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "56,050",
        "women": "51.6",
        "white": "8.8",
        "black": "7.5",
        "asian": "8.7"
      }
    },
    "wageData": {
      "2014": {
        "workers": "43,016",
        "median": "1,137",
        "maleWorkers": "20,758",
        "maleMedian": "1,346",
        "femaleWorkers": "22,258",
        "femaleMedian": "981"
      }
    }
  },
  "Management, business, and financial operations occupations": {
    "ageData": {
      "2014": {
        "total": "23,171",
        "16to19": "64",
        "20to24": "963",
        "25to34": "4,431",
        "35to44": "5,471",
        "45to54": "6,058",
        "55to64": "4,588",
        "65+": "1,596",
        "medianAge": "4,588"
      }
    },
    "title": "Management, business, and financial operations occupations",
    "depth": 2,
    "parent": "Management, professional, and related occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "23,171",
        "women": "43.7",
        "white": "7.5",
        "black": "6.1",
        "asian": "8.9"
      }
    },
    "wageData": {
      "2014": {
        "workers": "17,561",
        "median": "1,227",
        "maleWorkers": "9,385",
        "maleMedian": "1,416",
        "femaleWorkers": "8,176",
        "femaleMedian": "1,056"
      }
    }
  },
  "Management occupations": {
    "ageData": {
      "2014": {
        "total": "16,199",
        "16to19": "45",
        "20to24": "520",
        "25to34": "2,753",
        "35to44": "3,847",
        "45to54": "4,484",
        "55to64": "3,366",
        "65+": "1,183",
        "medianAge": "3,366"
      }
    },
    "title": "Management occupations",
    "depth": 3,
    "parent": "Management, business, and financial operations occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "16,199",
        "women": "38.6",
        "white": "6.7",
        "black": "5.4",
        "asian": "9.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "11,881",
        "median": "1,295",
        "maleWorkers": "6,943",
        "maleMedian": "1,454",
        "femaleWorkers": "4,938",
        "femaleMedian": "1,127"
      }
    }
  },
  "Chief executives": {
    "ageData": {
      "2014": {
        "total": "1,603",
        "16to19": "1",
        "20to24": "11",
        "25to34": "134",
        "35to44": "289",
        "45to54": "518",
        "55to64": "487",
        "65+": "163",
        "medianAge": "487"
      }
    },
    "title": "Chief executives",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "1,603",
        "women": "26.3",
        "white": "3.0",
        "black": "4.7",
        "asian": "4.7"
      }
    },
    "wageData": {
      "2014": {
        "workers": "1,077",
        "median": "2,023",
        "maleWorkers": "795",
        "maleMedian": "2,246",
        "femaleWorkers": "283",
        "femaleMedian": "1,572"
      }
    }
  },
  "General and operations managers": {
    "ageData": {
      "2014": {
        "total": "887",
        "16to19": "0",
        "20to24": "25",
        "25to34": "177",
        "35to44": "233",
        "45to54": "260",
        "55to64": "161",
        "65+": "31",
        "medianAge": "161"
      }
    },
    "title": "General and operations managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "887",
        "women": "29.5",
        "white": "6.2",
        "black": "3.9",
        "asian": "11.5"
      }
    },
    "wageData": {
      "2014": {
        "workers": "843",
        "median": "1,276",
        "maleWorkers": "587",
        "maleMedian": "1,350",
        "femaleWorkers": "256",
        "femaleMedian": "1,136"
      }
    }
  },
  "Legislators": {
    "ageData": {
      "2014": {
        "total": "17",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "5",
        "45to54": "3",
        "55to64": "3",
        "65+": "5",
        "medianAge": "3"
      }
    },
    "title": "Legislators",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "17",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "16",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Advertising and promotions managers": {
    "ageData": {
      "2014": {
        "total": "52",
        "16to19": "0",
        "20to24": "4",
        "25to34": "23",
        "35to44": "11",
        "45to54": "8",
        "55to64": "5",
        "65+": "1",
        "medianAge": "5"
      }
    },
    "title": "Advertising and promotions managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "52",
        "women": "57.9",
        "white": "6.7",
        "black": "4.9",
        "asian": "4.6"
      }
    },
    "wageData": {
      "2014": {
        "workers": "47",
        "median": "-",
        "maleWorkers": "20",
        "maleMedian": "-",
        "femaleWorkers": "27",
        "femaleMedian": "-"
      }
    }
  },
  "Marketing and sales managers": {
    "ageData": {
      "2014": {
        "total": "917",
        "16to19": "0",
        "20to24": "40",
        "25to34": "227",
        "35to44": "263",
        "45to54": "225",
        "55to64": "137",
        "65+": "26",
        "medianAge": "137"
      }
    },
    "title": "Marketing and sales managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "917",
        "women": "46.1",
        "white": "3.9",
        "black": "5.9",
        "asian": "8.3"
      }
    },
    "wageData": {
      "2014": {
        "workers": "823",
        "median": "1,405",
        "maleWorkers": "451",
        "maleMedian": "1,624",
        "femaleWorkers": "372",
        "femaleMedian": "1,150"
      }
    }
  },
  "Public relations and fundraising managers": {
    "ageData": {
      "2014": {
        "total": "71",
        "16to19": "0",
        "20to24": "4",
        "25to34": "21",
        "35to44": "13",
        "45to54": "16",
        "55to64": "17",
        "65+": "2",
        "medianAge": "17"
      }
    },
    "title": "Public relations and fundraising managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "71",
        "women": "60.0",
        "white": "7.7",
        "black": "7.9",
        "asian": "12.9"
      }
    },
    "wageData": {
      "2014": {
        "workers": "69",
        "median": "1,344",
        "maleWorkers": "28",
        "maleMedian": "-",
        "femaleWorkers": "41",
        "femaleMedian": "-"
      }
    }
  },
  "Administrative services managers": {
    "ageData": {
      "2014": {
        "total": "134",
        "16to19": "1",
        "20to24": "3",
        "25to34": "15",
        "35to44": "29",
        "45to54": "39",
        "55to64": "40",
        "65+": "8",
        "medianAge": "40"
      }
    },
    "title": "Administrative services managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "134",
        "women": "40.8",
        "white": "8.1",
        "black": "2.7",
        "asian": "9.0"
      }
    },
    "wageData": {
      "2014": {
        "workers": "114",
        "median": "1,163",
        "maleWorkers": "66",
        "maleMedian": "1,165",
        "femaleWorkers": "48",
        "femaleMedian": "-"
      }
    }
  },
  "Computer and information systems managers": {
    "ageData": {
      "2014": {
        "total": "629",
        "16to19": "1",
        "20to24": "5",
        "25to34": "111",
        "35to44": "195",
        "45to54": "195",
        "55to64": "103",
        "65+": "18",
        "medianAge": "103"
      }
    },
    "title": "Computer and information systems managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "629",
        "women": "26.7",
        "white": "6.3",
        "black": "11.6",
        "asian": "4.9"
      }
    },
    "wageData": {
      "2014": {
        "workers": "623",
        "median": "1,730",
        "maleWorkers": "453",
        "maleMedian": "1,763",
        "femaleWorkers": "169",
        "femaleMedian": "1,529"
      }
    }
  },
  "Financial managers": {
    "ageData": {
      "2014": {
        "total": "1,194",
        "16to19": "0",
        "20to24": "41",
        "25to34": "259",
        "35to44": "303",
        "45to54": "330",
        "55to64": "210",
        "65+": "50",
        "medianAge": "210"
      }
    },
    "title": "Financial managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "1,194",
        "women": "53.4",
        "white": "7.8",
        "black": "6.9",
        "asian": "10.5"
      }
    },
    "wageData": {
      "2014": {
        "workers": "1,133",
        "median": "1,325",
        "maleWorkers": "527",
        "maleMedian": "1,671",
        "femaleWorkers": "606",
        "femaleMedian": "1,127"
      }
    }
  },
  "Compensation and benefits managers": {
    "ageData": {
      "2014": {
        "total": "17",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "2",
        "45to54": "9",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Compensation and benefits managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "17",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "16",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Human resources managers": {
    "ageData": {
      "2014": {
        "total": "236",
        "16to19": "0",
        "20to24": "5",
        "25to34": "30",
        "35to44": "72",
        "45to54": "80",
        "55to64": "39",
        "65+": "10",
        "medianAge": "39"
      }
    },
    "title": "Human resources managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "236",
        "women": "74.4",
        "white": "11.8",
        "black": "3.3",
        "asian": "10.0"
      }
    },
    "wageData": {
      "2014": {
        "workers": "234",
        "median": "1,382",
        "maleWorkers": "56",
        "maleMedian": "1,827",
        "femaleWorkers": "178",
        "femaleMedian": "1,300"
      }
    }
  },
  "Training and development managers": {
    "ageData": {
      "2014": {
        "total": "38",
        "16to19": "0",
        "20to24": "2",
        "25to34": "8",
        "35to44": "14",
        "45to54": "8",
        "55to64": "6",
        "65+": "0",
        "medianAge": "6"
      }
    },
    "title": "Training and development managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "38",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "40",
        "median": "-",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "20",
        "femaleMedian": "-"
      }
    }
  },
  "Industrial production managers": {
    "ageData": {
      "2014": {
        "total": "273",
        "16to19": "0",
        "20to24": "5",
        "25to34": "39",
        "35to44": "70",
        "45to54": "88",
        "55to64": "63",
        "65+": "8",
        "medianAge": "63"
      }
    },
    "title": "Industrial production managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "273",
        "women": "17.8",
        "white": "4.2",
        "black": "3.6",
        "asian": "8.4"
      }
    },
    "wageData": {
      "2014": {
        "workers": "263",
        "median": "1,331",
        "maleWorkers": "220",
        "maleMedian": "1,383",
        "femaleWorkers": "42",
        "femaleMedian": "-"
      }
    }
  },
  "Purchasing managers": {
    "ageData": {
      "2014": {
        "total": "193",
        "16to19": "0",
        "20to24": "5",
        "25to34": "35",
        "35to44": "51",
        "45to54": "49",
        "55to64": "44",
        "65+": "9",
        "medianAge": "44"
      }
    },
    "title": "Purchasing managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "193",
        "women": "42.6",
        "white": "7.6",
        "black": "8.9",
        "asian": "11.6"
      }
    },
    "wageData": {
      "2014": {
        "workers": "180",
        "median": "1,329",
        "maleWorkers": "103",
        "maleMedian": "1,366",
        "femaleWorkers": "77",
        "femaleMedian": "1,276"
      }
    }
  },
  "Transportation, storage, and distribution managers": {
    "ageData": {
      "2014": {
        "total": "260",
        "16to19": "1",
        "20to24": "11",
        "25to34": "59",
        "35to44": "74",
        "45to54": "64",
        "55to64": "41",
        "65+": "10",
        "medianAge": "41"
      }
    },
    "title": "Transportation, storage, and distribution managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "260",
        "women": "21.6",
        "white": "7.0",
        "black": "6.1",
        "asian": "12.4"
      }
    },
    "wageData": {
      "2014": {
        "workers": "239",
        "median": "991",
        "maleWorkers": "188",
        "maleMedian": "1,014",
        "femaleWorkers": "51",
        "femaleMedian": "836"
      }
    }
  },
  "Farmers, ranchers, and other agricultural managers": {
    "ageData": {
      "2014": {
        "total": "941",
        "16to19": "5",
        "20to24": "24",
        "25to34": "99",
        "35to44": "123",
        "45to54": "182",
        "55to64": "237",
        "65+": "271",
        "medianAge": "237"
      }
    },
    "title": "Farmers, ranchers, and other agricultural managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "941",
        "women": "23.8",
        "white": "0.9",
        "black": "0.8",
        "asian": "4.2"
      }
    },
    "wageData": {
      "2014": {
        "workers": "106",
        "median": "812",
        "maleWorkers": "91",
        "maleMedian": "818",
        "femaleWorkers": "15",
        "femaleMedian": "-"
      }
    }
  },
  "Construction managers": {
    "ageData": {
      "2014": {
        "total": "711",
        "16to19": "1",
        "20to24": "14",
        "25to34": "123",
        "35to44": "198",
        "45to54": "200",
        "55to64": "142",
        "65+": "33",
        "medianAge": "142"
      }
    },
    "title": "Construction managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "711",
        "women": "7.4",
        "white": "3.7",
        "black": "2.3",
        "asian": "10.2"
      }
    },
    "wageData": {
      "2014": {
        "workers": "428",
        "median": "1,304",
        "maleWorkers": "395",
        "maleMedian": "1,332",
        "femaleWorkers": "33",
        "femaleMedian": "-"
      }
    }
  },
  "Education administrators": {
    "ageData": {
      "2014": {
        "total": "838",
        "16to19": "1",
        "20to24": "17",
        "25to34": "133",
        "35to44": "236",
        "45to54": "226",
        "55to64": "187",
        "65+": "38",
        "medianAge": "187"
      }
    },
    "title": "Education administrators",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "838",
        "women": "63.3",
        "white": "13.3",
        "black": "2.1",
        "asian": "9.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "730",
        "median": "1,272",
        "maleWorkers": "279",
        "maleMedian": "1,439",
        "femaleWorkers": "451",
        "femaleMedian": "1,171"
      }
    }
  },
  "Architectural and engineering managers": {
    "ageData": {
      "2014": {
        "total": "122",
        "16to19": "0",
        "20to24": "0",
        "25to34": "16",
        "35to44": "26",
        "45to54": "48",
        "55to64": "29",
        "65+": "2",
        "medianAge": "29"
      }
    },
    "title": "Architectural and engineering managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "122",
        "women": "7.6",
        "white": "2.7",
        "black": "9.9",
        "asian": "8.4"
      }
    },
    "wageData": {
      "2014": {
        "workers": "110",
        "median": "1,945",
        "maleWorkers": "103",
        "maleMedian": "1,975",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Food service managers": {
    "ageData": {
      "2014": {
        "total": "1,113",
        "16to19": "21",
        "20to24": "143",
        "25to34": "287",
        "35to44": "269",
        "45to54": "208",
        "55to64": "144",
        "65+": "41",
        "medianAge": "144"
      }
    },
    "title": "Food service managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "1,113",
        "women": "44.9",
        "white": "9.8",
        "black": "10.0",
        "asian": "16.4"
      }
    },
    "wageData": {
      "2014": {
        "workers": "740",
        "median": "704",
        "maleWorkers": "398",
        "maleMedian": "806",
        "femaleWorkers": "342",
        "femaleMedian": "595"
      }
    }
  },
  "Funeral service managers": {
    "ageData": {
      "2014": {
        "total": "13",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "2",
        "45to54": "4",
        "55to64": "2",
        "65+": "4",
        "medianAge": "2"
      }
    },
    "title": "Funeral service managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "13",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Gaming managers": {
    "ageData": {
      "2014": {
        "total": "28",
        "16to19": "0",
        "20to24": "3",
        "25to34": "3",
        "35to44": "8",
        "45to54": "8",
        "55to64": "5",
        "65+": "1",
        "medianAge": "5"
      }
    },
    "title": "Gaming managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "28",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "15",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Lodging managers": {
    "ageData": {
      "2014": {
        "total": "146",
        "16to19": "1",
        "20to24": "13",
        "25to34": "28",
        "35to44": "32",
        "45to54": "36",
        "55to64": "25",
        "65+": "11",
        "medianAge": "25"
      }
    },
    "title": "Lodging managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "146",
        "women": "54.6",
        "white": "8.1",
        "black": "15.5",
        "asian": "7.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "107",
        "median": "764",
        "maleWorkers": "47",
        "maleMedian": "-",
        "femaleWorkers": "60",
        "femaleMedian": "809"
      }
    }
  },
  "Medical and health services managers": {
    "ageData": {
      "2014": {
        "total": "593",
        "16to19": "0",
        "20to24": "9",
        "25to34": "99",
        "35to44": "155",
        "45to54": "160",
        "55to64": "137",
        "65+": "33",
        "medianAge": "137"
      }
    },
    "title": "Medical and health services managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "593",
        "women": "71.8",
        "white": "11.0",
        "black": "5.5",
        "asian": "8.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "541",
        "median": "1,266",
        "maleWorkers": "153",
        "maleMedian": "1,448",
        "femaleWorkers": "388",
        "femaleMedian": "1,215"
      }
    }
  },
  "Natural sciences managers": {
    "ageData": {
      "2014": {
        "total": "18",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "7",
        "45to54": "5",
        "55to64": "2",
        "65+": "2",
        "medianAge": "2"
      }
    },
    "title": "Natural sciences managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "18",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "18",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Postmasters and mail superintendents": {
    "ageData": {
      "2014": {
        "total": "33",
        "16to19": "0",
        "20to24": "3",
        "25to34": "3",
        "35to44": "7",
        "45to54": "11",
        "55to64": "7",
        "65+": "1",
        "medianAge": "7"
      }
    },
    "title": "Postmasters and mail superintendents",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "33",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "29",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "17",
        "femaleMedian": "-"
      }
    }
  },
  "Property, real estate, and community association managers": {
    "ageData": {
      "2014": {
        "total": "674",
        "16to19": "1",
        "20to24": "23",
        "25to34": "117",
        "35to44": "133",
        "45to54": "148",
        "55to64": "164",
        "65+": "89",
        "medianAge": "164"
      }
    },
    "title": "Property, real estate, and community association managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "674",
        "women": "48.9",
        "white": "7.5",
        "black": "3.1",
        "asian": "12.9"
      }
    },
    "wageData": {
      "2014": {
        "workers": "385",
        "median": "919",
        "maleWorkers": "167",
        "maleMedian": "976",
        "femaleWorkers": "218",
        "femaleMedian": "865"
      }
    }
  },
  "Social and community service managers": {
    "ageData": {
      "2014": {
        "total": "362",
        "16to19": "2",
        "20to24": "13",
        "25to34": "76",
        "35to44": "77",
        "45to54": "89",
        "55to64": "74",
        "65+": "30",
        "medianAge": "74"
      }
    },
    "title": "Social and community service managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "362",
        "women": "65.3",
        "white": "14.6",
        "black": "5.3",
        "asian": "7.6"
      }
    },
    "wageData": {
      "2014": {
        "workers": "332",
        "median": "1,008",
        "maleWorkers": "125",
        "maleMedian": "1,142",
        "femaleWorkers": "207",
        "femaleMedian": "978"
      }
    }
  },
  "Emergency management directors": {
    "ageData": {
      "2014": {
        "total": "8",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "3",
        "45to54": "1",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Emergency management directors",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "8",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Managers, all other": {
    "ageData": {
      "2014": {
        "total": "4,075",
        "16to19": "9",
        "20to24": "96",
        "25to34": "626",
        "35to44": "948",
        "45to54": "1,265",
        "55to64": "848",
        "65+": "284",
        "medianAge": "848"
      }
    },
    "title": "Managers, all other",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "4,075",
        "women": "33.3",
        "white": "6.6",
        "black": "5.5",
        "asian": "9.2"
      }
    },
    "wageData": {
      "2014": {
        "workers": "2,609",
        "median": "1,322",
        "maleWorkers": "1,626",
        "maleMedian": "1,412",
        "femaleWorkers": "983",
        "femaleMedian": "1,153"
      }
    }
  },
  "Business and financial operations occupations": {
    "ageData": {
      "2014": {
        "total": "6,972",
        "16to19": "19",
        "20to24": "442",
        "25to34": "1,678",
        "35to44": "1,623",
        "45to54": "1,574",
        "55to64": "1,222",
        "65+": "413",
        "medianAge": "1,222"
      }
    },
    "title": "Business and financial operations occupations",
    "depth": 3,
    "parent": "Management, business, and financial operations occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "6,972",
        "women": "55.7",
        "white": "9.5",
        "black": "7.6",
        "asian": "8.4"
      }
    },
    "wageData": {
      "2014": {
        "workers": "5,681",
        "median": "1,107",
        "maleWorkers": "2,442",
        "maleMedian": "1,310",
        "femaleWorkers": "3,238",
        "femaleMedian": "982"
      }
    }
  },
  "Agents and business managers of artists, performers, and athletes": {
    "ageData": {
      "2014": {
        "total": "52",
        "16to19": "0",
        "20to24": "4",
        "25to34": "18",
        "35to44": "9",
        "45to54": "9",
        "55to64": "11",
        "65+": "2",
        "medianAge": "11"
      }
    },
    "title": "Agents and business managers of artists, performers, and athletes",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "52",
        "women": "48.9",
        "white": "9.7",
        "black": "5.7",
        "asian": "8.3"
      }
    },
    "wageData": {
      "2014": {
        "workers": "35",
        "median": "-",
        "maleWorkers": "22",
        "maleMedian": "-",
        "femaleWorkers": "14",
        "femaleMedian": "-"
      }
    }
  },
  "Buyers and purchasing agents, farm products": {
    "ageData": {
      "2014": {
        "total": "15",
        "16to19": "0",
        "20to24": "2",
        "25to34": "3",
        "35to44": "3",
        "45to54": "2",
        "55to64": "3",
        "65+": "2",
        "medianAge": "3"
      }
    },
    "title": "Buyers and purchasing agents, farm products",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "15",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Wholesale and retail buyers, except farm products": {
    "ageData": {
      "2014": {
        "total": "216",
        "16to19": "2",
        "20to24": "25",
        "25to34": "43",
        "35to44": "51",
        "45to54": "47",
        "55to64": "36",
        "65+": "11",
        "medianAge": "36"
      }
    },
    "title": "Wholesale and retail buyers, except farm products",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "216",
        "women": "53.4",
        "white": "6.1",
        "black": "7.0",
        "asian": "8.8"
      }
    },
    "wageData": {
      "2014": {
        "workers": "143",
        "median": "859",
        "maleWorkers": "69",
        "maleMedian": "970",
        "femaleWorkers": "74",
        "femaleMedian": "806"
      }
    }
  },
  "Purchasing agents, except wholesale, retail, and farm products": {
    "ageData": {
      "2014": {
        "total": "271",
        "16to19": "1",
        "20to24": "13",
        "25to34": "52",
        "35to44": "56",
        "45to54": "72",
        "55to64": "60",
        "65+": "15",
        "medianAge": "60"
      }
    },
    "title": "Purchasing agents, except wholesale, retail, and farm products",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "271",
        "women": "55.3",
        "white": "10.8",
        "black": "3.5",
        "asian": "9.0"
      }
    },
    "wageData": {
      "2014": {
        "workers": "244",
        "median": "945",
        "maleWorkers": "105",
        "maleMedian": "1,054",
        "femaleWorkers": "139",
        "femaleMedian": "897"
      }
    }
  },
  "Claims adjusters, appraisers, examiners, and investigators": {
    "ageData": {
      "2014": {
        "total": "311",
        "16to19": "0",
        "20to24": "13",
        "25to34": "68",
        "35to44": "90",
        "45to54": "75",
        "55to64": "52",
        "65+": "13",
        "medianAge": "52"
      }
    },
    "title": "Claims adjusters, appraisers, examiners, and investigators",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "311",
        "women": "60.3",
        "white": "15.2",
        "black": "3.3",
        "asian": "9.7"
      }
    },
    "wageData": {
      "2014": {
        "workers": "285",
        "median": "989",
        "maleWorkers": "110",
        "maleMedian": "1,093",
        "femaleWorkers": "175",
        "femaleMedian": "908"
      }
    }
  },
  "Compliance officers": {
    "ageData": {
      "2014": {
        "total": "239",
        "16to19": "0",
        "20to24": "6",
        "25to34": "46",
        "35to44": "58",
        "45to54": "64",
        "55to64": "52",
        "65+": "13",
        "medianAge": "52"
      }
    },
    "title": "Compliance officers",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "239",
        "women": "46.4",
        "white": "10.4",
        "black": "5.3",
        "asian": "9.3"
      }
    },
    "wageData": {
      "2014": {
        "workers": "226",
        "median": "1,157",
        "maleWorkers": "118",
        "maleMedian": "1,237",
        "femaleWorkers": "108",
        "femaleMedian": "1,092"
      }
    }
  },
  "Cost estimators": {
    "ageData": {
      "2014": {
        "total": "105",
        "16to19": "0",
        "20to24": "4",
        "25to34": "24",
        "35to44": "19",
        "45to54": "20",
        "55to64": "30",
        "65+": "7",
        "medianAge": "30"
      }
    },
    "title": "Cost estimators",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "105",
        "women": "13.7",
        "white": "2.4",
        "black": "3.8",
        "asian": "5.6"
      }
    },
    "wageData": {
      "2014": {
        "workers": "97",
        "median": "1,059",
        "maleWorkers": "85",
        "maleMedian": "1,074",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Human resources workers": {
    "ageData": {
      "2014": {
        "total": "615",
        "16to19": "2",
        "20to24": "43",
        "25to34": "172",
        "35to44": "147",
        "45to54": "126",
        "55to64": "93",
        "65+": "32",
        "medianAge": "93"
      }
    },
    "title": "Human resources workers",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "615",
        "women": "71.8",
        "white": "13.3",
        "black": "4.0",
        "asian": "12.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "550",
        "median": "962",
        "maleWorkers": "147",
        "maleMedian": "1,257",
        "femaleWorkers": "403",
        "femaleMedian": "912"
      }
    }
  },
  "Compensation, benefits, and job analysis specialists": {
    "ageData": {
      "2014": {
        "total": "71",
        "16to19": "0",
        "20to24": "3",
        "25to34": "21",
        "35to44": "14",
        "45to54": "20",
        "55to64": "12",
        "65+": "2",
        "medianAge": "12"
      }
    },
    "title": "Compensation, benefits, and job analysis specialists",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "71",
        "women": "77.3",
        "white": "16.3",
        "black": "8.6",
        "asian": "9.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "66",
        "median": "1,022",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "49",
        "femaleMedian": "-"
      }
    }
  },
  "Training and development specialists": {
    "ageData": {
      "2014": {
        "total": "128",
        "16to19": "1",
        "20to24": "1",
        "25to34": "38",
        "35to44": "29",
        "45to54": "30",
        "55to64": "24",
        "65+": "4",
        "medianAge": "24"
      }
    },
    "title": "Training and development specialists",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "128",
        "women": "58.3",
        "white": "11.0",
        "black": "2.1",
        "asian": "7.6"
      }
    },
    "wageData": {
      "2014": {
        "workers": "118",
        "median": "1,091",
        "maleWorkers": "53",
        "maleMedian": "1,280",
        "femaleWorkers": "65",
        "femaleMedian": "955"
      }
    }
  },
  "Logisticians": {
    "ageData": {
      "2014": {
        "total": "100",
        "16to19": "0",
        "20to24": "6",
        "25to34": "31",
        "35to44": "20",
        "45to54": "22",
        "55to64": "18",
        "65+": "3",
        "medianAge": "18"
      }
    },
    "title": "Logisticians",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "100",
        "women": "41.4",
        "white": "12.0",
        "black": "4.2",
        "asian": "15.6"
      }
    },
    "wageData": {
      "2014": {
        "workers": "83",
        "median": "946",
        "maleWorkers": "43",
        "maleMedian": "-",
        "femaleWorkers": "39",
        "femaleMedian": "-"
      }
    }
  },
  "Management analysts": {
    "ageData": {
      "2014": {
        "total": "850",
        "16to19": "1",
        "20to24": "38",
        "25to34": "174",
        "35to44": "187",
        "45to54": "199",
        "55to64": "161",
        "65+": "90",
        "medianAge": "161"
      }
    },
    "title": "Management analysts",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "850",
        "women": "41.3",
        "white": "7.7",
        "black": "9.6",
        "asian": "5.8"
      }
    },
    "wageData": {
      "2014": {
        "workers": "551",
        "median": "1,470",
        "maleWorkers": "322",
        "maleMedian": "1,665",
        "femaleWorkers": "229",
        "femaleMedian": "1,239"
      }
    }
  },
  "Meeting, convention, and event planners": {
    "ageData": {
      "2014": {
        "total": "156",
        "16to19": "3",
        "20to24": "30",
        "25to34": "49",
        "35to44": "31",
        "45to54": "22",
        "55to64": "16",
        "65+": "5",
        "medianAge": "16"
      }
    },
    "title": "Meeting, convention, and event planners",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "156",
        "women": "79.2",
        "white": "12.8",
        "black": "3.3",
        "asian": "6.8"
      }
    },
    "wageData": {
      "2014": {
        "workers": "107",
        "median": "997",
        "maleWorkers": "20",
        "maleMedian": "-",
        "femaleWorkers": "88",
        "femaleMedian": "981"
      }
    }
  },
  "Fundraisers": {
    "ageData": {
      "2014": {
        "total": "92",
        "16to19": "1",
        "20to24": "10",
        "25to34": "23",
        "35to44": "20",
        "45to54": "18",
        "55to64": "17",
        "65+": "4",
        "medianAge": "17"
      }
    },
    "title": "Fundraisers",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "92",
        "women": "72.6",
        "white": "4.4",
        "black": "2.3",
        "asian": "3.5"
      }
    },
    "wageData": {
      "2014": {
        "workers": "72",
        "median": "1,122",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "51",
        "femaleMedian": "1,013"
      }
    }
  },
  "Market research analysts and marketing specialists": {
    "ageData": {
      "2014": {
        "total": "284",
        "16to19": "0",
        "20to24": "38",
        "25to34": "86",
        "35to44": "68",
        "45to54": "50",
        "55to64": "34",
        "65+": "9",
        "medianAge": "34"
      }
    },
    "title": "Market research analysts and marketing specialists",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "284",
        "women": "60.8",
        "white": "5.6",
        "black": "12.1",
        "asian": "7.3"
      }
    },
    "wageData": {
      "2014": {
        "workers": "219",
        "median": "1,135",
        "maleWorkers": "85",
        "maleMedian": "1,269",
        "femaleWorkers": "134",
        "femaleMedian": "1,002"
      }
    }
  },
  "Business operations specialists, all other": {
    "ageData": {
      "2014": {
        "total": "194",
        "16to19": "1",
        "20to24": "13",
        "25to34": "47",
        "35to44": "50",
        "45to54": "46",
        "55to64": "31",
        "65+": "7",
        "medianAge": "31"
      }
    },
    "title": "Business operations specialists, all other",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "194",
        "women": "57.2",
        "white": "10.7",
        "black": "7.4",
        "asian": "11.0"
      }
    },
    "wageData": {
      "2014": {
        "workers": "174",
        "median": "1,098",
        "maleWorkers": "74",
        "maleMedian": "1,229",
        "femaleWorkers": "99",
        "femaleMedian": "944"
      }
    }
  },
  "Accountants and auditors": {
    "ageData": {
      "2014": {
        "total": "1,724",
        "16to19": "2",
        "20to24": "94",
        "25to34": "407",
        "35to44": "403",
        "45to54": "417",
        "55to64": "296",
        "65+": "105",
        "medianAge": "296"
      }
    },
    "title": "Accountants and auditors",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "1,724",
        "women": "63.0",
        "white": "8.2",
        "black": "10.7",
        "asian": "7.8"
      }
    },
    "wageData": {
      "2014": {
        "workers": "1,437",
        "median": "1,072",
        "maleWorkers": "526",
        "maleMedian": "1,236",
        "femaleWorkers": "910",
        "femaleMedian": "999"
      }
    }
  },
  "Appraisers and assessors of real estate": {
    "ageData": {
      "2014": {
        "total": "95",
        "16to19": "0",
        "20to24": "1",
        "25to34": "20",
        "35to44": "15",
        "45to54": "28",
        "55to64": "22",
        "65+": "9",
        "medianAge": "22"
      }
    },
    "title": "Appraisers and assessors of real estate",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "95",
        "women": "40.1",
        "white": "5.4",
        "black": "1.6",
        "asian": "4.2"
      }
    },
    "wageData": {
      "2014": {
        "workers": "51",
        "median": "1,009",
        "maleWorkers": "27",
        "maleMedian": "-",
        "femaleWorkers": "24",
        "femaleMedian": "-"
      }
    }
  },
  "Budget analysts": {
    "ageData": {
      "2014": {
        "total": "48",
        "16to19": "0",
        "20to24": "2",
        "25to34": "12",
        "35to44": "12",
        "45to54": "12",
        "55to64": "9",
        "65+": "0",
        "medianAge": "9"
      }
    },
    "title": "Budget analysts",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "48",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "44",
        "median": "-",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "23",
        "femaleMedian": "-"
      }
    }
  },
  "Credit analysts": {
    "ageData": {
      "2014": {
        "total": "28",
        "16to19": "0",
        "20to24": "3",
        "25to34": "9",
        "35to44": "6",
        "45to54": "6",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Credit analysts",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "28",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "24",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "13",
        "femaleMedian": "-"
      }
    }
  },
  "Financial analysts": {
    "ageData": {
      "2014": {
        "total": "261",
        "16to19": "0",
        "20to24": "27",
        "25to34": "79",
        "35to44": "74",
        "45to54": "38",
        "55to64": "38",
        "65+": "5",
        "medianAge": "38"
      }
    },
    "title": "Financial analysts",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "261",
        "women": "40.5",
        "white": "4.9",
        "black": "15.8",
        "asian": "7.2"
      }
    },
    "wageData": {
      "2014": {
        "workers": "226",
        "median": "1,434",
        "maleWorkers": "133",
        "maleMedian": "1,493",
        "femaleWorkers": "93",
        "femaleMedian": "1,224"
      }
    }
  },
  "Personal financial advisors": {
    "ageData": {
      "2014": {
        "total": "434",
        "16to19": "1",
        "20to24": "33",
        "25to34": "100",
        "35to44": "101",
        "45to54": "91",
        "55to64": "82",
        "65+": "27",
        "medianAge": "82"
      }
    },
    "title": "Personal financial advisors",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "434",
        "women": "35.5",
        "white": "8.1",
        "black": "5.7",
        "asian": "7.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "335",
        "median": "1,337",
        "maleWorkers": "201",
        "maleMedian": "1,637",
        "femaleWorkers": "134",
        "femaleMedian": "1,004"
      }
    }
  },
  "Insurance underwriters": {
    "ageData": {
      "2014": {
        "total": "113",
        "16to19": "0",
        "20to24": "3",
        "25to34": "33",
        "35to44": "21",
        "45to54": "27",
        "55to64": "26",
        "65+": "3",
        "medianAge": "26"
      }
    },
    "title": "Insurance underwriters",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "113",
        "women": "60.5",
        "white": "12.3",
        "black": "2.4",
        "asian": "7.6"
      }
    },
    "wageData": {
      "2014": {
        "workers": "106",
        "median": "1,142",
        "maleWorkers": "42",
        "maleMedian": "-",
        "femaleWorkers": "64",
        "femaleMedian": "1,003"
      }
    }
  },
  "Financial examiners": {
    "ageData": {
      "2014": {
        "total": "16",
        "16to19": "0",
        "20to24": "2",
        "25to34": "3",
        "35to44": "6",
        "45to54": "3",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Financial examiners",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "16",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "16",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Credit counselors and loan officers": {
    "ageData": {
      "2014": {
        "total": "288",
        "16to19": "1",
        "20to24": "22",
        "25to34": "74",
        "35to44": "72",
        "45to54": "67",
        "55to64": "46",
        "65+": "7",
        "medianAge": "46"
      }
    },
    "title": "Credit counselors and loan officers",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "288",
        "women": "59.4",
        "white": "10.3",
        "black": "6.3",
        "asian": "11.0"
      }
    },
    "wageData": {
      "2014": {
        "workers": "269",
        "median": "997",
        "maleWorkers": "108",
        "maleMedian": "1,188",
        "femaleWorkers": "161",
        "femaleMedian": "915"
      }
    }
  },
  "Tax examiners and collectors, and revenue agents": {
    "ageData": {
      "2014": {
        "total": "70",
        "16to19": "0",
        "20to24": "0",
        "25to34": "15",
        "35to44": "21",
        "45to54": "19",
        "55to64": "11",
        "65+": "5",
        "medianAge": "11"
      }
    },
    "title": "Tax examiners and collectors, and revenue agents",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "70",
        "women": "60.2",
        "white": "18.3",
        "black": "7.3",
        "asian": "6.4"
      }
    },
    "wageData": {
      "2014": {
        "workers": "60",
        "median": "1,183",
        "maleWorkers": "23",
        "maleMedian": "-",
        "femaleWorkers": "37",
        "femaleMedian": "-"
      }
    }
  },
  "Tax preparers": {
    "ageData": {
      "2014": {
        "total": "103",
        "16to19": "1",
        "20to24": "0",
        "25to34": "17",
        "35to44": "18",
        "45to54": "18",
        "55to64": "22",
        "65+": "26",
        "medianAge": "22"
      }
    },
    "title": "Tax preparers",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "103",
        "women": "67.8",
        "white": "16.5",
        "black": "5.7",
        "asian": "13.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "51",
        "median": "904",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "38",
        "femaleMedian": "-"
      }
    }
  },
  "Financial specialists, all other": {
    "ageData": {
      "2014": {
        "total": "93",
        "16to19": "0",
        "20to24": "7",
        "25to34": "16",
        "35to44": "24",
        "45to54": "25",
        "55to64": "16",
        "65+": "5",
        "medianAge": "16"
      }
    },
    "title": "Financial specialists, all other",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "93",
        "women": "60.1",
        "white": "11.1",
        "black": "8.1",
        "asian": "11.2"
      }
    },
    "wageData": {
      "2014": {
        "workers": "79",
        "median": "949",
        "maleWorkers": "30",
        "maleMedian": "-",
        "femaleWorkers": "48",
        "femaleMedian": "-"
      }
    }
  },
  "Professional and related occupations": {
    "ageData": {
      "2014": {
        "total": "32,879",
        "16to19": "259",
        "20to24": "2,090",
        "25to34": "8,156",
        "35to44": "7,807",
        "45to54": "7,147",
        "55to64": "5,576",
        "65+": "1,845",
        "medianAge": "5,576"
      }
    },
    "title": "Professional and related occupations",
    "depth": 2,
    "parent": "Management, professional, and related occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "32,879",
        "women": "57.2",
        "white": "9.7",
        "black": "8.6",
        "asian": "8.6"
      }
    },
    "wageData": {
      "2014": {
        "workers": "25,455",
        "median": "1,078",
        "maleWorkers": "11,373",
        "maleMedian": "1,286",
        "femaleWorkers": "14,082",
        "femaleMedian": "948"
      }
    }
  },
  "Computer and mathematical occupations": {
    "ageData": {
      "2014": {
        "total": "4,303",
        "16to19": "25",
        "20to24": "261",
        "25to34": "1,190",
        "35to44": "1,177",
        "45to54": "989",
        "55to64": "549",
        "65+": "112",
        "medianAge": "549"
      }
    },
    "title": "Computer and mathematical occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "4,303",
        "women": "25.6",
        "white": "8.3",
        "black": "19.2",
        "asian": "6.6"
      }
    },
    "wageData": {
      "2014": {
        "workers": "3,879",
        "median": "1,368",
        "maleWorkers": "2,900",
        "maleMedian": "1,435",
        "femaleWorkers": "979",
        "femaleMedian": "1,165"
      }
    }
  },
  "Computer and information research scientists": {
    "ageData": {
      "2014": {
        "total": "29",
        "16to19": "0",
        "20to24": "1",
        "25to34": "12",
        "35to44": "5",
        "45to54": "5",
        "55to64": "5",
        "65+": "1",
        "medianAge": "5"
      }
    },
    "title": "Computer and information research scientists",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "29",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "27",
        "median": "-",
        "maleWorkers": "22",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Computer systems analysts": {
    "ageData": {
      "2014": {
        "total": "511",
        "16to19": "1",
        "20to24": "37",
        "25to34": "122",
        "35to44": "140",
        "45to54": "120",
        "55to64": "74",
        "65+": "17",
        "medianAge": "74"
      }
    },
    "title": "Computer systems analysts",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "511",
        "women": "34.2",
        "white": "9.7",
        "black": "20.9",
        "asian": "4.8"
      }
    },
    "wageData": {
      "2014": {
        "workers": "419",
        "median": "1,385",
        "maleWorkers": "272",
        "maleMedian": "1,460",
        "femaleWorkers": "147",
        "femaleMedian": "1,259"
      }
    }
  },
  "Information security analysts": {
    "ageData": {
      "2014": {
        "total": "68",
        "16to19": "0",
        "20to24": "4",
        "25to34": "12",
        "35to44": "18",
        "45to54": "17",
        "55to64": "14",
        "65+": "2",
        "medianAge": "14"
      }
    },
    "title": "Information security analysts",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "68",
        "women": "18.1",
        "white": "9.7",
        "black": "5.7",
        "asian": "6.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "55",
        "median": "1,419",
        "maleWorkers": "47",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Computer programmers": {
    "ageData": {
      "2014": {
        "total": "509",
        "16to19": "3",
        "20to24": "31",
        "25to34": "127",
        "35to44": "131",
        "45to54": "130",
        "55to64": "73",
        "65+": "15",
        "medianAge": "73"
      }
    },
    "title": "Computer programmers",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "509",
        "women": "21.4",
        "white": "5.7",
        "black": "19.7",
        "asian": "6.4"
      }
    },
    "wageData": {
      "2014": {
        "workers": "438",
        "median": "1,409",
        "maleWorkers": "354",
        "maleMedian": "1,447",
        "femaleWorkers": "84",
        "femaleMedian": "1,253"
      }
    }
  },
  "Software developers, applications and systems software": {
    "ageData": {
      "2014": {
        "total": "1,235",
        "16to19": "2",
        "20to24": "64",
        "25to34": "373",
        "35to44": "360",
        "45to54": "290",
        "55to64": "121",
        "65+": "26",
        "medianAge": "121"
      }
    },
    "title": "Software developers, applications and systems software",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "1,235",
        "women": "19.8",
        "white": "5.3",
        "black": "31.9",
        "asian": "5.2"
      }
    },
    "wageData": {
      "2014": {
        "workers": "1,141",
        "median": "1,693",
        "maleWorkers": "917",
        "maleMedian": "1,736",
        "femaleWorkers": "224",
        "femaleMedian": "1,457"
      }
    }
  },
  "Web developers": {
    "ageData": {
      "2014": {
        "total": "220",
        "16to19": "4",
        "20to24": "23",
        "25to34": "88",
        "35to44": "56",
        "45to54": "27",
        "55to64": "16",
        "65+": "5",
        "medianAge": "16"
      }
    },
    "title": "Web developers",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "220",
        "women": "35.2",
        "white": "6.3",
        "black": "5.7",
        "asian": "8.6"
      }
    },
    "wageData": {
      "2014": {
        "workers": "163",
        "median": "1,129",
        "maleWorkers": "105",
        "maleMedian": "1,245",
        "femaleWorkers": "57",
        "femaleMedian": "988"
      }
    }
  },
  "Computer support specialists": {
    "ageData": {
      "2014": {
        "total": "524",
        "16to19": "7",
        "20to24": "50",
        "25to34": "142",
        "35to44": "135",
        "45to54": "110",
        "55to64": "66",
        "65+": "13",
        "medianAge": "66"
      }
    },
    "title": "Computer support specialists",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "524",
        "women": "26.6",
        "white": "14.3",
        "black": "8.1",
        "asian": "9.4"
      }
    },
    "wageData": {
      "2014": {
        "workers": "503",
        "median": "966",
        "maleWorkers": "371",
        "maleMedian": "1,049",
        "femaleWorkers": "132",
        "femaleMedian": "837"
      }
    }
  },
  "Database administrators": {
    "ageData": {
      "2014": {
        "total": "108",
        "16to19": "0",
        "20to24": "1",
        "25to34": "21",
        "35to44": "35",
        "45to54": "23",
        "55to64": "24",
        "65+": "5",
        "medianAge": "24"
      }
    },
    "title": "Database administrators",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "108",
        "women": "28.0",
        "white": "11.7",
        "black": "13.3",
        "asian": "4.6"
      }
    },
    "wageData": {
      "2014": {
        "workers": "101",
        "median": "1,517",
        "maleWorkers": "76",
        "maleMedian": "1,662",
        "femaleWorkers": "25",
        "femaleMedian": "-"
      }
    }
  },
  "Network and computer systems administrators": {
    "ageData": {
      "2014": {
        "total": "205",
        "16to19": "0",
        "20to24": "7",
        "25to34": "53",
        "35to44": "66",
        "45to54": "53",
        "55to64": "23",
        "65+": "3",
        "medianAge": "23"
      }
    },
    "title": "Network and computer systems administrators",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "205",
        "women": "19.1",
        "white": "9.5",
        "black": "8.4",
        "asian": "7.9"
      }
    },
    "wageData": {
      "2014": {
        "workers": "204",
        "median": "1,263",
        "maleWorkers": "162",
        "maleMedian": "1,286",
        "femaleWorkers": "42",
        "femaleMedian": "-"
      }
    }
  },
  "Computer network architects": {
    "ageData": {
      "2014": {
        "total": "123",
        "16to19": "0",
        "20to24": "3",
        "25to34": "33",
        "35to44": "38",
        "45to54": "31",
        "55to64": "12",
        "65+": "5",
        "medianAge": "12"
      }
    },
    "title": "Computer network architects",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "123",
        "women": "12.4",
        "white": "7.4",
        "black": "17.3",
        "asian": "6.3"
      }
    },
    "wageData": {
      "2014": {
        "workers": "126",
        "median": "1,633",
        "maleWorkers": "111",
        "maleMedian": "1,650",
        "femaleWorkers": "15",
        "femaleMedian": "-"
      }
    }
  },
  "Computer occupations, all other": {
    "ageData": {
      "2014": {
        "total": "512",
        "16to19": "9",
        "20to24": "29",
        "25to34": "124",
        "35to44": "135",
        "45to54": "120",
        "55to64": "82",
        "65+": "13",
        "medianAge": "82"
      }
    },
    "title": "Computer occupations, all other",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "512",
        "women": "23.1",
        "white": "9.7",
        "black": "12.5",
        "asian": "8.5"
      }
    },
    "wageData": {
      "2014": {
        "workers": "452",
        "median": "1,088",
        "maleWorkers": "343",
        "maleMedian": "1,122",
        "femaleWorkers": "109",
        "femaleMedian": "984"
      }
    }
  },
  "Actuaries": {
    "ageData": {
      "2014": {
        "total": "28",
        "16to19": "0",
        "20to24": "4",
        "25to34": "12",
        "35to44": "4",
        "45to54": "6",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Actuaries",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "28",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "29",
        "median": "-",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "14",
        "femaleMedian": "-"
      }
    }
  },
  "Mathematicians": {
    "ageData": {
      "2014": {
        "total": "3",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "1",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Mathematicians",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Operations research analysts": {
    "ageData": {
      "2014": {
        "total": "138",
        "16to19": "0",
        "20to24": "3",
        "25to34": "36",
        "35to44": "30",
        "45to54": "38",
        "55to64": "27",
        "65+": "4",
        "medianAge": "27"
      }
    },
    "title": "Operations research analysts",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "138",
        "women": "55.4",
        "white": "11.0",
        "black": "11.9",
        "asian": "10.5"
      }
    },
    "wageData": {
      "2014": {
        "workers": "134",
        "median": "1,276",
        "maleWorkers": "61",
        "maleMedian": "1,457",
        "femaleWorkers": "72",
        "femaleMedian": "1,233"
      }
    }
  },
  "Statisticians": {
    "ageData": {
      "2014": {
        "total": "85",
        "16to19": "0",
        "20to24": "4",
        "25to34": "33",
        "35to44": "21",
        "45to54": "17",
        "55to64": "7",
        "65+": "3",
        "medianAge": "7"
      }
    },
    "title": "Statisticians",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "85",
        "women": "49.9",
        "white": "5.8",
        "black": "24.5",
        "asian": "4.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "78",
        "median": "1,349",
        "maleWorkers": "39",
        "maleMedian": "-",
        "femaleWorkers": "39",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous mathematical science occupations": {
    "ageData": {
      "2014": {
        "total": "5",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "1",
        "45to54": "2",
        "55to64": "0",
        "65+": "1",
        "medianAge": "0"
      }
    },
    "title": "Miscellaneous mathematical science occupations",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Architecture and engineering occupations": {
    "ageData": {
      "2014": {
        "total": "2,798",
        "16to19": "13",
        "20to24": "195",
        "25to34": "652",
        "35to44": "603",
        "45to54": "660",
        "55to64": "544",
        "65+": "132",
        "medianAge": "544"
      }
    },
    "title": "Architecture and engineering occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "2,798",
        "women": "15.4",
        "white": "5.2",
        "black": "11.7",
        "asian": "8.2"
      }
    },
    "wageData": {
      "2014": {
        "workers": "2,527",
        "median": "1,377",
        "maleWorkers": "2,150",
        "maleMedian": "1,413",
        "femaleWorkers": "377",
        "femaleMedian": "1,158"
      }
    }
  },
  "Architects, except naval": {
    "ageData": {
      "2014": {
        "total": "178",
        "16to19": "0",
        "20to24": "7",
        "25to34": "46",
        "35to44": "44",
        "45to54": "34",
        "55to64": "32",
        "65+": "15",
        "medianAge": "32"
      }
    },
    "title": "Architects, except naval",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "178",
        "women": "25.3",
        "white": "4.1",
        "black": "10.0",
        "asian": "7.2"
      }
    },
    "wageData": {
      "2014": {
        "workers": "122",
        "median": "1,329",
        "maleWorkers": "94",
        "maleMedian": "1,391",
        "femaleWorkers": "29",
        "femaleMedian": "-"
      }
    }
  },
  "Surveyors, cartographers, and photogrammetrists": {
    "ageData": {
      "2014": {
        "total": "41",
        "16to19": "0",
        "20to24": "4",
        "25to34": "12",
        "35to44": "6",
        "45to54": "8",
        "55to64": "8",
        "65+": "3",
        "medianAge": "8"
      }
    },
    "title": "Surveyors, cartographers, and photogrammetrists",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "41",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "33",
        "median": "-",
        "maleWorkers": "27",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Aerospace engineers": {
    "ageData": {
      "2014": {
        "total": "147",
        "16to19": "0",
        "20to24": "6",
        "25to34": "31",
        "35to44": "33",
        "45to54": "34",
        "55to64": "38",
        "65+": "5",
        "medianAge": "38"
      }
    },
    "title": "Aerospace engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "147",
        "women": "15.6",
        "white": "6.2",
        "black": "10.7",
        "asian": "11.4"
      }
    },
    "wageData": {
      "2014": {
        "workers": "143",
        "median": "1,642",
        "maleWorkers": "121",
        "maleMedian": "1,727",
        "femaleWorkers": "22",
        "femaleMedian": "-"
      }
    }
  },
  "Agricultural engineers": {
    "ageData": {
      "2014": {
        "total": "4",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "2",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Agricultural engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Biomedical engineers": {
    "ageData": {
      "2014": {
        "total": "14",
        "16to19": "0",
        "20to24": "1",
        "25to34": "5",
        "35to44": "1",
        "45to54": "4",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Biomedical engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "14",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Chemical engineers": {
    "ageData": {
      "2014": {
        "total": "79",
        "16to19": "0",
        "20to24": "10",
        "25to34": "23",
        "35to44": "10",
        "45to54": "23",
        "55to64": "13",
        "65+": "1",
        "medianAge": "13"
      }
    },
    "title": "Chemical engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "79",
        "women": "13.0",
        "white": "3.0",
        "black": "12.6",
        "asian": "3.6"
      }
    },
    "wageData": {
      "2014": {
        "workers": "75",
        "median": "1,465",
        "maleWorkers": "64",
        "maleMedian": "1,570",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "Civil engineers": {
    "ageData": {
      "2014": {
        "total": "349",
        "16to19": "0",
        "20to24": "24",
        "25to34": "89",
        "35to44": "64",
        "45to54": "81",
        "55to64": "64",
        "65+": "27",
        "medianAge": "64"
      }
    },
    "title": "Civil engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "349",
        "women": "16.5",
        "white": "6.1",
        "black": "9.7",
        "asian": "8.8"
      }
    },
    "wageData": {
      "2014": {
        "workers": "317",
        "median": "1,383",
        "maleWorkers": "263",
        "maleMedian": "1,406",
        "femaleWorkers": "53",
        "femaleMedian": "1,275"
      }
    }
  },
  "Computer hardware engineers": {
    "ageData": {
      "2014": {
        "total": "84",
        "16to19": "0",
        "20to24": "3",
        "25to34": "17",
        "35to44": "24",
        "45to54": "20",
        "55to64": "15",
        "65+": "4",
        "medianAge": "15"
      }
    },
    "title": "Computer hardware engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "84",
        "women": "15.3",
        "white": "7.4",
        "black": "22.8",
        "asian": "9.5"
      }
    },
    "wageData": {
      "2014": {
        "workers": "79",
        "median": "1,744",
        "maleWorkers": "70",
        "maleMedian": "1,795",
        "femaleWorkers": "9",
        "femaleMedian": "-"
      }
    }
  },
  "Electrical and electronics engineers": {
    "ageData": {
      "2014": {
        "total": "271",
        "16to19": "0",
        "20to24": "15",
        "25to34": "62",
        "35to44": "57",
        "45to54": "72",
        "55to64": "51",
        "65+": "12",
        "medianAge": "51"
      }
    },
    "title": "Electrical and electronics engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "271",
        "women": "12.3",
        "white": "3.9",
        "black": "21.4",
        "asian": "5.3"
      }
    },
    "wageData": {
      "2014": {
        "workers": "271",
        "median": "1,572",
        "maleWorkers": "242",
        "maleMedian": "1,568",
        "femaleWorkers": "29",
        "femaleMedian": "-"
      }
    }
  },
  "Environmental engineers": {
    "ageData": {
      "2014": {
        "total": "42",
        "16to19": "0",
        "20to24": "2",
        "25to34": "11",
        "35to44": "13",
        "45to54": "10",
        "55to64": "4",
        "65+": "2",
        "medianAge": "4"
      }
    },
    "title": "Environmental engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "42",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "40",
        "median": "-",
        "maleWorkers": "31",
        "maleMedian": "-",
        "femaleWorkers": "9",
        "femaleMedian": "-"
      }
    }
  },
  "Industrial engineers, including health and safety": {
    "ageData": {
      "2014": {
        "total": "194",
        "16to19": "0",
        "20to24": "17",
        "25to34": "44",
        "35to44": "35",
        "45to54": "50",
        "55to64": "40",
        "65+": "8",
        "medianAge": "40"
      }
    },
    "title": "Industrial engineers, including health and safety",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "194",
        "women": "16.0",
        "white": "5.5",
        "black": "9.7",
        "asian": "8.6"
      }
    },
    "wageData": {
      "2014": {
        "workers": "187",
        "median": "1,404",
        "maleWorkers": "155",
        "maleMedian": "1,464",
        "femaleWorkers": "32",
        "femaleMedian": "-"
      }
    }
  },
  "Marine engineers and naval architects": {
    "ageData": {
      "2014": {
        "total": "9",
        "16to19": "0",
        "20to24": "1",
        "25to34": "2",
        "35to44": "1",
        "45to54": "5",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Marine engineers and naval architects",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "9",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Materials engineers": {
    "ageData": {
      "2014": {
        "total": "35",
        "16to19": "0",
        "20to24": "0",
        "25to34": "9",
        "35to44": "8",
        "45to54": "10",
        "55to64": "7",
        "65+": "1",
        "medianAge": "7"
      }
    },
    "title": "Materials engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "35",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "33",
        "median": "-",
        "maleWorkers": "28",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Mechanical engineers": {
    "ageData": {
      "2014": {
        "total": "303",
        "16to19": "0",
        "20to24": "23",
        "25to34": "77",
        "35to44": "72",
        "45to54": "72",
        "55to64": "47",
        "65+": "12",
        "medianAge": "47"
      }
    },
    "title": "Mechanical engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "303",
        "women": "8.8",
        "white": "3.3",
        "black": "9.9",
        "asian": "5.6"
      }
    },
    "wageData": {
      "2014": {
        "workers": "286",
        "median": "1,434",
        "maleWorkers": "259",
        "maleMedian": "1,453",
        "femaleWorkers": "27",
        "femaleMedian": "-"
      }
    }
  },
  "Mining and geological engineers, including mining safety engineers": {
    "ageData": {
      "2014": {
        "total": "15",
        "16to19": "0",
        "20to24": "1",
        "25to34": "3",
        "35to44": "5",
        "45to54": "4",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Mining and geological engineers, including mining safety engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "15",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "18",
        "median": "-",
        "maleWorkers": "18",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Nuclear engineers": {
    "ageData": {
      "2014": {
        "total": "5",
        "16to19": "0",
        "20to24": "1",
        "25to34": "2",
        "35to44": "2",
        "45to54": "0",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Nuclear engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Petroleum engineers": {
    "ageData": {
      "2014": {
        "total": "37",
        "16to19": "0",
        "20to24": "1",
        "25to34": "12",
        "35to44": "10",
        "45to54": "6",
        "55to64": "8",
        "65+": "1",
        "medianAge": "8"
      }
    },
    "title": "Petroleum engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "37",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "36",
        "median": "-",
        "maleWorkers": "26",
        "maleMedian": "-",
        "femaleWorkers": "9",
        "femaleMedian": "-"
      }
    }
  },
  "Engineers, all other": {
    "ageData": {
      "2014": {
        "total": "406",
        "16to19": "3",
        "20to24": "14",
        "25to34": "92",
        "35to44": "88",
        "45to54": "96",
        "55to64": "88",
        "65+": "25",
        "medianAge": "88"
      }
    },
    "title": "Engineers, all other",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "406",
        "women": "11.7",
        "white": "4.4",
        "black": "18.2",
        "asian": "8.0"
      }
    },
    "wageData": {
      "2014": {
        "workers": "362",
        "median": "1,463",
        "maleWorkers": "317",
        "maleMedian": "1,488",
        "femaleWorkers": "44",
        "femaleMedian": "-"
      }
    }
  },
  "Drafters": {
    "ageData": {
      "2014": {
        "total": "138",
        "16to19": "0",
        "20to24": "17",
        "25to34": "26",
        "35to44": "36",
        "45to54": "32",
        "55to64": "24",
        "65+": "2",
        "medianAge": "24"
      }
    },
    "title": "Drafters",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "138",
        "women": "18.1",
        "white": "5.3",
        "black": "4.3",
        "asian": "6.5"
      }
    },
    "wageData": {
      "2014": {
        "workers": "122",
        "median": "979",
        "maleWorkers": "102",
        "maleMedian": "986",
        "femaleWorkers": "20",
        "femaleMedian": "-"
      }
    }
  },
  "Engineering technicians, except drafters": {
    "ageData": {
      "2014": {
        "total": "369",
        "16to19": "5",
        "20to24": "38",
        "25to34": "69",
        "35to44": "75",
        "45to54": "85",
        "55to64": "84",
        "65+": "12",
        "medianAge": "84"
      }
    },
    "title": "Engineering technicians, except drafters",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "369",
        "women": "20.5",
        "white": "8.2",
        "black": "8.7",
        "asian": "13.2"
      }
    },
    "wageData": {
      "2014": {
        "workers": "321",
        "median": "989",
        "maleWorkers": "253",
        "maleMedian": "1,066",
        "femaleWorkers": "68",
        "femaleMedian": "785"
      }
    }
  },
  "Surveying and mapping technicians": {
    "ageData": {
      "2014": {
        "total": "77",
        "16to19": "3",
        "20to24": "10",
        "25to34": "17",
        "35to44": "17",
        "45to54": "14",
        "55to64": "15",
        "65+": "1",
        "medianAge": "15"
      }
    },
    "title": "Surveying and mapping technicians",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "77",
        "women": "9.7",
        "white": "6.8",
        "black": "0.8",
        "asian": "9.4"
      }
    },
    "wageData": {
      "2014": {
        "workers": "56",
        "median": "879",
        "maleWorkers": "54",
        "maleMedian": "882",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Life, physical, and social science occupations": {
    "ageData": {
      "2014": {
        "total": "1,355",
        "16to19": "4",
        "20to24": "105",
        "25to34": "336",
        "35to44": "337",
        "45to54": "262",
        "55to64": "213",
        "65+": "98",
        "medianAge": "213"
      }
    },
    "title": "Life, physical, and social science occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "1,355",
        "women": "45.6",
        "white": "6.4",
        "black": "12.3",
        "asian": "7.5"
      }
    },
    "wageData": {
      "2014": {
        "workers": "1,124",
        "median": "1,168",
        "maleWorkers": "658",
        "maleMedian": "1,247",
        "femaleWorkers": "466",
        "femaleMedian": "1,062"
      }
    }
  },
  "Agricultural and food scientists": {
    "ageData": {
      "2014": {
        "total": "31",
        "16to19": "0",
        "20to24": "2",
        "25to34": "11",
        "35to44": "7",
        "45to54": "8",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Agricultural and food scientists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "31",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "26",
        "median": "-",
        "maleWorkers": "16",
        "maleMedian": "-",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Biological scientists": {
    "ageData": {
      "2014": {
        "total": "110",
        "16to19": "0",
        "20to24": "9",
        "25to34": "23",
        "35to44": "33",
        "45to54": "19",
        "55to64": "20",
        "65+": "6",
        "medianAge": "20"
      }
    },
    "title": "Biological scientists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "110",
        "women": "45.9",
        "white": "5.3",
        "black": "10.5",
        "asian": "6.0"
      }
    },
    "wageData": {
      "2014": {
        "workers": "94",
        "median": "1,152",
        "maleWorkers": "54",
        "maleMedian": "1,356",
        "femaleWorkers": "41",
        "femaleMedian": "-"
      }
    }
  },
  "Conservation scientists and foresters": {
    "ageData": {
      "2014": {
        "total": "27",
        "16to19": "0",
        "20to24": "1",
        "25to34": "7",
        "35to44": "8",
        "45to54": "3",
        "55to64": "7",
        "65+": "1",
        "medianAge": "7"
      }
    },
    "title": "Conservation scientists and foresters",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "27",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "25",
        "median": "-",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Medical scientists": {
    "ageData": {
      "2014": {
        "total": "143",
        "16to19": "0",
        "20to24": "7",
        "25to34": "43",
        "35to44": "40",
        "45to54": "24",
        "55to64": "20",
        "65+": "9",
        "medianAge": "20"
      }
    },
    "title": "Medical scientists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "143",
        "women": "52.5",
        "white": "5.1",
        "black": "33.8",
        "asian": "7.9"
      }
    },
    "wageData": {
      "2014": {
        "workers": "137",
        "median": "1,267",
        "maleWorkers": "69",
        "maleMedian": "1,449",
        "femaleWorkers": "68",
        "femaleMedian": "1,141"
      }
    }
  },
  "Life scientists, all other": {
    "ageData": {
      "2014": {
        "total": "0",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Life scientists, all other",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "0",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "0",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Astronomers and physicists": {
    "ageData": {
      "2014": {
        "total": "12",
        "16to19": "0",
        "20to24": "1",
        "25to34": "2",
        "35to44": "1",
        "45to54": "2",
        "55to64": "3",
        "65+": "3",
        "medianAge": "3"
      }
    },
    "title": "Astronomers and physicists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "12",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Atmospheric and space scientists": {
    "ageData": {
      "2014": {
        "total": "7",
        "16to19": "0",
        "20to24": "2",
        "25to34": "1",
        "35to44": "1",
        "45to54": "3",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Atmospheric and space scientists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Chemists and materials scientists": {
    "ageData": {
      "2014": {
        "total": "102",
        "16to19": "0",
        "20to24": "6",
        "25to34": "25",
        "35to44": "26",
        "45to54": "24",
        "55to64": "15",
        "65+": "5",
        "medianAge": "15"
      }
    },
    "title": "Chemists and materials scientists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "102",
        "women": "29.8",
        "white": "8.1",
        "black": "12.7",
        "asian": "6.0"
      }
    },
    "wageData": {
      "2014": {
        "workers": "98",
        "median": "1,251",
        "maleWorkers": "70",
        "maleMedian": "1,369",
        "femaleWorkers": "28",
        "femaleMedian": "-"
      }
    }
  },
  "Environmental scientists and geoscientists": {
    "ageData": {
      "2014": {
        "total": "91",
        "16to19": "0",
        "20to24": "7",
        "25to34": "17",
        "35to44": "24",
        "45to54": "20",
        "55to64": "19",
        "65+": "4",
        "medianAge": "19"
      }
    },
    "title": "Environmental scientists and geoscientists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "91",
        "women": "24.5",
        "white": "1.3",
        "black": "3.9",
        "asian": "6.6"
      }
    },
    "wageData": {
      "2014": {
        "workers": "72",
        "median": "1,358",
        "maleWorkers": "55",
        "maleMedian": "1,388",
        "femaleWorkers": "17",
        "femaleMedian": "-"
      }
    }
  },
  "Physical scientists, all other": {
    "ageData": {
      "2014": {
        "total": "183",
        "16to19": "0",
        "20to24": "8",
        "25to34": "45",
        "35to44": "53",
        "45to54": "39",
        "55to64": "25",
        "65+": "13",
        "medianAge": "25"
      }
    },
    "title": "Physical scientists, all other",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "183",
        "women": "37.6",
        "white": "3.0",
        "black": "25.4",
        "asian": "5.7"
      }
    },
    "wageData": {
      "2014": {
        "workers": "179",
        "median": "1,447",
        "maleWorkers": "116",
        "maleMedian": "1,547",
        "femaleWorkers": "63",
        "femaleMedian": "1,262"
      }
    }
  },
  "Economists": {
    "ageData": {
      "2014": {
        "total": "33",
        "16to19": "0",
        "20to24": "2",
        "25to34": "8",
        "35to44": "12",
        "45to54": "5",
        "55to64": "3",
        "65+": "3",
        "medianAge": "3"
      }
    },
    "title": "Economists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "33",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "27",
        "median": "-",
        "maleWorkers": "16",
        "maleMedian": "-",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Survey researchers": {
    "ageData": {
      "2014": {
        "total": "4",
        "16to19": "1",
        "20to24": "0",
        "25to34": "1",
        "35to44": "1",
        "45to54": "0",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Survey researchers",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Psychologists": {
    "ageData": {
      "2014": {
        "total": "232",
        "16to19": "0",
        "20to24": "2",
        "25to34": "45",
        "35to44": "53",
        "45to54": "46",
        "55to64": "47",
        "65+": "39",
        "medianAge": "47"
      }
    },
    "title": "Psychologists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "232",
        "women": "71.9",
        "white": "5.7",
        "black": "1.3",
        "asian": "8.0"
      }
    },
    "wageData": {
      "2014": {
        "workers": "124",
        "median": "1,232",
        "maleWorkers": "31",
        "maleMedian": "-",
        "femaleWorkers": "94",
        "femaleMedian": "1,212"
      }
    }
  },
  "Sociologists": {
    "ageData": {
      "2014": {
        "total": "3",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Sociologists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Urban and regional planners": {
    "ageData": {
      "2014": {
        "total": "24",
        "16to19": "0",
        "20to24": "1",
        "25to34": "5",
        "35to44": "8",
        "45to54": "5",
        "55to64": "5",
        "65+": "1",
        "medianAge": "5"
      }
    },
    "title": "Urban and regional planners",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "24",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "23",
        "median": "-",
        "maleWorkers": "18",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous social scientists and related workers": {
    "ageData": {
      "2014": {
        "total": "42",
        "16to19": "0",
        "20to24": "3",
        "25to34": "12",
        "35to44": "10",
        "45to54": "8",
        "55to64": "5",
        "65+": "4",
        "medianAge": "5"
      }
    },
    "title": "Miscellaneous social scientists and related workers",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "42",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "36",
        "median": "-",
        "maleWorkers": "20",
        "maleMedian": "-",
        "femaleWorkers": "16",
        "femaleMedian": "-"
      }
    }
  },
  "Agricultural and food science technicians": {
    "ageData": {
      "2014": {
        "total": "39",
        "16to19": "0",
        "20to24": "2",
        "25to34": "16",
        "35to44": "8",
        "45to54": "8",
        "55to64": "3",
        "65+": "3",
        "medianAge": "3"
      }
    },
    "title": "Agricultural and food science technicians",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "39",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "36",
        "median": "-",
        "maleWorkers": "22",
        "maleMedian": "-",
        "femaleWorkers": "13",
        "femaleMedian": "-"
      }
    }
  },
  "Biological technicians": {
    "ageData": {
      "2014": {
        "total": "25",
        "16to19": "0",
        "20to24": "10",
        "25to34": "7",
        "35to44": "5",
        "45to54": "3",
        "55to64": "0",
        "65+": "1",
        "medianAge": "0"
      }
    },
    "title": "Biological technicians",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "25",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "21",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Chemical technicians": {
    "ageData": {
      "2014": {
        "total": "71",
        "16to19": "0",
        "20to24": "6",
        "25to34": "21",
        "35to44": "17",
        "45to54": "13",
        "55to64": "11",
        "65+": "3",
        "medianAge": "11"
      }
    },
    "title": "Chemical technicians",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "71",
        "women": "32.3",
        "white": "16.4",
        "black": "4.6",
        "asian": "8.9"
      }
    },
    "wageData": {
      "2014": {
        "workers": "67",
        "median": "910",
        "maleWorkers": "45",
        "maleMedian": "-",
        "femaleWorkers": "22",
        "femaleMedian": "-"
      }
    }
  },
  "Geological and petroleum technicians": {
    "ageData": {
      "2014": {
        "total": "20",
        "16to19": "0",
        "20to24": "1",
        "25to34": "7",
        "35to44": "2",
        "45to54": "7",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Geological and petroleum technicians",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "20",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "23",
        "median": "-",
        "maleWorkers": "18",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Nuclear technicians": {
    "ageData": {
      "2014": {
        "total": "6",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "1",
        "45to54": "3",
        "55to64": "1",
        "65+": "1",
        "medianAge": "1"
      }
    },
    "title": "Nuclear technicians",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "6",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Social science research assistants": {
    "ageData": {
      "2014": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "1",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Social science research assistants",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous life, physical, and social science technicians": {
    "ageData": {
      "2014": {
        "total": "149",
        "16to19": "2",
        "20to24": "37",
        "25to34": "36",
        "35to44": "27",
        "45to54": "22",
        "55to64": "23",
        "65+": "2",
        "medianAge": "23"
      }
    },
    "title": "Miscellaneous life, physical, and social science technicians",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "149",
        "women": "51.4",
        "white": "7.4",
        "black": "11.6",
        "asian": "7.7"
      }
    },
    "wageData": {
      "2014": {
        "workers": "110",
        "median": "851",
        "maleWorkers": "58",
        "maleMedian": "872",
        "femaleWorkers": "53",
        "femaleMedian": "805"
      }
    }
  },
  "Community and social service occupations": {
    "ageData": {
      "2014": {
        "total": "2,495",
        "16to19": "20",
        "20to24": "142",
        "25to34": "586",
        "35to44": "588",
        "45to54": "542",
        "55to64": "446",
        "65+": "172",
        "medianAge": "446"
      }
    },
    "title": "Community and social service occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "2,495",
        "women": "64.3",
        "white": "17.5",
        "black": "3.4",
        "asian": "11.6"
      }
    },
    "wageData": {
      "2014": {
        "workers": "2,005",
        "median": "858",
        "maleWorkers": "741",
        "maleMedian": "934",
        "femaleWorkers": "1,264",
        "femaleMedian": "824"
      }
    }
  },
  "Counselors": {
    "ageData": {
      "2014": {
        "total": "737",
        "16to19": "16",
        "20to24": "50",
        "25to34": "189",
        "35to44": "164",
        "45to54": "144",
        "55to64": "134",
        "65+": "39",
        "medianAge": "134"
      }
    },
    "title": "Counselors",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "737",
        "women": "71.1",
        "white": "18.7",
        "black": "2.8",
        "asian": "10.6"
      }
    },
    "wageData": {
      "2014": {
        "workers": "552",
        "median": "849",
        "maleWorkers": "174",
        "maleMedian": "885",
        "femaleWorkers": "378",
        "femaleMedian": "831"
      }
    }
  },
  "Social workers": {
    "ageData": {
      "2014": {
        "total": "799",
        "16to19": "0",
        "20to24": "33",
        "25to34": "219",
        "35to44": "215",
        "45to54": "178",
        "55to64": "117",
        "65+": "36",
        "medianAge": "117"
      }
    },
    "title": "Social workers",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "799",
        "women": "81.9",
        "white": "21.3",
        "black": "2.4",
        "asian": "13.2"
      }
    },
    "wageData": {
      "2014": {
        "workers": "690",
        "median": "844",
        "maleWorkers": "127",
        "maleMedian": "892",
        "femaleWorkers": "563",
        "femaleMedian": "839"
      }
    }
  },
  "Probation officers and correctional treatment specialists": {
    "ageData": {
      "2014": {
        "total": "100",
        "16to19": "0",
        "20to24": "2",
        "25to34": "24",
        "35to44": "39",
        "45to54": "24",
        "55to64": "11",
        "65+": "0",
        "medianAge": "11"
      }
    },
    "title": "Probation officers and correctional treatment specialists",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "100",
        "women": "52.5",
        "white": "21.0",
        "black": "3.2",
        "asian": "16.4"
      }
    },
    "wageData": {
      "2014": {
        "workers": "95",
        "median": "999",
        "maleWorkers": "45",
        "maleMedian": "-",
        "femaleWorkers": "50",
        "femaleMedian": "1,141"
      }
    }
  },
  "Social and human service assistants": {
    "ageData": {
      "2014": {
        "total": "180",
        "16to19": "1",
        "20to24": "16",
        "25to34": "40",
        "35to44": "42",
        "45to54": "36",
        "55to64": "34",
        "65+": "11",
        "medianAge": "34"
      }
    },
    "title": "Social and human service assistants",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "180",
        "women": "77.9",
        "white": "25.5",
        "black": "2.2",
        "asian": "16.5"
      }
    },
    "wageData": {
      "2014": {
        "workers": "142",
        "median": "716",
        "maleWorkers": "29",
        "maleMedian": "-",
        "femaleWorkers": "113",
        "femaleMedian": "673"
      }
    }
  },
  "Miscellaneous community and social service specialists, including health educators and community health workers": {
    "ageData": {
      "2014": {
        "total": "114",
        "16to19": "2",
        "20to24": "10",
        "25to34": "27",
        "35to44": "22",
        "45to54": "22",
        "55to64": "23",
        "65+": "9",
        "medianAge": "23"
      }
    },
    "title": "Miscellaneous community and social service specialists, including health educators and community health workers",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "114",
        "women": "69.3",
        "white": "14.6",
        "black": "1.7",
        "asian": "17.4"
      }
    },
    "wageData": {
      "2014": {
        "workers": "93",
        "median": "773",
        "maleWorkers": "26",
        "maleMedian": "-",
        "femaleWorkers": "66",
        "femaleMedian": "747"
      }
    }
  },
  "Clergy": {
    "ageData": {
      "2014": {
        "total": "433",
        "16to19": "1",
        "20to24": "16",
        "25to34": "61",
        "35to44": "80",
        "45to54": "109",
        "55to64": "101",
        "65+": "67",
        "medianAge": "101"
      }
    },
    "title": "Clergy",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "433",
        "women": "18.6",
        "white": "8.3",
        "black": "6.9",
        "asian": "7.5"
      }
    },
    "wageData": {
      "2014": {
        "workers": "356",
        "median": "958",
        "maleWorkers": "303",
        "maleMedian": "1,007",
        "femaleWorkers": "53",
        "femaleMedian": "763"
      }
    }
  },
  "Directors, religious activities and education": {
    "ageData": {
      "2014": {
        "total": "64",
        "16to19": "0",
        "20to24": "6",
        "25to34": "18",
        "35to44": "11",
        "45to54": "11",
        "55to64": "15",
        "65+": "3",
        "medianAge": "15"
      }
    },
    "title": "Directors, religious activities and education",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "64",
        "women": "53.0",
        "white": "4.1",
        "black": "1.1",
        "asian": "7.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "37",
        "median": "-",
        "maleWorkers": "20",
        "maleMedian": "-",
        "femaleWorkers": "18",
        "femaleMedian": "-"
      }
    }
  },
  "Religious workers, all other": {
    "ageData": {
      "2014": {
        "total": "68",
        "16to19": "1",
        "20to24": "8",
        "25to34": "9",
        "35to44": "15",
        "45to54": "19",
        "55to64": "10",
        "65+": "6",
        "medianAge": "10"
      }
    },
    "title": "Religious workers, all other",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "68",
        "women": "58.8",
        "white": "7.3",
        "black": "6.3",
        "asian": "6.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "40",
        "median": "-",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "23",
        "femaleMedian": "-"
      }
    }
  },
  "Legal occupations": {
    "ageData": {
      "2014": {
        "total": "1,814",
        "16to19": "5",
        "20to24": "37",
        "25to34": "388",
        "35to44": "405",
        "45to54": "431",
        "55to64": "387",
        "65+": "160",
        "medianAge": "387"
      }
    },
    "title": "Legal occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "1,814",
        "women": "50.8",
        "white": "7.5",
        "black": "4.5",
        "asian": "7.9"
      }
    },
    "wageData": {
      "2014": {
        "workers": "1,313",
        "median": "1,271",
        "maleWorkers": "591",
        "maleMedian": "1,765",
        "femaleWorkers": "722",
        "femaleMedian": "1,001"
      }
    }
  },
  "Lawyers": {
    "ageData": {
      "2014": {
        "total": "1,132",
        "16to19": "0",
        "20to24": "5",
        "25to34": "223",
        "35to44": "276",
        "45to54": "254",
        "55to64": "250",
        "65+": "123",
        "medianAge": "250"
      }
    },
    "title": "Lawyers",
    "depth": 4,
    "parent": "Legal occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "1,132",
        "women": "32.9",
        "white": "5.7",
        "black": "4.4",
        "asian": "5.6"
      }
    },
    "wageData": {
      "2014": {
        "workers": "737",
        "median": "1,807",
        "maleWorkers": "486",
        "maleMedian": "1,915",
        "femaleWorkers": "251",
        "femaleMedian": "1,590"
      }
    }
  },
  "Judicial law clerks": {
    "ageData": {
      "2014": {
        "total": "12",
        "16to19": "0",
        "20to24": "0",
        "25to34": "6",
        "35to44": "3",
        "45to54": "1",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Judicial law clerks",
    "depth": 4,
    "parent": "Legal occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "12",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Judges, magistrates, and other judicial workers": {
    "ageData": {
      "2014": {
        "total": "53",
        "16to19": "0",
        "20to24": "1",
        "25to34": "5",
        "35to44": "7",
        "45to54": "17",
        "55to64": "16",
        "65+": "8",
        "medianAge": "16"
      }
    },
    "title": "Judges, magistrates, and other judicial workers",
    "depth": 4,
    "parent": "Legal occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "53",
        "women": "51.7",
        "white": "10.9",
        "black": "3.2",
        "asian": "4.8"
      }
    },
    "wageData": {
      "2014": {
        "workers": "48",
        "median": "-",
        "maleWorkers": "23",
        "maleMedian": "-",
        "femaleWorkers": "26",
        "femaleMedian": "-"
      }
    }
  },
  "Paralegals and legal assistants": {
    "ageData": {
      "2014": {
        "total": "417",
        "16to19": "2",
        "20to24": "25",
        "25to34": "110",
        "35to44": "86",
        "45to54": "104",
        "55to64": "72",
        "65+": "18",
        "medianAge": "72"
      }
    },
    "title": "Paralegals and legal assistants",
    "depth": 4,
    "parent": "Legal occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "417",
        "women": "87.3",
        "white": "9.5",
        "black": "4.3",
        "asian": "14.2"
      }
    },
    "wageData": {
      "2014": {
        "workers": "359",
        "median": "831",
        "maleWorkers": "37",
        "maleMedian": "-",
        "femaleWorkers": "321",
        "femaleMedian": "840"
      }
    }
  },
  "Miscellaneous legal support workers": {
    "ageData": {
      "2014": {
        "total": "200",
        "16to19": "3",
        "20to24": "7",
        "25to34": "45",
        "35to44": "33",
        "45to54": "55",
        "55to64": "47",
        "65+": "10",
        "medianAge": "47"
      }
    },
    "title": "Miscellaneous legal support workers",
    "depth": 4,
    "parent": "Legal occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "200",
        "women": "75.8",
        "white": "12.8",
        "black": "5.1",
        "asian": "8.9"
      }
    },
    "wageData": {
      "2014": {
        "workers": "158",
        "median": "870",
        "maleWorkers": "39",
        "maleMedian": "-",
        "femaleWorkers": "118",
        "femaleMedian": "817"
      }
    }
  },
  "Education, training, and library occupations": {
    "ageData": {
      "2014": {
        "total": "8,686",
        "16to19": "97",
        "20to24": "617",
        "25to34": "2,027",
        "35to44": "2,034",
        "45to54": "1,857",
        "55to64": "1,508",
        "65+": "545",
        "medianAge": "1,508"
      }
    },
    "title": "Education, training, and library occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "8,686",
        "women": "74.1",
        "white": "10.3",
        "black": "4.4",
        "asian": "9.5"
      }
    },
    "wageData": {
      "2014": {
        "workers": "6,595",
        "median": "953",
        "maleWorkers": "1,763",
        "maleMedian": "1,141",
        "femaleWorkers": "4,832",
        "femaleMedian": "897"
      }
    }
  },
  "Postsecondary teachers": {
    "ageData": {
      "2014": {
        "total": "1,259",
        "16to19": "5",
        "20to24": "84",
        "25to34": "296",
        "35to44": "229",
        "45to54": "236",
        "55to64": "260",
        "65+": "150",
        "medianAge": "260"
      }
    },
    "title": "Postsecondary teachers",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "1,259",
        "women": "50.2",
        "white": "6.1",
        "black": "12.2",
        "asian": "6.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "860",
        "median": "1,256",
        "maleWorkers": "454",
        "maleMedian": "1,409",
        "femaleWorkers": "405",
        "femaleMedian": "1,143"
      }
    }
  },
  "Preschool and kindergarten teachers": {
    "ageData": {
      "2014": {
        "total": "664",
        "16to19": "6",
        "20to24": "67",
        "25to34": "192",
        "35to44": "158",
        "45to54": "135",
        "55to64": "77",
        "65+": "29",
        "medianAge": "77"
      }
    },
    "title": "Preschool and kindergarten teachers",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "664",
        "women": "97.2",
        "white": "15.8",
        "black": "3.4",
        "asian": "13.9"
      }
    },
    "wageData": {
      "2014": {
        "workers": "499",
        "median": "634",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "486",
        "femaleMedian": "625"
      }
    }
  },
  "Elementary and middle school teachers": {
    "ageData": {
      "2014": {
        "total": "3,102",
        "16to19": "8",
        "20to24": "139",
        "25to34": "766",
        "35to44": "855",
        "45to54": "709",
        "55to64": "479",
        "65+": "146",
        "medianAge": "479"
      }
    },
    "title": "Elementary and middle school teachers",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "3,102",
        "women": "80.9",
        "white": "10.2",
        "black": "2.5",
        "asian": "8.7"
      }
    },
    "wageData": {
      "2014": {
        "workers": "2,730",
        "median": "980",
        "maleWorkers": "534",
        "maleMedian": "1,096",
        "femaleWorkers": "2,196",
        "femaleMedian": "956"
      }
    }
  },
  "Secondary school teachers": {
    "ageData": {
      "2014": {
        "total": "1,099",
        "16to19": "2",
        "20to24": "53",
        "25to34": "296",
        "35to44": "282",
        "45to54": "233",
        "55to64": "189",
        "65+": "43",
        "medianAge": "189"
      }
    },
    "title": "Secondary school teachers",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "1,099",
        "women": "57.0",
        "white": "10.0",
        "black": "2.2",
        "asian": "7.6"
      }
    },
    "wageData": {
      "2014": {
        "workers": "981",
        "median": "1,037",
        "maleWorkers": "432",
        "maleMedian": "1,108",
        "femaleWorkers": "549",
        "femaleMedian": "984"
      }
    }
  },
  "Special education teachers": {
    "ageData": {
      "2014": {
        "total": "336",
        "16to19": "0",
        "20to24": "15",
        "25to34": "78",
        "35to44": "80",
        "45to54": "72",
        "55to64": "80",
        "65+": "11",
        "medianAge": "80"
      }
    },
    "title": "Special education teachers",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "336",
        "women": "83.7",
        "white": "8.9",
        "black": "1.5",
        "asian": "6.8"
      }
    },
    "wageData": {
      "2014": {
        "workers": "295",
        "median": "1,001",
        "maleWorkers": "57",
        "maleMedian": "1,078",
        "femaleWorkers": "239",
        "femaleMedian": "987"
      }
    }
  },
  "Other teachers and instructors": {
    "ageData": {
      "2014": {
        "total": "836",
        "16to19": "49",
        "20to24": "119",
        "25to34": "182",
        "35to44": "142",
        "45to54": "140",
        "55to64": "129",
        "65+": "75",
        "medianAge": "129"
      }
    },
    "title": "Other teachers and instructors",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "836",
        "women": "65.4",
        "white": "11.5",
        "black": "6.0",
        "asian": "11.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "340",
        "median": "846",
        "maleWorkers": "142",
        "maleMedian": "1,096",
        "femaleWorkers": "198",
        "femaleMedian": "772"
      }
    }
  },
  "Archivists, curators, and museum technicians": {
    "ageData": {
      "2014": {
        "total": "47",
        "16to19": "0",
        "20to24": "6",
        "25to34": "6",
        "35to44": "15",
        "45to54": "10",
        "55to64": "7",
        "65+": "3",
        "medianAge": "7"
      }
    },
    "title": "Archivists, curators, and museum technicians",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "47",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "32",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "20",
        "femaleMedian": "-"
      }
    }
  },
  "Librarians": {
    "ageData": {
      "2014": {
        "total": "198",
        "16to19": "2",
        "20to24": "6",
        "25to34": "25",
        "35to44": "47",
        "45to54": "41",
        "55to64": "55",
        "65+": "21",
        "medianAge": "55"
      }
    },
    "title": "Librarians",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "198",
        "women": "84.8",
        "white": "3.6",
        "black": "4.0",
        "asian": "5.7"
      }
    },
    "wageData": {
      "2014": {
        "workers": "155",
        "median": "889",
        "maleWorkers": "27",
        "maleMedian": "-",
        "femaleWorkers": "129",
        "femaleMedian": "849"
      }
    }
  },
  "Library technicians": {
    "ageData": {
      "2014": {
        "total": "36",
        "16to19": "6",
        "20to24": "10",
        "25to34": "4",
        "35to44": "4",
        "45to54": "5",
        "55to64": "6",
        "65+": "1",
        "medianAge": "6"
      }
    },
    "title": "Library technicians",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "36",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "9",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Teacher assistants": {
    "ageData": {
      "2014": {
        "total": "904",
        "16to19": "18",
        "20to24": "105",
        "25to34": "142",
        "35to44": "179",
        "45to54": "226",
        "55to64": "185",
        "65+": "47",
        "medianAge": "185"
      }
    },
    "title": "Teacher assistants",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "904",
        "women": "90.3",
        "white": "14.4",
        "black": "3.0",
        "asian": "17.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "545",
        "median": "498",
        "maleWorkers": "52",
        "maleMedian": "580",
        "femaleWorkers": "492",
        "femaleMedian": "494"
      }
    }
  },
  "Other education, training, and library workers": {
    "ageData": {
      "2014": {
        "total": "206",
        "16to19": "0",
        "20to24": "14",
        "25to34": "39",
        "35to44": "42",
        "45to54": "50",
        "55to64": "43",
        "65+": "17",
        "medianAge": "43"
      }
    },
    "title": "Other education, training, and library workers",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "206",
        "women": "74.0",
        "white": "10.9",
        "black": "2.9",
        "asian": "8.7"
      }
    },
    "wageData": {
      "2014": {
        "workers": "149",
        "median": "956",
        "maleWorkers": "37",
        "maleMedian": "-",
        "femaleWorkers": "111",
        "femaleMedian": "983"
      }
    }
  },
  "Arts, design, entertainment, sports, and media occupations": {
    "ageData": {
      "2014": {
        "total": "2,935",
        "16to19": "66",
        "20to24": "297",
        "25to34": "760",
        "35to44": "620",
        "45to54": "565",
        "55to64": "440",
        "65+": "188",
        "medianAge": "440"
      }
    },
    "title": "Arts, design, entertainment, sports, and media occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "2,935",
        "women": "47.4",
        "white": "6.3",
        "black": "5.0",
        "asian": "9.8"
      }
    },
    "wageData": {
      "2014": {
        "workers": "1,573",
        "median": "956",
        "maleWorkers": "906",
        "maleMedian": "1,024",
        "femaleWorkers": "667",
        "femaleMedian": "870"
      }
    }
  },
  "Artists and related workers": {
    "ageData": {
      "2014": {
        "total": "203",
        "16to19": "2",
        "20to24": "9",
        "25to34": "38",
        "35to44": "37",
        "45to54": "44",
        "55to64": "49",
        "65+": "24",
        "medianAge": "49"
      }
    },
    "title": "Artists and related workers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "203",
        "women": "48.8",
        "white": "2.1",
        "black": "5.7",
        "asian": "9.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "65",
        "median": "1,166",
        "maleWorkers": "43",
        "maleMedian": "-",
        "femaleWorkers": "22",
        "femaleMedian": "-"
      }
    }
  },
  "Designers": {
    "ageData": {
      "2014": {
        "total": "830",
        "16to19": "5",
        "20to24": "72",
        "25to34": "216",
        "35to44": "187",
        "45to54": "179",
        "55to64": "125",
        "65+": "46",
        "medianAge": "125"
      }
    },
    "title": "Designers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "830",
        "women": "56.3",
        "white": "4.1",
        "black": "7.1",
        "asian": "11.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "546",
        "median": "921",
        "maleWorkers": "289",
        "maleMedian": "1,020",
        "femaleWorkers": "257",
        "femaleMedian": "812"
      }
    }
  },
  "Actors": {
    "ageData": {
      "2014": {
        "total": "43",
        "16to19": "3",
        "20to24": "4",
        "25to34": "9",
        "35to44": "10",
        "45to54": "9",
        "55to64": "4",
        "65+": "4",
        "medianAge": "4"
      }
    },
    "title": "Actors",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "43",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Producers and directors": {
    "ageData": {
      "2014": {
        "total": "161",
        "16to19": "0",
        "20to24": "15",
        "25to34": "48",
        "35to44": "31",
        "45to54": "35",
        "55to64": "24",
        "65+": "8",
        "medianAge": "24"
      }
    },
    "title": "Producers and directors",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "161",
        "women": "36.5",
        "white": "4.6",
        "black": "4.0",
        "asian": "11.7"
      }
    },
    "wageData": {
      "2014": {
        "workers": "101",
        "median": "1,192",
        "maleWorkers": "66",
        "maleMedian": "1,224",
        "femaleWorkers": "35",
        "femaleMedian": "-"
      }
    }
  },
  "Athletes, coaches, umpires, and related workers": {
    "ageData": {
      "2014": {
        "total": "294",
        "16to19": "37",
        "20to24": "61",
        "25to34": "71",
        "35to44": "55",
        "45to54": "33",
        "55to64": "27",
        "65+": "10",
        "medianAge": "27"
      }
    },
    "title": "Athletes, coaches, umpires, and related workers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "294",
        "women": "34.3",
        "white": "6.5",
        "black": "2.5",
        "asian": "8.8"
      }
    },
    "wageData": {
      "2014": {
        "workers": "113",
        "median": "871",
        "maleWorkers": "83",
        "maleMedian": "974",
        "femaleWorkers": "29",
        "femaleMedian": "-"
      }
    }
  },
  "Dancers and choreographers": {
    "ageData": {
      "2014": {
        "total": "18",
        "16to19": "2",
        "20to24": "6",
        "25to34": "5",
        "35to44": "1",
        "45to54": "2",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Dancers and choreographers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "18",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Musicians, singers, and related workers": {
    "ageData": {
      "2014": {
        "total": "194",
        "16to19": "4",
        "20to24": "13",
        "25to34": "40",
        "35to44": "37",
        "45to54": "39",
        "55to64": "37",
        "65+": "24",
        "medianAge": "37"
      }
    },
    "title": "Musicians, singers, and related workers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "194",
        "women": "34.0",
        "white": "12.6",
        "black": "3.0",
        "asian": "6.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "39",
        "median": "-",
        "maleWorkers": "28",
        "maleMedian": "-",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "Entertainers and performers, sports and related workers, all other": {
    "ageData": {
      "2014": {
        "total": "38",
        "16to19": "2",
        "20to24": "6",
        "25to34": "9",
        "35to44": "9",
        "45to54": "3",
        "55to64": "6",
        "65+": "3",
        "medianAge": "6"
      }
    },
    "title": "Entertainers and performers, sports and related workers, all other",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "38",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "10",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Announcers": {
    "ageData": {
      "2014": {
        "total": "60",
        "16to19": "0",
        "20to24": "9",
        "25to34": "19",
        "35to44": "15",
        "45to54": "8",
        "55to64": "7",
        "65+": "2",
        "medianAge": "7"
      }
    },
    "title": "Announcers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "60",
        "women": "25.7",
        "white": "17.1",
        "black": "5.1",
        "asian": "10.5"
      }
    },
    "wageData": {
      "2014": {
        "workers": "28",
        "median": "-",
        "maleWorkers": "23",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "News analysts, reporters and correspondents": {
    "ageData": {
      "2014": {
        "total": "80",
        "16to19": "1",
        "20to24": "16",
        "25to34": "30",
        "35to44": "16",
        "45to54": "9",
        "55to64": "5",
        "65+": "3",
        "medianAge": "5"
      }
    },
    "title": "News analysts, reporters and correspondents",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "80",
        "women": "43.9",
        "white": "7.5",
        "black": "6.3",
        "asian": "13.8"
      }
    },
    "wageData": {
      "2014": {
        "workers": "59",
        "median": "802",
        "maleWorkers": "35",
        "maleMedian": "-",
        "femaleWorkers": "24",
        "femaleMedian": "-"
      }
    }
  },
  "Public relations specialists": {
    "ageData": {
      "2014": {
        "total": "136",
        "16to19": "0",
        "20to24": "12",
        "25to34": "48",
        "35to44": "31",
        "45to54": "23",
        "55to64": "16",
        "65+": "5",
        "medianAge": "16"
      }
    },
    "title": "Public relations specialists",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "136",
        "women": "60.5",
        "white": "9.0",
        "black": "4.0",
        "asian": "9.2"
      }
    },
    "wageData": {
      "2014": {
        "workers": "111",
        "median": "982",
        "maleWorkers": "42",
        "maleMedian": "-",
        "femaleWorkers": "69",
        "femaleMedian": "933"
      }
    }
  },
  "Editors": {
    "ageData": {
      "2014": {
        "total": "163",
        "16to19": "2",
        "20to24": "15",
        "25to34": "40",
        "35to44": "35",
        "45to54": "34",
        "55to64": "26",
        "65+": "11",
        "medianAge": "26"
      }
    },
    "title": "Editors",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "163",
        "women": "48.2",
        "white": "2.8",
        "black": "5.5",
        "asian": "7.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "120",
        "median": "1,018",
        "maleWorkers": "73",
        "maleMedian": "1,051",
        "femaleWorkers": "47",
        "femaleMedian": "-"
      }
    }
  },
  "Technical writers": {
    "ageData": {
      "2014": {
        "total": "61",
        "16to19": "0",
        "20to24": "3",
        "25to34": "14",
        "35to44": "10",
        "45to54": "17",
        "55to64": "15",
        "65+": "1",
        "medianAge": "15"
      }
    },
    "title": "Technical writers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "61",
        "women": "48.9",
        "white": "5.7",
        "black": "3.6",
        "asian": "2.6"
      }
    },
    "wageData": {
      "2014": {
        "workers": "51",
        "median": "1,297",
        "maleWorkers": "27",
        "maleMedian": "-",
        "femaleWorkers": "23",
        "femaleMedian": "-"
      }
    }
  },
  "Writers and authors": {
    "ageData": {
      "2014": {
        "total": "221",
        "16to19": "1",
        "20to24": "17",
        "25to34": "49",
        "35to44": "42",
        "45to54": "45",
        "55to64": "44",
        "65+": "24",
        "medianAge": "44"
      }
    },
    "title": "Writers and authors",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "221",
        "women": "59.1",
        "white": "6.2",
        "black": "3.2",
        "asian": "5.6"
      }
    },
    "wageData": {
      "2014": {
        "workers": "103",
        "median": "1,016",
        "maleWorkers": "42",
        "maleMedian": "-",
        "femaleWorkers": "60",
        "femaleMedian": "863"
      }
    }
  },
  "Miscellaneous media and communication workers": {
    "ageData": {
      "2014": {
        "total": "78",
        "16to19": "0",
        "20to24": "6",
        "25to34": "9",
        "35to44": "23",
        "45to54": "21",
        "55to64": "13",
        "65+": "7",
        "medianAge": "13"
      }
    },
    "title": "Miscellaneous media and communication workers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "78",
        "women": "73.6",
        "white": "4.8",
        "black": "11.6",
        "asian": "33.3"
      }
    },
    "wageData": {
      "2014": {
        "workers": "39",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "25",
        "femaleMedian": "-"
      }
    }
  },
  "Broadcast and sound engineering technicians and radio operators": {
    "ageData": {
      "2014": {
        "total": "108",
        "16to19": "2",
        "20to24": "16",
        "25to34": "29",
        "35to44": "21",
        "45to54": "26",
        "55to64": "11",
        "65+": "3",
        "medianAge": "11"
      }
    },
    "title": "Broadcast and sound engineering technicians and radio operators",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "108",
        "women": "13.0",
        "white": "15.2",
        "black": "4.8",
        "asian": "10.5"
      }
    },
    "wageData": {
      "2014": {
        "workers": "79",
        "median": "948",
        "maleWorkers": "67",
        "maleMedian": "958",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "Photographers": {
    "ageData": {
      "2014": {
        "total": "174",
        "16to19": "3",
        "20to24": "11",
        "25to34": "64",
        "35to44": "42",
        "45to54": "25",
        "55to64": "18",
        "65+": "11",
        "medianAge": "18"
      }
    },
    "title": "Photographers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "174",
        "women": "51.0",
        "white": "4.3",
        "black": "3.7",
        "asian": "10.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "55",
        "median": "938",
        "maleWorkers": "32",
        "maleMedian": "-",
        "femaleWorkers": "22",
        "femaleMedian": "-"
      }
    }
  },
  "Television, video, and motion picture camera operators and editors": {
    "ageData": {
      "2014": {
        "total": "72",
        "16to19": "3",
        "20to24": "7",
        "25to34": "22",
        "35to44": "18",
        "45to54": "10",
        "55to64": "11",
        "65+": "1",
        "medianAge": "11"
      }
    },
    "title": "Television, video, and motion picture camera operators and editors",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "72",
        "women": "18.1",
        "white": "4.4",
        "black": "0.0",
        "asian": "5.7"
      }
    },
    "wageData": {
      "2014": {
        "workers": "34",
        "median": "-",
        "maleWorkers": "28",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Media and communication equipment workers, all other": {
    "ageData": {
      "2014": {
        "total": "0",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Media and communication equipment workers, all other",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "0",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "0",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Healthcare practitioners and technical occupations": {
    "ageData": {
      "2014": {
        "total": "8,493",
        "16to19": "29",
        "20to24": "435",
        "25to34": "2,218",
        "35to44": "2,044",
        "45to54": "1,840",
        "55to64": "1,490",
        "65+": "438",
        "medianAge": "1,490"
      }
    },
    "title": "Healthcare practitioners and technical occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "8,493",
        "women": "74.2",
        "white": "11.2",
        "black": "9.4",
        "asian": "7.7"
      }
    },
    "wageData": {
      "2014": {
        "workers": "6,438",
        "median": "1,033",
        "maleWorkers": "1,663",
        "maleMedian": "1,256",
        "femaleWorkers": "4,775",
        "femaleMedian": "983"
      }
    }
  },
  "Chiropractors": {
    "ageData": {
      "2014": {
        "total": "66",
        "16to19": "0",
        "20to24": "0",
        "25to34": "12",
        "35to44": "16",
        "45to54": "17",
        "55to64": "14",
        "65+": "8",
        "medianAge": "14"
      }
    },
    "title": "Chiropractors",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "66",
        "women": "22.4",
        "white": "0.0",
        "black": "7.0",
        "asian": "2.9"
      }
    },
    "wageData": {
      "2014": {
        "workers": "18",
        "median": "-",
        "maleWorkers": "16",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Dentists": {
    "ageData": {
      "2014": {
        "total": "192",
        "16to19": "0",
        "20to24": "2",
        "25to34": "28",
        "35to44": "51",
        "45to54": "34",
        "55to64": "47",
        "65+": "30",
        "medianAge": "47"
      }
    },
    "title": "Dentists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "192",
        "women": "29.1",
        "white": "5.6",
        "black": "15.5",
        "asian": "6.8"
      }
    },
    "wageData": {
      "2014": {
        "workers": "61",
        "median": "1,908",
        "maleWorkers": "38",
        "maleMedian": "-",
        "femaleWorkers": "22",
        "femaleMedian": "-"
      }
    }
  },
  "Dietitians and nutritionists": {
    "ageData": {
      "2014": {
        "total": "123",
        "16to19": "1",
        "20to24": "9",
        "25to34": "34",
        "35to44": "20",
        "45to54": "27",
        "55to64": "25",
        "65+": "6",
        "medianAge": "25"
      }
    },
    "title": "Dietitians and nutritionists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "123",
        "women": "92.4",
        "white": "10.6",
        "black": "10.2",
        "asian": "5.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "86",
        "median": "919",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "80",
        "femaleMedian": "875"
      }
    }
  },
  "Optometrists": {
    "ageData": {
      "2014": {
        "total": "48",
        "16to19": "0",
        "20to24": "0",
        "25to34": "8",
        "35to44": "12",
        "45to54": "12",
        "55to64": "12",
        "65+": "4",
        "medianAge": "12"
      }
    },
    "title": "Optometrists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "48",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "28",
        "median": "-",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Pharmacists": {
    "ageData": {
      "2014": {
        "total": "293",
        "16to19": "0",
        "20to24": "12",
        "25to34": "90",
        "35to44": "70",
        "45to54": "54",
        "55to64": "47",
        "65+": "20",
        "medianAge": "47"
      }
    },
    "title": "Pharmacists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "293",
        "women": "56.3",
        "white": "8.1",
        "black": "18.9",
        "asian": "5.6"
      }
    },
    "wageData": {
      "2014": {
        "workers": "214",
        "median": "1,995",
        "maleWorkers": "103",
        "maleMedian": "2,176",
        "femaleWorkers": "111",
        "femaleMedian": "1,902"
      }
    }
  },
  "Physicians and surgeons": {
    "ageData": {
      "2014": {
        "total": "1,014",
        "16to19": "0",
        "20to24": "9",
        "25to34": "224",
        "35to44": "262",
        "45to54": "221",
        "55to64": "189",
        "65+": "108",
        "medianAge": "189"
      }
    },
    "title": "Physicians and surgeons",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "1,014",
        "women": "36.7",
        "white": "5.5",
        "black": "21.0",
        "asian": "6.3"
      }
    },
    "wageData": {
      "2014": {
        "workers": "759",
        "median": "1,661",
        "maleWorkers": "475",
        "maleMedian": "2,002",
        "femaleWorkers": "284",
        "femaleMedian": "1,246"
      }
    }
  },
  "Physician assistants": {
    "ageData": {
      "2014": {
        "total": "84",
        "16to19": "0",
        "20to24": "4",
        "25to34": "34",
        "35to44": "19",
        "45to54": "11",
        "55to64": "14",
        "65+": "2",
        "medianAge": "14"
      }
    },
    "title": "Physician assistants",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "84",
        "women": "74.5",
        "white": "6.4",
        "black": "11.3",
        "asian": "5.9"
      }
    },
    "wageData": {
      "2014": {
        "workers": "71",
        "median": "1,619",
        "maleWorkers": "22",
        "maleMedian": "-",
        "femaleWorkers": "49",
        "femaleMedian": "-"
      }
    }
  },
  "Podiatrists": {
    "ageData": {
      "2014": {
        "total": "8",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "4",
        "45to54": "2",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Podiatrists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "8",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Audiologists": {
    "ageData": {
      "2014": {
        "total": "20",
        "16to19": "0",
        "20to24": "0",
        "25to34": "6",
        "35to44": "3",
        "45to54": "4",
        "55to64": "4",
        "65+": "3",
        "medianAge": "4"
      }
    },
    "title": "Audiologists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "20",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "11",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "9",
        "femaleMedian": "-"
      }
    }
  },
  "Occupational therapists": {
    "ageData": {
      "2014": {
        "total": "111",
        "16to19": "1",
        "20to24": "3",
        "25to34": "36",
        "35to44": "28",
        "45to54": "26",
        "55to64": "14",
        "65+": "3",
        "medianAge": "14"
      }
    },
    "title": "Occupational therapists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "111",
        "women": "92.4",
        "white": "5.7",
        "black": "3.0",
        "asian": "5.2"
      }
    },
    "wageData": {
      "2014": {
        "workers": "80",
        "median": "1,146",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "72",
        "femaleMedian": "1,139"
      }
    }
  },
  "Physical therapists": {
    "ageData": {
      "2014": {
        "total": "244",
        "16to19": "0",
        "20to24": "5",
        "25to34": "76",
        "35to44": "71",
        "45to54": "57",
        "55to64": "27",
        "65+": "8",
        "medianAge": "27"
      }
    },
    "title": "Physical therapists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "244",
        "women": "69.8",
        "white": "6.7",
        "black": "12.5",
        "asian": "4.6"
      }
    },
    "wageData": {
      "2014": {
        "workers": "193",
        "median": "1,387",
        "maleWorkers": "74",
        "maleMedian": "1,478",
        "femaleWorkers": "118",
        "femaleMedian": "1,307"
      }
    }
  },
  "Radiation therapists": {
    "ageData": {
      "2014": {
        "total": "17",
        "16to19": "0",
        "20to24": "0",
        "25to34": "4",
        "35to44": "2",
        "45to54": "6",
        "55to64": "5",
        "65+": "0",
        "medianAge": "5"
      }
    },
    "title": "Radiation therapists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "17",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Recreational therapists": {
    "ageData": {
      "2014": {
        "total": "10",
        "16to19": "0",
        "20to24": "0",
        "25to34": "4",
        "35to44": "2",
        "45to54": "2",
        "55to64": "1",
        "65+": "1",
        "medianAge": "1"
      }
    },
    "title": "Recreational therapists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "10",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "10",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Respiratory therapists": {
    "ageData": {
      "2014": {
        "total": "112",
        "16to19": "0",
        "20to24": "1",
        "25to34": "26",
        "35to44": "31",
        "45to54": "25",
        "55to64": "24",
        "65+": "4",
        "medianAge": "24"
      }
    },
    "title": "Respiratory therapists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "112",
        "women": "62.4",
        "white": "13.7",
        "black": "9.8",
        "asian": "4.9"
      }
    },
    "wageData": {
      "2014": {
        "workers": "95",
        "median": "1,037",
        "maleWorkers": "38",
        "maleMedian": "-",
        "femaleWorkers": "57",
        "femaleMedian": "906"
      }
    }
  },
  "Speech-language pathologists": {
    "ageData": {
      "2014": {
        "total": "137",
        "16to19": "0",
        "20to24": "2",
        "25to34": "43",
        "35to44": "42",
        "45to54": "25",
        "55to64": "21",
        "65+": "4",
        "medianAge": "21"
      }
    },
    "title": "Speech-language pathologists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "137",
        "women": "98.4",
        "white": "7.2",
        "black": "3.2",
        "asian": "8.4"
      }
    },
    "wageData": {
      "2014": {
        "workers": "101",
        "median": "1,086",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "98",
        "femaleMedian": "1,089"
      }
    }
  },
  "Exercise physiologists": {
    "ageData": {
      "2014": {
        "total": "5",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "1",
        "45to54": "0",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Exercise physiologists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Therapists, all other": {
    "ageData": {
      "2014": {
        "total": "186",
        "16to19": "0",
        "20to24": "10",
        "25to34": "59",
        "35to44": "51",
        "45to54": "33",
        "55to64": "21",
        "65+": "12",
        "medianAge": "21"
      }
    },
    "title": "Therapists, all other",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "186",
        "women": "79.9",
        "white": "9.7",
        "black": "2.4",
        "asian": "13.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "119",
        "median": "908",
        "maleWorkers": "28",
        "maleMedian": "-",
        "femaleWorkers": "91",
        "femaleMedian": "863"
      }
    }
  },
  "Veterinarians": {
    "ageData": {
      "2014": {
        "total": "81",
        "16to19": "0",
        "20to24": "1",
        "25to34": "14",
        "35to44": "23",
        "45to54": "17",
        "55to64": "20",
        "65+": "6",
        "medianAge": "20"
      }
    },
    "title": "Veterinarians",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "81",
        "women": "56.2",
        "white": "3.1",
        "black": "1.1",
        "asian": "4.9"
      }
    },
    "wageData": {
      "2014": {
        "workers": "38",
        "median": "-",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "29",
        "femaleMedian": "-"
      }
    }
  },
  "Registered nurses": {
    "ageData": {
      "2014": {
        "total": "2,888",
        "16to19": "2",
        "20to24": "121",
        "25to34": "733",
        "35to44": "687",
        "45to54": "686",
        "55to64": "535",
        "65+": "124",
        "medianAge": "535"
      }
    },
    "title": "Registered nurses",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "2,888",
        "women": "90.0",
        "white": "11.8",
        "black": "8.2",
        "asian": "6.7"
      }
    },
    "wageData": {
      "2014": {
        "workers": "2,309",
        "median": "1,090",
        "maleWorkers": "245",
        "maleMedian": "1,190",
        "femaleWorkers": "2,064",
        "femaleMedian": "1,076"
      }
    }
  },
  "Nurse anesthetists": {
    "ageData": {
      "2014": {
        "total": "30",
        "16to19": "0",
        "20to24": "0",
        "25to34": "6",
        "35to44": "12",
        "45to54": "9",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Nurse anesthetists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "30",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "24",
        "median": "-",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "15",
        "femaleMedian": "-"
      }
    }
  },
  "Nurse midwives": {
    "ageData": {
      "2014": {
        "total": "4",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "1",
        "45to54": "0",
        "55to64": "1",
        "65+": "1",
        "medianAge": "1"
      }
    },
    "title": "Nurse midwives",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Nurse practitioners": {
    "ageData": {
      "2014": {
        "total": "128",
        "16to19": "0",
        "20to24": "0",
        "25to34": "31",
        "35to44": "26",
        "45to54": "31",
        "55to64": "32",
        "65+": "7",
        "medianAge": "32"
      }
    },
    "title": "Nurse practitioners",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "128",
        "women": "91.5",
        "white": "7.7",
        "black": "5.2",
        "asian": "3.9"
      }
    },
    "wageData": {
      "2014": {
        "workers": "100",
        "median": "1,683",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "90",
        "femaleMedian": "1,682"
      }
    }
  },
  "Health diagnosing and treating practitioners, all other": {
    "ageData": {
      "2014": {
        "total": "24",
        "16to19": "0",
        "20to24": "1",
        "25to34": "4",
        "35to44": "4",
        "45to54": "7",
        "55to64": "5",
        "65+": "3",
        "medianAge": "5"
      }
    },
    "title": "Health diagnosing and treating practitioners, all other",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "24",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Clinical laboratory technologists and technicians": {
    "ageData": {
      "2014": {
        "total": "293",
        "16to19": "1",
        "20to24": "18",
        "25to34": "76",
        "35to44": "66",
        "45to54": "60",
        "55to64": "57",
        "65+": "15",
        "medianAge": "57"
      }
    },
    "title": "Clinical laboratory technologists and technicians",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "293",
        "women": "74.0",
        "white": "18.2",
        "black": "12.8",
        "asian": "10.9"
      }
    },
    "wageData": {
      "2014": {
        "workers": "264",
        "median": "824",
        "maleWorkers": "70",
        "maleMedian": "878",
        "femaleWorkers": "194",
        "femaleMedian": "818"
      }
    }
  },
  "Dental hygienists": {
    "ageData": {
      "2014": {
        "total": "175",
        "16to19": "0",
        "20to24": "8",
        "25to34": "56",
        "35to44": "47",
        "45to54": "37",
        "55to64": "25",
        "65+": "3",
        "medianAge": "25"
      }
    },
    "title": "Dental hygienists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "175",
        "women": "97.1",
        "white": "4.3",
        "black": "5.5",
        "asian": "9.9"
      }
    },
    "wageData": {
      "2014": {
        "workers": "87",
        "median": "957",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "82",
        "femaleMedian": "951"
      }
    }
  },
  "Diagnostic related technologists and technicians": {
    "ageData": {
      "2014": {
        "total": "331",
        "16to19": "0",
        "20to24": "16",
        "25to34": "88",
        "35to44": "84",
        "45to54": "72",
        "55to64": "64",
        "65+": "8",
        "medianAge": "64"
      }
    },
    "title": "Diagnostic related technologists and technicians",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "331",
        "women": "73.6",
        "white": "6.5",
        "black": "5.3",
        "asian": "8.0"
      }
    },
    "wageData": {
      "2014": {
        "workers": "256",
        "median": "991",
        "maleWorkers": "70",
        "maleMedian": "1,156",
        "femaleWorkers": "187",
        "femaleMedian": "940"
      }
    }
  },
  "Emergency medical technicians and paramedics": {
    "ageData": {
      "2014": {
        "total": "232",
        "16to19": "3",
        "20to24": "37",
        "25to34": "86",
        "35to44": "55",
        "45to54": "33",
        "55to64": "14",
        "65+": "3",
        "medianAge": "14"
      }
    },
    "title": "Emergency medical technicians and paramedics",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "232",
        "women": "25.8",
        "white": "7.7",
        "black": "1.2",
        "asian": "8.4"
      }
    },
    "wageData": {
      "2014": {
        "workers": "207",
        "median": "754",
        "maleWorkers": "152",
        "maleMedian": "791",
        "femaleWorkers": "55",
        "femaleMedian": "709"
      }
    }
  },
  "Health practitioner support technologists and technicians": {
    "ageData": {
      "2014": {
        "total": "583",
        "16to19": "13",
        "20to24": "87",
        "25to34": "200",
        "35to44": "120",
        "45to54": "92",
        "55to64": "59",
        "65+": "11",
        "medianAge": "59"
      }
    },
    "title": "Health practitioner support technologists and technicians",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "583",
        "women": "81.4",
        "white": "13.2",
        "black": "6.8",
        "asian": "13.2"
      }
    },
    "wageData": {
      "2014": {
        "workers": "443",
        "median": "618",
        "maleWorkers": "93",
        "maleMedian": "618",
        "femaleWorkers": "350",
        "femaleMedian": "618"
      }
    }
  },
  "Licensed practical and licensed vocational nurses": {
    "ageData": {
      "2014": {
        "total": "641",
        "16to19": "5",
        "20to24": "63",
        "25to34": "140",
        "35to44": "141",
        "45to54": "138",
        "55to64": "126",
        "65+": "28",
        "medianAge": "126"
      }
    },
    "title": "Licensed practical and licensed vocational nurses",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "641",
        "women": "89.0",
        "white": "27.9",
        "black": "5.0",
        "asian": "9.9"
      }
    },
    "wageData": {
      "2014": {
        "workers": "506",
        "median": "751",
        "maleWorkers": "55",
        "maleMedian": "868",
        "femaleWorkers": "451",
        "femaleMedian": "737"
      }
    }
  },
  "Medical records and health information technicians": {
    "ageData": {
      "2014": {
        "total": "138",
        "16to19": "0",
        "20to24": "5",
        "25to34": "27",
        "35to44": "30",
        "45to54": "39",
        "55to64": "32",
        "65+": "6",
        "medianAge": "32"
      }
    },
    "title": "Medical records and health information technicians",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "138",
        "women": "90.2",
        "white": "19.8",
        "black": "4.4",
        "asian": "12.7"
      }
    },
    "wageData": {
      "2014": {
        "workers": "120",
        "median": "790",
        "maleWorkers": "16",
        "maleMedian": "-",
        "femaleWorkers": "104",
        "femaleMedian": "796"
      }
    }
  },
  "Opticians, dispensing": {
    "ageData": {
      "2014": {
        "total": "46",
        "16to19": "0",
        "20to24": "2",
        "25to34": "11",
        "35to44": "7",
        "45to54": "10",
        "55to64": "11",
        "65+": "4",
        "medianAge": "11"
      }
    },
    "title": "Opticians, dispensing",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "46",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "28",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "17",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous health technologists and technicians": {
    "ageData": {
      "2014": {
        "total": "123",
        "16to19": "1",
        "20to24": "11",
        "25to34": "27",
        "35to44": "25",
        "45to54": "35",
        "55to64": "20",
        "65+": "5",
        "medianAge": "20"
      }
    },
    "title": "Miscellaneous health technologists and technicians",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "123",
        "women": "65.3",
        "white": "18.0",
        "black": "6.5",
        "asian": "11.6"
      }
    },
    "wageData": {
      "2014": {
        "workers": "92",
        "median": "820",
        "maleWorkers": "33",
        "maleMedian": "-",
        "femaleWorkers": "58",
        "femaleMedian": "690"
      }
    }
  },
  "Other healthcare practitioners and technical occupations": {
    "ageData": {
      "2014": {
        "total": "108",
        "16to19": "0",
        "20to24": "5",
        "25to34": "32",
        "35to44": "30",
        "45to54": "19",
        "55to64": "19",
        "65+": "3",
        "medianAge": "19"
      }
    },
    "title": "Other healthcare practitioners and technical occupations",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "108",
        "women": "53.7",
        "white": "8.7",
        "black": "4.0",
        "asian": "9.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "93",
        "median": "1,000",
        "maleWorkers": "43",
        "maleMedian": "-",
        "femaleWorkers": "50",
        "femaleMedian": "930"
      }
    }
  },
  "Service occupations": {
    "ageData": {
      "2014": {
        "total": "25,854",
        "16to19": "1,881",
        "20to24": "3,960",
        "25to34": "5,677",
        "35to44": "4,830",
        "45to54": "4,872",
        "55to64": "3,423",
        "65+": "1,212",
        "medianAge": "3,423"
      }
    },
    "title": "Service occupations",
    "depth": 1,
    "parent": "",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "25,854",
        "women": "56.7",
        "white": "16.2",
        "black": "5.4",
        "asian": "23.4"
      }
    },
    "wageData": {
      "2014": {
        "workers": "15,019",
        "median": "505",
        "maleWorkers": "7,622",
        "maleMedian": "583",
        "femaleWorkers": "7,398",
        "femaleMedian": "461"
      }
    }
  },
  "Healthcare support occupations": {
    "ageData": {
      "2014": {
        "total": "3,461",
        "16to19": "83",
        "20to24": "475",
        "25to34": "854",
        "35to44": "716",
        "45to54": "708",
        "55to64": "487",
        "65+": "138",
        "medianAge": "487"
      }
    },
    "title": "Healthcare support occupations",
    "depth": 2,
    "parent": "Service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "3,461",
        "women": "87.6",
        "white": "25.7",
        "black": "5.2",
        "asian": "16.2"
      }
    },
    "wageData": {
      "2014": {
        "workers": "2,368",
        "median": "498",
        "maleWorkers": "307",
        "maleMedian": "560",
        "femaleWorkers": "2,060",
        "femaleMedian": "492"
      }
    }
  },
  "Nursing, psychiatric, and home health aides": {
    "ageData": {
      "2014": {
        "total": "1,980",
        "16to19": "51",
        "20to24": "267",
        "25to34": "435",
        "35to44": "370",
        "45to54": "445",
        "55to64": "303",
        "65+": "107",
        "medianAge": "303"
      }
    },
    "title": "Nursing, psychiatric, and home health aides",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "1,980",
        "women": "88.5",
        "white": "35.9",
        "black": "4.5",
        "asian": "15.4"
      }
    },
    "wageData": {
      "2014": {
        "workers": "1,364",
        "median": "472",
        "maleWorkers": "164",
        "maleMedian": "528",
        "femaleWorkers": "1,200",
        "femaleMedian": "466"
      }
    }
  },
  "Occupational therapy assistants and aides": {
    "ageData": {
      "2014": {
        "total": "17",
        "16to19": "0",
        "20to24": "1",
        "25to34": "3",
        "35to44": "6",
        "45to54": "4",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Occupational therapy assistants and aides",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "17",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Physical therapist assistants and aides": {
    "ageData": {
      "2014": {
        "total": "70",
        "16to19": "0",
        "20to24": "9",
        "25to34": "22",
        "35to44": "15",
        "45to54": "15",
        "55to64": "9",
        "65+": "0",
        "medianAge": "9"
      }
    },
    "title": "Physical therapist assistants and aides",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "70",
        "women": "64.6",
        "white": "7.8",
        "black": "8.8",
        "asian": "5.5"
      }
    },
    "wageData": {
      "2014": {
        "workers": "50",
        "median": "693",
        "maleWorkers": "23",
        "maleMedian": "-",
        "femaleWorkers": "26",
        "femaleMedian": "-"
      }
    }
  },
  "Massage therapists": {
    "ageData": {
      "2014": {
        "total": "172",
        "16to19": "1",
        "20to24": "12",
        "25to34": "41",
        "35to44": "46",
        "45to54": "40",
        "55to64": "26",
        "65+": "7",
        "medianAge": "26"
      }
    },
    "title": "Massage therapists",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "172",
        "women": "82.7",
        "white": "4.3",
        "black": "10.6",
        "asian": "8.4"
      }
    },
    "wageData": {
      "2014": {
        "workers": "41",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "30",
        "femaleMedian": "-"
      }
    }
  },
  "Dental assistants": {
    "ageData": {
      "2014": {
        "total": "273",
        "16to19": "6",
        "20to24": "45",
        "25to34": "78",
        "35to44": "67",
        "45to54": "47",
        "55to64": "24",
        "65+": "5",
        "medianAge": "24"
      }
    },
    "title": "Dental assistants",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "273",
        "women": "96.6",
        "white": "5.1",
        "black": "5.4",
        "asian": "23.5"
      }
    },
    "wageData": {
      "2014": {
        "workers": "185",
        "median": "535",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "179",
        "femaleMedian": "535"
      }
    }
  },
  "Medical assistants": {
    "ageData": {
      "2014": {
        "total": "508",
        "16to19": "7",
        "20to24": "63",
        "25to34": "173",
        "35to44": "129",
        "45to54": "78",
        "55to64": "50",
        "65+": "8",
        "medianAge": "50"
      }
    },
    "title": "Medical assistants",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "508",
        "women": "92.8",
        "white": "14.3",
        "black": "5.0",
        "asian": "21.9"
      }
    },
    "wageData": {
      "2014": {
        "workers": "412",
        "median": "546",
        "maleWorkers": "29",
        "maleMedian": "-",
        "femaleWorkers": "383",
        "femaleMedian": "539"
      }
    }
  },
  "Medical transcriptionists": {
    "ageData": {
      "2014": {
        "total": "58",
        "16to19": "1",
        "20to24": "4",
        "25to34": "6",
        "35to44": "12",
        "45to54": "15",
        "55to64": "17",
        "65+": "4",
        "medianAge": "17"
      }
    },
    "title": "Medical transcriptionists",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "58",
        "women": "97.1",
        "white": "3.9",
        "black": "0.4",
        "asian": "4.7"
      }
    },
    "wageData": {
      "2014": {
        "workers": "32",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "31",
        "femaleMedian": "-"
      }
    }
  },
  "Pharmacy aides": {
    "ageData": {
      "2014": {
        "total": "45",
        "16to19": "2",
        "20to24": "10",
        "25to34": "17",
        "35to44": "7",
        "45to54": "5",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Pharmacy aides",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "45",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "24",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "17",
        "femaleMedian": "-"
      }
    }
  },
  "Veterinary assistants and laboratory animal caretakers": {
    "ageData": {
      "2014": {
        "total": "41",
        "16to19": "5",
        "20to24": "13",
        "25to34": "9",
        "35to44": "6",
        "45to54": "4",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Veterinary assistants and laboratory animal caretakers",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "41",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "26",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "22",
        "femaleMedian": "-"
      }
    }
  },
  "Phlebotomists": {
    "ageData": {
      "2014": {
        "total": "118",
        "16to19": "2",
        "20to24": "9",
        "25to34": "33",
        "35to44": "32",
        "45to54": "21",
        "55to64": "18",
        "65+": "2",
        "medianAge": "18"
      }
    },
    "title": "Phlebotomists",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "118",
        "women": "83.4",
        "white": "17.9",
        "black": "3.8",
        "asian": "18.0"
      }
    },
    "wageData": {
      "2014": {
        "workers": "103",
        "median": "533",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "84",
        "femaleMedian": "526"
      }
    }
  },
  "Miscellaneous healthcare support occupations, including medical equipment preparers": {
    "ageData": {
      "2014": {
        "total": "179",
        "16to19": "8",
        "20to24": "41",
        "25to34": "37",
        "35to44": "25",
        "45to54": "33",
        "55to64": "30",
        "65+": "4",
        "medianAge": "30"
      }
    },
    "title": "Miscellaneous healthcare support occupations, including medical equipment preparers",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "179",
        "women": "65.5",
        "white": "26.9",
        "black": "6.6",
        "asian": "11.4"
      }
    },
    "wageData": {
      "2014": {
        "workers": "123",
        "median": "509",
        "maleWorkers": "40",
        "maleMedian": "-",
        "femaleWorkers": "83",
        "femaleMedian": "493"
      }
    }
  },
  "Protective service occupations": {
    "ageData": {
      "2014": {
        "total": "3,140",
        "16to19": "92",
        "20to24": "264",
        "25to34": "743",
        "35to44": "742",
        "45to54": "719",
        "55to64": "416",
        "65+": "163",
        "medianAge": "416"
      }
    },
    "title": "Protective service occupations",
    "depth": 2,
    "parent": "Service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "3,140",
        "women": "21.8",
        "white": "19.7",
        "black": "2.3",
        "asian": "14.4"
      }
    },
    "wageData": {
      "2014": {
        "workers": "2,740",
        "median": "833",
        "maleWorkers": "2,211",
        "maleMedian": "886",
        "femaleWorkers": "530",
        "femaleMedian": "617"
      }
    }
  },
  "First-line supervisors of correctional officers": {
    "ageData": {
      "2014": {
        "total": "49",
        "16to19": "0",
        "20to24": "0",
        "25to34": "9",
        "35to44": "15",
        "45to54": "16",
        "55to64": "8",
        "65+": "1",
        "medianAge": "8"
      }
    },
    "title": "First-line supervisors of correctional officers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "49",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "46",
        "median": "-",
        "maleWorkers": "33",
        "maleMedian": "-",
        "femaleWorkers": "13",
        "femaleMedian": "-"
      }
    }
  },
  "First-line supervisors of police and detectives": {
    "ageData": {
      "2014": {
        "total": "126",
        "16to19": "0",
        "20to24": "0",
        "25to34": "5",
        "35to44": "39",
        "45to54": "54",
        "55to64": "24",
        "65+": "4",
        "medianAge": "24"
      }
    },
    "title": "First-line supervisors of police and detectives",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "126",
        "women": "15.9",
        "white": "14.4",
        "black": "0.7",
        "asian": "7.0"
      }
    },
    "wageData": {
      "2014": {
        "workers": "120",
        "median": "1,127",
        "maleWorkers": "103",
        "maleMedian": "1,149",
        "femaleWorkers": "17",
        "femaleMedian": "-"
      }
    }
  },
  "First-line supervisors of fire fighting and prevention workers": {
    "ageData": {
      "2014": {
        "total": "58",
        "16to19": "0",
        "20to24": "0",
        "25to34": "6",
        "35to44": "17",
        "45to54": "26",
        "55to64": "10",
        "65+": "0",
        "medianAge": "10"
      }
    },
    "title": "First-line supervisors of fire fighting and prevention workers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "58",
        "women": "7.8",
        "white": "6.2",
        "black": "1.6",
        "asian": "9.3"
      }
    },
    "wageData": {
      "2014": {
        "workers": "56",
        "median": "1,438",
        "maleWorkers": "54",
        "maleMedian": "1,448",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "First-line supervisors of protective service workers, all other": {
    "ageData": {
      "2014": {
        "total": "91",
        "16to19": "0",
        "20to24": "4",
        "25to34": "20",
        "35to44": "20",
        "45to54": "23",
        "55to64": "17",
        "65+": "6",
        "medianAge": "17"
      }
    },
    "title": "First-line supervisors of protective service workers, all other",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "91",
        "women": "20.9",
        "white": "10.9",
        "black": "1.6",
        "asian": "10.3"
      }
    },
    "wageData": {
      "2014": {
        "workers": "90",
        "median": "913",
        "maleWorkers": "74",
        "maleMedian": "1,004",
        "femaleWorkers": "16",
        "femaleMedian": "-"
      }
    }
  },
  "Firefighters": {
    "ageData": {
      "2014": {
        "total": "300",
        "16to19": "3",
        "20to24": "14",
        "25to34": "91",
        "35to44": "98",
        "45to54": "68",
        "55to64": "22",
        "65+": "4",
        "medianAge": "22"
      }
    },
    "title": "Firefighters",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "300",
        "women": "5.7",
        "white": "10.2",
        "black": "0.7",
        "asian": "9.6"
      }
    },
    "wageData": {
      "2014": {
        "workers": "291",
        "median": "1,138",
        "maleWorkers": "274",
        "maleMedian": "1,142",
        "femaleWorkers": "16",
        "femaleMedian": "-"
      }
    }
  },
  "Fire inspectors": {
    "ageData": {
      "2014": {
        "total": "13",
        "16to19": "0",
        "20to24": "0",
        "25to34": "5",
        "35to44": "2",
        "45to54": "2",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Fire inspectors",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "13",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "11",
        "median": "-",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Bailiffs, correctional officers, and jailers": {
    "ageData": {
      "2014": {
        "total": "395",
        "16to19": "4",
        "20to24": "39",
        "25to34": "96",
        "35to44": "93",
        "45to54": "103",
        "55to64": "50",
        "65+": "11",
        "medianAge": "50"
      }
    },
    "title": "Bailiffs, correctional officers, and jailers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "395",
        "women": "28.6",
        "white": "20.6",
        "black": "1.7",
        "asian": "16.0"
      }
    },
    "wageData": {
      "2014": {
        "workers": "392",
        "median": "730",
        "maleWorkers": "279",
        "maleMedian": "755",
        "femaleWorkers": "113",
        "femaleMedian": "687"
      }
    }
  },
  "Detectives and criminal investigators": {
    "ageData": {
      "2014": {
        "total": "164",
        "16to19": "1",
        "20to24": "4",
        "25to34": "33",
        "35to44": "54",
        "45to54": "40",
        "55to64": "28",
        "65+": "3",
        "medianAge": "28"
      }
    },
    "title": "Detectives and criminal investigators",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "164",
        "women": "21.0",
        "white": "14.6",
        "black": "1.6",
        "asian": "13.2"
      }
    },
    "wageData": {
      "2014": {
        "workers": "162",
        "median": "1,173",
        "maleWorkers": "130",
        "maleMedian": "1,183",
        "femaleWorkers": "32",
        "femaleMedian": "-"
      }
    }
  },
  "Fish and game wardens": {
    "ageData": {
      "2014": {
        "total": "3",
        "16to19": "0",
        "20to24": "1",
        "25to34": "0",
        "35to44": "1",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Fish and game wardens",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Parking enforcement workers": {
    "ageData": {
      "2014": {
        "total": "10",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "1",
        "45to54": "6",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Parking enforcement workers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "10",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "9",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Police and sheriff's patrol officers": {
    "ageData": {
      "2014": {
        "total": "680",
        "16to19": "2",
        "20to24": "32",
        "25to34": "198",
        "35to44": "204",
        "45to54": "178",
        "55to64": "49",
        "65+": "17",
        "medianAge": "49"
      }
    },
    "title": "Police and sheriff's patrol officers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "680",
        "women": "12.4",
        "white": "15.6",
        "black": "1.8",
        "asian": "13.2"
      }
    },
    "wageData": {
      "2014": {
        "workers": "671",
        "median": "1,018",
        "maleWorkers": "593",
        "maleMedian": "1,043",
        "femaleWorkers": "78",
        "femaleMedian": "743"
      }
    }
  },
  "Transit and railroad police": {
    "ageData": {
      "2014": {
        "total": "3",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "1",
        "45to54": "2",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Transit and railroad police",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Animal control workers": {
    "ageData": {
      "2014": {
        "total": "9",
        "16to19": "0",
        "20to24": "2",
        "25to34": "2",
        "35to44": "1",
        "45to54": "1",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Animal control workers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "9",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "10",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Private detectives and investigators": {
    "ageData": {
      "2014": {
        "total": "98",
        "16to19": "0",
        "20to24": "3",
        "25to34": "25",
        "35to44": "27",
        "45to54": "21",
        "55to64": "16",
        "65+": "5",
        "medianAge": "16"
      }
    },
    "title": "Private detectives and investigators",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "98",
        "women": "37.7",
        "white": "14.5",
        "black": "3.0",
        "asian": "13.5"
      }
    },
    "wageData": {
      "2014": {
        "workers": "78",
        "median": "1,025",
        "maleWorkers": "49",
        "maleMedian": "-",
        "femaleWorkers": "29",
        "femaleMedian": "-"
      }
    }
  },
  "Security guards and gaming surveillance officers": {
    "ageData": {
      "2014": {
        "total": "899",
        "16to19": "12",
        "20to24": "122",
        "25to34": "228",
        "35to44": "151",
        "45to54": "149",
        "55to64": "156",
        "65+": "81",
        "medianAge": "156"
      }
    },
    "title": "Security guards and gaming surveillance officers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "899",
        "women": "22.6",
        "white": "30.3",
        "black": "3.7",
        "asian": "19.0"
      }
    },
    "wageData": {
      "2014": {
        "workers": "710",
        "median": "569",
        "maleWorkers": "541",
        "maleMedian": "589",
        "femaleWorkers": "169",
        "femaleMedian": "514"
      }
    }
  },
  "Crossing guards": {
    "ageData": {
      "2014": {
        "total": "66",
        "16to19": "0",
        "20to24": "6",
        "25to34": "6",
        "35to44": "8",
        "45to54": "11",
        "55to64": "18",
        "65+": "17",
        "medianAge": "18"
      }
    },
    "title": "Crossing guards",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "66",
        "women": "66.3",
        "white": "21.6",
        "black": "6.2",
        "asian": "6.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "19",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Transportation security screeners": {
    "ageData": {
      "2014": {
        "total": "33",
        "16to19": "1",
        "20to24": "1",
        "25to34": "10",
        "35to44": "9",
        "45to54": "6",
        "55to64": "4",
        "65+": "3",
        "medianAge": "4"
      }
    },
    "title": "Transportation security screeners",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "33",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "28",
        "median": "-",
        "maleWorkers": "18",
        "maleMedian": "-",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Lifeguards and other recreational, and all other protective service workers": {
    "ageData": {
      "2014": {
        "total": "141",
        "16to19": "68",
        "20to24": "37",
        "25to34": "8",
        "35to44": "2",
        "45to54": "10",
        "55to64": "7",
        "65+": "7",
        "medianAge": "7"
      }
    },
    "title": "Lifeguards and other recreational, and all other protective service workers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "141",
        "women": "49.7",
        "white": "12.6",
        "black": "1.8",
        "asian": "11.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "43",
        "median": "-",
        "maleWorkers": "24",
        "maleMedian": "-",
        "femaleWorkers": "18",
        "femaleMedian": "-"
      }
    }
  },
  "Food preparation and serving related occupations": {
    "ageData": {
      "2014": {
        "total": "8,112",
        "16to19": "1,240",
        "20to24": "1,934",
        "25to34": "1,872",
        "35to44": "1,191",
        "45to54": "1,023",
        "55to64": "634",
        "65+": "219",
        "medianAge": "634"
      }
    },
    "title": "Food preparation and serving related occupations",
    "depth": 2,
    "parent": "Service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "8,112",
        "women": "55.1",
        "white": "12.6",
        "black": "6.0",
        "asian": "24.9"
      }
    },
    "wageData": {
      "2014": {
        "workers": "4,017",
        "median": "439",
        "maleWorkers": "2,140",
        "maleMedian": "463",
        "femaleWorkers": "1,877",
        "femaleMedian": "419"
      }
    }
  },
  "Chefs and head cooks": {
    "ageData": {
      "2014": {
        "total": "430",
        "16to19": "5",
        "20to24": "54",
        "25to34": "124",
        "35to44": "96",
        "45to54": "97",
        "55to64": "47",
        "65+": "7",
        "medianAge": "47"
      }
    },
    "title": "Chefs and head cooks",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "430",
        "women": "21.4",
        "white": "15.3",
        "black": "16.4",
        "asian": "18.3"
      }
    },
    "wageData": {
      "2014": {
        "workers": "364",
        "median": "590",
        "maleWorkers": "302",
        "maleMedian": "601",
        "femaleWorkers": "61",
        "femaleMedian": "531"
      }
    }
  },
  "First-line supervisors of food preparation and serving workers": {
    "ageData": {
      "2014": {
        "total": "545",
        "16to19": "25",
        "20to24": "106",
        "25to34": "139",
        "35to44": "110",
        "45to54": "95",
        "55to64": "53",
        "65+": "17",
        "medianAge": "53"
      }
    },
    "title": "First-line supervisors of food preparation and serving workers",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "545",
        "women": "59.1",
        "white": "13.7",
        "black": "4.7",
        "asian": "19.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "403",
        "median": "516",
        "maleWorkers": "184",
        "maleMedian": "583",
        "femaleWorkers": "219",
        "femaleMedian": "485"
      }
    }
  },
  "Cooks": {
    "ageData": {
      "2014": {
        "total": "1,992",
        "16to19": "229",
        "20to24": "330",
        "25to34": "504",
        "35to44": "348",
        "45to54": "326",
        "55to64": "198",
        "65+": "56",
        "medianAge": "198"
      }
    },
    "title": "Cooks",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "1,992",
        "women": "40.6",
        "white": "16.5",
        "black": "4.9",
        "asian": "34.0"
      }
    },
    "wageData": {
      "2014": {
        "workers": "1,166",
        "median": "413",
        "maleWorkers": "773",
        "maleMedian": "416",
        "femaleWorkers": "392",
        "femaleMedian": "405"
      }
    }
  },
  "Food preparation workers": {
    "ageData": {
      "2014": {
        "total": "885",
        "16to19": "167",
        "20to24": "243",
        "25to34": "178",
        "35to44": "107",
        "45to54": "96",
        "55to64": "61",
        "65+": "33",
        "medianAge": "61"
      }
    },
    "title": "Food preparation workers",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "885",
        "women": "54.7",
        "white": "13.4",
        "black": "6.9",
        "asian": "27.2"
      }
    },
    "wageData": {
      "2014": {
        "workers": "359",
        "median": "406",
        "maleWorkers": "178",
        "maleMedian": "411",
        "femaleWorkers": "181",
        "femaleMedian": "401"
      }
    }
  },
  "Bartenders": {
    "ageData": {
      "2014": {
        "total": "416",
        "16to19": "5",
        "20to24": "79",
        "25to34": "161",
        "35to44": "83",
        "45to54": "42",
        "55to64": "33",
        "65+": "12",
        "medianAge": "33"
      }
    },
    "title": "Bartenders",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "416",
        "women": "57.5",
        "white": "6.0",
        "black": "1.9",
        "asian": "15.5"
      }
    },
    "wageData": {
      "2014": {
        "workers": "216",
        "median": "522",
        "maleWorkers": "103",
        "maleMedian": "634",
        "femaleWorkers": "113",
        "femaleMedian": "459"
      }
    }
  },
  "Combined food preparation and serving workers, including fast food": {
    "ageData": {
      "2014": {
        "total": "428",
        "16to19": "92",
        "20to24": "116",
        "25to34": "76",
        "35to44": "41",
        "45to54": "45",
        "55to64": "38",
        "65+": "20",
        "medianAge": "38"
      }
    },
    "title": "Combined food preparation and serving workers, including fast food",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "428",
        "women": "61.9",
        "white": "20.5",
        "black": "3.3",
        "asian": "18.7"
      }
    },
    "wageData": {
      "2014": {
        "workers": "169",
        "median": "379",
        "maleWorkers": "66",
        "maleMedian": "384",
        "femaleWorkers": "102",
        "femaleMedian": "373"
      }
    }
  },
  "Counter attendants, cafeteria, food concession, and coffee shop": {
    "ageData": {
      "2014": {
        "total": "254",
        "16to19": "119",
        "20to24": "75",
        "25to34": "26",
        "35to44": "14",
        "45to54": "13",
        "55to64": "5",
        "65+": "2",
        "medianAge": "5"
      }
    },
    "title": "Counter attendants, cafeteria, food concession, and coffee shop",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "254",
        "women": "70.4",
        "white": "13.9",
        "black": "4.3",
        "asian": "21.6"
      }
    },
    "wageData": {
      "2014": {
        "workers": "60",
        "median": "349",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "41",
        "femaleMedian": "-"
      }
    }
  },
  "Waiters and waitresses": {
    "ageData": {
      "2014": {
        "total": "2,054",
        "16to19": "300",
        "20to24": "679",
        "25to34": "492",
        "35to44": "275",
        "45to54": "178",
        "55to64": "102",
        "65+": "29",
        "medianAge": "102"
      }
    },
    "title": "Waiters and waitresses",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "2,054",
        "women": "71.8",
        "white": "7.5",
        "black": "6.6",
        "asian": "19.4"
      }
    },
    "wageData": {
      "2014": {
        "workers": "826",
        "median": "436",
        "maleWorkers": "283",
        "maleMedian": "501",
        "femaleWorkers": "542",
        "femaleMedian": "415"
      }
    }
  },
  "Food servers, nonrestaurant": {
    "ageData": {
      "2014": {
        "total": "185",
        "16to19": "22",
        "20to24": "31",
        "25to34": "34",
        "35to44": "24",
        "45to54": "41",
        "55to64": "25",
        "65+": "8",
        "medianAge": "25"
      }
    },
    "title": "Food servers, nonrestaurant",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "185",
        "women": "61.9",
        "white": "23.5",
        "black": "6.1",
        "asian": "20.8"
      }
    },
    "wageData": {
      "2014": {
        "workers": "111",
        "median": "502",
        "maleWorkers": "47",
        "maleMedian": "-",
        "femaleWorkers": "64",
        "femaleMedian": "495"
      }
    }
  },
  "Dining room and cafeteria attendants and bartender helpers": {
    "ageData": {
      "2014": {
        "total": "375",
        "16to19": "92",
        "20to24": "75",
        "25to34": "60",
        "35to44": "48",
        "45to54": "49",
        "55to64": "36",
        "65+": "15",
        "medianAge": "36"
      }
    },
    "title": "Dining room and cafeteria attendants and bartender helpers",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "375",
        "women": "49.2",
        "white": "7.6",
        "black": "5.5",
        "asian": "34.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "151",
        "median": "451",
        "maleWorkers": "73",
        "maleMedian": "509",
        "femaleWorkers": "78",
        "femaleMedian": "420"
      }
    }
  },
  "Dishwashers": {
    "ageData": {
      "2014": {
        "total": "246",
        "16to19": "60",
        "20to24": "51",
        "25to34": "43",
        "35to44": "31",
        "45to54": "30",
        "55to64": "25",
        "65+": "7",
        "medianAge": "25"
      }
    },
    "title": "Dishwashers",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "246",
        "women": "21.2",
        "white": "12.3",
        "black": "5.7",
        "asian": "40.2"
      }
    },
    "wageData": {
      "2014": {
        "workers": "128",
        "median": "368",
        "maleWorkers": "101",
        "maleMedian": "364",
        "femaleWorkers": "27",
        "femaleMedian": "-"
      }
    }
  },
  "Hosts and hostesses, restaurant, lounge, and coffee shop": {
    "ageData": {
      "2014": {
        "total": "297",
        "16to19": "124",
        "20to24": "93",
        "25to34": "34",
        "35to44": "13",
        "45to54": "12",
        "55to64": "9",
        "65+": "13",
        "medianAge": "9"
      }
    },
    "title": "Hosts and hostesses, restaurant, lounge, and coffee shop",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "297",
        "women": "85.2",
        "white": "10.9",
        "black": "6.5",
        "asian": "18.2"
      }
    },
    "wageData": {
      "2014": {
        "workers": "62",
        "median": "433",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "53",
        "femaleMedian": "418"
      }
    }
  },
  "Food preparation and serving related workers, all other": {
    "ageData": {
      "2014": {
        "total": "6",
        "16to19": "2",
        "20to24": "1",
        "25to34": "1",
        "35to44": "0",
        "45to54": "0",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Food preparation and serving related workers, all other",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "6",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Building and grounds cleaning and maintenance occupations": {
    "ageData": {
      "2014": {
        "total": "5,803",
        "16to19": "198",
        "20to24": "506",
        "25to34": "1,081",
        "35to44": "1,192",
        "45to54": "1,401",
        "55to64": "1,089",
        "65+": "336",
        "medianAge": "1,089"
      }
    },
    "title": "Building and grounds cleaning and maintenance occupations",
    "depth": 2,
    "parent": "Service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "5,803",
        "women": "40.2",
        "white": "14.6",
        "black": "3.4",
        "asian": "36.7"
      }
    },
    "wageData": {
      "2014": {
        "workers": "3,568",
        "median": "480",
        "maleWorkers": "2,331",
        "maleMedian": "517",
        "femaleWorkers": "1,236",
        "femaleMedian": "413"
      }
    }
  },
  "First-line supervisors of housekeeping and janitorial workers": {
    "ageData": {
      "2014": {
        "total": "282",
        "16to19": "4",
        "20to24": "6",
        "25to34": "42",
        "35to44": "62",
        "45to54": "92",
        "55to64": "61",
        "65+": "15",
        "medianAge": "61"
      }
    },
    "title": "First-line supervisors of housekeeping and janitorial workers",
    "depth": 3,
    "parent": "Building and grounds cleaning and maintenance occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "282",
        "women": "42.3",
        "white": "14.7",
        "black": "2.8",
        "asian": "24.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "153",
        "median": "596",
        "maleWorkers": "89",
        "maleMedian": "720",
        "femaleWorkers": "64",
        "femaleMedian": "500"
      }
    }
  },
  "First-line supervisors of landscaping, lawn service, and groundskeeping workers": {
    "ageData": {
      "2014": {
        "total": "210",
        "16to19": "2",
        "20to24": "7",
        "25to34": "40",
        "35to44": "55",
        "45to54": "58",
        "55to64": "43",
        "65+": "6",
        "medianAge": "43"
      }
    },
    "title": "First-line supervisors of landscaping, lawn service, and groundskeeping workers",
    "depth": 3,
    "parent": "Building and grounds cleaning and maintenance occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "210",
        "women": "4.8",
        "white": "8.3",
        "black": "1.4",
        "asian": "22.7"
      }
    },
    "wageData": {
      "2014": {
        "workers": "91",
        "median": "717",
        "maleWorkers": "90",
        "maleMedian": "716",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Janitors and building cleaners": {
    "ageData": {
      "2014": {
        "total": "2,328",
        "16to19": "92",
        "20to24": "213",
        "25to34": "377",
        "35to44": "408",
        "45to54": "561",
        "55to64": "519",
        "65+": "160",
        "medianAge": "519"
      }
    },
    "title": "Janitors and building cleaners",
    "depth": 3,
    "parent": "Building and grounds cleaning and maintenance occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "2,328",
        "women": "33.2",
        "white": "17.5",
        "black": "3.4",
        "asian": "31.5"
      }
    },
    "wageData": {
      "2014": {
        "workers": "1,598",
        "median": "502",
        "maleWorkers": "1,160",
        "maleMedian": "540",
        "femaleWorkers": "438",
        "femaleMedian": "415"
      }
    }
  },
  "Maids and housekeeping cleaners": {
    "ageData": {
      "2014": {
        "total": "1,514",
        "16to19": "27",
        "20to24": "99",
        "25to34": "282",
        "35to44": "360",
        "45to54": "404",
        "55to64": "258",
        "65+": "85",
        "medianAge": "258"
      }
    },
    "title": "Maids and housekeeping cleaners",
    "depth": 3,
    "parent": "Building and grounds cleaning and maintenance occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "1,514",
        "women": "88.6",
        "white": "16.8",
        "black": "5.5",
        "asian": "43.8"
      }
    },
    "wageData": {
      "2014": {
        "workers": "816",
        "median": "401",
        "maleWorkers": "131",
        "maleMedian": "404",
        "femaleWorkers": "685",
        "femaleMedian": "400"
      }
    }
  },
  "Pest control workers": {
    "ageData": {
      "2014": {
        "total": "80",
        "16to19": "1",
        "20to24": "8",
        "25to34": "19",
        "35to44": "14",
        "45to54": "20",
        "55to64": "16",
        "65+": "2",
        "medianAge": "16"
      }
    },
    "title": "Pest control workers",
    "depth": 3,
    "parent": "Building and grounds cleaning and maintenance occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "80",
        "women": "3.7",
        "white": "10.9",
        "black": "0.5",
        "asian": "17.0"
      }
    },
    "wageData": {
      "2014": {
        "workers": "64",
        "median": "653",
        "maleWorkers": "62",
        "maleMedian": "653",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Grounds maintenance workers": {
    "ageData": {
      "2014": {
        "total": "1,389",
        "16to19": "73",
        "20to24": "173",
        "25to34": "321",
        "35to44": "293",
        "45to54": "266",
        "55to64": "193",
        "65+": "70",
        "medianAge": "193"
      }
    },
    "title": "Grounds maintenance workers",
    "depth": 3,
    "parent": "Building and grounds cleaning and maintenance occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "1,389",
        "women": "6.3",
        "white": "8.3",
        "black": "1.7",
        "asian": "43.6"
      }
    },
    "wageData": {
      "2014": {
        "workers": "847",
        "median": "459",
        "maleWorkers": "800",
        "maleMedian": "454",
        "femaleWorkers": "47",
        "femaleMedian": "-"
      }
    }
  },
  "Personal care and service occupations": {
    "ageData": {
      "2014": {
        "total": "5,337",
        "16to19": "267",
        "20to24": "781",
        "25to34": "1,127",
        "35to44": "989",
        "45to54": "1,022",
        "55to64": "796",
        "65+": "355",
        "medianAge": "796"
      }
    },
    "title": "Personal care and service occupations",
    "depth": 2,
    "parent": "Service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "5,337",
        "women": "77.4",
        "white": "15.0",
        "black": "8.4",
        "asian": "16.6"
      }
    },
    "wageData": {
      "2014": {
        "workers": "2,326",
        "median": "487",
        "maleWorkers": "632",
        "maleMedian": "607",
        "femaleWorkers": "1,695",
        "femaleMedian": "455"
      }
    }
  },
  "First-line supervisors of gaming workers": {
    "ageData": {
      "2014": {
        "total": "151",
        "16to19": "1",
        "20to24": "14",
        "25to34": "39",
        "35to44": "43",
        "45to54": "22",
        "55to64": "24",
        "65+": "8",
        "medianAge": "24"
      }
    },
    "title": "First-line supervisors of gaming workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "151",
        "women": "45.2",
        "white": "4.4",
        "black": "6.4",
        "asian": "7.3"
      }
    },
    "wageData": {
      "2014": {
        "workers": "93",
        "median": "789",
        "maleWorkers": "51",
        "maleMedian": "810",
        "femaleWorkers": "43",
        "femaleMedian": "-"
      }
    }
  },
  "First-line supervisors of personal service workers": {
    "ageData": {
      "2014": {
        "total": "202",
        "16to19": "0",
        "20to24": "10",
        "25to34": "40",
        "35to44": "49",
        "45to54": "59",
        "55to64": "31",
        "65+": "13",
        "medianAge": "31"
      }
    },
    "title": "First-line supervisors of personal service workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "202",
        "women": "71.3",
        "white": "11.5",
        "black": "18.2",
        "asian": "13.7"
      }
    },
    "wageData": {
      "2014": {
        "workers": "73",
        "median": "567",
        "maleWorkers": "27",
        "maleMedian": "-",
        "femaleWorkers": "46",
        "femaleMedian": "-"
      }
    }
  },
  "Animal trainers": {
    "ageData": {
      "2014": {
        "total": "41",
        "16to19": "0",
        "20to24": "7",
        "25to34": "4",
        "35to44": "6",
        "45to54": "9",
        "55to64": "12",
        "65+": "2",
        "medianAge": "12"
      }
    },
    "title": "Animal trainers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "41",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "10",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Nonfarm animal caretakers": {
    "ageData": {
      "2014": {
        "total": "201",
        "16to19": "17",
        "20to24": "47",
        "25to34": "50",
        "35to44": "24",
        "45to54": "36",
        "55to64": "21",
        "65+": "7",
        "medianAge": "21"
      }
    },
    "title": "Nonfarm animal caretakers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "201",
        "women": "69.9",
        "white": "2.8",
        "black": "0.9",
        "asian": "12.2"
      }
    },
    "wageData": {
      "2014": {
        "workers": "89",
        "median": "489",
        "maleWorkers": "29",
        "maleMedian": "-",
        "femaleWorkers": "60",
        "femaleMedian": "452"
      }
    }
  },
  "Gaming services workers": {
    "ageData": {
      "2014": {
        "total": "99",
        "16to19": "3",
        "20to24": "10",
        "25to34": "22",
        "35to44": "25",
        "45to54": "18",
        "55to64": "16",
        "65+": "5",
        "medianAge": "16"
      }
    },
    "title": "Gaming services workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "99",
        "women": "41.8",
        "white": "8.0",
        "black": "19.4",
        "asian": "12.5"
      }
    },
    "wageData": {
      "2014": {
        "workers": "78",
        "median": "627",
        "maleWorkers": "47",
        "maleMedian": "-",
        "femaleWorkers": "31",
        "femaleMedian": "-"
      }
    }
  },
  "Motion picture projectionists": {
    "ageData": {
      "2014": {
        "total": "4",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "1",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Motion picture projectionists",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Ushers, lobby attendants, and ticket takers": {
    "ageData": {
      "2014": {
        "total": "54",
        "16to19": "16",
        "20to24": "18",
        "25to34": "5",
        "35to44": "0",
        "45to54": "3",
        "55to64": "5",
        "65+": "7",
        "medianAge": "5"
      }
    },
    "title": "Ushers, lobby attendants, and ticket takers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "54",
        "women": "48.9",
        "white": "24.3",
        "black": "3.6",
        "asian": "13.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous entertainment attendants and related workers": {
    "ageData": {
      "2014": {
        "total": "189",
        "16to19": "38",
        "20to24": "53",
        "25to34": "35",
        "35to44": "14",
        "45to54": "16",
        "55to64": "14",
        "65+": "19",
        "medianAge": "14"
      }
    },
    "title": "Miscellaneous entertainment attendants and related workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "189",
        "women": "41.5",
        "white": "13.3",
        "black": "3.3",
        "asian": "17.3"
      }
    },
    "wageData": {
      "2014": {
        "workers": "69",
        "median": "473",
        "maleWorkers": "41",
        "maleMedian": "-",
        "femaleWorkers": "28",
        "femaleMedian": "-"
      }
    }
  },
  "Embalmers and funeral attendants": {
    "ageData": {
      "2014": {
        "total": "13",
        "16to19": "0",
        "20to24": "1",
        "25to34": "2",
        "35to44": "1",
        "45to54": "1",
        "55to64": "4",
        "65+": "3",
        "medianAge": "4"
      }
    },
    "title": "Embalmers and funeral attendants",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "13",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Morticians, undertakers, and funeral directors": {
    "ageData": {
      "2014": {
        "total": "29",
        "16to19": "0",
        "20to24": "1",
        "25to34": "8",
        "35to44": "3",
        "45to54": "6",
        "55to64": "4",
        "65+": "6",
        "medianAge": "4"
      }
    },
    "title": "Morticians, undertakers, and funeral directors",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "29",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "17",
        "median": "-",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Barbers": {
    "ageData": {
      "2014": {
        "total": "110",
        "16to19": "0",
        "20to24": "6",
        "25to34": "29",
        "35to44": "32",
        "45to54": "19",
        "55to64": "12",
        "65+": "11",
        "medianAge": "12"
      }
    },
    "title": "Barbers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "110",
        "women": "16.8",
        "white": "36.3",
        "black": "5.4",
        "asian": "23.7"
      }
    },
    "wageData": {
      "2014": {
        "workers": "42",
        "median": "-",
        "maleWorkers": "39",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Hairdressers, hairstylists, and cosmetologists": {
    "ageData": {
      "2014": {
        "total": "760",
        "16to19": "8",
        "20to24": "93",
        "25to34": "193",
        "35to44": "154",
        "45to54": "154",
        "55to64": "102",
        "65+": "55",
        "medianAge": "102"
      }
    },
    "title": "Hairdressers, hairstylists, and cosmetologists",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "760",
        "women": "94.6",
        "white": "12.4",
        "black": "4.8",
        "asian": "15.3"
      }
    },
    "wageData": {
      "2014": {
        "workers": "284",
        "median": "482",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "271",
        "femaleMedian": "476"
      }
    }
  },
  "Miscellaneous personal appearance workers": {
    "ageData": {
      "2014": {
        "total": "296",
        "16to19": "3",
        "20to24": "28",
        "25to34": "81",
        "35to44": "86",
        "45to54": "63",
        "55to64": "27",
        "65+": "7",
        "medianAge": "27"
      }
    },
    "title": "Miscellaneous personal appearance workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "296",
        "women": "88.9",
        "white": "5.9",
        "black": "55.3",
        "asian": "10.3"
      }
    },
    "wageData": {
      "2014": {
        "workers": "166",
        "median": "432",
        "maleWorkers": "22",
        "maleMedian": "-",
        "femaleWorkers": "144",
        "femaleMedian": "422"
      }
    }
  },
  "Baggage porters, bellhops, and concierges": {
    "ageData": {
      "2014": {
        "total": "85",
        "16to19": "2",
        "20to24": "11",
        "25to34": "20",
        "35to44": "12",
        "45to54": "22",
        "55to64": "12",
        "65+": "6",
        "medianAge": "12"
      }
    },
    "title": "Baggage porters, bellhops, and concierges",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "85",
        "women": "20.2",
        "white": "17.2",
        "black": "6.0",
        "asian": "24.8"
      }
    },
    "wageData": {
      "2014": {
        "workers": "66",
        "median": "633",
        "maleWorkers": "58",
        "maleMedian": "664",
        "femaleWorkers": "9",
        "femaleMedian": "-"
      }
    }
  },
  "Tour and travel guides": {
    "ageData": {
      "2014": {
        "total": "54",
        "16to19": "6",
        "20to24": "15",
        "25to34": "7",
        "35to44": "9",
        "45to54": "5",
        "55to64": "7",
        "65+": "7",
        "medianAge": "7"
      }
    },
    "title": "Tour and travel guides",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "54",
        "women": "46.9",
        "white": "5.9",
        "black": "5.8",
        "asian": "11.3"
      }
    },
    "wageData": {
      "2014": {
        "workers": "19",
        "median": "-",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Childcare workers": {
    "ageData": {
      "2014": {
        "total": "1,218",
        "16to19": "107",
        "20to24": "216",
        "25to34": "251",
        "35to44": "183",
        "45to54": "209",
        "55to64": "195",
        "65+": "56",
        "medianAge": "195"
      }
    },
    "title": "Childcare workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "1,218",
        "women": "95.5",
        "white": "15.9",
        "black": "3.5",
        "asian": "21.6"
      }
    },
    "wageData": {
      "2014": {
        "workers": "406",
        "median": "442",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "385",
        "femaleMedian": "444"
      }
    }
  },
  "Personal care aides": {
    "ageData": {
      "2014": {
        "total": "1,254",
        "16to19": "24",
        "20to24": "148",
        "25to34": "221",
        "35to44": "220",
        "45to54": "290",
        "55to64": "238",
        "65+": "114",
        "medianAge": "238"
      }
    },
    "title": "Personal care aides",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "1,254",
        "women": "83.9",
        "white": "23.0",
        "black": "7.9",
        "asian": "18.2"
      }
    },
    "wageData": {
      "2014": {
        "workers": "667",
        "median": "434",
        "maleWorkers": "133",
        "maleMedian": "465",
        "femaleWorkers": "534",
        "femaleMedian": "425"
      }
    }
  },
  "Recreation and fitness workers": {
    "ageData": {
      "2014": {
        "total": "404",
        "16to19": "29",
        "20to24": "71",
        "25to34": "79",
        "35to44": "91",
        "45to54": "71",
        "55to64": "51",
        "65+": "12",
        "medianAge": "51"
      }
    },
    "title": "Recreation and fitness workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "404",
        "women": "63.0",
        "white": "9.2",
        "black": "2.9",
        "asian": "11.6"
      }
    },
    "wageData": {
      "2014": {
        "workers": "167",
        "median": "599",
        "maleWorkers": "76",
        "maleMedian": "716",
        "femaleWorkers": "91",
        "femaleMedian": "521"
      }
    }
  },
  "Residential advisors": {
    "ageData": {
      "2014": {
        "total": "43",
        "16to19": "4",
        "20to24": "13",
        "25to34": "9",
        "35to44": "6",
        "45to54": "3",
        "55to64": "5",
        "65+": "3",
        "medianAge": "5"
      }
    },
    "title": "Residential advisors",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "43",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "22",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "14",
        "femaleMedian": "-"
      }
    }
  },
  "Personal care and service workers, all other": {
    "ageData": {
      "2014": {
        "total": "131",
        "16to19": "7",
        "20to24": "21",
        "25to34": "28",
        "35to44": "31",
        "45to54": "16",
        "55to64": "16",
        "65+": "13",
        "medianAge": "16"
      }
    },
    "title": "Personal care and service workers, all other",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "131",
        "women": "42.6",
        "white": "8.2",
        "black": "3.1",
        "asian": "15.3"
      }
    },
    "wageData": {
      "2014": {
        "workers": "42",
        "median": "-",
        "maleWorkers": "29",
        "maleMedian": "-",
        "femaleWorkers": "13",
        "femaleMedian": "-"
      }
    }
  },
  "Sales and office occupations": {
    "ageData": {
      "2014": {
        "total": "33,416",
        "16to19": "1,572",
        "20to24": "4,066",
        "25to34": "6,897",
        "35to44": "6,092",
        "45to54": "7,115",
        "55to64": "5,650",
        "65+": "2,023",
        "medianAge": "5,650"
      }
    },
    "title": "Sales and office occupations",
    "depth": 1,
    "parent": "",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "33,416",
        "women": "61.8",
        "white": "12.0",
        "black": "4.8",
        "asian": "14.9"
      }
    },
    "wageData": {
      "2014": {
        "workers": "23,402",
        "median": "666",
        "maleWorkers": "9,251",
        "maleMedian": "766",
        "femaleWorkers": "14,151",
        "femaleMedian": "620"
      }
    }
  },
  "Sales and related occupations": {
    "ageData": {
      "2014": {
        "total": "15,646",
        "16to19": "1,056",
        "20to24": "2,171",
        "25to34": "3,220",
        "35to44": "2,698",
        "45to54": "3,102",
        "55to64": "2,364",
        "65+": "1,035",
        "medianAge": "2,364"
      }
    },
    "title": "Sales and related occupations",
    "depth": 2,
    "parent": "Sales and office occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "15,646",
        "women": "49.2",
        "white": "10.7",
        "black": "5.3",
        "asian": "14.7"
      }
    },
    "wageData": {
      "2014": {
        "workers": "9,626",
        "median": "705",
        "maleWorkers": "5,411",
        "maleMedian": "841",
        "femaleWorkers": "4,215",
        "femaleMedian": "570"
      }
    }
  },
  "First-line supervisors of retail sales workers": {
    "ageData": {
      "2014": {
        "total": "3,285",
        "16to19": "26",
        "20to24": "288",
        "25to34": "766",
        "35to44": "708",
        "45to54": "780",
        "55to64": "525",
        "65+": "192",
        "medianAge": "525"
      }
    },
    "title": "First-line supervisors of retail sales workers",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "3,285",
        "women": "44.3",
        "white": "8.8",
        "black": "6.1",
        "asian": "12.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "2,317",
        "median": "696",
        "maleWorkers": "1,295",
        "maleMedian": "793",
        "femaleWorkers": "1,022",
        "femaleMedian": "595"
      }
    }
  },
  "First-line supervisors of non-retail sales workers": {
    "ageData": {
      "2014": {
        "total": "1,200",
        "16to19": "2",
        "20to24": "39",
        "25to34": "191",
        "35to44": "298",
        "45to54": "332",
        "55to64": "243",
        "65+": "95",
        "medianAge": "243"
      }
    },
    "title": "First-line supervisors of non-retail sales workers",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "1,200",
        "women": "26.0",
        "white": "5.6",
        "black": "4.8",
        "asian": "11.2"
      }
    },
    "wageData": {
      "2014": {
        "workers": "768",
        "median": "969",
        "maleWorkers": "530",
        "maleMedian": "1,004",
        "femaleWorkers": "238",
        "femaleMedian": "878"
      }
    }
  },
  "Cashiers": {
    "ageData": {
      "2014": {
        "total": "3,242",
        "16to19": "681",
        "20to24": "855",
        "25to34": "566",
        "35to44": "334",
        "45to54": "374",
        "55to64": "304",
        "65+": "129",
        "medianAge": "304"
      }
    },
    "title": "Cashiers",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "3,242",
        "women": "72.2",
        "white": "18.3",
        "black": "6.9",
        "asian": "22.0"
      }
    },
    "wageData": {
      "2014": {
        "workers": "1,305",
        "median": "394",
        "maleWorkers": "384",
        "maleMedian": "412",
        "femaleWorkers": "921",
        "femaleMedian": "387"
      }
    }
  },
  "Counter and rental clerks": {
    "ageData": {
      "2014": {
        "total": "113",
        "16to19": "8",
        "20to24": "23",
        "25to34": "29",
        "35to44": "20",
        "45to54": "16",
        "55to64": "15",
        "65+": "3",
        "medianAge": "15"
      }
    },
    "title": "Counter and rental clerks",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "113",
        "women": "49.8",
        "white": "11.8",
        "black": "4.5",
        "asian": "22.5"
      }
    },
    "wageData": {
      "2014": {
        "workers": "75",
        "median": "547",
        "maleWorkers": "45",
        "maleMedian": "-",
        "femaleWorkers": "31",
        "femaleMedian": "-"
      }
    }
  },
  "Parts salespersons": {
    "ageData": {
      "2014": {
        "total": "93",
        "16to19": "3",
        "20to24": "9",
        "25to34": "28",
        "35to44": "9",
        "45to54": "18",
        "55to64": "21",
        "65+": "5",
        "medianAge": "21"
      }
    },
    "title": "Parts salespersons",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "93",
        "women": "15.3",
        "white": "4.8",
        "black": "0.5",
        "asian": "12.2"
      }
    },
    "wageData": {
      "2014": {
        "workers": "88",
        "median": "617",
        "maleWorkers": "74",
        "maleMedian": "643",
        "femaleWorkers": "14",
        "femaleMedian": "-"
      }
    }
  },
  "Retail salespersons": {
    "ageData": {
      "2014": {
        "total": "3,316",
        "16to19": "293",
        "20to24": "668",
        "25to34": "713",
        "35to44": "421",
        "45to54": "526",
        "55to64": "476",
        "65+": "217",
        "medianAge": "476"
      }
    },
    "title": "Retail salespersons",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "3,316",
        "women": "49.8",
        "white": "12.3",
        "black": "4.6",
        "asian": "16.2"
      }
    },
    "wageData": {
      "2014": {
        "workers": "1,870",
        "median": "596",
        "maleWorkers": "1,137",
        "maleMedian": "698",
        "femaleWorkers": "733",
        "femaleMedian": "491"
      }
    }
  },
  "Advertising sales agents": {
    "ageData": {
      "2014": {
        "total": "227",
        "16to19": "2",
        "20to24": "24",
        "25to34": "71",
        "35to44": "42",
        "45to54": "47",
        "55to64": "32",
        "65+": "9",
        "medianAge": "32"
      }
    },
    "title": "Advertising sales agents",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "227",
        "women": "48.7",
        "white": "7.9",
        "black": "4.5",
        "asian": "12.7"
      }
    },
    "wageData": {
      "2014": {
        "workers": "194",
        "median": "942",
        "maleWorkers": "100",
        "maleMedian": "1,041",
        "femaleWorkers": "94",
        "femaleMedian": "871"
      }
    }
  },
  "Insurance sales agents": {
    "ageData": {
      "2014": {
        "total": "562",
        "16to19": "0",
        "20to24": "28",
        "25to34": "117",
        "35to44": "113",
        "45to54": "132",
        "55to64": "122",
        "65+": "50",
        "medianAge": "122"
      }
    },
    "title": "Insurance sales agents",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "562",
        "women": "47.0",
        "white": "8.3",
        "black": "4.9",
        "asian": "13.2"
      }
    },
    "wageData": {
      "2014": {
        "workers": "433",
        "median": "765",
        "maleWorkers": "204",
        "maleMedian": "946",
        "femaleWorkers": "230",
        "femaleMedian": "698"
      }
    }
  },
  "Securities, commodities, and financial services sales agents": {
    "ageData": {
      "2014": {
        "total": "256",
        "16to19": "0",
        "20to24": "11",
        "25to34": "81",
        "35to44": "64",
        "45to54": "61",
        "55to64": "27",
        "65+": "12",
        "medianAge": "27"
      }
    },
    "title": "Securities, commodities, and financial services sales agents",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "256",
        "women": "32.5",
        "white": "4.9",
        "black": "4.6",
        "asian": "9.5"
      }
    },
    "wageData": {
      "2014": {
        "workers": "235",
        "median": "1,122",
        "maleWorkers": "152",
        "maleMedian": "1,356",
        "femaleWorkers": "83",
        "femaleMedian": "883"
      }
    }
  },
  "Travel agents": {
    "ageData": {
      "2014": {
        "total": "82",
        "16to19": "0",
        "20to24": "3",
        "25to34": "10",
        "35to44": "15",
        "45to54": "25",
        "55to64": "17",
        "65+": "11",
        "medianAge": "17"
      }
    },
    "title": "Travel agents",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "82",
        "women": "81.4",
        "white": "7.9",
        "black": "13.2",
        "asian": "9.9"
      }
    },
    "wageData": {
      "2014": {
        "workers": "46",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "36",
        "femaleMedian": "-"
      }
    }
  },
  "Sales representatives, services, all other": {
    "ageData": {
      "2014": {
        "total": "480",
        "16to19": "3",
        "20to24": "39",
        "25to34": "132",
        "35to44": "105",
        "45to54": "104",
        "55to64": "74",
        "65+": "22",
        "medianAge": "74"
      }
    },
    "title": "Sales representatives, services, all other",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "480",
        "women": "29.5",
        "white": "6.6",
        "black": "4.3",
        "asian": "11.0"
      }
    },
    "wageData": {
      "2014": {
        "workers": "413",
        "median": "948",
        "maleWorkers": "296",
        "maleMedian": "986",
        "femaleWorkers": "117",
        "femaleMedian": "766"
      }
    }
  },
  "Sales representatives, wholesale and manufacturing": {
    "ageData": {
      "2014": {
        "total": "1,309",
        "16to19": "12",
        "20to24": "83",
        "25to34": "276",
        "35to44": "274",
        "45to54": "343",
        "55to64": "232",
        "65+": "89",
        "medianAge": "232"
      }
    },
    "title": "Sales representatives, wholesale and manufacturing",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "1,309",
        "women": "29.3",
        "white": "5.3",
        "black": "3.8",
        "asian": "9.3"
      }
    },
    "wageData": {
      "2014": {
        "workers": "1,133",
        "median": "1,015",
        "maleWorkers": "817",
        "maleMedian": "1,120",
        "femaleWorkers": "317",
        "femaleMedian": "873"
      }
    }
  },
  "Models, demonstrators, and product promoters": {
    "ageData": {
      "2014": {
        "total": "66",
        "16to19": "6",
        "20to24": "9",
        "25to34": "10",
        "35to44": "9",
        "45to54": "10",
        "55to64": "8",
        "65+": "14",
        "medianAge": "8"
      }
    },
    "title": "Models, demonstrators, and product promoters",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "66",
        "women": "68.4",
        "white": "5.5",
        "black": "4.0",
        "asian": "15.9"
      }
    },
    "wageData": {
      "2014": {
        "workers": "16",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Real estate brokers and sales agents": {
    "ageData": {
      "2014": {
        "total": "868",
        "16to19": "3",
        "20to24": "33",
        "25to34": "109",
        "35to44": "174",
        "45to54": "223",
        "55to64": "190",
        "65+": "138",
        "medianAge": "190"
      }
    },
    "title": "Real estate brokers and sales agents",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "868",
        "women": "54.9",
        "white": "5.4",
        "black": "4.2",
        "asian": "10.5"
      }
    },
    "wageData": {
      "2014": {
        "workers": "416",
        "median": "852",
        "maleWorkers": "189",
        "maleMedian": "991",
        "femaleWorkers": "227",
        "femaleMedian": "726"
      }
    }
  },
  "Sales engineers": {
    "ageData": {
      "2014": {
        "total": "43",
        "16to19": "0",
        "20to24": "2",
        "25to34": "7",
        "35to44": "11",
        "45to54": "12",
        "55to64": "9",
        "65+": "2",
        "medianAge": "9"
      }
    },
    "title": "Sales engineers",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "43",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "41",
        "median": "-",
        "maleWorkers": "39",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Telemarketers": {
    "ageData": {
      "2014": {
        "total": "75",
        "16to19": "4",
        "20to24": "16",
        "25to34": "19",
        "35to44": "15",
        "45to54": "10",
        "55to64": "6",
        "65+": "4",
        "medianAge": "6"
      }
    },
    "title": "Telemarketers",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "75",
        "women": "67.4",
        "white": "19.6",
        "black": "3.6",
        "asian": "18.5"
      }
    },
    "wageData": {
      "2014": {
        "workers": "55",
        "median": "526",
        "maleWorkers": "18",
        "maleMedian": "-",
        "femaleWorkers": "37",
        "femaleMedian": "-"
      }
    }
  },
  "Door-to-door sales workers, news and street vendors, and related workers": {
    "ageData": {
      "2014": {
        "total": "175",
        "16to19": "6",
        "20to24": "14",
        "25to34": "43",
        "35to44": "36",
        "45to54": "39",
        "55to64": "20",
        "65+": "18",
        "medianAge": "20"
      }
    },
    "title": "Door-to-door sales workers, news and street vendors, and related workers",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "175",
        "women": "64.0",
        "white": "10.6",
        "black": "3.5",
        "asian": "21.7"
      }
    },
    "wageData": {
      "2014": {
        "workers": "38",
        "median": "-",
        "maleWorkers": "23",
        "maleMedian": "-",
        "femaleWorkers": "16",
        "femaleMedian": "-"
      }
    }
  },
  "Sales and related workers, all other": {
    "ageData": {
      "2014": {
        "total": "253",
        "16to19": "8",
        "20to24": "28",
        "25to34": "52",
        "35to44": "48",
        "45to54": "50",
        "55to64": "42",
        "65+": "26",
        "medianAge": "42"
      }
    },
    "title": "Sales and related workers, all other",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "253",
        "women": "52.0",
        "white": "10.6",
        "black": "6.2",
        "asian": "5.7"
      }
    },
    "wageData": {
      "2014": {
        "workers": "183",
        "median": "807",
        "maleWorkers": "92",
        "maleMedian": "949",
        "femaleWorkers": "91",
        "femaleMedian": "664"
      }
    }
  },
  "Office and administrative support occupations": {
    "ageData": {
      "2014": {
        "total": "17,771",
        "16to19": "517",
        "20to24": "1,895",
        "25to34": "3,677",
        "35to44": "3,394",
        "45to54": "4,013",
        "55to64": "3,286",
        "65+": "989",
        "medianAge": "3,286"
      }
    },
    "title": "Office and administrative support occupations",
    "depth": 2,
    "parent": "Sales and office occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "17,771",
        "women": "72.9",
        "white": "13.1",
        "black": "4.4",
        "asian": "15.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "13,776",
        "median": "651",
        "maleWorkers": "3,840",
        "maleMedian": "701",
        "femaleWorkers": "9,936",
        "femaleMedian": "637"
      }
    }
  },
  "First-line supervisors of office and administrative support workers": {
    "ageData": {
      "2014": {
        "total": "1,351",
        "16to19": "3",
        "20to24": "46",
        "25to34": "255",
        "35to44": "347",
        "45to54": "362",
        "55to64": "272",
        "65+": "64",
        "medianAge": "272"
      }
    },
    "title": "First-line supervisors of office and administrative support workers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "1,351",
        "women": "67.4",
        "white": "11.1",
        "black": "3.6",
        "asian": "12.2"
      }
    },
    "wageData": {
      "2014": {
        "workers": "1,185",
        "median": "808",
        "maleWorkers": "410",
        "maleMedian": "919",
        "femaleWorkers": "775",
        "femaleMedian": "763"
      }
    }
  },
  "Switchboard operators, including answering service": {
    "ageData": {
      "2014": {
        "total": "19",
        "16to19": "0",
        "20to24": "4",
        "25to34": "5",
        "35to44": "3",
        "45to54": "1",
        "55to64": "5",
        "65+": "2",
        "medianAge": "5"
      }
    },
    "title": "Switchboard operators, including answering service",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "19",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "18",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "15",
        "femaleMedian": "-"
      }
    }
  },
  "Telephone operators": {
    "ageData": {
      "2014": {
        "total": "33",
        "16to19": "0",
        "20to24": "8",
        "25to34": "4",
        "35to44": "8",
        "45to54": "8",
        "55to64": "3",
        "65+": "2",
        "medianAge": "3"
      }
    },
    "title": "Telephone operators",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "33",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "27",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "17",
        "femaleMedian": "-"
      }
    }
  },
  "Communications equipment operators, all other": {
    "ageData": {
      "2014": {
        "total": "9",
        "16to19": "0",
        "20to24": "1",
        "25to34": "3",
        "35to44": "2",
        "45to54": "0",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Communications equipment operators, all other",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "9",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "9",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Bill and account collectors": {
    "ageData": {
      "2014": {
        "total": "165",
        "16to19": "2",
        "20to24": "23",
        "25to34": "39",
        "35to44": "42",
        "45to54": "29",
        "55to64": "27",
        "65+": "4",
        "medianAge": "27"
      }
    },
    "title": "Bill and account collectors",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "165",
        "women": "66.6",
        "white": "20.3",
        "black": "1.5",
        "asian": "13.9"
      }
    },
    "wageData": {
      "2014": {
        "workers": "146",
        "median": "619",
        "maleWorkers": "44",
        "maleMedian": "-",
        "femaleWorkers": "102",
        "femaleMedian": "592"
      }
    }
  },
  "Billing and posting clerks": {
    "ageData": {
      "2014": {
        "total": "507",
        "16to19": "3",
        "20to24": "29",
        "25to34": "104",
        "35to44": "117",
        "45to54": "139",
        "55to64": "91",
        "65+": "24",
        "medianAge": "91"
      }
    },
    "title": "Billing and posting clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "507",
        "women": "91.2",
        "white": "13.3",
        "black": "5.2",
        "asian": "12.2"
      }
    },
    "wageData": {
      "2014": {
        "workers": "412",
        "median": "642",
        "maleWorkers": "32",
        "maleMedian": "-",
        "femaleWorkers": "380",
        "femaleMedian": "640"
      }
    }
  },
  "Bookkeeping, accounting, and auditing clerks": {
    "ageData": {
      "2014": {
        "total": "1,231",
        "16to19": "8",
        "20to24": "54",
        "25to34": "172",
        "35to44": "240",
        "45to54": "333",
        "55to64": "291",
        "65+": "135",
        "medianAge": "291"
      }
    },
    "title": "Bookkeeping, accounting, and auditing clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "1,231",
        "women": "90.2",
        "white": "8.0",
        "black": "4.8",
        "asian": "10.9"
      }
    },
    "wageData": {
      "2014": {
        "workers": "830",
        "median": "665",
        "maleWorkers": "85",
        "maleMedian": "732",
        "femaleWorkers": "745",
        "femaleMedian": "660"
      }
    }
  },
  "Gaming cage workers": {
    "ageData": {
      "2014": {
        "total": "14",
        "16to19": "0",
        "20to24": "1",
        "25to34": "4",
        "35to44": "5",
        "45to54": "1",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Gaming cage workers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "14",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "14",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Payroll and timekeeping clerks": {
    "ageData": {
      "2014": {
        "total": "146",
        "16to19": "0",
        "20to24": "7",
        "25to34": "28",
        "35to44": "30",
        "45to54": "42",
        "55to64": "33",
        "65+": "5",
        "medianAge": "33"
      }
    },
    "title": "Payroll and timekeeping clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "146",
        "women": "89.4",
        "white": "15.1",
        "black": "3.9",
        "asian": "12.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "124",
        "median": "757",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "113",
        "femaleMedian": "762"
      }
    }
  },
  "Procurement clerks": {
    "ageData": {
      "2014": {
        "total": "31",
        "16to19": "0",
        "20to24": "3",
        "25to34": "5",
        "35to44": "6",
        "45to54": "8",
        "55to64": "7",
        "65+": "0",
        "medianAge": "7"
      }
    },
    "title": "Procurement clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "31",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "30",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "19",
        "femaleMedian": "-"
      }
    }
  },
  "Tellers": {
    "ageData": {
      "2014": {
        "total": "361",
        "16to19": "10",
        "20to24": "96",
        "25to34": "105",
        "35to44": "53",
        "45to54": "47",
        "55to64": "37",
        "65+": "14",
        "medianAge": "37"
      }
    },
    "title": "Tellers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "361",
        "women": "81.6",
        "white": "10.5",
        "black": "7.1",
        "asian": "16.8"
      }
    },
    "wageData": {
      "2014": {
        "workers": "284",
        "median": "510",
        "maleWorkers": "55",
        "maleMedian": "544",
        "femaleWorkers": "229",
        "femaleMedian": "507"
      }
    }
  },
  "Financial clerks, all other": {
    "ageData": {
      "2014": {
        "total": "65",
        "16to19": "0",
        "20to24": "5",
        "25to34": "16",
        "35to44": "13",
        "45to54": "20",
        "55to64": "9",
        "65+": "3",
        "medianAge": "9"
      }
    },
    "title": "Financial clerks, all other",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "65",
        "women": "66.6",
        "white": "11.8",
        "black": "6.4",
        "asian": "7.8"
      }
    },
    "wageData": {
      "2014": {
        "workers": "63",
        "median": "752",
        "maleWorkers": "23",
        "maleMedian": "-",
        "femaleWorkers": "40",
        "femaleMedian": "-"
      }
    }
  },
  "Brokerage clerks": {
    "ageData": {
      "2014": {
        "total": "5",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "1",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Brokerage clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Correspondence clerks": {
    "ageData": {
      "2014": {
        "total": "6",
        "16to19": "1",
        "20to24": "1",
        "25to34": "2",
        "35to44": "0",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Correspondence clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "6",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Court, municipal, and license clerks": {
    "ageData": {
      "2014": {
        "total": "69",
        "16to19": "1",
        "20to24": "2",
        "25to34": "9",
        "35to44": "14",
        "45to54": "23",
        "55to64": "13",
        "65+": "7",
        "medianAge": "13"
      }
    },
    "title": "Court, municipal, and license clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "69",
        "women": "77.4",
        "white": "12.7",
        "black": "0.8",
        "asian": "14.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "58",
        "median": "738",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "48",
        "femaleMedian": "-"
      }
    }
  },
  "Credit authorizers, checkers, and clerks": {
    "ageData": {
      "2014": {
        "total": "44",
        "16to19": "0",
        "20to24": "5",
        "25to34": "17",
        "35to44": "5",
        "45to54": "11",
        "55to64": "3",
        "65+": "2",
        "medianAge": "3"
      }
    },
    "title": "Credit authorizers, checkers, and clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "44",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "36",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "24",
        "femaleMedian": "-"
      }
    }
  },
  "Customer service representatives": {
    "ageData": {
      "2014": {
        "total": "2,086",
        "16to19": "122",
        "20to24": "341",
        "25to34": "531",
        "35to44": "373",
        "45to54": "377",
        "55to64": "277",
        "65+": "66",
        "medianAge": "277"
      }
    },
    "title": "Customer service representatives",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "2,086",
        "women": "64.6",
        "white": "17.0",
        "black": "4.5",
        "asian": "16.5"
      }
    },
    "wageData": {
      "2014": {
        "workers": "1,550",
        "median": "627",
        "maleWorkers": "537",
        "maleMedian": "698",
        "femaleWorkers": "1,014",
        "femaleMedian": "606"
      }
    }
  },
  "Eligibility interviewers, government programs": {
    "ageData": {
      "2014": {
        "total": "70",
        "16to19": "1",
        "20to24": "0",
        "25to34": "14",
        "35to44": "22",
        "45to54": "16",
        "55to64": "15",
        "65+": "2",
        "medianAge": "15"
      }
    },
    "title": "Eligibility interviewers, government programs",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "70",
        "women": "82.1",
        "white": "26.3",
        "black": "3.2",
        "asian": "20.6"
      }
    },
    "wageData": {
      "2014": {
        "workers": "66",
        "median": "783",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "52",
        "femaleMedian": "766"
      }
    }
  },
  "File clerks": {
    "ageData": {
      "2014": {
        "total": "226",
        "16to19": "11",
        "20to24": "29",
        "25to34": "40",
        "35to44": "40",
        "45to54": "51",
        "55to64": "35",
        "65+": "20",
        "medianAge": "35"
      }
    },
    "title": "File clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "226",
        "women": "81.6",
        "white": "15.6",
        "black": "3.8",
        "asian": "18.3"
      }
    },
    "wageData": {
      "2014": {
        "workers": "160",
        "median": "610",
        "maleWorkers": "29",
        "maleMedian": "-",
        "femaleWorkers": "130",
        "femaleMedian": "613"
      }
    }
  },
  "Hotel, motel, and resort desk clerks": {
    "ageData": {
      "2014": {
        "total": "120",
        "16to19": "7",
        "20to24": "31",
        "25to34": "34",
        "35to44": "20",
        "45to54": "14",
        "55to64": "10",
        "65+": "4",
        "medianAge": "10"
      }
    },
    "title": "Hotel, motel, and resort desk clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "120",
        "women": "63.5",
        "white": "12.4",
        "black": "9.3",
        "asian": "25.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "82",
        "median": "426",
        "maleWorkers": "30",
        "maleMedian": "-",
        "femaleWorkers": "52",
        "femaleMedian": "417"
      }
    }
  },
  "Interviewers, except eligibility and loan": {
    "ageData": {
      "2014": {
        "total": "160",
        "16to19": "1",
        "20to24": "11",
        "25to34": "48",
        "35to44": "30",
        "45to54": "34",
        "55to64": "21",
        "65+": "13",
        "medianAge": "21"
      }
    },
    "title": "Interviewers, except eligibility and loan",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "160",
        "women": "80.5",
        "white": "23.9",
        "black": "4.8",
        "asian": "11.3"
      }
    },
    "wageData": {
      "2014": {
        "workers": "124",
        "median": "636",
        "maleWorkers": "27",
        "maleMedian": "-",
        "femaleWorkers": "97",
        "femaleMedian": "620"
      }
    }
  },
  "Library assistants, clerical": {
    "ageData": {
      "2014": {
        "total": "98",
        "16to19": "7",
        "20to24": "16",
        "25to34": "17",
        "35to44": "14",
        "45to54": "16",
        "55to64": "16",
        "65+": "12",
        "medianAge": "16"
      }
    },
    "title": "Library assistants, clerical",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "98",
        "women": "84.0",
        "white": "7.3",
        "black": "3.2",
        "asian": "10.2"
      }
    },
    "wageData": {
      "2014": {
        "workers": "41",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "33",
        "femaleMedian": "-"
      }
    }
  },
  "Loan interviewers and clerks": {
    "ageData": {
      "2014": {
        "total": "143",
        "16to19": "1",
        "20to24": "15",
        "25to34": "46",
        "35to44": "32",
        "45to54": "27",
        "55to64": "16",
        "65+": "6",
        "medianAge": "16"
      }
    },
    "title": "Loan interviewers and clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "143",
        "women": "78.1",
        "white": "11.4",
        "black": "3.9",
        "asian": "9.6"
      }
    },
    "wageData": {
      "2014": {
        "workers": "131",
        "median": "710",
        "maleWorkers": "31",
        "maleMedian": "-",
        "femaleWorkers": "99",
        "femaleMedian": "692"
      }
    }
  },
  "New accounts clerks": {
    "ageData": {
      "2014": {
        "total": "27",
        "16to19": "1",
        "20to24": "1",
        "25to34": "9",
        "35to44": "7",
        "45to54": "7",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "New accounts clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "27",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "25",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "21",
        "femaleMedian": "-"
      }
    }
  },
  "Order clerks": {
    "ageData": {
      "2014": {
        "total": "104",
        "16to19": "3",
        "20to24": "17",
        "25to34": "35",
        "35to44": "15",
        "45to54": "17",
        "55to64": "14",
        "65+": "4",
        "medianAge": "14"
      }
    },
    "title": "Order clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "104",
        "women": "59.9",
        "white": "9.5",
        "black": "5.8",
        "asian": "19.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "79",
        "median": "600",
        "maleWorkers": "29",
        "maleMedian": "-",
        "femaleWorkers": "51",
        "femaleMedian": "574"
      }
    }
  },
  "Human resources assistants, except payroll and timekeeping": {
    "ageData": {
      "2014": {
        "total": "101",
        "16to19": "0",
        "20to24": "7",
        "25to34": "25",
        "35to44": "21",
        "45to54": "28",
        "55to64": "15",
        "65+": "4",
        "medianAge": "15"
      }
    },
    "title": "Human resources assistants, except payroll and timekeeping",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "101",
        "women": "87.6",
        "white": "17.1",
        "black": "1.9",
        "asian": "15.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "91",
        "median": "754",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "80",
        "femaleMedian": "711"
      }
    }
  },
  "Receptionists and information clerks": {
    "ageData": {
      "2014": {
        "total": "1,301",
        "16to19": "64",
        "20to24": "232",
        "25to34": "327",
        "35to44": "195",
        "45to54": "212",
        "55to64": "200",
        "65+": "72",
        "medianAge": "200"
      }
    },
    "title": "Receptionists and information clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "1,301",
        "women": "91.3",
        "white": "10.5",
        "black": "4.1",
        "asian": "18.7"
      }
    },
    "wageData": {
      "2014": {
        "workers": "910",
        "median": "540",
        "maleWorkers": "85",
        "maleMedian": "616",
        "femaleWorkers": "825",
        "femaleMedian": "532"
      }
    }
  },
  "Reservation and transportation ticket agents and travel clerks": {
    "ageData": {
      "2014": {
        "total": "94",
        "16to19": "1",
        "20to24": "13",
        "25to34": "20",
        "35to44": "14",
        "45to54": "22",
        "55to64": "21",
        "65+": "3",
        "medianAge": "21"
      }
    },
    "title": "Reservation and transportation ticket agents and travel clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "94",
        "women": "57.4",
        "white": "16.0",
        "black": "9.9",
        "asian": "18.5"
      }
    },
    "wageData": {
      "2014": {
        "workers": "85",
        "median": "687",
        "maleWorkers": "37",
        "maleMedian": "-",
        "femaleWorkers": "48",
        "femaleMedian": "-"
      }
    }
  },
  "Information and record clerks, all other": {
    "ageData": {
      "2014": {
        "total": "95",
        "16to19": "1",
        "20to24": "4",
        "25to34": "27",
        "35to44": "16",
        "45to54": "21",
        "55to64": "17",
        "65+": "9",
        "medianAge": "17"
      }
    },
    "title": "Information and record clerks, all other",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "95",
        "women": "81.6",
        "white": "9.7",
        "black": "6.5",
        "asian": "15.0"
      }
    },
    "wageData": {
      "2014": {
        "workers": "75",
        "median": "660",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "59",
        "femaleMedian": "658"
      }
    }
  },
  "Cargo and freight agents": {
    "ageData": {
      "2014": {
        "total": "23",
        "16to19": "0",
        "20to24": "3",
        "25to34": "5",
        "35to44": "5",
        "45to54": "4",
        "55to64": "6",
        "65+": "0",
        "medianAge": "6"
      }
    },
    "title": "Cargo and freight agents",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "23",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "17",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Couriers and messengers": {
    "ageData": {
      "2014": {
        "total": "233",
        "16to19": "6",
        "20to24": "18",
        "25to34": "41",
        "35to44": "53",
        "45to54": "49",
        "55to64": "52",
        "65+": "14",
        "medianAge": "52"
      }
    },
    "title": "Couriers and messengers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "233",
        "women": "16.0",
        "white": "14.4",
        "black": "3.2",
        "asian": "20.4"
      }
    },
    "wageData": {
      "2014": {
        "workers": "167",
        "median": "727",
        "maleWorkers": "142",
        "maleMedian": "737",
        "femaleWorkers": "25",
        "femaleMedian": "-"
      }
    }
  },
  "Dispatchers": {
    "ageData": {
      "2014": {
        "total": "267",
        "16to19": "6",
        "20to24": "23",
        "25to34": "71",
        "35to44": "64",
        "45to54": "58",
        "55to64": "39",
        "65+": "6",
        "medianAge": "39"
      }
    },
    "title": "Dispatchers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "267",
        "women": "60.9",
        "white": "14.0",
        "black": "1.8",
        "asian": "14.9"
      }
    },
    "wageData": {
      "2014": {
        "workers": "234",
        "median": "710",
        "maleWorkers": "96",
        "maleMedian": "764",
        "femaleWorkers": "138",
        "femaleMedian": "669"
      }
    }
  },
  "Meter readers, utilities": {
    "ageData": {
      "2014": {
        "total": "23",
        "16to19": "3",
        "20to24": "0",
        "25to34": "3",
        "35to44": "6",
        "45to54": "5",
        "55to64": "5",
        "65+": "2",
        "medianAge": "5"
      }
    },
    "title": "Meter readers, utilities",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "23",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "25",
        "median": "-",
        "maleWorkers": "22",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Postal service clerks": {
    "ageData": {
      "2014": {
        "total": "117",
        "16to19": "1",
        "20to24": "8",
        "25to34": "9",
        "35to44": "16",
        "45to54": "34",
        "55to64": "46",
        "65+": "3",
        "medianAge": "46"
      }
    },
    "title": "Postal service clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "117",
        "women": "52.3",
        "white": "30.8",
        "black": "8.7",
        "asian": "7.9"
      }
    },
    "wageData": {
      "2014": {
        "workers": "98",
        "median": "978",
        "maleWorkers": "43",
        "maleMedian": "-",
        "femaleWorkers": "56",
        "femaleMedian": "935"
      }
    }
  },
  "Postal service mail carriers": {
    "ageData": {
      "2014": {
        "total": "311",
        "16to19": "1",
        "20to24": "10",
        "25to34": "35",
        "35to44": "55",
        "45to54": "109",
        "55to64": "85",
        "65+": "16",
        "medianAge": "85"
      }
    },
    "title": "Postal service mail carriers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "311",
        "women": "38.7",
        "white": "19.2",
        "black": "5.3",
        "asian": "12.9"
      }
    },
    "wageData": {
      "2014": {
        "workers": "274",
        "median": "966",
        "maleWorkers": "178",
        "maleMedian": "1,025",
        "femaleWorkers": "95",
        "femaleMedian": "851"
      }
    }
  },
  "Postal service mail sorters, processors, and processing machine operators": {
    "ageData": {
      "2014": {
        "total": "61",
        "16to19": "1",
        "20to24": "5",
        "25to34": "5",
        "35to44": "10",
        "45to54": "15",
        "55to64": "23",
        "65+": "1",
        "medianAge": "23"
      }
    },
    "title": "Postal service mail sorters, processors, and processing machine operators",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "61",
        "women": "59.1",
        "white": "27.5",
        "black": "5.7",
        "asian": "9.5"
      }
    },
    "wageData": {
      "2014": {
        "workers": "51",
        "median": "979",
        "maleWorkers": "22",
        "maleMedian": "-",
        "femaleWorkers": "29",
        "femaleMedian": "-"
      }
    }
  },
  "Production, planning, and expediting clerks": {
    "ageData": {
      "2014": {
        "total": "244",
        "16to19": "1",
        "20to24": "15",
        "25to34": "42",
        "35to44": "58",
        "45to54": "65",
        "55to64": "53",
        "65+": "10",
        "medianAge": "53"
      }
    },
    "title": "Production, planning, and expediting clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "244",
        "women": "54.3",
        "white": "11.4",
        "black": "4.5",
        "asian": "13.5"
      }
    },
    "wageData": {
      "2014": {
        "workers": "208",
        "median": "837",
        "maleWorkers": "96",
        "maleMedian": "1,024",
        "femaleWorkers": "111",
        "femaleMedian": "738"
      }
    }
  },
  "Shipping, receiving, and traffic clerks": {
    "ageData": {
      "2014": {
        "total": "606",
        "16to19": "13",
        "20to24": "69",
        "25to34": "151",
        "35to44": "114",
        "45to54": "130",
        "55to64": "102",
        "65+": "29",
        "medianAge": "102"
      }
    },
    "title": "Shipping, receiving, and traffic clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "606",
        "women": "32.3",
        "white": "13.3",
        "black": "3.2",
        "asian": "23.2"
      }
    },
    "wageData": {
      "2014": {
        "workers": "545",
        "median": "592",
        "maleWorkers": "376",
        "maleMedian": "603",
        "femaleWorkers": "169",
        "femaleMedian": "576"
      }
    }
  },
  "Stock clerks and order fillers": {
    "ageData": {
      "2014": {
        "total": "1,483",
        "16to19": "123",
        "20to24": "312",
        "25to34": "306",
        "35to44": "230",
        "45to54": "253",
        "55to64": "188",
        "65+": "71",
        "medianAge": "188"
      }
    },
    "title": "Stock clerks and order fillers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "1,483",
        "women": "35.9",
        "white": "16.0",
        "black": "4.2",
        "asian": "22.3"
      }
    },
    "wageData": {
      "2014": {
        "workers": "995",
        "median": "508",
        "maleWorkers": "658",
        "maleMedian": "504",
        "femaleWorkers": "337",
        "femaleMedian": "514"
      }
    }
  },
  "Weighers, measurers, checkers, and samplers, recordkeeping": {
    "ageData": {
      "2014": {
        "total": "75",
        "16to19": "1",
        "20to24": "7",
        "25to34": "21",
        "35to44": "13",
        "45to54": "18",
        "55to64": "14",
        "65+": "2",
        "medianAge": "14"
      }
    },
    "title": "Weighers, measurers, checkers, and samplers, recordkeeping",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "75",
        "women": "41.8",
        "white": "14.7",
        "black": "1.5",
        "asian": "22.2"
      }
    },
    "wageData": {
      "2014": {
        "workers": "77",
        "median": "646",
        "maleWorkers": "45",
        "maleMedian": "-",
        "femaleWorkers": "32",
        "femaleMedian": "-"
      }
    }
  },
  "Secretaries and administrative assistants": {
    "ageData": {
      "2014": {
        "total": "2,995",
        "16to19": "34",
        "20to24": "160",
        "25to34": "504",
        "35to44": "576",
        "45to54": "805",
        "55to64": "727",
        "65+": "190",
        "medianAge": "727"
      }
    },
    "title": "Secretaries and administrative assistants",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "2,995",
        "women": "94.2",
        "white": "9.8",
        "black": "3.3",
        "asian": "12.7"
      }
    },
    "wageData": {
      "2014": {
        "workers": "2,342",
        "median": "691",
        "maleWorkers": "126",
        "maleMedian": "811",
        "femaleWorkers": "2,215",
        "femaleMedian": "685"
      }
    }
  },
  "Computer operators": {
    "ageData": {
      "2014": {
        "total": "87",
        "16to19": "1",
        "20to24": "4",
        "25to34": "21",
        "35to44": "17",
        "45to54": "26",
        "55to64": "14",
        "65+": "5",
        "medianAge": "14"
      }
    },
    "title": "Computer operators",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "87",
        "women": "55.0",
        "white": "12.5",
        "black": "4.3",
        "asian": "8.5"
      }
    },
    "wageData": {
      "2014": {
        "workers": "72",
        "median": "764",
        "maleWorkers": "34",
        "maleMedian": "-",
        "femaleWorkers": "37",
        "femaleMedian": "-"
      }
    }
  },
  "Data entry keyers": {
    "ageData": {
      "2014": {
        "total": "292",
        "16to19": "7",
        "20to24": "30",
        "25to34": "71",
        "35to44": "53",
        "45to54": "67",
        "55to64": "50",
        "65+": "15",
        "medianAge": "50"
      }
    },
    "title": "Data entry keyers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "292",
        "women": "78.7",
        "white": "16.1",
        "black": "8.4",
        "asian": "12.9"
      }
    },
    "wageData": {
      "2014": {
        "workers": "246",
        "median": "626",
        "maleWorkers": "51",
        "maleMedian": "673",
        "femaleWorkers": "195",
        "femaleMedian": "618"
      }
    }
  },
  "Word processors and typists": {
    "ageData": {
      "2014": {
        "total": "101",
        "16to19": "2",
        "20to24": "11",
        "25to34": "20",
        "35to44": "24",
        "45to54": "20",
        "55to64": "17",
        "65+": "7",
        "medianAge": "17"
      }
    },
    "title": "Word processors and typists",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "101",
        "women": "88.4",
        "white": "18.4",
        "black": "5.3",
        "asian": "10.7"
      }
    },
    "wageData": {
      "2014": {
        "workers": "77",
        "median": "622",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "63",
        "femaleMedian": "629"
      }
    }
  },
  "Desktop publishers": {
    "ageData": {
      "2014": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "1",
        "55to64": "0",
        "65+": "1",
        "medianAge": "0"
      }
    },
    "title": "Desktop publishers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "0",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Insurance claims and policy processing clerks": {
    "ageData": {
      "2014": {
        "total": "288",
        "16to19": "3",
        "20to24": "22",
        "25to34": "62",
        "35to44": "72",
        "45to54": "68",
        "55to64": "50",
        "65+": "12",
        "medianAge": "50"
      }
    },
    "title": "Insurance claims and policy processing clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "288",
        "women": "82.8",
        "white": "15.2",
        "black": "2.9",
        "asian": "10.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "275",
        "median": "647",
        "maleWorkers": "50",
        "maleMedian": "709",
        "femaleWorkers": "225",
        "femaleMedian": "632"
      }
    }
  },
  "Mail clerks and mail machine operators, except postal service": {
    "ageData": {
      "2014": {
        "total": "79",
        "16to19": "2",
        "20to24": "13",
        "25to34": "15",
        "35to44": "13",
        "45to54": "15",
        "55to64": "18",
        "65+": "3",
        "medianAge": "18"
      }
    },
    "title": "Mail clerks and mail machine operators, except postal service",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "79",
        "women": "53.0",
        "white": "20.0",
        "black": "10.3",
        "asian": "13.8"
      }
    },
    "wageData": {
      "2014": {
        "workers": "61",
        "median": "567",
        "maleWorkers": "28",
        "maleMedian": "-",
        "femaleWorkers": "33",
        "femaleMedian": "-"
      }
    }
  },
  "Office clerks, general": {
    "ageData": {
      "2014": {
        "total": "1,230",
        "16to19": "51",
        "20to24": "140",
        "25to34": "225",
        "35to44": "214",
        "45to54": "276",
        "55to64": "238",
        "65+": "86",
        "medianAge": "238"
      }
    },
    "title": "Office clerks, general",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "1,230",
        "women": "84.6",
        "white": "13.3",
        "black": "6.1",
        "asian": "15.5"
      }
    },
    "wageData": {
      "2014": {
        "workers": "888",
        "median": "630",
        "maleWorkers": "135",
        "maleMedian": "662",
        "femaleWorkers": "753",
        "femaleMedian": "626"
      }
    }
  },
  "Office machine operators, except computer": {
    "ageData": {
      "2014": {
        "total": "39",
        "16to19": "2",
        "20to24": "4",
        "25to34": "9",
        "35to44": "4",
        "45to54": "11",
        "55to64": "7",
        "65+": "2",
        "medianAge": "7"
      }
    },
    "title": "Office machine operators, except computer",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "39",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "25",
        "median": "-",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "17",
        "femaleMedian": "-"
      }
    }
  },
  "Proofreaders and copy markers": {
    "ageData": {
      "2014": {
        "total": "10",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "0",
        "45to54": "3",
        "55to64": "4",
        "65+": "1",
        "medianAge": "4"
      }
    },
    "title": "Proofreaders and copy markers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "10",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Statistical assistants": {
    "ageData": {
      "2014": {
        "total": "23",
        "16to19": "0",
        "20to24": "4",
        "25to34": "4",
        "35to44": "5",
        "45to54": "6",
        "55to64": "4",
        "65+": "1",
        "medianAge": "4"
      }
    },
    "title": "Statistical assistants",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "23",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "19",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Office and administrative support workers, all other": {
    "ageData": {
      "2014": {
        "total": "497",
        "16to19": "13",
        "20to24": "36",
        "25to34": "113",
        "35to44": "106",
        "45to54": "109",
        "55to64": "94",
        "65+": "27",
        "medianAge": "94"
      }
    },
    "title": "Office and administrative support workers, all other",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "497",
        "women": "73.8",
        "white": "12.9",
        "black": "3.9",
        "asian": "12.2"
      }
    },
    "wageData": {
      "2014": {
        "workers": "410",
        "median": "731",
        "maleWorkers": "114",
        "maleMedian": "878",
        "femaleWorkers": "296",
        "femaleMedian": "692"
      }
    }
  },
  "Natural resources, construction, and maintenance occupations": {
    "ageData": {
      "2014": {
        "total": "13,537",
        "16to19": "283",
        "20to24": "1,158",
        "25to34": "3,308",
        "35to44": "3,100",
        "45to54": "3,172",
        "55to64": "2,063",
        "65+": "453",
        "medianAge": "2,063"
      }
    },
    "title": "Natural resources, construction, and maintenance occupations",
    "depth": 1,
    "parent": "",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "13,537",
        "women": "4.4",
        "white": "7.3",
        "black": "2.1",
        "asian": "27.6"
      }
    },
    "wageData": {
      "2014": {
        "workers": "10,763",
        "median": "756",
        "maleWorkers": "10,346",
        "maleMedian": "764",
        "femaleWorkers": "417",
        "femaleMedian": "509"
      }
    }
  },
  "Farming, fishing, and forestry occupations": {
    "ageData": {
      "2014": {
        "total": "1,022",
        "16to19": "77",
        "20to24": "113",
        "25to34": "246",
        "35to44": "201",
        "45to54": "192",
        "55to64": "141",
        "65+": "50",
        "medianAge": "141"
      }
    },
    "title": "Farming, fishing, and forestry occupations",
    "depth": 2,
    "parent": "Natural resources, construction, and maintenance occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "1,022",
        "women": "22.4",
        "white": "6.0",
        "black": "1.6",
        "asian": "43.4"
      }
    },
    "wageData": {
      "2014": {
        "workers": "776",
        "median": "429",
        "maleWorkers": "625",
        "maleMedian": "457",
        "femaleWorkers": "151",
        "femaleMedian": "375"
      }
    }
  },
  "First-line supervisors of farming, fishing, and forestry workers": {
    "ageData": {
      "2014": {
        "total": "44",
        "16to19": "0",
        "20to24": "2",
        "25to34": "6",
        "35to44": "12",
        "45to54": "8",
        "55to64": "10",
        "65+": "7",
        "medianAge": "10"
      }
    },
    "title": "First-line supervisors of farming, fishing, and forestry workers",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "44",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "23",
        "median": "-",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Agricultural inspectors": {
    "ageData": {
      "2014": {
        "total": "17",
        "16to19": "0",
        "20to24": "0",
        "25to34": "4",
        "35to44": "2",
        "45to54": "5",
        "55to64": "4",
        "65+": "1",
        "medianAge": "4"
      }
    },
    "title": "Agricultural inspectors",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "17",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "16",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Animal breeders": {
    "ageData": {
      "2014": {
        "total": "6",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "1",
        "45to54": "2",
        "55to64": "0",
        "65+": "1",
        "medianAge": "0"
      }
    },
    "title": "Animal breeders",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "6",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Graders and sorters, agricultural products": {
    "ageData": {
      "2014": {
        "total": "93",
        "16to19": "2",
        "20to24": "10",
        "25to34": "26",
        "35to44": "24",
        "45to54": "15",
        "55to64": "14",
        "65+": "2",
        "medianAge": "14"
      }
    },
    "title": "Graders and sorters, agricultural products",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "93",
        "women": "59.9",
        "white": "13.4",
        "black": "2.1",
        "asian": "54.0"
      }
    },
    "wageData": {
      "2014": {
        "workers": "98",
        "median": "389",
        "maleWorkers": "45",
        "maleMedian": "-",
        "femaleWorkers": "53",
        "femaleMedian": "386"
      }
    }
  },
  "Miscellaneous agricultural workers": {
    "ageData": {
      "2014": {
        "total": "739",
        "16to19": "71",
        "20to24": "94",
        "25to34": "185",
        "35to44": "138",
        "45to54": "130",
        "55to64": "90",
        "65+": "31",
        "medianAge": "90"
      }
    },
    "title": "Miscellaneous agricultural workers",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "739",
        "women": "20.2",
        "white": "5.1",
        "black": "1.5",
        "asian": "49.2"
      }
    },
    "wageData": {
      "2014": {
        "workers": "569",
        "median": "421",
        "maleWorkers": "485",
        "maleMedian": "437",
        "femaleWorkers": "84",
        "femaleMedian": "369"
      }
    }
  },
  "Fishers and related fishing workers": {
    "ageData": {
      "2014": {
        "total": "32",
        "16to19": "2",
        "20to24": "2",
        "25to34": "5",
        "35to44": "4",
        "45to54": "11",
        "55to64": "7",
        "65+": "2",
        "medianAge": "7"
      }
    },
    "title": "Fishers and related fishing workers",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "32",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "9",
        "median": "-",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Hunters and trappers": {
    "ageData": {
      "2014": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "1",
        "medianAge": "0"
      }
    },
    "title": "Hunters and trappers",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Forest and conservation workers": {
    "ageData": {
      "2014": {
        "total": "18",
        "16to19": "0",
        "20to24": "1",
        "25to34": "3",
        "35to44": "6",
        "45to54": "1",
        "55to64": "5",
        "65+": "1",
        "medianAge": "5"
      }
    },
    "title": "Forest and conservation workers",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "18",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Logging workers": {
    "ageData": {
      "2014": {
        "total": "71",
        "16to19": "2",
        "20to24": "4",
        "25to34": "15",
        "35to44": "14",
        "45to54": "20",
        "55to64": "11",
        "65+": "5",
        "medianAge": "11"
      }
    },
    "title": "Logging workers",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "71",
        "women": "5.4",
        "white": "8.4",
        "black": "1.5",
        "asian": "11.8"
      }
    },
    "wageData": {
      "2014": {
        "workers": "44",
        "median": "-",
        "maleWorkers": "42",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Construction and extraction occupations": {
    "ageData": {
      "2014": {
        "total": "7,637",
        "16to19": "139",
        "20to24": "649",
        "25to34": "1,941",
        "35to44": "1,824",
        "45to54": "1,773",
        "55to64": "1,084",
        "65+": "226",
        "medianAge": "1,084"
      }
    },
    "title": "Construction and extraction occupations",
    "depth": 2,
    "parent": "Natural resources, construction, and maintenance occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "7,637",
        "women": "2.6",
        "white": "6.9",
        "black": "1.3",
        "asian": "32.3"
      }
    },
    "wageData": {
      "2014": {
        "workers": "5,756",
        "median": "756",
        "maleWorkers": "5,635",
        "maleMedian": "757",
        "femaleWorkers": "121",
        "femaleMedian": "691"
      }
    }
  },
  "First-line supervisors of construction trades and extraction workers": {
    "ageData": {
      "2014": {
        "total": "696",
        "16to19": "1",
        "20to24": "19",
        "25to34": "132",
        "35to44": "157",
        "45to54": "204",
        "55to64": "152",
        "65+": "30",
        "medianAge": "152"
      }
    },
    "title": "First-line supervisors of construction trades and extraction workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "696",
        "women": "2.4",
        "white": "5.7",
        "black": "1.1",
        "asian": "18.9"
      }
    },
    "wageData": {
      "2014": {
        "workers": "540",
        "median": "1,027",
        "maleWorkers": "528",
        "maleMedian": "1,033",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Boilermakers": {
    "ageData": {
      "2014": {
        "total": "17",
        "16to19": "0",
        "20to24": "3",
        "25to34": "4",
        "35to44": "4",
        "45to54": "4",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Boilermakers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "17",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "17",
        "median": "-",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Brickmasons, blockmasons, and stonemasons": {
    "ageData": {
      "2014": {
        "total": "142",
        "16to19": "3",
        "20to24": "9",
        "25to34": "45",
        "35to44": "37",
        "45to54": "24",
        "55to64": "18",
        "65+": "6",
        "medianAge": "18"
      }
    },
    "title": "Brickmasons, blockmasons, and stonemasons",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "142",
        "women": "0.7",
        "white": "8.1",
        "black": "0.3",
        "asian": "43.9"
      }
    },
    "wageData": {
      "2014": {
        "workers": "105",
        "median": "657",
        "maleWorkers": "105",
        "maleMedian": "656",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Carpenters": {
    "ageData": {
      "2014": {
        "total": "1,282",
        "16to19": "21",
        "20to24": "109",
        "25to34": "311",
        "35to44": "313",
        "45to54": "302",
        "55to64": "185",
        "65+": "40",
        "medianAge": "185"
      }
    },
    "title": "Carpenters",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "1,282",
        "women": "1.7",
        "white": "5.0",
        "black": "1.4",
        "asian": "31.9"
      }
    },
    "wageData": {
      "2014": {
        "workers": "801",
        "median": "696",
        "maleWorkers": "786",
        "maleMedian": "699",
        "femaleWorkers": "15",
        "femaleMedian": "-"
      }
    }
  },
  "Carpet, floor, and tile installers and finishers": {
    "ageData": {
      "2014": {
        "total": "170",
        "16to19": "2",
        "20to24": "11",
        "25to34": "48",
        "35to44": "41",
        "45to54": "39",
        "55to64": "26",
        "65+": "3",
        "medianAge": "26"
      }
    },
    "title": "Carpet, floor, and tile installers and finishers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "170",
        "women": "2.3",
        "white": "4.8",
        "black": "3.6",
        "asian": "44.2"
      }
    },
    "wageData": {
      "2014": {
        "workers": "94",
        "median": "683",
        "maleWorkers": "92",
        "maleMedian": "687",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Cement masons, concrete finishers, and terrazzo workers": {
    "ageData": {
      "2014": {
        "total": "58",
        "16to19": "1",
        "20to24": "4",
        "25to34": "14",
        "35to44": "18",
        "45to54": "16",
        "55to64": "2",
        "65+": "3",
        "medianAge": "2"
      }
    },
    "title": "Cement masons, concrete finishers, and terrazzo workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "58",
        "women": "0.0",
        "white": "9.5",
        "black": "0.0",
        "asian": "48.7"
      }
    },
    "wageData": {
      "2014": {
        "workers": "41",
        "median": "-",
        "maleWorkers": "41",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Construction laborers": {
    "ageData": {
      "2014": {
        "total": "1,686",
        "16to19": "57",
        "20to24": "187",
        "25to34": "469",
        "35to44": "389",
        "45to54": "347",
        "55to64": "191",
        "65+": "46",
        "medianAge": "191"
      }
    },
    "title": "Construction laborers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "1,686",
        "women": "2.5",
        "white": "9.4",
        "black": "1.8",
        "asian": "42.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "1,221",
        "median": "605",
        "maleWorkers": "1,199",
        "maleMedian": "605",
        "femaleWorkers": "22",
        "femaleMedian": "-"
      }
    }
  },
  "Paving, surfacing, and tamping equipment operators": {
    "ageData": {
      "2014": {
        "total": "19",
        "16to19": "0",
        "20to24": "1",
        "25to34": "6",
        "35to44": "3",
        "45to54": "6",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Paving, surfacing, and tamping equipment operators",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "19",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Pile-driver operators": {
    "ageData": {
      "2014": {
        "total": "1",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "0",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Pile-driver operators",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Operating engineers and other construction equipment operators": {
    "ageData": {
      "2014": {
        "total": "336",
        "16to19": "2",
        "20to24": "22",
        "25to34": "75",
        "35to44": "74",
        "45to54": "82",
        "55to64": "68",
        "65+": "13",
        "medianAge": "68"
      }
    },
    "title": "Operating engineers and other construction equipment operators",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "336",
        "women": "1.8",
        "white": "6.8",
        "black": "0.1",
        "asian": "16.9"
      }
    },
    "wageData": {
      "2014": {
        "workers": "310",
        "median": "864",
        "maleWorkers": "305",
        "maleMedian": "862",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Drywall installers, ceiling tile installers, and tapers": {
    "ageData": {
      "2014": {
        "total": "162",
        "16to19": "1",
        "20to24": "9",
        "25to34": "47",
        "35to44": "59",
        "45to54": "31",
        "55to64": "13",
        "65+": "2",
        "medianAge": "13"
      }
    },
    "title": "Drywall installers, ceiling tile installers, and tapers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "162",
        "women": "2.1",
        "white": "1.8",
        "black": "0.3",
        "asian": "61.5"
      }
    },
    "wageData": {
      "2014": {
        "workers": "119",
        "median": "597",
        "maleWorkers": "118",
        "maleMedian": "595",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Electricians": {
    "ageData": {
      "2014": {
        "total": "769",
        "16to19": "9",
        "20to24": "62",
        "25to34": "187",
        "35to44": "198",
        "45to54": "199",
        "55to64": "94",
        "65+": "20",
        "medianAge": "94"
      }
    },
    "title": "Electricians",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "769",
        "women": "2.4",
        "white": "6.5",
        "black": "1.8",
        "asian": "18.2"
      }
    },
    "wageData": {
      "2014": {
        "workers": "640",
        "median": "883",
        "maleWorkers": "624",
        "maleMedian": "884",
        "femaleWorkers": "16",
        "femaleMedian": "-"
      }
    }
  },
  "Glaziers": {
    "ageData": {
      "2014": {
        "total": "35",
        "16to19": "0",
        "20to24": "1",
        "25to34": "9",
        "35to44": "10",
        "45to54": "8",
        "55to64": "5",
        "65+": "2",
        "medianAge": "5"
      }
    },
    "title": "Glaziers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "35",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "28",
        "median": "-",
        "maleWorkers": "27",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Insulation workers": {
    "ageData": {
      "2014": {
        "total": "44",
        "16to19": "0",
        "20to24": "2",
        "25to34": "16",
        "35to44": "10",
        "45to54": "9",
        "55to64": "6",
        "65+": "0",
        "medianAge": "6"
      }
    },
    "title": "Insulation workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "44",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "41",
        "median": "-",
        "maleWorkers": "38",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Painters, construction and maintenance": {
    "ageData": {
      "2014": {
        "total": "561",
        "16to19": "8",
        "20to24": "47",
        "25to34": "131",
        "35to44": "144",
        "45to54": "143",
        "55to64": "72",
        "65+": "16",
        "medianAge": "72"
      }
    },
    "title": "Painters, construction and maintenance",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "561",
        "women": "6.0",
        "white": "7.3",
        "black": "1.4",
        "asian": "48.2"
      }
    },
    "wageData": {
      "2014": {
        "workers": "358",
        "median": "563",
        "maleWorkers": "342",
        "maleMedian": "562",
        "femaleWorkers": "16",
        "femaleMedian": "-"
      }
    }
  },
  "Paperhangers": {
    "ageData": {
      "2014": {
        "total": "4",
        "16to19": "0",
        "20to24": "1",
        "25to34": "0",
        "35to44": "0",
        "45to54": "2",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Paperhangers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Pipelayers, plumbers, pipefitters, and steamfitters": {
    "ageData": {
      "2014": {
        "total": "564",
        "16to19": "9",
        "20to24": "40",
        "25to34": "139",
        "35to44": "141",
        "45to54": "124",
        "55to64": "93",
        "65+": "19",
        "medianAge": "93"
      }
    },
    "title": "Pipelayers, plumbers, pipefitters, and steamfitters",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "564",
        "women": "1.6",
        "white": "6.9",
        "black": "1.6",
        "asian": "21.4"
      }
    },
    "wageData": {
      "2014": {
        "workers": "486",
        "median": "913",
        "maleWorkers": "478",
        "maleMedian": "916",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Plasterers and stucco masons": {
    "ageData": {
      "2014": {
        "total": "31",
        "16to19": "1",
        "20to24": "1",
        "25to34": "11",
        "35to44": "7",
        "45to54": "7",
        "55to64": "5",
        "65+": "0",
        "medianAge": "5"
      }
    },
    "title": "Plasterers and stucco masons",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "31",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "22",
        "median": "-",
        "maleWorkers": "22",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Reinforcing iron and rebar workers": {
    "ageData": {
      "2014": {
        "total": "9",
        "16to19": "0",
        "20to24": "2",
        "25to34": "5",
        "35to44": "1",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Reinforcing iron and rebar workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "9",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Roofers": {
    "ageData": {
      "2014": {
        "total": "206",
        "16to19": "6",
        "20to24": "21",
        "25to34": "79",
        "35to44": "50",
        "45to54": "33",
        "55to64": "17",
        "65+": "1",
        "medianAge": "17"
      }
    },
    "title": "Roofers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "206",
        "women": "0.5",
        "white": "4.3",
        "black": "0.5",
        "asian": "58.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "136",
        "median": "618",
        "maleWorkers": "135",
        "maleMedian": "620",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Sheet metal workers": {
    "ageData": {
      "2014": {
        "total": "110",
        "16to19": "1",
        "20to24": "10",
        "25to34": "21",
        "35to44": "24",
        "45to54": "29",
        "55to64": "22",
        "65+": "3",
        "medianAge": "22"
      }
    },
    "title": "Sheet metal workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "110",
        "women": "5.2",
        "white": "5.2",
        "black": "0.8",
        "asian": "10.2"
      }
    },
    "wageData": {
      "2014": {
        "workers": "113",
        "median": "868",
        "maleWorkers": "111",
        "maleMedian": "857",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Structural iron and steel workers": {
    "ageData": {
      "2014": {
        "total": "52",
        "16to19": "1",
        "20to24": "5",
        "25to34": "15",
        "35to44": "9",
        "45to54": "14",
        "55to64": "8",
        "65+": "1",
        "medianAge": "8"
      }
    },
    "title": "Structural iron and steel workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "52",
        "women": "2.0",
        "white": "10.8",
        "black": "0.0",
        "asian": "23.4"
      }
    },
    "wageData": {
      "2014": {
        "workers": "47",
        "median": "-",
        "maleWorkers": "47",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Solar photovoltaic installers": {
    "ageData": {
      "2014": {
        "total": "14",
        "16to19": "0",
        "20to24": "2",
        "25to34": "6",
        "35to44": "2",
        "45to54": "3",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Solar photovoltaic installers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "14",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Helpers, construction trades": {
    "ageData": {
      "2014": {
        "total": "57",
        "16to19": "6",
        "20to24": "16",
        "25to34": "13",
        "35to44": "10",
        "45to54": "6",
        "55to64": "5",
        "65+": "1",
        "medianAge": "5"
      }
    },
    "title": "Helpers, construction trades",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "57",
        "women": "4.7",
        "white": "12.1",
        "black": "0.0",
        "asian": "45.6"
      }
    },
    "wageData": {
      "2014": {
        "workers": "51",
        "median": "513",
        "maleWorkers": "48",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Construction and building inspectors": {
    "ageData": {
      "2014": {
        "total": "78",
        "16to19": "0",
        "20to24": "1",
        "25to34": "7",
        "35to44": "17",
        "45to54": "22",
        "55to64": "24",
        "65+": "6",
        "medianAge": "24"
      }
    },
    "title": "Construction and building inspectors",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "78",
        "women": "12.2",
        "white": "3.3",
        "black": "2.3",
        "asian": "5.3"
      }
    },
    "wageData": {
      "2014": {
        "workers": "59",
        "median": "1,031",
        "maleWorkers": "54",
        "maleMedian": "1,028",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Elevator installers and repairers": {
    "ageData": {
      "2014": {
        "total": "22",
        "16to19": "0",
        "20to24": "0",
        "25to34": "9",
        "35to44": "4",
        "45to54": "4",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Elevator installers and repairers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "22",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "21",
        "median": "-",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Fence erectors": {
    "ageData": {
      "2014": {
        "total": "33",
        "16to19": "1",
        "20to24": "3",
        "25to34": "11",
        "35to44": "8",
        "45to54": "8",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Fence erectors",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "33",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "31",
        "median": "-",
        "maleWorkers": "31",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Hazardous materials removal workers": {
    "ageData": {
      "2014": {
        "total": "23",
        "16to19": "0",
        "20to24": "4",
        "25to34": "3",
        "35to44": "5",
        "45to54": "7",
        "55to64": "2",
        "65+": "2",
        "medianAge": "2"
      }
    },
    "title": "Hazardous materials removal workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "23",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "22",
        "median": "-",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Highway maintenance workers": {
    "ageData": {
      "2014": {
        "total": "123",
        "16to19": "1",
        "20to24": "6",
        "25to34": "19",
        "35to44": "23",
        "45to54": "39",
        "55to64": "28",
        "65+": "6",
        "medianAge": "28"
      }
    },
    "title": "Highway maintenance workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "123",
        "women": "1.5",
        "white": "9.3",
        "black": "0.0",
        "asian": "17.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "116",
        "median": "794",
        "maleWorkers": "115",
        "maleMedian": "794",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Rail-track laying and maintenance equipment operators": {
    "ageData": {
      "2014": {
        "total": "17",
        "16to19": "0",
        "20to24": "1",
        "25to34": "4",
        "35to44": "4",
        "45to54": "2",
        "55to64": "4",
        "65+": "1",
        "medianAge": "4"
      }
    },
    "title": "Rail-track laying and maintenance equipment operators",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "17",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "16",
        "median": "-",
        "maleWorkers": "16",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Septic tank servicers and sewer pipe cleaners": {
    "ageData": {
      "2014": {
        "total": "14",
        "16to19": "0",
        "20to24": "1",
        "25to34": "4",
        "35to44": "4",
        "45to54": "4",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Septic tank servicers and sewer pipe cleaners",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "14",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous construction and related workers": {
    "ageData": {
      "2014": {
        "total": "33",
        "16to19": "1",
        "20to24": "4",
        "25to34": "12",
        "35to44": "6",
        "45to54": "6",
        "55to64": "4",
        "65+": "2",
        "medianAge": "4"
      }
    },
    "title": "Miscellaneous construction and related workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "33",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "19",
        "median": "-",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Derrick, rotary drill, and service unit operators, oil, gas, and mining": {
    "ageData": {
      "2014": {
        "total": "54",
        "16to19": "1",
        "20to24": "8",
        "25to34": "23",
        "35to44": "10",
        "45to54": "8",
        "55to64": "5",
        "65+": "0",
        "medianAge": "5"
      }
    },
    "title": "Derrick, rotary drill, and service unit operators, oil, gas, and mining",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "54",
        "women": "0.0",
        "white": "3.9",
        "black": "0.0",
        "asian": "32.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "53",
        "median": "1,187",
        "maleWorkers": "53",
        "maleMedian": "1,187",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Earth drillers, except oil and gas": {
    "ageData": {
      "2014": {
        "total": "29",
        "16to19": "1",
        "20to24": "2",
        "25to34": "8",
        "35to44": "8",
        "45to54": "8",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Earth drillers, except oil and gas",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "29",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "25",
        "median": "-",
        "maleWorkers": "25",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Explosives workers, ordnance handling experts, and blasters": {
    "ageData": {
      "2014": {
        "total": "5",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "0",
        "45to54": "2",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Explosives workers, ordnance handling experts, and blasters",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Mining machine operators": {
    "ageData": {
      "2014": {
        "total": "68",
        "16to19": "1",
        "20to24": "11",
        "25to34": "17",
        "35to44": "13",
        "45to54": "14",
        "55to64": "10",
        "65+": "0",
        "medianAge": "10"
      }
    },
    "title": "Mining machine operators",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "68",
        "women": "1.5",
        "white": "2.3",
        "black": "0.0",
        "asian": "22.4"
      }
    },
    "wageData": {
      "2014": {
        "workers": "59",
        "median": "1,042",
        "maleWorkers": "59",
        "maleMedian": "1,047",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Roof bolters, mining": {
    "ageData": {
      "2014": {
        "total": "3",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Roof bolters, mining",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Roustabouts, oil and gas": {
    "ageData": {
      "2014": {
        "total": "11",
        "16to19": "2",
        "20to24": "2",
        "25to34": "4",
        "35to44": "1",
        "45to54": "2",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Roustabouts, oil and gas",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "11",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "11",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Helpers--extraction workers": {
    "ageData": {
      "2014": {
        "total": "7",
        "16to19": "0",
        "20to24": "2",
        "25to34": "1",
        "35to44": "2",
        "45to54": "2",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Helpers--extraction workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Other extraction workers": {
    "ageData": {
      "2014": {
        "total": "92",
        "16to19": "3",
        "20to24": "19",
        "25to34": "29",
        "35to44": "19",
        "45to54": "14",
        "55to64": "7",
        "65+": "2",
        "medianAge": "7"
      }
    },
    "title": "Other extraction workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "92",
        "women": "1.5",
        "white": "5.7",
        "black": "0.0",
        "asian": "29.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "86",
        "median": "1,120",
        "maleWorkers": "85",
        "maleMedian": "1,126",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Installation, maintenance, and repair occupations": {
    "ageData": {
      "2014": {
        "total": "4,879",
        "16to19": "66",
        "20to24": "396",
        "25to34": "1,122",
        "35to44": "1,075",
        "45to54": "1,206",
        "55to64": "838",
        "65+": "176",
        "medianAge": "838"
      }
    },
    "title": "Installation, maintenance, and repair occupations",
    "depth": 2,
    "parent": "Natural resources, construction, and maintenance occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "4,879",
        "women": "3.5",
        "white": "8.4",
        "black": "3.3",
        "asian": "16.8"
      }
    },
    "wageData": {
      "2014": {
        "workers": "4,231",
        "median": "821",
        "maleWorkers": "4,085",
        "maleMedian": "824",
        "femaleWorkers": "146",
        "femaleMedian": "725"
      }
    }
  },
  "First-line supervisors of mechanics, installers, and repairers": {
    "ageData": {
      "2014": {
        "total": "284",
        "16to19": "0",
        "20to24": "3",
        "25to34": "40",
        "35to44": "69",
        "45to54": "93",
        "55to64": "67",
        "65+": "11",
        "medianAge": "67"
      }
    },
    "title": "First-line supervisors of mechanics, installers, and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "284",
        "women": "4.8",
        "white": "8.4",
        "black": "3.3",
        "asian": "11.2"
      }
    },
    "wageData": {
      "2014": {
        "workers": "289",
        "median": "1,022",
        "maleWorkers": "272",
        "maleMedian": "1,040",
        "femaleWorkers": "17",
        "femaleMedian": "-"
      }
    }
  },
  "Computer, automated teller, and office machine repairers": {
    "ageData": {
      "2014": {
        "total": "265",
        "16to19": "5",
        "20to24": "22",
        "25to34": "68",
        "35to44": "64",
        "45to54": "57",
        "55to64": "44",
        "65+": "5",
        "medianAge": "44"
      }
    },
    "title": "Computer, automated teller, and office machine repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "265",
        "women": "13.3",
        "white": "12.2",
        "black": "7.9",
        "asian": "12.3"
      }
    },
    "wageData": {
      "2014": {
        "workers": "232",
        "median": "880",
        "maleWorkers": "201",
        "maleMedian": "889",
        "femaleWorkers": "31",
        "femaleMedian": "-"
      }
    }
  },
  "Radio and telecommunications equipment installers and repairers": {
    "ageData": {
      "2014": {
        "total": "134",
        "16to19": "1",
        "20to24": "12",
        "25to34": "29",
        "35to44": "33",
        "45to54": "30",
        "55to64": "27",
        "65+": "3",
        "medianAge": "27"
      }
    },
    "title": "Radio and telecommunications equipment installers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "134",
        "women": "7.4",
        "white": "12.5",
        "black": "6.3",
        "asian": "8.7"
      }
    },
    "wageData": {
      "2014": {
        "workers": "118",
        "median": "859",
        "maleWorkers": "109",
        "maleMedian": "846",
        "femaleWorkers": "9",
        "femaleMedian": "-"
      }
    }
  },
  "Avionics technicians": {
    "ageData": {
      "2014": {
        "total": "8",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "0",
        "45to54": "3",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Avionics technicians",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "8",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Electric motor, power tool, and related repairers": {
    "ageData": {
      "2014": {
        "total": "35",
        "16to19": "0",
        "20to24": "4",
        "25to34": "8",
        "35to44": "8",
        "45to54": "7",
        "55to64": "6",
        "65+": "2",
        "medianAge": "6"
      }
    },
    "title": "Electric motor, power tool, and related repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "35",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "25",
        "median": "-",
        "maleWorkers": "25",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Electrical and electronics installers and repairers, transportation equipment": {
    "ageData": {
      "2014": {
        "total": "5",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "1",
        "45to54": "1",
        "55to64": "1",
        "65+": "1",
        "medianAge": "1"
      }
    },
    "title": "Electrical and electronics installers and repairers, transportation equipment",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Electrical and electronics repairers, industrial and utility": {
    "ageData": {
      "2014": {
        "total": "15",
        "16to19": "0",
        "20to24": "0",
        "25to34": "4",
        "35to44": "2",
        "45to54": "6",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Electrical and electronics repairers, industrial and utility",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "15",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "20",
        "median": "-",
        "maleWorkers": "20",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Electronic equipment installers and repairers, motor vehicles": {
    "ageData": {
      "2014": {
        "total": "17",
        "16to19": "0",
        "20to24": "0",
        "25to34": "5",
        "35to44": "4",
        "45to54": "4",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Electronic equipment installers and repairers, motor vehicles",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "17",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "18",
        "median": "-",
        "maleWorkers": "18",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Electronic home entertainment equipment installers and repairers": {
    "ageData": {
      "2014": {
        "total": "34",
        "16to19": "0",
        "20to24": "2",
        "25to34": "13",
        "35to44": "10",
        "45to54": "5",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Electronic home entertainment equipment installers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "34",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "23",
        "median": "-",
        "maleWorkers": "23",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Security and fire alarm systems installers": {
    "ageData": {
      "2014": {
        "total": "58",
        "16to19": "0",
        "20to24": "3",
        "25to34": "20",
        "35to44": "10",
        "45to54": "15",
        "55to64": "8",
        "65+": "1",
        "medianAge": "8"
      }
    },
    "title": "Security and fire alarm systems installers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "58",
        "women": "7.5",
        "white": "9.0",
        "black": "1.7",
        "asian": "26.2"
      }
    },
    "wageData": {
      "2014": {
        "workers": "45",
        "median": "-",
        "maleWorkers": "43",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Aircraft mechanics and service technicians": {
    "ageData": {
      "2014": {
        "total": "127",
        "16to19": "0",
        "20to24": "6",
        "25to34": "35",
        "35to44": "22",
        "45to54": "37",
        "55to64": "21",
        "65+": "5",
        "medianAge": "21"
      }
    },
    "title": "Aircraft mechanics and service technicians",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "127",
        "women": "3.3",
        "white": "9.0",
        "black": "6.9",
        "asian": "12.5"
      }
    },
    "wageData": {
      "2014": {
        "workers": "121",
        "median": "1,080",
        "maleWorkers": "117",
        "maleMedian": "1,062",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Automotive body and related repairers": {
    "ageData": {
      "2014": {
        "total": "133",
        "16to19": "3",
        "20to24": "14",
        "25to34": "30",
        "35to44": "26",
        "45to54": "38",
        "55to64": "17",
        "65+": "5",
        "medianAge": "17"
      }
    },
    "title": "Automotive body and related repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "133",
        "women": "1.6",
        "white": "5.9",
        "black": "1.7",
        "asian": "18.3"
      }
    },
    "wageData": {
      "2014": {
        "workers": "109",
        "median": "665",
        "maleWorkers": "106",
        "maleMedian": "668",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Automotive glass installers and repairers": {
    "ageData": {
      "2014": {
        "total": "21",
        "16to19": "1",
        "20to24": "2",
        "25to34": "6",
        "35to44": "5",
        "45to54": "3",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Automotive glass installers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "21",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "17",
        "median": "-",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Automotive service technicians and mechanics": {
    "ageData": {
      "2014": {
        "total": "883",
        "16to19": "17",
        "20to24": "96",
        "25to34": "222",
        "35to44": "214",
        "45to54": "198",
        "55to64": "111",
        "65+": "25",
        "medianAge": "111"
      }
    },
    "title": "Automotive service technicians and mechanics",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "883",
        "women": "1.4",
        "white": "9.0",
        "black": "4.5",
        "asian": "24.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "722",
        "median": "734",
        "maleWorkers": "706",
        "maleMedian": "737",
        "femaleWorkers": "16",
        "femaleMedian": "-"
      }
    }
  },
  "Bus and truck mechanics and diesel engine specialists": {
    "ageData": {
      "2014": {
        "total": "323",
        "16to19": "4",
        "20to24": "32",
        "25to34": "78",
        "35to44": "66",
        "45to54": "86",
        "55to64": "44",
        "65+": "13",
        "medianAge": "44"
      }
    },
    "title": "Bus and truck mechanics and diesel engine specialists",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "323",
        "women": "0.3",
        "white": "7.8",
        "black": "0.6",
        "asian": "15.6"
      }
    },
    "wageData": {
      "2014": {
        "workers": "285",
        "median": "813",
        "maleWorkers": "283",
        "maleMedian": "811",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Heavy vehicle and mobile equipment service technicians and mechanics": {
    "ageData": {
      "2014": {
        "total": "211",
        "16to19": "1",
        "20to24": "23",
        "25to34": "54",
        "35to44": "40",
        "45to54": "51",
        "55to64": "30",
        "65+": "12",
        "medianAge": "30"
      }
    },
    "title": "Heavy vehicle and mobile equipment service technicians and mechanics",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "211",
        "women": "0.5",
        "white": "3.8",
        "black": "0.6",
        "asian": "15.5"
      }
    },
    "wageData": {
      "2014": {
        "workers": "210",
        "median": "885",
        "maleWorkers": "209",
        "maleMedian": "886",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Small engine mechanics": {
    "ageData": {
      "2014": {
        "total": "50",
        "16to19": "2",
        "20to24": "4",
        "25to34": "19",
        "35to44": "8",
        "45to54": "9",
        "55to64": "6",
        "65+": "2",
        "medianAge": "6"
      }
    },
    "title": "Small engine mechanics",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "50",
        "women": "2.1",
        "white": "5.3",
        "black": "3.2",
        "asian": "9.5"
      }
    },
    "wageData": {
      "2014": {
        "workers": "38",
        "median": "-",
        "maleWorkers": "37",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous vehicle and mobile equipment mechanics, installers, and repairers": {
    "ageData": {
      "2014": {
        "total": "93",
        "16to19": "6",
        "20to24": "23",
        "25to34": "21",
        "35to44": "19",
        "45to54": "13",
        "55to64": "9",
        "65+": "2",
        "medianAge": "9"
      }
    },
    "title": "Miscellaneous vehicle and mobile equipment mechanics, installers, and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "93",
        "women": "0.3",
        "white": "10.1",
        "black": "2.4",
        "asian": "28.0"
      }
    },
    "wageData": {
      "2014": {
        "workers": "64",
        "median": "559",
        "maleWorkers": "64",
        "maleMedian": "559",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Control and valve installers and repairers": {
    "ageData": {
      "2014": {
        "total": "19",
        "16to19": "0",
        "20to24": "2",
        "25to34": "5",
        "35to44": "4",
        "45to54": "4",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Control and valve installers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "19",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "21",
        "median": "-",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Heating, air conditioning, and refrigeration mechanics and installers": {
    "ageData": {
      "2014": {
        "total": "378",
        "16to19": "5",
        "20to24": "32",
        "25to34": "93",
        "35to44": "99",
        "45to54": "74",
        "55to64": "64",
        "65+": "11",
        "medianAge": "64"
      }
    },
    "title": "Heating, air conditioning, and refrigeration mechanics and installers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "378",
        "women": "1.2",
        "white": "8.5",
        "black": "2.4",
        "asian": "16.4"
      }
    },
    "wageData": {
      "2014": {
        "workers": "322",
        "median": "769",
        "maleWorkers": "318",
        "maleMedian": "773",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Home appliance repairers": {
    "ageData": {
      "2014": {
        "total": "51",
        "16to19": "0",
        "20to24": "2",
        "25to34": "10",
        "35to44": "9",
        "45to54": "16",
        "55to64": "10",
        "65+": "4",
        "medianAge": "10"
      }
    },
    "title": "Home appliance repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "51",
        "women": "5.6",
        "white": "13.4",
        "black": "1.9",
        "asian": "19.2"
      }
    },
    "wageData": {
      "2014": {
        "workers": "32",
        "median": "-",
        "maleWorkers": "32",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Industrial and refractory machinery mechanics": {
    "ageData": {
      "2014": {
        "total": "454",
        "16to19": "3",
        "20to24": "23",
        "25to34": "84",
        "35to44": "96",
        "45to54": "129",
        "55to64": "103",
        "65+": "16",
        "medianAge": "103"
      }
    },
    "title": "Industrial and refractory machinery mechanics",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "454",
        "women": "2.8",
        "white": "6.3",
        "black": "1.7",
        "asian": "13.3"
      }
    },
    "wageData": {
      "2014": {
        "workers": "409",
        "median": "876",
        "maleWorkers": "398",
        "maleMedian": "876",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "Maintenance and repair workers, general": {
    "ageData": {
      "2014": {
        "total": "471",
        "16to19": "5",
        "20to24": "26",
        "25to34": "83",
        "35to44": "77",
        "45to54": "143",
        "55to64": "117",
        "65+": "20",
        "medianAge": "117"
      }
    },
    "title": "Maintenance and repair workers, general",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "471",
        "women": "3.2",
        "white": "9.9",
        "black": "3.6",
        "asian": "17.2"
      }
    },
    "wageData": {
      "2014": {
        "workers": "403",
        "median": "813",
        "maleWorkers": "391",
        "maleMedian": "812",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Maintenance workers, machinery": {
    "ageData": {
      "2014": {
        "total": "37",
        "16to19": "1",
        "20to24": "4",
        "25to34": "2",
        "35to44": "8",
        "45to54": "9",
        "55to64": "7",
        "65+": "4",
        "medianAge": "7"
      }
    },
    "title": "Maintenance workers, machinery",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "37",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "32",
        "median": "-",
        "maleWorkers": "30",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Millwrights": {
    "ageData": {
      "2014": {
        "total": "48",
        "16to19": "0",
        "20to24": "2",
        "25to34": "8",
        "35to44": "9",
        "45to54": "11",
        "55to64": "13",
        "65+": "4",
        "medianAge": "13"
      }
    },
    "title": "Millwrights",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "48",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "49",
        "median": "-",
        "maleWorkers": "49",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Electrical power-line installers and repairers": {
    "ageData": {
      "2014": {
        "total": "115",
        "16to19": "1",
        "20to24": "6",
        "25to34": "41",
        "35to44": "28",
        "45to54": "25",
        "55to64": "11",
        "65+": "3",
        "medianAge": "11"
      }
    },
    "title": "Electrical power-line installers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "115",
        "women": "3.0",
        "white": "5.2",
        "black": "0.9",
        "asian": "7.8"
      }
    },
    "wageData": {
      "2014": {
        "workers": "108",
        "median": "1,013",
        "maleWorkers": "105",
        "maleMedian": "1,018",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Telecommunications line installers and repairers": {
    "ageData": {
      "2014": {
        "total": "184",
        "16to19": "2",
        "20to24": "13",
        "25to34": "55",
        "35to44": "56",
        "45to54": "37",
        "55to64": "20",
        "65+": "1",
        "medianAge": "20"
      }
    },
    "title": "Telecommunications line installers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "184",
        "women": "4.4",
        "white": "12.1",
        "black": "4.7",
        "asian": "17.3"
      }
    },
    "wageData": {
      "2014": {
        "workers": "170",
        "median": "869",
        "maleWorkers": "163",
        "maleMedian": "877",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Precision instrument and equipment repairers": {
    "ageData": {
      "2014": {
        "total": "85",
        "16to19": "0",
        "20to24": "3",
        "25to34": "16",
        "35to44": "21",
        "45to54": "20",
        "55to64": "18",
        "65+": "7",
        "medianAge": "18"
      }
    },
    "title": "Precision instrument and equipment repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "85",
        "women": "14.2",
        "white": "7.2",
        "black": "4.7",
        "asian": "11.6"
      }
    },
    "wageData": {
      "2014": {
        "workers": "68",
        "median": "818",
        "maleWorkers": "60",
        "maleMedian": "836",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Wind turbine service technicians": {
    "ageData": {
      "2014": {
        "total": "4",
        "16to19": "0",
        "20to24": "1",
        "25to34": "0",
        "35to44": "0",
        "45to54": "2",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Wind turbine service technicians",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Coin, vending, and amusement machine servicers and repairers": {
    "ageData": {
      "2014": {
        "total": "43",
        "16to19": "0",
        "20to24": "4",
        "25to34": "5",
        "35to44": "10",
        "45to54": "7",
        "55to64": "14",
        "65+": "3",
        "medianAge": "14"
      }
    },
    "title": "Coin, vending, and amusement machine servicers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "43",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "32",
        "median": "-",
        "maleWorkers": "28",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Commercial divers": {
    "ageData": {
      "2014": {
        "total": "3",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "2",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Commercial divers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Locksmiths and safe repairers": {
    "ageData": {
      "2014": {
        "total": "30",
        "16to19": "1",
        "20to24": "0",
        "25to34": "6",
        "35to44": "4",
        "45to54": "6",
        "55to64": "10",
        "65+": "3",
        "medianAge": "10"
      }
    },
    "title": "Locksmiths and safe repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "30",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "19",
        "median": "-",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Manufactured building and mobile home installers": {
    "ageData": {
      "2014": {
        "total": "4",
        "16to19": "0",
        "20to24": "1",
        "25to34": "0",
        "35to44": "1",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Manufactured building and mobile home installers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "0",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Riggers": {
    "ageData": {
      "2014": {
        "total": "13",
        "16to19": "0",
        "20to24": "2",
        "25to34": "5",
        "35to44": "3",
        "45to54": "2",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Riggers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "13",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "14",
        "median": "-",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Signal and track switch repairers": {
    "ageData": {
      "2014": {
        "total": "7",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "2",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Signal and track switch repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Helpers--installation, maintenance, and repair workers": {
    "ageData": {
      "2014": {
        "total": "14",
        "16to19": "1",
        "20to24": "5",
        "25to34": "2",
        "35to44": "3",
        "45to54": "2",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Helpers--installation, maintenance, and repair workers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "14",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Other installation, maintenance, and repair workers": {
    "ageData": {
      "2014": {
        "total": "224",
        "16to19": "4",
        "20to24": "20",
        "25to34": "46",
        "35to44": "44",
        "45to54": "60",
        "55to64": "41",
        "65+": "9",
        "medianAge": "41"
      }
    },
    "title": "Other installation, maintenance, and repair workers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "224",
        "women": "5.2",
        "white": "5.1",
        "black": "2.7",
        "asian": "23.4"
      }
    },
    "wageData": {
      "2014": {
        "workers": "177",
        "median": "804",
        "maleWorkers": "170",
        "maleMedian": "811",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Production, transportation, and material moving occupations": {
    "ageData": {
      "2014": {
        "total": "17,448",
        "16to19": "490",
        "20to24": "1,657",
        "25to34": "3,505",
        "35to44": "3,667",
        "45to54": "4,192",
        "55to64": "3,095",
        "65+": "842",
        "medianAge": "3,095"
      }
    },
    "title": "Production, transportation, and material moving occupations",
    "depth": 1,
    "parent": "",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "17,448",
        "women": "21.7",
        "white": "15.0",
        "black": "4.7",
        "asian": "22.0"
      }
    },
    "wageData": {
      "2014": {
        "workers": "14,326",
        "median": "642",
        "maleWorkers": "11,474",
        "maleMedian": "689",
        "femaleWorkers": "2,852",
        "femaleMedian": "504"
      }
    }
  },
  "Production occupations": {
    "ageData": {
      "2014": {
        "total": "8,438",
        "16to19": "152",
        "20to24": "780",
        "25to34": "1,806",
        "35to44": "1,826",
        "45to54": "2,043",
        "55to64": "1,513",
        "65+": "319",
        "medianAge": "1,513"
      }
    },
    "title": "Production occupations",
    "depth": 2,
    "parent": "Production, transportation, and material moving occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "8,438",
        "women": "28.0",
        "white": "12.5",
        "black": "6.2",
        "asian": "21.9"
      }
    },
    "wageData": {
      "2014": {
        "workers": "7,481",
        "median": "646",
        "maleWorkers": "5,546",
        "maleMedian": "711",
        "femaleWorkers": "1,935",
        "femaleMedian": "502"
      }
    }
  },
  "First-line supervisors of production and operating workers": {
    "ageData": {
      "2014": {
        "total": "789",
        "16to19": "4",
        "20to24": "24",
        "25to34": "146",
        "35to44": "194",
        "45to54": "241",
        "55to64": "158",
        "65+": "22",
        "medianAge": "158"
      }
    },
    "title": "First-line supervisors of production and operating workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "789",
        "women": "18.6",
        "white": "11.0",
        "black": "3.4",
        "asian": "17.0"
      }
    },
    "wageData": {
      "2014": {
        "workers": "766",
        "median": "903",
        "maleWorkers": "631",
        "maleMedian": "942",
        "femaleWorkers": "134",
        "femaleMedian": "659"
      }
    }
  },
  "Aircraft structure, surfaces, rigging, and systems assemblers": {
    "ageData": {
      "2014": {
        "total": "15",
        "16to19": "0",
        "20to24": "2",
        "25to34": "1",
        "35to44": "1",
        "45to54": "4",
        "55to64": "5",
        "65+": "2",
        "medianAge": "5"
      }
    },
    "title": "Aircraft structure, surfaces, rigging, and systems assemblers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "15",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "16",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Electrical, electronics, and electromechanical assemblers": {
    "ageData": {
      "2014": {
        "total": "164",
        "16to19": "4",
        "20to24": "10",
        "25to34": "31",
        "35to44": "34",
        "45to54": "48",
        "55to64": "30",
        "65+": "7",
        "medianAge": "30"
      }
    },
    "title": "Electrical, electronics, and electromechanical assemblers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "164",
        "women": "46.7",
        "white": "15.3",
        "black": "16.8",
        "asian": "22.9"
      }
    },
    "wageData": {
      "2014": {
        "workers": "159",
        "median": "547",
        "maleWorkers": "91",
        "maleMedian": "598",
        "femaleWorkers": "68",
        "femaleMedian": "506"
      }
    }
  },
  "Engine and other machine assemblers": {
    "ageData": {
      "2014": {
        "total": "10",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "1",
        "45to54": "2",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Engine and other machine assemblers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "10",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "9",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Structural metal fabricators and fitters": {
    "ageData": {
      "2014": {
        "total": "24",
        "16to19": "0",
        "20to24": "3",
        "25to34": "6",
        "35to44": "7",
        "45to54": "5",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Structural metal fabricators and fitters",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "24",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "24",
        "median": "-",
        "maleWorkers": "24",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous assemblers and fabricators": {
    "ageData": {
      "2014": {
        "total": "1,002",
        "16to19": "24",
        "20to24": "123",
        "25to34": "238",
        "35to44": "204",
        "45to54": "204",
        "55to64": "176",
        "65+": "33",
        "medianAge": "176"
      }
    },
    "title": "Miscellaneous assemblers and fabricators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "1,002",
        "women": "37.5",
        "white": "17.0",
        "black": "8.7",
        "asian": "21.0"
      }
    },
    "wageData": {
      "2014": {
        "workers": "926",
        "median": "592",
        "maleWorkers": "589",
        "maleMedian": "633",
        "femaleWorkers": "337",
        "femaleMedian": "506"
      }
    }
  },
  "Bakers": {
    "ageData": {
      "2014": {
        "total": "224",
        "16to19": "8",
        "20to24": "36",
        "25to34": "58",
        "35to44": "32",
        "45to54": "52",
        "55to64": "27",
        "65+": "11",
        "medianAge": "27"
      }
    },
    "title": "Bakers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "224",
        "women": "62.8",
        "white": "10.1",
        "black": "7.5",
        "asian": "22.9"
      }
    },
    "wageData": {
      "2014": {
        "workers": "148",
        "median": "492",
        "maleWorkers": "64",
        "maleMedian": "518",
        "femaleWorkers": "84",
        "femaleMedian": "469"
      }
    }
  },
  "Butchers and other meat, poultry, and fish processing workers": {
    "ageData": {
      "2014": {
        "total": "331",
        "16to19": "10",
        "20to24": "50",
        "25to34": "89",
        "35to44": "61",
        "45to54": "69",
        "55to64": "42",
        "65+": "11",
        "medianAge": "42"
      }
    },
    "title": "Butchers and other meat, poultry, and fish processing workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "331",
        "women": "23.5",
        "white": "20.2",
        "black": "8.0",
        "asian": "35.4"
      }
    },
    "wageData": {
      "2014": {
        "workers": "287",
        "median": "512",
        "maleWorkers": "217",
        "maleMedian": "537",
        "femaleWorkers": "70",
        "femaleMedian": "457"
      }
    }
  },
  "Food and tobacco roasting, baking, and drying machine operators and tenders": {
    "ageData": {
      "2014": {
        "total": "10",
        "16to19": "0",
        "20to24": "2",
        "25to34": "2",
        "35to44": "3",
        "45to54": "2",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Food and tobacco roasting, baking, and drying machine operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "10",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Food batchmakers": {
    "ageData": {
      "2014": {
        "total": "95",
        "16to19": "3",
        "20to24": "18",
        "25to34": "19",
        "35to44": "20",
        "45to54": "22",
        "55to64": "11",
        "65+": "3",
        "medianAge": "11"
      }
    },
    "title": "Food batchmakers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "95",
        "women": "61.5",
        "white": "13.1",
        "black": "6.1",
        "asian": "25.3"
      }
    },
    "wageData": {
      "2014": {
        "workers": "82",
        "median": "473",
        "maleWorkers": "38",
        "maleMedian": "-",
        "femaleWorkers": "44",
        "femaleMedian": "-"
      }
    }
  },
  "Food cooking machine operators and tenders": {
    "ageData": {
      "2014": {
        "total": "16",
        "16to19": "1",
        "20to24": "4",
        "25to34": "3",
        "35to44": "3",
        "45to54": "4",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Food cooking machine operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "16",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "11",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Food processing workers, all other": {
    "ageData": {
      "2014": {
        "total": "128",
        "16to19": "1",
        "20to24": "10",
        "25to34": "30",
        "35to44": "30",
        "45to54": "33",
        "55to64": "20",
        "65+": "4",
        "medianAge": "20"
      }
    },
    "title": "Food processing workers, all other",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "128",
        "women": "32.0",
        "white": "18.1",
        "black": "7.6",
        "asian": "31.7"
      }
    },
    "wageData": {
      "2014": {
        "workers": "110",
        "median": "637",
        "maleWorkers": "80",
        "maleMedian": "651",
        "femaleWorkers": "30",
        "femaleMedian": "-"
      }
    }
  },
  "Computer control programmers and operators": {
    "ageData": {
      "2014": {
        "total": "71",
        "16to19": "2",
        "20to24": "5",
        "25to34": "19",
        "35to44": "25",
        "45to54": "12",
        "55to64": "9",
        "65+": "1",
        "medianAge": "9"
      }
    },
    "title": "Computer control programmers and operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "71",
        "women": "8.1",
        "white": "2.3",
        "black": "2.2",
        "asian": "14.7"
      }
    },
    "wageData": {
      "2014": {
        "workers": "79",
        "median": "759",
        "maleWorkers": "73",
        "maleMedian": "790",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Extruding and drawing machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2014": {
        "total": "14",
        "16to19": "0",
        "20to24": "2",
        "25to34": "1",
        "35to44": "5",
        "45to54": "4",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Extruding and drawing machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "14",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Forging machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2014": {
        "total": "5",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "1",
        "45to54": "1",
        "55to64": "1",
        "65+": "1",
        "medianAge": "1"
      }
    },
    "title": "Forging machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Rolling machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2014": {
        "total": "10",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "1",
        "45to54": "3",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Rolling machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "10",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "9",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Cutting, punching, and press machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2014": {
        "total": "85",
        "16to19": "1",
        "20to24": "11",
        "25to34": "15",
        "35to44": "17",
        "45to54": "22",
        "55to64": "17",
        "65+": "2",
        "medianAge": "17"
      }
    },
    "title": "Cutting, punching, and press machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "85",
        "women": "24.2",
        "white": "14.0",
        "black": "0.9",
        "asian": "15.9"
      }
    },
    "wageData": {
      "2014": {
        "workers": "83",
        "median": "620",
        "maleWorkers": "63",
        "maleMedian": "663",
        "femaleWorkers": "20",
        "femaleMedian": "-"
      }
    }
  },
  "Drilling and boring machine tool setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2014": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Drilling and boring machine tool setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Grinding, lapping, polishing, and buffing machine tool setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2014": {
        "total": "45",
        "16to19": "1",
        "20to24": "5",
        "25to34": "6",
        "35to44": "7",
        "45to54": "14",
        "55to64": "9",
        "65+": "3",
        "medianAge": "9"
      }
    },
    "title": "Grinding, lapping, polishing, and buffing machine tool setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "45",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "45",
        "median": "-",
        "maleWorkers": "38",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Lathe and turning machine tool setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2014": {
        "total": "9",
        "16to19": "0",
        "20to24": "4",
        "25to34": "2",
        "35to44": "1",
        "45to54": "2",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Lathe and turning machine tool setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "9",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Milling and planing machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2014": {
        "total": "5",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "0",
        "45to54": "2",
        "55to64": "0",
        "65+": "1",
        "medianAge": "0"
      }
    },
    "title": "Milling and planing machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Machinists": {
    "ageData": {
      "2014": {
        "total": "391",
        "16to19": "6",
        "20to24": "33",
        "25to34": "66",
        "35to44": "90",
        "45to54": "99",
        "55to64": "83",
        "65+": "13",
        "medianAge": "83"
      }
    },
    "title": "Machinists",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "391",
        "women": "4.5",
        "white": "3.9",
        "black": "4.1",
        "asian": "15.4"
      }
    },
    "wageData": {
      "2014": {
        "workers": "351",
        "median": "764",
        "maleWorkers": "339",
        "maleMedian": "770",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Metal furnace operators, tenders, pourers, and casters": {
    "ageData": {
      "2014": {
        "total": "27",
        "16to19": "0",
        "20to24": "5",
        "25to34": "6",
        "35to44": "6",
        "45to54": "5",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Metal furnace operators, tenders, pourers, and casters",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "27",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "26",
        "median": "-",
        "maleWorkers": "26",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Model makers and patternmakers, metal and plastic": {
    "ageData": {
      "2014": {
        "total": "5",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "1",
        "45to54": "2",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Model makers and patternmakers, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Molders and molding machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2014": {
        "total": "38",
        "16to19": "1",
        "20to24": "2",
        "25to34": "11",
        "35to44": "10",
        "45to54": "5",
        "55to64": "10",
        "65+": "1",
        "medianAge": "10"
      }
    },
    "title": "Molders and molding machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "38",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "34",
        "median": "-",
        "maleWorkers": "31",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Multiple machine tool setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2014": {
        "total": "4",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "2",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Multiple machine tool setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Tool and die makers": {
    "ageData": {
      "2014": {
        "total": "67",
        "16to19": "0",
        "20to24": "1",
        "25to34": "8",
        "35to44": "18",
        "45to54": "15",
        "55to64": "20",
        "65+": "5",
        "medianAge": "20"
      }
    },
    "title": "Tool and die makers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "67",
        "women": "1.3",
        "white": "0.7",
        "black": "0.0",
        "asian": "3.8"
      }
    },
    "wageData": {
      "2014": {
        "workers": "60",
        "median": "1,020",
        "maleWorkers": "59",
        "maleMedian": "1,025",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Welding, soldering, and brazing workers": {
    "ageData": {
      "2014": {
        "total": "615",
        "16to19": "14",
        "20to24": "67",
        "25to34": "173",
        "35to44": "142",
        "45to54": "135",
        "55to64": "73",
        "65+": "13",
        "medianAge": "73"
      }
    },
    "title": "Welding, soldering, and brazing workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "615",
        "women": "4.8",
        "white": "8.8",
        "black": "2.0",
        "asian": "22.4"
      }
    },
    "wageData": {
      "2014": {
        "workers": "551",
        "median": "723",
        "maleWorkers": "526",
        "maleMedian": "733",
        "femaleWorkers": "25",
        "femaleMedian": "-"
      }
    }
  },
  "Heat treating equipment setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2014": {
        "total": "4",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Heat treating equipment setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Layout workers, metal and plastic": {
    "ageData": {
      "2014": {
        "total": "9",
        "16to19": "1",
        "20to24": "1",
        "25to34": "3",
        "35to44": "1",
        "45to54": "0",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Layout workers, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "9",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "9",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Plating and coating machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2014": {
        "total": "29",
        "16to19": "0",
        "20to24": "1",
        "25to34": "4",
        "35to44": "4",
        "45to54": "16",
        "55to64": "4",
        "65+": "1",
        "medianAge": "4"
      }
    },
    "title": "Plating and coating machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "29",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "24",
        "median": "-",
        "maleWorkers": "22",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Tool grinders, filers, and sharpeners": {
    "ageData": {
      "2014": {
        "total": "10",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "4",
        "45to54": "2",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Tool grinders, filers, and sharpeners",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "10",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Metal workers and plastic workers, all other": {
    "ageData": {
      "2014": {
        "total": "342",
        "16to19": "6",
        "20to24": "29",
        "25to34": "65",
        "35to44": "68",
        "45to54": "90",
        "55to64": "73",
        "65+": "10",
        "medianAge": "73"
      }
    },
    "title": "Metal workers and plastic workers, all other",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "342",
        "women": "20.4",
        "white": "13.5",
        "black": "5.9",
        "asian": "23.0"
      }
    },
    "wageData": {
      "2014": {
        "workers": "333",
        "median": "624",
        "maleWorkers": "262",
        "maleMedian": "647",
        "femaleWorkers": "71",
        "femaleMedian": "511"
      }
    }
  },
  "Prepress technicians and workers": {
    "ageData": {
      "2014": {
        "total": "25",
        "16to19": "0",
        "20to24": "2",
        "25to34": "5",
        "35to44": "3",
        "45to54": "7",
        "55to64": "4",
        "65+": "3",
        "medianAge": "4"
      }
    },
    "title": "Prepress technicians and workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "25",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "20",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Printing press operators": {
    "ageData": {
      "2014": {
        "total": "187",
        "16to19": "1",
        "20to24": "18",
        "25to34": "41",
        "35to44": "40",
        "45to54": "52",
        "55to64": "27",
        "65+": "7",
        "medianAge": "27"
      }
    },
    "title": "Printing press operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "187",
        "women": "19.6",
        "white": "13.2",
        "black": "5.7",
        "asian": "21.7"
      }
    },
    "wageData": {
      "2014": {
        "workers": "173",
        "median": "646",
        "maleWorkers": "140",
        "maleMedian": "686",
        "femaleWorkers": "32",
        "femaleMedian": "-"
      }
    }
  },
  "Print binding and finishing workers": {
    "ageData": {
      "2014": {
        "total": "21",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "4",
        "45to54": "5",
        "55to64": "8",
        "65+": "2",
        "medianAge": "8"
      }
    },
    "title": "Print binding and finishing workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "21",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "18",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Laundry and dry-cleaning workers": {
    "ageData": {
      "2014": {
        "total": "156",
        "16to19": "4",
        "20to24": "7",
        "25to34": "33",
        "35to44": "29",
        "45to54": "36",
        "55to64": "35",
        "65+": "12",
        "medianAge": "35"
      }
    },
    "title": "Laundry and dry-cleaning workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "156",
        "women": "59.7",
        "white": "16.1",
        "black": "11.5",
        "asian": "38.0"
      }
    },
    "wageData": {
      "2014": {
        "workers": "128",
        "median": "417",
        "maleWorkers": "48",
        "maleMedian": "-",
        "femaleWorkers": "80",
        "femaleMedian": "410"
      }
    }
  },
  "Pressers, textile, garment, and related materials": {
    "ageData": {
      "2014": {
        "total": "48",
        "16to19": "0",
        "20to24": "1",
        "25to34": "16",
        "35to44": "10",
        "45to54": "12",
        "55to64": "5",
        "65+": "3",
        "medianAge": "5"
      }
    },
    "title": "Pressers, textile, garment, and related materials",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "48",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "30",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "17",
        "femaleMedian": "-"
      }
    }
  },
  "Sewing machine operators": {
    "ageData": {
      "2014": {
        "total": "158",
        "16to19": "2",
        "20to24": "6",
        "25to34": "18",
        "35to44": "27",
        "45to54": "44",
        "55to64": "48",
        "65+": "13",
        "medianAge": "48"
      }
    },
    "title": "Sewing machine operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "158",
        "women": "81.9",
        "white": "15.0",
        "black": "21.1",
        "asian": "34.6"
      }
    },
    "wageData": {
      "2014": {
        "workers": "114",
        "median": "440",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "95",
        "femaleMedian": "432"
      }
    }
  },
  "Shoe and leather workers and repairers": {
    "ageData": {
      "2014": {
        "total": "4",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "1",
        "55to64": "1",
        "65+": "1",
        "medianAge": "1"
      }
    },
    "title": "Shoe and leather workers and repairers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Shoe machine operators and tenders": {
    "ageData": {
      "2014": {
        "total": "3",
        "16to19": "1",
        "20to24": "0",
        "25to34": "0",
        "35to44": "1",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Shoe machine operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Tailors, dressmakers, and sewers": {
    "ageData": {
      "2014": {
        "total": "92",
        "16to19": "1",
        "20to24": "1",
        "25to34": "10",
        "35to44": "15",
        "45to54": "27",
        "55to64": "28",
        "65+": "9",
        "medianAge": "28"
      }
    },
    "title": "Tailors, dressmakers, and sewers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "92",
        "women": "80.6",
        "white": "9.5",
        "black": "16.7",
        "asian": "30.4"
      }
    },
    "wageData": {
      "2014": {
        "workers": "42",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "31",
        "femaleMedian": "-"
      }
    }
  },
  "Textile bleaching and dyeing machine operators and tenders": {
    "ageData": {
      "2014": {
        "total": "3",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "0",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Textile bleaching and dyeing machine operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Textile cutting machine setters, operators, and tenders": {
    "ageData": {
      "2014": {
        "total": "6",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "1",
        "45to54": "0",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Textile cutting machine setters, operators, and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "6",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Textile knitting and weaving machine setters, operators, and tenders": {
    "ageData": {
      "2014": {
        "total": "12",
        "16to19": "0",
        "20to24": "1",
        "25to34": "2",
        "35to44": "2",
        "45to54": "3",
        "55to64": "2",
        "65+": "2",
        "medianAge": "2"
      }
    },
    "title": "Textile knitting and weaving machine setters, operators, and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "12",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Textile winding, twisting, and drawing out machine setters, operators, and tenders": {
    "ageData": {
      "2014": {
        "total": "12",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "7",
        "45to54": "2",
        "55to64": "0",
        "65+": "1",
        "medianAge": "0"
      }
    },
    "title": "Textile winding, twisting, and drawing out machine setters, operators, and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "12",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Extruding and forming machine setters, operators, and tenders, synthetic and glass fibers": {
    "ageData": {
      "2014": {
        "total": "0",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Extruding and forming machine setters, operators, and tenders, synthetic and glass fibers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "0",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "0",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Fabric and apparel patternmakers": {
    "ageData": {
      "2014": {
        "total": "1",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Fabric and apparel patternmakers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Upholsterers": {
    "ageData": {
      "2014": {
        "total": "40",
        "16to19": "1",
        "20to24": "4",
        "25to34": "4",
        "35to44": "4",
        "45to54": "17",
        "55to64": "7",
        "65+": "5",
        "medianAge": "7"
      }
    },
    "title": "Upholsterers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "40",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "22",
        "median": "-",
        "maleWorkers": "20",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Textile, apparel, and furnishings workers, all other": {
    "ageData": {
      "2014": {
        "total": "14",
        "16to19": "1",
        "20to24": "1",
        "25to34": "1",
        "35to44": "5",
        "45to54": "2",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Textile, apparel, and furnishings workers, all other",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "14",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "11",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Cabinetmakers and bench carpenters": {
    "ageData": {
      "2014": {
        "total": "51",
        "16to19": "2",
        "20to24": "2",
        "25to34": "10",
        "35to44": "12",
        "45to54": "14",
        "55to64": "8",
        "65+": "2",
        "medianAge": "8"
      }
    },
    "title": "Cabinetmakers and bench carpenters",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "51",
        "women": "9.9",
        "white": "5.6",
        "black": "2.4",
        "asian": "27.8"
      }
    },
    "wageData": {
      "2014": {
        "workers": "30",
        "median": "-",
        "maleWorkers": "26",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Furniture finishers": {
    "ageData": {
      "2014": {
        "total": "7",
        "16to19": "1",
        "20to24": "0",
        "25to34": "2",
        "35to44": "0",
        "45to54": "3",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Furniture finishers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Model makers and patternmakers, wood": {
    "ageData": {
      "2014": {
        "total": "1",
        "16to19": "0",
        "20to24": "1",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Model makers and patternmakers, wood",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "0",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Sawing machine setters, operators, and tenders, wood": {
    "ageData": {
      "2014": {
        "total": "32",
        "16to19": "1",
        "20to24": "2",
        "25to34": "7",
        "35to44": "7",
        "45to54": "4",
        "55to64": "6",
        "65+": "4",
        "medianAge": "6"
      }
    },
    "title": "Sawing machine setters, operators, and tenders, wood",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "32",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "27",
        "median": "-",
        "maleWorkers": "25",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Woodworking machine setters, operators, and tenders, except sawing": {
    "ageData": {
      "2014": {
        "total": "19",
        "16to19": "1",
        "20to24": "1",
        "25to34": "5",
        "35to44": "6",
        "45to54": "5",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Woodworking machine setters, operators, and tenders, except sawing",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "19",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "19",
        "median": "-",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Woodworkers, all other": {
    "ageData": {
      "2014": {
        "total": "28",
        "16to19": "0",
        "20to24": "2",
        "25to34": "2",
        "35to44": "4",
        "45to54": "9",
        "55to64": "7",
        "65+": "4",
        "medianAge": "7"
      }
    },
    "title": "Woodworkers, all other",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "28",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "16",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Power plant operators, distributors, and dispatchers": {
    "ageData": {
      "2014": {
        "total": "45",
        "16to19": "0",
        "20to24": "1",
        "25to34": "14",
        "35to44": "10",
        "45to54": "10",
        "55to64": "10",
        "65+": "1",
        "medianAge": "10"
      }
    },
    "title": "Power plant operators, distributors, and dispatchers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "45",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "47",
        "median": "-",
        "maleWorkers": "43",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Stationary engineers and boiler operators": {
    "ageData": {
      "2014": {
        "total": "96",
        "16to19": "0",
        "20to24": "3",
        "25to34": "12",
        "35to44": "21",
        "45to54": "30",
        "55to64": "27",
        "65+": "4",
        "medianAge": "27"
      }
    },
    "title": "Stationary engineers and boiler operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "96",
        "women": "2.0",
        "white": "7.0",
        "black": "4.3",
        "asian": "15.2"
      }
    },
    "wageData": {
      "2014": {
        "workers": "93",
        "median": "1,044",
        "maleWorkers": "92",
        "maleMedian": "1,051",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Water and wastewater treatment plant and system operators": {
    "ageData": {
      "2014": {
        "total": "72",
        "16to19": "0",
        "20to24": "2",
        "25to34": "11",
        "35to44": "14",
        "45to54": "24",
        "55to64": "19",
        "65+": "2",
        "medianAge": "19"
      }
    },
    "title": "Water and wastewater treatment plant and system operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "72",
        "women": "4.5",
        "white": "10.7",
        "black": "2.3",
        "asian": "14.7"
      }
    },
    "wageData": {
      "2014": {
        "workers": "69",
        "median": "833",
        "maleWorkers": "66",
        "maleMedian": "835",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous plant and system operators": {
    "ageData": {
      "2014": {
        "total": "41",
        "16to19": "0",
        "20to24": "2",
        "25to34": "13",
        "35to44": "10",
        "45to54": "5",
        "55to64": "11",
        "65+": "0",
        "medianAge": "11"
      }
    },
    "title": "Miscellaneous plant and system operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "41",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "39",
        "median": "-",
        "maleWorkers": "36",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Chemical processing machine setters, operators, and tenders": {
    "ageData": {
      "2014": {
        "total": "63",
        "16to19": "0",
        "20to24": "4",
        "25to34": "18",
        "35to44": "15",
        "45to54": "17",
        "55to64": "7",
        "65+": "1",
        "medianAge": "7"
      }
    },
    "title": "Chemical processing machine setters, operators, and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "63",
        "women": "5.8",
        "white": "10.7",
        "black": "0.5",
        "asian": "10.3"
      }
    },
    "wageData": {
      "2014": {
        "workers": "55",
        "median": "818",
        "maleWorkers": "53",
        "maleMedian": "831",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Crushing, grinding, polishing, mixing, and blending workers": {
    "ageData": {
      "2014": {
        "total": "69",
        "16to19": "1",
        "20to24": "8",
        "25to34": "15",
        "35to44": "15",
        "45to54": "17",
        "55to64": "12",
        "65+": "1",
        "medianAge": "12"
      }
    },
    "title": "Crushing, grinding, polishing, mixing, and blending workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "69",
        "women": "11.7",
        "white": "14.1",
        "black": "3.5",
        "asian": "22.4"
      }
    },
    "wageData": {
      "2014": {
        "workers": "67",
        "median": "686",
        "maleWorkers": "60",
        "maleMedian": "694",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Cutting workers": {
    "ageData": {
      "2014": {
        "total": "60",
        "16to19": "2",
        "20to24": "9",
        "25to34": "7",
        "35to44": "17",
        "45to54": "15",
        "55to64": "9",
        "65+": "2",
        "medianAge": "9"
      }
    },
    "title": "Cutting workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "60",
        "women": "21.9",
        "white": "10.3",
        "black": "4.7",
        "asian": "22.0"
      }
    },
    "wageData": {
      "2014": {
        "workers": "43",
        "median": "-",
        "maleWorkers": "36",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Extruding, forming, pressing, and compacting machine setters, operators, and tenders": {
    "ageData": {
      "2014": {
        "total": "40",
        "16to19": "1",
        "20to24": "5",
        "25to34": "6",
        "35to44": "13",
        "45to54": "7",
        "55to64": "7",
        "65+": "1",
        "medianAge": "7"
      }
    },
    "title": "Extruding, forming, pressing, and compacting machine setters, operators, and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "40",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "39",
        "median": "-",
        "maleWorkers": "29",
        "maleMedian": "-",
        "femaleWorkers": "9",
        "femaleMedian": "-"
      }
    }
  },
  "Furnace, kiln, oven, drier, and kettle operators and tenders": {
    "ageData": {
      "2014": {
        "total": "9",
        "16to19": "0",
        "20to24": "0",
        "25to34": "4",
        "35to44": "1",
        "45to54": "2",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Furnace, kiln, oven, drier, and kettle operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "9",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "11",
        "median": "-",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Inspectors, testers, sorters, samplers, and weighers": {
    "ageData": {
      "2014": {
        "total": "752",
        "16to19": "6",
        "20to24": "63",
        "25to34": "181",
        "35to44": "153",
        "45to54": "171",
        "55to64": "146",
        "65+": "31",
        "medianAge": "146"
      }
    },
    "title": "Inspectors, testers, sorters, samplers, and weighers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "752",
        "women": "35.1",
        "white": "12.7",
        "black": "6.8",
        "asian": "16.8"
      }
    },
    "wageData": {
      "2014": {
        "workers": "675",
        "median": "728",
        "maleWorkers": "443",
        "maleMedian": "820",
        "femaleWorkers": "231",
        "femaleMedian": "578"
      }
    }
  },
  "Jewelers and precious stone and metal workers": {
    "ageData": {
      "2014": {
        "total": "44",
        "16to19": "0",
        "20to24": "2",
        "25to34": "7",
        "35to44": "8",
        "45to54": "12",
        "55to64": "13",
        "65+": "2",
        "medianAge": "13"
      }
    },
    "title": "Jewelers and precious stone and metal workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "44",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "24",
        "median": "-",
        "maleWorkers": "16",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Medical, dental, and ophthalmic laboratory technicians": {
    "ageData": {
      "2014": {
        "total": "85",
        "16to19": "1",
        "20to24": "8",
        "25to34": "15",
        "35to44": "22",
        "45to54": "14",
        "55to64": "20",
        "65+": "6",
        "medianAge": "20"
      }
    },
    "title": "Medical, dental, and ophthalmic laboratory technicians",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "85",
        "women": "52.3",
        "white": "3.0",
        "black": "14.3",
        "asian": "12.3"
      }
    },
    "wageData": {
      "2014": {
        "workers": "71",
        "median": "626",
        "maleWorkers": "29",
        "maleMedian": "-",
        "femaleWorkers": "43",
        "femaleMedian": "-"
      }
    }
  },
  "Packaging and filling machine operators and tenders": {
    "ageData": {
      "2014": {
        "total": "259",
        "16to19": "6",
        "20to24": "31",
        "25to34": "47",
        "35to44": "64",
        "45to54": "62",
        "55to64": "41",
        "65+": "8",
        "medianAge": "41"
      }
    },
    "title": "Packaging and filling machine operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "259",
        "women": "56.2",
        "white": "19.0",
        "black": "8.9",
        "asian": "36.0"
      }
    },
    "wageData": {
      "2014": {
        "workers": "239",
        "median": "490",
        "maleWorkers": "106",
        "maleMedian": "522",
        "femaleWorkers": "133",
        "femaleMedian": "477"
      }
    }
  },
  "Painting workers": {
    "ageData": {
      "2014": {
        "total": "156",
        "16to19": "4",
        "20to24": "15",
        "25to34": "38",
        "35to44": "40",
        "45to54": "33",
        "55to64": "23",
        "65+": "3",
        "medianAge": "23"
      }
    },
    "title": "Painting workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "156",
        "women": "12.7",
        "white": "8.1",
        "black": "4.3",
        "asian": "27.0"
      }
    },
    "wageData": {
      "2014": {
        "workers": "132",
        "median": "694",
        "maleWorkers": "120",
        "maleMedian": "703",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Photographic process workers and processing machine operators": {
    "ageData": {
      "2014": {
        "total": "27",
        "16to19": "0",
        "20to24": "6",
        "25to34": "8",
        "35to44": "4",
        "45to54": "5",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Photographic process workers and processing machine operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "27",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "21",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Semiconductor processors": {
    "ageData": {
      "2014": {
        "total": "1",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "1",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Semiconductor processors",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Adhesive bonding machine operators and tenders": {
    "ageData": {
      "2014": {
        "total": "6",
        "16to19": "0",
        "20to24": "1",
        "25to34": "0",
        "35to44": "2",
        "45to54": "2",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Adhesive bonding machine operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "6",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Cleaning, washing, and metal pickling equipment operators and tenders": {
    "ageData": {
      "2014": {
        "total": "6",
        "16to19": "0",
        "20to24": "1",
        "25to34": "0",
        "35to44": "3",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Cleaning, washing, and metal pickling equipment operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "6",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Cooling and freezing equipment operators and tenders": {
    "ageData": {
      "2014": {
        "total": "4",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "1",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Cooling and freezing equipment operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Etchers and engravers": {
    "ageData": {
      "2014": {
        "total": "13",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "1",
        "45to54": "5",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Etchers and engravers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "13",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "9",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Molders, shapers, and casters, except metal and plastic": {
    "ageData": {
      "2014": {
        "total": "31",
        "16to19": "0",
        "20to24": "2",
        "25to34": "6",
        "35to44": "7",
        "45to54": "7",
        "55to64": "7",
        "65+": "2",
        "medianAge": "7"
      }
    },
    "title": "Molders, shapers, and casters, except metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "31",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "21",
        "median": "-",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Paper goods machine setters, operators, and tenders": {
    "ageData": {
      "2014": {
        "total": "31",
        "16to19": "0",
        "20to24": "0",
        "25to34": "9",
        "35to44": "9",
        "45to54": "6",
        "55to64": "5",
        "65+": "2",
        "medianAge": "5"
      }
    },
    "title": "Paper goods machine setters, operators, and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "31",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "29",
        "median": "-",
        "maleWorkers": "27",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Tire builders": {
    "ageData": {
      "2014": {
        "total": "12",
        "16to19": "0",
        "20to24": "1",
        "25to34": "2",
        "35to44": "6",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Tire builders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "12",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "11",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Helpers--production workers": {
    "ageData": {
      "2014": {
        "total": "54",
        "16to19": "5",
        "20to24": "8",
        "25to34": "17",
        "35to44": "8",
        "45to54": "7",
        "55to64": "8",
        "65+": "1",
        "medianAge": "8"
      }
    },
    "title": "Helpers--production workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "54",
        "women": "25.1",
        "white": "10.9",
        "black": "9.6",
        "asian": "32.9"
      }
    },
    "wageData": {
      "2014": {
        "workers": "42",
        "median": "-",
        "maleWorkers": "31",
        "maleMedian": "-",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "Production workers, all other": {
    "ageData": {
      "2014": {
        "total": "947",
        "16to19": "21",
        "20to24": "106",
        "25to34": "198",
        "35to44": "215",
        "45to54": "223",
        "55to64": "146",
        "65+": "40",
        "medianAge": "146"
      }
    },
    "title": "Production workers, all other",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "947",
        "women": "24.7",
        "white": "15.1",
        "black": "5.5",
        "asian": "23.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "850",
        "median": "621",
        "maleWorkers": "659",
        "maleMedian": "676",
        "femaleWorkers": "191",
        "femaleMedian": "492"
      }
    }
  },
  "Transportation and material moving occupations": {
    "ageData": {
      "2014": {
        "total": "9,010",
        "16to19": "337",
        "20to24": "877",
        "25to34": "1,700",
        "35to44": "1,841",
        "45to54": "2,149",
        "55to64": "1,582",
        "65+": "523",
        "medianAge": "1,582"
      }
    },
    "title": "Transportation and material moving occupations",
    "depth": 2,
    "parent": "Production, transportation, and material moving occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "9,010",
        "women": "15.7",
        "white": "17.3",
        "black": "3.4",
        "asian": "22.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "6,845",
        "median": "637",
        "maleWorkers": "5,927",
        "maleMedian": "664",
        "femaleWorkers": "918",
        "femaleMedian": "510"
      }
    }
  },
  "Supervisors of transportation and material moving workers": {
    "ageData": {
      "2014": {
        "total": "199",
        "16to19": "1",
        "20to24": "10",
        "25to34": "45",
        "35to44": "39",
        "45to54": "61",
        "55to64": "36",
        "65+": "8",
        "medianAge": "36"
      }
    },
    "title": "Supervisors of transportation and material moving workers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "199",
        "women": "18.3",
        "white": "9.0",
        "black": "2.0",
        "asian": "22.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "181",
        "median": "829",
        "maleWorkers": "148",
        "maleMedian": "925",
        "femaleWorkers": "33",
        "femaleMedian": "-"
      }
    }
  },
  "Aircraft pilots and flight engineers": {
    "ageData": {
      "2014": {
        "total": "133",
        "16to19": "0",
        "20to24": "3",
        "25to34": "17",
        "35to44": "39",
        "45to54": "41",
        "55to64": "28",
        "65+": "6",
        "medianAge": "28"
      }
    },
    "title": "Aircraft pilots and flight engineers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "133",
        "women": "7.2",
        "white": "1.9",
        "black": "0.5",
        "asian": "2.7"
      }
    },
    "wageData": {
      "2014": {
        "workers": "113",
        "median": "1,427",
        "maleWorkers": "106",
        "maleMedian": "1,446",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Air traffic controllers and airfield operations specialists": {
    "ageData": {
      "2014": {
        "total": "31",
        "16to19": "0",
        "20to24": "0",
        "25to34": "10",
        "35to44": "5",
        "45to54": "11",
        "55to64": "4",
        "65+": "1",
        "medianAge": "4"
      }
    },
    "title": "Air traffic controllers and airfield operations specialists",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "31",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "31",
        "median": "-",
        "maleWorkers": "27",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Flight attendants": {
    "ageData": {
      "2014": {
        "total": "92",
        "16to19": "0",
        "20to24": "6",
        "25to34": "10",
        "35to44": "19",
        "45to54": "33",
        "55to64": "21",
        "65+": "3",
        "medianAge": "21"
      }
    },
    "title": "Flight attendants",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "92",
        "women": "75.8",
        "white": "10.2",
        "black": "2.5",
        "asian": "7.9"
      }
    },
    "wageData": {
      "2014": {
        "workers": "65",
        "median": "857",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "48",
        "femaleMedian": "-"
      }
    }
  },
  "Ambulance drivers and attendants, except emergency medical technicians": {
    "ageData": {
      "2014": {
        "total": "19",
        "16to19": "0",
        "20to24": "1",
        "25to34": "5",
        "35to44": "2",
        "45to54": "5",
        "55to64": "4",
        "65+": "2",
        "medianAge": "4"
      }
    },
    "title": "Ambulance drivers and attendants, except emergency medical technicians",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "19",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "15",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Bus drivers": {
    "ageData": {
      "2014": {
        "total": "584",
        "16to19": "1",
        "20to24": "11",
        "25to34": "56",
        "35to44": "98",
        "45to54": "159",
        "55to64": "165",
        "65+": "95",
        "medianAge": "165"
      }
    },
    "title": "Bus drivers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "584",
        "women": "45.0",
        "white": "25.9",
        "black": "2.5",
        "asian": "12.8"
      }
    },
    "wageData": {
      "2014": {
        "workers": "327",
        "median": "602",
        "maleWorkers": "191",
        "maleMedian": "616",
        "femaleWorkers": "136",
        "femaleMedian": "582"
      }
    }
  },
  "Driver/sales workers and truck drivers": {
    "ageData": {
      "2014": {
        "total": "3,406",
        "16to19": "53",
        "20to24": "193",
        "25to34": "559",
        "35to44": "772",
        "45to54": "933",
        "55to64": "684",
        "65+": "211",
        "medianAge": "684"
      }
    },
    "title": "Driver/sales workers and truck drivers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "3,406",
        "women": "5.8",
        "white": "15.6",
        "black": "2.5",
        "asian": "20.5"
      }
    },
    "wageData": {
      "2014": {
        "workers": "2,658",
        "median": "732",
        "maleWorkers": "2,555",
        "maleMedian": "739",
        "femaleWorkers": "103",
        "femaleMedian": "545"
      }
    }
  },
  "Taxi drivers and chauffeurs": {
    "ageData": {
      "2014": {
        "total": "383",
        "16to19": "0",
        "20to24": "10",
        "25to34": "69",
        "35to44": "78",
        "45to54": "91",
        "55to64": "88",
        "65+": "47",
        "medianAge": "88"
      }
    },
    "title": "Taxi drivers and chauffeurs",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "383",
        "women": "12.7",
        "white": "28.8",
        "black": "13.9",
        "asian": "16.5"
      }
    },
    "wageData": {
      "2014": {
        "workers": "250",
        "median": "608",
        "maleWorkers": "221",
        "maleMedian": "609",
        "femaleWorkers": "29",
        "femaleMedian": "-"
      }
    }
  },
  "Motor vehicle operators, all other": {
    "ageData": {
      "2014": {
        "total": "61",
        "16to19": "0",
        "20to24": "4",
        "25to34": "5",
        "35to44": "8",
        "45to54": "15",
        "55to64": "8",
        "65+": "21",
        "medianAge": "8"
      }
    },
    "title": "Motor vehicle operators, all other",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "61",
        "women": "8.9",
        "white": "16.1",
        "black": "2.3",
        "asian": "16.1"
      }
    },
    "wageData": {
      "2014": {
        "workers": "26",
        "median": "-",
        "maleWorkers": "22",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Locomotive engineers and operators": {
    "ageData": {
      "2014": {
        "total": "55",
        "16to19": "0",
        "20to24": "2",
        "25to34": "7",
        "35to44": "22",
        "45to54": "13",
        "55to64": "11",
        "65+": "1",
        "medianAge": "11"
      }
    },
    "title": "Locomotive engineers and operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "55",
        "women": "1.4",
        "white": "14.0",
        "black": "0.0",
        "asian": "5.6"
      }
    },
    "wageData": {
      "2014": {
        "workers": "56",
        "median": "1,187",
        "maleWorkers": "55",
        "maleMedian": "1,197",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Railroad brake, signal, and switch operators": {
    "ageData": {
      "2014": {
        "total": "9",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "2",
        "45to54": "3",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Railroad brake, signal, and switch operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "9",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Railroad conductors and yardmasters": {
    "ageData": {
      "2014": {
        "total": "46",
        "16to19": "0",
        "20to24": "3",
        "25to34": "13",
        "35to44": "12",
        "45to54": "13",
        "55to64": "5",
        "65+": "1",
        "medianAge": "5"
      }
    },
    "title": "Railroad conductors and yardmasters",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "46",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "48",
        "median": "-",
        "maleWorkers": "44",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Subway, streetcar, and other rail transportation workers": {
    "ageData": {
      "2014": {
        "total": "19",
        "16to19": "0",
        "20to24": "2",
        "25to34": "5",
        "35to44": "6",
        "45to54": "3",
        "55to64": "2",
        "65+": "2",
        "medianAge": "2"
      }
    },
    "title": "Subway, streetcar, and other rail transportation workers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "19",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "16",
        "median": "-",
        "maleWorkers": "16",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Sailors and marine oilers": {
    "ageData": {
      "2014": {
        "total": "27",
        "16to19": "2",
        "20to24": "9",
        "25to34": "4",
        "35to44": "4",
        "45to54": "3",
        "55to64": "4",
        "65+": "1",
        "medianAge": "4"
      }
    },
    "title": "Sailors and marine oilers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "27",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "21",
        "median": "-",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Ship and boat captains and operators": {
    "ageData": {
      "2014": {
        "total": "41",
        "16to19": "0",
        "20to24": "2",
        "25to34": "7",
        "35to44": "10",
        "45to54": "14",
        "55to64": "7",
        "65+": "2",
        "medianAge": "7"
      }
    },
    "title": "Ship and boat captains and operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "41",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "38",
        "median": "-",
        "maleWorkers": "38",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Ship engineers": {
    "ageData": {
      "2014": {
        "total": "7",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "3",
        "45to54": "2",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Ship engineers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Bridge and lock tenders": {
    "ageData": {
      "2014": {
        "total": "6",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "1",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Bridge and lock tenders",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "6",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Parking lot attendants": {
    "ageData": {
      "2014": {
        "total": "78",
        "16to19": "4",
        "20to24": "24",
        "25to34": "17",
        "35to44": "9",
        "45to54": "10",
        "55to64": "11",
        "65+": "3",
        "medianAge": "11"
      }
    },
    "title": "Parking lot attendants",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "78",
        "women": "10.5",
        "white": "26.3",
        "black": "9.9",
        "asian": "28.7"
      }
    },
    "wageData": {
      "2014": {
        "workers": "60",
        "median": "440",
        "maleWorkers": "55",
        "maleMedian": "441",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Automotive and watercraft service attendants": {
    "ageData": {
      "2014": {
        "total": "97",
        "16to19": "5",
        "20to24": "30",
        "25to34": "24",
        "35to44": "18",
        "45to54": "9",
        "55to64": "10",
        "65+": "1",
        "medianAge": "10"
      }
    },
    "title": "Automotive and watercraft service attendants",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "97",
        "women": "7.5",
        "white": "13.7",
        "black": "15.7",
        "asian": "21.9"
      }
    },
    "wageData": {
      "2014": {
        "workers": "77",
        "median": "512",
        "maleWorkers": "72",
        "maleMedian": "518",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Transportation inspectors": {
    "ageData": {
      "2014": {
        "total": "47",
        "16to19": "0",
        "20to24": "4",
        "25to34": "7",
        "35to44": "13",
        "45to54": "13",
        "55to64": "9",
        "65+": "3",
        "medianAge": "9"
      }
    },
    "title": "Transportation inspectors",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "47",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "53",
        "median": "909",
        "maleWorkers": "41",
        "maleMedian": "-",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Transportation attendants, except flight attendants": {
    "ageData": {
      "2014": {
        "total": "27",
        "16to19": "0",
        "20to24": "4",
        "25to34": "4",
        "35to44": "3",
        "45to54": "5",
        "55to64": "7",
        "65+": "4",
        "medianAge": "7"
      }
    },
    "title": "Transportation attendants, except flight attendants",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "27",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "24",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Other transportation workers": {
    "ageData": {
      "2014": {
        "total": "25",
        "16to19": "0",
        "20to24": "4",
        "25to34": "6",
        "35to44": "6",
        "45to54": "6",
        "55to64": "2",
        "65+": "2",
        "medianAge": "2"
      }
    },
    "title": "Other transportation workers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "25",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "21",
        "median": "-",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Conveyor operators and tenders": {
    "ageData": {
      "2014": {
        "total": "6",
        "16to19": "0",
        "20to24": "1",
        "25to34": "0",
        "35to44": "1",
        "45to54": "1",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Conveyor operators and tenders",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "6",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Crane and tower operators": {
    "ageData": {
      "2014": {
        "total": "74",
        "16to19": "0",
        "20to24": "3",
        "25to34": "19",
        "35to44": "19",
        "45to54": "18",
        "55to64": "13",
        "65+": "2",
        "medianAge": "13"
      }
    },
    "title": "Crane and tower operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "74",
        "women": "0.2",
        "white": "8.4",
        "black": "1.0",
        "asian": "11.2"
      }
    },
    "wageData": {
      "2014": {
        "workers": "73",
        "median": "959",
        "maleWorkers": "73",
        "maleMedian": "959",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Dredge, excavating, and loading machine operators": {
    "ageData": {
      "2014": {
        "total": "44",
        "16to19": "1",
        "20to24": "5",
        "25to34": "8",
        "35to44": "6",
        "45to54": "13",
        "55to64": "7",
        "65+": "4",
        "medianAge": "7"
      }
    },
    "title": "Dredge, excavating, and loading machine operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "44",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "30",
        "median": "-",
        "maleWorkers": "30",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Hoist and winch operators": {
    "ageData": {
      "2014": {
        "total": "7",
        "16to19": "0",
        "20to24": "2",
        "25to34": "1",
        "35to44": "1",
        "45to54": "2",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Hoist and winch operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Industrial truck and tractor operators": {
    "ageData": {
      "2014": {
        "total": "564",
        "16to19": "6",
        "20to24": "52",
        "25to34": "143",
        "35to44": "130",
        "45to54": "140",
        "55to64": "80",
        "65+": "12",
        "medianAge": "80"
      }
    },
    "title": "Industrial truck and tractor operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "564",
        "women": "7.4",
        "white": "24.4",
        "black": "2.2",
        "asian": "29.3"
      }
    },
    "wageData": {
      "2014": {
        "workers": "534",
        "median": "594",
        "maleWorkers": "497",
        "maleMedian": "595",
        "femaleWorkers": "38",
        "femaleMedian": "-"
      }
    }
  },
  "Cleaners of vehicles and equipment": {
    "ageData": {
      "2014": {
        "total": "375",
        "16to19": "50",
        "20to24": "83",
        "25to34": "85",
        "35to44": "58",
        "45to54": "53",
        "55to64": "35",
        "65+": "12",
        "medianAge": "35"
      }
    },
    "title": "Cleaners of vehicles and equipment",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "375",
        "women": "12.4",
        "white": "22.5",
        "black": "1.9",
        "asian": "33.4"
      }
    },
    "wageData": {
      "2014": {
        "workers": "255",
        "median": "482",
        "maleWorkers": "221",
        "maleMedian": "480",
        "femaleWorkers": "34",
        "femaleMedian": "-"
      }
    }
  },
  "Laborers and freight, stock, and material movers, hand": {
    "ageData": {
      "2014": {
        "total": "1,867",
        "16to19": "154",
        "20to24": "324",
        "25to34": "430",
        "35to44": "335",
        "45to54": "330",
        "55to64": "238",
        "65+": "55",
        "medianAge": "238"
      }
    },
    "title": "Laborers and freight, stock, and material movers, hand",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "1,867",
        "women": "18.3",
        "white": "15.6",
        "black": "3.2",
        "asian": "23.8"
      }
    },
    "wageData": {
      "2014": {
        "workers": "1,358",
        "median": "533",
        "maleWorkers": "1,138",
        "maleMedian": "546",
        "femaleWorkers": "221",
        "femaleMedian": "476"
      }
    }
  },
  "Machine feeders and offbearers": {
    "ageData": {
      "2014": {
        "total": "17",
        "16to19": "0",
        "20to24": "3",
        "25to34": "3",
        "35to44": "5",
        "45to54": "1",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Machine feeders and offbearers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "17",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "14",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Packers and packagers, hand": {
    "ageData": {
      "2014": {
        "total": "505",
        "16to19": "57",
        "20to24": "72",
        "25to34": "100",
        "35to44": "94",
        "45to54": "110",
        "55to64": "57",
        "65+": "16",
        "medianAge": "57"
      }
    },
    "title": "Packers and packagers, hand",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "505",
        "women": "53.8",
        "white": "18.2",
        "black": "5.9",
        "asian": "43.3"
      }
    },
    "wageData": {
      "2014": {
        "workers": "347",
        "median": "413",
        "maleWorkers": "140",
        "maleMedian": "459",
        "femaleWorkers": "207",
        "femaleMedian": "395"
      }
    }
  },
  "Pumping station operators": {
    "ageData": {
      "2014": {
        "total": "27",
        "16to19": "0",
        "20to24": "2",
        "25to34": "9",
        "35to44": "1",
        "45to54": "8",
        "55to64": "5",
        "65+": "1",
        "medianAge": "5"
      }
    },
    "title": "Pumping station operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "27",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "21",
        "median": "-",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Refuse and recyclable material collectors": {
    "ageData": {
      "2014": {
        "total": "84",
        "16to19": "2",
        "20to24": "8",
        "25to34": "20",
        "35to44": "17",
        "45to54": "21",
        "55to64": "10",
        "65+": "5",
        "medianAge": "10"
      }
    },
    "title": "Refuse and recyclable material collectors",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "84",
        "women": "8.6",
        "white": "19.0",
        "black": "0.8",
        "asian": "36.8"
      }
    },
    "wageData": {
      "2014": {
        "workers": "54",
        "median": "550",
        "maleWorkers": "51",
        "maleMedian": "564",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Mine shuttle car operators": {
    "ageData": {
      "2014": {
        "total": "1",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Mine shuttle car operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Tank car, truck, and ship loaders": {
    "ageData": {
      "2014": {
        "total": "6",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "0",
        "45to54": "2",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Tank car, truck, and ship loaders",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "6",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Material moving workers, all other": {
    "ageData": {
      "2014": {
        "total": "41",
        "16to19": "1",
        "20to24": "2",
        "25to34": "8",
        "35to44": "8",
        "45to54": "8",
        "55to64": "14",
        "65+": "1",
        "medianAge": "14"
      }
    },
    "title": "Material moving workers, all other",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2014": {
        "total": "41",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2014": {
        "workers": "39",
        "median": "-",
        "maleWorkers": "36",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  }
}