import React, { useEffect, useMemo, useRef, useState } from 'react'

import { Page } from '../../utilities/Page'

import { loadData } from './parseData'
import { css, cx } from '@emotion/css'

import './insight.min.css'

import bb, { bar, spline } from 'billboard.js'
import numeral from 'numeral'

import _ from 'lodash'
import Searcher from './Searcher'

export const WageEquality = () => {
  let occupations = useMemo(() => loadData(), [])

  const defaultOccupation = Object.values(occupations)[0]
  const [occupation, setOccupation] = useState(defaultOccupation)

  return (
    <>
      <Page>
        <Searcher occupations={occupations} setOccupation={setOccupation} />
        <PerJobFamily jobData={occupation} />
      </Page>
    </>
  )
}

function basicBarGraph (inputConfig) {
  return _.merge(
    {},
    basicGraph(),
    {
      data: {
        type: bar() // for ESM specify as: bar()
      },

      bar: {
        radius: 5,
        padding: 5,
        width: {
          ratio: 0.5
        }
      }
    },
    inputConfig
  )
}

function basicGraph (inputConfig) {
  return _.merge({}, {
    data: {
      x: 'x', // tells the chart that the column with the 'x' is the x axis.

      colors: {
        Men: '#00c73c',
        Women: '#fa7171',
        Median: '#2ad0ff',
        Total: '#e3e448'

        /*
          colors in "insight"
          #00c73c
          #fa7171
          #2ad0ff
          #7294ce
          #e3e448
          #cc7e6e
          #fb6ccf
          #c98dff
          #4aea99
          #bbbbbb
        */
      }
    }
  }, inputConfig)
}

function getAllKeys (data) {
  let keys = []
  Object.keys(data).forEach(key => {
    keys.push(key)
  })
  return keys
}

function getAllNumeralValues (data, property, mapFn) {
  let values = []
  Object.values(data).forEach(value => {
    let num = numeral(value[property]).value()
    if (mapFn) {
      num = mapFn(num, value)
    }
    values.push(num)
  })
  return values
}

export const PerJobFamily = ({ jobData }) => {
  return (
    <>
      {jobData.title}
      <Chart config={basicGraph({
        data: {
          type: spline(),
          columns: [
            ['x', ...getAllKeys(jobData.wageData)],
            ['Men', ...getAllNumeralValues(jobData.wageData, 'maleMedian')],
            ['Women', ...getAllNumeralValues(jobData.wageData, 'femaleMedian')],
            ['Median', ...getAllNumeralValues(jobData.wageData, 'median')]
          ],
          labels: {
            format (value) {
              if (!value && value !== 0) {
                return ''
              }
              return numeral(value).format('$0,0')
            }
          }
        },
        axis: {
          y: {
            label: 'Average weekly earnings',
            tick: {
              format (value) {
                return numeral(value).format('$0,0')
              }
            }
          },
          x: {
            type: 'category'
          }
        },
        tooltip: {
          format: {
            value (value, key, index) {
              return numeral(value).format('$0,0')
            }
          }
        }
      })}
      />

      <Chart
        config={() => {
          let keys = getAllKeys(jobData.sexRaceEthnicityData)
          let menData = []
          let menPercents = []
          let womenData = []
          let womenPercents = []
          let totalData = []

          Object.values(jobData.sexRaceEthnicityData).forEach(({ women, total }) => {
            if (women !== '-') {
              let totalNum = numeral(total).value()
              let womenPercent = numeral(women).value()
              let menPercent = 100 - womenPercent
              womenPercents.push(womenPercent)
              womenData.push((womenPercent / 100) * totalNum * 1000)

              menPercents.push(menPercent)
              menData.push((menPercent / 100) * totalNum * 1000)

              totalData.push(totalNum * 1000)
            } else {
              womenPercents.push(null)
              womenData.push(null)
              menPercents.push(null)
              menData.push(null)
              totalData.push(null)
            }
          })

          return basicGraph({
            data: {
              type: spline(),
              types: {
                Total: spline()
              },
              columns: [
                ['x', ...keys],
                ['Men', ...menPercents],
                ['Women', ...womenPercents]
              ],
              labels: {
                format (value, key, index) {
                  if (!value && value !== 0) {
                    return ''
                  }
                  if (key === 'Men') {
                    return `${Math.floor(menPercents[index])}%`
                  } else if (key === 'Women') {
                    return `${Math.floor(womenPercents[index])}%`
                  }
                }
              }
            },
            axis: {
              y: {
                label: 'Participation (percent)',
                tick: {
                  format (value) {
                    return numeral(value).format()
                  }
                },
                min: 0,
                max: 100
              },
              x: {
                type: 'category'
              }
            },
            tooltip: {
              format: {
                value (value, ratio, key, index) {
                  if (key === 'Men') {
                    return `${numeral(Math.floor(menData[index])).format()} (${menPercents[index]}%)`
                  } else if (key === 'Women') {
                    return `${numeral(Math.floor(womenData[index])).format()} (${womenPercents[index]}%)`
                  } else {
                    return numeral(value).format()
                  }
                }
              }
            }
          })
        }}
      />

    </>
  )
}

let increment = 0
const Chart = ({ config, ...htmlProps }) => {
  const chart = useRef()
  const chartId = useRef(`chart-${increment++}`)

  useEffect(() => {
    if (typeof config === 'function') {
      config = config()
    }

    chart.current = bb.generate({
      ...config,
      bindto: `#${chartId.current}`
    })
  }, [config])

  return (
    <div
      id={chartId.current}
      {...htmlProps}
      className={cx(css`
        width: 100%; 
        height: 500px;

        color: white;

        .bb-tooltip {
          border-radius: 8px;
        }

        .bb-tooltip-container {
          font-family: Source Code Pro Woff, Monaco, monospace;
        }

        .bb-tooltip {
          background: #1b1b1b;
        }

        .bb-tooltip td {
          background: none;
        } 

        .bb-tooltip td, .bb-tooltip th {
          padding: 8px 16px;
        }
        
        .bb-tooltip, .bb-tooltip td, .bb-tooltip th {
          border-color: #313131;
          font-size: 14px;
        }

      `, htmlProps.className || '')}
    />
  )
}
