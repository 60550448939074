import React, { useEffect, useRef, useState } from 'react'

import { Button, Card, Divider, Flex, Input, Modal, Popover, Select, Typography } from 'antd'
import { DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import { css } from '@emotion/css'
import { filterSetData, makeStateHook } from '../../utilities/stateHook'
import FileSaver from 'file-saver'
import _ from 'lodash'

const { confirm } = Modal

const [useTimeline, setTimeline, getTimeline, subscribeToTimeline] = makeStateHook({ scenes: [] })
const [useMetadata, setMetadata, getMetadata] = makeStateHook({
  highlightBars: [],
  characters: [],
  threads: [],
  tags: []
})
const [useSaveInfo, setSaveInfo] = makeStateHook({
  lastAutosavedAt: null,
})

// widths of the cards
const cardWidth = 400;

// height needed for the top cap
const topCapHeight = 64
const oppositeSideGap = 60
const sameSideGap = 60

// size of the pins on the bar
const pinSize = 24

// widths of the bar
const barWidth = 4
const barWidthHighlight = 10

// total width of the center gutter
const gutterWidth = (pinSize * 1.5) - 4;

const lineColor = 'white'

function loadInputData(data) {
  let parsedData = JSON.parse(data)
  let characterSet = new Set()
  let tagSet = new Set()
  let threadsSet = new Set()

  parsedData.scenes.forEach(scene => {
    scene.characters.forEach(character => {
      characterSet.add(character)
    })

    scene.tags.forEach(tag => {
      tagSet.add(tag)
    })

    scene.threads.forEach(threads => {
      threadsSet.add(threads)
    })
  })

  setTimeline(parsedData)
  setMetadata({
    highlightBars: [],
    characters: Array.from(characterSet),
    threads: Array.from(threadsSet),
    tags: Array.from(tagSet)
  })

  window.localStorage.setItem('timeline-save-hash', hashSave(data))
}

function arrayToOptions(array) {
  return array.map(item => ({ label: item, value: item }))
}

function hashSave(data) {
  return JSON.stringify(data)
}

function getHashedSave() {
  return window.localStorage.getItem('timeline-save-hash')
}

function saveFile() {
  let saveData = JSON.stringify(getTimeline())
  let blob = new window.Blob([saveData], { type: 'text/json;charset=utf-8' })
  FileSaver.saveAs(blob, getTimeline().title + '.json')

  window.localStorage.setItem('timeline-save-hash', hashSave(saveData))
}

function newTimeline() {
  unsavedChanges(() => {
    setTimeline({ scenes: [] })
    setMetadata({
      highlightBars: [],
      characters: [],
      threads: [],
      tags: []
    })
  })
}

function unsavedChanges(fn) {
  let saveData = JSON.stringify(getTimeline())

  let hasUnsavedChanges = getHashedSave() !== hashSave(saveData)
  let hasAnyData = getTimeline().scenes.length > 0

  if (hasUnsavedChanges && hasAnyData) {
    let modal = confirm({
      title: 'Unsaved Changes!',
      content: `Warning! You have unsaved changes on the current timeline. Would you like to save this timeline to a file before proceeding?`,
      icon: <ExclamationCircleFilled />,
      okText: 'Save',
      cancelText: 'Cancel',
      onOk() {
        saveFile()
        fn()
      },
      footer(node, { OkBtn, CancelBtn }) {
        return <>
          <CancelBtn />
          <Button danger onClick={() => {
            modal.destroy()
            fn()
          }}>Don't Save</Button>
          <OkBtn />
        </>
      }
    })
  } else {
    fn()
  }
}

function autosave() {
  window.localStorage.setItem('timeline', JSON.stringify(getTimeline()))
  setSaveInfo(saveInfo => ({ ...saveInfo, lastAutosavedAt: new Date() }))
}

export const TimelinePage = () => {
  const [timeline, setTimeline] = useTimeline()
  const scenes = timeline.scenes
  const setScenes = filterSetData(setTimeline, 'scenes')

  const [metadata, setMetadata] = useMetadata()

  const [filter, setFilter] = useState({
    characters: [],
    tags: [],
    threads: []
  })

  const inputFileRef = useRef(null)

  const [saveInfo] = useSaveInfo()

  useEffect(() => {
    let unsubscribeTimeline = subscribeToTimeline(_.debounce(autosave, 300))

    if (window.localStorage.getItem('timeline')) {
      try {
        loadInputData(window.localStorage.getItem('timeline'))
      } catch (e) {
        console.error('Cannot load file from storage', e)
      }
    }
    return () => {
      unsubscribeTimeline()
    }
  }, [])

  return (
    <>
      <Flex gap={20}>
        <Flex vertical>
          <strong className={css`display: inline-block; min-width: 150px;`}>Title</strong>
          <Input
            value={timeline.title}
            onChange={({ target }) => {
              setTimeline(timeline => {
                timeline.title = target.value
                return timeline
              })
            }}
          />
        </Flex>

        <Flex vertical justify='flex-end'>
          <Button onClick={saveFile}>Save file</Button>
        </Flex>

        <Flex vertical justify='flex-end'>
          <Button onClick={() => {
            unsavedChanges(() => {
              inputFileRef.current?.click()
            })
          }}
          >
            Load file
          </Button>
          <input
            ref={inputFileRef}
            type='file'
            className={css`
              display: none;
            `}
            onChange={(event) => {
              const reader = new window.FileReader()
              reader.onload = (event) => {
                loadInputData(event.target.result)
              }
              reader.readAsText(event.target.files[0])
              event.target.value = ''
            }}
          />
        </Flex>

        <Flex vertical justify='flex-end'>
          <Button onClick={newTimeline}>New timeline</Button>
        </Flex>

        <Flex>
          <Divider
            type='vertical'
            className={css`
              height: 100%;
            `}
          />
        </Flex>

        <Flex vertical>
          <strong className={css`display: inline-block; min-width: 150px;`}>Threads</strong>
          <Select
            options={arrayToOptions(metadata.threads)}
            mode='multiple'
            popupMatchSelectWidth={false}
            placeholder='All threads'
            onChange={(values) => setFilter((state) => {
              let nextFilter = { ...state }
              nextFilter.threads = values
              return nextFilter
            })}
          />
        </Flex>
        <Flex vertical>
          <strong className={css`display: inline-block; min-width: 150px;`}>Tags</strong>
          <Select
            options={arrayToOptions(metadata.tags)}
            mode='multiple'
            popupMatchSelectWidth={false}
            placeholder='All tags'
            onChange={(values) => setFilter((state) => {
              let nextFilter = { ...state }
              nextFilter.tags = values
              return nextFilter
            })}
          />
        </Flex>
        <Flex vertical>
          <strong className={css`display: inline-block; min-width: 150px;`}>Characters</strong>
          <Select
            options={arrayToOptions(metadata.characters)}
            mode='multiple'
            popupMatchSelectWidth={false}
            placeholder='All characters'
            onChange={(values) => setFilter((state) => {
              let nextFilter = { ...state }
              nextFilter.characters = values
              return nextFilter
            })}
          />
        </Flex>
      </Flex>
      <Flex className={css`margin-top: 5px;`}>
        <Typography.Text type='secondary'>{saveInfo.lastAutosavedAt ? `Last autosaved at: ${saveInfo.lastAutosavedAt}` : <>&nbsp;</>}</Typography.Text>
      </Flex>
      <br />
      <Timeline
        scenes={scenes}
        setScenes={setScenes}
        metadata={metadata}
        filter={filter}
      />
    </>
  )
}

function getElementHeights(elements) {
  let yCoordOnLeft = topCapHeight
  let yCoordOnRight = topCapHeight

  let positions = []

  elements.forEach((node, index) => {
    positions[index] = {}

    // not sure why this happens, but it does
    if (!node) {
      return
    }

    let isLast = (elements.length - 1) === index

    if (yCoordOnLeft <= yCoordOnRight) {
      positions[index].nodeSide = 'left'

      // store top so we can use it to calculate height after we've figured out the new top
      let nodeTop = yCoordOnLeft
      positions[index].nodeTop = nodeTop

      // before setting the new left top, make sure we position the right top appropriately
      if (yCoordOnRight - yCoordOnLeft < oppositeSideGap) {
        yCoordOnRight = yCoordOnLeft + oppositeSideGap
      }

      // then update leftTop with the appropriate height
      yCoordOnLeft += node.offsetHeight + sameSideGap

      // finally configure the height
      let nextPanelCoord = Math.min(yCoordOnLeft, yCoordOnRight)
      if (isLast) {
        nextPanelCoord = Math.max(yCoordOnLeft, yCoordOnRight)
      }

      let height = nextPanelCoord - nodeTop
      positions[index].barHeight = height

      positions[index].nodeBottom = nodeTop + height
    } else {
      positions[index].nodeSide = 'right'

      // store top so we can use it to calculate height after we've figured out the new top
      let nodeTop = yCoordOnRight
      positions[index].nodeTop = nodeTop

      // before setting the new left top, make sure we position the right top appropriately
      if (yCoordOnLeft - yCoordOnRight < oppositeSideGap) {
        yCoordOnLeft = yCoordOnRight + oppositeSideGap
      }

      // then update leftTop with the appropriate height
      yCoordOnRight += node.offsetHeight + sameSideGap

      // finally configure the height
      let nextPanelCoord = Math.min(yCoordOnLeft, yCoordOnRight)
      if (isLast) {
        nextPanelCoord = Math.max(yCoordOnLeft, yCoordOnRight)
      }

      let height = nextPanelCoord - nodeTop
      positions[index].barHeight = height

      positions[index].nodeBottom = nodeTop + height
    }
  })

  return positions
}

const Timeline = ({ scenes, setScenes, metadata, filter }) => {
  const sceneNodes = useRef([])
  const [positions, setPositions] = useState([])

  sceneNodes.current = []

  useEffect(() => {
    let newPositions = getElementHeights(sceneNodes.current)

    if (JSON.stringify(positions) !== JSON.stringify(newPositions)) {
      setPositions(newPositions)
    }
  })

  return (
    <div className={css`
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    `}
    >
      <div className={css`
        position: relative;
      `}>
        <Bar cap='top' insertAt={0} highlight={metadata.highlightBars.includes(0)} setScenes={setScenes} pin={false} filter={filter} />
        {scenes.map((scene, index) => {
          let hasCharacter = (filter.characters.length) ? Boolean(filter.characters.find(character => scene.characters.includes(character))) : true
          let hasThread = (filter.threads.length) ? Boolean(filter.threads.find(thread => scene.threads.includes(thread))) : true
          let hasTag = (filter.tags.length) ? Boolean(filter.tags.find(tag => scene.tags.includes(tag))) : true

          const shouldRender = hasCharacter && hasThread && hasTag

          if (!shouldRender) {
            return null
          }

          return (
            <React.Fragment
              key={scene.createdAt}
            >
              <Event
                nodes={sceneNodes}
                positions={positions}
                summary={scene.summary}
                characters={scene.characters}
                tags={scene.tags}
                threads={scene.threads}
                index={index}
                setScenes={setScenes}
                metadata={metadata}
                filter={filter}
                isLast={index === scenes.length - 1}
              />
            </React.Fragment>
          )
        })}
      </div>
    </div>
  )
}

function dragElement(element, positions, index, setTransformCoords, setScenes) {
  return event => {
    if (event.target === element.current) {
      let startingClientX = event.clientX
      let startingClientY = event.clientY

      let startingScrollX = window.scrollX
      let startingScrollY = window.scrollY

      event.preventDefault()

      let lastClientX = event.clientX
      let lastClientY = event.clientY

      let insertIndex

      function move(event) {
        lastClientX = event.clientX ?? lastClientX
        lastClientY = event.clientY ?? lastClientY

        let diffX = lastClientX - startingClientX + (startingScrollX - window.scrollX)
        let diffY = lastClientY - startingClientY - (startingScrollY - window.scrollY)

        let currentNodeTop = positions[index].nodeTop + diffY

        insertIndex = positions.findIndex((position, thisIndex) => {
          return currentNodeTop < position.nodeTop && thisIndex !== index
        })

        if (insertIndex === -1) {
          insertIndex = positions.length
        }

        if (insertIndex === index + 1) {
          setMetadata('highlightBars', [index, insertIndex])
        } else {
          setMetadata('highlightBars', [insertIndex])
        }

        setTransformCoords([diffX, diffY])
      }

      function up() {
        setMetadata('highlightBars', [])

        if (insertIndex !== undefined && insertIndex !== index + 1) {
          // This is a bit complex. We want to move our current item to a new position, but we need to remove it first. So there's a bunch of index hacking in here.
          setScenes(scenes => {
            let element = scenes[index]
            scenes.splice(index, 1)

            let normalizedInsertIndex = insertIndex

            // -1 means that we didn't find a next index, so we want to insert it at the end
            if (insertIndex === -1) {
              normalizedInsertIndex = scenes.length
            } else if (index < insertIndex) {
              // If our current index is less than our insertIndex, it means that when we remove the element (a few lines earlier), all the later indexes got shifted, so we have to shift our insert index too.
              normalizedInsertIndex = Math.max(insertIndex - 1, 0)
            }

            scenes.splice(normalizedInsertIndex, 0, element)

            return scenes
          })
        } else {
          // if we didn't move, just reset the coordinates (we don't do so in the other case, b/c there's a useEffect that handles the state transition more reliably in that case)
          setTransformCoords([0, 0])
        }

        document.removeEventListener('mousemove', move)
        document.removeEventListener('scroll', move)
        document.removeEventListener('mouseup', up)
      }

      document.addEventListener('mousemove', move)
      document.addEventListener('scroll', move)
      document.addEventListener('mouseup', up)
    }
  }
}

const Event = ({ nodes, positions, summary, characters, tags, threads, index, setScenes, metadata, isLast, filter } = {}) => {
  let position = positions[index]
  let side = position?.nodeSide ?? 'left'
  let height = position?.barHeight ?? 0

  let thisNode = useRef(null)
  let [transformCoords, setTransformCoords] = useState([0, 0])
  let [baseCoords, setBaseCords] = useState([0, 0])

  let xBase = side === 'right' ? gutterWidth : -cardWidth
  let yBase = -(pinSize / 2) + position?.nodeTop

  // This effect makes sure that there's at least one frame of rendering with a reorder WITHOUT a coordinate change. That allows the transition effect to work.
  useEffect(() => {
    setBaseCords([xBase, yBase])

    // Also, any time the base coords change (which only happens during re-ordering, clear the transform coords.)
    setTransformCoords([0, 0])
  }, [xBase, yBase])

  const dragger = (element) => dragElement(element, positions, index, setTransformCoords, setScenes)

  return (
    <div
      className={css`
        display: flex;
      `}
    >
      <Bar height={height} insertAt={index + 1} highlight={metadata.highlightBars.includes(index + 1)} setScenes={setScenes} cap={isLast ? 'bottom' : 'none'} pinY={transformCoords[1]} side={side} dragger={dragger} filter={filter} />
      <Card
        ref={node => {
          nodes.current[index] = node
          thisNode.current = node
        }}
        style={{
          transform: `translate(${transformCoords[0] + baseCoords[0]}px, ${transformCoords[1] + baseCoords[1]}px)`,
        }}
        className={css`
          position: absolute;
          top: 0;
          left: 0;

          margin-top: 20px;

          transition: ${(transformCoords[0] !== 0 || transformCoords[1] !== 0) ? 'none' : 'transform .3s'};
          ${(transformCoords[0] !== 0 || transformCoords[1] !== 0) ? css`z-index: 100;` : ''}

          width: ${cardWidth}px;
          padding: 12px;

          cursor: all-scroll;

          & .ant-card-body {
            padding: 0px;
            cursor: initial;
          }

          &:hover button {
            display: block;
          }`
        }
        onMouseDown={dragger(thisNode)}
      >
        <Input.TextArea
          autoSize
          value={summary}
          bordered={false}
          className={css`
            padding: 0;
          `}
          placeholder='Describe the scene...'
          onChange={(event) => {
            setScenes(index, (scene) => {
              return {
                ...scene,
                summary: event.target.value
              }
            })
          }}
        />

        <Popover
          content={<EditSceneDetails metadata={metadata} threads={threads} characters={characters} tags={tags} setScenes={setScenes} index={index} />}
          rootClassName={css`
            min-width: 400px;
          `}
          placement='bottom'
          trigger='click'
        >
          <Button type='text' size='small' className={css`
            width: 100%;
            text-align: left;
            height: auto !important;
          `}>
            <Typography.Text
              type='secondary'
              className={css`
              width: 100%;
              display: inline-block;
              margin-bottom: 0;
              font-size: 10px;
            `}
            >

              {!characters.length && !tags.length && !threads.length && <>Add scene details...</>}
              {!!characters.length && <p className={css`display: block; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;`}>Characters: {characters?.join(', ')}</p>}
              {!!tags.length && <p className={css`display: block; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; `}>Tags: {tags?.map(item => `#${item}`).join(', ')}</p>}
              {!!threads.length && <p className={css`display: block; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; `}>Threads: {threads?.map(item => `"${item}"`).join(', ')}</p>}
            </Typography.Text>
          </Button>
        </Popover>

        {/* Delete button must come after TextArea so that it's always "above" it. */}
        <Button
          icon={<DeleteOutlined />}
          shape='circle'
          type='text'
          size='small'
          className={css`
            position: absolute;
            display: none;
            top: 0;
            right: 0;
            margin-right: 4px;
            margin-top: 4px;
          `}
          onClick={() => confirm({
            title: 'Are you sure you want to delete this scene?',
            icon: <ExclamationCircleFilled />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
              setScenes(scenes => {
                scenes.splice(index, 1)
                return scenes
              })
            }
          })}
        />
      </Card>
    </div>
  )
}

const EditSceneDetails = ({ metadata, threads, characters, tags, setScenes, index }) => {
  function setSelect(key, values) {
    setMetadata(key, metadataItem => {
      let metadataSet = new Set(metadataItem)
      values.forEach(entry => {
        metadataSet.add(entry)
      })
      return Array.from(metadataSet)
    })
    setScenes(index, (scene) => {
      return {
        ...scene,
        [key]: values
      }
    })
  }

  return (
    <>

      <div>
        <strong className={css`display: inline-block; margin-top: 10px;`}>Thread</strong>
        <Select
          mode='tags'
          bordered={false}
          className={css`
            width: 100%;
            & .ant-select-selector {
              padding: 0;
            }
          `}
          placeholder='Which thread is this scene a part of?'
          options={metadata.threads.map(thread => ({ label: thread, value: thread }))}
          value={threads}
          onChange={(values) => setSelect('threads', values)}
        />
      </div>

      <div>
        <strong className={css`display: inline-block; margin-top: 4px;`}>Characters</strong>
        <Select
          mode='tags'
          bordered={false}
          className={css`
            width: 100%;
            & .ant-select-selector {
              padding: 0;
            }
          `}
          placeholder='Characters involved in scene'
          options={metadata.characters.map(character => ({ label: character, value: character }))}
          value={characters}
          onChange={(values) => setSelect('characters', values)}
        />
      </div>

      <div>

        <strong className={css`display: inline-block; margin-top: 4px;`}>Tags</strong>
        <Select
          mode='tags'
          bordered={false}
          className={css`
            width: 100%;
            & .ant-select-selector {
              padding: 0;
            }
          `}
          placeholder='Free-form tags'
          options={metadata.tags.map(tag => ({ label: tag, value: tag }))}
          value={tags.map(tag => {
            if (typeof tag !== 'string') {
              return tag[0] + ':' + tag[1]
            }
            return tag
          })}
          onChange={(values) => setSelect('tags', values)}
        />
      </div>
    </>
  )
}

const Bar = ({ height = topCapHeight, setScenes, highlight, insertAt, cap = 'none', pin = true, side, pinY = 0, dragger, filter }) => {
  const negativeTop = pinSize / 2
  const internalBarWidth = highlight ? barWidthHighlight : barWidth
  const containerRef = useRef(null)
  const pipeRef = useRef(null)
  const [createDotPosition, setCreateDotPosition] = useState(0)

  return (
    <div
      ref={containerRef}
      className={css`
        position: relative;
      `}
      onMouseMove={event => {
        let rect = containerRef.current.getBoundingClientRect()
        let y = (event.clientY - rect.top) - (pinSize / 2) // y position within the element.

        let buffer = 5

        let topBoundary = cap === 'top' ? 0 : pinSize - negativeTop + buffer
        let bottomBoundary = cap === 'bottom' ? rect.height - pinSize : rect.height - (pinSize * 1.5) - buffer

        if (y < topBoundary) {
          y = topBoundary
        } else if (y > bottomBoundary) {
          y = bottomBoundary
        }

        setCreateDotPosition(parseInt(y))
      }}
    >
      {pin && (<>
        <div
          style={{
            transform: `translateY(${pinY}px)`
          }}
          className={css`
            display: flex;
            justify-content: center;
            
            width: ${pinSize}px;
            height: ${pinSize}px;

            background-color: ${lineColor};
            border-radius: 100%;

            color: black;
            font-family: Arial;
            font-weight: bold;

            transition: margin-left .3s;

            position: absolute;
            top: -${negativeTop}px;
            left: ${gutterWidth / 2 - pinSize / 2}px;

            ${(pinY[0] !== 0) ? css`z-index: 100;` : ''};
          `}
        >
          {insertAt}

          <div
            ref={pipeRef}
            className={css`
              position: absolute;
              left: 0;
            
              width: ${cardWidth}px;
              margin-left: ${side === 'left' ? -cardWidth : pinSize}px;
          
              height: 100%;
              display: flex;
              align-items: center;
          
              cursor: all-scroll;
            `}
            onMouseDown={dragger(pipeRef)}
          >
            <div className={css`
              height: 2px;
              width: calc(${cardWidth}px + ${gutterWidth / 2}px);
              pointer-events: none;

              background: ${lineColor};
              transition: margin-left 1s;
            `} />
          </div>
        </div>
      </>
      )}

      <div className={css`
        &:hover>div>div {
          display: flex;
        }`}
      >
        <div className={css`
          background: ${lineColor};
        
          width: ${internalBarWidth}px;
          height: ${height}px;
        
          transition: all .3s;

          ${cap !== 'none' ? css`
          border-${cap}-left-radius: 50px;
          border-${cap}-right-radius: 50px;
          ` : ''}

          margin: 0px ${(gutterWidth / 2) - (internalBarWidth / 2)}px;
        `}
        >
          <div
            style={{
              top: `${createDotPosition}px`
            }}
            className={css`
              display: none;
              position: absolute;
              width: ${pinSize}px;
              height: ${pinSize}px;
              background-color: ${lineColor};
              border-radius: 100%;
              margin-left: -${(pinSize / 2) - (internalBarWidth / 2)}px;

              justify-content: center;
              color: black;
              font-family: Arial;
              font-size: 25px;

              cursor: pointer;
            `}
            onClick={event => {
              setScenes(scenes => {
                scenes.splice(insertAt, 0, {
                  createdAt: new Date(),
                  summary: '',
                  characters: filter?.characters ?? [],
                  tags: filter?.tags ?? [],
                  threads: filter?.threads ?? []
                })
                return scenes
              })
            }}
          >
            +
          </div>
        </div>
      </div>

    </div>

  )
}
