import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

export function useQueryState (paramKey, defaultValue = '') {
  const [searchParams, setSearchParams] = useSearchParams()
  const [paramValue, setParamValue] = useState(searchParams.get(paramKey) || defaultValue)

  function setParam (value) {
    setParamValue(value)

    let searchParamsNow = new URLSearchParams(window.location.search)
    searchParamsNow.set(paramKey, value)
    setSearchParams(searchParamsNow)
  }

  // initialize, if no query string
  useEffect(() => {
    let searchParamsNow = new URLSearchParams(window.location.search)
    if (!searchParamsNow.has(paramKey)) {
      searchParamsNow.set(paramKey, defaultValue)
      setSearchParams(searchParamsNow)
    }
  })

  // each time querystring changes...
  useEffect(() => {
    let searchParamsNow = new URLSearchParams(window.location.search)
    if (searchParamsNow.get(paramKey) && paramValue !== searchParamsNow.get(paramKey)) {
      setParamValue(searchParamsNow.get(paramKey))
    }
  }, [searchParams.get(paramKey)])

  return [paramValue, setParam]
}

// how do we handle cross-page params?
// useGlobalQueryState() ?
