import { useEffect, useReducer, useState } from 'react'

export function random (max, min = 1) {
  // the + 1 is b/c Math.random() generates a number from 0 to 1 including 0 but not including 1
  // if we want a random number between 0 and 20, we generally want to include both 0 and 20
  return Math.floor(Math.random() * (Number(max) + 1 - Number(min))) + Number(min)
}

export function round (number, decimal = 0) {
  const multiple = Number('1' + new Array(decimal).fill('0').join(''))
  return Math.round(number * multiple) / multiple
}

export function useResizeRerender () {
  const [, reload] = useReducer(c => (c + 1), 0)
  useEffect(() => {
    window.addEventListener('resize', reload)

    return () => window.removeEventListener('resize', reload)
  })
}

export function useInputState (defaultValue) {
  return convertToInputState(useState(defaultValue))
}

export function convertToInputState (stateHook) {
  const [state, setState] = stateHook

  return {
    state,
    value: state,
    set: setState,
    setState,
    setValue: setState,
    input: {
      onChange: ({ target }) => setState(target.value),
      value: state
    },
    checkbox: {
      onChange: ({ target }) => setState(target.checked),
      checked: state
    },
    antd: {
      select: {
        onChange: (value) => setState(value),
        value: state
      }
    }
  }
}
