import { useReducer, useLayoutEffect } from 'react'
import _ from 'lodash'

// type Data = any
// so, essentially this is a pub/sub store
// you make a new dataHook like this:
// const [useState, setState, getState, subscribeToState] = makeStateHook(imageLibrary)
// useState subscribes Functional Components to the state object
// setState is how you update state
// getState is a non-subscription method for getting state
// subscribeToState lets you run an arbitrary function when state changes; it returns an unsubscribe function
export function makeStateHook (
  defaultData,
  saveSideEffect
) {
  let subscriberId = 0
  const subscribers = {}

  const internal = {
    data: defaultData
  }

  // make sure data is defaulted to an object
  // doing it here so that we can ignore typescript's BS in an isolated spot
  // @ts-ignore - seriously, buzz off TS
  if (defaultData === undefined) internal.data = {}

  // iterate through the subscribers and force updates on all of them
  // pass them the unsubscribe function in case they want to stop listening dynamically
  function updateSubscribers () {
    Object.values(subscribers).forEach((subscriber) => {
      if (subscriber.path) {
        let currentData = _.get(internal.data, subscriber.path)
        if (subscriber.previousData !== currentData) {
          subscriber.previousData = currentData
          subscriber.update(_.cloneDeep(currentData), subscriber.unsubscribe)
        }
      } else {
        subscriber.update(_.cloneDeep(internal.data), subscriber.unsubscribe)
      }
    })
  }

  /**
   *
   * Subscribe a react component to data changes.
   *
   * @param path An optional path to filter the data being returned and set by the setter
   *
   * @example
   * // given `{ foo: { bar: 'baz' } }`
   * const [state, setState] = useData() // state === { foo: { bar: 'baz' } }
   *
   * @example
   * // given `{ foo: { bar: 'baz' } }`
   * const [state, setState] = useData('foo.bar') // state === 'baz'
   * setState(oldState => newState) // oldState === 'baz'
   *
   * @returns
   */
  function useData (path) {
    const [, forceUpdate] = useReducer((c) => (c > 100 ? 0 : c + 1), 0)

    // register this component's forceUpdate as a subscriber
    // subscribers get triggered by "updateSubscribers"
    // we're using a `useLayoutEffect` because `useEffect` happens too late in some circumstances
    useLayoutEffect(() => {
      return subscribeToData(() => forceUpdate(), path)
    }, [forceUpdate])

    if (path) {
      return [
        _.get(internal.data, path),
        function (
          pathOrDataOrDataFn,
          dataOrFn
        ) {
          if (arguments.length === 2) {
            setData(
              _.toPath(path).concat(_.toPath(pathOrDataOrDataFn)),
              dataOrFn
            )
          } else if (arguments.length === 1) {
            setData(path, pathOrDataOrDataFn)
          }
        }
      ]
    } else {
      return [internal.data, setData]
    }
  }

  /**
   *
   * Sets the new value of the data, then updates all subscribers.
   *
   * @param pathOrDataOrFn
   * @param dataOrFn
   *
   * @example
   * setData(newState) // overwrite the entire state
   * setData((oldState) => newState) // update the entire state with a function
   * setData('path.to', newState) // overwrite (or set) the state at 'path.to'
   * setData('path.to', (oldState) => newState) // overwrite (or set) the state at 'path.to' with a function
   */
  function setData (
    pathOrDataOrFn,
    dataOrFn
  ) {
    if (arguments.length === 2) {
      // two arguments means that the first argument is a lodash-style Path and the second is either new data or an updater function
      let path = pathOrDataOrFn

      let nextData = _.cloneDeep(internal.data) // prepare for new data

      let newData =
        typeof dataOrFn === 'function'
          ? (dataOrFn)(_.get(nextData, path)) // either run the updater to get new data
          : dataOrFn // or, if it's not an updater, use it as new data

      _.set(nextData ?? {}, path, newData) // set it

      internal.data = nextData
    } else if (arguments.length === 1) {
      // one argument means that the first argument is either new data or an updater function

      internal.data =
        typeof pathOrDataOrFn === 'function'
          ? (pathOrDataOrFn)(
            _.cloneDeep(internal.data)
          ) // either run the updater to get new data
          : (pathOrDataOrFn) // or, if it's not an updater, use it as new data
    }

    if (saveSideEffect) {
      internal.data = saveSideEffect(internal.data)
    }

    updateSubscribers()
  }

  /**
   *
   * Gets the data. (Suprise!) If provided, path filters it.
   *
   * @param path A lodash-style path that filters the returned value.    *
   * @example
   * // state = { foo: { bar: 'baz' } }
   * getData() // returns `{ foo: { bar: 'baz' } }`
   * getData('foo.bar') // return `baz`
   * @returns
   */
  function getData (path) {
    if (path) {
      return _.get(internal.data, path)
    } else {
      return internal.data
    }
  }

  /**
   *
   * Subscribe to data changes by providing a function to be fired when a change occurs.
   *
   * @param triggerOnChange The function to run on change.
   * @param path An optional path to filter the data provided to the subscriber.
   *
   * @returns returns a function that should be run when you want to unsubscribe
   */
  function subscribeToData (triggerOnChange, path) {
    const mySubscriberId = subscriberId
    subscriberId += 1

    /**
     * Unsubscribe to data changes.
     */
    function unsubscribe () {
      delete subscribers[mySubscriberId]
    }
    subscribers[mySubscriberId] = {
      update: triggerOnChange,
      unsubscribe,
      path,
      previousData: path ? _.get(internal.data, path) : undefined
    }

    // returning the unsubscribe fn to allow subscribers to store it for later use,
    // and it makes the useEffect (in useData) unsubscribe automatically on unmount
    return unsubscribe
  }

  let returnStatement = [useData, setData, getData, subscribeToData]

  return returnStatement
}

export function filterSetData (
  setData,
  basePath
) {
  return function (
    pathOrDataOrDataFn,
    dataOrFn
  ) {
    if (arguments.length === 2) {
      setData(
        _.toPath(basePath).concat(_.toPath(pathOrDataOrDataFn)),
        dataOrFn
      )
    } else if (arguments.length === 1) {
      setData(basePath, pathOrDataOrDataFn)
    }
  }
}
