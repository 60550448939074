import { useRef } from 'react'

export function useChange (fn, deps) {
  const previousDeps = useRef([])
  const firstRun = useRef(true)
  const previousValue = useRef()

  let shouldRun = false
  if (firstRun.current === true) {
    // on first run, always run
    firstRun.current = false
    shouldRun = true
  } else {
    // on second+ runs, check deps
    let length = Math.max(deps.length, previousDeps.current.length)
    let isEqual = true

    for (let index = 0; index < length; index++) {
      if (previousDeps.current[index] !== deps[index]) isEqual = false
    }

    shouldRun = !isEqual
  }

  if (shouldRun) {
    previousValue.current = fn(previousValue.current)
  }

  previousDeps.current = deps

  return previousValue.current
}
