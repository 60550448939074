import _ from 'lodash'

/**
 * A helper function that lets you pull parts of useState hooks off and modify them individually. Uses lodash-style paths (and lodash) to accomplish this. Returns a useState-like format ( `[state, setState]` )
 */
export function filterState (stateHook, path) {
  let value = _.get(stateHook[0], path)
  let setValue = (value) =>
    stateHook[1]((state) => {
      let nextState = _.cloneDeep(state)
      if (typeof value === 'function') {
        return _.set(nextState, path, value(_.get(nextState, path)))
      } else {
        return _.set(nextState, path, value)
      }
    })
  return [value, setValue]
}
