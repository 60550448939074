import numeral from 'numeral'
import _ from 'lodash'

// data sources (main page)               https://www.bls.gov/cps/tables.htm

// yearly data (replace aa2019 with desired year)
// - wage by gender data:                 https://www.bls.gov/cps/aa2019/cpsaat39.htm
// - occupation by age data:              https://www.bls.gov/cps/aa2019/cpsaat11b.htm
// - occupation by sex, race, ethnicity:  https://www.bls.gov/cps/aa2019/cpsaat11.htm

import _2019 from './years/2019.json'
import _2018 from './years/2018.json'
import _2017 from './years/2017.json'
import _2016 from './years/2016.json'
import _2015 from './years/2015.json'
import _2014 from './years/2014.json'
import _2013 from './years/2013.json'
import _2012 from './years/2012.json'
import _2011 from './years/2011.json'

export function loadData () {
  let data = {}

  _.mergeWith(data, _2018, _2019, _2017, _2016, _2015, _2014, _2013, _2012, _2011,
    (objValue, srcValue, key, object, source, stack) => {
      if (objValue && srcValue && objValue !== srcValue && typeof objValue !== 'object' && typeof srcValue !== 'object') {
        console.warn({ objValue, srcValue, key, object, source })
      }
    })

  // mark jobs as "complete", "incomplete", or "nodata"
  // record number of children
  Object.values(data).forEach(job => {
    setJobRelations(job, data)
    setJobDataCompleteness(job)
    setJobDomination(job)
  })

  function findSubJobs (job) {
    job.subcategories = 0
    job.occupations = 0
    job.occupationsWithNoData = 0
    job.occupationsWithMissingData = 0

    Object.keys(job.children).forEach(childJob => {
      let jobData = data[childJob]
      let [subcategories, occupations, noData, missingData] = findSubJobs(jobData)

      if (occupations) {
        job.subcategories++
      } else {
        job.occupations++
        if (jobData.noData) {
          job.occupationsWithNoData++
        }
        if (jobData.missingData) {
          job.occupationsWithMissingData++
        }
      }

      job.subcategories += subcategories
      job.occupations += occupations
      job.occupationsWithNoData += noData
      job.occupationsWithMissingData += missingData
    })

    return [job.subcategories, job.occupations, job.occupationsWithNoData, job.occupationsWithMissingData]
  }

  Object.entries(data).forEach(([parent, job]) => {
    if (!job.parent) {
      findSubJobs(job)
    }
  })

  return data
}

function setJobRelations (job, data) {
  if (!job.children) {
    job.children = {}
  }

  if (job.parent) {
    job.parent = data[job.parent]

    if (!job.parent.children) {
      job.parent.children = {}
    }
    job.parent.children[job.title] = job
  }
}

function setJobDataCompleteness (job) {
  let maleWageCount = 0
  let femaleWageCount = 0
  let totalWageCount = 0
  let totalMedianCount = 0
  Object.values(job.wageData).forEach(wageData => {
    totalWageCount++
    if (wageData.median !== '-') totalMedianCount++
    if (wageData.femaleMedian !== '-') femaleWageCount++
    if (wageData.maleMedian !== '-') maleWageCount++
  })

  job.noData = totalMedianCount === 0
  job.missingData = !job.noData && (femaleWageCount !== totalWageCount || maleWageCount !== totalWageCount)

  return job
}

function sum (...numbers) {
  return numbers.reduce((total, num) => {
    return total + num
  }, 0)
}

function avg (...numbers) {
  return sum(...numbers) / numbers.length
}

function confidence (score) {
  switch (Math.floor(score)) {
  case 0: return 'very low'
  case 1: return 'very low'
  case 2: return 'low'
  case 3: return 'medium-low'
  case 4: return 'medium'
  case 6: return 'medium'
  case 7: return 'medium-high'
  case 8: return 'medium-high'
  default: return 'high'
  }
}

function setJobDomination (job) {
  if (!job.noData) {
    let dominationPerYear = []
    let participationPerYear = []

    Object.entries(job.sexRaceEthnicityData).forEach(([year, data]) => {
      if (data.women !== '-') {
        let total = numeral(data.total).value()
        participationPerYear.push(total * 1000)

        let womenPercent = numeral(data.women).value()
        let menPercent = 100 - womenPercent
        let yearlyDomination = menPercent - womenPercent

        dominationPerYear.push(yearlyDomination)
      }
    })
    // for this, let's decide that "gender dominated" means a lead of +20 points on average
    let dominationAverage = avg(...dominationPerYear)
    let averageParticipation = avg(...participationPerYear)

    // the raw data
    job.dominationPerYear = dominationPerYear
    job.dominationAverage = dominationAverage
    job.confidenceValue = Math.floor((averageParticipation / 50000))
    job.confidenceScore = confidence(job.confidenceValue)

    let minIsFemale = Math.min(...dominationPerYear) < 0
    let maxIsMale = Math.max(...dominationPerYear) > 0
    job.hasFlipped = minIsFemale && maxIsMale
  }

  return job
}
