{
  "Total": {
    "ageData": {
      "2018": {
        "total": "155,761",
        "16to19": "5,126",
        "20to24": "14,051",
        "25to34": "35,324",
        "35to44": "32,616",
        "45to54": "32,373",
        "55to64": "26,565",
        "65+": "9,705",
        "medianAge": "26,565"
      }
    },
    "title": "Total",
    "depth": 1,
    "parent": "",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "155,761",
        "women": "46.9",
        "white": "78.0",
        "black": "12.3",
        "asian": "6.3",
        "hispanicOrLatino": "17.3"
      }
    },
    "wageData": {
      "2018": {
        "workers": "115,567",
        "median": "$886",
        "maleWorkers": "64,142",
        "maleMedian": "$973",
        "femaleWorkers": "51,425",
        "femaleMedian": "$789"
      }
    }
  },
  "Management, professional, and related occupations": {
    "ageData": {
      "2018": {
        "total": "62,436",
        "16to19": "436",
        "20to24": "3,359",
        "25to34": "14,673",
        "35to44": "14,572",
        "45to54": "13,979",
        "55to64": "11,126",
        "65+": "4,291",
        "medianAge": "11,126"
      }
    },
    "title": "Management, professional, and related occupations",
    "depth": 1,
    "parent": "",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "62,436",
        "women": "51.5",
        "white": "79.4",
        "black": "9.6",
        "asian": "8.5",
        "hispanicOrLatino": "9.7"
      }
    },
    "wageData": {
      "2018": {
        "workers": "48,808",
        "median": "1,246",
        "maleWorkers": "23,685",
        "maleMedian": "1,468",
        "femaleWorkers": "25,123",
        "femaleMedian": "1,078"
      }
    }
  },
  "Management, business, and financial operations occupations": {
    "ageData": {
      "2018": {
        "total": "25,850",
        "16to19": "91",
        "20to24": "1,005",
        "25to34": "5,151",
        "35to44": "6,069",
        "45to54": "6,362",
        "55to64": "5,228",
        "65+": "1,945",
        "medianAge": "5,228"
      }
    },
    "title": "Management, business, and financial operations occupations",
    "depth": 2,
    "parent": "Management, professional, and related occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "25,850",
        "women": "44.0",
        "white": "82.4",
        "black": "8.2",
        "asian": "6.8",
        "hispanicOrLatino": "9.8"
      }
    },
    "wageData": {
      "2018": {
        "workers": "19,863",
        "median": "1,355",
        "maleWorkers": "10,668",
        "maleMedian": "1,537",
        "femaleWorkers": "9,195",
        "femaleMedian": "1,168"
      }
    }
  },
  "Management occupations": {
    "ageData": {
      "2018": {
        "total": "18,263",
        "16to19": "69",
        "20to24": "565",
        "25to34": "3,259",
        "35to44": "4,411",
        "45to54": "4,666",
        "55to64": "3,874",
        "65+": "1,420",
        "medianAge": "3,874"
      }
    },
    "title": "Management occupations",
    "depth": 3,
    "parent": "Management, business, and financial operations occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "18,263",
        "women": "40.0",
        "white": "83.9",
        "black": "7.6",
        "asian": "5.9",
        "hispanicOrLatino": "10.3"
      }
    },
    "wageData": {
      "2018": {
        "workers": "13,477",
        "median": "1,429",
        "maleWorkers": "7,754",
        "maleMedian": "1,585",
        "femaleWorkers": "5,724",
        "femaleMedian": "1,236"
      }
    }
  },
  "Chief executives": {
    "ageData": {
      "2018": {
        "total": "1,573",
        "16to19": "0",
        "20to24": "3",
        "25to34": "147",
        "35to44": "349",
        "45to54": "467",
        "55to64": "413",
        "65+": "193",
        "medianAge": "413"
      }
    },
    "title": "Chief executives",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "1,573",
        "women": "26.9",
        "white": "89.5",
        "black": "3.5",
        "asian": "5.9",
        "hispanicOrLatino": "6.1"
      }
    },
    "wageData": {
      "2018": {
        "workers": "1,098",
        "median": "2,291",
        "maleWorkers": "790",
        "maleMedian": "2,488",
        "femaleWorkers": "307",
        "femaleMedian": "1,736"
      }
    }
  },
  "General and operations managers": {
    "ageData": {
      "2018": {
        "total": "1,037",
        "16to19": "1",
        "20to24": "30",
        "25to34": "235",
        "35to44": "279",
        "45to54": "245",
        "55to64": "204",
        "65+": "43",
        "medianAge": "204"
      }
    },
    "title": "General and operations managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "1,037",
        "women": "31.7",
        "white": "84.5",
        "black": "7.5",
        "asian": "4.8",
        "hispanicOrLatino": "10.5"
      }
    },
    "wageData": {
      "2018": {
        "workers": "939",
        "median": "1,338",
        "maleWorkers": "656",
        "maleMedian": "1,427",
        "femaleWorkers": "283",
        "femaleMedian": "1,139"
      }
    }
  },
  "Legislators": {
    "ageData": {
      "2018": {
        "total": "18",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "3",
        "45to54": "2",
        "55to64": "5",
        "65+": "7",
        "medianAge": "5"
      }
    },
    "title": "Legislators",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "18",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "14",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Advertising and promotions managers": {
    "ageData": {
      "2018": {
        "total": "44",
        "16to19": "0",
        "20to24": "5",
        "25to34": "13",
        "35to44": "14",
        "45to54": "8",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Advertising and promotions managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "44",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "39",
        "median": "-",
        "maleWorkers": "23",
        "maleMedian": "-",
        "femaleWorkers": "16",
        "femaleMedian": "-"
      }
    }
  },
  "Marketing and sales managers": {
    "ageData": {
      "2018": {
        "total": "1,083",
        "16to19": "4",
        "20to24": "65",
        "25to34": "269",
        "35to44": "282",
        "45to54": "252",
        "55to64": "176",
        "65+": "35",
        "medianAge": "176"
      }
    },
    "title": "Marketing and sales managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "1,083",
        "women": "47.6",
        "white": "85.8",
        "black": "6.7",
        "asian": "5.4",
        "hispanicOrLatino": "9.7"
      }
    },
    "wageData": {
      "2018": {
        "workers": "963",
        "median": "1,567",
        "maleWorkers": "517",
        "maleMedian": "1,852",
        "femaleWorkers": "447",
        "femaleMedian": "1,362"
      }
    }
  },
  "Public relations and fundraising managers": {
    "ageData": {
      "2018": {
        "total": "69",
        "16to19": "0",
        "20to24": "1",
        "25to34": "13",
        "35to44": "19",
        "45to54": "21",
        "55to64": "10",
        "65+": "6",
        "medianAge": "10"
      }
    },
    "title": "Public relations and fundraising managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "69",
        "women": "72.8",
        "white": "85.8",
        "black": "10.7",
        "asian": "3.1",
        "hispanicOrLatino": "3.1"
      }
    },
    "wageData": {
      "2018": {
        "workers": "61",
        "median": "1,415",
        "maleWorkers": "18",
        "maleMedian": "-",
        "femaleWorkers": "43",
        "femaleMedian": "-"
      }
    }
  },
  "Administrative services managers": {
    "ageData": {
      "2018": {
        "total": "161",
        "16to19": "1",
        "20to24": "5",
        "25to34": "19",
        "35to44": "35",
        "45to54": "48",
        "55to64": "45",
        "65+": "9",
        "medianAge": "45"
      }
    },
    "title": "Administrative services managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "161",
        "women": "34.2",
        "white": "85.4",
        "black": "7.4",
        "asian": "3.0",
        "hispanicOrLatino": "13.0"
      }
    },
    "wageData": {
      "2018": {
        "workers": "147",
        "median": "1,215",
        "maleWorkers": "101",
        "maleMedian": "1,266",
        "femaleWorkers": "46",
        "femaleMedian": "-"
      }
    }
  },
  "Computer and information systems managers": {
    "ageData": {
      "2018": {
        "total": "623",
        "16to19": "0",
        "20to24": "10",
        "25to34": "96",
        "35to44": "188",
        "45to54": "191",
        "55to64": "122",
        "65+": "16",
        "medianAge": "122"
      }
    },
    "title": "Computer and information systems managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "623",
        "women": "27.0",
        "white": "77.0",
        "black": "5.8",
        "asian": "14.3",
        "hispanicOrLatino": "6.6"
      }
    },
    "wageData": {
      "2018": {
        "workers": "601",
        "median": "1,877",
        "maleWorkers": "448",
        "maleMedian": "1,920",
        "femaleWorkers": "153",
        "femaleMedian": "1,727"
      }
    }
  },
  "Financial managers": {
    "ageData": {
      "2018": {
        "total": "1,231",
        "16to19": "3",
        "20to24": "34",
        "25to34": "279",
        "35to44": "338",
        "45to54": "298",
        "55to64": "220",
        "65+": "59",
        "medianAge": "220"
      }
    },
    "title": "Financial managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "1,231",
        "women": "55.2",
        "white": "81.7",
        "black": "8.1",
        "asian": "7.4",
        "hispanicOrLatino": "9.4"
      }
    },
    "wageData": {
      "2018": {
        "workers": "1,158",
        "median": "1,466",
        "maleWorkers": "495",
        "maleMedian": "1,784",
        "femaleWorkers": "663",
        "femaleMedian": "1,262"
      }
    }
  },
  "Compensation and benefits managers": {
    "ageData": {
      "2018": {
        "total": "23",
        "16to19": "1",
        "20to24": "2",
        "25to34": "2",
        "35to44": "5",
        "45to54": "10",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Compensation and benefits managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "23",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "21",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "16",
        "femaleMedian": "-"
      }
    }
  },
  "Human resources managers": {
    "ageData": {
      "2018": {
        "total": "313",
        "16to19": "0",
        "20to24": "7",
        "25to34": "64",
        "35to44": "95",
        "45to54": "94",
        "55to64": "43",
        "65+": "10",
        "medianAge": "43"
      }
    },
    "title": "Human resources managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "313",
        "women": "77.9",
        "white": "78.1",
        "black": "12.8",
        "asian": "6.3",
        "hispanicOrLatino": "10.2"
      }
    },
    "wageData": {
      "2018": {
        "workers": "294",
        "median": "1,381",
        "maleWorkers": "73",
        "maleMedian": "1,761",
        "femaleWorkers": "221",
        "femaleMedian": "1,330"
      }
    }
  },
  "Training and development managers": {
    "ageData": {
      "2018": {
        "total": "58",
        "16to19": "0",
        "20to24": "1",
        "25to34": "17",
        "35to44": "13",
        "45to54": "15",
        "55to64": "8",
        "65+": "3",
        "medianAge": "8"
      }
    },
    "title": "Training and development managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "58",
        "women": "48.6",
        "white": "86.3",
        "black": "10.1",
        "asian": "0.0",
        "hispanicOrLatino": "15.5"
      }
    },
    "wageData": {
      "2018": {
        "workers": "52",
        "median": "1,448",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "31",
        "femaleMedian": "-"
      }
    }
  },
  "Industrial production managers": {
    "ageData": {
      "2018": {
        "total": "264",
        "16to19": "0",
        "20to24": "8",
        "25to34": "45",
        "35to44": "63",
        "45to54": "77",
        "55to64": "61",
        "65+": "8",
        "medianAge": "61"
      }
    },
    "title": "Industrial production managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "264",
        "women": "22.0",
        "white": "85.7",
        "black": "6.3",
        "asian": "6.0",
        "hispanicOrLatino": "12.5"
      }
    },
    "wageData": {
      "2018": {
        "workers": "243",
        "median": "1,465",
        "maleWorkers": "188",
        "maleMedian": "1,573",
        "femaleWorkers": "55",
        "femaleMedian": "1,296"
      }
    }
  },
  "Purchasing managers": {
    "ageData": {
      "2018": {
        "total": "219",
        "16to19": "0",
        "20to24": "9",
        "25to34": "50",
        "35to44": "53",
        "45to54": "49",
        "55to64": "48",
        "65+": "10",
        "medianAge": "48"
      }
    },
    "title": "Purchasing managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "219",
        "women": "45.3",
        "white": "85.3",
        "black": "9.0",
        "asian": "4.0",
        "hispanicOrLatino": "10.7"
      }
    },
    "wageData": {
      "2018": {
        "workers": "222",
        "median": "1,362",
        "maleWorkers": "123",
        "maleMedian": "1,431",
        "femaleWorkers": "99",
        "femaleMedian": "1,229"
      }
    }
  },
  "Transportation, storage, and distribution managers": {
    "ageData": {
      "2018": {
        "total": "303",
        "16to19": "1",
        "20to24": "12",
        "25to34": "66",
        "35to44": "57",
        "45to54": "86",
        "55to64": "66",
        "65+": "15",
        "medianAge": "66"
      }
    },
    "title": "Transportation, storage, and distribution managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "303",
        "women": "16.0",
        "white": "84.9",
        "black": "7.2",
        "asian": "4.4",
        "hispanicOrLatino": "13.7"
      }
    },
    "wageData": {
      "2018": {
        "workers": "269",
        "median": "1,131",
        "maleWorkers": "226",
        "maleMedian": "1,138",
        "femaleWorkers": "43",
        "femaleMedian": "-"
      }
    }
  },
  "Farmers, ranchers, and other agricultural managers": {
    "ageData": {
      "2018": {
        "total": "991",
        "16to19": "4",
        "20to24": "16",
        "25to34": "108",
        "35to44": "124",
        "45to54": "160",
        "55to64": "294",
        "65+": "286",
        "medianAge": "294"
      }
    },
    "title": "Farmers, ranchers, and other agricultural managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "991",
        "women": "25.8",
        "white": "96.1",
        "black": "0.9",
        "asian": "1.4",
        "hispanicOrLatino": "5.1"
      }
    },
    "wageData": {
      "2018": {
        "workers": "119",
        "median": "945",
        "maleWorkers": "99",
        "maleMedian": "1,010",
        "femaleWorkers": "21",
        "femaleMedian": "-"
      }
    }
  },
  "Construction managers": {
    "ageData": {
      "2018": {
        "total": "1,021",
        "16to19": "1",
        "20to24": "27",
        "25to34": "154",
        "35to44": "281",
        "45to54": "261",
        "55to64": "221",
        "65+": "75",
        "medianAge": "221"
      }
    },
    "title": "Construction managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "1,021",
        "women": "7.7",
        "white": "91.8",
        "black": "3.1",
        "asian": "2.4",
        "hispanicOrLatino": "15.3"
      }
    },
    "wageData": {
      "2018": {
        "workers": "625",
        "median": "1,431",
        "maleWorkers": "572",
        "maleMedian": "1,467",
        "femaleWorkers": "53",
        "femaleMedian": "1,202"
      }
    }
  },
  "Education administrators": {
    "ageData": {
      "2018": {
        "total": "955",
        "16to19": "2",
        "20to24": "27",
        "25to34": "149",
        "35to44": "255",
        "45to54": "270",
        "55to64": "191",
        "65+": "60",
        "medianAge": "191"
      }
    },
    "title": "Education administrators",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "955",
        "women": "66.9",
        "white": "79.0",
        "black": "14.4",
        "asian": "3.4",
        "hispanicOrLatino": "10.5"
      }
    },
    "wageData": {
      "2018": {
        "workers": "857",
        "median": "1,381",
        "maleWorkers": "294",
        "maleMedian": "1,563",
        "femaleWorkers": "563",
        "femaleMedian": "1,245"
      }
    }
  },
  "Architectural and engineering managers": {
    "ageData": {
      "2018": {
        "total": "156",
        "16to19": "0",
        "20to24": "1",
        "25to34": "14",
        "35to44": "49",
        "45to54": "48",
        "55to64": "40",
        "65+": "4",
        "medianAge": "40"
      }
    },
    "title": "Architectural and engineering managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "156",
        "women": "11.5",
        "white": "84.5",
        "black": "5.0",
        "asian": "9.9",
        "hispanicOrLatino": "4.6"
      }
    },
    "wageData": {
      "2018": {
        "workers": "149",
        "median": "2,226",
        "maleWorkers": "131",
        "maleMedian": "2,285",
        "femaleWorkers": "19",
        "femaleMedian": "-"
      }
    }
  },
  "Food service managers": {
    "ageData": {
      "2018": {
        "total": "1,273",
        "16to19": "35",
        "20to24": "148",
        "25to34": "324",
        "35to44": "253",
        "45to54": "287",
        "55to64": "177",
        "65+": "48",
        "medianAge": "177"
      }
    },
    "title": "Food service managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "1,273",
        "women": "49.2",
        "white": "75.7",
        "black": "10.7",
        "asian": "10.0",
        "hispanicOrLatino": "18.5"
      }
    },
    "wageData": {
      "2018": {
        "workers": "827",
        "median": "809",
        "maleWorkers": "420",
        "maleMedian": "922",
        "femaleWorkers": "407",
        "femaleMedian": "708"
      }
    }
  },
  "Funeral service managers": {
    "ageData": {
      "2018": {
        "total": "3",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "1",
        "55to64": "0",
        "65+": "1",
        "medianAge": "0"
      }
    },
    "title": "Funeral service managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "0",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Gaming managers": {
    "ageData": {
      "2018": {
        "total": "28",
        "16to19": "0",
        "20to24": "5",
        "25to34": "4",
        "35to44": "9",
        "45to54": "3",
        "55to64": "5",
        "65+": "2",
        "medianAge": "5"
      }
    },
    "title": "Gaming managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "28",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "23",
        "median": "-",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Lodging managers": {
    "ageData": {
      "2018": {
        "total": "153",
        "16to19": "1",
        "20to24": "5",
        "25to34": "36",
        "35to44": "36",
        "45to54": "35",
        "55to64": "30",
        "65+": "11",
        "medianAge": "30"
      }
    },
    "title": "Lodging managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "153",
        "women": "56.0",
        "white": "76.2",
        "black": "11.1",
        "asian": "10.2",
        "hispanicOrLatino": "11.5"
      }
    },
    "wageData": {
      "2018": {
        "workers": "112",
        "median": "955",
        "maleWorkers": "48",
        "maleMedian": "-",
        "femaleWorkers": "64",
        "femaleMedian": "920"
      }
    }
  },
  "Medical and health services managers": {
    "ageData": {
      "2018": {
        "total": "639",
        "16to19": "1",
        "20to24": "12",
        "25to34": "113",
        "35to44": "167",
        "45to54": "164",
        "55to64": "152",
        "65+": "30",
        "medianAge": "152"
      }
    },
    "title": "Medical and health services managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "639",
        "women": "72.0",
        "white": "80.9",
        "black": "11.5",
        "asian": "5.0",
        "hispanicOrLatino": "9.0"
      }
    },
    "wageData": {
      "2018": {
        "workers": "567",
        "median": "1,408",
        "maleWorkers": "149",
        "maleMedian": "1,729",
        "femaleWorkers": "418",
        "femaleMedian": "1,331"
      }
    }
  },
  "Natural sciences managers": {
    "ageData": {
      "2018": {
        "total": "9",
        "16to19": "0",
        "20to24": "2",
        "25to34": "2",
        "35to44": "3",
        "45to54": "2",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Natural sciences managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "9",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Postmasters and mail superintendents": {
    "ageData": {
      "2018": {
        "total": "24",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "4",
        "45to54": "9",
        "55to64": "8",
        "65+": "2",
        "medianAge": "8"
      }
    },
    "title": "Postmasters and mail superintendents",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "24",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "22",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "15",
        "femaleMedian": "-"
      }
    }
  },
  "Property, real estate, and community association managers": {
    "ageData": {
      "2018": {
        "total": "717",
        "16to19": "0",
        "20to24": "19",
        "25to34": "125",
        "35to44": "142",
        "45to54": "150",
        "55to64": "178",
        "65+": "102",
        "medianAge": "178"
      }
    },
    "title": "Property, real estate, and community association managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "717",
        "women": "49.7",
        "white": "85.5",
        "black": "7.3",
        "asian": "4.6",
        "hispanicOrLatino": "13.6"
      }
    },
    "wageData": {
      "2018": {
        "workers": "390",
        "median": "1,018",
        "maleWorkers": "181",
        "maleMedian": "1,148",
        "femaleWorkers": "208",
        "femaleMedian": "927"
      }
    }
  },
  "Social and community service managers": {
    "ageData": {
      "2018": {
        "total": "437",
        "16to19": "1",
        "20to24": "7",
        "25to34": "93",
        "35to44": "90",
        "45to54": "120",
        "55to64": "93",
        "65+": "33",
        "medianAge": "93"
      }
    },
    "title": "Social and community service managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "437",
        "women": "69.1",
        "white": "78.4",
        "black": "13.8",
        "asian": "4.0",
        "hispanicOrLatino": "7.6"
      }
    },
    "wageData": {
      "2018": {
        "workers": "363",
        "median": "1,141",
        "maleWorkers": "112",
        "maleMedian": "1,336",
        "femaleWorkers": "251",
        "femaleMedian": "1,057"
      }
    }
  },
  "Emergency management directors": {
    "ageData": {
      "2018": {
        "total": "10",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "1",
        "45to54": "4",
        "55to64": "1",
        "65+": "1",
        "medianAge": "1"
      }
    },
    "title": "Emergency management directors",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "10",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "9",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Managers, all other": {
    "ageData": {
      "2018": {
        "total": "4,827",
        "16to19": "12",
        "20to24": "104",
        "25to34": "815",
        "35to44": "1,203",
        "45to54": "1,287",
        "55to64": "1,058",
        "65+": "347",
        "medianAge": "1,058"
      }
    },
    "title": "Managers, all other",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "4,827",
        "women": "35.4",
        "white": "83.2",
        "black": "7.8",
        "asian": "6.5",
        "hispanicOrLatino": "9.9"
      }
    },
    "wageData": {
      "2018": {
        "workers": "3,285",
        "median": "1,525",
        "maleWorkers": "2,022",
        "maleMedian": "1,628",
        "femaleWorkers": "1,263",
        "femaleMedian": "1,341"
      }
    }
  },
  "Business and financial operations occupations": {
    "ageData": {
      "2018": {
        "total": "7,587",
        "16to19": "23",
        "20to24": "440",
        "25to34": "1,892",
        "35to44": "1,658",
        "45to54": "1,697",
        "55to64": "1,353",
        "65+": "525",
        "medianAge": "1,353"
      }
    },
    "title": "Business and financial operations occupations",
    "depth": 3,
    "parent": "Management, business, and financial operations occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "7,587",
        "women": "53.8",
        "white": "78.8",
        "black": "9.8",
        "asian": "9.1",
        "hispanicOrLatino": "8.7"
      }
    },
    "wageData": {
      "2018": {
        "workers": "6,385",
        "median": "1,216",
        "maleWorkers": "2,914",
        "maleMedian": "1,383",
        "femaleWorkers": "3,471",
        "femaleMedian": "1,105"
      }
    }
  },
  "Agents and business managers of artists, performers, and athletes": {
    "ageData": {
      "2018": {
        "total": "54",
        "16to19": "2",
        "20to24": "8",
        "25to34": "10",
        "35to44": "13",
        "45to54": "12",
        "55to64": "7",
        "65+": "3",
        "medianAge": "7"
      }
    },
    "title": "Agents and business managers of artists, performers, and athletes",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "54",
        "women": "53.8",
        "white": "79.3",
        "black": "7.5",
        "asian": "11.0",
        "hispanicOrLatino": "12.2"
      }
    },
    "wageData": {
      "2018": {
        "workers": "31",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "18",
        "femaleMedian": "-"
      }
    }
  },
  "Buyers and purchasing agents, farm products": {
    "ageData": {
      "2018": {
        "total": "12",
        "16to19": "0",
        "20to24": "0",
        "25to34": "6",
        "35to44": "2",
        "45to54": "4",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Buyers and purchasing agents, farm products",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "12",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "10",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Wholesale and retail buyers, except farm products": {
    "ageData": {
      "2018": {
        "total": "193",
        "16to19": "0",
        "20to24": "13",
        "25to34": "52",
        "35to44": "31",
        "45to54": "40",
        "55to64": "42",
        "65+": "14",
        "medianAge": "42"
      }
    },
    "title": "Wholesale and retail buyers, except farm products",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "193",
        "women": "50.7",
        "white": "80.7",
        "black": "10.7",
        "asian": "5.7",
        "hispanicOrLatino": "11.1"
      }
    },
    "wageData": {
      "2018": {
        "workers": "147",
        "median": "878",
        "maleWorkers": "82",
        "maleMedian": "878",
        "femaleWorkers": "65",
        "femaleMedian": "880"
      }
    }
  },
  "Purchasing agents, except wholesale, retail, and farm products": {
    "ageData": {
      "2018": {
        "total": "244",
        "16to19": "2",
        "20to24": "11",
        "25to34": "47",
        "35to44": "43",
        "45to54": "67",
        "55to64": "56",
        "65+": "18",
        "medianAge": "56"
      }
    },
    "title": "Purchasing agents, except wholesale, retail, and farm products",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "244",
        "women": "46.7",
        "white": "84.3",
        "black": "8.0",
        "asian": "5.0",
        "hispanicOrLatino": "7.6"
      }
    },
    "wageData": {
      "2018": {
        "workers": "223",
        "median": "1,160",
        "maleWorkers": "121",
        "maleMedian": "1,209",
        "femaleWorkers": "102",
        "femaleMedian": "1,032"
      }
    }
  },
  "Claims adjusters, appraisers, examiners, and investigators": {
    "ageData": {
      "2018": {
        "total": "344",
        "16to19": "0",
        "20to24": "18",
        "25to34": "77",
        "35to44": "90",
        "45to54": "80",
        "55to64": "69",
        "65+": "11",
        "medianAge": "69"
      }
    },
    "title": "Claims adjusters, appraisers, examiners, and investigators",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "344",
        "women": "57.4",
        "white": "74.9",
        "black": "19.1",
        "asian": "3.7",
        "hispanicOrLatino": "10.9"
      }
    },
    "wageData": {
      "2018": {
        "workers": "334",
        "median": "998",
        "maleWorkers": "145",
        "maleMedian": "1,167",
        "femaleWorkers": "189",
        "femaleMedian": "913"
      }
    }
  },
  "Compliance officers": {
    "ageData": {
      "2018": {
        "total": "281",
        "16to19": "2",
        "20to24": "12",
        "25to34": "53",
        "35to44": "75",
        "45to54": "70",
        "55to64": "50",
        "65+": "19",
        "medianAge": "50"
      }
    },
    "title": "Compliance officers",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "281",
        "women": "53.7",
        "white": "82.3",
        "black": "11.3",
        "asian": "5.4",
        "hispanicOrLatino": "6.9"
      }
    },
    "wageData": {
      "2018": {
        "workers": "278",
        "median": "1,296",
        "maleWorkers": "129",
        "maleMedian": "1,450",
        "femaleWorkers": "149",
        "femaleMedian": "1,165"
      }
    }
  },
  "Cost estimators": {
    "ageData": {
      "2018": {
        "total": "117",
        "16to19": "1",
        "20to24": "7",
        "25to34": "19",
        "35to44": "27",
        "45to54": "27",
        "55to64": "25",
        "65+": "11",
        "medianAge": "25"
      }
    },
    "title": "Cost estimators",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "117",
        "women": "14.1",
        "white": "93.3",
        "black": "1.9",
        "asian": "4.0",
        "hispanicOrLatino": "10.5"
      }
    },
    "wageData": {
      "2018": {
        "workers": "109",
        "median": "1,165",
        "maleWorkers": "95",
        "maleMedian": "1,208",
        "femaleWorkers": "14",
        "femaleMedian": "-"
      }
    }
  },
  "Human resources workers": {
    "ageData": {
      "2018": {
        "total": "664",
        "16to19": "2",
        "20to24": "42",
        "25to34": "180",
        "35to44": "172",
        "45to54": "125",
        "55to64": "114",
        "65+": "28",
        "medianAge": "114"
      }
    },
    "title": "Human resources workers",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "664",
        "women": "70.7",
        "white": "79.2",
        "black": "10.5",
        "asian": "7.0",
        "hispanicOrLatino": "13.2"
      }
    },
    "wageData": {
      "2018": {
        "workers": "620",
        "median": "1,161",
        "maleWorkers": "190",
        "maleMedian": "1,328",
        "femaleWorkers": "429",
        "femaleMedian": "1,107"
      }
    }
  },
  "Compensation, benefits, and job analysis specialists": {
    "ageData": {
      "2018": {
        "total": "48",
        "16to19": "0",
        "20to24": "1",
        "25to34": "11",
        "35to44": "14",
        "45to54": "8",
        "55to64": "9",
        "65+": "5",
        "medianAge": "9"
      }
    },
    "title": "Compensation, benefits, and job analysis specialists",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "48",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "48",
        "median": "-",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "31",
        "femaleMedian": "-"
      }
    }
  },
  "Training and development specialists": {
    "ageData": {
      "2018": {
        "total": "120",
        "16to19": "2",
        "20to24": "5",
        "25to34": "31",
        "35to44": "21",
        "45to54": "27",
        "55to64": "27",
        "65+": "7",
        "medianAge": "27"
      }
    },
    "title": "Training and development specialists",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "120",
        "women": "54.4",
        "white": "76.2",
        "black": "15.3",
        "asian": "2.9",
        "hispanicOrLatino": "8.7"
      }
    },
    "wageData": {
      "2018": {
        "workers": "111",
        "median": "1,092",
        "maleWorkers": "47",
        "maleMedian": "-",
        "femaleWorkers": "64",
        "femaleMedian": "1,086"
      }
    }
  },
  "Logisticians": {
    "ageData": {
      "2018": {
        "total": "128",
        "16to19": "0",
        "20to24": "11",
        "25to34": "39",
        "35to44": "25",
        "45to54": "28",
        "55to64": "20",
        "65+": "5",
        "medianAge": "20"
      }
    },
    "title": "Logisticians",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "128",
        "women": "37.3",
        "white": "75.7",
        "black": "12.1",
        "asian": "8.1",
        "hispanicOrLatino": "10.1"
      }
    },
    "wageData": {
      "2018": {
        "workers": "127",
        "median": "1,019",
        "maleWorkers": "81",
        "maleMedian": "1,131",
        "femaleWorkers": "46",
        "femaleMedian": "-"
      }
    }
  },
  "Management analysts": {
    "ageData": {
      "2018": {
        "total": "944",
        "16to19": "1",
        "20to24": "33",
        "25to34": "212",
        "35to44": "190",
        "45to54": "217",
        "55to64": "181",
        "65+": "109",
        "medianAge": "181"
      }
    },
    "title": "Management analysts",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "944",
        "women": "41.3",
        "white": "77.6",
        "black": "8.3",
        "asian": "12.3",
        "hispanicOrLatino": "6.5"
      }
    },
    "wageData": {
      "2018": {
        "workers": "635",
        "median": "1,532",
        "maleWorkers": "356",
        "maleMedian": "1,642",
        "femaleWorkers": "279",
        "femaleMedian": "1,437"
      }
    }
  },
  "Meeting, convention, and event planners": {
    "ageData": {
      "2018": {
        "total": "126",
        "16to19": "2",
        "20to24": "20",
        "25to34": "42",
        "35to44": "25",
        "45to54": "21",
        "55to64": "15",
        "65+": "2",
        "medianAge": "15"
      }
    },
    "title": "Meeting, convention, and event planners",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "126",
        "women": "76.3",
        "white": "77.7",
        "black": "8.6",
        "asian": "5.3",
        "hispanicOrLatino": "10.5"
      }
    },
    "wageData": {
      "2018": {
        "workers": "89",
        "median": "1,002",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "69",
        "femaleMedian": "1,006"
      }
    }
  },
  "Fundraisers": {
    "ageData": {
      "2018": {
        "total": "96",
        "16to19": "1",
        "20to24": "7",
        "25to34": "28",
        "35to44": "20",
        "45to54": "13",
        "55to64": "20",
        "65+": "8",
        "medianAge": "20"
      }
    },
    "title": "Fundraisers",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "96",
        "women": "69.3",
        "white": "86.9",
        "black": "7.7",
        "asian": "3.2",
        "hispanicOrLatino": "5.4"
      }
    },
    "wageData": {
      "2018": {
        "workers": "82",
        "median": "1,262",
        "maleWorkers": "22",
        "maleMedian": "-",
        "femaleWorkers": "60",
        "femaleMedian": "1,168"
      }
    }
  },
  "Market research analysts and marketing specialists": {
    "ageData": {
      "2018": {
        "total": "355",
        "16to19": "1",
        "20to24": "42",
        "25to34": "125",
        "35to44": "79",
        "45to54": "60",
        "55to64": "36",
        "65+": "12",
        "medianAge": "36"
      }
    },
    "title": "Market research analysts and marketing specialists",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "355",
        "women": "60.2",
        "white": "81.8",
        "black": "5.8",
        "asian": "11.6",
        "hispanicOrLatino": "11.4"
      }
    },
    "wageData": {
      "2018": {
        "workers": "299",
        "median": "1,246",
        "maleWorkers": "125",
        "maleMedian": "1,503",
        "femaleWorkers": "174",
        "femaleMedian": "1,148"
      }
    }
  },
  "Business operations specialists, all other": {
    "ageData": {
      "2018": {
        "total": "236",
        "16to19": "1",
        "20to24": "16",
        "25to34": "49",
        "35to44": "63",
        "45to54": "52",
        "55to64": "39",
        "65+": "15",
        "medianAge": "39"
      }
    },
    "title": "Business operations specialists, all other",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "236",
        "women": "59.3",
        "white": "70.6",
        "black": "15.8",
        "asian": "9.0",
        "hispanicOrLatino": "9.4"
      }
    },
    "wageData": {
      "2018": {
        "workers": "223",
        "median": "1,150",
        "maleWorkers": "95",
        "maleMedian": "1,234",
        "femaleWorkers": "127",
        "femaleMedian": "1,072"
      }
    }
  },
  "Accountants and auditors": {
    "ageData": {
      "2018": {
        "total": "1,929",
        "16to19": "2",
        "20to24": "105",
        "25to34": "481",
        "35to44": "404",
        "45to54": "454",
        "55to64": "345",
        "65+": "137",
        "medianAge": "345"
      }
    },
    "title": "Accountants and auditors",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "1,929",
        "women": "60.6",
        "white": "75.8",
        "black": "9.3",
        "asian": "12.7",
        "hispanicOrLatino": "7.6"
      }
    },
    "wageData": {
      "2018": {
        "workers": "1,615",
        "median": "1,209",
        "maleWorkers": "646",
        "maleMedian": "1,404",
        "femaleWorkers": "969",
        "femaleMedian": "1,108"
      }
    }
  },
  "Appraisers and assessors of real estate": {
    "ageData": {
      "2018": {
        "total": "84",
        "16to19": "0",
        "20to24": "2",
        "25to34": "9",
        "35to44": "16",
        "45to54": "24",
        "55to64": "22",
        "65+": "11",
        "medianAge": "22"
      }
    },
    "title": "Appraisers and assessors of real estate",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "84",
        "women": "45.3",
        "white": "83.9",
        "black": "10.7",
        "asian": "4.0",
        "hispanicOrLatino": "4.2"
      }
    },
    "wageData": {
      "2018": {
        "workers": "47",
        "median": "-",
        "maleWorkers": "24",
        "maleMedian": "-",
        "femaleWorkers": "22",
        "femaleMedian": "-"
      }
    }
  },
  "Budget analysts": {
    "ageData": {
      "2018": {
        "total": "51",
        "16to19": "0",
        "20to24": "2",
        "25to34": "12",
        "35to44": "11",
        "45to54": "11",
        "55to64": "12",
        "65+": "2",
        "medianAge": "12"
      }
    },
    "title": "Budget analysts",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "51",
        "women": "62.2",
        "white": "72.6",
        "black": "11.2",
        "asian": "14.5",
        "hispanicOrLatino": "13.8"
      }
    },
    "wageData": {
      "2018": {
        "workers": "52",
        "median": "1,351",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "35",
        "femaleMedian": "-"
      }
    }
  },
  "Credit analysts": {
    "ageData": {
      "2018": {
        "total": "26",
        "16to19": "0",
        "20to24": "2",
        "25to34": "10",
        "35to44": "4",
        "45to54": "7",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Credit analysts",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "26",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "27",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "14",
        "femaleMedian": "-"
      }
    }
  },
  "Financial analysts": {
    "ageData": {
      "2018": {
        "total": "307",
        "16to19": "1",
        "20to24": "27",
        "25to34": "112",
        "35to44": "76",
        "45to54": "51",
        "55to64": "33",
        "65+": "8",
        "medianAge": "33"
      }
    },
    "title": "Financial analysts",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "307",
        "women": "40.7",
        "white": "76.1",
        "black": "8.1",
        "asian": "14.1",
        "hispanicOrLatino": "5.5"
      }
    },
    "wageData": {
      "2018": {
        "workers": "261",
        "median": "1,470",
        "maleWorkers": "138",
        "maleMedian": "1,599",
        "femaleWorkers": "122",
        "femaleMedian": "1,197"
      }
    }
  },
  "Personal financial advisors": {
    "ageData": {
      "2018": {
        "total": "537",
        "16to19": "1",
        "20to24": "21",
        "25to34": "125",
        "35to44": "128",
        "45to54": "119",
        "55to64": "97",
        "65+": "45",
        "medianAge": "97"
      }
    },
    "title": "Personal financial advisors",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "537",
        "women": "33.5",
        "white": "86.3",
        "black": "5.0",
        "asian": "6.9",
        "hispanicOrLatino": "6.6"
      }
    },
    "wageData": {
      "2018": {
        "workers": "414",
        "median": "1,473",
        "maleWorkers": "268",
        "maleMedian": "1,647",
        "femaleWorkers": "146",
        "femaleMedian": "1,207"
      }
    }
  },
  "Insurance underwriters": {
    "ageData": {
      "2018": {
        "total": "119",
        "16to19": "0",
        "20to24": "10",
        "25to34": "27",
        "35to44": "24",
        "45to54": "29",
        "55to64": "25",
        "65+": "3",
        "medianAge": "25"
      }
    },
    "title": "Insurance underwriters",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "119",
        "women": "59.7",
        "white": "80.7",
        "black": "13.7",
        "asian": "5.3",
        "hispanicOrLatino": "6.2"
      }
    },
    "wageData": {
      "2018": {
        "workers": "122",
        "median": "1,268",
        "maleWorkers": "51",
        "maleMedian": "1,413",
        "femaleWorkers": "71",
        "femaleMedian": "1,032"
      }
    }
  },
  "Financial examiners": {
    "ageData": {
      "2018": {
        "total": "13",
        "16to19": "0",
        "20to24": "1",
        "25to34": "4",
        "35to44": "4",
        "45to54": "2",
        "55to64": "0",
        "65+": "2",
        "medianAge": "0"
      }
    },
    "title": "Financial examiners",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "13",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "11",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "9",
        "femaleMedian": "-"
      }
    }
  },
  "Credit counselors and loan officers": {
    "ageData": {
      "2018": {
        "total": "350",
        "16to19": "1",
        "20to24": "15",
        "25to34": "86",
        "35to44": "74",
        "45to54": "93",
        "55to64": "59",
        "65+": "23",
        "medianAge": "59"
      }
    },
    "title": "Credit counselors and loan officers",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "350",
        "women": "54.1",
        "white": "85.5",
        "black": "7.6",
        "asian": "4.3",
        "hispanicOrLatino": "13.0"
      }
    },
    "wageData": {
      "2018": {
        "workers": "332",
        "median": "1,168",
        "maleWorkers": "155",
        "maleMedian": "1,443",
        "femaleWorkers": "177",
        "femaleMedian": "948"
      }
    }
  },
  "Tax examiners and collectors, and revenue agents": {
    "ageData": {
      "2018": {
        "total": "47",
        "16to19": "0",
        "20to24": "0",
        "25to34": "8",
        "35to44": "5",
        "45to54": "17",
        "55to64": "12",
        "65+": "5",
        "medianAge": "12"
      }
    },
    "title": "Tax examiners and collectors, and revenue agents",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "47",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "42",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "29",
        "femaleMedian": "-"
      }
    }
  },
  "Tax preparers": {
    "ageData": {
      "2018": {
        "total": "113",
        "16to19": "0",
        "20to24": "5",
        "25to34": "26",
        "35to44": "12",
        "45to54": "27",
        "55to64": "24",
        "65+": "19",
        "medianAge": "24"
      }
    },
    "title": "Tax preparers",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "113",
        "women": "56.8",
        "white": "76.1",
        "black": "16.7",
        "asian": "5.1",
        "hispanicOrLatino": "13.1"
      }
    },
    "wageData": {
      "2018": {
        "workers": "62",
        "median": "1,028",
        "maleWorkers": "26",
        "maleMedian": "-",
        "femaleWorkers": "36",
        "femaleMedian": "-"
      }
    }
  },
  "Financial specialists, all other": {
    "ageData": {
      "2018": {
        "total": "48",
        "16to19": "0",
        "20to24": "6",
        "25to34": "11",
        "35to44": "10",
        "45to54": "10",
        "55to64": "10",
        "65+": "1",
        "medianAge": "10"
      }
    },
    "title": "Financial specialists, all other",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "48",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "35",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "22",
        "femaleMedian": "-"
      }
    }
  },
  "Professional and related occupations": {
    "ageData": {
      "2018": {
        "total": "36,586",
        "16to19": "345",
        "20to24": "2,354",
        "25to34": "9,522",
        "35to44": "8,503",
        "45to54": "7,617",
        "55to64": "5,898",
        "65+": "2,347",
        "medianAge": "5,898"
      }
    },
    "title": "Professional and related occupations",
    "depth": 2,
    "parent": "Management, professional, and related occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "36,586",
        "women": "56.8",
        "white": "77.2",
        "black": "10.5",
        "asian": "9.6",
        "hispanicOrLatino": "9.6"
      }
    },
    "wageData": {
      "2018": {
        "workers": "28,945",
        "median": "1,176",
        "maleWorkers": "13,017",
        "maleMedian": "1,425",
        "femaleWorkers": "15,929",
        "femaleMedian": "1,024"
      }
    }
  },
  "Computer and mathematical occupations": {
    "ageData": {
      "2018": {
        "total": "5,126",
        "16to19": "25",
        "20to24": "330",
        "25to34": "1,563",
        "35to44": "1,333",
        "45to54": "1,038",
        "55to64": "692",
        "65+": "146",
        "medianAge": "692"
      }
    },
    "title": "Computer and mathematical occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "5,126",
        "women": "25.6",
        "white": "67.0",
        "black": "8.4",
        "asian": "22.0",
        "hispanicOrLatino": "7.5"
      }
    },
    "wageData": {
      "2018": {
        "workers": "4,755",
        "median": "1,539",
        "maleWorkers": "3,571",
        "maleMedian": "1,604",
        "femaleWorkers": "1,184",
        "femaleMedian": "1,345"
      }
    }
  },
  "Computer and information research scientists": {
    "ageData": {
      "2018": {
        "total": "19",
        "16to19": "0",
        "20to24": "0",
        "25to34": "10",
        "35to44": "4",
        "45to54": "3",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Computer and information research scientists",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "19",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "20",
        "median": "-",
        "maleWorkers": "16",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Computer systems analysts": {
    "ageData": {
      "2018": {
        "total": "652",
        "16to19": "2",
        "20to24": "38",
        "25to34": "193",
        "35to44": "162",
        "45to54": "136",
        "55to64": "92",
        "65+": "29",
        "medianAge": "92"
      }
    },
    "title": "Computer systems analysts",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "652",
        "women": "37.5",
        "white": "66.5",
        "black": "10.0",
        "asian": "20.4",
        "hispanicOrLatino": "7.9"
      }
    },
    "wageData": {
      "2018": {
        "workers": "580",
        "median": "1,521",
        "maleWorkers": "358",
        "maleMedian": "1,647",
        "femaleWorkers": "222",
        "femaleMedian": "1,246"
      }
    }
  },
  "Information security analysts": {
    "ageData": {
      "2018": {
        "total": "97",
        "16to19": "1",
        "20to24": "5",
        "25to34": "26",
        "35to44": "34",
        "45to54": "17",
        "55to64": "13",
        "65+": "3",
        "medianAge": "13"
      }
    },
    "title": "Information security analysts",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "97",
        "women": "24.9",
        "white": "76.8",
        "black": "12.3",
        "asian": "8.5",
        "hispanicOrLatino": "6.8"
      }
    },
    "wageData": {
      "2018": {
        "workers": "93",
        "median": "1,635",
        "maleWorkers": "75",
        "maleMedian": "1,625",
        "femaleWorkers": "18",
        "femaleMedian": "-"
      }
    }
  },
  "Computer programmers": {
    "ageData": {
      "2018": {
        "total": "477",
        "16to19": "3",
        "20to24": "28",
        "25to34": "117",
        "35to44": "111",
        "45to54": "126",
        "55to64": "73",
        "65+": "18",
        "medianAge": "73"
      }
    },
    "title": "Computer programmers",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "477",
        "women": "21.2",
        "white": "68.8",
        "black": "9.3",
        "asian": "19.4",
        "hispanicOrLatino": "7.8"
      }
    },
    "wageData": {
      "2018": {
        "workers": "428",
        "median": "1,591",
        "maleWorkers": "338",
        "maleMedian": "1,662",
        "femaleWorkers": "91",
        "femaleMedian": "1,445"
      }
    }
  },
  "Software developers, applications and systems software": {
    "ageData": {
      "2018": {
        "total": "1,682",
        "16to19": "1",
        "20to24": "99",
        "25to34": "595",
        "35to44": "462",
        "45to54": "291",
        "55to64": "200",
        "65+": "34",
        "medianAge": "200"
      }
    },
    "title": "Software developers, applications and systems software",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "1,682",
        "women": "19.3",
        "white": "58.6",
        "black": "3.9",
        "asian": "35.4",
        "hispanicOrLatino": "5.3"
      }
    },
    "wageData": {
      "2018": {
        "workers": "1,611",
        "median": "1,864",
        "maleWorkers": "1,283",
        "maleMedian": "1,894",
        "femaleWorkers": "327",
        "femaleMedian": "1,644"
      }
    }
  },
  "Web developers": {
    "ageData": {
      "2018": {
        "total": "208",
        "16to19": "3",
        "20to24": "11",
        "25to34": "74",
        "35to44": "61",
        "45to54": "33",
        "55to64": "23",
        "65+": "4",
        "medianAge": "23"
      }
    },
    "title": "Web developers",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "208",
        "women": "32.5",
        "white": "81.5",
        "black": "5.4",
        "asian": "8.9",
        "hispanicOrLatino": "7.2"
      }
    },
    "wageData": {
      "2018": {
        "workers": "164",
        "median": "1,333",
        "maleWorkers": "115",
        "maleMedian": "1,418",
        "femaleWorkers": "49",
        "femaleMedian": "-"
      }
    }
  },
  "Computer support specialists": {
    "ageData": {
      "2018": {
        "total": "566",
        "16to19": "5",
        "20to24": "62",
        "25to34": "175",
        "35to44": "128",
        "45to54": "104",
        "55to64": "78",
        "65+": "15",
        "medianAge": "78"
      }
    },
    "title": "Computer support specialists",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "566",
        "women": "28.1",
        "white": "72.4",
        "black": "12.6",
        "asian": "12.0",
        "hispanicOrLatino": "9.8"
      }
    },
    "wageData": {
      "2018": {
        "workers": "516",
        "median": "1,085",
        "maleWorkers": "376",
        "maleMedian": "1,114",
        "femaleWorkers": "140",
        "femaleMedian": "1,015"
      }
    }
  },
  "Database administrators": {
    "ageData": {
      "2018": {
        "total": "108",
        "16to19": "1",
        "20to24": "7",
        "25to34": "34",
        "35to44": "16",
        "45to54": "24",
        "55to64": "23",
        "65+": "3",
        "medianAge": "23"
      }
    },
    "title": "Database administrators",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "108",
        "women": "37.9",
        "white": "71.3",
        "black": "8.2",
        "asian": "16.7",
        "hispanicOrLatino": "9.5"
      }
    },
    "wageData": {
      "2018": {
        "workers": "108",
        "median": "1,493",
        "maleWorkers": "67",
        "maleMedian": "1,573",
        "femaleWorkers": "40",
        "femaleMedian": "-"
      }
    }
  },
  "Network and computer systems administrators": {
    "ageData": {
      "2018": {
        "total": "204",
        "16to19": "0",
        "20to24": "4",
        "25to34": "56",
        "35to44": "54",
        "45to54": "52",
        "55to64": "33",
        "65+": "5",
        "medianAge": "33"
      }
    },
    "title": "Network and computer systems administrators",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "204",
        "women": "21.2",
        "white": "75.8",
        "black": "12.1",
        "asian": "8.5",
        "hispanicOrLatino": "9.3"
      }
    },
    "wageData": {
      "2018": {
        "workers": "192",
        "median": "1,430",
        "maleWorkers": "155",
        "maleMedian": "1,478",
        "femaleWorkers": "37",
        "femaleMedian": "-"
      }
    }
  },
  "Computer network architects": {
    "ageData": {
      "2018": {
        "total": "114",
        "16to19": "0",
        "20to24": "1",
        "25to34": "24",
        "35to44": "34",
        "45to54": "33",
        "55to64": "18",
        "65+": "4",
        "medianAge": "18"
      }
    },
    "title": "Computer network architects",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "114",
        "women": "10.3",
        "white": "73.0",
        "black": "9.4",
        "asian": "14.7",
        "hispanicOrLatino": "9.9"
      }
    },
    "wageData": {
      "2018": {
        "workers": "112",
        "median": "1,765",
        "maleWorkers": "102",
        "maleMedian": "1,771",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Computer occupations, all other": {
    "ageData": {
      "2018": {
        "total": "732",
        "16to19": "8",
        "20to24": "60",
        "25to34": "189",
        "35to44": "197",
        "45to54": "159",
        "55to64": "95",
        "65+": "25",
        "medianAge": "95"
      }
    },
    "title": "Computer occupations, all other",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "732",
        "women": "21.9",
        "white": "72.1",
        "black": "11.4",
        "asian": "14.9",
        "hispanicOrLatino": "9.5"
      }
    },
    "wageData": {
      "2018": {
        "workers": "678",
        "median": "1,226",
        "maleWorkers": "548",
        "maleMedian": "1,238",
        "femaleWorkers": "131",
        "femaleMedian": "1,208"
      }
    }
  },
  "Actuaries": {
    "ageData": {
      "2018": {
        "total": "33",
        "16to19": "0",
        "20to24": "1",
        "25to34": "9",
        "35to44": "13",
        "45to54": "9",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Actuaries",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "33",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "35",
        "median": "-",
        "maleWorkers": "24",
        "maleMedian": "-",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "Mathematicians": {
    "ageData": {
      "2018": {
        "total": "3",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "3",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Mathematicians",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Operations research analysts": {
    "ageData": {
      "2018": {
        "total": "131",
        "16to19": "0",
        "20to24": "4",
        "25to34": "27",
        "35to44": "27",
        "45to54": "39",
        "55to64": "31",
        "65+": "4",
        "medianAge": "31"
      }
    },
    "title": "Operations research analysts",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "131",
        "women": "49.1",
        "white": "76.9",
        "black": "10.9",
        "asian": "9.8",
        "hispanicOrLatino": "9.9"
      }
    },
    "wageData": {
      "2018": {
        "workers": "125",
        "median": "1,418",
        "maleWorkers": "66",
        "maleMedian": "1,566",
        "femaleWorkers": "58",
        "femaleMedian": "1,299"
      }
    }
  },
  "Statisticians": {
    "ageData": {
      "2018": {
        "total": "90",
        "16to19": "0",
        "20to24": "10",
        "25to34": "30",
        "35to44": "27",
        "45to54": "11",
        "55to64": "8",
        "65+": "3",
        "medianAge": "8"
      }
    },
    "title": "Statisticians",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "90",
        "women": "53.8",
        "white": "57.0",
        "black": "15.3",
        "asian": "25.5",
        "hispanicOrLatino": "3.7"
      }
    },
    "wageData": {
      "2018": {
        "workers": "85",
        "median": "1,437",
        "maleWorkers": "41",
        "maleMedian": "-",
        "femaleWorkers": "43",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous mathematical science occupations": {
    "ageData": {
      "2018": {
        "total": "8",
        "16to19": "0",
        "20to24": "2",
        "25to34": "3",
        "35to44": "0",
        "45to54": "2",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Miscellaneous mathematical science occupations",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "8",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Architecture and engineering occupations": {
    "ageData": {
      "2018": {
        "total": "3,263",
        "16to19": "17",
        "20to24": "214",
        "25to34": "864",
        "35to44": "698",
        "45to54": "669",
        "55to64": "636",
        "65+": "165",
        "medianAge": "636"
      }
    },
    "title": "Architecture and engineering occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "3,263",
        "women": "15.9",
        "white": "79.3",
        "black": "6.5",
        "asian": "11.9",
        "hispanicOrLatino": "8.9"
      }
    },
    "wageData": {
      "2018": {
        "workers": "2,994",
        "median": "1,484",
        "maleWorkers": "2,541",
        "maleMedian": "1,528",
        "femaleWorkers": "453",
        "femaleMedian": "1,259"
      }
    }
  },
  "Architects, except naval": {
    "ageData": {
      "2018": {
        "total": "239",
        "16to19": "0",
        "20to24": "7",
        "25to34": "63",
        "35to44": "55",
        "45to54": "53",
        "55to64": "43",
        "65+": "18",
        "medianAge": "43"
      }
    },
    "title": "Architects, except naval",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "239",
        "women": "29.7",
        "white": "83.5",
        "black": "4.5",
        "asian": "11.5",
        "hispanicOrLatino": "10.1"
      }
    },
    "wageData": {
      "2018": {
        "workers": "170",
        "median": "1,464",
        "maleWorkers": "118",
        "maleMedian": "1,538",
        "femaleWorkers": "52",
        "femaleMedian": "1,301"
      }
    }
  },
  "Surveyors, cartographers, and photogrammetrists": {
    "ageData": {
      "2018": {
        "total": "47",
        "16to19": "0",
        "20to24": "2",
        "25to34": "8",
        "35to44": "13",
        "45to54": "6",
        "55to64": "12",
        "65+": "5",
        "medianAge": "12"
      }
    },
    "title": "Surveyors, cartographers, and photogrammetrists",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "47",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "35",
        "median": "-",
        "maleWorkers": "28",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Aerospace engineers": {
    "ageData": {
      "2018": {
        "total": "147",
        "16to19": "0",
        "20to24": "13",
        "25to34": "44",
        "35to44": "25",
        "45to54": "29",
        "55to64": "30",
        "65+": "6",
        "medianAge": "30"
      }
    },
    "title": "Aerospace engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "147",
        "women": "13.4",
        "white": "70.3",
        "black": "7.1",
        "asian": "17.6",
        "hispanicOrLatino": "7.8"
      }
    },
    "wageData": {
      "2018": {
        "workers": "145",
        "median": "1,788",
        "maleWorkers": "129",
        "maleMedian": "1,836",
        "femaleWorkers": "16",
        "femaleMedian": "-"
      }
    }
  },
  "Agricultural engineers": {
    "ageData": {
      "2018": {
        "total": "5",
        "16to19": "0",
        "20to24": "1",
        "25to34": "2",
        "35to44": "0",
        "45to54": "2",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Agricultural engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Biomedical engineers": {
    "ageData": {
      "2018": {
        "total": "11",
        "16to19": "0",
        "20to24": "2",
        "25to34": "3",
        "35to44": "4",
        "45to54": "1",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Biomedical engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "11",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Chemical engineers": {
    "ageData": {
      "2018": {
        "total": "72",
        "16to19": "0",
        "20to24": "1",
        "25to34": "19",
        "35to44": "20",
        "45to54": "12",
        "55to64": "15",
        "65+": "5",
        "medianAge": "15"
      }
    },
    "title": "Chemical engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "72",
        "women": "16.3",
        "white": "73.7",
        "black": "8.9",
        "asian": "17.3",
        "hispanicOrLatino": "1.9"
      }
    },
    "wageData": {
      "2018": {
        "workers": "65",
        "median": "1,858",
        "maleWorkers": "57",
        "maleMedian": "1,858",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Civil engineers": {
    "ageData": {
      "2018": {
        "total": "456",
        "16to19": "1",
        "20to24": "34",
        "25to34": "149",
        "35to44": "89",
        "45to54": "81",
        "55to64": "71",
        "65+": "33",
        "medianAge": "71"
      }
    },
    "title": "Civil engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "456",
        "women": "14.8",
        "white": "84.8",
        "black": "4.4",
        "asian": "9.3",
        "hispanicOrLatino": "7.9"
      }
    },
    "wageData": {
      "2018": {
        "workers": "410",
        "median": "1,495",
        "maleWorkers": "346",
        "maleMedian": "1,551",
        "femaleWorkers": "63",
        "femaleMedian": "1,282"
      }
    }
  },
  "Computer hardware engineers": {
    "ageData": {
      "2018": {
        "total": "71",
        "16to19": "0",
        "20to24": "5",
        "25to34": "21",
        "35to44": "16",
        "45to54": "15",
        "55to64": "11",
        "65+": "3",
        "medianAge": "11"
      }
    },
    "title": "Computer hardware engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "71",
        "women": "18.9",
        "white": "58.0",
        "black": "6.9",
        "asian": "30.9",
        "hispanicOrLatino": "14.1"
      }
    },
    "wageData": {
      "2018": {
        "workers": "70",
        "median": "1,772",
        "maleWorkers": "60",
        "maleMedian": "1,852",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "Electrical and electronics engineers": {
    "ageData": {
      "2018": {
        "total": "269",
        "16to19": "0",
        "20to24": "12",
        "25to34": "66",
        "35to44": "56",
        "45to54": "65",
        "55to64": "56",
        "65+": "14",
        "medianAge": "56"
      }
    },
    "title": "Electrical and electronics engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "269",
        "women": "9.4",
        "white": "70.2",
        "black": "8.4",
        "asian": "17.8",
        "hispanicOrLatino": "8.1"
      }
    },
    "wageData": {
      "2018": {
        "workers": "282",
        "median": "1,646",
        "maleWorkers": "251",
        "maleMedian": "1,661",
        "femaleWorkers": "31",
        "femaleMedian": "-"
      }
    }
  },
  "Environmental engineers": {
    "ageData": {
      "2018": {
        "total": "36",
        "16to19": "0",
        "20to24": "1",
        "25to34": "8",
        "35to44": "4",
        "45to54": "11",
        "55to64": "9",
        "65+": "3",
        "medianAge": "9"
      }
    },
    "title": "Environmental engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "36",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "35",
        "median": "-",
        "maleWorkers": "30",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Industrial engineers, including health and safety": {
    "ageData": {
      "2018": {
        "total": "246",
        "16to19": "1",
        "20to24": "12",
        "25to34": "68",
        "35to44": "57",
        "45to54": "42",
        "55to64": "56",
        "65+": "10",
        "medianAge": "56"
      }
    },
    "title": "Industrial engineers, including health and safety",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "246",
        "women": "23.0",
        "white": "83.2",
        "black": "4.9",
        "asian": "9.0",
        "hispanicOrLatino": "7.4"
      }
    },
    "wageData": {
      "2018": {
        "workers": "245",
        "median": "1,499",
        "maleWorkers": "191",
        "maleMedian": "1,571",
        "femaleWorkers": "54",
        "femaleMedian": "1,163"
      }
    }
  },
  "Marine engineers and naval architects": {
    "ageData": {
      "2018": {
        "total": "17",
        "16to19": "0",
        "20to24": "1",
        "25to34": "7",
        "35to44": "4",
        "45to54": "2",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Marine engineers and naval architects",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "17",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "15",
        "median": "-",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Materials engineers": {
    "ageData": {
      "2018": {
        "total": "41",
        "16to19": "0",
        "20to24": "3",
        "25to34": "8",
        "35to44": "12",
        "45to54": "9",
        "55to64": "9",
        "65+": "0",
        "medianAge": "9"
      }
    },
    "title": "Materials engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "41",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "42",
        "median": "-",
        "maleWorkers": "32",
        "maleMedian": "-",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Mechanical engineers": {
    "ageData": {
      "2018": {
        "total": "333",
        "16to19": "1",
        "20to24": "27",
        "25to34": "95",
        "35to44": "73",
        "45to54": "63",
        "55to64": "62",
        "65+": "11",
        "medianAge": "62"
      }
    },
    "title": "Mechanical engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "333",
        "women": "10.9",
        "white": "80.0",
        "black": "3.5",
        "asian": "15.0",
        "hispanicOrLatino": "5.5"
      }
    },
    "wageData": {
      "2018": {
        "workers": "313",
        "median": "1,562",
        "maleWorkers": "282",
        "maleMedian": "1,581",
        "femaleWorkers": "31",
        "femaleMedian": "-"
      }
    }
  },
  "Mining and geological engineers, including mining safety engineers": {
    "ageData": {
      "2018": {
        "total": "8",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "0",
        "45to54": "3",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Mining and geological engineers, including mining safety engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "8",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Nuclear engineers": {
    "ageData": {
      "2018": {
        "total": "14",
        "16to19": "0",
        "20to24": "1",
        "25to34": "5",
        "35to44": "2",
        "45to54": "3",
        "55to64": "1",
        "65+": "2",
        "medianAge": "1"
      }
    },
    "title": "Nuclear engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "14",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Petroleum engineers": {
    "ageData": {
      "2018": {
        "total": "27",
        "16to19": "0",
        "20to24": "4",
        "25to34": "6",
        "35to44": "9",
        "45to54": "4",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Petroleum engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "27",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "27",
        "median": "-",
        "maleWorkers": "22",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Engineers, all other": {
    "ageData": {
      "2018": {
        "total": "576",
        "16to19": "1",
        "20to24": "27",
        "25to34": "157",
        "35to44": "137",
        "45to54": "112",
        "55to64": "115",
        "65+": "27",
        "medianAge": "115"
      }
    },
    "title": "Engineers, all other",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "576",
        "women": "12.7",
        "white": "77.0",
        "black": "5.9",
        "asian": "15.4",
        "hispanicOrLatino": "9.2"
      }
    },
    "wageData": {
      "2018": {
        "workers": "528",
        "median": "1,575",
        "maleWorkers": "463",
        "maleMedian": "1,610",
        "femaleWorkers": "65",
        "femaleMedian": "1,356"
      }
    }
  },
  "Drafters": {
    "ageData": {
      "2018": {
        "total": "133",
        "16to19": "4",
        "20to24": "15",
        "25to34": "20",
        "35to44": "23",
        "45to54": "35",
        "55to64": "28",
        "65+": "8",
        "medianAge": "28"
      }
    },
    "title": "Drafters",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "133",
        "women": "20.6",
        "white": "88.5",
        "black": "4.8",
        "asian": "4.6",
        "hispanicOrLatino": "11.0"
      }
    },
    "wageData": {
      "2018": {
        "workers": "112",
        "median": "1,021",
        "maleWorkers": "91",
        "maleMedian": "1,029",
        "femaleWorkers": "22",
        "femaleMedian": "-"
      }
    }
  },
  "Engineering technicians, except drafters": {
    "ageData": {
      "2018": {
        "total": "439",
        "16to19": "7",
        "20to24": "33",
        "25to34": "98",
        "35to44": "88",
        "45to54": "100",
        "55to64": "95",
        "65+": "18",
        "medianAge": "95"
      }
    },
    "title": "Engineering technicians, except drafters",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "439",
        "women": "18.1",
        "white": "78.4",
        "black": "12.0",
        "asian": "6.2",
        "hispanicOrLatino": "11.1"
      }
    },
    "wageData": {
      "2018": {
        "workers": "398",
        "median": "1,129",
        "maleWorkers": "332",
        "maleMedian": "1,154",
        "femaleWorkers": "66",
        "femaleMedian": "934"
      }
    }
  },
  "Surveying and mapping technicians": {
    "ageData": {
      "2018": {
        "total": "76",
        "16to19": "1",
        "20to24": "13",
        "25to34": "13",
        "35to44": "12",
        "45to54": "23",
        "55to64": "11",
        "65+": "3",
        "medianAge": "11"
      }
    },
    "title": "Surveying and mapping technicians",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "76",
        "women": "3.2",
        "white": "90.8",
        "black": "4.3",
        "asian": "1.0",
        "hispanicOrLatino": "12.9"
      }
    },
    "wageData": {
      "2018": {
        "workers": "63",
        "median": "984",
        "maleWorkers": "60",
        "maleMedian": "995",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Life, physical, and social science occupations": {
    "ageData": {
      "2018": {
        "total": "1,529",
        "16to19": "10",
        "20to24": "135",
        "25to34": "429",
        "35to44": "345",
        "45to54": "283",
        "55to64": "237",
        "65+": "89",
        "medianAge": "237"
      }
    },
    "title": "Life, physical, and social science occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "1,529",
        "women": "46.7",
        "white": "78.1",
        "black": "7.1",
        "asian": "12.8",
        "hispanicOrLatino": "8.7"
      }
    },
    "wageData": {
      "2018": {
        "workers": "1,308",
        "median": "1,270",
        "maleWorkers": "733",
        "maleMedian": "1,357",
        "femaleWorkers": "575",
        "femaleMedian": "1,156"
      }
    }
  },
  "Agricultural and food scientists": {
    "ageData": {
      "2018": {
        "total": "37",
        "16to19": "0",
        "20to24": "5",
        "25to34": "12",
        "35to44": "7",
        "45to54": "5",
        "55to64": "7",
        "65+": "1",
        "medianAge": "7"
      }
    },
    "title": "Agricultural and food scientists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "37",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "30",
        "median": "-",
        "maleWorkers": "16",
        "maleMedian": "-",
        "femaleWorkers": "13",
        "femaleMedian": "-"
      }
    }
  },
  "Biological scientists": {
    "ageData": {
      "2018": {
        "total": "104",
        "16to19": "0",
        "20to24": "9",
        "25to34": "25",
        "35to44": "31",
        "45to54": "20",
        "55to64": "12",
        "65+": "6",
        "medianAge": "12"
      }
    },
    "title": "Biological scientists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "104",
        "women": "47.5",
        "white": "83.6",
        "black": "1.6",
        "asian": "13.7",
        "hispanicOrLatino": "3.6"
      }
    },
    "wageData": {
      "2018": {
        "workers": "101",
        "median": "1,250",
        "maleWorkers": "55",
        "maleMedian": "1,164",
        "femaleWorkers": "46",
        "femaleMedian": "-"
      }
    }
  },
  "Conservation scientists and foresters": {
    "ageData": {
      "2018": {
        "total": "22",
        "16to19": "1",
        "20to24": "3",
        "25to34": "5",
        "35to44": "7",
        "45to54": "3",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Conservation scientists and foresters",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "22",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "17",
        "median": "-",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Medical scientists": {
    "ageData": {
      "2018": {
        "total": "169",
        "16to19": "2",
        "20to24": "10",
        "25to34": "58",
        "35to44": "43",
        "45to54": "23",
        "55to64": "30",
        "65+": "3",
        "medianAge": "30"
      }
    },
    "title": "Medical scientists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "169",
        "women": "52.1",
        "white": "68.8",
        "black": "9.5",
        "asian": "20.7",
        "hispanicOrLatino": "8.6"
      }
    },
    "wageData": {
      "2018": {
        "workers": "160",
        "median": "1,319",
        "maleWorkers": "80",
        "maleMedian": "1,416",
        "femaleWorkers": "81",
        "femaleMedian": "1,156"
      }
    }
  },
  "Life scientists, all other": {
    "ageData": {
      "2018": {
        "total": "1",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "1",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Life scientists, all other",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Astronomers and physicists": {
    "ageData": {
      "2018": {
        "total": "20",
        "16to19": "0",
        "20to24": "2",
        "25to34": "6",
        "35to44": "2",
        "45to54": "5",
        "55to64": "2",
        "65+": "3",
        "medianAge": "2"
      }
    },
    "title": "Astronomers and physicists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "20",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "14",
        "median": "-",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Atmospheric and space scientists": {
    "ageData": {
      "2018": {
        "total": "12",
        "16to19": "0",
        "20to24": "0",
        "25to34": "5",
        "35to44": "1",
        "45to54": "3",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Atmospheric and space scientists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "12",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Chemists and materials scientists": {
    "ageData": {
      "2018": {
        "total": "118",
        "16to19": "0",
        "20to24": "14",
        "25to34": "29",
        "35to44": "23",
        "45to54": "25",
        "55to64": "21",
        "65+": "5",
        "medianAge": "21"
      }
    },
    "title": "Chemists and materials scientists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "118",
        "women": "37.7",
        "white": "78.1",
        "black": "4.2",
        "asian": "16.8",
        "hispanicOrLatino": "7.7"
      }
    },
    "wageData": {
      "2018": {
        "workers": "115",
        "median": "1,284",
        "maleWorkers": "70",
        "maleMedian": "1,362",
        "femaleWorkers": "45",
        "femaleMedian": "-"
      }
    }
  },
  "Environmental scientists and geoscientists": {
    "ageData": {
      "2018": {
        "total": "100",
        "16to19": "1",
        "20to24": "7",
        "25to34": "29",
        "35to44": "23",
        "45to54": "16",
        "55to64": "20",
        "65+": "5",
        "medianAge": "20"
      }
    },
    "title": "Environmental scientists and geoscientists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "100",
        "women": "33.1",
        "white": "90.4",
        "black": "7.8",
        "asian": "1.1",
        "hispanicOrLatino": "8.4"
      }
    },
    "wageData": {
      "2018": {
        "workers": "80",
        "median": "1,336",
        "maleWorkers": "52",
        "maleMedian": "1,427",
        "femaleWorkers": "28",
        "femaleMedian": "-"
      }
    }
  },
  "Physical scientists, all other": {
    "ageData": {
      "2018": {
        "total": "305",
        "16to19": "1",
        "20to24": "19",
        "25to34": "113",
        "35to44": "74",
        "45to54": "51",
        "55to64": "36",
        "65+": "10",
        "medianAge": "36"
      }
    },
    "title": "Physical scientists, all other",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "305",
        "women": "43.9",
        "white": "65.9",
        "black": "4.7",
        "asian": "26.0",
        "hispanicOrLatino": "6.1"
      }
    },
    "wageData": {
      "2018": {
        "workers": "289",
        "median": "1,455",
        "maleWorkers": "169",
        "maleMedian": "1,582",
        "femaleWorkers": "120",
        "femaleMedian": "1,341"
      }
    }
  },
  "Economists": {
    "ageData": {
      "2018": {
        "total": "36",
        "16to19": "0",
        "20to24": "1",
        "25to34": "11",
        "35to44": "8",
        "45to54": "7",
        "55to64": "8",
        "65+": "1",
        "medianAge": "8"
      }
    },
    "title": "Economists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "36",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "35",
        "median": "-",
        "maleWorkers": "29",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Survey researchers": {
    "ageData": {
      "2018": {
        "total": "3",
        "16to19": "0",
        "20to24": "1",
        "25to34": "0",
        "35to44": "1",
        "45to54": "0",
        "55to64": "0",
        "65+": "1",
        "medianAge": "0"
      }
    },
    "title": "Survey researchers",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "0",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Psychologists": {
    "ageData": {
      "2018": {
        "total": "224",
        "16to19": "0",
        "20to24": "1",
        "25to34": "38",
        "35to44": "52",
        "45to54": "63",
        "55to64": "33",
        "65+": "37",
        "medianAge": "33"
      }
    },
    "title": "Psychologists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "224",
        "women": "75.9",
        "white": "89.0",
        "black": "7.7",
        "asian": "3.0",
        "hispanicOrLatino": "11.2"
      }
    },
    "wageData": {
      "2018": {
        "workers": "134",
        "median": "1,331",
        "maleWorkers": "35",
        "maleMedian": "-",
        "femaleWorkers": "99",
        "femaleMedian": "1,255"
      }
    }
  },
  "Sociologists": {
    "ageData": {
      "2018": {
        "total": "5",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "3",
        "45to54": "0",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Sociologists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Urban and regional planners": {
    "ageData": {
      "2018": {
        "total": "29",
        "16to19": "0",
        "20to24": "3",
        "25to34": "5",
        "35to44": "8",
        "45to54": "11",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Urban and regional planners",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "29",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "28",
        "median": "-",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "13",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous social scientists and related workers": {
    "ageData": {
      "2018": {
        "total": "46",
        "16to19": "1",
        "20to24": "6",
        "25to34": "7",
        "35to44": "5",
        "45to54": "8",
        "55to64": "12",
        "65+": "7",
        "medianAge": "12"
      }
    },
    "title": "Miscellaneous social scientists and related workers",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "46",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "39",
        "median": "-",
        "maleWorkers": "25",
        "maleMedian": "-",
        "femaleWorkers": "14",
        "femaleMedian": "-"
      }
    }
  },
  "Agricultural and food science technicians": {
    "ageData": {
      "2018": {
        "total": "36",
        "16to19": "0",
        "20to24": "6",
        "25to34": "6",
        "35to44": "10",
        "45to54": "7",
        "55to64": "4",
        "65+": "3",
        "medianAge": "4"
      }
    },
    "title": "Agricultural and food science technicians",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "36",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "31",
        "median": "-",
        "maleWorkers": "24",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Biological technicians": {
    "ageData": {
      "2018": {
        "total": "19",
        "16to19": "0",
        "20to24": "4",
        "25to34": "9",
        "35to44": "3",
        "45to54": "2",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Biological technicians",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "19",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "17",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Chemical technicians": {
    "ageData": {
      "2018": {
        "total": "68",
        "16to19": "1",
        "20to24": "8",
        "25to34": "18",
        "35to44": "16",
        "45to54": "11",
        "55to64": "12",
        "65+": "0",
        "medianAge": "12"
      }
    },
    "title": "Chemical technicians",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "68",
        "women": "25.3",
        "white": "85.7",
        "black": "8.9",
        "asian": "3.0",
        "hispanicOrLatino": "17.6"
      }
    },
    "wageData": {
      "2018": {
        "workers": "58",
        "median": "1,002",
        "maleWorkers": "42",
        "maleMedian": "-",
        "femaleWorkers": "17",
        "femaleMedian": "-"
      }
    }
  },
  "Geological and petroleum technicians": {
    "ageData": {
      "2018": {
        "total": "12",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "4",
        "45to54": "4",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Geological and petroleum technicians",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "12",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "11",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Nuclear technicians": {
    "ageData": {
      "2018": {
        "total": "10",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "0",
        "45to54": "3",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Nuclear technicians",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "10",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "11",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Social science research assistants": {
    "ageData": {
      "2018": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Social science research assistants",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "0",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous life, physical, and social science technicians": {
    "ageData": {
      "2018": {
        "total": "152",
        "16to19": "4",
        "20to24": "33",
        "25to34": "46",
        "35to44": "23",
        "45to54": "15",
        "55to64": "26",
        "65+": "5",
        "medianAge": "26"
      }
    },
    "title": "Miscellaneous life, physical, and social science technicians",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "152",
        "women": "47.7",
        "white": "77.8",
        "black": "9.1",
        "asian": "11.5",
        "hispanicOrLatino": "11.2"
      }
    },
    "wageData": {
      "2018": {
        "workers": "124",
        "median": "862",
        "maleWorkers": "66",
        "maleMedian": "893",
        "femaleWorkers": "58",
        "femaleMedian": "856"
      }
    }
  },
  "Community and social service occupations": {
    "ageData": {
      "2018": {
        "total": "2,680",
        "16to19": "24",
        "20to24": "145",
        "25to34": "646",
        "35to44": "598",
        "45to54": "561",
        "55to64": "483",
        "65+": "225",
        "medianAge": "483"
      }
    },
    "title": "Community and social service occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "2,680",
        "women": "66.5",
        "white": "71.8",
        "black": "20.4",
        "asian": "3.9",
        "hispanicOrLatino": "12.1"
      }
    },
    "wageData": {
      "2018": {
        "workers": "2,223",
        "median": "913",
        "maleWorkers": "754",
        "maleMedian": "984",
        "femaleWorkers": "1,469",
        "femaleMedian": "886"
      }
    }
  },
  "Counselors": {
    "ageData": {
      "2018": {
        "total": "895",
        "16to19": "14",
        "20to24": "56",
        "25to34": "223",
        "35to44": "223",
        "45to54": "183",
        "55to64": "140",
        "65+": "56",
        "medianAge": "140"
      }
    },
    "title": "Counselors",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "895",
        "women": "72.0",
        "white": "70.0",
        "black": "22.2",
        "asian": "3.9",
        "hispanicOrLatino": "12.7"
      }
    },
    "wageData": {
      "2018": {
        "workers": "705",
        "median": "941",
        "maleWorkers": "199",
        "maleMedian": "1,004",
        "femaleWorkers": "506",
        "femaleMedian": "913"
      }
    }
  },
  "Social workers": {
    "ageData": {
      "2018": {
        "total": "835",
        "16to19": "3",
        "20to24": "40",
        "25to34": "227",
        "35to44": "201",
        "45to54": "186",
        "55to64": "132",
        "65+": "46",
        "medianAge": "132"
      }
    },
    "title": "Social workers",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "835",
        "women": "81.6",
        "white": "68.9",
        "black": "23.6",
        "asian": "3.5",
        "hispanicOrLatino": "13.4"
      }
    },
    "wageData": {
      "2018": {
        "workers": "753",
        "median": "907",
        "maleWorkers": "141",
        "maleMedian": "958",
        "femaleWorkers": "611",
        "femaleMedian": "897"
      }
    }
  },
  "Probation officers and correctional treatment specialists": {
    "ageData": {
      "2018": {
        "total": "104",
        "16to19": "0",
        "20to24": "2",
        "25to34": "36",
        "35to44": "28",
        "45to54": "29",
        "55to64": "9",
        "65+": "2",
        "medianAge": "9"
      }
    },
    "title": "Probation officers and correctional treatment specialists",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "104",
        "women": "56.9",
        "white": "69.3",
        "black": "27.0",
        "asian": "0.6",
        "hispanicOrLatino": "15.9"
      }
    },
    "wageData": {
      "2018": {
        "workers": "105",
        "median": "944",
        "maleWorkers": "45",
        "maleMedian": "-",
        "femaleWorkers": "60",
        "femaleMedian": "930"
      }
    }
  },
  "Social and human service assistants": {
    "ageData": {
      "2018": {
        "total": "208",
        "16to19": "4",
        "20to24": "24",
        "25to34": "57",
        "35to44": "36",
        "45to54": "41",
        "55to64": "36",
        "65+": "10",
        "medianAge": "36"
      }
    },
    "title": "Social and human service assistants",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "208",
        "women": "77.7",
        "white": "67.7",
        "black": "21.1",
        "asian": "4.1",
        "hispanicOrLatino": "18.6"
      }
    },
    "wageData": {
      "2018": {
        "workers": "166",
        "median": "749",
        "maleWorkers": "33",
        "maleMedian": "-",
        "femaleWorkers": "133",
        "femaleMedian": "752"
      }
    }
  },
  "Miscellaneous community and social service specialists, including health educators and community health workers": {
    "ageData": {
      "2018": {
        "total": "71",
        "16to19": "0",
        "20to24": "3",
        "25to34": "20",
        "35to44": "16",
        "45to54": "12",
        "55to64": "14",
        "65+": "5",
        "medianAge": "14"
      }
    },
    "title": "Miscellaneous community and social service specialists, including health educators and community health workers",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "71",
        "women": "76.3",
        "white": "68.8",
        "black": "21.5",
        "asian": "3.3",
        "hispanicOrLatino": "12.4"
      }
    },
    "wageData": {
      "2018": {
        "workers": "62",
        "median": "881",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "48",
        "femaleMedian": "-"
      }
    }
  },
  "Clergy": {
    "ageData": {
      "2018": {
        "total": "415",
        "16to19": "0",
        "20to24": "10",
        "25to34": "54",
        "35to44": "67",
        "45to54": "85",
        "55to64": "111",
        "65+": "88",
        "medianAge": "111"
      }
    },
    "title": "Clergy",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "415",
        "women": "22.4",
        "white": "80.3",
        "black": "12.5",
        "asian": "4.3",
        "hispanicOrLatino": "6.4"
      }
    },
    "wageData": {
      "2018": {
        "workers": "337",
        "median": "990",
        "maleWorkers": "268",
        "maleMedian": "1,036",
        "femaleWorkers": "68",
        "femaleMedian": "855"
      }
    }
  },
  "Directors, religious activities and education": {
    "ageData": {
      "2018": {
        "total": "76",
        "16to19": "0",
        "20to24": "7",
        "25to34": "15",
        "35to44": "16",
        "45to54": "13",
        "55to64": "19",
        "65+": "7",
        "medianAge": "19"
      }
    },
    "title": "Directors, religious activities and education",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "76",
        "women": "57.8",
        "white": "79.7",
        "black": "8.2",
        "asian": "10.6",
        "hispanicOrLatino": "8.3"
      }
    },
    "wageData": {
      "2018": {
        "workers": "51",
        "median": "927",
        "maleWorkers": "27",
        "maleMedian": "-",
        "femaleWorkers": "25",
        "femaleMedian": "-"
      }
    }
  },
  "Religious workers, all other": {
    "ageData": {
      "2018": {
        "total": "76",
        "16to19": "3",
        "20to24": "4",
        "25to34": "13",
        "35to44": "11",
        "45to54": "12",
        "55to64": "22",
        "65+": "11",
        "medianAge": "22"
      }
    },
    "title": "Religious workers, all other",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "76",
        "women": "59.2",
        "white": "88.3",
        "black": "6.9",
        "asian": "4.0",
        "hispanicOrLatino": "5.1"
      }
    },
    "wageData": {
      "2018": {
        "workers": "45",
        "median": "-",
        "maleWorkers": "27",
        "maleMedian": "-",
        "femaleWorkers": "18",
        "femaleMedian": "-"
      }
    }
  },
  "Legal occupations": {
    "ageData": {
      "2018": {
        "total": "1,891",
        "16to19": "3",
        "20to24": "39",
        "25to34": "380",
        "35to44": "443",
        "45to54": "424",
        "55to64": "376",
        "65+": "226",
        "medianAge": "376"
      }
    },
    "title": "Legal occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "1,891",
        "women": "51.6",
        "white": "85.7",
        "black": "7.3",
        "asian": "4.7",
        "hispanicOrLatino": "9.9"
      }
    },
    "wageData": {
      "2018": {
        "workers": "1,466",
        "median": "1,467",
        "maleWorkers": "651",
        "maleMedian": "1,910",
        "femaleWorkers": "815",
        "femaleMedian": "1,243"
      }
    }
  },
  "Lawyers": {
    "ageData": {
      "2018": {
        "total": "1,199",
        "16to19": "1",
        "20to24": "4",
        "25to34": "226",
        "35to44": "307",
        "45to54": "266",
        "55to64": "224",
        "65+": "172",
        "medianAge": "224"
      }
    },
    "title": "Lawyers",
    "depth": 4,
    "parent": "Legal occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "1,199",
        "women": "37.4",
        "white": "88.0",
        "black": "5.5",
        "asian": "4.9",
        "hispanicOrLatino": "6.1"
      }
    },
    "wageData": {
      "2018": {
        "workers": "853",
        "median": "1,947",
        "maleWorkers": "509",
        "maleMedian": "2,202",
        "femaleWorkers": "344",
        "femaleMedian": "1,762"
      }
    }
  },
  "Judicial law clerks": {
    "ageData": {
      "2018": {
        "total": "13",
        "16to19": "0",
        "20to24": "0",
        "25to34": "9",
        "35to44": "1",
        "45to54": "0",
        "55to64": "1",
        "65+": "1",
        "medianAge": "1"
      }
    },
    "title": "Judicial law clerks",
    "depth": 4,
    "parent": "Legal occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "13",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "15",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Judges, magistrates, and other judicial workers": {
    "ageData": {
      "2018": {
        "total": "80",
        "16to19": "0",
        "20to24": "0",
        "25to34": "5",
        "35to44": "12",
        "45to54": "17",
        "55to64": "29",
        "65+": "17",
        "medianAge": "29"
      }
    },
    "title": "Judges, magistrates, and other judicial workers",
    "depth": 4,
    "parent": "Legal occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "80",
        "women": "32.3",
        "white": "85.4",
        "black": "13.3",
        "asian": "1.2",
        "hispanicOrLatino": "6.7"
      }
    },
    "wageData": {
      "2018": {
        "workers": "69",
        "median": "1,602",
        "maleWorkers": "49",
        "maleMedian": "-",
        "femaleWorkers": "19",
        "femaleMedian": "-"
      }
    }
  },
  "Paralegals and legal assistants": {
    "ageData": {
      "2018": {
        "total": "444",
        "16to19": "2",
        "20to24": "30",
        "25to34": "104",
        "35to44": "92",
        "45to54": "97",
        "55to64": "93",
        "65+": "25",
        "medianAge": "93"
      }
    },
    "title": "Paralegals and legal assistants",
    "depth": 4,
    "parent": "Legal occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "444",
        "women": "86.4",
        "white": "79.1",
        "black": "11.3",
        "asian": "5.3",
        "hispanicOrLatino": "18.5"
      }
    },
    "wageData": {
      "2018": {
        "workers": "413",
        "median": "948",
        "maleWorkers": "54",
        "maleMedian": "917",
        "femaleWorkers": "359",
        "femaleMedian": "953"
      }
    }
  },
  "Miscellaneous legal support workers": {
    "ageData": {
      "2018": {
        "total": "156",
        "16to19": "0",
        "20to24": "5",
        "25to34": "36",
        "35to44": "32",
        "45to54": "43",
        "55to64": "28",
        "65+": "11",
        "medianAge": "28"
      }
    },
    "title": "Miscellaneous legal support workers",
    "depth": 4,
    "parent": "Legal occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "156",
        "women": "73.1",
        "white": "86.8",
        "black": "7.2",
        "asian": "2.9",
        "hispanicOrLatino": "16.6"
      }
    },
    "wageData": {
      "2018": {
        "workers": "117",
        "median": "1,143",
        "maleWorkers": "32",
        "maleMedian": "-",
        "femaleWorkers": "85",
        "femaleMedian": "1,109"
      }
    }
  },
  "Education, training, and library occupations": {
    "ageData": {
      "2018": {
        "total": "9,313",
        "16to19": "122",
        "20to24": "670",
        "25to34": "2,142",
        "35to44": "2,204",
        "45to54": "2,085",
        "55to64": "1,451",
        "65+": "639",
        "medianAge": "1,451"
      }
    },
    "title": "Education, training, and library occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "9,313",
        "women": "73.2",
        "white": "81.5",
        "black": "10.6",
        "asian": "5.2",
        "hispanicOrLatino": "10.7"
      }
    },
    "wageData": {
      "2018": {
        "workers": "7,166",
        "median": "1,002",
        "maleWorkers": "1,915",
        "maleMedian": "1,235",
        "femaleWorkers": "5,251",
        "femaleMedian": "934"
      }
    }
  },
  "Postsecondary teachers": {
    "ageData": {
      "2018": {
        "total": "1,417",
        "16to19": "10",
        "20to24": "119",
        "25to34": "305",
        "35to44": "304",
        "45to54": "275",
        "55to64": "241",
        "65+": "164",
        "medianAge": "241"
      }
    },
    "title": "Postsecondary teachers",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "1,417",
        "women": "49.0",
        "white": "75.7",
        "black": "7.9",
        "asian": "13.7",
        "hispanicOrLatino": "7.3"
      }
    },
    "wageData": {
      "2018": {
        "workers": "994",
        "median": "1,439",
        "maleWorkers": "534",
        "maleMedian": "1,603",
        "femaleWorkers": "460",
        "femaleMedian": "1,253"
      }
    }
  },
  "Preschool and kindergarten teachers": {
    "ageData": {
      "2018": {
        "total": "716",
        "16to19": "10",
        "20to24": "83",
        "25to34": "196",
        "35to44": "163",
        "45to54": "149",
        "55to64": "85",
        "65+": "29",
        "medianAge": "85"
      }
    },
    "title": "Preschool and kindergarten teachers",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "716",
        "women": "97.6",
        "white": "75.8",
        "black": "16.0",
        "asian": "4.0",
        "hispanicOrLatino": "14.5"
      }
    },
    "wageData": {
      "2018": {
        "workers": "568",
        "median": "648",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "555",
        "femaleMedian": "643"
      }
    }
  },
  "Elementary and middle school teachers": {
    "ageData": {
      "2018": {
        "total": "3,421",
        "16to19": "11",
        "20to24": "148",
        "25to34": "850",
        "35to44": "925",
        "45to54": "820",
        "55to64": "481",
        "65+": "185",
        "medianAge": "481"
      }
    },
    "title": "Elementary and middle school teachers",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "3,421",
        "women": "79.8",
        "white": "83.5",
        "black": "11.1",
        "asian": "3.3",
        "hispanicOrLatino": "10.3"
      }
    },
    "wageData": {
      "2018": {
        "workers": "3,030",
        "median": "1,010",
        "maleWorkers": "620",
        "maleMedian": "1,148",
        "femaleWorkers": "2,410",
        "femaleMedian": "982"
      }
    }
  },
  "Secondary school teachers": {
    "ageData": {
      "2018": {
        "total": "1,062",
        "16to19": "3",
        "20to24": "46",
        "25to34": "235",
        "35to44": "300",
        "45to54": "267",
        "55to64": "153",
        "65+": "58",
        "medianAge": "153"
      }
    },
    "title": "Secondary school teachers",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "1,062",
        "women": "58.0",
        "white": "88.3",
        "black": "7.3",
        "asian": "2.6",
        "hispanicOrLatino": "9.0"
      }
    },
    "wageData": {
      "2018": {
        "workers": "948",
        "median": "1,141",
        "maleWorkers": "392",
        "maleMedian": "1,226",
        "femaleWorkers": "556",
        "femaleMedian": "1,092"
      }
    }
  },
  "Special education teachers": {
    "ageData": {
      "2018": {
        "total": "369",
        "16to19": "0",
        "20to24": "16",
        "25to34": "85",
        "35to44": "98",
        "45to54": "89",
        "55to64": "61",
        "65+": "21",
        "medianAge": "61"
      }
    },
    "title": "Special education teachers",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "369",
        "women": "87.5",
        "white": "87.8",
        "black": "8.5",
        "asian": "1.6",
        "hispanicOrLatino": "7.6"
      }
    },
    "wageData": {
      "2018": {
        "workers": "324",
        "median": "1,039",
        "maleWorkers": "43",
        "maleMedian": "-",
        "femaleWorkers": "281",
        "femaleMedian": "1,042"
      }
    }
  },
  "Other teachers and instructors": {
    "ageData": {
      "2018": {
        "total": "905",
        "16to19": "49",
        "20to24": "109",
        "25to34": "208",
        "35to44": "139",
        "45to54": "165",
        "55to64": "149",
        "65+": "86",
        "medianAge": "149"
      }
    },
    "title": "Other teachers and instructors",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "905",
        "women": "61.9",
        "white": "78.8",
        "black": "11.4",
        "asian": "6.1",
        "hispanicOrLatino": "10.5"
      }
    },
    "wageData": {
      "2018": {
        "workers": "388",
        "median": "957",
        "maleWorkers": "172",
        "maleMedian": "1,095",
        "femaleWorkers": "215",
        "femaleMedian": "840"
      }
    }
  },
  "Archivists, curators, and museum technicians": {
    "ageData": {
      "2018": {
        "total": "45",
        "16to19": "1",
        "20to24": "5",
        "25to34": "7",
        "35to44": "9",
        "45to54": "10",
        "55to64": "8",
        "65+": "6",
        "medianAge": "8"
      }
    },
    "title": "Archivists, curators, and museum technicians",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "45",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "37",
        "median": "-",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "20",
        "femaleMedian": "-"
      }
    }
  },
  "Librarians": {
    "ageData": {
      "2018": {
        "total": "178",
        "16to19": "2",
        "20to24": "9",
        "25to34": "31",
        "35to44": "38",
        "45to54": "34",
        "55to64": "42",
        "65+": "21",
        "medianAge": "42"
      }
    },
    "title": "Librarians",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "178",
        "women": "78.5",
        "white": "85.5",
        "black": "6.6",
        "asian": "4.7",
        "hispanicOrLatino": "8.4"
      }
    },
    "wageData": {
      "2018": {
        "workers": "127",
        "median": "927",
        "maleWorkers": "29",
        "maleMedian": "-",
        "femaleWorkers": "98",
        "femaleMedian": "910"
      }
    }
  },
  "Library technicians": {
    "ageData": {
      "2018": {
        "total": "33",
        "16to19": "5",
        "20to24": "5",
        "25to34": "6",
        "35to44": "4",
        "45to54": "8",
        "55to64": "5",
        "65+": "2",
        "medianAge": "5"
      }
    },
    "title": "Library technicians",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "33",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "10",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Teacher assistants": {
    "ageData": {
      "2018": {
        "total": "994",
        "16to19": "28",
        "20to24": "115",
        "25to34": "181",
        "35to44": "191",
        "45to54": "228",
        "55to64": "198",
        "65+": "54",
        "medianAge": "198"
      }
    },
    "title": "Teacher assistants",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "994",
        "women": "88.9",
        "white": "78.7",
        "black": "12.9",
        "asian": "4.6",
        "hispanicOrLatino": "17.4"
      }
    },
    "wageData": {
      "2018": {
        "workers": "615",
        "median": "556",
        "maleWorkers": "62",
        "maleMedian": "584",
        "femaleWorkers": "553",
        "femaleMedian": "550"
      }
    }
  },
  "Other education, training, and library workers": {
    "ageData": {
      "2018": {
        "total": "173",
        "16to19": "3",
        "20to24": "17",
        "25to34": "39",
        "35to44": "32",
        "45to54": "41",
        "55to64": "28",
        "65+": "13",
        "medianAge": "28"
      }
    },
    "title": "Other education, training, and library workers",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "173",
        "women": "73.9",
        "white": "79.1",
        "black": "12.8",
        "asian": "5.4",
        "hispanicOrLatino": "14.3"
      }
    },
    "wageData": {
      "2018": {
        "workers": "125",
        "median": "1,095",
        "maleWorkers": "30",
        "maleMedian": "-",
        "femaleWorkers": "95",
        "femaleMedian": "1,036"
      }
    }
  },
  "Arts, design, entertainment, sports, and media occupations": {
    "ageData": {
      "2018": {
        "total": "3,362",
        "16to19": "111",
        "20to24": "302",
        "25to34": "921",
        "35to44": "667",
        "45to54": "621",
        "55to64": "459",
        "65+": "280",
        "medianAge": "459"
      }
    },
    "title": "Arts, design, entertainment, sports, and media occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "3,362",
        "women": "46.8",
        "white": "83.6",
        "black": "7.4",
        "asian": "5.5",
        "hispanicOrLatino": "11.8"
      }
    },
    "wageData": {
      "2018": {
        "workers": "1,880",
        "median": "1,086",
        "maleWorkers": "1,052",
        "maleMedian": "1,151",
        "femaleWorkers": "828",
        "femaleMedian": "997"
      }
    }
  },
  "Artists and related workers": {
    "ageData": {
      "2018": {
        "total": "232",
        "16to19": "2",
        "20to24": "19",
        "25to34": "50",
        "35to44": "45",
        "45to54": "41",
        "55to64": "44",
        "65+": "31",
        "medianAge": "44"
      }
    },
    "title": "Artists and related workers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "232",
        "women": "52.2",
        "white": "82.7",
        "black": "6.0",
        "asian": "5.8",
        "hispanicOrLatino": "9.6"
      }
    },
    "wageData": {
      "2018": {
        "workers": "64",
        "median": "1,078",
        "maleWorkers": "46",
        "maleMedian": "-",
        "femaleWorkers": "18",
        "femaleMedian": "-"
      }
    }
  },
  "Designers": {
    "ageData": {
      "2018": {
        "total": "984",
        "16to19": "15",
        "20to24": "78",
        "25to34": "299",
        "35to44": "200",
        "45to54": "189",
        "55to64": "142",
        "65+": "61",
        "medianAge": "142"
      }
    },
    "title": "Designers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "984",
        "women": "53.0",
        "white": "86.5",
        "black": "3.7",
        "asian": "7.2",
        "hispanicOrLatino": "13.1"
      }
    },
    "wageData": {
      "2018": {
        "workers": "684",
        "median": "1,060",
        "maleWorkers": "357",
        "maleMedian": "1,169",
        "femaleWorkers": "327",
        "femaleMedian": "932"
      }
    }
  },
  "Actors": {
    "ageData": {
      "2018": {
        "total": "36",
        "16to19": "0",
        "20to24": "3",
        "25to34": "14",
        "35to44": "8",
        "45to54": "7",
        "55to64": "1",
        "65+": "4",
        "medianAge": "1"
      }
    },
    "title": "Actors",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "36",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Producers and directors": {
    "ageData": {
      "2018": {
        "total": "201",
        "16to19": "0",
        "20to24": "6",
        "25to34": "67",
        "35to44": "55",
        "45to54": "45",
        "55to64": "18",
        "65+": "10",
        "medianAge": "18"
      }
    },
    "title": "Producers and directors",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "201",
        "women": "29.2",
        "white": "83.6",
        "black": "11.3",
        "asian": "2.3",
        "hispanicOrLatino": "7.1"
      }
    },
    "wageData": {
      "2018": {
        "workers": "147",
        "median": "1,224",
        "maleWorkers": "98",
        "maleMedian": "1,476",
        "femaleWorkers": "49",
        "femaleMedian": "-"
      }
    }
  },
  "Athletes, coaches, umpires, and related workers": {
    "ageData": {
      "2018": {
        "total": "359",
        "16to19": "65",
        "20to24": "56",
        "25to34": "89",
        "35to44": "59",
        "45to54": "48",
        "55to64": "22",
        "65+": "19",
        "medianAge": "22"
      }
    },
    "title": "Athletes, coaches, umpires, and related workers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "359",
        "women": "34.9",
        "white": "81.9",
        "black": "10.7",
        "asian": "2.4",
        "hispanicOrLatino": "11.8"
      }
    },
    "wageData": {
      "2018": {
        "workers": "164",
        "median": "1,044",
        "maleWorkers": "129",
        "maleMedian": "1,049",
        "femaleWorkers": "35",
        "femaleMedian": "-"
      }
    }
  },
  "Dancers and choreographers": {
    "ageData": {
      "2018": {
        "total": "24",
        "16to19": "4",
        "20to24": "7",
        "25to34": "7",
        "35to44": "3",
        "45to54": "0",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Dancers and choreographers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "24",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "11",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Musicians, singers, and related workers": {
    "ageData": {
      "2018": {
        "total": "202",
        "16to19": "4",
        "20to24": "12",
        "25to34": "52",
        "35to44": "41",
        "45to54": "32",
        "55to64": "26",
        "65+": "35",
        "medianAge": "26"
      }
    },
    "title": "Musicians, singers, and related workers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "202",
        "women": "35.0",
        "white": "75.9",
        "black": "15.1",
        "asian": "5.9",
        "hispanicOrLatino": "10.5"
      }
    },
    "wageData": {
      "2018": {
        "workers": "47",
        "median": "-",
        "maleWorkers": "28",
        "maleMedian": "-",
        "femaleWorkers": "19",
        "femaleMedian": "-"
      }
    }
  },
  "Entertainers and performers, sports and related workers, all other": {
    "ageData": {
      "2018": {
        "total": "68",
        "16to19": "2",
        "20to24": "4",
        "25to34": "26",
        "35to44": "10",
        "45to54": "12",
        "55to64": "7",
        "65+": "7",
        "medianAge": "7"
      }
    },
    "title": "Entertainers and performers, sports and related workers, all other",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "68",
        "women": "51.0",
        "white": "68.3",
        "black": "11.3",
        "asian": "9.7",
        "hispanicOrLatino": "8.0"
      }
    },
    "wageData": {
      "2018": {
        "workers": "17",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Announcers": {
    "ageData": {
      "2018": {
        "total": "56",
        "16to19": "3",
        "20to24": "3",
        "25to34": "17",
        "35to44": "10",
        "45to54": "9",
        "55to64": "10",
        "65+": "5",
        "medianAge": "10"
      }
    },
    "title": "Announcers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "56",
        "women": "17.7",
        "white": "74.5",
        "black": "22.3",
        "asian": "0.5",
        "hispanicOrLatino": "18.7"
      }
    },
    "wageData": {
      "2018": {
        "workers": "26",
        "median": "-",
        "maleWorkers": "22",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "News analysts, reporters and correspondents": {
    "ageData": {
      "2018": {
        "total": "79",
        "16to19": "0",
        "20to24": "11",
        "25to34": "24",
        "35to44": "17",
        "45to54": "10",
        "55to64": "14",
        "65+": "4",
        "medianAge": "14"
      }
    },
    "title": "News analysts, reporters and correspondents",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "79",
        "women": "51.7",
        "white": "80.9",
        "black": "8.7",
        "asian": "4.1",
        "hispanicOrLatino": "15.2"
      }
    },
    "wageData": {
      "2018": {
        "workers": "59",
        "median": "1,012",
        "maleWorkers": "29",
        "maleMedian": "-",
        "femaleWorkers": "30",
        "femaleMedian": "-"
      }
    }
  },
  "Public relations specialists": {
    "ageData": {
      "2018": {
        "total": "140",
        "16to19": "2",
        "20to24": "16",
        "25to34": "42",
        "35to44": "29",
        "45to54": "28",
        "55to64": "17",
        "65+": "6",
        "medianAge": "17"
      }
    },
    "title": "Public relations specialists",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "140",
        "women": "65.7",
        "white": "86.8",
        "black": "7.1",
        "asian": "4.9",
        "hispanicOrLatino": "10.0"
      }
    },
    "wageData": {
      "2018": {
        "workers": "118",
        "median": "1,313",
        "maleWorkers": "40",
        "maleMedian": "-",
        "femaleWorkers": "78",
        "femaleMedian": "1,169"
      }
    }
  },
  "Editors": {
    "ageData": {
      "2018": {
        "total": "160",
        "16to19": "2",
        "20to24": "10",
        "25to34": "46",
        "35to44": "34",
        "45to54": "31",
        "55to64": "27",
        "65+": "10",
        "medianAge": "27"
      }
    },
    "title": "Editors",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "160",
        "women": "52.2",
        "white": "91.7",
        "black": "1.7",
        "asian": "4.4",
        "hispanicOrLatino": "10.3"
      }
    },
    "wageData": {
      "2018": {
        "workers": "124",
        "median": "1,112",
        "maleWorkers": "57",
        "maleMedian": "1,104",
        "femaleWorkers": "67",
        "femaleMedian": "1,138"
      }
    }
  },
  "Technical writers": {
    "ageData": {
      "2018": {
        "total": "69",
        "16to19": "0",
        "20to24": "1",
        "25to34": "10",
        "35to44": "12",
        "45to54": "15",
        "55to64": "19",
        "65+": "12",
        "medianAge": "19"
      }
    },
    "title": "Technical writers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "69",
        "women": "55.9",
        "white": "82.4",
        "black": "8.3",
        "asian": "5.6",
        "hispanicOrLatino": "4.8"
      }
    },
    "wageData": {
      "2018": {
        "workers": "60",
        "median": "1,318",
        "maleWorkers": "25",
        "maleMedian": "-",
        "femaleWorkers": "35",
        "femaleMedian": "-"
      }
    }
  },
  "Writers and authors": {
    "ageData": {
      "2018": {
        "total": "227",
        "16to19": "1",
        "20to24": "12",
        "25to34": "50",
        "35to44": "42",
        "45to54": "47",
        "55to64": "43",
        "65+": "33",
        "medianAge": "43"
      }
    },
    "title": "Writers and authors",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "227",
        "women": "59.6",
        "white": "89.1",
        "black": "5.4",
        "asian": "4.0",
        "hispanicOrLatino": "4.5"
      }
    },
    "wageData": {
      "2018": {
        "workers": "110",
        "median": "1,233",
        "maleWorkers": "42",
        "maleMedian": "-",
        "femaleWorkers": "68",
        "femaleMedian": "1,036"
      }
    }
  },
  "Miscellaneous media and communication workers": {
    "ageData": {
      "2018": {
        "total": "111",
        "16to19": "2",
        "20to24": "12",
        "25to34": "13",
        "35to44": "24",
        "45to54": "28",
        "55to64": "19",
        "65+": "13",
        "medianAge": "19"
      }
    },
    "title": "Miscellaneous media and communication workers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "111",
        "women": "70.8",
        "white": "72.9",
        "black": "6.0",
        "asian": "16.0",
        "hispanicOrLatino": "24.9"
      }
    },
    "wageData": {
      "2018": {
        "workers": "62",
        "median": "880",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "51",
        "femaleMedian": "850"
      }
    }
  },
  "Broadcast and sound engineering technicians and radio operators": {
    "ageData": {
      "2018": {
        "total": "120",
        "16to19": "8",
        "20to24": "17",
        "25to34": "25",
        "35to44": "24",
        "45to54": "26",
        "55to64": "12",
        "65+": "8",
        "medianAge": "12"
      }
    },
    "title": "Broadcast and sound engineering technicians and radio operators",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "120",
        "women": "12.4",
        "white": "80.6",
        "black": "11.5",
        "asian": "5.7",
        "hispanicOrLatino": "16.3"
      }
    },
    "wageData": {
      "2018": {
        "workers": "73",
        "median": "1,028",
        "maleWorkers": "66",
        "maleMedian": "1,038",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Photographers": {
    "ageData": {
      "2018": {
        "total": "208",
        "16to19": "1",
        "20to24": "21",
        "25to34": "70",
        "35to44": "36",
        "45to54": "37",
        "55to64": "26",
        "65+": "17",
        "medianAge": "26"
      }
    },
    "title": "Photographers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "208",
        "women": "47.8",
        "white": "83.6",
        "black": "6.8",
        "asian": "5.8",
        "hispanicOrLatino": "11.4"
      }
    },
    "wageData": {
      "2018": {
        "workers": "68",
        "median": "904",
        "maleWorkers": "51",
        "maleMedian": "1,006",
        "femaleWorkers": "16",
        "femaleMedian": "-"
      }
    }
  },
  "Television, video, and motion picture camera operators and editors": {
    "ageData": {
      "2018": {
        "total": "82",
        "16to19": "0",
        "20to24": "13",
        "25to34": "22",
        "35to44": "17",
        "45to54": "15",
        "55to64": "10",
        "65+": "6",
        "medianAge": "10"
      }
    },
    "title": "Television, video, and motion picture camera operators and editors",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "82",
        "women": "16.1",
        "white": "85.6",
        "black": "9.5",
        "asian": "1.7",
        "hispanicOrLatino": "15.7"
      }
    },
    "wageData": {
      "2018": {
        "workers": "35",
        "median": "-",
        "maleWorkers": "30",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Media and communication equipment workers, all other": {
    "ageData": {
      "2018": {
        "total": "5",
        "16to19": "0",
        "20to24": "2",
        "25to34": "0",
        "35to44": "0",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Media and communication equipment workers, all other",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Healthcare practitioners and technical occupations": {
    "ageData": {
      "2018": {
        "total": "9,420",
        "16to19": "33",
        "20to24": "519",
        "25to34": "2,575",
        "35to44": "2,215",
        "45to54": "1,937",
        "55to64": "1,565",
        "65+": "576",
        "medianAge": "1,565"
      }
    },
    "title": "Healthcare practitioners and technical occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "9,420",
        "women": "75.0",
        "white": "75.2",
        "black": "12.6",
        "asian": "9.9",
        "hispanicOrLatino": "8.5"
      }
    },
    "wageData": {
      "2018": {
        "workers": "7,154",
        "median": "1,140",
        "maleWorkers": "1,800",
        "maleMedian": "1,383",
        "femaleWorkers": "5,354",
        "femaleMedian": "1,078"
      }
    }
  },
  "Chiropractors": {
    "ageData": {
      "2018": {
        "total": "63",
        "16to19": "0",
        "20to24": "2",
        "25to34": "9",
        "35to44": "18",
        "45to54": "15",
        "55to64": "12",
        "65+": "8",
        "medianAge": "12"
      }
    },
    "title": "Chiropractors",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "63",
        "women": "21.1",
        "white": "92.8",
        "black": "0.9",
        "asian": "4.0",
        "hispanicOrLatino": "3.0"
      }
    },
    "wageData": {
      "2018": {
        "workers": "15",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Dentists": {
    "ageData": {
      "2018": {
        "total": "162",
        "16to19": "0",
        "20to24": "0",
        "25to34": "39",
        "35to44": "35",
        "45to54": "25",
        "55to64": "35",
        "65+": "28",
        "medianAge": "35"
      }
    },
    "title": "Dentists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "162",
        "women": "35.7",
        "white": "79.0",
        "black": "1.6",
        "asian": "17.6",
        "hispanicOrLatino": "4.3"
      }
    },
    "wageData": {
      "2018": {
        "workers": "56",
        "median": "1,884",
        "maleWorkers": "27",
        "maleMedian": "-",
        "femaleWorkers": "28",
        "femaleMedian": "-"
      }
    }
  },
  "Dietitians and nutritionists": {
    "ageData": {
      "2018": {
        "total": "104",
        "16to19": "1",
        "20to24": "2",
        "25to34": "23",
        "35to44": "19",
        "45to54": "27",
        "55to64": "24",
        "65+": "8",
        "medianAge": "24"
      }
    },
    "title": "Dietitians and nutritionists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "104",
        "women": "93.1",
        "white": "78.6",
        "black": "9.6",
        "asian": "9.1",
        "hispanicOrLatino": "9.6"
      }
    },
    "wageData": {
      "2018": {
        "workers": "70",
        "median": "967",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "63",
        "femaleMedian": "940"
      }
    }
  },
  "Optometrists": {
    "ageData": {
      "2018": {
        "total": "54",
        "16to19": "0",
        "20to24": "0",
        "25to34": "9",
        "35to44": "8",
        "45to54": "19",
        "55to64": "9",
        "65+": "9",
        "medianAge": "9"
      }
    },
    "title": "Optometrists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "54",
        "women": "46.0",
        "white": "84.9",
        "black": "2.1",
        "asian": "9.1",
        "hispanicOrLatino": "8.5"
      }
    },
    "wageData": {
      "2018": {
        "workers": "29",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "15",
        "femaleMedian": "-"
      }
    }
  },
  "Pharmacists": {
    "ageData": {
      "2018": {
        "total": "348",
        "16to19": "1",
        "20to24": "17",
        "25to34": "107",
        "35to44": "86",
        "45to54": "62",
        "55to64": "48",
        "65+": "27",
        "medianAge": "48"
      }
    },
    "title": "Pharmacists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "348",
        "women": "63.4",
        "white": "67.9",
        "black": "7.2",
        "asian": "23.1",
        "hispanicOrLatino": "4.4"
      }
    },
    "wageData": {
      "2018": {
        "workers": "263",
        "median": "2,071",
        "maleWorkers": "98",
        "maleMedian": "2,271",
        "femaleWorkers": "165",
        "femaleMedian": "1,890"
      }
    }
  },
  "Physicians and surgeons": {
    "ageData": {
      "2018": {
        "total": "1,094",
        "16to19": "0",
        "20to24": "5",
        "25to34": "239",
        "35to44": "271",
        "45to54": "243",
        "55to64": "211",
        "65+": "125",
        "medianAge": "211"
      }
    },
    "title": "Physicians and surgeons",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "1,094",
        "women": "40.3",
        "white": "70.8",
        "black": "7.6",
        "asian": "19.8",
        "hispanicOrLatino": "7.4"
      }
    },
    "wageData": {
      "2018": {
        "workers": "827",
        "median": "2,001",
        "maleWorkers": "475",
        "maleMedian": "2,513",
        "femaleWorkers": "352",
        "femaleMedian": "1,677"
      }
    }
  },
  "Physician assistants": {
    "ageData": {
      "2018": {
        "total": "132",
        "16to19": "1",
        "20to24": "7",
        "25to34": "55",
        "35to44": "31",
        "45to54": "24",
        "55to64": "12",
        "65+": "3",
        "medianAge": "12"
      }
    },
    "title": "Physician assistants",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "132",
        "women": "72.1",
        "white": "90.3",
        "black": "2.9",
        "asian": "5.1",
        "hispanicOrLatino": "8.2"
      }
    },
    "wageData": {
      "2018": {
        "workers": "118",
        "median": "1,752",
        "maleWorkers": "37",
        "maleMedian": "-",
        "femaleWorkers": "80",
        "femaleMedian": "1,646"
      }
    }
  },
  "Podiatrists": {
    "ageData": {
      "2018": {
        "total": "11",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "4",
        "45to54": "3",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Podiatrists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "11",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Audiologists": {
    "ageData": {
      "2018": {
        "total": "10",
        "16to19": "0",
        "20to24": "2",
        "25to34": "5",
        "35to44": "1",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Audiologists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "10",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Occupational therapists": {
    "ageData": {
      "2018": {
        "total": "116",
        "16to19": "0",
        "20to24": "8",
        "25to34": "39",
        "35to44": "26",
        "45to54": "24",
        "55to64": "12",
        "65+": "6",
        "medianAge": "12"
      }
    },
    "title": "Occupational therapists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "116",
        "women": "86.8",
        "white": "91.1",
        "black": "2.5",
        "asian": "6.4",
        "hispanicOrLatino": "4.2"
      }
    },
    "wageData": {
      "2018": {
        "workers": "80",
        "median": "1,283",
        "maleWorkers": "16",
        "maleMedian": "-",
        "femaleWorkers": "64",
        "femaleMedian": "1,252"
      }
    }
  },
  "Physical therapists": {
    "ageData": {
      "2018": {
        "total": "286",
        "16to19": "0",
        "20to24": "8",
        "25to34": "96",
        "35to44": "74",
        "45to54": "52",
        "55to64": "43",
        "65+": "12",
        "medianAge": "43"
      }
    },
    "title": "Physical therapists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "286",
        "women": "69.5",
        "white": "76.4",
        "black": "7.4",
        "asian": "14.3",
        "hispanicOrLatino": "4.3"
      }
    },
    "wageData": {
      "2018": {
        "workers": "209",
        "median": "1,395",
        "maleWorkers": "71",
        "maleMedian": "1,410",
        "femaleWorkers": "139",
        "femaleMedian": "1,387"
      }
    }
  },
  "Radiation therapists": {
    "ageData": {
      "2018": {
        "total": "16",
        "16to19": "0",
        "20to24": "1",
        "25to34": "5",
        "35to44": "3",
        "45to54": "5",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Radiation therapists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "16",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Recreational therapists": {
    "ageData": {
      "2018": {
        "total": "11",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "3",
        "45to54": "4",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Recreational therapists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "11",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "11",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Respiratory therapists": {
    "ageData": {
      "2018": {
        "total": "108",
        "16to19": "0",
        "20to24": "1",
        "25to34": "33",
        "35to44": "23",
        "45to54": "24",
        "55to64": "17",
        "65+": "10",
        "medianAge": "17"
      }
    },
    "title": "Respiratory therapists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "108",
        "women": "63.8",
        "white": "64.9",
        "black": "17.3",
        "asian": "13.9",
        "hispanicOrLatino": "11.6"
      }
    },
    "wageData": {
      "2018": {
        "workers": "95",
        "median": "1,074",
        "maleWorkers": "36",
        "maleMedian": "-",
        "femaleWorkers": "59",
        "femaleMedian": "1,000"
      }
    }
  },
  "Speech-language pathologists": {
    "ageData": {
      "2018": {
        "total": "152",
        "16to19": "0",
        "20to24": "4",
        "25to34": "48",
        "35to44": "45",
        "45to54": "32",
        "55to64": "18",
        "65+": "5",
        "medianAge": "18"
      }
    },
    "title": "Speech-language pathologists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "152",
        "women": "96.0",
        "white": "94.1",
        "black": "3.5",
        "asian": "1.2",
        "hispanicOrLatino": "4.8"
      }
    },
    "wageData": {
      "2018": {
        "workers": "105",
        "median": "1,215",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "100",
        "femaleMedian": "1,214"
      }
    }
  },
  "Exercise physiologists": {
    "ageData": {
      "2018": {
        "total": "5",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "2",
        "45to54": "2",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Exercise physiologists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Therapists, all other": {
    "ageData": {
      "2018": {
        "total": "237",
        "16to19": "0",
        "20to24": "10",
        "25to34": "76",
        "35to44": "65",
        "45to54": "45",
        "55to64": "28",
        "65+": "12",
        "medianAge": "28"
      }
    },
    "title": "Therapists, all other",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "237",
        "women": "82.1",
        "white": "75.1",
        "black": "18.1",
        "asian": "5.3",
        "hispanicOrLatino": "8.8"
      }
    },
    "wageData": {
      "2018": {
        "workers": "155",
        "median": "1,047",
        "maleWorkers": "33",
        "maleMedian": "-",
        "femaleWorkers": "122",
        "femaleMedian": "1,014"
      }
    }
  },
  "Veterinarians": {
    "ageData": {
      "2018": {
        "total": "102",
        "16to19": "0",
        "20to24": "1",
        "25to34": "26",
        "35to44": "27",
        "45to54": "22",
        "55to64": "16",
        "65+": "10",
        "medianAge": "16"
      }
    },
    "title": "Veterinarians",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "102",
        "women": "71.2",
        "white": "92.9",
        "black": "0.0",
        "asian": "4.1",
        "hispanicOrLatino": "2.3"
      }
    },
    "wageData": {
      "2018": {
        "workers": "64",
        "median": "1,677",
        "maleWorkers": "18",
        "maleMedian": "-",
        "femaleWorkers": "46",
        "femaleMedian": "-"
      }
    }
  },
  "Registered nurses": {
    "ageData": {
      "2018": {
        "total": "3,213",
        "16to19": "2",
        "20to24": "155",
        "25to34": "842",
        "35to44": "745",
        "45to54": "717",
        "55to64": "589",
        "65+": "164",
        "medianAge": "589"
      }
    },
    "title": "Registered nurses",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "3,213",
        "women": "88.6",
        "white": "75.5",
        "black": "13.1",
        "asian": "9.0",
        "hispanicOrLatino": "7.2"
      }
    },
    "wageData": {
      "2018": {
        "workers": "2,584",
        "median": "1,167",
        "maleWorkers": "315",
        "maleMedian": "1,271",
        "femaleWorkers": "2,270",
        "femaleMedian": "1,156"
      }
    }
  },
  "Nurse anesthetists": {
    "ageData": {
      "2018": {
        "total": "26",
        "16to19": "0",
        "20to24": "0",
        "25to34": "10",
        "35to44": "5",
        "45to54": "6",
        "55to64": "4",
        "65+": "1",
        "medianAge": "4"
      }
    },
    "title": "Nurse anesthetists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "26",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "22",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Nurse midwives": {
    "ageData": {
      "2018": {
        "total": "3",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "1",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Nurse midwives",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Nurse practitioners": {
    "ageData": {
      "2018": {
        "total": "212",
        "16to19": "0",
        "20to24": "1",
        "25to34": "56",
        "35to44": "62",
        "45to54": "47",
        "55to64": "36",
        "65+": "10",
        "medianAge": "36"
      }
    },
    "title": "Nurse practitioners",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "212",
        "women": "87.2",
        "white": "79.1",
        "black": "11.2",
        "asian": "8.8",
        "hispanicOrLatino": "2.6"
      }
    },
    "wageData": {
      "2018": {
        "workers": "158",
        "median": "1,894",
        "maleWorkers": "24",
        "maleMedian": "-",
        "femaleWorkers": "134",
        "femaleMedian": "1,891"
      }
    }
  },
  "Health diagnosing and treating practitioners, all other": {
    "ageData": {
      "2018": {
        "total": "40",
        "16to19": "0",
        "20to24": "0",
        "25to34": "5",
        "35to44": "11",
        "45to54": "11",
        "55to64": "10",
        "65+": "4",
        "medianAge": "10"
      }
    },
    "title": "Health diagnosing and treating practitioners, all other",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "40",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "10",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Clinical laboratory technologists and technicians": {
    "ageData": {
      "2018": {
        "total": "318",
        "16to19": "1",
        "20to24": "18",
        "25to34": "85",
        "35to44": "72",
        "45to54": "65",
        "55to64": "60",
        "65+": "17",
        "medianAge": "60"
      }
    },
    "title": "Clinical laboratory technologists and technicians",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "318",
        "women": "75.2",
        "white": "70.0",
        "black": "18.6",
        "asian": "8.5",
        "hispanicOrLatino": "12.9"
      }
    },
    "wageData": {
      "2018": {
        "workers": "290",
        "median": "893",
        "maleWorkers": "75",
        "maleMedian": "819",
        "femaleWorkers": "215",
        "femaleMedian": "911"
      }
    }
  },
  "Dental hygienists": {
    "ageData": {
      "2018": {
        "total": "178",
        "16to19": "0",
        "20to24": "12",
        "25to34": "45",
        "35to44": "33",
        "45to54": "42",
        "55to64": "36",
        "65+": "11",
        "medianAge": "36"
      }
    },
    "title": "Dental hygienists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "178",
        "women": "97.1",
        "white": "88.3",
        "black": "5.6",
        "asian": "3.8",
        "hispanicOrLatino": "8.6"
      }
    },
    "wageData": {
      "2018": {
        "workers": "75",
        "median": "1,047",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "72",
        "femaleMedian": "1,055"
      }
    }
  },
  "Diagnostic related technologists and technicians": {
    "ageData": {
      "2018": {
        "total": "346",
        "16to19": "2",
        "20to24": "21",
        "25to34": "90",
        "35to44": "92",
        "45to54": "71",
        "55to64": "62",
        "65+": "7",
        "medianAge": "62"
      }
    },
    "title": "Diagnostic related technologists and technicians",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "346",
        "women": "67.9",
        "white": "79.5",
        "black": "11.8",
        "asian": "5.6",
        "hispanicOrLatino": "14.7"
      }
    },
    "wageData": {
      "2018": {
        "workers": "269",
        "median": "1,140",
        "maleWorkers": "97",
        "maleMedian": "1,232",
        "femaleWorkers": "172",
        "femaleMedian": "1,061"
      }
    }
  },
  "Emergency medical technicians and paramedics": {
    "ageData": {
      "2018": {
        "total": "214",
        "16to19": "3",
        "20to24": "33",
        "25to34": "91",
        "35to44": "45",
        "45to54": "25",
        "55to64": "15",
        "65+": "2",
        "medianAge": "15"
      }
    },
    "title": "Emergency medical technicians and paramedics",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "214",
        "women": "33.9",
        "white": "84.3",
        "black": "11.9",
        "asian": "2.2",
        "hispanicOrLatino": "13.9"
      }
    },
    "wageData": {
      "2018": {
        "workers": "180",
        "median": "894",
        "maleWorkers": "122",
        "maleMedian": "943",
        "femaleWorkers": "59",
        "femaleMedian": "764"
      }
    }
  },
  "Health practitioner support technologists and technicians": {
    "ageData": {
      "2018": {
        "total": "694",
        "16to19": "14",
        "20to24": "111",
        "25to34": "251",
        "35to44": "138",
        "45to54": "100",
        "55to64": "66",
        "65+": "14",
        "medianAge": "66"
      }
    },
    "title": "Health practitioner support technologists and technicians",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "694",
        "women": "80.6",
        "white": "75.5",
        "black": "14.1",
        "asian": "6.9",
        "hispanicOrLatino": "15.0"
      }
    },
    "wageData": {
      "2018": {
        "workers": "542",
        "median": "691",
        "maleWorkers": "112",
        "maleMedian": "807",
        "femaleWorkers": "431",
        "femaleMedian": "655"
      }
    }
  },
  "Licensed practical and licensed vocational nurses": {
    "ageData": {
      "2018": {
        "total": "658",
        "16to19": "4",
        "20to24": "68",
        "25to34": "156",
        "35to44": "149",
        "45to54": "132",
        "55to64": "101",
        "65+": "47",
        "medianAge": "101"
      }
    },
    "title": "Licensed practical and licensed vocational nurses",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "658",
        "women": "87.8",
        "white": "62.0",
        "black": "30.4",
        "asian": "5.1",
        "hispanicOrLatino": "11.5"
      }
    },
    "wageData": {
      "2018": {
        "workers": "490",
        "median": "757",
        "maleWorkers": "56",
        "maleMedian": "936",
        "femaleWorkers": "434",
        "femaleMedian": "743"
      }
    }
  },
  "Medical records and health information technicians": {
    "ageData": {
      "2018": {
        "total": "171",
        "16to19": "2",
        "20to24": "3",
        "25to34": "28",
        "35to44": "46",
        "45to54": "39",
        "55to64": "42",
        "65+": "10",
        "medianAge": "42"
      }
    },
    "title": "Medical records and health information technicians",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "171",
        "women": "93.6",
        "white": "72.3",
        "black": "15.5",
        "asian": "9.2",
        "hispanicOrLatino": "9.2"
      }
    },
    "wageData": {
      "2018": {
        "workers": "140",
        "median": "765",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "129",
        "femaleMedian": "739"
      }
    }
  },
  "Opticians, dispensing": {
    "ageData": {
      "2018": {
        "total": "61",
        "16to19": "0",
        "20to24": "1",
        "25to34": "16",
        "35to44": "14",
        "45to54": "10",
        "55to64": "13",
        "65+": "7",
        "medianAge": "13"
      }
    },
    "title": "Opticians, dispensing",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "61",
        "women": "73.4",
        "white": "85.4",
        "black": "7.3",
        "asian": "4.9",
        "hispanicOrLatino": "6.8"
      }
    },
    "wageData": {
      "2018": {
        "workers": "47",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "36",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous health technologists and technicians": {
    "ageData": {
      "2018": {
        "total": "139",
        "16to19": "1",
        "20to24": "13",
        "25to34": "47",
        "35to44": "26",
        "45to54": "26",
        "55to64": "16",
        "65+": "11",
        "medianAge": "16"
      }
    },
    "title": "Miscellaneous health technologists and technicians",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "139",
        "women": "66.5",
        "white": "59.8",
        "black": "24.1",
        "asian": "14.4",
        "hispanicOrLatino": "12.3"
      }
    },
    "wageData": {
      "2018": {
        "workers": "117",
        "median": "873",
        "maleWorkers": "44",
        "maleMedian": "-",
        "femaleWorkers": "73",
        "femaleMedian": "791"
      }
    }
  },
  "Other healthcare practitioners and technical occupations": {
    "ageData": {
      "2018": {
        "total": "137",
        "16to19": "1",
        "20to24": "16",
        "25to34": "41",
        "35to44": "33",
        "45to54": "18",
        "55to64": "21",
        "65+": "8",
        "medianAge": "21"
      }
    },
    "title": "Other healthcare practitioners and technical occupations",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "137",
        "women": "52.1",
        "white": "81.4",
        "black": "10.2",
        "asian": "4.1",
        "hispanicOrLatino": "10.8"
      }
    },
    "wageData": {
      "2018": {
        "workers": "100",
        "median": "1,022",
        "maleWorkers": "48",
        "maleMedian": "-",
        "femaleWorkers": "52",
        "femaleMedian": "961"
      }
    }
  },
  "Service occupations": {
    "ageData": {
      "2018": {
        "total": "26,854",
        "16to19": "2,092",
        "20to24": "3,668",
        "25to34": "6,150",
        "35to44": "4,898",
        "45to54": "4,816",
        "55to64": "3,772",
        "65+": "1,458",
        "medianAge": "3,772"
      }
    },
    "title": "Service occupations",
    "depth": 1,
    "parent": "",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "26,854",
        "women": "57.5",
        "white": "72.0",
        "black": "17.3",
        "asian": "6.1",
        "hispanicOrLatino": "24.4"
      }
    },
    "wageData": {
      "2018": {
        "workers": "16,288",
        "median": "569",
        "maleWorkers": "7,948",
        "maleMedian": "641",
        "femaleWorkers": "8,340",
        "femaleMedian": "511"
      }
    }
  },
  "Healthcare support occupations": {
    "ageData": {
      "2018": {
        "total": "3,629",
        "16to19": "95",
        "20to24": "489",
        "25to34": "951",
        "35to44": "709",
        "45to54": "684",
        "55to64": "560",
        "65+": "141",
        "medianAge": "560"
      }
    },
    "title": "Healthcare support occupations",
    "depth": 2,
    "parent": "Service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "3,629",
        "women": "87.1",
        "white": "64.3",
        "black": "26.2",
        "asian": "5.7",
        "hispanicOrLatino": "18.3"
      }
    },
    "wageData": {
      "2018": {
        "workers": "2,595",
        "median": "561",
        "maleWorkers": "361",
        "maleMedian": "661",
        "femaleWorkers": "2,235",
        "femaleMedian": "548"
      }
    }
  },
  "Nursing, psychiatric, and home health aides": {
    "ageData": {
      "2018": {
        "total": "2,035",
        "16to19": "61",
        "20to24": "266",
        "25to34": "461",
        "35to44": "397",
        "45to54": "410",
        "55to64": "342",
        "65+": "97",
        "medianAge": "342"
      }
    },
    "title": "Nursing, psychiatric, and home health aides",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "2,035",
        "women": "89.3",
        "white": "55.8",
        "black": "35.8",
        "asian": "5.4",
        "hispanicOrLatino": "16.3"
      }
    },
    "wageData": {
      "2018": {
        "workers": "1,419",
        "median": "516",
        "maleWorkers": "154",
        "maleMedian": "558",
        "femaleWorkers": "1,265",
        "femaleMedian": "513"
      }
    }
  },
  "Occupational therapy assistants and aides": {
    "ageData": {
      "2018": {
        "total": "35",
        "16to19": "0",
        "20to24": "0",
        "25to34": "9",
        "35to44": "9",
        "45to54": "8",
        "55to64": "8",
        "65+": "1",
        "medianAge": "8"
      }
    },
    "title": "Occupational therapy assistants and aides",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "35",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "24",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "21",
        "femaleMedian": "-"
      }
    }
  },
  "Physical therapist assistants and aides": {
    "ageData": {
      "2018": {
        "total": "88",
        "16to19": "3",
        "20to24": "15",
        "25to34": "24",
        "35to44": "13",
        "45to54": "21",
        "55to64": "11",
        "65+": "1",
        "medianAge": "11"
      }
    },
    "title": "Physical therapist assistants and aides",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "88",
        "women": "67.4",
        "white": "85.0",
        "black": "6.1",
        "asian": "6.7",
        "hispanicOrLatino": "7.1"
      }
    },
    "wageData": {
      "2018": {
        "workers": "68",
        "median": "848",
        "maleWorkers": "24",
        "maleMedian": "-",
        "femaleWorkers": "43",
        "femaleMedian": "-"
      }
    }
  },
  "Massage therapists": {
    "ageData": {
      "2018": {
        "total": "155",
        "16to19": "0",
        "20to24": "8",
        "25to34": "49",
        "35to44": "31",
        "45to54": "26",
        "55to64": "30",
        "65+": "11",
        "medianAge": "30"
      }
    },
    "title": "Massage therapists",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "155",
        "women": "83.2",
        "white": "73.7",
        "black": "9.2",
        "asian": "10.2",
        "hispanicOrLatino": "13.9"
      }
    },
    "wageData": {
      "2018": {
        "workers": "40",
        "median": "-",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "24",
        "femaleMedian": "-"
      }
    }
  },
  "Dental assistants": {
    "ageData": {
      "2018": {
        "total": "293",
        "16to19": "7",
        "20to24": "45",
        "25to34": "92",
        "35to44": "59",
        "45to54": "53",
        "55to64": "33",
        "65+": "5",
        "medianAge": "33"
      }
    },
    "title": "Dental assistants",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "293",
        "women": "96.0",
        "white": "80.8",
        "black": "9.9",
        "asian": "3.5",
        "hispanicOrLatino": "27.7"
      }
    },
    "wageData": {
      "2018": {
        "workers": "197",
        "median": "606",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "187",
        "femaleMedian": "598"
      }
    }
  },
  "Medical assistants": {
    "ageData": {
      "2018": {
        "total": "570",
        "16to19": "7",
        "20to24": "82",
        "25to34": "202",
        "35to44": "119",
        "45to54": "89",
        "55to64": "56",
        "65+": "14",
        "medianAge": "56"
      }
    },
    "title": "Medical assistants",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "570",
        "women": "90.6",
        "white": "74.1",
        "black": "15.3",
        "asian": "4.5",
        "hispanicOrLatino": "28.6"
      }
    },
    "wageData": {
      "2018": {
        "workers": "499",
        "median": "612",
        "maleWorkers": "45",
        "maleMedian": "-",
        "femaleWorkers": "454",
        "femaleMedian": "607"
      }
    }
  },
  "Medical transcriptionists": {
    "ageData": {
      "2018": {
        "total": "44",
        "16to19": "2",
        "20to24": "12",
        "25to34": "5",
        "35to44": "4",
        "45to54": "7",
        "55to64": "13",
        "65+": "2",
        "medianAge": "13"
      }
    },
    "title": "Medical transcriptionists",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "44",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "23",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "18",
        "femaleMedian": "-"
      }
    }
  },
  "Pharmacy aides": {
    "ageData": {
      "2018": {
        "total": "34",
        "16to19": "0",
        "20to24": "4",
        "25to34": "10",
        "35to44": "5",
        "45to54": "6",
        "55to64": "8",
        "65+": "1",
        "medianAge": "8"
      }
    },
    "title": "Pharmacy aides",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "34",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "28",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "23",
        "femaleMedian": "-"
      }
    }
  },
  "Veterinary assistants and laboratory animal caretakers": {
    "ageData": {
      "2018": {
        "total": "56",
        "16to19": "4",
        "20to24": "18",
        "25to34": "19",
        "35to44": "6",
        "45to54": "5",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Veterinary assistants and laboratory animal caretakers",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "56",
        "women": "86.3",
        "white": "84.5",
        "black": "7.0",
        "asian": "2.8",
        "hispanicOrLatino": "9.4"
      }
    },
    "wageData": {
      "2018": {
        "workers": "49",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "39",
        "femaleMedian": "-"
      }
    }
  },
  "Phlebotomists": {
    "ageData": {
      "2018": {
        "total": "115",
        "16to19": "1",
        "20to24": "11",
        "25to34": "36",
        "35to44": "29",
        "45to54": "22",
        "55to64": "15",
        "65+": "2",
        "medianAge": "15"
      }
    },
    "title": "Phlebotomists",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "115",
        "women": "75.0",
        "white": "72.7",
        "black": "16.9",
        "asian": "7.6",
        "hispanicOrLatino": "19.6"
      }
    },
    "wageData": {
      "2018": {
        "workers": "96",
        "median": "624",
        "maleWorkers": "29",
        "maleMedian": "-",
        "femaleWorkers": "67",
        "femaleMedian": "602"
      }
    }
  },
  "Miscellaneous healthcare support occupations, including medical equipment preparers": {
    "ageData": {
      "2018": {
        "total": "204",
        "16to19": "10",
        "20to24": "27",
        "25to34": "42",
        "35to44": "38",
        "45to54": "39",
        "55to64": "41",
        "65+": "7",
        "medianAge": "41"
      }
    },
    "title": "Miscellaneous healthcare support occupations, including medical equipment preparers",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "204",
        "women": "64.0",
        "white": "63.5",
        "black": "26.4",
        "asian": "8.6",
        "hispanicOrLatino": "11.9"
      }
    },
    "wageData": {
      "2018": {
        "workers": "154",
        "median": "598",
        "maleWorkers": "61",
        "maleMedian": "703",
        "femaleWorkers": "93",
        "femaleMedian": "559"
      }
    }
  },
  "Protective service occupations": {
    "ageData": {
      "2018": {
        "total": "3,203",
        "16to19": "96",
        "20to24": "286",
        "25to34": "829",
        "35to44": "743",
        "45to54": "677",
        "55to64": "416",
        "65+": "155",
        "medianAge": "416"
      }
    },
    "title": "Protective service occupations",
    "depth": 2,
    "parent": "Service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "3,203",
        "women": "22.5",
        "white": "73.5",
        "black": "20.1",
        "asian": "2.4",
        "hispanicOrLatino": "13.8"
      }
    },
    "wageData": {
      "2018": {
        "workers": "2,836",
        "median": "848",
        "maleWorkers": "2,258",
        "maleMedian": "922",
        "femaleWorkers": "578",
        "femaleMedian": "613"
      }
    }
  },
  "First-line supervisors of correctional officers": {
    "ageData": {
      "2018": {
        "total": "45",
        "16to19": "0",
        "20to24": "1",
        "25to34": "6",
        "35to44": "15",
        "45to54": "17",
        "55to64": "4",
        "65+": "1",
        "medianAge": "4"
      }
    },
    "title": "First-line supervisors of correctional officers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "45",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "47",
        "median": "-",
        "maleWorkers": "35",
        "maleMedian": "-",
        "femaleWorkers": "13",
        "femaleMedian": "-"
      }
    }
  },
  "First-line supervisors of police and detectives": {
    "ageData": {
      "2018": {
        "total": "88",
        "16to19": "0",
        "20to24": "0",
        "25to34": "10",
        "35to44": "34",
        "45to54": "28",
        "55to64": "13",
        "65+": "3",
        "medianAge": "13"
      }
    },
    "title": "First-line supervisors of police and detectives",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "88",
        "women": "17.0",
        "white": "88.8",
        "black": "9.2",
        "asian": "0.4",
        "hispanicOrLatino": "9.4"
      }
    },
    "wageData": {
      "2018": {
        "workers": "85",
        "median": "1,460",
        "maleWorkers": "74",
        "maleMedian": "1,461",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "First-line supervisors of fire fighting and prevention workers": {
    "ageData": {
      "2018": {
        "total": "49",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "17",
        "45to54": "17",
        "55to64": "11",
        "65+": "1",
        "medianAge": "11"
      }
    },
    "title": "First-line supervisors of fire fighting and prevention workers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "49",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "52",
        "median": "1,304",
        "maleWorkers": "50",
        "maleMedian": "1,321",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "First-line supervisors of protective service workers, all other": {
    "ageData": {
      "2018": {
        "total": "86",
        "16to19": "0",
        "20to24": "4",
        "25to34": "16",
        "35to44": "14",
        "45to54": "29",
        "55to64": "18",
        "65+": "6",
        "medianAge": "18"
      }
    },
    "title": "First-line supervisors of protective service workers, all other",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "86",
        "women": "22.4",
        "white": "72.5",
        "black": "22.1",
        "asian": "1.8",
        "hispanicOrLatino": "8.7"
      }
    },
    "wageData": {
      "2018": {
        "workers": "74",
        "median": "839",
        "maleWorkers": "58",
        "maleMedian": "886",
        "femaleWorkers": "16",
        "femaleMedian": "-"
      }
    }
  },
  "Firefighters": {
    "ageData": {
      "2018": {
        "total": "306",
        "16to19": "2",
        "20to24": "19",
        "25to34": "87",
        "35to44": "117",
        "45to54": "59",
        "55to64": "21",
        "65+": "1",
        "medianAge": "21"
      }
    },
    "title": "Firefighters",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "306",
        "women": "5.1",
        "white": "87.1",
        "black": "8.9",
        "asian": "1.1",
        "hispanicOrLatino": "7.4"
      }
    },
    "wageData": {
      "2018": {
        "workers": "295",
        "median": "1,126",
        "maleWorkers": "280",
        "maleMedian": "1,133",
        "femaleWorkers": "14",
        "femaleMedian": "-"
      }
    }
  },
  "Fire inspectors": {
    "ageData": {
      "2018": {
        "total": "23",
        "16to19": "0",
        "20to24": "1",
        "25to34": "5",
        "35to44": "6",
        "45to54": "6",
        "55to64": "4",
        "65+": "1",
        "medianAge": "4"
      }
    },
    "title": "Fire inspectors",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "23",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "18",
        "median": "-",
        "maleWorkers": "16",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Bailiffs, correctional officers, and jailers": {
    "ageData": {
      "2018": {
        "total": "406",
        "16to19": "2",
        "20to24": "38",
        "25to34": "142",
        "35to44": "81",
        "45to54": "79",
        "55to64": "50",
        "65+": "13",
        "medianAge": "50"
      }
    },
    "title": "Bailiffs, correctional officers, and jailers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "406",
        "women": "29.6",
        "white": "67.0",
        "black": "29.0",
        "asian": "1.2",
        "hispanicOrLatino": "13.7"
      }
    },
    "wageData": {
      "2018": {
        "workers": "394",
        "median": "742",
        "maleWorkers": "289",
        "maleMedian": "811",
        "femaleWorkers": "105",
        "femaleMedian": "646"
      }
    }
  },
  "Detectives and criminal investigators": {
    "ageData": {
      "2018": {
        "total": "164",
        "16to19": "0",
        "20to24": "2",
        "25to34": "34",
        "35to44": "53",
        "45to54": "53",
        "55to64": "19",
        "65+": "3",
        "medianAge": "19"
      }
    },
    "title": "Detectives and criminal investigators",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "164",
        "women": "24.4",
        "white": "85.3",
        "black": "10.2",
        "asian": "1.0",
        "hispanicOrLatino": "11.2"
      }
    },
    "wageData": {
      "2018": {
        "workers": "157",
        "median": "1,331",
        "maleWorkers": "122",
        "maleMedian": "1,317",
        "femaleWorkers": "35",
        "femaleMedian": "-"
      }
    }
  },
  "Fish and game wardens": {
    "ageData": {
      "2018": {
        "total": "7",
        "16to19": "0",
        "20to24": "0",
        "25to34": "4",
        "35to44": "1",
        "45to54": "2",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Fish and game wardens",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Parking enforcement workers": {
    "ageData": {
      "2018": {
        "total": "4",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "3",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Parking enforcement workers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Police and sheriff's patrol officers": {
    "ageData": {
      "2018": {
        "total": "728",
        "16to19": "2",
        "20to24": "46",
        "25to34": "216",
        "35to44": "213",
        "45to54": "178",
        "55to64": "56",
        "65+": "17",
        "medianAge": "56"
      }
    },
    "title": "Police and sheriff's patrol officers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "728",
        "women": "15.4",
        "white": "81.7",
        "black": "12.7",
        "asian": "2.1",
        "hispanicOrLatino": "13.5"
      }
    },
    "wageData": {
      "2018": {
        "workers": "728",
        "median": "1,092",
        "maleWorkers": "622",
        "maleMedian": "1,131",
        "femaleWorkers": "106",
        "femaleMedian": "816"
      }
    }
  },
  "Transit and railroad police": {
    "ageData": {
      "2018": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Transit and railroad police",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Animal control workers": {
    "ageData": {
      "2018": {
        "total": "19",
        "16to19": "0",
        "20to24": "1",
        "25to34": "8",
        "35to44": "2",
        "45to54": "4",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Animal control workers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "19",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Private detectives and investigators": {
    "ageData": {
      "2018": {
        "total": "96",
        "16to19": "0",
        "20to24": "4",
        "25to34": "32",
        "35to44": "23",
        "45to54": "15",
        "55to64": "17",
        "65+": "5",
        "medianAge": "17"
      }
    },
    "title": "Private detectives and investigators",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "96",
        "women": "49.5",
        "white": "78.2",
        "black": "15.7",
        "asian": "3.3",
        "hispanicOrLatino": "20.2"
      }
    },
    "wageData": {
      "2018": {
        "workers": "89",
        "median": "851",
        "maleWorkers": "43",
        "maleMedian": "-",
        "femaleWorkers": "46",
        "femaleMedian": "-"
      }
    }
  },
  "Security guards and gaming surveillance officers": {
    "ageData": {
      "2018": {
        "total": "958",
        "16to19": "18",
        "20to24": "141",
        "25to34": "237",
        "35to44": "144",
        "45to54": "160",
        "55to64": "174",
        "65+": "84",
        "medianAge": "174"
      }
    },
    "title": "Security guards and gaming surveillance officers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "958",
        "women": "22.4",
        "white": "60.0",
        "black": "30.7",
        "asian": "3.9",
        "hispanicOrLatino": "16.8"
      }
    },
    "wageData": {
      "2018": {
        "workers": "781",
        "median": "596",
        "maleWorkers": "594",
        "maleMedian": "636",
        "femaleWorkers": "187",
        "femaleMedian": "506"
      }
    }
  },
  "Crossing guards": {
    "ageData": {
      "2018": {
        "total": "66",
        "16to19": "0",
        "20to24": "2",
        "25to34": "10",
        "35to44": "10",
        "45to54": "14",
        "55to64": "15",
        "65+": "14",
        "medianAge": "15"
      }
    },
    "title": "Crossing guards",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "66",
        "women": "53.2",
        "white": "58.4",
        "black": "26.5",
        "asian": "1.9",
        "hispanicOrLatino": "20.9"
      }
    },
    "wageData": {
      "2018": {
        "workers": "28",
        "median": "-",
        "maleWorkers": "22",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Transportation security screeners": {
    "ageData": {
      "2018": {
        "total": "41",
        "16to19": "0",
        "20to24": "5",
        "25to34": "13",
        "35to44": "10",
        "45to54": "5",
        "55to64": "5",
        "65+": "2",
        "medianAge": "5"
      }
    },
    "title": "Transportation security screeners",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "41",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "33",
        "median": "-",
        "maleWorkers": "27",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Lifeguards and other recreational, and all other protective service workers": {
    "ageData": {
      "2018": {
        "total": "117",
        "16to19": "72",
        "20to24": "22",
        "25to34": "6",
        "35to44": "5",
        "45to54": "5",
        "55to64": "3",
        "65+": "3",
        "medianAge": "3"
      }
    },
    "title": "Lifeguards and other recreational, and all other protective service workers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "117",
        "women": "46.3",
        "white": "85.2",
        "black": "6.5",
        "asian": "4.0",
        "hispanicOrLatino": "9.7"
      }
    },
    "wageData": {
      "2018": {
        "workers": "31",
        "median": "-",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "16",
        "femaleMedian": "-"
      }
    }
  },
  "Food preparation and serving related occupations": {
    "ageData": {
      "2018": {
        "total": "8,220",
        "16to19": "1,364",
        "20to24": "1,739",
        "25to34": "1,928",
        "35to44": "1,187",
        "45to54": "1,001",
        "55to64": "739",
        "65+": "262",
        "medianAge": "739"
      }
    },
    "title": "Food preparation and serving related occupations",
    "depth": 2,
    "parent": "Service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "8,220",
        "women": "55.5",
        "white": "73.2",
        "black": "14.8",
        "asian": "6.5",
        "hispanicOrLatino": "25.9"
      }
    },
    "wageData": {
      "2018": {
        "workers": "4,394",
        "median": "501",
        "maleWorkers": "2,210",
        "maleMedian": "533",
        "femaleWorkers": "2,184",
        "femaleMedian": "473"
      }
    }
  },
  "Chefs and head cooks": {
    "ageData": {
      "2018": {
        "total": "427",
        "16to19": "13",
        "20to24": "34",
        "25to34": "122",
        "35to44": "102",
        "45to54": "91",
        "55to64": "47",
        "65+": "18",
        "medianAge": "47"
      }
    },
    "title": "Chefs and head cooks",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "427",
        "women": "22.0",
        "white": "61.0",
        "black": "17.2",
        "asian": "16.6",
        "hispanicOrLatino": "26.6"
      }
    },
    "wageData": {
      "2018": {
        "workers": "365",
        "median": "615",
        "maleWorkers": "300",
        "maleMedian": "624",
        "femaleWorkers": "65",
        "femaleMedian": "562"
      }
    }
  },
  "First-line supervisors of food preparation and serving workers": {
    "ageData": {
      "2018": {
        "total": "563",
        "16to19": "25",
        "20to24": "89",
        "25to34": "177",
        "35to44": "92",
        "45to54": "88",
        "55to64": "72",
        "65+": "20",
        "medianAge": "72"
      }
    },
    "title": "First-line supervisors of food preparation and serving workers",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "563",
        "women": "57.2",
        "white": "74.5",
        "black": "17.8",
        "asian": "2.8",
        "hispanicOrLatino": "16.8"
      }
    },
    "wageData": {
      "2018": {
        "workers": "397",
        "median": "583",
        "maleWorkers": "166",
        "maleMedian": "668",
        "femaleWorkers": "231",
        "femaleMedian": "539"
      }
    }
  },
  "Cooks": {
    "ageData": {
      "2018": {
        "total": "2,067",
        "16to19": "251",
        "20to24": "312",
        "25to34": "458",
        "35to44": "410",
        "45to54": "339",
        "55to64": "221",
        "65+": "76",
        "medianAge": "221"
      }
    },
    "title": "Cooks",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "2,067",
        "women": "41.8",
        "white": "71.7",
        "black": "17.6",
        "asian": "6.3",
        "hispanicOrLatino": "34.7"
      }
    },
    "wageData": {
      "2018": {
        "workers": "1,325",
        "median": "486",
        "maleWorkers": "804",
        "maleMedian": "518",
        "femaleWorkers": "521",
        "femaleMedian": "437"
      }
    }
  },
  "Food preparation workers": {
    "ageData": {
      "2018": {
        "total": "988",
        "16to19": "179",
        "20to24": "252",
        "25to34": "195",
        "35to44": "120",
        "45to54": "113",
        "55to64": "92",
        "65+": "35",
        "medianAge": "92"
      }
    },
    "title": "Food preparation workers",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "988",
        "women": "61.0",
        "white": "71.1",
        "black": "15.9",
        "asian": "6.2",
        "hispanicOrLatino": "26.2"
      }
    },
    "wageData": {
      "2018": {
        "workers": "473",
        "median": "463",
        "maleWorkers": "206",
        "maleMedian": "486",
        "femaleWorkers": "267",
        "femaleMedian": "446"
      }
    }
  },
  "Bartenders": {
    "ageData": {
      "2018": {
        "total": "474",
        "16to19": "5",
        "20to24": "87",
        "25to34": "208",
        "35to44": "76",
        "45to54": "54",
        "55to64": "32",
        "65+": "13",
        "medianAge": "32"
      }
    },
    "title": "Bartenders",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "474",
        "women": "57.2",
        "white": "84.3",
        "black": "7.3",
        "asian": "2.9",
        "hispanicOrLatino": "15.1"
      }
    },
    "wageData": {
      "2018": {
        "workers": "275",
        "median": "581",
        "maleWorkers": "124",
        "maleMedian": "601",
        "femaleWorkers": "151",
        "femaleMedian": "560"
      }
    }
  },
  "Combined food preparation and serving workers, including fast food": {
    "ageData": {
      "2018": {
        "total": "322",
        "16to19": "79",
        "20to24": "71",
        "25to34": "71",
        "35to44": "25",
        "45to54": "28",
        "55to64": "32",
        "65+": "15",
        "medianAge": "32"
      }
    },
    "title": "Combined food preparation and serving workers, including fast food",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "322",
        "women": "63.0",
        "white": "73.2",
        "black": "17.8",
        "asian": "3.5",
        "hispanicOrLatino": "21.0"
      }
    },
    "wageData": {
      "2018": {
        "workers": "147",
        "median": "436",
        "maleWorkers": "64",
        "maleMedian": "410",
        "femaleWorkers": "83",
        "femaleMedian": "475"
      }
    }
  },
  "Counter attendants, cafeteria, food concession, and coffee shop": {
    "ageData": {
      "2018": {
        "total": "202",
        "16to19": "101",
        "20to24": "46",
        "25to34": "25",
        "35to44": "10",
        "45to54": "6",
        "55to64": "7",
        "65+": "6",
        "medianAge": "7"
      }
    },
    "title": "Counter attendants, cafeteria, food concession, and coffee shop",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "202",
        "women": "68.8",
        "white": "74.6",
        "black": "15.3",
        "asian": "4.9",
        "hispanicOrLatino": "18.0"
      }
    },
    "wageData": {
      "2018": {
        "workers": "41",
        "median": "-",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "22",
        "femaleMedian": "-"
      }
    }
  },
  "Waiters and waitresses": {
    "ageData": {
      "2018": {
        "total": "2,020",
        "16to19": "366",
        "20to24": "602",
        "25to34": "494",
        "35to44": "233",
        "45to54": "165",
        "55to64": "129",
        "65+": "31",
        "medianAge": "129"
      }
    },
    "title": "Waiters and waitresses",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "2,020",
        "women": "69.9",
        "white": "76.0",
        "black": "10.7",
        "asian": "7.6",
        "hispanicOrLatino": "22.6"
      }
    },
    "wageData": {
      "2018": {
        "workers": "932",
        "median": "496",
        "maleWorkers": "321",
        "maleMedian": "551",
        "femaleWorkers": "611",
        "femaleMedian": "478"
      }
    }
  },
  "Food servers, nonrestaurant": {
    "ageData": {
      "2018": {
        "total": "226",
        "16to19": "35",
        "20to24": "49",
        "25to34": "42",
        "35to44": "25",
        "45to54": "27",
        "55to64": "36",
        "65+": "13",
        "medianAge": "36"
      }
    },
    "title": "Food servers, nonrestaurant",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "226",
        "women": "70.2",
        "white": "61.2",
        "black": "25.5",
        "asian": "8.4",
        "hispanicOrLatino": "22.2"
      }
    },
    "wageData": {
      "2018": {
        "workers": "106",
        "median": "484",
        "maleWorkers": "32",
        "maleMedian": "-",
        "femaleWorkers": "74",
        "femaleMedian": "475"
      }
    }
  },
  "Dining room and cafeteria attendants and bartender helpers": {
    "ageData": {
      "2018": {
        "total": "309",
        "16to19": "81",
        "20to24": "52",
        "25to34": "51",
        "35to44": "36",
        "45to54": "40",
        "55to64": "36",
        "65+": "12",
        "medianAge": "36"
      }
    },
    "title": "Dining room and cafeteria attendants and bartender helpers",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "309",
        "women": "45.7",
        "white": "73.1",
        "black": "13.5",
        "asian": "6.0",
        "hispanicOrLatino": "29.2"
      }
    },
    "wageData": {
      "2018": {
        "workers": "118",
        "median": "447",
        "maleWorkers": "59",
        "maleMedian": "500",
        "femaleWorkers": "58",
        "femaleMedian": "408"
      }
    }
  },
  "Dishwashers": {
    "ageData": {
      "2018": {
        "total": "263",
        "16to19": "66",
        "20to24": "38",
        "25to34": "44",
        "35to44": "41",
        "45to54": "37",
        "55to64": "24",
        "65+": "12",
        "medianAge": "24"
      }
    },
    "title": "Dishwashers",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "263",
        "women": "24.3",
        "white": "74.3",
        "black": "13.2",
        "asian": "5.7",
        "hispanicOrLatino": "27.9"
      }
    },
    "wageData": {
      "2018": {
        "workers": "111",
        "median": "441",
        "maleWorkers": "89",
        "maleMedian": "434",
        "femaleWorkers": "22",
        "femaleMedian": "-"
      }
    }
  },
  "Hosts and hostesses, restaurant, lounge, and coffee shop": {
    "ageData": {
      "2018": {
        "total": "354",
        "16to19": "160",
        "20to24": "105",
        "25to34": "37",
        "35to44": "17",
        "45to54": "13",
        "55to64": "11",
        "65+": "10",
        "medianAge": "11"
      }
    },
    "title": "Hosts and hostesses, restaurant, lounge, and coffee shop",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "354",
        "women": "81.6",
        "white": "75.1",
        "black": "12.9",
        "asian": "4.5",
        "hispanicOrLatino": "27.0"
      }
    },
    "wageData": {
      "2018": {
        "workers": "102",
        "median": "471",
        "maleWorkers": "24",
        "maleMedian": "-",
        "femaleWorkers": "78",
        "femaleMedian": "473"
      }
    }
  },
  "Food preparation and serving related workers, all other": {
    "ageData": {
      "2018": {
        "total": "5",
        "16to19": "1",
        "20to24": "0",
        "25to34": "3",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Food preparation and serving related workers, all other",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Building and grounds cleaning and maintenance occupations": {
    "ageData": {
      "2018": {
        "total": "5,854",
        "16to19": "189",
        "20to24": "423",
        "25to34": "1,118",
        "35to44": "1,181",
        "45to54": "1,367",
        "55to64": "1,152",
        "65+": "424",
        "medianAge": "1,152"
      }
    },
    "title": "Building and grounds cleaning and maintenance occupations",
    "depth": 2,
    "parent": "Service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "5,854",
        "women": "41.3",
        "white": "77.3",
        "black": "14.8",
        "asian": "3.1",
        "hispanicOrLatino": "38.8"
      }
    },
    "wageData": {
      "2018": {
        "workers": "3,695",
        "median": "551",
        "maleWorkers": "2,373",
        "maleMedian": "604",
        "femaleWorkers": "1,322",
        "femaleMedian": "477"
      }
    }
  },
  "First-line supervisors of housekeeping and janitorial workers": {
    "ageData": {
      "2018": {
        "total": "339",
        "16to19": "1",
        "20to24": "11",
        "25to34": "51",
        "35to44": "74",
        "45to54": "106",
        "55to64": "75",
        "65+": "22",
        "medianAge": "75"
      }
    },
    "title": "First-line supervisors of housekeeping and janitorial workers",
    "depth": 3,
    "parent": "Building and grounds cleaning and maintenance occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "339",
        "women": "42.4",
        "white": "75.5",
        "black": "15.9",
        "asian": "3.6",
        "hispanicOrLatino": "25.1"
      }
    },
    "wageData": {
      "2018": {
        "workers": "193",
        "median": "711",
        "maleWorkers": "116",
        "maleMedian": "772",
        "femaleWorkers": "77",
        "femaleMedian": "615"
      }
    }
  },
  "First-line supervisors of landscaping, lawn service, and groundskeeping workers": {
    "ageData": {
      "2018": {
        "total": "254",
        "16to19": "3",
        "20to24": "7",
        "25to34": "48",
        "35to44": "67",
        "45to54": "64",
        "55to64": "45",
        "65+": "20",
        "medianAge": "45"
      }
    },
    "title": "First-line supervisors of landscaping, lawn service, and groundskeeping workers",
    "depth": 3,
    "parent": "Building and grounds cleaning and maintenance occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "254",
        "women": "8.0",
        "white": "88.3",
        "black": "6.5",
        "asian": "1.5",
        "hispanicOrLatino": "26.2"
      }
    },
    "wageData": {
      "2018": {
        "workers": "95",
        "median": "770",
        "maleWorkers": "93",
        "maleMedian": "774",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Janitors and building cleaners": {
    "ageData": {
      "2018": {
        "total": "2,342",
        "16to19": "89",
        "20to24": "152",
        "25to34": "403",
        "35to44": "423",
        "45to54": "542",
        "55to64": "535",
        "65+": "197",
        "medianAge": "535"
      }
    },
    "title": "Janitors and building cleaners",
    "depth": 3,
    "parent": "Building and grounds cleaning and maintenance occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "2,342",
        "women": "34.1",
        "white": "73.0",
        "black": "19.1",
        "asian": "3.5",
        "hispanicOrLatino": "32.8"
      }
    },
    "wageData": {
      "2018": {
        "workers": "1,672",
        "median": "571",
        "maleWorkers": "1,193",
        "maleMedian": "607",
        "femaleWorkers": "480",
        "femaleMedian": "491"
      }
    }
  },
  "Maids and housekeeping cleaners": {
    "ageData": {
      "2018": {
        "total": "1,512",
        "16to19": "35",
        "20to24": "93",
        "25to34": "247",
        "35to44": "323",
        "45to54": "399",
        "55to64": "309",
        "65+": "107",
        "medianAge": "309"
      }
    },
    "title": "Maids and housekeeping cleaners",
    "depth": 3,
    "parent": "Building and grounds cleaning and maintenance occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "1,512",
        "women": "90.1",
        "white": "74.1",
        "black": "16.1",
        "asian": "4.5",
        "hispanicOrLatino": "49.3"
      }
    },
    "wageData": {
      "2018": {
        "workers": "825",
        "median": "467",
        "maleWorkers": "102",
        "maleMedian": "559",
        "femaleWorkers": "723",
        "femaleMedian": "457"
      }
    }
  },
  "Pest control workers": {
    "ageData": {
      "2018": {
        "total": "79",
        "16to19": "1",
        "20to24": "8",
        "25to34": "20",
        "35to44": "16",
        "45to54": "17",
        "55to64": "15",
        "65+": "1",
        "medianAge": "15"
      }
    },
    "title": "Pest control workers",
    "depth": 3,
    "parent": "Building and grounds cleaning and maintenance occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "79",
        "women": "8.4",
        "white": "84.9",
        "black": "10.6",
        "asian": "0.0",
        "hispanicOrLatino": "12.2"
      }
    },
    "wageData": {
      "2018": {
        "workers": "75",
        "median": "688",
        "maleWorkers": "70",
        "maleMedian": "725",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Grounds maintenance workers": {
    "ageData": {
      "2018": {
        "total": "1,328",
        "16to19": "61",
        "20to24": "152",
        "25to34": "348",
        "35to44": "277",
        "45to54": "239",
        "55to64": "173",
        "65+": "77",
        "medianAge": "173"
      }
    },
    "title": "Grounds maintenance workers",
    "depth": 3,
    "parent": "Building and grounds cleaning and maintenance occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "1,328",
        "women": "6.7",
        "white": "86.5",
        "black": "7.5",
        "asian": "1.0",
        "hispanicOrLatino": "45.0"
      }
    },
    "wageData": {
      "2018": {
        "workers": "834",
        "median": "572",
        "maleWorkers": "798",
        "maleMedian": "576",
        "femaleWorkers": "36",
        "femaleMedian": "-"
      }
    }
  },
  "Personal care and service occupations": {
    "ageData": {
      "2018": {
        "total": "5,947",
        "16to19": "347",
        "20to24": "730",
        "25to34": "1,324",
        "35to44": "1,077",
        "45to54": "1,088",
        "55to64": "905",
        "65+": "476",
        "medianAge": "905"
      }
    },
    "title": "Personal care and service occupations",
    "depth": 2,
    "parent": "Service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "5,947",
        "women": "76.9",
        "white": "69.2",
        "black": "16.3",
        "asian": "10.5",
        "hispanicOrLatino": "17.8"
      }
    },
    "wageData": {
      "2018": {
        "workers": "2,768",
        "median": "544",
        "maleWorkers": "746",
        "maleMedian": "638",
        "femaleWorkers": "2,022",
        "femaleMedian": "517"
      }
    }
  },
  "First-line supervisors of gaming workers": {
    "ageData": {
      "2018": {
        "total": "187",
        "16to19": "6",
        "20to24": "18",
        "25to34": "54",
        "35to44": "31",
        "45to54": "41",
        "55to64": "22",
        "65+": "14",
        "medianAge": "22"
      }
    },
    "title": "First-line supervisors of gaming workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "187",
        "women": "44.4",
        "white": "79.8",
        "black": "10.4",
        "asian": "4.1",
        "hispanicOrLatino": "10.2"
      }
    },
    "wageData": {
      "2018": {
        "workers": "125",
        "median": "793",
        "maleWorkers": "83",
        "maleMedian": "914",
        "femaleWorkers": "43",
        "femaleMedian": "-"
      }
    }
  },
  "First-line supervisors of personal service workers": {
    "ageData": {
      "2018": {
        "total": "208",
        "16to19": "1",
        "20to24": "10",
        "25to34": "47",
        "35to44": "52",
        "45to54": "53",
        "55to64": "31",
        "65+": "14",
        "medianAge": "31"
      }
    },
    "title": "First-line supervisors of personal service workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "208",
        "women": "73.3",
        "white": "69.5",
        "black": "11.9",
        "asian": "17.8",
        "hispanicOrLatino": "9.5"
      }
    },
    "wageData": {
      "2018": {
        "workers": "86",
        "median": "747",
        "maleWorkers": "27",
        "maleMedian": "-",
        "femaleWorkers": "60",
        "femaleMedian": "739"
      }
    }
  },
  "Animal trainers": {
    "ageData": {
      "2018": {
        "total": "55",
        "16to19": "6",
        "20to24": "4",
        "25to34": "19",
        "35to44": "7",
        "45to54": "10",
        "55to64": "5",
        "65+": "5",
        "medianAge": "5"
      }
    },
    "title": "Animal trainers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "55",
        "women": "72.6",
        "white": "87.2",
        "black": "1.7",
        "asian": "4.4",
        "hispanicOrLatino": "10.5"
      }
    },
    "wageData": {
      "2018": {
        "workers": "11",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Nonfarm animal caretakers": {
    "ageData": {
      "2018": {
        "total": "268",
        "16to19": "29",
        "20to24": "51",
        "25to34": "64",
        "35to44": "41",
        "45to54": "34",
        "55to64": "39",
        "65+": "10",
        "medianAge": "39"
      }
    },
    "title": "Nonfarm animal caretakers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "268",
        "women": "77.6",
        "white": "92.1",
        "black": "3.8",
        "asian": "1.8",
        "hispanicOrLatino": "9.8"
      }
    },
    "wageData": {
      "2018": {
        "workers": "102",
        "median": "555",
        "maleWorkers": "24",
        "maleMedian": "-",
        "femaleWorkers": "79",
        "femaleMedian": "548"
      }
    }
  },
  "Gaming services workers": {
    "ageData": {
      "2018": {
        "total": "102",
        "16to19": "3",
        "20to24": "6",
        "25to34": "23",
        "35to44": "19",
        "45to54": "21",
        "55to64": "19",
        "65+": "10",
        "medianAge": "19"
      }
    },
    "title": "Gaming services workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "102",
        "women": "47.8",
        "white": "49.7",
        "black": "10.6",
        "asian": "30.3",
        "hispanicOrLatino": "6.7"
      }
    },
    "wageData": {
      "2018": {
        "workers": "73",
        "median": "654",
        "maleWorkers": "37",
        "maleMedian": "-",
        "femaleWorkers": "36",
        "femaleMedian": "-"
      }
    }
  },
  "Motion picture projectionists": {
    "ageData": {
      "2018": {
        "total": "1",
        "16to19": "1",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Motion picture projectionists",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "0",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Ushers, lobby attendants, and ticket takers": {
    "ageData": {
      "2018": {
        "total": "35",
        "16to19": "12",
        "20to24": "6",
        "25to34": "5",
        "35to44": "2",
        "45to54": "2",
        "55to64": "3",
        "65+": "4",
        "medianAge": "3"
      }
    },
    "title": "Ushers, lobby attendants, and ticket takers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "35",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous entertainment attendants and related workers": {
    "ageData": {
      "2018": {
        "total": "210",
        "16to19": "58",
        "20to24": "59",
        "25to34": "30",
        "35to44": "11",
        "45to54": "13",
        "55to64": "20",
        "65+": "19",
        "medianAge": "20"
      }
    },
    "title": "Miscellaneous entertainment attendants and related workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "210",
        "women": "38.3",
        "white": "80.5",
        "black": "7.7",
        "asian": "5.5",
        "hispanicOrLatino": "16.8"
      }
    },
    "wageData": {
      "2018": {
        "workers": "85",
        "median": "523",
        "maleWorkers": "56",
        "maleMedian": "518",
        "femaleWorkers": "28",
        "femaleMedian": "-"
      }
    }
  },
  "Embalmers and funeral attendants": {
    "ageData": {
      "2018": {
        "total": "17",
        "16to19": "0",
        "20to24": "1",
        "25to34": "2",
        "35to44": "2",
        "45to54": "1",
        "55to64": "2",
        "65+": "8",
        "medianAge": "2"
      }
    },
    "title": "Embalmers and funeral attendants",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "17",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "11",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Morticians, undertakers, and funeral directors": {
    "ageData": {
      "2018": {
        "total": "34",
        "16to19": "0",
        "20to24": "1",
        "25to34": "4",
        "35to44": "4",
        "45to54": "6",
        "55to64": "12",
        "65+": "7",
        "medianAge": "12"
      }
    },
    "title": "Morticians, undertakers, and funeral directors",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "34",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "27",
        "median": "-",
        "maleWorkers": "20",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Barbers": {
    "ageData": {
      "2018": {
        "total": "132",
        "16to19": "0",
        "20to24": "10",
        "25to34": "33",
        "35to44": "29",
        "45to54": "24",
        "55to64": "17",
        "65+": "20",
        "medianAge": "17"
      }
    },
    "title": "Barbers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "132",
        "women": "21.0",
        "white": "62.0",
        "black": "30.2",
        "asian": "5.3",
        "hispanicOrLatino": "27.3"
      }
    },
    "wageData": {
      "2018": {
        "workers": "48",
        "median": "-",
        "maleWorkers": "34",
        "maleMedian": "-",
        "femaleWorkers": "15",
        "femaleMedian": "-"
      }
    }
  },
  "Hairdressers, hairstylists, and cosmetologists": {
    "ageData": {
      "2018": {
        "total": "834",
        "16to19": "11",
        "20to24": "82",
        "25to34": "210",
        "35to44": "192",
        "45to54": "161",
        "55to64": "107",
        "65+": "70",
        "medianAge": "107"
      }
    },
    "title": "Hairdressers, hairstylists, and cosmetologists",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "834",
        "women": "92.1",
        "white": "75.7",
        "black": "14.2",
        "asian": "7.3",
        "hispanicOrLatino": "17.6"
      }
    },
    "wageData": {
      "2018": {
        "workers": "309",
        "median": "545",
        "maleWorkers": "22",
        "maleMedian": "-",
        "femaleWorkers": "287",
        "femaleMedian": "548"
      }
    }
  },
  "Miscellaneous personal appearance workers": {
    "ageData": {
      "2018": {
        "total": "449",
        "16to19": "6",
        "20to24": "38",
        "25to34": "104",
        "35to44": "120",
        "45to54": "107",
        "55to64": "57",
        "65+": "17",
        "medianAge": "57"
      }
    },
    "title": "Miscellaneous personal appearance workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "449",
        "women": "83.6",
        "white": "31.2",
        "black": "9.1",
        "asian": "57.2",
        "hispanicOrLatino": "10.8"
      }
    },
    "wageData": {
      "2018": {
        "workers": "258",
        "median": "523",
        "maleWorkers": "52",
        "maleMedian": "615",
        "femaleWorkers": "206",
        "femaleMedian": "512"
      }
    }
  },
  "Baggage porters, bellhops, and concierges": {
    "ageData": {
      "2018": {
        "total": "92",
        "16to19": "3",
        "20to24": "13",
        "25to34": "21",
        "35to44": "13",
        "45to54": "15",
        "55to64": "22",
        "65+": "5",
        "medianAge": "22"
      }
    },
    "title": "Baggage porters, bellhops, and concierges",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "92",
        "women": "22.6",
        "white": "49.7",
        "black": "37.7",
        "asian": "10.3",
        "hispanicOrLatino": "26.1"
      }
    },
    "wageData": {
      "2018": {
        "workers": "73",
        "median": "620",
        "maleWorkers": "59",
        "maleMedian": "652",
        "femaleWorkers": "13",
        "femaleMedian": "-"
      }
    }
  },
  "Tour and travel guides": {
    "ageData": {
      "2018": {
        "total": "65",
        "16to19": "5",
        "20to24": "15",
        "25to34": "8",
        "35to44": "9",
        "45to54": "9",
        "55to64": "12",
        "65+": "7",
        "medianAge": "12"
      }
    },
    "title": "Tour and travel guides",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "65",
        "women": "43.6",
        "white": "85.0",
        "black": "6.4",
        "asian": "2.9",
        "hispanicOrLatino": "13.4"
      }
    },
    "wageData": {
      "2018": {
        "workers": "22",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "9",
        "femaleMedian": "-"
      }
    }
  },
  "Childcare workers": {
    "ageData": {
      "2018": {
        "total": "1,193",
        "16to19": "127",
        "20to24": "182",
        "25to34": "245",
        "35to44": "185",
        "45to54": "199",
        "55to64": "179",
        "65+": "76",
        "medianAge": "179"
      }
    },
    "title": "Childcare workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "1,193",
        "women": "94.0",
        "white": "76.7",
        "black": "15.5",
        "asian": "4.1",
        "hispanicOrLatino": "24.3"
      }
    },
    "wageData": {
      "2018": {
        "workers": "437",
        "median": "500",
        "maleWorkers": "32",
        "maleMedian": "-",
        "femaleWorkers": "405",
        "femaleMedian": "492"
      }
    }
  },
  "Personal care aides": {
    "ageData": {
      "2018": {
        "total": "1,354",
        "16to19": "36",
        "20to24": "134",
        "25to34": "269",
        "35to44": "225",
        "45to54": "285",
        "55to64": "267",
        "65+": "139",
        "medianAge": "267"
      }
    },
    "title": "Personal care aides",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "1,354",
        "women": "84.4",
        "white": "62.3",
        "black": "24.6",
        "asian": "8.3",
        "hispanicOrLatino": "22.4"
      }
    },
    "wageData": {
      "2018": {
        "workers": "786",
        "median": "500",
        "maleWorkers": "132",
        "maleMedian": "555",
        "femaleWorkers": "654",
        "femaleMedian": "493"
      }
    }
  },
  "Recreation and fitness workers": {
    "ageData": {
      "2018": {
        "total": "497",
        "16to19": "33",
        "20to24": "69",
        "25to34": "124",
        "35to44": "99",
        "45to54": "75",
        "55to64": "64",
        "65+": "33",
        "medianAge": "64"
      }
    },
    "title": "Recreation and fitness workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "497",
        "women": "60.5",
        "white": "76.0",
        "black": "15.6",
        "asian": "4.3",
        "hispanicOrLatino": "9.2"
      }
    },
    "wageData": {
      "2018": {
        "workers": "208",
        "median": "603",
        "maleWorkers": "100",
        "maleMedian": "724",
        "femaleWorkers": "109",
        "femaleMedian": "543"
      }
    }
  },
  "Residential advisors": {
    "ageData": {
      "2018": {
        "total": "41",
        "16to19": "2",
        "20to24": "11",
        "25to34": "11",
        "35to44": "5",
        "45to54": "6",
        "55to64": "5",
        "65+": "2",
        "medianAge": "5"
      }
    },
    "title": "Residential advisors",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "41",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "29",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "18",
        "femaleMedian": "-"
      }
    }
  },
  "Personal care and service workers, all other": {
    "ageData": {
      "2018": {
        "total": "171",
        "16to19": "10",
        "20to24": "20",
        "25to34": "49",
        "35to44": "30",
        "45to54": "23",
        "55to64": "22",
        "65+": "17",
        "medianAge": "22"
      }
    },
    "title": "Personal care and service workers, all other",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "171",
        "women": "68.1",
        "white": "72.2",
        "black": "17.8",
        "asian": "5.4",
        "hispanicOrLatino": "15.3"
      }
    },
    "wageData": {
      "2018": {
        "workers": "74",
        "median": "522",
        "maleWorkers": "32",
        "maleMedian": "-",
        "femaleWorkers": "42",
        "femaleMedian": "-"
      }
    }
  },
  "Sales and office occupations": {
    "ageData": {
      "2018": {
        "total": "33,461",
        "16to19": "1,714",
        "20to24": "3,995",
        "25to34": "7,106",
        "35to44": "6,099",
        "45to54": "6,471",
        "55to64": "5,752",
        "65+": "2,324",
        "medianAge": "5,752"
      }
    },
    "title": "Sales and office occupations",
    "depth": 1,
    "parent": "",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "33,461",
        "women": "61.1",
        "white": "78.6",
        "black": "12.8",
        "asian": "4.9",
        "hispanicOrLatino": "16.6"
      }
    },
    "wageData": {
      "2018": {
        "workers": "23,714",
        "median": "742",
        "maleWorkers": "9,548",
        "maleMedian": "846",
        "femaleWorkers": "14,166",
        "femaleMedian": "696"
      }
    }
  },
  "Sales and related occupations": {
    "ageData": {
      "2018": {
        "total": "15,806",
        "16to19": "1,142",
        "20to24": "2,000",
        "25to34": "3,210",
        "35to44": "2,827",
        "45to54": "2,941",
        "55to64": "2,512",
        "65+": "1,174",
        "medianAge": "2,512"
      }
    },
    "title": "Sales and related occupations",
    "depth": 2,
    "parent": "Sales and office occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "15,806",
        "women": "49.4",
        "white": "80.2",
        "black": "11.0",
        "asian": "5.3",
        "hispanicOrLatino": "16.3"
      }
    },
    "wageData": {
      "2018": {
        "workers": "10,077",
        "median": "798",
        "maleWorkers": "5,621",
        "maleMedian": "949",
        "femaleWorkers": "4,455",
        "femaleMedian": "651"
      }
    }
  },
  "First-line supervisors of retail sales workers": {
    "ageData": {
      "2018": {
        "total": "3,366",
        "16to19": "30",
        "20to24": "253",
        "25to34": "832",
        "35to44": "745",
        "45to54": "710",
        "55to64": "576",
        "65+": "218",
        "medianAge": "576"
      }
    },
    "title": "First-line supervisors of retail sales workers",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "3,366",
        "women": "44.6",
        "white": "80.8",
        "black": "10.2",
        "asian": "5.3",
        "hispanicOrLatino": "13.9"
      }
    },
    "wageData": {
      "2018": {
        "workers": "2,421",
        "median": "795",
        "maleWorkers": "1,359",
        "maleMedian": "911",
        "femaleWorkers": "1,062",
        "femaleMedian": "672"
      }
    }
  },
  "First-line supervisors of non-retail sales workers": {
    "ageData": {
      "2018": {
        "total": "1,257",
        "16to19": "4",
        "20to24": "27",
        "25to34": "218",
        "35to44": "281",
        "45to54": "337",
        "55to64": "270",
        "65+": "119",
        "medianAge": "270"
      }
    },
    "title": "First-line supervisors of non-retail sales workers",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "1,257",
        "women": "28.5",
        "white": "84.7",
        "black": "6.9",
        "asian": "6.4",
        "hispanicOrLatino": "15.0"
      }
    },
    "wageData": {
      "2018": {
        "workers": "893",
        "median": "1,153",
        "maleWorkers": "588",
        "maleMedian": "1,199",
        "femaleWorkers": "305",
        "femaleMedian": "1,054"
      }
    }
  },
  "Cashiers": {
    "ageData": {
      "2018": {
        "total": "3,200",
        "16to19": "792",
        "20to24": "774",
        "25to34": "539",
        "35to44": "336",
        "45to54": "298",
        "55to64": "302",
        "65+": "158",
        "medianAge": "302"
      }
    },
    "title": "Cashiers",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "3,200",
        "women": "73.8",
        "white": "70.3",
        "black": "17.3",
        "asian": "6.6",
        "hispanicOrLatino": "24.5"
      }
    },
    "wageData": {
      "2018": {
        "workers": "1,390",
        "median": "465",
        "maleWorkers": "377",
        "maleMedian": "468",
        "femaleWorkers": "1,013",
        "femaleMedian": "463"
      }
    }
  },
  "Counter and rental clerks": {
    "ageData": {
      "2018": {
        "total": "114",
        "16to19": "9",
        "20to24": "19",
        "25to34": "27",
        "35to44": "24",
        "45to54": "14",
        "55to64": "16",
        "65+": "4",
        "medianAge": "16"
      }
    },
    "title": "Counter and rental clerks",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "114",
        "women": "40.6",
        "white": "71.4",
        "black": "20.4",
        "asian": "5.8",
        "hispanicOrLatino": "24.6"
      }
    },
    "wageData": {
      "2018": {
        "workers": "82",
        "median": "729",
        "maleWorkers": "51",
        "maleMedian": "769",
        "femaleWorkers": "30",
        "femaleMedian": "-"
      }
    }
  },
  "Parts salespersons": {
    "ageData": {
      "2018": {
        "total": "127",
        "16to19": "4",
        "20to24": "11",
        "25to34": "22",
        "35to44": "26",
        "45to54": "23",
        "55to64": "30",
        "65+": "11",
        "medianAge": "30"
      }
    },
    "title": "Parts salespersons",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "127",
        "women": "10.4",
        "white": "82.2",
        "black": "9.2",
        "asian": "2.8",
        "hispanicOrLatino": "19.3"
      }
    },
    "wageData": {
      "2018": {
        "workers": "109",
        "median": "702",
        "maleWorkers": "98",
        "maleMedian": "718",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "Retail salespersons": {
    "ageData": {
      "2018": {
        "total": "3,147",
        "16to19": "272",
        "20to24": "607",
        "25to34": "687",
        "35to44": "419",
        "45to54": "476",
        "55to64": "446",
        "65+": "239",
        "medianAge": "446"
      }
    },
    "title": "Retail salespersons",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "3,147",
        "women": "48.7",
        "white": "78.7",
        "black": "12.6",
        "asian": "5.1",
        "hispanicOrLatino": "18.2"
      }
    },
    "wageData": {
      "2018": {
        "workers": "1,860",
        "median": "657",
        "maleWorkers": "1,144",
        "maleMedian": "764",
        "femaleWorkers": "716",
        "femaleMedian": "543"
      }
    }
  },
  "Advertising sales agents": {
    "ageData": {
      "2018": {
        "total": "221",
        "16to19": "2",
        "20to24": "21",
        "25to34": "58",
        "35to44": "49",
        "45to54": "56",
        "55to64": "28",
        "65+": "8",
        "medianAge": "28"
      }
    },
    "title": "Advertising sales agents",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "221",
        "women": "50.4",
        "white": "81.2",
        "black": "10.4",
        "asian": "4.8",
        "hispanicOrLatino": "14.9"
      }
    },
    "wageData": {
      "2018": {
        "workers": "169",
        "median": "998",
        "maleWorkers": "91",
        "maleMedian": "950",
        "femaleWorkers": "79",
        "femaleMedian": "1,053"
      }
    }
  },
  "Insurance sales agents": {
    "ageData": {
      "2018": {
        "total": "619",
        "16to19": "1",
        "20to24": "40",
        "25to34": "117",
        "35to44": "153",
        "45to54": "115",
        "55to64": "131",
        "65+": "62",
        "medianAge": "131"
      }
    },
    "title": "Insurance sales agents",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "619",
        "women": "51.4",
        "white": "83.3",
        "black": "8.7",
        "asian": "5.3",
        "hispanicOrLatino": "11.6"
      }
    },
    "wageData": {
      "2018": {
        "workers": "466",
        "median": "859",
        "maleWorkers": "205",
        "maleMedian": "1,048",
        "femaleWorkers": "261",
        "femaleMedian": "809"
      }
    }
  },
  "Securities, commodities, and financial services sales agents": {
    "ageData": {
      "2018": {
        "total": "231",
        "16to19": "1",
        "20to24": "14",
        "25to34": "58",
        "35to44": "53",
        "45to54": "46",
        "55to64": "41",
        "65+": "19",
        "medianAge": "41"
      }
    },
    "title": "Securities, commodities, and financial services sales agents",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "231",
        "women": "31.8",
        "white": "85.6",
        "black": "6.3",
        "asian": "6.8",
        "hispanicOrLatino": "11.9"
      }
    },
    "wageData": {
      "2018": {
        "workers": "197",
        "median": "1,440",
        "maleWorkers": "131",
        "maleMedian": "1,639",
        "femaleWorkers": "66",
        "femaleMedian": "1,047"
      }
    }
  },
  "Travel agents": {
    "ageData": {
      "2018": {
        "total": "79",
        "16to19": "0",
        "20to24": "3",
        "25to34": "11",
        "35to44": "13",
        "45to54": "21",
        "55to64": "19",
        "65+": "12",
        "medianAge": "19"
      }
    },
    "title": "Travel agents",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "79",
        "women": "85.5",
        "white": "77.9",
        "black": "17.7",
        "asian": "4.4",
        "hispanicOrLatino": "9.9"
      }
    },
    "wageData": {
      "2018": {
        "workers": "53",
        "median": "789",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "43",
        "femaleMedian": "-"
      }
    }
  },
  "Sales representatives, services, all other": {
    "ageData": {
      "2018": {
        "total": "516",
        "16to19": "3",
        "20to24": "47",
        "25to34": "124",
        "35to44": "108",
        "45to54": "127",
        "55to64": "79",
        "65+": "28",
        "medianAge": "79"
      }
    },
    "title": "Sales representatives, services, all other",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "516",
        "women": "30.0",
        "white": "89.0",
        "black": "6.5",
        "asian": "2.9",
        "hispanicOrLatino": "7.5"
      }
    },
    "wageData": {
      "2018": {
        "workers": "427",
        "median": "1,230",
        "maleWorkers": "315",
        "maleMedian": "1,345",
        "femaleWorkers": "113",
        "femaleMedian": "933"
      }
    }
  },
  "Sales representatives, wholesale and manufacturing": {
    "ageData": {
      "2018": {
        "total": "1,273",
        "16to19": "9",
        "20to24": "64",
        "25to34": "228",
        "35to44": "288",
        "45to54": "342",
        "55to64": "262",
        "65+": "80",
        "medianAge": "262"
      }
    },
    "title": "Sales representatives, wholesale and manufacturing",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "1,273",
        "women": "27.6",
        "white": "90.8",
        "black": "4.1",
        "asian": "2.9",
        "hispanicOrLatino": "11.2"
      }
    },
    "wageData": {
      "2018": {
        "workers": "1,112",
        "median": "1,161",
        "maleWorkers": "817",
        "maleMedian": "1,239",
        "femaleWorkers": "295",
        "femaleMedian": "1,009"
      }
    }
  },
  "Models, demonstrators, and product promoters": {
    "ageData": {
      "2018": {
        "total": "60",
        "16to19": "4",
        "20to24": "9",
        "25to34": "10",
        "35to44": "4",
        "45to54": "8",
        "55to64": "9",
        "65+": "16",
        "medianAge": "9"
      }
    },
    "title": "Models, demonstrators, and product promoters",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "60",
        "women": "72.1",
        "white": "79.0",
        "black": "8.8",
        "asian": "7.2",
        "hispanicOrLatino": "13.4"
      }
    },
    "wageData": {
      "2018": {
        "workers": "15",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "Real estate brokers and sales agents": {
    "ageData": {
      "2018": {
        "total": "1,072",
        "16to19": "5",
        "20to24": "51",
        "25to34": "162",
        "35to44": "220",
        "45to54": "265",
        "55to64": "221",
        "65+": "149",
        "medianAge": "221"
      }
    },
    "title": "Real estate brokers and sales agents",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "1,072",
        "women": "56.2",
        "white": "86.0",
        "black": "6.4",
        "asian": "6.1",
        "hispanicOrLatino": "10.8"
      }
    },
    "wageData": {
      "2018": {
        "workers": "528",
        "median": "1,019",
        "maleWorkers": "236",
        "maleMedian": "1,264",
        "femaleWorkers": "291",
        "femaleMedian": "883"
      }
    }
  },
  "Sales engineers": {
    "ageData": {
      "2018": {
        "total": "34",
        "16to19": "0",
        "20to24": "2",
        "25to34": "9",
        "35to44": "4",
        "45to54": "10",
        "55to64": "8",
        "65+": "1",
        "medianAge": "8"
      }
    },
    "title": "Sales engineers",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "34",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "38",
        "median": "-",
        "maleWorkers": "34",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Telemarketers": {
    "ageData": {
      "2018": {
        "total": "58",
        "16to19": "2",
        "20to24": "13",
        "25to34": "19",
        "35to44": "13",
        "45to54": "3",
        "55to64": "6",
        "65+": "2",
        "medianAge": "6"
      }
    },
    "title": "Telemarketers",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "58",
        "women": "65.3",
        "white": "69.3",
        "black": "23.3",
        "asian": "3.7",
        "hispanicOrLatino": "22.9"
      }
    },
    "wageData": {
      "2018": {
        "workers": "50",
        "median": "559",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "31",
        "femaleMedian": "-"
      }
    }
  },
  "Door-to-door sales workers, news and street vendors, and related workers": {
    "ageData": {
      "2018": {
        "total": "158",
        "16to19": "1",
        "20to24": "17",
        "25to34": "22",
        "35to44": "32",
        "45to54": "35",
        "55to64": "29",
        "65+": "22",
        "medianAge": "29"
      }
    },
    "title": "Door-to-door sales workers, news and street vendors, and related workers",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "158",
        "women": "60.9",
        "white": "86.1",
        "black": "10.6",
        "asian": "1.3",
        "hispanicOrLatino": "22.8"
      }
    },
    "wageData": {
      "2018": {
        "workers": "41",
        "median": "-",
        "maleWorkers": "25",
        "maleMedian": "-",
        "femaleWorkers": "17",
        "femaleMedian": "-"
      }
    }
  },
  "Sales and related workers, all other": {
    "ageData": {
      "2018": {
        "total": "275",
        "16to19": "4",
        "20to24": "27",
        "25to34": "68",
        "35to44": "58",
        "45to54": "55",
        "55to64": "41",
        "65+": "24",
        "medianAge": "41"
      }
    },
    "title": "Sales and related workers, all other",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "275",
        "women": "48.4",
        "white": "85.0",
        "black": "8.7",
        "asian": "4.5",
        "hispanicOrLatino": "7.2"
      }
    },
    "wageData": {
      "2018": {
        "workers": "227",
        "median": "1,036",
        "maleWorkers": "118",
        "maleMedian": "1,323",
        "femaleWorkers": "109",
        "femaleMedian": "873"
      }
    }
  },
  "Office and administrative support occupations": {
    "ageData": {
      "2018": {
        "total": "17,655",
        "16to19": "572",
        "20to24": "1,995",
        "25to34": "3,896",
        "35to44": "3,272",
        "45to54": "3,530",
        "55to64": "3,240",
        "65+": "1,150",
        "medianAge": "3,240"
      }
    },
    "title": "Office and administrative support occupations",
    "depth": 2,
    "parent": "Sales and office occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "17,655",
        "women": "71.6",
        "white": "77.1",
        "black": "14.4",
        "asian": "4.6",
        "hispanicOrLatino": "16.9"
      }
    },
    "wageData": {
      "2018": {
        "workers": "13,637",
        "median": "717",
        "maleWorkers": "3,926",
        "maleMedian": "738",
        "femaleWorkers": "9,711",
        "femaleMedian": "711"
      }
    }
  },
  "First-line supervisors of office and administrative support workers": {
    "ageData": {
      "2018": {
        "total": "1,450",
        "16to19": "3",
        "20to24": "66",
        "25to34": "290",
        "35to44": "335",
        "45to54": "386",
        "55to64": "288",
        "65+": "82",
        "medianAge": "288"
      }
    },
    "title": "First-line supervisors of office and administrative support workers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "1,450",
        "women": "71.7",
        "white": "81.6",
        "black": "11.2",
        "asian": "3.5",
        "hispanicOrLatino": "13.5"
      }
    },
    "wageData": {
      "2018": {
        "workers": "1,279",
        "median": "885",
        "maleWorkers": "386",
        "maleMedian": "1,050",
        "femaleWorkers": "892",
        "femaleMedian": "835"
      }
    }
  },
  "Switchboard operators, including answering service": {
    "ageData": {
      "2018": {
        "total": "23",
        "16to19": "0",
        "20to24": "3",
        "25to34": "2",
        "35to44": "4",
        "45to54": "5",
        "55to64": "5",
        "65+": "3",
        "medianAge": "5"
      }
    },
    "title": "Switchboard operators, including answering service",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "23",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "21",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "19",
        "femaleMedian": "-"
      }
    }
  },
  "Telephone operators": {
    "ageData": {
      "2018": {
        "total": "30",
        "16to19": "0",
        "20to24": "4",
        "25to34": "10",
        "35to44": "5",
        "45to54": "3",
        "55to64": "4",
        "65+": "3",
        "medianAge": "4"
      }
    },
    "title": "Telephone operators",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "30",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "29",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "22",
        "femaleMedian": "-"
      }
    }
  },
  "Communications equipment operators, all other": {
    "ageData": {
      "2018": {
        "total": "9",
        "16to19": "0",
        "20to24": "1",
        "25to34": "2",
        "35to44": "2",
        "45to54": "2",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Communications equipment operators, all other",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "9",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Bill and account collectors": {
    "ageData": {
      "2018": {
        "total": "122",
        "16to19": "2",
        "20to24": "16",
        "25to34": "31",
        "35to44": "36",
        "45to54": "19",
        "55to64": "12",
        "65+": "5",
        "medianAge": "12"
      }
    },
    "title": "Bill and account collectors",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "122",
        "women": "71.1",
        "white": "69.4",
        "black": "24.0",
        "asian": "1.4",
        "hispanicOrLatino": "21.3"
      }
    },
    "wageData": {
      "2018": {
        "workers": "114",
        "median": "711",
        "maleWorkers": "28",
        "maleMedian": "-",
        "femaleWorkers": "85",
        "femaleMedian": "694"
      }
    }
  },
  "Billing and posting clerks": {
    "ageData": {
      "2018": {
        "total": "490",
        "16to19": "2",
        "20to24": "37",
        "25to34": "106",
        "35to44": "112",
        "45to54": "115",
        "55to64": "93",
        "65+": "25",
        "medianAge": "93"
      }
    },
    "title": "Billing and posting clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "490",
        "women": "87.1",
        "white": "77.0",
        "black": "13.8",
        "asian": "5.2",
        "hispanicOrLatino": "18.0"
      }
    },
    "wageData": {
      "2018": {
        "workers": "417",
        "median": "721",
        "maleWorkers": "56",
        "maleMedian": "664",
        "femaleWorkers": "361",
        "femaleMedian": "725"
      }
    }
  },
  "Bookkeeping, accounting, and auditing clerks": {
    "ageData": {
      "2018": {
        "total": "1,045",
        "16to19": "5",
        "20to24": "44",
        "25to34": "139",
        "35to44": "192",
        "45to54": "247",
        "55to64": "281",
        "65+": "137",
        "medianAge": "281"
      }
    },
    "title": "Bookkeeping, accounting, and auditing clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "1,045",
        "women": "86.7",
        "white": "87.5",
        "black": "7.0",
        "asian": "3.7",
        "hispanicOrLatino": "10.2"
      }
    },
    "wageData": {
      "2018": {
        "workers": "679",
        "median": "742",
        "maleWorkers": "104",
        "maleMedian": "778",
        "femaleWorkers": "575",
        "femaleMedian": "739"
      }
    }
  },
  "Gaming cage workers": {
    "ageData": {
      "2018": {
        "total": "8",
        "16to19": "0",
        "20to24": "1",
        "25to34": "4",
        "35to44": "2",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Gaming cage workers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "8",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Payroll and timekeeping clerks": {
    "ageData": {
      "2018": {
        "total": "121",
        "16to19": "0",
        "20to24": "4",
        "25to34": "24",
        "35to44": "27",
        "45to54": "27",
        "55to64": "31",
        "65+": "7",
        "medianAge": "31"
      }
    },
    "title": "Payroll and timekeeping clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "121",
        "women": "89.8",
        "white": "78.2",
        "black": "13.3",
        "asian": "6.6",
        "hispanicOrLatino": "14.9"
      }
    },
    "wageData": {
      "2018": {
        "workers": "111",
        "median": "827",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "100",
        "femaleMedian": "807"
      }
    }
  },
  "Procurement clerks": {
    "ageData": {
      "2018": {
        "total": "31",
        "16to19": "0",
        "20to24": "2",
        "25to34": "8",
        "35to44": "6",
        "45to54": "8",
        "55to64": "6",
        "65+": "0",
        "medianAge": "6"
      }
    },
    "title": "Procurement clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "31",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "30",
        "median": "-",
        "maleWorkers": "16",
        "maleMedian": "-",
        "femaleWorkers": "14",
        "femaleMedian": "-"
      }
    }
  },
  "Tellers": {
    "ageData": {
      "2018": {
        "total": "300",
        "16to19": "10",
        "20to24": "74",
        "25to34": "98",
        "35to44": "36",
        "45to54": "32",
        "55to64": "38",
        "65+": "12",
        "medianAge": "38"
      }
    },
    "title": "Tellers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "300",
        "women": "85.4",
        "white": "77.2",
        "black": "12.7",
        "asian": "6.1",
        "hispanicOrLatino": "17.8"
      }
    },
    "wageData": {
      "2018": {
        "workers": "240",
        "median": "584",
        "maleWorkers": "34",
        "maleMedian": "-",
        "femaleWorkers": "206",
        "femaleMedian": "584"
      }
    }
  },
  "Financial clerks, all other": {
    "ageData": {
      "2018": {
        "total": "92",
        "16to19": "0",
        "20to24": "16",
        "25to34": "30",
        "35to44": "13",
        "45to54": "14",
        "55to64": "17",
        "65+": "2",
        "medianAge": "17"
      }
    },
    "title": "Financial clerks, all other",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "92",
        "women": "61.9",
        "white": "76.7",
        "black": "9.2",
        "asian": "7.7",
        "hispanicOrLatino": "23.2"
      }
    },
    "wageData": {
      "2018": {
        "workers": "77",
        "median": "839",
        "maleWorkers": "30",
        "maleMedian": "-",
        "femaleWorkers": "48",
        "femaleMedian": "-"
      }
    }
  },
  "Brokerage clerks": {
    "ageData": {
      "2018": {
        "total": "4",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "3",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Brokerage clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Correspondence clerks": {
    "ageData": {
      "2018": {
        "total": "4",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "0",
        "45to54": "1",
        "55to64": "1",
        "65+": "1",
        "medianAge": "1"
      }
    },
    "title": "Correspondence clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Court, municipal, and license clerks": {
    "ageData": {
      "2018": {
        "total": "83",
        "16to19": "0",
        "20to24": "2",
        "25to34": "12",
        "35to44": "21",
        "45to54": "19",
        "55to64": "23",
        "65+": "6",
        "medianAge": "23"
      }
    },
    "title": "Court, municipal, and license clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "83",
        "women": "76.5",
        "white": "79.1",
        "black": "12.6",
        "asian": "2.9",
        "hispanicOrLatino": "16.1"
      }
    },
    "wageData": {
      "2018": {
        "workers": "78",
        "median": "897",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "61",
        "femaleMedian": "804"
      }
    }
  },
  "Credit authorizers, checkers, and clerks": {
    "ageData": {
      "2018": {
        "total": "54",
        "16to19": "0",
        "20to24": "4",
        "25to34": "14",
        "35to44": "12",
        "45to54": "14",
        "55to64": "8",
        "65+": "1",
        "medianAge": "8"
      }
    },
    "title": "Credit authorizers, checkers, and clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "54",
        "women": "64.5",
        "white": "67.2",
        "black": "28.9",
        "asian": "2.7",
        "hispanicOrLatino": "12.3"
      }
    },
    "wageData": {
      "2018": {
        "workers": "50",
        "median": "818",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "30",
        "femaleMedian": "-"
      }
    }
  },
  "Customer service representatives": {
    "ageData": {
      "2018": {
        "total": "2,563",
        "16to19": "177",
        "20to24": "454",
        "25to34": "678",
        "35to44": "404",
        "45to54": "405",
        "55to64": "344",
        "65+": "102",
        "medianAge": "344"
      }
    },
    "title": "Customer service representatives",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "2,563",
        "women": "63.7",
        "white": "72.8",
        "black": "17.8",
        "asian": "4.7",
        "hispanicOrLatino": "19.1"
      }
    },
    "wageData": {
      "2018": {
        "workers": "1,904",
        "median": "683",
        "maleWorkers": "678",
        "maleMedian": "689",
        "femaleWorkers": "1,226",
        "femaleMedian": "680"
      }
    }
  },
  "Eligibility interviewers, government programs": {
    "ageData": {
      "2018": {
        "total": "68",
        "16to19": "1",
        "20to24": "2",
        "25to34": "18",
        "35to44": "16",
        "45to54": "13",
        "55to64": "12",
        "65+": "6",
        "medianAge": "12"
      }
    },
    "title": "Eligibility interviewers, government programs",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "68",
        "women": "80.0",
        "white": "65.9",
        "black": "25.2",
        "asian": "3.1",
        "hispanicOrLatino": "20.5"
      }
    },
    "wageData": {
      "2018": {
        "workers": "67",
        "median": "887",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "53",
        "femaleMedian": "841"
      }
    }
  },
  "File clerks": {
    "ageData": {
      "2018": {
        "total": "161",
        "16to19": "10",
        "20to24": "18",
        "25to34": "29",
        "35to44": "33",
        "45to54": "26",
        "55to64": "32",
        "65+": "14",
        "medianAge": "32"
      }
    },
    "title": "File clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "161",
        "women": "76.8",
        "white": "78.3",
        "black": "15.1",
        "asian": "5.5",
        "hispanicOrLatino": "15.5"
      }
    },
    "wageData": {
      "2018": {
        "workers": "110",
        "median": "650",
        "maleWorkers": "23",
        "maleMedian": "-",
        "femaleWorkers": "86",
        "femaleMedian": "624"
      }
    }
  },
  "Hotel, motel, and resort desk clerks": {
    "ageData": {
      "2018": {
        "total": "131",
        "16to19": "6",
        "20to24": "37",
        "25to34": "43",
        "35to44": "15",
        "45to54": "16",
        "55to64": "9",
        "65+": "5",
        "medianAge": "9"
      }
    },
    "title": "Hotel, motel, and resort desk clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "131",
        "women": "68.6",
        "white": "71.2",
        "black": "13.7",
        "asian": "5.9",
        "hispanicOrLatino": "21.8"
      }
    },
    "wageData": {
      "2018": {
        "workers": "85",
        "median": "520",
        "maleWorkers": "29",
        "maleMedian": "-",
        "femaleWorkers": "56",
        "femaleMedian": "505"
      }
    }
  },
  "Interviewers, except eligibility and loan": {
    "ageData": {
      "2018": {
        "total": "152",
        "16to19": "4",
        "20to24": "16",
        "25to34": "32",
        "35to44": "25",
        "45to54": "35",
        "55to64": "26",
        "65+": "15",
        "medianAge": "26"
      }
    },
    "title": "Interviewers, except eligibility and loan",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "152",
        "women": "81.6",
        "white": "70.0",
        "black": "21.7",
        "asian": "3.1",
        "hispanicOrLatino": "16.4"
      }
    },
    "wageData": {
      "2018": {
        "workers": "114",
        "median": "702",
        "maleWorkers": "18",
        "maleMedian": "-",
        "femaleWorkers": "96",
        "femaleMedian": "686"
      }
    }
  },
  "Library assistants, clerical": {
    "ageData": {
      "2018": {
        "total": "103",
        "16to19": "4",
        "20to24": "19",
        "25to34": "21",
        "35to44": "14",
        "45to54": "10",
        "55to64": "24",
        "65+": "9",
        "medianAge": "24"
      }
    },
    "title": "Library assistants, clerical",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "103",
        "women": "78.0",
        "white": "84.9",
        "black": "9.5",
        "asian": "3.8",
        "hispanicOrLatino": "8.3"
      }
    },
    "wageData": {
      "2018": {
        "workers": "34",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "26",
        "femaleMedian": "-"
      }
    }
  },
  "Loan interviewers and clerks": {
    "ageData": {
      "2018": {
        "total": "117",
        "16to19": "1",
        "20to24": "7",
        "25to34": "35",
        "35to44": "25",
        "45to54": "21",
        "55to64": "20",
        "65+": "9",
        "medianAge": "20"
      }
    },
    "title": "Loan interviewers and clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "117",
        "women": "80.8",
        "white": "74.5",
        "black": "13.9",
        "asian": "9.5",
        "hispanicOrLatino": "11.7"
      }
    },
    "wageData": {
      "2018": {
        "workers": "115",
        "median": "827",
        "maleWorkers": "16",
        "maleMedian": "-",
        "femaleWorkers": "99",
        "femaleMedian": "797"
      }
    }
  },
  "New accounts clerks": {
    "ageData": {
      "2018": {
        "total": "23",
        "16to19": "3",
        "20to24": "2",
        "25to34": "4",
        "35to44": "5",
        "45to54": "3",
        "55to64": "5",
        "65+": "1",
        "medianAge": "5"
      }
    },
    "title": "New accounts clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "23",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "21",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "13",
        "femaleMedian": "-"
      }
    }
  },
  "Order clerks": {
    "ageData": {
      "2018": {
        "total": "78",
        "16to19": "5",
        "20to24": "11",
        "25to34": "23",
        "35to44": "13",
        "45to54": "12",
        "55to64": "9",
        "65+": "5",
        "medianAge": "9"
      }
    },
    "title": "Order clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "78",
        "women": "49.1",
        "white": "70.3",
        "black": "13.2",
        "asian": "8.2",
        "hispanicOrLatino": "17.7"
      }
    },
    "wageData": {
      "2018": {
        "workers": "57",
        "median": "659",
        "maleWorkers": "28",
        "maleMedian": "-",
        "femaleWorkers": "29",
        "femaleMedian": "-"
      }
    }
  },
  "Human resources assistants, except payroll and timekeeping": {
    "ageData": {
      "2018": {
        "total": "57",
        "16to19": "0",
        "20to24": "5",
        "25to34": "12",
        "35to44": "14",
        "45to54": "17",
        "55to64": "7",
        "65+": "3",
        "medianAge": "7"
      }
    },
    "title": "Human resources assistants, except payroll and timekeeping",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "57",
        "women": "83.3",
        "white": "68.8",
        "black": "20.1",
        "asian": "6.0",
        "hispanicOrLatino": "12.3"
      }
    },
    "wageData": {
      "2018": {
        "workers": "53",
        "median": "816",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "45",
        "femaleMedian": "-"
      }
    }
  },
  "Receptionists and information clerks": {
    "ageData": {
      "2018": {
        "total": "1,324",
        "16to19": "66",
        "20to24": "239",
        "25to34": "329",
        "35to44": "197",
        "45to54": "203",
        "55to64": "196",
        "65+": "93",
        "medianAge": "196"
      }
    },
    "title": "Receptionists and information clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "1,324",
        "women": "90.5",
        "white": "79.5",
        "black": "13.2",
        "asian": "3.2",
        "hispanicOrLatino": "20.4"
      }
    },
    "wageData": {
      "2018": {
        "workers": "934",
        "median": "605",
        "maleWorkers": "81",
        "maleMedian": "593",
        "femaleWorkers": "853",
        "femaleMedian": "606"
      }
    }
  },
  "Reservation and transportation ticket agents and travel clerks": {
    "ageData": {
      "2018": {
        "total": "128",
        "16to19": "2",
        "20to24": "13",
        "25to34": "30",
        "35to44": "29",
        "45to54": "25",
        "55to64": "24",
        "65+": "4",
        "medianAge": "24"
      }
    },
    "title": "Reservation and transportation ticket agents and travel clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "128",
        "women": "59.4",
        "white": "67.0",
        "black": "17.3",
        "asian": "5.3",
        "hispanicOrLatino": "21.9"
      }
    },
    "wageData": {
      "2018": {
        "workers": "109",
        "median": "745",
        "maleWorkers": "50",
        "maleMedian": "681",
        "femaleWorkers": "60",
        "femaleMedian": "766"
      }
    }
  },
  "Information and record clerks, all other": {
    "ageData": {
      "2018": {
        "total": "121",
        "16to19": "5",
        "20to24": "9",
        "25to34": "21",
        "35to44": "28",
        "45to54": "23",
        "55to64": "29",
        "65+": "8",
        "medianAge": "29"
      }
    },
    "title": "Information and record clerks, all other",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "121",
        "women": "77.6",
        "white": "79.7",
        "black": "11.7",
        "asian": "6.8",
        "hispanicOrLatino": "15.2"
      }
    },
    "wageData": {
      "2018": {
        "workers": "95",
        "median": "733",
        "maleWorkers": "24",
        "maleMedian": "-",
        "femaleWorkers": "71",
        "femaleMedian": "724"
      }
    }
  },
  "Cargo and freight agents": {
    "ageData": {
      "2018": {
        "total": "30",
        "16to19": "0",
        "20to24": "1",
        "25to34": "8",
        "35to44": "4",
        "45to54": "6",
        "55to64": "10",
        "65+": "1",
        "medianAge": "10"
      }
    },
    "title": "Cargo and freight agents",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "30",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "18",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Couriers and messengers": {
    "ageData": {
      "2018": {
        "total": "293",
        "16to19": "7",
        "20to24": "33",
        "25to34": "74",
        "35to44": "53",
        "45to54": "58",
        "55to64": "50",
        "65+": "17",
        "medianAge": "50"
      }
    },
    "title": "Couriers and messengers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "293",
        "women": "21.1",
        "white": "70.8",
        "black": "21.1",
        "asian": "4.3",
        "hispanicOrLatino": "20.3"
      }
    },
    "wageData": {
      "2018": {
        "workers": "192",
        "median": "786",
        "maleWorkers": "164",
        "maleMedian": "821",
        "femaleWorkers": "29",
        "femaleMedian": "-"
      }
    }
  },
  "Dispatchers": {
    "ageData": {
      "2018": {
        "total": "293",
        "16to19": "4",
        "20to24": "23",
        "25to34": "76",
        "35to44": "70",
        "45to54": "62",
        "55to64": "48",
        "65+": "9",
        "medianAge": "48"
      }
    },
    "title": "Dispatchers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "293",
        "women": "60.7",
        "white": "79.3",
        "black": "15.2",
        "asian": "2.8",
        "hispanicOrLatino": "13.3"
      }
    },
    "wageData": {
      "2018": {
        "workers": "270",
        "median": "783",
        "maleWorkers": "108",
        "maleMedian": "926",
        "femaleWorkers": "162",
        "femaleMedian": "725"
      }
    }
  },
  "Meter readers, utilities": {
    "ageData": {
      "2018": {
        "total": "24",
        "16to19": "0",
        "20to24": "2",
        "25to34": "8",
        "35to44": "7",
        "45to54": "1",
        "55to64": "2",
        "65+": "3",
        "medianAge": "2"
      }
    },
    "title": "Meter readers, utilities",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "24",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "24",
        "median": "-",
        "maleWorkers": "22",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Postal service clerks": {
    "ageData": {
      "2018": {
        "total": "120",
        "16to19": "0",
        "20to24": "6",
        "25to34": "18",
        "35to44": "24",
        "45to54": "37",
        "55to64": "30",
        "65+": "6",
        "medianAge": "30"
      }
    },
    "title": "Postal service clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "120",
        "women": "54.9",
        "white": "46.5",
        "black": "40.9",
        "asian": "8.3",
        "hispanicOrLatino": "8.6"
      }
    },
    "wageData": {
      "2018": {
        "workers": "115",
        "median": "765",
        "maleWorkers": "51",
        "maleMedian": "758",
        "femaleWorkers": "63",
        "femaleMedian": "771"
      }
    }
  },
  "Postal service mail carriers": {
    "ageData": {
      "2018": {
        "total": "302",
        "16to19": "0",
        "20to24": "10",
        "25to34": "63",
        "35to44": "64",
        "45to54": "75",
        "55to64": "73",
        "65+": "16",
        "medianAge": "73"
      }
    },
    "title": "Postal service mail carriers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "302",
        "women": "39.8",
        "white": "64.8",
        "black": "23.6",
        "asian": "6.9",
        "hispanicOrLatino": "13.3"
      }
    },
    "wageData": {
      "2018": {
        "workers": "288",
        "median": "931",
        "maleWorkers": "170",
        "maleMedian": "983",
        "femaleWorkers": "118",
        "femaleMedian": "808"
      }
    }
  },
  "Postal service mail sorters, processors, and processing machine operators": {
    "ageData": {
      "2018": {
        "total": "58",
        "16to19": "0",
        "20to24": "1",
        "25to34": "10",
        "35to44": "12",
        "45to54": "14",
        "55to64": "18",
        "65+": "3",
        "medianAge": "18"
      }
    },
    "title": "Postal service mail sorters, processors, and processing machine operators",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "58",
        "women": "56.6",
        "white": "50.5",
        "black": "41.1",
        "asian": "4.4",
        "hispanicOrLatino": "11.7"
      }
    },
    "wageData": {
      "2018": {
        "workers": "53",
        "median": "980",
        "maleWorkers": "22",
        "maleMedian": "-",
        "femaleWorkers": "32",
        "femaleMedian": "-"
      }
    }
  },
  "Production, planning, and expediting clerks": {
    "ageData": {
      "2018": {
        "total": "269",
        "16to19": "0",
        "20to24": "12",
        "25to34": "70",
        "35to44": "61",
        "45to54": "65",
        "55to64": "47",
        "65+": "14",
        "medianAge": "47"
      }
    },
    "title": "Production, planning, and expediting clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "269",
        "women": "54.0",
        "white": "80.0",
        "black": "9.4",
        "asian": "7.3",
        "hispanicOrLatino": "12.5"
      }
    },
    "wageData": {
      "2018": {
        "workers": "252",
        "median": "870",
        "maleWorkers": "119",
        "maleMedian": "1,004",
        "femaleWorkers": "134",
        "femaleMedian": "790"
      }
    }
  },
  "Shipping, receiving, and traffic clerks": {
    "ageData": {
      "2018": {
        "total": "578",
        "16to19": "13",
        "20to24": "70",
        "25to34": "134",
        "35to44": "131",
        "45to54": "108",
        "55to64": "102",
        "65+": "20",
        "medianAge": "102"
      }
    },
    "title": "Shipping, receiving, and traffic clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "578",
        "women": "29.1",
        "white": "76.2",
        "black": "15.2",
        "asian": "3.1",
        "hispanicOrLatino": "23.6"
      }
    },
    "wageData": {
      "2018": {
        "workers": "524",
        "median": "632",
        "maleWorkers": "365",
        "maleMedian": "654",
        "femaleWorkers": "160",
        "femaleMedian": "590"
      }
    }
  },
  "Stock clerks and order fillers": {
    "ageData": {
      "2018": {
        "total": "1,527",
        "16to19": "154",
        "20to24": "331",
        "25to34": "379",
        "35to44": "224",
        "45to54": "201",
        "55to64": "173",
        "65+": "66",
        "medianAge": "173"
      }
    },
    "title": "Stock clerks and order fillers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "1,527",
        "women": "37.4",
        "white": "70.2",
        "black": "19.7",
        "asian": "4.5",
        "hispanicOrLatino": "22.9"
      }
    },
    "wageData": {
      "2018": {
        "workers": "988",
        "median": "562",
        "maleWorkers": "629",
        "maleMedian": "558",
        "femaleWorkers": "359",
        "femaleMedian": "570"
      }
    }
  },
  "Weighers, measurers, checkers, and samplers, recordkeeping": {
    "ageData": {
      "2018": {
        "total": "56",
        "16to19": "0",
        "20to24": "9",
        "25to34": "13",
        "35to44": "9",
        "45to54": "10",
        "55to64": "9",
        "65+": "6",
        "medianAge": "9"
      }
    },
    "title": "Weighers, measurers, checkers, and samplers, recordkeeping",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "56",
        "women": "38.9",
        "white": "86.1",
        "black": "9.5",
        "asian": "4.0",
        "hispanicOrLatino": "18.1"
      }
    },
    "wageData": {
      "2018": {
        "workers": "44",
        "median": "-",
        "maleWorkers": "30",
        "maleMedian": "-",
        "femaleWorkers": "14",
        "femaleMedian": "-"
      }
    }
  },
  "Secretaries and administrative assistants": {
    "ageData": {
      "2018": {
        "total": "2,569",
        "16to19": "22",
        "20to24": "138",
        "25to34": "427",
        "35to44": "474",
        "45to54": "646",
        "55to64": "633",
        "65+": "228",
        "medianAge": "633"
      }
    },
    "title": "Secretaries and administrative assistants",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "2,569",
        "women": "94.0",
        "white": "84.8",
        "black": "8.9",
        "asian": "3.3",
        "hispanicOrLatino": "13.5"
      }
    },
    "wageData": {
      "2018": {
        "workers": "1,977",
        "median": "759",
        "maleWorkers": "122",
        "maleMedian": "903",
        "femaleWorkers": "1,855",
        "femaleMedian": "753"
      }
    }
  },
  "Computer operators": {
    "ageData": {
      "2018": {
        "total": "73",
        "16to19": "1",
        "20to24": "7",
        "25to34": "17",
        "35to44": "15",
        "45to54": "9",
        "55to64": "14",
        "65+": "9",
        "medianAge": "14"
      }
    },
    "title": "Computer operators",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "73",
        "women": "63.8",
        "white": "72.4",
        "black": "16.8",
        "asian": "9.5",
        "hispanicOrLatino": "10.7"
      }
    },
    "wageData": {
      "2018": {
        "workers": "67",
        "median": "763",
        "maleWorkers": "27",
        "maleMedian": "-",
        "femaleWorkers": "40",
        "femaleMedian": "-"
      }
    }
  },
  "Data entry keyers": {
    "ageData": {
      "2018": {
        "total": "270",
        "16to19": "2",
        "20to24": "25",
        "25to34": "65",
        "35to44": "57",
        "45to54": "61",
        "55to64": "47",
        "65+": "13",
        "medianAge": "47"
      }
    },
    "title": "Data entry keyers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "270",
        "women": "77.2",
        "white": "71.7",
        "black": "17.0",
        "asian": "9.1",
        "hispanicOrLatino": "18.1"
      }
    },
    "wageData": {
      "2018": {
        "workers": "224",
        "median": "655",
        "maleWorkers": "52",
        "maleMedian": "730",
        "femaleWorkers": "173",
        "femaleMedian": "643"
      }
    }
  },
  "Word processors and typists": {
    "ageData": {
      "2018": {
        "total": "67",
        "16to19": "3",
        "20to24": "7",
        "25to34": "12",
        "35to44": "11",
        "45to54": "11",
        "55to64": "17",
        "65+": "8",
        "medianAge": "17"
      }
    },
    "title": "Word processors and typists",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "67",
        "women": "85.1",
        "white": "62.9",
        "black": "22.2",
        "asian": "8.0",
        "hispanicOrLatino": "15.9"
      }
    },
    "wageData": {
      "2018": {
        "workers": "53",
        "median": "858",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "43",
        "femaleMedian": "-"
      }
    }
  },
  "Desktop publishers": {
    "ageData": {
      "2018": {
        "total": "1",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Desktop publishers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Insurance claims and policy processing clerks": {
    "ageData": {
      "2018": {
        "total": "244",
        "16to19": "1",
        "20to24": "20",
        "25to34": "66",
        "35to44": "52",
        "45to54": "51",
        "55to64": "43",
        "65+": "11",
        "medianAge": "43"
      }
    },
    "title": "Insurance claims and policy processing clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "244",
        "women": "82.5",
        "white": "80.7",
        "black": "14.9",
        "asian": "2.6",
        "hispanicOrLatino": "14.8"
      }
    },
    "wageData": {
      "2018": {
        "workers": "211",
        "median": "719",
        "maleWorkers": "36",
        "maleMedian": "-",
        "femaleWorkers": "175",
        "femaleMedian": "713"
      }
    }
  },
  "Mail clerks and mail machine operators, except postal service": {
    "ageData": {
      "2018": {
        "total": "63",
        "16to19": "2",
        "20to24": "5",
        "25to34": "13",
        "35to44": "10",
        "45to54": "17",
        "55to64": "11",
        "65+": "6",
        "medianAge": "11"
      }
    },
    "title": "Mail clerks and mail machine operators, except postal service",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "63",
        "women": "41.5",
        "white": "70.5",
        "black": "20.6",
        "asian": "2.5",
        "hispanicOrLatino": "20.5"
      }
    },
    "wageData": {
      "2018": {
        "workers": "44",
        "median": "-",
        "maleWorkers": "31",
        "maleMedian": "-",
        "femaleWorkers": "13",
        "femaleMedian": "-"
      }
    }
  },
  "Office clerks, general": {
    "ageData": {
      "2018": {
        "total": "1,309",
        "16to19": "48",
        "20to24": "150",
        "25to34": "255",
        "35to44": "235",
        "45to54": "256",
        "55to64": "252",
        "65+": "114",
        "medianAge": "252"
      }
    },
    "title": "Office clerks, general",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "1,309",
        "women": "84.5",
        "white": "75.7",
        "black": "14.2",
        "asian": "7.0",
        "hispanicOrLatino": "19.1"
      }
    },
    "wageData": {
      "2018": {
        "workers": "925",
        "median": "697",
        "maleWorkers": "128",
        "maleMedian": "670",
        "femaleWorkers": "797",
        "femaleMedian": "701"
      }
    }
  },
  "Office machine operators, except computer": {
    "ageData": {
      "2018": {
        "total": "24",
        "16to19": "2",
        "20to24": "2",
        "25to34": "4",
        "35to44": "5",
        "45to54": "5",
        "55to64": "3",
        "65+": "3",
        "medianAge": "3"
      }
    },
    "title": "Office machine operators, except computer",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "24",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "9",
        "femaleMedian": "-"
      }
    }
  },
  "Proofreaders and copy markers": {
    "ageData": {
      "2018": {
        "total": "11",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "1",
        "45to54": "3",
        "55to64": "4",
        "65+": "2",
        "medianAge": "4"
      }
    },
    "title": "Proofreaders and copy markers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "11",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Statistical assistants": {
    "ageData": {
      "2018": {
        "total": "40",
        "16to19": "0",
        "20to24": "3",
        "25to34": "12",
        "35to44": "7",
        "45to54": "5",
        "55to64": "12",
        "65+": "1",
        "medianAge": "12"
      }
    },
    "title": "Statistical assistants",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "40",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "41",
        "median": "-",
        "maleWorkers": "16",
        "maleMedian": "-",
        "femaleWorkers": "25",
        "femaleMedian": "-"
      }
    }
  },
  "Office and administrative support workers, all other": {
    "ageData": {
      "2018": {
        "total": "540",
        "16to19": "4",
        "20to24": "34",
        "25to34": "123",
        "35to44": "124",
        "45to54": "127",
        "55to64": "92",
        "65+": "35",
        "medianAge": "92"
      }
    },
    "title": "Office and administrative support workers, all other",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "540",
        "women": "78.5",
        "white": "78.1",
        "black": "14.0",
        "asian": "5.0",
        "hispanicOrLatino": "14.1"
      }
    },
    "wageData": {
      "2018": {
        "workers": "456",
        "median": "794",
        "maleWorkers": "104",
        "maleMedian": "920",
        "femaleWorkers": "352",
        "femaleMedian": "762"
      }
    }
  },
  "Natural resources, construction, and maintenance occupations": {
    "ageData": {
      "2018": {
        "total": "14,471",
        "16to19": "356",
        "20to24": "1,241",
        "25to34": "3,383",
        "35to44": "3,434",
        "45to54": "3,041",
        "55to64": "2,433",
        "65+": "584",
        "medianAge": "2,433"
      }
    },
    "title": "Natural resources, construction, and maintenance occupations",
    "depth": 1,
    "parent": "",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "14,471",
        "women": "5.1",
        "white": "86.2",
        "black": "7.6",
        "asian": "2.2",
        "hispanicOrLatino": "31.9"
      }
    },
    "wageData": {
      "2018": {
        "workers": "11,546",
        "median": "824",
        "maleWorkers": "11,030",
        "maleMedian": "834",
        "femaleWorkers": "516",
        "femaleMedian": "638"
      }
    }
  },
  "Farming, fishing, and forestry occupations": {
    "ageData": {
      "2018": {
        "total": "1,121",
        "16to19": "89",
        "20to24": "136",
        "25to34": "235",
        "35to44": "226",
        "45to54": "207",
        "55to64": "169",
        "65+": "58",
        "medianAge": "169"
      }
    },
    "title": "Farming, fishing, and forestry occupations",
    "depth": 2,
    "parent": "Natural resources, construction, and maintenance occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "1,121",
        "women": "24.3",
        "white": "89.9",
        "black": "3.8",
        "asian": "2.2",
        "hispanicOrLatino": "46.0"
      }
    },
    "wageData": {
      "2018": {
        "workers": "850",
        "median": "581",
        "maleWorkers": "667",
        "maleMedian": "602",
        "femaleWorkers": "183",
        "femaleMedian": "483"
      }
    }
  },
  "First-line supervisors of farming, fishing, and forestry workers": {
    "ageData": {
      "2018": {
        "total": "60",
        "16to19": "0",
        "20to24": "6",
        "25to34": "7",
        "35to44": "14",
        "45to54": "18",
        "55to64": "12",
        "65+": "4",
        "medianAge": "12"
      }
    },
    "title": "First-line supervisors of farming, fishing, and forestry workers",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "60",
        "women": "16.5",
        "white": "96.9",
        "black": "0.4",
        "asian": "0.1",
        "hispanicOrLatino": "33.6"
      }
    },
    "wageData": {
      "2018": {
        "workers": "45",
        "median": "-",
        "maleWorkers": "37",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Agricultural inspectors": {
    "ageData": {
      "2018": {
        "total": "22",
        "16to19": "1",
        "20to24": "1",
        "25to34": "3",
        "35to44": "4",
        "45to54": "4",
        "55to64": "5",
        "65+": "4",
        "medianAge": "5"
      }
    },
    "title": "Agricultural inspectors",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "22",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "18",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Animal breeders": {
    "ageData": {
      "2018": {
        "total": "5",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "1",
        "45to54": "2",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Animal breeders",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Graders and sorters, agricultural products": {
    "ageData": {
      "2018": {
        "total": "85",
        "16to19": "2",
        "20to24": "9",
        "25to34": "21",
        "35to44": "13",
        "45to54": "24",
        "55to64": "13",
        "65+": "3",
        "medianAge": "13"
      }
    },
    "title": "Graders and sorters, agricultural products",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "85",
        "women": "57.8",
        "white": "84.0",
        "black": "7.6",
        "asian": "5.3",
        "hispanicOrLatino": "44.2"
      }
    },
    "wageData": {
      "2018": {
        "workers": "75",
        "median": "593",
        "maleWorkers": "38",
        "maleMedian": "-",
        "femaleWorkers": "37",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous agricultural workers": {
    "ageData": {
      "2018": {
        "total": "833",
        "16to19": "82",
        "20to24": "109",
        "25to34": "173",
        "35to44": "174",
        "45to54": "136",
        "55to64": "119",
        "65+": "39",
        "medianAge": "119"
      }
    },
    "title": "Miscellaneous agricultural workers",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "833",
        "women": "23.4",
        "white": "90.3",
        "black": "3.6",
        "asian": "1.7",
        "hispanicOrLatino": "52.8"
      }
    },
    "wageData": {
      "2018": {
        "workers": "641",
        "median": "552",
        "maleWorkers": "512",
        "maleMedian": "578",
        "femaleWorkers": "130",
        "femaleMedian": "474"
      }
    }
  },
  "Fishers and related fishing workers": {
    "ageData": {
      "2018": {
        "total": "39",
        "16to19": "2",
        "20to24": "5",
        "25to34": "11",
        "35to44": "6",
        "45to54": "10",
        "55to64": "4",
        "65+": "2",
        "medianAge": "4"
      }
    },
    "title": "Fishers and related fishing workers",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "39",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Hunters and trappers": {
    "ageData": {
      "2018": {
        "total": "3",
        "16to19": "1",
        "20to24": "0",
        "25to34": "0",
        "35to44": "1",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Hunters and trappers",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Forest and conservation workers": {
    "ageData": {
      "2018": {
        "total": "16",
        "16to19": "0",
        "20to24": "0",
        "25to34": "7",
        "35to44": "3",
        "45to54": "3",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Forest and conservation workers",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "16",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "17",
        "median": "-",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Logging workers": {
    "ageData": {
      "2018": {
        "total": "58",
        "16to19": "1",
        "20to24": "7",
        "25to34": "12",
        "35to44": "10",
        "45to54": "11",
        "55to64": "12",
        "65+": "6",
        "medianAge": "12"
      }
    },
    "title": "Logging workers",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "58",
        "women": "2.6",
        "white": "94.6",
        "black": "1.0",
        "asian": "3.1",
        "hispanicOrLatino": "8.5"
      }
    },
    "wageData": {
      "2018": {
        "workers": "36",
        "median": "-",
        "maleWorkers": "36",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Construction and extraction occupations": {
    "ageData": {
      "2018": {
        "total": "8,338",
        "16to19": "175",
        "20to24": "696",
        "25to34": "2,025",
        "35to44": "2,100",
        "45to54": "1,728",
        "55to64": "1,304",
        "65+": "312",
        "medianAge": "1,304"
      }
    },
    "title": "Construction and extraction occupations",
    "depth": 2,
    "parent": "Natural resources, construction, and maintenance occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "8,338",
        "women": "3.4",
        "white": "87.5",
        "black": "7.1",
        "asian": "1.6",
        "hispanicOrLatino": "37.0"
      }
    },
    "wageData": {
      "2018": {
        "workers": "6,414",
        "median": "808",
        "maleWorkers": "6,223",
        "maleMedian": "809",
        "femaleWorkers": "191",
        "femaleMedian": "785"
      }
    }
  },
  "First-line supervisors of construction trades and extraction workers": {
    "ageData": {
      "2018": {
        "total": "634",
        "16to19": "0",
        "20to24": "21",
        "25to34": "131",
        "35to44": "169",
        "45to54": "166",
        "55to64": "122",
        "65+": "26",
        "medianAge": "122"
      }
    },
    "title": "First-line supervisors of construction trades and extraction workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "634",
        "women": "3.5",
        "white": "92.2",
        "black": "4.2",
        "asian": "0.8",
        "hispanicOrLatino": "23.0"
      }
    },
    "wageData": {
      "2018": {
        "workers": "541",
        "median": "1,059",
        "maleWorkers": "521",
        "maleMedian": "1,073",
        "femaleWorkers": "20",
        "femaleMedian": "-"
      }
    }
  },
  "Boilermakers": {
    "ageData": {
      "2018": {
        "total": "20",
        "16to19": "1",
        "20to24": "1",
        "25to34": "3",
        "35to44": "8",
        "45to54": "7",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Boilermakers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "20",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "16",
        "median": "-",
        "maleWorkers": "16",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Brickmasons, blockmasons, and stonemasons": {
    "ageData": {
      "2018": {
        "total": "143",
        "16to19": "1",
        "20to24": "8",
        "25to34": "23",
        "35to44": "42",
        "45to54": "33",
        "55to64": "31",
        "65+": "4",
        "medianAge": "31"
      }
    },
    "title": "Brickmasons, blockmasons, and stonemasons",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "143",
        "women": "1.9",
        "white": "87.1",
        "black": "9.3",
        "asian": "1.5",
        "hispanicOrLatino": "35.5"
      }
    },
    "wageData": {
      "2018": {
        "workers": "102",
        "median": "763",
        "maleWorkers": "100",
        "maleMedian": "761",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Carpenters": {
    "ageData": {
      "2018": {
        "total": "1,375",
        "16to19": "27",
        "20to24": "101",
        "25to34": "312",
        "35to44": "379",
        "45to54": "288",
        "55to64": "215",
        "65+": "52",
        "medianAge": "215"
      }
    },
    "title": "Carpenters",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "1,375",
        "women": "2.2",
        "white": "86.1",
        "black": "6.2",
        "asian": "2.3",
        "hispanicOrLatino": "39.1"
      }
    },
    "wageData": {
      "2018": {
        "workers": "973",
        "median": "756",
        "maleWorkers": "953",
        "maleMedian": "759",
        "femaleWorkers": "20",
        "femaleMedian": "-"
      }
    }
  },
  "Carpet, floor, and tile installers and finishers": {
    "ageData": {
      "2018": {
        "total": "164",
        "16to19": "1",
        "20to24": "17",
        "25to34": "35",
        "35to44": "41",
        "45to54": "38",
        "55to64": "28",
        "65+": "4",
        "medianAge": "28"
      }
    },
    "title": "Carpet, floor, and tile installers and finishers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "164",
        "women": "1.9",
        "white": "86.5",
        "black": "8.4",
        "asian": "0.7",
        "hispanicOrLatino": "46.4"
      }
    },
    "wageData": {
      "2018": {
        "workers": "108",
        "median": "692",
        "maleWorkers": "105",
        "maleMedian": "682",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Cement masons, concrete finishers, and terrazzo workers": {
    "ageData": {
      "2018": {
        "total": "50",
        "16to19": "1",
        "20to24": "3",
        "25to34": "14",
        "35to44": "11",
        "45to54": "9",
        "55to64": "10",
        "65+": "2",
        "medianAge": "10"
      }
    },
    "title": "Cement masons, concrete finishers, and terrazzo workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "50",
        "women": "0.0",
        "white": "79.3",
        "black": "12.5",
        "asian": "0.0",
        "hispanicOrLatino": "46.6"
      }
    },
    "wageData": {
      "2018": {
        "workers": "46",
        "median": "-",
        "maleWorkers": "46",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Construction laborers": {
    "ageData": {
      "2018": {
        "total": "2,110",
        "16to19": "76",
        "20to24": "225",
        "25to34": "516",
        "35to44": "532",
        "45to54": "408",
        "55to64": "274",
        "65+": "80",
        "medianAge": "274"
      }
    },
    "title": "Construction laborers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "2,110",
        "women": "3.7",
        "white": "87.4",
        "black": "7.5",
        "asian": "1.6",
        "hispanicOrLatino": "47.6"
      }
    },
    "wageData": {
      "2018": {
        "workers": "1,470",
        "median": "717",
        "maleWorkers": "1,421",
        "maleMedian": "717",
        "femaleWorkers": "49",
        "femaleMedian": "-"
      }
    }
  },
  "Paving, surfacing, and tamping equipment operators": {
    "ageData": {
      "2018": {
        "total": "21",
        "16to19": "0",
        "20to24": "1",
        "25to34": "2",
        "35to44": "5",
        "45to54": "5",
        "55to64": "7",
        "65+": "0",
        "medianAge": "7"
      }
    },
    "title": "Paving, surfacing, and tamping equipment operators",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "21",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "19",
        "median": "-",
        "maleWorkers": "18",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Pile-driver operators": {
    "ageData": {
      "2018": {
        "total": "1",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Pile-driver operators",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Operating engineers and other construction equipment operators": {
    "ageData": {
      "2018": {
        "total": "342",
        "16to19": "2",
        "20to24": "21",
        "25to34": "82",
        "35to44": "79",
        "45to54": "76",
        "55to64": "65",
        "65+": "17",
        "medianAge": "65"
      }
    },
    "title": "Operating engineers and other construction equipment operators",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "342",
        "women": "2.0",
        "white": "89.0",
        "black": "7.5",
        "asian": "0.3",
        "hispanicOrLatino": "17.4"
      }
    },
    "wageData": {
      "2018": {
        "workers": "328",
        "median": "905",
        "maleWorkers": "323",
        "maleMedian": "905",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Drywall installers, ceiling tile installers, and tapers": {
    "ageData": {
      "2018": {
        "total": "164",
        "16to19": "4",
        "20to24": "20",
        "25to34": "41",
        "35to44": "46",
        "45to54": "30",
        "55to64": "19",
        "65+": "4",
        "medianAge": "19"
      }
    },
    "title": "Drywall installers, ceiling tile installers, and tapers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "164",
        "women": "1.6",
        "white": "88.1",
        "black": "4.6",
        "asian": "0.6",
        "hispanicOrLatino": "70.2"
      }
    },
    "wageData": {
      "2018": {
        "workers": "125",
        "median": "704",
        "maleWorkers": "124",
        "maleMedian": "707",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Electricians": {
    "ageData": {
      "2018": {
        "total": "887",
        "16to19": "16",
        "20to24": "79",
        "25to34": "210",
        "35to44": "204",
        "45to54": "190",
        "55to64": "158",
        "65+": "31",
        "medianAge": "158"
      }
    },
    "title": "Electricians",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "887",
        "women": "2.4",
        "white": "86.9",
        "black": "8.2",
        "asian": "1.8",
        "hispanicOrLatino": "23.3"
      }
    },
    "wageData": {
      "2018": {
        "workers": "781",
        "median": "965",
        "maleWorkers": "766",
        "maleMedian": "966",
        "femaleWorkers": "16",
        "femaleMedian": "-"
      }
    }
  },
  "Glaziers": {
    "ageData": {
      "2018": {
        "total": "45",
        "16to19": "0",
        "20to24": "3",
        "25to34": "16",
        "35to44": "10",
        "45to54": "9",
        "55to64": "6",
        "65+": "1",
        "medianAge": "6"
      }
    },
    "title": "Glaziers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "45",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "35",
        "median": "-",
        "maleWorkers": "35",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Insulation workers": {
    "ageData": {
      "2018": {
        "total": "60",
        "16to19": "4",
        "20to24": "6",
        "25to34": "18",
        "35to44": "18",
        "45to54": "8",
        "55to64": "6",
        "65+": "0",
        "medianAge": "6"
      }
    },
    "title": "Insulation workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "60",
        "women": "2.5",
        "white": "84.2",
        "black": "8.7",
        "asian": "1.9",
        "hispanicOrLatino": "38.9"
      }
    },
    "wageData": {
      "2018": {
        "workers": "49",
        "median": "-",
        "maleWorkers": "48",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Painters, construction and maintenance": {
    "ageData": {
      "2018": {
        "total": "607",
        "16to19": "14",
        "20to24": "38",
        "25to34": "150",
        "35to44": "170",
        "45to54": "116",
        "55to64": "93",
        "65+": "27",
        "medianAge": "93"
      }
    },
    "title": "Painters, construction and maintenance",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "607",
        "women": "7.2",
        "white": "87.6",
        "black": "5.7",
        "asian": "2.3",
        "hispanicOrLatino": "54.9"
      }
    },
    "wageData": {
      "2018": {
        "workers": "380",
        "median": "679",
        "maleWorkers": "360",
        "maleMedian": "679",
        "femaleWorkers": "20",
        "femaleMedian": "-"
      }
    }
  },
  "Paperhangers": {
    "ageData": {
      "2018": {
        "total": "5",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "1",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Paperhangers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Pipelayers, plumbers, pipefitters, and steamfitters": {
    "ageData": {
      "2018": {
        "total": "641",
        "16to19": "9",
        "20to24": "53",
        "25to34": "155",
        "35to44": "158",
        "45to54": "141",
        "55to64": "103",
        "65+": "22",
        "medianAge": "103"
      }
    },
    "title": "Pipelayers, plumbers, pipefitters, and steamfitters",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "641",
        "women": "3.5",
        "white": "88.5",
        "black": "8.2",
        "asian": "1.3",
        "hispanicOrLatino": "26.6"
      }
    },
    "wageData": {
      "2018": {
        "workers": "531",
        "median": "921",
        "maleWorkers": "514",
        "maleMedian": "916",
        "femaleWorkers": "17",
        "femaleMedian": "-"
      }
    }
  },
  "Plasterers and stucco masons": {
    "ageData": {
      "2018": {
        "total": "19",
        "16to19": "0",
        "20to24": "2",
        "25to34": "8",
        "35to44": "5",
        "45to54": "4",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Plasterers and stucco masons",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "19",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "15",
        "median": "-",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Reinforcing iron and rebar workers": {
    "ageData": {
      "2018": {
        "total": "3",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "0",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Reinforcing iron and rebar workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Roofers": {
    "ageData": {
      "2018": {
        "total": "201",
        "16to19": "2",
        "20to24": "20",
        "25to34": "75",
        "35to44": "54",
        "45to54": "31",
        "55to64": "14",
        "65+": "5",
        "medianAge": "14"
      }
    },
    "title": "Roofers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "201",
        "women": "2.6",
        "white": "86.7",
        "black": "6.6",
        "asian": "1.8",
        "hispanicOrLatino": "56.3"
      }
    },
    "wageData": {
      "2018": {
        "workers": "148",
        "median": "687",
        "maleWorkers": "146",
        "maleMedian": "686",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Sheet metal workers": {
    "ageData": {
      "2018": {
        "total": "147",
        "16to19": "3",
        "20to24": "13",
        "25to34": "43",
        "35to44": "34",
        "45to54": "32",
        "55to64": "20",
        "65+": "4",
        "medianAge": "20"
      }
    },
    "title": "Sheet metal workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "147",
        "women": "2.8",
        "white": "88.5",
        "black": "7.7",
        "asian": "1.3",
        "hispanicOrLatino": "23.3"
      }
    },
    "wageData": {
      "2018": {
        "workers": "129",
        "median": "881",
        "maleWorkers": "127",
        "maleMedian": "882",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Structural iron and steel workers": {
    "ageData": {
      "2018": {
        "total": "60",
        "16to19": "0",
        "20to24": "5",
        "25to34": "17",
        "35to44": "17",
        "45to54": "13",
        "55to64": "7",
        "65+": "0",
        "medianAge": "7"
      }
    },
    "title": "Structural iron and steel workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "60",
        "women": "2.2",
        "white": "81.7",
        "black": "6.3",
        "asian": "4.4",
        "hispanicOrLatino": "22.9"
      }
    },
    "wageData": {
      "2018": {
        "workers": "52",
        "median": "934",
        "maleWorkers": "51",
        "maleMedian": "941",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Solar photovoltaic installers": {
    "ageData": {
      "2018": {
        "total": "16",
        "16to19": "0",
        "20to24": "5",
        "25to34": "4",
        "35to44": "3",
        "45to54": "0",
        "55to64": "2",
        "65+": "2",
        "medianAge": "2"
      }
    },
    "title": "Solar photovoltaic installers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "16",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "9",
        "median": "-",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Helpers, construction trades": {
    "ageData": {
      "2018": {
        "total": "77",
        "16to19": "9",
        "20to24": "13",
        "25to34": "24",
        "35to44": "12",
        "45to54": "10",
        "55to64": "8",
        "65+": "1",
        "medianAge": "8"
      }
    },
    "title": "Helpers, construction trades",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "77",
        "women": "5.6",
        "white": "80.6",
        "black": "13.3",
        "asian": "1.7",
        "hispanicOrLatino": "35.9"
      }
    },
    "wageData": {
      "2018": {
        "workers": "68",
        "median": "620",
        "maleWorkers": "67",
        "maleMedian": "622",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Construction and building inspectors": {
    "ageData": {
      "2018": {
        "total": "108",
        "16to19": "0",
        "20to24": "3",
        "25to34": "14",
        "35to44": "20",
        "45to54": "28",
        "55to64": "32",
        "65+": "10",
        "medianAge": "32"
      }
    },
    "title": "Construction and building inspectors",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "108",
        "women": "14.0",
        "white": "85.6",
        "black": "7.7",
        "asian": "2.2",
        "hispanicOrLatino": "8.4"
      }
    },
    "wageData": {
      "2018": {
        "workers": "82",
        "median": "996",
        "maleWorkers": "68",
        "maleMedian": "1,015",
        "femaleWorkers": "14",
        "femaleMedian": "-"
      }
    }
  },
  "Elevator installers and repairers": {
    "ageData": {
      "2018": {
        "total": "25",
        "16to19": "0",
        "20to24": "0",
        "25to34": "8",
        "35to44": "5",
        "45to54": "6",
        "55to64": "7",
        "65+": "0",
        "medianAge": "7"
      }
    },
    "title": "Elevator installers and repairers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "25",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "25",
        "median": "-",
        "maleWorkers": "23",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Fence erectors": {
    "ageData": {
      "2018": {
        "total": "33",
        "16to19": "0",
        "20to24": "3",
        "25to34": "11",
        "35to44": "5",
        "45to54": "5",
        "55to64": "7",
        "65+": "2",
        "medianAge": "7"
      }
    },
    "title": "Fence erectors",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "33",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "21",
        "median": "-",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Hazardous materials removal workers": {
    "ageData": {
      "2018": {
        "total": "36",
        "16to19": "1",
        "20to24": "1",
        "25to34": "8",
        "35to44": "9",
        "45to54": "4",
        "55to64": "11",
        "65+": "2",
        "medianAge": "11"
      }
    },
    "title": "Hazardous materials removal workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "36",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "30",
        "median": "-",
        "maleWorkers": "27",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Highway maintenance workers": {
    "ageData": {
      "2018": {
        "total": "105",
        "16to19": "1",
        "20to24": "7",
        "25to34": "25",
        "35to44": "21",
        "45to54": "28",
        "55to64": "16",
        "65+": "8",
        "medianAge": "16"
      }
    },
    "title": "Highway maintenance workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "105",
        "women": "3.9",
        "white": "82.8",
        "black": "12.2",
        "asian": "0.7",
        "hispanicOrLatino": "12.7"
      }
    },
    "wageData": {
      "2018": {
        "workers": "109",
        "median": "781",
        "maleWorkers": "105",
        "maleMedian": "772",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Rail-track laying and maintenance equipment operators": {
    "ageData": {
      "2018": {
        "total": "10",
        "16to19": "0",
        "20to24": "1",
        "25to34": "3",
        "35to44": "0",
        "45to54": "1",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Rail-track laying and maintenance equipment operators",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "10",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Septic tank servicers and sewer pipe cleaners": {
    "ageData": {
      "2018": {
        "total": "9",
        "16to19": "0",
        "20to24": "1",
        "25to34": "3",
        "35to44": "0",
        "45to54": "5",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Septic tank servicers and sewer pipe cleaners",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "9",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous construction and related workers": {
    "ageData": {
      "2018": {
        "total": "28",
        "16to19": "0",
        "20to24": "3",
        "25to34": "10",
        "35to44": "7",
        "45to54": "6",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Miscellaneous construction and related workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "28",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "25",
        "median": "-",
        "maleWorkers": "24",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Derrick, rotary drill, and service unit operators, oil, gas, and mining": {
    "ageData": {
      "2018": {
        "total": "30",
        "16to19": "0",
        "20to24": "5",
        "25to34": "11",
        "35to44": "7",
        "45to54": "3",
        "55to64": "4",
        "65+": "1",
        "medianAge": "4"
      }
    },
    "title": "Derrick, rotary drill, and service unit operators, oil, gas, and mining",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "30",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "29",
        "median": "-",
        "maleWorkers": "28",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Earth drillers, except oil and gas": {
    "ageData": {
      "2018": {
        "total": "22",
        "16to19": "0",
        "20to24": "6",
        "25to34": "6",
        "35to44": "4",
        "45to54": "2",
        "55to64": "2",
        "65+": "2",
        "medianAge": "2"
      }
    },
    "title": "Earth drillers, except oil and gas",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "22",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "23",
        "median": "-",
        "maleWorkers": "23",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Explosives workers, ordnance handling experts, and blasters": {
    "ageData": {
      "2018": {
        "total": "12",
        "16to19": "0",
        "20to24": "1",
        "25to34": "5",
        "35to44": "0",
        "45to54": "2",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Explosives workers, ordnance handling experts, and blasters",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "12",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "14",
        "median": "-",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Mining machine operators": {
    "ageData": {
      "2018": {
        "total": "65",
        "16to19": "0",
        "20to24": "5",
        "25to34": "18",
        "35to44": "13",
        "45to54": "12",
        "55to64": "14",
        "65+": "2",
        "medianAge": "14"
      }
    },
    "title": "Mining machine operators",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "65",
        "women": "4.9",
        "white": "86.0",
        "black": "6.7",
        "asian": "0.8",
        "hispanicOrLatino": "18.1"
      }
    },
    "wageData": {
      "2018": {
        "workers": "59",
        "median": "1,402",
        "maleWorkers": "55",
        "maleMedian": "1,389",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Roof bolters, mining": {
    "ageData": {
      "2018": {
        "total": "1",
        "16to19": "0",
        "20to24": "1",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Roof bolters, mining",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Roustabouts, oil and gas": {
    "ageData": {
      "2018": {
        "total": "10",
        "16to19": "0",
        "20to24": "1",
        "25to34": "5",
        "35to44": "3",
        "45to54": "0",
        "55to64": "0",
        "65+": "1",
        "medianAge": "0"
      }
    },
    "title": "Roustabouts, oil and gas",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "10",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "9",
        "median": "-",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Helpers--extraction workers": {
    "ageData": {
      "2018": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Helpers--extraction workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Other extraction workers": {
    "ageData": {
      "2018": {
        "total": "49",
        "16to19": "1",
        "20to24": "6",
        "25to34": "17",
        "35to44": "9",
        "45to54": "10",
        "55to64": "5",
        "65+": "1",
        "medianAge": "5"
      }
    },
    "title": "Other extraction workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "49",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "35",
        "median": "-",
        "maleWorkers": "34",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Installation, maintenance, and repair occupations": {
    "ageData": {
      "2018": {
        "total": "5,012",
        "16to19": "92",
        "20to24": "409",
        "25to34": "1,123",
        "35to44": "1,109",
        "45to54": "1,106",
        "55to64": "961",
        "65+": "213",
        "medianAge": "961"
      }
    },
    "title": "Installation, maintenance, and repair occupations",
    "depth": 2,
    "parent": "Natural resources, construction, and maintenance occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "5,012",
        "women": "3.7",
        "white": "83.4",
        "black": "9.4",
        "asian": "3.3",
        "hispanicOrLatino": "20.1"
      }
    },
    "wageData": {
      "2018": {
        "workers": "4,282",
        "median": "934",
        "maleWorkers": "4,140",
        "maleMedian": "936",
        "femaleWorkers": "142",
        "femaleMedian": "823"
      }
    }
  },
  "First-line supervisors of mechanics, installers, and repairers": {
    "ageData": {
      "2018": {
        "total": "280",
        "16to19": "0",
        "20to24": "7",
        "25to34": "42",
        "35to44": "59",
        "45to54": "80",
        "55to64": "75",
        "65+": "16",
        "medianAge": "75"
      }
    },
    "title": "First-line supervisors of mechanics, installers, and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "280",
        "women": "5.9",
        "white": "87.0",
        "black": "7.4",
        "asian": "1.6",
        "hispanicOrLatino": "12.9"
      }
    },
    "wageData": {
      "2018": {
        "workers": "270",
        "median": "1,087",
        "maleWorkers": "255",
        "maleMedian": "1,111",
        "femaleWorkers": "15",
        "femaleMedian": "-"
      }
    }
  },
  "Computer, automated teller, and office machine repairers": {
    "ageData": {
      "2018": {
        "total": "185",
        "16to19": "2",
        "20to24": "15",
        "25to34": "45",
        "35to44": "39",
        "45to54": "34",
        "55to64": "42",
        "65+": "7",
        "medianAge": "42"
      }
    },
    "title": "Computer, automated teller, and office machine repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "185",
        "women": "13.7",
        "white": "75.6",
        "black": "14.0",
        "asian": "7.6",
        "hispanicOrLatino": "14.7"
      }
    },
    "wageData": {
      "2018": {
        "workers": "157",
        "median": "932",
        "maleWorkers": "137",
        "maleMedian": "940",
        "femaleWorkers": "20",
        "femaleMedian": "-"
      }
    }
  },
  "Radio and telecommunications equipment installers and repairers": {
    "ageData": {
      "2018": {
        "total": "136",
        "16to19": "2",
        "20to24": "6",
        "25to34": "26",
        "35to44": "35",
        "45to54": "40",
        "55to64": "21",
        "65+": "7",
        "medianAge": "21"
      }
    },
    "title": "Radio and telecommunications equipment installers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "136",
        "women": "13.6",
        "white": "73.4",
        "black": "16.0",
        "asian": "8.8",
        "hispanicOrLatino": "19.1"
      }
    },
    "wageData": {
      "2018": {
        "workers": "125",
        "median": "1,131",
        "maleWorkers": "108",
        "maleMedian": "1,134",
        "femaleWorkers": "18",
        "femaleMedian": "-"
      }
    }
  },
  "Avionics technicians": {
    "ageData": {
      "2018": {
        "total": "13",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "5",
        "45to54": "4",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Avionics technicians",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "13",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "11",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Electric motor, power tool, and related repairers": {
    "ageData": {
      "2018": {
        "total": "26",
        "16to19": "1",
        "20to24": "3",
        "25to34": "7",
        "35to44": "4",
        "45to54": "6",
        "55to64": "4",
        "65+": "1",
        "medianAge": "4"
      }
    },
    "title": "Electric motor, power tool, and related repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "26",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "22",
        "median": "-",
        "maleWorkers": "20",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Electrical and electronics installers and repairers, transportation equipment": {
    "ageData": {
      "2018": {
        "total": "1",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Electrical and electronics installers and repairers, transportation equipment",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "0",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Electrical and electronics repairers, industrial and utility": {
    "ageData": {
      "2018": {
        "total": "11",
        "16to19": "0",
        "20to24": "2",
        "25to34": "1",
        "35to44": "4",
        "45to54": "3",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Electrical and electronics repairers, industrial and utility",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "11",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Electronic equipment installers and repairers, motor vehicles": {
    "ageData": {
      "2018": {
        "total": "5",
        "16to19": "1",
        "20to24": "1",
        "25to34": "1",
        "35to44": "0",
        "45to54": "2",
        "55to64": "0",
        "65+": "1",
        "medianAge": "0"
      }
    },
    "title": "Electronic equipment installers and repairers, motor vehicles",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Electronic home entertainment equipment installers and repairers": {
    "ageData": {
      "2018": {
        "total": "38",
        "16to19": "1",
        "20to24": "5",
        "25to34": "10",
        "35to44": "11",
        "45to54": "6",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Electronic home entertainment equipment installers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "38",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "29",
        "median": "-",
        "maleWorkers": "29",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Security and fire alarm systems installers": {
    "ageData": {
      "2018": {
        "total": "63",
        "16to19": "0",
        "20to24": "8",
        "25to34": "18",
        "35to44": "13",
        "45to54": "11",
        "55to64": "9",
        "65+": "3",
        "medianAge": "9"
      }
    },
    "title": "Security and fire alarm systems installers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "63",
        "women": "0.7",
        "white": "86.9",
        "black": "7.9",
        "asian": "1.0",
        "hispanicOrLatino": "22.7"
      }
    },
    "wageData": {
      "2018": {
        "workers": "47",
        "median": "-",
        "maleWorkers": "47",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Aircraft mechanics and service technicians": {
    "ageData": {
      "2018": {
        "total": "164",
        "16to19": "1",
        "20to24": "13",
        "25to34": "37",
        "35to44": "32",
        "45to54": "33",
        "55to64": "38",
        "65+": "8",
        "medianAge": "38"
      }
    },
    "title": "Aircraft mechanics and service technicians",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "164",
        "women": "3.6",
        "white": "82.4",
        "black": "9.1",
        "asian": "5.0",
        "hispanicOrLatino": "19.4"
      }
    },
    "wageData": {
      "2018": {
        "workers": "155",
        "median": "1,117",
        "maleWorkers": "147",
        "maleMedian": "1,141",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Automotive body and related repairers": {
    "ageData": {
      "2018": {
        "total": "152",
        "16to19": "5",
        "20to24": "12",
        "25to34": "29",
        "35to44": "36",
        "45to54": "38",
        "55to64": "24",
        "65+": "9",
        "medianAge": "24"
      }
    },
    "title": "Automotive body and related repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "152",
        "women": "1.8",
        "white": "91.3",
        "black": "5.0",
        "asian": "1.6",
        "hispanicOrLatino": "30.9"
      }
    },
    "wageData": {
      "2018": {
        "workers": "114",
        "median": "820",
        "maleWorkers": "114",
        "maleMedian": "820",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Automotive glass installers and repairers": {
    "ageData": {
      "2018": {
        "total": "24",
        "16to19": "0",
        "20to24": "4",
        "25to34": "6",
        "35to44": "7",
        "45to54": "5",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Automotive glass installers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "24",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "22",
        "median": "-",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Automotive service technicians and mechanics": {
    "ageData": {
      "2018": {
        "total": "916",
        "16to19": "24",
        "20to24": "109",
        "25to34": "246",
        "35to44": "198",
        "45to54": "171",
        "55to64": "142",
        "65+": "26",
        "medianAge": "142"
      }
    },
    "title": "Automotive service technicians and mechanics",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "916",
        "women": "2.1",
        "white": "82.9",
        "black": "8.8",
        "asian": "4.1",
        "hispanicOrLatino": "26.0"
      }
    },
    "wageData": {
      "2018": {
        "workers": "709",
        "median": "800",
        "maleWorkers": "698",
        "maleMedian": "802",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Bus and truck mechanics and diesel engine specialists": {
    "ageData": {
      "2018": {
        "total": "389",
        "16to19": "6",
        "20to24": "40",
        "25to34": "98",
        "35to44": "74",
        "45to54": "90",
        "55to64": "72",
        "65+": "8",
        "medianAge": "72"
      }
    },
    "title": "Bus and truck mechanics and diesel engine specialists",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "389",
        "women": "0.9",
        "white": "87.9",
        "black": "8.1",
        "asian": "1.8",
        "hispanicOrLatino": "18.7"
      }
    },
    "wageData": {
      "2018": {
        "workers": "358",
        "median": "942",
        "maleWorkers": "352",
        "maleMedian": "943",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Heavy vehicle and mobile equipment service technicians and mechanics": {
    "ageData": {
      "2018": {
        "total": "211",
        "16to19": "1",
        "20to24": "17",
        "25to34": "46",
        "35to44": "45",
        "45to54": "49",
        "55to64": "43",
        "65+": "10",
        "medianAge": "43"
      }
    },
    "title": "Heavy vehicle and mobile equipment service technicians and mechanics",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "211",
        "women": "0.8",
        "white": "84.2",
        "black": "6.4",
        "asian": "2.1",
        "hispanicOrLatino": "12.4"
      }
    },
    "wageData": {
      "2018": {
        "workers": "199",
        "median": "1,013",
        "maleWorkers": "196",
        "maleMedian": "1,011",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Small engine mechanics": {
    "ageData": {
      "2018": {
        "total": "51",
        "16to19": "0",
        "20to24": "1",
        "25to34": "10",
        "35to44": "9",
        "45to54": "15",
        "55to64": "13",
        "65+": "3",
        "medianAge": "13"
      }
    },
    "title": "Small engine mechanics",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "51",
        "women": "2.7",
        "white": "98.0",
        "black": "0.6",
        "asian": "0.1",
        "hispanicOrLatino": "15.7"
      }
    },
    "wageData": {
      "2018": {
        "workers": "28",
        "median": "-",
        "maleWorkers": "28",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous vehicle and mobile equipment mechanics, installers, and repairers": {
    "ageData": {
      "2018": {
        "total": "93",
        "16to19": "4",
        "20to24": "15",
        "25to34": "26",
        "35to44": "17",
        "45to54": "18",
        "55to64": "12",
        "65+": "2",
        "medianAge": "12"
      }
    },
    "title": "Miscellaneous vehicle and mobile equipment mechanics, installers, and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "93",
        "women": "2.8",
        "white": "78.7",
        "black": "12.9",
        "asian": "2.3",
        "hispanicOrLatino": "31.1"
      }
    },
    "wageData": {
      "2018": {
        "workers": "68",
        "median": "611",
        "maleWorkers": "65",
        "maleMedian": "612",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Control and valve installers and repairers": {
    "ageData": {
      "2018": {
        "total": "20",
        "16to19": "0",
        "20to24": "1",
        "25to34": "3",
        "35to44": "6",
        "45to54": "8",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Control and valve installers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "20",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "20",
        "median": "-",
        "maleWorkers": "18",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Heating, air conditioning, and refrigeration mechanics and installers": {
    "ageData": {
      "2018": {
        "total": "472",
        "16to19": "11",
        "20to24": "47",
        "25to34": "105",
        "35to44": "118",
        "45to54": "96",
        "55to64": "75",
        "65+": "19",
        "medianAge": "75"
      }
    },
    "title": "Heating, air conditioning, and refrigeration mechanics and installers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "472",
        "women": "1.4",
        "white": "86.4",
        "black": "7.6",
        "asian": "2.9",
        "hispanicOrLatino": "21.5"
      }
    },
    "wageData": {
      "2018": {
        "workers": "384",
        "median": "890",
        "maleWorkers": "381",
        "maleMedian": "891",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Home appliance repairers": {
    "ageData": {
      "2018": {
        "total": "41",
        "16to19": "0",
        "20to24": "5",
        "25to34": "7",
        "35to44": "9",
        "45to54": "7",
        "55to64": "10",
        "65+": "3",
        "medianAge": "10"
      }
    },
    "title": "Home appliance repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "41",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "28",
        "median": "-",
        "maleWorkers": "28",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Industrial and refractory machinery mechanics": {
    "ageData": {
      "2018": {
        "total": "442",
        "16to19": "6",
        "20to24": "24",
        "25to34": "87",
        "35to44": "103",
        "45to54": "100",
        "55to64": "101",
        "65+": "20",
        "medianAge": "101"
      }
    },
    "title": "Industrial and refractory machinery mechanics",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "442",
        "women": "2.4",
        "white": "83.9",
        "black": "9.3",
        "asian": "3.3",
        "hispanicOrLatino": "15.4"
      }
    },
    "wageData": {
      "2018": {
        "workers": "424",
        "median": "981",
        "maleWorkers": "418",
        "maleMedian": "982",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Maintenance and repair workers, general": {
    "ageData": {
      "2018": {
        "total": "507",
        "16to19": "9",
        "20to24": "26",
        "25to34": "90",
        "35to44": "111",
        "45to54": "121",
        "55to64": "122",
        "65+": "29",
        "medianAge": "122"
      }
    },
    "title": "Maintenance and repair workers, general",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "507",
        "women": "3.5",
        "white": "79.8",
        "black": "12.7",
        "asian": "3.1",
        "hispanicOrLatino": "19.0"
      }
    },
    "wageData": {
      "2018": {
        "workers": "456",
        "median": "870",
        "maleWorkers": "439",
        "maleMedian": "874",
        "femaleWorkers": "16",
        "femaleMedian": "-"
      }
    }
  },
  "Maintenance workers, machinery": {
    "ageData": {
      "2018": {
        "total": "25",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "6",
        "45to54": "6",
        "55to64": "8",
        "65+": "2",
        "medianAge": "8"
      }
    },
    "title": "Maintenance workers, machinery",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "25",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "23",
        "median": "-",
        "maleWorkers": "23",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Millwrights": {
    "ageData": {
      "2018": {
        "total": "46",
        "16to19": "0",
        "20to24": "1",
        "25to34": "8",
        "35to44": "7",
        "45to54": "11",
        "55to64": "16",
        "65+": "2",
        "medianAge": "16"
      }
    },
    "title": "Millwrights",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "46",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "40",
        "median": "-",
        "maleWorkers": "39",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Electrical power-line installers and repairers": {
    "ageData": {
      "2018": {
        "total": "137",
        "16to19": "0",
        "20to24": "6",
        "25to34": "39",
        "35to44": "33",
        "45to54": "29",
        "55to64": "23",
        "65+": "6",
        "medianAge": "23"
      }
    },
    "title": "Electrical power-line installers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "137",
        "women": "4.1",
        "white": "86.0",
        "black": "10.0",
        "asian": "0.9",
        "hispanicOrLatino": "12.1"
      }
    },
    "wageData": {
      "2018": {
        "workers": "129",
        "median": "1,300",
        "maleWorkers": "124",
        "maleMedian": "1,292",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Telecommunications line installers and repairers": {
    "ageData": {
      "2018": {
        "total": "210",
        "16to19": "1",
        "20to24": "17",
        "25to34": "56",
        "35to44": "56",
        "45to54": "50",
        "55to64": "27",
        "65+": "3",
        "medianAge": "27"
      }
    },
    "title": "Telecommunications line installers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "210",
        "women": "3.9",
        "white": "77.4",
        "black": "13.1",
        "asian": "4.2",
        "hispanicOrLatino": "19.2"
      }
    },
    "wageData": {
      "2018": {
        "workers": "196",
        "median": "1,014",
        "maleWorkers": "187",
        "maleMedian": "1,026",
        "femaleWorkers": "9",
        "femaleMedian": "-"
      }
    }
  },
  "Precision instrument and equipment repairers": {
    "ageData": {
      "2018": {
        "total": "65",
        "16to19": "2",
        "20to24": "3",
        "25to34": "13",
        "35to44": "14",
        "45to54": "10",
        "55to64": "17",
        "65+": "7",
        "medianAge": "17"
      }
    },
    "title": "Precision instrument and equipment repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "65",
        "women": "13.2",
        "white": "74.0",
        "black": "12.8",
        "asian": "7.7",
        "hispanicOrLatino": "12.2"
      }
    },
    "wageData": {
      "2018": {
        "workers": "46",
        "median": "-",
        "maleWorkers": "39",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Wind turbine service technicians": {
    "ageData": {
      "2018": {
        "total": "3",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "1",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Wind turbine service technicians",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Coin, vending, and amusement machine servicers and repairers": {
    "ageData": {
      "2018": {
        "total": "36",
        "16to19": "1",
        "20to24": "4",
        "25to34": "6",
        "35to44": "9",
        "45to54": "8",
        "55to64": "6",
        "65+": "2",
        "medianAge": "6"
      }
    },
    "title": "Coin, vending, and amusement machine servicers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "36",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "25",
        "median": "-",
        "maleWorkers": "23",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Commercial divers": {
    "ageData": {
      "2018": {
        "total": "1",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Commercial divers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "0",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Locksmiths and safe repairers": {
    "ageData": {
      "2018": {
        "total": "34",
        "16to19": "1",
        "20to24": "0",
        "25to34": "8",
        "35to44": "7",
        "45to54": "6",
        "55to64": "9",
        "65+": "2",
        "medianAge": "9"
      }
    },
    "title": "Locksmiths and safe repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "34",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "18",
        "median": "-",
        "maleWorkers": "18",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Manufactured building and mobile home installers": {
    "ageData": {
      "2018": {
        "total": "7",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "3",
        "45to54": "4",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Manufactured building and mobile home installers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Riggers": {
    "ageData": {
      "2018": {
        "total": "14",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "5",
        "45to54": "6",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Riggers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "14",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Signal and track switch repairers": {
    "ageData": {
      "2018": {
        "total": "14",
        "16to19": "0",
        "20to24": "0",
        "25to34": "6",
        "35to44": "3",
        "45to54": "0",
        "55to64": "5",
        "65+": "0",
        "medianAge": "5"
      }
    },
    "title": "Signal and track switch repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "14",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "14",
        "median": "-",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Helpers--installation, maintenance, and repair workers": {
    "ageData": {
      "2018": {
        "total": "16",
        "16to19": "3",
        "20to24": "3",
        "25to34": "3",
        "35to44": "3",
        "45to54": "1",
        "55to64": "1",
        "65+": "1",
        "medianAge": "1"
      }
    },
    "title": "Helpers--installation, maintenance, and repair workers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "16",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "17",
        "median": "-",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Other installation, maintenance, and repair workers": {
    "ageData": {
      "2018": {
        "total": "167",
        "16to19": "5",
        "20to24": "13",
        "25to34": "33",
        "35to44": "29",
        "45to54": "38",
        "55to64": "33",
        "65+": "16",
        "medianAge": "33"
      }
    },
    "title": "Other installation, maintenance, and repair workers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "167",
        "women": "7.0",
        "white": "83.6",
        "black": "9.4",
        "asian": "3.6",
        "hispanicOrLatino": "24.4"
      }
    },
    "wageData": {
      "2018": {
        "workers": "111",
        "median": "827",
        "maleWorkers": "108",
        "maleMedian": "837",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Production, transportation, and material moving occupations": {
    "ageData": {
      "2018": {
        "total": "18,539",
        "16to19": "529",
        "20to24": "1,788",
        "25to34": "4,013",
        "35to44": "3,614",
        "45to54": "4,066",
        "55to64": "3,481",
        "65+": "1,048",
        "medianAge": "3,481"
      }
    },
    "title": "Production, transportation, and material moving occupations",
    "depth": 1,
    "parent": "",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "18,539",
        "women": "23.1",
        "white": "74.4",
        "black": "16.6",
        "asian": "5.1",
        "hispanicOrLatino": "22.8"
      }
    },
    "wageData": {
      "2018": {
        "workers": "15,210",
        "median": "707",
        "maleWorkers": "11,932",
        "maleMedian": "762",
        "femaleWorkers": "3,279",
        "femaleMedian": "561"
      }
    }
  },
  "Production occupations": {
    "ageData": {
      "2018": {
        "total": "8,621",
        "16to19": "167",
        "20to24": "799",
        "25to34": "1,958",
        "35to44": "1,748",
        "45to54": "1,920",
        "55to64": "1,646",
        "65+": "383",
        "medianAge": "1,646"
      }
    },
    "title": "Production occupations",
    "depth": 2,
    "parent": "Production, transportation, and material moving occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "8,621",
        "women": "28.8",
        "white": "76.6",
        "black": "13.5",
        "asian": "5.8",
        "hispanicOrLatino": "23.4"
      }
    },
    "wageData": {
      "2018": {
        "workers": "7,668",
        "median": "723",
        "maleWorkers": "5,627",
        "maleMedian": "793",
        "femaleWorkers": "2,041",
        "femaleMedian": "575"
      }
    }
  },
  "First-line supervisors of production and operating workers": {
    "ageData": {
      "2018": {
        "total": "832",
        "16to19": "3",
        "20to24": "31",
        "25to34": "151",
        "35to44": "202",
        "45to54": "226",
        "55to64": "178",
        "65+": "40",
        "medianAge": "178"
      }
    },
    "title": "First-line supervisors of production and operating workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "832",
        "women": "19.6",
        "white": "81.1",
        "black": "10.2",
        "asian": "4.3",
        "hispanicOrLatino": "18.9"
      }
    },
    "wageData": {
      "2018": {
        "workers": "792",
        "median": "980",
        "maleWorkers": "642",
        "maleMedian": "1,050",
        "femaleWorkers": "150",
        "femaleMedian": "745"
      }
    }
  },
  "Aircraft structure, surfaces, rigging, and systems assemblers": {
    "ageData": {
      "2018": {
        "total": "5",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "1",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Aircraft structure, surfaces, rigging, and systems assemblers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Electrical, electronics, and electromechanical assemblers": {
    "ageData": {
      "2018": {
        "total": "124",
        "16to19": "3",
        "20to24": "9",
        "25to34": "32",
        "35to44": "20",
        "45to54": "29",
        "55to64": "24",
        "65+": "6",
        "medianAge": "24"
      }
    },
    "title": "Electrical, electronics, and electromechanical assemblers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "124",
        "women": "49.2",
        "white": "69.7",
        "black": "12.2",
        "asian": "15.1",
        "hispanicOrLatino": "22.5"
      }
    },
    "wageData": {
      "2018": {
        "workers": "110",
        "median": "605",
        "maleWorkers": "57",
        "maleMedian": "706",
        "femaleWorkers": "53",
        "femaleMedian": "518"
      }
    }
  },
  "Engine and other machine assemblers": {
    "ageData": {
      "2018": {
        "total": "10",
        "16to19": "0",
        "20to24": "1",
        "25to34": "4",
        "35to44": "1",
        "45to54": "3",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Engine and other machine assemblers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "10",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Structural metal fabricators and fitters": {
    "ageData": {
      "2018": {
        "total": "33",
        "16to19": "1",
        "20to24": "4",
        "25to34": "11",
        "35to44": "6",
        "45to54": "8",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Structural metal fabricators and fitters",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "33",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "27",
        "median": "-",
        "maleWorkers": "27",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous assemblers and fabricators": {
    "ageData": {
      "2018": {
        "total": "1,064",
        "16to19": "19",
        "20to24": "120",
        "25to34": "275",
        "35to44": "199",
        "45to54": "208",
        "55to64": "198",
        "65+": "46",
        "medianAge": "198"
      }
    },
    "title": "Miscellaneous assemblers and fabricators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "1,064",
        "women": "36.6",
        "white": "66.8",
        "black": "19.9",
        "asian": "9.5",
        "hispanicOrLatino": "19.1"
      }
    },
    "wageData": {
      "2018": {
        "workers": "989",
        "median": "663",
        "maleWorkers": "641",
        "maleMedian": "723",
        "femaleWorkers": "348",
        "femaleMedian": "583"
      }
    }
  },
  "Bakers": {
    "ageData": {
      "2018": {
        "total": "219",
        "16to19": "8",
        "20to24": "31",
        "25to34": "50",
        "35to44": "41",
        "45to54": "41",
        "55to64": "35",
        "65+": "12",
        "medianAge": "35"
      }
    },
    "title": "Bakers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "219",
        "women": "61.1",
        "white": "74.6",
        "black": "12.8",
        "asian": "7.3",
        "hispanicOrLatino": "29.5"
      }
    },
    "wageData": {
      "2018": {
        "workers": "151",
        "median": "549",
        "maleWorkers": "61",
        "maleMedian": "565",
        "femaleWorkers": "89",
        "femaleMedian": "533"
      }
    }
  },
  "Butchers and other meat, poultry, and fish processing workers": {
    "ageData": {
      "2018": {
        "total": "265",
        "16to19": "5",
        "20to24": "36",
        "25to34": "64",
        "35to44": "59",
        "45to54": "56",
        "55to64": "34",
        "65+": "10",
        "medianAge": "34"
      }
    },
    "title": "Butchers and other meat, poultry, and fish processing workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "265",
        "women": "28.3",
        "white": "65.5",
        "black": "20.8",
        "asian": "6.4",
        "hispanicOrLatino": "32.4"
      }
    },
    "wageData": {
      "2018": {
        "workers": "223",
        "median": "606",
        "maleWorkers": "158",
        "maleMedian": "632",
        "femaleWorkers": "65",
        "femaleMedian": "557"
      }
    }
  },
  "Food and tobacco roasting, baking, and drying machine operators and tenders": {
    "ageData": {
      "2018": {
        "total": "6",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "0",
        "45to54": "2",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Food and tobacco roasting, baking, and drying machine operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "6",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Food batchmakers": {
    "ageData": {
      "2018": {
        "total": "80",
        "16to19": "4",
        "20to24": "14",
        "25to34": "18",
        "35to44": "14",
        "45to54": "15",
        "55to64": "8",
        "65+": "6",
        "medianAge": "8"
      }
    },
    "title": "Food batchmakers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "80",
        "women": "63.3",
        "white": "78.6",
        "black": "11.2",
        "asian": "7.3",
        "hispanicOrLatino": "22.0"
      }
    },
    "wageData": {
      "2018": {
        "workers": "55",
        "median": "557",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "34",
        "femaleMedian": "-"
      }
    }
  },
  "Food cooking machine operators and tenders": {
    "ageData": {
      "2018": {
        "total": "14",
        "16to19": "4",
        "20to24": "1",
        "25to34": "4",
        "35to44": "4",
        "45to54": "0",
        "55to64": "0",
        "65+": "1",
        "medianAge": "0"
      }
    },
    "title": "Food cooking machine operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "14",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Food processing workers, all other": {
    "ageData": {
      "2018": {
        "total": "158",
        "16to19": "1",
        "20to24": "13",
        "25to34": "32",
        "35to44": "42",
        "45to54": "42",
        "55to64": "24",
        "65+": "4",
        "medianAge": "24"
      }
    },
    "title": "Food processing workers, all other",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "158",
        "women": "37.0",
        "white": "67.4",
        "black": "22.6",
        "asian": "4.8",
        "hispanicOrLatino": "39.4"
      }
    },
    "wageData": {
      "2018": {
        "workers": "142",
        "median": "624",
        "maleWorkers": "90",
        "maleMedian": "749",
        "femaleWorkers": "52",
        "femaleMedian": "539"
      }
    }
  },
  "Computer control programmers and operators": {
    "ageData": {
      "2018": {
        "total": "107",
        "16to19": "1",
        "20to24": "12",
        "25to34": "24",
        "35to44": "24",
        "45to54": "25",
        "55to64": "19",
        "65+": "3",
        "medianAge": "19"
      }
    },
    "title": "Computer control programmers and operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "107",
        "women": "9.7",
        "white": "91.4",
        "black": "7.1",
        "asian": "1.5",
        "hispanicOrLatino": "19.1"
      }
    },
    "wageData": {
      "2018": {
        "workers": "106",
        "median": "882",
        "maleWorkers": "94",
        "maleMedian": "890",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Extruding and drawing machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2018": {
        "total": "14",
        "16to19": "0",
        "20to24": "5",
        "25to34": "3",
        "35to44": "3",
        "45to54": "2",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Extruding and drawing machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "14",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "17",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Forging machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2018": {
        "total": "6",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "1",
        "45to54": "2",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Forging machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "6",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Rolling machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2018": {
        "total": "11",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "3",
        "45to54": "0",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Rolling machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "11",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "11",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Cutting, punching, and press machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2018": {
        "total": "61",
        "16to19": "0",
        "20to24": "5",
        "25to34": "12",
        "35to44": "9",
        "45to54": "15",
        "55to64": "16",
        "65+": "3",
        "medianAge": "16"
      }
    },
    "title": "Cutting, punching, and press machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "61",
        "women": "9.9",
        "white": "75.0",
        "black": "12.6",
        "asian": "4.1",
        "hispanicOrLatino": "8.2"
      }
    },
    "wageData": {
      "2018": {
        "workers": "58",
        "median": "745",
        "maleWorkers": "53",
        "maleMedian": "766",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Drilling and boring machine tool setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2018": {
        "total": "3",
        "16to19": "1",
        "20to24": "0",
        "25to34": "2",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Drilling and boring machine tool setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Grinding, lapping, polishing, and buffing machine tool setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2018": {
        "total": "38",
        "16to19": "0",
        "20to24": "5",
        "25to34": "4",
        "35to44": "7",
        "45to54": "10",
        "55to64": "8",
        "65+": "4",
        "medianAge": "8"
      }
    },
    "title": "Grinding, lapping, polishing, and buffing machine tool setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "38",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "35",
        "median": "-",
        "maleWorkers": "33",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Lathe and turning machine tool setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2018": {
        "total": "8",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "2",
        "45to54": "2",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Lathe and turning machine tool setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "8",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Milling and planing machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2018": {
        "total": "3",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "0",
        "45to54": "0",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Milling and planing machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Machinists": {
    "ageData": {
      "2018": {
        "total": "344",
        "16to19": "7",
        "20to24": "23",
        "25to34": "69",
        "35to44": "59",
        "45to54": "79",
        "55to64": "89",
        "65+": "18",
        "medianAge": "89"
      }
    },
    "title": "Machinists",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "344",
        "women": "6.4",
        "white": "83.7",
        "black": "7.2",
        "asian": "5.8",
        "hispanicOrLatino": "13.6"
      }
    },
    "wageData": {
      "2018": {
        "workers": "309",
        "median": "863",
        "maleWorkers": "293",
        "maleMedian": "878",
        "femaleWorkers": "16",
        "femaleMedian": "-"
      }
    }
  },
  "Metal furnace operators, tenders, pourers, and casters": {
    "ageData": {
      "2018": {
        "total": "20",
        "16to19": "0",
        "20to24": "2",
        "25to34": "6",
        "35to44": "1",
        "45to54": "8",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Metal furnace operators, tenders, pourers, and casters",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "20",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "17",
        "median": "-",
        "maleWorkers": "16",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Model makers and patternmakers, metal and plastic": {
    "ageData": {
      "2018": {
        "total": "6",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "0",
        "45to54": "1",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Model makers and patternmakers, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "6",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Molders and molding machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2018": {
        "total": "40",
        "16to19": "0",
        "20to24": "5",
        "25to34": "5",
        "35to44": "11",
        "45to54": "10",
        "55to64": "9",
        "65+": "0",
        "medianAge": "9"
      }
    },
    "title": "Molders and molding machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "40",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "35",
        "median": "-",
        "maleWorkers": "32",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Multiple machine tool setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2018": {
        "total": "5",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "3",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Multiple machine tool setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Tool and die makers": {
    "ageData": {
      "2018": {
        "total": "50",
        "16to19": "1",
        "20to24": "2",
        "25to34": "5",
        "35to44": "6",
        "45to54": "14",
        "55to64": "20",
        "65+": "3",
        "medianAge": "20"
      }
    },
    "title": "Tool and die makers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "50",
        "women": "5.7",
        "white": "96.1",
        "black": "1.8",
        "asian": "1.7",
        "hispanicOrLatino": "12.4"
      }
    },
    "wageData": {
      "2018": {
        "workers": "47",
        "median": "-",
        "maleWorkers": "45",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Welding, soldering, and brazing workers": {
    "ageData": {
      "2018": {
        "total": "633",
        "16to19": "17",
        "20to24": "69",
        "25to34": "176",
        "35to44": "141",
        "45to54": "124",
        "55to64": "92",
        "65+": "13",
        "medianAge": "92"
      }
    },
    "title": "Welding, soldering, and brazing workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "633",
        "women": "5.4",
        "white": "85.7",
        "black": "8.3",
        "asian": "1.4",
        "hispanicOrLatino": "25.0"
      }
    },
    "wageData": {
      "2018": {
        "workers": "562",
        "median": "840",
        "maleWorkers": "530",
        "maleMedian": "847",
        "femaleWorkers": "32",
        "femaleMedian": "-"
      }
    }
  },
  "Heat treating equipment setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2018": {
        "total": "9",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "3",
        "45to54": "3",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Heat treating equipment setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "9",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "10",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Layout workers, metal and plastic": {
    "ageData": {
      "2018": {
        "total": "9",
        "16to19": "0",
        "20to24": "2",
        "25to34": "1",
        "35to44": "2",
        "45to54": "2",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Layout workers, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "9",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "10",
        "median": "-",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Plating and coating machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2018": {
        "total": "17",
        "16to19": "0",
        "20to24": "2",
        "25to34": "3",
        "35to44": "5",
        "45to54": "2",
        "55to64": "4",
        "65+": "1",
        "medianAge": "4"
      }
    },
    "title": "Plating and coating machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "17",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "17",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Tool grinders, filers, and sharpeners": {
    "ageData": {
      "2018": {
        "total": "3",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "1",
        "55to64": "1",
        "65+": "1",
        "medianAge": "1"
      }
    },
    "title": "Tool grinders, filers, and sharpeners",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Metal workers and plastic workers, all other": {
    "ageData": {
      "2018": {
        "total": "373",
        "16to19": "5",
        "20to24": "27",
        "25to34": "92",
        "35to44": "80",
        "45to54": "83",
        "55to64": "72",
        "65+": "16",
        "medianAge": "72"
      }
    },
    "title": "Metal workers and plastic workers, all other",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "373",
        "women": "18.3",
        "white": "73.6",
        "black": "15.0",
        "asian": "7.0",
        "hispanicOrLatino": "22.9"
      }
    },
    "wageData": {
      "2018": {
        "workers": "356",
        "median": "698",
        "maleWorkers": "290",
        "maleMedian": "724",
        "femaleWorkers": "66",
        "femaleMedian": "611"
      }
    }
  },
  "Prepress technicians and workers": {
    "ageData": {
      "2018": {
        "total": "18",
        "16to19": "0",
        "20to24": "2",
        "25to34": "6",
        "35to44": "3",
        "45to54": "3",
        "55to64": "4",
        "65+": "1",
        "medianAge": "4"
      }
    },
    "title": "Prepress technicians and workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "18",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "14",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Printing press operators": {
    "ageData": {
      "2018": {
        "total": "164",
        "16to19": "5",
        "20to24": "11",
        "25to34": "27",
        "35to44": "34",
        "45to54": "43",
        "55to64": "36",
        "65+": "8",
        "medianAge": "36"
      }
    },
    "title": "Printing press operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "164",
        "women": "22.7",
        "white": "82.1",
        "black": "10.2",
        "asian": "3.6",
        "hispanicOrLatino": "20.6"
      }
    },
    "wageData": {
      "2018": {
        "workers": "137",
        "median": "754",
        "maleWorkers": "111",
        "maleMedian": "804",
        "femaleWorkers": "26",
        "femaleMedian": "-"
      }
    }
  },
  "Print binding and finishing workers": {
    "ageData": {
      "2018": {
        "total": "13",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "3",
        "45to54": "2",
        "55to64": "5",
        "65+": "2",
        "medianAge": "5"
      }
    },
    "title": "Print binding and finishing workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "13",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Laundry and dry-cleaning workers": {
    "ageData": {
      "2018": {
        "total": "143",
        "16to19": "5",
        "20to24": "7",
        "25to34": "24",
        "35to44": "34",
        "45to54": "34",
        "55to64": "29",
        "65+": "11",
        "medianAge": "29"
      }
    },
    "title": "Laundry and dry-cleaning workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "143",
        "women": "71.5",
        "white": "64.9",
        "black": "20.1",
        "asian": "7.1",
        "hispanicOrLatino": "43.1"
      }
    },
    "wageData": {
      "2018": {
        "workers": "108",
        "median": "432",
        "maleWorkers": "32",
        "maleMedian": "-",
        "femaleWorkers": "76",
        "femaleMedian": "416"
      }
    }
  },
  "Pressers, textile, garment, and related materials": {
    "ageData": {
      "2018": {
        "total": "36",
        "16to19": "1",
        "20to24": "3",
        "25to34": "3",
        "35to44": "14",
        "45to54": "5",
        "55to64": "7",
        "65+": "3",
        "medianAge": "7"
      }
    },
    "title": "Pressers, textile, garment, and related materials",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "36",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "28",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "18",
        "femaleMedian": "-"
      }
    }
  },
  "Sewing machine operators": {
    "ageData": {
      "2018": {
        "total": "174",
        "16to19": "2",
        "20to24": "6",
        "25to34": "23",
        "35to44": "33",
        "45to54": "47",
        "55to64": "47",
        "65+": "16",
        "medianAge": "47"
      }
    },
    "title": "Sewing machine operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "174",
        "women": "73.4",
        "white": "74.1",
        "black": "9.4",
        "asian": "12.7",
        "hispanicOrLatino": "44.3"
      }
    },
    "wageData": {
      "2018": {
        "workers": "131",
        "median": "498",
        "maleWorkers": "37",
        "maleMedian": "-",
        "femaleWorkers": "94",
        "femaleMedian": "497"
      }
    }
  },
  "Shoe and leather workers and repairers": {
    "ageData": {
      "2018": {
        "total": "8",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "2",
        "45to54": "1",
        "55to64": "4",
        "65+": "2",
        "medianAge": "4"
      }
    },
    "title": "Shoe and leather workers and repairers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "8",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Shoe machine operators and tenders": {
    "ageData": {
      "2018": {
        "total": "4",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "1",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Shoe machine operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Tailors, dressmakers, and sewers": {
    "ageData": {
      "2018": {
        "total": "85",
        "16to19": "0",
        "20to24": "0",
        "25to34": "9",
        "35to44": "13",
        "45to54": "23",
        "55to64": "21",
        "65+": "19",
        "medianAge": "21"
      }
    },
    "title": "Tailors, dressmakers, and sewers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "85",
        "women": "75.1",
        "white": "67.7",
        "black": "3.6",
        "asian": "25.3",
        "hispanicOrLatino": "23.5"
      }
    },
    "wageData": {
      "2018": {
        "workers": "43",
        "median": "-",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "24",
        "femaleMedian": "-"
      }
    }
  },
  "Textile bleaching and dyeing machine operators and tenders": {
    "ageData": {
      "2018": {
        "total": "1",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Textile bleaching and dyeing machine operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "0",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Textile cutting machine setters, operators, and tenders": {
    "ageData": {
      "2018": {
        "total": "6",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "3",
        "45to54": "0",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Textile cutting machine setters, operators, and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "6",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Textile knitting and weaving machine setters, operators, and tenders": {
    "ageData": {
      "2018": {
        "total": "5",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "1",
        "45to54": "0",
        "55to64": "1",
        "65+": "2",
        "medianAge": "1"
      }
    },
    "title": "Textile knitting and weaving machine setters, operators, and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Textile winding, twisting, and drawing out machine setters, operators, and tenders": {
    "ageData": {
      "2018": {
        "total": "5",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "0",
        "45to54": "4",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Textile winding, twisting, and drawing out machine setters, operators, and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Extruding and forming machine setters, operators, and tenders, synthetic and glass fibers": {
    "ageData": {
      "2018": {
        "total": "0",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Extruding and forming machine setters, operators, and tenders, synthetic and glass fibers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "0",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "0",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Fabric and apparel patternmakers": {
    "ageData": {
      "2018": {
        "total": "3",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "1",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Fabric and apparel patternmakers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Upholsterers": {
    "ageData": {
      "2018": {
        "total": "31",
        "16to19": "1",
        "20to24": "2",
        "25to34": "4",
        "35to44": "6",
        "45to54": "11",
        "55to64": "6",
        "65+": "1",
        "medianAge": "6"
      }
    },
    "title": "Upholsterers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "31",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "19",
        "median": "-",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Textile, apparel, and furnishings workers, all other": {
    "ageData": {
      "2018": {
        "total": "16",
        "16to19": "1",
        "20to24": "3",
        "25to34": "3",
        "35to44": "1",
        "45to54": "2",
        "55to64": "6",
        "65+": "1",
        "medianAge": "6"
      }
    },
    "title": "Textile, apparel, and furnishings workers, all other",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "16",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "14",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Cabinetmakers and bench carpenters": {
    "ageData": {
      "2018": {
        "total": "65",
        "16to19": "1",
        "20to24": "4",
        "25to34": "15",
        "35to44": "9",
        "45to54": "14",
        "55to64": "17",
        "65+": "5",
        "medianAge": "17"
      }
    },
    "title": "Cabinetmakers and bench carpenters",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "65",
        "women": "6.5",
        "white": "88.3",
        "black": "5.7",
        "asian": "3.3",
        "hispanicOrLatino": "14.4"
      }
    },
    "wageData": {
      "2018": {
        "workers": "47",
        "median": "-",
        "maleWorkers": "44",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Furniture finishers": {
    "ageData": {
      "2018": {
        "total": "20",
        "16to19": "0",
        "20to24": "1",
        "25to34": "3",
        "35to44": "4",
        "45to54": "4",
        "55to64": "5",
        "65+": "3",
        "medianAge": "5"
      }
    },
    "title": "Furniture finishers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "20",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "15",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Model makers and patternmakers, wood": {
    "ageData": {
      "2018": {
        "total": "0",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Model makers and patternmakers, wood",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "0",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "0",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Sawing machine setters, operators, and tenders, wood": {
    "ageData": {
      "2018": {
        "total": "37",
        "16to19": "2",
        "20to24": "8",
        "25to34": "8",
        "35to44": "8",
        "45to54": "8",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Sawing machine setters, operators, and tenders, wood",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "37",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "32",
        "median": "-",
        "maleWorkers": "27",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Woodworking machine setters, operators, and tenders, except sawing": {
    "ageData": {
      "2018": {
        "total": "27",
        "16to19": "3",
        "20to24": "5",
        "25to34": "7",
        "35to44": "2",
        "45to54": "4",
        "55to64": "6",
        "65+": "1",
        "medianAge": "6"
      }
    },
    "title": "Woodworking machine setters, operators, and tenders, except sawing",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "27",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "23",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Woodworkers, all other": {
    "ageData": {
      "2018": {
        "total": "28",
        "16to19": "1",
        "20to24": "3",
        "25to34": "6",
        "35to44": "3",
        "45to54": "3",
        "55to64": "10",
        "65+": "3",
        "medianAge": "10"
      }
    },
    "title": "Woodworkers, all other",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "28",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "11",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Power plant operators, distributors, and dispatchers": {
    "ageData": {
      "2018": {
        "total": "33",
        "16to19": "0",
        "20to24": "1",
        "25to34": "12",
        "35to44": "4",
        "45to54": "8",
        "55to64": "7",
        "65+": "1",
        "medianAge": "7"
      }
    },
    "title": "Power plant operators, distributors, and dispatchers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "33",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "33",
        "median": "-",
        "maleWorkers": "32",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Stationary engineers and boiler operators": {
    "ageData": {
      "2018": {
        "total": "79",
        "16to19": "1",
        "20to24": "1",
        "25to34": "7",
        "35to44": "9",
        "45to54": "31",
        "55to64": "26",
        "65+": "4",
        "medianAge": "26"
      }
    },
    "title": "Stationary engineers and boiler operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "79",
        "women": "1.3",
        "white": "77.8",
        "black": "8.8",
        "asian": "9.4",
        "hispanicOrLatino": "10.6"
      }
    },
    "wageData": {
      "2018": {
        "workers": "84",
        "median": "1,073",
        "maleWorkers": "82",
        "maleMedian": "1,086",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Water and wastewater treatment plant and system operators": {
    "ageData": {
      "2018": {
        "total": "101",
        "16to19": "0",
        "20to24": "1",
        "25to34": "17",
        "35to44": "24",
        "45to54": "27",
        "55to64": "26",
        "65+": "4",
        "medianAge": "26"
      }
    },
    "title": "Water and wastewater treatment plant and system operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "101",
        "women": "7.2",
        "white": "85.6",
        "black": "7.6",
        "asian": "1.4",
        "hispanicOrLatino": "9.4"
      }
    },
    "wageData": {
      "2018": {
        "workers": "99",
        "median": "943",
        "maleWorkers": "94",
        "maleMedian": "940",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous plant and system operators": {
    "ageData": {
      "2018": {
        "total": "38",
        "16to19": "0",
        "20to24": "5",
        "25to34": "14",
        "35to44": "7",
        "45to54": "6",
        "55to64": "6",
        "65+": "1",
        "medianAge": "6"
      }
    },
    "title": "Miscellaneous plant and system operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "38",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "35",
        "median": "-",
        "maleWorkers": "35",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Chemical processing machine setters, operators, and tenders": {
    "ageData": {
      "2018": {
        "total": "75",
        "16to19": "2",
        "20to24": "5",
        "25to34": "27",
        "35to44": "9",
        "45to54": "17",
        "55to64": "15",
        "65+": "1",
        "medianAge": "15"
      }
    },
    "title": "Chemical processing machine setters, operators, and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "75",
        "women": "11.3",
        "white": "90.3",
        "black": "7.5",
        "asian": "0.5",
        "hispanicOrLatino": "11.2"
      }
    },
    "wageData": {
      "2018": {
        "workers": "65",
        "median": "894",
        "maleWorkers": "57",
        "maleMedian": "899",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Crushing, grinding, polishing, mixing, and blending workers": {
    "ageData": {
      "2018": {
        "total": "79",
        "16to19": "0",
        "20to24": "13",
        "25to34": "25",
        "35to44": "10",
        "45to54": "18",
        "55to64": "13",
        "65+": "1",
        "medianAge": "13"
      }
    },
    "title": "Crushing, grinding, polishing, mixing, and blending workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "79",
        "women": "9.1",
        "white": "79.8",
        "black": "15.7",
        "asian": "0.3",
        "hispanicOrLatino": "20.0"
      }
    },
    "wageData": {
      "2018": {
        "workers": "83",
        "median": "672",
        "maleWorkers": "78",
        "maleMedian": "673",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Cutting workers": {
    "ageData": {
      "2018": {
        "total": "60",
        "16to19": "1",
        "20to24": "10",
        "25to34": "12",
        "35to44": "17",
        "45to54": "9",
        "55to64": "8",
        "65+": "3",
        "medianAge": "8"
      }
    },
    "title": "Cutting workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "60",
        "women": "27.5",
        "white": "77.0",
        "black": "11.4",
        "asian": "8.4",
        "hispanicOrLatino": "30.6"
      }
    },
    "wageData": {
      "2018": {
        "workers": "56",
        "median": "595",
        "maleWorkers": "43",
        "maleMedian": "-",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Extruding, forming, pressing, and compacting machine setters, operators, and tenders": {
    "ageData": {
      "2018": {
        "total": "22",
        "16to19": "0",
        "20to24": "1",
        "25to34": "4",
        "35to44": "5",
        "45to54": "5",
        "55to64": "4",
        "65+": "2",
        "medianAge": "4"
      }
    },
    "title": "Extruding, forming, pressing, and compacting machine setters, operators, and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "22",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "17",
        "median": "-",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Furnace, kiln, oven, drier, and kettle operators and tenders": {
    "ageData": {
      "2018": {
        "total": "25",
        "16to19": "0",
        "20to24": "3",
        "25to34": "2",
        "35to44": "8",
        "45to54": "4",
        "55to64": "7",
        "65+": "1",
        "medianAge": "7"
      }
    },
    "title": "Furnace, kiln, oven, drier, and kettle operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "25",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "20",
        "median": "-",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Inspectors, testers, sorters, samplers, and weighers": {
    "ageData": {
      "2018": {
        "total": "807",
        "16to19": "9",
        "20to24": "67",
        "25to34": "200",
        "35to44": "154",
        "45to54": "183",
        "55to64": "160",
        "65+": "33",
        "medianAge": "160"
      }
    },
    "title": "Inspectors, testers, sorters, samplers, and weighers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "807",
        "women": "36.6",
        "white": "77.1",
        "black": "13.9",
        "asian": "6.0",
        "hispanicOrLatino": "19.1"
      }
    },
    "wageData": {
      "2018": {
        "workers": "756",
        "median": "771",
        "maleWorkers": "490",
        "maleMedian": "868",
        "femaleWorkers": "267",
        "femaleMedian": "679"
      }
    }
  },
  "Jewelers and precious stone and metal workers": {
    "ageData": {
      "2018": {
        "total": "38",
        "16to19": "0",
        "20to24": "2",
        "25to34": "8",
        "35to44": "9",
        "45to54": "5",
        "55to64": "8",
        "65+": "5",
        "medianAge": "8"
      }
    },
    "title": "Jewelers and precious stone and metal workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "38",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "20",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Medical, dental, and ophthalmic laboratory technicians": {
    "ageData": {
      "2018": {
        "total": "87",
        "16to19": "0",
        "20to24": "10",
        "25to34": "16",
        "35to44": "17",
        "45to54": "20",
        "55to64": "18",
        "65+": "5",
        "medianAge": "18"
      }
    },
    "title": "Medical, dental, and ophthalmic laboratory technicians",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "87",
        "women": "54.2",
        "white": "70.8",
        "black": "10.1",
        "asian": "13.2",
        "hispanicOrLatino": "19.9"
      }
    },
    "wageData": {
      "2018": {
        "workers": "74",
        "median": "741",
        "maleWorkers": "33",
        "maleMedian": "-",
        "femaleWorkers": "41",
        "femaleMedian": "-"
      }
    }
  },
  "Packaging and filling machine operators and tenders": {
    "ageData": {
      "2018": {
        "total": "277",
        "16to19": "10",
        "20to24": "39",
        "25to34": "59",
        "35to44": "74",
        "45to54": "46",
        "55to64": "42",
        "65+": "6",
        "medianAge": "42"
      }
    },
    "title": "Packaging and filling machine operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "277",
        "women": "55.8",
        "white": "67.0",
        "black": "22.3",
        "asian": "7.6",
        "hispanicOrLatino": "38.9"
      }
    },
    "wageData": {
      "2018": {
        "workers": "250",
        "median": "529",
        "maleWorkers": "110",
        "maleMedian": "602",
        "femaleWorkers": "140",
        "femaleMedian": "496"
      }
    }
  },
  "Painting workers": {
    "ageData": {
      "2018": {
        "total": "165",
        "16to19": "5",
        "20to24": "20",
        "25to34": "44",
        "35to44": "35",
        "45to54": "33",
        "55to64": "25",
        "65+": "4",
        "medianAge": "25"
      }
    },
    "title": "Painting workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "165",
        "women": "9.4",
        "white": "80.7",
        "black": "11.2",
        "asian": "1.8",
        "hispanicOrLatino": "36.0"
      }
    },
    "wageData": {
      "2018": {
        "workers": "159",
        "median": "710",
        "maleWorkers": "142",
        "maleMedian": "725",
        "femaleWorkers": "16",
        "femaleMedian": "-"
      }
    }
  },
  "Photographic process workers and processing machine operators": {
    "ageData": {
      "2018": {
        "total": "17",
        "16to19": "0",
        "20to24": "2",
        "25to34": "5",
        "35to44": "1",
        "45to54": "6",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Photographic process workers and processing machine operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "17",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "15",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Semiconductor processors": {
    "ageData": {
      "2018": {
        "total": "1",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Semiconductor processors",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Adhesive bonding machine operators and tenders": {
    "ageData": {
      "2018": {
        "total": "8",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "2",
        "45to54": "1",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Adhesive bonding machine operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "8",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Cleaning, washing, and metal pickling equipment operators and tenders": {
    "ageData": {
      "2018": {
        "total": "9",
        "16to19": "0",
        "20to24": "3",
        "25to34": "2",
        "35to44": "0",
        "45to54": "0",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Cleaning, washing, and metal pickling equipment operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "9",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Cooling and freezing equipment operators and tenders": {
    "ageData": {
      "2018": {
        "total": "4",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "3",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Cooling and freezing equipment operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Etchers and engravers": {
    "ageData": {
      "2018": {
        "total": "11",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "1",
        "45to54": "1",
        "55to64": "5",
        "65+": "2",
        "medianAge": "5"
      }
    },
    "title": "Etchers and engravers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "11",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Molders, shapers, and casters, except metal and plastic": {
    "ageData": {
      "2018": {
        "total": "37",
        "16to19": "0",
        "20to24": "3",
        "25to34": "6",
        "35to44": "13",
        "45to54": "6",
        "55to64": "7",
        "65+": "3",
        "medianAge": "7"
      }
    },
    "title": "Molders, shapers, and casters, except metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "37",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "28",
        "median": "-",
        "maleWorkers": "27",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Paper goods machine setters, operators, and tenders": {
    "ageData": {
      "2018": {
        "total": "21",
        "16to19": "0",
        "20to24": "2",
        "25to34": "3",
        "35to44": "5",
        "45to54": "5",
        "55to64": "5",
        "65+": "1",
        "medianAge": "5"
      }
    },
    "title": "Paper goods machine setters, operators, and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "21",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "20",
        "median": "-",
        "maleWorkers": "16",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Tire builders": {
    "ageData": {
      "2018": {
        "total": "7",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "4",
        "45to54": "0",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Tire builders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Helpers--production workers": {
    "ageData": {
      "2018": {
        "total": "53",
        "16to19": "8",
        "20to24": "9",
        "25to34": "15",
        "35to44": "5",
        "45to54": "9",
        "55to64": "6",
        "65+": "2",
        "medianAge": "6"
      }
    },
    "title": "Helpers--production workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "53",
        "women": "31.8",
        "white": "77.6",
        "black": "13.9",
        "asian": "4.3",
        "hispanicOrLatino": "37.9"
      }
    },
    "wageData": {
      "2018": {
        "workers": "37",
        "median": "-",
        "maleWorkers": "26",
        "maleMedian": "-",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Production workers, all other": {
    "ageData": {
      "2018": {
        "total": "1,107",
        "16to19": "26",
        "20to24": "127",
        "25to34": "273",
        "35to44": "213",
        "45to54": "252",
        "55to64": "184",
        "65+": "32",
        "medianAge": "184"
      }
    },
    "title": "Production workers, all other",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "1,107",
        "women": "29.5",
        "white": "74.9",
        "black": "16.6",
        "asian": "4.1",
        "hispanicOrLatino": "26.4"
      }
    },
    "wageData": {
      "2018": {
        "workers": "992",
        "median": "678",
        "maleWorkers": "729",
        "maleMedian": "725",
        "femaleWorkers": "263",
        "femaleMedian": "566"
      }
    }
  },
  "Transportation and material moving occupations": {
    "ageData": {
      "2018": {
        "total": "9,918",
        "16to19": "362",
        "20to24": "990",
        "25to34": "2,055",
        "35to44": "1,866",
        "45to54": "2,145",
        "55to64": "1,835",
        "65+": "665",
        "medianAge": "1,835"
      }
    },
    "title": "Transportation and material moving occupations",
    "depth": 2,
    "parent": "Production, transportation, and material moving occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "9,918",
        "women": "18.1",
        "white": "72.5",
        "black": "19.2",
        "asian": "4.5",
        "hispanicOrLatino": "22.3"
      }
    },
    "wageData": {
      "2018": {
        "workers": "7,542",
        "median": "689",
        "maleWorkers": "6,305",
        "maleMedian": "724",
        "femaleWorkers": "1,237",
        "femaleMedian": "538"
      }
    }
  },
  "Supervisors of transportation and material moving workers": {
    "ageData": {
      "2018": {
        "total": "217",
        "16to19": "0",
        "20to24": "13",
        "25to34": "49",
        "35to44": "44",
        "45to54": "64",
        "55to64": "39",
        "65+": "7",
        "medianAge": "39"
      }
    },
    "title": "Supervisors of transportation and material moving workers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "217",
        "women": "23.2",
        "white": "68.3",
        "black": "19.5",
        "asian": "6.7",
        "hispanicOrLatino": "14.2"
      }
    },
    "wageData": {
      "2018": {
        "workers": "197",
        "median": "850",
        "maleWorkers": "152",
        "maleMedian": "878",
        "femaleWorkers": "45",
        "femaleMedian": "-"
      }
    }
  },
  "Aircraft pilots and flight engineers": {
    "ageData": {
      "2018": {
        "total": "119",
        "16to19": "0",
        "20to24": "3",
        "25to34": "21",
        "35to44": "30",
        "45to54": "26",
        "55to64": "36",
        "65+": "3",
        "medianAge": "36"
      }
    },
    "title": "Aircraft pilots and flight engineers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "119",
        "women": "9.0",
        "white": "92.4",
        "black": "2.7",
        "asian": "4.2",
        "hispanicOrLatino": "6.8"
      }
    },
    "wageData": {
      "2018": {
        "workers": "98",
        "median": "1,743",
        "maleWorkers": "91",
        "maleMedian": "1,837",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Air traffic controllers and airfield operations specialists": {
    "ageData": {
      "2018": {
        "total": "37",
        "16to19": "0",
        "20to24": "1",
        "25to34": "17",
        "35to44": "9",
        "45to54": "4",
        "55to64": "6",
        "65+": "0",
        "medianAge": "6"
      }
    },
    "title": "Air traffic controllers and airfield operations specialists",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "37",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "38",
        "median": "-",
        "maleWorkers": "35",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Flight attendants": {
    "ageData": {
      "2018": {
        "total": "104",
        "16to19": "0",
        "20to24": "4",
        "25to34": "16",
        "35to44": "19",
        "45to54": "31",
        "55to64": "25",
        "65+": "10",
        "medianAge": "25"
      }
    },
    "title": "Flight attendants",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "104",
        "women": "74.9",
        "white": "66.1",
        "black": "20.8",
        "asian": "10.6",
        "hispanicOrLatino": "9.7"
      }
    },
    "wageData": {
      "2018": {
        "workers": "74",
        "median": "878",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "53",
        "femaleMedian": "822"
      }
    }
  },
  "Ambulance drivers and attendants, except emergency medical technicians": {
    "ageData": {
      "2018": {
        "total": "12",
        "16to19": "0",
        "20to24": "0",
        "25to34": "7",
        "35to44": "1",
        "45to54": "3",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Ambulance drivers and attendants, except emergency medical technicians",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "12",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Bus drivers": {
    "ageData": {
      "2018": {
        "total": "599",
        "16to19": "1",
        "20to24": "9",
        "25to34": "65",
        "35to44": "89",
        "45to54": "161",
        "55to64": "175",
        "65+": "99",
        "medianAge": "175"
      }
    },
    "title": "Bus drivers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "599",
        "women": "43.8",
        "white": "64.8",
        "black": "29.4",
        "asian": "3.8",
        "hispanicOrLatino": "15.1"
      }
    },
    "wageData": {
      "2018": {
        "workers": "361",
        "median": "655",
        "maleWorkers": "216",
        "maleMedian": "735",
        "femaleWorkers": "144",
        "femaleMedian": "599"
      }
    }
  },
  "Driver/sales workers and truck drivers": {
    "ageData": {
      "2018": {
        "total": "3,549",
        "16to19": "64",
        "20to24": "236",
        "25to34": "636",
        "35to44": "701",
        "45to54": "871",
        "55to64": "764",
        "65+": "277",
        "medianAge": "764"
      }
    },
    "title": "Driver/sales workers and truck drivers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "3,549",
        "women": "6.6",
        "white": "77.0",
        "black": "16.3",
        "asian": "3.2",
        "hispanicOrLatino": "20.9"
      }
    },
    "wageData": {
      "2018": {
        "workers": "2,791",
        "median": "817",
        "maleWorkers": "2,636",
        "maleMedian": "829",
        "femaleWorkers": "155",
        "femaleMedian": "559"
      }
    }
  },
  "Taxi drivers and chauffeurs": {
    "ageData": {
      "2018": {
        "total": "777",
        "16to19": "6",
        "20to24": "38",
        "25to34": "168",
        "35to44": "151",
        "45to54": "175",
        "55to64": "148",
        "65+": "91",
        "medianAge": "148"
      }
    },
    "title": "Taxi drivers and chauffeurs",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "777",
        "women": "18.0",
        "white": "52.5",
        "black": "27.9",
        "asian": "14.9",
        "hispanicOrLatino": "18.3"
      }
    },
    "wageData": {
      "2018": {
        "workers": "399",
        "median": "611",
        "maleWorkers": "341",
        "maleMedian": "628",
        "femaleWorkers": "58",
        "femaleMedian": "450"
      }
    }
  },
  "Motor vehicle operators, all other": {
    "ageData": {
      "2018": {
        "total": "63",
        "16to19": "1",
        "20to24": "3",
        "25to34": "9",
        "35to44": "7",
        "45to54": "7",
        "55to64": "16",
        "65+": "19",
        "medianAge": "16"
      }
    },
    "title": "Motor vehicle operators, all other",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "63",
        "women": "18.1",
        "white": "75.7",
        "black": "20.8",
        "asian": "1.2",
        "hispanicOrLatino": "21.0"
      }
    },
    "wageData": {
      "2018": {
        "workers": "39",
        "median": "-",
        "maleWorkers": "34",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Locomotive engineers and operators": {
    "ageData": {
      "2018": {
        "total": "36",
        "16to19": "0",
        "20to24": "0",
        "25to34": "9",
        "35to44": "10",
        "45to54": "10",
        "55to64": "7",
        "65+": "1",
        "medianAge": "7"
      }
    },
    "title": "Locomotive engineers and operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "36",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "34",
        "median": "-",
        "maleWorkers": "30",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Railroad brake, signal, and switch operators": {
    "ageData": {
      "2018": {
        "total": "5",
        "16to19": "0",
        "20to24": "1",
        "25to34": "0",
        "35to44": "2",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Railroad brake, signal, and switch operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Railroad conductors and yardmasters": {
    "ageData": {
      "2018": {
        "total": "53",
        "16to19": "0",
        "20to24": "3",
        "25to34": "17",
        "35to44": "17",
        "45to54": "12",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Railroad conductors and yardmasters",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "53",
        "women": "7.1",
        "white": "76.1",
        "black": "15.0",
        "asian": "5.6",
        "hispanicOrLatino": "13.4"
      }
    },
    "wageData": {
      "2018": {
        "workers": "54",
        "median": "1,132",
        "maleWorkers": "50",
        "maleMedian": "1,143",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Subway, streetcar, and other rail transportation workers": {
    "ageData": {
      "2018": {
        "total": "15",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "4",
        "45to54": "5",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Subway, streetcar, and other rail transportation workers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "15",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "17",
        "median": "-",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Sailors and marine oilers": {
    "ageData": {
      "2018": {
        "total": "21",
        "16to19": "2",
        "20to24": "4",
        "25to34": "6",
        "35to44": "3",
        "45to54": "1",
        "55to64": "5",
        "65+": "0",
        "medianAge": "5"
      }
    },
    "title": "Sailors and marine oilers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "21",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "21",
        "median": "-",
        "maleWorkers": "20",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Ship and boat captains and operators": {
    "ageData": {
      "2018": {
        "total": "33",
        "16to19": "0",
        "20to24": "1",
        "25to34": "8",
        "35to44": "9",
        "45to54": "7",
        "55to64": "7",
        "65+": "1",
        "medianAge": "7"
      }
    },
    "title": "Ship and boat captains and operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "33",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "30",
        "median": "-",
        "maleWorkers": "29",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Ship engineers": {
    "ageData": {
      "2018": {
        "total": "6",
        "16to19": "0",
        "20to24": "1",
        "25to34": "3",
        "35to44": "0",
        "45to54": "2",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Ship engineers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "6",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Bridge and lock tenders": {
    "ageData": {
      "2018": {
        "total": "7",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "1",
        "45to54": "1",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Bridge and lock tenders",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Parking lot attendants": {
    "ageData": {
      "2018": {
        "total": "87",
        "16to19": "4",
        "20to24": "32",
        "25to34": "14",
        "35to44": "12",
        "45to54": "10",
        "55to64": "10",
        "65+": "5",
        "medianAge": "10"
      }
    },
    "title": "Parking lot attendants",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "87",
        "women": "9.7",
        "white": "74.3",
        "black": "17.1",
        "asian": "7.8",
        "hispanicOrLatino": "44.2"
      }
    },
    "wageData": {
      "2018": {
        "workers": "61",
        "median": "575",
        "maleWorkers": "54",
        "maleMedian": "575",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Automotive and watercraft service attendants": {
    "ageData": {
      "2018": {
        "total": "102",
        "16to19": "10",
        "20to24": "23",
        "25to34": "22",
        "35to44": "11",
        "45to54": "16",
        "55to64": "15",
        "65+": "4",
        "medianAge": "15"
      }
    },
    "title": "Automotive and watercraft service attendants",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "102",
        "women": "20.5",
        "white": "76.3",
        "black": "9.5",
        "asian": "7.7",
        "hispanicOrLatino": "20.8"
      }
    },
    "wageData": {
      "2018": {
        "workers": "70",
        "median": "570",
        "maleWorkers": "56",
        "maleMedian": "575",
        "femaleWorkers": "14",
        "femaleMedian": "-"
      }
    }
  },
  "Transportation inspectors": {
    "ageData": {
      "2018": {
        "total": "37",
        "16to19": "1",
        "20to24": "1",
        "25to34": "9",
        "35to44": "6",
        "45to54": "11",
        "55to64": "7",
        "65+": "1",
        "medianAge": "7"
      }
    },
    "title": "Transportation inspectors",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "37",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "37",
        "median": "-",
        "maleWorkers": "34",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Transportation attendants, except flight attendants": {
    "ageData": {
      "2018": {
        "total": "40",
        "16to19": "2",
        "20to24": "4",
        "25to34": "5",
        "35to44": "6",
        "45to54": "8",
        "55to64": "11",
        "65+": "4",
        "medianAge": "11"
      }
    },
    "title": "Transportation attendants, except flight attendants",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "40",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "19",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "13",
        "femaleMedian": "-"
      }
    }
  },
  "Other transportation workers": {
    "ageData": {
      "2018": {
        "total": "24",
        "16to19": "0",
        "20to24": "3",
        "25to34": "6",
        "35to44": "5",
        "45to54": "6",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Other transportation workers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "24",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "19",
        "median": "-",
        "maleWorkers": "18",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Conveyor operators and tenders": {
    "ageData": {
      "2018": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Conveyor operators and tenders",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Crane and tower operators": {
    "ageData": {
      "2018": {
        "total": "68",
        "16to19": "0",
        "20to24": "3",
        "25to34": "18",
        "35to44": "15",
        "45to54": "14",
        "55to64": "14",
        "65+": "2",
        "medianAge": "14"
      }
    },
    "title": "Crane and tower operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "68",
        "women": "6.0",
        "white": "82.2",
        "black": "12.3",
        "asian": "3.6",
        "hispanicOrLatino": "8.3"
      }
    },
    "wageData": {
      "2018": {
        "workers": "64",
        "median": "1,122",
        "maleWorkers": "60",
        "maleMedian": "1,079",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Dredge, excavating, and loading machine operators": {
    "ageData": {
      "2018": {
        "total": "24",
        "16to19": "0",
        "20to24": "2",
        "25to34": "4",
        "35to44": "6",
        "45to54": "7",
        "55to64": "4",
        "65+": "2",
        "medianAge": "4"
      }
    },
    "title": "Dredge, excavating, and loading machine operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "24",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "19",
        "median": "-",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Hoist and winch operators": {
    "ageData": {
      "2018": {
        "total": "10",
        "16to19": "2",
        "20to24": "0",
        "25to34": "1",
        "35to44": "5",
        "45to54": "0",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Hoist and winch operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "10",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Industrial truck and tractor operators": {
    "ageData": {
      "2018": {
        "total": "631",
        "16to19": "7",
        "20to24": "71",
        "25to34": "176",
        "35to44": "127",
        "45to54": "137",
        "55to64": "101",
        "65+": "13",
        "medianAge": "101"
      }
    },
    "title": "Industrial truck and tractor operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "631",
        "women": "8.1",
        "white": "71.2",
        "black": "21.5",
        "asian": "1.7",
        "hispanicOrLatino": "30.2"
      }
    },
    "wageData": {
      "2018": {
        "workers": "596",
        "median": "641",
        "maleWorkers": "549",
        "maleMedian": "641",
        "femaleWorkers": "48",
        "femaleMedian": "-"
      }
    }
  },
  "Cleaners of vehicles and equipment": {
    "ageData": {
      "2018": {
        "total": "329",
        "16to19": "41",
        "20to24": "54",
        "25to34": "78",
        "35to44": "59",
        "45to54": "47",
        "55to64": "38",
        "65+": "12",
        "medianAge": "38"
      }
    },
    "title": "Cleaners of vehicles and equipment",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "329",
        "women": "15.2",
        "white": "73.2",
        "black": "19.8",
        "asian": "2.0",
        "hispanicOrLatino": "32.9"
      }
    },
    "wageData": {
      "2018": {
        "workers": "232",
        "median": "538",
        "maleWorkers": "192",
        "maleMedian": "536",
        "femaleWorkers": "40",
        "femaleMedian": "-"
      }
    }
  },
  "Laborers and freight, stock, and material movers, hand": {
    "ageData": {
      "2018": {
        "total": "2,106",
        "16to19": "158",
        "20to24": "366",
        "25to34": "510",
        "35to44": "373",
        "45to54": "355",
        "55to64": "272",
        "65+": "73",
        "medianAge": "272"
      }
    },
    "title": "Laborers and freight, stock, and material movers, hand",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "2,106",
        "women": "21.4",
        "white": "74.0",
        "black": "18.9",
        "asian": "3.1",
        "hispanicOrLatino": "22.0"
      }
    },
    "wageData": {
      "2018": {
        "workers": "1,595",
        "median": "603",
        "maleWorkers": "1,277",
        "maleMedian": "620",
        "femaleWorkers": "318",
        "femaleMedian": "541"
      }
    }
  },
  "Machine feeders and offbearers": {
    "ageData": {
      "2018": {
        "total": "30",
        "16to19": "1",
        "20to24": "5",
        "25to34": "6",
        "35to44": "5",
        "45to54": "5",
        "55to64": "7",
        "65+": "0",
        "medianAge": "7"
      }
    },
    "title": "Machine feeders and offbearers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "30",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "25",
        "median": "-",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Packers and packagers, hand": {
    "ageData": {
      "2018": {
        "total": "607",
        "16to19": "57",
        "20to24": "92",
        "25to34": "131",
        "35to44": "103",
        "45to54": "113",
        "55to64": "85",
        "65+": "25",
        "medianAge": "85"
      }
    },
    "title": "Packers and packagers, hand",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "607",
        "women": "54.5",
        "white": "68.1",
        "black": "19.8",
        "asian": "7.9",
        "hispanicOrLatino": "42.6"
      }
    },
    "wageData": {
      "2018": {
        "workers": "490",
        "median": "495",
        "maleWorkers": "205",
        "maleMedian": "516",
        "femaleWorkers": "285",
        "femaleMedian": "484"
      }
    }
  },
  "Pumping station operators": {
    "ageData": {
      "2018": {
        "total": "16",
        "16to19": "0",
        "20to24": "0",
        "25to34": "5",
        "35to44": "4",
        "45to54": "2",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Pumping station operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "16",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "16",
        "median": "-",
        "maleWorkers": "16",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Refuse and recyclable material collectors": {
    "ageData": {
      "2018": {
        "total": "91",
        "16to19": "5",
        "20to24": "6",
        "25to34": "20",
        "35to44": "20",
        "45to54": "19",
        "55to64": "15",
        "65+": "7",
        "medianAge": "15"
      }
    },
    "title": "Refuse and recyclable material collectors",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "91",
        "women": "14.5",
        "white": "68.8",
        "black": "23.5",
        "asian": "2.8",
        "hispanicOrLatino": "28.6"
      }
    },
    "wageData": {
      "2018": {
        "workers": "63",
        "median": "524",
        "maleWorkers": "55",
        "maleMedian": "536",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Mine shuttle car operators": {
    "ageData": {
      "2018": {
        "total": "1",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Mine shuttle car operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "0",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Tank car, truck, and ship loaders": {
    "ageData": {
      "2018": {
        "total": "8",
        "16to19": "0",
        "20to24": "2",
        "25to34": "5",
        "35to44": "0",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Tank car, truck, and ship loaders",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "8",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2018": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Material moving workers, all other": {
    "ageData": {
      "2018": {
        "total": "51",
        "16to19": "0",
        "20to24": "8",
        "25to34": "7",
        "35to44": "11",
        "45to54": "12",
        "55to64": "10",
        "65+": "3",
        "medianAge": "10"
      }
    },
    "title": "Material moving workers, all other",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2018": {
        "total": "51",
        "women": "19.9",
        "white": "74.9",
        "black": "24.3",
        "asian": "0.1",
        "hispanicOrLatino": "25.7"
      }
    },
    "wageData": {
      "2018": {
        "workers": "41",
        "median": "-",
        "maleWorkers": "37",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  }
}