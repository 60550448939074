import React, { useRef } from 'react'

import { css, cx } from '@emotion/css'

import './insight.min.css'

import Select, { components } from 'react-select'

const Searcher = ({ setOccupation, occupations }) => {
  let options = Object.values(occupations).map(suggestion => {
    suggestion.value = suggestion.title
    suggestion.label = suggestion.title
    suggestion.isDisabled = suggestion.noData
    return suggestion
  })

  let keepers = useRef({
    lastFilteredOn: '',
    filtered: getKeepers(options, '')
  })

  return (
    <Select
      escapeClearsValue
      isClearable
      defaultValue={options[0]}
      styles={
        {
          option: () => ({}),
          singleValue: (original) => ({ ...original, color: 'white' }),
          input: (original) => ({ ...original, color: 'white' }),
          control: (original) => ({ ...original, color: 'white', backgroundColor: '#1d1f21' }),
          menu: () => ({ border: '1px solid #aaa', borderRadius: '5px', backgroundColor: '#1d1f21' })
        }
      }
      components={{
        Option
      }}
      filterOption={({ data }, string) => {
        if (keepers.current.lastFilteredOn !== string) {
          keepers.current.filtered = getKeepers(options, string)
          keepers.current.lastFilteredOn = string
        }
        return keepers.current.filtered[data.title]
      }}
      options={options}
      onChange={(option, { action }) => {
        if (action === 'select-option') {
          setOccupation(option)
        }
      }}
    />
  )
}

export default Searcher

const Option = ({ children, ...props }) => {
  const { onMouseMove, onMouseOver, ...rest } = props.innerProps
  const newProps = Object.assign(props, { innerProps: rest })

  const { data: option } = newProps

  let prefix = ''
  if (option.noData) {
    prefix = '[no data]'
  } else if (option.missingData) {
    prefix = '[incomplete data]'
  }

  let subtitle = []
  let subjobs = []

  if (option.subcategories) {
    subjobs.push(`${option.subcategories} subcategories`)
  }
  if (option.occupations) {
    subjobs.push(`${option.occupations} occupations`)
  }

  if (subjobs.length) {
    subtitle.push(subjobs.join(', '))
  }

  if (option.dominationAverage) {
    if (option.dominationAverage > 20) {
      subtitle.push(`male dominated (+${Math.round(option.dominationAverage)}%) (confidence: ${option.confidenceScore})`)
    } else if (option.dominationAverage > 0) {
      subtitle.push(`male leading (+${Math.round(option.dominationAverage)}%) (confidence: ${option.confidenceScore})`)
    } else if (option.dominationAverage < -20) {
      subtitle.push(`female dominated (+${-Math.round(option.dominationAverage)}%) (confidence: ${option.confidenceScore})`)
    } else if (option.dominationAverage < 0) {
      subtitle.push(`female leading (+${-Math.round(option.dominationAverage)}%) (confidence: ${option.confidenceScore})`)
    }
  }

  return (
    <components.Option
      {...newProps}
      className={cx(
        css`
          cursor: default;
          display: block;
          padding: 8px 12px;
          width: 100%;
          user-select: none;
          box-sizing: border-box;

          padding-left: ${option.depth * 20}px;

          ${newProps.isDisabled ? css`
            opacity: .5;
          ` : ''}

          ${newProps.isSelected ? css`
            background-color: #2684FF;
          ` : ''}

          ${newProps.isFocused ? css`
            background-color: rgba(255,255,255,.25);
          ` : ''}

          :hover {
            background-color: rgba(255,255,255,.25)
          }
        `,
        {
          option: true,
          'option--is-disabled': newProps.isDisabled,
          'option--is-focused': newProps.isFocused,
          'option--is-selected': newProps.isSelected
        }
      )}
    >
      {option.noData && (
        <div>
          <span className={css``}>{prefix} </span>
          <span className={css`text-decoration: line-through;`}>{option.title}</span>
        </div>
      )}
      {option.missingData && (
        <div>
          <span className={css``}>{prefix} </span>
          <span>{option.title}</span>
        </div>
      )}
      {(!option.noData && !option.missingData) && (
        <div>{option.title}</div>
      )}
      <div
        className={css`
        font-style: italic;
        font-size: 14px;
      `}
      >
        {subtitle.join(' | ')}
      </div>
    </components.Option>
  )
}

function getKeepers (options, value) {
  let keepers = {}
  function keepUp (occupation) {
    keepers[occupation.title] = true

    if (occupation.parent) {
      keepUp(occupation.parent)
    }
  }

  function keepDown (occupation) {
    keepers[occupation.title] = true

    let children = Object.values(occupation.children)
    if (children.length) {
      children.forEach(keepDown)
    }
  }

  options.forEach(occupation => {
    // if we've already marked this as good, don't do anything more
    if (keepers[occupation.title] === true) {
      return
    }

    // default to false
    keepers[occupation.title] = false

    if ((occupation.title.toLowerCase()).includes(value.toLowerCase())) {
      keepUp(occupation)
      // not sure we want to keep down; makes it harder to find specifics
      // keepDown(occupation)
    }
  })

  return keepers
}
