import React, { useRef, useState } from 'react'

import { Bar, getElementAtEvent } from 'react-chartjs-2'

import { Button, Card, Collapse, Flex, Input, Spin, Tag, Tooltip } from 'antd'
import { CloseOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons'

import { css } from '@emotion/css'
import { DiceRoller as DiceRollerFactory } from '@3d-dice/dice-roller-parser'

import { round } from '../../utilities/misc'
import { useChange } from '../../utilities/useChange'
import { useQueryState } from '../../utilities/useQueryState'
import { makeStateHook } from '../../utilities/stateHook'

import _ from 'lodash'

let roller = new DiceRollerFactory()

function evaluateRoll (successLevels, roll) {
  return successLevels.filter(config => {
    return config.condition.every(condition => {
      return eval(`${roll} ${condition}`)
    })
  })
}

function colorizeDataBySuccessLevels (successLevels, data) {
  let backgroundColor = {}

  data.forEach((entry) => {
    let matchingSuccessLevel = evaluateRoll(successLevels, entry.roll)
    if (matchingSuccessLevel.length > 2) {
      throw new Error('Cannot match more than two labels.')
    }
    backgroundColor[entry.roll] = matchingSuccessLevel[0]?.style?.fillStyle ?? 'grey'

    entry.successLevel = matchingSuccessLevel[0]

    matchingSuccessLevel[0].data[entry.roll] = entry.percent
  })

  successLevels.forEach(config => {
    config.percent = 0
    Object.values(config.data).forEach(value => {
      config.percent += value
    })
  })

  let lower = 0
  successLevels.forEach(config => {
    config.thisOrLower = lower + config.percent
    config.thisOrHigher = 100 - lower

    lower += config.percent
  })

  // A cute way of removing 'empty' elements.
  backgroundColor = Object.entries(backgroundColor)
    .sort((a, b) => Number(a[0]) - Number(b[0]))
    .map(([key, value]) => value)

  return [successLevels, backgroundColor]
}

const colors = [
  'crimson',
  'darkorange',
  'gold',
  'chartreuse',
  'dodgerblue'
]

function defaultLevelsConfig () {
  return {
    label: 'Roll',
    style: {
      fillStyle: 'dodgerblue'
    },
    condition: ['>= -Infinity'],
    data: {},
    percent: 0,
    thisOrHigher: 0,
    thisOrLower: 0
  }
}

// currently ignores middle levels w/o numbers
function parseSuccessLevelsString (successLevelsString) {
  // let thresholdRegex = /\s*(\d+(?:-(\d+))?)?\s*(.+)?/
  let thresholdRegex = /\s*(?:(\d+)(?:-(\d+))?)?\s*(.+)?/
  let sep = '__SEP__'

  return successLevelsString
    .replace(/,\s*(\d+)/g, `${sep}$1`).split(sep)
    .reduce((all, thresholdString, index, array) => {
      let [, lowerBound, upperBound, label] = thresholdString.match(thresholdRegex)

      let isLast = index === array.length - 1

      let parsedLowerBound = Number(lowerBound)
      let parsedUpperBound = Number(upperBound)

      let hasLowerBound = !Number.isNaN(parsedLowerBound)
      let hasUpperBound = !Number.isNaN(parsedUpperBound)

      let min = hasLowerBound ? parsedLowerBound : undefined
      let max = hasUpperBound ? parsedUpperBound : undefined

      let previousThreshold = all[all.length - 1]

      // To keep this simple, each time we look at a bound, we also check to see if we should "fix" the previous bound. This allows us to make sure we're not leaving any gaps. The only time we can't do that is at the very end, so we have to handle that as well.

      if (hasLowerBound) {
        let previousMaxShouldBe = min - 1

        if (!previousThreshold) {
          all.push({ threshold: undefined, min: undefined, max: previousMaxShouldBe, label: undefined })
        } else {
          // correct previous threshold
          if (previousThreshold.max < previousMaxShouldBe) {
            all.push({ threshold: previousThreshold.max + 1, min: previousThreshold.max + 1, max: previousMaxShouldBe, label: undefined })
          } else if (previousThreshold.max > previousMaxShouldBe) {
            // Time for an error!
          }
        }
      }

      all.push({ threshold: min, min, max, label })

      if (isLast && hasUpperBound) {
        all.push({ threshold: max + 1, min: max + 1, max: undefined, label: undefined })
      }

      return all
    }, [])
    .sort((a, b) => a.threshold - b.threshold)
}

function buildSuccessLevelsString (successLevels) {
  return successLevels.reduce((string, level, index) => {
    let threshold = [level.threshold, level.label].filter(item => item).join(' ')
    return string + (index !== 0 ? ', ' : '') + threshold
  }, '')
}

function generateCondition (index, allIncs) {
  let level = allIncs[index]
  let nextLevel = allIncs[index + 1]

  let isFirst = index === 0
  let isLast = !nextLevel

  if (isFirst && isLast) {
    let friendlyCondition = 'All values'
    let condition = ['∞']
    return { condition, friendlyCondition }
  } else if (isFirst) {
    let friendlyCondition = `${Number(nextLevel.threshold) - 1} or lower`
    let condition = [`< ${nextLevel.threshold}`]
    return { condition, friendlyCondition }
  } else if (isLast) {
    let friendlyCondition = `${level.threshold} or higher`
    let condition = [`>= ${level.threshold}`]
    return { condition, friendlyCondition }
  } else {
    let friendlyCondition = `${level.threshold} to ${Number(nextLevel.threshold) - 1}`
    let condition = [`>= ${level.threshold}`, `< ${nextLevel.threshold}`]
    return { condition, friendlyCondition }
  }
}

function generateSuccessLevelsFromString (successLevelsString) {
  let configs = []

  parseSuccessLevelsString(successLevelsString).forEach((level, index, allIncs) => {
    const { condition, friendlyCondition } = generateCondition(index, allIncs)

    configs.push({
      threshold: level.threshold,
      label: level.label || friendlyCondition,
      style: {
        fillStyle: colors[configs.length % colors.length]
      },
      condition,
      friendlyCondition,
      data: {},
      percent: 0,
      thisOrHigher: 0,
      thisOrLower: 0
    })
  })

  if (configs.length === 0) {
    configs.push(defaultLevelsConfig())
  }

  return configs
}

function getGraphConfig (levelsString = '', data) {
  let result = []
  try {
    let successLevels = generateSuccessLevelsFromString(levelsString)
    result = colorizeDataBySuccessLevels(successLevels, data)
  } catch (e) {
    let successLevels = [defaultLevelsConfig()]
    result = colorizeDataBySuccessLevels(successLevels, data)
  }
  return result
}

function generateRollData (rollString) {
  let internalData = {}
  let rolls = 200_000
  for (let count = rolls; count; count--) {
    let roll
    try {
      roll = roller.rollValue(rollString)
    } catch (e) {
      roll = 1
    }
    if (!internalData[roll]) internalData[roll] = 0
    internalData[roll]++
  }
  let lower = 0
  return Object.keys(internalData)
    .map(key => {
      return { roll: key, count: internalData[key], percent: (internalData[key] / rolls) * 100 }
    })
    .sort((a, b) => Number(a.roll) - Number(b.roll))
    .map(roll => {
      roll.thisOrLower = lower + roll.percent
      roll.thisOrHigher = 100 - lower

      lower += roll.percent

      return roll
    })
}

const setRollData = _.debounce((setRollData, setLoading, rollString) => {
  setRollData(generateRollData(rollString))
  setLoading(false)
}, 1000)

export const DiceRoller = () => {
  const [rollString, setRollString] = useQueryState('dice', '2d6')
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(() => generateRollData(rollString))

  const [successLevelsString, setSuccessLevelsString] = useQueryState('success-levels', 'Failure, 7 Partial success, 10 Complete success')

  const [successLevels, bgColorConfig] = getGraphConfig(successLevelsString, data)

  const inputCss = css`
    font-family: Arial;
    font-size: 20px;
    margin: 0;
    border-radius: 3px;
    padding: 3.5px 10px;
  `

  const chartRef = useRef()

  return (
    <div className={css`
    height: 100%;
    font-family: Arial; 
    font-weight: 100; 
    display: grid; 
    grid-template-columns: 3fr 5fr; 
    column-gap: 30px;
  `}
    >
      <Roller />
      <div>
        <div className={css`
          display: grid;
          grid-template-columns: 1fr 2fr;
          column-gap: 20px;
          margin-bottom: 20px;`}
        >
          <Flex className={css`gap: 5px;`}>
            <Input
              placeholder='d1' className={inputCss} value={rollString} onChange={({ target }) => {
                setLoading(true)
                setRollString(target.value)
                setRollData(setData, setLoading, target.value)
              }}
            />
            <Tooltip title={<>Check out the Roll20 Dice Specification doc to see what kinds of rolls are supported!<br /><br />(Opens in a new window.)</>}>
              <Button href='https://help.roll20.net/hc/en-us/articles/360037773133-Dice-Reference#DiceReference-Roll20DiceSpecification' icon={<QuestionCircleOutlined />} shape='circle' size='small' type='text' target='_blank' />
            </Tooltip>
          </Flex>
          <Button
            size='large'
            onClick={() => {
              performRoll({
                key: `${rollString}:${successLevelsString}`,
                diceRoll: rollString,
                rolls: [],
                successLevels
              })
            }}
          >Roll this
          </Button>
        </div>

        <Spin spinning={loading}>
          <Thresholds
            successLevels={successLevels}
            successLevelsString={successLevelsString}
            setSuccessLevelsString={setSuccessLevelsString}
            data={data}
          />

          <Bar
            ref={chartRef}
            data={{
              datasets: [{ data, backgroundColor: bgColorConfig }]
            }}
            options={{
              scales: {
                x: {
                  title: {
                    display: true,
                    text: 'Result rolled'
                  }
                },
                y: {
                  display: false
                }
              },
              parsing: {
                xAxisKey: 'roll',
                yAxisKey: 'percent'
              },
              plugins: {
              // legend: {
              //   labels: {
              //     generateLabels: (...args) => {
              //       return successLevels.map(config => {
              //         return { text: `${config.label} (~${Math.round(config.percent)}%)`, ...config.style }
              //       })
              //     }
              //   }
              // },
                legend: false,
                tooltip: {
                  callbacks: {
                    title (data) {
                      const { label, raw } = data[0]
                      return `Roll of ${label} (${raw.successLevel.label})`
                    },
                    label (data) {
                      const { raw } = data
                      return ` ${round(raw.percent, 2)}%`
                    },
                    // beforeBody (data) { return 'beforeBody' },
                    // beforeLabel () { return 'beforeLabel' },
                    // afterLabel () { return 'afterLabel' },
                    afterBody (data) {
                      return `\n${data[0].label} or higher: ${Math.round(data[0].raw.thisOrHigher)}%\n${data[0].label} or lower: ${Math.round(data[0].raw.thisOrLower)}%`
                    }
                  }
                }
              }
            }}
            onClick={(event) => {
              let clickedBar = getElementAtEvent(chartRef.current, event)[0]
              if (clickedBar) {
                setSuccessLevelsString(clickedBar.element.$context.raw.roll)
              }
            }}
          />
        </Spin>
      </div>
    </div>
  )
}

const [useRollerConfigs, setRollerConfigs] = makeStateHook({})

let id = 0
const Thresholds = ({ successLevels, successLevelsString, setSuccessLevelsString, data }) => {
  const [editAsText, setEditAsText] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [editState, setEditState] = useState(null)

  return (
    <>
      {/* <Divider orientation='left'>Thresholds</Divider> */}
      {isEditing ? (
        <>
          {editAsText ? (
            <>
              <Input.TextArea
                autoSize
                value={editState}
                onChange={event => {
                  setEditState(
                    event.target.value
                  )
                }}
              />
              <Card className={css`margin-top: 10px;`} size='small' title='Preview'>
                <RenderThresholds successLevels={getGraphConfig(editState.split(/\n/g).join(', '), data)[0]} />
              </Card>
            </>
          ) : (
            <>
              <div
                className={css`
                  display: grid;
                  grid-template-columns: auto 1fr 50px;
                  column-gap: 20px;
                  row-gap: 10px;
                  margin-bottom: 10px;
                `}
              >
                {editState.map((currentLevel, index, allSuccessLevels) => {
                  let nextLevel = allSuccessLevels[index + 1]
                  let isFirst = index === 0
                  let isLast = !nextLevel
                  return (
                    <React.Fragment key={currentLevel.id}>
                      <Input
                        value={currentLevel.label}
                        onChange={({ target }) => {
                          setEditState(editState => {
                            let newEditState = [...editState]
                            newEditState[index] = {
                              ...currentLevel,
                              label: target.value
                            }
                            return newEditState
                          })
                        }}
                      />
                      <div className={css` display: flex; column-gap: 20px;`}>
                        {isFirst || isLast ? (
                          <>
                            <div className={css`white-space: nowrap;`}>≤</div>
                            <Input
                              placeholder='0'
                              // There's some quirky behaviour inferring the thresholds if it's first or last.
                              value={isFirst && isLast ? '' : isFirst ? nextLevel.threshold - 1 : currentLevel.threshold}
                              onChange={({ target }) => {
                                setEditState(editState => {
                                  let newEditState = [...editState]
                                  newEditState[index + 1] = {
                                    ...nextLevel,
                                    threshold: Number(target.value) + 1
                                  }
                                  return newEditState
                                })
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <Input
                              placeholder='0'
                              value={currentLevel.threshold}
                              onChange={({ target }) => {
                                setEditState(editState => {
                                  let newEditState = [...editState]
                                  newEditState[index] = {
                                    ...currentLevel,
                                    threshold: Number(target.value)
                                  }
                                  return newEditState
                                })
                              }}
                            />
                            -
                            <Input
                              placeholder='0'
                              value={nextLevel.threshold - 1}
                              onChange={({ target }) => {
                                setEditState(editState => {
                                  let newEditState = [...editState]
                                  newEditState[index + 1] = {
                                    ...nextLevel,
                                    threshold: Number(target.value) + 1
                                  }
                                  return newEditState
                                })
                              }}
                            />
                          </>
                        )}
                      </div>

                      <Button
                        icon={<CloseOutlined />} shape='circle' onClick={() => {
                          setEditState(editState => {
                            let newEditState = editState.filter(level => level.id !== currentLevel.id)
                            return newEditState
                          })
                        }}
                      />
                    </React.Fragment>
                  )
                })}
              </div>

              <Button
                icon={<PlusOutlined />}
                onClick={() => {
                  setEditState(editState => {
                    return [
                      ...editState,
                      {
                        threshold: 0,
                        secondaryThreshold: undefined,
                        label: '',
                        id: id++
                      }
                    ]
                  })
                }}
              >
                Add threshold
              </Button>

              <Card className={css`margin-top: 10px;`} size='small' title='Preview'>
                <RenderThresholds successLevels={getGraphConfig(buildSuccessLevelsString(editState).split(/\n/g).join(', '), data)[0]} />
              </Card>
            </>
          )}
          <Flex
            gap='small'
            className={css`
              margin-top: 10px;
              display: flex;
              justify-content: center;
            `}
          >
            <Button onClick={() => {
              if (editAsText) {
                setSuccessLevelsString(editState.split(/\n/g).join(', '))
              } else {
                setSuccessLevelsString(buildSuccessLevelsString(editState))
              }
              setIsEditing(false)
            }}
            >
              Save thresholds
            </Button>
            <Button onClick={() => {
              setIsEditing(false)
            }}
            >
              Cancel changes
            </Button>
          </Flex>
        </>
      ) : (
        <Flex className={css`
          flex-direction: row;
          justify-content: center;
          align-items: center;
        `}
        >
          <RenderThresholds successLevels={successLevels} />
          <Flex
            className={css`
              margin-left: 20px;
              flex-direction: column;
              display: flex;
              justify-content: center;
              button {
                text-align: left;
                font-size: 12px !important;
                color: gray;
              }
            `}
          >
            <Button
              type='text' size='small'
              onClick={() => {
                setEditState(successLevels.map(level => ({
                  threshold: level.threshold,
                  label: level.label,
                  id: id++
                })))
                setEditAsText(false)
                setIsEditing(true)
              }}
            >Edit thresholds
            </Button>

            <Button
              type='text' size='small'
              onClick={() => {
                setEditState(successLevelsString.replace(/,\s+/g, '\n'))
                setEditAsText(true)
                setIsEditing(true)
              }}
            >Edit thresholds as text
            </Button>
          </Flex>
        </Flex>
      )}
    </>
  )
}

const RenderThresholds = ({ successLevels }) => {
  return (
    <div
      className={css`
        display: grid;
        grid-template-columns: max-content max-content max-content max-content;
        column-gap: 20px;
      `}
    >
      {/* <div style={{ fontWeight: 'bold' }} />
      <div style={{ fontWeight: 'bold' }} />
      <div style={{ fontWeight: 'bold' }} />
      <div style={{ fontWeight: 'bold' }}>chance</div>
      <div style={{ fontWeight: 'bold' }}>at least</div> */}
      {successLevels.map((level, index) => {
        return (
          <React.Fragment
            key={level.label}
          >
            <div
              className={css`
                background-color: ${level.style.fillStyle};
                border: 2px solid gray;
                margin: 2px;
                width: 40px;
              `}
            />
            <div>{level.label}</div>
            <div>{level.friendlyCondition}</div>
            <div>~{Math.round(level.percent)}%
              <Tooltip title={
                <div className={css`display: flex; flex-direction: column; gap: 20px;`}>
                  {index !== 0 && (
                    <div>
                      <b>If trying to roll high:</b>
                      <br />
                      ~{Math.round(level.thisOrHigher)}% chance to meet or beat {level.label}
                    </div>
                  )}
                  {index !== successLevels.length - 1 && (
                    <div>
                      <b>If trying to roll low:</b>
                      <br />
                      ~{Math.round(level.thisOrLower)}% chance to meet or beat {level.label}
                    </div>
                  )}
                </div>
              }
              >
                <Button href='https://help.roll20.net/hc/en-us/articles/360037773133-Dice-Reference#DiceReference-Roll20DiceSpecification' icon={<QuestionCircleOutlined />} shape='circle' size='small' type='text' target='_blank' />
              </Tooltip>
            </div>
          </React.Fragment>
        )
      })}
    </div>
  )
}

const Roller = () => {
  const [rollerConfigs] = useRollerConfigs()

  let rollersElements = Object.values(rollerConfigs).map(roller => <Panel key={roller.key} rollerConfig={roller} />)

  if (rollersElements.length === 0) {
    return "No dice rolls yet. Enter a dice roll (and optional success levels) and click 'Roll this'."
  } else {
    return (
      <div>
        {rollersElements}
      </div>
    )
  }
}

function performRoll (rollerConfig) {
  setRollerConfigs(rollerConfigs => {
    let newRollerConfigs = _.cloneDeep(rollerConfigs)
    let thisRollerConfig = newRollerConfigs[rollerConfig.key]

    if (!thisRollerConfig) {
      thisRollerConfig = rollerConfig
      newRollerConfigs[thisRollerConfig.key] = thisRollerConfig
    }

    thisRollerConfig.active = true

    let rollValue = roller.rollValue(thisRollerConfig.diceRoll)
    let matchedLevels = evaluateRoll(thisRollerConfig.successLevels, rollValue)

    thisRollerConfig.rolls.unshift({
      value: rollValue,
      levelText: matchedLevels[0].label,
      levelColor: matchedLevels[0].style.fillStyle,
      level: matchedLevels[0]
    })

    return newRollerConfigs
  })
}

const Panel = ({ rollerConfig }) => {
  return (
    <Collapse
      ghost
      activeKey={rollerConfig.active ? ['key'] : []}
      onChange={() => {
        setRollerConfigs(rollerConfigs => {
          let newRollerConfigs = { ...rollerConfigs }
          newRollerConfigs[rollerConfig.key].active = !newRollerConfigs[rollerConfig.key].active
          return newRollerConfigs
        })
      }}
    >
      <Collapse.Panel
        key='key'
        header={<b>{rollerConfig.diceRoll}</b>}
        extra={
          <>
            <Button
              onClick={event => {
                event.stopPropagation()
                performRoll(rollerConfig)
              }} type='prim' size='small'
            >Roll
            </Button>

            <Button
              onClick={event => {
                event.stopPropagation()
                setRollerConfigs(rollerConfigs => {
                  let newRollerConfigs = _.cloneDeep(rollerConfigs)
                  newRollerConfigs[rollerConfig.key].rolls = []
                  return newRollerConfigs
                })
              }} type='text' size='small'
            >Clear rolls
            </Button>

            <Button
              onClick={event => {
                event.stopPropagation()
                setRollerConfigs(rollerConfigs => {
                  let newRollerConfigs = _.cloneDeep(rollerConfigs)
                  delete newRollerConfigs[rollerConfig.key]
                  return newRollerConfigs
                })
              }} type='text' size='small' danger
            >Remove roller
            </Button>
          </>
        }
      >
        <div className={css`
          display: grid;
          grid-template-columns: 1fr;
          row-gap: 6px;
        `}
        >

          {rollerConfig.rolls.map((roll, index) => {
            return (
              <React.Fragment key={index}>
                <ResultTag value={roll.value} label={roll.levelText} color={roll.levelColor} />
              </React.Fragment>
            )
          })}
        </div>
      </Collapse.Panel>
    </Collapse>
  )
}

const ResultTag = ({ color, value, label }) => {
  return (
    <Tag
      color={color} className={css`
        border-radius: 20px;
        padding: 1px 11px 1px 1px;
        display: flex;
        width: max-content;
        font-size: 14px;
        align-items: center;
      `}
    >
      <div className={css`
        background: white;
        color: black;
        min-width: 26px;
        height: 26px;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        font-size: 13px;
        margin-right: 7px;
        font-weight: bold;
        font-size: 20px;
      `}
      >{value}
      </div>
      {label}
    </Tag>
  )
}
