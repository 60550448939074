import { css, cx } from '@emotion/css'
import React from 'react'

export const Label = ({ children, title, className }) => {
  return (
    <div className={cx(css`display: flex; flex-direction: column; flex: 1 1 auto;`, className)}>
      <span className={css`margin-left: 3px;`}>{title} &nbsp;</span>
      {children}
    </div>
  )
}
