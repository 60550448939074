{
  "Total": {
    "ageData": {
      "2019": {
        "total": "157,538",
        "16to19": "5,150",
        "20to24": "14,172",
        "25to34": "35,807",
        "35to44": "33,127",
        "45to54": "32,042",
        "55to64": "26,893",
        "65+": "10,347",
        "medianAge": "26,893"
      }
    },
    "title": "Total",
    "depth": 1,
    "parent": "",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "157,538",
        "women": "47.0",
        "white": "77.7",
        "black": "12.3",
        "asian": "6.5",
        "hispanicOrLatino": "17.6"
      }
    },
    "wageData": {
      "2019": {
        "workers": "117,584",
        "median": "$917",
        "maleWorkers": "65,007",
        "maleMedian": "$1,007",
        "femaleWorkers": "52,577",
        "femaleMedian": "$821"
      }
    }
  },
  "Management, professional, and related occupations": {
    "ageData": {
      "2019": {
        "total": "64,218",
        "16to19": "428",
        "20to24": "3,402",
        "25to34": "15,126",
        "35to44": "15,133",
        "45to54": "14,103",
        "55to64": "11,360",
        "65+": "4,665",
        "medianAge": "11,360"
      }
    },
    "title": "Management, professional, and related occupations",
    "depth": 1,
    "parent": "",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "64,218",
        "women": "51.8",
        "white": "79.0",
        "black": "9.6",
        "asian": "8.7",
        "hispanicOrLatino": "10.1"
      }
    },
    "wageData": {
      "2019": {
        "workers": "50,119",
        "median": "1,309",
        "maleWorkers": "24,260",
        "maleMedian": "1,539",
        "femaleWorkers": "25,859",
        "femaleMedian": "1,135"
      }
    }
  },
  "Management, business, and financial operations occupations": {
    "ageData": {
      "2019": {
        "total": "26,981",
        "16to19": "82",
        "20to24": "969",
        "25to34": "5,529",
        "35to44": "6,330",
        "45to54": "6,469",
        "55to64": "5,394",
        "65+": "2,208",
        "medianAge": "5,394"
      }
    },
    "title": "Management, business, and financial operations occupations",
    "depth": 2,
    "parent": "Management, professional, and related occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "26,981",
        "women": "44.1",
        "white": "82.1",
        "black": "8.4",
        "asian": "7.0",
        "hispanicOrLatino": "10.4"
      }
    },
    "wageData": {
      "2019": {
        "workers": "20,696",
        "median": "1,415",
        "maleWorkers": "11,111",
        "maleMedian": "1,598",
        "femaleWorkers": "9,585",
        "femaleMedian": "1,221"
      }
    }
  },
  "Management occupations": {
    "ageData": {
      "2019": {
        "total": "18,985",
        "16to19": "62",
        "20to24": "536",
        "25to34": "3,452",
        "35to44": "4,524",
        "45to54": "4,731",
        "55to64": "4,024",
        "65+": "1,655",
        "medianAge": "4,024"
      }
    },
    "title": "Management occupations",
    "depth": 3,
    "parent": "Management, business, and financial operations occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "18,985",
        "women": "40.0",
        "white": "83.6",
        "black": "7.8",
        "asian": "6.1",
        "hispanicOrLatino": "10.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "13,948",
        "median": "1,478",
        "maleWorkers": "8,002",
        "maleMedian": "1,659",
        "femaleWorkers": "5,946",
        "femaleMedian": "1,266"
      }
    }
  },
  "Chief executives": {
    "ageData": {
      "2019": {
        "total": "1,602",
        "16to19": "2",
        "20to24": "5",
        "25to34": "121",
        "35to44": "329",
        "45to54": "461",
        "55to64": "465",
        "65+": "219",
        "medianAge": "465"
      }
    },
    "title": "Chief executives",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "1,602",
        "women": "27.6",
        "white": "88.8",
        "black": "4.1",
        "asian": "5.8",
        "hispanicOrLatino": "6.2"
      }
    },
    "wageData": {
      "2019": {
        "workers": "1,113",
        "median": "2,380",
        "maleWorkers": "812",
        "maleMedian": "2,509",
        "femaleWorkers": "302",
        "femaleMedian": "2,019"
      }
    }
  },
  "General and operations managers": {
    "ageData": {
      "2019": {
        "total": "1,058",
        "16to19": "0",
        "20to24": "19",
        "25to34": "258",
        "35to44": "282",
        "45to54": "257",
        "55to64": "196",
        "65+": "46",
        "medianAge": "196"
      }
    },
    "title": "General and operations managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "1,058",
        "women": "30.6",
        "white": "86.4",
        "black": "6.6",
        "asian": "3.5",
        "hispanicOrLatino": "12.5"
      }
    },
    "wageData": {
      "2019": {
        "workers": "990",
        "median": "1,384",
        "maleWorkers": "678",
        "maleMedian": "1,476",
        "femaleWorkers": "312",
        "femaleMedian": "1,153"
      }
    }
  },
  "Legislators": {
    "ageData": {
      "2019": {
        "total": "19",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "2",
        "45to54": "1",
        "55to64": "5",
        "65+": "7",
        "medianAge": "5"
      }
    },
    "title": "Legislators",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "19",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "14",
        "median": "-",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Advertising and promotions managers": {
    "ageData": {
      "2019": {
        "total": "55",
        "16to19": "0",
        "20to24": "9",
        "25to34": "13",
        "35to44": "12",
        "45to54": "10",
        "55to64": "8",
        "65+": "3",
        "medianAge": "8"
      }
    },
    "title": "Advertising and promotions managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "55",
        "women": "56.9",
        "white": "89.5",
        "black": "0.7",
        "asian": "7.0",
        "hispanicOrLatino": "9.0"
      }
    },
    "wageData": {
      "2019": {
        "workers": "45",
        "median": "-",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "27",
        "femaleMedian": "-"
      }
    }
  },
  "Marketing and sales managers": {
    "ageData": {
      "2019": {
        "total": "1,184",
        "16to19": "4",
        "20to24": "69",
        "25to34": "283",
        "35to44": "313",
        "45to54": "289",
        "55to64": "180",
        "65+": "45",
        "medianAge": "180"
      }
    },
    "title": "Marketing and sales managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "1,184",
        "women": "48.5",
        "white": "86.1",
        "black": "6.1",
        "asian": "5.6",
        "hispanicOrLatino": "8.9"
      }
    },
    "wageData": {
      "2019": {
        "workers": "1,109",
        "median": "1,600",
        "maleWorkers": "591",
        "maleMedian": "1,895",
        "femaleWorkers": "518",
        "femaleMedian": "1,393"
      }
    }
  },
  "Public relations and fundraising managers": {
    "ageData": {
      "2019": {
        "total": "73",
        "16to19": "0",
        "20to24": "2",
        "25to34": "19",
        "35to44": "12",
        "45to54": "17",
        "55to64": "19",
        "65+": "4",
        "medianAge": "19"
      }
    },
    "title": "Public relations and fundraising managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "73",
        "women": "71.4",
        "white": "89.8",
        "black": "8.0",
        "asian": "0.4",
        "hispanicOrLatino": "1.4"
      }
    },
    "wageData": {
      "2019": {
        "workers": "72",
        "median": "1,416",
        "maleWorkers": "22",
        "maleMedian": "-",
        "femaleWorkers": "50",
        "femaleMedian": "1,399"
      }
    }
  },
  "Administrative services managers": {
    "ageData": {
      "2019": {
        "total": "184",
        "16to19": "1",
        "20to24": "3",
        "25to34": "19",
        "35to44": "41",
        "45to54": "46",
        "55to64": "56",
        "65+": "18",
        "medianAge": "56"
      }
    },
    "title": "Administrative services managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "184",
        "women": "42.0",
        "white": "83.9",
        "black": "10.5",
        "asian": "3.2",
        "hispanicOrLatino": "10.9"
      }
    },
    "wageData": {
      "2019": {
        "workers": "154",
        "median": "1,235",
        "maleWorkers": "93",
        "maleMedian": "1,277",
        "femaleWorkers": "61",
        "femaleMedian": "1,084"
      }
    }
  },
  "Computer and information systems managers": {
    "ageData": {
      "2019": {
        "total": "654",
        "16to19": "1",
        "20to24": "6",
        "25to34": "115",
        "35to44": "197",
        "45to54": "198",
        "55to64": "116",
        "65+": "23",
        "medianAge": "116"
      }
    },
    "title": "Computer and information systems managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "654",
        "women": "28.7",
        "white": "72.7",
        "black": "9.6",
        "asian": "15.8",
        "hispanicOrLatino": "4.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "629",
        "median": "1,984",
        "maleWorkers": "439",
        "maleMedian": "2,019",
        "femaleWorkers": "190",
        "femaleMedian": "1,892"
      }
    }
  },
  "Financial managers": {
    "ageData": {
      "2019": {
        "total": "1,194",
        "16to19": "1",
        "20to24": "30",
        "25to34": "264",
        "35to44": "332",
        "45to54": "299",
        "55to64": "210",
        "65+": "58",
        "medianAge": "210"
      }
    },
    "title": "Financial managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "1,194",
        "women": "53.5",
        "white": "80.2",
        "black": "8.5",
        "asian": "9.4",
        "hispanicOrLatino": "11.4"
      }
    },
    "wageData": {
      "2019": {
        "workers": "1,142",
        "median": "1,447",
        "maleWorkers": "523",
        "maleMedian": "1,897",
        "femaleWorkers": "619",
        "femaleMedian": "1,207"
      }
    }
  },
  "Compensation and benefits managers": {
    "ageData": {
      "2019": {
        "total": "24",
        "16to19": "0",
        "20to24": "2",
        "25to34": "1",
        "35to44": "6",
        "45to54": "10",
        "55to64": "3",
        "65+": "2",
        "medianAge": "3"
      }
    },
    "title": "Compensation and benefits managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "24",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "23",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "15",
        "femaleMedian": "-"
      }
    }
  },
  "Human resources managers": {
    "ageData": {
      "2019": {
        "total": "321",
        "16to19": "1",
        "20to24": "6",
        "25to34": "64",
        "35to44": "99",
        "45to54": "77",
        "55to64": "64",
        "65+": "11",
        "medianAge": "64"
      }
    },
    "title": "Human resources managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "321",
        "women": "74.7",
        "white": "77.8",
        "black": "11.1",
        "asian": "7.1",
        "hispanicOrLatino": "10.1"
      }
    },
    "wageData": {
      "2019": {
        "workers": "299",
        "median": "1,592",
        "maleWorkers": "69",
        "maleMedian": "1,829",
        "femaleWorkers": "230",
        "femaleMedian": "1,538"
      }
    }
  },
  "Training and development managers": {
    "ageData": {
      "2019": {
        "total": "46",
        "16to19": "2",
        "20to24": "1",
        "25to34": "9",
        "35to44": "17",
        "45to54": "6",
        "55to64": "9",
        "65+": "2",
        "medianAge": "9"
      }
    },
    "title": "Training and development managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "46",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "44",
        "median": "-",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "25",
        "femaleMedian": "-"
      }
    }
  },
  "Industrial production managers": {
    "ageData": {
      "2019": {
        "total": "274",
        "16to19": "0",
        "20to24": "3",
        "25to34": "51",
        "35to44": "77",
        "45to54": "68",
        "55to64": "65",
        "65+": "11",
        "medianAge": "65"
      }
    },
    "title": "Industrial production managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "274",
        "women": "22.4",
        "white": "87.2",
        "black": "5.4",
        "asian": "5.5",
        "hispanicOrLatino": "10.9"
      }
    },
    "wageData": {
      "2019": {
        "workers": "270",
        "median": "1,472",
        "maleWorkers": "208",
        "maleMedian": "1,469",
        "femaleWorkers": "62",
        "femaleMedian": "1,497"
      }
    }
  },
  "Purchasing managers": {
    "ageData": {
      "2019": {
        "total": "227",
        "16to19": "0",
        "20to24": "5",
        "25to34": "44",
        "35to44": "56",
        "45to54": "67",
        "55to64": "46",
        "65+": "9",
        "medianAge": "46"
      }
    },
    "title": "Purchasing managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "227",
        "women": "42.8",
        "white": "75.1",
        "black": "14.4",
        "asian": "5.9",
        "hispanicOrLatino": "10.0"
      }
    },
    "wageData": {
      "2019": {
        "workers": "214",
        "median": "1,539",
        "maleWorkers": "129",
        "maleMedian": "1,657",
        "femaleWorkers": "84",
        "femaleMedian": "1,390"
      }
    }
  },
  "Transportation, storage, and distribution managers": {
    "ageData": {
      "2019": {
        "total": "281",
        "16to19": "0",
        "20to24": "11",
        "25to34": "55",
        "35to44": "59",
        "45to54": "77",
        "55to64": "69",
        "65+": "10",
        "medianAge": "69"
      }
    },
    "title": "Transportation, storage, and distribution managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "281",
        "women": "17.3",
        "white": "85.1",
        "black": "9.6",
        "asian": "2.4",
        "hispanicOrLatino": "17.3"
      }
    },
    "wageData": {
      "2019": {
        "workers": "261",
        "median": "1,160",
        "maleWorkers": "213",
        "maleMedian": "1,238",
        "femaleWorkers": "49",
        "femaleMedian": "-"
      }
    }
  },
  "Farmers, ranchers, and other agricultural managers": {
    "ageData": {
      "2019": {
        "total": "962",
        "16to19": "3",
        "20to24": "23",
        "25to34": "114",
        "35to44": "132",
        "45to54": "145",
        "55to64": "251",
        "65+": "296",
        "medianAge": "251"
      }
    },
    "title": "Farmers, ranchers, and other agricultural managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "962",
        "women": "24.5",
        "white": "96.6",
        "black": "0.9",
        "asian": "1.0",
        "hispanicOrLatino": "5.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "114",
        "median": "987",
        "maleWorkers": "94",
        "maleMedian": "1,162",
        "femaleWorkers": "20",
        "femaleMedian": "-"
      }
    }
  },
  "Construction managers": {
    "ageData": {
      "2019": {
        "total": "994",
        "16to19": "1",
        "20to24": "27",
        "25to34": "184",
        "35to44": "259",
        "45to54": "233",
        "55to64": "214",
        "65+": "77",
        "medianAge": "214"
      }
    },
    "title": "Construction managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "994",
        "women": "10.0",
        "white": "90.9",
        "black": "3.5",
        "asian": "2.5",
        "hispanicOrLatino": "13.9"
      }
    },
    "wageData": {
      "2019": {
        "workers": "610",
        "median": "1,468",
        "maleWorkers": "539",
        "maleMedian": "1,511",
        "femaleWorkers": "71",
        "femaleMedian": "1,210"
      }
    }
  },
  "Education administrators": {
    "ageData": {
      "2019": {
        "total": "958",
        "16to19": "4",
        "20to24": "21",
        "25to34": "140",
        "35to44": "255",
        "45to54": "307",
        "55to64": "178",
        "65+": "52",
        "medianAge": "178"
      }
    },
    "title": "Education administrators",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "958",
        "women": "67.7",
        "white": "78.6",
        "black": "15.3",
        "asian": "3.3",
        "hispanicOrLatino": "11.8"
      }
    },
    "wageData": {
      "2019": {
        "workers": "811",
        "median": "1,371",
        "maleWorkers": "276",
        "maleMedian": "1,467",
        "femaleWorkers": "536",
        "femaleMedian": "1,335"
      }
    }
  },
  "Architectural and engineering managers": {
    "ageData": {
      "2019": {
        "total": "154",
        "16to19": "0",
        "20to24": "1",
        "25to34": "21",
        "35to44": "41",
        "45to54": "45",
        "55to64": "41",
        "65+": "5",
        "medianAge": "41"
      }
    },
    "title": "Architectural and engineering managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "154",
        "women": "11.7",
        "white": "81.5",
        "black": "6.0",
        "asian": "11.8",
        "hispanicOrLatino": "6.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "148",
        "median": "2,471",
        "maleWorkers": "128",
        "maleMedian": "2,457",
        "femaleWorkers": "20",
        "femaleMedian": "-"
      }
    }
  },
  "Food service managers": {
    "ageData": {
      "2019": {
        "total": "1,249",
        "16to19": "24",
        "20to24": "131",
        "25to34": "309",
        "35to44": "283",
        "45to54": "264",
        "55to64": "176",
        "65+": "61",
        "medianAge": "176"
      }
    },
    "title": "Food service managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "1,249",
        "women": "46.5",
        "white": "76.3",
        "black": "9.8",
        "asian": "9.6",
        "hispanicOrLatino": "19.8"
      }
    },
    "wageData": {
      "2019": {
        "workers": "785",
        "median": "863",
        "maleWorkers": "420",
        "maleMedian": "964",
        "femaleWorkers": "365",
        "femaleMedian": "719"
      }
    }
  },
  "Funeral service managers": {
    "ageData": {
      "2019": {
        "total": "13",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "2",
        "45to54": "1",
        "55to64": "6",
        "65+": "4",
        "medianAge": "6"
      }
    },
    "title": "Funeral service managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "13",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Gaming managers": {
    "ageData": {
      "2019": {
        "total": "19",
        "16to19": "0",
        "20to24": "2",
        "25to34": "1",
        "35to44": "4",
        "45to54": "6",
        "55to64": "3",
        "65+": "2",
        "medianAge": "3"
      }
    },
    "title": "Gaming managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "19",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "18",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "9",
        "femaleMedian": "-"
      }
    }
  },
  "Lodging managers": {
    "ageData": {
      "2019": {
        "total": "162",
        "16to19": "1",
        "20to24": "8",
        "25to34": "37",
        "35to44": "36",
        "45to54": "32",
        "55to64": "31",
        "65+": "18",
        "medianAge": "31"
      }
    },
    "title": "Lodging managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "162",
        "women": "49.5",
        "white": "80.9",
        "black": "7.0",
        "asian": "9.3",
        "hispanicOrLatino": "14.6"
      }
    },
    "wageData": {
      "2019": {
        "workers": "116",
        "median": "975",
        "maleWorkers": "61",
        "maleMedian": "1,096",
        "femaleWorkers": "55",
        "femaleMedian": "894"
      }
    }
  },
  "Medical and health services managers": {
    "ageData": {
      "2019": {
        "total": "677",
        "16to19": "1",
        "20to24": "8",
        "25to34": "135",
        "35to44": "177",
        "45to54": "164",
        "55to64": "142",
        "65+": "50",
        "medianAge": "142"
      }
    },
    "title": "Medical and health services managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "677",
        "women": "69.7",
        "white": "79.0",
        "black": "12.7",
        "asian": "5.7",
        "hispanicOrLatino": "11.8"
      }
    },
    "wageData": {
      "2019": {
        "workers": "578",
        "median": "1,478",
        "maleWorkers": "169",
        "maleMedian": "1,828",
        "femaleWorkers": "409",
        "femaleMedian": "1,362"
      }
    }
  },
  "Natural sciences managers": {
    "ageData": {
      "2019": {
        "total": "15",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "3",
        "45to54": "8",
        "55to64": "0",
        "65+": "1",
        "medianAge": "0"
      }
    },
    "title": "Natural sciences managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "15",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Postmasters and mail superintendents": {
    "ageData": {
      "2019": {
        "total": "30",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "7",
        "45to54": "10",
        "55to64": "9",
        "65+": "2",
        "medianAge": "9"
      }
    },
    "title": "Postmasters and mail superintendents",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "30",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "28",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "14",
        "femaleMedian": "-"
      }
    }
  },
  "Property, real estate, and community association managers": {
    "ageData": {
      "2019": {
        "total": "780",
        "16to19": "1",
        "20to24": "21",
        "25to34": "152",
        "35to44": "140",
        "45to54": "162",
        "55to64": "169",
        "65+": "134",
        "medianAge": "169"
      }
    },
    "title": "Property, real estate, and community association managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "780",
        "women": "47.3",
        "white": "83.6",
        "black": "8.9",
        "asian": "4.5",
        "hispanicOrLatino": "12.8"
      }
    },
    "wageData": {
      "2019": {
        "workers": "438",
        "median": "954",
        "maleWorkers": "214",
        "maleMedian": "1,168",
        "femaleWorkers": "224",
        "femaleMedian": "849"
      }
    }
  },
  "Social and community service managers": {
    "ageData": {
      "2019": {
        "total": "470",
        "16to19": "3",
        "20to24": "17",
        "25to34": "96",
        "35to44": "109",
        "45to54": "107",
        "55to64": "97",
        "65+": "41",
        "medianAge": "97"
      }
    },
    "title": "Social and community service managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "470",
        "women": "68.2",
        "white": "79.9",
        "black": "12.3",
        "asian": "3.2",
        "hispanicOrLatino": "10.6"
      }
    },
    "wageData": {
      "2019": {
        "workers": "403",
        "median": "1,243",
        "maleWorkers": "143",
        "maleMedian": "1,452",
        "femaleWorkers": "260",
        "femaleMedian": "1,156"
      }
    }
  },
  "Emergency management directors": {
    "ageData": {
      "2019": {
        "total": "7",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "0",
        "45to54": "4",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Emergency management directors",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "9",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Managers, all other": {
    "ageData": {
      "2019": {
        "total": "5,299",
        "16to19": "12",
        "20to24": "105",
        "25to34": "939",
        "35to44": "1,243",
        "45to54": "1,360",
        "55to64": "1,196",
        "65+": "444",
        "medianAge": "1,196"
      }
    },
    "title": "Managers, all other",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "5,299",
        "women": "36.0",
        "white": "83.4",
        "black": "7.5",
        "asian": "6.8",
        "hispanicOrLatino": "10.3"
      }
    },
    "wageData": {
      "2019": {
        "workers": "3,497",
        "median": "1,533",
        "maleWorkers": "2,088",
        "maleMedian": "1,725",
        "femaleWorkers": "1,409",
        "femaleMedian": "1,317"
      }
    }
  },
  "Business and financial operations occupations": {
    "ageData": {
      "2019": {
        "total": "7,996",
        "16to19": "19",
        "20to24": "433",
        "25to34": "2,076",
        "35to44": "1,806",
        "45to54": "1,738",
        "55to64": "1,370",
        "65+": "554",
        "medianAge": "1,370"
      }
    },
    "title": "Business and financial operations occupations",
    "depth": 3,
    "parent": "Management, business, and financial operations occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "7,996",
        "women": "54.0",
        "white": "78.4",
        "black": "9.9",
        "asian": "9.0",
        "hispanicOrLatino": "9.5"
      }
    },
    "wageData": {
      "2019": {
        "workers": "6,748",
        "median": "1,285",
        "maleWorkers": "3,109",
        "maleMedian": "1,468",
        "femaleWorkers": "3,639",
        "femaleMedian": "1,148"
      }
    }
  },
  "Agents and business managers of artists, performers, and athletes": {
    "ageData": {
      "2019": {
        "total": "47",
        "16to19": "1",
        "20to24": "3",
        "25to34": "11",
        "35to44": "12",
        "45to54": "10",
        "55to64": "3",
        "65+": "7",
        "medianAge": "3"
      }
    },
    "title": "Agents and business managers of artists, performers, and athletes",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "47",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "28",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "15",
        "femaleMedian": "-"
      }
    }
  },
  "Buyers and purchasing agents, farm products": {
    "ageData": {
      "2019": {
        "total": "16",
        "16to19": "0",
        "20to24": "1",
        "25to34": "4",
        "35to44": "6",
        "45to54": "1",
        "55to64": "1",
        "65+": "3",
        "medianAge": "1"
      }
    },
    "title": "Buyers and purchasing agents, farm products",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "16",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Wholesale and retail buyers, except farm products": {
    "ageData": {
      "2019": {
        "total": "226",
        "16to19": "1",
        "20to24": "20",
        "25to34": "56",
        "35to44": "41",
        "45to54": "38",
        "55to64": "50",
        "65+": "20",
        "medianAge": "50"
      }
    },
    "title": "Wholesale and retail buyers, except farm products",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "226",
        "women": "47.8",
        "white": "87.0",
        "black": "6.9",
        "asian": "3.5",
        "hispanicOrLatino": "14.6"
      }
    },
    "wageData": {
      "2019": {
        "workers": "162",
        "median": "979",
        "maleWorkers": "87",
        "maleMedian": "982",
        "femaleWorkers": "76",
        "femaleMedian": "977"
      }
    }
  },
  "Purchasing agents, except wholesale, retail, and farm products": {
    "ageData": {
      "2019": {
        "total": "279",
        "16to19": "0",
        "20to24": "16",
        "25to34": "57",
        "35to44": "56",
        "45to54": "73",
        "55to64": "53",
        "65+": "23",
        "medianAge": "53"
      }
    },
    "title": "Purchasing agents, except wholesale, retail, and farm products",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "279",
        "women": "48.5",
        "white": "82.5",
        "black": "9.9",
        "asian": "4.2",
        "hispanicOrLatino": "10.3"
      }
    },
    "wageData": {
      "2019": {
        "workers": "248",
        "median": "1,170",
        "maleWorkers": "134",
        "maleMedian": "1,202",
        "femaleWorkers": "113",
        "femaleMedian": "1,081"
      }
    }
  },
  "Claims adjusters, appraisers, examiners, and investigators": {
    "ageData": {
      "2019": {
        "total": "318",
        "16to19": "0",
        "20to24": "11",
        "25to34": "64",
        "35to44": "81",
        "45to54": "88",
        "55to64": "58",
        "65+": "16",
        "medianAge": "58"
      }
    },
    "title": "Claims adjusters, appraisers, examiners, and investigators",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "318",
        "women": "62.1",
        "white": "73.6",
        "black": "19.5",
        "asian": "4.4",
        "hispanicOrLatino": "9.5"
      }
    },
    "wageData": {
      "2019": {
        "workers": "299",
        "median": "1,103",
        "maleWorkers": "104",
        "maleMedian": "1,277",
        "femaleWorkers": "195",
        "femaleMedian": "972"
      }
    }
  },
  "Compliance officers": {
    "ageData": {
      "2019": {
        "total": "298",
        "16to19": "0",
        "20to24": "10",
        "25to34": "56",
        "35to44": "84",
        "45to54": "85",
        "55to64": "50",
        "65+": "13",
        "medianAge": "50"
      }
    },
    "title": "Compliance officers",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "298",
        "women": "59.5",
        "white": "80.7",
        "black": "10.2",
        "asian": "6.1",
        "hispanicOrLatino": "10.5"
      }
    },
    "wageData": {
      "2019": {
        "workers": "271",
        "median": "1,346",
        "maleWorkers": "105",
        "maleMedian": "1,561",
        "femaleWorkers": "165",
        "femaleMedian": "1,264"
      }
    }
  },
  "Cost estimators": {
    "ageData": {
      "2019": {
        "total": "127",
        "16to19": "0",
        "20to24": "2",
        "25to34": "27",
        "35to44": "25",
        "45to54": "26",
        "55to64": "31",
        "65+": "15",
        "medianAge": "31"
      }
    },
    "title": "Cost estimators",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "127",
        "women": "14.3",
        "white": "90.0",
        "black": "3.1",
        "asian": "5.8",
        "hispanicOrLatino": "8.1"
      }
    },
    "wageData": {
      "2019": {
        "workers": "113",
        "median": "1,254",
        "maleWorkers": "100",
        "maleMedian": "1,264",
        "femaleWorkers": "13",
        "femaleMedian": "-"
      }
    }
  },
  "Human resources workers": {
    "ageData": {
      "2019": {
        "total": "770",
        "16to19": "2",
        "20to24": "43",
        "25to34": "235",
        "35to44": "195",
        "45to54": "155",
        "55to64": "105",
        "65+": "35",
        "medianAge": "105"
      }
    },
    "title": "Human resources workers",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "770",
        "women": "73.5",
        "white": "75.7",
        "black": "13.8",
        "asian": "7.1",
        "hispanicOrLatino": "13.8"
      }
    },
    "wageData": {
      "2019": {
        "workers": "680",
        "median": "1,203",
        "maleWorkers": "186",
        "maleMedian": "1,325",
        "femaleWorkers": "494",
        "femaleMedian": "1,149"
      }
    }
  },
  "Compensation, benefits, and job analysis specialists": {
    "ageData": {
      "2019": {
        "total": "51",
        "16to19": "1",
        "20to24": "2",
        "25to34": "10",
        "35to44": "11",
        "45to54": "15",
        "55to64": "8",
        "65+": "4",
        "medianAge": "8"
      }
    },
    "title": "Compensation, benefits, and job analysis specialists",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "51",
        "women": "75.0",
        "white": "76.0",
        "black": "19.0",
        "asian": "3.7",
        "hispanicOrLatino": "6.0"
      }
    },
    "wageData": {
      "2019": {
        "workers": "46",
        "median": "-",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "32",
        "femaleMedian": "-"
      }
    }
  },
  "Training and development specialists": {
    "ageData": {
      "2019": {
        "total": "125",
        "16to19": "2",
        "20to24": "2",
        "25to34": "26",
        "35to44": "29",
        "45to54": "28",
        "55to64": "30",
        "65+": "8",
        "medianAge": "30"
      }
    },
    "title": "Training and development specialists",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "125",
        "women": "52.5",
        "white": "77.3",
        "black": "15.4",
        "asian": "4.0",
        "hispanicOrLatino": "10.6"
      }
    },
    "wageData": {
      "2019": {
        "workers": "120",
        "median": "1,127",
        "maleWorkers": "51",
        "maleMedian": "1,249",
        "femaleWorkers": "68",
        "femaleMedian": "968"
      }
    }
  },
  "Logisticians": {
    "ageData": {
      "2019": {
        "total": "154",
        "16to19": "0",
        "20to24": "12",
        "25to34": "45",
        "35to44": "33",
        "45to54": "41",
        "55to64": "20",
        "65+": "3",
        "medianAge": "20"
      }
    },
    "title": "Logisticians",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "154",
        "women": "31.7",
        "white": "77.1",
        "black": "15.5",
        "asian": "2.7",
        "hispanicOrLatino": "8.2"
      }
    },
    "wageData": {
      "2019": {
        "workers": "152",
        "median": "1,093",
        "maleWorkers": "107",
        "maleMedian": "1,149",
        "femaleWorkers": "45",
        "femaleMedian": "-"
      }
    }
  },
  "Management analysts": {
    "ageData": {
      "2019": {
        "total": "950",
        "16to19": "1",
        "20to24": "31",
        "25to34": "241",
        "35to44": "191",
        "45to54": "203",
        "55to64": "181",
        "65+": "102",
        "medianAge": "181"
      }
    },
    "title": "Management analysts",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "950",
        "women": "42.1",
        "white": "76.1",
        "black": "7.5",
        "asian": "13.7",
        "hispanicOrLatino": "8.6"
      }
    },
    "wageData": {
      "2019": {
        "workers": "692",
        "median": "1,622",
        "maleWorkers": "389",
        "maleMedian": "1,870",
        "femaleWorkers": "303",
        "femaleMedian": "1,517"
      }
    }
  },
  "Meeting, convention, and event planners": {
    "ageData": {
      "2019": {
        "total": "146",
        "16to19": "1",
        "20to24": "22",
        "25to34": "47",
        "35to44": "27",
        "45to54": "28",
        "55to64": "15",
        "65+": "6",
        "medianAge": "15"
      }
    },
    "title": "Meeting, convention, and event planners",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "146",
        "women": "78.7",
        "white": "82.0",
        "black": "8.8",
        "asian": "7.4",
        "hispanicOrLatino": "11.9"
      }
    },
    "wageData": {
      "2019": {
        "workers": "116",
        "median": "1,023",
        "maleWorkers": "28",
        "maleMedian": "-",
        "femaleWorkers": "89",
        "femaleMedian": "1,018"
      }
    }
  },
  "Fundraisers": {
    "ageData": {
      "2019": {
        "total": "93",
        "16to19": "0",
        "20to24": "5",
        "25to34": "29",
        "35to44": "23",
        "45to54": "17",
        "55to64": "11",
        "65+": "8",
        "medianAge": "11"
      }
    },
    "title": "Fundraisers",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "93",
        "women": "67.2",
        "white": "89.3",
        "black": "5.1",
        "asian": "2.5",
        "hispanicOrLatino": "5.2"
      }
    },
    "wageData": {
      "2019": {
        "workers": "85",
        "median": "1,330",
        "maleWorkers": "33",
        "maleMedian": "-",
        "femaleWorkers": "52",
        "femaleMedian": "1,229"
      }
    }
  },
  "Market research analysts and marketing specialists": {
    "ageData": {
      "2019": {
        "total": "369",
        "16to19": "1",
        "20to24": "41",
        "25to34": "128",
        "35to44": "86",
        "45to54": "68",
        "55to64": "32",
        "65+": "12",
        "medianAge": "32"
      }
    },
    "title": "Market research analysts and marketing specialists",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "369",
        "women": "56.6",
        "white": "80.6",
        "black": "5.9",
        "asian": "11.4",
        "hispanicOrLatino": "7.2"
      }
    },
    "wageData": {
      "2019": {
        "workers": "281",
        "median": "1,363",
        "maleWorkers": "130",
        "maleMedian": "1,688",
        "femaleWorkers": "152",
        "femaleMedian": "1,228"
      }
    }
  },
  "Business operations specialists, all other": {
    "ageData": {
      "2019": {
        "total": "269",
        "16to19": "1",
        "20to24": "19",
        "25to34": "69",
        "35to44": "62",
        "45to54": "49",
        "55to64": "55",
        "65+": "14",
        "medianAge": "55"
      }
    },
    "title": "Business operations specialists, all other",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "269",
        "women": "57.7",
        "white": "75.8",
        "black": "10.3",
        "asian": "10.9",
        "hispanicOrLatino": "10.5"
      }
    },
    "wageData": {
      "2019": {
        "workers": "248",
        "median": "1,283",
        "maleWorkers": "100",
        "maleMedian": "1,540",
        "femaleWorkers": "148",
        "femaleMedian": "1,193"
      }
    }
  },
  "Accountants and auditors": {
    "ageData": {
      "2019": {
        "total": "1,964",
        "16to19": "5",
        "20to24": "91",
        "25to34": "503",
        "35to44": "413",
        "45to54": "429",
        "55to64": "364",
        "65+": "160",
        "medianAge": "364"
      }
    },
    "title": "Accountants and auditors",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "1,964",
        "women": "61.7",
        "white": "77.1",
        "black": "8.5",
        "asian": "12.0",
        "hispanicOrLatino": "8.9"
      }
    },
    "wageData": {
      "2019": {
        "workers": "1,683",
        "median": "1,221",
        "maleWorkers": "670",
        "maleMedian": "1,419",
        "femaleWorkers": "1,013",
        "femaleMedian": "1,141"
      }
    }
  },
  "Appraisers and assessors of real estate": {
    "ageData": {
      "2019": {
        "total": "84",
        "16to19": "1",
        "20to24": "0",
        "25to34": "9",
        "35to44": "18",
        "45to54": "18",
        "55to64": "28",
        "65+": "10",
        "medianAge": "28"
      }
    },
    "title": "Appraisers and assessors of real estate",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "84",
        "women": "42.0",
        "white": "93.2",
        "black": "2.7",
        "asian": "4.1",
        "hispanicOrLatino": "8.3"
      }
    },
    "wageData": {
      "2019": {
        "workers": "46",
        "median": "-",
        "maleWorkers": "24",
        "maleMedian": "-",
        "femaleWorkers": "23",
        "femaleMedian": "-"
      }
    }
  },
  "Budget analysts": {
    "ageData": {
      "2019": {
        "total": "51",
        "16to19": "0",
        "20to24": "1",
        "25to34": "19",
        "35to44": "10",
        "45to54": "11",
        "55to64": "9",
        "65+": "1",
        "medianAge": "9"
      }
    },
    "title": "Budget analysts",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "51",
        "women": "60.4",
        "white": "64.6",
        "black": "19.3",
        "asian": "12.2",
        "hispanicOrLatino": "3.6"
      }
    },
    "wageData": {
      "2019": {
        "workers": "40",
        "median": "-",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "21",
        "femaleMedian": "-"
      }
    }
  },
  "Credit analysts": {
    "ageData": {
      "2019": {
        "total": "28",
        "16to19": "0",
        "20to24": "5",
        "25to34": "8",
        "35to44": "8",
        "45to54": "4",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Credit analysts",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "28",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "26",
        "median": "-",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "18",
        "femaleMedian": "-"
      }
    }
  },
  "Financial analysts": {
    "ageData": {
      "2019": {
        "total": "345",
        "16to19": "1",
        "20to24": "27",
        "25to34": "119",
        "35to44": "94",
        "45to54": "67",
        "55to64": "29",
        "65+": "7",
        "medianAge": "29"
      }
    },
    "title": "Financial analysts",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "345",
        "women": "34.2",
        "white": "77.8",
        "black": "8.6",
        "asian": "11.7",
        "hispanicOrLatino": "8.3"
      }
    },
    "wageData": {
      "2019": {
        "workers": "307",
        "median": "1,613",
        "maleWorkers": "203",
        "maleMedian": "1,763",
        "femaleWorkers": "104",
        "femaleMedian": "1,386"
      }
    }
  },
  "Personal financial advisors": {
    "ageData": {
      "2019": {
        "total": "551",
        "16to19": "0",
        "20to24": "31",
        "25to34": "140",
        "35to44": "133",
        "45to54": "112",
        "55to64": "93",
        "65+": "41",
        "medianAge": "93"
      }
    },
    "title": "Personal financial advisors",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "551",
        "women": "32.1",
        "white": "82.2",
        "black": "6.9",
        "asian": "8.6",
        "hispanicOrLatino": "6.3"
      }
    },
    "wageData": {
      "2019": {
        "workers": "448",
        "median": "1,618",
        "maleWorkers": "296",
        "maleMedian": "1,905",
        "femaleWorkers": "152",
        "femaleMedian": "1,232"
      }
    }
  },
  "Insurance underwriters": {
    "ageData": {
      "2019": {
        "total": "105",
        "16to19": "1",
        "20to24": "8",
        "25to34": "23",
        "35to44": "21",
        "45to54": "31",
        "55to64": "18",
        "65+": "3",
        "medianAge": "18"
      }
    },
    "title": "Insurance underwriters",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "105",
        "women": "51.1",
        "white": "87.0",
        "black": "5.9",
        "asian": "5.4",
        "hispanicOrLatino": "6.2"
      }
    },
    "wageData": {
      "2019": {
        "workers": "116",
        "median": "1,169",
        "maleWorkers": "57",
        "maleMedian": "1,338",
        "femaleWorkers": "59",
        "femaleMedian": "1,142"
      }
    }
  },
  "Financial examiners": {
    "ageData": {
      "2019": {
        "total": "21",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "7",
        "45to54": "2",
        "55to64": "10",
        "65+": "0",
        "medianAge": "10"
      }
    },
    "title": "Financial examiners",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "21",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "20",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Credit counselors and loan officers": {
    "ageData": {
      "2019": {
        "total": "403",
        "16to19": "1",
        "20to24": "20",
        "25to34": "104",
        "35to44": "103",
        "45to54": "88",
        "55to64": "70",
        "65+": "17",
        "medianAge": "70"
      }
    },
    "title": "Credit counselors and loan officers",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "403",
        "women": "53.9",
        "white": "81.7",
        "black": "12.2",
        "asian": "4.2",
        "hispanicOrLatino": "11.6"
      }
    },
    "wageData": {
      "2019": {
        "workers": "366",
        "median": "1,235",
        "maleWorkers": "173",
        "maleMedian": "1,542",
        "femaleWorkers": "193",
        "femaleMedian": "1,020"
      }
    }
  },
  "Tax examiners and collectors, and revenue agents": {
    "ageData": {
      "2019": {
        "total": "53",
        "16to19": "0",
        "20to24": "0",
        "25to34": "12",
        "35to44": "11",
        "45to54": "12",
        "55to64": "14",
        "65+": "3",
        "medianAge": "14"
      }
    },
    "title": "Tax examiners and collectors, and revenue agents",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "53",
        "women": "69.2",
        "white": "58.8",
        "black": "25.8",
        "asian": "12.8",
        "hispanicOrLatino": "12.1"
      }
    },
    "wageData": {
      "2019": {
        "workers": "49",
        "median": "-",
        "maleWorkers": "18",
        "maleMedian": "-",
        "femaleWorkers": "31",
        "femaleMedian": "-"
      }
    }
  },
  "Tax preparers": {
    "ageData": {
      "2019": {
        "total": "97",
        "16to19": "0",
        "20to24": "7",
        "25to34": "14",
        "35to44": "12",
        "45to54": "20",
        "55to64": "22",
        "65+": "23",
        "medianAge": "22"
      }
    },
    "title": "Tax preparers",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "97",
        "women": "62.3",
        "white": "77.5",
        "black": "16.6",
        "asian": "5.6",
        "hispanicOrLatino": "15.2"
      }
    },
    "wageData": {
      "2019": {
        "workers": "46",
        "median": "-",
        "maleWorkers": "20",
        "maleMedian": "-",
        "femaleWorkers": "26",
        "femaleMedian": "-"
      }
    }
  },
  "Financial specialists, all other": {
    "ageData": {
      "2019": {
        "total": "57",
        "16to19": "0",
        "20to24": "3",
        "25to34": "15",
        "35to44": "15",
        "45to54": "16",
        "55to64": "7",
        "65+": "1",
        "medianAge": "7"
      }
    },
    "title": "Financial specialists, all other",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "57",
        "women": "46.8",
        "white": "68.6",
        "black": "17.9",
        "asian": "8.7",
        "hispanicOrLatino": "15.9"
      }
    },
    "wageData": {
      "2019": {
        "workers": "50",
        "median": "1,209",
        "maleWorkers": "24",
        "maleMedian": "-",
        "femaleWorkers": "26",
        "femaleMedian": "-"
      }
    }
  },
  "Professional and related occupations": {
    "ageData": {
      "2019": {
        "total": "37,237",
        "16to19": "346",
        "20to24": "2,433",
        "25to34": "9,598",
        "35to44": "8,803",
        "45to54": "7,634",
        "55to64": "5,966",
        "65+": "2,457",
        "medianAge": "5,966"
      }
    },
    "title": "Professional and related occupations",
    "depth": 2,
    "parent": "Management, professional, and related occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "37,237",
        "women": "57.4",
        "white": "76.7",
        "black": "10.5",
        "asian": "10.0",
        "hispanicOrLatino": "9.9"
      }
    },
    "wageData": {
      "2019": {
        "workers": "29,423",
        "median": "1,237",
        "maleWorkers": "13,149",
        "maleMedian": "1,493",
        "femaleWorkers": "16,274",
        "femaleMedian": "1,085"
      }
    }
  },
  "Computer and mathematical occupations": {
    "ageData": {
      "2019": {
        "total": "5,352",
        "16to19": "26",
        "20to24": "354",
        "25to34": "1,593",
        "35to44": "1,414",
        "45to54": "1,092",
        "55to64": "700",
        "65+": "173",
        "medianAge": "700"
      }
    },
    "title": "Computer and mathematical occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "5,352",
        "women": "25.8",
        "white": "65.7",
        "black": "8.7",
        "asian": "23.1",
        "hispanicOrLatino": "7.8"
      }
    },
    "wageData": {
      "2019": {
        "workers": "4,947",
        "median": "1,579",
        "maleWorkers": "3,668",
        "maleMedian": "1,658",
        "femaleWorkers": "1,279",
        "femaleMedian": "1,353"
      }
    }
  },
  "Computer and information research scientists": {
    "ageData": {
      "2019": {
        "total": "24",
        "16to19": "0",
        "20to24": "4",
        "25to34": "6",
        "35to44": "7",
        "45to54": "4",
        "55to64": "1",
        "65+": "1",
        "medianAge": "1"
      }
    },
    "title": "Computer and information research scientists",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "24",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "20",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Computer systems analysts": {
    "ageData": {
      "2019": {
        "total": "663",
        "16to19": "1",
        "20to24": "59",
        "25to34": "191",
        "35to44": "181",
        "45to54": "121",
        "55to64": "94",
        "65+": "16",
        "medianAge": "94"
      }
    },
    "title": "Computer systems analysts",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "663",
        "women": "40.1",
        "white": "67.8",
        "black": "9.7",
        "asian": "20.3",
        "hispanicOrLatino": "8.3"
      }
    },
    "wageData": {
      "2019": {
        "workers": "623",
        "median": "1,461",
        "maleWorkers": "368",
        "maleMedian": "1,580",
        "femaleWorkers": "255",
        "femaleMedian": "1,289"
      }
    }
  },
  "Information security analysts": {
    "ageData": {
      "2019": {
        "total": "125",
        "16to19": "1",
        "20to24": "12",
        "25to34": "46",
        "35to44": "31",
        "45to54": "18",
        "55to64": "15",
        "65+": "2",
        "medianAge": "15"
      }
    },
    "title": "Information security analysts",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "125",
        "women": "17.1",
        "white": "74.6",
        "black": "16.6",
        "asian": "7.6",
        "hispanicOrLatino": "12.9"
      }
    },
    "wageData": {
      "2019": {
        "workers": "128",
        "median": "1,758",
        "maleWorkers": "103",
        "maleMedian": "1,875",
        "femaleWorkers": "25",
        "femaleMedian": "-"
      }
    }
  },
  "Computer programmers": {
    "ageData": {
      "2019": {
        "total": "454",
        "16to19": "2",
        "20to24": "19",
        "25to34": "117",
        "35to44": "105",
        "45to54": "116",
        "55to64": "72",
        "65+": "24",
        "medianAge": "72"
      }
    },
    "title": "Computer programmers",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "454",
        "women": "20.3",
        "white": "67.8",
        "black": "8.5",
        "asian": "21.8",
        "hispanicOrLatino": "8.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "425",
        "median": "1,586",
        "maleWorkers": "343",
        "maleMedian": "1,613",
        "femaleWorkers": "82",
        "femaleMedian": "1,463"
      }
    }
  },
  "Software developers, applications and systems software": {
    "ageData": {
      "2019": {
        "total": "1,815",
        "16to19": "2",
        "20to24": "111",
        "25to34": "640",
        "35to44": "496",
        "45to54": "313",
        "55to64": "195",
        "65+": "56",
        "medianAge": "195"
      }
    },
    "title": "Software developers, applications and systems software",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "1,815",
        "women": "18.7",
        "white": "54.0",
        "black": "5.8",
        "asian": "37.7",
        "hispanicOrLatino": "5.1"
      }
    },
    "wageData": {
      "2019": {
        "workers": "1,714",
        "median": "1,894",
        "maleWorkers": "1,379",
        "maleMedian": "1,920",
        "femaleWorkers": "335",
        "femaleMedian": "1,718"
      }
    }
  },
  "Web developers": {
    "ageData": {
      "2019": {
        "total": "193",
        "16to19": "4",
        "20to24": "15",
        "25to34": "56",
        "35to44": "60",
        "45to54": "36",
        "55to64": "16",
        "65+": "7",
        "medianAge": "16"
      }
    },
    "title": "Web developers",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "193",
        "women": "41.4",
        "white": "77.6",
        "black": "6.7",
        "asian": "12.0",
        "hispanicOrLatino": "4.6"
      }
    },
    "wageData": {
      "2019": {
        "workers": "144",
        "median": "1,470",
        "maleWorkers": "88",
        "maleMedian": "1,633",
        "femaleWorkers": "56",
        "femaleMedian": "1,150"
      }
    }
  },
  "Computer support specialists": {
    "ageData": {
      "2019": {
        "total": "547",
        "16to19": "5",
        "20to24": "36",
        "25to34": "158",
        "35to44": "145",
        "45to54": "119",
        "55to64": "68",
        "65+": "15",
        "medianAge": "68"
      }
    },
    "title": "Computer support specialists",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "547",
        "women": "26.4",
        "white": "74.2",
        "black": "10.5",
        "asian": "12.7",
        "hispanicOrLatino": "11.3"
      }
    },
    "wageData": {
      "2019": {
        "workers": "486",
        "median": "1,192",
        "maleWorkers": "365",
        "maleMedian": "1,223",
        "femaleWorkers": "121",
        "femaleMedian": "1,103"
      }
    }
  },
  "Database administrators": {
    "ageData": {
      "2019": {
        "total": "106",
        "16to19": "0",
        "20to24": "5",
        "25to34": "21",
        "35to44": "29",
        "45to54": "27",
        "55to64": "18",
        "65+": "7",
        "medianAge": "18"
      }
    },
    "title": "Database administrators",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "106",
        "women": "30.2",
        "white": "72.0",
        "black": "6.8",
        "asian": "16.7",
        "hispanicOrLatino": "6.2"
      }
    },
    "wageData": {
      "2019": {
        "workers": "96",
        "median": "1,642",
        "maleWorkers": "67",
        "maleMedian": "1,752",
        "femaleWorkers": "29",
        "femaleMedian": "-"
      }
    }
  },
  "Network and computer systems administrators": {
    "ageData": {
      "2019": {
        "total": "199",
        "16to19": "0",
        "20to24": "8",
        "25to34": "48",
        "35to44": "65",
        "45to54": "50",
        "55to64": "25",
        "65+": "4",
        "medianAge": "25"
      }
    },
    "title": "Network and computer systems administrators",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "199",
        "women": "26.1",
        "white": "77.2",
        "black": "9.7",
        "asian": "11.7",
        "hispanicOrLatino": "8.5"
      }
    },
    "wageData": {
      "2019": {
        "workers": "194",
        "median": "1,447",
        "maleWorkers": "144",
        "maleMedian": "1,541",
        "femaleWorkers": "50",
        "femaleMedian": "1,233"
      }
    }
  },
  "Computer network architects": {
    "ageData": {
      "2019": {
        "total": "106",
        "16to19": "0",
        "20to24": "1",
        "25to34": "15",
        "35to44": "32",
        "45to54": "37",
        "55to64": "19",
        "65+": "3",
        "medianAge": "19"
      }
    },
    "title": "Computer network architects",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "106",
        "women": "9.2",
        "white": "76.1",
        "black": "13.1",
        "asian": "10.3",
        "hispanicOrLatino": "14.1"
      }
    },
    "wageData": {
      "2019": {
        "workers": "104",
        "median": "1,883",
        "maleWorkers": "95",
        "maleMedian": "1,901",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Computer occupations, all other": {
    "ageData": {
      "2019": {
        "total": "808",
        "16to19": "11",
        "20to24": "61",
        "25to34": "205",
        "35to44": "190",
        "45to54": "188",
        "55to64": "121",
        "65+": "33",
        "medianAge": "121"
      }
    },
    "title": "Computer occupations, all other",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "808",
        "women": "23.6",
        "white": "70.5",
        "black": "12.7",
        "asian": "14.1",
        "hispanicOrLatino": "9.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "721",
        "median": "1,277",
        "maleWorkers": "542",
        "maleMedian": "1,365",
        "femaleWorkers": "179",
        "femaleMedian": "1,148"
      }
    }
  },
  "Actuaries": {
    "ageData": {
      "2019": {
        "total": "33",
        "16to19": "0",
        "20to24": "1",
        "25to34": "7",
        "35to44": "7",
        "45to54": "10",
        "55to64": "7",
        "65+": "0",
        "medianAge": "7"
      }
    },
    "title": "Actuaries",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "33",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "28",
        "median": "-",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Mathematicians": {
    "ageData": {
      "2019": {
        "total": "3",
        "16to19": "0",
        "20to24": "1",
        "25to34": "0",
        "35to44": "1",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Mathematicians",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Operations research analysts": {
    "ageData": {
      "2019": {
        "total": "152",
        "16to19": "0",
        "20to24": "8",
        "25to34": "42",
        "35to44": "34",
        "45to54": "35",
        "55to64": "30",
        "65+": "4",
        "medianAge": "30"
      }
    },
    "title": "Operations research analysts",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "152",
        "women": "42.7",
        "white": "80.5",
        "black": "6.8",
        "asian": "8.3",
        "hispanicOrLatino": "9.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "153",
        "median": "1,512",
        "maleWorkers": "86",
        "maleMedian": "1,753",
        "femaleWorkers": "67",
        "femaleMedian": "1,188"
      }
    }
  },
  "Statisticians": {
    "ageData": {
      "2019": {
        "total": "101",
        "16to19": "0",
        "20to24": "9",
        "25to34": "35",
        "35to44": "26",
        "45to54": "14",
        "55to64": "15",
        "65+": "2",
        "medianAge": "15"
      }
    },
    "title": "Statisticians",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "101",
        "women": "52.4",
        "white": "69.6",
        "black": "8.2",
        "asian": "21.1",
        "hispanicOrLatino": "7.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "90",
        "median": "1,456",
        "maleWorkers": "44",
        "maleMedian": "-",
        "femaleWorkers": "46",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous mathematical science occupations": {
    "ageData": {
      "2019": {
        "total": "22",
        "16to19": "0",
        "20to24": "5",
        "25to34": "6",
        "35to44": "5",
        "45to54": "4",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Miscellaneous mathematical science occupations",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "22",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "18",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Architecture and engineering occupations": {
    "ageData": {
      "2019": {
        "total": "3,305",
        "16to19": "13",
        "20to24": "209",
        "25to34": "871",
        "35to44": "718",
        "45to54": "659",
        "55to64": "663",
        "65+": "173",
        "medianAge": "663"
      }
    },
    "title": "Architecture and engineering occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "3,305",
        "women": "15.7",
        "white": "77.5",
        "black": "6.8",
        "asian": "13.3",
        "hispanicOrLatino": "9.2"
      }
    },
    "wageData": {
      "2019": {
        "workers": "3,011",
        "median": "1,550",
        "maleWorkers": "2,559",
        "maleMedian": "1,589",
        "femaleWorkers": "452",
        "femaleMedian": "1,339"
      }
    }
  },
  "Architects, except naval": {
    "ageData": {
      "2019": {
        "total": "208",
        "16to19": "0",
        "20to24": "3",
        "25to34": "42",
        "35to44": "53",
        "45to54": "43",
        "55to64": "46",
        "65+": "21",
        "medianAge": "46"
      }
    },
    "title": "Architects, except naval",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "208",
        "women": "24.5",
        "white": "82.6",
        "black": "6.3",
        "asian": "8.8",
        "hispanicOrLatino": "8.5"
      }
    },
    "wageData": {
      "2019": {
        "workers": "152",
        "median": "1,572",
        "maleWorkers": "114",
        "maleMedian": "1,706",
        "femaleWorkers": "38",
        "femaleMedian": "-"
      }
    }
  },
  "Surveyors, cartographers, and photogrammetrists": {
    "ageData": {
      "2019": {
        "total": "48",
        "16to19": "0",
        "20to24": "1",
        "25to34": "9",
        "35to44": "9",
        "45to54": "10",
        "55to64": "13",
        "65+": "5",
        "medianAge": "13"
      }
    },
    "title": "Surveyors, cartographers, and photogrammetrists",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "48",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "42",
        "median": "-",
        "maleWorkers": "34",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Aerospace engineers": {
    "ageData": {
      "2019": {
        "total": "134",
        "16to19": "0",
        "20to24": "4",
        "25to34": "36",
        "35to44": "23",
        "45to54": "22",
        "55to64": "39",
        "65+": "10",
        "medianAge": "39"
      }
    },
    "title": "Aerospace engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "134",
        "women": "13.8",
        "white": "78.0",
        "black": "6.5",
        "asian": "14.5",
        "hispanicOrLatino": "7.3"
      }
    },
    "wageData": {
      "2019": {
        "workers": "138",
        "median": "1,917",
        "maleWorkers": "121",
        "maleMedian": "2,018",
        "femaleWorkers": "17",
        "femaleMedian": "-"
      }
    }
  },
  "Agricultural engineers": {
    "ageData": {
      "2019": {
        "total": "6",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "0",
        "45to54": "0",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Agricultural engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "6",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Biomedical engineers": {
    "ageData": {
      "2019": {
        "total": "25",
        "16to19": "0",
        "20to24": "4",
        "25to34": "8",
        "35to44": "6",
        "45to54": "1",
        "55to64": "5",
        "65+": "1",
        "medianAge": "5"
      }
    },
    "title": "Biomedical engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "25",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "17",
        "median": "-",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Chemical engineers": {
    "ageData": {
      "2019": {
        "total": "87",
        "16to19": "0",
        "20to24": "6",
        "25to34": "28",
        "35to44": "14",
        "45to54": "17",
        "55to64": "15",
        "65+": "6",
        "medianAge": "15"
      }
    },
    "title": "Chemical engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "87",
        "women": "14.4",
        "white": "75.6",
        "black": "4.0",
        "asian": "17.1",
        "hispanicOrLatino": "2.4"
      }
    },
    "wageData": {
      "2019": {
        "workers": "86",
        "median": "1,863",
        "maleWorkers": "74",
        "maleMedian": "1,878",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Civil engineers": {
    "ageData": {
      "2019": {
        "total": "475",
        "16to19": "0",
        "20to24": "37",
        "25to34": "134",
        "35to44": "109",
        "45to54": "75",
        "55to64": "90",
        "65+": "29",
        "medianAge": "90"
      }
    },
    "title": "Civil engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "475",
        "women": "13.9",
        "white": "80.3",
        "black": "5.9",
        "asian": "12.2",
        "hispanicOrLatino": "8.4"
      }
    },
    "wageData": {
      "2019": {
        "workers": "421",
        "median": "1,616",
        "maleWorkers": "360",
        "maleMedian": "1,657",
        "femaleWorkers": "61",
        "femaleMedian": "1,353"
      }
    }
  },
  "Computer hardware engineers": {
    "ageData": {
      "2019": {
        "total": "77",
        "16to19": "0",
        "20to24": "12",
        "25to34": "17",
        "35to44": "22",
        "45to54": "11",
        "55to64": "13",
        "65+": "1",
        "medianAge": "13"
      }
    },
    "title": "Computer hardware engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "77",
        "women": "22.8",
        "white": "54.3",
        "black": "5.7",
        "asian": "38.4",
        "hispanicOrLatino": "10.2"
      }
    },
    "wageData": {
      "2019": {
        "workers": "71",
        "median": "1,882",
        "maleWorkers": "57",
        "maleMedian": "1,919",
        "femaleWorkers": "15",
        "femaleMedian": "-"
      }
    }
  },
  "Electrical and electronics engineers": {
    "ageData": {
      "2019": {
        "total": "285",
        "16to19": "0",
        "20to24": "13",
        "25to34": "70",
        "35to44": "69",
        "45to54": "54",
        "55to64": "61",
        "65+": "18",
        "medianAge": "61"
      }
    },
    "title": "Electrical and electronics engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "285",
        "women": "11.6",
        "white": "71.3",
        "black": "7.3",
        "asian": "19.9",
        "hispanicOrLatino": "8.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "266",
        "median": "1,781",
        "maleWorkers": "241",
        "maleMedian": "1,830",
        "femaleWorkers": "25",
        "femaleMedian": "-"
      }
    }
  },
  "Environmental engineers": {
    "ageData": {
      "2019": {
        "total": "25",
        "16to19": "0",
        "20to24": "0",
        "25to34": "8",
        "35to44": "5",
        "45to54": "6",
        "55to64": "3",
        "65+": "2",
        "medianAge": "3"
      }
    },
    "title": "Environmental engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "25",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "25",
        "median": "-",
        "maleWorkers": "20",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Industrial engineers, including health and safety": {
    "ageData": {
      "2019": {
        "total": "245",
        "16to19": "1",
        "20to24": "11",
        "25to34": "64",
        "35to44": "56",
        "45to54": "53",
        "55to64": "49",
        "65+": "11",
        "medianAge": "49"
      }
    },
    "title": "Industrial engineers, including health and safety",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "245",
        "women": "24.3",
        "white": "80.7",
        "black": "7.9",
        "asian": "9.4",
        "hispanicOrLatino": "5.9"
      }
    },
    "wageData": {
      "2019": {
        "workers": "227",
        "median": "1,589",
        "maleWorkers": "169",
        "maleMedian": "1,616",
        "femaleWorkers": "58",
        "femaleMedian": "1,487"
      }
    }
  },
  "Marine engineers and naval architects": {
    "ageData": {
      "2019": {
        "total": "12",
        "16to19": "0",
        "20to24": "0",
        "25to34": "4",
        "35to44": "3",
        "45to54": "3",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Marine engineers and naval architects",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "12",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Materials engineers": {
    "ageData": {
      "2019": {
        "total": "44",
        "16to19": "0",
        "20to24": "1",
        "25to34": "16",
        "35to44": "7",
        "45to54": "10",
        "55to64": "8",
        "65+": "3",
        "medianAge": "8"
      }
    },
    "title": "Materials engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "44",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "44",
        "median": "-",
        "maleWorkers": "39",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Mechanical engineers": {
    "ageData": {
      "2019": {
        "total": "351",
        "16to19": "4",
        "20to24": "22",
        "25to34": "113",
        "35to44": "74",
        "45to54": "73",
        "55to64": "54",
        "65+": "12",
        "medianAge": "54"
      }
    },
    "title": "Mechanical engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "351",
        "women": "6.6",
        "white": "80.4",
        "black": "5.1",
        "asian": "11.6",
        "hispanicOrLatino": "8.4"
      }
    },
    "wageData": {
      "2019": {
        "workers": "322",
        "median": "1,625",
        "maleWorkers": "303",
        "maleMedian": "1,635",
        "femaleWorkers": "19",
        "femaleMedian": "-"
      }
    }
  },
  "Mining and geological engineers, including mining safety engineers": {
    "ageData": {
      "2019": {
        "total": "8",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "1",
        "45to54": "2",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Mining and geological engineers, including mining safety engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "8",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Nuclear engineers": {
    "ageData": {
      "2019": {
        "total": "8",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "1",
        "45to54": "1",
        "55to64": "2",
        "65+": "2",
        "medianAge": "2"
      }
    },
    "title": "Nuclear engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "8",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Petroleum engineers": {
    "ageData": {
      "2019": {
        "total": "24",
        "16to19": "0",
        "20to24": "1",
        "25to34": "7",
        "35to44": "9",
        "45to54": "4",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Petroleum engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "24",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "24",
        "median": "-",
        "maleWorkers": "22",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Engineers, all other": {
    "ageData": {
      "2019": {
        "total": "582",
        "16to19": "1",
        "20to24": "32",
        "25to34": "150",
        "35to44": "128",
        "45to54": "118",
        "55to64": "125",
        "65+": "27",
        "medianAge": "125"
      }
    },
    "title": "Engineers, all other",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "582",
        "women": "13.9",
        "white": "71.8",
        "black": "5.4",
        "asian": "20.6",
        "hispanicOrLatino": "9.8"
      }
    },
    "wageData": {
      "2019": {
        "workers": "529",
        "median": "1,636",
        "maleWorkers": "455",
        "maleMedian": "1,663",
        "femaleWorkers": "74",
        "femaleMedian": "1,446"
      }
    }
  },
  "Drafters": {
    "ageData": {
      "2019": {
        "total": "113",
        "16to19": "0",
        "20to24": "12",
        "25to34": "23",
        "35to44": "24",
        "45to54": "32",
        "55to64": "17",
        "65+": "4",
        "medianAge": "17"
      }
    },
    "title": "Drafters",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "113",
        "women": "23.3",
        "white": "90.8",
        "black": "3.5",
        "asian": "2.7",
        "hispanicOrLatino": "14.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "104",
        "median": "1,045",
        "maleWorkers": "82",
        "maleMedian": "1,047",
        "femaleWorkers": "22",
        "femaleMedian": "-"
      }
    }
  },
  "Engineering technicians, except drafters": {
    "ageData": {
      "2019": {
        "total": "496",
        "16to19": "5",
        "20to24": "41",
        "25to34": "120",
        "35to44": "97",
        "45to54": "111",
        "55to64": "106",
        "65+": "15",
        "medianAge": "106"
      }
    },
    "title": "Engineering technicians, except drafters",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "496",
        "women": "19.8",
        "white": "76.5",
        "black": "12.1",
        "asian": "8.3",
        "hispanicOrLatino": "12.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "462",
        "median": "1,161",
        "maleWorkers": "382",
        "maleMedian": "1,200",
        "femaleWorkers": "79",
        "femaleMedian": "915"
      }
    }
  },
  "Surveying and mapping technicians": {
    "ageData": {
      "2019": {
        "total": "53",
        "16to19": "1",
        "20to24": "7",
        "25to34": "13",
        "35to44": "6",
        "45to54": "13",
        "55to64": "8",
        "65+": "5",
        "medianAge": "8"
      }
    },
    "title": "Surveying and mapping technicians",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "53",
        "women": "6.8",
        "white": "86.3",
        "black": "6.6",
        "asian": "3.5",
        "hispanicOrLatino": "10.9"
      }
    },
    "wageData": {
      "2019": {
        "workers": "50",
        "median": "1,060",
        "maleWorkers": "46",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Life, physical, and social science occupations": {
    "ageData": {
      "2019": {
        "total": "1,485",
        "16to19": "9",
        "20to24": "137",
        "25to34": "427",
        "35to44": "320",
        "45to54": "262",
        "55to64": "231",
        "65+": "99",
        "medianAge": "231"
      }
    },
    "title": "Life, physical, and social science occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "1,485",
        "women": "49.4",
        "white": "76.9",
        "black": "6.3",
        "asian": "14.4",
        "hispanicOrLatino": "9.5"
      }
    },
    "wageData": {
      "2019": {
        "workers": "1,233",
        "median": "1,334",
        "maleWorkers": "676",
        "maleMedian": "1,447",
        "femaleWorkers": "556",
        "femaleMedian": "1,237"
      }
    }
  },
  "Agricultural and food scientists": {
    "ageData": {
      "2019": {
        "total": "37",
        "16to19": "0",
        "20to24": "8",
        "25to34": "13",
        "35to44": "6",
        "45to54": "5",
        "55to64": "6",
        "65+": "1",
        "medianAge": "6"
      }
    },
    "title": "Agricultural and food scientists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "37",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "36",
        "median": "-",
        "maleWorkers": "23",
        "maleMedian": "-",
        "femaleWorkers": "13",
        "femaleMedian": "-"
      }
    }
  },
  "Biological scientists": {
    "ageData": {
      "2019": {
        "total": "104",
        "16to19": "0",
        "20to24": "8",
        "25to34": "32",
        "35to44": "23",
        "45to54": "23",
        "55to64": "12",
        "65+": "7",
        "medianAge": "12"
      }
    },
    "title": "Biological scientists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "104",
        "women": "47.7",
        "white": "81.0",
        "black": "3.4",
        "asian": "14.4",
        "hispanicOrLatino": "8.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "101",
        "median": "1,250",
        "maleWorkers": "57",
        "maleMedian": "1,266",
        "femaleWorkers": "44",
        "femaleMedian": "-"
      }
    }
  },
  "Conservation scientists and foresters": {
    "ageData": {
      "2019": {
        "total": "20",
        "16to19": "0",
        "20to24": "1",
        "25to34": "3",
        "35to44": "8",
        "45to54": "3",
        "55to64": "4",
        "65+": "1",
        "medianAge": "4"
      }
    },
    "title": "Conservation scientists and foresters",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "20",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "20",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Medical scientists": {
    "ageData": {
      "2019": {
        "total": "170",
        "16to19": "0",
        "20to24": "12",
        "25to34": "59",
        "35to44": "43",
        "45to54": "24",
        "55to64": "25",
        "65+": "7",
        "medianAge": "25"
      }
    },
    "title": "Medical scientists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "170",
        "women": "51.8",
        "white": "64.1",
        "black": "7.3",
        "asian": "27.8",
        "hispanicOrLatino": "8.8"
      }
    },
    "wageData": {
      "2019": {
        "workers": "146",
        "median": "1,188",
        "maleWorkers": "77",
        "maleMedian": "1,214",
        "femaleWorkers": "70",
        "femaleMedian": "1,175"
      }
    }
  },
  "Life scientists, all other": {
    "ageData": {
      "2019": {
        "total": "2",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "0",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Life scientists, all other",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Astronomers and physicists": {
    "ageData": {
      "2019": {
        "total": "17",
        "16to19": "0",
        "20to24": "0",
        "25to34": "4",
        "35to44": "4",
        "45to54": "3",
        "55to64": "6",
        "65+": "1",
        "medianAge": "6"
      }
    },
    "title": "Astronomers and physicists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "17",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "15",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Atmospheric and space scientists": {
    "ageData": {
      "2019": {
        "total": "10",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "2",
        "45to54": "2",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Atmospheric and space scientists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "10",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Chemists and materials scientists": {
    "ageData": {
      "2019": {
        "total": "97",
        "16to19": "0",
        "20to24": "7",
        "25to34": "35",
        "35to44": "16",
        "45to54": "16",
        "55to64": "17",
        "65+": "5",
        "medianAge": "17"
      }
    },
    "title": "Chemists and materials scientists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "97",
        "women": "42.5",
        "white": "75.7",
        "black": "7.0",
        "asian": "13.7",
        "hispanicOrLatino": "8.2"
      }
    },
    "wageData": {
      "2019": {
        "workers": "85",
        "median": "1,582",
        "maleWorkers": "49",
        "maleMedian": "-",
        "femaleWorkers": "36",
        "femaleMedian": "-"
      }
    }
  },
  "Environmental scientists and geoscientists": {
    "ageData": {
      "2019": {
        "total": "119",
        "16to19": "1",
        "20to24": "10",
        "25to34": "32",
        "35to44": "31",
        "45to54": "18",
        "55to64": "18",
        "65+": "10",
        "medianAge": "18"
      }
    },
    "title": "Environmental scientists and geoscientists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "119",
        "women": "33.0",
        "white": "85.1",
        "black": "4.8",
        "asian": "7.4",
        "hispanicOrLatino": "12.4"
      }
    },
    "wageData": {
      "2019": {
        "workers": "101",
        "median": "1,486",
        "maleWorkers": "65",
        "maleMedian": "1,519",
        "femaleWorkers": "36",
        "femaleMedian": "-"
      }
    }
  },
  "Physical scientists, all other": {
    "ageData": {
      "2019": {
        "total": "298",
        "16to19": "0",
        "20to24": "23",
        "25to34": "99",
        "35to44": "71",
        "45to54": "50",
        "55to64": "45",
        "65+": "10",
        "medianAge": "45"
      }
    },
    "title": "Physical scientists, all other",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "298",
        "women": "43.7",
        "white": "68.3",
        "black": "3.4",
        "asian": "25.7",
        "hispanicOrLatino": "7.1"
      }
    },
    "wageData": {
      "2019": {
        "workers": "278",
        "median": "1,669",
        "maleWorkers": "158",
        "maleMedian": "1,901",
        "femaleWorkers": "120",
        "femaleMedian": "1,385"
      }
    }
  },
  "Economists": {
    "ageData": {
      "2019": {
        "total": "35",
        "16to19": "0",
        "20to24": "0",
        "25to34": "9",
        "35to44": "8",
        "45to54": "6",
        "55to64": "10",
        "65+": "2",
        "medianAge": "10"
      }
    },
    "title": "Economists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "35",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "35",
        "median": "-",
        "maleWorkers": "26",
        "maleMedian": "-",
        "femaleWorkers": "9",
        "femaleMedian": "-"
      }
    }
  },
  "Survey researchers": {
    "ageData": {
      "2019": {
        "total": "0",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Survey researchers",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "0",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "0",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Psychologists": {
    "ageData": {
      "2019": {
        "total": "234",
        "16to19": "0",
        "20to24": "4",
        "25to34": "43",
        "35to44": "49",
        "45to54": "61",
        "55to64": "37",
        "65+": "41",
        "medianAge": "37"
      }
    },
    "title": "Psychologists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "234",
        "women": "79.7",
        "white": "89.9",
        "black": "5.8",
        "asian": "3.5",
        "hispanicOrLatino": "10.3"
      }
    },
    "wageData": {
      "2019": {
        "workers": "114",
        "median": "1,468",
        "maleWorkers": "22",
        "maleMedian": "-",
        "femaleWorkers": "93",
        "femaleMedian": "1,441"
      }
    }
  },
  "Sociologists": {
    "ageData": {
      "2019": {
        "total": "2",
        "16to19": "0",
        "20to24": "2",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Sociologists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Urban and regional planners": {
    "ageData": {
      "2019": {
        "total": "25",
        "16to19": "0",
        "20to24": "3",
        "25to34": "8",
        "35to44": "6",
        "45to54": "5",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Urban and regional planners",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "25",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "22",
        "median": "-",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous social scientists and related workers": {
    "ageData": {
      "2019": {
        "total": "39",
        "16to19": "0",
        "20to24": "2",
        "25to34": "11",
        "35to44": "8",
        "45to54": "8",
        "55to64": "5",
        "65+": "5",
        "medianAge": "5"
      }
    },
    "title": "Miscellaneous social scientists and related workers",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "39",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "32",
        "median": "-",
        "maleWorkers": "20",
        "maleMedian": "-",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Agricultural and food science technicians": {
    "ageData": {
      "2019": {
        "total": "20",
        "16to19": "0",
        "20to24": "5",
        "25to34": "4",
        "35to44": "1",
        "45to54": "3",
        "55to64": "4",
        "65+": "3",
        "medianAge": "4"
      }
    },
    "title": "Agricultural and food science technicians",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "20",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "20",
        "median": "-",
        "maleWorkers": "16",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Biological technicians": {
    "ageData": {
      "2019": {
        "total": "23",
        "16to19": "0",
        "20to24": "3",
        "25to34": "8",
        "35to44": "6",
        "45to54": "3",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Biological technicians",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "23",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "20",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Chemical technicians": {
    "ageData": {
      "2019": {
        "total": "56",
        "16to19": "1",
        "20to24": "3",
        "25to34": "17",
        "35to44": "15",
        "45to54": "11",
        "55to64": "9",
        "65+": "1",
        "medianAge": "9"
      }
    },
    "title": "Chemical technicians",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "56",
        "women": "30.8",
        "white": "80.1",
        "black": "10.6",
        "asian": "6.3",
        "hispanicOrLatino": "12.2"
      }
    },
    "wageData": {
      "2019": {
        "workers": "61",
        "median": "893",
        "maleWorkers": "41",
        "maleMedian": "-",
        "femaleWorkers": "20",
        "femaleMedian": "-"
      }
    }
  },
  "Geological and petroleum technicians": {
    "ageData": {
      "2019": {
        "total": "20",
        "16to19": "0",
        "20to24": "1",
        "25to34": "3",
        "35to44": "6",
        "45to54": "8",
        "55to64": "0",
        "65+": "1",
        "medianAge": "0"
      }
    },
    "title": "Geological and petroleum technicians",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "20",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "18",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Nuclear technicians": {
    "ageData": {
      "2019": {
        "total": "3",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Nuclear technicians",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Social science research assistants": {
    "ageData": {
      "2019": {
        "total": "1",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Social science research assistants",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous life, physical, and social science technicians": {
    "ageData": {
      "2019": {
        "total": "152",
        "16to19": "6",
        "20to24": "44",
        "25to34": "45",
        "35to44": "16",
        "45to54": "13",
        "55to64": "22",
        "65+": "6",
        "medianAge": "22"
      }
    },
    "title": "Miscellaneous life, physical, and social science technicians",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "152",
        "women": "54.0",
        "white": "72.8",
        "black": "8.6",
        "asian": "15.0",
        "hispanicOrLatino": "13.4"
      }
    },
    "wageData": {
      "2019": {
        "workers": "108",
        "median": "907",
        "maleWorkers": "57",
        "maleMedian": "928",
        "femaleWorkers": "51",
        "femaleMedian": "896"
      }
    }
  },
  "Community and social service occupations": {
    "ageData": {
      "2019": {
        "total": "2,717",
        "16to19": "20",
        "20to24": "168",
        "25to34": "648",
        "35to44": "596",
        "45to54": "559",
        "55to64": "479",
        "65+": "247",
        "medianAge": "479"
      }
    },
    "title": "Community and social service occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "2,717",
        "women": "67.5",
        "white": "72.9",
        "black": "20.1",
        "asian": "3.6",
        "hispanicOrLatino": "13.0"
      }
    },
    "wageData": {
      "2019": {
        "workers": "2,240",
        "median": "968",
        "maleWorkers": "742",
        "maleMedian": "1,064",
        "femaleWorkers": "1,498",
        "femaleMedian": "941"
      }
    }
  },
  "Counselors": {
    "ageData": {
      "2019": {
        "total": "927",
        "16to19": "13",
        "20to24": "67",
        "25to34": "232",
        "35to44": "214",
        "45to54": "182",
        "55to64": "142",
        "65+": "78",
        "medianAge": "142"
      }
    },
    "title": "Counselors",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "927",
        "women": "75.7",
        "white": "72.7",
        "black": "21.2",
        "asian": "2.7",
        "hispanicOrLatino": "12.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "720",
        "median": "987",
        "maleWorkers": "179",
        "maleMedian": "944",
        "femaleWorkers": "541",
        "femaleMedian": "1,003"
      }
    }
  },
  "Social workers": {
    "ageData": {
      "2019": {
        "total": "823",
        "16to19": "2",
        "20to24": "38",
        "25to34": "226",
        "35to44": "190",
        "45to54": "187",
        "55to64": "140",
        "65+": "40",
        "medianAge": "140"
      }
    },
    "title": "Social workers",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "823",
        "women": "81.9",
        "white": "69.6",
        "black": "23.0",
        "asian": "3.7",
        "hispanicOrLatino": "14.3"
      }
    },
    "wageData": {
      "2019": {
        "workers": "739",
        "median": "964",
        "maleWorkers": "136",
        "maleMedian": "1,108",
        "femaleWorkers": "603",
        "femaleMedian": "944"
      }
    }
  },
  "Probation officers and correctional treatment specialists": {
    "ageData": {
      "2019": {
        "total": "97",
        "16to19": "0",
        "20to24": "2",
        "25to34": "26",
        "35to44": "27",
        "45to54": "31",
        "55to64": "10",
        "65+": "1",
        "medianAge": "10"
      }
    },
    "title": "Probation officers and correctional treatment specialists",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "97",
        "women": "44.6",
        "white": "70.6",
        "black": "26.7",
        "asian": "0.4",
        "hispanicOrLatino": "15.8"
      }
    },
    "wageData": {
      "2019": {
        "workers": "94",
        "median": "1,067",
        "maleWorkers": "50",
        "maleMedian": "1,050",
        "femaleWorkers": "45",
        "femaleMedian": "-"
      }
    }
  },
  "Social and human service assistants": {
    "ageData": {
      "2019": {
        "total": "238",
        "16to19": "2",
        "20to24": "30",
        "25to34": "63",
        "35to44": "46",
        "45to54": "39",
        "55to64": "45",
        "65+": "13",
        "medianAge": "45"
      }
    },
    "title": "Social and human service assistants",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "238",
        "women": "79.0",
        "white": "65.2",
        "black": "25.5",
        "asian": "4.3",
        "hispanicOrLatino": "16.4"
      }
    },
    "wageData": {
      "2019": {
        "workers": "207",
        "median": "805",
        "maleWorkers": "41",
        "maleMedian": "-",
        "femaleWorkers": "166",
        "femaleMedian": "778"
      }
    }
  },
  "Miscellaneous community and social service specialists, including health educators and community health workers": {
    "ageData": {
      "2019": {
        "total": "78",
        "16to19": "0",
        "20to24": "4",
        "25to34": "13",
        "35to44": "21",
        "45to54": "21",
        "55to64": "15",
        "65+": "4",
        "medianAge": "15"
      }
    },
    "title": "Miscellaneous community and social service specialists, including health educators and community health workers",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "78",
        "women": "70.8",
        "white": "71.1",
        "black": "22.5",
        "asian": "2.5",
        "hispanicOrLatino": "21.9"
      }
    },
    "wageData": {
      "2019": {
        "workers": "66",
        "median": "920",
        "maleWorkers": "20",
        "maleMedian": "-",
        "femaleWorkers": "46",
        "femaleMedian": "-"
      }
    }
  },
  "Clergy": {
    "ageData": {
      "2019": {
        "total": "413",
        "16to19": "0",
        "20to24": "11",
        "25to34": "60",
        "35to44": "73",
        "45to54": "80",
        "55to64": "103",
        "65+": "86",
        "medianAge": "103"
      }
    },
    "title": "Clergy",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "413",
        "women": "20.7",
        "white": "79.1",
        "black": "12.2",
        "asian": "5.9",
        "hispanicOrLatino": "7.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "332",
        "median": "1,066",
        "maleWorkers": "275",
        "maleMedian": "1,155",
        "femaleWorkers": "57",
        "femaleMedian": "862"
      }
    }
  },
  "Directors, religious activities and education": {
    "ageData": {
      "2019": {
        "total": "72",
        "16to19": "1",
        "20to24": "5",
        "25to34": "20",
        "35to44": "13",
        "45to54": "11",
        "55to64": "12",
        "65+": "10",
        "medianAge": "12"
      }
    },
    "title": "Directors, religious activities and education",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "72",
        "women": "48.6",
        "white": "92.3",
        "black": "5.1",
        "asian": "2.3",
        "hispanicOrLatino": "13.4"
      }
    },
    "wageData": {
      "2019": {
        "workers": "48",
        "median": "-",
        "maleWorkers": "29",
        "maleMedian": "-",
        "femaleWorkers": "19",
        "femaleMedian": "-"
      }
    }
  },
  "Religious workers, all other": {
    "ageData": {
      "2019": {
        "total": "69",
        "16to19": "2",
        "20to24": "9",
        "25to34": "8",
        "35to44": "12",
        "45to54": "9",
        "55to64": "13",
        "65+": "15",
        "medianAge": "13"
      }
    },
    "title": "Religious workers, all other",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "69",
        "women": "72.7",
        "white": "89.6",
        "black": "5.1",
        "asian": "4.7",
        "hispanicOrLatino": "8.3"
      }
    },
    "wageData": {
      "2019": {
        "workers": "34",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "21",
        "femaleMedian": "-"
      }
    }
  },
  "Legal occupations": {
    "ageData": {
      "2019": {
        "total": "1,955",
        "16to19": "4",
        "20to24": "54",
        "25to34": "406",
        "35to44": "438",
        "45to54": "432",
        "55to64": "368",
        "65+": "251",
        "medianAge": "368"
      }
    },
    "title": "Legal occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "1,955",
        "women": "52.7",
        "white": "83.0",
        "black": "8.3",
        "asian": "6.1",
        "hispanicOrLatino": "9.5"
      }
    },
    "wageData": {
      "2019": {
        "workers": "1,478",
        "median": "1,562",
        "maleWorkers": "670",
        "maleMedian": "1,999",
        "femaleWorkers": "808",
        "femaleMedian": "1,266"
      }
    }
  },
  "Lawyers": {
    "ageData": {
      "2019": {
        "total": "1,240",
        "16to19": "2",
        "20to24": "5",
        "25to34": "233",
        "35to44": "310",
        "45to54": "279",
        "55to64": "224",
        "65+": "188",
        "medianAge": "224"
      }
    },
    "title": "Lawyers",
    "depth": 4,
    "parent": "Legal occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "1,240",
        "women": "36.4",
        "white": "86.6",
        "black": "5.9",
        "asian": "5.7",
        "hispanicOrLatino": "5.8"
      }
    },
    "wageData": {
      "2019": {
        "workers": "884",
        "median": "2,043",
        "maleWorkers": "548",
        "maleMedian": "2,202",
        "femaleWorkers": "336",
        "femaleMedian": "1,878"
      }
    }
  },
  "Judicial law clerks": {
    "ageData": {
      "2019": {
        "total": "7",
        "16to19": "0",
        "20to24": "0",
        "25to34": "4",
        "35to44": "0",
        "45to54": "2",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Judicial law clerks",
    "depth": 4,
    "parent": "Legal occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Judges, magistrates, and other judicial workers": {
    "ageData": {
      "2019": {
        "total": "70",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "10",
        "45to54": "21",
        "55to64": "22",
        "65+": "15",
        "medianAge": "22"
      }
    },
    "title": "Judges, magistrates, and other judicial workers",
    "depth": 4,
    "parent": "Legal occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "70",
        "women": "52.5",
        "white": "81.5",
        "black": "13.4",
        "asian": "4.8",
        "hispanicOrLatino": "8.6"
      }
    },
    "wageData": {
      "2019": {
        "workers": "61",
        "median": "1,794",
        "maleWorkers": "27",
        "maleMedian": "-",
        "femaleWorkers": "34",
        "femaleMedian": "-"
      }
    }
  },
  "Paralegals and legal assistants": {
    "ageData": {
      "2019": {
        "total": "444",
        "16to19": "2",
        "20to24": "37",
        "25to34": "115",
        "35to44": "83",
        "45to54": "84",
        "55to64": "92",
        "65+": "30",
        "medianAge": "92"
      }
    },
    "title": "Paralegals and legal assistants",
    "depth": 4,
    "parent": "Legal occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "444",
        "women": "89.6",
        "white": "77.1",
        "black": "11.8",
        "asian": "6.7",
        "hispanicOrLatino": "17.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "364",
        "median": "963",
        "maleWorkers": "41",
        "maleMedian": "-",
        "femaleWorkers": "323",
        "femaleMedian": "970"
      }
    }
  },
  "Miscellaneous legal support workers": {
    "ageData": {
      "2019": {
        "total": "193",
        "16to19": "0",
        "20to24": "12",
        "25to34": "51",
        "35to44": "36",
        "45to54": "46",
        "55to64": "30",
        "65+": "18",
        "medianAge": "30"
      }
    },
    "title": "Miscellaneous legal support workers",
    "depth": 4,
    "parent": "Legal occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "193",
        "women": "72.5",
        "white": "74.2",
        "black": "13.2",
        "asian": "8.0",
        "hispanicOrLatino": "13.9"
      }
    },
    "wageData": {
      "2019": {
        "workers": "157",
        "median": "1,124",
        "maleWorkers": "49",
        "maleMedian": "-",
        "femaleWorkers": "109",
        "femaleMedian": "1,009"
      }
    }
  },
  "Education, training, and library occupations": {
    "ageData": {
      "2019": {
        "total": "9,455",
        "16to19": "126",
        "20to24": "689",
        "25to34": "2,157",
        "35to44": "2,254",
        "45to54": "2,053",
        "55to64": "1,511",
        "65+": "664",
        "medianAge": "1,511"
      }
    },
    "title": "Education, training, and library occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "9,455",
        "women": "73.6",
        "white": "81.7",
        "black": "10.2",
        "asian": "5.3",
        "hispanicOrLatino": "11.0"
      }
    },
    "wageData": {
      "2019": {
        "workers": "7,238",
        "median": "1,057",
        "maleWorkers": "1,991",
        "maleMedian": "1,265",
        "femaleWorkers": "5,247",
        "femaleMedian": "987"
      }
    }
  },
  "Postsecondary teachers": {
    "ageData": {
      "2019": {
        "total": "1,386",
        "16to19": "6",
        "20to24": "103",
        "25to34": "284",
        "35to44": "312",
        "45to54": "258",
        "55to64": "251",
        "65+": "172",
        "medianAge": "251"
      }
    },
    "title": "Postsecondary teachers",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "1,386",
        "women": "47.4",
        "white": "75.3",
        "black": "7.0",
        "asian": "15.1",
        "hispanicOrLatino": "8.3"
      }
    },
    "wageData": {
      "2019": {
        "workers": "986",
        "median": "1,512",
        "maleWorkers": "544",
        "maleMedian": "1,670",
        "femaleWorkers": "442",
        "femaleMedian": "1,358"
      }
    }
  },
  "Preschool and kindergarten teachers": {
    "ageData": {
      "2019": {
        "total": "655",
        "16to19": "10",
        "20to24": "80",
        "25to34": "175",
        "35to44": "157",
        "45to54": "131",
        "55to64": "79",
        "65+": "23",
        "medianAge": "79"
      }
    },
    "title": "Preschool and kindergarten teachers",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "655",
        "women": "98.7",
        "white": "75.8",
        "black": "15.7",
        "asian": "5.2",
        "hispanicOrLatino": "14.9"
      }
    },
    "wageData": {
      "2019": {
        "workers": "496",
        "median": "701",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "489",
        "femaleMedian": "705"
      }
    }
  },
  "Elementary and middle school teachers": {
    "ageData": {
      "2019": {
        "total": "3,604",
        "16to19": "6",
        "20to24": "171",
        "25to34": "887",
        "35to44": "938",
        "45to54": "877",
        "55to64": "539",
        "65+": "185",
        "medianAge": "539"
      }
    },
    "title": "Elementary and middle school teachers",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "3,604",
        "women": "80.5",
        "white": "84.7",
        "black": "10.2",
        "asian": "2.6",
        "hispanicOrLatino": "10.2"
      }
    },
    "wageData": {
      "2019": {
        "workers": "3,162",
        "median": "1,067",
        "maleWorkers": "640",
        "maleMedian": "1,161",
        "femaleWorkers": "2,522",
        "femaleMedian": "1,042"
      }
    }
  },
  "Secondary school teachers": {
    "ageData": {
      "2019": {
        "total": "1,015",
        "16to19": "4",
        "20to24": "49",
        "25to34": "261",
        "35to44": "280",
        "45to54": "225",
        "55to64": "147",
        "65+": "50",
        "medianAge": "147"
      }
    },
    "title": "Secondary school teachers",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "1,015",
        "women": "56.5",
        "white": "87.1",
        "black": "7.3",
        "asian": "3.3",
        "hispanicOrLatino": "8.8"
      }
    },
    "wageData": {
      "2019": {
        "workers": "882",
        "median": "1,161",
        "maleWorkers": "408",
        "maleMedian": "1,262",
        "femaleWorkers": "474",
        "femaleMedian": "1,095"
      }
    }
  },
  "Special education teachers": {
    "ageData": {
      "2019": {
        "total": "331",
        "16to19": "1",
        "20to24": "14",
        "25to34": "74",
        "35to44": "84",
        "45to54": "86",
        "55to64": "58",
        "65+": "15",
        "medianAge": "58"
      }
    },
    "title": "Special education teachers",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "331",
        "women": "86.7",
        "white": "85.2",
        "black": "9.2",
        "asian": "3.5",
        "hispanicOrLatino": "6.5"
      }
    },
    "wageData": {
      "2019": {
        "workers": "295",
        "median": "1,108",
        "maleWorkers": "42",
        "maleMedian": "-",
        "femaleWorkers": "253",
        "femaleMedian": "1,104"
      }
    }
  },
  "Other teachers and instructors": {
    "ageData": {
      "2019": {
        "total": "1,017",
        "16to19": "63",
        "20to24": "123",
        "25to34": "205",
        "35to44": "187",
        "45to54": "169",
        "55to64": "158",
        "65+": "112",
        "medianAge": "158"
      }
    },
    "title": "Other teachers and instructors",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "1,017",
        "women": "64.6",
        "white": "78.4",
        "black": "11.7",
        "asian": "6.1",
        "hispanicOrLatino": "11.6"
      }
    },
    "wageData": {
      "2019": {
        "workers": "455",
        "median": "1,054",
        "maleWorkers": "192",
        "maleMedian": "1,190",
        "femaleWorkers": "263",
        "femaleMedian": "942"
      }
    }
  },
  "Archivists, curators, and museum technicians": {
    "ageData": {
      "2019": {
        "total": "48",
        "16to19": "1",
        "20to24": "5",
        "25to34": "10",
        "35to44": "11",
        "45to54": "10",
        "55to64": "5",
        "65+": "8",
        "medianAge": "5"
      }
    },
    "title": "Archivists, curators, and museum technicians",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "48",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "34",
        "median": "-",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "19",
        "femaleMedian": "-"
      }
    }
  },
  "Librarians": {
    "ageData": {
      "2019": {
        "total": "179",
        "16to19": "0",
        "20to24": "9",
        "25to34": "33",
        "35to44": "37",
        "45to54": "34",
        "55to64": "38",
        "65+": "26",
        "medianAge": "38"
      }
    },
    "title": "Librarians",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "179",
        "women": "79.9",
        "white": "87.8",
        "black": "6.0",
        "asian": "3.2",
        "hispanicOrLatino": "9.8"
      }
    },
    "wageData": {
      "2019": {
        "workers": "138",
        "median": "1,095",
        "maleWorkers": "27",
        "maleMedian": "-",
        "femaleWorkers": "111",
        "femaleMedian": "1,093"
      }
    }
  },
  "Library technicians": {
    "ageData": {
      "2019": {
        "total": "33",
        "16to19": "7",
        "20to24": "6",
        "25to34": "2",
        "35to44": "5",
        "45to54": "5",
        "55to64": "6",
        "65+": "1",
        "medianAge": "6"
      }
    },
    "title": "Library technicians",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "33",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "9",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Teacher assistants": {
    "ageData": {
      "2019": {
        "total": "999",
        "16to19": "25",
        "20to24": "115",
        "25to34": "186",
        "35to44": "202",
        "45to54": "217",
        "55to64": "197",
        "65+": "57",
        "medianAge": "197"
      }
    },
    "title": "Teacher assistants",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "999",
        "women": "89.7",
        "white": "79.0",
        "black": "13.4",
        "asian": "4.1",
        "hispanicOrLatino": "18.0"
      }
    },
    "wageData": {
      "2019": {
        "workers": "637",
        "median": "590",
        "maleWorkers": "72",
        "maleMedian": "707",
        "femaleWorkers": "565",
        "femaleMedian": "579"
      }
    }
  },
  "Other education, training, and library workers": {
    "ageData": {
      "2019": {
        "total": "189",
        "16to19": "2",
        "20to24": "15",
        "25to34": "41",
        "35to44": "40",
        "45to54": "42",
        "55to64": "34",
        "65+": "16",
        "medianAge": "34"
      }
    },
    "title": "Other education, training, and library workers",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "189",
        "women": "72.6",
        "white": "82.4",
        "black": "12.6",
        "asian": "3.5",
        "hispanicOrLatino": "12.2"
      }
    },
    "wageData": {
      "2019": {
        "workers": "143",
        "median": "1,143",
        "maleWorkers": "41",
        "maleMedian": "-",
        "femaleWorkers": "102",
        "femaleMedian": "1,058"
      }
    }
  },
  "Arts, design, entertainment, sports, and media occupations": {
    "ageData": {
      "2019": {
        "total": "3,285",
        "16to19": "100",
        "20to24": "290",
        "25to34": "910",
        "35to44": "690",
        "45to54": "586",
        "55to64": "437",
        "65+": "272",
        "medianAge": "437"
      }
    },
    "title": "Arts, design, entertainment, sports, and media occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "3,285",
        "women": "49.0",
        "white": "82.8",
        "black": "7.7",
        "asian": "5.9",
        "hispanicOrLatino": "11.6"
      }
    },
    "wageData": {
      "2019": {
        "workers": "1,762",
        "median": "1,151",
        "maleWorkers": "956",
        "maleMedian": "1,231",
        "femaleWorkers": "806",
        "femaleMedian": "1,077"
      }
    }
  },
  "Artists and related workers": {
    "ageData": {
      "2019": {
        "total": "248",
        "16to19": "4",
        "20to24": "11",
        "25to34": "64",
        "35to44": "51",
        "45to54": "39",
        "55to64": "45",
        "65+": "34",
        "medianAge": "45"
      }
    },
    "title": "Artists and related workers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "248",
        "women": "54.0",
        "white": "86.8",
        "black": "4.5",
        "asian": "3.8",
        "hispanicOrLatino": "11.1"
      }
    },
    "wageData": {
      "2019": {
        "workers": "83",
        "median": "1,155",
        "maleWorkers": "48",
        "maleMedian": "-",
        "femaleWorkers": "35",
        "femaleMedian": "-"
      }
    }
  },
  "Designers": {
    "ageData": {
      "2019": {
        "total": "983",
        "16to19": "10",
        "20to24": "80",
        "25to34": "299",
        "35to44": "226",
        "45to54": "169",
        "55to64": "133",
        "65+": "66",
        "medianAge": "133"
      }
    },
    "title": "Designers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "983",
        "women": "54.0",
        "white": "82.2",
        "black": "5.7",
        "asian": "9.0",
        "hispanicOrLatino": "11.0"
      }
    },
    "wageData": {
      "2019": {
        "workers": "661",
        "median": "1,126",
        "maleWorkers": "337",
        "maleMedian": "1,214",
        "femaleWorkers": "324",
        "femaleMedian": "1,052"
      }
    }
  },
  "Actors": {
    "ageData": {
      "2019": {
        "total": "40",
        "16to19": "2",
        "20to24": "3",
        "25to34": "11",
        "35to44": "9",
        "45to54": "7",
        "55to64": "6",
        "65+": "2",
        "medianAge": "6"
      }
    },
    "title": "Actors",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "40",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Producers and directors": {
    "ageData": {
      "2019": {
        "total": "186",
        "16to19": "0",
        "20to24": "5",
        "25to34": "64",
        "35to44": "44",
        "45to54": "47",
        "55to64": "21",
        "65+": "5",
        "medianAge": "21"
      }
    },
    "title": "Producers and directors",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "186",
        "women": "42.5",
        "white": "86.7",
        "black": "6.4",
        "asian": "3.5",
        "hispanicOrLatino": "8.0"
      }
    },
    "wageData": {
      "2019": {
        "workers": "109",
        "median": "1,483",
        "maleWorkers": "59",
        "maleMedian": "1,652",
        "femaleWorkers": "50",
        "femaleMedian": "1,156"
      }
    }
  },
  "Athletes, coaches, umpires, and related workers": {
    "ageData": {
      "2019": {
        "total": "345",
        "16to19": "65",
        "20to24": "53",
        "25to34": "84",
        "35to44": "50",
        "45to54": "43",
        "55to64": "23",
        "65+": "26",
        "medianAge": "23"
      }
    },
    "title": "Athletes, coaches, umpires, and related workers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "345",
        "women": "35.3",
        "white": "81.1",
        "black": "9.3",
        "asian": "4.3",
        "hispanicOrLatino": "10.8"
      }
    },
    "wageData": {
      "2019": {
        "workers": "157",
        "median": "1,051",
        "maleWorkers": "122",
        "maleMedian": "1,134",
        "femaleWorkers": "35",
        "femaleMedian": "-"
      }
    }
  },
  "Dancers and choreographers": {
    "ageData": {
      "2019": {
        "total": "23",
        "16to19": "1",
        "20to24": "6",
        "25to34": "9",
        "35to44": "2",
        "45to54": "1",
        "55to64": "1",
        "65+": "2",
        "medianAge": "1"
      }
    },
    "title": "Dancers and choreographers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "23",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Musicians, singers, and related workers": {
    "ageData": {
      "2019": {
        "total": "202",
        "16to19": "4",
        "20to24": "17",
        "25to34": "42",
        "35to44": "35",
        "45to54": "36",
        "55to64": "29",
        "65+": "41",
        "medianAge": "29"
      }
    },
    "title": "Musicians, singers, and related workers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "202",
        "women": "36.7",
        "white": "78.9",
        "black": "12.4",
        "asian": "3.9",
        "hispanicOrLatino": "9.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "36",
        "median": "-",
        "maleWorkers": "26",
        "maleMedian": "-",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Entertainers and performers, sports and related workers, all other": {
    "ageData": {
      "2019": {
        "total": "50",
        "16to19": "1",
        "20to24": "3",
        "25to34": "21",
        "35to44": "8",
        "45to54": "6",
        "55to64": "6",
        "65+": "5",
        "medianAge": "6"
      }
    },
    "title": "Entertainers and performers, sports and related workers, all other",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "50",
        "women": "32.8",
        "white": "86.4",
        "black": "5.0",
        "asian": "0.3",
        "hispanicOrLatino": "7.3"
      }
    },
    "wageData": {
      "2019": {
        "workers": "15",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Announcers": {
    "ageData": {
      "2019": {
        "total": "58",
        "16to19": "1",
        "20to24": "3",
        "25to34": "16",
        "35to44": "14",
        "45to54": "14",
        "55to64": "8",
        "65+": "3",
        "medianAge": "8"
      }
    },
    "title": "Announcers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "58",
        "women": "30.4",
        "white": "75.6",
        "black": "15.7",
        "asian": "5.3",
        "hispanicOrLatino": "19.2"
      }
    },
    "wageData": {
      "2019": {
        "workers": "20",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "9",
        "femaleMedian": "-"
      }
    }
  },
  "News analysts, reporters and correspondents": {
    "ageData": {
      "2019": {
        "total": "93",
        "16to19": "0",
        "20to24": "10",
        "25to34": "32",
        "35to44": "15",
        "45to54": "18",
        "55to64": "14",
        "65+": "4",
        "medianAge": "14"
      }
    },
    "title": "News analysts, reporters and correspondents",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "93",
        "women": "45.1",
        "white": "82.4",
        "black": "6.9",
        "asian": "9.7",
        "hispanicOrLatino": "9.3"
      }
    },
    "wageData": {
      "2019": {
        "workers": "77",
        "median": "1,301",
        "maleWorkers": "40",
        "maleMedian": "-",
        "femaleWorkers": "37",
        "femaleMedian": "-"
      }
    }
  },
  "Public relations specialists": {
    "ageData": {
      "2019": {
        "total": "137",
        "16to19": "1",
        "20to24": "19",
        "25to34": "33",
        "35to44": "33",
        "45to54": "30",
        "55to64": "14",
        "65+": "6",
        "medianAge": "14"
      }
    },
    "title": "Public relations specialists",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "137",
        "women": "63.6",
        "white": "83.6",
        "black": "9.9",
        "asian": "5.8",
        "hispanicOrLatino": "13.6"
      }
    },
    "wageData": {
      "2019": {
        "workers": "119",
        "median": "1,410",
        "maleWorkers": "41",
        "maleMedian": "-",
        "femaleWorkers": "78",
        "femaleMedian": "1,255"
      }
    }
  },
  "Editors": {
    "ageData": {
      "2019": {
        "total": "147",
        "16to19": "1",
        "20to24": "13",
        "25to34": "29",
        "35to44": "31",
        "45to54": "33",
        "55to64": "29",
        "65+": "10",
        "medianAge": "29"
      }
    },
    "title": "Editors",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "147",
        "women": "63.3",
        "white": "91.3",
        "black": "3.0",
        "asian": "2.4",
        "hispanicOrLatino": "10.6"
      }
    },
    "wageData": {
      "2019": {
        "workers": "98",
        "median": "1,163",
        "maleWorkers": "32",
        "maleMedian": "-",
        "femaleWorkers": "66",
        "femaleMedian": "1,135"
      }
    }
  },
  "Technical writers": {
    "ageData": {
      "2019": {
        "total": "61",
        "16to19": "0",
        "20to24": "2",
        "25to34": "16",
        "35to44": "10",
        "45to54": "14",
        "55to64": "13",
        "65+": "7",
        "medianAge": "13"
      }
    },
    "title": "Technical writers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "61",
        "women": "57.8",
        "white": "84.2",
        "black": "11.8",
        "asian": "2.9",
        "hispanicOrLatino": "8.8"
      }
    },
    "wageData": {
      "2019": {
        "workers": "54",
        "median": "1,434",
        "maleWorkers": "26",
        "maleMedian": "-",
        "femaleWorkers": "28",
        "femaleMedian": "-"
      }
    }
  },
  "Writers and authors": {
    "ageData": {
      "2019": {
        "total": "225",
        "16to19": "0",
        "20to24": "15",
        "25to34": "58",
        "35to44": "49",
        "45to54": "40",
        "55to64": "33",
        "65+": "29",
        "medianAge": "33"
      }
    },
    "title": "Writers and authors",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "225",
        "women": "63.5",
        "white": "89.6",
        "black": "5.9",
        "asian": "3.4",
        "hispanicOrLatino": "4.8"
      }
    },
    "wageData": {
      "2019": {
        "workers": "88",
        "median": "1,159",
        "maleWorkers": "41",
        "maleMedian": "-",
        "femaleWorkers": "48",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous media and communication workers": {
    "ageData": {
      "2019": {
        "total": "118",
        "16to19": "1",
        "20to24": "4",
        "25to34": "28",
        "35to44": "28",
        "45to54": "28",
        "55to64": "20",
        "65+": "8",
        "medianAge": "20"
      }
    },
    "title": "Miscellaneous media and communication workers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "118",
        "women": "66.4",
        "white": "67.8",
        "black": "14.6",
        "asian": "11.2",
        "hispanicOrLatino": "35.3"
      }
    },
    "wageData": {
      "2019": {
        "workers": "56",
        "median": "794",
        "maleWorkers": "22",
        "maleMedian": "-",
        "femaleWorkers": "34",
        "femaleMedian": "-"
      }
    }
  },
  "Broadcast and sound engineering technicians and radio operators": {
    "ageData": {
      "2019": {
        "total": "119",
        "16to19": "5",
        "20to24": "16",
        "25to34": "32",
        "35to44": "32",
        "45to54": "21",
        "55to64": "10",
        "65+": "4",
        "medianAge": "10"
      }
    },
    "title": "Broadcast and sound engineering technicians and radio operators",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "119",
        "women": "12.0",
        "white": "78.3",
        "black": "9.1",
        "asian": "7.9",
        "hispanicOrLatino": "14.0"
      }
    },
    "wageData": {
      "2019": {
        "workers": "90",
        "median": "1,158",
        "maleWorkers": "79",
        "maleMedian": "1,189",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "Photographers": {
    "ageData": {
      "2019": {
        "total": "188",
        "16to19": "2",
        "20to24": "20",
        "25to34": "52",
        "35to44": "39",
        "45to54": "32",
        "55to64": "29",
        "65+": "14",
        "medianAge": "29"
      }
    },
    "title": "Photographers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "188",
        "women": "49.3",
        "white": "84.3",
        "black": "8.6",
        "asian": "3.5",
        "hispanicOrLatino": "12.3"
      }
    },
    "wageData": {
      "2019": {
        "workers": "45",
        "median": "-",
        "maleWorkers": "29",
        "maleMedian": "-",
        "femaleWorkers": "16",
        "femaleMedian": "-"
      }
    }
  },
  "Television, video, and motion picture camera operators and editors": {
    "ageData": {
      "2019": {
        "total": "60",
        "16to19": "2",
        "20to24": "9",
        "25to34": "19",
        "35to44": "14",
        "45to54": "7",
        "55to64": "4",
        "65+": "5",
        "medianAge": "4"
      }
    },
    "title": "Television, video, and motion picture camera operators and editors",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "60",
        "women": "16.3",
        "white": "83.0",
        "black": "10.7",
        "asian": "0.6",
        "hispanicOrLatino": "12.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "27",
        "median": "-",
        "maleWorkers": "20",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Media and communication equipment workers, all other": {
    "ageData": {
      "2019": {
        "total": "0",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Media and communication equipment workers, all other",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "0",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "0",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Healthcare practitioners and technical occupations": {
    "ageData": {
      "2019": {
        "total": "9,684",
        "16to19": "48",
        "20to24": "532",
        "25to34": "2,586",
        "35to44": "2,373",
        "45to54": "1,990",
        "55to64": "1,576",
        "65+": "579",
        "medianAge": "1,576"
      }
    },
    "title": "Healthcare practitioners and technical occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "9,684",
        "women": "75.4",
        "white": "75.3",
        "black": "12.5",
        "asian": "9.6",
        "hispanicOrLatino": "9.0"
      }
    },
    "wageData": {
      "2019": {
        "workers": "7,514",
        "median": "1,180",
        "maleWorkers": "1,887",
        "maleMedian": "1,422",
        "femaleWorkers": "5,627",
        "femaleMedian": "1,130"
      }
    }
  },
  "Chiropractors": {
    "ageData": {
      "2019": {
        "total": "61",
        "16to19": "0",
        "20to24": "1",
        "25to34": "11",
        "35to44": "17",
        "45to54": "15",
        "55to64": "14",
        "65+": "4",
        "medianAge": "14"
      }
    },
    "title": "Chiropractors",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "61",
        "women": "28.0",
        "white": "86.1",
        "black": "2.8",
        "asian": "5.3",
        "hispanicOrLatino": "6.4"
      }
    },
    "wageData": {
      "2019": {
        "workers": "19",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Dentists": {
    "ageData": {
      "2019": {
        "total": "162",
        "16to19": "0",
        "20to24": "0",
        "25to34": "32",
        "35to44": "35",
        "45to54": "41",
        "55to64": "30",
        "65+": "23",
        "medianAge": "30"
      }
    },
    "title": "Dentists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "162",
        "women": "33.9",
        "white": "74.3",
        "black": "0.8",
        "asian": "23.6",
        "hispanicOrLatino": "7.2"
      }
    },
    "wageData": {
      "2019": {
        "workers": "71",
        "median": "2,302",
        "maleWorkers": "43",
        "maleMedian": "-",
        "femaleWorkers": "28",
        "femaleMedian": "-"
      }
    }
  },
  "Dietitians and nutritionists": {
    "ageData": {
      "2019": {
        "total": "128",
        "16to19": "3",
        "20to24": "4",
        "25to34": "33",
        "35to44": "20",
        "45to54": "34",
        "55to64": "23",
        "65+": "11",
        "medianAge": "23"
      }
    },
    "title": "Dietitians and nutritionists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "128",
        "women": "92.1",
        "white": "77.9",
        "black": "15.2",
        "asian": "5.9",
        "hispanicOrLatino": "9.0"
      }
    },
    "wageData": {
      "2019": {
        "workers": "94",
        "median": "1,019",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "85",
        "femaleMedian": "948"
      }
    }
  },
  "Optometrists": {
    "ageData": {
      "2019": {
        "total": "43",
        "16to19": "0",
        "20to24": "1",
        "25to34": "16",
        "35to44": "10",
        "45to54": "8",
        "55to64": "4",
        "65+": "5",
        "medianAge": "4"
      }
    },
    "title": "Optometrists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "43",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "27",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "15",
        "femaleMedian": "-"
      }
    }
  },
  "Pharmacists": {
    "ageData": {
      "2019": {
        "total": "341",
        "16to19": "0",
        "20to24": "11",
        "25to34": "109",
        "35to44": "90",
        "45to54": "69",
        "55to64": "43",
        "65+": "19",
        "medianAge": "43"
      }
    },
    "title": "Pharmacists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "341",
        "women": "60.4",
        "white": "64.5",
        "black": "10.5",
        "asian": "22.0",
        "hispanicOrLatino": "2.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "255",
        "median": "2,013",
        "maleWorkers": "100",
        "maleMedian": "2,245",
        "femaleWorkers": "155",
        "femaleMedian": "1,877"
      }
    }
  },
  "Physicians and surgeons": {
    "ageData": {
      "2019": {
        "total": "1,098",
        "16to19": "0",
        "20to24": "5",
        "25to34": "250",
        "35to44": "272",
        "45to54": "250",
        "55to64": "194",
        "65+": "127",
        "medianAge": "194"
      }
    },
    "title": "Physicians and surgeons",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "1,098",
        "women": "40.8",
        "white": "72.0",
        "black": "8.2",
        "asian": "18.0",
        "hispanicOrLatino": "7.6"
      }
    },
    "wageData": {
      "2019": {
        "workers": "857",
        "median": "2,292",
        "maleWorkers": "507",
        "maleMedian": "2,500",
        "femaleWorkers": "349",
        "femaleMedian": "1,878"
      }
    }
  },
  "Physician assistants": {
    "ageData": {
      "2019": {
        "total": "131",
        "16to19": "0",
        "20to24": "2",
        "25to34": "56",
        "35to44": "35",
        "45to54": "27",
        "55to64": "9",
        "65+": "2",
        "medianAge": "9"
      }
    },
    "title": "Physician assistants",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "131",
        "women": "70.8",
        "white": "84.3",
        "black": "4.5",
        "asian": "6.8",
        "hispanicOrLatino": "8.6"
      }
    },
    "wageData": {
      "2019": {
        "workers": "109",
        "median": "1,831",
        "maleWorkers": "31",
        "maleMedian": "-",
        "femaleWorkers": "77",
        "femaleMedian": "1,763"
      }
    }
  },
  "Podiatrists": {
    "ageData": {
      "2019": {
        "total": "10",
        "16to19": "0",
        "20to24": "0",
        "25to34": "4",
        "35to44": "0",
        "45to54": "3",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Podiatrists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "10",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Audiologists": {
    "ageData": {
      "2019": {
        "total": "14",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "5",
        "45to54": "2",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Audiologists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "14",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "11",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Occupational therapists": {
    "ageData": {
      "2019": {
        "total": "136",
        "16to19": "0",
        "20to24": "2",
        "25to34": "42",
        "35to44": "42",
        "45to54": "33",
        "55to64": "13",
        "65+": "3",
        "medianAge": "13"
      }
    },
    "title": "Occupational therapists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "136",
        "women": "88.0",
        "white": "75.7",
        "black": "10.2",
        "asian": "12.4",
        "hispanicOrLatino": "10.2"
      }
    },
    "wageData": {
      "2019": {
        "workers": "92",
        "median": "1,447",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "79",
        "femaleMedian": "1,441"
      }
    }
  },
  "Physical therapists": {
    "ageData": {
      "2019": {
        "total": "304",
        "16to19": "0",
        "20to24": "5",
        "25to34": "84",
        "35to44": "95",
        "45to54": "74",
        "55to64": "37",
        "65+": "10",
        "medianAge": "37"
      }
    },
    "title": "Physical therapists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "304",
        "women": "67.9",
        "white": "76.4",
        "black": "5.9",
        "asian": "14.1",
        "hispanicOrLatino": "4.0"
      }
    },
    "wageData": {
      "2019": {
        "workers": "211",
        "median": "1,431",
        "maleWorkers": "81",
        "maleMedian": "1,610",
        "femaleWorkers": "130",
        "femaleMedian": "1,323"
      }
    }
  },
  "Radiation therapists": {
    "ageData": {
      "2019": {
        "total": "16",
        "16to19": "0",
        "20to24": "2",
        "25to34": "8",
        "35to44": "2",
        "45to54": "3",
        "55to64": "0",
        "65+": "1",
        "medianAge": "0"
      }
    },
    "title": "Radiation therapists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "16",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "9",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Recreational therapists": {
    "ageData": {
      "2019": {
        "total": "10",
        "16to19": "0",
        "20to24": "2",
        "25to34": "2",
        "35to44": "1",
        "45to54": "2",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Recreational therapists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "10",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "9",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Respiratory therapists": {
    "ageData": {
      "2019": {
        "total": "112",
        "16to19": "0",
        "20to24": "2",
        "25to34": "27",
        "35to44": "32",
        "45to54": "28",
        "55to64": "21",
        "65+": "3",
        "medianAge": "21"
      }
    },
    "title": "Respiratory therapists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "112",
        "women": "66.8",
        "white": "66.2",
        "black": "23.2",
        "asian": "8.9",
        "hispanicOrLatino": "8.6"
      }
    },
    "wageData": {
      "2019": {
        "workers": "93",
        "median": "1,213",
        "maleWorkers": "34",
        "maleMedian": "-",
        "femaleWorkers": "59",
        "femaleMedian": "1,073"
      }
    }
  },
  "Speech-language pathologists": {
    "ageData": {
      "2019": {
        "total": "180",
        "16to19": "0",
        "20to24": "9",
        "25to34": "58",
        "35to44": "49",
        "45to54": "33",
        "55to64": "22",
        "65+": "8",
        "medianAge": "22"
      }
    },
    "title": "Speech-language pathologists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "180",
        "women": "95.8",
        "white": "93.7",
        "black": "3.9",
        "asian": "2.2",
        "hispanicOrLatino": "6.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "131",
        "median": "1,193",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "124",
        "femaleMedian": "1,171"
      }
    }
  },
  "Exercise physiologists": {
    "ageData": {
      "2019": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Exercise physiologists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Therapists, all other": {
    "ageData": {
      "2019": {
        "total": "235",
        "16to19": "2",
        "20to24": "14",
        "25to34": "77",
        "35to44": "44",
        "45to54": "40",
        "55to64": "36",
        "65+": "22",
        "medianAge": "36"
      }
    },
    "title": "Therapists, all other",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "235",
        "women": "81.4",
        "white": "81.1",
        "black": "12.2",
        "asian": "5.1",
        "hispanicOrLatino": "11.9"
      }
    },
    "wageData": {
      "2019": {
        "workers": "162",
        "median": "935",
        "maleWorkers": "31",
        "maleMedian": "-",
        "femaleWorkers": "131",
        "femaleMedian": "919"
      }
    }
  },
  "Veterinarians": {
    "ageData": {
      "2019": {
        "total": "104",
        "16to19": "0",
        "20to24": "1",
        "25to34": "21",
        "35to44": "33",
        "45to54": "21",
        "55to64": "21",
        "65+": "7",
        "medianAge": "21"
      }
    },
    "title": "Veterinarians",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "104",
        "women": "68.3",
        "white": "89.8",
        "black": "0.0",
        "asian": "6.1",
        "hispanicOrLatino": "1.6"
      }
    },
    "wageData": {
      "2019": {
        "workers": "72",
        "median": "1,773",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "55",
        "femaleMedian": "1,985"
      }
    }
  },
  "Registered nurses": {
    "ageData": {
      "2019": {
        "total": "3,242",
        "16to19": "2",
        "20to24": "138",
        "25to34": "860",
        "35to44": "803",
        "45to54": "684",
        "55to64": "586",
        "65+": "168",
        "medianAge": "586"
      }
    },
    "title": "Registered nurses",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "3,242",
        "women": "88.9",
        "white": "75.9",
        "black": "12.4",
        "asian": "9.2",
        "hispanicOrLatino": "7.2"
      }
    },
    "wageData": {
      "2019": {
        "workers": "2,640",
        "median": "1,223",
        "maleWorkers": "320",
        "maleMedian": "1,256",
        "femaleWorkers": "2,321",
        "femaleMedian": "1,217"
      }
    }
  },
  "Nurse anesthetists": {
    "ageData": {
      "2019": {
        "total": "33",
        "16to19": "0",
        "20to24": "0",
        "25to34": "8",
        "35to44": "10",
        "45to54": "8",
        "55to64": "4",
        "65+": "3",
        "medianAge": "4"
      }
    },
    "title": "Nurse anesthetists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "33",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "22",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Nurse midwives": {
    "ageData": {
      "2019": {
        "total": "10",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "4",
        "45to54": "2",
        "55to64": "1",
        "65+": "2",
        "medianAge": "1"
      }
    },
    "title": "Nurse midwives",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "10",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Nurse practitioners": {
    "ageData": {
      "2019": {
        "total": "225",
        "16to19": "0",
        "20to24": "0",
        "25to34": "51",
        "35to44": "71",
        "45to54": "50",
        "55to64": "38",
        "65+": "16",
        "medianAge": "38"
      }
    },
    "title": "Nurse practitioners",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "225",
        "women": "87.8",
        "white": "76.6",
        "black": "12.2",
        "asian": "9.9",
        "hispanicOrLatino": "2.6"
      }
    },
    "wageData": {
      "2019": {
        "workers": "187",
        "median": "1,760",
        "maleWorkers": "23",
        "maleMedian": "-",
        "femaleWorkers": "163",
        "femaleMedian": "1,753"
      }
    }
  },
  "Health diagnosing and treating practitioners, all other": {
    "ageData": {
      "2019": {
        "total": "35",
        "16to19": "0",
        "20to24": "0",
        "25to34": "8",
        "35to44": "8",
        "45to54": "7",
        "55to64": "5",
        "65+": "7",
        "medianAge": "5"
      }
    },
    "title": "Health diagnosing and treating practitioners, all other",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "35",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Clinical laboratory technologists and technicians": {
    "ageData": {
      "2019": {
        "total": "323",
        "16to19": "4",
        "20to24": "29",
        "25to34": "75",
        "35to44": "77",
        "45to54": "64",
        "55to64": "58",
        "65+": "16",
        "medianAge": "58"
      }
    },
    "title": "Clinical laboratory technologists and technicians",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "323",
        "women": "73.8",
        "white": "71.1",
        "black": "15.3",
        "asian": "10.0",
        "hispanicOrLatino": "13.0"
      }
    },
    "wageData": {
      "2019": {
        "workers": "281",
        "median": "979",
        "maleWorkers": "66",
        "maleMedian": "1,117",
        "femaleWorkers": "215",
        "femaleMedian": "952"
      }
    }
  },
  "Dental hygienists": {
    "ageData": {
      "2019": {
        "total": "208",
        "16to19": "0",
        "20to24": "10",
        "25to34": "67",
        "35to44": "39",
        "45to54": "44",
        "55to64": "41",
        "65+": "7",
        "medianAge": "41"
      }
    },
    "title": "Dental hygienists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "208",
        "women": "96.0",
        "white": "88.2",
        "black": "5.1",
        "asian": "5.0",
        "hispanicOrLatino": "11.3"
      }
    },
    "wageData": {
      "2019": {
        "workers": "104",
        "median": "1,094",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "99",
        "femaleMedian": "1,066"
      }
    }
  },
  "Diagnostic related technologists and technicians": {
    "ageData": {
      "2019": {
        "total": "398",
        "16to19": "1",
        "20to24": "15",
        "25to34": "103",
        "35to44": "109",
        "45to54": "83",
        "55to64": "70",
        "65+": "17",
        "medianAge": "70"
      }
    },
    "title": "Diagnostic related technologists and technicians",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "398",
        "women": "70.0",
        "white": "76.7",
        "black": "12.8",
        "asian": "5.8",
        "hispanicOrLatino": "9.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "320",
        "median": "1,126",
        "maleWorkers": "107",
        "maleMedian": "1,166",
        "femaleWorkers": "213",
        "femaleMedian": "1,076"
      }
    }
  },
  "Emergency medical technicians and paramedics": {
    "ageData": {
      "2019": {
        "total": "206",
        "16to19": "1",
        "20to24": "33",
        "25to34": "81",
        "35to44": "44",
        "45to54": "27",
        "55to64": "14",
        "65+": "5",
        "medianAge": "14"
      }
    },
    "title": "Emergency medical technicians and paramedics",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "206",
        "women": "33.5",
        "white": "86.6",
        "black": "10.5",
        "asian": "0.8",
        "hispanicOrLatino": "11.5"
      }
    },
    "wageData": {
      "2019": {
        "workers": "179",
        "median": "912",
        "maleWorkers": "125",
        "maleMedian": "991",
        "femaleWorkers": "54",
        "femaleMedian": "786"
      }
    }
  },
  "Health practitioner support technologists and technicians": {
    "ageData": {
      "2019": {
        "total": "710",
        "16to19": "19",
        "20to24": "131",
        "25to34": "234",
        "35to44": "138",
        "45to54": "92",
        "55to64": "78",
        "65+": "18",
        "medianAge": "78"
      }
    },
    "title": "Health practitioner support technologists and technicians",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "710",
        "women": "79.0",
        "white": "74.7",
        "black": "15.6",
        "asian": "5.4",
        "hispanicOrLatino": "16.5"
      }
    },
    "wageData": {
      "2019": {
        "workers": "570",
        "median": "735",
        "maleWorkers": "126",
        "maleMedian": "843",
        "femaleWorkers": "444",
        "femaleMedian": "712"
      }
    }
  },
  "Licensed practical and licensed vocational nurses": {
    "ageData": {
      "2019": {
        "total": "687",
        "16to19": "10",
        "20to24": "62",
        "25to34": "150",
        "35to44": "161",
        "45to54": "144",
        "55to64": "117",
        "65+": "43",
        "medianAge": "117"
      }
    },
    "title": "Licensed practical and licensed vocational nurses",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "687",
        "women": "90.8",
        "white": "67.0",
        "black": "27.0",
        "asian": "3.3",
        "hispanicOrLatino": "13.8"
      }
    },
    "wageData": {
      "2019": {
        "workers": "533",
        "median": "806",
        "maleWorkers": "53",
        "maleMedian": "896",
        "femaleWorkers": "481",
        "femaleMedian": "799"
      }
    }
  },
  "Medical records and health information technicians": {
    "ageData": {
      "2019": {
        "total": "185",
        "16to19": "2",
        "20to24": "9",
        "25to34": "32",
        "35to44": "49",
        "45to54": "42",
        "55to64": "40",
        "65+": "11",
        "medianAge": "40"
      }
    },
    "title": "Medical records and health information technicians",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "185",
        "women": "93.3",
        "white": "72.8",
        "black": "17.5",
        "asian": "6.0",
        "hispanicOrLatino": "14.4"
      }
    },
    "wageData": {
      "2019": {
        "workers": "152",
        "median": "829",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "138",
        "femaleMedian": "799"
      }
    }
  },
  "Opticians, dispensing": {
    "ageData": {
      "2019": {
        "total": "49",
        "16to19": "0",
        "20to24": "0",
        "25to34": "7",
        "35to44": "8",
        "45to54": "11",
        "55to64": "15",
        "65+": "7",
        "medianAge": "15"
      }
    },
    "title": "Opticians, dispensing",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "49",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "34",
        "median": "-",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "26",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous health technologists and technicians": {
    "ageData": {
      "2019": {
        "total": "152",
        "16to19": "3",
        "20to24": "30",
        "25to34": "38",
        "35to44": "33",
        "45to54": "22",
        "55to64": "19",
        "65+": "7",
        "medianAge": "19"
      }
    },
    "title": "Miscellaneous health technologists and technicians",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "152",
        "women": "65.0",
        "white": "64.6",
        "black": "19.6",
        "asian": "13.7",
        "hispanicOrLatino": "16.3"
      }
    },
    "wageData": {
      "2019": {
        "workers": "130",
        "median": "880",
        "maleWorkers": "46",
        "maleMedian": "-",
        "femaleWorkers": "84",
        "femaleMedian": "799"
      }
    }
  },
  "Other healthcare practitioners and technical occupations": {
    "ageData": {
      "2019": {
        "total": "133",
        "16to19": "0",
        "20to24": "12",
        "25to34": "36",
        "35to44": "36",
        "45to54": "26",
        "55to64": "18",
        "65+": "6",
        "medianAge": "18"
      }
    },
    "title": "Other healthcare practitioners and technical occupations",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "133",
        "women": "49.5",
        "white": "80.9",
        "black": "12.5",
        "asian": "4.3",
        "hispanicOrLatino": "12.1"
      }
    },
    "wageData": {
      "2019": {
        "workers": "117",
        "median": "1,252",
        "maleWorkers": "69",
        "maleMedian": "1,261",
        "femaleWorkers": "48",
        "femaleMedian": "-"
      }
    }
  },
  "Service occupations": {
    "ageData": {
      "2019": {
        "total": "26,978",
        "16to19": "2,126",
        "20to24": "3,815",
        "25to34": "6,091",
        "35to44": "4,847",
        "45to54": "4,705",
        "55to64": "3,855",
        "65+": "1,539",
        "medianAge": "3,855"
      }
    },
    "title": "Service occupations",
    "depth": 1,
    "parent": "",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "26,978",
        "women": "57.6",
        "white": "72.2",
        "black": "17.1",
        "asian": "5.9",
        "hispanicOrLatino": "25.0"
      }
    },
    "wageData": {
      "2019": {
        "workers": "16,558",
        "median": "592",
        "maleWorkers": "7,898",
        "maleMedian": "659",
        "femaleWorkers": "8,660",
        "femaleMedian": "537"
      }
    }
  },
  "Healthcare support occupations": {
    "ageData": {
      "2019": {
        "total": "3,758",
        "16to19": "104",
        "20to24": "521",
        "25to34": "1,019",
        "35to44": "705",
        "45to54": "648",
        "55to64": "571",
        "65+": "189",
        "medianAge": "571"
      }
    },
    "title": "Healthcare support occupations",
    "depth": 2,
    "parent": "Service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "3,758",
        "women": "86.9",
        "white": "64.2",
        "black": "26.7",
        "asian": "5.1",
        "hispanicOrLatino": "19.1"
      }
    },
    "wageData": {
      "2019": {
        "workers": "2,700",
        "median": "591",
        "maleWorkers": "363",
        "maleMedian": "639",
        "femaleWorkers": "2,337",
        "femaleMedian": "585"
      }
    }
  },
  "Nursing, psychiatric, and home health aides": {
    "ageData": {
      "2019": {
        "total": "2,086",
        "16to19": "61",
        "20to24": "261",
        "25to34": "498",
        "35to44": "390",
        "45to54": "381",
        "55to64": "363",
        "65+": "132",
        "medianAge": "363"
      }
    },
    "title": "Nursing, psychiatric, and home health aides",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "2,086",
        "women": "88.3",
        "white": "54.3",
        "black": "37.2",
        "asian": "4.4",
        "hispanicOrLatino": "17.6"
      }
    },
    "wageData": {
      "2019": {
        "workers": "1,471",
        "median": "562",
        "maleWorkers": "184",
        "maleMedian": "588",
        "femaleWorkers": "1,287",
        "femaleMedian": "556"
      }
    }
  },
  "Occupational therapy assistants and aides": {
    "ageData": {
      "2019": {
        "total": "29",
        "16to19": "0",
        "20to24": "1",
        "25to34": "9",
        "35to44": "5",
        "45to54": "7",
        "55to64": "8",
        "65+": "1",
        "medianAge": "8"
      }
    },
    "title": "Occupational therapy assistants and aides",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "29",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "23",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "19",
        "femaleMedian": "-"
      }
    }
  },
  "Physical therapist assistants and aides": {
    "ageData": {
      "2019": {
        "total": "101",
        "16to19": "0",
        "20to24": "20",
        "25to34": "22",
        "35to44": "13",
        "45to54": "25",
        "55to64": "18",
        "65+": "2",
        "medianAge": "18"
      }
    },
    "title": "Physical therapist assistants and aides",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "101",
        "women": "63.4",
        "white": "83.2",
        "black": "6.8",
        "asian": "6.5",
        "hispanicOrLatino": "7.2"
      }
    },
    "wageData": {
      "2019": {
        "workers": "72",
        "median": "955",
        "maleWorkers": "29",
        "maleMedian": "-",
        "femaleWorkers": "43",
        "femaleMedian": "-"
      }
    }
  },
  "Massage therapists": {
    "ageData": {
      "2019": {
        "total": "183",
        "16to19": "2",
        "20to24": "7",
        "25to34": "49",
        "35to44": "43",
        "45to54": "41",
        "55to64": "26",
        "65+": "16",
        "medianAge": "26"
      }
    },
    "title": "Massage therapists",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "183",
        "women": "83.6",
        "white": "72.3",
        "black": "8.8",
        "asian": "13.1",
        "hispanicOrLatino": "11.1"
      }
    },
    "wageData": {
      "2019": {
        "workers": "57",
        "median": "582",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "42",
        "femaleMedian": "-"
      }
    }
  },
  "Dental assistants": {
    "ageData": {
      "2019": {
        "total": "284",
        "16to19": "6",
        "20to24": "54",
        "25to34": "82",
        "35to44": "65",
        "45to54": "42",
        "55to64": "30",
        "65+": "5",
        "medianAge": "30"
      }
    },
    "title": "Dental assistants",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "284",
        "women": "94.9",
        "white": "85.5",
        "black": "6.7",
        "asian": "3.1",
        "hispanicOrLatino": "28.9"
      }
    },
    "wageData": {
      "2019": {
        "workers": "199",
        "median": "615",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "193",
        "femaleMedian": "613"
      }
    }
  },
  "Medical assistants": {
    "ageData": {
      "2019": {
        "total": "596",
        "16to19": "13",
        "20to24": "84",
        "25to34": "230",
        "35to44": "113",
        "45to54": "82",
        "55to64": "61",
        "65+": "13",
        "medianAge": "61"
      }
    },
    "title": "Medical assistants",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "596",
        "women": "92.7",
        "white": "75.1",
        "black": "15.3",
        "asian": "4.8",
        "hispanicOrLatino": "29.0"
      }
    },
    "wageData": {
      "2019": {
        "workers": "521",
        "median": "635",
        "maleWorkers": "40",
        "maleMedian": "-",
        "femaleWorkers": "481",
        "femaleMedian": "635"
      }
    }
  },
  "Medical transcriptionists": {
    "ageData": {
      "2019": {
        "total": "41",
        "16to19": "0",
        "20to24": "15",
        "25to34": "5",
        "35to44": "3",
        "45to54": "6",
        "55to64": "11",
        "65+": "1",
        "medianAge": "11"
      }
    },
    "title": "Medical transcriptionists",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "41",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "28",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "23",
        "femaleMedian": "-"
      }
    }
  },
  "Pharmacy aides": {
    "ageData": {
      "2019": {
        "total": "49",
        "16to19": "1",
        "20to24": "9",
        "25to34": "19",
        "35to44": "6",
        "45to54": "9",
        "55to64": "3",
        "65+": "2",
        "medianAge": "3"
      }
    },
    "title": "Pharmacy aides",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "49",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "41",
        "median": "-",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "27",
        "femaleMedian": "-"
      }
    }
  },
  "Veterinary assistants and laboratory animal caretakers": {
    "ageData": {
      "2019": {
        "total": "59",
        "16to19": "4",
        "20to24": "23",
        "25to34": "18",
        "35to44": "7",
        "45to54": "3",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Veterinary assistants and laboratory animal caretakers",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "59",
        "women": "82.7",
        "white": "97.2",
        "black": "2.1",
        "asian": "0.4",
        "hispanicOrLatino": "3.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "39",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "31",
        "femaleMedian": "-"
      }
    }
  },
  "Phlebotomists": {
    "ageData": {
      "2019": {
        "total": "124",
        "16to19": "2",
        "20to24": "18",
        "25to34": "36",
        "35to44": "26",
        "45to54": "25",
        "55to64": "17",
        "65+": "1",
        "medianAge": "17"
      }
    },
    "title": "Phlebotomists",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "124",
        "women": "89.3",
        "white": "70.5",
        "black": "24.6",
        "asian": "2.9",
        "hispanicOrLatino": "14.9"
      }
    },
    "wageData": {
      "2019": {
        "workers": "98",
        "median": "623",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "88",
        "femaleMedian": "612"
      }
    }
  },
  "Miscellaneous healthcare support occupations, including medical equipment preparers": {
    "ageData": {
      "2019": {
        "total": "206",
        "16to19": "16",
        "20to24": "29",
        "25to34": "53",
        "35to44": "35",
        "45to54": "28",
        "55to64": "32",
        "65+": "14",
        "medianAge": "32"
      }
    },
    "title": "Miscellaneous healthcare support occupations, including medical equipment preparers",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "206",
        "women": "68.1",
        "white": "64.7",
        "black": "25.7",
        "asian": "7.1",
        "hispanicOrLatino": "13.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "151",
        "median": "617",
        "maleWorkers": "47",
        "maleMedian": "-",
        "femaleWorkers": "103",
        "femaleMedian": "571"
      }
    }
  },
  "Protective service occupations": {
    "ageData": {
      "2019": {
        "total": "3,128",
        "16to19": "96",
        "20to24": "256",
        "25to34": "835",
        "35to44": "707",
        "45to54": "657",
        "55to64": "410",
        "65+": "166",
        "medianAge": "410"
      }
    },
    "title": "Protective service occupations",
    "depth": 2,
    "parent": "Service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "3,128",
        "women": "22.1",
        "white": "73.9",
        "black": "20.3",
        "asian": "2.2",
        "hispanicOrLatino": "15.3"
      }
    },
    "wageData": {
      "2019": {
        "workers": "2,725",
        "median": "900",
        "maleWorkers": "2,172",
        "maleMedian": "953",
        "femaleWorkers": "553",
        "femaleMedian": "731"
      }
    }
  },
  "First-line supervisors of correctional officers": {
    "ageData": {
      "2019": {
        "total": "44",
        "16to19": "0",
        "20to24": "2",
        "25to34": "9",
        "35to44": "12",
        "45to54": "12",
        "55to64": "7",
        "65+": "2",
        "medianAge": "7"
      }
    },
    "title": "First-line supervisors of correctional officers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "44",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "41",
        "median": "-",
        "maleWorkers": "29",
        "maleMedian": "-",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "First-line supervisors of police and detectives": {
    "ageData": {
      "2019": {
        "total": "83",
        "16to19": "0",
        "20to24": "0",
        "25to34": "12",
        "35to44": "26",
        "45to54": "33",
        "55to64": "11",
        "65+": "1",
        "medianAge": "11"
      }
    },
    "title": "First-line supervisors of police and detectives",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "83",
        "women": "9.9",
        "white": "89.2",
        "black": "7.6",
        "asian": "0.4",
        "hispanicOrLatino": "5.6"
      }
    },
    "wageData": {
      "2019": {
        "workers": "80",
        "median": "1,438",
        "maleWorkers": "71",
        "maleMedian": "1,449",
        "femaleWorkers": "9",
        "femaleMedian": "-"
      }
    }
  },
  "First-line supervisors of fire fighting and prevention workers": {
    "ageData": {
      "2019": {
        "total": "42",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "19",
        "45to54": "14",
        "55to64": "5",
        "65+": "1",
        "medianAge": "5"
      }
    },
    "title": "First-line supervisors of fire fighting and prevention workers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "42",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "40",
        "median": "-",
        "maleWorkers": "36",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "First-line supervisors of protective service workers, all other": {
    "ageData": {
      "2019": {
        "total": "78",
        "16to19": "0",
        "20to24": "2",
        "25to34": "8",
        "35to44": "21",
        "45to54": "24",
        "55to64": "15",
        "65+": "8",
        "medianAge": "15"
      }
    },
    "title": "First-line supervisors of protective service workers, all other",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "78",
        "women": "25.0",
        "white": "69.8",
        "black": "20.7",
        "asian": "2.2",
        "hispanicOrLatino": "11.3"
      }
    },
    "wageData": {
      "2019": {
        "workers": "66",
        "median": "659",
        "maleWorkers": "50",
        "maleMedian": "698",
        "femaleWorkers": "17",
        "femaleMedian": "-"
      }
    }
  },
  "Firefighters": {
    "ageData": {
      "2019": {
        "total": "318",
        "16to19": "0",
        "20to24": "13",
        "25to34": "100",
        "35to44": "96",
        "45to54": "71",
        "55to64": "30",
        "65+": "6",
        "medianAge": "30"
      }
    },
    "title": "Firefighters",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "318",
        "women": "3.3",
        "white": "86.7",
        "black": "8.5",
        "asian": "1.3",
        "hispanicOrLatino": "11.6"
      }
    },
    "wageData": {
      "2019": {
        "workers": "292",
        "median": "1,207",
        "maleWorkers": "287",
        "maleMedian": "1,203",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Fire inspectors": {
    "ageData": {
      "2019": {
        "total": "21",
        "16to19": "1",
        "20to24": "0",
        "25to34": "4",
        "35to44": "4",
        "45to54": "4",
        "55to64": "5",
        "65+": "3",
        "medianAge": "5"
      }
    },
    "title": "Fire inspectors",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "21",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "16",
        "median": "-",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Bailiffs, correctional officers, and jailers": {
    "ageData": {
      "2019": {
        "total": "372",
        "16to19": "2",
        "20to24": "33",
        "25to34": "113",
        "35to44": "81",
        "45to54": "80",
        "55to64": "47",
        "65+": "16",
        "medianAge": "47"
      }
    },
    "title": "Bailiffs, correctional officers, and jailers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "372",
        "women": "30.1",
        "white": "60.5",
        "black": "34.2",
        "asian": "1.7",
        "hispanicOrLatino": "12.3"
      }
    },
    "wageData": {
      "2019": {
        "workers": "365",
        "median": "776",
        "maleWorkers": "251",
        "maleMedian": "890",
        "femaleWorkers": "114",
        "femaleMedian": "664"
      }
    }
  },
  "Detectives and criminal investigators": {
    "ageData": {
      "2019": {
        "total": "164",
        "16to19": "0",
        "20to24": "5",
        "25to34": "27",
        "35to44": "57",
        "45to54": "49",
        "55to64": "23",
        "65+": "4",
        "medianAge": "23"
      }
    },
    "title": "Detectives and criminal investigators",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "164",
        "women": "23.0",
        "white": "82.7",
        "black": "12.7",
        "asian": "1.9",
        "hispanicOrLatino": "8.4"
      }
    },
    "wageData": {
      "2019": {
        "workers": "151",
        "median": "1,428",
        "maleWorkers": "119",
        "maleMedian": "1,472",
        "femaleWorkers": "32",
        "femaleMedian": "-"
      }
    }
  },
  "Fish and game wardens": {
    "ageData": {
      "2019": {
        "total": "4",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "2",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Fish and game wardens",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Parking enforcement workers": {
    "ageData": {
      "2019": {
        "total": "4",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "2",
        "45to54": "1",
        "55to64": "0",
        "65+": "1",
        "medianAge": "0"
      }
    },
    "title": "Parking enforcement workers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Police and sheriff's patrol officers": {
    "ageData": {
      "2019": {
        "total": "716",
        "16to19": "1",
        "20to24": "44",
        "25to34": "229",
        "35to44": "190",
        "45to54": "175",
        "55to64": "56",
        "65+": "21",
        "medianAge": "56"
      }
    },
    "title": "Police and sheriff's patrol officers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "716",
        "women": "17.6",
        "white": "82.9",
        "black": "12.6",
        "asian": "1.5",
        "hispanicOrLatino": "17.0"
      }
    },
    "wageData": {
      "2019": {
        "workers": "686",
        "median": "1,111",
        "maleWorkers": "559",
        "maleMedian": "1,143",
        "femaleWorkers": "127",
        "femaleMedian": "922"
      }
    }
  },
  "Transit and railroad police": {
    "ageData": {
      "2019": {
        "total": "5",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "1",
        "45to54": "0",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Transit and railroad police",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Animal control workers": {
    "ageData": {
      "2019": {
        "total": "10",
        "16to19": "0",
        "20to24": "0",
        "25to34": "4",
        "35to44": "2",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Animal control workers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "10",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Private detectives and investigators": {
    "ageData": {
      "2019": {
        "total": "97",
        "16to19": "0",
        "20to24": "3",
        "25to34": "28",
        "35to44": "19",
        "45to54": "25",
        "55to64": "16",
        "65+": "6",
        "medianAge": "16"
      }
    },
    "title": "Private detectives and investigators",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "97",
        "women": "47.6",
        "white": "72.6",
        "black": "23.5",
        "asian": "3.0",
        "hispanicOrLatino": "17.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "78",
        "median": "973",
        "maleWorkers": "38",
        "maleMedian": "-",
        "femaleWorkers": "41",
        "femaleMedian": "-"
      }
    }
  },
  "Security guards and gaming surveillance officers": {
    "ageData": {
      "2019": {
        "total": "937",
        "16to19": "16",
        "20to24": "114",
        "25to34": "258",
        "35to44": "156",
        "45to54": "146",
        "55to64": "167",
        "65+": "80",
        "medianAge": "167"
      }
    },
    "title": "Security guards and gaming surveillance officers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "937",
        "women": "21.5",
        "white": "62.9",
        "black": "29.6",
        "asian": "3.5",
        "hispanicOrLatino": "18.8"
      }
    },
    "wageData": {
      "2019": {
        "workers": "790",
        "median": "650",
        "maleWorkers": "631",
        "maleMedian": "671",
        "femaleWorkers": "159",
        "femaleMedian": "589"
      }
    }
  },
  "Crossing guards": {
    "ageData": {
      "2019": {
        "total": "52",
        "16to19": "1",
        "20to24": "3",
        "25to34": "10",
        "35to44": "4",
        "45to54": "5",
        "55to64": "16",
        "65+": "13",
        "medianAge": "16"
      }
    },
    "title": "Crossing guards",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "52",
        "women": "48.6",
        "white": "76.3",
        "black": "17.6",
        "asian": "1.3",
        "hispanicOrLatino": "21.0"
      }
    },
    "wageData": {
      "2019": {
        "workers": "21",
        "median": "-",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Transportation security screeners": {
    "ageData": {
      "2019": {
        "total": "43",
        "16to19": "0",
        "20to24": "5",
        "25to34": "13",
        "35to44": "11",
        "45to54": "8",
        "55to64": "4",
        "65+": "2",
        "medianAge": "4"
      }
    },
    "title": "Transportation security screeners",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "43",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "41",
        "median": "-",
        "maleWorkers": "32",
        "maleMedian": "-",
        "femaleWorkers": "9",
        "femaleMedian": "-"
      }
    }
  },
  "Lifeguards and other recreational, and all other protective service workers": {
    "ageData": {
      "2019": {
        "total": "139",
        "16to19": "75",
        "20to24": "30",
        "25to34": "12",
        "35to44": "6",
        "45to54": "8",
        "55to64": "5",
        "65+": "3",
        "medianAge": "5"
      }
    },
    "title": "Lifeguards and other recreational, and all other protective service workers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "139",
        "women": "49.4",
        "white": "87.7",
        "black": "6.4",
        "asian": "0.7",
        "hispanicOrLatino": "14.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "41",
        "median": "-",
        "maleWorkers": "28",
        "maleMedian": "-",
        "femaleWorkers": "13",
        "femaleMedian": "-"
      }
    }
  },
  "Food preparation and serving related occupations": {
    "ageData": {
      "2019": {
        "total": "8,378",
        "16to19": "1,379",
        "20to24": "1,811",
        "25to34": "1,902",
        "35to44": "1,189",
        "45to54": "1,027",
        "55to64": "791",
        "65+": "278",
        "medianAge": "791"
      }
    },
    "title": "Food preparation and serving related occupations",
    "depth": 2,
    "parent": "Service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "8,378",
        "women": "54.5",
        "white": "73.7",
        "black": "13.9",
        "asian": "6.9",
        "hispanicOrLatino": "27.0"
      }
    },
    "wageData": {
      "2019": {
        "workers": "4,689",
        "median": "522",
        "maleWorkers": "2,352",
        "maleMedian": "576",
        "femaleWorkers": "2,337",
        "femaleMedian": "492"
      }
    }
  },
  "Chefs and head cooks": {
    "ageData": {
      "2019": {
        "total": "474",
        "16to19": "10",
        "20to24": "48",
        "25to34": "132",
        "35to44": "102",
        "45to54": "110",
        "55to64": "59",
        "65+": "14",
        "medianAge": "59"
      }
    },
    "title": "Chefs and head cooks",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "474",
        "women": "22.0",
        "white": "63.0",
        "black": "14.3",
        "asian": "16.4",
        "hispanicOrLatino": "26.0"
      }
    },
    "wageData": {
      "2019": {
        "workers": "406",
        "median": "698",
        "maleWorkers": "334",
        "maleMedian": "727",
        "femaleWorkers": "72",
        "femaleMedian": "553"
      }
    }
  },
  "First-line supervisors of food preparation and serving workers": {
    "ageData": {
      "2019": {
        "total": "597",
        "16to19": "31",
        "20to24": "101",
        "25to34": "162",
        "35to44": "115",
        "45to54": "93",
        "55to64": "76",
        "65+": "20",
        "medianAge": "76"
      }
    },
    "title": "First-line supervisors of food preparation and serving workers",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "597",
        "women": "57.4",
        "white": "76.7",
        "black": "14.9",
        "asian": "4.8",
        "hispanicOrLatino": "22.6"
      }
    },
    "wageData": {
      "2019": {
        "workers": "426",
        "median": "584",
        "maleWorkers": "184",
        "maleMedian": "657",
        "femaleWorkers": "242",
        "femaleMedian": "545"
      }
    }
  },
  "Cooks": {
    "ageData": {
      "2019": {
        "total": "2,031",
        "16to19": "228",
        "20to24": "322",
        "25to34": "481",
        "35to44": "360",
        "45to54": "313",
        "55to64": "247",
        "65+": "81",
        "medianAge": "247"
      }
    },
    "title": "Cooks",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "2,031",
        "women": "41.7",
        "white": "70.4",
        "black": "18.1",
        "asian": "6.2",
        "hispanicOrLatino": "36.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "1,362",
        "median": "512",
        "maleWorkers": "831",
        "maleMedian": "535",
        "femaleWorkers": "531",
        "femaleMedian": "489"
      }
    }
  },
  "Food preparation workers": {
    "ageData": {
      "2019": {
        "total": "1,079",
        "16to19": "230",
        "20to24": "242",
        "25to34": "217",
        "35to44": "132",
        "45to54": "124",
        "55to64": "89",
        "65+": "44",
        "medianAge": "89"
      }
    },
    "title": "Food preparation workers",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "1,079",
        "women": "59.3",
        "white": "73.5",
        "black": "13.2",
        "asian": "8.3",
        "hispanicOrLatino": "28.1"
      }
    },
    "wageData": {
      "2019": {
        "workers": "574",
        "median": "496",
        "maleWorkers": "246",
        "maleMedian": "532",
        "femaleWorkers": "328",
        "femaleMedian": "463"
      }
    }
  },
  "Bartenders": {
    "ageData": {
      "2019": {
        "total": "464",
        "16to19": "12",
        "20to24": "83",
        "25to34": "170",
        "35to44": "97",
        "45to54": "61",
        "55to64": "30",
        "65+": "10",
        "medianAge": "30"
      }
    },
    "title": "Bartenders",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "464",
        "women": "53.1",
        "white": "86.1",
        "black": "6.3",
        "asian": "2.8",
        "hispanicOrLatino": "16.5"
      }
    },
    "wageData": {
      "2019": {
        "workers": "293",
        "median": "610",
        "maleWorkers": "159",
        "maleMedian": "655",
        "femaleWorkers": "134",
        "femaleMedian": "578"
      }
    }
  },
  "Combined food preparation and serving workers, including fast food": {
    "ageData": {
      "2019": {
        "total": "372",
        "16to19": "96",
        "20to24": "84",
        "25to34": "69",
        "35to44": "46",
        "45to54": "33",
        "55to64": "34",
        "65+": "11",
        "medianAge": "34"
      }
    },
    "title": "Combined food preparation and serving workers, including fast food",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "372",
        "women": "62.4",
        "white": "70.2",
        "black": "19.6",
        "asian": "5.4",
        "hispanicOrLatino": "19.9"
      }
    },
    "wageData": {
      "2019": {
        "workers": "191",
        "median": "462",
        "maleWorkers": "65",
        "maleMedian": "457",
        "femaleWorkers": "126",
        "femaleMedian": "467"
      }
    }
  },
  "Counter attendants, cafeteria, food concession, and coffee shop": {
    "ageData": {
      "2019": {
        "total": "200",
        "16to19": "105",
        "20to24": "49",
        "25to34": "20",
        "35to44": "12",
        "45to54": "2",
        "55to64": "9",
        "65+": "3",
        "medianAge": "9"
      }
    },
    "title": "Counter attendants, cafeteria, food concession, and coffee shop",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "200",
        "women": "59.2",
        "white": "76.0",
        "black": "11.3",
        "asian": "5.2",
        "hispanicOrLatino": "18.6"
      }
    },
    "wageData": {
      "2019": {
        "workers": "48",
        "median": "-",
        "maleWorkers": "26",
        "maleMedian": "-",
        "femaleWorkers": "22",
        "femaleMedian": "-"
      }
    }
  },
  "Waiters and waitresses": {
    "ageData": {
      "2019": {
        "total": "2,038",
        "16to19": "335",
        "20to24": "627",
        "25to34": "494",
        "35to44": "226",
        "45to54": "178",
        "55to64": "136",
        "65+": "42",
        "medianAge": "136"
      }
    },
    "title": "Waiters and waitresses",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "2,038",
        "women": "71.3",
        "white": "75.5",
        "black": "11.1",
        "asian": "7.6",
        "hispanicOrLatino": "23.4"
      }
    },
    "wageData": {
      "2019": {
        "workers": "973",
        "median": "511",
        "maleWorkers": "292",
        "maleMedian": "594",
        "femaleWorkers": "681",
        "femaleMedian": "491"
      }
    }
  },
  "Food servers, nonrestaurant": {
    "ageData": {
      "2019": {
        "total": "191",
        "16to19": "39",
        "20to24": "34",
        "25to34": "38",
        "35to44": "20",
        "45to54": "17",
        "55to64": "30",
        "65+": "13",
        "medianAge": "30"
      }
    },
    "title": "Food servers, nonrestaurant",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "191",
        "women": "66.1",
        "white": "68.4",
        "black": "19.2",
        "asian": "6.6",
        "hispanicOrLatino": "21.6"
      }
    },
    "wageData": {
      "2019": {
        "workers": "89",
        "median": "515",
        "maleWorkers": "29",
        "maleMedian": "-",
        "femaleWorkers": "60",
        "femaleMedian": "501"
      }
    }
  },
  "Dining room and cafeteria attendants and bartender helpers": {
    "ageData": {
      "2019": {
        "total": "338",
        "16to19": "87",
        "20to24": "66",
        "25to34": "43",
        "35to44": "32",
        "45to54": "48",
        "55to64": "40",
        "65+": "22",
        "medianAge": "40"
      }
    },
    "title": "Dining room and cafeteria attendants and bartender helpers",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "338",
        "women": "40.5",
        "white": "77.1",
        "black": "12.6",
        "asian": "6.2",
        "hispanicOrLatino": "34.2"
      }
    },
    "wageData": {
      "2019": {
        "workers": "116",
        "median": "554",
        "maleWorkers": "66",
        "maleMedian": "605",
        "femaleWorkers": "50",
        "femaleMedian": "513"
      }
    }
  },
  "Dishwashers": {
    "ageData": {
      "2019": {
        "total": "264",
        "16to19": "63",
        "20to24": "46",
        "25to34": "45",
        "35to44": "39",
        "45to54": "33",
        "55to64": "28",
        "65+": "10",
        "medianAge": "28"
      }
    },
    "title": "Dishwashers",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "264",
        "women": "21.0",
        "white": "73.4",
        "black": "16.1",
        "asian": "4.2",
        "hispanicOrLatino": "28.9"
      }
    },
    "wageData": {
      "2019": {
        "workers": "126",
        "median": "453",
        "maleWorkers": "102",
        "maleMedian": "464",
        "femaleWorkers": "24",
        "femaleMedian": "-"
      }
    }
  },
  "Hosts and hostesses, restaurant, lounge, and coffee shop": {
    "ageData": {
      "2019": {
        "total": "322",
        "16to19": "141",
        "20to24": "106",
        "25to34": "29",
        "35to44": "8",
        "45to54": "16",
        "55to64": "13",
        "65+": "9",
        "medianAge": "13"
      }
    },
    "title": "Hosts and hostesses, restaurant, lounge, and coffee shop",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "322",
        "women": "82.1",
        "white": "77.6",
        "black": "8.6",
        "asian": "4.0",
        "hispanicOrLatino": "17.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "81",
        "median": "456",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "64",
        "femaleMedian": "441"
      }
    }
  },
  "Food preparation and serving related workers, all other": {
    "ageData": {
      "2019": {
        "total": "7",
        "16to19": "1",
        "20to24": "3",
        "25to34": "3",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Food preparation and serving related workers, all other",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Building and grounds cleaning and maintenance occupations": {
    "ageData": {
      "2019": {
        "total": "5,746",
        "16to19": "205",
        "20to24": "411",
        "25to34": "1,065",
        "35to44": "1,182",
        "45to54": "1,300",
        "55to64": "1,158",
        "65+": "427",
        "medianAge": "1,158"
      }
    },
    "title": "Building and grounds cleaning and maintenance occupations",
    "depth": 2,
    "parent": "Service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "5,746",
        "women": "42.0",
        "white": "77.3",
        "black": "14.9",
        "asian": "2.9",
        "hispanicOrLatino": "38.2"
      }
    },
    "wageData": {
      "2019": {
        "workers": "3,679",
        "median": "580",
        "maleWorkers": "2,293",
        "maleMedian": "618",
        "femaleWorkers": "1,386",
        "femaleMedian": "502"
      }
    }
  },
  "First-line supervisors of housekeeping and janitorial workers": {
    "ageData": {
      "2019": {
        "total": "352",
        "16to19": "3",
        "20to24": "7",
        "25to34": "55",
        "35to44": "92",
        "45to54": "102",
        "55to64": "71",
        "65+": "22",
        "medianAge": "71"
      }
    },
    "title": "First-line supervisors of housekeeping and janitorial workers",
    "depth": 3,
    "parent": "Building and grounds cleaning and maintenance occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "352",
        "women": "41.6",
        "white": "78.5",
        "black": "15.0",
        "asian": "3.2",
        "hispanicOrLatino": "27.5"
      }
    },
    "wageData": {
      "2019": {
        "workers": "215",
        "median": "710",
        "maleWorkers": "134",
        "maleMedian": "758",
        "femaleWorkers": "80",
        "femaleMedian": "639"
      }
    }
  },
  "First-line supervisors of landscaping, lawn service, and groundskeeping workers": {
    "ageData": {
      "2019": {
        "total": "285",
        "16to19": "4",
        "20to24": "11",
        "25to34": "50",
        "35to44": "78",
        "45to54": "68",
        "55to64": "55",
        "65+": "18",
        "medianAge": "55"
      }
    },
    "title": "First-line supervisors of landscaping, lawn service, and groundskeeping workers",
    "depth": 3,
    "parent": "Building and grounds cleaning and maintenance occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "285",
        "women": "9.3",
        "white": "88.0",
        "black": "7.5",
        "asian": "1.1",
        "hispanicOrLatino": "28.1"
      }
    },
    "wageData": {
      "2019": {
        "workers": "121",
        "median": "797",
        "maleWorkers": "110",
        "maleMedian": "800",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "Janitors and building cleaners": {
    "ageData": {
      "2019": {
        "total": "2,265",
        "16to19": "92",
        "20to24": "176",
        "25to34": "378",
        "35to44": "378",
        "45to54": "503",
        "55to64": "535",
        "65+": "202",
        "medianAge": "535"
      }
    },
    "title": "Janitors and building cleaners",
    "depth": 3,
    "parent": "Building and grounds cleaning and maintenance occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "2,265",
        "women": "37.2",
        "white": "74.0",
        "black": "18.2",
        "asian": "3.4",
        "hispanicOrLatino": "31.6"
      }
    },
    "wageData": {
      "2019": {
        "workers": "1,563",
        "median": "582",
        "maleWorkers": "1,068",
        "maleMedian": "610",
        "femaleWorkers": "494",
        "femaleMedian": "510"
      }
    }
  },
  "Maids and housekeeping cleaners": {
    "ageData": {
      "2019": {
        "total": "1,475",
        "16to19": "26",
        "20to24": "77",
        "25to34": "235",
        "35to44": "348",
        "45to54": "402",
        "55to64": "293",
        "65+": "94",
        "medianAge": "293"
      }
    },
    "title": "Maids and housekeeping cleaners",
    "depth": 3,
    "parent": "Building and grounds cleaning and maintenance occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "1,475",
        "women": "89.0",
        "white": "72.2",
        "black": "17.4",
        "asian": "4.1",
        "hispanicOrLatino": "49.2"
      }
    },
    "wageData": {
      "2019": {
        "workers": "895",
        "median": "490",
        "maleWorkers": "126",
        "maleMedian": "520",
        "femaleWorkers": "769",
        "femaleMedian": "484"
      }
    }
  },
  "Pest control workers": {
    "ageData": {
      "2019": {
        "total": "96",
        "16to19": "2",
        "20to24": "9",
        "25to34": "24",
        "35to44": "23",
        "45to54": "18",
        "55to64": "16",
        "65+": "4",
        "medianAge": "16"
      }
    },
    "title": "Pest control workers",
    "depth": 3,
    "parent": "Building and grounds cleaning and maintenance occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "96",
        "women": "6.7",
        "white": "85.2",
        "black": "8.5",
        "asian": "1.6",
        "hispanicOrLatino": "18.5"
      }
    },
    "wageData": {
      "2019": {
        "workers": "73",
        "median": "731",
        "maleWorkers": "71",
        "maleMedian": "741",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Grounds maintenance workers": {
    "ageData": {
      "2019": {
        "total": "1,273",
        "16to19": "77",
        "20to24": "130",
        "25to34": "323",
        "35to44": "262",
        "45to54": "208",
        "55to64": "188",
        "65+": "85",
        "medianAge": "188"
      }
    },
    "title": "Grounds maintenance workers",
    "depth": 3,
    "parent": "Building and grounds cleaning and maintenance occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "1,273",
        "women": "6.3",
        "white": "86.0",
        "black": "8.2",
        "asian": "0.9",
        "hispanicOrLatino": "43.6"
      }
    },
    "wageData": {
      "2019": {
        "workers": "813",
        "median": "606",
        "maleWorkers": "784",
        "maleMedian": "607",
        "femaleWorkers": "29",
        "femaleMedian": "-"
      }
    }
  },
  "Personal care and service occupations": {
    "ageData": {
      "2019": {
        "total": "5,968",
        "16to19": "343",
        "20to24": "816",
        "25to34": "1,271",
        "35to44": "1,063",
        "45to54": "1,072",
        "55to64": "924",
        "65+": "479",
        "medianAge": "924"
      }
    },
    "title": "Personal care and service occupations",
    "depth": 2,
    "parent": "Service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "5,968",
        "women": "76.9",
        "white": "69.4",
        "black": "16.1",
        "asian": "10.1",
        "hispanicOrLatino": "18.2"
      }
    },
    "wageData": {
      "2019": {
        "workers": "2,764",
        "median": "565",
        "maleWorkers": "718",
        "maleMedian": "624",
        "femaleWorkers": "2,047",
        "femaleMedian": "531"
      }
    }
  },
  "First-line supervisors of gaming workers": {
    "ageData": {
      "2019": {
        "total": "211",
        "16to19": "6",
        "20to24": "14",
        "25to34": "54",
        "35to44": "46",
        "45to54": "41",
        "55to64": "29",
        "65+": "21",
        "medianAge": "29"
      }
    },
    "title": "First-line supervisors of gaming workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "211",
        "women": "43.2",
        "white": "82.7",
        "black": "9.1",
        "asian": "4.1",
        "hispanicOrLatino": "10.3"
      }
    },
    "wageData": {
      "2019": {
        "workers": "117",
        "median": "821",
        "maleWorkers": "63",
        "maleMedian": "853",
        "femaleWorkers": "54",
        "femaleMedian": "791"
      }
    }
  },
  "First-line supervisors of personal service workers": {
    "ageData": {
      "2019": {
        "total": "213",
        "16to19": "2",
        "20to24": "11",
        "25to34": "38",
        "35to44": "49",
        "45to54": "58",
        "55to64": "43",
        "65+": "12",
        "medianAge": "43"
      }
    },
    "title": "First-line supervisors of personal service workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "213",
        "women": "68.5",
        "white": "72.4",
        "black": "4.5",
        "asian": "19.8",
        "hispanicOrLatino": "13.0"
      }
    },
    "wageData": {
      "2019": {
        "workers": "75",
        "median": "778",
        "maleWorkers": "32",
        "maleMedian": "-",
        "femaleWorkers": "43",
        "femaleMedian": "-"
      }
    }
  },
  "Animal trainers": {
    "ageData": {
      "2019": {
        "total": "63",
        "16to19": "2",
        "20to24": "9",
        "25to34": "17",
        "35to44": "10",
        "45to54": "12",
        "55to64": "8",
        "65+": "4",
        "medianAge": "8"
      }
    },
    "title": "Animal trainers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "63",
        "women": "58.6",
        "white": "86.7",
        "black": "4.6",
        "asian": "2.0",
        "hispanicOrLatino": "12.3"
      }
    },
    "wageData": {
      "2019": {
        "workers": "25",
        "median": "-",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "9",
        "femaleMedian": "-"
      }
    }
  },
  "Nonfarm animal caretakers": {
    "ageData": {
      "2019": {
        "total": "296",
        "16to19": "31",
        "20to24": "68",
        "25to34": "63",
        "35to44": "46",
        "45to54": "30",
        "55to64": "43",
        "65+": "17",
        "medianAge": "43"
      }
    },
    "title": "Nonfarm animal caretakers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "296",
        "women": "75.1",
        "white": "88.8",
        "black": "5.0",
        "asian": "2.1",
        "hispanicOrLatino": "12.5"
      }
    },
    "wageData": {
      "2019": {
        "workers": "105",
        "median": "515",
        "maleWorkers": "25",
        "maleMedian": "-",
        "femaleWorkers": "79",
        "femaleMedian": "489"
      }
    }
  },
  "Gaming services workers": {
    "ageData": {
      "2019": {
        "total": "93",
        "16to19": "7",
        "20to24": "7",
        "25to34": "24",
        "35to44": "20",
        "45to54": "12",
        "55to64": "15",
        "65+": "9",
        "medianAge": "15"
      }
    },
    "title": "Gaming services workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "93",
        "women": "45.1",
        "white": "40.5",
        "black": "19.6",
        "asian": "27.3",
        "hispanicOrLatino": "12.2"
      }
    },
    "wageData": {
      "2019": {
        "workers": "60",
        "median": "635",
        "maleWorkers": "32",
        "maleMedian": "-",
        "femaleWorkers": "28",
        "femaleMedian": "-"
      }
    }
  },
  "Motion picture projectionists": {
    "ageData": {
      "2019": {
        "total": "1",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Motion picture projectionists",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "0",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Ushers, lobby attendants, and ticket takers": {
    "ageData": {
      "2019": {
        "total": "38",
        "16to19": "12",
        "20to24": "8",
        "25to34": "3",
        "35to44": "2",
        "45to54": "4",
        "55to64": "3",
        "65+": "7",
        "medianAge": "3"
      }
    },
    "title": "Ushers, lobby attendants, and ticket takers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "38",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "10",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous entertainment attendants and related workers": {
    "ageData": {
      "2019": {
        "total": "210",
        "16to19": "46",
        "20to24": "55",
        "25to34": "34",
        "35to44": "20",
        "45to54": "9",
        "55to64": "25",
        "65+": "22",
        "medianAge": "25"
      }
    },
    "title": "Miscellaneous entertainment attendants and related workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "210",
        "women": "39.3",
        "white": "79.6",
        "black": "10.8",
        "asian": "5.4",
        "hispanicOrLatino": "15.5"
      }
    },
    "wageData": {
      "2019": {
        "workers": "77",
        "median": "583",
        "maleWorkers": "54",
        "maleMedian": "557",
        "femaleWorkers": "24",
        "femaleMedian": "-"
      }
    }
  },
  "Embalmers and funeral attendants": {
    "ageData": {
      "2019": {
        "total": "15",
        "16to19": "0",
        "20to24": "2",
        "25to34": "2",
        "35to44": "2",
        "45to54": "1",
        "55to64": "4",
        "65+": "5",
        "medianAge": "4"
      }
    },
    "title": "Embalmers and funeral attendants",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "15",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Morticians, undertakers, and funeral directors": {
    "ageData": {
      "2019": {
        "total": "26",
        "16to19": "0",
        "20to24": "3",
        "25to34": "2",
        "35to44": "6",
        "45to54": "4",
        "55to64": "7",
        "65+": "4",
        "medianAge": "7"
      }
    },
    "title": "Morticians, undertakers, and funeral directors",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "26",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "20",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Barbers": {
    "ageData": {
      "2019": {
        "total": "137",
        "16to19": "1",
        "20to24": "12",
        "25to34": "37",
        "35to44": "30",
        "45to54": "29",
        "55to64": "12",
        "65+": "16",
        "medianAge": "12"
      }
    },
    "title": "Barbers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "137",
        "women": "23.0",
        "white": "61.8",
        "black": "31.1",
        "asian": "4.5",
        "hispanicOrLatino": "29.0"
      }
    },
    "wageData": {
      "2019": {
        "workers": "62",
        "median": "662",
        "maleWorkers": "50",
        "maleMedian": "789",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Hairdressers, hairstylists, and cosmetologists": {
    "ageData": {
      "2019": {
        "total": "803",
        "16to19": "9",
        "20to24": "91",
        "25to34": "203",
        "35to44": "175",
        "45to54": "160",
        "55to64": "101",
        "65+": "64",
        "medianAge": "101"
      }
    },
    "title": "Hairdressers, hairstylists, and cosmetologists",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "803",
        "women": "92.3",
        "white": "77.3",
        "black": "13.9",
        "asian": "6.1",
        "hispanicOrLatino": "17.0"
      }
    },
    "wageData": {
      "2019": {
        "workers": "310",
        "median": "570",
        "maleWorkers": "24",
        "maleMedian": "-",
        "femaleWorkers": "286",
        "femaleMedian": "569"
      }
    }
  },
  "Miscellaneous personal appearance workers": {
    "ageData": {
      "2019": {
        "total": "409",
        "16to19": "6",
        "20to24": "33",
        "25to34": "102",
        "35to44": "104",
        "45to54": "98",
        "55to64": "44",
        "65+": "22",
        "medianAge": "44"
      }
    },
    "title": "Miscellaneous personal appearance workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "409",
        "women": "85.5",
        "white": "30.7",
        "black": "7.7",
        "asian": "59.2",
        "hispanicOrLatino": "11.6"
      }
    },
    "wageData": {
      "2019": {
        "workers": "227",
        "median": "571",
        "maleWorkers": "33",
        "maleMedian": "-",
        "femaleWorkers": "193",
        "femaleMedian": "554"
      }
    }
  },
  "Baggage porters, bellhops, and concierges": {
    "ageData": {
      "2019": {
        "total": "92",
        "16to19": "5",
        "20to24": "14",
        "25to34": "19",
        "35to44": "7",
        "45to54": "17",
        "55to64": "23",
        "65+": "9",
        "medianAge": "23"
      }
    },
    "title": "Baggage porters, bellhops, and concierges",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "92",
        "women": "24.3",
        "white": "58.1",
        "black": "26.2",
        "asian": "12.4",
        "hispanicOrLatino": "30.6"
      }
    },
    "wageData": {
      "2019": {
        "workers": "69",
        "median": "663",
        "maleWorkers": "54",
        "maleMedian": "672",
        "femaleWorkers": "15",
        "femaleMedian": "-"
      }
    }
  },
  "Tour and travel guides": {
    "ageData": {
      "2019": {
        "total": "61",
        "16to19": "5",
        "20to24": "12",
        "25to34": "10",
        "35to44": "12",
        "45to54": "3",
        "55to64": "11",
        "65+": "7",
        "medianAge": "11"
      }
    },
    "title": "Tour and travel guides",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "61",
        "women": "43.6",
        "white": "82.3",
        "black": "1.7",
        "asian": "5.5",
        "hispanicOrLatino": "5.3"
      }
    },
    "wageData": {
      "2019": {
        "workers": "28",
        "median": "-",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "Childcare workers": {
    "ageData": {
      "2019": {
        "total": "1,193",
        "16to19": "128",
        "20to24": "222",
        "25to34": "223",
        "35to44": "173",
        "45to54": "193",
        "55to64": "174",
        "65+": "79",
        "medianAge": "174"
      }
    },
    "title": "Childcare workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "1,193",
        "women": "93.4",
        "white": "76.0",
        "black": "17.4",
        "asian": "3.3",
        "hispanicOrLatino": "24.6"
      }
    },
    "wageData": {
      "2019": {
        "workers": "473",
        "median": "495",
        "maleWorkers": "40",
        "maleMedian": "-",
        "femaleWorkers": "433",
        "femaleMedian": "491"
      }
    }
  },
  "Personal care aides": {
    "ageData": {
      "2019": {
        "total": "1,458",
        "16to19": "33",
        "20to24": "155",
        "25to34": "272",
        "35to44": "253",
        "45to54": "299",
        "55to64": "305",
        "65+": "140",
        "medianAge": "305"
      }
    },
    "title": "Personal care aides",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "1,458",
        "women": "85.6",
        "white": "60.2",
        "black": "25.1",
        "asian": "8.4",
        "hispanicOrLatino": "21.6"
      }
    },
    "wageData": {
      "2019": {
        "workers": "815",
        "median": "516",
        "maleWorkers": "130",
        "maleMedian": "581",
        "femaleWorkers": "685",
        "femaleMedian": "509"
      }
    }
  },
  "Recreation and fitness workers": {
    "ageData": {
      "2019": {
        "total": "440",
        "16to19": "37",
        "20to24": "64",
        "25to34": "111",
        "35to44": "70",
        "45to54": "68",
        "55to64": "58",
        "65+": "32",
        "medianAge": "58"
      }
    },
    "title": "Recreation and fitness workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "440",
        "women": "63.3",
        "white": "81.6",
        "black": "10.8",
        "asian": "3.4",
        "hispanicOrLatino": "10.2"
      }
    },
    "wageData": {
      "2019": {
        "workers": "191",
        "median": "607",
        "maleWorkers": "88",
        "maleMedian": "656",
        "femaleWorkers": "102",
        "femaleMedian": "580"
      }
    }
  },
  "Residential advisors": {
    "ageData": {
      "2019": {
        "total": "30",
        "16to19": "1",
        "20to24": "10",
        "25to34": "5",
        "35to44": "5",
        "45to54": "5",
        "55to64": "2",
        "65+": "2",
        "medianAge": "2"
      }
    },
    "title": "Residential advisors",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "30",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "21",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "13",
        "femaleMedian": "-"
      }
    }
  },
  "Personal care and service workers, all other": {
    "ageData": {
      "2019": {
        "total": "178",
        "16to19": "11",
        "20to24": "25",
        "25to34": "54",
        "35to44": "33",
        "45to54": "27",
        "55to64": "18",
        "65+": "10",
        "medianAge": "18"
      }
    },
    "title": "Personal care and service workers, all other",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "178",
        "women": "61.7",
        "white": "73.8",
        "black": "14.3",
        "asian": "7.0",
        "hispanicOrLatino": "18.1"
      }
    },
    "wageData": {
      "2019": {
        "workers": "74",
        "median": "611",
        "maleWorkers": "30",
        "maleMedian": "-",
        "femaleWorkers": "44",
        "femaleMedian": "-"
      }
    }
  },
  "Sales and office occupations": {
    "ageData": {
      "2019": {
        "total": "33,370",
        "16to19": "1,717",
        "20to24": "3,836",
        "25to34": "7,243",
        "35to44": "6,025",
        "45to54": "6,331",
        "55to64": "5,794",
        "65+": "2,425",
        "medianAge": "5,794"
      }
    },
    "title": "Sales and office occupations",
    "depth": 1,
    "parent": "",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "33,370",
        "women": "60.6",
        "white": "78.1",
        "black": "13.0",
        "asian": "5.2",
        "hispanicOrLatino": "17.1"
      }
    },
    "wageData": {
      "2019": {
        "workers": "23,883",
        "median": "758",
        "maleWorkers": "9,695",
        "maleMedian": "874",
        "femaleWorkers": "14,188",
        "femaleMedian": "713"
      }
    }
  },
  "Sales and related occupations": {
    "ageData": {
      "2019": {
        "total": "15,582",
        "16to19": "1,100",
        "20to24": "1,885",
        "25to34": "3,231",
        "35to44": "2,737",
        "45to54": "2,889",
        "55to64": "2,508",
        "65+": "1,232",
        "medianAge": "2,508"
      }
    },
    "title": "Sales and related occupations",
    "depth": 2,
    "parent": "Sales and office occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "15,582",
        "women": "48.8",
        "white": "79.8",
        "black": "11.2",
        "asian": "5.4",
        "hispanicOrLatino": "16.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "9,929",
        "median": "830",
        "maleWorkers": "5,525",
        "maleMedian": "982",
        "femaleWorkers": "4,404",
        "femaleMedian": "677"
      }
    }
  },
  "First-line supervisors of retail sales workers": {
    "ageData": {
      "2019": {
        "total": "3,232",
        "16to19": "36",
        "20to24": "221",
        "25to34": "782",
        "35to44": "695",
        "45to54": "683",
        "55to64": "581",
        "65+": "232",
        "medianAge": "581"
      }
    },
    "title": "First-line supervisors of retail sales workers",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "3,232",
        "women": "45.5",
        "white": "81.0",
        "black": "9.5",
        "asian": "5.5",
        "hispanicOrLatino": "14.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "2,316",
        "median": "817",
        "maleWorkers": "1,246",
        "maleMedian": "955",
        "femaleWorkers": "1,070",
        "femaleMedian": "708"
      }
    }
  },
  "First-line supervisors of non-retail sales workers": {
    "ageData": {
      "2019": {
        "total": "1,247",
        "16to19": "5",
        "20to24": "43",
        "25to34": "211",
        "35to44": "269",
        "45to54": "349",
        "55to64": "259",
        "65+": "111",
        "medianAge": "259"
      }
    },
    "title": "First-line supervisors of non-retail sales workers",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "1,247",
        "women": "27.1",
        "white": "86.2",
        "black": "6.5",
        "asian": "5.7",
        "hispanicOrLatino": "14.4"
      }
    },
    "wageData": {
      "2019": {
        "workers": "857",
        "median": "1,162",
        "maleWorkers": "596",
        "maleMedian": "1,157",
        "femaleWorkers": "261",
        "femaleMedian": "1,173"
      }
    }
  },
  "Cashiers": {
    "ageData": {
      "2019": {
        "total": "3,164",
        "16to19": "738",
        "20to24": "735",
        "25to34": "557",
        "35to44": "368",
        "45to54": "319",
        "55to64": "299",
        "65+": "148",
        "medianAge": "299"
      }
    },
    "title": "Cashiers",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "3,164",
        "women": "71.2",
        "white": "69.2",
        "black": "17.9",
        "asian": "7.3",
        "hispanicOrLatino": "24.1"
      }
    },
    "wageData": {
      "2019": {
        "workers": "1,398",
        "median": "503",
        "maleWorkers": "438",
        "maleMedian": "510",
        "femaleWorkers": "959",
        "femaleMedian": "499"
      }
    }
  },
  "Counter and rental clerks": {
    "ageData": {
      "2019": {
        "total": "97",
        "16to19": "4",
        "20to24": "12",
        "25to34": "20",
        "35to44": "25",
        "45to54": "14",
        "55to64": "11",
        "65+": "10",
        "medianAge": "11"
      }
    },
    "title": "Counter and rental clerks",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "97",
        "women": "42.6",
        "white": "72.8",
        "black": "18.3",
        "asian": "6.6",
        "hispanicOrLatino": "15.8"
      }
    },
    "wageData": {
      "2019": {
        "workers": "67",
        "median": "686",
        "maleWorkers": "44",
        "maleMedian": "-",
        "femaleWorkers": "23",
        "femaleMedian": "-"
      }
    }
  },
  "Parts salespersons": {
    "ageData": {
      "2019": {
        "total": "142",
        "16to19": "2",
        "20to24": "13",
        "25to34": "38",
        "35to44": "25",
        "45to54": "27",
        "55to64": "26",
        "65+": "10",
        "medianAge": "26"
      }
    },
    "title": "Parts salespersons",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "142",
        "women": "12.0",
        "white": "84.9",
        "black": "10.4",
        "asian": "1.3",
        "hispanicOrLatino": "19.8"
      }
    },
    "wageData": {
      "2019": {
        "workers": "122",
        "median": "777",
        "maleWorkers": "107",
        "maleMedian": "768",
        "femaleWorkers": "15",
        "femaleMedian": "-"
      }
    }
  },
  "Retail salespersons": {
    "ageData": {
      "2019": {
        "total": "3,105",
        "16to19": "258",
        "20to24": "611",
        "25to34": "647",
        "35to44": "411",
        "45to54": "441",
        "55to64": "456",
        "65+": "281",
        "medianAge": "456"
      }
    },
    "title": "Retail salespersons",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "3,105",
        "women": "48.5",
        "white": "78.9",
        "black": "12.4",
        "asian": "4.5",
        "hispanicOrLatino": "18.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "1,856",
        "median": "692",
        "maleWorkers": "1,094",
        "maleMedian": "806",
        "femaleWorkers": "762",
        "femaleMedian": "578"
      }
    }
  },
  "Advertising sales agents": {
    "ageData": {
      "2019": {
        "total": "190",
        "16to19": "1",
        "20to24": "14",
        "25to34": "59",
        "35to44": "39",
        "45to54": "39",
        "55to64": "29",
        "65+": "9",
        "medianAge": "29"
      }
    },
    "title": "Advertising sales agents",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "190",
        "women": "49.7",
        "white": "81.9",
        "black": "10.8",
        "asian": "3.7",
        "hispanicOrLatino": "13.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "169",
        "median": "1,050",
        "maleWorkers": "88",
        "maleMedian": "1,143",
        "femaleWorkers": "81",
        "femaleMedian": "874"
      }
    }
  },
  "Insurance sales agents": {
    "ageData": {
      "2019": {
        "total": "595",
        "16to19": "2",
        "20to24": "31",
        "25to34": "133",
        "35to44": "131",
        "45to54": "119",
        "55to64": "118",
        "65+": "61",
        "medianAge": "118"
      }
    },
    "title": "Insurance sales agents",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "595",
        "women": "50.6",
        "white": "81.2",
        "black": "10.1",
        "asian": "5.6",
        "hispanicOrLatino": "13.4"
      }
    },
    "wageData": {
      "2019": {
        "workers": "461",
        "median": "927",
        "maleWorkers": "214",
        "maleMedian": "1,034",
        "femaleWorkers": "247",
        "femaleMedian": "806"
      }
    }
  },
  "Securities, commodities, and financial services sales agents": {
    "ageData": {
      "2019": {
        "total": "231",
        "16to19": "2",
        "20to24": "16",
        "25to34": "60",
        "35to44": "51",
        "45to54": "53",
        "55to64": "34",
        "65+": "13",
        "medianAge": "34"
      }
    },
    "title": "Securities, commodities, and financial services sales agents",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "231",
        "women": "30.0",
        "white": "85.9",
        "black": "5.4",
        "asian": "7.5",
        "hispanicOrLatino": "11.9"
      }
    },
    "wageData": {
      "2019": {
        "workers": "192",
        "median": "1,302",
        "maleWorkers": "129",
        "maleMedian": "1,386",
        "femaleWorkers": "63",
        "femaleMedian": "1,063"
      }
    }
  },
  "Travel agents": {
    "ageData": {
      "2019": {
        "total": "82",
        "16to19": "0",
        "20to24": "3",
        "25to34": "7",
        "35to44": "13",
        "45to54": "30",
        "55to64": "17",
        "65+": "12",
        "medianAge": "17"
      }
    },
    "title": "Travel agents",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "82",
        "women": "86.0",
        "white": "81.6",
        "black": "9.5",
        "asian": "7.1",
        "hispanicOrLatino": "6.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "52",
        "median": "745",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "44",
        "femaleMedian": "-"
      }
    }
  },
  "Sales representatives, services, all other": {
    "ageData": {
      "2019": {
        "total": "557",
        "16to19": "5",
        "20to24": "47",
        "25to34": "161",
        "35to44": "117",
        "45to54": "121",
        "55to64": "80",
        "65+": "26",
        "medianAge": "80"
      }
    },
    "title": "Sales representatives, services, all other",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "557",
        "women": "30.4",
        "white": "85.2",
        "black": "7.7",
        "asian": "5.0",
        "hispanicOrLatino": "12.3"
      }
    },
    "wageData": {
      "2019": {
        "workers": "477",
        "median": "1,211",
        "maleWorkers": "340",
        "maleMedian": "1,332",
        "femaleWorkers": "137",
        "femaleMedian": "973"
      }
    }
  },
  "Sales representatives, wholesale and manufacturing": {
    "ageData": {
      "2019": {
        "total": "1,281",
        "16to19": "17",
        "20to24": "51",
        "25to34": "256",
        "35to44": "279",
        "45to54": "330",
        "55to64": "258",
        "65+": "90",
        "medianAge": "258"
      }
    },
    "title": "Sales representatives, wholesale and manufacturing",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "1,281",
        "women": "27.2",
        "white": "88.8",
        "black": "5.9",
        "asian": "3.9",
        "hispanicOrLatino": "10.9"
      }
    },
    "wageData": {
      "2019": {
        "workers": "1,107",
        "median": "1,206",
        "maleWorkers": "813",
        "maleMedian": "1,262",
        "femaleWorkers": "294",
        "femaleMedian": "1,067"
      }
    }
  },
  "Models, demonstrators, and product promoters": {
    "ageData": {
      "2019": {
        "total": "62",
        "16to19": "3",
        "20to24": "4",
        "25to34": "11",
        "35to44": "7",
        "45to54": "7",
        "55to64": "8",
        "65+": "21",
        "medianAge": "8"
      }
    },
    "title": "Models, demonstrators, and product promoters",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "62",
        "women": "73.3",
        "white": "79.5",
        "black": "11.3",
        "asian": "3.1",
        "hispanicOrLatino": "16.0"
      }
    },
    "wageData": {
      "2019": {
        "workers": "15",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Real estate brokers and sales agents": {
    "ageData": {
      "2019": {
        "total": "1,095",
        "16to19": "5",
        "20to24": "38",
        "25to34": "181",
        "35to44": "214",
        "45to54": "255",
        "55to64": "240",
        "65+": "161",
        "medianAge": "240"
      }
    },
    "title": "Real estate brokers and sales agents",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "1,095",
        "women": "58.9",
        "white": "85.0",
        "black": "7.9",
        "asian": "5.2",
        "hispanicOrLatino": "10.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "543",
        "median": "1,019",
        "maleWorkers": "222",
        "maleMedian": "1,344",
        "femaleWorkers": "321",
        "femaleMedian": "883"
      }
    }
  },
  "Sales engineers": {
    "ageData": {
      "2019": {
        "total": "35",
        "16to19": "0",
        "20to24": "2",
        "25to34": "10",
        "35to44": "6",
        "45to54": "8",
        "55to64": "9",
        "65+": "1",
        "medianAge": "9"
      }
    },
    "title": "Sales engineers",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "35",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "32",
        "median": "-",
        "maleWorkers": "29",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Telemarketers": {
    "ageData": {
      "2019": {
        "total": "49",
        "16to19": "2",
        "20to24": "9",
        "25to34": "16",
        "35to44": "5",
        "45to54": "9",
        "55to64": "4",
        "65+": "3",
        "medianAge": "4"
      }
    },
    "title": "Telemarketers",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "49",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "42",
        "median": "-",
        "maleWorkers": "18",
        "maleMedian": "-",
        "femaleWorkers": "24",
        "femaleMedian": "-"
      }
    }
  },
  "Door-to-door sales workers, news and street vendors, and related workers": {
    "ageData": {
      "2019": {
        "total": "135",
        "16to19": "4",
        "20to24": "9",
        "25to34": "21",
        "35to44": "29",
        "45to54": "24",
        "55to64": "30",
        "65+": "19",
        "medianAge": "30"
      }
    },
    "title": "Door-to-door sales workers, news and street vendors, and related workers",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "135",
        "women": "59.5",
        "white": "85.4",
        "black": "9.4",
        "asian": "1.2",
        "hispanicOrLatino": "24.9"
      }
    },
    "wageData": {
      "2019": {
        "workers": "31",
        "median": "-",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "16",
        "femaleMedian": "-"
      }
    }
  },
  "Sales and related workers, all other": {
    "ageData": {
      "2019": {
        "total": "284",
        "16to19": "14",
        "20to24": "26",
        "25to34": "60",
        "35to44": "53",
        "45to54": "61",
        "55to64": "47",
        "65+": "23",
        "medianAge": "47"
      }
    },
    "title": "Sales and related workers, all other",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "284",
        "women": "42.9",
        "white": "83.9",
        "black": "10.6",
        "asian": "1.1",
        "hispanicOrLatino": "13.9"
      }
    },
    "wageData": {
      "2019": {
        "workers": "194",
        "median": "935",
        "maleWorkers": "123",
        "maleMedian": "966",
        "femaleWorkers": "71",
        "femaleMedian": "848"
      }
    }
  },
  "Office and administrative support occupations": {
    "ageData": {
      "2019": {
        "total": "17,789",
        "16to19": "616",
        "20to24": "1,951",
        "25to34": "4,011",
        "35to44": "3,288",
        "45to54": "3,443",
        "55to64": "3,286",
        "65+": "1,192",
        "medianAge": "3,286"
      }
    },
    "title": "Office and administrative support occupations",
    "depth": 2,
    "parent": "Sales and office occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "17,789",
        "women": "70.9",
        "white": "76.6",
        "black": "14.5",
        "asian": "5.0",
        "hispanicOrLatino": "17.5"
      }
    },
    "wageData": {
      "2019": {
        "workers": "13,954",
        "median": "732",
        "maleWorkers": "4,170",
        "maleMedian": "771",
        "femaleWorkers": "9,784",
        "femaleMedian": "721"
      }
    }
  },
  "First-line supervisors of office and administrative support workers": {
    "ageData": {
      "2019": {
        "total": "1,306",
        "16to19": "6",
        "20to24": "51",
        "25to34": "286",
        "35to44": "294",
        "45to54": "335",
        "55to64": "264",
        "65+": "71",
        "medianAge": "264"
      }
    },
    "title": "First-line supervisors of office and administrative support workers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "1,306",
        "women": "68.5",
        "white": "80.2",
        "black": "13.4",
        "asian": "3.7",
        "hispanicOrLatino": "13.3"
      }
    },
    "wageData": {
      "2019": {
        "workers": "1,215",
        "median": "866",
        "maleWorkers": "406",
        "maleMedian": "971",
        "femaleWorkers": "809",
        "femaleMedian": "816"
      }
    }
  },
  "Switchboard operators, including answering service": {
    "ageData": {
      "2019": {
        "total": "21",
        "16to19": "0",
        "20to24": "1",
        "25to34": "5",
        "35to44": "2",
        "45to54": "4",
        "55to64": "8",
        "65+": "1",
        "medianAge": "8"
      }
    },
    "title": "Switchboard operators, including answering service",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "21",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "20",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "16",
        "femaleMedian": "-"
      }
    }
  },
  "Telephone operators": {
    "ageData": {
      "2019": {
        "total": "42",
        "16to19": "3",
        "20to24": "7",
        "25to34": "10",
        "35to44": "10",
        "45to54": "4",
        "55to64": "5",
        "65+": "3",
        "medianAge": "5"
      }
    },
    "title": "Telephone operators",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "42",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "34",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "28",
        "femaleMedian": "-"
      }
    }
  },
  "Communications equipment operators, all other": {
    "ageData": {
      "2019": {
        "total": "12",
        "16to19": "0",
        "20to24": "0",
        "25to34": "5",
        "35to44": "1",
        "45to54": "4",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Communications equipment operators, all other",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "12",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Bill and account collectors": {
    "ageData": {
      "2019": {
        "total": "116",
        "16to19": "1",
        "20to24": "11",
        "25to34": "47",
        "35to44": "18",
        "45to54": "22",
        "55to64": "11",
        "65+": "5",
        "medianAge": "11"
      }
    },
    "title": "Bill and account collectors",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "116",
        "women": "80.8",
        "white": "63.6",
        "black": "30.2",
        "asian": "1.3",
        "hispanicOrLatino": "17.8"
      }
    },
    "wageData": {
      "2019": {
        "workers": "113",
        "median": "701",
        "maleWorkers": "22",
        "maleMedian": "-",
        "femaleWorkers": "91",
        "femaleMedian": "672"
      }
    }
  },
  "Billing and posting clerks": {
    "ageData": {
      "2019": {
        "total": "459",
        "16to19": "4",
        "20to24": "34",
        "25to34": "90",
        "35to44": "110",
        "45to54": "112",
        "55to64": "82",
        "65+": "27",
        "medianAge": "82"
      }
    },
    "title": "Billing and posting clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "459",
        "women": "89.5",
        "white": "81.5",
        "black": "10.3",
        "asian": "4.7",
        "hispanicOrLatino": "15.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "389",
        "median": "742",
        "maleWorkers": "45",
        "maleMedian": "-",
        "femaleWorkers": "344",
        "femaleMedian": "736"
      }
    }
  },
  "Bookkeeping, accounting, and auditing clerks": {
    "ageData": {
      "2019": {
        "total": "1,015",
        "16to19": "9",
        "20to24": "50",
        "25to34": "123",
        "35to44": "181",
        "45to54": "239",
        "55to64": "285",
        "65+": "129",
        "medianAge": "285"
      }
    },
    "title": "Bookkeeping, accounting, and auditing clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "1,015",
        "women": "88.5",
        "white": "85.2",
        "black": "6.9",
        "asian": "5.4",
        "hispanicOrLatino": "13.0"
      }
    },
    "wageData": {
      "2019": {
        "workers": "657",
        "median": "764",
        "maleWorkers": "84",
        "maleMedian": "784",
        "femaleWorkers": "573",
        "femaleMedian": "764"
      }
    }
  },
  "Gaming cage workers": {
    "ageData": {
      "2019": {
        "total": "13",
        "16to19": "0",
        "20to24": "0",
        "25to34": "5",
        "35to44": "2",
        "45to54": "2",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Gaming cage workers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "13",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "10",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Payroll and timekeeping clerks": {
    "ageData": {
      "2019": {
        "total": "155",
        "16to19": "0",
        "20to24": "5",
        "25to34": "24",
        "35to44": "35",
        "45to54": "34",
        "55to64": "38",
        "65+": "17",
        "medianAge": "38"
      }
    },
    "title": "Payroll and timekeeping clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "155",
        "women": "85.2",
        "white": "79.8",
        "black": "8.4",
        "asian": "6.9",
        "hispanicOrLatino": "17.9"
      }
    },
    "wageData": {
      "2019": {
        "workers": "127",
        "median": "766",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "110",
        "femaleMedian": "756"
      }
    }
  },
  "Procurement clerks": {
    "ageData": {
      "2019": {
        "total": "29",
        "16to19": "0",
        "20to24": "3",
        "25to34": "3",
        "35to44": "4",
        "45to54": "12",
        "55to64": "7",
        "65+": "0",
        "medianAge": "7"
      }
    },
    "title": "Procurement clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "29",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "29",
        "median": "-",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "21",
        "femaleMedian": "-"
      }
    }
  },
  "Tellers": {
    "ageData": {
      "2019": {
        "total": "294",
        "16to19": "10",
        "20to24": "77",
        "25to34": "84",
        "35to44": "45",
        "45to54": "36",
        "55to64": "32",
        "65+": "10",
        "medianAge": "32"
      }
    },
    "title": "Tellers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "294",
        "women": "84.7",
        "white": "80.0",
        "black": "9.9",
        "asian": "4.8",
        "hispanicOrLatino": "20.0"
      }
    },
    "wageData": {
      "2019": {
        "workers": "241",
        "median": "575",
        "maleWorkers": "35",
        "maleMedian": "-",
        "femaleWorkers": "207",
        "femaleMedian": "571"
      }
    }
  },
  "Financial clerks, all other": {
    "ageData": {
      "2019": {
        "total": "119",
        "16to19": "0",
        "20to24": "15",
        "25to34": "29",
        "35to44": "32",
        "45to54": "21",
        "55to64": "18",
        "65+": "5",
        "medianAge": "18"
      }
    },
    "title": "Financial clerks, all other",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "119",
        "women": "59.7",
        "white": "83.2",
        "black": "10.9",
        "asian": "3.8",
        "hispanicOrLatino": "15.4"
      }
    },
    "wageData": {
      "2019": {
        "workers": "119",
        "median": "890",
        "maleWorkers": "48",
        "maleMedian": "-",
        "femaleWorkers": "71",
        "femaleMedian": "797"
      }
    }
  },
  "Brokerage clerks": {
    "ageData": {
      "2019": {
        "total": "3",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "2",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Brokerage clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Correspondence clerks": {
    "ageData": {
      "2019": {
        "total": "5",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "1",
        "45to54": "0",
        "55to64": "1",
        "65+": "1",
        "medianAge": "1"
      }
    },
    "title": "Correspondence clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Court, municipal, and license clerks": {
    "ageData": {
      "2019": {
        "total": "95",
        "16to19": "0",
        "20to24": "2",
        "25to34": "24",
        "35to44": "13",
        "45to54": "21",
        "55to64": "28",
        "65+": "6",
        "medianAge": "28"
      }
    },
    "title": "Court, municipal, and license clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "95",
        "women": "75.3",
        "white": "81.6",
        "black": "14.5",
        "asian": "1.2",
        "hispanicOrLatino": "17.3"
      }
    },
    "wageData": {
      "2019": {
        "workers": "83",
        "median": "748",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "66",
        "femaleMedian": "759"
      }
    }
  },
  "Credit authorizers, checkers, and clerks": {
    "ageData": {
      "2019": {
        "total": "41",
        "16to19": "0",
        "20to24": "1",
        "25to34": "17",
        "35to44": "6",
        "45to54": "9",
        "55to64": "5",
        "65+": "2",
        "medianAge": "5"
      }
    },
    "title": "Credit authorizers, checkers, and clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "41",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "39",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "30",
        "femaleMedian": "-"
      }
    }
  },
  "Customer service representatives": {
    "ageData": {
      "2019": {
        "total": "2,552",
        "16to19": "201",
        "20to24": "398",
        "25to34": "679",
        "35to44": "448",
        "45to54": "388",
        "55to64": "332",
        "65+": "105",
        "medianAge": "332"
      }
    },
    "title": "Customer service representatives",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "2,552",
        "women": "64.2",
        "white": "73.3",
        "black": "17.7",
        "asian": "5.3",
        "hispanicOrLatino": "19.6"
      }
    },
    "wageData": {
      "2019": {
        "workers": "1,977",
        "median": "693",
        "maleWorkers": "717",
        "maleMedian": "736",
        "femaleWorkers": "1,259",
        "femaleMedian": "673"
      }
    }
  },
  "Eligibility interviewers, government programs": {
    "ageData": {
      "2019": {
        "total": "70",
        "16to19": "0",
        "20to24": "0",
        "25to34": "15",
        "35to44": "24",
        "45to54": "16",
        "55to64": "13",
        "65+": "2",
        "medianAge": "13"
      }
    },
    "title": "Eligibility interviewers, government programs",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "70",
        "women": "80.1",
        "white": "70.1",
        "black": "22.2",
        "asian": "1.1",
        "hispanicOrLatino": "23.0"
      }
    },
    "wageData": {
      "2019": {
        "workers": "68",
        "median": "763",
        "maleWorkers": "16",
        "maleMedian": "-",
        "femaleWorkers": "52",
        "femaleMedian": "756"
      }
    }
  },
  "File clerks": {
    "ageData": {
      "2019": {
        "total": "175",
        "16to19": "12",
        "20to24": "18",
        "25to34": "35",
        "35to44": "24",
        "45to54": "41",
        "55to64": "27",
        "65+": "17",
        "medianAge": "27"
      }
    },
    "title": "File clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "175",
        "women": "83.1",
        "white": "75.3",
        "black": "16.8",
        "asian": "4.1",
        "hispanicOrLatino": "14.2"
      }
    },
    "wageData": {
      "2019": {
        "workers": "137",
        "median": "672",
        "maleWorkers": "27",
        "maleMedian": "-",
        "femaleWorkers": "110",
        "femaleMedian": "667"
      }
    }
  },
  "Hotel, motel, and resort desk clerks": {
    "ageData": {
      "2019": {
        "total": "116",
        "16to19": "3",
        "20to24": "35",
        "25to34": "44",
        "35to44": "13",
        "45to54": "12",
        "55to64": "5",
        "65+": "5",
        "medianAge": "5"
      }
    },
    "title": "Hotel, motel, and resort desk clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "116",
        "women": "74.6",
        "white": "60.7",
        "black": "21.6",
        "asian": "7.4",
        "hispanicOrLatino": "22.3"
      }
    },
    "wageData": {
      "2019": {
        "workers": "90",
        "median": "519",
        "maleWorkers": "24",
        "maleMedian": "-",
        "femaleWorkers": "65",
        "femaleMedian": "490"
      }
    }
  },
  "Interviewers, except eligibility and loan": {
    "ageData": {
      "2019": {
        "total": "131",
        "16to19": "5",
        "20to24": "14",
        "25to34": "27",
        "35to44": "27",
        "45to54": "20",
        "55to64": "21",
        "65+": "17",
        "medianAge": "21"
      }
    },
    "title": "Interviewers, except eligibility and loan",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "131",
        "women": "85.5",
        "white": "72.9",
        "black": "18.1",
        "asian": "4.7",
        "hispanicOrLatino": "18.3"
      }
    },
    "wageData": {
      "2019": {
        "workers": "93",
        "median": "740",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "82",
        "femaleMedian": "734"
      }
    }
  },
  "Library assistants, clerical": {
    "ageData": {
      "2019": {
        "total": "88",
        "16to19": "4",
        "20to24": "15",
        "25to34": "21",
        "35to44": "8",
        "45to54": "14",
        "55to64": "16",
        "65+": "11",
        "medianAge": "16"
      }
    },
    "title": "Library assistants, clerical",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "88",
        "women": "81.4",
        "white": "78.2",
        "black": "12.0",
        "asian": "8.9",
        "hispanicOrLatino": "12.6"
      }
    },
    "wageData": {
      "2019": {
        "workers": "34",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "29",
        "femaleMedian": "-"
      }
    }
  },
  "Loan interviewers and clerks": {
    "ageData": {
      "2019": {
        "total": "116",
        "16to19": "0",
        "20to24": "5",
        "25to34": "37",
        "35to44": "32",
        "45to54": "22",
        "55to64": "13",
        "65+": "5",
        "medianAge": "13"
      }
    },
    "title": "Loan interviewers and clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "116",
        "women": "76.8",
        "white": "75.2",
        "black": "16.3",
        "asian": "4.3",
        "hispanicOrLatino": "13.3"
      }
    },
    "wageData": {
      "2019": {
        "workers": "118",
        "median": "825",
        "maleWorkers": "30",
        "maleMedian": "-",
        "femaleWorkers": "88",
        "femaleMedian": "802"
      }
    }
  },
  "New accounts clerks": {
    "ageData": {
      "2019": {
        "total": "22",
        "16to19": "0",
        "20to24": "2",
        "25to34": "4",
        "35to44": "7",
        "45to54": "3",
        "55to64": "4",
        "65+": "1",
        "medianAge": "4"
      }
    },
    "title": "New accounts clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "22",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "25",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "17",
        "femaleMedian": "-"
      }
    }
  },
  "Order clerks": {
    "ageData": {
      "2019": {
        "total": "112",
        "16to19": "5",
        "20to24": "15",
        "25to34": "27",
        "35to44": "21",
        "45to54": "19",
        "55to64": "20",
        "65+": "5",
        "medianAge": "20"
      }
    },
    "title": "Order clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "112",
        "women": "53.3",
        "white": "66.9",
        "black": "18.2",
        "asian": "9.9",
        "hispanicOrLatino": "18.3"
      }
    },
    "wageData": {
      "2019": {
        "workers": "72",
        "median": "783",
        "maleWorkers": "40",
        "maleMedian": "-",
        "femaleWorkers": "32",
        "femaleMedian": "-"
      }
    }
  },
  "Human resources assistants, except payroll and timekeeping": {
    "ageData": {
      "2019": {
        "total": "60",
        "16to19": "1",
        "20to24": "5",
        "25to34": "17",
        "35to44": "14",
        "45to54": "12",
        "55to64": "8",
        "65+": "3",
        "medianAge": "8"
      }
    },
    "title": "Human resources assistants, except payroll and timekeeping",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "60",
        "women": "81.2",
        "white": "74.2",
        "black": "12.7",
        "asian": "3.8",
        "hispanicOrLatino": "18.5"
      }
    },
    "wageData": {
      "2019": {
        "workers": "51",
        "median": "702",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "41",
        "femaleMedian": "-"
      }
    }
  },
  "Receptionists and information clerks": {
    "ageData": {
      "2019": {
        "total": "1,288",
        "16to19": "72",
        "20to24": "219",
        "25to34": "317",
        "35to44": "207",
        "45to54": "192",
        "55to64": "187",
        "65+": "94",
        "medianAge": "187"
      }
    },
    "title": "Receptionists and information clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "1,288",
        "women": "89.3",
        "white": "75.8",
        "black": "15.4",
        "asian": "4.6",
        "hispanicOrLatino": "21.8"
      }
    },
    "wageData": {
      "2019": {
        "workers": "909",
        "median": "642",
        "maleWorkers": "100",
        "maleMedian": "735",
        "femaleWorkers": "809",
        "femaleMedian": "636"
      }
    }
  },
  "Reservation and transportation ticket agents and travel clerks": {
    "ageData": {
      "2019": {
        "total": "119",
        "16to19": "1",
        "20to24": "10",
        "25to34": "37",
        "35to44": "23",
        "45to54": "22",
        "55to64": "22",
        "65+": "3",
        "medianAge": "22"
      }
    },
    "title": "Reservation and transportation ticket agents and travel clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "119",
        "women": "58.9",
        "white": "51.4",
        "black": "27.9",
        "asian": "9.1",
        "hispanicOrLatino": "13.5"
      }
    },
    "wageData": {
      "2019": {
        "workers": "103",
        "median": "813",
        "maleWorkers": "46",
        "maleMedian": "-",
        "femaleWorkers": "56",
        "femaleMedian": "789"
      }
    }
  },
  "Information and record clerks, all other": {
    "ageData": {
      "2019": {
        "total": "99",
        "16to19": "0",
        "20to24": "9",
        "25to34": "23",
        "35to44": "24",
        "45to54": "17",
        "55to64": "20",
        "65+": "6",
        "medianAge": "20"
      }
    },
    "title": "Information and record clerks, all other",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "99",
        "women": "76.9",
        "white": "79.9",
        "black": "13.4",
        "asian": "2.3",
        "hispanicOrLatino": "22.8"
      }
    },
    "wageData": {
      "2019": {
        "workers": "88",
        "median": "735",
        "maleWorkers": "24",
        "maleMedian": "-",
        "femaleWorkers": "64",
        "femaleMedian": "691"
      }
    }
  },
  "Cargo and freight agents": {
    "ageData": {
      "2019": {
        "total": "20",
        "16to19": "1",
        "20to24": "1",
        "25to34": "5",
        "35to44": "6",
        "45to54": "3",
        "55to64": "4",
        "65+": "2",
        "medianAge": "4"
      }
    },
    "title": "Cargo and freight agents",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "20",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "19",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Couriers and messengers": {
    "ageData": {
      "2019": {
        "total": "402",
        "16to19": "14",
        "20to24": "50",
        "25to34": "102",
        "35to44": "70",
        "45to54": "76",
        "55to64": "58",
        "65+": "33",
        "medianAge": "58"
      }
    },
    "title": "Couriers and messengers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "402",
        "women": "20.7",
        "white": "73.3",
        "black": "17.4",
        "asian": "4.4",
        "hispanicOrLatino": "21.4"
      }
    },
    "wageData": {
      "2019": {
        "workers": "260",
        "median": "769",
        "maleWorkers": "221",
        "maleMedian": "789",
        "femaleWorkers": "40",
        "femaleMedian": "-"
      }
    }
  },
  "Dispatchers": {
    "ageData": {
      "2019": {
        "total": "261",
        "16to19": "3",
        "20to24": "23",
        "25to34": "72",
        "35to44": "67",
        "45to54": "51",
        "55to64": "35",
        "65+": "11",
        "medianAge": "35"
      }
    },
    "title": "Dispatchers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "261",
        "women": "53.1",
        "white": "80.8",
        "black": "14.2",
        "asian": "2.5",
        "hispanicOrLatino": "15.2"
      }
    },
    "wageData": {
      "2019": {
        "workers": "240",
        "median": "806",
        "maleWorkers": "108",
        "maleMedian": "854",
        "femaleWorkers": "131",
        "femaleMedian": "763"
      }
    }
  },
  "Meter readers, utilities": {
    "ageData": {
      "2019": {
        "total": "17",
        "16to19": "0",
        "20to24": "4",
        "25to34": "3",
        "35to44": "1",
        "45to54": "4",
        "55to64": "3",
        "65+": "2",
        "medianAge": "3"
      }
    },
    "title": "Meter readers, utilities",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "17",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Postal service clerks": {
    "ageData": {
      "2019": {
        "total": "96",
        "16to19": "0",
        "20to24": "8",
        "25to34": "11",
        "35to44": "17",
        "45to54": "21",
        "55to64": "31",
        "65+": "8",
        "medianAge": "31"
      }
    },
    "title": "Postal service clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "96",
        "women": "55.1",
        "white": "47.4",
        "black": "35.8",
        "asian": "13.0",
        "hispanicOrLatino": "6.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "73",
        "median": "907",
        "maleWorkers": "30",
        "maleMedian": "-",
        "femaleWorkers": "43",
        "femaleMedian": "-"
      }
    }
  },
  "Postal service mail carriers": {
    "ageData": {
      "2019": {
        "total": "331",
        "16to19": "0",
        "20to24": "17",
        "25to34": "73",
        "35to44": "69",
        "45to54": "79",
        "55to64": "79",
        "65+": "14",
        "medianAge": "79"
      }
    },
    "title": "Postal service mail carriers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "331",
        "women": "36.6",
        "white": "72.7",
        "black": "19.6",
        "asian": "5.5",
        "hispanicOrLatino": "14.4"
      }
    },
    "wageData": {
      "2019": {
        "workers": "296",
        "median": "951",
        "maleWorkers": "185",
        "maleMedian": "994",
        "femaleWorkers": "111",
        "femaleMedian": "893"
      }
    }
  },
  "Postal service mail sorters, processors, and processing machine operators": {
    "ageData": {
      "2019": {
        "total": "76",
        "16to19": "0",
        "20to24": "5",
        "25to34": "17",
        "35to44": "5",
        "45to54": "13",
        "55to64": "28",
        "65+": "9",
        "medianAge": "28"
      }
    },
    "title": "Postal service mail sorters, processors, and processing machine operators",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "76",
        "women": "53.3",
        "white": "35.8",
        "black": "42.3",
        "asian": "17.8",
        "hispanicOrLatino": "5.8"
      }
    },
    "wageData": {
      "2019": {
        "workers": "67",
        "median": "1,145",
        "maleWorkers": "37",
        "maleMedian": "-",
        "femaleWorkers": "30",
        "femaleMedian": "-"
      }
    }
  },
  "Production, planning, and expediting clerks": {
    "ageData": {
      "2019": {
        "total": "297",
        "16to19": "3",
        "20to24": "14",
        "25to34": "80",
        "35to44": "60",
        "45to54": "63",
        "55to64": "61",
        "65+": "15",
        "medianAge": "61"
      }
    },
    "title": "Production, planning, and expediting clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "297",
        "women": "57.7",
        "white": "80.0",
        "black": "14.0",
        "asian": "3.3",
        "hispanicOrLatino": "12.5"
      }
    },
    "wageData": {
      "2019": {
        "workers": "268",
        "median": "939",
        "maleWorkers": "114",
        "maleMedian": "1,039",
        "femaleWorkers": "154",
        "femaleMedian": "872"
      }
    }
  },
  "Shipping, receiving, and traffic clerks": {
    "ageData": {
      "2019": {
        "total": "563",
        "16to19": "16",
        "20to24": "57",
        "25to34": "132",
        "35to44": "98",
        "45to54": "125",
        "55to64": "102",
        "65+": "32",
        "medianAge": "102"
      }
    },
    "title": "Shipping, receiving, and traffic clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "563",
        "women": "34.9",
        "white": "74.7",
        "black": "15.5",
        "asian": "4.2",
        "hispanicOrLatino": "25.1"
      }
    },
    "wageData": {
      "2019": {
        "workers": "507",
        "median": "679",
        "maleWorkers": "332",
        "maleMedian": "686",
        "femaleWorkers": "175",
        "femaleMedian": "662"
      }
    }
  },
  "Stock clerks and order fillers": {
    "ageData": {
      "2019": {
        "total": "1,512",
        "16to19": "136",
        "20to24": "308",
        "25to34": "364",
        "35to44": "226",
        "45to54": "216",
        "55to64": "191",
        "65+": "71",
        "medianAge": "191"
      }
    },
    "title": "Stock clerks and order fillers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "1,512",
        "women": "37.5",
        "white": "70.3",
        "black": "18.2",
        "asian": "5.0",
        "hispanicOrLatino": "23.1"
      }
    },
    "wageData": {
      "2019": {
        "workers": "1,016",
        "median": "599",
        "maleWorkers": "636",
        "maleMedian": "596",
        "femaleWorkers": "380",
        "femaleMedian": "605"
      }
    }
  },
  "Weighers, measurers, checkers, and samplers, recordkeeping": {
    "ageData": {
      "2019": {
        "total": "66",
        "16to19": "2",
        "20to24": "2",
        "25to34": "10",
        "35to44": "16",
        "45to54": "17",
        "55to64": "15",
        "65+": "3",
        "medianAge": "15"
      }
    },
    "title": "Weighers, measurers, checkers, and samplers, recordkeeping",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "66",
        "women": "47.3",
        "white": "75.8",
        "black": "10.6",
        "asian": "8.5",
        "hispanicOrLatino": "21.1"
      }
    },
    "wageData": {
      "2019": {
        "workers": "53",
        "median": "716",
        "maleWorkers": "26",
        "maleMedian": "-",
        "femaleWorkers": "27",
        "femaleMedian": "-"
      }
    }
  },
  "Secretaries and administrative assistants": {
    "ageData": {
      "2019": {
        "total": "2,688",
        "16to19": "27",
        "20to24": "155",
        "25to34": "439",
        "35to44": "511",
        "45to54": "632",
        "55to64": "675",
        "65+": "249",
        "medianAge": "675"
      }
    },
    "title": "Secretaries and administrative assistants",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "2,688",
        "women": "93.2",
        "white": "85.2",
        "black": "8.7",
        "asian": "3.5",
        "hispanicOrLatino": "13.4"
      }
    },
    "wageData": {
      "2019": {
        "workers": "2,069",
        "median": "765",
        "maleWorkers": "137",
        "maleMedian": "795",
        "femaleWorkers": "1,932",
        "femaleMedian": "763"
      }
    }
  },
  "Computer operators": {
    "ageData": {
      "2019": {
        "total": "72",
        "16to19": "3",
        "20to24": "3",
        "25to34": "19",
        "35to44": "13",
        "45to54": "12",
        "55to64": "14",
        "65+": "8",
        "medianAge": "14"
      }
    },
    "title": "Computer operators",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "72",
        "women": "47.5",
        "white": "76.3",
        "black": "11.9",
        "asian": "9.1",
        "hispanicOrLatino": "19.4"
      }
    },
    "wageData": {
      "2019": {
        "workers": "68",
        "median": "960",
        "maleWorkers": "41",
        "maleMedian": "-",
        "femaleWorkers": "28",
        "femaleMedian": "-"
      }
    }
  },
  "Data entry keyers": {
    "ageData": {
      "2019": {
        "total": "250",
        "16to19": "7",
        "20to24": "27",
        "25to34": "56",
        "35to44": "62",
        "45to54": "42",
        "55to64": "44",
        "65+": "12",
        "medianAge": "44"
      }
    },
    "title": "Data entry keyers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "250",
        "women": "73.4",
        "white": "75.1",
        "black": "14.7",
        "asian": "6.5",
        "hispanicOrLatino": "20.3"
      }
    },
    "wageData": {
      "2019": {
        "workers": "203",
        "median": "675",
        "maleWorkers": "50",
        "maleMedian": "738",
        "femaleWorkers": "153",
        "femaleMedian": "656"
      }
    }
  },
  "Word processors and typists": {
    "ageData": {
      "2019": {
        "total": "57",
        "16to19": "2",
        "20to24": "6",
        "25to34": "13",
        "35to44": "11",
        "45to54": "7",
        "55to64": "14",
        "65+": "4",
        "medianAge": "14"
      }
    },
    "title": "Word processors and typists",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "57",
        "women": "86.0",
        "white": "77.8",
        "black": "12.6",
        "asian": "3.3",
        "hispanicOrLatino": "16.1"
      }
    },
    "wageData": {
      "2019": {
        "workers": "36",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "30",
        "femaleMedian": "-"
      }
    }
  },
  "Desktop publishers": {
    "ageData": {
      "2019": {
        "total": "0",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Desktop publishers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "0",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "0",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Insurance claims and policy processing clerks": {
    "ageData": {
      "2019": {
        "total": "269",
        "16to19": "5",
        "20to24": "20",
        "25to34": "84",
        "35to44": "50",
        "45to54": "51",
        "55to64": "51",
        "65+": "8",
        "medianAge": "51"
      }
    },
    "title": "Insurance claims and policy processing clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "269",
        "women": "81.7",
        "white": "71.3",
        "black": "21.8",
        "asian": "2.4",
        "hispanicOrLatino": "15.5"
      }
    },
    "wageData": {
      "2019": {
        "workers": "251",
        "median": "746",
        "maleWorkers": "47",
        "maleMedian": "-",
        "femaleWorkers": "204",
        "femaleMedian": "739"
      }
    }
  },
  "Mail clerks and mail machine operators, except postal service": {
    "ageData": {
      "2019": {
        "total": "68",
        "16to19": "1",
        "20to24": "10",
        "25to34": "19",
        "35to44": "10",
        "45to54": "13",
        "55to64": "9",
        "65+": "5",
        "medianAge": "9"
      }
    },
    "title": "Mail clerks and mail machine operators, except postal service",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "68",
        "women": "49.8",
        "white": "67.6",
        "black": "24.0",
        "asian": "4.1",
        "hispanicOrLatino": "14.2"
      }
    },
    "wageData": {
      "2019": {
        "workers": "48",
        "median": "-",
        "maleWorkers": "26",
        "maleMedian": "-",
        "femaleWorkers": "22",
        "femaleMedian": "-"
      }
    }
  },
  "Office clerks, general": {
    "ageData": {
      "2019": {
        "total": "1,355",
        "16to19": "54",
        "20to24": "174",
        "25to34": "301",
        "35to44": "222",
        "45to54": "237",
        "55to64": "263",
        "65+": "105",
        "medianAge": "263"
      }
    },
    "title": "Office clerks, general",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "1,355",
        "women": "82.7",
        "white": "74.0",
        "black": "13.6",
        "asian": "8.6",
        "hispanicOrLatino": "20.9"
      }
    },
    "wageData": {
      "2019": {
        "workers": "997",
        "median": "685",
        "maleWorkers": "188",
        "maleMedian": "718",
        "femaleWorkers": "809",
        "femaleMedian": "677"
      }
    }
  },
  "Office machine operators, except computer": {
    "ageData": {
      "2019": {
        "total": "32",
        "16to19": "2",
        "20to24": "5",
        "25to34": "8",
        "35to44": "6",
        "45to54": "4",
        "55to64": "6",
        "65+": "2",
        "medianAge": "6"
      }
    },
    "title": "Office machine operators, except computer",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "32",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "30",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "17",
        "femaleMedian": "-"
      }
    }
  },
  "Proofreaders and copy markers": {
    "ageData": {
      "2019": {
        "total": "7",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "2",
        "45to54": "1",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Proofreaders and copy markers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Statistical assistants": {
    "ageData": {
      "2019": {
        "total": "58",
        "16to19": "0",
        "20to24": "7",
        "25to34": "18",
        "35to44": "11",
        "45to54": "13",
        "55to64": "10",
        "65+": "0",
        "medianAge": "10"
      }
    },
    "title": "Statistical assistants",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "58",
        "women": "42.8",
        "white": "67.4",
        "black": "0.0",
        "asian": "32.3",
        "hispanicOrLatino": "4.9"
      }
    },
    "wageData": {
      "2019": {
        "workers": "58",
        "median": "1,480",
        "maleWorkers": "33",
        "maleMedian": "-",
        "femaleWorkers": "25",
        "femaleMedian": "-"
      }
    }
  },
  "Office and administrative support workers, all other": {
    "ageData": {
      "2019": {
        "total": "618",
        "16to19": "3",
        "20to24": "53",
        "25to34": "147",
        "35to44": "129",
        "45to54": "128",
        "55to64": "116",
        "65+": "42",
        "medianAge": "116"
      }
    },
    "title": "Office and administrative support workers, all other",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "618",
        "women": "74.6",
        "white": "77.7",
        "black": "15.5",
        "asian": "3.4",
        "hispanicOrLatino": "13.6"
      }
    },
    "wageData": {
      "2019": {
        "workers": "522",
        "median": "866",
        "maleWorkers": "147",
        "maleMedian": "950",
        "femaleWorkers": "375",
        "femaleMedian": "820"
      }
    }
  },
  "Natural resources, construction, and maintenance occupations": {
    "ageData": {
      "2019": {
        "total": "14,343",
        "16to19": "336",
        "20to24": "1,311",
        "25to34": "3,320",
        "35to44": "3,479",
        "45to54": "2,952",
        "55to64": "2,342",
        "65+": "602",
        "medianAge": "2,342"
      }
    },
    "title": "Natural resources, construction, and maintenance occupations",
    "depth": 1,
    "parent": "",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "14,343",
        "women": "5.4",
        "white": "86.2",
        "black": "7.7",
        "asian": "2.2",
        "hispanicOrLatino": "31.9"
      }
    },
    "wageData": {
      "2019": {
        "workers": "11,671",
        "median": "869",
        "maleWorkers": "11,134",
        "maleMedian": "881",
        "femaleWorkers": "537",
        "femaleMedian": "614"
      }
    }
  },
  "Farming, fishing, and forestry occupations": {
    "ageData": {
      "2019": {
        "total": "1,156",
        "16to19": "75",
        "20to24": "126",
        "25to34": "257",
        "35to44": "247",
        "45to54": "201",
        "55to64": "182",
        "65+": "69",
        "medianAge": "182"
      }
    },
    "title": "Farming, fishing, and forestry occupations",
    "depth": 2,
    "parent": "Natural resources, construction, and maintenance occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "1,156",
        "women": "25.2",
        "white": "89.3",
        "black": "4.4",
        "asian": "1.8",
        "hispanicOrLatino": "47.6"
      }
    },
    "wageData": {
      "2019": {
        "workers": "900",
        "median": "574",
        "maleWorkers": "690",
        "maleMedian": "593",
        "femaleWorkers": "210",
        "femaleMedian": "513"
      }
    }
  },
  "First-line supervisors of farming, fishing, and forestry workers": {
    "ageData": {
      "2019": {
        "total": "51",
        "16to19": "0",
        "20to24": "2",
        "25to34": "10",
        "35to44": "10",
        "45to54": "8",
        "55to64": "17",
        "65+": "4",
        "medianAge": "17"
      }
    },
    "title": "First-line supervisors of farming, fishing, and forestry workers",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "51",
        "women": "9.4",
        "white": "88.8",
        "black": "1.2",
        "asian": "1.3",
        "hispanicOrLatino": "28.4"
      }
    },
    "wageData": {
      "2019": {
        "workers": "40",
        "median": "-",
        "maleWorkers": "37",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Agricultural inspectors": {
    "ageData": {
      "2019": {
        "total": "18",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "3",
        "45to54": "3",
        "55to64": "8",
        "65+": "0",
        "medianAge": "8"
      }
    },
    "title": "Agricultural inspectors",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "18",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "16",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Animal breeders": {
    "ageData": {
      "2019": {
        "total": "10",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "5",
        "45to54": "1",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Animal breeders",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "10",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Graders and sorters, agricultural products": {
    "ageData": {
      "2019": {
        "total": "88",
        "16to19": "1",
        "20to24": "11",
        "25to34": "25",
        "35to44": "21",
        "45to54": "15",
        "55to64": "11",
        "65+": "4",
        "medianAge": "11"
      }
    },
    "title": "Graders and sorters, agricultural products",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "88",
        "women": "64.5",
        "white": "71.2",
        "black": "21.3",
        "asian": "3.6",
        "hispanicOrLatino": "49.9"
      }
    },
    "wageData": {
      "2019": {
        "workers": "78",
        "median": "564",
        "maleWorkers": "29",
        "maleMedian": "-",
        "femaleWorkers": "50",
        "femaleMedian": "549"
      }
    }
  },
  "Miscellaneous agricultural workers": {
    "ageData": {
      "2019": {
        "total": "866",
        "16to19": "68",
        "20to24": "100",
        "25to34": "192",
        "35to44": "187",
        "45to54": "144",
        "55to64": "126",
        "65+": "50",
        "medianAge": "126"
      }
    },
    "title": "Miscellaneous agricultural workers",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "866",
        "women": "24.2",
        "white": "92.5",
        "black": "2.6",
        "asian": "1.2",
        "hispanicOrLatino": "54.0"
      }
    },
    "wageData": {
      "2019": {
        "workers": "691",
        "median": "558",
        "maleWorkers": "544",
        "maleMedian": "581",
        "femaleWorkers": "148",
        "femaleMedian": "502"
      }
    }
  },
  "Fishers and related fishing workers": {
    "ageData": {
      "2019": {
        "total": "36",
        "16to19": "1",
        "20to24": "4",
        "25to34": "6",
        "35to44": "6",
        "45to54": "10",
        "55to64": "6",
        "65+": "4",
        "medianAge": "6"
      }
    },
    "title": "Fishers and related fishing workers",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "36",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "14",
        "median": "-",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Hunters and trappers": {
    "ageData": {
      "2019": {
        "total": "0",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Hunters and trappers",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "0",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "0",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Forest and conservation workers": {
    "ageData": {
      "2019": {
        "total": "21",
        "16to19": "0",
        "20to24": "2",
        "25to34": "5",
        "35to44": "3",
        "45to54": "6",
        "55to64": "2",
        "65+": "3",
        "medianAge": "2"
      }
    },
    "title": "Forest and conservation workers",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "21",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "14",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Logging workers": {
    "ageData": {
      "2019": {
        "total": "66",
        "16to19": "3",
        "20to24": "7",
        "25to34": "14",
        "35to44": "12",
        "45to54": "15",
        "55to64": "11",
        "65+": "4",
        "medianAge": "11"
      }
    },
    "title": "Logging workers",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "66",
        "women": "1.8",
        "white": "83.2",
        "black": "2.7",
        "asian": "2.9",
        "hispanicOrLatino": "16.0"
      }
    },
    "wageData": {
      "2019": {
        "workers": "44",
        "median": "-",
        "maleWorkers": "44",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Construction and extraction occupations": {
    "ageData": {
      "2019": {
        "total": "8,325",
        "16to19": "171",
        "20to24": "745",
        "25to34": "1,967",
        "35to44": "2,157",
        "45to54": "1,705",
        "55to64": "1,258",
        "65+": "321",
        "medianAge": "1,258"
      }
    },
    "title": "Construction and extraction occupations",
    "depth": 2,
    "parent": "Natural resources, construction, and maintenance occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "8,325",
        "women": "3.5",
        "white": "87.1",
        "black": "7.3",
        "asian": "1.6",
        "hispanicOrLatino": "36.4"
      }
    },
    "wageData": {
      "2019": {
        "workers": "6,467",
        "median": "866",
        "maleWorkers": "6,280",
        "maleMedian": "873",
        "femaleWorkers": "187",
        "femaleMedian": "711"
      }
    }
  },
  "First-line supervisors of construction trades and extraction workers": {
    "ageData": {
      "2019": {
        "total": "682",
        "16to19": "3",
        "20to24": "22",
        "25to34": "126",
        "35to44": "197",
        "45to54": "178",
        "55to64": "121",
        "65+": "35",
        "medianAge": "121"
      }
    },
    "title": "First-line supervisors of construction trades and extraction workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "682",
        "women": "3.4",
        "white": "88.0",
        "black": "7.5",
        "asian": "1.2",
        "hispanicOrLatino": "24.8"
      }
    },
    "wageData": {
      "2019": {
        "workers": "586",
        "median": "1,162",
        "maleWorkers": "571",
        "maleMedian": "1,167",
        "femaleWorkers": "15",
        "femaleMedian": "-"
      }
    }
  },
  "Boilermakers": {
    "ageData": {
      "2019": {
        "total": "24",
        "16to19": "0",
        "20to24": "2",
        "25to34": "5",
        "35to44": "9",
        "45to54": "5",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Boilermakers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "24",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "26",
        "median": "-",
        "maleWorkers": "26",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Brickmasons, blockmasons, and stonemasons": {
    "ageData": {
      "2019": {
        "total": "152",
        "16to19": "1",
        "20to24": "12",
        "25to34": "31",
        "35to44": "35",
        "45to54": "44",
        "55to64": "20",
        "65+": "9",
        "medianAge": "20"
      }
    },
    "title": "Brickmasons, blockmasons, and stonemasons",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "152",
        "women": "0.7",
        "white": "91.6",
        "black": "5.2",
        "asian": "1.2",
        "hispanicOrLatino": "37.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "115",
        "median": "817",
        "maleWorkers": "114",
        "maleMedian": "819",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Carpenters": {
    "ageData": {
      "2019": {
        "total": "1,292",
        "16to19": "21",
        "20to24": "104",
        "25to34": "285",
        "35to44": "363",
        "45to54": "256",
        "55to64": "207",
        "65+": "55",
        "medianAge": "207"
      }
    },
    "title": "Carpenters",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "1,292",
        "women": "2.8",
        "white": "89.1",
        "black": "5.1",
        "asian": "1.5",
        "hispanicOrLatino": "37.3"
      }
    },
    "wageData": {
      "2019": {
        "workers": "873",
        "median": "820",
        "maleWorkers": "855",
        "maleMedian": "826",
        "femaleWorkers": "18",
        "femaleMedian": "-"
      }
    }
  },
  "Carpet, floor, and tile installers and finishers": {
    "ageData": {
      "2019": {
        "total": "170",
        "16to19": "4",
        "20to24": "11",
        "25to34": "49",
        "35to44": "47",
        "45to54": "35",
        "55to64": "17",
        "65+": "7",
        "medianAge": "17"
      }
    },
    "title": "Carpet, floor, and tile installers and finishers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "170",
        "women": "1.9",
        "white": "90.8",
        "black": "5.1",
        "asian": "0.5",
        "hispanicOrLatino": "59.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "118",
        "median": "708",
        "maleWorkers": "115",
        "maleMedian": "709",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Cement masons, concrete finishers, and terrazzo workers": {
    "ageData": {
      "2019": {
        "total": "65",
        "16to19": "1",
        "20to24": "7",
        "25to34": "17",
        "35to44": "19",
        "45to54": "12",
        "55to64": "7",
        "65+": "2",
        "medianAge": "7"
      }
    },
    "title": "Cement masons, concrete finishers, and terrazzo workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "65",
        "women": "3.0",
        "white": "89.5",
        "black": "4.4",
        "asian": "1.1",
        "hispanicOrLatino": "53.5"
      }
    },
    "wageData": {
      "2019": {
        "workers": "60",
        "median": "975",
        "maleWorkers": "60",
        "maleMedian": "975",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Construction laborers": {
    "ageData": {
      "2019": {
        "total": "2,051",
        "16to19": "74",
        "20to24": "243",
        "25to34": "484",
        "35to44": "535",
        "45to54": "386",
        "55to64": "265",
        "65+": "65",
        "medianAge": "265"
      }
    },
    "title": "Construction laborers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "2,051",
        "women": "3.5",
        "white": "85.3",
        "black": "8.6",
        "asian": "1.6",
        "hispanicOrLatino": "46.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "1,434",
        "median": "723",
        "maleWorkers": "1,393",
        "maleMedian": "724",
        "femaleWorkers": "41",
        "femaleMedian": "-"
      }
    }
  },
  "Paving, surfacing, and tamping equipment operators": {
    "ageData": {
      "2019": {
        "total": "22",
        "16to19": "0",
        "20to24": "3",
        "25to34": "7",
        "35to44": "2",
        "45to54": "5",
        "55to64": "5",
        "65+": "1",
        "medianAge": "5"
      }
    },
    "title": "Paving, surfacing, and tamping equipment operators",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "22",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "20",
        "median": "-",
        "maleWorkers": "20",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Pile-driver operators": {
    "ageData": {
      "2019": {
        "total": "1",
        "16to19": "0",
        "20to24": "1",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Pile-driver operators",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Operating engineers and other construction equipment operators": {
    "ageData": {
      "2019": {
        "total": "375",
        "16to19": "7",
        "20to24": "29",
        "25to34": "77",
        "35to44": "90",
        "45to54": "86",
        "55to64": "67",
        "65+": "19",
        "medianAge": "67"
      }
    },
    "title": "Operating engineers and other construction equipment operators",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "375",
        "women": "1.7",
        "white": "85.1",
        "black": "8.9",
        "asian": "0.9",
        "hispanicOrLatino": "14.9"
      }
    },
    "wageData": {
      "2019": {
        "workers": "354",
        "median": "930",
        "maleWorkers": "350",
        "maleMedian": "927",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Drywall installers, ceiling tile installers, and tapers": {
    "ageData": {
      "2019": {
        "total": "178",
        "16to19": "4",
        "20to24": "15",
        "25to34": "44",
        "35to44": "50",
        "45to54": "37",
        "55to64": "23",
        "65+": "5",
        "medianAge": "23"
      }
    },
    "title": "Drywall installers, ceiling tile installers, and tapers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "178",
        "women": "0.7",
        "white": "91.1",
        "black": "2.5",
        "asian": "1.7",
        "hispanicOrLatino": "67.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "131",
        "median": "790",
        "maleWorkers": "131",
        "maleMedian": "790",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Electricians": {
    "ageData": {
      "2019": {
        "total": "914",
        "16to19": "17",
        "20to24": "95",
        "25to34": "210",
        "35to44": "217",
        "45to54": "192",
        "55to64": "149",
        "65+": "34",
        "medianAge": "149"
      }
    },
    "title": "Electricians",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "914",
        "women": "2.2",
        "white": "87.4",
        "black": "6.8",
        "asian": "2.6",
        "hispanicOrLatino": "21.1"
      }
    },
    "wageData": {
      "2019": {
        "workers": "814",
        "median": "1,012",
        "maleWorkers": "799",
        "maleMedian": "1,015",
        "femaleWorkers": "15",
        "femaleMedian": "-"
      }
    }
  },
  "Glaziers": {
    "ageData": {
      "2019": {
        "total": "38",
        "16to19": "0",
        "20to24": "3",
        "25to34": "10",
        "35to44": "8",
        "45to54": "13",
        "55to64": "5",
        "65+": "0",
        "medianAge": "5"
      }
    },
    "title": "Glaziers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "38",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "35",
        "median": "-",
        "maleWorkers": "32",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Insulation workers": {
    "ageData": {
      "2019": {
        "total": "59",
        "16to19": "0",
        "20to24": "7",
        "25to34": "14",
        "35to44": "21",
        "45to54": "9",
        "55to64": "6",
        "65+": "2",
        "medianAge": "6"
      }
    },
    "title": "Insulation workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "59",
        "women": "3.7",
        "white": "91.8",
        "black": "5.9",
        "asian": "1.4",
        "hispanicOrLatino": "43.3"
      }
    },
    "wageData": {
      "2019": {
        "workers": "56",
        "median": "877",
        "maleWorkers": "54",
        "maleMedian": "885",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Painters, construction and maintenance": {
    "ageData": {
      "2019": {
        "total": "599",
        "16to19": "8",
        "20to24": "35",
        "25to34": "136",
        "35to44": "166",
        "45to54": "133",
        "55to64": "95",
        "65+": "25",
        "medianAge": "95"
      }
    },
    "title": "Painters, construction and maintenance",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "599",
        "women": "8.9",
        "white": "87.4",
        "black": "8.1",
        "asian": "0.6",
        "hispanicOrLatino": "55.5"
      }
    },
    "wageData": {
      "2019": {
        "workers": "395",
        "median": "732",
        "maleWorkers": "364",
        "maleMedian": "744",
        "femaleWorkers": "31",
        "femaleMedian": "-"
      }
    }
  },
  "Paperhangers": {
    "ageData": {
      "2019": {
        "total": "7",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "2",
        "45to54": "0",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Paperhangers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Pipelayers, plumbers, pipefitters, and steamfitters": {
    "ageData": {
      "2019": {
        "total": "637",
        "16to19": "8",
        "20to24": "49",
        "25to34": "165",
        "35to44": "171",
        "45to54": "114",
        "55to64": "104",
        "65+": "26",
        "medianAge": "104"
      }
    },
    "title": "Pipelayers, plumbers, pipefitters, and steamfitters",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "637",
        "women": "2.7",
        "white": "87.1",
        "black": "8.4",
        "asian": "0.9",
        "hispanicOrLatino": "27.1"
      }
    },
    "wageData": {
      "2019": {
        "workers": "561",
        "median": "921",
        "maleWorkers": "546",
        "maleMedian": "918",
        "femaleWorkers": "15",
        "femaleMedian": "-"
      }
    }
  },
  "Plasterers and stucco masons": {
    "ageData": {
      "2019": {
        "total": "23",
        "16to19": "0",
        "20to24": "1",
        "25to34": "8",
        "35to44": "6",
        "45to54": "2",
        "55to64": "5",
        "65+": "0",
        "medianAge": "5"
      }
    },
    "title": "Plasterers and stucco masons",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "23",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "15",
        "median": "-",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Reinforcing iron and rebar workers": {
    "ageData": {
      "2019": {
        "total": "6",
        "16to19": "1",
        "20to24": "2",
        "25to34": "1",
        "35to44": "2",
        "45to54": "0",
        "55to64": "0",
        "65+": "1",
        "medianAge": "0"
      }
    },
    "title": "Reinforcing iron and rebar workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "6",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Roofers": {
    "ageData": {
      "2019": {
        "total": "222",
        "16to19": "3",
        "20to24": "27",
        "25to34": "82",
        "35to44": "47",
        "45to54": "38",
        "55to64": "19",
        "65+": "5",
        "medianAge": "19"
      }
    },
    "title": "Roofers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "222",
        "women": "1.9",
        "white": "87.7",
        "black": "5.2",
        "asian": "0.7",
        "hispanicOrLatino": "50.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "156",
        "median": "687",
        "maleWorkers": "154",
        "maleMedian": "690",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Sheet metal workers": {
    "ageData": {
      "2019": {
        "total": "140",
        "16to19": "4",
        "20to24": "8",
        "25to34": "31",
        "35to44": "37",
        "45to54": "35",
        "55to64": "22",
        "65+": "4",
        "medianAge": "22"
      }
    },
    "title": "Sheet metal workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "140",
        "women": "6.8",
        "white": "89.1",
        "black": "6.7",
        "asian": "2.3",
        "hispanicOrLatino": "28.9"
      }
    },
    "wageData": {
      "2019": {
        "workers": "124",
        "median": "894",
        "maleWorkers": "117",
        "maleMedian": "900",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Structural iron and steel workers": {
    "ageData": {
      "2019": {
        "total": "66",
        "16to19": "2",
        "20to24": "4",
        "25to34": "19",
        "35to44": "19",
        "45to54": "12",
        "55to64": "8",
        "65+": "1",
        "medianAge": "8"
      }
    },
    "title": "Structural iron and steel workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "66",
        "women": "0.9",
        "white": "75.7",
        "black": "11.7",
        "asian": "7.8",
        "hispanicOrLatino": "18.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "71",
        "median": "1,057",
        "maleWorkers": "71",
        "maleMedian": "1,057",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Solar photovoltaic installers": {
    "ageData": {
      "2019": {
        "total": "18",
        "16to19": "0",
        "20to24": "4",
        "25to34": "10",
        "35to44": "2",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Solar photovoltaic installers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "18",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "16",
        "median": "-",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Helpers, construction trades": {
    "ageData": {
      "2019": {
        "total": "50",
        "16to19": "6",
        "20to24": "10",
        "25to34": "16",
        "35to44": "7",
        "45to54": "5",
        "55to64": "5",
        "65+": "1",
        "medianAge": "5"
      }
    },
    "title": "Helpers, construction trades",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "50",
        "women": "5.7",
        "white": "86.6",
        "black": "3.4",
        "asian": "4.5",
        "hispanicOrLatino": "35.2"
      }
    },
    "wageData": {
      "2019": {
        "workers": "51",
        "median": "612",
        "maleWorkers": "48",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Construction and building inspectors": {
    "ageData": {
      "2019": {
        "total": "105",
        "16to19": "2",
        "20to24": "5",
        "25to34": "12",
        "35to44": "16",
        "45to54": "28",
        "55to64": "32",
        "65+": "10",
        "medianAge": "32"
      }
    },
    "title": "Construction and building inspectors",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "105",
        "women": "10.4",
        "white": "82.0",
        "black": "8.3",
        "asian": "6.8",
        "hispanicOrLatino": "12.8"
      }
    },
    "wageData": {
      "2019": {
        "workers": "77",
        "median": "1,134",
        "maleWorkers": "70",
        "maleMedian": "1,134",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Elevator installers and repairers": {
    "ageData": {
      "2019": {
        "total": "20",
        "16to19": "0",
        "20to24": "3",
        "25to34": "2",
        "35to44": "5",
        "45to54": "3",
        "55to64": "6",
        "65+": "0",
        "medianAge": "6"
      }
    },
    "title": "Elevator installers and repairers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "20",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "19",
        "median": "-",
        "maleWorkers": "18",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Fence erectors": {
    "ageData": {
      "2019": {
        "total": "41",
        "16to19": "1",
        "20to24": "5",
        "25to34": "17",
        "35to44": "7",
        "45to54": "8",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Fence erectors",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "41",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "26",
        "median": "-",
        "maleWorkers": "26",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Hazardous materials removal workers": {
    "ageData": {
      "2019": {
        "total": "40",
        "16to19": "0",
        "20to24": "3",
        "25to34": "8",
        "35to44": "8",
        "45to54": "9",
        "55to64": "9",
        "65+": "1",
        "medianAge": "9"
      }
    },
    "title": "Hazardous materials removal workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "40",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "36",
        "median": "-",
        "maleWorkers": "30",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Highway maintenance workers": {
    "ageData": {
      "2019": {
        "total": "113",
        "16to19": "3",
        "20to24": "12",
        "25to34": "23",
        "35to44": "15",
        "45to54": "29",
        "55to64": "24",
        "65+": "7",
        "medianAge": "24"
      }
    },
    "title": "Highway maintenance workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "113",
        "women": "3.6",
        "white": "83.6",
        "black": "12.7",
        "asian": "0.4",
        "hispanicOrLatino": "13.3"
      }
    },
    "wageData": {
      "2019": {
        "workers": "96",
        "median": "799",
        "maleWorkers": "91",
        "maleMedian": "806",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Rail-track laying and maintenance equipment operators": {
    "ageData": {
      "2019": {
        "total": "4",
        "16to19": "0",
        "20to24": "1",
        "25to34": "2",
        "35to44": "0",
        "45to54": "0",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Rail-track laying and maintenance equipment operators",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Septic tank servicers and sewer pipe cleaners": {
    "ageData": {
      "2019": {
        "total": "8",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "3",
        "45to54": "2",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Septic tank servicers and sewer pipe cleaners",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "8",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous construction and related workers": {
    "ageData": {
      "2019": {
        "total": "32",
        "16to19": "0",
        "20to24": "8",
        "25to34": "7",
        "35to44": "6",
        "45to54": "5",
        "55to64": "3",
        "65+": "2",
        "medianAge": "3"
      }
    },
    "title": "Miscellaneous construction and related workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "32",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "26",
        "median": "-",
        "maleWorkers": "26",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Derrick, rotary drill, and service unit operators, oil, gas, and mining": {
    "ageData": {
      "2019": {
        "total": "29",
        "16to19": "0",
        "20to24": "2",
        "25to34": "14",
        "35to44": "8",
        "45to54": "3",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Derrick, rotary drill, and service unit operators, oil, gas, and mining",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "29",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "24",
        "median": "-",
        "maleWorkers": "24",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Earth drillers, except oil and gas": {
    "ageData": {
      "2019": {
        "total": "22",
        "16to19": "0",
        "20to24": "1",
        "25to34": "8",
        "35to44": "7",
        "45to54": "3",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Earth drillers, except oil and gas",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "22",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "15",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Explosives workers, ordnance handling experts, and blasters": {
    "ageData": {
      "2019": {
        "total": "8",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "3",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Explosives workers, ordnance handling experts, and blasters",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "8",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Mining machine operators": {
    "ageData": {
      "2019": {
        "total": "56",
        "16to19": "0",
        "20to24": "4",
        "25to34": "18",
        "35to44": "13",
        "45to54": "9",
        "55to64": "10",
        "65+": "1",
        "medianAge": "10"
      }
    },
    "title": "Mining machine operators",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "56",
        "women": "2.0",
        "white": "85.2",
        "black": "9.3",
        "asian": "0.0",
        "hispanicOrLatino": "13.1"
      }
    },
    "wageData": {
      "2019": {
        "workers": "51",
        "median": "1,094",
        "maleWorkers": "50",
        "maleMedian": "1,101",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Roof bolters, mining": {
    "ageData": {
      "2019": {
        "total": "1",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "1",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Roof bolters, mining",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Roustabouts, oil and gas": {
    "ageData": {
      "2019": {
        "total": "8",
        "16to19": "0",
        "20to24": "1",
        "25to34": "4",
        "35to44": "1",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Roustabouts, oil and gas",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "8",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Helpers--extraction workers": {
    "ageData": {
      "2019": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Helpers--extraction workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Other extraction workers": {
    "ageData": {
      "2019": {
        "total": "46",
        "16to19": "0",
        "20to24": "5",
        "25to34": "17",
        "35to44": "11",
        "45to54": "6",
        "55to64": "6",
        "65+": "0",
        "medianAge": "6"
      }
    },
    "title": "Other extraction workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "46",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "51",
        "median": "1,128",
        "maleWorkers": "45",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Installation, maintenance, and repair occupations": {
    "ageData": {
      "2019": {
        "total": "4,862",
        "16to19": "90",
        "20to24": "440",
        "25to34": "1,096",
        "35to44": "1,075",
        "45to54": "1,046",
        "55to64": "903",
        "65+": "212",
        "medianAge": "903"
      }
    },
    "title": "Installation, maintenance, and repair occupations",
    "depth": 2,
    "parent": "Natural resources, construction, and maintenance occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "4,862",
        "women": "3.9",
        "white": "84.0",
        "black": "9.1",
        "asian": "3.3",
        "hispanicOrLatino": "20.3"
      }
    },
    "wageData": {
      "2019": {
        "workers": "4,304",
        "median": "939",
        "maleWorkers": "4,164",
        "maleMedian": "943",
        "femaleWorkers": "140",
        "femaleMedian": "850"
      }
    }
  },
  "First-line supervisors of mechanics, installers, and repairers": {
    "ageData": {
      "2019": {
        "total": "272",
        "16to19": "0",
        "20to24": "4",
        "25to34": "46",
        "35to44": "67",
        "45to54": "71",
        "55to64": "68",
        "65+": "16",
        "medianAge": "68"
      }
    },
    "title": "First-line supervisors of mechanics, installers, and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "272",
        "women": "8.2",
        "white": "85.7",
        "black": "9.9",
        "asian": "1.5",
        "hispanicOrLatino": "16.1"
      }
    },
    "wageData": {
      "2019": {
        "workers": "267",
        "median": "1,061",
        "maleWorkers": "250",
        "maleMedian": "1,082",
        "femaleWorkers": "17",
        "femaleMedian": "-"
      }
    }
  },
  "Computer, automated teller, and office machine repairers": {
    "ageData": {
      "2019": {
        "total": "184",
        "16to19": "2",
        "20to24": "20",
        "25to34": "50",
        "35to44": "33",
        "45to54": "43",
        "55to64": "28",
        "65+": "7",
        "medianAge": "28"
      }
    },
    "title": "Computer, automated teller, and office machine repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "184",
        "women": "14.0",
        "white": "74.7",
        "black": "11.7",
        "asian": "9.3",
        "hispanicOrLatino": "15.9"
      }
    },
    "wageData": {
      "2019": {
        "workers": "162",
        "median": "897",
        "maleWorkers": "141",
        "maleMedian": "899",
        "femaleWorkers": "21",
        "femaleMedian": "-"
      }
    }
  },
  "Radio and telecommunications equipment installers and repairers": {
    "ageData": {
      "2019": {
        "total": "131",
        "16to19": "3",
        "20to24": "13",
        "25to34": "27",
        "35to44": "30",
        "45to54": "31",
        "55to64": "22",
        "65+": "5",
        "medianAge": "22"
      }
    },
    "title": "Radio and telecommunications equipment installers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "131",
        "women": "7.6",
        "white": "73.1",
        "black": "15.8",
        "asian": "4.9",
        "hispanicOrLatino": "15.0"
      }
    },
    "wageData": {
      "2019": {
        "workers": "124",
        "median": "1,063",
        "maleWorkers": "117",
        "maleMedian": "1,058",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Avionics technicians": {
    "ageData": {
      "2019": {
        "total": "13",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "4",
        "45to54": "3",
        "55to64": "5",
        "65+": "0",
        "medianAge": "5"
      }
    },
    "title": "Avionics technicians",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "13",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "16",
        "median": "-",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Electric motor, power tool, and related repairers": {
    "ageData": {
      "2019": {
        "total": "16",
        "16to19": "1",
        "20to24": "1",
        "25to34": "3",
        "35to44": "2",
        "45to54": "2",
        "55to64": "5",
        "65+": "1",
        "medianAge": "5"
      }
    },
    "title": "Electric motor, power tool, and related repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "16",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Electrical and electronics installers and repairers, transportation equipment": {
    "ageData": {
      "2019": {
        "total": "7",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "2",
        "45to54": "1",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Electrical and electronics installers and repairers, transportation equipment",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Electrical and electronics repairers, industrial and utility": {
    "ageData": {
      "2019": {
        "total": "17",
        "16to19": "0",
        "20to24": "0",
        "25to34": "6",
        "35to44": "2",
        "45to54": "6",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Electrical and electronics repairers, industrial and utility",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "17",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "22",
        "median": "-",
        "maleWorkers": "22",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Electronic equipment installers and repairers, motor vehicles": {
    "ageData": {
      "2019": {
        "total": "9",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "0",
        "45to54": "3",
        "55to64": "1",
        "65+": "1",
        "medianAge": "1"
      }
    },
    "title": "Electronic equipment installers and repairers, motor vehicles",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "9",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Electronic home entertainment equipment installers and repairers": {
    "ageData": {
      "2019": {
        "total": "32",
        "16to19": "1",
        "20to24": "3",
        "25to34": "12",
        "35to44": "8",
        "45to54": "5",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Electronic home entertainment equipment installers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "32",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "25",
        "median": "-",
        "maleWorkers": "25",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Security and fire alarm systems installers": {
    "ageData": {
      "2019": {
        "total": "58",
        "16to19": "1",
        "20to24": "6",
        "25to34": "12",
        "35to44": "17",
        "45to54": "15",
        "55to64": "7",
        "65+": "1",
        "medianAge": "7"
      }
    },
    "title": "Security and fire alarm systems installers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "58",
        "women": "2.9",
        "white": "76.9",
        "black": "14.3",
        "asian": "4.6",
        "hispanicOrLatino": "23.5"
      }
    },
    "wageData": {
      "2019": {
        "workers": "55",
        "median": "869",
        "maleWorkers": "54",
        "maleMedian": "875",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Aircraft mechanics and service technicians": {
    "ageData": {
      "2019": {
        "total": "152",
        "16to19": "1",
        "20to24": "11",
        "25to34": "25",
        "35to44": "32",
        "45to54": "41",
        "55to64": "32",
        "65+": "10",
        "medianAge": "32"
      }
    },
    "title": "Aircraft mechanics and service technicians",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "152",
        "women": "4.8",
        "white": "82.4",
        "black": "10.5",
        "asian": "4.8",
        "hispanicOrLatino": "12.4"
      }
    },
    "wageData": {
      "2019": {
        "workers": "155",
        "median": "1,245",
        "maleWorkers": "148",
        "maleMedian": "1,265",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Automotive body and related repairers": {
    "ageData": {
      "2019": {
        "total": "125",
        "16to19": "1",
        "20to24": "7",
        "25to34": "31",
        "35to44": "30",
        "45to54": "27",
        "55to64": "25",
        "65+": "5",
        "medianAge": "25"
      }
    },
    "title": "Automotive body and related repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "125",
        "women": "2.2",
        "white": "92.4",
        "black": "3.4",
        "asian": "1.8",
        "hispanicOrLatino": "29.6"
      }
    },
    "wageData": {
      "2019": {
        "workers": "94",
        "median": "885",
        "maleWorkers": "94",
        "maleMedian": "885",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Automotive glass installers and repairers": {
    "ageData": {
      "2019": {
        "total": "21",
        "16to19": "1",
        "20to24": "4",
        "25to34": "4",
        "35to44": "4",
        "45to54": "5",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Automotive glass installers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "21",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "15",
        "median": "-",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Automotive service technicians and mechanics": {
    "ageData": {
      "2019": {
        "total": "880",
        "16to19": "22",
        "20to24": "120",
        "25to34": "223",
        "35to44": "184",
        "45to54": "176",
        "55to64": "133",
        "65+": "22",
        "medianAge": "133"
      }
    },
    "title": "Automotive service technicians and mechanics",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "880",
        "women": "1.9",
        "white": "83.8",
        "black": "8.7",
        "asian": "3.2",
        "hispanicOrLatino": "27.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "705",
        "median": "811",
        "maleWorkers": "693",
        "maleMedian": "811",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Bus and truck mechanics and diesel engine specialists": {
    "ageData": {
      "2019": {
        "total": "347",
        "16to19": "5",
        "20to24": "42",
        "25to34": "83",
        "35to44": "79",
        "45to54": "65",
        "55to64": "62",
        "65+": "11",
        "medianAge": "62"
      }
    },
    "title": "Bus and truck mechanics and diesel engine specialists",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "347",
        "women": "1.5",
        "white": "87.3",
        "black": "6.5",
        "asian": "3.7",
        "hispanicOrLatino": "14.1"
      }
    },
    "wageData": {
      "2019": {
        "workers": "315",
        "median": "984",
        "maleWorkers": "312",
        "maleMedian": "983",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Heavy vehicle and mobile equipment service technicians and mechanics": {
    "ageData": {
      "2019": {
        "total": "211",
        "16to19": "2",
        "20to24": "21",
        "25to34": "50",
        "35to44": "38",
        "45to54": "46",
        "55to64": "46",
        "65+": "8",
        "medianAge": "46"
      }
    },
    "title": "Heavy vehicle and mobile equipment service technicians and mechanics",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "211",
        "women": "1.5",
        "white": "88.3",
        "black": "6.0",
        "asian": "2.6",
        "hispanicOrLatino": "15.8"
      }
    },
    "wageData": {
      "2019": {
        "workers": "212",
        "median": "1,008",
        "maleWorkers": "212",
        "maleMedian": "1,008",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Small engine mechanics": {
    "ageData": {
      "2019": {
        "total": "45",
        "16to19": "3",
        "20to24": "4",
        "25to34": "10",
        "35to44": "11",
        "45to54": "5",
        "55to64": "8",
        "65+": "5",
        "medianAge": "8"
      }
    },
    "title": "Small engine mechanics",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "45",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "24",
        "median": "-",
        "maleWorkers": "24",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous vehicle and mobile equipment mechanics, installers, and repairers": {
    "ageData": {
      "2019": {
        "total": "73",
        "16to19": "6",
        "20to24": "14",
        "25to34": "18",
        "35to44": "14",
        "45to54": "10",
        "55to64": "11",
        "65+": "1",
        "medianAge": "11"
      }
    },
    "title": "Miscellaneous vehicle and mobile equipment mechanics, installers, and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "73",
        "women": "1.3",
        "white": "77.0",
        "black": "12.2",
        "asian": "3.6",
        "hispanicOrLatino": "31.0"
      }
    },
    "wageData": {
      "2019": {
        "workers": "58",
        "median": "627",
        "maleWorkers": "58",
        "maleMedian": "628",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Control and valve installers and repairers": {
    "ageData": {
      "2019": {
        "total": "22",
        "16to19": "0",
        "20to24": "1",
        "25to34": "4",
        "35to44": "7",
        "45to54": "4",
        "55to64": "5",
        "65+": "1",
        "medianAge": "5"
      }
    },
    "title": "Control and valve installers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "22",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "22",
        "median": "-",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Heating, air conditioning, and refrigeration mechanics and installers": {
    "ageData": {
      "2019": {
        "total": "466",
        "16to19": "13",
        "20to24": "44",
        "25to34": "121",
        "35to44": "104",
        "45to54": "97",
        "55to64": "68",
        "65+": "19",
        "medianAge": "68"
      }
    },
    "title": "Heating, air conditioning, and refrigeration mechanics and installers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "466",
        "women": "1.5",
        "white": "84.0",
        "black": "9.1",
        "asian": "3.0",
        "hispanicOrLatino": "20.2"
      }
    },
    "wageData": {
      "2019": {
        "workers": "416",
        "median": "944",
        "maleWorkers": "409",
        "maleMedian": "947",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Home appliance repairers": {
    "ageData": {
      "2019": {
        "total": "51",
        "16to19": "1",
        "20to24": "3",
        "25to34": "11",
        "35to44": "8",
        "45to54": "16",
        "55to64": "7",
        "65+": "5",
        "medianAge": "7"
      }
    },
    "title": "Home appliance repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "51",
        "women": "5.7",
        "white": "86.3",
        "black": "11.7",
        "asian": "1.8",
        "hispanicOrLatino": "18.8"
      }
    },
    "wageData": {
      "2019": {
        "workers": "32",
        "median": "-",
        "maleWorkers": "31",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Industrial and refractory machinery mechanics": {
    "ageData": {
      "2019": {
        "total": "408",
        "16to19": "4",
        "20to24": "24",
        "25to34": "71",
        "35to44": "100",
        "45to54": "95",
        "55to64": "99",
        "65+": "14",
        "medianAge": "99"
      }
    },
    "title": "Industrial and refractory machinery mechanics",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "408",
        "women": "4.1",
        "white": "85.6",
        "black": "8.7",
        "asian": "2.5",
        "hispanicOrLatino": "18.1"
      }
    },
    "wageData": {
      "2019": {
        "workers": "392",
        "median": "1,018",
        "maleWorkers": "382",
        "maleMedian": "1,028",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Maintenance and repair workers, general": {
    "ageData": {
      "2019": {
        "total": "579",
        "16to19": "8",
        "20to24": "32",
        "25to34": "102",
        "35to44": "123",
        "45to54": "141",
        "55to64": "134",
        "65+": "40",
        "medianAge": "134"
      }
    },
    "title": "Maintenance and repair workers, general",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "579",
        "women": "3.8",
        "white": "83.9",
        "black": "9.4",
        "asian": "3.2",
        "hispanicOrLatino": "20.1"
      }
    },
    "wageData": {
      "2019": {
        "workers": "534",
        "median": "882",
        "maleWorkers": "514",
        "maleMedian": "883",
        "femaleWorkers": "20",
        "femaleMedian": "-"
      }
    }
  },
  "Maintenance workers, machinery": {
    "ageData": {
      "2019": {
        "total": "21",
        "16to19": "0",
        "20to24": "0",
        "25to34": "6",
        "35to44": "4",
        "45to54": "5",
        "55to64": "4",
        "65+": "2",
        "medianAge": "4"
      }
    },
    "title": "Maintenance workers, machinery",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "21",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "21",
        "median": "-",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Millwrights": {
    "ageData": {
      "2019": {
        "total": "52",
        "16to19": "0",
        "20to24": "3",
        "25to34": "9",
        "35to44": "13",
        "45to54": "11",
        "55to64": "12",
        "65+": "3",
        "medianAge": "12"
      }
    },
    "title": "Millwrights",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "52",
        "women": "5.0",
        "white": "88.9",
        "black": "7.9",
        "asian": "1.8",
        "hispanicOrLatino": "10.3"
      }
    },
    "wageData": {
      "2019": {
        "workers": "51",
        "median": "1,180",
        "maleWorkers": "48",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Electrical power-line installers and repairers": {
    "ageData": {
      "2019": {
        "total": "133",
        "16to19": "1",
        "20to24": "18",
        "25to34": "40",
        "35to44": "39",
        "45to54": "23",
        "55to64": "13",
        "65+": "1",
        "medianAge": "13"
      }
    },
    "title": "Electrical power-line installers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "133",
        "women": "1.6",
        "white": "89.8",
        "black": "3.0",
        "asian": "0.6",
        "hispanicOrLatino": "15.4"
      }
    },
    "wageData": {
      "2019": {
        "workers": "123",
        "median": "1,460",
        "maleWorkers": "123",
        "maleMedian": "1,460",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Telecommunications line installers and repairers": {
    "ageData": {
      "2019": {
        "total": "184",
        "16to19": "5",
        "20to24": "12",
        "25to34": "53",
        "35to44": "52",
        "45to54": "43",
        "55to64": "16",
        "65+": "2",
        "medianAge": "16"
      }
    },
    "title": "Telecommunications line installers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "184",
        "women": "4.5",
        "white": "82.2",
        "black": "12.5",
        "asian": "2.3",
        "hispanicOrLatino": "24.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "178",
        "median": "901",
        "maleWorkers": "168",
        "maleMedian": "884",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Precision instrument and equipment repairers": {
    "ageData": {
      "2019": {
        "total": "69",
        "16to19": "1",
        "20to24": "5",
        "25to34": "9",
        "35to44": "12",
        "45to54": "12",
        "55to64": "20",
        "65+": "9",
        "medianAge": "20"
      }
    },
    "title": "Precision instrument and equipment repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "69",
        "women": "11.6",
        "white": "73.7",
        "black": "11.9",
        "asian": "12.6",
        "hispanicOrLatino": "11.4"
      }
    },
    "wageData": {
      "2019": {
        "workers": "48",
        "median": "-",
        "maleWorkers": "42",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Wind turbine service technicians": {
    "ageData": {
      "2019": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "1",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Wind turbine service technicians",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Coin, vending, and amusement machine servicers and repairers": {
    "ageData": {
      "2019": {
        "total": "28",
        "16to19": "0",
        "20to24": "3",
        "25to34": "3",
        "35to44": "7",
        "45to54": "3",
        "55to64": "8",
        "65+": "3",
        "medianAge": "8"
      }
    },
    "title": "Coin, vending, and amusement machine servicers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "28",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "20",
        "median": "-",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Commercial divers": {
    "ageData": {
      "2019": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "1",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Commercial divers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "0",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Locksmiths and safe repairers": {
    "ageData": {
      "2019": {
        "total": "32",
        "16to19": "0",
        "20to24": "0",
        "25to34": "11",
        "35to44": "6",
        "45to54": "5",
        "55to64": "7",
        "65+": "2",
        "medianAge": "7"
      }
    },
    "title": "Locksmiths and safe repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "32",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "21",
        "median": "-",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Manufactured building and mobile home installers": {
    "ageData": {
      "2019": {
        "total": "5",
        "16to19": "0",
        "20to24": "0",
        "25to34": "4",
        "35to44": "0",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Manufactured building and mobile home installers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Riggers": {
    "ageData": {
      "2019": {
        "total": "12",
        "16to19": "0",
        "20to24": "0",
        "25to34": "5",
        "35to44": "3",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Riggers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "12",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Signal and track switch repairers": {
    "ageData": {
      "2019": {
        "total": "7",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "2",
        "45to54": "1",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Signal and track switch repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Helpers--installation, maintenance, and repair workers": {
    "ageData": {
      "2019": {
        "total": "19",
        "16to19": "3",
        "20to24": "4",
        "25to34": "4",
        "35to44": "4",
        "45to54": "1",
        "55to64": "1",
        "65+": "2",
        "medianAge": "1"
      }
    },
    "title": "Helpers--installation, maintenance, and repair workers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "19",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "17",
        "median": "-",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Other installation, maintenance, and repair workers": {
    "ageData": {
      "2019": {
        "total": "179",
        "16to19": "5",
        "20to24": "21",
        "25to34": "36",
        "35to44": "34",
        "45to54": "30",
        "55to64": "41",
        "65+": "13",
        "medianAge": "41"
      }
    },
    "title": "Other installation, maintenance, and repair workers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "179",
        "women": "8.0",
        "white": "88.4",
        "black": "8.1",
        "asian": "0.9",
        "hispanicOrLatino": "27.1"
      }
    },
    "wageData": {
      "2019": {
        "workers": "128",
        "median": "839",
        "maleWorkers": "118",
        "maleMedian": "850",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Production, transportation, and material moving occupations": {
    "ageData": {
      "2019": {
        "total": "18,628",
        "16to19": "543",
        "20to24": "1,808",
        "25to34": "4,027",
        "35to44": "3,644",
        "45to54": "3,950",
        "55to64": "3,542",
        "65+": "1,115",
        "medianAge": "3,542"
      }
    },
    "title": "Production, transportation, and material moving occupations",
    "depth": 1,
    "parent": "",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "18,628",
        "women": "23.0",
        "white": "74.2",
        "black": "16.9",
        "asian": "5.0",
        "hispanicOrLatino": "23.0"
      }
    },
    "wageData": {
      "2019": {
        "workers": "15,353",
        "median": "727",
        "maleWorkers": "12,020",
        "maleMedian": "780",
        "femaleWorkers": "3,334",
        "femaleMedian": "593"
      }
    }
  },
  "Production occupations": {
    "ageData": {
      "2019": {
        "total": "8,565",
        "16to19": "170",
        "20to24": "775",
        "25to34": "1,897",
        "35to44": "1,778",
        "45to54": "1,841",
        "55to64": "1,705",
        "65+": "399",
        "medianAge": "1,705"
      }
    },
    "title": "Production occupations",
    "depth": 2,
    "parent": "Production, transportation, and material moving occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "8,565",
        "women": "28.6",
        "white": "76.6",
        "black": "13.3",
        "asian": "6.0",
        "hispanicOrLatino": "23.1"
      }
    },
    "wageData": {
      "2019": {
        "workers": "7,741",
        "median": "745",
        "maleWorkers": "5,668",
        "maleMedian": "814",
        "femaleWorkers": "2,073",
        "femaleMedian": "596"
      }
    }
  },
  "First-line supervisors of production and operating workers": {
    "ageData": {
      "2019": {
        "total": "844",
        "16to19": "1",
        "20to24": "20",
        "25to34": "173",
        "35to44": "209",
        "45to54": "227",
        "55to64": "186",
        "65+": "28",
        "medianAge": "186"
      }
    },
    "title": "First-line supervisors of production and operating workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "844",
        "women": "21.3",
        "white": "80.3",
        "black": "12.2",
        "asian": "3.8",
        "hispanicOrLatino": "17.2"
      }
    },
    "wageData": {
      "2019": {
        "workers": "806",
        "median": "949",
        "maleWorkers": "633",
        "maleMedian": "1,031",
        "femaleWorkers": "173",
        "femaleMedian": "721"
      }
    }
  },
  "Aircraft structure, surfaces, rigging, and systems assemblers": {
    "ageData": {
      "2019": {
        "total": "8",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "0",
        "45to54": "2",
        "55to64": "1",
        "65+": "2",
        "medianAge": "1"
      }
    },
    "title": "Aircraft structure, surfaces, rigging, and systems assemblers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "8",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "9",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Electrical, electronics, and electromechanical assemblers": {
    "ageData": {
      "2019": {
        "total": "101",
        "16to19": "3",
        "20to24": "9",
        "25to34": "19",
        "35to44": "19",
        "45to54": "24",
        "55to64": "22",
        "65+": "4",
        "medianAge": "22"
      }
    },
    "title": "Electrical, electronics, and electromechanical assemblers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "101",
        "women": "50.4",
        "white": "64.5",
        "black": "8.0",
        "asian": "20.2",
        "hispanicOrLatino": "20.1"
      }
    },
    "wageData": {
      "2019": {
        "workers": "103",
        "median": "644",
        "maleWorkers": "52",
        "maleMedian": "712",
        "femaleWorkers": "51",
        "femaleMedian": "594"
      }
    }
  },
  "Engine and other machine assemblers": {
    "ageData": {
      "2019": {
        "total": "9",
        "16to19": "0",
        "20to24": "1",
        "25to34": "3",
        "35to44": "2",
        "45to54": "2",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Engine and other machine assemblers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "9",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Structural metal fabricators and fitters": {
    "ageData": {
      "2019": {
        "total": "25",
        "16to19": "1",
        "20to24": "3",
        "25to34": "9",
        "35to44": "7",
        "45to54": "2",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Structural metal fabricators and fitters",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "25",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "24",
        "median": "-",
        "maleWorkers": "24",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous assemblers and fabricators": {
    "ageData": {
      "2019": {
        "total": "1,001",
        "16to19": "30",
        "20to24": "120",
        "25to34": "240",
        "35to44": "201",
        "45to54": "190",
        "55to64": "181",
        "65+": "39",
        "medianAge": "181"
      }
    },
    "title": "Miscellaneous assemblers and fabricators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "1,001",
        "women": "36.4",
        "white": "67.0",
        "black": "19.7",
        "asian": "9.6",
        "hispanicOrLatino": "21.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "900",
        "median": "684",
        "maleWorkers": "574",
        "maleMedian": "724",
        "femaleWorkers": "326",
        "femaleMedian": "597"
      }
    }
  },
  "Bakers": {
    "ageData": {
      "2019": {
        "total": "228",
        "16to19": "7",
        "20to24": "22",
        "25to34": "53",
        "35to44": "46",
        "45to54": "42",
        "55to64": "46",
        "65+": "12",
        "medianAge": "46"
      }
    },
    "title": "Bakers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "228",
        "women": "60.4",
        "white": "74.0",
        "black": "11.7",
        "asian": "9.6",
        "hispanicOrLatino": "30.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "151",
        "median": "561",
        "maleWorkers": "70",
        "maleMedian": "590",
        "femaleWorkers": "81",
        "femaleMedian": "522"
      }
    }
  },
  "Butchers and other meat, poultry, and fish processing workers": {
    "ageData": {
      "2019": {
        "total": "297",
        "16to19": "5",
        "20to24": "32",
        "25to34": "62",
        "35to44": "70",
        "45to54": "68",
        "55to64": "43",
        "65+": "16",
        "medianAge": "43"
      }
    },
    "title": "Butchers and other meat, poultry, and fish processing workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "297",
        "women": "26.9",
        "white": "71.2",
        "black": "17.1",
        "asian": "6.2",
        "hispanicOrLatino": "37.3"
      }
    },
    "wageData": {
      "2019": {
        "workers": "265",
        "median": "648",
        "maleWorkers": "198",
        "maleMedian": "671",
        "femaleWorkers": "67",
        "femaleMedian": "573"
      }
    }
  },
  "Food and tobacco roasting, baking, and drying machine operators and tenders": {
    "ageData": {
      "2019": {
        "total": "17",
        "16to19": "0",
        "20to24": "0",
        "25to34": "4",
        "35to44": "3",
        "45to54": "4",
        "55to64": "3",
        "65+": "2",
        "medianAge": "3"
      }
    },
    "title": "Food and tobacco roasting, baking, and drying machine operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "17",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Food batchmakers": {
    "ageData": {
      "2019": {
        "total": "82",
        "16to19": "3",
        "20to24": "5",
        "25to34": "23",
        "35to44": "14",
        "45to54": "19",
        "55to64": "13",
        "65+": "5",
        "medianAge": "13"
      }
    },
    "title": "Food batchmakers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "82",
        "women": "62.7",
        "white": "87.9",
        "black": "7.3",
        "asian": "1.6",
        "hispanicOrLatino": "36.2"
      }
    },
    "wageData": {
      "2019": {
        "workers": "68",
        "median": "615",
        "maleWorkers": "26",
        "maleMedian": "-",
        "femaleWorkers": "42",
        "femaleMedian": "-"
      }
    }
  },
  "Food cooking machine operators and tenders": {
    "ageData": {
      "2019": {
        "total": "3",
        "16to19": "1",
        "20to24": "0",
        "25to34": "1",
        "35to44": "1",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Food cooking machine operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Food processing workers, all other": {
    "ageData": {
      "2019": {
        "total": "147",
        "16to19": "2",
        "20to24": "16",
        "25to34": "31",
        "35to44": "40",
        "45to54": "28",
        "55to64": "26",
        "65+": "4",
        "medianAge": "26"
      }
    },
    "title": "Food processing workers, all other",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "147",
        "women": "33.8",
        "white": "72.1",
        "black": "18.1",
        "asian": "7.0",
        "hispanicOrLatino": "38.8"
      }
    },
    "wageData": {
      "2019": {
        "workers": "144",
        "median": "658",
        "maleWorkers": "94",
        "maleMedian": "779",
        "femaleWorkers": "50",
        "femaleMedian": "573"
      }
    }
  },
  "Computer control programmers and operators": {
    "ageData": {
      "2019": {
        "total": "108",
        "16to19": "2",
        "20to24": "13",
        "25to34": "36",
        "35to44": "21",
        "45to54": "19",
        "55to64": "14",
        "65+": "3",
        "medianAge": "14"
      }
    },
    "title": "Computer control programmers and operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "108",
        "women": "3.7",
        "white": "87.0",
        "black": "6.6",
        "asian": "5.0",
        "hispanicOrLatino": "11.4"
      }
    },
    "wageData": {
      "2019": {
        "workers": "103",
        "median": "849",
        "maleWorkers": "100",
        "maleMedian": "857",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Extruding and drawing machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2019": {
        "total": "13",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "3",
        "45to54": "4",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Extruding and drawing machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "13",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "16",
        "median": "-",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Forging machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2019": {
        "total": "7",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "3",
        "45to54": "1",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Forging machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "9",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Rolling machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2019": {
        "total": "13",
        "16to19": "0",
        "20to24": "1",
        "25to34": "0",
        "35to44": "4",
        "45to54": "4",
        "55to64": "3",
        "65+": "2",
        "medianAge": "3"
      }
    },
    "title": "Rolling machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "13",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Cutting, punching, and press machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2019": {
        "total": "70",
        "16to19": "0",
        "20to24": "6",
        "25to34": "15",
        "35to44": "16",
        "45to54": "13",
        "55to64": "16",
        "65+": "4",
        "medianAge": "16"
      }
    },
    "title": "Cutting, punching, and press machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "70",
        "women": "15.8",
        "white": "75.2",
        "black": "17.3",
        "asian": "0.3",
        "hispanicOrLatino": "13.9"
      }
    },
    "wageData": {
      "2019": {
        "workers": "67",
        "median": "830",
        "maleWorkers": "58",
        "maleMedian": "816",
        "femaleWorkers": "9",
        "femaleMedian": "-"
      }
    }
  },
  "Drilling and boring machine tool setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2019": {
        "total": "6",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "3",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Drilling and boring machine tool setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "6",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Grinding, lapping, polishing, and buffing machine tool setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2019": {
        "total": "44",
        "16to19": "2",
        "20to24": "1",
        "25to34": "9",
        "35to44": "8",
        "45to54": "10",
        "55to64": "9",
        "65+": "3",
        "medianAge": "9"
      }
    },
    "title": "Grinding, lapping, polishing, and buffing machine tool setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "44",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "40",
        "median": "-",
        "maleWorkers": "35",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Lathe and turning machine tool setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2019": {
        "total": "6",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "1",
        "45to54": "2",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Lathe and turning machine tool setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "6",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Milling and planing machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2019": {
        "total": "5",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "1",
        "45to54": "0",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Milling and planing machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Machinists": {
    "ageData": {
      "2019": {
        "total": "375",
        "16to19": "7",
        "20to24": "38",
        "25to34": "70",
        "35to44": "61",
        "45to54": "74",
        "55to64": "108",
        "65+": "16",
        "medianAge": "108"
      }
    },
    "title": "Machinists",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "375",
        "women": "5.6",
        "white": "84.8",
        "black": "7.0",
        "asian": "4.9",
        "hispanicOrLatino": "11.5"
      }
    },
    "wageData": {
      "2019": {
        "workers": "357",
        "median": "888",
        "maleWorkers": "335",
        "maleMedian": "889",
        "femaleWorkers": "22",
        "femaleMedian": "-"
      }
    }
  },
  "Metal furnace operators, tenders, pourers, and casters": {
    "ageData": {
      "2019": {
        "total": "23",
        "16to19": "0",
        "20to24": "2",
        "25to34": "4",
        "35to44": "2",
        "45to54": "11",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Metal furnace operators, tenders, pourers, and casters",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "23",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "26",
        "median": "-",
        "maleWorkers": "25",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Model makers and patternmakers, metal and plastic": {
    "ageData": {
      "2019": {
        "total": "5",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "1",
        "45to54": "1",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Model makers and patternmakers, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Molders and molding machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2019": {
        "total": "45",
        "16to19": "1",
        "20to24": "6",
        "25to34": "8",
        "35to44": "4",
        "45to54": "15",
        "55to64": "10",
        "65+": "1",
        "medianAge": "10"
      }
    },
    "title": "Molders and molding machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "45",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "37",
        "median": "-",
        "maleWorkers": "32",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Multiple machine tool setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2019": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "1",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Multiple machine tool setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Tool and die makers": {
    "ageData": {
      "2019": {
        "total": "55",
        "16to19": "2",
        "20to24": "3",
        "25to34": "11",
        "35to44": "7",
        "45to54": "15",
        "55to64": "14",
        "65+": "4",
        "medianAge": "14"
      }
    },
    "title": "Tool and die makers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "55",
        "women": "4.8",
        "white": "95.1",
        "black": "3.7",
        "asian": "0.3",
        "hispanicOrLatino": "3.4"
      }
    },
    "wageData": {
      "2019": {
        "workers": "53",
        "median": "952",
        "maleWorkers": "51",
        "maleMedian": "972",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Welding, soldering, and brazing workers": {
    "ageData": {
      "2019": {
        "total": "592",
        "16to19": "16",
        "20to24": "67",
        "25to34": "164",
        "35to44": "127",
        "45to54": "114",
        "55to64": "91",
        "65+": "13",
        "medianAge": "91"
      }
    },
    "title": "Welding, soldering, and brazing workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "592",
        "women": "5.3",
        "white": "85.3",
        "black": "8.8",
        "asian": "2.3",
        "hispanicOrLatino": "22.8"
      }
    },
    "wageData": {
      "2019": {
        "workers": "549",
        "median": "801",
        "maleWorkers": "518",
        "maleMedian": "810",
        "femaleWorkers": "31",
        "femaleMedian": "-"
      }
    }
  },
  "Heat treating equipment setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2019": {
        "total": "4",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "1",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Heat treating equipment setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Layout workers, metal and plastic": {
    "ageData": {
      "2019": {
        "total": "10",
        "16to19": "0",
        "20to24": "2",
        "25to34": "2",
        "35to44": "2",
        "45to54": "1",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Layout workers, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "10",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Plating and coating machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2019": {
        "total": "13",
        "16to19": "0",
        "20to24": "1",
        "25to34": "3",
        "35to44": "1",
        "45to54": "4",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Plating and coating machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "13",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Tool grinders, filers, and sharpeners": {
    "ageData": {
      "2019": {
        "total": "1",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Tool grinders, filers, and sharpeners",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Metal workers and plastic workers, all other": {
    "ageData": {
      "2019": {
        "total": "392",
        "16to19": "3",
        "20to24": "36",
        "25to34": "85",
        "35to44": "88",
        "45to54": "82",
        "55to64": "79",
        "65+": "17",
        "medianAge": "79"
      }
    },
    "title": "Metal workers and plastic workers, all other",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "392",
        "women": "19.5",
        "white": "72.0",
        "black": "14.5",
        "asian": "8.2",
        "hispanicOrLatino": "26.0"
      }
    },
    "wageData": {
      "2019": {
        "workers": "385",
        "median": "736",
        "maleWorkers": "312",
        "maleMedian": "795",
        "femaleWorkers": "74",
        "femaleMedian": "592"
      }
    }
  },
  "Prepress technicians and workers": {
    "ageData": {
      "2019": {
        "total": "27",
        "16to19": "0",
        "20to24": "7",
        "25to34": "7",
        "35to44": "2",
        "45to54": "6",
        "55to64": "5",
        "65+": "0",
        "medianAge": "5"
      }
    },
    "title": "Prepress technicians and workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "27",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "27",
        "median": "-",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Printing press operators": {
    "ageData": {
      "2019": {
        "total": "133",
        "16to19": "2",
        "20to24": "15",
        "25to34": "27",
        "35to44": "22",
        "45to54": "38",
        "55to64": "23",
        "65+": "6",
        "medianAge": "23"
      }
    },
    "title": "Printing press operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "133",
        "women": "22.1",
        "white": "84.3",
        "black": "7.2",
        "asian": "4.0",
        "hispanicOrLatino": "23.1"
      }
    },
    "wageData": {
      "2019": {
        "workers": "109",
        "median": "814",
        "maleWorkers": "87",
        "maleMedian": "886",
        "femaleWorkers": "22",
        "femaleMedian": "-"
      }
    }
  },
  "Print binding and finishing workers": {
    "ageData": {
      "2019": {
        "total": "14",
        "16to19": "0",
        "20to24": "1",
        "25to34": "3",
        "35to44": "1",
        "45to54": "3",
        "55to64": "5",
        "65+": "1",
        "medianAge": "5"
      }
    },
    "title": "Print binding and finishing workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "14",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "15",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Laundry and dry-cleaning workers": {
    "ageData": {
      "2019": {
        "total": "134",
        "16to19": "4",
        "20to24": "5",
        "25to34": "21",
        "35to44": "32",
        "45to54": "31",
        "55to64": "36",
        "65+": "6",
        "medianAge": "36"
      }
    },
    "title": "Laundry and dry-cleaning workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "134",
        "women": "75.4",
        "white": "55.9",
        "black": "25.1",
        "asian": "12.2",
        "hispanicOrLatino": "37.9"
      }
    },
    "wageData": {
      "2019": {
        "workers": "112",
        "median": "490",
        "maleWorkers": "37",
        "maleMedian": "-",
        "femaleWorkers": "74",
        "femaleMedian": "482"
      }
    }
  },
  "Pressers, textile, garment, and related materials": {
    "ageData": {
      "2019": {
        "total": "28",
        "16to19": "0",
        "20to24": "2",
        "25to34": "7",
        "35to44": "11",
        "45to54": "5",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Pressers, textile, garment, and related materials",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "28",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "19",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "14",
        "femaleMedian": "-"
      }
    }
  },
  "Sewing machine operators": {
    "ageData": {
      "2019": {
        "total": "179",
        "16to19": "2",
        "20to24": "15",
        "25to34": "14",
        "35to44": "37",
        "45to54": "52",
        "55to64": "44",
        "65+": "15",
        "medianAge": "44"
      }
    },
    "title": "Sewing machine operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "179",
        "women": "72.4",
        "white": "69.3",
        "black": "11.0",
        "asian": "15.3",
        "hispanicOrLatino": "35.4"
      }
    },
    "wageData": {
      "2019": {
        "workers": "138",
        "median": "524",
        "maleWorkers": "40",
        "maleMedian": "-",
        "femaleWorkers": "98",
        "femaleMedian": "521"
      }
    }
  },
  "Shoe and leather workers and repairers": {
    "ageData": {
      "2019": {
        "total": "7",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "2",
        "55to64": "2",
        "65+": "3",
        "medianAge": "2"
      }
    },
    "title": "Shoe and leather workers and repairers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Shoe machine operators and tenders": {
    "ageData": {
      "2019": {
        "total": "5",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "2",
        "45to54": "0",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Shoe machine operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Tailors, dressmakers, and sewers": {
    "ageData": {
      "2019": {
        "total": "86",
        "16to19": "0",
        "20to24": "0",
        "25to34": "11",
        "35to44": "16",
        "45to54": "14",
        "55to64": "25",
        "65+": "21",
        "medianAge": "25"
      }
    },
    "title": "Tailors, dressmakers, and sewers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "86",
        "women": "75.4",
        "white": "66.3",
        "black": "9.5",
        "asian": "23.7",
        "hispanicOrLatino": "20.9"
      }
    },
    "wageData": {
      "2019": {
        "workers": "55",
        "median": "576",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "36",
        "femaleMedian": "-"
      }
    }
  },
  "Textile bleaching and dyeing machine operators and tenders": {
    "ageData": {
      "2019": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Textile bleaching and dyeing machine operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "0",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Textile cutting machine setters, operators, and tenders": {
    "ageData": {
      "2019": {
        "total": "4",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "0",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Textile cutting machine setters, operators, and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Textile knitting and weaving machine setters, operators, and tenders": {
    "ageData": {
      "2019": {
        "total": "9",
        "16to19": "1",
        "20to24": "0",
        "25to34": "0",
        "35to44": "2",
        "45to54": "2",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Textile knitting and weaving machine setters, operators, and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "9",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Textile winding, twisting, and drawing out machine setters, operators, and tenders": {
    "ageData": {
      "2019": {
        "total": "10",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "2",
        "45to54": "3",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Textile winding, twisting, and drawing out machine setters, operators, and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "10",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Extruding and forming machine setters, operators, and tenders, synthetic and glass fibers": {
    "ageData": {
      "2019": {
        "total": "1",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Extruding and forming machine setters, operators, and tenders, synthetic and glass fibers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "0",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Fabric and apparel patternmakers": {
    "ageData": {
      "2019": {
        "total": "0",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Fabric and apparel patternmakers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "0",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Upholsterers": {
    "ageData": {
      "2019": {
        "total": "30",
        "16to19": "1",
        "20to24": "2",
        "25to34": "2",
        "35to44": "5",
        "45to54": "14",
        "55to64": "6",
        "65+": "1",
        "medianAge": "6"
      }
    },
    "title": "Upholsterers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "30",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "21",
        "median": "-",
        "maleWorkers": "18",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Textile, apparel, and furnishings workers, all other": {
    "ageData": {
      "2019": {
        "total": "13",
        "16to19": "0",
        "20to24": "4",
        "25to34": "2",
        "35to44": "0",
        "45to54": "2",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Textile, apparel, and furnishings workers, all other",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "13",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "10",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Cabinetmakers and bench carpenters": {
    "ageData": {
      "2019": {
        "total": "65",
        "16to19": "1",
        "20to24": "3",
        "25to34": "12",
        "35to44": "12",
        "45to54": "17",
        "55to64": "14",
        "65+": "5",
        "medianAge": "14"
      }
    },
    "title": "Cabinetmakers and bench carpenters",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "65",
        "women": "8.0",
        "white": "88.2",
        "black": "7.7",
        "asian": "1.1",
        "hispanicOrLatino": "21.3"
      }
    },
    "wageData": {
      "2019": {
        "workers": "44",
        "median": "-",
        "maleWorkers": "41",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Furniture finishers": {
    "ageData": {
      "2019": {
        "total": "18",
        "16to19": "1",
        "20to24": "2",
        "25to34": "1",
        "35to44": "5",
        "45to54": "4",
        "55to64": "3",
        "65+": "2",
        "medianAge": "3"
      }
    },
    "title": "Furniture finishers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "18",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "14",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Model makers and patternmakers, wood": {
    "ageData": {
      "2019": {
        "total": "0",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Model makers and patternmakers, wood",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "0",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "0",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Sawing machine setters, operators, and tenders, wood": {
    "ageData": {
      "2019": {
        "total": "33",
        "16to19": "1",
        "20to24": "6",
        "25to34": "9",
        "35to44": "6",
        "45to54": "5",
        "55to64": "5",
        "65+": "2",
        "medianAge": "5"
      }
    },
    "title": "Sawing machine setters, operators, and tenders, wood",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "33",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "28",
        "median": "-",
        "maleWorkers": "24",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Woodworking machine setters, operators, and tenders, except sawing": {
    "ageData": {
      "2019": {
        "total": "12",
        "16to19": "0",
        "20to24": "2",
        "25to34": "2",
        "35to44": "3",
        "45to54": "3",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Woodworking machine setters, operators, and tenders, except sawing",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "12",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "14",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Woodworkers, all other": {
    "ageData": {
      "2019": {
        "total": "23",
        "16to19": "0",
        "20to24": "1",
        "25to34": "3",
        "35to44": "6",
        "45to54": "3",
        "55to64": "5",
        "65+": "4",
        "medianAge": "5"
      }
    },
    "title": "Woodworkers, all other",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "23",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "10",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Power plant operators, distributors, and dispatchers": {
    "ageData": {
      "2019": {
        "total": "43",
        "16to19": "0",
        "20to24": "1",
        "25to34": "8",
        "35to44": "11",
        "45to54": "11",
        "55to64": "9",
        "65+": "2",
        "medianAge": "9"
      }
    },
    "title": "Power plant operators, distributors, and dispatchers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "43",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "38",
        "median": "-",
        "maleWorkers": "36",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Stationary engineers and boiler operators": {
    "ageData": {
      "2019": {
        "total": "52",
        "16to19": "0",
        "20to24": "0",
        "25to34": "11",
        "35to44": "5",
        "45to54": "13",
        "55to64": "18",
        "65+": "6",
        "medianAge": "18"
      }
    },
    "title": "Stationary engineers and boiler operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "52",
        "women": "3.0",
        "white": "84.3",
        "black": "5.2",
        "asian": "5.5",
        "hispanicOrLatino": "9.8"
      }
    },
    "wageData": {
      "2019": {
        "workers": "51",
        "median": "869",
        "maleWorkers": "50",
        "maleMedian": "862",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Water and wastewater treatment plant and system operators": {
    "ageData": {
      "2019": {
        "total": "110",
        "16to19": "0",
        "20to24": "5",
        "25to34": "25",
        "35to44": "20",
        "45to54": "26",
        "55to64": "29",
        "65+": "5",
        "medianAge": "29"
      }
    },
    "title": "Water and wastewater treatment plant and system operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "110",
        "women": "8.0",
        "white": "84.6",
        "black": "8.4",
        "asian": "3.5",
        "hispanicOrLatino": "9.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "105",
        "median": "931",
        "maleWorkers": "97",
        "maleMedian": "930",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous plant and system operators": {
    "ageData": {
      "2019": {
        "total": "51",
        "16to19": "0",
        "20to24": "3",
        "25to34": "11",
        "35to44": "12",
        "45to54": "15",
        "55to64": "7",
        "65+": "2",
        "medianAge": "7"
      }
    },
    "title": "Miscellaneous plant and system operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "51",
        "women": "4.9",
        "white": "83.2",
        "black": "11.6",
        "asian": "3.9",
        "hispanicOrLatino": "14.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "46",
        "median": "-",
        "maleWorkers": "44",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Chemical processing machine setters, operators, and tenders": {
    "ageData": {
      "2019": {
        "total": "73",
        "16to19": "0",
        "20to24": "5",
        "25to34": "25",
        "35to44": "14",
        "45to54": "12",
        "55to64": "16",
        "65+": "2",
        "medianAge": "16"
      }
    },
    "title": "Chemical processing machine setters, operators, and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "73",
        "women": "11.1",
        "white": "86.8",
        "black": "11.0",
        "asian": "1.0",
        "hispanicOrLatino": "8.1"
      }
    },
    "wageData": {
      "2019": {
        "workers": "75",
        "median": "999",
        "maleWorkers": "67",
        "maleMedian": "1,017",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Crushing, grinding, polishing, mixing, and blending workers": {
    "ageData": {
      "2019": {
        "total": "90",
        "16to19": "1",
        "20to24": "11",
        "25to34": "26",
        "35to44": "13",
        "45to54": "14",
        "55to64": "18",
        "65+": "7",
        "medianAge": "18"
      }
    },
    "title": "Crushing, grinding, polishing, mixing, and blending workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "90",
        "women": "7.5",
        "white": "80.8",
        "black": "11.4",
        "asian": "1.9",
        "hispanicOrLatino": "26.1"
      }
    },
    "wageData": {
      "2019": {
        "workers": "79",
        "median": "764",
        "maleWorkers": "74",
        "maleMedian": "772",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Cutting workers": {
    "ageData": {
      "2019": {
        "total": "60",
        "16to19": "4",
        "20to24": "5",
        "25to34": "14",
        "35to44": "15",
        "45to54": "10",
        "55to64": "9",
        "65+": "4",
        "medianAge": "9"
      }
    },
    "title": "Cutting workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "60",
        "women": "12.7",
        "white": "78.3",
        "black": "18.8",
        "asian": "2.5",
        "hispanicOrLatino": "25.2"
      }
    },
    "wageData": {
      "2019": {
        "workers": "50",
        "median": "762",
        "maleWorkers": "42",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Extruding, forming, pressing, and compacting machine setters, operators, and tenders": {
    "ageData": {
      "2019": {
        "total": "27",
        "16to19": "1",
        "20to24": "4",
        "25to34": "7",
        "35to44": "2",
        "45to54": "9",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Extruding, forming, pressing, and compacting machine setters, operators, and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "27",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "29",
        "median": "-",
        "maleWorkers": "26",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Furnace, kiln, oven, drier, and kettle operators and tenders": {
    "ageData": {
      "2019": {
        "total": "17",
        "16to19": "0",
        "20to24": "2",
        "25to34": "1",
        "35to44": "3",
        "45to54": "4",
        "55to64": "7",
        "65+": "0",
        "medianAge": "7"
      }
    },
    "title": "Furnace, kiln, oven, drier, and kettle operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "17",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "17",
        "median": "-",
        "maleWorkers": "16",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Inspectors, testers, sorters, samplers, and weighers": {
    "ageData": {
      "2019": {
        "total": "802",
        "16to19": "13",
        "20to24": "69",
        "25to34": "171",
        "35to44": "168",
        "45to54": "171",
        "55to64": "165",
        "65+": "44",
        "medianAge": "165"
      }
    },
    "title": "Inspectors, testers, sorters, samplers, and weighers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "802",
        "women": "35.6",
        "white": "77.6",
        "black": "11.5",
        "asian": "5.4",
        "hispanicOrLatino": "17.5"
      }
    },
    "wageData": {
      "2019": {
        "workers": "722",
        "median": "835",
        "maleWorkers": "463",
        "maleMedian": "939",
        "femaleWorkers": "259",
        "femaleMedian": "693"
      }
    }
  },
  "Jewelers and precious stone and metal workers": {
    "ageData": {
      "2019": {
        "total": "40",
        "16to19": "1",
        "20to24": "2",
        "25to34": "2",
        "35to44": "11",
        "45to54": "7",
        "55to64": "11",
        "65+": "7",
        "medianAge": "11"
      }
    },
    "title": "Jewelers and precious stone and metal workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "40",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "24",
        "median": "-",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "Medical, dental, and ophthalmic laboratory technicians": {
    "ageData": {
      "2019": {
        "total": "91",
        "16to19": "2",
        "20to24": "8",
        "25to34": "22",
        "35to44": "18",
        "45to54": "18",
        "55to64": "16",
        "65+": "6",
        "medianAge": "16"
      }
    },
    "title": "Medical, dental, and ophthalmic laboratory technicians",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "91",
        "women": "60.0",
        "white": "81.8",
        "black": "4.6",
        "asian": "10.5",
        "hispanicOrLatino": "17.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "79",
        "median": "779",
        "maleWorkers": "35",
        "maleMedian": "-",
        "femaleWorkers": "44",
        "femaleMedian": "-"
      }
    }
  },
  "Packaging and filling machine operators and tenders": {
    "ageData": {
      "2019": {
        "total": "262",
        "16to19": "9",
        "20to24": "26",
        "25to34": "61",
        "35to44": "47",
        "45to54": "56",
        "55to64": "54",
        "65+": "8",
        "medianAge": "54"
      }
    },
    "title": "Packaging and filling machine operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "262",
        "women": "54.6",
        "white": "68.8",
        "black": "20.4",
        "asian": "6.3",
        "hispanicOrLatino": "37.3"
      }
    },
    "wageData": {
      "2019": {
        "workers": "244",
        "median": "575",
        "maleWorkers": "116",
        "maleMedian": "646",
        "femaleWorkers": "128",
        "femaleMedian": "533"
      }
    }
  },
  "Painting workers": {
    "ageData": {
      "2019": {
        "total": "171",
        "16to19": "3",
        "20to24": "15",
        "25to34": "52",
        "35to44": "37",
        "45to54": "28",
        "55to64": "30",
        "65+": "5",
        "medianAge": "30"
      }
    },
    "title": "Painting workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "171",
        "women": "10.6",
        "white": "81.5",
        "black": "13.0",
        "asian": "1.8",
        "hispanicOrLatino": "41.8"
      }
    },
    "wageData": {
      "2019": {
        "workers": "160",
        "median": "780",
        "maleWorkers": "144",
        "maleMedian": "804",
        "femaleWorkers": "15",
        "femaleMedian": "-"
      }
    }
  },
  "Photographic process workers and processing machine operators": {
    "ageData": {
      "2019": {
        "total": "13",
        "16to19": "0",
        "20to24": "2",
        "25to34": "3",
        "35to44": "2",
        "45to54": "3",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Photographic process workers and processing machine operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "13",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "9",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Semiconductor processors": {
    "ageData": {
      "2019": {
        "total": "0",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Semiconductor processors",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "0",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Adhesive bonding machine operators and tenders": {
    "ageData": {
      "2019": {
        "total": "10",
        "16to19": "1",
        "20to24": "1",
        "25to34": "1",
        "35to44": "2",
        "45to54": "2",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Adhesive bonding machine operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "10",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Cleaning, washing, and metal pickling equipment operators and tenders": {
    "ageData": {
      "2019": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Cleaning, washing, and metal pickling equipment operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Cooling and freezing equipment operators and tenders": {
    "ageData": {
      "2019": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Cooling and freezing equipment operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Etchers and engravers": {
    "ageData": {
      "2019": {
        "total": "8",
        "16to19": "0",
        "20to24": "2",
        "25to34": "0",
        "35to44": "0",
        "45to54": "1",
        "55to64": "3",
        "65+": "2",
        "medianAge": "3"
      }
    },
    "title": "Etchers and engravers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "8",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Molders, shapers, and casters, except metal and plastic": {
    "ageData": {
      "2019": {
        "total": "31",
        "16to19": "0",
        "20to24": "3",
        "25to34": "7",
        "35to44": "11",
        "45to54": "5",
        "55to64": "5",
        "65+": "1",
        "medianAge": "5"
      }
    },
    "title": "Molders, shapers, and casters, except metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "31",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "28",
        "median": "-",
        "maleWorkers": "22",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Paper goods machine setters, operators, and tenders": {
    "ageData": {
      "2019": {
        "total": "24",
        "16to19": "1",
        "20to24": "3",
        "25to34": "3",
        "35to44": "8",
        "45to54": "4",
        "55to64": "2",
        "65+": "3",
        "medianAge": "2"
      }
    },
    "title": "Paper goods machine setters, operators, and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "24",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "20",
        "median": "-",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Tire builders": {
    "ageData": {
      "2019": {
        "total": "8",
        "16to19": "0",
        "20to24": "1",
        "25to34": "3",
        "35to44": "2",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Tire builders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "8",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Helpers--production workers": {
    "ageData": {
      "2019": {
        "total": "54",
        "16to19": "5",
        "20to24": "11",
        "25to34": "12",
        "35to44": "9",
        "45to54": "8",
        "55to64": "7",
        "65+": "2",
        "medianAge": "7"
      }
    },
    "title": "Helpers--production workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "54",
        "women": "32.6",
        "white": "74.9",
        "black": "17.2",
        "asian": "4.0",
        "hispanicOrLatino": "30.3"
      }
    },
    "wageData": {
      "2019": {
        "workers": "29",
        "median": "-",
        "maleWorkers": "25",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Production workers, all other": {
    "ageData": {
      "2019": {
        "total": "1,141",
        "16to19": "24",
        "20to24": "123",
        "25to34": "266",
        "35to44": "233",
        "45to54": "236",
        "55to64": "208",
        "65+": "50",
        "medianAge": "208"
      }
    },
    "title": "Production workers, all other",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "1,141",
        "women": "28.6",
        "white": "73.8",
        "black": "16.1",
        "asian": "5.7",
        "hispanicOrLatino": "24.6"
      }
    },
    "wageData": {
      "2019": {
        "workers": "1,056",
        "median": "704",
        "maleWorkers": "763",
        "maleMedian": "767",
        "femaleWorkers": "293",
        "femaleMedian": "566"
      }
    }
  },
  "Transportation and material moving occupations": {
    "ageData": {
      "2019": {
        "total": "10,063",
        "16to19": "373",
        "20to24": "1,033",
        "25to34": "2,129",
        "35to44": "1,866",
        "45to54": "2,109",
        "55to64": "1,837",
        "65+": "716",
        "medianAge": "1,837"
      }
    },
    "title": "Transportation and material moving occupations",
    "depth": 2,
    "parent": "Production, transportation, and material moving occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "10,063",
        "women": "18.2",
        "white": "72.2",
        "black": "20.0",
        "asian": "4.1",
        "hispanicOrLatino": "22.9"
      }
    },
    "wageData": {
      "2019": {
        "workers": "7,612",
        "median": "711",
        "maleWorkers": "6,352",
        "maleMedian": "747",
        "femaleWorkers": "1,260",
        "femaleMedian": "586"
      }
    }
  },
  "Supervisors of transportation and material moving workers": {
    "ageData": {
      "2019": {
        "total": "206",
        "16to19": "0",
        "20to24": "12",
        "25to34": "59",
        "35to44": "45",
        "45to54": "52",
        "55to64": "33",
        "65+": "5",
        "medianAge": "33"
      }
    },
    "title": "Supervisors of transportation and material moving workers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "206",
        "women": "21.5",
        "white": "72.2",
        "black": "19.4",
        "asian": "6.7",
        "hispanicOrLatino": "20.3"
      }
    },
    "wageData": {
      "2019": {
        "workers": "184",
        "median": "923",
        "maleWorkers": "156",
        "maleMedian": "978",
        "femaleWorkers": "28",
        "femaleMedian": "-"
      }
    }
  },
  "Aircraft pilots and flight engineers": {
    "ageData": {
      "2019": {
        "total": "141",
        "16to19": "0",
        "20to24": "3",
        "25to34": "19",
        "35to44": "32",
        "45to54": "41",
        "55to64": "38",
        "65+": "10",
        "medianAge": "38"
      }
    },
    "title": "Aircraft pilots and flight engineers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "141",
        "women": "7.5",
        "white": "93.7",
        "black": "2.6",
        "asian": "3.4",
        "hispanicOrLatino": "2.2"
      }
    },
    "wageData": {
      "2019": {
        "workers": "118",
        "median": "1,857",
        "maleWorkers": "109",
        "maleMedian": "1,670",
        "femaleWorkers": "9",
        "femaleMedian": "-"
      }
    }
  },
  "Air traffic controllers and airfield operations specialists": {
    "ageData": {
      "2019": {
        "total": "43",
        "16to19": "0",
        "20to24": "0",
        "25to34": "14",
        "35to44": "11",
        "45to54": "12",
        "55to64": "6",
        "65+": "0",
        "medianAge": "6"
      }
    },
    "title": "Air traffic controllers and airfield operations specialists",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "43",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "40",
        "median": "-",
        "maleWorkers": "34",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Flight attendants": {
    "ageData": {
      "2019": {
        "total": "110",
        "16to19": "0",
        "20to24": "5",
        "25to34": "27",
        "35to44": "15",
        "45to54": "27",
        "55to64": "28",
        "65+": "9",
        "medianAge": "28"
      }
    },
    "title": "Flight attendants",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "110",
        "women": "81.3",
        "white": "73.7",
        "black": "17.5",
        "asian": "5.4",
        "hispanicOrLatino": "15.3"
      }
    },
    "wageData": {
      "2019": {
        "workers": "70",
        "median": "823",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "57",
        "femaleMedian": "835"
      }
    }
  },
  "Ambulance drivers and attendants, except emergency medical technicians": {
    "ageData": {
      "2019": {
        "total": "15",
        "16to19": "0",
        "20to24": "0",
        "25to34": "4",
        "35to44": "0",
        "45to54": "7",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Ambulance drivers and attendants, except emergency medical technicians",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "15",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Bus drivers": {
    "ageData": {
      "2019": {
        "total": "546",
        "16to19": "0",
        "20to24": "2",
        "25to34": "62",
        "35to44": "92",
        "45to54": "130",
        "55to64": "154",
        "65+": "104",
        "medianAge": "154"
      }
    },
    "title": "Bus drivers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "546",
        "women": "45.3",
        "white": "67.2",
        "black": "27.0",
        "asian": "3.1",
        "hispanicOrLatino": "15.0"
      }
    },
    "wageData": {
      "2019": {
        "workers": "364",
        "median": "674",
        "maleWorkers": "205",
        "maleMedian": "718",
        "femaleWorkers": "159",
        "femaleMedian": "625"
      }
    }
  },
  "Driver/sales workers and truck drivers": {
    "ageData": {
      "2019": {
        "total": "3,608",
        "16to19": "45",
        "20to24": "256",
        "25to34": "652",
        "35to44": "724",
        "45to54": "860",
        "55to64": "792",
        "65+": "279",
        "medianAge": "792"
      }
    },
    "title": "Driver/sales workers and truck drivers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "3,608",
        "women": "6.7",
        "white": "75.2",
        "black": "18.1",
        "asian": "2.9",
        "hispanicOrLatino": "20.5"
      }
    },
    "wageData": {
      "2019": {
        "workers": "2,723",
        "median": "843",
        "maleWorkers": "2,564",
        "maleMedian": "861",
        "femaleWorkers": "159",
        "femaleMedian": "660"
      }
    }
  },
  "Taxi drivers and chauffeurs": {
    "ageData": {
      "2019": {
        "total": "790",
        "16to19": "5",
        "20to24": "50",
        "25to34": "167",
        "35to44": "149",
        "45to54": "158",
        "55to64": "159",
        "65+": "102",
        "medianAge": "159"
      }
    },
    "title": "Taxi drivers and chauffeurs",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "790",
        "women": "16.8",
        "white": "55.5",
        "black": "29.5",
        "asian": "13.1",
        "hispanicOrLatino": "23.6"
      }
    },
    "wageData": {
      "2019": {
        "workers": "406",
        "median": "614",
        "maleWorkers": "356",
        "maleMedian": "624",
        "femaleWorkers": "50",
        "femaleMedian": "520"
      }
    }
  },
  "Motor vehicle operators, all other": {
    "ageData": {
      "2019": {
        "total": "66",
        "16to19": "1",
        "20to24": "6",
        "25to34": "8",
        "35to44": "6",
        "45to54": "8",
        "55to64": "12",
        "65+": "26",
        "medianAge": "12"
      }
    },
    "title": "Motor vehicle operators, all other",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "66",
        "women": "17.2",
        "white": "83.6",
        "black": "10.6",
        "asian": "5.5",
        "hispanicOrLatino": "18.6"
      }
    },
    "wageData": {
      "2019": {
        "workers": "34",
        "median": "-",
        "maleWorkers": "29",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Locomotive engineers and operators": {
    "ageData": {
      "2019": {
        "total": "42",
        "16to19": "0",
        "20to24": "0",
        "25to34": "7",
        "35to44": "14",
        "45to54": "17",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Locomotive engineers and operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "42",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "39",
        "median": "-",
        "maleWorkers": "37",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Railroad brake, signal, and switch operators": {
    "ageData": {
      "2019": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "1",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Railroad brake, signal, and switch operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Railroad conductors and yardmasters": {
    "ageData": {
      "2019": {
        "total": "52",
        "16to19": "2",
        "20to24": "1",
        "25to34": "12",
        "35to44": "16",
        "45to54": "16",
        "55to64": "2",
        "65+": "2",
        "medianAge": "2"
      }
    },
    "title": "Railroad conductors and yardmasters",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "52",
        "women": "2.4",
        "white": "66.7",
        "black": "24.0",
        "asian": "3.1",
        "hispanicOrLatino": "5.4"
      }
    },
    "wageData": {
      "2019": {
        "workers": "59",
        "median": "1,160",
        "maleWorkers": "57",
        "maleMedian": "1,170",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Subway, streetcar, and other rail transportation workers": {
    "ageData": {
      "2019": {
        "total": "9",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "1",
        "45to54": "1",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Subway, streetcar, and other rail transportation workers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "9",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "10",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Sailors and marine oilers": {
    "ageData": {
      "2019": {
        "total": "19",
        "16to19": "2",
        "20to24": "4",
        "25to34": "7",
        "35to44": "2",
        "45to54": "2",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Sailors and marine oilers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "19",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "20",
        "median": "-",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Ship and boat captains and operators": {
    "ageData": {
      "2019": {
        "total": "45",
        "16to19": "0",
        "20to24": "3",
        "25to34": "9",
        "35to44": "12",
        "45to54": "14",
        "55to64": "5",
        "65+": "2",
        "medianAge": "5"
      }
    },
    "title": "Ship and boat captains and operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "45",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "33",
        "median": "-",
        "maleWorkers": "29",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Ship engineers": {
    "ageData": {
      "2019": {
        "total": "5",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "0",
        "45to54": "3",
        "55to64": "0",
        "65+": "1",
        "medianAge": "0"
      }
    },
    "title": "Ship engineers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Bridge and lock tenders": {
    "ageData": {
      "2019": {
        "total": "0",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Bridge and lock tenders",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "0",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "0",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Parking lot attendants": {
    "ageData": {
      "2019": {
        "total": "83",
        "16to19": "6",
        "20to24": "28",
        "25to34": "20",
        "35to44": "6",
        "45to54": "9",
        "55to64": "7",
        "65+": "8",
        "medianAge": "7"
      }
    },
    "title": "Parking lot attendants",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "83",
        "women": "14.8",
        "white": "72.6",
        "black": "19.3",
        "asian": "5.7",
        "hispanicOrLatino": "31.0"
      }
    },
    "wageData": {
      "2019": {
        "workers": "58",
        "median": "561",
        "maleWorkers": "50",
        "maleMedian": "581",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Automotive and watercraft service attendants": {
    "ageData": {
      "2019": {
        "total": "88",
        "16to19": "15",
        "20to24": "15",
        "25to34": "26",
        "35to44": "11",
        "45to54": "9",
        "55to64": "9",
        "65+": "3",
        "medianAge": "9"
      }
    },
    "title": "Automotive and watercraft service attendants",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "88",
        "women": "18.4",
        "white": "68.9",
        "black": "12.1",
        "asian": "13.7",
        "hispanicOrLatino": "14.5"
      }
    },
    "wageData": {
      "2019": {
        "workers": "67",
        "median": "518",
        "maleWorkers": "59",
        "maleMedian": "520",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Transportation inspectors": {
    "ageData": {
      "2019": {
        "total": "42",
        "16to19": "1",
        "20to24": "2",
        "25to34": "11",
        "35to44": "7",
        "45to54": "10",
        "55to64": "8",
        "65+": "2",
        "medianAge": "8"
      }
    },
    "title": "Transportation inspectors",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "42",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "42",
        "median": "-",
        "maleWorkers": "37",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Transportation attendants, except flight attendants": {
    "ageData": {
      "2019": {
        "total": "43",
        "16to19": "1",
        "20to24": "3",
        "25to34": "7",
        "35to44": "5",
        "45to54": "11",
        "55to64": "11",
        "65+": "6",
        "medianAge": "11"
      }
    },
    "title": "Transportation attendants, except flight attendants",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "43",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "22",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Other transportation workers": {
    "ageData": {
      "2019": {
        "total": "38",
        "16to19": "0",
        "20to24": "2",
        "25to34": "12",
        "35to44": "7",
        "45to54": "10",
        "55to64": "4",
        "65+": "3",
        "medianAge": "4"
      }
    },
    "title": "Other transportation workers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "38",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "31",
        "median": "-",
        "maleWorkers": "27",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Conveyor operators and tenders": {
    "ageData": {
      "2019": {
        "total": "2",
        "16to19": "0",
        "20to24": "1",
        "25to34": "0",
        "35to44": "1",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Conveyor operators and tenders",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Crane and tower operators": {
    "ageData": {
      "2019": {
        "total": "63",
        "16to19": "1",
        "20to24": "6",
        "25to34": "12",
        "35to44": "13",
        "45to54": "15",
        "55to64": "14",
        "65+": "3",
        "medianAge": "14"
      }
    },
    "title": "Crane and tower operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "63",
        "women": "5.2",
        "white": "90.1",
        "black": "6.3",
        "asian": "0.0",
        "hispanicOrLatino": "10.6"
      }
    },
    "wageData": {
      "2019": {
        "workers": "63",
        "median": "1,070",
        "maleWorkers": "59",
        "maleMedian": "1,104",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Dredge, excavating, and loading machine operators": {
    "ageData": {
      "2019": {
        "total": "28",
        "16to19": "0",
        "20to24": "2",
        "25to34": "7",
        "35to44": "6",
        "45to54": "3",
        "55to64": "5",
        "65+": "4",
        "medianAge": "5"
      }
    },
    "title": "Dredge, excavating, and loading machine operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "28",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "19",
        "median": "-",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Hoist and winch operators": {
    "ageData": {
      "2019": {
        "total": "9",
        "16to19": "1",
        "20to24": "2",
        "25to34": "3",
        "35to44": "1",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Hoist and winch operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "9",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "9",
        "median": "-",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Industrial truck and tractor operators": {
    "ageData": {
      "2019": {
        "total": "571",
        "16to19": "12",
        "20to24": "76",
        "25to34": "132",
        "35to44": "128",
        "45to54": "121",
        "55to64": "84",
        "65+": "18",
        "medianAge": "84"
      }
    },
    "title": "Industrial truck and tractor operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "571",
        "women": "8.7",
        "white": "69.0",
        "black": "25.8",
        "asian": "1.7",
        "hispanicOrLatino": "31.4"
      }
    },
    "wageData": {
      "2019": {
        "workers": "541",
        "median": "677",
        "maleWorkers": "489",
        "maleMedian": "686",
        "femaleWorkers": "52",
        "femaleMedian": "625"
      }
    }
  },
  "Cleaners of vehicles and equipment": {
    "ageData": {
      "2019": {
        "total": "344",
        "16to19": "43",
        "20to24": "58",
        "25to34": "84",
        "35to44": "52",
        "45to54": "54",
        "55to64": "35",
        "65+": "17",
        "medianAge": "35"
      }
    },
    "title": "Cleaners of vehicles and equipment",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "344",
        "women": "16.0",
        "white": "76.1",
        "black": "19.4",
        "asian": "1.4",
        "hispanicOrLatino": "34.8"
      }
    },
    "wageData": {
      "2019": {
        "workers": "254",
        "median": "587",
        "maleWorkers": "225",
        "maleMedian": "595",
        "femaleWorkers": "29",
        "femaleMedian": "-"
      }
    }
  },
  "Laborers and freight, stock, and material movers, hand": {
    "ageData": {
      "2019": {
        "total": "2,235",
        "16to19": "180",
        "20to24": "377",
        "25to34": "569",
        "35to44": "376",
        "45to54": "359",
        "55to64": "305",
        "65+": "70",
        "medianAge": "305"
      }
    },
    "title": "Laborers and freight, stock, and material movers, hand",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "2,235",
        "women": "21.5",
        "white": "72.4",
        "black": "19.8",
        "asian": "2.7",
        "hispanicOrLatino": "23.1"
      }
    },
    "wageData": {
      "2019": {
        "workers": "1,764",
        "median": "634",
        "maleWorkers": "1,402",
        "maleMedian": "657",
        "femaleWorkers": "362",
        "femaleMedian": "567"
      }
    }
  },
  "Machine feeders and offbearers": {
    "ageData": {
      "2019": {
        "total": "24",
        "16to19": "2",
        "20to24": "4",
        "25to34": "4",
        "35to44": "4",
        "45to54": "3",
        "55to64": "7",
        "65+": "0",
        "medianAge": "7"
      }
    },
    "title": "Machine feeders and offbearers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "24",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "21",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "Packers and packagers, hand": {
    "ageData": {
      "2019": {
        "total": "628",
        "16to19": "52",
        "20to24": "90",
        "25to34": "143",
        "35to44": "105",
        "45to54": "121",
        "55to64": "83",
        "65+": "34",
        "medianAge": "83"
      }
    },
    "title": "Packers and packagers, hand",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "628",
        "women": "54.8",
        "white": "69.2",
        "black": "19.0",
        "asian": "7.7",
        "hispanicOrLatino": "41.5"
      }
    },
    "wageData": {
      "2019": {
        "workers": "467",
        "median": "530",
        "maleWorkers": "202",
        "maleMedian": "576",
        "femaleWorkers": "265",
        "femaleMedian": "515"
      }
    }
  },
  "Pumping station operators": {
    "ageData": {
      "2019": {
        "total": "16",
        "16to19": "0",
        "20to24": "2",
        "25to34": "3",
        "35to44": "4",
        "45to54": "5",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Pumping station operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "16",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "11",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Refuse and recyclable material collectors": {
    "ageData": {
      "2019": {
        "total": "99",
        "16to19": "4",
        "20to24": "14",
        "25to34": "29",
        "35to44": "14",
        "45to54": "21",
        "55to64": "14",
        "65+": "5",
        "medianAge": "14"
      }
    },
    "title": "Refuse and recyclable material collectors",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "99",
        "women": "7.6",
        "white": "74.0",
        "black": "18.2",
        "asian": "3.6",
        "hispanicOrLatino": "29.7"
      }
    },
    "wageData": {
      "2019": {
        "workers": "78",
        "median": "686",
        "maleWorkers": "72",
        "maleMedian": "683",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Mine shuttle car operators": {
    "ageData": {
      "2019": {
        "total": "2",
        "16to19": "1",
        "20to24": "0",
        "25to34": "0",
        "35to44": "1",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Mine shuttle car operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Tank car, truck, and ship loaders": {
    "ageData": {
      "2019": {
        "total": "6",
        "16to19": "0",
        "20to24": "2",
        "25to34": "1",
        "35to44": "1",
        "45to54": "0",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Tank car, truck, and ship loaders",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "6",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Material moving workers, all other": {
    "ageData": {
      "2019": {
        "total": "41",
        "16to19": "0",
        "20to24": "8",
        "25to34": "11",
        "35to44": "6",
        "45to54": "8",
        "55to64": "6",
        "65+": "2",
        "medianAge": "6"
      }
    },
    "title": "Material moving workers, all other",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2019": {
        "total": "41",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-",
        "hispanicOrLatino": "-"
      }
    },
    "wageData": {
      "2019": {
        "workers": "39",
        "median": "-",
        "maleWorkers": "35",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  }
}