import React from 'react'
import ReactDOM from 'react-dom'

import { BrowserRouter, Navigate, Route, Routes, useLocation, useParams, useResolvedPath } from 'react-router-dom'

import { WageEquality } from './pages/WageEquality/WageEquality'
import { Home } from './pages/Home/Home'
import { TabletopTools } from './pages/TabletopTools/TabletopTools'
import { DiceRoller } from './pages/TabletopTools/DiceRoller'
import { NameGenerator } from './pages/TabletopTools/NameGenerator'
import { TimelinePage } from './pages/WriterTools/Timeline'
import { ConfigProvider, theme } from 'antd'
import { WriterTools } from './pages/WriterTools/WriterTools'

function redirect ({ from = '', to }) {
  let fromList = (typeof from === 'string') ? [from] : from

  let routes = fromList.map(from => {
    let fromWithStar
    if (from.endsWith('/*')) fromWithStar = from
    else if (from.endsWith('/')) fromWithStar = from + '*'
    else fromWithStar = from + '/*'

    let fromWithoutStar = fromWithStar.replace(/\/\*$/, '')

    let fromProp = {}

    if (from === '') {
      fromProp.index = true
    } else {
      fromProp.path = fromWithStar
    }

    return <Route key={from} {...fromProp} element={<Redirect from={fromWithoutStar} to={to} />} />
  })

  return (
    <>
      {routes}
    </>
  )
}

const Redirect = ({ from, to }) => {
  const { pathname } = useResolvedPath('')
  const { search, hash } = useLocation()
  const parentRoute = pathname.replace(new RegExp(from + '(/|$)'), '')

  const theRestOfThePath = useParams()?.['*'] ?? ''

  let finalTo = to.endsWith('/') ? to : [parentRoute, to, theRestOfThePath].join('/').replace(/\/+/g, '/') + search + hash

  return <Navigate to={finalTo} replace />
}

const App = () => {
  return (
    <ConfigProvider theme={{
      algorithm: theme.darkAlgorithm
    }}
    >
      <BrowserRouter>
        <Routes>
          <Route path='/'>
            <Route index element={<Home />} />

            {redirect({ from: 'we', to: 'wage-equality' })}
            <Route path='we/*' element={<Redirect />} />
            <Route path='wage-equality' element={<WageEquality />} />

            {redirect({ from: 'tt', to: 'tabletop-tools' })}
            <Route path='tabletop-tools' element={<TabletopTools />}>
              {redirect({ from: ['', 'dr'], to: 'dice-roller' })}
              <Route path='dice-roller' element={<DiceRoller />} />
              {redirect({ from: ['ng'], to: 'name-generator' })}
              <Route path='name-generator' element={<NameGenerator />} />
            </Route>

            {redirect({ from: ['wt', 'at', 'author-tools'], to: 'writer-tools' })}
            <Route path='writer-tools' element={<WriterTools />}>
              {redirect({ from: ['', 'tl'], to: 'timeline' })}
              <Route path='timeline' element={<TimelinePage />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  )
}

ReactDOM.render(
  <App />,
  document.querySelector('#app')
)
