import React from 'react'
import { css, cx } from '@emotion/css'

export const PageHeader = ({ expand = false }) => {
  const nathaniel = css`
    color: rgb(90,155,230);
  `
  const hutchins = css`
    color: rgb(110,192,56);
    white-space: nowrap;
  `

  return (
    <header className={css`
      display: block;
      position: relative;
      top: 0px; 
      left: 0px; 
      right: 0px; 
      min-width: 600px; 
      border-bottom: 2px solid orange;
      box-shadow: 0px 1px 4px rgba(0,0,0,.25);
    `}
    >
      <h1
        id='title'
        className={css`
            display: block;

            top: 0px; 
            left: 0px; 
            right: 0px;

            margin: auto;
            padding: 15px 20px 20px 20px;

            ${expand ? ('') : (css`
              max-width: 940px;
              min-width: 560px; /* Not sure I need this bit */
            `)}

            font-family: Source Code Pro Woff, Monaco, monospace;
            font-size: 31.5pt; /* 42px */
            text-shadow: 1px 1px 3px rgba(0,0,0,.3);
            line-height: 1.1;
            color: white;
          `}
      >
        <a href='/'>
          &lt; <span className={nathaniel}>nathaniel</span><br />
          <span className={hutchins}>&nbsp;&nbsp;hutchins</span> /&gt;
        </a>
      </h1>
    </header>
  )
}

export const PageContent = ({ expand = false, children, ...htmlProps }) => {
  const containerCss = css`
    position: relative;
  
    left: 0px;
    right: 0px;
  
    margin: auto;
  
    margin-top: 15px;
    margin-bottom: 40px;
  
    padding-left: 20px;
    padding-right: 20px;
  
    ${expand ? css`
      padding: 20px 73px;
    ` : css`
      padding: 20px;
      max-width: 910px;
      min-width: 530px;
    `}

    font-size: 12pt; /* 16px */
    line-height: 24px; /* Required for the numbers on the side. */
  
    color: rgb(190, 190, 190);
  `

  return (
    <div {...htmlProps} className={cx(containerCss, htmlProps.className || '')}>
      {children}
    </div>
  )
}

export const Page = ({ expand = false, children, className }) => {
  return (
    <>
      <PageHeader expand={expand} />
      <PageContent expand={expand} className={className}>{children}</PageContent>
    </>
  )
}
