{
  "Total": {
    "ageData": {
      "2013": {
        "total": "143,929",
        "16to19": "4,458",
        "20to24": "13,599",
        "25to34": "31,242",
        "35to44": "30,650",
        "45to54": "32,523",
        "55to64": "23,776",
        "65+": "7,681",
        "medianAge": "23,776"
      }
    },
    "title": "Total",
    "depth": 1,
    "parent": "",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "143,929",
        "women": "47.0",
        "white": "11.2",
        "black": "5.7",
        "asian": "15.6"
      }
    },
    "wageData": {
      "2013": {
        "workers": "104,262",
        "median": "$776",
        "maleWorkers": "57,994",
        "maleMedian": "$860",
        "femaleWorkers": "46,268",
        "femaleMedian": "$706"
      }
    }
  },
  "Management, professional, and related occupations": {
    "ageData": {
      "2013": {
        "total": "54,712",
        "16to19": "326",
        "20to24": "2,777",
        "25to34": "12,084",
        "35to44": "12,993",
        "45to54": "13,206",
        "55to64": "10,055",
        "65+": "3,271",
        "medianAge": "10,055"
      }
    },
    "title": "Management, professional, and related occupations",
    "depth": 1,
    "parent": "",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "54,712",
        "women": "51.4",
        "white": "8.5",
        "black": "7.4",
        "asian": "8.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "41,820",
        "median": "1,132",
        "maleWorkers": "20,290",
        "maleMedian": "1,349",
        "femaleWorkers": "21,530",
        "femaleMedian": "973"
      }
    }
  },
  "Management, business, and financial operations occupations": {
    "ageData": {
      "2013": {
        "total": "22,794",
        "16to19": "71",
        "20to24": "840",
        "25to34": "4,321",
        "35to44": "5,342",
        "45to54": "6,117",
        "55to64": "4,544",
        "65+": "1,558",
        "medianAge": "4,544"
      }
    },
    "title": "Management, business, and financial operations occupations",
    "depth": 2,
    "parent": "Management, professional, and related occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "22,794",
        "women": "43.4",
        "white": "7.4",
        "black": "6.1",
        "asian": "8.4"
      }
    },
    "wageData": {
      "2013": {
        "workers": "17,137",
        "median": "1,208",
        "maleWorkers": "9,200",
        "maleMedian": "1,412",
        "femaleWorkers": "7,937",
        "femaleMedian": "1,049"
      }
    }
  },
  "Management occupations": {
    "ageData": {
      "2013": {
        "total": "16,037",
        "16to19": "51",
        "20to24": "463",
        "25to34": "2,702",
        "35to44": "3,762",
        "45to54": "4,501",
        "55to64": "3,378",
        "65+": "1,180",
        "medianAge": "3,378"
      }
    },
    "title": "Management occupations",
    "depth": 3,
    "parent": "Management, business, and financial operations occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "16,037",
        "women": "38.2",
        "white": "6.5",
        "black": "5.4",
        "asian": "8.5"
      }
    },
    "wageData": {
      "2013": {
        "workers": "11,501",
        "median": "1,285",
        "maleWorkers": "6,764",
        "maleMedian": "1,456",
        "femaleWorkers": "4,737",
        "femaleMedian": "1,103"
      }
    }
  },
  "Chief executives": {
    "ageData": {
      "2013": {
        "total": "1,520",
        "16to19": "0",
        "20to24": "9",
        "25to34": "107",
        "35to44": "312",
        "45to54": "505",
        "55to64": "420",
        "65+": "167",
        "medianAge": "420"
      }
    },
    "title": "Chief executives",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "1,520",
        "women": "26.8",
        "white": "2.9",
        "black": "4.8",
        "asian": "4.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "1,051",
        "median": "2,069",
        "maleWorkers": "764",
        "maleMedian": "2,266",
        "femaleWorkers": "286",
        "femaleMedian": "1,811"
      }
    }
  },
  "General and operations managers": {
    "ageData": {
      "2013": {
        "total": "1,075",
        "16to19": "0",
        "20to24": "22",
        "25to34": "202",
        "35to44": "283",
        "45to54": "337",
        "55to64": "187",
        "65+": "44",
        "medianAge": "187"
      }
    },
    "title": "General and operations managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "1,075",
        "women": "28.1",
        "white": "5.1",
        "black": "5.0",
        "asian": "8.7"
      }
    },
    "wageData": {
      "2013": {
        "workers": "947",
        "median": "1,370",
        "maleWorkers": "698",
        "maleMedian": "1,444",
        "femaleWorkers": "250",
        "femaleMedian": "1,161"
      }
    }
  },
  "Legislators": {
    "ageData": {
      "2013": {
        "total": "13",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "1",
        "45to54": "5",
        "55to64": "2",
        "65+": "3",
        "medianAge": "2"
      }
    },
    "title": "Legislators",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "13",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "10",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Advertising and promotions managers": {
    "ageData": {
      "2013": {
        "total": "51",
        "16to19": "0",
        "20to24": "4",
        "25to34": "17",
        "35to44": "11",
        "45to54": "8",
        "55to64": "9",
        "65+": "1",
        "medianAge": "9"
      }
    },
    "title": "Advertising and promotions managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "51",
        "women": "67.8",
        "white": "0.1",
        "black": "3.5",
        "asian": "16.1"
      }
    },
    "wageData": {
      "2013": {
        "workers": "53",
        "median": "1,526",
        "maleWorkers": "18",
        "maleMedian": "-",
        "femaleWorkers": "35",
        "femaleMedian": "-"
      }
    }
  },
  "Marketing and sales managers": {
    "ageData": {
      "2013": {
        "total": "907",
        "16to19": "5",
        "20to24": "30",
        "25to34": "225",
        "35to44": "249",
        "45to54": "241",
        "55to64": "133",
        "65+": "24",
        "medianAge": "133"
      }
    },
    "title": "Marketing and sales managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "907",
        "women": "43.1",
        "white": "5.0",
        "black": "4.7",
        "asian": "6.6"
      }
    },
    "wageData": {
      "2013": {
        "workers": "829",
        "median": "1,389",
        "maleWorkers": "476",
        "maleMedian": "1,658",
        "femaleWorkers": "353",
        "femaleMedian": "1,124"
      }
    }
  },
  "Public relations and fundraising managers": {
    "ageData": {
      "2013": {
        "total": "55",
        "16to19": "0",
        "20to24": "2",
        "25to34": "23",
        "35to44": "9",
        "45to54": "13",
        "55to64": "6",
        "65+": "1",
        "medianAge": "6"
      }
    },
    "title": "Public relations and fundraising managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "55",
        "women": "61.1",
        "white": "3.6",
        "black": "6.5",
        "asian": "13.8"
      }
    },
    "wageData": {
      "2013": {
        "workers": "51",
        "median": "1,159",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "29",
        "femaleMedian": "-"
      }
    }
  },
  "Administrative services managers": {
    "ageData": {
      "2013": {
        "total": "113",
        "16to19": "0",
        "20to24": "3",
        "25to34": "10",
        "35to44": "29",
        "45to54": "32",
        "55to64": "31",
        "65+": "8",
        "medianAge": "31"
      }
    },
    "title": "Administrative services managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "113",
        "women": "36.8",
        "white": "9.4",
        "black": "2.6",
        "asian": "8.2"
      }
    },
    "wageData": {
      "2013": {
        "workers": "109",
        "median": "1,198",
        "maleWorkers": "71",
        "maleMedian": "1,232",
        "femaleWorkers": "38",
        "femaleMedian": "-"
      }
    }
  },
  "Computer and information systems managers": {
    "ageData": {
      "2013": {
        "total": "602",
        "16to19": "0",
        "20to24": "12",
        "25to34": "108",
        "35to44": "199",
        "45to54": "182",
        "55to64": "90",
        "65+": "12",
        "medianAge": "90"
      }
    },
    "title": "Computer and information systems managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "602",
        "women": "28.6",
        "white": "5.0",
        "black": "14.1",
        "asian": "4.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "570",
        "median": "1,728",
        "maleWorkers": "407",
        "maleMedian": "1,769",
        "femaleWorkers": "163",
        "femaleMedian": "1,549"
      }
    }
  },
  "Financial managers": {
    "ageData": {
      "2013": {
        "total": "1,218",
        "16to19": "4",
        "20to24": "41",
        "25to34": "299",
        "35to44": "296",
        "45to54": "328",
        "55to64": "208",
        "65+": "41",
        "medianAge": "208"
      }
    },
    "title": "Financial managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "1,218",
        "women": "55.3",
        "white": "8.8",
        "black": "6.8",
        "asian": "10.5"
      }
    },
    "wageData": {
      "2013": {
        "workers": "1,127",
        "median": "1,236",
        "maleWorkers": "514",
        "maleMedian": "1,518",
        "femaleWorkers": "613",
        "femaleMedian": "1,064"
      }
    }
  },
  "Compensation and benefits managers": {
    "ageData": {
      "2013": {
        "total": "12",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "3",
        "45to54": "6",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Compensation and benefits managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "12",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "11",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Human resources managers": {
    "ageData": {
      "2013": {
        "total": "219",
        "16to19": "0",
        "20to24": "6",
        "25to34": "42",
        "35to44": "66",
        "45to54": "61",
        "55to64": "37",
        "65+": "7",
        "medianAge": "37"
      }
    },
    "title": "Human resources managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "219",
        "women": "71.9",
        "white": "12.0",
        "black": "4.2",
        "asian": "11.6"
      }
    },
    "wageData": {
      "2013": {
        "workers": "211",
        "median": "1,327",
        "maleWorkers": "60",
        "maleMedian": "1,536",
        "femaleWorkers": "152",
        "femaleMedian": "1,240"
      }
    }
  },
  "Training and development managers": {
    "ageData": {
      "2013": {
        "total": "34",
        "16to19": "0",
        "20to24": "4",
        "25to34": "6",
        "35to44": "10",
        "45to54": "8",
        "55to64": "5",
        "65+": "1",
        "medianAge": "5"
      }
    },
    "title": "Training and development managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "34",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "33",
        "median": "-",
        "maleWorkers": "16",
        "maleMedian": "-",
        "femaleWorkers": "17",
        "femaleMedian": "-"
      }
    }
  },
  "Industrial production managers": {
    "ageData": {
      "2013": {
        "total": "256",
        "16to19": "0",
        "20to24": "5",
        "25to34": "37",
        "35to44": "70",
        "45to54": "82",
        "55to64": "56",
        "65+": "7",
        "medianAge": "56"
      }
    },
    "title": "Industrial production managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "256",
        "women": "16.8",
        "white": "4.4",
        "black": "3.0",
        "asian": "10.4"
      }
    },
    "wageData": {
      "2013": {
        "workers": "256",
        "median": "1,338",
        "maleWorkers": "215",
        "maleMedian": "1,352",
        "femaleWorkers": "41",
        "femaleMedian": "-"
      }
    }
  },
  "Purchasing managers": {
    "ageData": {
      "2013": {
        "total": "184",
        "16to19": "0",
        "20to24": "3",
        "25to34": "33",
        "35to44": "47",
        "45to54": "50",
        "55to64": "42",
        "65+": "9",
        "medianAge": "42"
      }
    },
    "title": "Purchasing managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "184",
        "women": "47.8",
        "white": "10.9",
        "black": "7.0",
        "asian": "6.5"
      }
    },
    "wageData": {
      "2013": {
        "workers": "181",
        "median": "1,358",
        "maleWorkers": "92",
        "maleMedian": "1,441",
        "femaleWorkers": "89",
        "femaleMedian": "1,290"
      }
    }
  },
  "Transportation, storage, and distribution managers": {
    "ageData": {
      "2013": {
        "total": "264",
        "16to19": "1",
        "20to24": "8",
        "25to34": "53",
        "35to44": "62",
        "45to54": "88",
        "55to64": "46",
        "65+": "7",
        "medianAge": "46"
      }
    },
    "title": "Transportation, storage, and distribution managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "264",
        "women": "22.5",
        "white": "9.6",
        "black": "4.7",
        "asian": "13.7"
      }
    },
    "wageData": {
      "2013": {
        "workers": "252",
        "median": "1,005",
        "maleWorkers": "194",
        "maleMedian": "1,029",
        "femaleWorkers": "58",
        "femaleMedian": "920"
      }
    }
  },
  "Farmers, ranchers, and other agricultural managers": {
    "ageData": {
      "2013": {
        "total": "929",
        "16to19": "8",
        "20to24": "27",
        "25to34": "95",
        "35to44": "111",
        "45to54": "177",
        "55to64": "242",
        "65+": "270",
        "medianAge": "242"
      }
    },
    "title": "Farmers, ranchers, and other agricultural managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "929",
        "women": "25.3",
        "white": "0.7",
        "black": "1.5",
        "asian": "3.4"
      }
    },
    "wageData": {
      "2013": {
        "workers": "97",
        "median": "731",
        "maleWorkers": "78",
        "maleMedian": "716",
        "femaleWorkers": "19",
        "femaleMedian": "-"
      }
    }
  },
  "Construction managers": {
    "ageData": {
      "2013": {
        "total": "821",
        "16to19": "1",
        "20to24": "20",
        "25to34": "151",
        "35to44": "191",
        "45to54": "224",
        "55to64": "184",
        "65+": "50",
        "medianAge": "184"
      }
    },
    "title": "Construction managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "821",
        "women": "7.3",
        "white": "3.1",
        "black": "2.5",
        "asian": "10.1"
      }
    },
    "wageData": {
      "2013": {
        "workers": "414",
        "median": "1,275",
        "maleWorkers": "376",
        "maleMedian": "1,328",
        "femaleWorkers": "38",
        "femaleMedian": "-"
      }
    }
  },
  "Education administrators": {
    "ageData": {
      "2013": {
        "total": "804",
        "16to19": "0",
        "20to24": "17",
        "25to34": "112",
        "35to44": "197",
        "45to54": "233",
        "55to64": "200",
        "65+": "45",
        "medianAge": "200"
      }
    },
    "title": "Education administrators",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "804",
        "women": "63.8",
        "white": "13.4",
        "black": "2.6",
        "asian": "6.8"
      }
    },
    "wageData": {
      "2013": {
        "workers": "698",
        "median": "1,259",
        "maleWorkers": "248",
        "maleMedian": "1,543",
        "femaleWorkers": "450",
        "femaleMedian": "1,130"
      }
    }
  },
  "Architectural and engineering managers": {
    "ageData": {
      "2013": {
        "total": "121",
        "16to19": "0",
        "20to24": "0",
        "25to34": "12",
        "35to44": "32",
        "45to54": "48",
        "55to64": "26",
        "65+": "3",
        "medianAge": "26"
      }
    },
    "title": "Architectural and engineering managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "121",
        "women": "10.7",
        "white": "3.2",
        "black": "6.2",
        "asian": "5.5"
      }
    },
    "wageData": {
      "2013": {
        "workers": "117",
        "median": "1,894",
        "maleWorkers": "104",
        "maleMedian": "1,898",
        "femaleWorkers": "13",
        "femaleMedian": "-"
      }
    }
  },
  "Food service managers": {
    "ageData": {
      "2013": {
        "total": "1,077",
        "16to19": "22",
        "20to24": "127",
        "25to34": "269",
        "35to44": "249",
        "45to54": "224",
        "55to64": "146",
        "65+": "40",
        "medianAge": "146"
      }
    },
    "title": "Food service managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "1,077",
        "women": "45.3",
        "white": "8.6",
        "black": "11.5",
        "asian": "14.6"
      }
    },
    "wageData": {
      "2013": {
        "workers": "716",
        "median": "701",
        "maleWorkers": "381",
        "maleMedian": "801",
        "femaleWorkers": "335",
        "femaleMedian": "620"
      }
    }
  },
  "Funeral service managers": {
    "ageData": {
      "2013": {
        "total": "12",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "0",
        "45to54": "4",
        "55to64": "3",
        "65+": "3",
        "medianAge": "3"
      }
    },
    "title": "Funeral service managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "12",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Gaming managers": {
    "ageData": {
      "2013": {
        "total": "17",
        "16to19": "0",
        "20to24": "2",
        "25to34": "3",
        "35to44": "6",
        "45to54": "3",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Gaming managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "17",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "16",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Lodging managers": {
    "ageData": {
      "2013": {
        "total": "143",
        "16to19": "0",
        "20to24": "4",
        "25to34": "26",
        "35to44": "33",
        "45to54": "29",
        "55to64": "31",
        "65+": "20",
        "medianAge": "31"
      }
    },
    "title": "Lodging managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "143",
        "women": "52.5",
        "white": "9.7",
        "black": "16.9",
        "asian": "6.8"
      }
    },
    "wageData": {
      "2013": {
        "workers": "98",
        "median": "901",
        "maleWorkers": "45",
        "maleMedian": "-",
        "femaleWorkers": "53",
        "femaleMedian": "858"
      }
    }
  },
  "Medical and health services managers": {
    "ageData": {
      "2013": {
        "total": "585",
        "16to19": "1",
        "20to24": "13",
        "25to34": "83",
        "35to44": "147",
        "45to54": "162",
        "55to64": "145",
        "65+": "34",
        "medianAge": "145"
      }
    },
    "title": "Medical and health services managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "585",
        "women": "69.7",
        "white": "10.6",
        "black": "4.7",
        "asian": "9.6"
      }
    },
    "wageData": {
      "2013": {
        "workers": "529",
        "median": "1,258",
        "maleWorkers": "154",
        "maleMedian": "1,412",
        "femaleWorkers": "375",
        "femaleMedian": "1,224"
      }
    }
  },
  "Natural sciences managers": {
    "ageData": {
      "2013": {
        "total": "14",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "3",
        "45to54": "5",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Natural sciences managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "14",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Postmasters and mail superintendents": {
    "ageData": {
      "2013": {
        "total": "31",
        "16to19": "0",
        "20to24": "1",
        "25to34": "3",
        "35to44": "5",
        "45to54": "10",
        "55to64": "10",
        "65+": "1",
        "medianAge": "10"
      }
    },
    "title": "Postmasters and mail superintendents",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "31",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "30",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "17",
        "femaleMedian": "-"
      }
    }
  },
  "Property, real estate, and community association managers": {
    "ageData": {
      "2013": {
        "total": "654",
        "16to19": "0",
        "20to24": "15",
        "25to34": "96",
        "35to44": "128",
        "45to54": "172",
        "55to64": "154",
        "65+": "90",
        "medianAge": "154"
      }
    },
    "title": "Property, real estate, and community association managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "654",
        "women": "47.6",
        "white": "8.1",
        "black": "4.5",
        "asian": "11.0"
      }
    },
    "wageData": {
      "2013": {
        "workers": "358",
        "median": "862",
        "maleWorkers": "161",
        "maleMedian": "1,068",
        "femaleWorkers": "198",
        "femaleMedian": "784"
      }
    }
  },
  "Social and community service managers": {
    "ageData": {
      "2013": {
        "total": "332",
        "16to19": "1",
        "20to24": "10",
        "25to34": "66",
        "35to44": "75",
        "45to54": "76",
        "55to64": "78",
        "65+": "27",
        "medianAge": "78"
      }
    },
    "title": "Social and community service managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "332",
        "women": "65.6",
        "white": "13.2",
        "black": "3.3",
        "asian": "6.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "280",
        "median": "1,042",
        "maleWorkers": "94",
        "maleMedian": "1,150",
        "femaleWorkers": "185",
        "femaleMedian": "1,004"
      }
    }
  },
  "Emergency management directors": {
    "ageData": {
      "2013": {
        "total": "7",
        "16to19": "0",
        "20to24": "1",
        "25to34": "0",
        "35to44": "2",
        "45to54": "0",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Emergency management directors",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "9",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Managers, all other": {
    "ageData": {
      "2013": {
        "total": "3,969",
        "16to19": "8",
        "20to24": "79",
        "25to34": "618",
        "35to44": "934",
        "45to54": "1,191",
        "55to64": "876",
        "65+": "263",
        "medianAge": "876"
      }
    },
    "title": "Managers, all other",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "3,969",
        "women": "33.7",
        "white": "6.2",
        "black": "4.8",
        "asian": "9.1"
      }
    },
    "wageData": {
      "2013": {
        "workers": "2,431",
        "median": "1,264",
        "maleWorkers": "1,527",
        "maleMedian": "1,399",
        "femaleWorkers": "905",
        "femaleMedian": "1,105"
      }
    }
  },
  "Business and financial operations occupations": {
    "ageData": {
      "2013": {
        "total": "6,757",
        "16to19": "20",
        "20to24": "377",
        "25to34": "1,620",
        "35to44": "1,580",
        "45to54": "1,616",
        "55to64": "1,166",
        "65+": "378",
        "medianAge": "1,166"
      }
    },
    "title": "Business and financial operations occupations",
    "depth": 3,
    "parent": "Management, business, and financial operations occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "6,757",
        "women": "55.7",
        "white": "9.4",
        "black": "7.7",
        "asian": "8.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "5,636",
        "median": "1,091",
        "maleWorkers": "2,436",
        "maleMedian": "1,263",
        "femaleWorkers": "3,199",
        "femaleMedian": "979"
      }
    }
  },
  "Agents and business managers of artists, performers, and athletes": {
    "ageData": {
      "2013": {
        "total": "59",
        "16to19": "2",
        "20to24": "10",
        "25to34": "17",
        "35to44": "12",
        "45to54": "6",
        "55to64": "8",
        "65+": "4",
        "medianAge": "8"
      }
    },
    "title": "Agents and business managers of artists, performers, and athletes",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "59",
        "women": "51.3",
        "white": "2.6",
        "black": "7.8",
        "asian": "10.5"
      }
    },
    "wageData": {
      "2013": {
        "workers": "38",
        "median": "-",
        "maleWorkers": "20",
        "maleMedian": "-",
        "femaleWorkers": "18",
        "femaleMedian": "-"
      }
    }
  },
  "Buyers and purchasing agents, farm products": {
    "ageData": {
      "2013": {
        "total": "14",
        "16to19": "0",
        "20to24": "0",
        "25to34": "6",
        "35to44": "2",
        "45to54": "1",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Buyers and purchasing agents, farm products",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "14",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "17",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Wholesale and retail buyers, except farm products": {
    "ageData": {
      "2013": {
        "total": "215",
        "16to19": "2",
        "20to24": "18",
        "25to34": "55",
        "35to44": "42",
        "45to54": "52",
        "55to64": "37",
        "65+": "9",
        "medianAge": "37"
      }
    },
    "title": "Wholesale and retail buyers, except farm products",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "215",
        "women": "55.1",
        "white": "6.0",
        "black": "6.4",
        "asian": "9.8"
      }
    },
    "wageData": {
      "2013": {
        "workers": "158",
        "median": "899",
        "maleWorkers": "71",
        "maleMedian": "871",
        "femaleWorkers": "88",
        "femaleMedian": "930"
      }
    }
  },
  "Purchasing agents, except wholesale, retail, and farm products": {
    "ageData": {
      "2013": {
        "total": "271",
        "16to19": "1",
        "20to24": "10",
        "25to34": "46",
        "35to44": "64",
        "45to54": "79",
        "55to64": "59",
        "65+": "12",
        "medianAge": "59"
      }
    },
    "title": "Purchasing agents, except wholesale, retail, and farm products",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "271",
        "women": "57.4",
        "white": "9.6",
        "black": "3.0",
        "asian": "11.2"
      }
    },
    "wageData": {
      "2013": {
        "workers": "263",
        "median": "984",
        "maleWorkers": "112",
        "maleMedian": "1,116",
        "femaleWorkers": "151",
        "femaleMedian": "912"
      }
    }
  },
  "Claims adjusters, appraisers, examiners, and investigators": {
    "ageData": {
      "2013": {
        "total": "329",
        "16to19": "0",
        "20to24": "9",
        "25to34": "81",
        "35to44": "92",
        "45to54": "80",
        "55to64": "55",
        "65+": "12",
        "medianAge": "55"
      }
    },
    "title": "Claims adjusters, appraisers, examiners, and investigators",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "329",
        "women": "63.3",
        "white": "13.9",
        "black": "4.3",
        "asian": "7.7"
      }
    },
    "wageData": {
      "2013": {
        "workers": "300",
        "median": "931",
        "maleWorkers": "104",
        "maleMedian": "1,144",
        "femaleWorkers": "195",
        "femaleMedian": "838"
      }
    }
  },
  "Compliance officers": {
    "ageData": {
      "2013": {
        "total": "195",
        "16to19": "0",
        "20to24": "4",
        "25to34": "41",
        "35to44": "40",
        "45to54": "53",
        "55to64": "45",
        "65+": "12",
        "medianAge": "45"
      }
    },
    "title": "Compliance officers",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "195",
        "women": "53.4",
        "white": "11.7",
        "black": "7.1",
        "asian": "7.8"
      }
    },
    "wageData": {
      "2013": {
        "workers": "182",
        "median": "1,124",
        "maleWorkers": "83",
        "maleMedian": "1,170",
        "femaleWorkers": "99",
        "femaleMedian": "1,081"
      }
    }
  },
  "Cost estimators": {
    "ageData": {
      "2013": {
        "total": "123",
        "16to19": "0",
        "20to24": "5",
        "25to34": "18",
        "35to44": "32",
        "45to54": "29",
        "55to64": "32",
        "65+": "8",
        "medianAge": "32"
      }
    },
    "title": "Cost estimators",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "123",
        "women": "10.8",
        "white": "2.2",
        "black": "3.8",
        "asian": "10.1"
      }
    },
    "wageData": {
      "2013": {
        "workers": "102",
        "median": "1,050",
        "maleWorkers": "89",
        "maleMedian": "1,071",
        "femaleWorkers": "13",
        "femaleMedian": "-"
      }
    }
  },
  "Human resources workers": {
    "ageData": {
      "2013": {
        "total": "584",
        "16to19": "1",
        "20to24": "34",
        "25to34": "150",
        "35to44": "158",
        "45to54": "133",
        "55to64": "81",
        "65+": "26",
        "medianAge": "81"
      }
    },
    "title": "Human resources workers",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "584",
        "women": "72.4",
        "white": "12.3",
        "black": "4.1",
        "asian": "11.7"
      }
    },
    "wageData": {
      "2013": {
        "workers": "498",
        "median": "981",
        "maleWorkers": "131",
        "maleMedian": "1,139",
        "femaleWorkers": "366",
        "femaleMedian": "958"
      }
    }
  },
  "Compensation, benefits, and job analysis specialists": {
    "ageData": {
      "2013": {
        "total": "83",
        "16to19": "0",
        "20to24": "4",
        "25to34": "17",
        "35to44": "26",
        "45to54": "17",
        "55to64": "15",
        "65+": "3",
        "medianAge": "15"
      }
    },
    "title": "Compensation, benefits, and job analysis specialists",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "83",
        "women": "80.0",
        "white": "11.0",
        "black": "3.6",
        "asian": "12.6"
      }
    },
    "wageData": {
      "2013": {
        "workers": "72",
        "median": "1,033",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "59",
        "femaleMedian": "1,010"
      }
    }
  },
  "Training and development specialists": {
    "ageData": {
      "2013": {
        "total": "119",
        "16to19": "0",
        "20to24": "7",
        "25to34": "34",
        "35to44": "24",
        "45to54": "27",
        "55to64": "22",
        "65+": "4",
        "medianAge": "22"
      }
    },
    "title": "Training and development specialists",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "119",
        "women": "59.0",
        "white": "12.4",
        "black": "2.9",
        "asian": "13.8"
      }
    },
    "wageData": {
      "2013": {
        "workers": "109",
        "median": "1,079",
        "maleWorkers": "47",
        "maleMedian": "-",
        "femaleWorkers": "62",
        "femaleMedian": "979"
      }
    }
  },
  "Logisticians": {
    "ageData": {
      "2013": {
        "total": "88",
        "16to19": "0",
        "20to24": "8",
        "25to34": "22",
        "35to44": "9",
        "45to54": "27",
        "55to64": "20",
        "65+": "3",
        "medianAge": "20"
      }
    },
    "title": "Logisticians",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "88",
        "women": "40.0",
        "white": "12.8",
        "black": "6.5",
        "asian": "17.2"
      }
    },
    "wageData": {
      "2013": {
        "workers": "91",
        "median": "993",
        "maleWorkers": "58",
        "maleMedian": "1,045",
        "femaleWorkers": "33",
        "femaleMedian": "-"
      }
    }
  },
  "Management analysts": {
    "ageData": {
      "2013": {
        "total": "811",
        "16to19": "1",
        "20to24": "34",
        "25to34": "156",
        "35to44": "168",
        "45to54": "203",
        "55to64": "167",
        "65+": "82",
        "medianAge": "167"
      }
    },
    "title": "Management analysts",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "811",
        "women": "41.8",
        "white": "7.1",
        "black": "8.5",
        "asian": "5.7"
      }
    },
    "wageData": {
      "2013": {
        "workers": "547",
        "median": "1,450",
        "maleWorkers": "318",
        "maleMedian": "1,614",
        "femaleWorkers": "229",
        "femaleMedian": "1,319"
      }
    }
  },
  "Meeting, convention, and event planners": {
    "ageData": {
      "2013": {
        "total": "130",
        "16to19": "0",
        "20to24": "15",
        "25to34": "45",
        "35to44": "22",
        "45to54": "28",
        "55to64": "14",
        "65+": "7",
        "medianAge": "14"
      }
    },
    "title": "Meeting, convention, and event planners",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "130",
        "women": "74.7",
        "white": "10.2",
        "black": "6.1",
        "asian": "6.1"
      }
    },
    "wageData": {
      "2013": {
        "workers": "95",
        "median": "930",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "80",
        "femaleMedian": "901"
      }
    }
  },
  "Fundraisers": {
    "ageData": {
      "2013": {
        "total": "81",
        "16to19": "2",
        "20to24": "5",
        "25to34": "17",
        "35to44": "24",
        "45to54": "13",
        "55to64": "17",
        "65+": "3",
        "medianAge": "17"
      }
    },
    "title": "Fundraisers",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "81",
        "women": "71.4",
        "white": "3.4",
        "black": "0.4",
        "asian": "5.4"
      }
    },
    "wageData": {
      "2013": {
        "workers": "80",
        "median": "1,124",
        "maleWorkers": "22",
        "maleMedian": "-",
        "femaleWorkers": "58",
        "femaleMedian": "1,084"
      }
    }
  },
  "Market research analysts and marketing specialists": {
    "ageData": {
      "2013": {
        "total": "241",
        "16to19": "1",
        "20to24": "24",
        "25to34": "75",
        "35to44": "54",
        "45to54": "50",
        "55to64": "26",
        "65+": "9",
        "medianAge": "26"
      }
    },
    "title": "Market research analysts and marketing specialists",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "241",
        "women": "58.1",
        "white": "7.1",
        "black": "10.4",
        "asian": "5.1"
      }
    },
    "wageData": {
      "2013": {
        "workers": "190",
        "median": "1,139",
        "maleWorkers": "80",
        "maleMedian": "1,171",
        "femaleWorkers": "110",
        "femaleMedian": "967"
      }
    }
  },
  "Business operations specialists, all other": {
    "ageData": {
      "2013": {
        "total": "195",
        "16to19": "2",
        "20to24": "13",
        "25to34": "46",
        "35to44": "43",
        "45to54": "46",
        "55to64": "37",
        "65+": "9",
        "medianAge": "37"
      }
    },
    "title": "Business operations specialists, all other",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "195",
        "women": "62.7",
        "white": "11.3",
        "black": "6.2",
        "asian": "8.2"
      }
    },
    "wageData": {
      "2013": {
        "workers": "180",
        "median": "1,044",
        "maleWorkers": "69",
        "maleMedian": "1,226",
        "femaleWorkers": "110",
        "femaleMedian": "899"
      }
    }
  },
  "Accountants and auditors": {
    "ageData": {
      "2013": {
        "total": "1,814",
        "16to19": "4",
        "20to24": "87",
        "25to34": "451",
        "35to44": "442",
        "45to54": "443",
        "55to64": "293",
        "65+": "94",
        "medianAge": "293"
      }
    },
    "title": "Accountants and auditors",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "1,814",
        "women": "62.1",
        "white": "8.6",
        "black": "11.9",
        "asian": "7.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "1,516",
        "median": "1,109",
        "maleWorkers": "571",
        "maleMedian": "1,268",
        "femaleWorkers": "945",
        "femaleMedian": "1,029"
      }
    }
  },
  "Appraisers and assessors of real estate": {
    "ageData": {
      "2013": {
        "total": "98",
        "16to19": "0",
        "20to24": "3",
        "25to34": "19",
        "35to44": "16",
        "45to54": "29",
        "55to64": "24",
        "65+": "7",
        "medianAge": "24"
      }
    },
    "title": "Appraisers and assessors of real estate",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "98",
        "women": "42.4",
        "white": "5.5",
        "black": "3.0",
        "asian": "5.5"
      }
    },
    "wageData": {
      "2013": {
        "workers": "60",
        "median": "1,142",
        "maleWorkers": "33",
        "maleMedian": "-",
        "femaleWorkers": "27",
        "femaleMedian": "-"
      }
    }
  },
  "Budget analysts": {
    "ageData": {
      "2013": {
        "total": "62",
        "16to19": "0",
        "20to24": "3",
        "25to34": "14",
        "35to44": "14",
        "45to54": "19",
        "55to64": "11",
        "65+": "2",
        "medianAge": "11"
      }
    },
    "title": "Budget analysts",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "62",
        "women": "49.3",
        "white": "14.2",
        "black": "2.8",
        "asian": "11.0"
      }
    },
    "wageData": {
      "2013": {
        "workers": "58",
        "median": "1,389",
        "maleWorkers": "31",
        "maleMedian": "-",
        "femaleWorkers": "26",
        "femaleMedian": "-"
      }
    }
  },
  "Credit analysts": {
    "ageData": {
      "2013": {
        "total": "30",
        "16to19": "0",
        "20to24": "3",
        "25to34": "11",
        "35to44": "7",
        "45to54": "6",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Credit analysts",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "30",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "31",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "19",
        "femaleMedian": "-"
      }
    }
  },
  "Financial analysts": {
    "ageData": {
      "2013": {
        "total": "105",
        "16to19": "0",
        "20to24": "9",
        "25to34": "33",
        "35to44": "32",
        "45to54": "20",
        "55to64": "10",
        "65+": "2",
        "medianAge": "10"
      }
    },
    "title": "Financial analysts",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "105",
        "women": "32.6",
        "white": "8.5",
        "black": "13.7",
        "asian": "5.5"
      }
    },
    "wageData": {
      "2013": {
        "workers": "89",
        "median": "1,466",
        "maleWorkers": "56",
        "maleMedian": "1,757",
        "femaleWorkers": "33",
        "femaleMedian": "-"
      }
    }
  },
  "Personal financial advisors": {
    "ageData": {
      "2013": {
        "total": "383",
        "16to19": "1",
        "20to24": "25",
        "25to34": "89",
        "35to44": "92",
        "45to54": "85",
        "55to64": "60",
        "65+": "31",
        "medianAge": "60"
      }
    },
    "title": "Personal financial advisors",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "383",
        "women": "25.7",
        "white": "6.5",
        "black": "8.0",
        "asian": "5.2"
      }
    },
    "wageData": {
      "2013": {
        "workers": "288",
        "median": "1,424",
        "maleWorkers": "215",
        "maleMedian": "1,565",
        "femaleWorkers": "72",
        "femaleMedian": "1,149"
      }
    }
  },
  "Insurance underwriters": {
    "ageData": {
      "2013": {
        "total": "94",
        "16to19": "0",
        "20to24": "5",
        "25to34": "22",
        "35to44": "20",
        "45to54": "22",
        "55to64": "22",
        "65+": "3",
        "medianAge": "22"
      }
    },
    "title": "Insurance underwriters",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "94",
        "women": "63.6",
        "white": "13.7",
        "black": "2.1",
        "asian": "8.7"
      }
    },
    "wageData": {
      "2013": {
        "workers": "98",
        "median": "1,045",
        "maleWorkers": "37",
        "maleMedian": "-",
        "femaleWorkers": "61",
        "femaleMedian": "879"
      }
    }
  },
  "Financial examiners": {
    "ageData": {
      "2013": {
        "total": "14",
        "16to19": "0",
        "20to24": "4",
        "25to34": "1",
        "35to44": "3",
        "45to54": "2",
        "55to64": "4",
        "65+": "1",
        "medianAge": "4"
      }
    },
    "title": "Financial examiners",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "14",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "10",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Credit counselors and loan officers": {
    "ageData": {
      "2013": {
        "total": "365",
        "16to19": "1",
        "20to24": "24",
        "25to34": "97",
        "35to44": "94",
        "45to54": "82",
        "55to64": "59",
        "65+": "9",
        "medianAge": "59"
      }
    },
    "title": "Credit counselors and loan officers",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "365",
        "women": "56.6",
        "white": "10.6",
        "black": "6.0",
        "asian": "9.6"
      }
    },
    "wageData": {
      "2013": {
        "workers": "360",
        "median": "970",
        "maleWorkers": "152",
        "maleMedian": "1,162",
        "femaleWorkers": "208",
        "femaleMedian": "864"
      }
    }
  },
  "Tax examiners and collectors, and revenue agents": {
    "ageData": {
      "2013": {
        "total": "60",
        "16to19": "0",
        "20to24": "0",
        "25to34": "14",
        "35to44": "13",
        "45to54": "25",
        "55to64": "7",
        "65+": "1",
        "medianAge": "7"
      }
    },
    "title": "Tax examiners and collectors, and revenue agents",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "60",
        "women": "70.2",
        "white": "20.9",
        "black": "9.1",
        "asian": "7.1"
      }
    },
    "wageData": {
      "2013": {
        "workers": "62",
        "median": "952",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "43",
        "femaleMedian": "-"
      }
    }
  },
  "Tax preparers": {
    "ageData": {
      "2013": {
        "total": "97",
        "16to19": "0",
        "20to24": "2",
        "25to34": "17",
        "35to44": "15",
        "45to54": "22",
        "55to64": "21",
        "65+": "20",
        "medianAge": "21"
      }
    },
    "title": "Tax preparers",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "97",
        "women": "62.1",
        "white": "21.4",
        "black": "9.6",
        "asian": "17.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "56",
        "median": "767",
        "maleWorkers": "22",
        "maleMedian": "-",
        "femaleWorkers": "34",
        "femaleMedian": "-"
      }
    }
  },
  "Financial specialists, all other": {
    "ageData": {
      "2013": {
        "total": "94",
        "16to19": "1",
        "20to24": "11",
        "25to34": "26",
        "35to44": "21",
        "45to54": "18",
        "55to64": "13",
        "65+": "5",
        "medianAge": "13"
      }
    },
    "title": "Financial specialists, all other",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "94",
        "women": "56.5",
        "white": "12.5",
        "black": "6.4",
        "asian": "10.8"
      }
    },
    "wageData": {
      "2013": {
        "workers": "87",
        "median": "962",
        "maleWorkers": "38",
        "maleMedian": "-",
        "femaleWorkers": "49",
        "femaleMedian": "-"
      }
    }
  },
  "Professional and related occupations": {
    "ageData": {
      "2013": {
        "total": "31,917",
        "16to19": "255",
        "20to24": "1,936",
        "25to34": "7,762",
        "35to44": "7,651",
        "45to54": "7,089",
        "55to64": "5,511",
        "65+": "1,713",
        "medianAge": "5,511"
      }
    },
    "title": "Professional and related occupations",
    "depth": 2,
    "parent": "Management, professional, and related occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "31,917",
        "women": "57.1",
        "white": "9.3",
        "black": "8.3",
        "asian": "8.2"
      }
    },
    "wageData": {
      "2013": {
        "workers": "24,683",
        "median": "1,071",
        "maleWorkers": "11,089",
        "maleMedian": "1,295",
        "femaleWorkers": "13,594",
        "femaleMedian": "944"
      }
    }
  },
  "Computer and mathematical occupations": {
    "ageData": {
      "2013": {
        "total": "3,980",
        "16to19": "20",
        "20to24": "235",
        "25to34": "1,062",
        "35to44": "1,143",
        "45to54": "946",
        "55to64": "476",
        "65+": "98",
        "medianAge": "476"
      }
    },
    "title": "Computer and mathematical occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "3,980",
        "women": "26.1",
        "white": "8.3",
        "black": "18.5",
        "asian": "6.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "3,621",
        "median": "1,365",
        "maleWorkers": "2,693",
        "maleMedian": "1,452",
        "femaleWorkers": "928",
        "femaleMedian": "1,174"
      }
    }
  },
  "Computer and information research scientists": {
    "ageData": {
      "2013": {
        "total": "18",
        "16to19": "0",
        "20to24": "1",
        "25to34": "4",
        "35to44": "4",
        "45to54": "5",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Computer and information research scientists",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "18",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "15",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Computer systems analysts": {
    "ageData": {
      "2013": {
        "total": "534",
        "16to19": "1",
        "20to24": "36",
        "25to34": "132",
        "35to44": "152",
        "45to54": "125",
        "55to64": "76",
        "65+": "11",
        "medianAge": "76"
      }
    },
    "title": "Computer systems analysts",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "534",
        "women": "34.9",
        "white": "9.5",
        "black": "20.5",
        "asian": "5.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "443",
        "median": "1,367",
        "maleWorkers": "285",
        "maleMedian": "1,455",
        "femaleWorkers": "158",
        "femaleMedian": "1,216"
      }
    }
  },
  "Information security analysts": {
    "ageData": {
      "2013": {
        "total": "48",
        "16to19": "0",
        "20to24": "4",
        "25to34": "8",
        "35to44": "16",
        "45to54": "12",
        "55to64": "7",
        "65+": "2",
        "medianAge": "7"
      }
    },
    "title": "Information security analysts",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "48",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "53",
        "median": "1,460",
        "maleWorkers": "43",
        "maleMedian": "-",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Computer programmers": {
    "ageData": {
      "2013": {
        "total": "489",
        "16to19": "2",
        "20to24": "25",
        "25to34": "105",
        "35to44": "146",
        "45to54": "129",
        "55to64": "68",
        "65+": "14",
        "medianAge": "68"
      }
    },
    "title": "Computer programmers",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "489",
        "women": "23.0",
        "white": "8.1",
        "black": "18.8",
        "asian": "5.6"
      }
    },
    "wageData": {
      "2013": {
        "workers": "437",
        "median": "1,372",
        "maleWorkers": "332",
        "maleMedian": "1,428",
        "femaleWorkers": "106",
        "femaleMedian": "1,162"
      }
    }
  },
  "Software developers, applications and systems software": {
    "ageData": {
      "2013": {
        "total": "1,103",
        "16to19": "6",
        "20to24": "49",
        "25to34": "332",
        "35to44": "320",
        "45to54": "260",
        "55to64": "117",
        "65+": "19",
        "medianAge": "117"
      }
    },
    "title": "Software developers, applications and systems software",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "1,103",
        "women": "19.7",
        "white": "4.1",
        "black": "28.8",
        "asian": "5.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "1,053",
        "median": "1,643",
        "maleWorkers": "844",
        "maleMedian": "1,737",
        "femaleWorkers": "209",
        "femaleMedian": "1,370"
      }
    }
  },
  "Web developers": {
    "ageData": {
      "2013": {
        "total": "192",
        "16to19": "1",
        "20to24": "22",
        "25to34": "60",
        "35to44": "60",
        "45to54": "36",
        "55to64": "12",
        "65+": "2",
        "medianAge": "12"
      }
    },
    "title": "Web developers",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "192",
        "women": "39.5",
        "white": "8.0",
        "black": "8.7",
        "asian": "6.5"
      }
    },
    "wageData": {
      "2013": {
        "workers": "137",
        "median": "1,060",
        "maleWorkers": "84",
        "maleMedian": "1,157",
        "femaleWorkers": "53",
        "femaleMedian": "937"
      }
    }
  },
  "Computer support specialists": {
    "ageData": {
      "2013": {
        "total": "517",
        "16to19": "5",
        "20to24": "46",
        "25to34": "150",
        "35to44": "125",
        "45to54": "107",
        "55to64": "71",
        "65+": "12",
        "medianAge": "71"
      }
    },
    "title": "Computer support specialists",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "517",
        "women": "29.0",
        "white": "13.2",
        "black": "7.6",
        "asian": "7.6"
      }
    },
    "wageData": {
      "2013": {
        "workers": "474",
        "median": "980",
        "maleWorkers": "340",
        "maleMedian": "1,019",
        "femaleWorkers": "134",
        "femaleMedian": "901"
      }
    }
  },
  "Database administrators": {
    "ageData": {
      "2013": {
        "total": "98",
        "16to19": "0",
        "20to24": "2",
        "25to34": "24",
        "35to44": "30",
        "45to54": "30",
        "55to64": "11",
        "65+": "1",
        "medianAge": "11"
      }
    },
    "title": "Database administrators",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "98",
        "women": "37.4",
        "white": "14.2",
        "black": "10.8",
        "asian": "6.8"
      }
    },
    "wageData": {
      "2013": {
        "workers": "95",
        "median": "1,345",
        "maleWorkers": "65",
        "maleMedian": "1,563",
        "femaleWorkers": "30",
        "femaleMedian": "-"
      }
    }
  },
  "Network and computer systems administrators": {
    "ageData": {
      "2013": {
        "total": "205",
        "16to19": "0",
        "20to24": "10",
        "25to34": "61",
        "35to44": "68",
        "45to54": "38",
        "55to64": "25",
        "65+": "2",
        "medianAge": "25"
      }
    },
    "title": "Network and computer systems administrators",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "205",
        "women": "17.3",
        "white": "9.3",
        "black": "11.3",
        "asian": "7.4"
      }
    },
    "wageData": {
      "2013": {
        "workers": "210",
        "median": "1,237",
        "maleWorkers": "173",
        "maleMedian": "1,252",
        "femaleWorkers": "37",
        "femaleMedian": "-"
      }
    }
  },
  "Computer network architects": {
    "ageData": {
      "2013": {
        "total": "135",
        "16to19": "0",
        "20to24": "2",
        "25to34": "27",
        "35to44": "50",
        "45to54": "41",
        "55to64": "12",
        "65+": "3",
        "medianAge": "12"
      }
    },
    "title": "Computer network architects",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "135",
        "women": "7.5",
        "white": "9.4",
        "black": "14.5",
        "asian": "5.1"
      }
    },
    "wageData": {
      "2013": {
        "workers": "131",
        "median": "1,630",
        "maleWorkers": "122",
        "maleMedian": "1,593",
        "femaleWorkers": "9",
        "femaleMedian": "-"
      }
    }
  },
  "Computer occupations, all other": {
    "ageData": {
      "2013": {
        "total": "410",
        "16to19": "4",
        "20to24": "28",
        "25to34": "96",
        "35to44": "116",
        "45to54": "105",
        "55to64": "43",
        "65+": "19",
        "medianAge": "43"
      }
    },
    "title": "Computer occupations, all other",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "410",
        "women": "22.8",
        "white": "9.9",
        "black": "16.5",
        "asian": "8.1"
      }
    },
    "wageData": {
      "2013": {
        "workers": "359",
        "median": "1,149",
        "maleWorkers": "277",
        "maleMedian": "1,136",
        "femaleWorkers": "81",
        "femaleMedian": "1,176"
      }
    }
  },
  "Actuaries": {
    "ageData": {
      "2013": {
        "total": "28",
        "16to19": "0",
        "20to24": "4",
        "25to34": "7",
        "35to44": "7",
        "45to54": "6",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Actuaries",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "28",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "25",
        "median": "-",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "9",
        "femaleMedian": "-"
      }
    }
  },
  "Mathematicians": {
    "ageData": {
      "2013": {
        "total": "1",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "1",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Mathematicians",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Operations research analysts": {
    "ageData": {
      "2013": {
        "total": "128",
        "16to19": "0",
        "20to24": "3",
        "25to34": "35",
        "35to44": "26",
        "45to54": "35",
        "55to64": "17",
        "65+": "11",
        "medianAge": "17"
      }
    },
    "title": "Operations research analysts",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "128",
        "women": "52.0",
        "white": "7.8",
        "black": "8.3",
        "asian": "11.2"
      }
    },
    "wageData": {
      "2013": {
        "workers": "119",
        "median": "1,444",
        "maleWorkers": "56",
        "maleMedian": "1,569",
        "femaleWorkers": "63",
        "femaleMedian": "1,313"
      }
    }
  },
  "Statisticians": {
    "ageData": {
      "2013": {
        "total": "72",
        "16to19": "0",
        "20to24": "5",
        "25to34": "21",
        "35to44": "21",
        "45to54": "13",
        "55to64": "11",
        "65+": "1",
        "medianAge": "11"
      }
    },
    "title": "Statisticians",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "72",
        "women": "38.3",
        "white": "11.4",
        "black": "25.4",
        "asian": "3.0"
      }
    },
    "wageData": {
      "2013": {
        "workers": "65",
        "median": "1,414",
        "maleWorkers": "42",
        "maleMedian": "-",
        "femaleWorkers": "23",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous mathematical science occupations": {
    "ageData": {
      "2013": {
        "total": "3",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "1",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Miscellaneous mathematical science occupations",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Architecture and engineering occupations": {
    "ageData": {
      "2013": {
        "total": "2,806",
        "16to19": "11",
        "20to24": "163",
        "25to34": "672",
        "35to44": "605",
        "45to54": "712",
        "55to64": "512",
        "65+": "130",
        "medianAge": "512"
      }
    },
    "title": "Architecture and engineering occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "2,806",
        "women": "14.1",
        "white": "5.5",
        "black": "11.3",
        "asian": "7.5"
      }
    },
    "wageData": {
      "2013": {
        "workers": "2,540",
        "median": "1,365",
        "maleWorkers": "2,209",
        "maleMedian": "1,403",
        "femaleWorkers": "330",
        "femaleMedian": "1,143"
      }
    }
  },
  "Architects, except naval": {
    "ageData": {
      "2013": {
        "total": "193",
        "16to19": "0",
        "20to24": "2",
        "25to34": "44",
        "35to44": "49",
        "45to54": "41",
        "55to64": "40",
        "65+": "18",
        "medianAge": "40"
      }
    },
    "title": "Architects, except naval",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "193",
        "women": "25.0",
        "white": "1.6",
        "black": "5.8",
        "asian": "7.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "118",
        "median": "1,292",
        "maleWorkers": "88",
        "maleMedian": "1,347",
        "femaleWorkers": "30",
        "femaleMedian": "-"
      }
    }
  },
  "Surveyors, cartographers, and photogrammetrists": {
    "ageData": {
      "2013": {
        "total": "39",
        "16to19": "0",
        "20to24": "2",
        "25to34": "16",
        "35to44": "7",
        "45to54": "9",
        "55to64": "6",
        "65+": "0",
        "medianAge": "6"
      }
    },
    "title": "Surveyors, cartographers, and photogrammetrists",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "39",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "32",
        "median": "-",
        "maleWorkers": "22",
        "maleMedian": "-",
        "femaleWorkers": "9",
        "femaleMedian": "-"
      }
    }
  },
  "Aerospace engineers": {
    "ageData": {
      "2013": {
        "total": "144",
        "16to19": "1",
        "20to24": "5",
        "25to34": "32",
        "35to44": "34",
        "45to54": "40",
        "55to64": "27",
        "65+": "6",
        "medianAge": "27"
      }
    },
    "title": "Aerospace engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "144",
        "women": "12.2",
        "white": "4.9",
        "black": "10.9",
        "asian": "4.7"
      }
    },
    "wageData": {
      "2013": {
        "workers": "137",
        "median": "1,865",
        "maleWorkers": "124",
        "maleMedian": "1,873",
        "femaleWorkers": "13",
        "femaleMedian": "-"
      }
    }
  },
  "Agricultural engineers": {
    "ageData": {
      "2013": {
        "total": "3",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "2",
        "45to54": "0",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Agricultural engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Biomedical engineers": {
    "ageData": {
      "2013": {
        "total": "11",
        "16to19": "0",
        "20to24": "0",
        "25to34": "7",
        "35to44": "0",
        "45to54": "2",
        "55to64": "0",
        "65+": "1",
        "medianAge": "0"
      }
    },
    "title": "Biomedical engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "11",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "10",
        "median": "-",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Chemical engineers": {
    "ageData": {
      "2013": {
        "total": "61",
        "16to19": "0",
        "20to24": "5",
        "25to34": "14",
        "35to44": "11",
        "45to54": "12",
        "55to64": "13",
        "65+": "5",
        "medianAge": "13"
      }
    },
    "title": "Chemical engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "61",
        "women": "15.6",
        "white": "5.9",
        "black": "7.5",
        "asian": "6.0"
      }
    },
    "wageData": {
      "2013": {
        "workers": "56",
        "median": "1,568",
        "maleWorkers": "48",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Civil engineers": {
    "ageData": {
      "2013": {
        "total": "360",
        "16to19": "0",
        "20to24": "23",
        "25to34": "93",
        "35to44": "74",
        "45to54": "93",
        "55to64": "55",
        "65+": "20",
        "medianAge": "55"
      }
    },
    "title": "Civil engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "360",
        "women": "12.1",
        "white": "6.4",
        "black": "10.4",
        "asian": "4.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "324",
        "median": "1,373",
        "maleWorkers": "288",
        "maleMedian": "1,417",
        "femaleWorkers": "36",
        "femaleMedian": "-"
      }
    }
  },
  "Computer hardware engineers": {
    "ageData": {
      "2013": {
        "total": "90",
        "16to19": "0",
        "20to24": "5",
        "25to34": "24",
        "35to44": "26",
        "45to54": "23",
        "55to64": "11",
        "65+": "1",
        "medianAge": "11"
      }
    },
    "title": "Computer hardware engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "90",
        "women": "9.2",
        "white": "6.3",
        "black": "25.6",
        "asian": "10.4"
      }
    },
    "wageData": {
      "2013": {
        "workers": "92",
        "median": "1,507",
        "maleWorkers": "82",
        "maleMedian": "1,544",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Electrical and electronics engineers": {
    "ageData": {
      "2013": {
        "total": "300",
        "16to19": "1",
        "20to24": "19",
        "25to34": "66",
        "35to44": "60",
        "45to54": "81",
        "55to64": "59",
        "65+": "14",
        "medianAge": "59"
      }
    },
    "title": "Electrical and electronics engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "300",
        "women": "8.3",
        "white": "4.2",
        "black": "18.5",
        "asian": "7.6"
      }
    },
    "wageData": {
      "2013": {
        "workers": "268",
        "median": "1,522",
        "maleWorkers": "247",
        "maleMedian": "1,514",
        "femaleWorkers": "21",
        "femaleMedian": "-"
      }
    }
  },
  "Environmental engineers": {
    "ageData": {
      "2013": {
        "total": "33",
        "16to19": "1",
        "20to24": "2",
        "25to34": "6",
        "35to44": "7",
        "45to54": "5",
        "55to64": "7",
        "65+": "3",
        "medianAge": "7"
      }
    },
    "title": "Environmental engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "33",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "28",
        "median": "-",
        "maleWorkers": "22",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Industrial engineers, including health and safety": {
    "ageData": {
      "2013": {
        "total": "190",
        "16to19": "1",
        "20to24": "9",
        "25to34": "31",
        "35to44": "43",
        "45to54": "57",
        "55to64": "42",
        "65+": "7",
        "medianAge": "42"
      }
    },
    "title": "Industrial engineers, including health and safety",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "190",
        "women": "17.2",
        "white": "5.9",
        "black": "10.5",
        "asian": "8.8"
      }
    },
    "wageData": {
      "2013": {
        "workers": "181",
        "median": "1,385",
        "maleWorkers": "151",
        "maleMedian": "1,417",
        "femaleWorkers": "30",
        "femaleMedian": "-"
      }
    }
  },
  "Marine engineers and naval architects": {
    "ageData": {
      "2013": {
        "total": "11",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "4",
        "45to54": "3",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Marine engineers and naval architects",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "11",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Materials engineers": {
    "ageData": {
      "2013": {
        "total": "46",
        "16to19": "0",
        "20to24": "3",
        "25to34": "11",
        "35to44": "8",
        "45to54": "17",
        "55to64": "4",
        "65+": "3",
        "medianAge": "4"
      }
    },
    "title": "Materials engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "46",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "43",
        "median": "-",
        "maleWorkers": "36",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Mechanical engineers": {
    "ageData": {
      "2013": {
        "total": "327",
        "16to19": "1",
        "20to24": "27",
        "25to34": "80",
        "35to44": "75",
        "45to54": "84",
        "55to64": "50",
        "65+": "10",
        "medianAge": "50"
      }
    },
    "title": "Mechanical engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "327",
        "women": "7.2",
        "white": "3.3",
        "black": "12.6",
        "asian": "6.8"
      }
    },
    "wageData": {
      "2013": {
        "workers": "297",
        "median": "1,496",
        "maleWorkers": "273",
        "maleMedian": "1,554",
        "femaleWorkers": "24",
        "femaleMedian": "-"
      }
    }
  },
  "Mining and geological engineers, including mining safety engineers": {
    "ageData": {
      "2013": {
        "total": "18",
        "16to19": "0",
        "20to24": "1",
        "25to34": "6",
        "35to44": "2",
        "45to54": "3",
        "55to64": "6",
        "65+": "0",
        "medianAge": "6"
      }
    },
    "title": "Mining and geological engineers, including mining safety engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "18",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "16",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Nuclear engineers": {
    "ageData": {
      "2013": {
        "total": "4",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "1",
        "45to54": "1",
        "55to64": "0",
        "65+": "1",
        "medianAge": "0"
      }
    },
    "title": "Nuclear engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Petroleum engineers": {
    "ageData": {
      "2013": {
        "total": "33",
        "16to19": "0",
        "20to24": "5",
        "25to34": "10",
        "35to44": "8",
        "45to54": "5",
        "55to64": "5",
        "65+": "0",
        "medianAge": "5"
      }
    },
    "title": "Petroleum engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "33",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "34",
        "median": "-",
        "maleWorkers": "33",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Engineers, all other": {
    "ageData": {
      "2013": {
        "total": "398",
        "16to19": "0",
        "20to24": "16",
        "25to34": "105",
        "35to44": "92",
        "45to54": "92",
        "55to64": "70",
        "65+": "24",
        "medianAge": "70"
      }
    },
    "title": "Engineers, all other",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "398",
        "women": "14.4",
        "white": "5.8",
        "black": "12.6",
        "asian": "6.7"
      }
    },
    "wageData": {
      "2013": {
        "workers": "371",
        "median": "1,528",
        "maleWorkers": "322",
        "maleMedian": "1,561",
        "femaleWorkers": "48",
        "femaleMedian": "-"
      }
    }
  },
  "Drafters": {
    "ageData": {
      "2013": {
        "total": "125",
        "16to19": "1",
        "20to24": "10",
        "25to34": "33",
        "35to44": "26",
        "45to54": "33",
        "55to64": "21",
        "65+": "1",
        "medianAge": "21"
      }
    },
    "title": "Drafters",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "125",
        "women": "21.2",
        "white": "2.1",
        "black": "8.7",
        "asian": "7.1"
      }
    },
    "wageData": {
      "2013": {
        "workers": "104",
        "median": "974",
        "maleWorkers": "85",
        "maleMedian": "991",
        "femaleWorkers": "20",
        "femaleMedian": "-"
      }
    }
  },
  "Engineering technicians, except drafters": {
    "ageData": {
      "2013": {
        "total": "365",
        "16to19": "4",
        "20to24": "26",
        "25to34": "78",
        "35to44": "66",
        "45to54": "97",
        "55to64": "83",
        "65+": "12",
        "medianAge": "83"
      }
    },
    "title": "Engineering technicians, except drafters",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "365",
        "women": "17.6",
        "white": "8.3",
        "black": "6.7",
        "asian": "13.0"
      }
    },
    "wageData": {
      "2013": {
        "workers": "361",
        "median": "1,011",
        "maleWorkers": "302",
        "maleMedian": "1,027",
        "femaleWorkers": "59",
        "femaleMedian": "934"
      }
    }
  },
  "Surveying and mapping technicians": {
    "ageData": {
      "2013": {
        "total": "54",
        "16to19": "1",
        "20to24": "2",
        "25to34": "14",
        "35to44": "10",
        "45to54": "14",
        "55to64": "10",
        "65+": "4",
        "medianAge": "10"
      }
    },
    "title": "Surveying and mapping technicians",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "54",
        "women": "10.8",
        "white": "4.1",
        "black": "4.3",
        "asian": "3.4"
      }
    },
    "wageData": {
      "2013": {
        "workers": "48",
        "median": "-",
        "maleWorkers": "43",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Life, physical, and social science occupations": {
    "ageData": {
      "2013": {
        "total": "1,307",
        "16to19": "5",
        "20to24": "79",
        "25to34": "330",
        "35to44": "325",
        "45to54": "258",
        "55to64": "236",
        "65+": "75",
        "medianAge": "236"
      }
    },
    "title": "Life, physical, and social science occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "1,307",
        "women": "46.1",
        "white": "5.6",
        "black": "11.8",
        "asian": "7.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "1,063",
        "median": "1,152",
        "maleWorkers": "608",
        "maleMedian": "1,271",
        "femaleWorkers": "456",
        "femaleMedian": "1,030"
      }
    }
  },
  "Agricultural and food scientists": {
    "ageData": {
      "2013": {
        "total": "44",
        "16to19": "0",
        "20to24": "2",
        "25to34": "14",
        "35to44": "12",
        "45to54": "3",
        "55to64": "10",
        "65+": "2",
        "medianAge": "10"
      }
    },
    "title": "Agricultural and food scientists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "44",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "30",
        "median": "-",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Biological scientists": {
    "ageData": {
      "2013": {
        "total": "112",
        "16to19": "0",
        "20to24": "8",
        "25to34": "26",
        "35to44": "31",
        "45to54": "21",
        "55to64": "19",
        "65+": "6",
        "medianAge": "19"
      }
    },
    "title": "Biological scientists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "112",
        "women": "52.7",
        "white": "2.6",
        "black": "17.0",
        "asian": "4.8"
      }
    },
    "wageData": {
      "2013": {
        "workers": "107",
        "median": "1,157",
        "maleWorkers": "54",
        "maleMedian": "1,233",
        "femaleWorkers": "53",
        "femaleMedian": "1,104"
      }
    }
  },
  "Conservation scientists and foresters": {
    "ageData": {
      "2013": {
        "total": "32",
        "16to19": "0",
        "20to24": "1",
        "25to34": "7",
        "35to44": "6",
        "45to54": "8",
        "55to64": "8",
        "65+": "2",
        "medianAge": "8"
      }
    },
    "title": "Conservation scientists and foresters",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "32",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "27",
        "median": "-",
        "maleWorkers": "23",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Medical scientists": {
    "ageData": {
      "2013": {
        "total": "144",
        "16to19": "0",
        "20to24": "4",
        "25to34": "39",
        "35to44": "50",
        "45to54": "26",
        "55to64": "23",
        "65+": "2",
        "medianAge": "23"
      }
    },
    "title": "Medical scientists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "144",
        "women": "52.4",
        "white": "6.5",
        "black": "26.3",
        "asian": "6.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "131",
        "median": "1,216",
        "maleWorkers": "58",
        "maleMedian": "1,430",
        "femaleWorkers": "73",
        "femaleMedian": "1,142"
      }
    }
  },
  "Life scientists, all other": {
    "ageData": {
      "2013": {
        "total": "0",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Life scientists, all other",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "0",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Astronomers and physicists": {
    "ageData": {
      "2013": {
        "total": "14",
        "16to19": "0",
        "20to24": "0",
        "25to34": "4",
        "35to44": "1",
        "45to54": "3",
        "55to64": "1",
        "65+": "4",
        "medianAge": "1"
      }
    },
    "title": "Astronomers and physicists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "14",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Atmospheric and space scientists": {
    "ageData": {
      "2013": {
        "total": "14",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "5",
        "45to54": "3",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Atmospheric and space scientists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "14",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "11",
        "median": "-",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Chemists and materials scientists": {
    "ageData": {
      "2013": {
        "total": "121",
        "16to19": "0",
        "20to24": "7",
        "25to34": "38",
        "35to44": "31",
        "45to54": "24",
        "55to64": "18",
        "65+": "3",
        "medianAge": "18"
      }
    },
    "title": "Chemists and materials scientists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "121",
        "women": "39.0",
        "white": "7.3",
        "black": "10.4",
        "asian": "12.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "109",
        "median": "1,182",
        "maleWorkers": "68",
        "maleMedian": "1,380",
        "femaleWorkers": "42",
        "femaleMedian": "-"
      }
    }
  },
  "Environmental scientists and geoscientists": {
    "ageData": {
      "2013": {
        "total": "94",
        "16to19": "0",
        "20to24": "4",
        "25to34": "25",
        "35to44": "17",
        "45to54": "28",
        "55to64": "16",
        "65+": "4",
        "medianAge": "16"
      }
    },
    "title": "Environmental scientists and geoscientists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "94",
        "women": "27.9",
        "white": "4.0",
        "black": "6.3",
        "asian": "3.8"
      }
    },
    "wageData": {
      "2013": {
        "workers": "76",
        "median": "1,330",
        "maleWorkers": "58",
        "maleMedian": "1,392",
        "femaleWorkers": "18",
        "femaleMedian": "-"
      }
    }
  },
  "Physical scientists, all other": {
    "ageData": {
      "2013": {
        "total": "158",
        "16to19": "0",
        "20to24": "6",
        "25to34": "31",
        "35to44": "52",
        "45to54": "39",
        "55to64": "21",
        "65+": "9",
        "medianAge": "21"
      }
    },
    "title": "Physical scientists, all other",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "158",
        "women": "34.5",
        "white": "6.8",
        "black": "22.5",
        "asian": "3.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "132",
        "median": "1,517",
        "maleWorkers": "88",
        "maleMedian": "1,597",
        "femaleWorkers": "44",
        "femaleMedian": "-"
      }
    }
  },
  "Economists": {
    "ageData": {
      "2013": {
        "total": "34",
        "16to19": "0",
        "20to24": "0",
        "25to34": "7",
        "35to44": "12",
        "45to54": "6",
        "55to64": "6",
        "65+": "3",
        "medianAge": "6"
      }
    },
    "title": "Economists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "34",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "29",
        "median": "-",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "14",
        "femaleMedian": "-"
      }
    }
  },
  "Survey researchers": {
    "ageData": {
      "2013": {
        "total": "3",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "1",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Survey researchers",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Psychologists": {
    "ageData": {
      "2013": {
        "total": "186",
        "16to19": "1",
        "20to24": "2",
        "25to34": "32",
        "35to44": "41",
        "45to54": "37",
        "55to64": "49",
        "65+": "25",
        "medianAge": "49"
      }
    },
    "title": "Psychologists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "186",
        "women": "73.8",
        "white": "3.6",
        "black": "2.5",
        "asian": "8.6"
      }
    },
    "wageData": {
      "2013": {
        "workers": "96",
        "median": "1,267",
        "maleWorkers": "27",
        "maleMedian": "-",
        "femaleWorkers": "69",
        "femaleMedian": "1,178"
      }
    }
  },
  "Sociologists": {
    "ageData": {
      "2013": {
        "total": "5",
        "16to19": "0",
        "20to24": "0",
        "25to34": "5",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Sociologists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Urban and regional planners": {
    "ageData": {
      "2013": {
        "total": "20",
        "16to19": "0",
        "20to24": "0",
        "25to34": "5",
        "35to44": "8",
        "45to54": "2",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Urban and regional planners",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "20",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "20",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous social scientists and related workers": {
    "ageData": {
      "2013": {
        "total": "57",
        "16to19": "0",
        "20to24": "3",
        "25to34": "14",
        "35to44": "15",
        "45to54": "10",
        "55to64": "9",
        "65+": "6",
        "medianAge": "9"
      }
    },
    "title": "Miscellaneous social scientists and related workers",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "57",
        "women": "52.7",
        "white": "11.3",
        "black": "3.8",
        "asian": "7.0"
      }
    },
    "wageData": {
      "2013": {
        "workers": "40",
        "median": "-",
        "maleWorkers": "22",
        "maleMedian": "-",
        "femaleWorkers": "18",
        "femaleMedian": "-"
      }
    }
  },
  "Agricultural and food science technicians": {
    "ageData": {
      "2013": {
        "total": "28",
        "16to19": "1",
        "20to24": "3",
        "25to34": "13",
        "35to44": "2",
        "45to54": "3",
        "55to64": "6",
        "65+": "0",
        "medianAge": "6"
      }
    },
    "title": "Agricultural and food science technicians",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "28",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "23",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Biological technicians": {
    "ageData": {
      "2013": {
        "total": "21",
        "16to19": "0",
        "20to24": "1",
        "25to34": "7",
        "35to44": "4",
        "45to54": "6",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Biological technicians",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "21",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "16",
        "median": "-",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Chemical technicians": {
    "ageData": {
      "2013": {
        "total": "61",
        "16to19": "0",
        "20to24": "3",
        "25to34": "17",
        "35to44": "10",
        "45to54": "12",
        "55to64": "14",
        "65+": "4",
        "medianAge": "14"
      }
    },
    "title": "Chemical technicians",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "61",
        "women": "41.3",
        "white": "16.3",
        "black": "6.3",
        "asian": "9.7"
      }
    },
    "wageData": {
      "2013": {
        "workers": "62",
        "median": "849",
        "maleWorkers": "41",
        "maleMedian": "-",
        "femaleWorkers": "21",
        "femaleMedian": "-"
      }
    }
  },
  "Geological and petroleum technicians": {
    "ageData": {
      "2013": {
        "total": "22",
        "16to19": "0",
        "20to24": "2",
        "25to34": "7",
        "35to44": "3",
        "45to54": "5",
        "55to64": "5",
        "65+": "1",
        "medianAge": "5"
      }
    },
    "title": "Geological and petroleum technicians",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "22",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "23",
        "median": "-",
        "maleWorkers": "18",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Nuclear technicians": {
    "ageData": {
      "2013": {
        "total": "1",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "1",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Nuclear technicians",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Social science research assistants": {
    "ageData": {
      "2013": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "0",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Social science research assistants",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous life, physical, and social science technicians": {
    "ageData": {
      "2013": {
        "total": "133",
        "16to19": "2",
        "20to24": "30",
        "25to34": "34",
        "35to44": "22",
        "45to54": "20",
        "55to64": "20",
        "65+": "2",
        "medianAge": "20"
      }
    },
    "title": "Miscellaneous life, physical, and social science technicians",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "133",
        "women": "49.3",
        "white": "4.0",
        "black": "11.1",
        "asian": "10.0"
      }
    },
    "wageData": {
      "2013": {
        "workers": "105",
        "median": "761",
        "maleWorkers": "59",
        "maleMedian": "911",
        "femaleWorkers": "47",
        "femaleMedian": "-"
      }
    }
  },
  "Community and social service occupations": {
    "ageData": {
      "2013": {
        "total": "2,332",
        "16to19": "24",
        "20to24": "117",
        "25to34": "551",
        "35to44": "546",
        "45to54": "482",
        "55to64": "439",
        "65+": "173",
        "medianAge": "439"
      }
    },
    "title": "Community and social service occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "2,332",
        "women": "62.3",
        "white": "18.6",
        "black": "2.9",
        "asian": "10.0"
      }
    },
    "wageData": {
      "2013": {
        "workers": "1,913",
        "median": "847",
        "maleWorkers": "755",
        "maleMedian": "930",
        "femaleWorkers": "1,158",
        "femaleMedian": "808"
      }
    }
  },
  "Counselors": {
    "ageData": {
      "2013": {
        "total": "727",
        "16to19": "11",
        "20to24": "35",
        "25to34": "181",
        "35to44": "171",
        "45to54": "152",
        "55to64": "130",
        "65+": "46",
        "medianAge": "130"
      }
    },
    "title": "Counselors",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "727",
        "women": "69.3",
        "white": "20.4",
        "black": "1.9",
        "asian": "9.1"
      }
    },
    "wageData": {
      "2013": {
        "workers": "568",
        "median": "885",
        "maleWorkers": "178",
        "maleMedian": "889",
        "femaleWorkers": "391",
        "femaleMedian": "884"
      }
    }
  },
  "Social workers": {
    "ageData": {
      "2013": {
        "total": "727",
        "16to19": "1",
        "20to24": "36",
        "25to34": "203",
        "35to44": "195",
        "45to54": "150",
        "55to64": "111",
        "65+": "32",
        "medianAge": "111"
      }
    },
    "title": "Social workers",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "727",
        "women": "80.3",
        "white": "21.9",
        "black": "3.1",
        "asian": "13.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "636",
        "median": "845",
        "maleWorkers": "129",
        "maleMedian": "978",
        "femaleWorkers": "507",
        "femaleMedian": "818"
      }
    }
  },
  "Probation officers and correctional treatment specialists": {
    "ageData": {
      "2013": {
        "total": "105",
        "16to19": "0",
        "20to24": "2",
        "25to34": "25",
        "35to44": "39",
        "45to54": "28",
        "55to64": "9",
        "65+": "1",
        "medianAge": "9"
      }
    },
    "title": "Probation officers and correctional treatment specialists",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "105",
        "women": "45.7",
        "white": "21.8",
        "black": "1.3",
        "asian": "12.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "105",
        "median": "888",
        "maleWorkers": "62",
        "maleMedian": "927",
        "femaleWorkers": "43",
        "femaleMedian": "-"
      }
    }
  },
  "Social and human service assistants": {
    "ageData": {
      "2013": {
        "total": "127",
        "16to19": "4",
        "20to24": "14",
        "25to34": "30",
        "35to44": "26",
        "45to54": "21",
        "55to64": "26",
        "65+": "7",
        "medianAge": "26"
      }
    },
    "title": "Social and human service assistants",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "127",
        "women": "73.2",
        "white": "27.7",
        "black": "0.8",
        "asian": "14.2"
      }
    },
    "wageData": {
      "2013": {
        "workers": "102",
        "median": "668",
        "maleWorkers": "34",
        "maleMedian": "-",
        "femaleWorkers": "68",
        "femaleMedian": "641"
      }
    }
  },
  "Miscellaneous community and social service specialists, including health educators and community health workers": {
    "ageData": {
      "2013": {
        "total": "106",
        "16to19": "2",
        "20to24": "7",
        "25to34": "24",
        "35to44": "27",
        "45to54": "22",
        "55to64": "20",
        "65+": "4",
        "medianAge": "20"
      }
    },
    "title": "Miscellaneous community and social service specialists, including health educators and community health workers",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "106",
        "women": "73.5",
        "white": "19.8",
        "black": "1.1",
        "asian": "6.8"
      }
    },
    "wageData": {
      "2013": {
        "workers": "83",
        "median": "756",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "64",
        "femaleMedian": "740"
      }
    }
  },
  "Clergy": {
    "ageData": {
      "2013": {
        "total": "410",
        "16to19": "2",
        "20to24": "12",
        "25to34": "61",
        "35to44": "67",
        "45to54": "91",
        "55to64": "113",
        "65+": "64",
        "medianAge": "113"
      }
    },
    "title": "Clergy",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "410",
        "women": "15.5",
        "white": "9.1",
        "black": "6.5",
        "asian": "6.4"
      }
    },
    "wageData": {
      "2013": {
        "workers": "343",
        "median": "935",
        "maleWorkers": "296",
        "maleMedian": "968",
        "femaleWorkers": "47",
        "femaleMedian": "-"
      }
    }
  },
  "Directors, religious activities and education": {
    "ageData": {
      "2013": {
        "total": "57",
        "16to19": "0",
        "20to24": "5",
        "25to34": "13",
        "35to44": "11",
        "45to54": "11",
        "55to64": "13",
        "65+": "4",
        "medianAge": "13"
      }
    },
    "title": "Directors, religious activities and education",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "57",
        "women": "64.8",
        "white": "3.7",
        "black": "1.0",
        "asian": "1.1"
      }
    },
    "wageData": {
      "2013": {
        "workers": "41",
        "median": "-",
        "maleWorkers": "20",
        "maleMedian": "-",
        "femaleWorkers": "21",
        "femaleMedian": "-"
      }
    }
  },
  "Religious workers, all other": {
    "ageData": {
      "2013": {
        "total": "74",
        "16to19": "4",
        "20to24": "5",
        "25to34": "14",
        "35to44": "11",
        "45to54": "9",
        "55to64": "17",
        "65+": "14",
        "medianAge": "17"
      }
    },
    "title": "Religious workers, all other",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "74",
        "women": "63.1",
        "white": "9.3",
        "black": "1.4",
        "asian": "6.7"
      }
    },
    "wageData": {
      "2013": {
        "workers": "34",
        "median": "-",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "17",
        "femaleMedian": "-"
      }
    }
  },
  "Legal occupations": {
    "ageData": {
      "2013": {
        "total": "1,809",
        "16to19": "6",
        "20to24": "56",
        "25to34": "367",
        "35to44": "419",
        "45to54": "446",
        "55to64": "372",
        "65+": "144",
        "medianAge": "372"
      }
    },
    "title": "Legal occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "1,809",
        "women": "50.8",
        "white": "6.2",
        "black": "4.9",
        "asian": "8.2"
      }
    },
    "wageData": {
      "2013": {
        "workers": "1,305",
        "median": "1,253",
        "maleWorkers": "598",
        "maleMedian": "1,764",
        "femaleWorkers": "707",
        "femaleMedian": "1,010"
      }
    }
  },
  "Lawyers": {
    "ageData": {
      "2013": {
        "total": "1,092",
        "16to19": "1",
        "20to24": "4",
        "25to34": "205",
        "35to44": "279",
        "45to54": "258",
        "55to64": "232",
        "65+": "113",
        "medianAge": "232"
      }
    },
    "title": "Lawyers",
    "depth": 4,
    "parent": "Legal occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "1,092",
        "women": "33.1",
        "white": "4.2",
        "black": "5.1",
        "asian": "5.1"
      }
    },
    "wageData": {
      "2013": {
        "workers": "710",
        "median": "1,880",
        "maleWorkers": "463",
        "maleMedian": "1,986",
        "femaleWorkers": "247",
        "femaleMedian": "1,566"
      }
    }
  },
  "Judicial law clerks": {
    "ageData": {
      "2013": {
        "total": "13",
        "16to19": "0",
        "20to24": "2",
        "25to34": "4",
        "35to44": "2",
        "45to54": "2",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Judicial law clerks",
    "depth": 4,
    "parent": "Legal occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "13",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "9",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Judges, magistrates, and other judicial workers": {
    "ageData": {
      "2013": {
        "total": "55",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "10",
        "45to54": "13",
        "55to64": "20",
        "65+": "10",
        "medianAge": "20"
      }
    },
    "title": "Judges, magistrates, and other judicial workers",
    "depth": 4,
    "parent": "Legal occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "55",
        "women": "35.6",
        "white": "7.8",
        "black": "0.1",
        "asian": "6.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "49",
        "median": "-",
        "maleWorkers": "30",
        "maleMedian": "-",
        "femaleWorkers": "19",
        "femaleMedian": "-"
      }
    }
  },
  "Paralegals and legal assistants": {
    "ageData": {
      "2013": {
        "total": "404",
        "16to19": "2",
        "20to24": "36",
        "25to34": "101",
        "35to44": "83",
        "45to54": "101",
        "55to64": "72",
        "65+": "9",
        "medianAge": "72"
      }
    },
    "title": "Paralegals and legal assistants",
    "depth": 4,
    "parent": "Legal occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "404",
        "women": "86.1",
        "white": "9.5",
        "black": "4.5",
        "asian": "15.2"
      }
    },
    "wageData": {
      "2013": {
        "workers": "337",
        "median": "846",
        "maleWorkers": "50",
        "maleMedian": "923",
        "femaleWorkers": "287",
        "femaleMedian": "825"
      }
    }
  },
  "Miscellaneous legal support workers": {
    "ageData": {
      "2013": {
        "total": "244",
        "16to19": "3",
        "20to24": "14",
        "25to34": "54",
        "35to44": "45",
        "45to54": "73",
        "55to64": "45",
        "65+": "11",
        "medianAge": "45"
      }
    },
    "title": "Miscellaneous legal support workers",
    "depth": 4,
    "parent": "Legal occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "244",
        "women": "74.6",
        "white": "9.5",
        "black": "5.6",
        "asian": "11.4"
      }
    },
    "wageData": {
      "2013": {
        "workers": "199",
        "median": "873",
        "maleWorkers": "51",
        "maleMedian": "1,013",
        "femaleWorkers": "148",
        "femaleMedian": "787"
      }
    }
  },
  "Education, training, and library occupations": {
    "ageData": {
      "2013": {
        "total": "8,623",
        "16to19": "90",
        "20to24": "579",
        "25to34": "2,029",
        "35to44": "2,008",
        "45to54": "1,879",
        "55to64": "1,529",
        "65+": "509",
        "medianAge": "1,529"
      }
    },
    "title": "Education, training, and library occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "8,623",
        "women": "73.8",
        "white": "9.4",
        "black": "4.5",
        "asian": "9.6"
      }
    },
    "wageData": {
      "2013": {
        "workers": "6,589",
        "median": "937",
        "maleWorkers": "1,808",
        "maleMedian": "1,091",
        "femaleWorkers": "4,782",
        "femaleMedian": "888"
      }
    }
  },
  "Postsecondary teachers": {
    "ageData": {
      "2013": {
        "total": "1,313",
        "16to19": "5",
        "20to24": "100",
        "25to34": "285",
        "35to44": "255",
        "45to54": "248",
        "55to64": "269",
        "65+": "152",
        "medianAge": "269"
      }
    },
    "title": "Postsecondary teachers",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "1,313",
        "women": "50.2",
        "white": "6.8",
        "black": "13.0",
        "asian": "5.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "893",
        "median": "1,172",
        "maleWorkers": "469",
        "maleMedian": "1,338",
        "femaleWorkers": "424",
        "femaleMedian": "1,100"
      }
    }
  },
  "Preschool and kindergarten teachers": {
    "ageData": {
      "2013": {
        "total": "695",
        "16to19": "8",
        "20to24": "75",
        "25to34": "195",
        "35to44": "160",
        "45to54": "143",
        "55to64": "91",
        "65+": "23",
        "medianAge": "91"
      }
    },
    "title": "Preschool and kindergarten teachers",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "695",
        "women": "97.8",
        "white": "12.1",
        "black": "4.5",
        "asian": "12.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "496",
        "median": "638",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "484",
        "femaleMedian": "624"
      }
    }
  },
  "Elementary and middle school teachers": {
    "ageData": {
      "2013": {
        "total": "3,038",
        "16to19": "6",
        "20to24": "132",
        "25to34": "743",
        "35to44": "838",
        "45to54": "683",
        "55to64": "506",
        "65+": "130",
        "medianAge": "506"
      }
    },
    "title": "Elementary and middle school teachers",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "3,038",
        "women": "81.0",
        "white": "9.4",
        "black": "2.0",
        "asian": "9.8"
      }
    },
    "wageData": {
      "2013": {
        "workers": "2,669",
        "median": "954",
        "maleWorkers": "531",
        "maleMedian": "1,025",
        "femaleWorkers": "2,138",
        "femaleMedian": "937"
      }
    }
  },
  "Secondary school teachers": {
    "ageData": {
      "2013": {
        "total": "1,063",
        "16to19": "2",
        "20to24": "45",
        "25to34": "303",
        "35to44": "258",
        "45to54": "229",
        "55to64": "188",
        "65+": "36",
        "medianAge": "188"
      }
    },
    "title": "Secondary school teachers",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "1,063",
        "women": "56.7",
        "white": "6.6",
        "black": "2.8",
        "asian": "7.1"
      }
    },
    "wageData": {
      "2013": {
        "workers": "956",
        "median": "1,031",
        "maleWorkers": "427",
        "maleMedian": "1,093",
        "femaleWorkers": "529",
        "femaleMedian": "986"
      }
    }
  },
  "Special education teachers": {
    "ageData": {
      "2013": {
        "total": "377",
        "16to19": "3",
        "20to24": "11",
        "25to34": "99",
        "35to44": "96",
        "45to54": "95",
        "55to64": "64",
        "65+": "10",
        "medianAge": "64"
      }
    },
    "title": "Special education teachers",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "377",
        "women": "80.4",
        "white": "9.9",
        "black": "2.8",
        "asian": "8.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "337",
        "median": "951",
        "maleWorkers": "65",
        "maleMedian": "977",
        "femaleWorkers": "271",
        "femaleMedian": "944"
      }
    }
  },
  "Other teachers and instructors": {
    "ageData": {
      "2013": {
        "total": "753",
        "16to19": "32",
        "20to24": "107",
        "25to34": "159",
        "35to44": "136",
        "45to54": "117",
        "55to64": "130",
        "65+": "71",
        "medianAge": "130"
      }
    },
    "title": "Other teachers and instructors",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "753",
        "women": "63.8",
        "white": "8.6",
        "black": "5.5",
        "asian": "9.1"
      }
    },
    "wageData": {
      "2013": {
        "workers": "336",
        "median": "905",
        "maleWorkers": "149",
        "maleMedian": "1,055",
        "femaleWorkers": "187",
        "femaleMedian": "729"
      }
    }
  },
  "Archivists, curators, and museum technicians": {
    "ageData": {
      "2013": {
        "total": "41",
        "16to19": "0",
        "20to24": "1",
        "25to34": "10",
        "35to44": "6",
        "45to54": "11",
        "55to64": "8",
        "65+": "5",
        "medianAge": "8"
      }
    },
    "title": "Archivists, curators, and museum technicians",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "41",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "33",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "20",
        "femaleMedian": "-"
      }
    }
  },
  "Librarians": {
    "ageData": {
      "2013": {
        "total": "194",
        "16to19": "2",
        "20to24": "7",
        "25to34": "27",
        "35to44": "35",
        "45to54": "54",
        "55to64": "53",
        "65+": "17",
        "medianAge": "53"
      }
    },
    "title": "Librarians",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "194",
        "women": "84.1",
        "white": "7.7",
        "black": "2.1",
        "asian": "5.1"
      }
    },
    "wageData": {
      "2013": {
        "workers": "149",
        "median": "919",
        "maleWorkers": "28",
        "maleMedian": "-",
        "femaleWorkers": "121",
        "femaleMedian": "905"
      }
    }
  },
  "Library technicians": {
    "ageData": {
      "2013": {
        "total": "39",
        "16to19": "4",
        "20to24": "9",
        "25to34": "6",
        "35to44": "7",
        "45to54": "5",
        "55to64": "7",
        "65+": "1",
        "medianAge": "7"
      }
    },
    "title": "Library technicians",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "39",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "18",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "14",
        "femaleMedian": "-"
      }
    }
  },
  "Teacher assistants": {
    "ageData": {
      "2013": {
        "total": "918",
        "16to19": "26",
        "20to24": "80",
        "25to34": "164",
        "35to44": "178",
        "45to54": "255",
        "55to64": "169",
        "65+": "47",
        "medianAge": "169"
      }
    },
    "title": "Teacher assistants",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "918",
        "women": "89.2",
        "white": "14.5",
        "black": "3.7",
        "asian": "16.6"
      }
    },
    "wageData": {
      "2013": {
        "workers": "571",
        "median": "479",
        "maleWorkers": "70",
        "maleMedian": "501",
        "femaleWorkers": "501",
        "femaleMedian": "475"
      }
    }
  },
  "Other education, training, and library workers": {
    "ageData": {
      "2013": {
        "total": "192",
        "16to19": "2",
        "20to24": "13",
        "25to34": "37",
        "35to44": "38",
        "45to54": "40",
        "55to64": "46",
        "65+": "17",
        "medianAge": "46"
      }
    },
    "title": "Other education, training, and library workers",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "192",
        "women": "72.4",
        "white": "14.3",
        "black": "2.8",
        "asian": "8.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "131",
        "median": "1,031",
        "maleWorkers": "39",
        "maleMedian": "-",
        "femaleWorkers": "91",
        "femaleMedian": "992"
      }
    }
  },
  "Arts, design, entertainment, sports, and media occupations": {
    "ageData": {
      "2013": {
        "total": "2,879",
        "16to19": "77",
        "20to24": "300",
        "25to34": "752",
        "35to44": "594",
        "45to54": "537",
        "55to64": "435",
        "65+": "183",
        "medianAge": "435"
      }
    },
    "title": "Arts, design, entertainment, sports, and media occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "2,879",
        "women": "47.2",
        "white": "6.5",
        "black": "5.0",
        "asian": "8.4"
      }
    },
    "wageData": {
      "2013": {
        "workers": "1,510",
        "median": "988",
        "maleWorkers": "861",
        "maleMedian": "1,118",
        "femaleWorkers": "649",
        "femaleMedian": "884"
      }
    }
  },
  "Artists and related workers": {
    "ageData": {
      "2013": {
        "total": "194",
        "16to19": "2",
        "20to24": "7",
        "25to34": "43",
        "35to44": "32",
        "45to54": "34",
        "55to64": "48",
        "65+": "27",
        "medianAge": "48"
      }
    },
    "title": "Artists and related workers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "194",
        "women": "56.4",
        "white": "1.8",
        "black": "5.6",
        "asian": "5.1"
      }
    },
    "wageData": {
      "2013": {
        "workers": "63",
        "median": "1,032",
        "maleWorkers": "38",
        "maleMedian": "-",
        "femaleWorkers": "25",
        "femaleMedian": "-"
      }
    }
  },
  "Designers": {
    "ageData": {
      "2013": {
        "total": "784",
        "16to19": "5",
        "20to24": "64",
        "25to34": "214",
        "35to44": "179",
        "45to54": "170",
        "55to64": "111",
        "65+": "41",
        "medianAge": "111"
      }
    },
    "title": "Designers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "784",
        "women": "53.5",
        "white": "5.4",
        "black": "6.9",
        "asian": "9.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "514",
        "median": "961",
        "maleWorkers": "270",
        "maleMedian": "1,095",
        "femaleWorkers": "244",
        "femaleMedian": "866"
      }
    }
  },
  "Actors": {
    "ageData": {
      "2013": {
        "total": "46",
        "16to19": "0",
        "20to24": "13",
        "25to34": "10",
        "35to44": "10",
        "45to54": "10",
        "55to64": "1",
        "65+": "2",
        "medianAge": "1"
      }
    },
    "title": "Actors",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "46",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "18",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Producers and directors": {
    "ageData": {
      "2013": {
        "total": "150",
        "16to19": "0",
        "20to24": "9",
        "25to34": "46",
        "35to44": "35",
        "45to54": "33",
        "55to64": "24",
        "65+": "4",
        "medianAge": "24"
      }
    },
    "title": "Producers and directors",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "150",
        "women": "37.3",
        "white": "6.3",
        "black": "1.9",
        "asian": "7.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "104",
        "median": "1,166",
        "maleWorkers": "66",
        "maleMedian": "1,331",
        "femaleWorkers": "38",
        "femaleMedian": "-"
      }
    }
  },
  "Athletes, coaches, umpires, and related workers": {
    "ageData": {
      "2013": {
        "total": "288",
        "16to19": "44",
        "20to24": "66",
        "25to34": "57",
        "35to44": "40",
        "45to54": "40",
        "55to64": "28",
        "65+": "12",
        "medianAge": "28"
      }
    },
    "title": "Athletes, coaches, umpires, and related workers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "288",
        "women": "34.8",
        "white": "7.4",
        "black": "2.1",
        "asian": "5.5"
      }
    },
    "wageData": {
      "2013": {
        "workers": "115",
        "median": "889",
        "maleWorkers": "86",
        "maleMedian": "934",
        "femaleWorkers": "29",
        "femaleMedian": "-"
      }
    }
  },
  "Dancers and choreographers": {
    "ageData": {
      "2013": {
        "total": "19",
        "16to19": "1",
        "20to24": "6",
        "25to34": "6",
        "35to44": "5",
        "45to54": "1",
        "55to64": "0",
        "65+": "1",
        "medianAge": "0"
      }
    },
    "title": "Dancers and choreographers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "19",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Musicians, singers, and related workers": {
    "ageData": {
      "2013": {
        "total": "202",
        "16to19": "10",
        "20to24": "11",
        "25to34": "51",
        "35to44": "36",
        "45to54": "30",
        "55to64": "45",
        "65+": "18",
        "medianAge": "45"
      }
    },
    "title": "Musicians, singers, and related workers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "202",
        "women": "29.6",
        "white": "12.2",
        "black": "3.3",
        "asian": "5.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "46",
        "median": "-",
        "maleWorkers": "33",
        "maleMedian": "-",
        "femaleWorkers": "13",
        "femaleMedian": "-"
      }
    }
  },
  "Entertainers and performers, sports and related workers, all other": {
    "ageData": {
      "2013": {
        "total": "35",
        "16to19": "2",
        "20to24": "3",
        "25to34": "12",
        "35to44": "5",
        "45to54": "5",
        "55to64": "8",
        "65+": "1",
        "medianAge": "8"
      }
    },
    "title": "Entertainers and performers, sports and related workers, all other",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "35",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "9",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Announcers": {
    "ageData": {
      "2013": {
        "total": "48",
        "16to19": "0",
        "20to24": "7",
        "25to34": "13",
        "35to44": "12",
        "45to54": "9",
        "55to64": "2",
        "65+": "5",
        "medianAge": "2"
      }
    },
    "title": "Announcers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "48",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "18",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "News analysts, reporters and correspondents": {
    "ageData": {
      "2013": {
        "total": "88",
        "16to19": "0",
        "20to24": "10",
        "25to34": "28",
        "35to44": "15",
        "45to54": "10",
        "55to64": "18",
        "65+": "8",
        "medianAge": "18"
      }
    },
    "title": "News analysts, reporters and correspondents",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "88",
        "women": "38.5",
        "white": "7.8",
        "black": "1.0",
        "asian": "6.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "68",
        "median": "1,041",
        "maleWorkers": "46",
        "maleMedian": "-",
        "femaleWorkers": "22",
        "femaleMedian": "-"
      }
    }
  },
  "Public relations specialists": {
    "ageData": {
      "2013": {
        "total": "149",
        "16to19": "1",
        "20to24": "14",
        "25to34": "48",
        "35to44": "37",
        "45to54": "22",
        "55to64": "22",
        "65+": "5",
        "medianAge": "22"
      }
    },
    "title": "Public relations specialists",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "149",
        "women": "63.3",
        "white": "7.1",
        "black": "4.2",
        "asian": "5.7"
      }
    },
    "wageData": {
      "2013": {
        "workers": "109",
        "median": "1,129",
        "maleWorkers": "41",
        "maleMedian": "-",
        "femaleWorkers": "68",
        "femaleMedian": "921"
      }
    }
  },
  "Editors": {
    "ageData": {
      "2013": {
        "total": "150",
        "16to19": "3",
        "20to24": "24",
        "25to34": "34",
        "35to44": "30",
        "45to54": "30",
        "55to64": "22",
        "65+": "7",
        "medianAge": "22"
      }
    },
    "title": "Editors",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "150",
        "women": "54.6",
        "white": "2.8",
        "black": "4.3",
        "asian": "4.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "112",
        "median": "1,092",
        "maleWorkers": "53",
        "maleMedian": "1,239",
        "femaleWorkers": "59",
        "femaleMedian": "988"
      }
    }
  },
  "Technical writers": {
    "ageData": {
      "2013": {
        "total": "70",
        "16to19": "0",
        "20to24": "2",
        "25to34": "13",
        "35to44": "18",
        "45to54": "20",
        "55to64": "14",
        "65+": "3",
        "medianAge": "14"
      }
    },
    "title": "Technical writers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "70",
        "women": "55.5",
        "white": "9.6",
        "black": "3.7",
        "asian": "3.4"
      }
    },
    "wageData": {
      "2013": {
        "workers": "57",
        "median": "1,364",
        "maleWorkers": "27",
        "maleMedian": "-",
        "femaleWorkers": "30",
        "femaleMedian": "-"
      }
    }
  },
  "Writers and authors": {
    "ageData": {
      "2013": {
        "total": "209",
        "16to19": "0",
        "20to24": "14",
        "25to34": "52",
        "35to44": "35",
        "45to54": "47",
        "55to64": "34",
        "65+": "27",
        "medianAge": "34"
      }
    },
    "title": "Writers and authors",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "209",
        "women": "57.8",
        "white": "4.8",
        "black": "3.9",
        "asian": "4.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "79",
        "median": "996",
        "maleWorkers": "39",
        "maleMedian": "-",
        "femaleWorkers": "40",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous media and communication workers": {
    "ageData": {
      "2013": {
        "total": "90",
        "16to19": "1",
        "20to24": "6",
        "25to34": "24",
        "35to44": "24",
        "45to54": "17",
        "55to64": "13",
        "65+": "4",
        "medianAge": "13"
      }
    },
    "title": "Miscellaneous media and communication workers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "90",
        "women": "76.6",
        "white": "6.4",
        "black": "12.0",
        "asian": "31.5"
      }
    },
    "wageData": {
      "2013": {
        "workers": "32",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "24",
        "femaleMedian": "-"
      }
    }
  },
  "Broadcast and sound engineering technicians and radio operators": {
    "ageData": {
      "2013": {
        "total": "99",
        "16to19": "2",
        "20to24": "8",
        "25to34": "34",
        "35to44": "18",
        "45to54": "22",
        "55to64": "12",
        "65+": "2",
        "medianAge": "12"
      }
    },
    "title": "Broadcast and sound engineering technicians and radio operators",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "99",
        "women": "9.4",
        "white": "8.2",
        "black": "5.1",
        "asian": "15.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "68",
        "median": "947",
        "maleWorkers": "61",
        "maleMedian": "1,029",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Photographers": {
    "ageData": {
      "2013": {
        "total": "180",
        "16to19": "5",
        "20to24": "19",
        "25to34": "46",
        "35to44": "46",
        "45to54": "26",
        "55to64": "26",
        "65+": "11",
        "medianAge": "26"
      }
    },
    "title": "Photographers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "180",
        "women": "50.0",
        "white": "6.4",
        "black": "4.6",
        "asian": "8.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "48",
        "median": "-",
        "maleWorkers": "31",
        "maleMedian": "-",
        "femaleWorkers": "17",
        "femaleMedian": "-"
      }
    }
  },
  "Television, video, and motion picture camera operators and editors": {
    "ageData": {
      "2013": {
        "total": "76",
        "16to19": "2",
        "20to24": "16",
        "25to34": "23",
        "35to44": "16",
        "45to54": "9",
        "55to64": "6",
        "65+": "5",
        "medianAge": "6"
      }
    },
    "title": "Television, video, and motion picture camera operators and editors",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "76",
        "women": "15.9",
        "white": "8.7",
        "black": "6.5",
        "asian": "10.7"
      }
    },
    "wageData": {
      "2013": {
        "workers": "45",
        "median": "-",
        "maleWorkers": "38",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Media and communication equipment workers, all other": {
    "ageData": {
      "2013": {
        "total": "1",
        "16to19": "0",
        "20to24": "1",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Media and communication equipment workers, all other",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Healthcare practitioners and technical occupations": {
    "ageData": {
      "2013": {
        "total": "8,182",
        "16to19": "22",
        "20to24": "408",
        "25to34": "2,000",
        "35to44": "2,010",
        "45to54": "1,828",
        "55to64": "1,512",
        "65+": "403",
        "medianAge": "1,512"
      }
    },
    "title": "Healthcare practitioners and technical occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "8,182",
        "women": "74.4",
        "white": "10.5",
        "black": "9.3",
        "asian": "7.4"
      }
    },
    "wageData": {
      "2013": {
        "workers": "6,142",
        "median": "1,048",
        "maleWorkers": "1,558",
        "maleMedian": "1,312",
        "femaleWorkers": "4,585",
        "femaleMedian": "994"
      }
    }
  },
  "Chiropractors": {
    "ageData": {
      "2013": {
        "total": "58",
        "16to19": "0",
        "20to24": "0",
        "25to34": "10",
        "35to44": "19",
        "45to54": "15",
        "55to64": "7",
        "65+": "6",
        "medianAge": "7"
      }
    },
    "title": "Chiropractors",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "58",
        "women": "18.6",
        "white": "0.0",
        "black": "2.3",
        "asian": "2.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Dentists": {
    "ageData": {
      "2013": {
        "total": "183",
        "16to19": "0",
        "20to24": "2",
        "25to34": "28",
        "35to44": "46",
        "45to54": "47",
        "55to64": "42",
        "65+": "18",
        "medianAge": "42"
      }
    },
    "title": "Dentists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "183",
        "women": "30.8",
        "white": "4.7",
        "black": "13.1",
        "asian": "7.2"
      }
    },
    "wageData": {
      "2013": {
        "workers": "55",
        "median": "1,533",
        "maleWorkers": "37",
        "maleMedian": "-",
        "femaleWorkers": "18",
        "femaleMedian": "-"
      }
    }
  },
  "Dietitians and nutritionists": {
    "ageData": {
      "2013": {
        "total": "110",
        "16to19": "0",
        "20to24": "7",
        "25to34": "23",
        "35to44": "25",
        "45to54": "25",
        "55to64": "22",
        "65+": "8",
        "medianAge": "22"
      }
    },
    "title": "Dietitians and nutritionists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "110",
        "women": "89.7",
        "white": "14.6",
        "black": "5.3",
        "asian": "8.1"
      }
    },
    "wageData": {
      "2013": {
        "workers": "82",
        "median": "885",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "71",
        "femaleMedian": "864"
      }
    }
  },
  "Optometrists": {
    "ageData": {
      "2013": {
        "total": "41",
        "16to19": "0",
        "20to24": "0",
        "25to34": "6",
        "35to44": "8",
        "45to54": "9",
        "55to64": "15",
        "65+": "3",
        "medianAge": "15"
      }
    },
    "title": "Optometrists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "41",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "14",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Pharmacists": {
    "ageData": {
      "2013": {
        "total": "277",
        "16to19": "0",
        "20to24": "11",
        "25to34": "75",
        "35to44": "73",
        "45to54": "55",
        "55to64": "45",
        "65+": "18",
        "medianAge": "45"
      }
    },
    "title": "Pharmacists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "277",
        "women": "56.0",
        "white": "5.4",
        "black": "22.0",
        "asian": "4.4"
      }
    },
    "wageData": {
      "2013": {
        "workers": "215",
        "median": "1,960",
        "maleWorkers": "98",
        "maleMedian": "2,092",
        "femaleWorkers": "117",
        "femaleMedian": "1,802"
      }
    }
  },
  "Physicians and surgeons": {
    "ageData": {
      "2013": {
        "total": "934",
        "16to19": "0",
        "20to24": "5",
        "25to34": "192",
        "35to44": "243",
        "45to54": "213",
        "55to64": "170",
        "65+": "111",
        "medianAge": "170"
      }
    },
    "title": "Physicians and surgeons",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "934",
        "women": "35.5",
        "white": "6.4",
        "black": "21.6",
        "asian": "3.8"
      }
    },
    "wageData": {
      "2013": {
        "workers": "667",
        "median": "1,885",
        "maleWorkers": "426",
        "maleMedian": "2,087",
        "femaleWorkers": "241",
        "femaleMedian": "1,497"
      }
    }
  },
  "Physician assistants": {
    "ageData": {
      "2013": {
        "total": "129",
        "16to19": "1",
        "20to24": "7",
        "25to34": "41",
        "35to44": "35",
        "45to54": "22",
        "55to64": "18",
        "65+": "4",
        "medianAge": "18"
      }
    },
    "title": "Physician assistants",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "129",
        "women": "64.6",
        "white": "4.9",
        "black": "4.3",
        "asian": "10.4"
      }
    },
    "wageData": {
      "2013": {
        "workers": "103",
        "median": "1,456",
        "maleWorkers": "36",
        "maleMedian": "-",
        "femaleWorkers": "67",
        "femaleMedian": "1,491"
      }
    }
  },
  "Podiatrists": {
    "ageData": {
      "2013": {
        "total": "12",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "4",
        "45to54": "3",
        "55to64": "1",
        "65+": "2",
        "medianAge": "1"
      }
    },
    "title": "Podiatrists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "12",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Audiologists": {
    "ageData": {
      "2013": {
        "total": "16",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "5",
        "45to54": "3",
        "55to64": "4",
        "65+": "2",
        "medianAge": "4"
      }
    },
    "title": "Audiologists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "16",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "10",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Occupational therapists": {
    "ageData": {
      "2013": {
        "total": "112",
        "16to19": "0",
        "20to24": "3",
        "25to34": "35",
        "35to44": "35",
        "45to54": "24",
        "55to64": "13",
        "65+": "2",
        "medianAge": "13"
      }
    },
    "title": "Occupational therapists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "112",
        "women": "88.4",
        "white": "2.5",
        "black": "5.4",
        "asian": "4.8"
      }
    },
    "wageData": {
      "2013": {
        "workers": "78",
        "median": "1,295",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "68",
        "femaleMedian": "1,240"
      }
    }
  },
  "Physical therapists": {
    "ageData": {
      "2013": {
        "total": "224",
        "16to19": "0",
        "20to24": "7",
        "25to34": "71",
        "35to44": "57",
        "45to54": "50",
        "55to64": "28",
        "65+": "10",
        "medianAge": "28"
      }
    },
    "title": "Physical therapists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "224",
        "women": "60.6",
        "white": "6.5",
        "black": "8.7",
        "asian": "6.5"
      }
    },
    "wageData": {
      "2013": {
        "workers": "161",
        "median": "1,382",
        "maleWorkers": "74",
        "maleMedian": "1,457",
        "femaleWorkers": "87",
        "femaleMedian": "1,300"
      }
    }
  },
  "Radiation therapists": {
    "ageData": {
      "2013": {
        "total": "13",
        "16to19": "0",
        "20to24": "1",
        "25to34": "4",
        "35to44": "4",
        "45to54": "2",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Radiation therapists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "13",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Recreational therapists": {
    "ageData": {
      "2013": {
        "total": "9",
        "16to19": "0",
        "20to24": "1",
        "25to34": "2",
        "35to44": "2",
        "45to54": "4",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Recreational therapists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "9",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "10",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Respiratory therapists": {
    "ageData": {
      "2013": {
        "total": "110",
        "16to19": "0",
        "20to24": "2",
        "25to34": "20",
        "35to44": "25",
        "45to54": "28",
        "55to64": "28",
        "65+": "7",
        "medianAge": "28"
      }
    },
    "title": "Respiratory therapists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "110",
        "women": "60.0",
        "white": "11.6",
        "black": "8.2",
        "asian": "6.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "96",
        "median": "1,095",
        "maleWorkers": "44",
        "maleMedian": "-",
        "femaleWorkers": "52",
        "femaleMedian": "1,059"
      }
    }
  },
  "Speech-language pathologists": {
    "ageData": {
      "2013": {
        "total": "137",
        "16to19": "0",
        "20to24": "2",
        "25to34": "44",
        "35to44": "34",
        "45to54": "26",
        "55to64": "26",
        "65+": "5",
        "medianAge": "26"
      }
    },
    "title": "Speech-language pathologists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "137",
        "women": "93.4",
        "white": "3.2",
        "black": "3.5",
        "asian": "6.7"
      }
    },
    "wageData": {
      "2013": {
        "workers": "98",
        "median": "1,218",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "93",
        "femaleMedian": "1,191"
      }
    }
  },
  "Exercise physiologists": {
    "ageData": {
      "2013": {
        "total": "6",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "2",
        "45to54": "2",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Exercise physiologists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "6",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Therapists, all other": {
    "ageData": {
      "2013": {
        "total": "147",
        "16to19": "0",
        "20to24": "8",
        "25to34": "45",
        "35to44": "34",
        "45to54": "21",
        "55to64": "31",
        "65+": "9",
        "medianAge": "31"
      }
    },
    "title": "Therapists, all other",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "147",
        "women": "79.0",
        "white": "10.5",
        "black": "3.4",
        "asian": "12.7"
      }
    },
    "wageData": {
      "2013": {
        "workers": "96",
        "median": "864",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "77",
        "femaleMedian": "870"
      }
    }
  },
  "Veterinarians": {
    "ageData": {
      "2013": {
        "total": "87",
        "16to19": "1",
        "20to24": "1",
        "25to34": "16",
        "35to44": "26",
        "45to54": "18",
        "55to64": "24",
        "65+": "3",
        "medianAge": "24"
      }
    },
    "title": "Veterinarians",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "87",
        "women": "54.7",
        "white": "1.5",
        "black": "0.6",
        "asian": "7.7"
      }
    },
    "wageData": {
      "2013": {
        "workers": "41",
        "median": "-",
        "maleWorkers": "18",
        "maleMedian": "-",
        "femaleWorkers": "23",
        "femaleMedian": "-"
      }
    }
  },
  "Registered nurses": {
    "ageData": {
      "2013": {
        "total": "2,892",
        "16to19": "2",
        "20to24": "127",
        "25to34": "685",
        "35to44": "710",
        "45to54": "667",
        "55to64": "597",
        "65+": "105",
        "medianAge": "597"
      }
    },
    "title": "Registered nurses",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "2,892",
        "women": "90.1",
        "white": "10.5",
        "black": "8.0",
        "asian": "6.5"
      }
    },
    "wageData": {
      "2013": {
        "workers": "2,278",
        "median": "1,099",
        "maleWorkers": "254",
        "maleMedian": "1,236",
        "femaleWorkers": "2,023",
        "femaleMedian": "1,086"
      }
    }
  },
  "Nurse anesthetists": {
    "ageData": {
      "2013": {
        "total": "24",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "10",
        "45to54": "5",
        "55to64": "6",
        "65+": "0",
        "medianAge": "6"
      }
    },
    "title": "Nurse anesthetists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "24",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "22",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "14",
        "femaleMedian": "-"
      }
    }
  },
  "Nurse midwives": {
    "ageData": {
      "2013": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "1",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Nurse midwives",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Nurse practitioners": {
    "ageData": {
      "2013": {
        "total": "126",
        "16to19": "0",
        "20to24": "0",
        "25to34": "26",
        "35to44": "32",
        "45to54": "36",
        "55to64": "26",
        "65+": "6",
        "medianAge": "26"
      }
    },
    "title": "Nurse practitioners",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "126",
        "women": "91.8",
        "white": "5.8",
        "black": "5.9",
        "asian": "7.1"
      }
    },
    "wageData": {
      "2013": {
        "workers": "102",
        "median": "1,615",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "91",
        "femaleMedian": "1,539"
      }
    }
  },
  "Health diagnosing and treating practitioners, all other": {
    "ageData": {
      "2013": {
        "total": "25",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "7",
        "45to54": "10",
        "55to64": "4",
        "65+": "2",
        "medianAge": "4"
      }
    },
    "title": "Health diagnosing and treating practitioners, all other",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "25",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Clinical laboratory technologists and technicians": {
    "ageData": {
      "2013": {
        "total": "340",
        "16to19": "2",
        "20to24": "19",
        "25to34": "87",
        "35to44": "69",
        "45to54": "75",
        "55to64": "72",
        "65+": "17",
        "medianAge": "72"
      }
    },
    "title": "Clinical laboratory technologists and technicians",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "340",
        "women": "72.9",
        "white": "17.6",
        "black": "12.3",
        "asian": "11.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "307",
        "median": "891",
        "maleWorkers": "81",
        "maleMedian": "980",
        "femaleWorkers": "226",
        "femaleMedian": "858"
      }
    }
  },
  "Dental hygienists": {
    "ageData": {
      "2013": {
        "total": "184",
        "16to19": "0",
        "20to24": "10",
        "25to34": "44",
        "35to44": "46",
        "45to54": "51",
        "55to64": "28",
        "65+": "5",
        "medianAge": "28"
      }
    },
    "title": "Dental hygienists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "184",
        "women": "98.3",
        "white": "4.8",
        "black": "5.3",
        "asian": "5.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "82",
        "median": "1,005",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "80",
        "femaleMedian": "1,011"
      }
    }
  },
  "Diagnostic related technologists and technicians": {
    "ageData": {
      "2013": {
        "total": "362",
        "16to19": "0",
        "20to24": "19",
        "25to34": "107",
        "35to44": "86",
        "45to54": "73",
        "55to64": "70",
        "65+": "7",
        "medianAge": "70"
      }
    },
    "title": "Diagnostic related technologists and technicians",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "362",
        "women": "72.0",
        "white": "8.3",
        "black": "3.7",
        "asian": "8.5"
      }
    },
    "wageData": {
      "2013": {
        "workers": "287",
        "median": "919",
        "maleWorkers": "92",
        "maleMedian": "959",
        "femaleWorkers": "195",
        "femaleMedian": "909"
      }
    }
  },
  "Emergency medical technicians and paramedics": {
    "ageData": {
      "2013": {
        "total": "176",
        "16to19": "2",
        "20to24": "26",
        "25to34": "59",
        "35to44": "50",
        "45to54": "26",
        "55to64": "11",
        "65+": "1",
        "medianAge": "11"
      }
    },
    "title": "Emergency medical technicians and paramedics",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "176",
        "women": "39.2",
        "white": "5.5",
        "black": "2.2",
        "asian": "9.2"
      }
    },
    "wageData": {
      "2013": {
        "workers": "140",
        "median": "796",
        "maleWorkers": "82",
        "maleMedian": "832",
        "femaleWorkers": "58",
        "femaleMedian": "785"
      }
    }
  },
  "Health practitioner support technologists and technicians": {
    "ageData": {
      "2013": {
        "total": "554",
        "16to19": "11",
        "20to24": "91",
        "25to34": "172",
        "35to44": "110",
        "45to54": "98",
        "55to64": "55",
        "65+": "16",
        "medianAge": "55"
      }
    },
    "title": "Health practitioner support technologists and technicians",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "554",
        "women": "80.5",
        "white": "14.6",
        "black": "6.6",
        "asian": "12.7"
      }
    },
    "wageData": {
      "2013": {
        "workers": "416",
        "median": "619",
        "maleWorkers": "78",
        "maleMedian": "664",
        "femaleWorkers": "337",
        "femaleMedian": "613"
      }
    }
  },
  "Licensed practical and licensed vocational nurses": {
    "ageData": {
      "2013": {
        "total": "558",
        "16to19": "2",
        "20to24": "35",
        "25to34": "115",
        "35to44": "143",
        "45to54": "141",
        "55to64": "103",
        "65+": "19",
        "medianAge": "103"
      }
    },
    "title": "Licensed practical and licensed vocational nurses",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "558",
        "women": "91.5",
        "white": "25.2",
        "black": "5.1",
        "asian": "10.6"
      }
    },
    "wageData": {
      "2013": {
        "workers": "444",
        "median": "741",
        "maleWorkers": "40",
        "maleMedian": "-",
        "femaleWorkers": "404",
        "femaleMedian": "732"
      }
    }
  },
  "Medical records and health information technicians": {
    "ageData": {
      "2013": {
        "total": "88",
        "16to19": "0",
        "20to24": "5",
        "25to34": "12",
        "35to44": "18",
        "45to54": "26",
        "55to64": "21",
        "65+": "6",
        "medianAge": "21"
      }
    },
    "title": "Medical records and health information technicians",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "88",
        "women": "92.4",
        "white": "22.4",
        "black": "4.4",
        "asian": "14.6"
      }
    },
    "wageData": {
      "2013": {
        "workers": "78",
        "median": "612",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "72",
        "femaleMedian": "595"
      }
    }
  },
  "Opticians, dispensing": {
    "ageData": {
      "2013": {
        "total": "47",
        "16to19": "0",
        "20to24": "6",
        "25to34": "9",
        "35to44": "8",
        "45to54": "12",
        "55to64": "8",
        "65+": "4",
        "medianAge": "8"
      }
    },
    "title": "Opticians, dispensing",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "47",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "37",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "25",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous health technologists and technicians": {
    "ageData": {
      "2013": {
        "total": "122",
        "16to19": "0",
        "20to24": "8",
        "25to34": "33",
        "35to44": "25",
        "45to54": "31",
        "55to64": "20",
        "65+": "5",
        "medianAge": "20"
      }
    },
    "title": "Miscellaneous health technologists and technicians",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "122",
        "women": "70.1",
        "white": "21.2",
        "black": "12.1",
        "asian": "11.2"
      }
    },
    "wageData": {
      "2013": {
        "workers": "114",
        "median": "849",
        "maleWorkers": "41",
        "maleMedian": "-",
        "femaleWorkers": "73",
        "femaleMedian": "782"
      }
    }
  },
  "Other healthcare practitioners and technical occupations": {
    "ageData": {
      "2013": {
        "total": "76",
        "16to19": "0",
        "20to24": "6",
        "25to34": "25",
        "35to44": "20",
        "45to54": "12",
        "55to64": "12",
        "65+": "1",
        "medianAge": "12"
      }
    },
    "title": "Other healthcare practitioners and technical occupations",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "76",
        "women": "52.6",
        "white": "6.9",
        "black": "3.5",
        "asian": "9.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "69",
        "median": "1,065",
        "maleWorkers": "37",
        "maleMedian": "-",
        "femaleWorkers": "31",
        "femaleMedian": "-"
      }
    }
  },
  "Service occupations": {
    "ageData": {
      "2013": {
        "total": "25,929",
        "16to19": "1,897",
        "20to24": "3,939",
        "25to34": "5,774",
        "35to44": "4,956",
        "45to54": "4,930",
        "55to64": "3,241",
        "65+": "1,192",
        "medianAge": "3,241"
      }
    },
    "title": "Service occupations",
    "depth": 1,
    "parent": "",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "25,929",
        "women": "56.6",
        "white": "15.9",
        "black": "5.4",
        "asian": "23.1"
      }
    },
    "wageData": {
      "2013": {
        "workers": "15,052",
        "median": "493",
        "maleWorkers": "7,597",
        "maleMedian": "555",
        "femaleWorkers": "7,456",
        "femaleMedian": "452"
      }
    }
  },
  "Healthcare support occupations": {
    "ageData": {
      "2013": {
        "total": "3,537",
        "16to19": "74",
        "20to24": "529",
        "25to34": "900",
        "35to44": "726",
        "45to54": "708",
        "55to64": "473",
        "65+": "127",
        "medianAge": "473"
      }
    },
    "title": "Healthcare support occupations",
    "depth": 2,
    "parent": "Service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "3,537",
        "women": "88.9",
        "white": "26.4",
        "black": "4.7",
        "asian": "16.0"
      }
    },
    "wageData": {
      "2013": {
        "workers": "2,324",
        "median": "491",
        "maleWorkers": "304",
        "maleMedian": "546",
        "femaleWorkers": "2,020",
        "femaleMedian": "486"
      }
    }
  },
  "Nursing, psychiatric, and home health aides": {
    "ageData": {
      "2013": {
        "total": "2,134",
        "16to19": "43",
        "20to24": "300",
        "25to34": "495",
        "35to44": "433",
        "45to54": "458",
        "55to64": "315",
        "65+": "90",
        "medianAge": "315"
      }
    },
    "title": "Nursing, psychiatric, and home health aides",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "2,134",
        "women": "89.0",
        "white": "36.4",
        "black": "4.7",
        "asian": "14.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "1,393",
        "median": "457",
        "maleWorkers": "186",
        "maleMedian": "499",
        "femaleWorkers": "1,207",
        "femaleMedian": "450"
      }
    }
  },
  "Occupational therapy assistants and aides": {
    "ageData": {
      "2013": {
        "total": "19",
        "16to19": "0",
        "20to24": "1",
        "25to34": "9",
        "35to44": "3",
        "45to54": "3",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Occupational therapy assistants and aides",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "19",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "11",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "Physical therapist assistants and aides": {
    "ageData": {
      "2013": {
        "total": "68",
        "16to19": "3",
        "20to24": "15",
        "25to34": "16",
        "35to44": "10",
        "45to54": "15",
        "55to64": "9",
        "65+": "0",
        "medianAge": "9"
      }
    },
    "title": "Physical therapist assistants and aides",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "68",
        "women": "70.1",
        "white": "6.2",
        "black": "5.7",
        "asian": "9.1"
      }
    },
    "wageData": {
      "2013": {
        "workers": "39",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "26",
        "femaleMedian": "-"
      }
    }
  },
  "Massage therapists": {
    "ageData": {
      "2013": {
        "total": "172",
        "16to19": "0",
        "20to24": "21",
        "25to34": "42",
        "35to44": "40",
        "45to54": "33",
        "55to64": "28",
        "65+": "9",
        "medianAge": "28"
      }
    },
    "title": "Massage therapists",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "172",
        "women": "82.0",
        "white": "4.3",
        "black": "9.3",
        "asian": "9.2"
      }
    },
    "wageData": {
      "2013": {
        "workers": "48",
        "median": "-",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "32",
        "femaleMedian": "-"
      }
    }
  },
  "Dental assistants": {
    "ageData": {
      "2013": {
        "total": "279",
        "16to19": "3",
        "20to24": "43",
        "25to34": "90",
        "35to44": "64",
        "45to54": "56",
        "55to64": "19",
        "65+": "4",
        "medianAge": "19"
      }
    },
    "title": "Dental assistants",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "279",
        "women": "95.7",
        "white": "5.7",
        "black": "5.4",
        "asian": "22.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "181",
        "median": "571",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "174",
        "femaleMedian": "571"
      }
    }
  },
  "Medical assistants": {
    "ageData": {
      "2013": {
        "total": "458",
        "16to19": "3",
        "20to24": "91",
        "25to34": "144",
        "35to44": "103",
        "45to54": "63",
        "55to64": "47",
        "65+": "8",
        "medianAge": "47"
      }
    },
    "title": "Medical assistants",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "458",
        "women": "94.1",
        "white": "13.1",
        "black": "4.0",
        "asian": "24.8"
      }
    },
    "wageData": {
      "2013": {
        "workers": "365",
        "median": "531",
        "maleWorkers": "26",
        "maleMedian": "-",
        "femaleWorkers": "339",
        "femaleMedian": "523"
      }
    }
  },
  "Medical transcriptionists": {
    "ageData": {
      "2013": {
        "total": "52",
        "16to19": "0",
        "20to24": "2",
        "25to34": "5",
        "35to44": "10",
        "45to54": "14",
        "55to64": "17",
        "65+": "4",
        "medianAge": "17"
      }
    },
    "title": "Medical transcriptionists",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "52",
        "women": "97.8",
        "white": "4.6",
        "black": "0.0",
        "asian": "8.0"
      }
    },
    "wageData": {
      "2013": {
        "workers": "34",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "33",
        "femaleMedian": "-"
      }
    }
  },
  "Pharmacy aides": {
    "ageData": {
      "2013": {
        "total": "36",
        "16to19": "2",
        "20to24": "10",
        "25to34": "9",
        "35to44": "3",
        "45to54": "4",
        "55to64": "5",
        "65+": "3",
        "medianAge": "5"
      }
    },
    "title": "Pharmacy aides",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "36",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "22",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "19",
        "femaleMedian": "-"
      }
    }
  },
  "Veterinary assistants and laboratory animal caretakers": {
    "ageData": {
      "2013": {
        "total": "40",
        "16to19": "5",
        "20to24": "11",
        "25to34": "14",
        "35to44": "3",
        "45to54": "4",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Veterinary assistants and laboratory animal caretakers",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "40",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "23",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "20",
        "femaleMedian": "-"
      }
    }
  },
  "Phlebotomists": {
    "ageData": {
      "2013": {
        "total": "119",
        "16to19": "0",
        "20to24": "12",
        "25to34": "44",
        "35to44": "28",
        "45to54": "25",
        "55to64": "8",
        "65+": "2",
        "medianAge": "8"
      }
    },
    "title": "Phlebotomists",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "119",
        "women": "81.8",
        "white": "29.1",
        "black": "3.7",
        "asian": "14.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "97",
        "median": "583",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "77",
        "femaleMedian": "573"
      }
    }
  },
  "Miscellaneous healthcare support occupations, including medical equipment preparers": {
    "ageData": {
      "2013": {
        "total": "160",
        "16to19": "13",
        "20to24": "23",
        "25to34": "34",
        "35to44": "30",
        "45to54": "33",
        "55to64": "21",
        "65+": "6",
        "medianAge": "21"
      }
    },
    "title": "Miscellaneous healthcare support occupations, including medical equipment preparers",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "160",
        "women": "77.8",
        "white": "17.6",
        "black": "4.8",
        "asian": "7.6"
      }
    },
    "wageData": {
      "2013": {
        "workers": "112",
        "median": "462",
        "maleWorkers": "31",
        "maleMedian": "-",
        "femaleWorkers": "81",
        "femaleMedian": "440"
      }
    }
  },
  "Protective service occupations": {
    "ageData": {
      "2013": {
        "total": "3,130",
        "16to19": "108",
        "20to24": "246",
        "25to34": "746",
        "35to44": "807",
        "45to54": "706",
        "55to64": "370",
        "65+": "146",
        "medianAge": "370"
      }
    },
    "title": "Protective service occupations",
    "depth": 2,
    "parent": "Service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "3,130",
        "women": "21.1",
        "white": "18.6",
        "black": "2.4",
        "asian": "15.1"
      }
    },
    "wageData": {
      "2013": {
        "workers": "2,685",
        "median": "783",
        "maleWorkers": "2,172",
        "maleMedian": "824",
        "femaleWorkers": "512",
        "femaleMedian": "643"
      }
    }
  },
  "First-line supervisors of correctional officers": {
    "ageData": {
      "2013": {
        "total": "36",
        "16to19": "0",
        "20to24": "1",
        "25to34": "10",
        "35to44": "8",
        "45to54": "12",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "First-line supervisors of correctional officers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "36",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "33",
        "median": "-",
        "maleWorkers": "28",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "First-line supervisors of police and detectives": {
    "ageData": {
      "2013": {
        "total": "115",
        "16to19": "0",
        "20to24": "1",
        "25to34": "7",
        "35to44": "37",
        "45to54": "48",
        "55to64": "20",
        "65+": "2",
        "medianAge": "20"
      }
    },
    "title": "First-line supervisors of police and detectives",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "115",
        "women": "15.3",
        "white": "8.6",
        "black": "1.5",
        "asian": "10.5"
      }
    },
    "wageData": {
      "2013": {
        "workers": "114",
        "median": "1,139",
        "maleWorkers": "97",
        "maleMedian": "1,182",
        "femaleWorkers": "17",
        "femaleMedian": "-"
      }
    }
  },
  "First-line supervisors of fire fighting and prevention workers": {
    "ageData": {
      "2013": {
        "total": "58",
        "16to19": "0",
        "20to24": "0",
        "25to34": "8",
        "35to44": "17",
        "45to54": "19",
        "55to64": "12",
        "65+": "2",
        "medianAge": "12"
      }
    },
    "title": "First-line supervisors of fire fighting and prevention workers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "58",
        "women": "0.1",
        "white": "4.5",
        "black": "0.2",
        "asian": "3.5"
      }
    },
    "wageData": {
      "2013": {
        "workers": "60",
        "median": "1,082",
        "maleWorkers": "60",
        "maleMedian": "1,082",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "First-line supervisors of protective service workers, all other": {
    "ageData": {
      "2013": {
        "total": "100",
        "16to19": "0",
        "20to24": "1",
        "25to34": "23",
        "35to44": "21",
        "45to54": "29",
        "55to64": "19",
        "65+": "6",
        "medianAge": "19"
      }
    },
    "title": "First-line supervisors of protective service workers, all other",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "100",
        "women": "21.7",
        "white": "29.4",
        "black": "1.5",
        "asian": "11.6"
      }
    },
    "wageData": {
      "2013": {
        "workers": "82",
        "median": "785",
        "maleWorkers": "57",
        "maleMedian": "788",
        "femaleWorkers": "25",
        "femaleMedian": "-"
      }
    }
  },
  "Firefighters": {
    "ageData": {
      "2013": {
        "total": "306",
        "16to19": "3",
        "20to24": "18",
        "25to34": "92",
        "35to44": "95",
        "45to54": "68",
        "55to64": "26",
        "65+": "3",
        "medianAge": "26"
      }
    },
    "title": "Firefighters",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "306",
        "women": "3.5",
        "white": "11.4",
        "black": "0.7",
        "asian": "8.5"
      }
    },
    "wageData": {
      "2013": {
        "workers": "294",
        "median": "996",
        "maleWorkers": "285",
        "maleMedian": "1,000",
        "femaleWorkers": "9",
        "femaleMedian": "-"
      }
    }
  },
  "Fire inspectors": {
    "ageData": {
      "2013": {
        "total": "19",
        "16to19": "0",
        "20to24": "1",
        "25to34": "2",
        "35to44": "3",
        "45to54": "7",
        "55to64": "5",
        "65+": "1",
        "medianAge": "5"
      }
    },
    "title": "Fire inspectors",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "19",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "18",
        "median": "-",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Bailiffs, correctional officers, and jailers": {
    "ageData": {
      "2013": {
        "total": "413",
        "16to19": "2",
        "20to24": "37",
        "25to34": "96",
        "35to44": "133",
        "45to54": "94",
        "55to64": "40",
        "65+": "11",
        "medianAge": "40"
      }
    },
    "title": "Bailiffs, correctional officers, and jailers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "413",
        "women": "27.2",
        "white": "23.6",
        "black": "1.1",
        "asian": "20.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "418",
        "median": "683",
        "maleWorkers": "298",
        "maleMedian": "729",
        "femaleWorkers": "120",
        "femaleMedian": "599"
      }
    }
  },
  "Detectives and criminal investigators": {
    "ageData": {
      "2013": {
        "total": "158",
        "16to19": "0",
        "20to24": "2",
        "25to34": "28",
        "35to44": "59",
        "45to54": "41",
        "55to64": "25",
        "65+": "3",
        "medianAge": "25"
      }
    },
    "title": "Detectives and criminal investigators",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "158",
        "women": "20.1",
        "white": "13.8",
        "black": "1.0",
        "asian": "14.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "159",
        "median": "1,054",
        "maleWorkers": "128",
        "maleMedian": "1,120",
        "femaleWorkers": "30",
        "femaleMedian": "-"
      }
    }
  },
  "Fish and game wardens": {
    "ageData": {
      "2013": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Fish and game wardens",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Parking enforcement workers": {
    "ageData": {
      "2013": {
        "total": "9",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "2",
        "45to54": "2",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Parking enforcement workers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "9",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Police and sheriff's patrol officers": {
    "ageData": {
      "2013": {
        "total": "697",
        "16to19": "2",
        "20to24": "26",
        "25to34": "212",
        "35to44": "235",
        "45to54": "157",
        "55to64": "51",
        "65+": "14",
        "medianAge": "51"
      }
    },
    "title": "Police and sheriff's patrol officers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "697",
        "women": "13.4",
        "white": "14.2",
        "black": "2.4",
        "asian": "15.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "682",
        "median": "1,007",
        "maleWorkers": "593",
        "maleMedian": "1,032",
        "femaleWorkers": "89",
        "femaleMedian": "881"
      }
    }
  },
  "Transit and railroad police": {
    "ageData": {
      "2013": {
        "total": "1",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Transit and railroad police",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Animal control workers": {
    "ageData": {
      "2013": {
        "total": "11",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "4",
        "45to54": "2",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Animal control workers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "11",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "10",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Private detectives and investigators": {
    "ageData": {
      "2013": {
        "total": "86",
        "16to19": "1",
        "20to24": "3",
        "25to34": "20",
        "35to44": "26",
        "45to54": "16",
        "55to64": "13",
        "65+": "7",
        "medianAge": "13"
      }
    },
    "title": "Private detectives and investigators",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "86",
        "women": "38.5",
        "white": "6.6",
        "black": "4.4",
        "asian": "15.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "72",
        "median": "948",
        "maleWorkers": "40",
        "maleMedian": "-",
        "femaleWorkers": "32",
        "femaleMedian": "-"
      }
    }
  },
  "Security guards and gaming surveillance officers": {
    "ageData": {
      "2013": {
        "total": "858",
        "16to19": "13",
        "20to24": "115",
        "25to34": "218",
        "35to44": "142",
        "45to54": "171",
        "55to64": "121",
        "65+": "77",
        "medianAge": "121"
      }
    },
    "title": "Security guards and gaming surveillance officers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "858",
        "women": "20.4",
        "white": "26.5",
        "black": "4.0",
        "asian": "17.1"
      }
    },
    "wageData": {
      "2013": {
        "workers": "643",
        "median": "532",
        "maleWorkers": "517",
        "maleMedian": "549",
        "femaleWorkers": "125",
        "femaleMedian": "500"
      }
    }
  },
  "Crossing guards": {
    "ageData": {
      "2013": {
        "total": "61",
        "16to19": "1",
        "20to24": "6",
        "25to34": "6",
        "35to44": "12",
        "45to54": "13",
        "55to64": "12",
        "65+": "11",
        "medianAge": "12"
      }
    },
    "title": "Crossing guards",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "61",
        "women": "69.1",
        "white": "28.3",
        "black": "3.3",
        "asian": "9.1"
      }
    },
    "wageData": {
      "2013": {
        "workers": "14",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Transportation security screeners": {
    "ageData": {
      "2013": {
        "total": "28",
        "16to19": "0",
        "20to24": "1",
        "25to34": "6",
        "35to44": "8",
        "45to54": "10",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Transportation security screeners",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "28",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "25",
        "median": "-",
        "maleWorkers": "16",
        "maleMedian": "-",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Lifeguards and other recreational, and all other protective service workers": {
    "ageData": {
      "2013": {
        "total": "175",
        "16to19": "85",
        "20to24": "34",
        "25to34": "11",
        "35to44": "7",
        "45to54": "16",
        "55to64": "14",
        "65+": "9",
        "medianAge": "14"
      }
    },
    "title": "Lifeguards and other recreational, and all other protective service workers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "175",
        "women": "56.4",
        "white": "10.4",
        "black": "2.2",
        "asian": "9.2"
      }
    },
    "wageData": {
      "2013": {
        "workers": "52",
        "median": "484",
        "maleWorkers": "16",
        "maleMedian": "-",
        "femaleWorkers": "36",
        "femaleMedian": "-"
      }
    }
  },
  "Food preparation and serving related occupations": {
    "ageData": {
      "2013": {
        "total": "8,209",
        "16to19": "1,210",
        "20to24": "1,971",
        "25to34": "1,946",
        "35to44": "1,188",
        "45to54": "1,057",
        "55to64": "616",
        "65+": "221",
        "medianAge": "616"
      }
    },
    "title": "Food preparation and serving related occupations",
    "depth": 2,
    "parent": "Service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "8,209",
        "women": "54.7",
        "white": "12.2",
        "black": "5.7",
        "asian": "24.4"
      }
    },
    "wageData": {
      "2013": {
        "workers": "4,140",
        "median": "416",
        "maleWorkers": "2,187",
        "maleMedian": "437",
        "femaleWorkers": "1,953",
        "femaleMedian": "400"
      }
    }
  },
  "Chefs and head cooks": {
    "ageData": {
      "2013": {
        "total": "425",
        "16to19": "9",
        "20to24": "46",
        "25to34": "125",
        "35to44": "106",
        "45to54": "92",
        "55to64": "36",
        "65+": "11",
        "medianAge": "36"
      }
    },
    "title": "Chefs and head cooks",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "425",
        "women": "20.0",
        "white": "13.7",
        "black": "13.6",
        "asian": "19.1"
      }
    },
    "wageData": {
      "2013": {
        "workers": "351",
        "median": "574",
        "maleWorkers": "282",
        "maleMedian": "589",
        "femaleWorkers": "69",
        "femaleMedian": "510"
      }
    }
  },
  "First-line supervisors of food preparation and serving workers": {
    "ageData": {
      "2013": {
        "total": "581",
        "16to19": "27",
        "20to24": "123",
        "25to34": "153",
        "35to44": "106",
        "45to54": "98",
        "55to64": "54",
        "65+": "20",
        "medianAge": "54"
      }
    },
    "title": "First-line supervisors of food preparation and serving workers",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "581",
        "women": "58.0",
        "white": "13.1",
        "black": "4.6",
        "asian": "18.5"
      }
    },
    "wageData": {
      "2013": {
        "workers": "423",
        "median": "491",
        "maleWorkers": "174",
        "maleMedian": "580",
        "femaleWorkers": "249",
        "femaleMedian": "448"
      }
    }
  },
  "Cooks": {
    "ageData": {
      "2013": {
        "total": "1,988",
        "16to19": "197",
        "20to24": "371",
        "25to34": "502",
        "35to44": "355",
        "45to54": "305",
        "55to64": "205",
        "65+": "53",
        "medianAge": "205"
      }
    },
    "title": "Cooks",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "1,988",
        "women": "39.7",
        "white": "15.3",
        "black": "5.5",
        "asian": "34.0"
      }
    },
    "wageData": {
      "2013": {
        "workers": "1,209",
        "median": "402",
        "maleWorkers": "791",
        "maleMedian": "411",
        "femaleWorkers": "418",
        "femaleMedian": "382"
      }
    }
  },
  "Food preparation workers": {
    "ageData": {
      "2013": {
        "total": "885",
        "16to19": "175",
        "20to24": "216",
        "25to34": "183",
        "35to44": "114",
        "45to54": "113",
        "55to64": "58",
        "65+": "27",
        "medianAge": "58"
      }
    },
    "title": "Food preparation workers",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "885",
        "women": "55.8",
        "white": "12.7",
        "black": "5.9",
        "asian": "29.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "398",
        "median": "387",
        "maleWorkers": "206",
        "maleMedian": "392",
        "femaleWorkers": "191",
        "femaleMedian": "380"
      }
    }
  },
  "Bartenders": {
    "ageData": {
      "2013": {
        "total": "417",
        "16to19": "4",
        "20to24": "85",
        "25to34": "165",
        "35to44": "75",
        "45to54": "44",
        "55to64": "35",
        "65+": "11",
        "medianAge": "35"
      }
    },
    "title": "Bartenders",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "417",
        "women": "58.0",
        "white": "5.5",
        "black": "2.4",
        "asian": "13.5"
      }
    },
    "wageData": {
      "2013": {
        "workers": "207",
        "median": "513",
        "maleWorkers": "98",
        "maleMedian": "594",
        "femaleWorkers": "109",
        "femaleMedian": "483"
      }
    }
  },
  "Combined food preparation and serving workers, including fast food": {
    "ageData": {
      "2013": {
        "total": "377",
        "16to19": "69",
        "20to24": "101",
        "25to34": "78",
        "35to44": "37",
        "45to54": "49",
        "55to64": "30",
        "65+": "14",
        "medianAge": "30"
      }
    },
    "title": "Combined food preparation and serving workers, including fast food",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "377",
        "women": "65.3",
        "white": "16.4",
        "black": "3.2",
        "asian": "16.1"
      }
    },
    "wageData": {
      "2013": {
        "workers": "175",
        "median": "384",
        "maleWorkers": "68",
        "maleMedian": "413",
        "femaleWorkers": "107",
        "femaleMedian": "370"
      }
    }
  },
  "Counter attendants, cafeteria, food concession, and coffee shop": {
    "ageData": {
      "2013": {
        "total": "221",
        "16to19": "110",
        "20to24": "57",
        "25to34": "24",
        "35to44": "15",
        "45to54": "7",
        "55to64": "4",
        "65+": "3",
        "medianAge": "4"
      }
    },
    "title": "Counter attendants, cafeteria, food concession, and coffee shop",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "221",
        "women": "70.1",
        "white": "12.2",
        "black": "3.0",
        "asian": "15.4"
      }
    },
    "wageData": {
      "2013": {
        "workers": "57",
        "median": "320",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "43",
        "femaleMedian": "-"
      }
    }
  },
  "Waiters and waitresses": {
    "ageData": {
      "2013": {
        "total": "2,124",
        "16to19": "323",
        "20to24": "708",
        "25to34": "524",
        "35to44": "250",
        "45to54": "196",
        "55to64": "87",
        "65+": "35",
        "medianAge": "87"
      }
    },
    "title": "Waiters and waitresses",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "2,124",
        "women": "70.4",
        "white": "8.1",
        "black": "6.5",
        "asian": "19.4"
      }
    },
    "wageData": {
      "2013": {
        "workers": "883",
        "median": "413",
        "maleWorkers": "325",
        "maleMedian": "449",
        "femaleWorkers": "558",
        "femaleMedian": "400"
      }
    }
  },
  "Food servers, nonrestaurant": {
    "ageData": {
      "2013": {
        "total": "229",
        "16to19": "29",
        "20to24": "46",
        "25to34": "41",
        "35to44": "39",
        "45to54": "36",
        "55to64": "26",
        "65+": "12",
        "medianAge": "26"
      }
    },
    "title": "Food servers, nonrestaurant",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "229",
        "women": "67.2",
        "white": "18.5",
        "black": "6.3",
        "asian": "17.4"
      }
    },
    "wageData": {
      "2013": {
        "workers": "109",
        "median": "458",
        "maleWorkers": "36",
        "maleMedian": "-",
        "femaleWorkers": "74",
        "femaleMedian": "410"
      }
    }
  },
  "Dining room and cafeteria attendants and bartender helpers": {
    "ageData": {
      "2013": {
        "total": "358",
        "16to19": "81",
        "20to24": "66",
        "25to34": "59",
        "35to44": "40",
        "45to54": "54",
        "55to64": "39",
        "65+": "19",
        "medianAge": "39"
      }
    },
    "title": "Dining room and cafeteria attendants and bartender helpers",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "358",
        "women": "45.6",
        "white": "11.0",
        "black": "4.3",
        "asian": "31.4"
      }
    },
    "wageData": {
      "2013": {
        "workers": "130",
        "median": "412",
        "maleWorkers": "76",
        "maleMedian": "426",
        "femaleWorkers": "55",
        "femaleMedian": "398"
      }
    }
  },
  "Dishwashers": {
    "ageData": {
      "2013": {
        "total": "301",
        "16to19": "62",
        "20to24": "61",
        "25to34": "63",
        "35to44": "33",
        "45to54": "47",
        "55to64": "26",
        "65+": "9",
        "medianAge": "26"
      }
    },
    "title": "Dishwashers",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "301",
        "women": "20.6",
        "white": "15.6",
        "black": "5.8",
        "asian": "37.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "127",
        "median": "356",
        "maleWorkers": "106",
        "maleMedian": "359",
        "femaleWorkers": "21",
        "femaleMedian": "-"
      }
    }
  },
  "Hosts and hostesses, restaurant, lounge, and coffee shop": {
    "ageData": {
      "2013": {
        "total": "299",
        "16to19": "124",
        "20to24": "90",
        "25to34": "30",
        "35to44": "18",
        "45to54": "14",
        "55to64": "16",
        "65+": "8",
        "medianAge": "16"
      }
    },
    "title": "Hosts and hostesses, restaurant, lounge, and coffee shop",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "299",
        "women": "88.5",
        "white": "12.5",
        "black": "3.0",
        "asian": "16.0"
      }
    },
    "wageData": {
      "2013": {
        "workers": "69",
        "median": "393",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "60",
        "femaleMedian": "391"
      }
    }
  },
  "Food preparation and serving related workers, all other": {
    "ageData": {
      "2013": {
        "total": "4",
        "16to19": "2",
        "20to24": "1",
        "25to34": "0",
        "35to44": "0",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Food preparation and serving related workers, all other",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Building and grounds cleaning and maintenance occupations": {
    "ageData": {
      "2013": {
        "total": "5,661",
        "16to19": "207",
        "20to24": "457",
        "25to34": "1,027",
        "35to44": "1,220",
        "45to54": "1,380",
        "55to64": "1,015",
        "65+": "355",
        "medianAge": "1,015"
      }
    },
    "title": "Building and grounds cleaning and maintenance occupations",
    "depth": 2,
    "parent": "Service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "5,661",
        "women": "38.7",
        "white": "14.6",
        "black": "3.4",
        "asian": "35.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "3,421",
        "median": "475",
        "maleWorkers": "2,279",
        "maleMedian": "505",
        "femaleWorkers": "1,142",
        "femaleMedian": "417"
      }
    }
  },
  "First-line supervisors of housekeeping and janitorial workers": {
    "ageData": {
      "2013": {
        "total": "322",
        "16to19": "1",
        "20to24": "7",
        "25to34": "45",
        "35to44": "72",
        "45to54": "112",
        "55to64": "64",
        "65+": "20",
        "medianAge": "64"
      }
    },
    "title": "First-line supervisors of housekeeping and janitorial workers",
    "depth": 3,
    "parent": "Building and grounds cleaning and maintenance occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "322",
        "women": "41.4",
        "white": "14.6",
        "black": "2.8",
        "asian": "20.0"
      }
    },
    "wageData": {
      "2013": {
        "workers": "186",
        "median": "638",
        "maleWorkers": "108",
        "maleMedian": "801",
        "femaleWorkers": "78",
        "femaleMedian": "511"
      }
    }
  },
  "First-line supervisors of landscaping, lawn service, and groundskeeping workers": {
    "ageData": {
      "2013": {
        "total": "259",
        "16to19": "1",
        "20to24": "10",
        "25to34": "45",
        "35to44": "67",
        "45to54": "77",
        "55to64": "46",
        "65+": "13",
        "medianAge": "46"
      }
    },
    "title": "First-line supervisors of landscaping, lawn service, and groundskeeping workers",
    "depth": 3,
    "parent": "Building and grounds cleaning and maintenance occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "259",
        "women": "7.4",
        "white": "7.2",
        "black": "2.0",
        "asian": "19.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "104",
        "median": "766",
        "maleWorkers": "99",
        "maleMedian": "759",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Janitors and building cleaners": {
    "ageData": {
      "2013": {
        "total": "2,275",
        "16to19": "83",
        "20to24": "181",
        "25to34": "364",
        "35to44": "410",
        "45to54": "561",
        "55to64": "495",
        "65+": "181",
        "medianAge": "495"
      }
    },
    "title": "Janitors and building cleaners",
    "depth": 3,
    "parent": "Building and grounds cleaning and maintenance occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "2,275",
        "women": "32.6",
        "white": "18.4",
        "black": "3.9",
        "asian": "30.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "1,537",
        "median": "487",
        "maleWorkers": "1,116",
        "maleMedian": "517",
        "femaleWorkers": "421",
        "femaleMedian": "418"
      }
    }
  },
  "Maids and housekeeping cleaners": {
    "ageData": {
      "2013": {
        "total": "1,401",
        "16to19": "38",
        "20to24": "96",
        "25to34": "241",
        "35to44": "342",
        "45to54": "364",
        "55to64": "245",
        "65+": "76",
        "medianAge": "245"
      }
    },
    "title": "Maids and housekeeping cleaners",
    "depth": 3,
    "parent": "Building and grounds cleaning and maintenance occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "1,401",
        "women": "87.7",
        "white": "16.8",
        "black": "5.2",
        "asian": "44.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "733",
        "median": "413",
        "maleWorkers": "128",
        "maleMedian": "467",
        "femaleWorkers": "605",
        "femaleMedian": "406"
      }
    }
  },
  "Pest control workers": {
    "ageData": {
      "2013": {
        "total": "77",
        "16to19": "1",
        "20to24": "3",
        "25to34": "19",
        "35to44": "25",
        "45to54": "11",
        "55to64": "14",
        "65+": "4",
        "medianAge": "14"
      }
    },
    "title": "Pest control workers",
    "depth": 3,
    "parent": "Building and grounds cleaning and maintenance occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "77",
        "women": "3.5",
        "white": "11.8",
        "black": "1.5",
        "asian": "19.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "62",
        "median": "609",
        "maleWorkers": "60",
        "maleMedian": "606",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Grounds maintenance workers": {
    "ageData": {
      "2013": {
        "total": "1,327",
        "16to19": "84",
        "20to24": "161",
        "25to34": "312",
        "35to44": "304",
        "45to54": "254",
        "55to64": "150",
        "65+": "62",
        "medianAge": "150"
      }
    },
    "title": "Grounds maintenance workers",
    "depth": 3,
    "parent": "Building and grounds cleaning and maintenance occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "1,327",
        "women": "4.7",
        "white": "7.4",
        "black": "1.3",
        "asian": "44.8"
      }
    },
    "wageData": {
      "2013": {
        "workers": "799",
        "median": "445",
        "maleWorkers": "768",
        "maleMedian": "441",
        "femaleWorkers": "31",
        "femaleMedian": "-"
      }
    }
  },
  "Personal care and service occupations": {
    "ageData": {
      "2013": {
        "total": "5,392",
        "16to19": "298",
        "20to24": "736",
        "25to34": "1,155",
        "35to44": "1,015",
        "45to54": "1,080",
        "55to64": "767",
        "65+": "343",
        "medianAge": "767"
      }
    },
    "title": "Personal care and service occupations",
    "depth": 2,
    "parent": "Service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "5,392",
        "women": "77.7",
        "white": "14.6",
        "black": "9.0",
        "asian": "16.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "2,482",
        "median": "481",
        "maleWorkers": "654",
        "maleMedian": "549",
        "femaleWorkers": "1,829",
        "femaleMedian": "464"
      }
    }
  },
  "First-line supervisors of gaming workers": {
    "ageData": {
      "2013": {
        "total": "157",
        "16to19": "2",
        "20to24": "16",
        "25to34": "41",
        "35to44": "39",
        "45to54": "30",
        "55to64": "21",
        "65+": "7",
        "medianAge": "21"
      }
    },
    "title": "First-line supervisors of gaming workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "157",
        "women": "47.7",
        "white": "3.6",
        "black": "11.0",
        "asian": "6.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "97",
        "median": "755",
        "maleWorkers": "49",
        "maleMedian": "-",
        "femaleWorkers": "48",
        "femaleMedian": "-"
      }
    }
  },
  "First-line supervisors of personal service workers": {
    "ageData": {
      "2013": {
        "total": "228",
        "16to19": "1",
        "20to24": "11",
        "25to34": "45",
        "35to44": "54",
        "45to54": "65",
        "55to64": "39",
        "65+": "14",
        "medianAge": "39"
      }
    },
    "title": "First-line supervisors of personal service workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "228",
        "women": "70.3",
        "white": "11.7",
        "black": "17.6",
        "asian": "13.2"
      }
    },
    "wageData": {
      "2013": {
        "workers": "72",
        "median": "595",
        "maleWorkers": "28",
        "maleMedian": "-",
        "femaleWorkers": "44",
        "femaleMedian": "-"
      }
    }
  },
  "Animal trainers": {
    "ageData": {
      "2013": {
        "total": "36",
        "16to19": "2",
        "20to24": "3",
        "25to34": "7",
        "35to44": "6",
        "45to54": "9",
        "55to64": "6",
        "65+": "4",
        "medianAge": "6"
      }
    },
    "title": "Animal trainers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "36",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Nonfarm animal caretakers": {
    "ageData": {
      "2013": {
        "total": "175",
        "16to19": "9",
        "20to24": "45",
        "25to34": "49",
        "35to44": "19",
        "45to54": "28",
        "55to64": "21",
        "65+": "4",
        "medianAge": "21"
      }
    },
    "title": "Nonfarm animal caretakers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "175",
        "women": "73.6",
        "white": "4.1",
        "black": "1.3",
        "asian": "12.0"
      }
    },
    "wageData": {
      "2013": {
        "workers": "78",
        "median": "458",
        "maleWorkers": "22",
        "maleMedian": "-",
        "femaleWorkers": "57",
        "femaleMedian": "468"
      }
    }
  },
  "Gaming services workers": {
    "ageData": {
      "2013": {
        "total": "102",
        "16to19": "2",
        "20to24": "10",
        "25to34": "20",
        "35to44": "24",
        "45to54": "19",
        "55to64": "15",
        "65+": "12",
        "medianAge": "15"
      }
    },
    "title": "Gaming services workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "102",
        "women": "47.7",
        "white": "10.3",
        "black": "26.1",
        "asian": "15.1"
      }
    },
    "wageData": {
      "2013": {
        "workers": "74",
        "median": "651",
        "maleWorkers": "35",
        "maleMedian": "-",
        "femaleWorkers": "39",
        "femaleMedian": "-"
      }
    }
  },
  "Motion picture projectionists": {
    "ageData": {
      "2013": {
        "total": "5",
        "16to19": "0",
        "20to24": "3",
        "25to34": "1",
        "35to44": "0",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Motion picture projectionists",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Ushers, lobby attendants, and ticket takers": {
    "ageData": {
      "2013": {
        "total": "39",
        "16to19": "11",
        "20to24": "12",
        "25to34": "6",
        "35to44": "1",
        "45to54": "1",
        "55to64": "5",
        "65+": "4",
        "medianAge": "5"
      }
    },
    "title": "Ushers, lobby attendants, and ticket takers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "39",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "9",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous entertainment attendants and related workers": {
    "ageData": {
      "2013": {
        "total": "174",
        "16to19": "42",
        "20to24": "44",
        "25to34": "25",
        "35to44": "12",
        "45to54": "16",
        "55to64": "18",
        "65+": "16",
        "medianAge": "18"
      }
    },
    "title": "Miscellaneous entertainment attendants and related workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "174",
        "women": "44.0",
        "white": "9.9",
        "black": "4.3",
        "asian": "18.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "74",
        "median": "419",
        "maleWorkers": "37",
        "maleMedian": "-",
        "femaleWorkers": "37",
        "femaleMedian": "-"
      }
    }
  },
  "Embalmers and funeral attendants": {
    "ageData": {
      "2013": {
        "total": "16",
        "16to19": "0",
        "20to24": "4",
        "25to34": "1",
        "35to44": "3",
        "45to54": "2",
        "55to64": "4",
        "65+": "2",
        "medianAge": "4"
      }
    },
    "title": "Embalmers and funeral attendants",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "16",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "10",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Morticians, undertakers, and funeral directors": {
    "ageData": {
      "2013": {
        "total": "39",
        "16to19": "0",
        "20to24": "1",
        "25to34": "7",
        "35to44": "7",
        "45to54": "9",
        "55to64": "9",
        "65+": "6",
        "medianAge": "9"
      }
    },
    "title": "Morticians, undertakers, and funeral directors",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "39",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "28",
        "median": "-",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Barbers": {
    "ageData": {
      "2013": {
        "total": "127",
        "16to19": "2",
        "20to24": "6",
        "25to34": "35",
        "35to44": "33",
        "45to54": "24",
        "55to64": "16",
        "65+": "12",
        "medianAge": "16"
      }
    },
    "title": "Barbers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "127",
        "women": "19.2",
        "white": "34.5",
        "black": "3.4",
        "asian": "30.0"
      }
    },
    "wageData": {
      "2013": {
        "workers": "60",
        "median": "389",
        "maleWorkers": "51",
        "maleMedian": "369",
        "femaleWorkers": "9",
        "femaleMedian": "-"
      }
    }
  },
  "Hairdressers, hairstylists, and cosmetologists": {
    "ageData": {
      "2013": {
        "total": "786",
        "16to19": "10",
        "20to24": "77",
        "25to34": "217",
        "35to44": "175",
        "45to54": "149",
        "55to64": "108",
        "65+": "50",
        "medianAge": "108"
      }
    },
    "title": "Hairdressers, hairstylists, and cosmetologists",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "786",
        "women": "94.8",
        "white": "12.8",
        "black": "5.2",
        "asian": "14.6"
      }
    },
    "wageData": {
      "2013": {
        "workers": "329",
        "median": "488",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "308",
        "femaleMedian": "485"
      }
    }
  },
  "Miscellaneous personal appearance workers": {
    "ageData": {
      "2013": {
        "total": "316",
        "16to19": "5",
        "20to24": "26",
        "25to34": "81",
        "35to44": "97",
        "45to54": "62",
        "55to64": "37",
        "65+": "8",
        "medianAge": "37"
      }
    },
    "title": "Miscellaneous personal appearance workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "316",
        "women": "85.1",
        "white": "6.1",
        "black": "56.7",
        "asian": "7.8"
      }
    },
    "wageData": {
      "2013": {
        "workers": "170",
        "median": "495",
        "maleWorkers": "34",
        "maleMedian": "-",
        "femaleWorkers": "136",
        "femaleMedian": "480"
      }
    }
  },
  "Baggage porters, bellhops, and concierges": {
    "ageData": {
      "2013": {
        "total": "87",
        "16to19": "1",
        "20to24": "14",
        "25to34": "18",
        "35to44": "13",
        "45to54": "21",
        "55to64": "10",
        "65+": "9",
        "medianAge": "10"
      }
    },
    "title": "Baggage porters, bellhops, and concierges",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "87",
        "women": "15.9",
        "white": "22.7",
        "black": "7.6",
        "asian": "25.7"
      }
    },
    "wageData": {
      "2013": {
        "workers": "71",
        "median": "491",
        "maleWorkers": "60",
        "maleMedian": "495",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Tour and travel guides": {
    "ageData": {
      "2013": {
        "total": "45",
        "16to19": "4",
        "20to24": "10",
        "25to34": "7",
        "35to44": "6",
        "45to54": "8",
        "55to64": "7",
        "65+": "4",
        "medianAge": "7"
      }
    },
    "title": "Tour and travel guides",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "45",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "21",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "13",
        "femaleMedian": "-"
      }
    }
  },
  "Childcare workers": {
    "ageData": {
      "2013": {
        "total": "1,230",
        "16to19": "122",
        "20to24": "211",
        "25to34": "226",
        "35to44": "200",
        "45to54": "238",
        "55to64": "170",
        "65+": "62",
        "medianAge": "170"
      }
    },
    "title": "Childcare workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "1,230",
        "women": "94.8",
        "white": "13.2",
        "black": "3.4",
        "asian": "22.4"
      }
    },
    "wageData": {
      "2013": {
        "workers": "441",
        "median": "418",
        "maleWorkers": "31",
        "maleMedian": "-",
        "femaleWorkers": "410",
        "femaleMedian": "418"
      }
    }
  },
  "Personal care aides": {
    "ageData": {
      "2013": {
        "total": "1,242",
        "16to19": "31",
        "20to24": "148",
        "25to34": "232",
        "35to44": "212",
        "45to54": "286",
        "55to64": "228",
        "65+": "105",
        "medianAge": "228"
      }
    },
    "title": "Personal care aides",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "1,242",
        "women": "84.2",
        "white": "22.1",
        "black": "6.5",
        "asian": "19.7"
      }
    },
    "wageData": {
      "2013": {
        "workers": "657",
        "median": "449",
        "maleWorkers": "119",
        "maleMedian": "470",
        "femaleWorkers": "539",
        "femaleMedian": "445"
      }
    }
  },
  "Recreation and fitness workers": {
    "ageData": {
      "2013": {
        "total": "444",
        "16to19": "44",
        "20to24": "71",
        "25to34": "99",
        "35to44": "85",
        "45to54": "91",
        "55to64": "39",
        "65+": "15",
        "medianAge": "39"
      }
    },
    "title": "Recreation and fitness workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "444",
        "women": "63.2",
        "white": "14.2",
        "black": "5.5",
        "asian": "10.1"
      }
    },
    "wageData": {
      "2013": {
        "workers": "213",
        "median": "538",
        "maleWorkers": "90",
        "maleMedian": "561",
        "femaleWorkers": "123",
        "femaleMedian": "523"
      }
    }
  },
  "Residential advisors": {
    "ageData": {
      "2013": {
        "total": "40",
        "16to19": "1",
        "20to24": "9",
        "25to34": "11",
        "35to44": "6",
        "45to54": "8",
        "55to64": "5",
        "65+": "1",
        "medianAge": "5"
      }
    },
    "title": "Residential advisors",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "40",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "32",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "18",
        "femaleMedian": "-"
      }
    }
  },
  "Personal care and service workers, all other": {
    "ageData": {
      "2013": {
        "total": "105",
        "16to19": "7",
        "20to24": "18",
        "25to34": "29",
        "35to44": "21",
        "45to54": "11",
        "55to64": "10",
        "65+": "9",
        "medianAge": "10"
      }
    },
    "title": "Personal care and service workers, all other",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "105",
        "women": "48.6",
        "white": "5.6",
        "black": "6.3",
        "asian": "11.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "36",
        "median": "-",
        "maleWorkers": "22",
        "maleMedian": "-",
        "femaleWorkers": "13",
        "femaleMedian": "-"
      }
    }
  },
  "Sales and office occupations": {
    "ageData": {
      "2013": {
        "total": "33,246",
        "16to19": "1,500",
        "20to24": "4,081",
        "25to34": "6,891",
        "35to44": "6,136",
        "45to54": "7,083",
        "55to64": "5,596",
        "65+": "1,960",
        "medianAge": "5,596"
      }
    },
    "title": "Sales and office occupations",
    "depth": 1,
    "parent": "",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "33,246",
        "women": "61.9",
        "white": "11.8",
        "black": "4.9",
        "asian": "14.4"
      }
    },
    "wageData": {
      "2013": {
        "workers": "23,120",
        "median": "659",
        "maleWorkers": "9,112",
        "maleMedian": "756",
        "femaleWorkers": "14,008",
        "femaleMedian": "615"
      }
    }
  },
  "Sales and related occupations": {
    "ageData": {
      "2013": {
        "total": "15,444",
        "16to19": "1,012",
        "20to24": "2,216",
        "25to34": "3,147",
        "35to44": "2,738",
        "45to54": "3,007",
        "55to64": "2,349",
        "65+": "975",
        "medianAge": "2,349"
      }
    },
    "title": "Sales and related occupations",
    "depth": 2,
    "parent": "Sales and office occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "15,444",
        "women": "48.6",
        "white": "10.4",
        "black": "5.6",
        "asian": "14.5"
      }
    },
    "wageData": {
      "2013": {
        "workers": "9,376",
        "median": "708",
        "maleWorkers": "5,371",
        "maleMedian": "835",
        "femaleWorkers": "4,005",
        "femaleMedian": "566"
      }
    }
  },
  "First-line supervisors of retail sales workers": {
    "ageData": {
      "2013": {
        "total": "3,223",
        "16to19": "25",
        "20to24": "262",
        "25to34": "750",
        "35to44": "713",
        "45to54": "746",
        "55to64": "528",
        "65+": "200",
        "medianAge": "528"
      }
    },
    "title": "First-line supervisors of retail sales workers",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "3,223",
        "women": "43.2",
        "white": "8.5",
        "black": "6.9",
        "asian": "11.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "2,320",
        "median": "709",
        "maleWorkers": "1,338",
        "maleMedian": "778",
        "femaleWorkers": "981",
        "femaleMedian": "612"
      }
    }
  },
  "First-line supervisors of non-retail sales workers": {
    "ageData": {
      "2013": {
        "total": "1,188",
        "16to19": "2",
        "20to24": "35",
        "25to34": "206",
        "35to44": "286",
        "45to54": "347",
        "55to64": "223",
        "65+": "89",
        "medianAge": "223"
      }
    },
    "title": "First-line supervisors of non-retail sales workers",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "1,188",
        "women": "24.8",
        "white": "6.5",
        "black": "6.0",
        "asian": "11.6"
      }
    },
    "wageData": {
      "2013": {
        "workers": "757",
        "median": "980",
        "maleWorkers": "540",
        "maleMedian": "1,004",
        "femaleWorkers": "217",
        "femaleMedian": "934"
      }
    }
  },
  "Cashiers": {
    "ageData": {
      "2013": {
        "total": "3,254",
        "16to19": "664",
        "20to24": "887",
        "25to34": "592",
        "35to44": "355",
        "45to54": "343",
        "55to64": "289",
        "65+": "124",
        "medianAge": "289"
      }
    },
    "title": "Cashiers",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "3,254",
        "women": "71.7",
        "white": "18.0",
        "black": "6.8",
        "asian": "21.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "1,350",
        "median": "392",
        "maleWorkers": "418",
        "maleMedian": "426",
        "femaleWorkers": "932",
        "femaleMedian": "379"
      }
    }
  },
  "Counter and rental clerks": {
    "ageData": {
      "2013": {
        "total": "101",
        "16to19": "8",
        "20to24": "14",
        "25to34": "15",
        "35to44": "16",
        "45to54": "20",
        "55to64": "22",
        "65+": "6",
        "medianAge": "22"
      }
    },
    "title": "Counter and rental clerks",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "101",
        "women": "51.4",
        "white": "11.2",
        "black": "5.2",
        "asian": "11.2"
      }
    },
    "wageData": {
      "2013": {
        "workers": "58",
        "median": "612",
        "maleWorkers": "35",
        "maleMedian": "-",
        "femaleWorkers": "24",
        "femaleMedian": "-"
      }
    }
  },
  "Parts salespersons": {
    "ageData": {
      "2013": {
        "total": "98",
        "16to19": "1",
        "20to24": "10",
        "25to34": "25",
        "35to44": "19",
        "45to54": "18",
        "55to64": "23",
        "65+": "2",
        "medianAge": "23"
      }
    },
    "title": "Parts salespersons",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "98",
        "women": "10.4",
        "white": "4.6",
        "black": "3.2",
        "asian": "18.7"
      }
    },
    "wageData": {
      "2013": {
        "workers": "83",
        "median": "663",
        "maleWorkers": "75",
        "maleMedian": "665",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Retail salespersons": {
    "ageData": {
      "2013": {
        "total": "3,230",
        "16to19": "260",
        "20to24": "739",
        "25to34": "644",
        "35to44": "446",
        "45to54": "476",
        "55to64": "455",
        "65+": "209",
        "medianAge": "455"
      }
    },
    "title": "Retail salespersons",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "3,230",
        "women": "49.7",
        "white": "11.7",
        "black": "4.8",
        "asian": "15.7"
      }
    },
    "wageData": {
      "2013": {
        "workers": "1,829",
        "median": "598",
        "maleWorkers": "1,092",
        "maleMedian": "719",
        "femaleWorkers": "737",
        "femaleMedian": "485"
      }
    }
  },
  "Advertising sales agents": {
    "ageData": {
      "2013": {
        "total": "238",
        "16to19": "4",
        "20to24": "18",
        "25to34": "78",
        "35to44": "51",
        "45to54": "41",
        "55to64": "35",
        "65+": "11",
        "medianAge": "35"
      }
    },
    "title": "Advertising sales agents",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "238",
        "women": "47.2",
        "white": "8.2",
        "black": "5.0",
        "asian": "13.4"
      }
    },
    "wageData": {
      "2013": {
        "workers": "207",
        "median": "938",
        "maleWorkers": "110",
        "maleMedian": "1,005",
        "femaleWorkers": "96",
        "femaleMedian": "868"
      }
    }
  },
  "Insurance sales agents": {
    "ageData": {
      "2013": {
        "total": "602",
        "16to19": "4",
        "20to24": "36",
        "25to34": "148",
        "35to44": "108",
        "45to54": "140",
        "55to64": "117",
        "65+": "49",
        "medianAge": "117"
      }
    },
    "title": "Insurance sales agents",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "602",
        "women": "45.2",
        "white": "7.3",
        "black": "5.8",
        "asian": "11.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "431",
        "median": "838",
        "maleWorkers": "216",
        "maleMedian": "1,029",
        "femaleWorkers": "216",
        "femaleMedian": "733"
      }
    }
  },
  "Securities, commodities, and financial services sales agents": {
    "ageData": {
      "2013": {
        "total": "278",
        "16to19": "1",
        "20to24": "18",
        "25to34": "77",
        "35to44": "66",
        "45to54": "66",
        "55to64": "35",
        "65+": "13",
        "medianAge": "35"
      }
    },
    "title": "Securities, commodities, and financial services sales agents",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "278",
        "women": "32.9",
        "white": "5.3",
        "black": "5.7",
        "asian": "8.1"
      }
    },
    "wageData": {
      "2013": {
        "workers": "219",
        "median": "1,119",
        "maleWorkers": "145",
        "maleMedian": "1,389",
        "femaleWorkers": "75",
        "femaleMedian": "863"
      }
    }
  },
  "Travel agents": {
    "ageData": {
      "2013": {
        "total": "77",
        "16to19": "0",
        "20to24": "2",
        "25to34": "7",
        "35to44": "15",
        "45to54": "22",
        "55to64": "16",
        "65+": "16",
        "medianAge": "16"
      }
    },
    "title": "Travel agents",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "77",
        "women": "73.1",
        "white": "7.5",
        "black": "8.8",
        "asian": "8.0"
      }
    },
    "wageData": {
      "2013": {
        "workers": "42",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "32",
        "femaleMedian": "-"
      }
    }
  },
  "Sales representatives, services, all other": {
    "ageData": {
      "2013": {
        "total": "432",
        "16to19": "4",
        "20to24": "34",
        "25to34": "113",
        "35to44": "96",
        "45to54": "101",
        "55to64": "69",
        "65+": "14",
        "medianAge": "69"
      }
    },
    "title": "Sales representatives, services, all other",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "432",
        "women": "34.1",
        "white": "6.4",
        "black": "3.4",
        "asian": "11.5"
      }
    },
    "wageData": {
      "2013": {
        "workers": "348",
        "median": "948",
        "maleWorkers": "242",
        "maleMedian": "1,013",
        "femaleWorkers": "106",
        "femaleMedian": "766"
      }
    }
  },
  "Sales representatives, wholesale and manufacturing": {
    "ageData": {
      "2013": {
        "total": "1,319",
        "16to19": "8",
        "20to24": "63",
        "25to34": "258",
        "35to44": "332",
        "45to54": "351",
        "55to64": "241",
        "65+": "66",
        "medianAge": "241"
      }
    },
    "title": "Sales representatives, wholesale and manufacturing",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "1,319",
        "women": "26.3",
        "white": "4.2",
        "black": "3.1",
        "asian": "10.5"
      }
    },
    "wageData": {
      "2013": {
        "workers": "1,085",
        "median": "1,042",
        "maleWorkers": "838",
        "maleMedian": "1,131",
        "femaleWorkers": "247",
        "femaleMedian": "859"
      }
    }
  },
  "Models, demonstrators, and product promoters": {
    "ageData": {
      "2013": {
        "total": "82",
        "16to19": "5",
        "20to24": "11",
        "25to34": "19",
        "35to44": "10",
        "45to54": "14",
        "55to64": "10",
        "65+": "13",
        "medianAge": "10"
      }
    },
    "title": "Models, demonstrators, and product promoters",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "82",
        "women": "72.5",
        "white": "13.4",
        "black": "5.0",
        "asian": "8.2"
      }
    },
    "wageData": {
      "2013": {
        "workers": "24",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "13",
        "femaleMedian": "-"
      }
    }
  },
  "Real estate brokers and sales agents": {
    "ageData": {
      "2013": {
        "total": "769",
        "16to19": "3",
        "20to24": "22",
        "25to34": "105",
        "35to44": "140",
        "45to54": "195",
        "55to64": "195",
        "65+": "108",
        "medianAge": "195"
      }
    },
    "title": "Real estate brokers and sales agents",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "769",
        "women": "57.6",
        "white": "4.2",
        "black": "4.6",
        "asian": "11.2"
      }
    },
    "wageData": {
      "2013": {
        "workers": "357",
        "median": "809",
        "maleWorkers": "148",
        "maleMedian": "928",
        "femaleWorkers": "209",
        "femaleMedian": "756"
      }
    }
  },
  "Sales engineers": {
    "ageData": {
      "2013": {
        "total": "36",
        "16to19": "0",
        "20to24": "2",
        "25to34": "7",
        "35to44": "7",
        "45to54": "9",
        "55to64": "11",
        "65+": "0",
        "medianAge": "11"
      }
    },
    "title": "Sales engineers",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "36",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "33",
        "median": "-",
        "maleWorkers": "29",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Telemarketers": {
    "ageData": {
      "2013": {
        "total": "93",
        "16to19": "6",
        "20to24": "26",
        "25to34": "26",
        "35to44": "10",
        "45to54": "17",
        "55to64": "6",
        "65+": "2",
        "medianAge": "6"
      }
    },
    "title": "Telemarketers",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "93",
        "women": "70.0",
        "white": "26.1",
        "black": "1.4",
        "asian": "15.4"
      }
    },
    "wageData": {
      "2013": {
        "workers": "49",
        "median": "-",
        "maleWorkers": "18",
        "maleMedian": "-",
        "femaleWorkers": "32",
        "femaleMedian": "-"
      }
    }
  },
  "Door-to-door sales workers, news and street vendors, and related workers": {
    "ageData": {
      "2013": {
        "total": "191",
        "16to19": "5",
        "20to24": "14",
        "25to34": "36",
        "35to44": "32",
        "45to54": "42",
        "55to64": "38",
        "65+": "25",
        "medianAge": "38"
      }
    },
    "title": "Door-to-door sales workers, news and street vendors, and related workers",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "191",
        "women": "59.6",
        "white": "6.6",
        "black": "3.9",
        "asian": "18.8"
      }
    },
    "wageData": {
      "2013": {
        "workers": "34",
        "median": "-",
        "maleWorkers": "23",
        "maleMedian": "-",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "Sales and related workers, all other": {
    "ageData": {
      "2013": {
        "total": "234",
        "16to19": "12",
        "20to24": "25",
        "25to34": "41",
        "35to44": "35",
        "45to54": "57",
        "55to64": "36",
        "65+": "28",
        "medianAge": "36"
      }
    },
    "title": "Sales and related workers, all other",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "234",
        "women": "47.7",
        "white": "8.9",
        "black": "3.5",
        "asian": "9.4"
      }
    },
    "wageData": {
      "2013": {
        "workers": "150",
        "median": "915",
        "maleWorkers": "85",
        "maleMedian": "1,161",
        "femaleWorkers": "65",
        "femaleMedian": "724"
      }
    }
  },
  "Office and administrative support occupations": {
    "ageData": {
      "2013": {
        "total": "17,802",
        "16to19": "488",
        "20to24": "1,864",
        "25to34": "3,743",
        "35to44": "3,398",
        "45to54": "4,076",
        "55to64": "3,247",
        "65+": "985",
        "medianAge": "3,247"
      }
    },
    "title": "Office and administrative support occupations",
    "depth": 2,
    "parent": "Sales and office occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "17,802",
        "women": "73.3",
        "white": "13.1",
        "black": "4.3",
        "asian": "14.4"
      }
    },
    "wageData": {
      "2013": {
        "workers": "13,744",
        "median": "638",
        "maleWorkers": "3,741",
        "maleMedian": "673",
        "femaleWorkers": "10,003",
        "femaleMedian": "628"
      }
    }
  },
  "First-line supervisors of office and administrative support workers": {
    "ageData": {
      "2013": {
        "total": "1,363",
        "16to19": "2",
        "20to24": "54",
        "25to34": "262",
        "35to44": "338",
        "45to54": "361",
        "55to64": "286",
        "65+": "60",
        "medianAge": "286"
      }
    },
    "title": "First-line supervisors of office and administrative support workers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "1,363",
        "women": "69.5",
        "white": "10.4",
        "black": "3.7",
        "asian": "12.6"
      }
    },
    "wageData": {
      "2013": {
        "workers": "1,224",
        "median": "772",
        "maleWorkers": "395",
        "maleMedian": "846",
        "femaleWorkers": "828",
        "femaleMedian": "748"
      }
    }
  },
  "Switchboard operators, including answering service": {
    "ageData": {
      "2013": {
        "total": "32",
        "16to19": "1",
        "20to24": "7",
        "25to34": "3",
        "35to44": "6",
        "45to54": "7",
        "55to64": "5",
        "65+": "3",
        "medianAge": "5"
      }
    },
    "title": "Switchboard operators, including answering service",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "32",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "22",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "16",
        "femaleMedian": "-"
      }
    }
  },
  "Telephone operators": {
    "ageData": {
      "2013": {
        "total": "29",
        "16to19": "1",
        "20to24": "3",
        "25to34": "7",
        "35to44": "4",
        "45to54": "7",
        "55to64": "5",
        "65+": "1",
        "medianAge": "5"
      }
    },
    "title": "Telephone operators",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "29",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "20",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "17",
        "femaleMedian": "-"
      }
    }
  },
  "Communications equipment operators, all other": {
    "ageData": {
      "2013": {
        "total": "3",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "1",
        "45to54": "2",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Communications equipment operators, all other",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Bill and account collectors": {
    "ageData": {
      "2013": {
        "total": "177",
        "16to19": "3",
        "20to24": "15",
        "25to34": "54",
        "35to44": "39",
        "45to54": "36",
        "55to64": "23",
        "65+": "6",
        "medianAge": "23"
      }
    },
    "title": "Bill and account collectors",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "177",
        "women": "66.1",
        "white": "20.3",
        "black": "2.0",
        "asian": "12.0"
      }
    },
    "wageData": {
      "2013": {
        "workers": "156",
        "median": "599",
        "maleWorkers": "45",
        "maleMedian": "-",
        "femaleWorkers": "111",
        "femaleMedian": "586"
      }
    }
  },
  "Billing and posting clerks": {
    "ageData": {
      "2013": {
        "total": "497",
        "16to19": "2",
        "20to24": "29",
        "25to34": "105",
        "35to44": "122",
        "45to54": "127",
        "55to64": "87",
        "65+": "25",
        "medianAge": "87"
      }
    },
    "title": "Billing and posting clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "497",
        "women": "91.7",
        "white": "13.2",
        "black": "4.0",
        "asian": "11.2"
      }
    },
    "wageData": {
      "2013": {
        "workers": "422",
        "median": "637",
        "maleWorkers": "42",
        "maleMedian": "-",
        "femaleWorkers": "380",
        "femaleMedian": "629"
      }
    }
  },
  "Bookkeeping, accounting, and auditing clerks": {
    "ageData": {
      "2013": {
        "total": "1,241",
        "16to19": "5",
        "20to24": "59",
        "25to34": "187",
        "35to44": "236",
        "45to54": "319",
        "55to64": "296",
        "65+": "138",
        "medianAge": "296"
      }
    },
    "title": "Bookkeeping, accounting, and auditing clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "1,241",
        "women": "88.7",
        "white": "6.4",
        "black": "5.7",
        "asian": "8.5"
      }
    },
    "wageData": {
      "2013": {
        "workers": "804",
        "median": "677",
        "maleWorkers": "102",
        "maleMedian": "751",
        "femaleWorkers": "702",
        "femaleMedian": "670"
      }
    }
  },
  "Gaming cage workers": {
    "ageData": {
      "2013": {
        "total": "14",
        "16to19": "0",
        "20to24": "2",
        "25to34": "4",
        "35to44": "5",
        "45to54": "3",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Gaming cage workers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "14",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Payroll and timekeeping clerks": {
    "ageData": {
      "2013": {
        "total": "156",
        "16to19": "0",
        "20to24": "6",
        "25to34": "24",
        "35to44": "33",
        "45to54": "46",
        "55to64": "39",
        "65+": "7",
        "medianAge": "39"
      }
    },
    "title": "Payroll and timekeeping clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "156",
        "women": "92.4",
        "white": "14.4",
        "black": "3.9",
        "asian": "12.2"
      }
    },
    "wageData": {
      "2013": {
        "workers": "128",
        "median": "731",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "118",
        "femaleMedian": "727"
      }
    }
  },
  "Procurement clerks": {
    "ageData": {
      "2013": {
        "total": "27",
        "16to19": "0",
        "20to24": "0",
        "25to34": "5",
        "35to44": "6",
        "45to54": "7",
        "55to64": "8",
        "65+": "0",
        "medianAge": "8"
      }
    },
    "title": "Procurement clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "27",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "30",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "18",
        "femaleMedian": "-"
      }
    }
  },
  "Tellers": {
    "ageData": {
      "2013": {
        "total": "369",
        "16to19": "19",
        "20to24": "101",
        "25to34": "94",
        "35to44": "44",
        "45to54": "50",
        "55to64": "51",
        "65+": "11",
        "medianAge": "51"
      }
    },
    "title": "Tellers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "369",
        "women": "84.3",
        "white": "11.3",
        "black": "4.9",
        "asian": "21.1"
      }
    },
    "wageData": {
      "2013": {
        "workers": "265",
        "median": "494",
        "maleWorkers": "36",
        "maleMedian": "-",
        "femaleWorkers": "229",
        "femaleMedian": "494"
      }
    }
  },
  "Financial clerks, all other": {
    "ageData": {
      "2013": {
        "total": "55",
        "16to19": "0",
        "20to24": "6",
        "25to34": "10",
        "35to44": "13",
        "45to54": "11",
        "55to64": "11",
        "65+": "3",
        "medianAge": "11"
      }
    },
    "title": "Financial clerks, all other",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "55",
        "women": "66.4",
        "white": "19.7",
        "black": "7.9",
        "asian": "10.0"
      }
    },
    "wageData": {
      "2013": {
        "workers": "54",
        "median": "686",
        "maleWorkers": "20",
        "maleMedian": "-",
        "femaleWorkers": "34",
        "femaleMedian": "-"
      }
    }
  },
  "Brokerage clerks": {
    "ageData": {
      "2013": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "1",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Brokerage clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Correspondence clerks": {
    "ageData": {
      "2013": {
        "total": "11",
        "16to19": "0",
        "20to24": "1",
        "25to34": "4",
        "35to44": "3",
        "45to54": "1",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Correspondence clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "11",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Court, municipal, and license clerks": {
    "ageData": {
      "2013": {
        "total": "81",
        "16to19": "0",
        "20to24": "3",
        "25to34": "14",
        "35to44": "16",
        "45to54": "28",
        "55to64": "19",
        "65+": "2",
        "medianAge": "19"
      }
    },
    "title": "Court, municipal, and license clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "81",
        "women": "69.4",
        "white": "19.7",
        "black": "3.0",
        "asian": "13.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "74",
        "median": "676",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "53",
        "femaleMedian": "665"
      }
    }
  },
  "Credit authorizers, checkers, and clerks": {
    "ageData": {
      "2013": {
        "total": "40",
        "16to19": "0",
        "20to24": "2",
        "25to34": "10",
        "35to44": "6",
        "45to54": "11",
        "55to64": "10",
        "65+": "1",
        "medianAge": "10"
      }
    },
    "title": "Credit authorizers, checkers, and clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "40",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "40",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "30",
        "femaleMedian": "-"
      }
    }
  },
  "Customer service representatives": {
    "ageData": {
      "2013": {
        "total": "2,069",
        "16to19": "109",
        "20to24": "306",
        "25to34": "566",
        "35to44": "388",
        "45to54": "380",
        "55to64": "255",
        "65+": "67",
        "medianAge": "255"
      }
    },
    "title": "Customer service representatives",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "2,069",
        "women": "65.8",
        "white": "17.0",
        "black": "4.7",
        "asian": "16.6"
      }
    },
    "wageData": {
      "2013": {
        "workers": "1,615",
        "median": "621",
        "maleWorkers": "547",
        "maleMedian": "639",
        "femaleWorkers": "1,068",
        "femaleMedian": "616"
      }
    }
  },
  "Eligibility interviewers, government programs": {
    "ageData": {
      "2013": {
        "total": "75",
        "16to19": "1",
        "20to24": "1",
        "25to34": "17",
        "35to44": "17",
        "45to54": "22",
        "55to64": "13",
        "65+": "4",
        "medianAge": "13"
      }
    },
    "title": "Eligibility interviewers, government programs",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "75",
        "women": "80.5",
        "white": "20.2",
        "black": "4.3",
        "asian": "21.6"
      }
    },
    "wageData": {
      "2013": {
        "workers": "75",
        "median": "778",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "60",
        "femaleMedian": "746"
      }
    }
  },
  "File clerks": {
    "ageData": {
      "2013": {
        "total": "257",
        "16to19": "19",
        "20to24": "34",
        "25to34": "48",
        "35to44": "46",
        "45to54": "50",
        "55to64": "44",
        "65+": "16",
        "medianAge": "44"
      }
    },
    "title": "File clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "257",
        "women": "80.1",
        "white": "10.6",
        "black": "5.5",
        "asian": "18.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "182",
        "median": "625",
        "maleWorkers": "42",
        "maleMedian": "-",
        "femaleWorkers": "140",
        "femaleMedian": "604"
      }
    }
  },
  "Hotel, motel, and resort desk clerks": {
    "ageData": {
      "2013": {
        "total": "112",
        "16to19": "5",
        "20to24": "38",
        "25to34": "30",
        "35to44": "12",
        "45to54": "12",
        "55to64": "11",
        "65+": "4",
        "medianAge": "11"
      }
    },
    "title": "Hotel, motel, and resort desk clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "112",
        "women": "72.8",
        "white": "17.1",
        "black": "6.1",
        "asian": "20.7"
      }
    },
    "wageData": {
      "2013": {
        "workers": "71",
        "median": "418",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "50",
        "femaleMedian": "417"
      }
    }
  },
  "Interviewers, except eligibility and loan": {
    "ageData": {
      "2013": {
        "total": "146",
        "16to19": "2",
        "20to24": "13",
        "25to34": "38",
        "35to44": "26",
        "45to54": "40",
        "55to64": "21",
        "65+": "6",
        "medianAge": "21"
      }
    },
    "title": "Interviewers, except eligibility and loan",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "146",
        "women": "80.9",
        "white": "24.9",
        "black": "6.4",
        "asian": "15.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "110",
        "median": "621",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "89",
        "femaleMedian": "605"
      }
    }
  },
  "Library assistants, clerical": {
    "ageData": {
      "2013": {
        "total": "87",
        "16to19": "9",
        "20to24": "15",
        "25to34": "15",
        "35to44": "9",
        "45to54": "16",
        "55to64": "17",
        "65+": "7",
        "medianAge": "17"
      }
    },
    "title": "Library assistants, clerical",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "87",
        "women": "79.2",
        "white": "9.6",
        "black": "5.0",
        "asian": "13.2"
      }
    },
    "wageData": {
      "2013": {
        "workers": "50",
        "median": "559",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "42",
        "femaleMedian": "-"
      }
    }
  },
  "Loan interviewers and clerks": {
    "ageData": {
      "2013": {
        "total": "168",
        "16to19": "0",
        "20to24": "10",
        "25to34": "57",
        "35to44": "43",
        "45to54": "31",
        "55to64": "18",
        "65+": "8",
        "medianAge": "18"
      }
    },
    "title": "Loan interviewers and clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "168",
        "women": "78.0",
        "white": "10.2",
        "black": "2.7",
        "asian": "16.6"
      }
    },
    "wageData": {
      "2013": {
        "workers": "158",
        "median": "719",
        "maleWorkers": "29",
        "maleMedian": "-",
        "femaleWorkers": "129",
        "femaleMedian": "688"
      }
    }
  },
  "New accounts clerks": {
    "ageData": {
      "2013": {
        "total": "25",
        "16to19": "1",
        "20to24": "2",
        "25to34": "8",
        "35to44": "5",
        "45to54": "4",
        "55to64": "4",
        "65+": "1",
        "medianAge": "4"
      }
    },
    "title": "New accounts clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "25",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "29",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "26",
        "femaleMedian": "-"
      }
    }
  },
  "Order clerks": {
    "ageData": {
      "2013": {
        "total": "94",
        "16to19": "5",
        "20to24": "12",
        "25to34": "23",
        "35to44": "17",
        "45to54": "18",
        "55to64": "14",
        "65+": "4",
        "medianAge": "14"
      }
    },
    "title": "Order clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "94",
        "women": "61.2",
        "white": "11.9",
        "black": "6.9",
        "asian": "23.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "79",
        "median": "583",
        "maleWorkers": "37",
        "maleMedian": "-",
        "femaleWorkers": "42",
        "femaleMedian": "-"
      }
    }
  },
  "Human resources assistants, except payroll and timekeeping": {
    "ageData": {
      "2013": {
        "total": "133",
        "16to19": "1",
        "20to24": "5",
        "25to34": "37",
        "35to44": "34",
        "45to54": "31",
        "55to64": "20",
        "65+": "5",
        "medianAge": "20"
      }
    },
    "title": "Human resources assistants, except payroll and timekeeping",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "133",
        "women": "82.0",
        "white": "15.6",
        "black": "4.2",
        "asian": "11.2"
      }
    },
    "wageData": {
      "2013": {
        "workers": "129",
        "median": "877",
        "maleWorkers": "26",
        "maleMedian": "-",
        "femaleWorkers": "103",
        "femaleMedian": "873"
      }
    }
  },
  "Receptionists and information clerks": {
    "ageData": {
      "2013": {
        "total": "1,326",
        "16to19": "63",
        "20to24": "243",
        "25to34": "311",
        "35to44": "206",
        "45to54": "234",
        "55to64": "191",
        "65+": "79",
        "medianAge": "191"
      }
    },
    "title": "Receptionists and information clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "1,326",
        "women": "92.2",
        "white": "11.0",
        "black": "4.7",
        "asian": "18.4"
      }
    },
    "wageData": {
      "2013": {
        "workers": "901",
        "median": "536",
        "maleWorkers": "73",
        "maleMedian": "600",
        "femaleWorkers": "828",
        "femaleMedian": "527"
      }
    }
  },
  "Reservation and transportation ticket agents and travel clerks": {
    "ageData": {
      "2013": {
        "total": "119",
        "16to19": "0",
        "20to24": "9",
        "25to34": "32",
        "35to44": "19",
        "45to54": "31",
        "55to64": "24",
        "65+": "5",
        "medianAge": "24"
      }
    },
    "title": "Reservation and transportation ticket agents and travel clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "119",
        "women": "58.9",
        "white": "17.6",
        "black": "9.1",
        "asian": "15.8"
      }
    },
    "wageData": {
      "2013": {
        "workers": "100",
        "median": "699",
        "maleWorkers": "46",
        "maleMedian": "-",
        "femaleWorkers": "54",
        "femaleMedian": "620"
      }
    }
  },
  "Information and record clerks, all other": {
    "ageData": {
      "2013": {
        "total": "103",
        "16to19": "2",
        "20to24": "11",
        "25to34": "22",
        "35to44": "16",
        "45to54": "24",
        "55to64": "22",
        "65+": "7",
        "medianAge": "22"
      }
    },
    "title": "Information and record clerks, all other",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "103",
        "women": "74.9",
        "white": "14.1",
        "black": "3.3",
        "asian": "11.4"
      }
    },
    "wageData": {
      "2013": {
        "workers": "85",
        "median": "710",
        "maleWorkers": "23",
        "maleMedian": "-",
        "femaleWorkers": "62",
        "femaleMedian": "727"
      }
    }
  },
  "Cargo and freight agents": {
    "ageData": {
      "2013": {
        "total": "28",
        "16to19": "0",
        "20to24": "2",
        "25to34": "8",
        "35to44": "7",
        "45to54": "7",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Cargo and freight agents",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "28",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "26",
        "median": "-",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "9",
        "femaleMedian": "-"
      }
    }
  },
  "Couriers and messengers": {
    "ageData": {
      "2013": {
        "total": "198",
        "16to19": "4",
        "20to24": "15",
        "25to34": "34",
        "35to44": "41",
        "45to54": "45",
        "55to64": "36",
        "65+": "22",
        "medianAge": "36"
      }
    },
    "title": "Couriers and messengers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "198",
        "women": "15.3",
        "white": "14.2",
        "black": "3.2",
        "asian": "18.5"
      }
    },
    "wageData": {
      "2013": {
        "workers": "127",
        "median": "624",
        "maleWorkers": "112",
        "maleMedian": "616",
        "femaleWorkers": "16",
        "femaleMedian": "-"
      }
    }
  },
  "Dispatchers": {
    "ageData": {
      "2013": {
        "total": "272",
        "16to19": "4",
        "20to24": "20",
        "25to34": "76",
        "35to44": "65",
        "45to54": "53",
        "55to64": "45",
        "65+": "9",
        "medianAge": "45"
      }
    },
    "title": "Dispatchers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "272",
        "women": "57.0",
        "white": "15.0",
        "black": "1.3",
        "asian": "12.7"
      }
    },
    "wageData": {
      "2013": {
        "workers": "246",
        "median": "676",
        "maleWorkers": "107",
        "maleMedian": "747",
        "femaleWorkers": "140",
        "femaleMedian": "617"
      }
    }
  },
  "Meter readers, utilities": {
    "ageData": {
      "2013": {
        "total": "29",
        "16to19": "0",
        "20to24": "5",
        "25to34": "7",
        "35to44": "5",
        "45to54": "3",
        "55to64": "5",
        "65+": "3",
        "medianAge": "5"
      }
    },
    "title": "Meter readers, utilities",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "29",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "24",
        "median": "-",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Postal service clerks": {
    "ageData": {
      "2013": {
        "total": "100",
        "16to19": "0",
        "20to24": "3",
        "25to34": "6",
        "35to44": "18",
        "45to54": "33",
        "55to64": "37",
        "65+": "3",
        "medianAge": "37"
      }
    },
    "title": "Postal service clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "100",
        "women": "54.8",
        "white": "21.6",
        "black": "7.2",
        "asian": "13.6"
      }
    },
    "wageData": {
      "2013": {
        "workers": "90",
        "median": "968",
        "maleWorkers": "45",
        "maleMedian": "-",
        "femaleWorkers": "45",
        "femaleMedian": "-"
      }
    }
  },
  "Postal service mail carriers": {
    "ageData": {
      "2013": {
        "total": "316",
        "16to19": "1",
        "20to24": "10",
        "25to34": "38",
        "35to44": "69",
        "45to54": "101",
        "55to64": "85",
        "65+": "13",
        "medianAge": "85"
      }
    },
    "title": "Postal service mail carriers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "316",
        "women": "39.1",
        "white": "14.6",
        "black": "6.7",
        "asian": "12.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "277",
        "median": "964",
        "maleWorkers": "176",
        "maleMedian": "1,012",
        "femaleWorkers": "101",
        "femaleMedian": "878"
      }
    }
  },
  "Postal service mail sorters, processors, and processing machine operators": {
    "ageData": {
      "2013": {
        "total": "74",
        "16to19": "1",
        "20to24": "5",
        "25to34": "8",
        "35to44": "11",
        "45to54": "25",
        "55to64": "21",
        "65+": "3",
        "medianAge": "21"
      }
    },
    "title": "Postal service mail sorters, processors, and processing machine operators",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "74",
        "women": "54.6",
        "white": "31.0",
        "black": "12.6",
        "asian": "6.4"
      }
    },
    "wageData": {
      "2013": {
        "workers": "70",
        "median": "873",
        "maleWorkers": "35",
        "maleMedian": "-",
        "femaleWorkers": "35",
        "femaleMedian": "-"
      }
    }
  },
  "Production, planning, and expediting clerks": {
    "ageData": {
      "2013": {
        "total": "282",
        "16to19": "3",
        "20to24": "27",
        "25to34": "47",
        "35to44": "60",
        "45to54": "73",
        "55to64": "61",
        "65+": "10",
        "medianAge": "61"
      }
    },
    "title": "Production, planning, and expediting clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "282",
        "women": "54.8",
        "white": "11.9",
        "black": "4.4",
        "asian": "10.8"
      }
    },
    "wageData": {
      "2013": {
        "workers": "272",
        "median": "745",
        "maleWorkers": "124",
        "maleMedian": "864",
        "femaleWorkers": "148",
        "femaleMedian": "690"
      }
    }
  },
  "Shipping, receiving, and traffic clerks": {
    "ageData": {
      "2013": {
        "total": "563",
        "16to19": "10",
        "20to24": "81",
        "25to34": "120",
        "35to44": "107",
        "45to54": "141",
        "55to64": "86",
        "65+": "18",
        "medianAge": "86"
      }
    },
    "title": "Shipping, receiving, and traffic clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "563",
        "women": "30.2",
        "white": "16.6",
        "black": "3.0",
        "asian": "24.5"
      }
    },
    "wageData": {
      "2013": {
        "workers": "493",
        "median": "539",
        "maleWorkers": "342",
        "maleMedian": "547",
        "femaleWorkers": "151",
        "femaleMedian": "524"
      }
    }
  },
  "Stock clerks and order fillers": {
    "ageData": {
      "2013": {
        "total": "1,508",
        "16to19": "121",
        "20to24": "303",
        "25to34": "353",
        "35to44": "226",
        "45to54": "241",
        "55to64": "190",
        "65+": "72",
        "medianAge": "190"
      }
    },
    "title": "Stock clerks and order fillers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "1,508",
        "women": "36.7",
        "white": "17.9",
        "black": "3.7",
        "asian": "18.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "918",
        "median": "496",
        "maleWorkers": "596",
        "maleMedian": "503",
        "femaleWorkers": "321",
        "femaleMedian": "484"
      }
    }
  },
  "Weighers, measurers, checkers, and samplers, recordkeeping": {
    "ageData": {
      "2013": {
        "total": "84",
        "16to19": "1",
        "20to24": "4",
        "25to34": "16",
        "35to44": "15",
        "45to54": "22",
        "55to64": "21",
        "65+": "3",
        "medianAge": "21"
      }
    },
    "title": "Weighers, measurers, checkers, and samplers, recordkeeping",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "84",
        "women": "50.3",
        "white": "10.8",
        "black": "0.5",
        "asian": "18.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "74",
        "median": "624",
        "maleWorkers": "37",
        "maleMedian": "-",
        "femaleWorkers": "37",
        "femaleMedian": "-"
      }
    }
  },
  "Secretaries and administrative assistants": {
    "ageData": {
      "2013": {
        "total": "2,922",
        "16to19": "28",
        "20to24": "135",
        "25to34": "504",
        "35to44": "543",
        "45to54": "821",
        "55to64": "690",
        "65+": "200",
        "medianAge": "690"
      }
    },
    "title": "Secretaries and administrative assistants",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "2,922",
        "women": "94.4",
        "white": "9.3",
        "black": "3.3",
        "asian": "10.5"
      }
    },
    "wageData": {
      "2013": {
        "workers": "2,232",
        "median": "681",
        "maleWorkers": "120",
        "maleMedian": "772",
        "femaleWorkers": "2,113",
        "femaleMedian": "677"
      }
    }
  },
  "Computer operators": {
    "ageData": {
      "2013": {
        "total": "95",
        "16to19": "1",
        "20to24": "5",
        "25to34": "15",
        "35to44": "21",
        "45to54": "31",
        "55to64": "18",
        "65+": "4",
        "medianAge": "18"
      }
    },
    "title": "Computer operators",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "95",
        "women": "52.4",
        "white": "11.0",
        "black": "6.3",
        "asian": "13.1"
      }
    },
    "wageData": {
      "2013": {
        "workers": "80",
        "median": "732",
        "maleWorkers": "41",
        "maleMedian": "-",
        "femaleWorkers": "39",
        "femaleMedian": "-"
      }
    }
  },
  "Data entry keyers": {
    "ageData": {
      "2013": {
        "total": "307",
        "16to19": "8",
        "20to24": "39",
        "25to34": "60",
        "35to44": "68",
        "45to54": "62",
        "55to64": "59",
        "65+": "12",
        "medianAge": "59"
      }
    },
    "title": "Data entry keyers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "307",
        "women": "80.3",
        "white": "15.1",
        "black": "5.7",
        "asian": "11.6"
      }
    },
    "wageData": {
      "2013": {
        "workers": "235",
        "median": "634",
        "maleWorkers": "46",
        "maleMedian": "-",
        "femaleWorkers": "189",
        "femaleMedian": "632"
      }
    }
  },
  "Word processors and typists": {
    "ageData": {
      "2013": {
        "total": "101",
        "16to19": "2",
        "20to24": "7",
        "25to34": "20",
        "35to44": "17",
        "45to54": "24",
        "55to64": "22",
        "65+": "10",
        "medianAge": "22"
      }
    },
    "title": "Word processors and typists",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "101",
        "women": "94.1",
        "white": "16.3",
        "black": "2.1",
        "asian": "10.8"
      }
    },
    "wageData": {
      "2013": {
        "workers": "90",
        "median": "621",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "82",
        "femaleMedian": "607"
      }
    }
  },
  "Desktop publishers": {
    "ageData": {
      "2013": {
        "total": "1",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "1",
        "medianAge": "0"
      }
    },
    "title": "Desktop publishers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "0",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Insurance claims and policy processing clerks": {
    "ageData": {
      "2013": {
        "total": "287",
        "16to19": "2",
        "20to24": "28",
        "25to34": "72",
        "35to44": "62",
        "45to54": "72",
        "55to64": "42",
        "65+": "10",
        "medianAge": "42"
      }
    },
    "title": "Insurance claims and policy processing clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "287",
        "women": "81.0",
        "white": "17.3",
        "black": "2.7",
        "asian": "10.2"
      }
    },
    "wageData": {
      "2013": {
        "workers": "252",
        "median": "652",
        "maleWorkers": "46",
        "maleMedian": "-",
        "femaleWorkers": "206",
        "femaleMedian": "631"
      }
    }
  },
  "Mail clerks and mail machine operators, except postal service": {
    "ageData": {
      "2013": {
        "total": "75",
        "16to19": "1",
        "20to24": "6",
        "25to34": "9",
        "35to44": "12",
        "45to54": "19",
        "55to64": "22",
        "65+": "7",
        "medianAge": "22"
      }
    },
    "title": "Mail clerks and mail machine operators, except postal service",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "75",
        "women": "53.5",
        "white": "17.9",
        "black": "7.1",
        "asian": "16.7"
      }
    },
    "wageData": {
      "2013": {
        "workers": "48",
        "median": "-",
        "maleWorkers": "26",
        "maleMedian": "-",
        "femaleWorkers": "22",
        "femaleMedian": "-"
      }
    }
  },
  "Office clerks, general": {
    "ageData": {
      "2013": {
        "total": "1,184",
        "16to19": "41",
        "20to24": "135",
        "25to34": "237",
        "35to44": "227",
        "45to54": "257",
        "55to64": "202",
        "65+": "85",
        "medianAge": "202"
      }
    },
    "title": "Office clerks, general",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "1,184",
        "women": "84.4",
        "white": "13.5",
        "black": "5.1",
        "asian": "17.1"
      }
    },
    "wageData": {
      "2013": {
        "workers": "852",
        "median": "598",
        "maleWorkers": "118",
        "maleMedian": "620",
        "femaleWorkers": "734",
        "femaleMedian": "596"
      }
    }
  },
  "Office machine operators, except computer": {
    "ageData": {
      "2013": {
        "total": "41",
        "16to19": "0",
        "20to24": "5",
        "25to34": "12",
        "35to44": "4",
        "45to54": "10",
        "55to64": "10",
        "65+": "1",
        "medianAge": "10"
      }
    },
    "title": "Office machine operators, except computer",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "41",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "35",
        "median": "-",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "21",
        "femaleMedian": "-"
      }
    }
  },
  "Proofreaders and copy markers": {
    "ageData": {
      "2013": {
        "total": "7",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "1",
        "45to54": "2",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Proofreaders and copy markers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Statistical assistants": {
    "ageData": {
      "2013": {
        "total": "24",
        "16to19": "0",
        "20to24": "3",
        "25to34": "5",
        "35to44": "5",
        "45to54": "6",
        "55to64": "3",
        "65+": "2",
        "medianAge": "3"
      }
    },
    "title": "Statistical assistants",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "24",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "18",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Office and administrative support workers, all other": {
    "ageData": {
      "2013": {
        "total": "494",
        "16to19": "9",
        "20to24": "39",
        "25to34": "110",
        "35to44": "103",
        "45to54": "118",
        "55to64": "91",
        "65+": "24",
        "medianAge": "91"
      }
    },
    "title": "Office and administrative support workers, all other",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "494",
        "women": "77.4",
        "white": "10.8",
        "black": "3.5",
        "asian": "10.2"
      }
    },
    "wageData": {
      "2013": {
        "workers": "431",
        "median": "724",
        "maleWorkers": "100",
        "maleMedian": "795",
        "femaleWorkers": "331",
        "femaleMedian": "695"
      }
    }
  },
  "Natural resources, construction, and maintenance occupations": {
    "ageData": {
      "2013": {
        "total": "13,058",
        "16to19": "295",
        "20to24": "1,138",
        "25to34": "3,052",
        "35to44": "3,014",
        "45to54": "3,185",
        "55to64": "1,929",
        "65+": "446",
        "medianAge": "1,929"
      }
    },
    "title": "Natural resources, construction, and maintenance occupations",
    "depth": 1,
    "parent": "",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "13,058",
        "women": "4.6",
        "white": "7.0",
        "black": "2.2",
        "asian": "26.6"
      }
    },
    "wageData": {
      "2013": {
        "workers": "10,341",
        "median": "747",
        "maleWorkers": "9,906",
        "maleMedian": "757",
        "femaleWorkers": "434",
        "femaleMedian": "578"
      }
    }
  },
  "Farming, fishing, and forestry occupations": {
    "ageData": {
      "2013": {
        "total": "964",
        "16to19": "89",
        "20to24": "138",
        "25to34": "225",
        "35to44": "189",
        "45to54": "165",
        "55to64": "114",
        "65+": "44",
        "medianAge": "114"
      }
    },
    "title": "Farming, fishing, and forestry occupations",
    "depth": 2,
    "parent": "Natural resources, construction, and maintenance occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "964",
        "women": "21.7",
        "white": "5.6",
        "black": "1.8",
        "asian": "42.8"
      }
    },
    "wageData": {
      "2013": {
        "workers": "720",
        "median": "448",
        "maleWorkers": "576",
        "maleMedian": "472",
        "femaleWorkers": "144",
        "femaleMedian": "368"
      }
    }
  },
  "First-line supervisors of farming, fishing, and forestry workers": {
    "ageData": {
      "2013": {
        "total": "48",
        "16to19": "1",
        "20to24": "3",
        "25to34": "11",
        "35to44": "10",
        "45to54": "12",
        "55to64": "8",
        "65+": "4",
        "medianAge": "8"
      }
    },
    "title": "First-line supervisors of farming, fishing, and forestry workers",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "48",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "34",
        "median": "-",
        "maleWorkers": "25",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Agricultural inspectors": {
    "ageData": {
      "2013": {
        "total": "12",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "4",
        "45to54": "4",
        "55to64": "1",
        "65+": "2",
        "medianAge": "1"
      }
    },
    "title": "Agricultural inspectors",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "12",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "11",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Animal breeders": {
    "ageData": {
      "2013": {
        "total": "4",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "2",
        "45to54": "1",
        "55to64": "0",
        "65+": "1",
        "medianAge": "0"
      }
    },
    "title": "Animal breeders",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Graders and sorters, agricultural products": {
    "ageData": {
      "2013": {
        "total": "94",
        "16to19": "3",
        "20to24": "10",
        "25to34": "19",
        "35to44": "21",
        "45to54": "19",
        "55to64": "18",
        "65+": "4",
        "medianAge": "18"
      }
    },
    "title": "Graders and sorters, agricultural products",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "94",
        "women": "61.5",
        "white": "15.5",
        "black": "4.4",
        "asian": "47.0"
      }
    },
    "wageData": {
      "2013": {
        "workers": "91",
        "median": "416",
        "maleWorkers": "34",
        "maleMedian": "-",
        "femaleWorkers": "57",
        "femaleMedian": "389"
      }
    }
  },
  "Miscellaneous agricultural workers": {
    "ageData": {
      "2013": {
        "total": "679",
        "16to19": "79",
        "20to24": "112",
        "25to34": "179",
        "35to44": "122",
        "45to54": "98",
        "55to64": "67",
        "65+": "23",
        "medianAge": "67"
      }
    },
    "title": "Miscellaneous agricultural workers",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "679",
        "women": "19.1",
        "white": "4.0",
        "black": "1.3",
        "asian": "50.0"
      }
    },
    "wageData": {
      "2013": {
        "workers": "516",
        "median": "428",
        "maleWorkers": "445",
        "maleMedian": "447",
        "femaleWorkers": "71",
        "femaleMedian": "352"
      }
    }
  },
  "Fishers and related fishing workers": {
    "ageData": {
      "2013": {
        "total": "40",
        "16to19": "2",
        "20to24": "2",
        "25to34": "6",
        "35to44": "9",
        "45to54": "11",
        "55to64": "6",
        "65+": "5",
        "medianAge": "6"
      }
    },
    "title": "Fishers and related fishing workers",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "40",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "16",
        "median": "-",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Hunters and trappers": {
    "ageData": {
      "2013": {
        "total": "3",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "1",
        "45to54": "0",
        "55to64": "0",
        "65+": "1",
        "medianAge": "0"
      }
    },
    "title": "Hunters and trappers",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "0",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Forest and conservation workers": {
    "ageData": {
      "2013": {
        "total": "18",
        "16to19": "0",
        "20to24": "2",
        "25to34": "2",
        "35to44": "4",
        "45to54": "4",
        "55to64": "2",
        "65+": "3",
        "medianAge": "2"
      }
    },
    "title": "Forest and conservation workers",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "18",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "11",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Logging workers": {
    "ageData": {
      "2013": {
        "total": "67",
        "16to19": "4",
        "20to24": "9",
        "25to34": "6",
        "35to44": "18",
        "45to54": "17",
        "55to64": "12",
        "65+": "1",
        "medianAge": "12"
      }
    },
    "title": "Logging workers",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "67",
        "women": "2.1",
        "white": "8.6",
        "black": "0.0",
        "asian": "6.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "39",
        "median": "-",
        "maleWorkers": "37",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Construction and extraction occupations": {
    "ageData": {
      "2013": {
        "total": "7,130",
        "16to19": "139",
        "20to24": "582",
        "25to34": "1,755",
        "35to44": "1,716",
        "45to54": "1,729",
        "55to64": "998",
        "65+": "213",
        "medianAge": "998"
      }
    },
    "title": "Construction and extraction occupations",
    "depth": 2,
    "parent": "Natural resources, construction, and maintenance occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "7,130",
        "women": "2.6",
        "white": "6.2",
        "black": "1.6",
        "asian": "31.0"
      }
    },
    "wageData": {
      "2013": {
        "workers": "5,353",
        "median": "732",
        "maleWorkers": "5,242",
        "maleMedian": "736",
        "femaleWorkers": "112",
        "femaleMedian": "654"
      }
    }
  },
  "First-line supervisors of construction trades and extraction workers": {
    "ageData": {
      "2013": {
        "total": "631",
        "16to19": "0",
        "20to24": "18",
        "25to34": "119",
        "35to44": "155",
        "45to54": "191",
        "55to64": "121",
        "65+": "26",
        "medianAge": "121"
      }
    },
    "title": "First-line supervisors of construction trades and extraction workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "631",
        "women": "2.1",
        "white": "3.5",
        "black": "1.2",
        "asian": "15.7"
      }
    },
    "wageData": {
      "2013": {
        "workers": "509",
        "median": "990",
        "maleWorkers": "501",
        "maleMedian": "996",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Boilermakers": {
    "ageData": {
      "2013": {
        "total": "18",
        "16to19": "0",
        "20to24": "0",
        "25to34": "5",
        "35to44": "5",
        "45to54": "5",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Boilermakers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "18",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "14",
        "median": "-",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Brickmasons, blockmasons, and stonemasons": {
    "ageData": {
      "2013": {
        "total": "130",
        "16to19": "2",
        "20to24": "7",
        "25to34": "35",
        "35to44": "43",
        "45to54": "25",
        "55to64": "14",
        "65+": "4",
        "medianAge": "14"
      }
    },
    "title": "Brickmasons, blockmasons, and stonemasons",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "130",
        "women": "0.7",
        "white": "2.5",
        "black": "0.3",
        "asian": "41.0"
      }
    },
    "wageData": {
      "2013": {
        "workers": "106",
        "median": "768",
        "maleWorkers": "106",
        "maleMedian": "766",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Carpenters": {
    "ageData": {
      "2013": {
        "total": "1,164",
        "16to19": "16",
        "20to24": "84",
        "25to34": "292",
        "35to44": "274",
        "45to54": "294",
        "55to64": "162",
        "65+": "42",
        "medianAge": "162"
      }
    },
    "title": "Carpenters",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "1,164",
        "women": "1.6",
        "white": "5.4",
        "black": "1.4",
        "asian": "27.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "712",
        "median": "657",
        "maleWorkers": "703",
        "maleMedian": "659",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Carpet, floor, and tile installers and finishers": {
    "ageData": {
      "2013": {
        "total": "142",
        "16to19": "3",
        "20to24": "11",
        "25to34": "38",
        "35to44": "38",
        "45to54": "34",
        "55to64": "16",
        "65+": "2",
        "medianAge": "16"
      }
    },
    "title": "Carpet, floor, and tile installers and finishers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "142",
        "women": "3.5",
        "white": "4.3",
        "black": "1.5",
        "asian": "46.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "80",
        "median": "582",
        "maleWorkers": "78",
        "maleMedian": "579",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Cement masons, concrete finishers, and terrazzo workers": {
    "ageData": {
      "2013": {
        "total": "52",
        "16to19": "2",
        "20to24": "4",
        "25to34": "11",
        "35to44": "14",
        "45to54": "18",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Cement masons, concrete finishers, and terrazzo workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "52",
        "women": "0.0",
        "white": "5.2",
        "black": "0.0",
        "asian": "52.5"
      }
    },
    "wageData": {
      "2013": {
        "workers": "46",
        "median": "-",
        "maleWorkers": "46",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Construction laborers": {
    "ageData": {
      "2013": {
        "total": "1,536",
        "16to19": "55",
        "20to24": "175",
        "25to34": "416",
        "35to44": "346",
        "45to54": "322",
        "55to64": "181",
        "65+": "41",
        "medianAge": "181"
      }
    },
    "title": "Construction laborers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "1,536",
        "women": "3.6",
        "white": "6.8",
        "black": "1.9",
        "asian": "44.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "1,087",
        "median": "594",
        "maleWorkers": "1,058",
        "maleMedian": "592",
        "femaleWorkers": "29",
        "femaleMedian": "-"
      }
    }
  },
  "Paving, surfacing, and tamping equipment operators": {
    "ageData": {
      "2013": {
        "total": "22",
        "16to19": "0",
        "20to24": "3",
        "25to34": "6",
        "35to44": "4",
        "45to54": "3",
        "55to64": "5",
        "65+": "0",
        "medianAge": "5"
      }
    },
    "title": "Paving, surfacing, and tamping equipment operators",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "22",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "18",
        "median": "-",
        "maleWorkers": "18",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Pile-driver operators": {
    "ageData": {
      "2013": {
        "total": "1",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Pile-driver operators",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Operating engineers and other construction equipment operators": {
    "ageData": {
      "2013": {
        "total": "352",
        "16to19": "6",
        "20to24": "18",
        "25to34": "74",
        "35to44": "79",
        "45to54": "99",
        "55to64": "68",
        "65+": "9",
        "medianAge": "68"
      }
    },
    "title": "Operating engineers and other construction equipment operators",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "352",
        "women": "1.4",
        "white": "5.8",
        "black": "0.6",
        "asian": "16.5"
      }
    },
    "wageData": {
      "2013": {
        "workers": "322",
        "median": "801",
        "maleWorkers": "317",
        "maleMedian": "804",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Drywall installers, ceiling tile installers, and tapers": {
    "ageData": {
      "2013": {
        "total": "126",
        "16to19": "2",
        "20to24": "4",
        "25to34": "43",
        "35to44": "39",
        "45to54": "26",
        "55to64": "10",
        "65+": "2",
        "medianAge": "10"
      }
    },
    "title": "Drywall installers, ceiling tile installers, and tapers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "126",
        "women": "0.6",
        "white": "2.7",
        "black": "0.0",
        "asian": "55.1"
      }
    },
    "wageData": {
      "2013": {
        "workers": "81",
        "median": "521",
        "maleWorkers": "81",
        "maleMedian": "522",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Electricians": {
    "ageData": {
      "2013": {
        "total": "730",
        "16to19": "8",
        "20to24": "48",
        "25to34": "161",
        "35to44": "191",
        "45to54": "194",
        "55to64": "111",
        "65+": "17",
        "medianAge": "111"
      }
    },
    "title": "Electricians",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "730",
        "women": "1.8",
        "white": "7.5",
        "black": "2.0",
        "asian": "17.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "641",
        "median": "949",
        "maleWorkers": "630",
        "maleMedian": "952",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "Glaziers": {
    "ageData": {
      "2013": {
        "total": "37",
        "16to19": "1",
        "20to24": "1",
        "25to34": "13",
        "35to44": "8",
        "45to54": "7",
        "55to64": "6",
        "65+": "1",
        "medianAge": "6"
      }
    },
    "title": "Glaziers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "37",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "31",
        "median": "-",
        "maleWorkers": "31",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Insulation workers": {
    "ageData": {
      "2013": {
        "total": "50",
        "16to19": "1",
        "20to24": "6",
        "25to34": "10",
        "35to44": "15",
        "45to54": "13",
        "55to64": "4",
        "65+": "1",
        "medianAge": "4"
      }
    },
    "title": "Insulation workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "50",
        "women": "2.5",
        "white": "15.6",
        "black": "0.1",
        "asian": "37.7"
      }
    },
    "wageData": {
      "2013": {
        "workers": "44",
        "median": "-",
        "maleWorkers": "42",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Painters, construction and maintenance": {
    "ageData": {
      "2013": {
        "total": "517",
        "16to19": "12",
        "20to24": "45",
        "25to34": "123",
        "35to44": "125",
        "45to54": "121",
        "55to64": "75",
        "65+": "15",
        "medianAge": "75"
      }
    },
    "title": "Painters, construction and maintenance",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "517",
        "women": "5.7",
        "white": "7.0",
        "black": "1.3",
        "asian": "47.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "331",
        "median": "576",
        "maleWorkers": "318",
        "maleMedian": "579",
        "femaleWorkers": "14",
        "femaleMedian": "-"
      }
    }
  },
  "Paperhangers": {
    "ageData": {
      "2013": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "1",
        "55to64": "1",
        "65+": "1",
        "medianAge": "1"
      }
    },
    "title": "Paperhangers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "0",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Pipelayers, plumbers, pipefitters, and steamfitters": {
    "ageData": {
      "2013": {
        "total": "553",
        "16to19": "5",
        "20to24": "46",
        "25to34": "141",
        "35to44": "130",
        "45to54": "137",
        "55to64": "79",
        "65+": "16",
        "medianAge": "79"
      }
    },
    "title": "Pipelayers, plumbers, pipefitters, and steamfitters",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "553",
        "women": "1.1",
        "white": "7.9",
        "black": "3.2",
        "asian": "20.8"
      }
    },
    "wageData": {
      "2013": {
        "workers": "450",
        "median": "909",
        "maleWorkers": "447",
        "maleMedian": "912",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Plasterers and stucco masons": {
    "ageData": {
      "2013": {
        "total": "27",
        "16to19": "1",
        "20to24": "5",
        "25to34": "6",
        "35to44": "5",
        "45to54": "9",
        "55to64": "1",
        "65+": "1",
        "medianAge": "1"
      }
    },
    "title": "Plasterers and stucco masons",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "27",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "24",
        "median": "-",
        "maleWorkers": "24",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Reinforcing iron and rebar workers": {
    "ageData": {
      "2013": {
        "total": "9",
        "16to19": "0",
        "20to24": "2",
        "25to34": "2",
        "35to44": "3",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Reinforcing iron and rebar workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "9",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Roofers": {
    "ageData": {
      "2013": {
        "total": "203",
        "16to19": "6",
        "20to24": "23",
        "25to34": "68",
        "35to44": "55",
        "45to54": "28",
        "55to64": "17",
        "65+": "7",
        "medianAge": "17"
      }
    },
    "title": "Roofers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "203",
        "women": "0.7",
        "white": "4.2",
        "black": "1.1",
        "asian": "51.2"
      }
    },
    "wageData": {
      "2013": {
        "workers": "133",
        "median": "566",
        "maleWorkers": "133",
        "maleMedian": "566",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Sheet metal workers": {
    "ageData": {
      "2013": {
        "total": "113",
        "16to19": "0",
        "20to24": "8",
        "25to34": "23",
        "35to44": "30",
        "45to54": "29",
        "55to64": "18",
        "65+": "5",
        "medianAge": "18"
      }
    },
    "title": "Sheet metal workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "113",
        "women": "5.5",
        "white": "2.2",
        "black": "1.8",
        "asian": "19.2"
      }
    },
    "wageData": {
      "2013": {
        "workers": "100",
        "median": "789",
        "maleWorkers": "93",
        "maleMedian": "797",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Structural iron and steel workers": {
    "ageData": {
      "2013": {
        "total": "49",
        "16to19": "2",
        "20to24": "5",
        "25to34": "14",
        "35to44": "14",
        "45to54": "10",
        "55to64": "2",
        "65+": "2",
        "medianAge": "2"
      }
    },
    "title": "Structural iron and steel workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "49",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "38",
        "median": "-",
        "maleWorkers": "38",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Solar photovoltaic installers": {
    "ageData": {
      "2013": {
        "total": "5",
        "16to19": "0",
        "20to24": "1",
        "25to34": "2",
        "35to44": "0",
        "45to54": "2",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Solar photovoltaic installers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Helpers, construction trades": {
    "ageData": {
      "2013": {
        "total": "63",
        "16to19": "4",
        "20to24": "20",
        "25to34": "14",
        "35to44": "15",
        "45to54": "7",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Helpers, construction trades",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "63",
        "women": "2.9",
        "white": "14.0",
        "black": "0.1",
        "asian": "43.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "46",
        "median": "-",
        "maleWorkers": "44",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Construction and building inspectors": {
    "ageData": {
      "2013": {
        "total": "101",
        "16to19": "0",
        "20to24": "2",
        "25to34": "8",
        "35to44": "13",
        "45to54": "36",
        "55to64": "34",
        "65+": "8",
        "medianAge": "34"
      }
    },
    "title": "Construction and building inspectors",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "101",
        "women": "7.1",
        "white": "6.2",
        "black": "4.3",
        "asian": "5.2"
      }
    },
    "wageData": {
      "2013": {
        "workers": "73",
        "median": "903",
        "maleWorkers": "67",
        "maleMedian": "920",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Elevator installers and repairers": {
    "ageData": {
      "2013": {
        "total": "24",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "4",
        "45to54": "9",
        "55to64": "6",
        "65+": "1",
        "medianAge": "6"
      }
    },
    "title": "Elevator installers and repairers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "24",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "25",
        "median": "-",
        "maleWorkers": "25",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Fence erectors": {
    "ageData": {
      "2013": {
        "total": "35",
        "16to19": "1",
        "20to24": "2",
        "25to34": "13",
        "35to44": "7",
        "45to54": "8",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Fence erectors",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "35",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "24",
        "median": "-",
        "maleWorkers": "24",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Hazardous materials removal workers": {
    "ageData": {
      "2013": {
        "total": "32",
        "16to19": "0",
        "20to24": "4",
        "25to34": "7",
        "35to44": "10",
        "45to54": "6",
        "55to64": "6",
        "65+": "1",
        "medianAge": "6"
      }
    },
    "title": "Hazardous materials removal workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "32",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "28",
        "median": "-",
        "maleWorkers": "20",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Highway maintenance workers": {
    "ageData": {
      "2013": {
        "total": "97",
        "16to19": "2",
        "20to24": "5",
        "25to34": "17",
        "35to44": "18",
        "45to54": "30",
        "55to64": "22",
        "65+": "3",
        "medianAge": "22"
      }
    },
    "title": "Highway maintenance workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "97",
        "women": "1.1",
        "white": "8.6",
        "black": "0.8",
        "asian": "9.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "95",
        "median": "709",
        "maleWorkers": "95",
        "maleMedian": "709",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Rail-track laying and maintenance equipment operators": {
    "ageData": {
      "2013": {
        "total": "13",
        "16to19": "1",
        "20to24": "1",
        "25to34": "3",
        "35to44": "2",
        "45to54": "4",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Rail-track laying and maintenance equipment operators",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "13",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "16",
        "median": "-",
        "maleWorkers": "16",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Septic tank servicers and sewer pipe cleaners": {
    "ageData": {
      "2013": {
        "total": "10",
        "16to19": "0",
        "20to24": "2",
        "25to34": "0",
        "35to44": "6",
        "45to54": "2",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Septic tank servicers and sewer pipe cleaners",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "10",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous construction and related workers": {
    "ageData": {
      "2013": {
        "total": "29",
        "16to19": "1",
        "20to24": "2",
        "25to34": "7",
        "35to44": "5",
        "45to54": "8",
        "55to64": "4",
        "65+": "2",
        "medianAge": "4"
      }
    },
    "title": "Miscellaneous construction and related workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "29",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "21",
        "median": "-",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Derrick, rotary drill, and service unit operators, oil, gas, and mining": {
    "ageData": {
      "2013": {
        "total": "43",
        "16to19": "0",
        "20to24": "5",
        "25to34": "15",
        "35to44": "10",
        "45to54": "9",
        "55to64": "5",
        "65+": "0",
        "medianAge": "5"
      }
    },
    "title": "Derrick, rotary drill, and service unit operators, oil, gas, and mining",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "43",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "38",
        "median": "-",
        "maleWorkers": "38",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Earth drillers, except oil and gas": {
    "ageData": {
      "2013": {
        "total": "33",
        "16to19": "0",
        "20to24": "4",
        "25to34": "9",
        "35to44": "8",
        "45to54": "10",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Earth drillers, except oil and gas",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "33",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "26",
        "median": "-",
        "maleWorkers": "26",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Explosives workers, ordnance handling experts, and blasters": {
    "ageData": {
      "2013": {
        "total": "8",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "2",
        "45to54": "3",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Explosives workers, ordnance handling experts, and blasters",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "8",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Mining machine operators": {
    "ageData": {
      "2013": {
        "total": "58",
        "16to19": "1",
        "20to24": "4",
        "25to34": "23",
        "35to44": "13",
        "45to54": "10",
        "55to64": "8",
        "65+": "0",
        "medianAge": "8"
      }
    },
    "title": "Mining machine operators",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "58",
        "women": "5.0",
        "white": "4.4",
        "black": "0.4",
        "asian": "21.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "60",
        "median": "1,014",
        "maleWorkers": "58",
        "maleMedian": "1,084",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Roof bolters, mining": {
    "ageData": {
      "2013": {
        "total": "3",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Roof bolters, mining",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Roustabouts, oil and gas": {
    "ageData": {
      "2013": {
        "total": "12",
        "16to19": "0",
        "20to24": "4",
        "25to34": "3",
        "35to44": "3",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Roustabouts, oil and gas",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "12",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Helpers--extraction workers": {
    "ageData": {
      "2013": {
        "total": "3",
        "16to19": "1",
        "20to24": "1",
        "25to34": "0",
        "35to44": "0",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Helpers--extraction workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Other extraction workers": {
    "ageData": {
      "2013": {
        "total": "95",
        "16to19": "7",
        "20to24": "12",
        "25to34": "25",
        "35to44": "26",
        "45to54": "16",
        "55to64": "7",
        "65+": "3",
        "medianAge": "7"
      }
    },
    "title": "Other extraction workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "95",
        "women": "2.5",
        "white": "4.3",
        "black": "1.4",
        "asian": "39.6"
      }
    },
    "wageData": {
      "2013": {
        "workers": "86",
        "median": "920",
        "maleWorkers": "83",
        "maleMedian": "909",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Installation, maintenance, and repair occupations": {
    "ageData": {
      "2013": {
        "total": "4,964",
        "16to19": "67",
        "20to24": "419",
        "25to34": "1,072",
        "35to44": "1,109",
        "45to54": "1,291",
        "55to64": "817",
        "65+": "189",
        "medianAge": "817"
      }
    },
    "title": "Installation, maintenance, and repair occupations",
    "depth": 2,
    "parent": "Natural resources, construction, and maintenance occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "4,964",
        "women": "4.2",
        "white": "8.5",
        "black": "3.2",
        "asian": "17.0"
      }
    },
    "wageData": {
      "2013": {
        "workers": "4,268",
        "median": "821",
        "maleWorkers": "4,089",
        "maleMedian": "824",
        "femaleWorkers": "179",
        "femaleMedian": "710"
      }
    }
  },
  "First-line supervisors of mechanics, installers, and repairers": {
    "ageData": {
      "2013": {
        "total": "266",
        "16to19": "0",
        "20to24": "8",
        "25to34": "41",
        "35to44": "55",
        "45to54": "87",
        "55to64": "63",
        "65+": "12",
        "medianAge": "63"
      }
    },
    "title": "First-line supervisors of mechanics, installers, and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "266",
        "women": "10.2",
        "white": "7.8",
        "black": "2.5",
        "asian": "10.4"
      }
    },
    "wageData": {
      "2013": {
        "workers": "258",
        "median": "980",
        "maleWorkers": "229",
        "maleMedian": "990",
        "femaleWorkers": "29",
        "femaleMedian": "-"
      }
    }
  },
  "Computer, automated teller, and office machine repairers": {
    "ageData": {
      "2013": {
        "total": "285",
        "16to19": "2",
        "20to24": "21",
        "25to34": "67",
        "35to44": "82",
        "45to54": "61",
        "55to64": "40",
        "65+": "12",
        "medianAge": "40"
      }
    },
    "title": "Computer, automated teller, and office machine repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "285",
        "women": "13.3",
        "white": "13.0",
        "black": "8.6",
        "asian": "10.1"
      }
    },
    "wageData": {
      "2013": {
        "workers": "240",
        "median": "875",
        "maleWorkers": "211",
        "maleMedian": "894",
        "femaleWorkers": "29",
        "femaleMedian": "-"
      }
    }
  },
  "Radio and telecommunications equipment installers and repairers": {
    "ageData": {
      "2013": {
        "total": "129",
        "16to19": "1",
        "20to24": "6",
        "25to34": "31",
        "35to44": "28",
        "45to54": "42",
        "55to64": "20",
        "65+": "2",
        "medianAge": "20"
      }
    },
    "title": "Radio and telecommunications equipment installers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "129",
        "women": "10.0",
        "white": "9.5",
        "black": "3.3",
        "asian": "12.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "122",
        "median": "1,025",
        "maleWorkers": "108",
        "maleMedian": "1,021",
        "femaleWorkers": "13",
        "femaleMedian": "-"
      }
    }
  },
  "Avionics technicians": {
    "ageData": {
      "2013": {
        "total": "10",
        "16to19": "0",
        "20to24": "2",
        "25to34": "3",
        "35to44": "2",
        "45to54": "3",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Avionics technicians",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "10",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "10",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Electric motor, power tool, and related repairers": {
    "ageData": {
      "2013": {
        "total": "30",
        "16to19": "0",
        "20to24": "1",
        "25to34": "6",
        "35to44": "4",
        "45to54": "4",
        "55to64": "9",
        "65+": "5",
        "medianAge": "9"
      }
    },
    "title": "Electric motor, power tool, and related repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "30",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "24",
        "median": "-",
        "maleWorkers": "23",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Electrical and electronics installers and repairers, transportation equipment": {
    "ageData": {
      "2013": {
        "total": "7",
        "16to19": "0",
        "20to24": "2",
        "25to34": "0",
        "35to44": "2",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Electrical and electronics installers and repairers, transportation equipment",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Electrical and electronics repairers, industrial and utility": {
    "ageData": {
      "2013": {
        "total": "13",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "1",
        "45to54": "6",
        "55to64": "1",
        "65+": "2",
        "medianAge": "1"
      }
    },
    "title": "Electrical and electronics repairers, industrial and utility",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "13",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Electronic equipment installers and repairers, motor vehicles": {
    "ageData": {
      "2013": {
        "total": "23",
        "16to19": "0",
        "20to24": "3",
        "25to34": "5",
        "35to44": "5",
        "45to54": "5",
        "55to64": "5",
        "65+": "0",
        "medianAge": "5"
      }
    },
    "title": "Electronic equipment installers and repairers, motor vehicles",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "23",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "24",
        "median": "-",
        "maleWorkers": "22",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Electronic home entertainment equipment installers and repairers": {
    "ageData": {
      "2013": {
        "total": "54",
        "16to19": "0",
        "20to24": "5",
        "25to34": "18",
        "35to44": "12",
        "45to54": "10",
        "55to64": "9",
        "65+": "0",
        "medianAge": "9"
      }
    },
    "title": "Electronic home entertainment equipment installers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "54",
        "women": "5.1",
        "white": "2.5",
        "black": "4.6",
        "asian": "19.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "42",
        "median": "-",
        "maleWorkers": "41",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Security and fire alarm systems installers": {
    "ageData": {
      "2013": {
        "total": "72",
        "16to19": "0",
        "20to24": "9",
        "25to34": "23",
        "35to44": "14",
        "45to54": "13",
        "55to64": "8",
        "65+": "5",
        "medianAge": "8"
      }
    },
    "title": "Security and fire alarm systems installers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "72",
        "women": "3.5",
        "white": "10.0",
        "black": "1.9",
        "asian": "20.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "60",
        "median": "780",
        "maleWorkers": "58",
        "maleMedian": "780",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Aircraft mechanics and service technicians": {
    "ageData": {
      "2013": {
        "total": "162",
        "16to19": "1",
        "20to24": "6",
        "25to34": "27",
        "35to44": "39",
        "45to54": "48",
        "55to64": "36",
        "65+": "6",
        "medianAge": "36"
      }
    },
    "title": "Aircraft mechanics and service technicians",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "162",
        "women": "2.2",
        "white": "7.9",
        "black": "9.0",
        "asian": "9.0"
      }
    },
    "wageData": {
      "2013": {
        "workers": "155",
        "median": "978",
        "maleWorkers": "152",
        "maleMedian": "974",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Automotive body and related repairers": {
    "ageData": {
      "2013": {
        "total": "168",
        "16to19": "4",
        "20to24": "15",
        "25to34": "36",
        "35to44": "36",
        "45to54": "36",
        "55to64": "31",
        "65+": "10",
        "medianAge": "31"
      }
    },
    "title": "Automotive body and related repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "168",
        "women": "1.5",
        "white": "3.3",
        "black": "2.2",
        "asian": "32.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "124",
        "median": "687",
        "maleWorkers": "123",
        "maleMedian": "688",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Automotive glass installers and repairers": {
    "ageData": {
      "2013": {
        "total": "27",
        "16to19": "0",
        "20to24": "1",
        "25to34": "10",
        "35to44": "9",
        "45to54": "4",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Automotive glass installers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "27",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "20",
        "median": "-",
        "maleWorkers": "20",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Automotive service technicians and mechanics": {
    "ageData": {
      "2013": {
        "total": "863",
        "16to19": "16",
        "20to24": "117",
        "25to34": "195",
        "35to44": "194",
        "45to54": "215",
        "55to64": "99",
        "65+": "26",
        "medianAge": "99"
      }
    },
    "title": "Automotive service technicians and mechanics",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "863",
        "women": "1.8",
        "white": "11.4",
        "black": "4.0",
        "asian": "21.7"
      }
    },
    "wageData": {
      "2013": {
        "workers": "672",
        "median": "713",
        "maleWorkers": "659",
        "maleMedian": "714",
        "femaleWorkers": "13",
        "femaleMedian": "-"
      }
    }
  },
  "Bus and truck mechanics and diesel engine specialists": {
    "ageData": {
      "2013": {
        "total": "316",
        "16to19": "6",
        "20to24": "36",
        "25to34": "64",
        "35to44": "67",
        "45to54": "91",
        "55to64": "44",
        "65+": "8",
        "medianAge": "44"
      }
    },
    "title": "Bus and truck mechanics and diesel engine specialists",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "316",
        "women": "0.8",
        "white": "6.2",
        "black": "0.6",
        "asian": "15.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "302",
        "median": "794",
        "maleWorkers": "301",
        "maleMedian": "795",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Heavy vehicle and mobile equipment service technicians and mechanics": {
    "ageData": {
      "2013": {
        "total": "218",
        "16to19": "3",
        "20to24": "13",
        "25to34": "56",
        "35to44": "50",
        "45to54": "47",
        "55to64": "43",
        "65+": "7",
        "medianAge": "43"
      }
    },
    "title": "Heavy vehicle and mobile equipment service technicians and mechanics",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "218",
        "women": "0.6",
        "white": "7.5",
        "black": "1.4",
        "asian": "15.7"
      }
    },
    "wageData": {
      "2013": {
        "workers": "196",
        "median": "867",
        "maleWorkers": "195",
        "maleMedian": "868",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Small engine mechanics": {
    "ageData": {
      "2013": {
        "total": "41",
        "16to19": "1",
        "20to24": "2",
        "25to34": "15",
        "35to44": "9",
        "45to54": "6",
        "55to64": "8",
        "65+": "1",
        "medianAge": "8"
      }
    },
    "title": "Small engine mechanics",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "41",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "32",
        "median": "-",
        "maleWorkers": "31",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous vehicle and mobile equipment mechanics, installers, and repairers": {
    "ageData": {
      "2013": {
        "total": "98",
        "16to19": "11",
        "20to24": "22",
        "25to34": "27",
        "35to44": "18",
        "45to54": "12",
        "55to64": "6",
        "65+": "2",
        "medianAge": "6"
      }
    },
    "title": "Miscellaneous vehicle and mobile equipment mechanics, installers, and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "98",
        "women": "1.8",
        "white": "11.6",
        "black": "0.9",
        "asian": "31.6"
      }
    },
    "wageData": {
      "2013": {
        "workers": "74",
        "median": "468",
        "maleWorkers": "72",
        "maleMedian": "480",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Control and valve installers and repairers": {
    "ageData": {
      "2013": {
        "total": "23",
        "16to19": "0",
        "20to24": "2",
        "25to34": "4",
        "35to44": "3",
        "45to54": "10",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Control and valve installers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "23",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "24",
        "median": "-",
        "maleWorkers": "22",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Heating, air conditioning, and refrigeration mechanics and installers": {
    "ageData": {
      "2013": {
        "total": "384",
        "16to19": "4",
        "20to24": "34",
        "25to34": "100",
        "35to44": "94",
        "45to54": "89",
        "55to64": "55",
        "65+": "6",
        "medianAge": "55"
      }
    },
    "title": "Heating, air conditioning, and refrigeration mechanics and installers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "384",
        "women": "1.2",
        "white": "7.4",
        "black": "2.5",
        "asian": "16.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "316",
        "median": "792",
        "maleWorkers": "313",
        "maleMedian": "791",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Home appliance repairers": {
    "ageData": {
      "2013": {
        "total": "52",
        "16to19": "0",
        "20to24": "4",
        "25to34": "11",
        "35to44": "10",
        "45to54": "15",
        "55to64": "10",
        "65+": "3",
        "medianAge": "10"
      }
    },
    "title": "Home appliance repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "52",
        "women": "0.4",
        "white": "12.5",
        "black": "2.1",
        "asian": "20.1"
      }
    },
    "wageData": {
      "2013": {
        "workers": "38",
        "median": "-",
        "maleWorkers": "38",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Industrial and refractory machinery mechanics": {
    "ageData": {
      "2013": {
        "total": "437",
        "16to19": "3",
        "20to24": "17",
        "25to34": "72",
        "35to44": "93",
        "45to54": "134",
        "55to64": "103",
        "65+": "16",
        "medianAge": "103"
      }
    },
    "title": "Industrial and refractory machinery mechanics",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "437",
        "women": "4.6",
        "white": "4.6",
        "black": "3.3",
        "asian": "12.8"
      }
    },
    "wageData": {
      "2013": {
        "workers": "434",
        "median": "896",
        "maleWorkers": "414",
        "maleMedian": "899",
        "femaleWorkers": "20",
        "femaleMedian": "-"
      }
    }
  },
  "Maintenance and repair workers, general": {
    "ageData": {
      "2013": {
        "total": "462",
        "16to19": "5",
        "20to24": "18",
        "25to34": "76",
        "35to44": "103",
        "45to54": "141",
        "55to64": "87",
        "65+": "30",
        "medianAge": "87"
      }
    },
    "title": "Maintenance and repair workers, general",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "462",
        "women": "4.0",
        "white": "10.4",
        "black": "2.7",
        "asian": "19.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "405",
        "median": "805",
        "maleWorkers": "386",
        "maleMedian": "807",
        "femaleWorkers": "18",
        "femaleMedian": "-"
      }
    }
  },
  "Maintenance workers, machinery": {
    "ageData": {
      "2013": {
        "total": "40",
        "16to19": "1",
        "20to24": "7",
        "25to34": "6",
        "35to44": "8",
        "45to54": "12",
        "55to64": "4",
        "65+": "3",
        "medianAge": "4"
      }
    },
    "title": "Maintenance workers, machinery",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "40",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "33",
        "median": "-",
        "maleWorkers": "30",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Millwrights": {
    "ageData": {
      "2013": {
        "total": "66",
        "16to19": "1",
        "20to24": "4",
        "25to34": "11",
        "35to44": "12",
        "45to54": "20",
        "55to64": "15",
        "65+": "2",
        "medianAge": "15"
      }
    },
    "title": "Millwrights",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "66",
        "women": "1.2",
        "white": "3.6",
        "black": "0.8",
        "asian": "8.7"
      }
    },
    "wageData": {
      "2013": {
        "workers": "62",
        "median": "1,019",
        "maleWorkers": "61",
        "maleMedian": "1,025",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Electrical power-line installers and repairers": {
    "ageData": {
      "2013": {
        "total": "117",
        "16to19": "0",
        "20to24": "15",
        "25to34": "33",
        "35to44": "24",
        "45to54": "28",
        "55to64": "13",
        "65+": "2",
        "medianAge": "13"
      }
    },
    "title": "Electrical power-line installers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "117",
        "women": "1.1",
        "white": "5.7",
        "black": "1.7",
        "asian": "12.6"
      }
    },
    "wageData": {
      "2013": {
        "workers": "113",
        "median": "922",
        "maleWorkers": "112",
        "maleMedian": "929",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Telecommunications line installers and repairers": {
    "ageData": {
      "2013": {
        "total": "183",
        "16to19": "0",
        "20to24": "13",
        "25to34": "46",
        "35to44": "58",
        "45to54": "43",
        "55to64": "18",
        "65+": "5",
        "medianAge": "18"
      }
    },
    "title": "Telecommunications line installers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "183",
        "women": "2.8",
        "white": "13.5",
        "black": "3.0",
        "asian": "15.5"
      }
    },
    "wageData": {
      "2013": {
        "workers": "177",
        "median": "930",
        "maleWorkers": "172",
        "maleMedian": "929",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Precision instrument and equipment repairers": {
    "ageData": {
      "2013": {
        "total": "76",
        "16to19": "0",
        "20to24": "3",
        "25to34": "19",
        "35to44": "14",
        "45to54": "19",
        "55to64": "16",
        "65+": "5",
        "medianAge": "16"
      }
    },
    "title": "Precision instrument and equipment repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "76",
        "women": "11.8",
        "white": "6.9",
        "black": "8.2",
        "asian": "6.1"
      }
    },
    "wageData": {
      "2013": {
        "workers": "58",
        "median": "951",
        "maleWorkers": "51",
        "maleMedian": "956",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Wind turbine service technicians": {
    "ageData": {
      "2013": {
        "total": "3",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "0",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Wind turbine service technicians",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Coin, vending, and amusement machine servicers and repairers": {
    "ageData": {
      "2013": {
        "total": "40",
        "16to19": "1",
        "20to24": "4",
        "25to34": "5",
        "35to44": "8",
        "45to54": "12",
        "55to64": "8",
        "65+": "2",
        "medianAge": "8"
      }
    },
    "title": "Coin, vending, and amusement machine servicers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "40",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "32",
        "median": "-",
        "maleWorkers": "28",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Commercial divers": {
    "ageData": {
      "2013": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "1",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Commercial divers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Locksmiths and safe repairers": {
    "ageData": {
      "2013": {
        "total": "29",
        "16to19": "0",
        "20to24": "4",
        "25to34": "2",
        "35to44": "2",
        "45to54": "10",
        "55to64": "9",
        "65+": "3",
        "medianAge": "9"
      }
    },
    "title": "Locksmiths and safe repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "29",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "17",
        "median": "-",
        "maleWorkers": "16",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Manufactured building and mobile home installers": {
    "ageData": {
      "2013": {
        "total": "8",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "4",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Manufactured building and mobile home installers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "8",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "10",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Riggers": {
    "ageData": {
      "2013": {
        "total": "13",
        "16to19": "0",
        "20to24": "2",
        "25to34": "1",
        "35to44": "4",
        "45to54": "5",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Riggers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "13",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Signal and track switch repairers": {
    "ageData": {
      "2013": {
        "total": "6",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "1",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Signal and track switch repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "6",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Helpers--installation, maintenance, and repair workers": {
    "ageData": {
      "2013": {
        "total": "22",
        "16to19": "2",
        "20to24": "5",
        "25to34": "5",
        "35to44": "2",
        "45to54": "4",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Helpers--installation, maintenance, and repair workers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "22",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "18",
        "median": "-",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Other installation, maintenance, and repair workers": {
    "ageData": {
      "2013": {
        "total": "215",
        "16to19": "2",
        "20to24": "17",
        "25to34": "49",
        "35to44": "41",
        "45to54": "54",
        "55to64": "40",
        "65+": "13",
        "medianAge": "40"
      }
    },
    "title": "Other installation, maintenance, and repair workers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "215",
        "women": "5.9",
        "white": "7.3",
        "black": "1.0",
        "asian": "22.2"
      }
    },
    "wageData": {
      "2013": {
        "workers": "135",
        "median": "667",
        "maleWorkers": "129",
        "maleMedian": "674",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Production, transportation, and material moving occupations": {
    "ageData": {
      "2013": {
        "total": "16,984",
        "16to19": "441",
        "20to24": "1,664",
        "25to34": "3,442",
        "35to44": "3,551",
        "45to54": "4,119",
        "55to64": "2,955",
        "65+": "812",
        "medianAge": "2,955"
      }
    },
    "title": "Production, transportation, and material moving occupations",
    "depth": 1,
    "parent": "",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "16,984",
        "women": "21.4",
        "white": "14.9",
        "black": "4.7",
        "asian": "21.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "13,930",
        "median": "621",
        "maleWorkers": "11,090",
        "maleMedian": "674",
        "femaleWorkers": "2,840",
        "femaleMedian": "498"
      }
    }
  },
  "Production occupations": {
    "ageData": {
      "2013": {
        "total": "8,275",
        "16to19": "144",
        "20to24": "767",
        "25to34": "1,745",
        "35to44": "1,783",
        "45to54": "2,059",
        "55to64": "1,475",
        "65+": "302",
        "medianAge": "1,475"
      }
    },
    "title": "Production occupations",
    "depth": 2,
    "parent": "Production, transportation, and material moving occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "8,275",
        "women": "27.6",
        "white": "12.3",
        "black": "6.3",
        "asian": "22.5"
      }
    },
    "wageData": {
      "2013": {
        "workers": "7,307",
        "median": "623",
        "maleWorkers": "5,382",
        "maleMedian": "697",
        "femaleWorkers": "1,925",
        "femaleMedian": "498"
      }
    }
  },
  "First-line supervisors of production and operating workers": {
    "ageData": {
      "2013": {
        "total": "731",
        "16to19": "1",
        "20to24": "17",
        "25to34": "124",
        "35to44": "171",
        "45to54": "235",
        "55to64": "154",
        "65+": "29",
        "medianAge": "154"
      }
    },
    "title": "First-line supervisors of production and operating workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "731",
        "women": "18.6",
        "white": "9.5",
        "black": "4.9",
        "asian": "14.4"
      }
    },
    "wageData": {
      "2013": {
        "workers": "675",
        "median": "902",
        "maleWorkers": "550",
        "maleMedian": "952",
        "femaleWorkers": "125",
        "femaleMedian": "682"
      }
    }
  },
  "Aircraft structure, surfaces, rigging, and systems assemblers": {
    "ageData": {
      "2013": {
        "total": "14",
        "16to19": "0",
        "20to24": "1",
        "25to34": "4",
        "35to44": "4",
        "45to54": "2",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Aircraft structure, surfaces, rigging, and systems assemblers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "14",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "16",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Electrical, electronics, and electromechanical assemblers": {
    "ageData": {
      "2013": {
        "total": "141",
        "16to19": "1",
        "20to24": "12",
        "25to34": "23",
        "35to44": "26",
        "45to54": "44",
        "55to64": "28",
        "65+": "7",
        "medianAge": "28"
      }
    },
    "title": "Electrical, electronics, and electromechanical assemblers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "141",
        "women": "47.0",
        "white": "11.4",
        "black": "18.2",
        "asian": "21.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "126",
        "median": "503",
        "maleWorkers": "64",
        "maleMedian": "522",
        "femaleWorkers": "61",
        "femaleMedian": "490"
      }
    }
  },
  "Engine and other machine assemblers": {
    "ageData": {
      "2013": {
        "total": "20",
        "16to19": "0",
        "20to24": "2",
        "25to34": "5",
        "35to44": "4",
        "45to54": "5",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Engine and other machine assemblers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "20",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "19",
        "median": "-",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Structural metal fabricators and fitters": {
    "ageData": {
      "2013": {
        "total": "23",
        "16to19": "0",
        "20to24": "0",
        "25to34": "4",
        "35to44": "6",
        "45to54": "9",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Structural metal fabricators and fitters",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "23",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "21",
        "median": "-",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous assemblers and fabricators": {
    "ageData": {
      "2013": {
        "total": "1,013",
        "16to19": "21",
        "20to24": "120",
        "25to34": "214",
        "35to44": "235",
        "45to54": "230",
        "55to64": "156",
        "65+": "36",
        "medianAge": "156"
      }
    },
    "title": "Miscellaneous assemblers and fabricators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "1,013",
        "women": "37.0",
        "white": "16.5",
        "black": "7.7",
        "asian": "19.6"
      }
    },
    "wageData": {
      "2013": {
        "workers": "900",
        "median": "564",
        "maleWorkers": "566",
        "maleMedian": "610",
        "femaleWorkers": "335",
        "femaleMedian": "493"
      }
    }
  },
  "Bakers": {
    "ageData": {
      "2013": {
        "total": "207",
        "16to19": "9",
        "20to24": "34",
        "25to34": "47",
        "35to44": "30",
        "45to54": "44",
        "55to64": "34",
        "65+": "9",
        "medianAge": "34"
      }
    },
    "title": "Bakers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "207",
        "women": "59.8",
        "white": "9.0",
        "black": "7.1",
        "asian": "25.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "117",
        "median": "505",
        "maleWorkers": "56",
        "maleMedian": "488",
        "femaleWorkers": "61",
        "femaleMedian": "524"
      }
    }
  },
  "Butchers and other meat, poultry, and fish processing workers": {
    "ageData": {
      "2013": {
        "total": "323",
        "16to19": "11",
        "20to24": "45",
        "25to34": "80",
        "35to44": "74",
        "45to54": "66",
        "55to64": "37",
        "65+": "10",
        "medianAge": "37"
      }
    },
    "title": "Butchers and other meat, poultry, and fish processing workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "323",
        "women": "25.5",
        "white": "14.8",
        "black": "11.2",
        "asian": "34.7"
      }
    },
    "wageData": {
      "2013": {
        "workers": "284",
        "median": "506",
        "maleWorkers": "211",
        "maleMedian": "525",
        "femaleWorkers": "73",
        "femaleMedian": "476"
      }
    }
  },
  "Food and tobacco roasting, baking, and drying machine operators and tenders": {
    "ageData": {
      "2013": {
        "total": "10",
        "16to19": "1",
        "20to24": "1",
        "25to34": "2",
        "35to44": "3",
        "45to54": "2",
        "55to64": "1",
        "65+": "1",
        "medianAge": "1"
      }
    },
    "title": "Food and tobacco roasting, baking, and drying machine operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "10",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Food batchmakers": {
    "ageData": {
      "2013": {
        "total": "93",
        "16to19": "8",
        "20to24": "11",
        "25to34": "20",
        "35to44": "17",
        "45to54": "18",
        "55to64": "16",
        "65+": "4",
        "medianAge": "16"
      }
    },
    "title": "Food batchmakers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "93",
        "women": "51.4",
        "white": "8.4",
        "black": "4.4",
        "asian": "28.2"
      }
    },
    "wageData": {
      "2013": {
        "workers": "66",
        "median": "516",
        "maleWorkers": "33",
        "maleMedian": "-",
        "femaleWorkers": "33",
        "femaleMedian": "-"
      }
    }
  },
  "Food cooking machine operators and tenders": {
    "ageData": {
      "2013": {
        "total": "9",
        "16to19": "1",
        "20to24": "1",
        "25to34": "1",
        "35to44": "4",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Food cooking machine operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "9",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Food processing workers, all other": {
    "ageData": {
      "2013": {
        "total": "123",
        "16to19": "1",
        "20to24": "17",
        "25to34": "29",
        "35to44": "27",
        "45to54": "27",
        "55to64": "20",
        "65+": "2",
        "medianAge": "20"
      }
    },
    "title": "Food processing workers, all other",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "123",
        "women": "31.4",
        "white": "11.5",
        "black": "7.7",
        "asian": "33.8"
      }
    },
    "wageData": {
      "2013": {
        "workers": "113",
        "median": "571",
        "maleWorkers": "78",
        "maleMedian": "635",
        "femaleWorkers": "35",
        "femaleMedian": "-"
      }
    }
  },
  "Computer control programmers and operators": {
    "ageData": {
      "2013": {
        "total": "85",
        "16to19": "1",
        "20to24": "5",
        "25to34": "23",
        "35to44": "24",
        "45to54": "17",
        "55to64": "14",
        "65+": "1",
        "medianAge": "14"
      }
    },
    "title": "Computer control programmers and operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "85",
        "women": "5.1",
        "white": "3.6",
        "black": "2.3",
        "asian": "12.6"
      }
    },
    "wageData": {
      "2013": {
        "workers": "69",
        "median": "849",
        "maleWorkers": "66",
        "maleMedian": "859",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Extruding and drawing machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2013": {
        "total": "13",
        "16to19": "0",
        "20to24": "0",
        "25to34": "4",
        "35to44": "4",
        "45to54": "3",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Extruding and drawing machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "13",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "16",
        "median": "-",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Forging machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2013": {
        "total": "8",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "1",
        "45to54": "3",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Forging machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "8",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "9",
        "median": "-",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Rolling machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2013": {
        "total": "4",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "1",
        "45to54": "2",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Rolling machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Cutting, punching, and press machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2013": {
        "total": "76",
        "16to19": "3",
        "20to24": "8",
        "25to34": "18",
        "35to44": "18",
        "45to54": "20",
        "55to64": "9",
        "65+": "1",
        "medianAge": "9"
      }
    },
    "title": "Cutting, punching, and press machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "76",
        "women": "16.4",
        "white": "8.9",
        "black": "2.8",
        "asian": "24.7"
      }
    },
    "wageData": {
      "2013": {
        "workers": "70",
        "median": "545",
        "maleWorkers": "60",
        "maleMedian": "532",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Drilling and boring machine tool setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2013": {
        "total": "1",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Drilling and boring machine tool setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Grinding, lapping, polishing, and buffing machine tool setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2013": {
        "total": "57",
        "16to19": "0",
        "20to24": "5",
        "25to34": "12",
        "35to44": "12",
        "45to54": "12",
        "55to64": "12",
        "65+": "4",
        "medianAge": "12"
      }
    },
    "title": "Grinding, lapping, polishing, and buffing machine tool setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "57",
        "women": "14.2",
        "white": "9.1",
        "black": "5.1",
        "asian": "25.8"
      }
    },
    "wageData": {
      "2013": {
        "workers": "50",
        "median": "610",
        "maleWorkers": "44",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Lathe and turning machine tool setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2013": {
        "total": "11",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "1",
        "45to54": "4",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Lathe and turning machine tool setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "11",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "11",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Milling and planing machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2013": {
        "total": "5",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "2",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Milling and planing machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Machinists": {
    "ageData": {
      "2013": {
        "total": "398",
        "16to19": "5",
        "20to24": "25",
        "25to34": "75",
        "35to44": "79",
        "45to54": "102",
        "55to64": "92",
        "65+": "20",
        "medianAge": "92"
      }
    },
    "title": "Machinists",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "398",
        "women": "5.3",
        "white": "4.4",
        "black": "6.3",
        "asian": "15.1"
      }
    },
    "wageData": {
      "2013": {
        "workers": "388",
        "median": "777",
        "maleWorkers": "367",
        "maleMedian": "795",
        "femaleWorkers": "21",
        "femaleMedian": "-"
      }
    }
  },
  "Metal furnace operators, tenders, pourers, and casters": {
    "ageData": {
      "2013": {
        "total": "24",
        "16to19": "0",
        "20to24": "2",
        "25to34": "4",
        "35to44": "7",
        "45to54": "4",
        "55to64": "5",
        "65+": "1",
        "medianAge": "5"
      }
    },
    "title": "Metal furnace operators, tenders, pourers, and casters",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "24",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "23",
        "median": "-",
        "maleWorkers": "23",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Model makers and patternmakers, metal and plastic": {
    "ageData": {
      "2013": {
        "total": "7",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "1",
        "45to54": "2",
        "55to64": "0",
        "65+": "2",
        "medianAge": "0"
      }
    },
    "title": "Model makers and patternmakers, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Molders and molding machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2013": {
        "total": "50",
        "16to19": "1",
        "20to24": "6",
        "25to34": "10",
        "35to44": "11",
        "45to54": "14",
        "55to64": "8",
        "65+": "1",
        "medianAge": "8"
      }
    },
    "title": "Molders and molding machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "50",
        "women": "25.6",
        "white": "3.6",
        "black": "1.1",
        "asian": "23.5"
      }
    },
    "wageData": {
      "2013": {
        "workers": "51",
        "median": "717",
        "maleWorkers": "38",
        "maleMedian": "-",
        "femaleWorkers": "13",
        "femaleMedian": "-"
      }
    }
  },
  "Multiple machine tool setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2013": {
        "total": "4",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "2",
        "45to54": "1",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Multiple machine tool setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Tool and die makers": {
    "ageData": {
      "2013": {
        "total": "53",
        "16to19": "0",
        "20to24": "1",
        "25to34": "5",
        "35to44": "9",
        "45to54": "18",
        "55to64": "17",
        "65+": "2",
        "medianAge": "17"
      }
    },
    "title": "Tool and die makers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "53",
        "women": "0.3",
        "white": "0.9",
        "black": "1.1",
        "asian": "4.4"
      }
    },
    "wageData": {
      "2013": {
        "workers": "55",
        "median": "908",
        "maleWorkers": "55",
        "maleMedian": "911",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Welding, soldering, and brazing workers": {
    "ageData": {
      "2013": {
        "total": "575",
        "16to19": "8",
        "20to24": "67",
        "25to34": "162",
        "35to44": "132",
        "45to54": "122",
        "55to64": "72",
        "65+": "13",
        "medianAge": "72"
      }
    },
    "title": "Welding, soldering, and brazing workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "575",
        "women": "4.6",
        "white": "8.1",
        "black": "3.3",
        "asian": "22.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "524",
        "median": "723",
        "maleWorkers": "499",
        "maleMedian": "730",
        "femaleWorkers": "25",
        "femaleMedian": "-"
      }
    }
  },
  "Heat treating equipment setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2013": {
        "total": "9",
        "16to19": "1",
        "20to24": "2",
        "25to34": "4",
        "35to44": "1",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Heat treating equipment setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "9",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "9",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Layout workers, metal and plastic": {
    "ageData": {
      "2013": {
        "total": "5",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "2",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Layout workers, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Plating and coating machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2013": {
        "total": "18",
        "16to19": "0",
        "20to24": "2",
        "25to34": "5",
        "35to44": "5",
        "45to54": "4",
        "55to64": "1",
        "65+": "1",
        "medianAge": "1"
      }
    },
    "title": "Plating and coating machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "18",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "16",
        "median": "-",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Tool grinders, filers, and sharpeners": {
    "ageData": {
      "2013": {
        "total": "5",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "2",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Tool grinders, filers, and sharpeners",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Metal workers and plastic workers, all other": {
    "ageData": {
      "2013": {
        "total": "338",
        "16to19": "5",
        "20to24": "31",
        "25to34": "63",
        "35to44": "86",
        "45to54": "85",
        "55to64": "61",
        "65+": "8",
        "medianAge": "61"
      }
    },
    "title": "Metal workers and plastic workers, all other",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "338",
        "women": "22.0",
        "white": "17.2",
        "black": "6.1",
        "asian": "25.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "323",
        "median": "592",
        "maleWorkers": "257",
        "maleMedian": "606",
        "femaleWorkers": "66",
        "femaleMedian": "557"
      }
    }
  },
  "Prepress technicians and workers": {
    "ageData": {
      "2013": {
        "total": "29",
        "16to19": "1",
        "20to24": "0",
        "25to34": "5",
        "35to44": "7",
        "45to54": "11",
        "55to64": "3",
        "65+": "2",
        "medianAge": "3"
      }
    },
    "title": "Prepress technicians and workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "29",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "25",
        "median": "-",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "9",
        "femaleMedian": "-"
      }
    }
  },
  "Printing press operators": {
    "ageData": {
      "2013": {
        "total": "193",
        "16to19": "1",
        "20to24": "13",
        "25to34": "44",
        "35to44": "33",
        "45to54": "61",
        "55to64": "35",
        "65+": "7",
        "medianAge": "35"
      }
    },
    "title": "Printing press operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "193",
        "women": "18.1",
        "white": "7.4",
        "black": "4.2",
        "asian": "18.1"
      }
    },
    "wageData": {
      "2013": {
        "workers": "175",
        "median": "656",
        "maleWorkers": "145",
        "maleMedian": "703",
        "femaleWorkers": "30",
        "femaleMedian": "-"
      }
    }
  },
  "Print binding and finishing workers": {
    "ageData": {
      "2013": {
        "total": "26",
        "16to19": "0",
        "20to24": "3",
        "25to34": "4",
        "35to44": "2",
        "45to54": "8",
        "55to64": "7",
        "65+": "2",
        "medianAge": "7"
      }
    },
    "title": "Print binding and finishing workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "26",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "22",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "13",
        "femaleMedian": "-"
      }
    }
  },
  "Laundry and dry-cleaning workers": {
    "ageData": {
      "2013": {
        "total": "167",
        "16to19": "7",
        "20to24": "16",
        "25to34": "31",
        "35to44": "34",
        "45to54": "37",
        "55to64": "32",
        "65+": "9",
        "medianAge": "32"
      }
    },
    "title": "Laundry and dry-cleaning workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "167",
        "women": "60.1",
        "white": "20.6",
        "black": "6.1",
        "asian": "42.5"
      }
    },
    "wageData": {
      "2013": {
        "workers": "121",
        "median": "385",
        "maleWorkers": "50",
        "maleMedian": "425",
        "femaleWorkers": "71",
        "femaleMedian": "360"
      }
    }
  },
  "Pressers, textile, garment, and related materials": {
    "ageData": {
      "2013": {
        "total": "55",
        "16to19": "1",
        "20to24": "0",
        "25to34": "15",
        "35to44": "10",
        "45to54": "16",
        "55to64": "9",
        "65+": "4",
        "medianAge": "9"
      }
    },
    "title": "Pressers, textile, garment, and related materials",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "55",
        "women": "55.7",
        "white": "27.6",
        "black": "4.7",
        "asian": "52.6"
      }
    },
    "wageData": {
      "2013": {
        "workers": "35",
        "median": "-",
        "maleWorkers": "18",
        "maleMedian": "-",
        "femaleWorkers": "17",
        "femaleMedian": "-"
      }
    }
  },
  "Sewing machine operators": {
    "ageData": {
      "2013": {
        "total": "158",
        "16to19": "0",
        "20to24": "16",
        "25to34": "21",
        "35to44": "26",
        "45to54": "47",
        "55to64": "41",
        "65+": "6",
        "medianAge": "41"
      }
    },
    "title": "Sewing machine operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "158",
        "women": "76.1",
        "white": "12.1",
        "black": "17.3",
        "asian": "41.4"
      }
    },
    "wageData": {
      "2013": {
        "workers": "121",
        "median": "405",
        "maleWorkers": "29",
        "maleMedian": "-",
        "femaleWorkers": "91",
        "femaleMedian": "405"
      }
    }
  },
  "Shoe and leather workers and repairers": {
    "ageData": {
      "2013": {
        "total": "15",
        "16to19": "0",
        "20to24": "0",
        "25to34": "4",
        "35to44": "2",
        "45to54": "4",
        "55to64": "2",
        "65+": "4",
        "medianAge": "2"
      }
    },
    "title": "Shoe and leather workers and repairers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "15",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Shoe machine operators and tenders": {
    "ageData": {
      "2013": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Shoe machine operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Tailors, dressmakers, and sewers": {
    "ageData": {
      "2013": {
        "total": "84",
        "16to19": "0",
        "20to24": "5",
        "25to34": "7",
        "35to44": "15",
        "45to54": "23",
        "55to64": "23",
        "65+": "10",
        "medianAge": "23"
      }
    },
    "title": "Tailors, dressmakers, and sewers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "84",
        "women": "72.0",
        "white": "9.7",
        "black": "21.8",
        "asian": "24.6"
      }
    },
    "wageData": {
      "2013": {
        "workers": "42",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "28",
        "femaleMedian": "-"
      }
    }
  },
  "Textile bleaching and dyeing machine operators and tenders": {
    "ageData": {
      "2013": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "0",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Textile bleaching and dyeing machine operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Textile cutting machine setters, operators, and tenders": {
    "ageData": {
      "2013": {
        "total": "6",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "2",
        "45to54": "2",
        "55to64": "0",
        "65+": "1",
        "medianAge": "0"
      }
    },
    "title": "Textile cutting machine setters, operators, and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "6",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Textile knitting and weaving machine setters, operators, and tenders": {
    "ageData": {
      "2013": {
        "total": "6",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "1",
        "45to54": "2",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Textile knitting and weaving machine setters, operators, and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "6",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Textile winding, twisting, and drawing out machine setters, operators, and tenders": {
    "ageData": {
      "2013": {
        "total": "16",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "9",
        "45to54": "2",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Textile winding, twisting, and drawing out machine setters, operators, and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "16",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Extruding and forming machine setters, operators, and tenders, synthetic and glass fibers": {
    "ageData": {
      "2013": {
        "total": "1",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Extruding and forming machine setters, operators, and tenders, synthetic and glass fibers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Fabric and apparel patternmakers": {
    "ageData": {
      "2013": {
        "total": "1",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Fabric and apparel patternmakers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Upholsterers": {
    "ageData": {
      "2013": {
        "total": "35",
        "16to19": "1",
        "20to24": "0",
        "25to34": "3",
        "35to44": "5",
        "45to54": "17",
        "55to64": "8",
        "65+": "1",
        "medianAge": "8"
      }
    },
    "title": "Upholsterers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "35",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "19",
        "median": "-",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Textile, apparel, and furnishings workers, all other": {
    "ageData": {
      "2013": {
        "total": "14",
        "16to19": "1",
        "20to24": "0",
        "25to34": "3",
        "35to44": "3",
        "45to54": "2",
        "55to64": "3",
        "65+": "2",
        "medianAge": "3"
      }
    },
    "title": "Textile, apparel, and furnishings workers, all other",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "14",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Cabinetmakers and bench carpenters": {
    "ageData": {
      "2013": {
        "total": "47",
        "16to19": "2",
        "20to24": "4",
        "25to34": "7",
        "35to44": "8",
        "45to54": "14",
        "55to64": "11",
        "65+": "1",
        "medianAge": "11"
      }
    },
    "title": "Cabinetmakers and bench carpenters",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "47",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "28",
        "median": "-",
        "maleWorkers": "27",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Furniture finishers": {
    "ageData": {
      "2013": {
        "total": "7",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "1",
        "45to54": "2",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Furniture finishers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Model makers and patternmakers, wood": {
    "ageData": {
      "2013": {
        "total": "0",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Model makers and patternmakers, wood",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "0",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "0",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Sawing machine setters, operators, and tenders, wood": {
    "ageData": {
      "2013": {
        "total": "33",
        "16to19": "2",
        "20to24": "3",
        "25to34": "8",
        "35to44": "4",
        "45to54": "7",
        "55to64": "8",
        "65+": "1",
        "medianAge": "8"
      }
    },
    "title": "Sawing machine setters, operators, and tenders, wood",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "33",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "25",
        "median": "-",
        "maleWorkers": "23",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Woodworking machine setters, operators, and tenders, except sawing": {
    "ageData": {
      "2013": {
        "total": "19",
        "16to19": "1",
        "20to24": "3",
        "25to34": "3",
        "35to44": "4",
        "45to54": "5",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Woodworking machine setters, operators, and tenders, except sawing",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "19",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "18",
        "median": "-",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Woodworkers, all other": {
    "ageData": {
      "2013": {
        "total": "19",
        "16to19": "0",
        "20to24": "1",
        "25to34": "3",
        "35to44": "2",
        "45to54": "8",
        "55to64": "3",
        "65+": "2",
        "medianAge": "3"
      }
    },
    "title": "Woodworkers, all other",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "19",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "9",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Power plant operators, distributors, and dispatchers": {
    "ageData": {
      "2013": {
        "total": "45",
        "16to19": "0",
        "20to24": "1",
        "25to34": "6",
        "35to44": "15",
        "45to54": "13",
        "55to64": "7",
        "65+": "2",
        "medianAge": "7"
      }
    },
    "title": "Power plant operators, distributors, and dispatchers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "45",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "41",
        "median": "-",
        "maleWorkers": "35",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Stationary engineers and boiler operators": {
    "ageData": {
      "2013": {
        "total": "91",
        "16to19": "0",
        "20to24": "1",
        "25to34": "19",
        "35to44": "19",
        "45to54": "23",
        "55to64": "23",
        "65+": "5",
        "medianAge": "23"
      }
    },
    "title": "Stationary engineers and boiler operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "91",
        "women": "5.8",
        "white": "11.2",
        "black": "4.7",
        "asian": "26.1"
      }
    },
    "wageData": {
      "2013": {
        "workers": "90",
        "median": "903",
        "maleWorkers": "84",
        "maleMedian": "922",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Water and wastewater treatment plant and system operators": {
    "ageData": {
      "2013": {
        "total": "68",
        "16to19": "0",
        "20to24": "4",
        "25to34": "8",
        "35to44": "11",
        "45to54": "26",
        "55to64": "16",
        "65+": "2",
        "medianAge": "16"
      }
    },
    "title": "Water and wastewater treatment plant and system operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "68",
        "women": "6.9",
        "white": "9.1",
        "black": "3.1",
        "asian": "15.2"
      }
    },
    "wageData": {
      "2013": {
        "workers": "68",
        "median": "828",
        "maleWorkers": "64",
        "maleMedian": "829",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous plant and system operators": {
    "ageData": {
      "2013": {
        "total": "37",
        "16to19": "0",
        "20to24": "2",
        "25to34": "7",
        "35to44": "8",
        "45to54": "11",
        "55to64": "10",
        "65+": "1",
        "medianAge": "10"
      }
    },
    "title": "Miscellaneous plant and system operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "37",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "41",
        "median": "-",
        "maleWorkers": "40",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Chemical processing machine setters, operators, and tenders": {
    "ageData": {
      "2013": {
        "total": "54",
        "16to19": "2",
        "20to24": "3",
        "25to34": "9",
        "35to44": "9",
        "45to54": "23",
        "55to64": "6",
        "65+": "1",
        "medianAge": "6"
      }
    },
    "title": "Chemical processing machine setters, operators, and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "54",
        "women": "8.1",
        "white": "5.2",
        "black": "2.5",
        "asian": "11.7"
      }
    },
    "wageData": {
      "2013": {
        "workers": "46",
        "median": "-",
        "maleWorkers": "41",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Crushing, grinding, polishing, mixing, and blending workers": {
    "ageData": {
      "2013": {
        "total": "87",
        "16to19": "2",
        "20to24": "10",
        "25to34": "19",
        "35to44": "21",
        "45to54": "20",
        "55to64": "13",
        "65+": "3",
        "medianAge": "13"
      }
    },
    "title": "Crushing, grinding, polishing, mixing, and blending workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "87",
        "women": "12.8",
        "white": "13.3",
        "black": "5.3",
        "asian": "22.1"
      }
    },
    "wageData": {
      "2013": {
        "workers": "88",
        "median": "716",
        "maleWorkers": "77",
        "maleMedian": "807",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "Cutting workers": {
    "ageData": {
      "2013": {
        "total": "59",
        "16to19": "2",
        "20to24": "6",
        "25to34": "17",
        "35to44": "11",
        "45to54": "12",
        "55to64": "11",
        "65+": "2",
        "medianAge": "11"
      }
    },
    "title": "Cutting workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "59",
        "women": "24.2",
        "white": "10.8",
        "black": "3.9",
        "asian": "36.5"
      }
    },
    "wageData": {
      "2013": {
        "workers": "40",
        "median": "-",
        "maleWorkers": "30",
        "maleMedian": "-",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Extruding, forming, pressing, and compacting machine setters, operators, and tenders": {
    "ageData": {
      "2013": {
        "total": "26",
        "16to19": "0",
        "20to24": "3",
        "25to34": "3",
        "35to44": "6",
        "45to54": "7",
        "55to64": "7",
        "65+": "0",
        "medianAge": "7"
      }
    },
    "title": "Extruding, forming, pressing, and compacting machine setters, operators, and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "26",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "31",
        "median": "-",
        "maleWorkers": "26",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Furnace, kiln, oven, drier, and kettle operators and tenders": {
    "ageData": {
      "2013": {
        "total": "15",
        "16to19": "0",
        "20to24": "1",
        "25to34": "2",
        "35to44": "6",
        "45to54": "5",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Furnace, kiln, oven, drier, and kettle operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "15",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "10",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Inspectors, testers, sorters, samplers, and weighers": {
    "ageData": {
      "2013": {
        "total": "686",
        "16to19": "8",
        "20to24": "58",
        "25to34": "153",
        "35to44": "144",
        "45to54": "152",
        "55to64": "151",
        "65+": "22",
        "medianAge": "151"
      }
    },
    "title": "Inspectors, testers, sorters, samplers, and weighers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "686",
        "women": "32.8",
        "white": "12.9",
        "black": "6.8",
        "asian": "15.7"
      }
    },
    "wageData": {
      "2013": {
        "workers": "650",
        "median": "741",
        "maleWorkers": "439",
        "maleMedian": "835",
        "femaleWorkers": "211",
        "femaleMedian": "583"
      }
    }
  },
  "Jewelers and precious stone and metal workers": {
    "ageData": {
      "2013": {
        "total": "54",
        "16to19": "0",
        "20to24": "2",
        "25to34": "8",
        "35to44": "13",
        "45to54": "11",
        "55to64": "10",
        "65+": "10",
        "medianAge": "10"
      }
    },
    "title": "Jewelers and precious stone and metal workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "54",
        "women": "45.1",
        "white": "9.2",
        "black": "11.7",
        "asian": "16.7"
      }
    },
    "wageData": {
      "2013": {
        "workers": "23",
        "median": "-",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Medical, dental, and ophthalmic laboratory technicians": {
    "ageData": {
      "2013": {
        "total": "85",
        "16to19": "0",
        "20to24": "8",
        "25to34": "17",
        "35to44": "20",
        "45to54": "17",
        "55to64": "21",
        "65+": "3",
        "medianAge": "21"
      }
    },
    "title": "Medical, dental, and ophthalmic laboratory technicians",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "85",
        "women": "48.9",
        "white": "4.9",
        "black": "6.3",
        "asian": "15.7"
      }
    },
    "wageData": {
      "2013": {
        "workers": "67",
        "median": "701",
        "maleWorkers": "34",
        "maleMedian": "-",
        "femaleWorkers": "33",
        "femaleMedian": "-"
      }
    }
  },
  "Packaging and filling machine operators and tenders": {
    "ageData": {
      "2013": {
        "total": "304",
        "16to19": "6",
        "20to24": "37",
        "25to34": "73",
        "35to44": "66",
        "45to54": "78",
        "55to64": "35",
        "65+": "10",
        "medianAge": "35"
      }
    },
    "title": "Packaging and filling machine operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "304",
        "women": "51.5",
        "white": "19.7",
        "black": "9.6",
        "asian": "37.4"
      }
    },
    "wageData": {
      "2013": {
        "workers": "297",
        "median": "488",
        "maleWorkers": "137",
        "maleMedian": "537",
        "femaleWorkers": "159",
        "femaleMedian": "429"
      }
    }
  },
  "Painting workers": {
    "ageData": {
      "2013": {
        "total": "147",
        "16to19": "1",
        "20to24": "16",
        "25to34": "38",
        "35to44": "24",
        "45to54": "35",
        "55to64": "26",
        "65+": "5",
        "medianAge": "26"
      }
    },
    "title": "Painting workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "147",
        "women": "12.8",
        "white": "8.8",
        "black": "3.1",
        "asian": "31.8"
      }
    },
    "wageData": {
      "2013": {
        "workers": "125",
        "median": "664",
        "maleWorkers": "108",
        "maleMedian": "673",
        "femaleWorkers": "17",
        "femaleMedian": "-"
      }
    }
  },
  "Photographic process workers and processing machine operators": {
    "ageData": {
      "2013": {
        "total": "43",
        "16to19": "4",
        "20to24": "13",
        "25to34": "8",
        "35to44": "7",
        "45to54": "6",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Photographic process workers and processing machine operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "43",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "26",
        "median": "-",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "Semiconductor processors": {
    "ageData": {
      "2013": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Semiconductor processors",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Adhesive bonding machine operators and tenders": {
    "ageData": {
      "2013": {
        "total": "12",
        "16to19": "0",
        "20to24": "2",
        "25to34": "1",
        "35to44": "4",
        "45to54": "4",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Adhesive bonding machine operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "12",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "11",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Cleaning, washing, and metal pickling equipment operators and tenders": {
    "ageData": {
      "2013": {
        "total": "4",
        "16to19": "0",
        "20to24": "1",
        "25to34": "2",
        "35to44": "0",
        "45to54": "0",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Cleaning, washing, and metal pickling equipment operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Cooling and freezing equipment operators and tenders": {
    "ageData": {
      "2013": {
        "total": "3",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "2",
        "45to54": "0",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Cooling and freezing equipment operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Etchers and engravers": {
    "ageData": {
      "2013": {
        "total": "7",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "1",
        "45to54": "3",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Etchers and engravers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Molders, shapers, and casters, except metal and plastic": {
    "ageData": {
      "2013": {
        "total": "41",
        "16to19": "0",
        "20to24": "8",
        "25to34": "7",
        "35to44": "9",
        "45to54": "11",
        "55to64": "4",
        "65+": "1",
        "medianAge": "4"
      }
    },
    "title": "Molders, shapers, and casters, except metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "41",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "31",
        "median": "-",
        "maleWorkers": "28",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Paper goods machine setters, operators, and tenders": {
    "ageData": {
      "2013": {
        "total": "24",
        "16to19": "0",
        "20to24": "2",
        "25to34": "6",
        "35to44": "8",
        "45to54": "3",
        "55to64": "5",
        "65+": "0",
        "medianAge": "5"
      }
    },
    "title": "Paper goods machine setters, operators, and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "24",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "21",
        "median": "-",
        "maleWorkers": "18",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Tire builders": {
    "ageData": {
      "2013": {
        "total": "21",
        "16to19": "1",
        "20to24": "5",
        "25to34": "3",
        "35to44": "6",
        "45to54": "2",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Tire builders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "21",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "23",
        "median": "-",
        "maleWorkers": "16",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Helpers--production workers": {
    "ageData": {
      "2013": {
        "total": "39",
        "16to19": "6",
        "20to24": "7",
        "25to34": "8",
        "35to44": "6",
        "45to54": "4",
        "55to64": "7",
        "65+": "1",
        "medianAge": "7"
      }
    },
    "title": "Helpers--production workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "39",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "29",
        "median": "-",
        "maleWorkers": "20",
        "maleMedian": "-",
        "femaleWorkers": "9",
        "femaleMedian": "-"
      }
    }
  },
  "Production workers, all other": {
    "ageData": {
      "2013": {
        "total": "933",
        "16to19": "17",
        "20to24": "93",
        "25to34": "222",
        "35to44": "197",
        "45to54": "224",
        "55to64": "154",
        "65+": "25",
        "medianAge": "154"
      }
    },
    "title": "Production workers, all other",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "933",
        "women": "26.4",
        "white": "15.7",
        "black": "5.2",
        "asian": "22.5"
      }
    },
    "wageData": {
      "2013": {
        "workers": "866",
        "median": "586",
        "maleWorkers": "630",
        "maleMedian": "619",
        "femaleWorkers": "236",
        "femaleMedian": "501"
      }
    }
  },
  "Transportation and material moving occupations": {
    "ageData": {
      "2013": {
        "total": "8,709",
        "16to19": "297",
        "20to24": "897",
        "25to34": "1,697",
        "35to44": "1,768",
        "45to54": "2,060",
        "55to64": "1,479",
        "65+": "510",
        "medianAge": "1,479"
      }
    },
    "title": "Transportation and material moving occupations",
    "depth": 2,
    "parent": "Production, transportation, and material moving occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "8,709",
        "women": "15.5",
        "white": "17.4",
        "black": "3.1",
        "asian": "21.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "6,623",
        "median": "619",
        "maleWorkers": "5,707",
        "maleMedian": "645",
        "femaleWorkers": "916",
        "femaleMedian": "497"
      }
    }
  },
  "Supervisors of transportation and material moving workers": {
    "ageData": {
      "2013": {
        "total": "194",
        "16to19": "1",
        "20to24": "13",
        "25to34": "42",
        "35to44": "50",
        "45to54": "52",
        "55to64": "34",
        "65+": "2",
        "medianAge": "34"
      }
    },
    "title": "Supervisors of transportation and material moving workers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "194",
        "women": "21.4",
        "white": "14.5",
        "black": "1.6",
        "asian": "18.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "165",
        "median": "882",
        "maleWorkers": "126",
        "maleMedian": "930",
        "femaleWorkers": "38",
        "femaleMedian": "-"
      }
    }
  },
  "Aircraft pilots and flight engineers": {
    "ageData": {
      "2013": {
        "total": "135",
        "16to19": "0",
        "20to24": "2",
        "25to34": "21",
        "35to44": "34",
        "45to54": "41",
        "55to64": "32",
        "65+": "4",
        "medianAge": "32"
      }
    },
    "title": "Aircraft pilots and flight engineers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "135",
        "women": "5.5",
        "white": "3.4",
        "black": "3.7",
        "asian": "4.5"
      }
    },
    "wageData": {
      "2013": {
        "workers": "100",
        "median": "1,845",
        "maleWorkers": "93",
        "maleMedian": "1,859",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Air traffic controllers and airfield operations specialists": {
    "ageData": {
      "2013": {
        "total": "40",
        "16to19": "0",
        "20to24": "0",
        "25to34": "14",
        "35to44": "6",
        "45to54": "15",
        "55to64": "3",
        "65+": "2",
        "medianAge": "3"
      }
    },
    "title": "Air traffic controllers and airfield operations specialists",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "40",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "41",
        "median": "-",
        "maleWorkers": "37",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Flight attendants": {
    "ageData": {
      "2013": {
        "total": "89",
        "16to19": "0",
        "20to24": "1",
        "25to34": "10",
        "35to44": "18",
        "45to54": "30",
        "55to64": "24",
        "65+": "5",
        "medianAge": "24"
      }
    },
    "title": "Flight attendants",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "89",
        "women": "80.9",
        "white": "12.4",
        "black": "4.9",
        "asian": "10.1"
      }
    },
    "wageData": {
      "2013": {
        "workers": "69",
        "median": "767",
        "maleWorkers": "16",
        "maleMedian": "-",
        "femaleWorkers": "54",
        "femaleMedian": "749"
      }
    }
  },
  "Ambulance drivers and attendants, except emergency medical technicians": {
    "ageData": {
      "2013": {
        "total": "17",
        "16to19": "1",
        "20to24": "4",
        "25to34": "4",
        "35to44": "1",
        "45to54": "2",
        "55to64": "3",
        "65+": "2",
        "medianAge": "3"
      }
    },
    "title": "Ambulance drivers and attendants, except emergency medical technicians",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "17",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Bus drivers": {
    "ageData": {
      "2013": {
        "total": "582",
        "16to19": "1",
        "20to24": "21",
        "25to34": "53",
        "35to44": "99",
        "45to54": "161",
        "55to64": "158",
        "65+": "90",
        "medianAge": "158"
      }
    },
    "title": "Bus drivers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "582",
        "women": "45.7",
        "white": "27.3",
        "black": "2.1",
        "asian": "13.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "339",
        "median": "579",
        "maleWorkers": "192",
        "maleMedian": "597",
        "femaleWorkers": "147",
        "femaleMedian": "556"
      }
    }
  },
  "Driver/sales workers and truck drivers": {
    "ageData": {
      "2013": {
        "total": "3,252",
        "16to19": "39",
        "20to24": "199",
        "25to34": "545",
        "35to44": "741",
        "45to54": "903",
        "55to64": "622",
        "65+": "203",
        "medianAge": "622"
      }
    },
    "title": "Driver/sales workers and truck drivers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "3,252",
        "women": "5.2",
        "white": "15.3",
        "black": "2.3",
        "asian": "18.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "2,587",
        "median": "730",
        "maleWorkers": "2,482",
        "maleMedian": "738",
        "femaleWorkers": "106",
        "femaleMedian": "583"
      }
    }
  },
  "Taxi drivers and chauffeurs": {
    "ageData": {
      "2013": {
        "total": "338",
        "16to19": "2",
        "20to24": "9",
        "25to34": "55",
        "35to44": "60",
        "45to54": "96",
        "55to64": "72",
        "65+": "43",
        "medianAge": "72"
      }
    },
    "title": "Taxi drivers and chauffeurs",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "338",
        "women": "14.9",
        "white": "24.7",
        "black": "11.7",
        "asian": "14.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "202",
        "median": "521",
        "maleWorkers": "172",
        "maleMedian": "540",
        "femaleWorkers": "30",
        "femaleMedian": "-"
      }
    }
  },
  "Motor vehicle operators, all other": {
    "ageData": {
      "2013": {
        "total": "74",
        "16to19": "4",
        "20to24": "7",
        "25to34": "6",
        "35to44": "7",
        "45to54": "8",
        "55to64": "10",
        "65+": "33",
        "medianAge": "10"
      }
    },
    "title": "Motor vehicle operators, all other",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "74",
        "women": "11.1",
        "white": "11.4",
        "black": "2.8",
        "asian": "20.7"
      }
    },
    "wageData": {
      "2013": {
        "workers": "39",
        "median": "-",
        "maleWorkers": "37",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Locomotive engineers and operators": {
    "ageData": {
      "2013": {
        "total": "58",
        "16to19": "0",
        "20to24": "2",
        "25to34": "7",
        "35to44": "19",
        "45to54": "16",
        "55to64": "13",
        "65+": "0",
        "medianAge": "13"
      }
    },
    "title": "Locomotive engineers and operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "58",
        "women": "6.1",
        "white": "10.3",
        "black": "2.4",
        "asian": "12.2"
      }
    },
    "wageData": {
      "2013": {
        "workers": "50",
        "median": "1,396",
        "maleWorkers": "48",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Railroad brake, signal, and switch operators": {
    "ageData": {
      "2013": {
        "total": "10",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "4",
        "45to54": "2",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Railroad brake, signal, and switch operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "10",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Railroad conductors and yardmasters": {
    "ageData": {
      "2013": {
        "total": "50",
        "16to19": "0",
        "20to24": "2",
        "25to34": "16",
        "35to44": "18",
        "45to54": "8",
        "55to64": "5",
        "65+": "1",
        "medianAge": "5"
      }
    },
    "title": "Railroad conductors and yardmasters",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "50",
        "women": "6.4",
        "white": "17.7",
        "black": "1.5",
        "asian": "9.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "46",
        "median": "-",
        "maleWorkers": "43",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Subway, streetcar, and other rail transportation workers": {
    "ageData": {
      "2013": {
        "total": "9",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "2",
        "45to54": "1",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Subway, streetcar, and other rail transportation workers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "9",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Sailors and marine oilers": {
    "ageData": {
      "2013": {
        "total": "30",
        "16to19": "2",
        "20to24": "2",
        "25to34": "10",
        "35to44": "6",
        "45to54": "6",
        "55to64": "5",
        "65+": "1",
        "medianAge": "5"
      }
    },
    "title": "Sailors and marine oilers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "30",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "29",
        "median": "-",
        "maleWorkers": "28",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Ship and boat captains and operators": {
    "ageData": {
      "2013": {
        "total": "40",
        "16to19": "0",
        "20to24": "3",
        "25to34": "9",
        "35to44": "8",
        "45to54": "10",
        "55to64": "7",
        "65+": "3",
        "medianAge": "7"
      }
    },
    "title": "Ship and boat captains and operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "40",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "32",
        "median": "-",
        "maleWorkers": "32",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Ship engineers": {
    "ageData": {
      "2013": {
        "total": "4",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "3",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Ship engineers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Bridge and lock tenders": {
    "ageData": {
      "2013": {
        "total": "5",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "1",
        "45to54": "2",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Bridge and lock tenders",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Parking lot attendants": {
    "ageData": {
      "2013": {
        "total": "88",
        "16to19": "5",
        "20to24": "35",
        "25to34": "18",
        "35to44": "6",
        "45to54": "11",
        "55to64": "7",
        "65+": "6",
        "medianAge": "7"
      }
    },
    "title": "Parking lot attendants",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "88",
        "women": "12.9",
        "white": "22.0",
        "black": "4.3",
        "asian": "37.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "50",
        "median": "423",
        "maleWorkers": "43",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Automotive and watercraft service attendants": {
    "ageData": {
      "2013": {
        "total": "92",
        "16to19": "13",
        "20to24": "29",
        "25to34": "19",
        "35to44": "12",
        "45to54": "9",
        "55to64": "7",
        "65+": "3",
        "medianAge": "7"
      }
    },
    "title": "Automotive and watercraft service attendants",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "92",
        "women": "11.9",
        "white": "11.4",
        "black": "7.7",
        "asian": "19.7"
      }
    },
    "wageData": {
      "2013": {
        "workers": "78",
        "median": "398",
        "maleWorkers": "67",
        "maleMedian": "403",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Transportation inspectors": {
    "ageData": {
      "2013": {
        "total": "45",
        "16to19": "0",
        "20to24": "4",
        "25to34": "9",
        "35to44": "3",
        "45to54": "13",
        "55to64": "12",
        "65+": "3",
        "medianAge": "12"
      }
    },
    "title": "Transportation inspectors",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "45",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "41",
        "median": "-",
        "maleWorkers": "35",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Transportation attendants, except flight attendants": {
    "ageData": {
      "2013": {
        "total": "37",
        "16to19": "1",
        "20to24": "2",
        "25to34": "6",
        "35to44": "7",
        "45to54": "9",
        "55to64": "8",
        "65+": "4",
        "medianAge": "8"
      }
    },
    "title": "Transportation attendants, except flight attendants",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "37",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "30",
        "median": "-",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "16",
        "femaleMedian": "-"
      }
    }
  },
  "Other transportation workers": {
    "ageData": {
      "2013": {
        "total": "21",
        "16to19": "1",
        "20to24": "5",
        "25to34": "1",
        "35to44": "3",
        "45to54": "7",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Other transportation workers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "21",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "17",
        "median": "-",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Conveyor operators and tenders": {
    "ageData": {
      "2013": {
        "total": "5",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "1",
        "45to54": "2",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Conveyor operators and tenders",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Crane and tower operators": {
    "ageData": {
      "2013": {
        "total": "72",
        "16to19": "0",
        "20to24": "8",
        "25to34": "10",
        "35to44": "20",
        "45to54": "14",
        "55to64": "18",
        "65+": "2",
        "medianAge": "18"
      }
    },
    "title": "Crane and tower operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "72",
        "women": "0.7",
        "white": "12.3",
        "black": "1.9",
        "asian": "16.0"
      }
    },
    "wageData": {
      "2013": {
        "workers": "70",
        "median": "846",
        "maleWorkers": "69",
        "maleMedian": "871",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Dredge, excavating, and loading machine operators": {
    "ageData": {
      "2013": {
        "total": "40",
        "16to19": "1",
        "20to24": "1",
        "25to34": "9",
        "35to44": "6",
        "45to54": "9",
        "55to64": "8",
        "65+": "5",
        "medianAge": "8"
      }
    },
    "title": "Dredge, excavating, and loading machine operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "40",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "28",
        "median": "-",
        "maleWorkers": "28",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Hoist and winch operators": {
    "ageData": {
      "2013": {
        "total": "7",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "1",
        "45to54": "2",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Hoist and winch operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Industrial truck and tractor operators": {
    "ageData": {
      "2013": {
        "total": "557",
        "16to19": "7",
        "20to24": "54",
        "25to34": "163",
        "35to44": "118",
        "45to54": "128",
        "55to64": "77",
        "65+": "10",
        "medianAge": "77"
      }
    },
    "title": "Industrial truck and tractor operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "557",
        "women": "6.2",
        "white": "20.7",
        "black": "1.9",
        "asian": "30.9"
      }
    },
    "wageData": {
      "2013": {
        "workers": "542",
        "median": "559",
        "maleWorkers": "510",
        "maleMedian": "559",
        "femaleWorkers": "32",
        "femaleMedian": "-"
      }
    }
  },
  "Cleaners of vehicles and equipment": {
    "ageData": {
      "2013": {
        "total": "348",
        "16to19": "43",
        "20to24": "78",
        "25to34": "87",
        "35to44": "56",
        "45to54": "49",
        "55to64": "28",
        "65+": "8",
        "medianAge": "28"
      }
    },
    "title": "Cleaners of vehicles and equipment",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "348",
        "women": "15.4",
        "white": "21.2",
        "black": "2.8",
        "asian": "33.1"
      }
    },
    "wageData": {
      "2013": {
        "workers": "235",
        "median": "429",
        "maleWorkers": "206",
        "maleMedian": "434",
        "femaleWorkers": "28",
        "femaleMedian": "-"
      }
    }
  },
  "Laborers and freight, stock, and material movers, hand": {
    "ageData": {
      "2013": {
        "total": "1,752",
        "16to19": "123",
        "20to24": "315",
        "25to34": "420",
        "35to44": "335",
        "45to54": "303",
        "55to64": "208",
        "65+": "49",
        "medianAge": "208"
      }
    },
    "title": "Laborers and freight, stock, and material movers, hand",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "1,752",
        "women": "17.9",
        "white": "18.0",
        "black": "3.1",
        "asian": "23.1"
      }
    },
    "wageData": {
      "2013": {
        "workers": "1,277",
        "median": "511",
        "maleWorkers": "1,056",
        "maleMedian": "524",
        "femaleWorkers": "221",
        "femaleMedian": "421"
      }
    }
  },
  "Machine feeders and offbearers": {
    "ageData": {
      "2013": {
        "total": "21",
        "16to19": "1",
        "20to24": "1",
        "25to34": "5",
        "35to44": "5",
        "45to54": "5",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Machine feeders and offbearers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "21",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "20",
        "median": "-",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Packers and packagers, hand": {
    "ageData": {
      "2013": {
        "total": "493",
        "16to19": "48",
        "20to24": "81",
        "25to34": "96",
        "35to44": "82",
        "45to54": "98",
        "55to64": "68",
        "65+": "20",
        "medianAge": "68"
      }
    },
    "title": "Packers and packagers, hand",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "493",
        "women": "49.4",
        "white": "18.0",
        "black": "4.6",
        "asian": "37.7"
      }
    },
    "wageData": {
      "2013": {
        "workers": "331",
        "median": "422",
        "maleWorkers": "149",
        "maleMedian": "434",
        "femaleWorkers": "182",
        "femaleMedian": "416"
      }
    }
  },
  "Pumping station operators": {
    "ageData": {
      "2013": {
        "total": "30",
        "16to19": "0",
        "20to24": "3",
        "25to34": "10",
        "35to44": "4",
        "45to54": "6",
        "55to64": "6",
        "65+": "2",
        "medianAge": "6"
      }
    },
    "title": "Pumping station operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "30",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "24",
        "median": "-",
        "maleWorkers": "24",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Refuse and recyclable material collectors": {
    "ageData": {
      "2013": {
        "total": "108",
        "16to19": "2",
        "20to24": "10",
        "25to34": "32",
        "35to44": "20",
        "45to54": "24",
        "55to64": "15",
        "65+": "6",
        "medianAge": "15"
      }
    },
    "title": "Refuse and recyclable material collectors",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "108",
        "women": "4.8",
        "white": "15.3",
        "black": "1.8",
        "asian": "24.7"
      }
    },
    "wageData": {
      "2013": {
        "workers": "75",
        "median": "550",
        "maleWorkers": "74",
        "maleMedian": "555",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Mine shuttle car operators": {
    "ageData": {
      "2013": {
        "total": "4",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "1",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Mine shuttle car operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Tank car, truck, and ship loaders": {
    "ageData": {
      "2013": {
        "total": "7",
        "16to19": "0",
        "20to24": "0",
        "25to34": "4",
        "35to44": "1",
        "45to54": "2",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Tank car, truck, and ship loaders",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2013": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Material moving workers, all other": {
    "ageData": {
      "2013": {
        "total": "51",
        "16to19": "3",
        "20to24": "4",
        "25to34": "8",
        "35to44": "9",
        "45to54": "12",
        "55to64": "12",
        "65+": "2",
        "medianAge": "12"
      }
    },
    "title": "Material moving workers, all other",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2013": {
        "total": "51",
        "women": "13.1",
        "white": "20.8",
        "black": "0.8",
        "asian": "22.3"
      }
    },
    "wageData": {
      "2013": {
        "workers": "45",
        "median": "-",
        "maleWorkers": "41",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  }
}