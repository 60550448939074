{
  "Total": {
    "ageData": {
      "2016": {
        "total": "151,436",
        "16to19": "4,965",
        "20to24": "14,027",
        "25to34": "33,722",
        "35to44": "31,562",
        "45to54": "32,720",
        "55to64": "25,524",
        "65+": "8,916",
        "medianAge": "25,524"
      }
    },
    "title": "Total",
    "depth": 1,
    "parent": "",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "151,436",
        "women": "46.8",
        "white": "11.9",
        "black": "6.1",
        "asian": "16.7"
      }
    },
    "wageData": {
      "2016": {
        "workers": "111,091",
        "median": "$832",
        "maleWorkers": "61,930",
        "maleMedian": "$915",
        "femaleWorkers": "49,161",
        "femaleMedian": "$749"
      }
    }
  },
  "Management, professional, and related occupations": {
    "ageData": {
      "2016": {
        "total": "59,438",
        "16to19": "396",
        "20to24": "3,118",
        "25to34": "13,561",
        "35to44": "13,926",
        "45to54": "13,818",
        "55to64": "10,689",
        "65+": "3,931",
        "medianAge": "10,689"
      }
    },
    "title": "Management, professional, and related occupations",
    "depth": 1,
    "parent": "",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "59,438",
        "women": "51.5",
        "white": "9.1",
        "black": "8.1",
        "asian": "9.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "45,930",
        "median": "1,188",
        "maleWorkers": "22,256",
        "maleMedian": "1,420",
        "femaleWorkers": "23,674",
        "femaleMedian": "1,027"
      }
    }
  },
  "Management, business, and financial operations occupations": {
    "ageData": {
      "2016": {
        "total": "24,941",
        "16to19": "87",
        "20to24": "938",
        "25to34": "4,931",
        "35to44": "5,772",
        "45to54": "6,424",
        "55to64": "4,929",
        "65+": "1,860",
        "medianAge": "4,929"
      }
    },
    "title": "Management, business, and financial operations occupations",
    "depth": 2,
    "parent": "Management, professional, and related occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "24,941",
        "women": "43.8",
        "white": "8.2",
        "black": "6.6",
        "asian": "9.8"
      }
    },
    "wageData": {
      "2016": {
        "workers": "19,023",
        "median": "1,284",
        "maleWorkers": "10,261",
        "maleMedian": "1,491",
        "femaleWorkers": "8,761",
        "femaleMedian": "1,099"
      }
    }
  },
  "Management occupations": {
    "ageData": {
      "2016": {
        "total": "17,418",
        "16to19": "64",
        "20to24": "532",
        "25to34": "3,031",
        "35to44": "4,097",
        "45to54": "4,758",
        "55to64": "3,539",
        "65+": "1,395",
        "medianAge": "3,539"
      }
    },
    "title": "Management occupations",
    "depth": 3,
    "parent": "Management, business, and financial operations occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "17,418",
        "women": "39.1",
        "white": "7.5",
        "black": "6.1",
        "asian": "9.9"
      }
    },
    "wageData": {
      "2016": {
        "workers": "12,828",
        "median": "1,370",
        "maleWorkers": "7,514",
        "maleMedian": "1,539",
        "femaleWorkers": "5,314",
        "femaleMedian": "1,148"
      }
    }
  },
  "Chief executives": {
    "ageData": {
      "2016": {
        "total": "1,649",
        "16to19": "2",
        "20to24": "11",
        "25to34": "120",
        "35to44": "309",
        "45to54": "560",
        "55to64": "449",
        "65+": "198",
        "medianAge": "449"
      }
    },
    "title": "Chief executives",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "1,649",
        "women": "27.3",
        "white": "3.4",
        "black": "6.0",
        "asian": "5.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "1,150",
        "median": "2,303",
        "maleWorkers": "832",
        "maleMedian": "2,419",
        "femaleWorkers": "318",
        "femaleMedian": "1,876"
      }
    }
  },
  "General and operations managers": {
    "ageData": {
      "2016": {
        "total": "949",
        "16to19": "2",
        "20to24": "34",
        "25to34": "231",
        "35to44": "261",
        "45to54": "245",
        "55to64": "141",
        "65+": "35",
        "medianAge": "141"
      }
    },
    "title": "General and operations managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "949",
        "women": "29.8",
        "white": "7.1",
        "black": "5.6",
        "asian": "11.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "876",
        "median": "1,266",
        "maleWorkers": "626",
        "maleMedian": "1,358",
        "femaleWorkers": "250",
        "femaleMedian": "1,037"
      }
    }
  },
  "Legislators": {
    "ageData": {
      "2016": {
        "total": "20",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "7",
        "45to54": "3",
        "55to64": "2",
        "65+": "6",
        "medianAge": "2"
      }
    },
    "title": "Legislators",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "20",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "11",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Advertising and promotions managers": {
    "ageData": {
      "2016": {
        "total": "67",
        "16to19": "0",
        "20to24": "2",
        "25to34": "27",
        "35to44": "12",
        "45to54": "18",
        "55to64": "2",
        "65+": "4",
        "medianAge": "2"
      }
    },
    "title": "Advertising and promotions managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "67",
        "women": "56.5",
        "white": "7.1",
        "black": "1.3",
        "asian": "4.8"
      }
    },
    "wageData": {
      "2016": {
        "workers": "56",
        "median": "1,332",
        "maleWorkers": "24",
        "maleMedian": "-",
        "femaleWorkers": "32",
        "femaleMedian": "-"
      }
    }
  },
  "Marketing and sales managers": {
    "ageData": {
      "2016": {
        "total": "1,066",
        "16to19": "7",
        "20to24": "52",
        "25to34": "261",
        "35to44": "283",
        "45to54": "263",
        "55to64": "166",
        "65+": "35",
        "medianAge": "166"
      }
    },
    "title": "Marketing and sales managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "1,066",
        "women": "45.3",
        "white": "5.3",
        "black": "6.0",
        "asian": "9.9"
      }
    },
    "wageData": {
      "2016": {
        "workers": "921",
        "median": "1,468",
        "maleWorkers": "518",
        "maleMedian": "1,745",
        "femaleWorkers": "403",
        "femaleMedian": "1,142"
      }
    }
  },
  "Public relations and fundraising managers": {
    "ageData": {
      "2016": {
        "total": "62",
        "16to19": "0",
        "20to24": "2",
        "25to34": "19",
        "35to44": "17",
        "45to54": "16",
        "55to64": "8",
        "65+": "1",
        "medianAge": "8"
      }
    },
    "title": "Public relations and fundraising managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "62",
        "women": "70.9",
        "white": "10.3",
        "black": "5.4",
        "asian": "3.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "60",
        "median": "1,532",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "38",
        "femaleMedian": "-"
      }
    }
  },
  "Administrative services managers": {
    "ageData": {
      "2016": {
        "total": "181",
        "16to19": "1",
        "20to24": "6",
        "25to34": "29",
        "35to44": "29",
        "45to54": "63",
        "55to64": "44",
        "65+": "9",
        "medianAge": "44"
      }
    },
    "title": "Administrative services managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "181",
        "women": "42.6",
        "white": "13.2",
        "black": "3.4",
        "asian": "10.0"
      }
    },
    "wageData": {
      "2016": {
        "workers": "172",
        "median": "1,261",
        "maleWorkers": "101",
        "maleMedian": "1,398",
        "femaleWorkers": "71",
        "femaleMedian": "952"
      }
    }
  },
  "Computer and information systems managers": {
    "ageData": {
      "2016": {
        "total": "597",
        "16to19": "1",
        "20to24": "9",
        "25to34": "95",
        "35to44": "178",
        "45to54": "202",
        "55to64": "95",
        "65+": "18",
        "medianAge": "95"
      }
    },
    "title": "Computer and information systems managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "597",
        "women": "25.5",
        "white": "6.2",
        "black": "15.2",
        "asian": "6.7"
      }
    },
    "wageData": {
      "2016": {
        "workers": "592",
        "median": "1,738",
        "maleWorkers": "443",
        "maleMedian": "1,756",
        "femaleWorkers": "150",
        "femaleMedian": "1,680"
      }
    }
  },
  "Financial managers": {
    "ageData": {
      "2016": {
        "total": "1,197",
        "16to19": "2",
        "20to24": "31",
        "25to34": "251",
        "35to44": "340",
        "45to54": "321",
        "55to64": "196",
        "65+": "57",
        "medianAge": "196"
      }
    },
    "title": "Financial managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "1,197",
        "women": "52.5",
        "white": "6.9",
        "black": "8.3",
        "asian": "10.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "1,105",
        "median": "1,423",
        "maleWorkers": "527",
        "maleMedian": "1,670",
        "femaleWorkers": "578",
        "femaleMedian": "1,157"
      }
    }
  },
  "Compensation and benefits managers": {
    "ageData": {
      "2016": {
        "total": "20",
        "16to19": "0",
        "20to24": "0",
        "25to34": "5",
        "35to44": "6",
        "45to54": "5",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Compensation and benefits managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "20",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "16",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Human resources managers": {
    "ageData": {
      "2016": {
        "total": "287",
        "16to19": "0",
        "20to24": "6",
        "25to34": "44",
        "35to44": "97",
        "45to54": "84",
        "55to64": "44",
        "65+": "11",
        "medianAge": "44"
      }
    },
    "title": "Human resources managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "287",
        "women": "74.2",
        "white": "13.5",
        "black": "4.9",
        "asian": "11.2"
      }
    },
    "wageData": {
      "2016": {
        "workers": "263",
        "median": "1,340",
        "maleWorkers": "73",
        "maleMedian": "1,737",
        "femaleWorkers": "190",
        "femaleMedian": "1,283"
      }
    }
  },
  "Training and development managers": {
    "ageData": {
      "2016": {
        "total": "58",
        "16to19": "1",
        "20to24": "2",
        "25to34": "10",
        "35to44": "14",
        "45to54": "18",
        "55to64": "11",
        "65+": "0",
        "medianAge": "11"
      }
    },
    "title": "Training and development managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "58",
        "women": "58.5",
        "white": "8.3",
        "black": "2.1",
        "asian": "8.5"
      }
    },
    "wageData": {
      "2016": {
        "workers": "58",
        "median": "1,507",
        "maleWorkers": "27",
        "maleMedian": "-",
        "femaleWorkers": "31",
        "femaleMedian": "-"
      }
    }
  },
  "Industrial production managers": {
    "ageData": {
      "2016": {
        "total": "275",
        "16to19": "0",
        "20to24": "6",
        "25to34": "44",
        "35to44": "66",
        "45to54": "85",
        "55to64": "63",
        "65+": "11",
        "medianAge": "63"
      }
    },
    "title": "Industrial production managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "275",
        "women": "23.2",
        "white": "4.9",
        "black": "9.2",
        "asian": "7.4"
      }
    },
    "wageData": {
      "2016": {
        "workers": "267",
        "median": "1,361",
        "maleWorkers": "201",
        "maleMedian": "1,409",
        "femaleWorkers": "66",
        "femaleMedian": "1,219"
      }
    }
  },
  "Purchasing managers": {
    "ageData": {
      "2016": {
        "total": "203",
        "16to19": "0",
        "20to24": "8",
        "25to34": "29",
        "35to44": "45",
        "45to54": "50",
        "55to64": "56",
        "65+": "14",
        "medianAge": "56"
      }
    },
    "title": "Purchasing managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "203",
        "women": "47.4",
        "white": "9.1",
        "black": "2.9",
        "asian": "10.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "187",
        "median": "1,274",
        "maleWorkers": "95",
        "maleMedian": "1,400",
        "femaleWorkers": "92",
        "femaleMedian": "1,169"
      }
    }
  },
  "Transportation, storage, and distribution managers": {
    "ageData": {
      "2016": {
        "total": "299",
        "16to19": "1",
        "20to24": "15",
        "25to34": "60",
        "35to44": "78",
        "45to54": "80",
        "55to64": "51",
        "65+": "13",
        "medianAge": "51"
      }
    },
    "title": "Transportation, storage, and distribution managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "299",
        "women": "17.1",
        "white": "11.4",
        "black": "4.8",
        "asian": "18.4"
      }
    },
    "wageData": {
      "2016": {
        "workers": "288",
        "median": "990",
        "maleWorkers": "237",
        "maleMedian": "990",
        "femaleWorkers": "51",
        "femaleMedian": "988"
      }
    }
  },
  "Farmers, ranchers, and other agricultural managers": {
    "ageData": {
      "2016": {
        "total": "1,042",
        "16to19": "8",
        "20to24": "25",
        "25to34": "122",
        "35to44": "139",
        "45to54": "192",
        "55to64": "264",
        "65+": "293",
        "medianAge": "264"
      }
    },
    "title": "Farmers, ranchers, and other agricultural managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "1,042",
        "women": "24.8",
        "white": "1.0",
        "black": "1.0",
        "asian": "4.7"
      }
    },
    "wageData": {
      "2016": {
        "workers": "119",
        "median": "892",
        "maleWorkers": "102",
        "maleMedian": "906",
        "femaleWorkers": "17",
        "femaleMedian": "-"
      }
    }
  },
  "Construction managers": {
    "ageData": {
      "2016": {
        "total": "812",
        "16to19": "4",
        "20to24": "14",
        "25to34": "140",
        "35to44": "177",
        "45to54": "237",
        "55to64": "188",
        "65+": "54",
        "medianAge": "188"
      }
    },
    "title": "Construction managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "812",
        "women": "7.4",
        "white": "3.8",
        "black": "2.0",
        "asian": "11.0"
      }
    },
    "wageData": {
      "2016": {
        "workers": "487",
        "median": "1,395",
        "maleWorkers": "452",
        "maleMedian": "1,401",
        "femaleWorkers": "35",
        "femaleMedian": "-"
      }
    }
  },
  "Education administrators": {
    "ageData": {
      "2016": {
        "total": "954",
        "16to19": "5",
        "20to24": "24",
        "25to34": "134",
        "35to44": "260",
        "45to54": "280",
        "55to64": "176",
        "65+": "76",
        "medianAge": "176"
      }
    },
    "title": "Education administrators",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "954",
        "women": "65.1",
        "white": "14.3",
        "black": "2.2",
        "asian": "8.8"
      }
    },
    "wageData": {
      "2016": {
        "workers": "838",
        "median": "1,381",
        "maleWorkers": "297",
        "maleMedian": "1,528",
        "femaleWorkers": "541",
        "femaleMedian": "1,280"
      }
    }
  },
  "Architectural and engineering managers": {
    "ageData": {
      "2016": {
        "total": "136",
        "16to19": "0",
        "20to24": "0",
        "25to34": "16",
        "35to44": "39",
        "45to54": "39",
        "55to64": "34",
        "65+": "7",
        "medianAge": "34"
      }
    },
    "title": "Architectural and engineering managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "136",
        "women": "5.3",
        "white": "4.2",
        "black": "10.6",
        "asian": "3.8"
      }
    },
    "wageData": {
      "2016": {
        "workers": "143",
        "median": "2,258",
        "maleWorkers": "134",
        "maleMedian": "2,265",
        "femaleWorkers": "9",
        "femaleMedian": "-"
      }
    }
  },
  "Food service managers": {
    "ageData": {
      "2016": {
        "total": "1,157",
        "16to19": "20",
        "20to24": "140",
        "25to34": "303",
        "35to44": "228",
        "45to54": "254",
        "55to64": "165",
        "65+": "47",
        "medianAge": "165"
      }
    },
    "title": "Food service managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "1,157",
        "women": "45.9",
        "white": "10.2",
        "black": "12.0",
        "asian": "17.7"
      }
    },
    "wageData": {
      "2016": {
        "workers": "760",
        "median": "739",
        "maleWorkers": "410",
        "maleMedian": "853",
        "femaleWorkers": "350",
        "femaleMedian": "632"
      }
    }
  },
  "Funeral service managers": {
    "ageData": {
      "2016": {
        "total": "15",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "4",
        "45to54": "2",
        "55to64": "4",
        "65+": "2",
        "medianAge": "4"
      }
    },
    "title": "Funeral service managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "15",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Gaming managers": {
    "ageData": {
      "2016": {
        "total": "16",
        "16to19": "0",
        "20to24": "1",
        "25to34": "5",
        "35to44": "4",
        "45to54": "3",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Gaming managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "16",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "18",
        "median": "-",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Lodging managers": {
    "ageData": {
      "2016": {
        "total": "166",
        "16to19": "2",
        "20to24": "9",
        "25to34": "35",
        "35to44": "42",
        "45to54": "39",
        "55to64": "28",
        "65+": "11",
        "medianAge": "28"
      }
    },
    "title": "Lodging managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "166",
        "women": "52.2",
        "white": "9.7",
        "black": "15.8",
        "asian": "9.0"
      }
    },
    "wageData": {
      "2016": {
        "workers": "124",
        "median": "929",
        "maleWorkers": "59",
        "maleMedian": "1,068",
        "femaleWorkers": "64",
        "femaleMedian": "764"
      }
    }
  },
  "Medical and health services managers": {
    "ageData": {
      "2016": {
        "total": "593",
        "16to19": "0",
        "20to24": "11",
        "25to34": "102",
        "35to44": "148",
        "45to54": "165",
        "55to64": "132",
        "65+": "35",
        "medianAge": "132"
      }
    },
    "title": "Medical and health services managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "593",
        "women": "72.3",
        "white": "13.2",
        "black": "5.4",
        "asian": "10.2"
      }
    },
    "wageData": {
      "2016": {
        "workers": "533",
        "median": "1,402",
        "maleWorkers": "133",
        "maleMedian": "1,610",
        "femaleWorkers": "399",
        "femaleMedian": "1,254"
      }
    }
  },
  "Natural sciences managers": {
    "ageData": {
      "2016": {
        "total": "14",
        "16to19": "0",
        "20to24": "2",
        "25to34": "4",
        "35to44": "4",
        "45to54": "1",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Natural sciences managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "14",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "14",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Postmasters and mail superintendents": {
    "ageData": {
      "2016": {
        "total": "25",
        "16to19": "0",
        "20to24": "1",
        "25to34": "0",
        "35to44": "3",
        "45to54": "10",
        "55to64": "10",
        "65+": "1",
        "medianAge": "10"
      }
    },
    "title": "Postmasters and mail superintendents",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "25",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "23",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "Property, real estate, and community association managers": {
    "ageData": {
      "2016": {
        "total": "709",
        "16to19": "1",
        "20to24": "11",
        "25to34": "114",
        "35to44": "141",
        "45to54": "173",
        "55to64": "164",
        "65+": "106",
        "medianAge": "164"
      }
    },
    "title": "Property, real estate, and community association managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "709",
        "women": "46.5",
        "white": "9.0",
        "black": "3.6",
        "asian": "11.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "402",
        "median": "959",
        "maleWorkers": "181",
        "maleMedian": "1,157",
        "femaleWorkers": "221",
        "femaleMedian": "815"
      }
    }
  },
  "Social and community service managers": {
    "ageData": {
      "2016": {
        "total": "421",
        "16to19": "0",
        "20to24": "20",
        "25to34": "96",
        "35to44": "92",
        "45to54": "93",
        "55to64": "87",
        "65+": "32",
        "medianAge": "87"
      }
    },
    "title": "Social and community service managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "421",
        "women": "70.5",
        "white": "15.4",
        "black": "4.8",
        "asian": "11.0"
      }
    },
    "wageData": {
      "2016": {
        "workers": "363",
        "median": "1,079",
        "maleWorkers": "115",
        "maleMedian": "1,206",
        "femaleWorkers": "248",
        "femaleMedian": "992"
      }
    }
  },
  "Emergency management directors": {
    "ageData": {
      "2016": {
        "total": "10",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "2",
        "45to54": "4",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Emergency management directors",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "10",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Managers, all other": {
    "ageData": {
      "2016": {
        "total": "4,420",
        "16to19": "7",
        "20to24": "91",
        "25to34": "728",
        "35to44": "1,073",
        "45to54": "1,254",
        "55to64": "947",
        "65+": "320",
        "medianAge": "947"
      }
    },
    "title": "Managers, all other",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "4,420",
        "women": "34.4",
        "white": "7.2",
        "black": "6.3",
        "asian": "10.2"
      }
    },
    "wageData": {
      "2016": {
        "workers": "2,960",
        "median": "1,422",
        "maleWorkers": "1,845",
        "maleMedian": "1,542",
        "femaleWorkers": "1,115",
        "femaleMedian": "1,188"
      }
    }
  },
  "Business and financial operations occupations": {
    "ageData": {
      "2016": {
        "total": "7,523",
        "16to19": "22",
        "20to24": "406",
        "25to34": "1,900",
        "35to44": "1,675",
        "45to54": "1,666",
        "55to64": "1,389",
        "65+": "465",
        "medianAge": "1,389"
      }
    },
    "title": "Business and financial operations occupations",
    "depth": 3,
    "parent": "Management, business, and financial operations occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "7,523",
        "women": "54.7",
        "white": "9.7",
        "black": "7.8",
        "asian": "9.8"
      }
    },
    "wageData": {
      "2016": {
        "workers": "6,195",
        "median": "1,161",
        "maleWorkers": "2,747",
        "maleMedian": "1,373",
        "femaleWorkers": "3,448",
        "femaleMedian": "1,018"
      }
    }
  },
  "Agents and business managers of artists, performers, and athletes": {
    "ageData": {
      "2016": {
        "total": "38",
        "16to19": "0",
        "20to24": "3",
        "25to34": "12",
        "35to44": "8",
        "45to54": "6",
        "55to64": "3",
        "65+": "5",
        "medianAge": "3"
      }
    },
    "title": "Agents and business managers of artists, performers, and athletes",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "38",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "23",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Buyers and purchasing agents, farm products": {
    "ageData": {
      "2016": {
        "total": "11",
        "16to19": "1",
        "20to24": "1",
        "25to34": "2",
        "35to44": "1",
        "45to54": "1",
        "55to64": "5",
        "65+": "1",
        "medianAge": "5"
      }
    },
    "title": "Buyers and purchasing agents, farm products",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "11",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Wholesale and retail buyers, except farm products": {
    "ageData": {
      "2016": {
        "total": "207",
        "16to19": "4",
        "20to24": "18",
        "25to34": "43",
        "35to44": "33",
        "45to54": "44",
        "55to64": "49",
        "65+": "17",
        "medianAge": "49"
      }
    },
    "title": "Wholesale and retail buyers, except farm products",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "207",
        "women": "53.1",
        "white": "6.4",
        "black": "4.4",
        "asian": "11.8"
      }
    },
    "wageData": {
      "2016": {
        "workers": "152",
        "median": "838",
        "maleWorkers": "68",
        "maleMedian": "942",
        "femaleWorkers": "83",
        "femaleMedian": "765"
      }
    }
  },
  "Purchasing agents, except wholesale, retail, and farm products": {
    "ageData": {
      "2016": {
        "total": "272",
        "16to19": "1",
        "20to24": "13",
        "25to34": "63",
        "35to44": "57",
        "45to54": "65",
        "55to64": "59",
        "65+": "13",
        "medianAge": "59"
      }
    },
    "title": "Purchasing agents, except wholesale, retail, and farm products",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "272",
        "women": "48.0",
        "white": "9.9",
        "black": "6.2",
        "asian": "11.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "253",
        "median": "1,024",
        "maleWorkers": "128",
        "maleMedian": "1,037",
        "femaleWorkers": "125",
        "femaleMedian": "974"
      }
    }
  },
  "Claims adjusters, appraisers, examiners, and investigators": {
    "ageData": {
      "2016": {
        "total": "349",
        "16to19": "0",
        "20to24": "15",
        "25to34": "88",
        "35to44": "87",
        "45to54": "83",
        "55to64": "63",
        "65+": "14",
        "medianAge": "63"
      }
    },
    "title": "Claims adjusters, appraisers, examiners, and investigators",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "349",
        "women": "62.2",
        "white": "15.1",
        "black": "1.6",
        "asian": "12.7"
      }
    },
    "wageData": {
      "2016": {
        "workers": "321",
        "median": "969",
        "maleWorkers": "114",
        "maleMedian": "1,139",
        "femaleWorkers": "206",
        "femaleMedian": "912"
      }
    }
  },
  "Compliance officers": {
    "ageData": {
      "2016": {
        "total": "270",
        "16to19": "0",
        "20to24": "10",
        "25to34": "50",
        "35to44": "72",
        "45to54": "58",
        "55to64": "65",
        "65+": "14",
        "medianAge": "65"
      }
    },
    "title": "Compliance officers",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "270",
        "women": "57.1",
        "white": "12.4",
        "black": "7.9",
        "asian": "10.0"
      }
    },
    "wageData": {
      "2016": {
        "workers": "252",
        "median": "1,243",
        "maleWorkers": "106",
        "maleMedian": "1,274",
        "femaleWorkers": "146",
        "femaleMedian": "1,163"
      }
    }
  },
  "Cost estimators": {
    "ageData": {
      "2016": {
        "total": "113",
        "16to19": "0",
        "20to24": "5",
        "25to34": "25",
        "35to44": "20",
        "45to54": "26",
        "55to64": "29",
        "65+": "9",
        "medianAge": "29"
      }
    },
    "title": "Cost estimators",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "113",
        "women": "12.0",
        "white": "2.9",
        "black": "3.2",
        "asian": "8.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "104",
        "median": "1,159",
        "maleWorkers": "96",
        "maleMedian": "1,172",
        "femaleWorkers": "9",
        "femaleMedian": "-"
      }
    }
  },
  "Human resources workers": {
    "ageData": {
      "2016": {
        "total": "693",
        "16to19": "4",
        "20to24": "34",
        "25to34": "210",
        "35to44": "180",
        "45to54": "135",
        "55to64": "105",
        "65+": "25",
        "medianAge": "105"
      }
    },
    "title": "Human resources workers",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "693",
        "women": "74.6",
        "white": "12.8",
        "black": "5.2",
        "asian": "11.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "631",
        "median": "1,139",
        "maleWorkers": "166",
        "maleMedian": "1,364",
        "femaleWorkers": "465",
        "femaleMedian": "1,089"
      }
    }
  },
  "Compensation, benefits, and job analysis specialists": {
    "ageData": {
      "2016": {
        "total": "77",
        "16to19": "0",
        "20to24": "3",
        "25to34": "19",
        "35to44": "19",
        "45to54": "21",
        "55to64": "15",
        "65+": "1",
        "medianAge": "15"
      }
    },
    "title": "Compensation, benefits, and job analysis specialists",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "77",
        "women": "72.1",
        "white": "17.8",
        "black": "7.2",
        "asian": "8.5"
      }
    },
    "wageData": {
      "2016": {
        "workers": "72",
        "median": "1,069",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "51",
        "femaleMedian": "1,001"
      }
    }
  },
  "Training and development specialists": {
    "ageData": {
      "2016": {
        "total": "156",
        "16to19": "1",
        "20to24": "10",
        "25to34": "35",
        "35to44": "33",
        "45to54": "38",
        "55to64": "30",
        "65+": "8",
        "medianAge": "30"
      }
    },
    "title": "Training and development specialists",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "156",
        "women": "60.7",
        "white": "12.0",
        "black": "3.0",
        "asian": "10.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "129",
        "median": "1,103",
        "maleWorkers": "47",
        "maleMedian": "-",
        "femaleWorkers": "82",
        "femaleMedian": "959"
      }
    }
  },
  "Logisticians": {
    "ageData": {
      "2016": {
        "total": "115",
        "16to19": "0",
        "20to24": "10",
        "25to34": "38",
        "35to44": "21",
        "45to54": "22",
        "55to64": "22",
        "65+": "2",
        "medianAge": "22"
      }
    },
    "title": "Logisticians",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "115",
        "women": "32.3",
        "white": "17.0",
        "black": "6.9",
        "asian": "12.0"
      }
    },
    "wageData": {
      "2016": {
        "workers": "111",
        "median": "1,011",
        "maleWorkers": "75",
        "maleMedian": "1,116",
        "femaleWorkers": "37",
        "femaleMedian": "-"
      }
    }
  },
  "Management analysts": {
    "ageData": {
      "2016": {
        "total": "902",
        "16to19": "1",
        "20to24": "37",
        "25to34": "207",
        "35to44": "202",
        "45to54": "199",
        "55to64": "171",
        "65+": "86",
        "medianAge": "171"
      }
    },
    "title": "Management analysts",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "902",
        "women": "41.7",
        "white": "9.0",
        "black": "10.7",
        "asian": "7.9"
      }
    },
    "wageData": {
      "2016": {
        "workers": "585",
        "median": "1,476",
        "maleWorkers": "332",
        "maleMedian": "1,586",
        "femaleWorkers": "253",
        "femaleMedian": "1,342"
      }
    }
  },
  "Meeting, convention, and event planners": {
    "ageData": {
      "2016": {
        "total": "162",
        "16to19": "1",
        "20to24": "21",
        "25to34": "50",
        "35to44": "33",
        "45to54": "23",
        "55to64": "25",
        "65+": "8",
        "medianAge": "25"
      }
    },
    "title": "Meeting, convention, and event planners",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "162",
        "women": "77.6",
        "white": "8.6",
        "black": "4.5",
        "asian": "11.1"
      }
    },
    "wageData": {
      "2016": {
        "workers": "119",
        "median": "867",
        "maleWorkers": "30",
        "maleMedian": "-",
        "femaleWorkers": "88",
        "femaleMedian": "852"
      }
    }
  },
  "Fundraisers": {
    "ageData": {
      "2016": {
        "total": "86",
        "16to19": "1",
        "20to24": "9",
        "25to34": "21",
        "35to44": "17",
        "45to54": "19",
        "55to64": "15",
        "65+": "5",
        "medianAge": "15"
      }
    },
    "title": "Fundraisers",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "86",
        "women": "66.5",
        "white": "4.3",
        "black": "1.9",
        "asian": "4.8"
      }
    },
    "wageData": {
      "2016": {
        "workers": "64",
        "median": "1,111",
        "maleWorkers": "22",
        "maleMedian": "-",
        "femaleWorkers": "42",
        "femaleMedian": "-"
      }
    }
  },
  "Market research analysts and marketing specialists": {
    "ageData": {
      "2016": {
        "total": "302",
        "16to19": "2",
        "20to24": "31",
        "25to34": "112",
        "35to44": "64",
        "45to54": "47",
        "55to64": "37",
        "65+": "9",
        "medianAge": "37"
      }
    },
    "title": "Market research analysts and marketing specialists",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "302",
        "women": "55.0",
        "white": "5.0",
        "black": "8.8",
        "asian": "8.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "246",
        "median": "1,171",
        "maleWorkers": "116",
        "maleMedian": "1,345",
        "femaleWorkers": "130",
        "femaleMedian": "1,097"
      }
    }
  },
  "Business operations specialists, all other": {
    "ageData": {
      "2016": {
        "total": "290",
        "16to19": "3",
        "20to24": "20",
        "25to34": "54",
        "35to44": "63",
        "45to54": "72",
        "55to64": "59",
        "65+": "20",
        "medianAge": "59"
      }
    },
    "title": "Business operations specialists, all other",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "290",
        "women": "60.9",
        "white": "13.7",
        "black": "4.7",
        "asian": "13.5"
      }
    },
    "wageData": {
      "2016": {
        "workers": "257",
        "median": "1,125",
        "maleWorkers": "110",
        "maleMedian": "1,263",
        "femaleWorkers": "146",
        "femaleMedian": "1,001"
      }
    }
  },
  "Accountants and auditors": {
    "ageData": {
      "2016": {
        "total": "1,777",
        "16to19": "1",
        "20to24": "87",
        "25to34": "430",
        "35to44": "356",
        "45to54": "433",
        "55to64": "343",
        "65+": "126",
        "medianAge": "343"
      }
    },
    "title": "Accountants and auditors",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "1,777",
        "women": "61.3",
        "white": "8.5",
        "black": "10.9",
        "asian": "9.0"
      }
    },
    "wageData": {
      "2016": {
        "workers": "1,451",
        "median": "1,153",
        "maleWorkers": "559",
        "maleMedian": "1,441",
        "femaleWorkers": "892",
        "femaleMedian": "1,018"
      }
    }
  },
  "Appraisers and assessors of real estate": {
    "ageData": {
      "2016": {
        "total": "73",
        "16to19": "0",
        "20to24": "1",
        "25to34": "6",
        "35to44": "16",
        "45to54": "20",
        "55to64": "22",
        "65+": "7",
        "medianAge": "22"
      }
    },
    "title": "Appraisers and assessors of real estate",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "73",
        "women": "35.6",
        "white": "5.5",
        "black": "2.2",
        "asian": "9.5"
      }
    },
    "wageData": {
      "2016": {
        "workers": "31",
        "median": "-",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "13",
        "femaleMedian": "-"
      }
    }
  },
  "Budget analysts": {
    "ageData": {
      "2016": {
        "total": "53",
        "16to19": "0",
        "20to24": "1",
        "25to34": "8",
        "35to44": "12",
        "45to54": "17",
        "55to64": "12",
        "65+": "2",
        "medianAge": "12"
      }
    },
    "title": "Budget analysts",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "53",
        "women": "51.3",
        "white": "16.8",
        "black": "8.6",
        "asian": "5.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "48",
        "median": "-",
        "maleWorkers": "22",
        "maleMedian": "-",
        "femaleWorkers": "26",
        "femaleMedian": "-"
      }
    }
  },
  "Credit analysts": {
    "ageData": {
      "2016": {
        "total": "27",
        "16to19": "0",
        "20to24": "2",
        "25to34": "6",
        "35to44": "12",
        "45to54": "4",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Credit analysts",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "27",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "28",
        "median": "-",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "13",
        "femaleMedian": "-"
      }
    }
  },
  "Financial analysts": {
    "ageData": {
      "2016": {
        "total": "307",
        "16to19": "0",
        "20to24": "22",
        "25to34": "126",
        "35to44": "69",
        "45to54": "49",
        "55to64": "32",
        "65+": "10",
        "medianAge": "32"
      }
    },
    "title": "Financial analysts",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "307",
        "women": "36.9",
        "white": "6.5",
        "black": "12.8",
        "asian": "10.9"
      }
    },
    "wageData": {
      "2016": {
        "workers": "277",
        "median": "1,525",
        "maleWorkers": "173",
        "maleMedian": "1,747",
        "femaleWorkers": "104",
        "femaleMedian": "1,252"
      }
    }
  },
  "Personal financial advisors": {
    "ageData": {
      "2016": {
        "total": "513",
        "16to19": "0",
        "20to24": "20",
        "25to34": "129",
        "35to44": "129",
        "45to54": "106",
        "55to64": "92",
        "65+": "37",
        "medianAge": "92"
      }
    },
    "title": "Personal financial advisors",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "513",
        "women": "31.6",
        "white": "6.0",
        "black": "7.7",
        "asian": "7.1"
      }
    },
    "wageData": {
      "2016": {
        "workers": "399",
        "median": "1,404",
        "maleWorkers": "258",
        "maleMedian": "1,714",
        "femaleWorkers": "142",
        "femaleMedian": "953"
      }
    }
  },
  "Insurance underwriters": {
    "ageData": {
      "2016": {
        "total": "104",
        "16to19": "0",
        "20to24": "4",
        "25to34": "28",
        "35to44": "22",
        "45to54": "27",
        "55to64": "20",
        "65+": "3",
        "medianAge": "20"
      }
    },
    "title": "Insurance underwriters",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "104",
        "women": "62.5",
        "white": "9.6",
        "black": "6.3",
        "asian": "4.9"
      }
    },
    "wageData": {
      "2016": {
        "workers": "102",
        "median": "1,113",
        "maleWorkers": "34",
        "maleMedian": "-",
        "femaleWorkers": "68",
        "femaleMedian": "910"
      }
    }
  },
  "Financial examiners": {
    "ageData": {
      "2016": {
        "total": "14",
        "16to19": "0",
        "20to24": "1",
        "25to34": "2",
        "35to44": "2",
        "45to54": "4",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Financial examiners",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "14",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Credit counselors and loan officers": {
    "ageData": {
      "2016": {
        "total": "367",
        "16to19": "2",
        "20to24": "19",
        "25to34": "89",
        "35to44": "97",
        "45to54": "91",
        "55to64": "54",
        "65+": "16",
        "medianAge": "54"
      }
    },
    "title": "Credit counselors and loan officers",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "367",
        "women": "57.7",
        "white": "11.5",
        "black": "5.6",
        "asian": "10.9"
      }
    },
    "wageData": {
      "2016": {
        "workers": "351",
        "median": "1,054",
        "maleWorkers": "151",
        "maleMedian": "1,331",
        "femaleWorkers": "200",
        "femaleMedian": "926"
      }
    }
  },
  "Tax examiners and collectors, and revenue agents": {
    "ageData": {
      "2016": {
        "total": "68",
        "16to19": "0",
        "20to24": "1",
        "25to34": "16",
        "35to44": "11",
        "45to54": "20",
        "55to64": "18",
        "65+": "3",
        "medianAge": "18"
      }
    },
    "title": "Tax examiners and collectors, and revenue agents",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "68",
        "women": "63.8",
        "white": "15.8",
        "black": "8.1",
        "asian": "13.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "58",
        "median": "1,138",
        "maleWorkers": "20",
        "maleMedian": "-",
        "femaleWorkers": "38",
        "femaleMedian": "-"
      }
    }
  },
  "Tax preparers": {
    "ageData": {
      "2016": {
        "total": "108",
        "16to19": "0",
        "20to24": "5",
        "25to34": "16",
        "35to44": "19",
        "45to54": "23",
        "55to64": "27",
        "65+": "19",
        "medianAge": "27"
      }
    },
    "title": "Tax preparers",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "108",
        "women": "63.2",
        "white": "8.5",
        "black": "5.3",
        "asian": "14.9"
      }
    },
    "wageData": {
      "2016": {
        "workers": "53",
        "median": "1,049",
        "maleWorkers": "20",
        "maleMedian": "-",
        "femaleWorkers": "33",
        "femaleMedian": "-"
      }
    }
  },
  "Financial specialists, all other": {
    "ageData": {
      "2016": {
        "total": "68",
        "16to19": "0",
        "20to24": "2",
        "25to34": "16",
        "35to44": "22",
        "45to54": "13",
        "55to64": "11",
        "65+": "4",
        "medianAge": "11"
      }
    },
    "title": "Financial specialists, all other",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "68",
        "women": "55.7",
        "white": "16.8",
        "black": "5.4",
        "asian": "13.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "56",
        "median": "1,155",
        "maleWorkers": "20",
        "maleMedian": "-",
        "femaleWorkers": "37",
        "femaleMedian": "-"
      }
    }
  },
  "Professional and related occupations": {
    "ageData": {
      "2016": {
        "total": "34,498",
        "16to19": "309",
        "20to24": "2,180",
        "25to34": "8,630",
        "35to44": "8,154",
        "45to54": "7,394",
        "55to64": "5,760",
        "65+": "2,071",
        "medianAge": "5,760"
      }
    },
    "title": "Professional and related occupations",
    "depth": 2,
    "parent": "Management, professional, and related occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "34,498",
        "women": "57.0",
        "white": "9.8",
        "black": "9.2",
        "asian": "9.0"
      }
    },
    "wageData": {
      "2016": {
        "workers": "26,907",
        "median": "1,141",
        "maleWorkers": "11,995",
        "maleMedian": "1,364",
        "femaleWorkers": "14,912",
        "femaleMedian": "998"
      }
    }
  },
  "Computer and mathematical occupations": {
    "ageData": {
      "2016": {
        "total": "4,601",
        "16to19": "29",
        "20to24": "279",
        "25to34": "1,332",
        "35to44": "1,250",
        "45to54": "995",
        "55to64": "592",
        "65+": "123",
        "medianAge": "592"
      }
    },
    "title": "Computer and mathematical occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "4,601",
        "women": "25.5",
        "white": "7.9",
        "black": "21.3",
        "asian": "6.8"
      }
    },
    "wageData": {
      "2016": {
        "workers": "4,104",
        "median": "1,443",
        "maleWorkers": "3,068",
        "maleMedian": "1,518",
        "femaleWorkers": "1,035",
        "femaleMedian": "1,325"
      }
    }
  },
  "Computer and information research scientists": {
    "ageData": {
      "2016": {
        "total": "20",
        "16to19": "0",
        "20to24": "1",
        "25to34": "4",
        "35to44": "7",
        "45to54": "6",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Computer and information research scientists",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "20",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "20",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Computer systems analysts": {
    "ageData": {
      "2016": {
        "total": "526",
        "16to19": "1",
        "20to24": "30",
        "25to34": "138",
        "35to44": "137",
        "45to54": "129",
        "55to64": "79",
        "65+": "12",
        "medianAge": "79"
      }
    },
    "title": "Computer systems analysts",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "526",
        "women": "35.7",
        "white": "10.8",
        "black": "17.6",
        "asian": "6.7"
      }
    },
    "wageData": {
      "2016": {
        "workers": "496",
        "median": "1,393",
        "maleWorkers": "314",
        "maleMedian": "1,475",
        "femaleWorkers": "182",
        "femaleMedian": "1,328"
      }
    }
  },
  "Information security analysts": {
    "ageData": {
      "2016": {
        "total": "89",
        "16to19": "0",
        "20to24": "7",
        "25to34": "15",
        "35to44": "25",
        "45to54": "24",
        "55to64": "17",
        "65+": "1",
        "medianAge": "17"
      }
    },
    "title": "Information security analysts",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "89",
        "women": "21.8",
        "white": "9.2",
        "black": "7.7",
        "asian": "8.9"
      }
    },
    "wageData": {
      "2016": {
        "workers": "85",
        "median": "1,617",
        "maleWorkers": "65",
        "maleMedian": "1,826",
        "femaleWorkers": "20",
        "femaleMedian": "-"
      }
    }
  },
  "Computer programmers": {
    "ageData": {
      "2016": {
        "total": "466",
        "16to19": "3",
        "20to24": "26",
        "25to34": "109",
        "35to44": "124",
        "45to54": "119",
        "55to64": "66",
        "65+": "19",
        "medianAge": "66"
      }
    },
    "title": "Computer programmers",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "466",
        "women": "22.6",
        "white": "7.6",
        "black": "19.8",
        "asian": "6.7"
      }
    },
    "wageData": {
      "2016": {
        "workers": "403",
        "median": "1,398",
        "maleWorkers": "300",
        "maleMedian": "1,466",
        "femaleWorkers": "104",
        "femaleMedian": "1,312"
      }
    }
  },
  "Software developers, applications and systems software": {
    "ageData": {
      "2016": {
        "total": "1,483",
        "16to19": "6",
        "20to24": "79",
        "25to34": "490",
        "35to44": "404",
        "45to54": "302",
        "55to64": "168",
        "65+": "35",
        "medianAge": "168"
      }
    },
    "title": "Software developers, applications and systems software",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "1,483",
        "women": "20.0",
        "white": "4.1",
        "black": "35.7",
        "asian": "4.8"
      }
    },
    "wageData": {
      "2016": {
        "workers": "1,351",
        "median": "1,776",
        "maleWorkers": "1,084",
        "maleMedian": "1,863",
        "femaleWorkers": "266",
        "femaleMedian": "1,553"
      }
    }
  },
  "Web developers": {
    "ageData": {
      "2016": {
        "total": "205",
        "16to19": "2",
        "20to24": "16",
        "25to34": "77",
        "35to44": "64",
        "45to54": "30",
        "55to64": "14",
        "65+": "3",
        "medianAge": "14"
      }
    },
    "title": "Web developers",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "205",
        "women": "33.6",
        "white": "8.4",
        "black": "9.3",
        "asian": "5.8"
      }
    },
    "wageData": {
      "2016": {
        "workers": "142",
        "median": "1,236",
        "maleWorkers": "97",
        "maleMedian": "1,284",
        "femaleWorkers": "45",
        "femaleMedian": "-"
      }
    }
  },
  "Computer support specialists": {
    "ageData": {
      "2016": {
        "total": "570",
        "16to19": "8",
        "20to24": "44",
        "25to34": "163",
        "35to44": "143",
        "45to54": "110",
        "55to64": "84",
        "65+": "18",
        "medianAge": "84"
      }
    },
    "title": "Computer support specialists",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "570",
        "women": "25.5",
        "white": "9.8",
        "black": "12.6",
        "asian": "7.8"
      }
    },
    "wageData": {
      "2016": {
        "workers": "491",
        "median": "1,060",
        "maleWorkers": "367",
        "maleMedian": "1,078",
        "femaleWorkers": "124",
        "femaleMedian": "1,014"
      }
    }
  },
  "Database administrators": {
    "ageData": {
      "2016": {
        "total": "90",
        "16to19": "0",
        "20to24": "5",
        "25to34": "23",
        "35to44": "20",
        "45to54": "19",
        "55to64": "20",
        "65+": "2",
        "medianAge": "20"
      }
    },
    "title": "Database administrators",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "90",
        "women": "46.2",
        "white": "4.4",
        "black": "16.2",
        "asian": "2.9"
      }
    },
    "wageData": {
      "2016": {
        "workers": "83",
        "median": "1,731",
        "maleWorkers": "46",
        "maleMedian": "-",
        "femaleWorkers": "37",
        "femaleMedian": "-"
      }
    }
  },
  "Network and computer systems administrators": {
    "ageData": {
      "2016": {
        "total": "218",
        "16to19": "0",
        "20to24": "10",
        "25to34": "52",
        "35to44": "68",
        "45to54": "61",
        "55to64": "22",
        "65+": "5",
        "medianAge": "22"
      }
    },
    "title": "Network and computer systems administrators",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "218",
        "women": "17.1",
        "white": "9.6",
        "black": "10.8",
        "asian": "9.4"
      }
    },
    "wageData": {
      "2016": {
        "workers": "197",
        "median": "1,232",
        "maleWorkers": "168",
        "maleMedian": "1,250",
        "femaleWorkers": "29",
        "femaleMedian": "-"
      }
    }
  },
  "Computer network architects": {
    "ageData": {
      "2016": {
        "total": "115",
        "16to19": "0",
        "20to24": "5",
        "25to34": "31",
        "35to44": "42",
        "45to54": "26",
        "55to64": "9",
        "65+": "3",
        "medianAge": "9"
      }
    },
    "title": "Computer network architects",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "115",
        "women": "9.7",
        "white": "13.0",
        "black": "11.8",
        "asian": "9.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "105",
        "median": "1,502",
        "maleWorkers": "93",
        "maleMedian": "1,535",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Computer occupations, all other": {
    "ageData": {
      "2016": {
        "total": "596",
        "16to19": "10",
        "20to24": "41",
        "25to34": "157",
        "35to44": "165",
        "45to54": "125",
        "55to64": "82",
        "65+": "16",
        "medianAge": "82"
      }
    },
    "title": "Computer occupations, all other",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "596",
        "women": "23.4",
        "white": "11.0",
        "black": "12.7",
        "asian": "10.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "524",
        "median": "1,195",
        "maleWorkers": "418",
        "maleMedian": "1,244",
        "femaleWorkers": "106",
        "femaleMedian": "1,055"
      }
    }
  },
  "Actuaries": {
    "ageData": {
      "2016": {
        "total": "14",
        "16to19": "0",
        "20to24": "0",
        "25to34": "7",
        "35to44": "1",
        "45to54": "4",
        "55to64": "0",
        "65+": "1",
        "medianAge": "0"
      }
    },
    "title": "Actuaries",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "14",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Mathematicians": {
    "ageData": {
      "2016": {
        "total": "4",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "3",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Mathematicians",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Operations research analysts": {
    "ageData": {
      "2016": {
        "total": "129",
        "16to19": "0",
        "20to24": "5",
        "25to34": "30",
        "35to44": "39",
        "45to54": "30",
        "55to64": "19",
        "65+": "6",
        "medianAge": "19"
      }
    },
    "title": "Operations research analysts",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "129",
        "women": "55.4",
        "white": "11.2",
        "black": "12.9",
        "asian": "10.4"
      }
    },
    "wageData": {
      "2016": {
        "workers": "125",
        "median": "1,315",
        "maleWorkers": "59",
        "maleMedian": "1,382",
        "femaleWorkers": "66",
        "femaleMedian": "1,300"
      }
    }
  },
  "Statisticians": {
    "ageData": {
      "2016": {
        "total": "74",
        "16to19": "0",
        "20to24": "10",
        "25to34": "33",
        "35to44": "10",
        "45to54": "9",
        "55to64": "9",
        "65+": "2",
        "medianAge": "9"
      }
    },
    "title": "Statisticians",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "74",
        "women": "52.5",
        "white": "8.4",
        "black": "19.8",
        "asian": "3.9"
      }
    },
    "wageData": {
      "2016": {
        "workers": "65",
        "median": "1,405",
        "maleWorkers": "30",
        "maleMedian": "-",
        "femaleWorkers": "35",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous mathematical science occupations": {
    "ageData": {
      "2016": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Miscellaneous mathematical science occupations",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Architecture and engineering occupations": {
    "ageData": {
      "2016": {
        "total": "3,106",
        "16to19": "17",
        "20to24": "186",
        "25to34": "768",
        "35to44": "681",
        "45to54": "702",
        "55to64": "580",
        "65+": "172",
        "medianAge": "580"
      }
    },
    "title": "Architecture and engineering occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "3,106",
        "women": "14.2",
        "white": "5.6",
        "black": "11.6",
        "asian": "8.8"
      }
    },
    "wageData": {
      "2016": {
        "workers": "2,835",
        "median": "1,482",
        "maleWorkers": "2,437",
        "maleMedian": "1,529",
        "femaleWorkers": "398",
        "femaleMedian": "1,207"
      }
    }
  },
  "Architects, except naval": {
    "ageData": {
      "2016": {
        "total": "246",
        "16to19": "1",
        "20to24": "9",
        "25to34": "66",
        "35to44": "45",
        "45to54": "67",
        "55to64": "35",
        "65+": "24",
        "medianAge": "35"
      }
    },
    "title": "Architects, except naval",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "246",
        "women": "26.0",
        "white": "3.0",
        "black": "11.1",
        "asian": "8.0"
      }
    },
    "wageData": {
      "2016": {
        "workers": "178",
        "median": "1,339",
        "maleWorkers": "135",
        "maleMedian": "1,462",
        "femaleWorkers": "43",
        "femaleMedian": "-"
      }
    }
  },
  "Surveyors, cartographers, and photogrammetrists": {
    "ageData": {
      "2016": {
        "total": "34",
        "16to19": "0",
        "20to24": "2",
        "25to34": "9",
        "35to44": "9",
        "45to54": "6",
        "55to64": "7",
        "65+": "2",
        "medianAge": "7"
      }
    },
    "title": "Surveyors, cartographers, and photogrammetrists",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "34",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "28",
        "median": "-",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "9",
        "femaleMedian": "-"
      }
    }
  },
  "Aerospace engineers": {
    "ageData": {
      "2016": {
        "total": "130",
        "16to19": "1",
        "20to24": "3",
        "25to34": "31",
        "35to44": "27",
        "45to54": "28",
        "55to64": "33",
        "65+": "7",
        "medianAge": "33"
      }
    },
    "title": "Aerospace engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "130",
        "women": "7.8",
        "white": "2.6",
        "black": "9.3",
        "asian": "7.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "124",
        "median": "1,854",
        "maleWorkers": "114",
        "maleMedian": "1,846",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Agricultural engineers": {
    "ageData": {
      "2016": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "2",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Agricultural engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Biomedical engineers": {
    "ageData": {
      "2016": {
        "total": "13",
        "16to19": "0",
        "20to24": "2",
        "25to34": "6",
        "35to44": "3",
        "45to54": "0",
        "55to64": "1",
        "65+": "2",
        "medianAge": "1"
      }
    },
    "title": "Biomedical engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "13",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "14",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Chemical engineers": {
    "ageData": {
      "2016": {
        "total": "79",
        "16to19": "0",
        "20to24": "7",
        "25to34": "19",
        "35to44": "15",
        "45to54": "17",
        "55to64": "17",
        "65+": "3",
        "medianAge": "17"
      }
    },
    "title": "Chemical engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "79",
        "women": "20.1",
        "white": "4.1",
        "black": "10.3",
        "asian": "6.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "91",
        "median": "1,877",
        "maleWorkers": "73",
        "maleMedian": "1,901",
        "femaleWorkers": "18",
        "femaleMedian": "-"
      }
    }
  },
  "Civil engineers": {
    "ageData": {
      "2016": {
        "total": "425",
        "16to19": "2",
        "20to24": "30",
        "25to34": "114",
        "35to44": "89",
        "45to54": "92",
        "55to64": "77",
        "65+": "21",
        "medianAge": "77"
      }
    },
    "title": "Civil engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "425",
        "women": "10.8",
        "white": "3.6",
        "black": "7.7",
        "asian": "10.4"
      }
    },
    "wageData": {
      "2016": {
        "workers": "389",
        "median": "1,504",
        "maleWorkers": "350",
        "maleMedian": "1,526",
        "femaleWorkers": "39",
        "femaleMedian": "-"
      }
    }
  },
  "Computer hardware engineers": {
    "ageData": {
      "2016": {
        "total": "69",
        "16to19": "0",
        "20to24": "4",
        "25to34": "17",
        "35to44": "19",
        "45to54": "18",
        "55to64": "8",
        "65+": "2",
        "medianAge": "8"
      }
    },
    "title": "Computer hardware engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "69",
        "women": "24.7",
        "white": "5.3",
        "black": "29.2",
        "asian": "12.0"
      }
    },
    "wageData": {
      "2016": {
        "workers": "58",
        "median": "1,843",
        "maleWorkers": "46",
        "maleMedian": "-",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Electrical and electronics engineers": {
    "ageData": {
      "2016": {
        "total": "293",
        "16to19": "1",
        "20to24": "16",
        "25to34": "68",
        "35to44": "67",
        "45to54": "63",
        "55to64": "65",
        "65+": "13",
        "medianAge": "65"
      }
    },
    "title": "Electrical and electronics engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "293",
        "women": "10.8",
        "white": "7.5",
        "black": "15.1",
        "asian": "9.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "271",
        "median": "1,729",
        "maleWorkers": "245",
        "maleMedian": "1,736",
        "femaleWorkers": "26",
        "femaleMedian": "-"
      }
    }
  },
  "Environmental engineers": {
    "ageData": {
      "2016": {
        "total": "33",
        "16to19": "1",
        "20to24": "1",
        "25to34": "5",
        "35to44": "13",
        "45to54": "6",
        "55to64": "7",
        "65+": "1",
        "medianAge": "7"
      }
    },
    "title": "Environmental engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "33",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "31",
        "median": "-",
        "maleWorkers": "27",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Industrial engineers, including health and safety": {
    "ageData": {
      "2016": {
        "total": "220",
        "16to19": "0",
        "20to24": "13",
        "25to34": "64",
        "35to44": "42",
        "45to54": "48",
        "55to64": "44",
        "65+": "9",
        "medianAge": "44"
      }
    },
    "title": "Industrial engineers, including health and safety",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "220",
        "women": "20.3",
        "white": "2.7",
        "black": "6.6",
        "asian": "11.2"
      }
    },
    "wageData": {
      "2016": {
        "workers": "215",
        "median": "1,526",
        "maleWorkers": "169",
        "maleMedian": "1,580",
        "femaleWorkers": "46",
        "femaleMedian": "-"
      }
    }
  },
  "Marine engineers and naval architects": {
    "ageData": {
      "2016": {
        "total": "13",
        "16to19": "0",
        "20to24": "1",
        "25to34": "3",
        "35to44": "4",
        "45to54": "3",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Marine engineers and naval architects",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "13",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "19",
        "median": "-",
        "maleWorkers": "18",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Materials engineers": {
    "ageData": {
      "2016": {
        "total": "31",
        "16to19": "0",
        "20to24": "0",
        "25to34": "5",
        "35to44": "8",
        "45to54": "6",
        "55to64": "8",
        "65+": "3",
        "medianAge": "8"
      }
    },
    "title": "Materials engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "31",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "26",
        "median": "-",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Mechanical engineers": {
    "ageData": {
      "2016": {
        "total": "350",
        "16to19": "1",
        "20to24": "24",
        "25to34": "93",
        "35to44": "82",
        "45to54": "70",
        "55to64": "60",
        "65+": "19",
        "medianAge": "60"
      }
    },
    "title": "Mechanical engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "350",
        "women": "6.4",
        "white": "4.2",
        "black": "10.5",
        "asian": "5.5"
      }
    },
    "wageData": {
      "2016": {
        "workers": "335",
        "median": "1,560",
        "maleWorkers": "311",
        "maleMedian": "1,567",
        "femaleWorkers": "24",
        "femaleMedian": "-"
      }
    }
  },
  "Mining and geological engineers, including mining safety engineers": {
    "ageData": {
      "2016": {
        "total": "9",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "3",
        "45to54": "1",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Mining and geological engineers, including mining safety engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "9",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Nuclear engineers": {
    "ageData": {
      "2016": {
        "total": "7",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "3",
        "45to54": "0",
        "55to64": "1",
        "65+": "1",
        "medianAge": "1"
      }
    },
    "title": "Nuclear engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Petroleum engineers": {
    "ageData": {
      "2016": {
        "total": "36",
        "16to19": "0",
        "20to24": "2",
        "25to34": "15",
        "35to44": "7",
        "45to54": "5",
        "55to64": "6",
        "65+": "2",
        "medianAge": "6"
      }
    },
    "title": "Petroleum engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "36",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "35",
        "median": "-",
        "maleWorkers": "32",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Engineers, all other": {
    "ageData": {
      "2016": {
        "total": "539",
        "16to19": "1",
        "20to24": "24",
        "25to34": "131",
        "35to44": "125",
        "45to54": "141",
        "55to64": "87",
        "65+": "31",
        "medianAge": "87"
      }
    },
    "title": "Engineers, all other",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "539",
        "women": "12.2",
        "white": "7.2",
        "black": "18.2",
        "asian": "6.1"
      }
    },
    "wageData": {
      "2016": {
        "workers": "493",
        "median": "1,573",
        "maleWorkers": "430",
        "maleMedian": "1,659",
        "femaleWorkers": "63",
        "femaleMedian": "1,401"
      }
    }
  },
  "Drafters": {
    "ageData": {
      "2016": {
        "total": "109",
        "16to19": "2",
        "20to24": "9",
        "25to34": "23",
        "35to44": "23",
        "45to54": "28",
        "55to64": "22",
        "65+": "3",
        "medianAge": "22"
      }
    },
    "title": "Drafters",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "109",
        "women": "15.0",
        "white": "4.9",
        "black": "4.9",
        "asian": "22.7"
      }
    },
    "wageData": {
      "2016": {
        "workers": "93",
        "median": "990",
        "maleWorkers": "83",
        "maleMedian": "1,030",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Engineering technicians, except drafters": {
    "ageData": {
      "2016": {
        "total": "385",
        "16to19": "6",
        "20to24": "29",
        "25to34": "75",
        "35to44": "87",
        "45to54": "89",
        "55to64": "78",
        "65+": "20",
        "medianAge": "78"
      }
    },
    "title": "Engineering technicians, except drafters",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "385",
        "women": "19.8",
        "white": "11.2",
        "black": "8.8",
        "asian": "10.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "350",
        "median": "997",
        "maleWorkers": "275",
        "maleMedian": "1,075",
        "femaleWorkers": "75",
        "femaleMedian": "793"
      }
    }
  },
  "Surveying and mapping technicians": {
    "ageData": {
      "2016": {
        "total": "83",
        "16to19": "2",
        "20to24": "9",
        "25to34": "22",
        "35to44": "11",
        "45to54": "13",
        "55to64": "18",
        "65+": "8",
        "medianAge": "18"
      }
    },
    "title": "Surveying and mapping technicians",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "83",
        "women": "7.9",
        "white": "3.7",
        "black": "3.2",
        "asian": "7.4"
      }
    },
    "wageData": {
      "2016": {
        "workers": "67",
        "median": "811",
        "maleWorkers": "61",
        "maleMedian": "790",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Life, physical, and social science occupations": {
    "ageData": {
      "2016": {
        "total": "1,367",
        "16to19": "6",
        "20to24": "103",
        "25to34": "381",
        "35to44": "292",
        "45to54": "273",
        "55to64": "209",
        "65+": "103",
        "medianAge": "209"
      }
    },
    "title": "Life, physical, and social science occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "1,367",
        "women": "44.0",
        "white": "6.4",
        "black": "16.0",
        "asian": "8.2"
      }
    },
    "wageData": {
      "2016": {
        "workers": "1,151",
        "median": "1,209",
        "maleWorkers": "665",
        "maleMedian": "1,319",
        "femaleWorkers": "486",
        "femaleMedian": "1,119"
      }
    }
  },
  "Agricultural and food scientists": {
    "ageData": {
      "2016": {
        "total": "35",
        "16to19": "0",
        "20to24": "6",
        "25to34": "9",
        "35to44": "4",
        "45to54": "7",
        "55to64": "5",
        "65+": "4",
        "medianAge": "5"
      }
    },
    "title": "Agricultural and food scientists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "35",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "32",
        "median": "-",
        "maleWorkers": "20",
        "maleMedian": "-",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Biological scientists": {
    "ageData": {
      "2016": {
        "total": "96",
        "16to19": "0",
        "20to24": "5",
        "25to34": "27",
        "35to44": "30",
        "45to54": "13",
        "55to64": "15",
        "65+": "5",
        "medianAge": "15"
      }
    },
    "title": "Biological scientists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "96",
        "women": "43.4",
        "white": "6.5",
        "black": "17.2",
        "asian": "7.0"
      }
    },
    "wageData": {
      "2016": {
        "workers": "81",
        "median": "1,346",
        "maleWorkers": "46",
        "maleMedian": "-",
        "femaleWorkers": "35",
        "femaleMedian": "-"
      }
    }
  },
  "Conservation scientists and foresters": {
    "ageData": {
      "2016": {
        "total": "31",
        "16to19": "0",
        "20to24": "1",
        "25to34": "8",
        "35to44": "5",
        "45to54": "8",
        "55to64": "7",
        "65+": "2",
        "medianAge": "7"
      }
    },
    "title": "Conservation scientists and foresters",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "31",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "26",
        "median": "-",
        "maleWorkers": "23",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Medical scientists": {
    "ageData": {
      "2016": {
        "total": "154",
        "16to19": "0",
        "20to24": "17",
        "25to34": "46",
        "35to44": "37",
        "45to54": "30",
        "55to64": "13",
        "65+": "10",
        "medianAge": "13"
      }
    },
    "title": "Medical scientists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "154",
        "women": "46.9",
        "white": "3.7",
        "black": "37.3",
        "asian": "4.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "127",
        "median": "1,211",
        "maleWorkers": "69",
        "maleMedian": "1,250",
        "femaleWorkers": "57",
        "femaleMedian": "1,169"
      }
    }
  },
  "Life scientists, all other": {
    "ageData": {
      "2016": {
        "total": "3",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "1",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Life scientists, all other",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Astronomers and physicists": {
    "ageData": {
      "2016": {
        "total": "19",
        "16to19": "0",
        "20to24": "2",
        "25to34": "8",
        "35to44": "2",
        "45to54": "1",
        "55to64": "6",
        "65+": "1",
        "medianAge": "6"
      }
    },
    "title": "Astronomers and physicists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "19",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "20",
        "median": "-",
        "maleWorkers": "16",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Atmospheric and space scientists": {
    "ageData": {
      "2016": {
        "total": "6",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "1",
        "45to54": "3",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Atmospheric and space scientists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "6",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Chemists and materials scientists": {
    "ageData": {
      "2016": {
        "total": "95",
        "16to19": "0",
        "20to24": "4",
        "25to34": "33",
        "35to44": "20",
        "45to54": "15",
        "55to64": "16",
        "65+": "8",
        "medianAge": "16"
      }
    },
    "title": "Chemists and materials scientists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "95",
        "women": "34.6",
        "white": "10.6",
        "black": "18.4",
        "asian": "2.7"
      }
    },
    "wageData": {
      "2016": {
        "workers": "93",
        "median": "1,306",
        "maleWorkers": "56",
        "maleMedian": "1,504",
        "femaleWorkers": "36",
        "femaleMedian": "-"
      }
    }
  },
  "Environmental scientists and geoscientists": {
    "ageData": {
      "2016": {
        "total": "89",
        "16to19": "0",
        "20to24": "2",
        "25to34": "27",
        "35to44": "15",
        "45to54": "18",
        "55to64": "18",
        "65+": "8",
        "medianAge": "18"
      }
    },
    "title": "Environmental scientists and geoscientists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "89",
        "women": "30.0",
        "white": "3.4",
        "black": "8.0",
        "asian": "5.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "79",
        "median": "1,555",
        "maleWorkers": "58",
        "maleMedian": "1,591",
        "femaleWorkers": "21",
        "femaleMedian": "-"
      }
    }
  },
  "Physical scientists, all other": {
    "ageData": {
      "2016": {
        "total": "261",
        "16to19": "0",
        "20to24": "10",
        "25to34": "84",
        "35to44": "58",
        "45to54": "60",
        "55to64": "34",
        "65+": "14",
        "medianAge": "34"
      }
    },
    "title": "Physical scientists, all other",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "261",
        "women": "45.0",
        "white": "4.9",
        "black": "26.9",
        "asian": "9.7"
      }
    },
    "wageData": {
      "2016": {
        "workers": "241",
        "median": "1,416",
        "maleWorkers": "131",
        "maleMedian": "1,727",
        "femaleWorkers": "111",
        "femaleMedian": "1,323"
      }
    }
  },
  "Economists": {
    "ageData": {
      "2016": {
        "total": "26",
        "16to19": "1",
        "20to24": "0",
        "25to34": "8",
        "35to44": "10",
        "45to54": "3",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Economists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "26",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "22",
        "median": "-",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "9",
        "femaleMedian": "-"
      }
    }
  },
  "Survey researchers": {
    "ageData": {
      "2016": {
        "total": "3",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "0",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Survey researchers",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Psychologists": {
    "ageData": {
      "2016": {
        "total": "161",
        "16to19": "0",
        "20to24": "2",
        "25to34": "33",
        "35to44": "33",
        "45to54": "31",
        "55to64": "32",
        "65+": "31",
        "medianAge": "32"
      }
    },
    "title": "Psychologists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "161",
        "women": "67.5",
        "white": "5.8",
        "black": "4.2",
        "asian": "6.9"
      }
    },
    "wageData": {
      "2016": {
        "workers": "91",
        "median": "1,260",
        "maleWorkers": "25",
        "maleMedian": "-",
        "femaleWorkers": "66",
        "femaleMedian": "1,187"
      }
    }
  },
  "Sociologists": {
    "ageData": {
      "2016": {
        "total": "3",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "1",
        "45to54": "2",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Sociologists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Urban and regional planners": {
    "ageData": {
      "2016": {
        "total": "38",
        "16to19": "0",
        "20to24": "0",
        "25to34": "6",
        "35to44": "14",
        "45to54": "11",
        "55to64": "6",
        "65+": "1",
        "medianAge": "6"
      }
    },
    "title": "Urban and regional planners",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "38",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "34",
        "median": "-",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "13",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous social scientists and related workers": {
    "ageData": {
      "2016": {
        "total": "39",
        "16to19": "0",
        "20to24": "3",
        "25to34": "16",
        "35to44": "5",
        "45to54": "7",
        "55to64": "3",
        "65+": "5",
        "medianAge": "3"
      }
    },
    "title": "Miscellaneous social scientists and related workers",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "39",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "30",
        "median": "-",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "Agricultural and food science technicians": {
    "ageData": {
      "2016": {
        "total": "35",
        "16to19": "0",
        "20to24": "6",
        "25to34": "6",
        "35to44": "7",
        "45to54": "8",
        "55to64": "7",
        "65+": "1",
        "medianAge": "7"
      }
    },
    "title": "Agricultural and food science technicians",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "35",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "24",
        "median": "-",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Biological technicians": {
    "ageData": {
      "2016": {
        "total": "17",
        "16to19": "0",
        "20to24": "3",
        "25to34": "5",
        "35to44": "4",
        "45to54": "3",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Biological technicians",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "17",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "19",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Chemical technicians": {
    "ageData": {
      "2016": {
        "total": "76",
        "16to19": "1",
        "20to24": "9",
        "25to34": "13",
        "35to44": "16",
        "45to54": "17",
        "55to64": "17",
        "65+": "3",
        "medianAge": "17"
      }
    },
    "title": "Chemical technicians",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "76",
        "women": "34.6",
        "white": "14.9",
        "black": "8.2",
        "asian": "12.9"
      }
    },
    "wageData": {
      "2016": {
        "workers": "75",
        "median": "919",
        "maleWorkers": "50",
        "maleMedian": "919",
        "femaleWorkers": "25",
        "femaleMedian": "-"
      }
    }
  },
  "Geological and petroleum technicians": {
    "ageData": {
      "2016": {
        "total": "18",
        "16to19": "1",
        "20to24": "0",
        "25to34": "6",
        "35to44": "2",
        "45to54": "5",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Geological and petroleum technicians",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "18",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "17",
        "median": "-",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Nuclear technicians": {
    "ageData": {
      "2016": {
        "total": "5",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "3",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Nuclear technicians",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Social science research assistants": {
    "ageData": {
      "2016": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Social science research assistants",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous life, physical, and social science technicians": {
    "ageData": {
      "2016": {
        "total": "155",
        "16to19": "3",
        "20to24": "32",
        "25to34": "40",
        "35to44": "25",
        "45to54": "25",
        "55to64": "20",
        "65+": "9",
        "medianAge": "20"
      }
    },
    "title": "Miscellaneous life, physical, and social science technicians",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "155",
        "women": "51.0",
        "white": "8.0",
        "black": "13.0",
        "asian": "11.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "119",
        "median": "894",
        "maleWorkers": "62",
        "maleMedian": "949",
        "femaleWorkers": "57",
        "femaleMedian": "826"
      }
    }
  },
  "Community and social service occupations": {
    "ageData": {
      "2016": {
        "total": "2,612",
        "16to19": "19",
        "20to24": "153",
        "25to34": "614",
        "35to44": "583",
        "45to54": "548",
        "55to64": "488",
        "65+": "207",
        "medianAge": "488"
      }
    },
    "title": "Community and social service occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "2,612",
        "women": "65.5",
        "white": "18.6",
        "black": "3.4",
        "asian": "12.1"
      }
    },
    "wageData": {
      "2016": {
        "workers": "2,136",
        "median": "919",
        "maleWorkers": "759",
        "maleMedian": "1,004",
        "femaleWorkers": "1,377",
        "femaleMedian": "880"
      }
    }
  },
  "Counselors": {
    "ageData": {
      "2016": {
        "total": "869",
        "16to19": "10",
        "20to24": "77",
        "25to34": "251",
        "35to44": "183",
        "45to54": "171",
        "55to64": "136",
        "65+": "42",
        "medianAge": "136"
      }
    },
    "title": "Counselors",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "869",
        "women": "73.0",
        "white": "19.6",
        "black": "2.7",
        "asian": "11.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "657",
        "median": "904",
        "maleWorkers": "184",
        "maleMedian": "892",
        "femaleWorkers": "473",
        "femaleMedian": "907"
      }
    }
  },
  "Social workers": {
    "ageData": {
      "2016": {
        "total": "781",
        "16to19": "3",
        "20to24": "31",
        "25to34": "199",
        "35to44": "194",
        "45to54": "171",
        "55to64": "137",
        "65+": "46",
        "medianAge": "137"
      }
    },
    "title": "Social workers",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "781",
        "women": "81.5",
        "white": "22.7",
        "black": "3.0",
        "asian": "13.8"
      }
    },
    "wageData": {
      "2016": {
        "workers": "684",
        "median": "906",
        "maleWorkers": "127",
        "maleMedian": "1,039",
        "femaleWorkers": "557",
        "femaleMedian": "884"
      }
    }
  },
  "Probation officers and correctional treatment specialists": {
    "ageData": {
      "2016": {
        "total": "102",
        "16to19": "0",
        "20to24": "3",
        "25to34": "19",
        "35to44": "34",
        "45to54": "27",
        "55to64": "14",
        "65+": "6",
        "medianAge": "14"
      }
    },
    "title": "Probation officers and correctional treatment specialists",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "102",
        "women": "57.4",
        "white": "21.0",
        "black": "1.3",
        "asian": "14.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "100",
        "median": "992",
        "maleWorkers": "39",
        "maleMedian": "-",
        "femaleWorkers": "61",
        "femaleMedian": "1,004"
      }
    }
  },
  "Social and human service assistants": {
    "ageData": {
      "2016": {
        "total": "184",
        "16to19": "2",
        "20to24": "14",
        "25to34": "46",
        "35to44": "41",
        "45to54": "42",
        "55to64": "26",
        "65+": "12",
        "medianAge": "26"
      }
    },
    "title": "Social and human service assistants",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "184",
        "women": "80.5",
        "white": "21.6",
        "black": "2.6",
        "asian": "14.1"
      }
    },
    "wageData": {
      "2016": {
        "workers": "151",
        "median": "736",
        "maleWorkers": "29",
        "maleMedian": "-",
        "femaleWorkers": "122",
        "femaleMedian": "727"
      }
    }
  },
  "Miscellaneous community and social service specialists, including health educators and community health workers": {
    "ageData": {
      "2016": {
        "total": "89",
        "16to19": "1",
        "20to24": "7",
        "25to34": "24",
        "35to44": "22",
        "45to54": "13",
        "55to64": "17",
        "65+": "6",
        "medianAge": "17"
      }
    },
    "title": "Miscellaneous community and social service specialists, including health educators and community health workers",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "89",
        "women": "74.9",
        "white": "20.4",
        "black": "2.5",
        "asian": "16.1"
      }
    },
    "wageData": {
      "2016": {
        "workers": "83",
        "median": "831",
        "maleWorkers": "20",
        "maleMedian": "-",
        "femaleWorkers": "63",
        "femaleMedian": "767"
      }
    }
  },
  "Clergy": {
    "ageData": {
      "2016": {
        "total": "436",
        "16to19": "0",
        "20to24": "8",
        "25to34": "50",
        "35to44": "81",
        "45to54": "100",
        "55to64": "120",
        "65+": "77",
        "medianAge": "120"
      }
    },
    "title": "Clergy",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "436",
        "women": "17.6",
        "white": "11.8",
        "black": "5.9",
        "asian": "9.1"
      }
    },
    "wageData": {
      "2016": {
        "workers": "368",
        "median": "1,039",
        "maleWorkers": "315",
        "maleMedian": "1,070",
        "femaleWorkers": "53",
        "femaleMedian": "893"
      }
    }
  },
  "Directors, religious activities and education": {
    "ageData": {
      "2016": {
        "total": "78",
        "16to19": "1",
        "20to24": "8",
        "25to34": "12",
        "35to44": "16",
        "45to54": "11",
        "55to64": "22",
        "65+": "9",
        "medianAge": "22"
      }
    },
    "title": "Directors, religious activities and education",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "78",
        "women": "55.0",
        "white": "3.1",
        "black": "4.2",
        "asian": "9.9"
      }
    },
    "wageData": {
      "2016": {
        "workers": "49",
        "median": "-",
        "maleWorkers": "23",
        "maleMedian": "-",
        "femaleWorkers": "26",
        "femaleMedian": "-"
      }
    }
  },
  "Religious workers, all other": {
    "ageData": {
      "2016": {
        "total": "73",
        "16to19": "1",
        "20to24": "6",
        "25to34": "13",
        "35to44": "12",
        "45to54": "14",
        "55to64": "17",
        "65+": "10",
        "medianAge": "17"
      }
    },
    "title": "Religious workers, all other",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "73",
        "women": "64.4",
        "white": "7.4",
        "black": "7.9",
        "asian": "8.0"
      }
    },
    "wageData": {
      "2016": {
        "workers": "42",
        "median": "-",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "21",
        "femaleMedian": "-"
      }
    }
  },
  "Legal occupations": {
    "ageData": {
      "2016": {
        "total": "1,808",
        "16to19": "3",
        "20to24": "35",
        "25to34": "404",
        "35to44": "436",
        "45to54": "395",
        "55to64": "332",
        "65+": "201",
        "medianAge": "332"
      }
    },
    "title": "Legal occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "1,808",
        "women": "51.8",
        "white": "6.3",
        "black": "4.1",
        "asian": "8.4"
      }
    },
    "wageData": {
      "2016": {
        "workers": "1,294",
        "median": "1,431",
        "maleWorkers": "562",
        "maleMedian": "1,904",
        "femaleWorkers": "732",
        "femaleMedian": "1,152"
      }
    }
  },
  "Lawyers": {
    "ageData": {
      "2016": {
        "total": "1,133",
        "16to19": "0",
        "20to24": "0",
        "25to34": "272",
        "35to44": "288",
        "45to54": "237",
        "55to64": "197",
        "65+": "140",
        "medianAge": "197"
      }
    },
    "title": "Lawyers",
    "depth": 4,
    "parent": "Legal occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "1,133",
        "women": "35.7",
        "white": "4.4",
        "black": "4.7",
        "asian": "5.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "745",
        "median": "1,897",
        "maleWorkers": "446",
        "maleMedian": "2,086",
        "femaleWorkers": "299",
        "femaleMedian": "1,619"
      }
    }
  },
  "Judicial law clerks": {
    "ageData": {
      "2016": {
        "total": "15",
        "16to19": "0",
        "20to24": "1",
        "25to34": "9",
        "35to44": "3",
        "45to54": "0",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Judicial law clerks",
    "depth": 4,
    "parent": "Legal occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "15",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "14",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Judges, magistrates, and other judicial workers": {
    "ageData": {
      "2016": {
        "total": "59",
        "16to19": "0",
        "20to24": "0",
        "25to34": "4",
        "35to44": "7",
        "45to54": "16",
        "55to64": "17",
        "65+": "15",
        "medianAge": "17"
      }
    },
    "title": "Judges, magistrates, and other judicial workers",
    "depth": 4,
    "parent": "Legal occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "59",
        "women": "34.2",
        "white": "11.0",
        "black": "1.2",
        "asian": "3.5"
      }
    },
    "wageData": {
      "2016": {
        "workers": "56",
        "median": "1,560",
        "maleWorkers": "35",
        "maleMedian": "-",
        "femaleWorkers": "21",
        "femaleMedian": "-"
      }
    }
  },
  "Paralegals and legal assistants": {
    "ageData": {
      "2016": {
        "total": "433",
        "16to19": "3",
        "20to24": "30",
        "25to34": "96",
        "35to44": "101",
        "45to54": "101",
        "55to64": "72",
        "65+": "30",
        "medianAge": "72"
      }
    },
    "title": "Paralegals and legal assistants",
    "depth": 4,
    "parent": "Legal occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "433",
        "women": "86.7",
        "white": "10.2",
        "black": "3.5",
        "asian": "16.2"
      }
    },
    "wageData": {
      "2016": {
        "workers": "351",
        "median": "874",
        "maleWorkers": "43",
        "maleMedian": "-",
        "femaleWorkers": "308",
        "femaleMedian": "863"
      }
    }
  },
  "Miscellaneous legal support workers": {
    "ageData": {
      "2016": {
        "total": "168",
        "16to19": "0",
        "20to24": "3",
        "25to34": "23",
        "35to44": "37",
        "45to54": "42",
        "55to64": "46",
        "65+": "16",
        "medianAge": "46"
      }
    },
    "title": "Miscellaneous legal support workers",
    "depth": 4,
    "parent": "Legal occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "168",
        "women": "77.0",
        "white": "8.4",
        "black": "1.9",
        "asian": "8.7"
      }
    },
    "wageData": {
      "2016": {
        "workers": "128",
        "median": "1,009",
        "maleWorkers": "30",
        "maleMedian": "-",
        "femaleWorkers": "98",
        "femaleMedian": "867"
      }
    }
  },
  "Education, training, and library occupations": {
    "ageData": {
      "2016": {
        "total": "8,948",
        "16to19": "111",
        "20to24": "651",
        "25to34": "2,012",
        "35to44": "2,159",
        "45to54": "1,935",
        "55to64": "1,523",
        "65+": "557",
        "medianAge": "1,523"
      }
    },
    "title": "Education, training, and library occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "8,948",
        "women": "73.1",
        "white": "10.0",
        "black": "4.7",
        "asian": "10.0"
      }
    },
    "wageData": {
      "2016": {
        "workers": "6,859",
        "median": "984",
        "maleWorkers": "1,877",
        "maleMedian": "1,154",
        "femaleWorkers": "4,982",
        "femaleMedian": "933"
      }
    }
  },
  "Postsecondary teachers": {
    "ageData": {
      "2016": {
        "total": "1,420",
        "16to19": "13",
        "20to24": "108",
        "25to34": "334",
        "35to44": "292",
        "45to54": "251",
        "55to64": "269",
        "65+": "152",
        "medianAge": "269"
      }
    },
    "title": "Postsecondary teachers",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "1,420",
        "women": "48.3",
        "white": "7.0",
        "black": "12.3",
        "asian": "6.9"
      }
    },
    "wageData": {
      "2016": {
        "workers": "980",
        "median": "1,247",
        "maleWorkers": "535",
        "maleMedian": "1,356",
        "femaleWorkers": "445",
        "femaleMedian": "1,152"
      }
    }
  },
  "Preschool and kindergarten teachers": {
    "ageData": {
      "2016": {
        "total": "692",
        "16to19": "6",
        "20to24": "68",
        "25to34": "206",
        "35to44": "164",
        "45to54": "140",
        "55to64": "92",
        "65+": "17",
        "medianAge": "92"
      }
    },
    "title": "Preschool and kindergarten teachers",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "692",
        "women": "97.5",
        "white": "12.5",
        "black": "3.4",
        "asian": "13.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "530",
        "median": "626",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "518",
        "femaleMedian": "621"
      }
    }
  },
  "Elementary and middle school teachers": {
    "ageData": {
      "2016": {
        "total": "3,186",
        "16to19": "8",
        "20to24": "151",
        "25to34": "729",
        "35to44": "903",
        "45to54": "737",
        "55to64": "506",
        "65+": "152",
        "medianAge": "506"
      }
    },
    "title": "Elementary and middle school teachers",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "3,186",
        "women": "78.5",
        "white": "10.3",
        "black": "2.4",
        "asian": "9.8"
      }
    },
    "wageData": {
      "2016": {
        "workers": "2,836",
        "median": "1,009",
        "maleWorkers": "605",
        "maleMedian": "1,126",
        "femaleWorkers": "2,231",
        "femaleMedian": "981"
      }
    }
  },
  "Secondary school teachers": {
    "ageData": {
      "2016": {
        "total": "1,078",
        "16to19": "4",
        "20to24": "47",
        "25to34": "265",
        "35to44": "274",
        "45to54": "265",
        "55to64": "173",
        "65+": "49",
        "medianAge": "173"
      }
    },
    "title": "Secondary school teachers",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "1,078",
        "women": "60.5",
        "white": "8.0",
        "black": "2.0",
        "asian": "7.9"
      }
    },
    "wageData": {
      "2016": {
        "workers": "965",
        "median": "1,111",
        "maleWorkers": "403",
        "maleMedian": "1,146",
        "femaleWorkers": "562",
        "femaleMedian": "1,074"
      }
    }
  },
  "Special education teachers": {
    "ageData": {
      "2016": {
        "total": "353",
        "16to19": "1",
        "20to24": "18",
        "25to34": "83",
        "35to44": "95",
        "45to54": "80",
        "55to64": "62",
        "65+": "14",
        "medianAge": "62"
      }
    },
    "title": "Special education teachers",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "353",
        "women": "85.2",
        "white": "8.6",
        "black": "2.2",
        "asian": "6.5"
      }
    },
    "wageData": {
      "2016": {
        "workers": "313",
        "median": "1,021",
        "maleWorkers": "49",
        "maleMedian": "-",
        "femaleWorkers": "264",
        "femaleMedian": "1,018"
      }
    }
  },
  "Other teachers and instructors": {
    "ageData": {
      "2016": {
        "total": "868",
        "16to19": "51",
        "20to24": "126",
        "25to34": "163",
        "35to44": "164",
        "45to54": "134",
        "55to64": "151",
        "65+": "78",
        "medianAge": "151"
      }
    },
    "title": "Other teachers and instructors",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "868",
        "women": "65.3",
        "white": "10.3",
        "black": "7.2",
        "asian": "10.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "370",
        "median": "938",
        "maleWorkers": "156",
        "maleMedian": "1,135",
        "femaleWorkers": "214",
        "femaleMedian": "786"
      }
    }
  },
  "Archivists, curators, and museum technicians": {
    "ageData": {
      "2016": {
        "total": "43",
        "16to19": "1",
        "20to24": "2",
        "25to34": "10",
        "35to44": "10",
        "45to54": "4",
        "55to64": "8",
        "65+": "7",
        "medianAge": "8"
      }
    },
    "title": "Archivists, curators, and museum technicians",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "43",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "36",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "25",
        "femaleMedian": "-"
      }
    }
  },
  "Librarians": {
    "ageData": {
      "2016": {
        "total": "190",
        "16to19": "1",
        "20to24": "6",
        "25to34": "35",
        "35to44": "29",
        "45to54": "46",
        "55to64": "46",
        "65+": "27",
        "medianAge": "46"
      }
    },
    "title": "Librarians",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "190",
        "women": "83.8",
        "white": "7.5",
        "black": "5.8",
        "asian": "7.1"
      }
    },
    "wageData": {
      "2016": {
        "workers": "140",
        "median": "987",
        "maleWorkers": "23",
        "maleMedian": "-",
        "femaleWorkers": "117",
        "femaleMedian": "980"
      }
    }
  },
  "Library technicians": {
    "ageData": {
      "2016": {
        "total": "37",
        "16to19": "2",
        "20to24": "6",
        "25to34": "6",
        "35to44": "8",
        "45to54": "5",
        "55to64": "6",
        "65+": "2",
        "medianAge": "6"
      }
    },
    "title": "Library technicians",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "37",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "10",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Teacher assistants": {
    "ageData": {
      "2016": {
        "total": "950",
        "16to19": "23",
        "20to24": "108",
        "25to34": "158",
        "35to44": "192",
        "45to54": "243",
        "55to64": "184",
        "65+": "42",
        "medianAge": "184"
      }
    },
    "title": "Teacher assistants",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "950",
        "women": "89.3",
        "white": "14.8",
        "black": "3.4",
        "asian": "17.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "588",
        "median": "523",
        "maleWorkers": "56",
        "maleMedian": "501",
        "femaleWorkers": "532",
        "femaleMedian": "525"
      }
    }
  },
  "Other education, training, and library workers": {
    "ageData": {
      "2016": {
        "total": "132",
        "16to19": "3",
        "20to24": "11",
        "25to34": "23",
        "35to44": "27",
        "45to54": "28",
        "55to64": "24",
        "65+": "16",
        "medianAge": "24"
      }
    },
    "title": "Other education, training, and library workers",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "132",
        "women": "71.4",
        "white": "12.2",
        "black": "6.3",
        "asian": "9.7"
      }
    },
    "wageData": {
      "2016": {
        "workers": "91",
        "median": "1,107",
        "maleWorkers": "24",
        "maleMedian": "-",
        "femaleWorkers": "67",
        "femaleMedian": "1,068"
      }
    }
  },
  "Arts, design, entertainment, sports, and media occupations": {
    "ageData": {
      "2016": {
        "total": "3,097",
        "16to19": "88",
        "20to24": "285",
        "25to34": "801",
        "35to44": "655",
        "45to54": "578",
        "55to64": "458",
        "65+": "232",
        "medianAge": "458"
      }
    },
    "title": "Arts, design, entertainment, sports, and media occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "3,097",
        "women": "48.5",
        "white": "6.6",
        "black": "5.5",
        "asian": "10.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "1,671",
        "median": "1,040",
        "maleWorkers": "919",
        "maleMedian": "1,139",
        "femaleWorkers": "752",
        "femaleMedian": "951"
      }
    }
  },
  "Artists and related workers": {
    "ageData": {
      "2016": {
        "total": "232",
        "16to19": "3",
        "20to24": "9",
        "25to34": "46",
        "35to44": "43",
        "45to54": "47",
        "55to64": "48",
        "65+": "36",
        "medianAge": "48"
      }
    },
    "title": "Artists and related workers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "232",
        "women": "56.6",
        "white": "3.6",
        "black": "3.6",
        "asian": "9.9"
      }
    },
    "wageData": {
      "2016": {
        "workers": "61",
        "median": "1,236",
        "maleWorkers": "38",
        "maleMedian": "-",
        "femaleWorkers": "23",
        "femaleMedian": "-"
      }
    }
  },
  "Designers": {
    "ageData": {
      "2016": {
        "total": "878",
        "16to19": "5",
        "20to24": "80",
        "25to34": "250",
        "35to44": "179",
        "45to54": "188",
        "55to64": "121",
        "65+": "55",
        "medianAge": "121"
      }
    },
    "title": "Designers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "878",
        "women": "54.8",
        "white": "5.1",
        "black": "8.4",
        "asian": "10.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "586",
        "median": "1,086",
        "maleWorkers": "290",
        "maleMedian": "1,267",
        "femaleWorkers": "297",
        "femaleMedian": "922"
      }
    }
  },
  "Actors": {
    "ageData": {
      "2016": {
        "total": "47",
        "16to19": "1",
        "20to24": "4",
        "25to34": "17",
        "35to44": "8",
        "45to54": "7",
        "55to64": "3",
        "65+": "7",
        "medianAge": "3"
      }
    },
    "title": "Actors",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "47",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Producers and directors": {
    "ageData": {
      "2016": {
        "total": "173",
        "16to19": "1",
        "20to24": "8",
        "25to34": "56",
        "35to44": "44",
        "45to54": "30",
        "55to64": "26",
        "65+": "7",
        "medianAge": "26"
      }
    },
    "title": "Producers and directors",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "173",
        "women": "38.5",
        "white": "3.6",
        "black": "4.6",
        "asian": "6.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "128",
        "median": "1,163",
        "maleWorkers": "78",
        "maleMedian": "1,228",
        "femaleWorkers": "50",
        "femaleMedian": "1,030"
      }
    }
  },
  "Athletes, coaches, umpires, and related workers": {
    "ageData": {
      "2016": {
        "total": "312",
        "16to19": "51",
        "20to24": "58",
        "25to34": "68",
        "35to44": "43",
        "45to54": "40",
        "55to64": "34",
        "65+": "18",
        "medianAge": "34"
      }
    },
    "title": "Athletes, coaches, umpires, and related workers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "312",
        "women": "34.2",
        "white": "8.4",
        "black": "2.2",
        "asian": "11.7"
      }
    },
    "wageData": {
      "2016": {
        "workers": "141",
        "median": "826",
        "maleWorkers": "106",
        "maleMedian": "862",
        "femaleWorkers": "35",
        "femaleMedian": "-"
      }
    }
  },
  "Dancers and choreographers": {
    "ageData": {
      "2016": {
        "total": "17",
        "16to19": "0",
        "20to24": "7",
        "25to34": "5",
        "35to44": "4",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Dancers and choreographers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "17",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Musicians, singers, and related workers": {
    "ageData": {
      "2016": {
        "total": "192",
        "16to19": "3",
        "20to24": "13",
        "25to34": "51",
        "35to44": "44",
        "45to54": "19",
        "55to64": "36",
        "65+": "24",
        "medianAge": "36"
      }
    },
    "title": "Musicians, singers, and related workers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "192",
        "women": "40.4",
        "white": "14.6",
        "black": "3.7",
        "asian": "9.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "37",
        "median": "-",
        "maleWorkers": "24",
        "maleMedian": "-",
        "femaleWorkers": "13",
        "femaleMedian": "-"
      }
    }
  },
  "Entertainers and performers, sports and related workers, all other": {
    "ageData": {
      "2016": {
        "total": "49",
        "16to19": "3",
        "20to24": "8",
        "25to34": "10",
        "35to44": "10",
        "45to54": "9",
        "55to64": "7",
        "65+": "1",
        "medianAge": "7"
      }
    },
    "title": "Entertainers and performers, sports and related workers, all other",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "49",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Announcers": {
    "ageData": {
      "2016": {
        "total": "59",
        "16to19": "2",
        "20to24": "5",
        "25to34": "15",
        "35to44": "14",
        "45to54": "11",
        "55to64": "8",
        "65+": "5",
        "medianAge": "8"
      }
    },
    "title": "Announcers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "59",
        "women": "20.9",
        "white": "16.6",
        "black": "4.0",
        "asian": "20.7"
      }
    },
    "wageData": {
      "2016": {
        "workers": "32",
        "median": "-",
        "maleWorkers": "24",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "News analysts, reporters and correspondents": {
    "ageData": {
      "2016": {
        "total": "73",
        "16to19": "0",
        "20to24": "8",
        "25to34": "20",
        "35to44": "11",
        "45to54": "18",
        "55to64": "12",
        "65+": "3",
        "medianAge": "12"
      }
    },
    "title": "News analysts, reporters and correspondents",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "73",
        "women": "48.8",
        "white": "3.6",
        "black": "9.6",
        "asian": "10.5"
      }
    },
    "wageData": {
      "2016": {
        "workers": "58",
        "median": "1,166",
        "maleWorkers": "30",
        "maleMedian": "-",
        "femaleWorkers": "27",
        "femaleMedian": "-"
      }
    }
  },
  "Public relations specialists": {
    "ageData": {
      "2016": {
        "total": "121",
        "16to19": "1",
        "20to24": "13",
        "25to34": "32",
        "35to44": "34",
        "45to54": "21",
        "55to64": "17",
        "65+": "2",
        "medianAge": "17"
      }
    },
    "title": "Public relations specialists",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "121",
        "women": "58.9",
        "white": "8.2",
        "black": "1.7",
        "asian": "7.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "107",
        "median": "1,228",
        "maleWorkers": "40",
        "maleMedian": "-",
        "femaleWorkers": "67",
        "femaleMedian": "1,134"
      }
    }
  },
  "Editors": {
    "ageData": {
      "2016": {
        "total": "167",
        "16to19": "1",
        "20to24": "15",
        "25to34": "42",
        "35to44": "35",
        "45to54": "38",
        "55to64": "28",
        "65+": "7",
        "medianAge": "28"
      }
    },
    "title": "Editors",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "167",
        "women": "52.4",
        "white": "1.9",
        "black": "6.6",
        "asian": "6.0"
      }
    },
    "wageData": {
      "2016": {
        "workers": "120",
        "median": "1,053",
        "maleWorkers": "64",
        "maleMedian": "1,112",
        "femaleWorkers": "56",
        "femaleMedian": "1,035"
      }
    }
  },
  "Technical writers": {
    "ageData": {
      "2016": {
        "total": "61",
        "16to19": "0",
        "20to24": "2",
        "25to34": "11",
        "35to44": "12",
        "45to54": "13",
        "55to64": "16",
        "65+": "7",
        "medianAge": "16"
      }
    },
    "title": "Technical writers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "61",
        "women": "55.7",
        "white": "7.8",
        "black": "4.1",
        "asian": "7.8"
      }
    },
    "wageData": {
      "2016": {
        "workers": "56",
        "median": "1,253",
        "maleWorkers": "29",
        "maleMedian": "-",
        "femaleWorkers": "27",
        "femaleMedian": "-"
      }
    }
  },
  "Writers and authors": {
    "ageData": {
      "2016": {
        "total": "229",
        "16to19": "2",
        "20to24": "15",
        "25to34": "48",
        "35to44": "45",
        "45to54": "58",
        "55to64": "29",
        "65+": "32",
        "medianAge": "29"
      }
    },
    "title": "Writers and authors",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "229",
        "women": "62.8",
        "white": "3.4",
        "black": "2.5",
        "asian": "5.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "99",
        "median": "1,070",
        "maleWorkers": "39",
        "maleMedian": "-",
        "femaleWorkers": "60",
        "femaleMedian": "983"
      }
    }
  },
  "Miscellaneous media and communication workers": {
    "ageData": {
      "2016": {
        "total": "116",
        "16to19": "1",
        "20to24": "7",
        "25to34": "31",
        "35to44": "32",
        "45to54": "19",
        "55to64": "17",
        "65+": "9",
        "medianAge": "17"
      }
    },
    "title": "Miscellaneous media and communication workers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "116",
        "women": "68.7",
        "white": "11.3",
        "black": "13.0",
        "asian": "37.8"
      }
    },
    "wageData": {
      "2016": {
        "workers": "59",
        "median": "745",
        "maleWorkers": "24",
        "maleMedian": "-",
        "femaleWorkers": "36",
        "femaleMedian": "-"
      }
    }
  },
  "Broadcast and sound engineering technicians and radio operators": {
    "ageData": {
      "2016": {
        "total": "111",
        "16to19": "3",
        "20to24": "9",
        "25to34": "34",
        "35to44": "30",
        "45to54": "18",
        "55to64": "15",
        "65+": "2",
        "medianAge": "15"
      }
    },
    "title": "Broadcast and sound engineering technicians and radio operators",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "111",
        "women": "15.2",
        "white": "13.1",
        "black": "2.4",
        "asian": "12.1"
      }
    },
    "wageData": {
      "2016": {
        "workers": "80",
        "median": "1,020",
        "maleWorkers": "67",
        "maleMedian": "1,099",
        "femaleWorkers": "13",
        "femaleMedian": "-"
      }
    }
  },
  "Photographers": {
    "ageData": {
      "2016": {
        "total": "205",
        "16to19": "8",
        "20to24": "17",
        "25to34": "44",
        "35to44": "51",
        "45to54": "36",
        "55to64": "35",
        "65+": "13",
        "medianAge": "35"
      }
    },
    "title": "Photographers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "205",
        "women": "47.7",
        "white": "5.7",
        "black": "5.7",
        "asian": "8.4"
      }
    },
    "wageData": {
      "2016": {
        "workers": "43",
        "median": "-",
        "maleWorkers": "24",
        "maleMedian": "-",
        "femaleWorkers": "19",
        "femaleMedian": "-"
      }
    }
  },
  "Television, video, and motion picture camera operators and editors": {
    "ageData": {
      "2016": {
        "total": "54",
        "16to19": "2",
        "20to24": "6",
        "25to34": "20",
        "35to44": "15",
        "45to54": "4",
        "55to64": "4",
        "65+": "3",
        "medianAge": "4"
      }
    },
    "title": "Television, video, and motion picture camera operators and editors",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "54",
        "women": "21.4",
        "white": "3.1",
        "black": "3.8",
        "asian": "11.4"
      }
    },
    "wageData": {
      "2016": {
        "workers": "31",
        "median": "-",
        "maleWorkers": "24",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Media and communication equipment workers, all other": {
    "ageData": {
      "2016": {
        "total": "1",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "1",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Media and communication equipment workers, all other",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Healthcare practitioners and technical occupations": {
    "ageData": {
      "2016": {
        "total": "8,960",
        "16to19": "36",
        "20to24": "487",
        "25to34": "2,317",
        "35to44": "2,097",
        "45to54": "1,967",
        "55to64": "1,578",
        "65+": "475",
        "medianAge": "1,578"
      }
    },
    "title": "Healthcare practitioners and technical occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "8,960",
        "women": "75.6",
        "white": "11.8",
        "black": "9.6",
        "asian": "7.8"
      }
    },
    "wageData": {
      "2016": {
        "workers": "6,857",
        "median": "1,104",
        "maleWorkers": "1,706",
        "maleMedian": "1,332",
        "femaleWorkers": "5,150",
        "femaleMedian": "1,036"
      }
    }
  },
  "Chiropractors": {
    "ageData": {
      "2016": {
        "total": "51",
        "16to19": "0",
        "20to24": "1",
        "25to34": "9",
        "35to44": "11",
        "45to54": "15",
        "55to64": "11",
        "65+": "5",
        "medianAge": "11"
      }
    },
    "title": "Chiropractors",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "51",
        "women": "27.4",
        "white": "1.7",
        "black": "4.6",
        "asian": "9.8"
      }
    },
    "wageData": {
      "2016": {
        "workers": "18",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Dentists": {
    "ageData": {
      "2016": {
        "total": "163",
        "16to19": "0",
        "20to24": "1",
        "25to34": "41",
        "35to44": "31",
        "45to54": "33",
        "55to64": "42",
        "65+": "15",
        "medianAge": "42"
      }
    },
    "title": "Dentists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "163",
        "women": "34.4",
        "white": "3.2",
        "black": "20.2",
        "asian": "9.5"
      }
    },
    "wageData": {
      "2016": {
        "workers": "61",
        "median": "1,850",
        "maleWorkers": "34",
        "maleMedian": "-",
        "femaleWorkers": "27",
        "femaleMedian": "-"
      }
    }
  },
  "Dietitians and nutritionists": {
    "ageData": {
      "2016": {
        "total": "120",
        "16to19": "0",
        "20to24": "8",
        "25to34": "34",
        "35to44": "29",
        "45to54": "22",
        "55to64": "20",
        "65+": "7",
        "medianAge": "20"
      }
    },
    "title": "Dietitians and nutritionists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "120",
        "women": "89.4",
        "white": "15.9",
        "black": "6.1",
        "asian": "4.9"
      }
    },
    "wageData": {
      "2016": {
        "workers": "82",
        "median": "986",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "71",
        "femaleMedian": "997"
      }
    }
  },
  "Optometrists": {
    "ageData": {
      "2016": {
        "total": "36",
        "16to19": "0",
        "20to24": "1",
        "25to34": "12",
        "35to44": "6",
        "45to54": "11",
        "55to64": "4",
        "65+": "3",
        "medianAge": "4"
      }
    },
    "title": "Optometrists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "36",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "18",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "15",
        "femaleMedian": "-"
      }
    }
  },
  "Pharmacists": {
    "ageData": {
      "2016": {
        "total": "306",
        "16to19": "0",
        "20to24": "14",
        "25to34": "101",
        "35to44": "71",
        "45to54": "49",
        "55to64": "50",
        "65+": "19",
        "medianAge": "50"
      }
    },
    "title": "Pharmacists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "306",
        "women": "59.9",
        "white": "10.1",
        "black": "18.1",
        "asian": "4.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "222",
        "median": "1,924",
        "maleWorkers": "89",
        "maleMedian": "2,096",
        "femaleWorkers": "133",
        "femaleMedian": "1,839"
      }
    }
  },
  "Physicians and surgeons": {
    "ageData": {
      "2016": {
        "total": "1,061",
        "16to19": "1",
        "20to24": "2",
        "25to34": "240",
        "35to44": "263",
        "45to54": "247",
        "55to64": "205",
        "65+": "103",
        "medianAge": "205"
      }
    },
    "title": "Physicians and surgeons",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "1,061",
        "women": "38.2",
        "white": "7.5",
        "black": "19.3",
        "asian": "5.9"
      }
    },
    "wageData": {
      "2016": {
        "workers": "806",
        "median": "1,916",
        "maleWorkers": "497",
        "maleMedian": "2,343",
        "femaleWorkers": "308",
        "femaleMedian": "1,476"
      }
    }
  },
  "Physician assistants": {
    "ageData": {
      "2016": {
        "total": "92",
        "16to19": "0",
        "20to24": "2",
        "25to34": "35",
        "35to44": "28",
        "45to54": "15",
        "55to64": "9",
        "65+": "3",
        "medianAge": "9"
      }
    },
    "title": "Physician assistants",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "92",
        "women": "70.0",
        "white": "3.2",
        "black": "11.5",
        "asian": "6.0"
      }
    },
    "wageData": {
      "2016": {
        "workers": "75",
        "median": "1,544",
        "maleWorkers": "25",
        "maleMedian": "-",
        "femaleWorkers": "50",
        "femaleMedian": "1,547"
      }
    }
  },
  "Podiatrists": {
    "ageData": {
      "2016": {
        "total": "12",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "3",
        "45to54": "4",
        "55to64": "4",
        "65+": "1",
        "medianAge": "4"
      }
    },
    "title": "Podiatrists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "12",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Audiologists": {
    "ageData": {
      "2016": {
        "total": "15",
        "16to19": "0",
        "20to24": "0",
        "25to34": "4",
        "35to44": "3",
        "45to54": "5",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Audiologists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "15",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "11",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Occupational therapists": {
    "ageData": {
      "2016": {
        "total": "118",
        "16to19": "0",
        "20to24": "5",
        "25to34": "37",
        "35to44": "31",
        "45to54": "29",
        "55to64": "12",
        "65+": "4",
        "medianAge": "12"
      }
    },
    "title": "Occupational therapists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "118",
        "women": "89.2",
        "white": "7.5",
        "black": "8.2",
        "asian": "6.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "71",
        "median": "1,365",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "61",
        "femaleMedian": "1,315"
      }
    }
  },
  "Physical therapists": {
    "ageData": {
      "2016": {
        "total": "290",
        "16to19": "0",
        "20to24": "5",
        "25to34": "90",
        "35to44": "75",
        "45to54": "70",
        "55to64": "38",
        "65+": "11",
        "medianAge": "38"
      }
    },
    "title": "Physical therapists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "290",
        "women": "69.3",
        "white": "5.2",
        "black": "9.5",
        "asian": "5.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "197",
        "median": "1,325",
        "maleWorkers": "69",
        "maleMedian": "1,348",
        "femaleWorkers": "128",
        "femaleMedian": "1,306"
      }
    }
  },
  "Radiation therapists": {
    "ageData": {
      "2016": {
        "total": "13",
        "16to19": "0",
        "20to24": "0",
        "25to34": "7",
        "35to44": "2",
        "45to54": "2",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Radiation therapists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "13",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "11",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Recreational therapists": {
    "ageData": {
      "2016": {
        "total": "11",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "4",
        "45to54": "2",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Recreational therapists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "11",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "9",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Respiratory therapists": {
    "ageData": {
      "2016": {
        "total": "84",
        "16to19": "0",
        "20to24": "1",
        "25to34": "24",
        "35to44": "20",
        "45to54": "22",
        "55to64": "13",
        "65+": "3",
        "medianAge": "13"
      }
    },
    "title": "Respiratory therapists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "84",
        "women": "73.5",
        "white": "8.5",
        "black": "5.2",
        "asian": "8.7"
      }
    },
    "wageData": {
      "2016": {
        "workers": "76",
        "median": "998",
        "maleWorkers": "22",
        "maleMedian": "-",
        "femaleWorkers": "54",
        "femaleMedian": "993"
      }
    }
  },
  "Speech-language pathologists": {
    "ageData": {
      "2016": {
        "total": "162",
        "16to19": "0",
        "20to24": "7",
        "25to34": "45",
        "35to44": "50",
        "45to54": "27",
        "55to64": "27",
        "65+": "5",
        "medianAge": "27"
      }
    },
    "title": "Speech-language pathologists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "162",
        "women": "97.5",
        "white": "2.9",
        "black": "3.2",
        "asian": "9.0"
      }
    },
    "wageData": {
      "2016": {
        "workers": "112",
        "median": "1,200",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "109",
        "femaleMedian": "1,172"
      }
    }
  },
  "Exercise physiologists": {
    "ageData": {
      "2016": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "1",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Exercise physiologists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Therapists, all other": {
    "ageData": {
      "2016": {
        "total": "205",
        "16to19": "2",
        "20to24": "20",
        "25to34": "62",
        "35to44": "48",
        "45to54": "36",
        "55to64": "23",
        "65+": "15",
        "medianAge": "23"
      }
    },
    "title": "Therapists, all other",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "205",
        "women": "82.6",
        "white": "9.2",
        "black": "6.1",
        "asian": "15.7"
      }
    },
    "wageData": {
      "2016": {
        "workers": "137",
        "median": "920",
        "maleWorkers": "32",
        "maleMedian": "-",
        "femaleWorkers": "106",
        "femaleMedian": "913"
      }
    }
  },
  "Veterinarians": {
    "ageData": {
      "2016": {
        "total": "101",
        "16to19": "0",
        "20to24": "1",
        "25to34": "26",
        "35to44": "26",
        "45to54": "22",
        "55to64": "19",
        "65+": "7",
        "medianAge": "19"
      }
    },
    "title": "Veterinarians",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "101",
        "women": "63.2",
        "white": "2.1",
        "black": "3.7",
        "asian": "0.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "65",
        "median": "1,338",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "46",
        "femaleMedian": "-"
      }
    }
  },
  "Registered nurses": {
    "ageData": {
      "2016": {
        "total": "3,102",
        "16to19": "4",
        "20to24": "140",
        "25to34": "766",
        "35to44": "711",
        "45to54": "709",
        "55to64": "621",
        "65+": "150",
        "medianAge": "621"
      }
    },
    "title": "Registered nurses",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "3,102",
        "women": "90.0",
        "white": "11.9",
        "black": "9.0",
        "asian": "6.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "2,498",
        "median": "1,154",
        "maleWorkers": "285",
        "maleMedian": "1,261",
        "femaleWorkers": "2,213",
        "femaleMedian": "1,143"
      }
    }
  },
  "Nurse anesthetists": {
    "ageData": {
      "2016": {
        "total": "23",
        "16to19": "0",
        "20to24": "0",
        "25to34": "8",
        "35to44": "8",
        "45to54": "4",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Nurse anesthetists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "23",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "14",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Nurse midwives": {
    "ageData": {
      "2016": {
        "total": "6",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "0",
        "45to54": "3",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Nurse midwives",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "6",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Nurse practitioners": {
    "ageData": {
      "2016": {
        "total": "176",
        "16to19": "0",
        "20to24": "1",
        "25to34": "35",
        "35to44": "51",
        "45to54": "39",
        "55to64": "38",
        "65+": "12",
        "medianAge": "38"
      }
    },
    "title": "Nurse practitioners",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "176",
        "women": "94.0",
        "white": "8.5",
        "black": "6.2",
        "asian": "3.5"
      }
    },
    "wageData": {
      "2016": {
        "workers": "144",
        "median": "1,750",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "133",
        "femaleMedian": "1,753"
      }
    }
  },
  "Health diagnosing and treating practitioners, all other": {
    "ageData": {
      "2016": {
        "total": "32",
        "16to19": "0",
        "20to24": "0",
        "25to34": "5",
        "35to44": "7",
        "45to54": "10",
        "55to64": "5",
        "65+": "5",
        "medianAge": "5"
      }
    },
    "title": "Health diagnosing and treating practitioners, all other",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "32",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Clinical laboratory technologists and technicians": {
    "ageData": {
      "2016": {
        "total": "361",
        "16to19": "3",
        "20to24": "37",
        "25to34": "87",
        "35to44": "81",
        "45to54": "73",
        "55to64": "59",
        "65+": "22",
        "medianAge": "59"
      }
    },
    "title": "Clinical laboratory technologists and technicians",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "361",
        "women": "68.5",
        "white": "16.5",
        "black": "10.6",
        "asian": "13.0"
      }
    },
    "wageData": {
      "2016": {
        "workers": "295",
        "median": "887",
        "maleWorkers": "97",
        "maleMedian": "1,071",
        "femaleWorkers": "198",
        "femaleMedian": "834"
      }
    }
  },
  "Dental hygienists": {
    "ageData": {
      "2016": {
        "total": "169",
        "16to19": "1",
        "20to24": "9",
        "25to34": "44",
        "35to44": "40",
        "45to54": "42",
        "55to64": "28",
        "65+": "5",
        "medianAge": "28"
      }
    },
    "title": "Dental hygienists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "169",
        "women": "97.1",
        "white": "5.2",
        "black": "2.7",
        "asian": "9.2"
      }
    },
    "wageData": {
      "2016": {
        "workers": "75",
        "median": "1,034",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "73",
        "femaleMedian": "1,045"
      }
    }
  },
  "Diagnostic related technologists and technicians": {
    "ageData": {
      "2016": {
        "total": "311",
        "16to19": "1",
        "20to24": "16",
        "25to34": "73",
        "35to44": "69",
        "45to54": "87",
        "55to64": "54",
        "65+": "11",
        "medianAge": "54"
      }
    },
    "title": "Diagnostic related technologists and technicians",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "311",
        "women": "73.0",
        "white": "9.8",
        "black": "5.4",
        "asian": "8.8"
      }
    },
    "wageData": {
      "2016": {
        "workers": "254",
        "median": "1,041",
        "maleWorkers": "79",
        "maleMedian": "1,156",
        "femaleWorkers": "175",
        "femaleMedian": "936"
      }
    }
  },
  "Emergency medical technicians and paramedics": {
    "ageData": {
      "2016": {
        "total": "194",
        "16to19": "1",
        "20to24": "32",
        "25to34": "75",
        "35to44": "40",
        "45to54": "34",
        "55to64": "10",
        "65+": "2",
        "medianAge": "10"
      }
    },
    "title": "Emergency medical technicians and paramedics",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "194",
        "women": "35.7",
        "white": "12.4",
        "black": "2.3",
        "asian": "12.1"
      }
    },
    "wageData": {
      "2016": {
        "workers": "174",
        "median": "779",
        "maleWorkers": "114",
        "maleMedian": "821",
        "femaleWorkers": "60",
        "femaleMedian": "650"
      }
    }
  },
  "Health practitioner support technologists and technicians": {
    "ageData": {
      "2016": {
        "total": "610",
        "16to19": "12",
        "20to24": "94",
        "25to34": "201",
        "35to44": "130",
        "45to54": "95",
        "55to64": "64",
        "65+": "13",
        "medianAge": "64"
      }
    },
    "title": "Health practitioner support technologists and technicians",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "610",
        "women": "79.5",
        "white": "12.7",
        "black": "8.8",
        "asian": "12.2"
      }
    },
    "wageData": {
      "2016": {
        "workers": "474",
        "median": "662",
        "maleWorkers": "113",
        "maleMedian": "712",
        "femaleWorkers": "361",
        "femaleMedian": "643"
      }
    }
  },
  "Licensed practical and licensed vocational nurses": {
    "ageData": {
      "2016": {
        "total": "653",
        "16to19": "9",
        "20to24": "56",
        "25to34": "150",
        "35to44": "153",
        "45to54": "149",
        "55to64": "110",
        "65+": "25",
        "medianAge": "110"
      }
    },
    "title": "Licensed practical and licensed vocational nurses",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "653",
        "women": "90.0",
        "white": "30.3",
        "black": "4.2",
        "asian": "10.1"
      }
    },
    "wageData": {
      "2016": {
        "workers": "525",
        "median": "749",
        "maleWorkers": "44",
        "maleMedian": "-",
        "femaleWorkers": "481",
        "femaleMedian": "743"
      }
    }
  },
  "Medical records and health information technicians": {
    "ageData": {
      "2016": {
        "total": "184",
        "16to19": "1",
        "20to24": "6",
        "25to34": "31",
        "35to44": "38",
        "45to54": "53",
        "55to64": "49",
        "65+": "7",
        "medianAge": "49"
      }
    },
    "title": "Medical records and health information technicians",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "184",
        "women": "92.1",
        "white": "10.7",
        "black": "5.3",
        "asian": "6.4"
      }
    },
    "wageData": {
      "2016": {
        "workers": "170",
        "median": "798",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "159",
        "femaleMedian": "793"
      }
    }
  },
  "Opticians, dispensing": {
    "ageData": {
      "2016": {
        "total": "55",
        "16to19": "0",
        "20to24": "3",
        "25to34": "13",
        "35to44": "7",
        "45to54": "14",
        "55to64": "12",
        "65+": "6",
        "medianAge": "12"
      }
    },
    "title": "Opticians, dispensing",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "55",
        "women": "69.7",
        "white": "7.3",
        "black": "2.2",
        "asian": "11.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "42",
        "median": "-",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "28",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous health technologists and technicians": {
    "ageData": {
      "2016": {
        "total": "130",
        "16to19": "0",
        "20to24": "13",
        "25to34": "32",
        "35to44": "34",
        "45to54": "26",
        "55to64": "18",
        "65+": "6",
        "medianAge": "18"
      }
    },
    "title": "Miscellaneous health technologists and technicians",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "130",
        "women": "65.6",
        "white": "24.3",
        "black": "11.9",
        "asian": "9.7"
      }
    },
    "wageData": {
      "2016": {
        "workers": "107",
        "median": "745",
        "maleWorkers": "43",
        "maleMedian": "-",
        "femaleWorkers": "64",
        "femaleMedian": "714"
      }
    }
  },
  "Other healthcare practitioners and technical occupations": {
    "ageData": {
      "2016": {
        "total": "114",
        "16to19": "1",
        "20to24": "12",
        "25to34": "25",
        "35to44": "28",
        "45to54": "19",
        "55to64": "21",
        "65+": "7",
        "medianAge": "21"
      }
    },
    "title": "Other healthcare practitioners and technical occupations",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "114",
        "women": "51.7",
        "white": "13.5",
        "black": "3.4",
        "asian": "11.9"
      }
    },
    "wageData": {
      "2016": {
        "workers": "96",
        "median": "1,083",
        "maleWorkers": "49",
        "maleMedian": "-",
        "femaleWorkers": "47",
        "femaleMedian": "-"
      }
    }
  },
  "Service occupations": {
    "ageData": {
      "2016": {
        "total": "26,811",
        "16to19": "2,070",
        "20to24": "4,007",
        "25to34": "6,077",
        "35to44": "4,889",
        "45to54": "4,821",
        "55to64": "3,655",
        "65+": "1,292",
        "medianAge": "3,655"
      }
    },
    "title": "Service occupations",
    "depth": 1,
    "parent": "",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "26,811",
        "women": "56.6",
        "white": "16.8",
        "black": "5.5",
        "asian": "23.9"
      }
    },
    "wageData": {
      "2016": {
        "workers": "15,908",
        "median": "523",
        "maleWorkers": "7,994",
        "maleMedian": "595",
        "femaleWorkers": "7,914",
        "femaleMedian": "487"
      }
    }
  },
  "Healthcare support occupations": {
    "ageData": {
      "2016": {
        "total": "3,554",
        "16to19": "82",
        "20to24": "504",
        "25to34": "975",
        "35to44": "681",
        "45to54": "665",
        "55to64": "500",
        "65+": "146",
        "medianAge": "500"
      }
    },
    "title": "Healthcare support occupations",
    "depth": 2,
    "parent": "Service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "3,554",
        "women": "87.7",
        "white": "27.3",
        "black": "5.5",
        "asian": "16.9"
      }
    },
    "wageData": {
      "2016": {
        "workers": "2,423",
        "median": "525",
        "maleWorkers": "329",
        "maleMedian": "602",
        "femaleWorkers": "2,094",
        "femaleMedian": "519"
      }
    }
  },
  "Nursing, psychiatric, and home health aides": {
    "ageData": {
      "2016": {
        "total": "2,058",
        "16to19": "46",
        "20to24": "285",
        "25to34": "519",
        "35to44": "373",
        "45to54": "408",
        "55to64": "330",
        "65+": "97",
        "medianAge": "330"
      }
    },
    "title": "Nursing, psychiatric, and home health aides",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "2,058",
        "women": "88.1",
        "white": "37.5",
        "black": "5.8",
        "asian": "14.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "1,386",
        "median": "503",
        "maleWorkers": "194",
        "maleMedian": "534",
        "femaleWorkers": "1,192",
        "femaleMedian": "498"
      }
    }
  },
  "Occupational therapy assistants and aides": {
    "ageData": {
      "2016": {
        "total": "20",
        "16to19": "0",
        "20to24": "5",
        "25to34": "3",
        "35to44": "5",
        "45to54": "5",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Occupational therapy assistants and aides",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "20",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Physical therapist assistants and aides": {
    "ageData": {
      "2016": {
        "total": "70",
        "16to19": "1",
        "20to24": "14",
        "25to34": "22",
        "35to44": "14",
        "45to54": "12",
        "55to64": "6",
        "65+": "1",
        "medianAge": "6"
      }
    },
    "title": "Physical therapist assistants and aides",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "70",
        "women": "69.4",
        "white": "6.4",
        "black": "6.3",
        "asian": "10.4"
      }
    },
    "wageData": {
      "2016": {
        "workers": "48",
        "median": "-",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "32",
        "femaleMedian": "-"
      }
    }
  },
  "Massage therapists": {
    "ageData": {
      "2016": {
        "total": "170",
        "16to19": "0",
        "20to24": "9",
        "25to34": "41",
        "35to44": "37",
        "45to54": "38",
        "55to64": "36",
        "65+": "9",
        "medianAge": "36"
      }
    },
    "title": "Massage therapists",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "170",
        "women": "80.7",
        "white": "6.8",
        "black": "7.7",
        "asian": "9.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "45",
        "median": "-",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "30",
        "femaleMedian": "-"
      }
    }
  },
  "Dental assistants": {
    "ageData": {
      "2016": {
        "total": "294",
        "16to19": "9",
        "20to24": "44",
        "25to34": "90",
        "35to44": "69",
        "45to54": "45",
        "55to64": "30",
        "65+": "7",
        "medianAge": "30"
      }
    },
    "title": "Dental assistants",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "294",
        "women": "93.3",
        "white": "11.4",
        "black": "6.5",
        "asian": "24.1"
      }
    },
    "wageData": {
      "2016": {
        "workers": "199",
        "median": "592",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "180",
        "femaleMedian": "583"
      }
    }
  },
  "Medical assistants": {
    "ageData": {
      "2016": {
        "total": "570",
        "16to19": "3",
        "20to24": "86",
        "25to34": "203",
        "35to44": "124",
        "45to54": "91",
        "55to64": "50",
        "65+": "13",
        "medianAge": "50"
      }
    },
    "title": "Medical assistants",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "570",
        "women": "92.9",
        "white": "14.3",
        "black": "4.1",
        "asian": "28.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "488",
        "median": "572",
        "maleWorkers": "36",
        "maleMedian": "-",
        "femaleWorkers": "452",
        "femaleMedian": "562"
      }
    }
  },
  "Medical transcriptionists": {
    "ageData": {
      "2016": {
        "total": "39",
        "16to19": "0",
        "20to24": "5",
        "25to34": "5",
        "35to44": "4",
        "45to54": "9",
        "55to64": "10",
        "65+": "6",
        "medianAge": "10"
      }
    },
    "title": "Medical transcriptionists",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "39",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "23",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "23",
        "femaleMedian": "-"
      }
    }
  },
  "Pharmacy aides": {
    "ageData": {
      "2016": {
        "total": "36",
        "16to19": "1",
        "20to24": "10",
        "25to34": "8",
        "35to44": "2",
        "45to54": "3",
        "55to64": "7",
        "65+": "4",
        "medianAge": "7"
      }
    },
    "title": "Pharmacy aides",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "36",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "21",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "19",
        "femaleMedian": "-"
      }
    }
  },
  "Veterinary assistants and laboratory animal caretakers": {
    "ageData": {
      "2016": {
        "total": "39",
        "16to19": "4",
        "20to24": "10",
        "25to34": "14",
        "35to44": "4",
        "45to54": "4",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Veterinary assistants and laboratory animal caretakers",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "39",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "24",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "17",
        "femaleMedian": "-"
      }
    }
  },
  "Phlebotomists": {
    "ageData": {
      "2016": {
        "total": "101",
        "16to19": "2",
        "20to24": "13",
        "25to34": "31",
        "35to44": "20",
        "45to54": "24",
        "55to64": "10",
        "65+": "3",
        "medianAge": "10"
      }
    },
    "title": "Phlebotomists",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "101",
        "women": "86.5",
        "white": "22.0",
        "black": "3.6",
        "asian": "16.2"
      }
    },
    "wageData": {
      "2016": {
        "workers": "84",
        "median": "586",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "71",
        "femaleMedian": "570"
      }
    }
  },
  "Miscellaneous healthcare support occupations, including medical equipment preparers": {
    "ageData": {
      "2016": {
        "total": "156",
        "16to19": "16",
        "20to24": "22",
        "25to34": "38",
        "35to44": "29",
        "45to54": "27",
        "55to64": "18",
        "65+": "6",
        "medianAge": "18"
      }
    },
    "title": "Miscellaneous healthcare support occupations, including medical equipment preparers",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "156",
        "women": "73.9",
        "white": "26.0",
        "black": "4.0",
        "asian": "7.9"
      }
    },
    "wageData": {
      "2016": {
        "workers": "94",
        "median": "537",
        "maleWorkers": "29",
        "maleMedian": "-",
        "femaleWorkers": "65",
        "femaleMedian": "504"
      }
    }
  },
  "Protective service occupations": {
    "ageData": {
      "2016": {
        "total": "3,117",
        "16to19": "114",
        "20to24": "292",
        "25to34": "773",
        "35to44": "689",
        "45to54": "676",
        "55to64": "410",
        "65+": "163",
        "medianAge": "410"
      }
    },
    "title": "Protective service occupations",
    "depth": 2,
    "parent": "Service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "3,117",
        "women": "22.3",
        "white": "19.0",
        "black": "2.8",
        "asian": "14.1"
      }
    },
    "wageData": {
      "2016": {
        "workers": "2,697",
        "median": "809",
        "maleWorkers": "2,173",
        "maleMedian": "854",
        "femaleWorkers": "524",
        "femaleMedian": "688"
      }
    }
  },
  "First-line supervisors of correctional officers": {
    "ageData": {
      "2016": {
        "total": "61",
        "16to19": "0",
        "20to24": "2",
        "25to34": "8",
        "35to44": "17",
        "45to54": "24",
        "55to64": "9",
        "65+": "0",
        "medianAge": "9"
      }
    },
    "title": "First-line supervisors of correctional officers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "61",
        "women": "34.0",
        "white": "23.3",
        "black": "2.6",
        "asian": "17.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "55",
        "median": "873",
        "maleWorkers": "35",
        "maleMedian": "-",
        "femaleWorkers": "20",
        "femaleMedian": "-"
      }
    }
  },
  "First-line supervisors of police and detectives": {
    "ageData": {
      "2016": {
        "total": "114",
        "16to19": "0",
        "20to24": "0",
        "25to34": "11",
        "35to44": "34",
        "45to54": "46",
        "55to64": "21",
        "65+": "3",
        "medianAge": "21"
      }
    },
    "title": "First-line supervisors of police and detectives",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "114",
        "women": "18.3",
        "white": "12.3",
        "black": "0.2",
        "asian": "10.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "120",
        "median": "1,324",
        "maleWorkers": "97",
        "maleMedian": "1,361",
        "femaleWorkers": "23",
        "femaleMedian": "-"
      }
    }
  },
  "First-line supervisors of fire fighting and prevention workers": {
    "ageData": {
      "2016": {
        "total": "44",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "10",
        "45to54": "17",
        "55to64": "13",
        "65+": "1",
        "medianAge": "13"
      }
    },
    "title": "First-line supervisors of fire fighting and prevention workers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "44",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "45",
        "median": "-",
        "maleWorkers": "41",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "First-line supervisors of protective service workers, all other": {
    "ageData": {
      "2016": {
        "total": "88",
        "16to19": "0",
        "20to24": "5",
        "25to34": "17",
        "35to44": "13",
        "45to54": "24",
        "55to64": "22",
        "65+": "7",
        "medianAge": "22"
      }
    },
    "title": "First-line supervisors of protective service workers, all other",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "88",
        "women": "23.5",
        "white": "16.9",
        "black": "3.0",
        "asian": "11.7"
      }
    },
    "wageData": {
      "2016": {
        "workers": "89",
        "median": "880",
        "maleWorkers": "66",
        "maleMedian": "895",
        "femaleWorkers": "23",
        "femaleMedian": "-"
      }
    }
  },
  "Firefighters": {
    "ageData": {
      "2016": {
        "total": "252",
        "16to19": "0",
        "20to24": "12",
        "25to34": "90",
        "35to44": "74",
        "45to54": "48",
        "55to64": "24",
        "65+": "5",
        "medianAge": "24"
      }
    },
    "title": "Firefighters",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "252",
        "women": "3.5",
        "white": "6.8",
        "black": "1.4",
        "asian": "7.7"
      }
    },
    "wageData": {
      "2016": {
        "workers": "252",
        "median": "1,065",
        "maleWorkers": "244",
        "maleMedian": "1,056",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Fire inspectors": {
    "ageData": {
      "2016": {
        "total": "27",
        "16to19": "1",
        "20to24": "4",
        "25to34": "2",
        "35to44": "6",
        "45to54": "9",
        "55to64": "4",
        "65+": "1",
        "medianAge": "4"
      }
    },
    "title": "Fire inspectors",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "27",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "16",
        "median": "-",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Bailiffs, correctional officers, and jailers": {
    "ageData": {
      "2016": {
        "total": "383",
        "16to19": "3",
        "20to24": "43",
        "25to34": "114",
        "35to44": "81",
        "45to54": "93",
        "55to64": "37",
        "65+": "12",
        "medianAge": "37"
      }
    },
    "title": "Bailiffs, correctional officers, and jailers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "383",
        "women": "28.2",
        "white": "25.5",
        "black": "1.9",
        "asian": "12.9"
      }
    },
    "wageData": {
      "2016": {
        "workers": "365",
        "median": "749",
        "maleWorkers": "275",
        "maleMedian": "793",
        "femaleWorkers": "91",
        "femaleMedian": "672"
      }
    }
  },
  "Detectives and criminal investigators": {
    "ageData": {
      "2016": {
        "total": "130",
        "16to19": "0",
        "20to24": "4",
        "25to34": "25",
        "35to44": "40",
        "45to54": "40",
        "55to64": "20",
        "65+": "1",
        "medianAge": "20"
      }
    },
    "title": "Detectives and criminal investigators",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "130",
        "women": "23.6",
        "white": "6.9",
        "black": "4.0",
        "asian": "7.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "132",
        "median": "1,182",
        "maleWorkers": "104",
        "maleMedian": "1,251",
        "femaleWorkers": "28",
        "femaleMedian": "-"
      }
    }
  },
  "Fish and game wardens": {
    "ageData": {
      "2016": {
        "total": "9",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "4",
        "45to54": "2",
        "55to64": "1",
        "65+": "1",
        "medianAge": "1"
      }
    },
    "title": "Fish and game wardens",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "9",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Parking enforcement workers": {
    "ageData": {
      "2016": {
        "total": "8",
        "16to19": "1",
        "20to24": "0",
        "25to34": "3",
        "35to44": "1",
        "45to54": "2",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Parking enforcement workers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "8",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Police and sheriff's patrol officers": {
    "ageData": {
      "2016": {
        "total": "708",
        "16to19": "1",
        "20to24": "42",
        "25to34": "196",
        "35to44": "230",
        "45to54": "159",
        "55to64": "60",
        "65+": "19",
        "medianAge": "60"
      }
    },
    "title": "Police and sheriff's patrol officers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "708",
        "women": "14.1",
        "white": "12.0",
        "black": "2.7",
        "asian": "16.0"
      }
    },
    "wageData": {
      "2016": {
        "workers": "709",
        "median": "1,004",
        "maleWorkers": "609",
        "maleMedian": "1,008",
        "femaleWorkers": "100",
        "femaleMedian": "938"
      }
    }
  },
  "Transit and railroad police": {
    "ageData": {
      "2016": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Transit and railroad police",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Animal control workers": {
    "ageData": {
      "2016": {
        "total": "9",
        "16to19": "0",
        "20to24": "2",
        "25to34": "2",
        "35to44": "1",
        "45to54": "2",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Animal control workers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "9",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Private detectives and investigators": {
    "ageData": {
      "2016": {
        "total": "94",
        "16to19": "0",
        "20to24": "4",
        "25to34": "16",
        "35to44": "22",
        "45to54": "19",
        "55to64": "18",
        "65+": "13",
        "medianAge": "18"
      }
    },
    "title": "Private detectives and investigators",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "94",
        "women": "44.0",
        "white": "11.5",
        "black": "4.5",
        "asian": "6.7"
      }
    },
    "wageData": {
      "2016": {
        "workers": "60",
        "median": "904",
        "maleWorkers": "31",
        "maleMedian": "-",
        "femaleWorkers": "29",
        "femaleMedian": "-"
      }
    }
  },
  "Security guards and gaming surveillance officers": {
    "ageData": {
      "2016": {
        "total": "923",
        "16to19": "18",
        "20to24": "139",
        "25to34": "241",
        "35to44": "139",
        "45to54": "159",
        "55to64": "149",
        "65+": "78",
        "medianAge": "149"
      }
    },
    "title": "Security guards and gaming surveillance officers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "923",
        "women": "22.0",
        "white": "30.4",
        "black": "4.0",
        "asian": "17.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "727",
        "median": "585",
        "maleWorkers": "576",
        "maleMedian": "597",
        "femaleWorkers": "152",
        "femaleMedian": "516"
      }
    }
  },
  "Crossing guards": {
    "ageData": {
      "2016": {
        "total": "72",
        "16to19": "2",
        "20to24": "5",
        "25to34": "12",
        "35to44": "9",
        "45to54": "10",
        "55to64": "18",
        "65+": "16",
        "medianAge": "18"
      }
    },
    "title": "Crossing guards",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "72",
        "women": "60.4",
        "white": "26.4",
        "black": "1.5",
        "asian": "15.5"
      }
    },
    "wageData": {
      "2016": {
        "workers": "23",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Transportation security screeners": {
    "ageData": {
      "2016": {
        "total": "44",
        "16to19": "0",
        "20to24": "2",
        "25to34": "13",
        "35to44": "4",
        "45to54": "14",
        "55to64": "10",
        "65+": "2",
        "medianAge": "10"
      }
    },
    "title": "Transportation security screeners",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "44",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "40",
        "median": "-",
        "maleWorkers": "23",
        "maleMedian": "-",
        "femaleWorkers": "16",
        "femaleMedian": "-"
      }
    }
  },
  "Lifeguards and other recreational, and all other protective service workers": {
    "ageData": {
      "2016": {
        "total": "149",
        "16to19": "88",
        "20to24": "29",
        "25to34": "15",
        "35to44": "4",
        "45to54": "8",
        "55to64": "1",
        "65+": "4",
        "medianAge": "1"
      }
    },
    "title": "Lifeguards and other recreational, and all other protective service workers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "149",
        "women": "44.2",
        "white": "8.7",
        "black": "1.2",
        "asian": "11.4"
      }
    },
    "wageData": {
      "2016": {
        "workers": "42",
        "median": "-",
        "maleWorkers": "28",
        "maleMedian": "-",
        "femaleWorkers": "14",
        "femaleMedian": "-"
      }
    }
  },
  "Food preparation and serving related occupations": {
    "ageData": {
      "2016": {
        "total": "8,542",
        "16to19": "1,347",
        "20to24": "1,936",
        "25to34": "2,015",
        "35to44": "1,221",
        "45to54": "1,050",
        "55to64": "743",
        "65+": "229",
        "medianAge": "743"
      }
    },
    "title": "Food preparation and serving related occupations",
    "depth": 2,
    "parent": "Service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "8,542",
        "women": "53.5",
        "white": "13.8",
        "black": "5.8",
        "asian": "25.8"
      }
    },
    "wageData": {
      "2016": {
        "workers": "4,619",
        "median": "465",
        "maleWorkers": "2,417",
        "maleMedian": "491",
        "femaleWorkers": "2,202",
        "femaleMedian": "434"
      }
    }
  },
  "Chefs and head cooks": {
    "ageData": {
      "2016": {
        "total": "460",
        "16to19": "6",
        "20to24": "53",
        "25to34": "141",
        "35to44": "108",
        "45to54": "88",
        "55to64": "55",
        "65+": "10",
        "medianAge": "55"
      }
    },
    "title": "Chefs and head cooks",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "460",
        "women": "21.4",
        "white": "14.7",
        "black": "11.6",
        "asian": "23.0"
      }
    },
    "wageData": {
      "2016": {
        "workers": "356",
        "median": "614",
        "maleWorkers": "288",
        "maleMedian": "632",
        "femaleWorkers": "68",
        "femaleMedian": "519"
      }
    }
  },
  "First-line supervisors of food preparation and serving workers": {
    "ageData": {
      "2016": {
        "total": "543",
        "16to19": "25",
        "20to24": "89",
        "25to34": "147",
        "35to44": "97",
        "45to54": "98",
        "55to64": "72",
        "65+": "14",
        "medianAge": "72"
      }
    },
    "title": "First-line supervisors of food preparation and serving workers",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "543",
        "women": "57.7",
        "white": "15.5",
        "black": "5.3",
        "asian": "17.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "425",
        "median": "528",
        "maleWorkers": "174",
        "maleMedian": "666",
        "femaleWorkers": "251",
        "femaleMedian": "485"
      }
    }
  },
  "Cooks": {
    "ageData": {
      "2016": {
        "total": "2,179",
        "16to19": "254",
        "20to24": "359",
        "25to34": "527",
        "35to44": "409",
        "45to54": "331",
        "55to64": "231",
        "65+": "68",
        "medianAge": "231"
      }
    },
    "title": "Cooks",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "2,179",
        "women": "38.7",
        "white": "17.3",
        "black": "4.8",
        "asian": "35.0"
      }
    },
    "wageData": {
      "2016": {
        "workers": "1,408",
        "median": "444",
        "maleWorkers": "892",
        "maleMedian": "459",
        "femaleWorkers": "515",
        "femaleMedian": "421"
      }
    }
  },
  "Food preparation workers": {
    "ageData": {
      "2016": {
        "total": "1,000",
        "16to19": "187",
        "20to24": "252",
        "25to34": "183",
        "35to44": "123",
        "45to54": "127",
        "55to64": "89",
        "65+": "39",
        "medianAge": "89"
      }
    },
    "title": "Food preparation workers",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "1,000",
        "women": "58.2",
        "white": "14.9",
        "black": "6.7",
        "asian": "29.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "477",
        "median": "419",
        "maleWorkers": "215",
        "maleMedian": "432",
        "femaleWorkers": "262",
        "femaleMedian": "413"
      }
    }
  },
  "Bartenders": {
    "ageData": {
      "2016": {
        "total": "454",
        "16to19": "4",
        "20to24": "85",
        "25to34": "190",
        "35to44": "80",
        "45to54": "57",
        "55to64": "31",
        "65+": "9",
        "medianAge": "31"
      }
    },
    "title": "Bartenders",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "454",
        "women": "56.1",
        "white": "7.4",
        "black": "2.5",
        "asian": "17.0"
      }
    },
    "wageData": {
      "2016": {
        "workers": "253",
        "median": "602",
        "maleWorkers": "131",
        "maleMedian": "701",
        "femaleWorkers": "122",
        "femaleMedian": "498"
      }
    }
  },
  "Combined food preparation and serving workers, including fast food": {
    "ageData": {
      "2016": {
        "total": "420",
        "16to19": "92",
        "20to24": "116",
        "25to34": "82",
        "35to44": "38",
        "45to54": "42",
        "55to64": "38",
        "65+": "11",
        "medianAge": "38"
      }
    },
    "title": "Combined food preparation and serving workers, including fast food",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "420",
        "women": "63.5",
        "white": "18.0",
        "black": "3.3",
        "asian": "20.2"
      }
    },
    "wageData": {
      "2016": {
        "workers": "196",
        "median": "396",
        "maleWorkers": "72",
        "maleMedian": "381",
        "femaleWorkers": "125",
        "femaleMedian": "402"
      }
    }
  },
  "Counter attendants, cafeteria, food concession, and coffee shop": {
    "ageData": {
      "2016": {
        "total": "214",
        "16to19": "108",
        "20to24": "47",
        "25to34": "20",
        "35to44": "9",
        "45to54": "16",
        "55to64": "8",
        "65+": "5",
        "medianAge": "8"
      }
    },
    "title": "Counter attendants, cafeteria, food concession, and coffee shop",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "214",
        "women": "67.2",
        "white": "11.4",
        "black": "5.7",
        "asian": "18.0"
      }
    },
    "wageData": {
      "2016": {
        "workers": "68",
        "median": "374",
        "maleWorkers": "20",
        "maleMedian": "-",
        "femaleWorkers": "49",
        "femaleMedian": "-"
      }
    }
  },
  "Waiters and waitresses": {
    "ageData": {
      "2016": {
        "total": "2,085",
        "16to19": "326",
        "20to24": "672",
        "25to34": "538",
        "35to44": "242",
        "45to54": "174",
        "55to64": "104",
        "65+": "30",
        "medianAge": "104"
      }
    },
    "title": "Waiters and waitresses",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "2,085",
        "women": "70.0",
        "white": "9.4",
        "black": "6.6",
        "asian": "20.9"
      }
    },
    "wageData": {
      "2016": {
        "workers": "949",
        "median": "470",
        "maleWorkers": "342",
        "maleMedian": "504",
        "femaleWorkers": "607",
        "femaleMedian": "441"
      }
    }
  },
  "Food servers, nonrestaurant": {
    "ageData": {
      "2016": {
        "total": "213",
        "16to19": "43",
        "20to24": "37",
        "25to34": "44",
        "35to44": "15",
        "45to54": "28",
        "55to64": "35",
        "65+": "11",
        "medianAge": "35"
      }
    },
    "title": "Food servers, nonrestaurant",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "213",
        "women": "71.3",
        "white": "16.3",
        "black": "5.7",
        "asian": "22.5"
      }
    },
    "wageData": {
      "2016": {
        "workers": "104",
        "median": "479",
        "maleWorkers": "33",
        "maleMedian": "-",
        "femaleWorkers": "71",
        "femaleMedian": "445"
      }
    }
  },
  "Dining room and cafeteria attendants and bartender helpers": {
    "ageData": {
      "2016": {
        "total": "331",
        "16to19": "87",
        "20to24": "67",
        "25to34": "51",
        "35to44": "37",
        "45to54": "37",
        "55to64": "37",
        "65+": "15",
        "medianAge": "37"
      }
    },
    "title": "Dining room and cafeteria attendants and bartender helpers",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "331",
        "women": "42.9",
        "white": "15.3",
        "black": "6.4",
        "asian": "27.8"
      }
    },
    "wageData": {
      "2016": {
        "workers": "133",
        "median": "420",
        "maleWorkers": "82",
        "maleMedian": "465",
        "femaleWorkers": "51",
        "femaleMedian": "407"
      }
    }
  },
  "Dishwashers": {
    "ageData": {
      "2016": {
        "total": "319",
        "16to19": "58",
        "20to24": "72",
        "25to34": "58",
        "35to44": "46",
        "45to54": "42",
        "55to64": "32",
        "65+": "11",
        "medianAge": "32"
      }
    },
    "title": "Dishwashers",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "319",
        "women": "18.0",
        "white": "16.3",
        "black": "5.9",
        "asian": "35.4"
      }
    },
    "wageData": {
      "2016": {
        "workers": "172",
        "median": "389",
        "maleWorkers": "142",
        "maleMedian": "388",
        "femaleWorkers": "30",
        "femaleMedian": "-"
      }
    }
  },
  "Hosts and hostesses, restaurant, lounge, and coffee shop": {
    "ageData": {
      "2016": {
        "total": "319",
        "16to19": "156",
        "20to24": "86",
        "25to34": "34",
        "35to44": "17",
        "45to54": "11",
        "55to64": "11",
        "65+": "5",
        "medianAge": "11"
      }
    },
    "title": "Hosts and hostesses, restaurant, lounge, and coffee shop",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "319",
        "women": "80.8",
        "white": "8.8",
        "black": "3.8",
        "asian": "16.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "74",
        "median": "442",
        "maleWorkers": "24",
        "maleMedian": "-",
        "femaleWorkers": "50",
        "femaleMedian": "432"
      }
    }
  },
  "Food preparation and serving related workers, all other": {
    "ageData": {
      "2016": {
        "total": "3",
        "16to19": "1",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "1",
        "medianAge": "0"
      }
    },
    "title": "Food preparation and serving related workers, all other",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Building and grounds cleaning and maintenance occupations": {
    "ageData": {
      "2016": {
        "total": "5,804",
        "16to19": "202",
        "20to24": "485",
        "25to34": "1,069",
        "35to44": "1,248",
        "45to54": "1,306",
        "55to64": "1,144",
        "65+": "351",
        "medianAge": "1,144"
      }
    },
    "title": "Building and grounds cleaning and maintenance occupations",
    "depth": 2,
    "parent": "Service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "5,804",
        "women": "40.1",
        "white": "14.6",
        "black": "2.9",
        "asian": "38.2"
      }
    },
    "wageData": {
      "2016": {
        "workers": "3,602",
        "median": "510",
        "maleWorkers": "2,392",
        "maleMedian": "548",
        "femaleWorkers": "1,210",
        "femaleMedian": "454"
      }
    }
  },
  "First-line supervisors of housekeeping and janitorial workers": {
    "ageData": {
      "2016": {
        "total": "323",
        "16to19": "1",
        "20to24": "12",
        "25to34": "47",
        "35to44": "78",
        "45to54": "88",
        "55to64": "80",
        "65+": "17",
        "medianAge": "80"
      }
    },
    "title": "First-line supervisors of housekeeping and janitorial workers",
    "depth": 3,
    "parent": "Building and grounds cleaning and maintenance occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "323",
        "women": "39.9",
        "white": "16.0",
        "black": "1.2",
        "asian": "28.4"
      }
    },
    "wageData": {
      "2016": {
        "workers": "189",
        "median": "655",
        "maleWorkers": "119",
        "maleMedian": "702",
        "femaleWorkers": "71",
        "femaleMedian": "524"
      }
    }
  },
  "First-line supervisors of landscaping, lawn service, and groundskeeping workers": {
    "ageData": {
      "2016": {
        "total": "231",
        "16to19": "0",
        "20to24": "17",
        "25to34": "62",
        "35to44": "63",
        "45to54": "45",
        "55to64": "36",
        "65+": "8",
        "medianAge": "36"
      }
    },
    "title": "First-line supervisors of landscaping, lawn service, and groundskeeping workers",
    "depth": 3,
    "parent": "Building and grounds cleaning and maintenance occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "231",
        "women": "4.6",
        "white": "5.1",
        "black": "1.0",
        "asian": "24.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "113",
        "median": "707",
        "maleWorkers": "112",
        "maleMedian": "710",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Janitors and building cleaners": {
    "ageData": {
      "2016": {
        "total": "2,276",
        "16to19": "86",
        "20to24": "176",
        "25to34": "379",
        "35to44": "402",
        "45to54": "498",
        "55to64": "555",
        "65+": "180",
        "medianAge": "555"
      }
    },
    "title": "Janitors and building cleaners",
    "depth": 3,
    "parent": "Building and grounds cleaning and maintenance occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "2,276",
        "women": "34.2",
        "white": "17.9",
        "black": "3.4",
        "asian": "32.8"
      }
    },
    "wageData": {
      "2016": {
        "workers": "1,580",
        "median": "526",
        "maleWorkers": "1,139",
        "maleMedian": "564",
        "femaleWorkers": "441",
        "femaleMedian": "476"
      }
    }
  },
  "Maids and housekeeping cleaners": {
    "ageData": {
      "2016": {
        "total": "1,485",
        "16to19": "35",
        "20to24": "98",
        "25to34": "239",
        "35to44": "372",
        "45to54": "387",
        "55to64": "278",
        "65+": "75",
        "medianAge": "278"
      }
    },
    "title": "Maids and housekeeping cleaners",
    "depth": 3,
    "parent": "Building and grounds cleaning and maintenance occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "1,485",
        "women": "89.6",
        "white": "17.3",
        "black": "4.2",
        "asian": "47.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "781",
        "median": "441",
        "maleWorkers": "120",
        "maleMedian": "497",
        "femaleWorkers": "661",
        "femaleMedian": "427"
      }
    }
  },
  "Pest control workers": {
    "ageData": {
      "2016": {
        "total": "79",
        "16to19": "3",
        "20to24": "7",
        "25to34": "16",
        "35to44": "19",
        "45to54": "16",
        "55to64": "14",
        "65+": "4",
        "medianAge": "14"
      }
    },
    "title": "Pest control workers",
    "depth": 3,
    "parent": "Building and grounds cleaning and maintenance occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "79",
        "women": "2.6",
        "white": "6.2",
        "black": "0.2",
        "asian": "14.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "61",
        "median": "655",
        "maleWorkers": "59",
        "maleMedian": "649",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Grounds maintenance workers": {
    "ageData": {
      "2016": {
        "total": "1,411",
        "16to19": "77",
        "20to24": "176",
        "25to34": "327",
        "35to44": "315",
        "45to54": "272",
        "55to64": "180",
        "65+": "65",
        "medianAge": "180"
      }
    },
    "title": "Grounds maintenance workers",
    "depth": 3,
    "parent": "Building and grounds cleaning and maintenance occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "1,411",
        "women": "5.4",
        "white": "8.1",
        "black": "1.4",
        "asian": "43.1"
      }
    },
    "wageData": {
      "2016": {
        "workers": "878",
        "median": "502",
        "maleWorkers": "844",
        "maleMedian": "503",
        "femaleWorkers": "34",
        "femaleMedian": "-"
      }
    }
  },
  "Personal care and service occupations": {
    "ageData": {
      "2016": {
        "total": "5,795",
        "16to19": "325",
        "20to24": "789",
        "25to34": "1,245",
        "35to44": "1,050",
        "45to54": "1,124",
        "55to64": "858",
        "65+": "403",
        "medianAge": "858"
      }
    },
    "title": "Personal care and service occupations",
    "depth": 2,
    "parent": "Service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "5,795",
        "women": "77.3",
        "white": "15.9",
        "black": "9.3",
        "asian": "16.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "2,568",
        "median": "505",
        "maleWorkers": "684",
        "maleMedian": "613",
        "femaleWorkers": "1,884",
        "femaleMedian": "482"
      }
    }
  },
  "First-line supervisors of gaming workers": {
    "ageData": {
      "2016": {
        "total": "206",
        "16to19": "4",
        "20to24": "17",
        "25to34": "46",
        "35to44": "47",
        "45to54": "53",
        "55to64": "25",
        "65+": "15",
        "medianAge": "25"
      }
    },
    "title": "First-line supervisors of gaming workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "206",
        "women": "46.4",
        "white": "4.8",
        "black": "4.1",
        "asian": "11.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "127",
        "median": "792",
        "maleWorkers": "68",
        "maleMedian": "840",
        "femaleWorkers": "59",
        "femaleMedian": "717"
      }
    }
  },
  "First-line supervisors of personal service workers": {
    "ageData": {
      "2016": {
        "total": "191",
        "16to19": "0",
        "20to24": "6",
        "25to34": "45",
        "35to44": "53",
        "45to54": "50",
        "55to64": "25",
        "65+": "11",
        "medianAge": "25"
      }
    },
    "title": "First-line supervisors of personal service workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "191",
        "women": "70.5",
        "white": "9.2",
        "black": "15.5",
        "asian": "10.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "62",
        "median": "651",
        "maleWorkers": "25",
        "maleMedian": "-",
        "femaleWorkers": "37",
        "femaleMedian": "-"
      }
    }
  },
  "Animal trainers": {
    "ageData": {
      "2016": {
        "total": "61",
        "16to19": "1",
        "20to24": "7",
        "25to34": "11",
        "35to44": "13",
        "45to54": "14",
        "55to64": "13",
        "65+": "1",
        "medianAge": "13"
      }
    },
    "title": "Animal trainers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "61",
        "women": "47.3",
        "white": "1.2",
        "black": "1.3",
        "asian": "11.7"
      }
    },
    "wageData": {
      "2016": {
        "workers": "24",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "Nonfarm animal caretakers": {
    "ageData": {
      "2016": {
        "total": "233",
        "16to19": "24",
        "20to24": "49",
        "25to34": "57",
        "35to44": "32",
        "45to54": "36",
        "55to64": "26",
        "65+": "9",
        "medianAge": "26"
      }
    },
    "title": "Nonfarm animal caretakers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "233",
        "women": "76.9",
        "white": "4.7",
        "black": "2.4",
        "asian": "11.7"
      }
    },
    "wageData": {
      "2016": {
        "workers": "88",
        "median": "485",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "71",
        "femaleMedian": "489"
      }
    }
  },
  "Gaming services workers": {
    "ageData": {
      "2016": {
        "total": "92",
        "16to19": "2",
        "20to24": "8",
        "25to34": "20",
        "35to44": "19",
        "45to54": "24",
        "55to64": "14",
        "65+": "6",
        "medianAge": "14"
      }
    },
    "title": "Gaming services workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "92",
        "women": "47.9",
        "white": "8.0",
        "black": "28.2",
        "asian": "9.8"
      }
    },
    "wageData": {
      "2016": {
        "workers": "64",
        "median": "624",
        "maleWorkers": "33",
        "maleMedian": "-",
        "femaleWorkers": "31",
        "femaleMedian": "-"
      }
    }
  },
  "Motion picture projectionists": {
    "ageData": {
      "2016": {
        "total": "6",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "2",
        "45to54": "2",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Motion picture projectionists",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "6",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Ushers, lobby attendants, and ticket takers": {
    "ageData": {
      "2016": {
        "total": "40",
        "16to19": "14",
        "20to24": "9",
        "25to34": "5",
        "35to44": "1",
        "45to54": "1",
        "55to64": "4",
        "65+": "5",
        "medianAge": "4"
      }
    },
    "title": "Ushers, lobby attendants, and ticket takers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "40",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "14",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous entertainment attendants and related workers": {
    "ageData": {
      "2016": {
        "total": "199",
        "16to19": "47",
        "20to24": "50",
        "25to34": "32",
        "35to44": "11",
        "45to54": "16",
        "55to64": "27",
        "65+": "16",
        "medianAge": "27"
      }
    },
    "title": "Miscellaneous entertainment attendants and related workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "199",
        "women": "41.0",
        "white": "15.7",
        "black": "6.4",
        "asian": "9.8"
      }
    },
    "wageData": {
      "2016": {
        "workers": "79",
        "median": "481",
        "maleWorkers": "48",
        "maleMedian": "-",
        "femaleWorkers": "31",
        "femaleMedian": "-"
      }
    }
  },
  "Embalmers and funeral attendants": {
    "ageData": {
      "2016": {
        "total": "12",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "2",
        "45to54": "3",
        "55to64": "3",
        "65+": "3",
        "medianAge": "3"
      }
    },
    "title": "Embalmers and funeral attendants",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "12",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Morticians, undertakers, and funeral directors": {
    "ageData": {
      "2016": {
        "total": "40",
        "16to19": "0",
        "20to24": "2",
        "25to34": "6",
        "35to44": "8",
        "45to54": "10",
        "55to64": "6",
        "65+": "8",
        "medianAge": "6"
      }
    },
    "title": "Morticians, undertakers, and funeral directors",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "40",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "23",
        "median": "-",
        "maleWorkers": "18",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Barbers": {
    "ageData": {
      "2016": {
        "total": "126",
        "16to19": "1",
        "20to24": "9",
        "25to34": "32",
        "35to44": "39",
        "45to54": "21",
        "55to64": "14",
        "65+": "10",
        "medianAge": "14"
      }
    },
    "title": "Barbers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "126",
        "women": "11.7",
        "white": "39.9",
        "black": "3.3",
        "asian": "19.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "56",
        "median": "584",
        "maleWorkers": "48",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Hairdressers, hairstylists, and cosmetologists": {
    "ageData": {
      "2016": {
        "total": "806",
        "16to19": "10",
        "20to24": "74",
        "25to34": "220",
        "35to44": "169",
        "45to54": "178",
        "55to64": "93",
        "65+": "62",
        "medianAge": "93"
      }
    },
    "title": "Hairdressers, hairstylists, and cosmetologists",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "806",
        "women": "92.4",
        "white": "12.4",
        "black": "5.2",
        "asian": "16.5"
      }
    },
    "wageData": {
      "2016": {
        "workers": "307",
        "median": "510",
        "maleWorkers": "27",
        "maleMedian": "-",
        "femaleWorkers": "281",
        "femaleMedian": "500"
      }
    }
  },
  "Miscellaneous personal appearance workers": {
    "ageData": {
      "2016": {
        "total": "364",
        "16to19": "4",
        "20to24": "31",
        "25to34": "84",
        "35to44": "96",
        "45to54": "91",
        "55to64": "51",
        "65+": "6",
        "medianAge": "51"
      }
    },
    "title": "Miscellaneous personal appearance workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "364",
        "women": "86.1",
        "white": "9.5",
        "black": "57.8",
        "asian": "9.2"
      }
    },
    "wageData": {
      "2016": {
        "workers": "205",
        "median": "490",
        "maleWorkers": "37",
        "maleMedian": "-",
        "femaleWorkers": "167",
        "femaleMedian": "466"
      }
    }
  },
  "Baggage porters, bellhops, and concierges": {
    "ageData": {
      "2016": {
        "total": "66",
        "16to19": "4",
        "20to24": "12",
        "25to34": "13",
        "35to44": "10",
        "45to54": "15",
        "55to64": "9",
        "65+": "4",
        "medianAge": "9"
      }
    },
    "title": "Baggage porters, bellhops, and concierges",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "66",
        "women": "17.5",
        "white": "18.4",
        "black": "8.1",
        "asian": "31.8"
      }
    },
    "wageData": {
      "2016": {
        "workers": "56",
        "median": "625",
        "maleWorkers": "46",
        "maleMedian": "-",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Tour and travel guides": {
    "ageData": {
      "2016": {
        "total": "53",
        "16to19": "4",
        "20to24": "10",
        "25to34": "7",
        "35to44": "7",
        "45to54": "7",
        "55to64": "7",
        "65+": "9",
        "medianAge": "7"
      }
    },
    "title": "Tour and travel guides",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "53",
        "women": "40.2",
        "white": "13.0",
        "black": "5.7",
        "asian": "11.4"
      }
    },
    "wageData": {
      "2016": {
        "workers": "18",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Childcare workers": {
    "ageData": {
      "2016": {
        "total": "1,280",
        "16to19": "129",
        "20to24": "230",
        "25to34": "275",
        "35to44": "189",
        "45to54": "215",
        "55to64": "178",
        "65+": "63",
        "medianAge": "178"
      }
    },
    "title": "Childcare workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "1,280",
        "women": "94.4",
        "white": "17.1",
        "black": "2.8",
        "asian": "20.9"
      }
    },
    "wageData": {
      "2016": {
        "workers": "405",
        "median": "452",
        "maleWorkers": "24",
        "maleMedian": "-",
        "femaleWorkers": "381",
        "femaleMedian": "451"
      }
    }
  },
  "Personal care aides": {
    "ageData": {
      "2016": {
        "total": "1,396",
        "16to19": "33",
        "20to24": "164",
        "25to34": "255",
        "35to44": "231",
        "45to54": "294",
        "55to64": "286",
        "65+": "133",
        "medianAge": "286"
      }
    },
    "title": "Personal care aides",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "1,396",
        "women": "84.9",
        "white": "24.4",
        "black": "10.2",
        "asian": "20.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "762",
        "median": "477",
        "maleWorkers": "125",
        "maleMedian": "514",
        "femaleWorkers": "636",
        "femaleMedian": "469"
      }
    }
  },
  "Recreation and fitness workers": {
    "ageData": {
      "2016": {
        "total": "440",
        "16to19": "34",
        "20to24": "67",
        "25to34": "91",
        "35to44": "90",
        "45to54": "75",
        "55to64": "56",
        "65+": "27",
        "medianAge": "56"
      }
    },
    "title": "Recreation and fitness workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "440",
        "women": "62.2",
        "white": "10.0",
        "black": "1.0",
        "asian": "7.8"
      }
    },
    "wageData": {
      "2016": {
        "workers": "192",
        "median": "596",
        "maleWorkers": "92",
        "maleMedian": "668",
        "femaleWorkers": "100",
        "femaleMedian": "517"
      }
    }
  },
  "Residential advisors": {
    "ageData": {
      "2016": {
        "total": "42",
        "16to19": "5",
        "20to24": "16",
        "25to34": "9",
        "35to44": "7",
        "45to54": "2",
        "55to64": "4",
        "65+": "1",
        "medianAge": "4"
      }
    },
    "title": "Residential advisors",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "42",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "19",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "Personal care and service workers, all other": {
    "ageData": {
      "2016": {
        "total": "142",
        "16to19": "8",
        "20to24": "29",
        "25to34": "34",
        "35to44": "25",
        "45to54": "17",
        "55to64": "15",
        "65+": "14",
        "medianAge": "15"
      }
    },
    "title": "Personal care and service workers, all other",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "142",
        "women": "56.5",
        "white": "14.4",
        "black": "4.4",
        "asian": "12.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "61",
        "median": "573",
        "maleWorkers": "35",
        "maleMedian": "-",
        "femaleWorkers": "26",
        "femaleMedian": "-"
      }
    }
  },
  "Sales and office occupations": {
    "ageData": {
      "2016": {
        "total": "33,539",
        "16to19": "1,682",
        "20to24": "4,052",
        "25to34": "7,159",
        "35to44": "5,907",
        "45to54": "6,748",
        "55to64": "5,754",
        "65+": "2,237",
        "medianAge": "5,754"
      }
    },
    "title": "Sales and office occupations",
    "depth": 1,
    "parent": "",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "33,539",
        "women": "61.2",
        "white": "12.6",
        "black": "5.2",
        "asian": "15.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "23,625",
        "median": "698",
        "maleWorkers": "9,461",
        "maleMedian": "805",
        "femaleWorkers": "14,163",
        "femaleMedian": "649"
      }
    }
  },
  "Sales and related occupations": {
    "ageData": {
      "2016": {
        "total": "15,848",
        "16to19": "1,130",
        "20to24": "2,079",
        "25to34": "3,321",
        "35to44": "2,793",
        "45to54": "2,926",
        "55to64": "2,434",
        "65+": "1,165",
        "medianAge": "2,434"
      }
    },
    "title": "Sales and related occupations",
    "depth": 2,
    "parent": "Sales and office occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "15,848",
        "women": "49.0",
        "white": "11.0",
        "black": "5.7",
        "asian": "15.7"
      }
    },
    "wageData": {
      "2016": {
        "workers": "9,759",
        "median": "744",
        "maleWorkers": "5,509",
        "maleMedian": "904",
        "femaleWorkers": "4,250",
        "femaleMedian": "590"
      }
    }
  },
  "First-line supervisors of retail sales workers": {
    "ageData": {
      "2016": {
        "total": "3,282",
        "16to19": "33",
        "20to24": "254",
        "25to34": "815",
        "35to44": "720",
        "45to54": "723",
        "55to64": "540",
        "65+": "197",
        "medianAge": "540"
      }
    },
    "title": "First-line supervisors of retail sales workers",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "3,282",
        "women": "44.1",
        "white": "8.9",
        "black": "6.1",
        "asian": "14.7"
      }
    },
    "wageData": {
      "2016": {
        "workers": "2,368",
        "median": "743",
        "maleWorkers": "1,321",
        "maleMedian": "857",
        "femaleWorkers": "1,047",
        "femaleMedian": "630"
      }
    }
  },
  "First-line supervisors of non-retail sales workers": {
    "ageData": {
      "2016": {
        "total": "1,180",
        "16to19": "3",
        "20to24": "40",
        "25to34": "201",
        "35to44": "251",
        "45to54": "316",
        "55to64": "280",
        "65+": "89",
        "medianAge": "280"
      }
    },
    "title": "First-line supervisors of non-retail sales workers",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "1,180",
        "women": "26.8",
        "white": "6.8",
        "black": "6.7",
        "asian": "13.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "781",
        "median": "1,137",
        "maleWorkers": "552",
        "maleMedian": "1,161",
        "femaleWorkers": "230",
        "femaleMedian": "1,004"
      }
    }
  },
  "Cashiers": {
    "ageData": {
      "2016": {
        "total": "3,260",
        "16to19": "738",
        "20to24": "799",
        "25to34": "617",
        "35to44": "335",
        "45to54": "344",
        "55to64": "281",
        "65+": "146",
        "medianAge": "281"
      }
    },
    "title": "Cashiers",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "3,260",
        "women": "73.2",
        "white": "17.8",
        "black": "7.2",
        "asian": "20.7"
      }
    },
    "wageData": {
      "2016": {
        "workers": "1,367",
        "median": "414",
        "maleWorkers": "402",
        "maleMedian": "475",
        "femaleWorkers": "965",
        "femaleMedian": "403"
      }
    }
  },
  "Counter and rental clerks": {
    "ageData": {
      "2016": {
        "total": "105",
        "16to19": "9",
        "20to24": "13",
        "25to34": "33",
        "35to44": "16",
        "45to54": "17",
        "55to64": "10",
        "65+": "7",
        "medianAge": "10"
      }
    },
    "title": "Counter and rental clerks",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "105",
        "women": "46.6",
        "white": "14.0",
        "black": "3.2",
        "asian": "22.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "84",
        "median": "517",
        "maleWorkers": "47",
        "maleMedian": "-",
        "femaleWorkers": "36",
        "femaleMedian": "-"
      }
    }
  },
  "Parts salespersons": {
    "ageData": {
      "2016": {
        "total": "117",
        "16to19": "7",
        "20to24": "12",
        "25to34": "31",
        "35to44": "15",
        "45to54": "24",
        "55to64": "21",
        "65+": "8",
        "medianAge": "21"
      }
    },
    "title": "Parts salespersons",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "117",
        "women": "13.8",
        "white": "7.5",
        "black": "3.1",
        "asian": "17.9"
      }
    },
    "wageData": {
      "2016": {
        "workers": "96",
        "median": "639",
        "maleWorkers": "85",
        "maleMedian": "643",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "Retail salespersons": {
    "ageData": {
      "2016": {
        "total": "3,312",
        "16to19": "290",
        "20to24": "675",
        "25to34": "650",
        "35to44": "485",
        "45to54": "482",
        "55to64": "461",
        "65+": "269",
        "medianAge": "461"
      }
    },
    "title": "Retail salespersons",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "3,312",
        "women": "48.4",
        "white": "12.3",
        "black": "5.3",
        "asian": "18.2"
      }
    },
    "wageData": {
      "2016": {
        "workers": "1,831",
        "median": "623",
        "maleWorkers": "1,103",
        "maleMedian": "730",
        "femaleWorkers": "728",
        "femaleMedian": "514"
      }
    }
  },
  "Advertising sales agents": {
    "ageData": {
      "2016": {
        "total": "222",
        "16to19": "4",
        "20to24": "21",
        "25to34": "59",
        "35to44": "44",
        "45to54": "48",
        "55to64": "34",
        "65+": "12",
        "medianAge": "34"
      }
    },
    "title": "Advertising sales agents",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "222",
        "women": "45.5",
        "white": "12.0",
        "black": "4.0",
        "asian": "11.7"
      }
    },
    "wageData": {
      "2016": {
        "workers": "203",
        "median": "1,023",
        "maleWorkers": "122",
        "maleMedian": "1,034",
        "femaleWorkers": "81",
        "femaleMedian": "999"
      }
    }
  },
  "Insurance sales agents": {
    "ageData": {
      "2016": {
        "total": "630",
        "16to19": "5",
        "20to24": "38",
        "25to34": "135",
        "35to44": "138",
        "45to54": "140",
        "55to64": "108",
        "65+": "66",
        "medianAge": "108"
      }
    },
    "title": "Insurance sales agents",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "630",
        "women": "46.5",
        "white": "11.0",
        "black": "4.6",
        "asian": "11.7"
      }
    },
    "wageData": {
      "2016": {
        "workers": "423",
        "median": "839",
        "maleWorkers": "218",
        "maleMedian": "1,166",
        "femaleWorkers": "205",
        "femaleMedian": "676"
      }
    }
  },
  "Securities, commodities, and financial services sales agents": {
    "ageData": {
      "2016": {
        "total": "263",
        "16to19": "1",
        "20to24": "15",
        "25to34": "70",
        "35to44": "66",
        "45to54": "59",
        "55to64": "36",
        "65+": "15",
        "medianAge": "36"
      }
    },
    "title": "Securities, commodities, and financial services sales agents",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "263",
        "women": "30.4",
        "white": "7.1",
        "black": "7.2",
        "asian": "8.9"
      }
    },
    "wageData": {
      "2016": {
        "workers": "219",
        "median": "1,351",
        "maleWorkers": "155",
        "maleMedian": "1,458",
        "femaleWorkers": "63",
        "femaleMedian": "951"
      }
    }
  },
  "Travel agents": {
    "ageData": {
      "2016": {
        "total": "83",
        "16to19": "0",
        "20to24": "6",
        "25to34": "10",
        "35to44": "15",
        "45to54": "18",
        "55to64": "21",
        "65+": "13",
        "medianAge": "21"
      }
    },
    "title": "Travel agents",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "83",
        "women": "84.7",
        "white": "2.6",
        "black": "10.3",
        "asian": "11.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "46",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "38",
        "femaleMedian": "-"
      }
    }
  },
  "Sales representatives, services, all other": {
    "ageData": {
      "2016": {
        "total": "512",
        "16to19": "6",
        "20to24": "45",
        "25to34": "142",
        "35to44": "122",
        "45to54": "101",
        "55to64": "65",
        "65+": "31",
        "medianAge": "65"
      }
    },
    "title": "Sales representatives, services, all other",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "512",
        "women": "31.8",
        "white": "8.5",
        "black": "3.9",
        "asian": "11.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "427",
        "median": "1,040",
        "maleWorkers": "282",
        "maleMedian": "1,202",
        "femaleWorkers": "145",
        "femaleMedian": "826"
      }
    }
  },
  "Sales representatives, wholesale and manufacturing": {
    "ageData": {
      "2016": {
        "total": "1,308",
        "16to19": "11",
        "20to24": "65",
        "25to34": "300",
        "35to44": "276",
        "45to54": "320",
        "55to64": "263",
        "65+": "73",
        "medianAge": "263"
      }
    },
    "title": "Sales representatives, wholesale and manufacturing",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "1,308",
        "women": "29.1",
        "white": "4.8",
        "black": "4.3",
        "asian": "10.7"
      }
    },
    "wageData": {
      "2016": {
        "workers": "1,121",
        "median": "1,047",
        "maleWorkers": "819",
        "maleMedian": "1,140",
        "femaleWorkers": "302",
        "femaleMedian": "872"
      }
    }
  },
  "Models, demonstrators, and product promoters": {
    "ageData": {
      "2016": {
        "total": "59",
        "16to19": "4",
        "20to24": "7",
        "25to34": "15",
        "35to44": "7",
        "45to54": "4",
        "55to64": "12",
        "65+": "10",
        "medianAge": "12"
      }
    },
    "title": "Models, demonstrators, and product promoters",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "59",
        "women": "67.4",
        "white": "9.6",
        "black": "3.0",
        "asian": "9.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "14",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Real estate brokers and sales agents": {
    "ageData": {
      "2016": {
        "total": "977",
        "16to19": "4",
        "20to24": "30",
        "25to34": "134",
        "35to44": "198",
        "45to54": "222",
        "55to64": "209",
        "65+": "179",
        "medianAge": "209"
      }
    },
    "title": "Real estate brokers and sales agents",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "977",
        "women": "55.5",
        "white": "6.9",
        "black": "4.6",
        "asian": "10.2"
      }
    },
    "wageData": {
      "2016": {
        "workers": "493",
        "median": "983",
        "maleWorkers": "209",
        "maleMedian": "1,222",
        "femaleWorkers": "284",
        "femaleMedian": "780"
      }
    }
  },
  "Sales engineers": {
    "ageData": {
      "2016": {
        "total": "43",
        "16to19": "0",
        "20to24": "1",
        "25to34": "7",
        "35to44": "11",
        "45to54": "14",
        "55to64": "8",
        "65+": "2",
        "medianAge": "8"
      }
    },
    "title": "Sales engineers",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "43",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "40",
        "median": "-",
        "maleWorkers": "35",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Telemarketers": {
    "ageData": {
      "2016": {
        "total": "61",
        "16to19": "3",
        "20to24": "9",
        "25to34": "20",
        "35to44": "9",
        "45to54": "11",
        "55to64": "6",
        "65+": "3",
        "medianAge": "6"
      }
    },
    "title": "Telemarketers",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "61",
        "women": "64.0",
        "white": "34.9",
        "black": "4.8",
        "asian": "19.7"
      }
    },
    "wageData": {
      "2016": {
        "workers": "42",
        "median": "-",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "25",
        "femaleMedian": "-"
      }
    }
  },
  "Door-to-door sales workers, news and street vendors, and related workers": {
    "ageData": {
      "2016": {
        "total": "160",
        "16to19": "5",
        "20to24": "16",
        "25to34": "25",
        "35to44": "36",
        "45to54": "33",
        "55to64": "26",
        "65+": "19",
        "medianAge": "26"
      }
    },
    "title": "Door-to-door sales workers, news and street vendors, and related workers",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "160",
        "women": "62.5",
        "white": "9.1",
        "black": "3.4",
        "asian": "22.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "34",
        "median": "-",
        "maleWorkers": "23",
        "maleMedian": "-",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "Sales and related workers, all other": {
    "ageData": {
      "2016": {
        "total": "274",
        "16to19": "8",
        "20to24": "33",
        "25to34": "58",
        "35to44": "50",
        "45to54": "49",
        "55to64": "53",
        "65+": "24",
        "medianAge": "53"
      }
    },
    "title": "Sales and related workers, all other",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "274",
        "women": "47.2",
        "white": "9.7",
        "black": "3.2",
        "asian": "12.1"
      }
    },
    "wageData": {
      "2016": {
        "workers": "171",
        "median": "805",
        "maleWorkers": "103",
        "maleMedian": "946",
        "femaleWorkers": "68",
        "femaleMedian": "709"
      }
    }
  },
  "Office and administrative support occupations": {
    "ageData": {
      "2016": {
        "total": "17,691",
        "16to19": "552",
        "20to24": "1,973",
        "25to34": "3,838",
        "35to44": "3,114",
        "45to54": "3,822",
        "55to64": "3,321",
        "65+": "1,071",
        "medianAge": "3,321"
      }
    },
    "title": "Office and administrative support occupations",
    "depth": 2,
    "parent": "Sales and office occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "17,691",
        "women": "72.1",
        "white": "14.1",
        "black": "4.7",
        "asian": "15.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "13,866",
        "median": "679",
        "maleWorkers": "3,952",
        "maleMedian": "708",
        "femaleWorkers": "9,913",
        "femaleMedian": "669"
      }
    }
  },
  "First-line supervisors of office and administrative support workers": {
    "ageData": {
      "2016": {
        "total": "1,352",
        "16to19": "2",
        "20to24": "48",
        "25to34": "285",
        "35to44": "300",
        "45to54": "370",
        "55to64": "283",
        "65+": "64",
        "medianAge": "283"
      }
    },
    "title": "First-line supervisors of office and administrative support workers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "1,352",
        "women": "68.5",
        "white": "11.1",
        "black": "4.6",
        "asian": "13.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "1,203",
        "median": "848",
        "maleWorkers": "383",
        "maleMedian": "942",
        "femaleWorkers": "819",
        "femaleMedian": "809"
      }
    }
  },
  "Switchboard operators, including answering service": {
    "ageData": {
      "2016": {
        "total": "25",
        "16to19": "0",
        "20to24": "5",
        "25to34": "7",
        "35to44": "5",
        "45to54": "3",
        "55to64": "1",
        "65+": "4",
        "medianAge": "1"
      }
    },
    "title": "Switchboard operators, including answering service",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "25",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "17",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "14",
        "femaleMedian": "-"
      }
    }
  },
  "Telephone operators": {
    "ageData": {
      "2016": {
        "total": "41",
        "16to19": "1",
        "20to24": "9",
        "25to34": "6",
        "35to44": "4",
        "45to54": "10",
        "55to64": "8",
        "65+": "3",
        "medianAge": "8"
      }
    },
    "title": "Telephone operators",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "41",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "36",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "31",
        "femaleMedian": "-"
      }
    }
  },
  "Communications equipment operators, all other": {
    "ageData": {
      "2016": {
        "total": "5",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "1",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Communications equipment operators, all other",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Bill and account collectors": {
    "ageData": {
      "2016": {
        "total": "152",
        "16to19": "1",
        "20to24": "10",
        "25to34": "43",
        "35to44": "41",
        "45to54": "30",
        "55to64": "21",
        "65+": "6",
        "medianAge": "21"
      }
    },
    "title": "Bill and account collectors",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "152",
        "women": "67.4",
        "white": "25.1",
        "black": "2.0",
        "asian": "19.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "144",
        "median": "625",
        "maleWorkers": "39",
        "maleMedian": "-",
        "femaleWorkers": "105",
        "femaleMedian": "650"
      }
    }
  },
  "Billing and posting clerks": {
    "ageData": {
      "2016": {
        "total": "452",
        "16to19": "2",
        "20to24": "23",
        "25to34": "96",
        "35to44": "93",
        "45to54": "129",
        "55to64": "95",
        "65+": "14",
        "medianAge": "95"
      }
    },
    "title": "Billing and posting clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "452",
        "women": "89.0",
        "white": "14.0",
        "black": "4.6",
        "asian": "15.8"
      }
    },
    "wageData": {
      "2016": {
        "workers": "404",
        "median": "666",
        "maleWorkers": "44",
        "maleMedian": "-",
        "femaleWorkers": "360",
        "femaleMedian": "660"
      }
    }
  },
  "Bookkeeping, accounting, and auditing clerks": {
    "ageData": {
      "2016": {
        "total": "1,137",
        "16to19": "4",
        "20to24": "62",
        "25to34": "173",
        "35to44": "211",
        "45to54": "279",
        "55to64": "275",
        "65+": "133",
        "medianAge": "275"
      }
    },
    "title": "Bookkeeping, accounting, and auditing clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "1,137",
        "women": "88.5",
        "white": "7.7",
        "black": "4.9",
        "asian": "11.9"
      }
    },
    "wageData": {
      "2016": {
        "workers": "756",
        "median": "722",
        "maleWorkers": "115",
        "maleMedian": "790",
        "femaleWorkers": "641",
        "femaleMedian": "716"
      }
    }
  },
  "Gaming cage workers": {
    "ageData": {
      "2016": {
        "total": "12",
        "16to19": "1",
        "20to24": "1",
        "25to34": "5",
        "35to44": "2",
        "45to54": "2",
        "55to64": "1",
        "65+": "1",
        "medianAge": "1"
      }
    },
    "title": "Gaming cage workers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "12",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "9",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Payroll and timekeeping clerks": {
    "ageData": {
      "2016": {
        "total": "129",
        "16to19": "0",
        "20to24": "3",
        "25to34": "26",
        "35to44": "31",
        "45to54": "33",
        "55to64": "29",
        "65+": "7",
        "medianAge": "29"
      }
    },
    "title": "Payroll and timekeeping clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "129",
        "women": "90.7",
        "white": "10.4",
        "black": "8.1",
        "asian": "13.2"
      }
    },
    "wageData": {
      "2016": {
        "workers": "125",
        "median": "786",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "110",
        "femaleMedian": "785"
      }
    }
  },
  "Procurement clerks": {
    "ageData": {
      "2016": {
        "total": "27",
        "16to19": "0",
        "20to24": "3",
        "25to34": "3",
        "35to44": "7",
        "45to54": "5",
        "55to64": "9",
        "65+": "0",
        "medianAge": "9"
      }
    },
    "title": "Procurement clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "27",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "25",
        "median": "-",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "16",
        "femaleMedian": "-"
      }
    }
  },
  "Tellers": {
    "ageData": {
      "2016": {
        "total": "342",
        "16to19": "15",
        "20to24": "91",
        "25to34": "97",
        "35to44": "43",
        "45to54": "45",
        "55to64": "41",
        "65+": "10",
        "medianAge": "41"
      }
    },
    "title": "Tellers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "342",
        "women": "81.7",
        "white": "12.5",
        "black": "5.3",
        "asian": "17.5"
      }
    },
    "wageData": {
      "2016": {
        "workers": "235",
        "median": "542",
        "maleWorkers": "39",
        "maleMedian": "-",
        "femaleWorkers": "195",
        "femaleMedian": "539"
      }
    }
  },
  "Financial clerks, all other": {
    "ageData": {
      "2016": {
        "total": "69",
        "16to19": "1",
        "20to24": "5",
        "25to34": "19",
        "35to44": "15",
        "45to54": "16",
        "55to64": "11",
        "65+": "2",
        "medianAge": "11"
      }
    },
    "title": "Financial clerks, all other",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "69",
        "women": "63.9",
        "white": "14.6",
        "black": "9.1",
        "asian": "14.2"
      }
    },
    "wageData": {
      "2016": {
        "workers": "62",
        "median": "708",
        "maleWorkers": "18",
        "maleMedian": "-",
        "femaleWorkers": "44",
        "femaleMedian": "-"
      }
    }
  },
  "Brokerage clerks": {
    "ageData": {
      "2016": {
        "total": "3",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "0",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Brokerage clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Correspondence clerks": {
    "ageData": {
      "2016": {
        "total": "7",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "2",
        "45to54": "0",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Correspondence clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Court, municipal, and license clerks": {
    "ageData": {
      "2016": {
        "total": "80",
        "16to19": "0",
        "20to24": "1",
        "25to34": "14",
        "35to44": "18",
        "45to54": "24",
        "55to64": "18",
        "65+": "4",
        "medianAge": "18"
      }
    },
    "title": "Court, municipal, and license clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "80",
        "women": "71.1",
        "white": "18.9",
        "black": "2.4",
        "asian": "19.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "79",
        "median": "691",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "60",
        "femaleMedian": "681"
      }
    }
  },
  "Credit authorizers, checkers, and clerks": {
    "ageData": {
      "2016": {
        "total": "44",
        "16to19": "1",
        "20to24": "5",
        "25to34": "10",
        "35to44": "11",
        "45to54": "10",
        "55to64": "6",
        "65+": "1",
        "medianAge": "6"
      }
    },
    "title": "Credit authorizers, checkers, and clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "44",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "41",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "33",
        "femaleMedian": "-"
      }
    }
  },
  "Customer service representatives": {
    "ageData": {
      "2016": {
        "total": "2,388",
        "16to19": "152",
        "20to24": "367",
        "25to34": "637",
        "35to44": "406",
        "45to54": "418",
        "55to64": "299",
        "65+": "108",
        "medianAge": "299"
      }
    },
    "title": "Customer service representatives",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "2,388",
        "women": "65.0",
        "white": "16.9",
        "black": "4.5",
        "asian": "18.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "1,850",
        "median": "640",
        "maleWorkers": "664",
        "maleMedian": "676",
        "femaleWorkers": "1,185",
        "femaleMedian": "623"
      }
    }
  },
  "Eligibility interviewers, government programs": {
    "ageData": {
      "2016": {
        "total": "77",
        "16to19": "1",
        "20to24": "1",
        "25to34": "18",
        "35to44": "16",
        "45to54": "20",
        "55to64": "17",
        "65+": "2",
        "medianAge": "17"
      }
    },
    "title": "Eligibility interviewers, government programs",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "77",
        "women": "75.2",
        "white": "27.6",
        "black": "6.6",
        "asian": "19.4"
      }
    },
    "wageData": {
      "2016": {
        "workers": "72",
        "median": "828",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "55",
        "femaleMedian": "735"
      }
    }
  },
  "File clerks": {
    "ageData": {
      "2016": {
        "total": "183",
        "16to19": "13",
        "20to24": "27",
        "25to34": "43",
        "35to44": "32",
        "45to54": "23",
        "55to64": "36",
        "65+": "9",
        "medianAge": "36"
      }
    },
    "title": "File clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "183",
        "women": "82.7",
        "white": "21.2",
        "black": "5.7",
        "asian": "11.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "134",
        "median": "676",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "113",
        "femaleMedian": "680"
      }
    }
  },
  "Hotel, motel, and resort desk clerks": {
    "ageData": {
      "2016": {
        "total": "138",
        "16to19": "9",
        "20to24": "30",
        "25to34": "43",
        "35to44": "21",
        "45to54": "14",
        "55to64": "14",
        "65+": "6",
        "medianAge": "14"
      }
    },
    "title": "Hotel, motel, and resort desk clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "138",
        "women": "66.1",
        "white": "17.2",
        "black": "9.7",
        "asian": "21.5"
      }
    },
    "wageData": {
      "2016": {
        "workers": "102",
        "median": "513",
        "maleWorkers": "36",
        "maleMedian": "-",
        "femaleWorkers": "66",
        "femaleMedian": "500"
      }
    }
  },
  "Interviewers, except eligibility and loan": {
    "ageData": {
      "2016": {
        "total": "152",
        "16to19": "2",
        "20to24": "20",
        "25to34": "31",
        "35to44": "29",
        "45to54": "33",
        "55to64": "25",
        "65+": "10",
        "medianAge": "25"
      }
    },
    "title": "Interviewers, except eligibility and loan",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "152",
        "women": "88.1",
        "white": "20.7",
        "black": "5.2",
        "asian": "11.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "105",
        "median": "639",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "98",
        "femaleMedian": "634"
      }
    }
  },
  "Library assistants, clerical": {
    "ageData": {
      "2016": {
        "total": "87",
        "16to19": "5",
        "20to24": "12",
        "25to34": "10",
        "35to44": "16",
        "45to54": "18",
        "55to64": "19",
        "65+": "8",
        "medianAge": "19"
      }
    },
    "title": "Library assistants, clerical",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "87",
        "women": "86.2",
        "white": "12.5",
        "black": "1.4",
        "asian": "10.5"
      }
    },
    "wageData": {
      "2016": {
        "workers": "45",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "38",
        "femaleMedian": "-"
      }
    }
  },
  "Loan interviewers and clerks": {
    "ageData": {
      "2016": {
        "total": "132",
        "16to19": "0",
        "20to24": "5",
        "25to34": "37",
        "35to44": "32",
        "45to54": "28",
        "55to64": "25",
        "65+": "5",
        "medianAge": "25"
      }
    },
    "title": "Loan interviewers and clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "132",
        "women": "79.0",
        "white": "10.8",
        "black": "5.4",
        "asian": "13.8"
      }
    },
    "wageData": {
      "2016": {
        "workers": "124",
        "median": "774",
        "maleWorkers": "25",
        "maleMedian": "-",
        "femaleWorkers": "100",
        "femaleMedian": "756"
      }
    }
  },
  "New accounts clerks": {
    "ageData": {
      "2016": {
        "total": "24",
        "16to19": "0",
        "20to24": "3",
        "25to34": "10",
        "35to44": "6",
        "45to54": "3",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "New accounts clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "24",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "21",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "21",
        "femaleMedian": "-"
      }
    }
  },
  "Order clerks": {
    "ageData": {
      "2016": {
        "total": "105",
        "16to19": "5",
        "20to24": "16",
        "25to34": "23",
        "35to44": "14",
        "45to54": "25",
        "55to64": "19",
        "65+": "3",
        "medianAge": "19"
      }
    },
    "title": "Order clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "105",
        "women": "57.3",
        "white": "8.1",
        "black": "4.1",
        "asian": "21.5"
      }
    },
    "wageData": {
      "2016": {
        "workers": "85",
        "median": "646",
        "maleWorkers": "40",
        "maleMedian": "-",
        "femaleWorkers": "45",
        "femaleMedian": "-"
      }
    }
  },
  "Human resources assistants, except payroll and timekeeping": {
    "ageData": {
      "2016": {
        "total": "42",
        "16to19": "0",
        "20to24": "5",
        "25to34": "10",
        "35to44": "8",
        "45to54": "11",
        "55to64": "7",
        "65+": "0",
        "medianAge": "7"
      }
    },
    "title": "Human resources assistants, except payroll and timekeeping",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "42",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "36",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "32",
        "femaleMedian": "-"
      }
    }
  },
  "Receptionists and information clerks": {
    "ageData": {
      "2016": {
        "total": "1,331",
        "16to19": "64",
        "20to24": "260",
        "25to34": "316",
        "35to44": "200",
        "45to54": "191",
        "55to64": "203",
        "65+": "97",
        "medianAge": "203"
      }
    },
    "title": "Receptionists and information clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "1,331",
        "women": "90.1",
        "white": "13.0",
        "black": "3.7",
        "asian": "18.4"
      }
    },
    "wageData": {
      "2016": {
        "workers": "951",
        "median": "583",
        "maleWorkers": "103",
        "maleMedian": "600",
        "femaleWorkers": "848",
        "femaleMedian": "581"
      }
    }
  },
  "Reservation and transportation ticket agents and travel clerks": {
    "ageData": {
      "2016": {
        "total": "144",
        "16to19": "3",
        "20to24": "18",
        "25to34": "36",
        "35to44": "24",
        "45to54": "36",
        "55to64": "23",
        "65+": "5",
        "medianAge": "23"
      }
    },
    "title": "Reservation and transportation ticket agents and travel clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "144",
        "women": "55.1",
        "white": "24.8",
        "black": "8.2",
        "asian": "20.0"
      }
    },
    "wageData": {
      "2016": {
        "workers": "102",
        "median": "705",
        "maleWorkers": "46",
        "maleMedian": "-",
        "femaleWorkers": "56",
        "femaleMedian": "646"
      }
    }
  },
  "Information and record clerks, all other": {
    "ageData": {
      "2016": {
        "total": "118",
        "16to19": "1",
        "20to24": "14",
        "25to34": "23",
        "35to44": "21",
        "45to54": "28",
        "55to64": "20",
        "65+": "10",
        "medianAge": "20"
      }
    },
    "title": "Information and record clerks, all other",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "118",
        "women": "71.9",
        "white": "19.8",
        "black": "3.6",
        "asian": "7.9"
      }
    },
    "wageData": {
      "2016": {
        "workers": "104",
        "median": "654",
        "maleWorkers": "31",
        "maleMedian": "-",
        "femaleWorkers": "73",
        "femaleMedian": "631"
      }
    }
  },
  "Cargo and freight agents": {
    "ageData": {
      "2016": {
        "total": "27",
        "16to19": "0",
        "20to24": "3",
        "25to34": "4",
        "35to44": "5",
        "45to54": "10",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Cargo and freight agents",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "27",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "23",
        "median": "-",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "9",
        "femaleMedian": "-"
      }
    }
  },
  "Couriers and messengers": {
    "ageData": {
      "2016": {
        "total": "227",
        "16to19": "6",
        "20to24": "18",
        "25to34": "40",
        "35to44": "42",
        "45to54": "55",
        "55to64": "45",
        "65+": "21",
        "medianAge": "45"
      }
    },
    "title": "Couriers and messengers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "227",
        "women": "18.4",
        "white": "22.1",
        "black": "6.0",
        "asian": "19.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "160",
        "median": "726",
        "maleWorkers": "135",
        "maleMedian": "739",
        "femaleWorkers": "25",
        "femaleMedian": "-"
      }
    }
  },
  "Dispatchers": {
    "ageData": {
      "2016": {
        "total": "292",
        "16to19": "3",
        "20to24": "26",
        "25to34": "84",
        "35to44": "67",
        "45to54": "69",
        "55to64": "37",
        "65+": "7",
        "medianAge": "37"
      }
    },
    "title": "Dispatchers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "292",
        "women": "55.7",
        "white": "12.8",
        "black": "3.1",
        "asian": "16.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "279",
        "median": "704",
        "maleWorkers": "126",
        "maleMedian": "734",
        "femaleWorkers": "153",
        "femaleMedian": "671"
      }
    }
  },
  "Meter readers, utilities": {
    "ageData": {
      "2016": {
        "total": "31",
        "16to19": "0",
        "20to24": "3",
        "25to34": "5",
        "35to44": "4",
        "45to54": "11",
        "55to64": "7",
        "65+": "2",
        "medianAge": "7"
      }
    },
    "title": "Meter readers, utilities",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "31",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "30",
        "median": "-",
        "maleWorkers": "28",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Postal service clerks": {
    "ageData": {
      "2016": {
        "total": "121",
        "16to19": "0",
        "20to24": "4",
        "25to34": "14",
        "35to44": "15",
        "45to54": "35",
        "55to64": "46",
        "65+": "7",
        "medianAge": "46"
      }
    },
    "title": "Postal service clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "121",
        "women": "53.7",
        "white": "23.9",
        "black": "12.1",
        "asian": "8.7"
      }
    },
    "wageData": {
      "2016": {
        "workers": "111",
        "median": "901",
        "maleWorkers": "58",
        "maleMedian": "999",
        "femaleWorkers": "53",
        "femaleMedian": "805"
      }
    }
  },
  "Postal service mail carriers": {
    "ageData": {
      "2016": {
        "total": "320",
        "16to19": "0",
        "20to24": "13",
        "25to34": "61",
        "35to44": "52",
        "45to54": "103",
        "55to64": "78",
        "65+": "13",
        "medianAge": "78"
      }
    },
    "title": "Postal service mail carriers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "320",
        "women": "38.9",
        "white": "18.6",
        "black": "8.3",
        "asian": "12.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "304",
        "median": "981",
        "maleWorkers": "185",
        "maleMedian": "1,020",
        "femaleWorkers": "119",
        "femaleMedian": "931"
      }
    }
  },
  "Postal service mail sorters, processors, and processing machine operators": {
    "ageData": {
      "2016": {
        "total": "60",
        "16to19": "0",
        "20to24": "5",
        "25to34": "8",
        "35to44": "10",
        "45to54": "19",
        "55to64": "14",
        "65+": "3",
        "medianAge": "14"
      }
    },
    "title": "Postal service mail sorters, processors, and processing machine operators",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "60",
        "women": "42.4",
        "white": "34.3",
        "black": "7.5",
        "asian": "9.7"
      }
    },
    "wageData": {
      "2016": {
        "workers": "56",
        "median": "783",
        "maleWorkers": "33",
        "maleMedian": "-",
        "femaleWorkers": "23",
        "femaleMedian": "-"
      }
    }
  },
  "Production, planning, and expediting clerks": {
    "ageData": {
      "2016": {
        "total": "245",
        "16to19": "3",
        "20to24": "15",
        "25to34": "64",
        "35to44": "56",
        "45to54": "68",
        "55to64": "33",
        "65+": "6",
        "medianAge": "33"
      }
    },
    "title": "Production, planning, and expediting clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "245",
        "women": "51.5",
        "white": "8.6",
        "black": "4.2",
        "asian": "9.1"
      }
    },
    "wageData": {
      "2016": {
        "workers": "224",
        "median": "853",
        "maleWorkers": "113",
        "maleMedian": "935",
        "femaleWorkers": "111",
        "femaleMedian": "765"
      }
    }
  },
  "Shipping, receiving, and traffic clerks": {
    "ageData": {
      "2016": {
        "total": "546",
        "16to19": "24",
        "20to24": "72",
        "25to34": "141",
        "35to44": "81",
        "45to54": "111",
        "55to64": "99",
        "65+": "19",
        "medianAge": "99"
      }
    },
    "title": "Shipping, receiving, and traffic clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "546",
        "women": "33.9",
        "white": "16.1",
        "black": "2.2",
        "asian": "21.7"
      }
    },
    "wageData": {
      "2016": {
        "workers": "473",
        "median": "596",
        "maleWorkers": "322",
        "maleMedian": "602",
        "femaleWorkers": "151",
        "femaleMedian": "583"
      }
    }
  },
  "Stock clerks and order fillers": {
    "ageData": {
      "2016": {
        "total": "1,530",
        "16to19": "135",
        "20to24": "317",
        "25to34": "344",
        "35to44": "206",
        "45to54": "238",
        "55to64": "225",
        "65+": "66",
        "medianAge": "225"
      }
    },
    "title": "Stock clerks and order fillers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "1,530",
        "women": "37.0",
        "white": "18.8",
        "black": "4.8",
        "asian": "19.5"
      }
    },
    "wageData": {
      "2016": {
        "workers": "1,014",
        "median": "530",
        "maleWorkers": "643",
        "maleMedian": "533",
        "femaleWorkers": "371",
        "femaleMedian": "526"
      }
    }
  },
  "Weighers, measurers, checkers, and samplers, recordkeeping": {
    "ageData": {
      "2016": {
        "total": "51",
        "16to19": "0",
        "20to24": "4",
        "25to34": "10",
        "35to44": "7",
        "45to54": "15",
        "55to64": "12",
        "65+": "1",
        "medianAge": "12"
      }
    },
    "title": "Weighers, measurers, checkers, and samplers, recordkeeping",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "51",
        "women": "52.0",
        "white": "17.8",
        "black": "1.7",
        "asian": "19.2"
      }
    },
    "wageData": {
      "2016": {
        "workers": "45",
        "median": "-",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "24",
        "femaleMedian": "-"
      }
    }
  },
  "Secretaries and administrative assistants": {
    "ageData": {
      "2016": {
        "total": "2,744",
        "16to19": "25",
        "20to24": "143",
        "25to34": "443",
        "35to44": "496",
        "45to54": "696",
        "55to64": "695",
        "65+": "246",
        "medianAge": "695"
      }
    },
    "title": "Secretaries and administrative assistants",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "2,744",
        "women": "94.6",
        "white": "8.6",
        "black": "3.2",
        "asian": "11.8"
      }
    },
    "wageData": {
      "2016": {
        "workers": "2,210",
        "median": "713",
        "maleWorkers": "133",
        "maleMedian": "831",
        "femaleWorkers": "2,078",
        "femaleMedian": "708"
      }
    }
  },
  "Computer operators": {
    "ageData": {
      "2016": {
        "total": "91",
        "16to19": "1",
        "20to24": "7",
        "25to34": "18",
        "35to44": "15",
        "45to54": "28",
        "55to64": "19",
        "65+": "3",
        "medianAge": "19"
      }
    },
    "title": "Computer operators",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "91",
        "women": "50.8",
        "white": "13.8",
        "black": "4.1",
        "asian": "14.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "78",
        "median": "811",
        "maleWorkers": "41",
        "maleMedian": "-",
        "femaleWorkers": "37",
        "femaleMedian": "-"
      }
    }
  },
  "Data entry keyers": {
    "ageData": {
      "2016": {
        "total": "272",
        "16to19": "9",
        "20to24": "42",
        "25to34": "71",
        "35to44": "50",
        "45to54": "50",
        "55to64": "39",
        "65+": "10",
        "medianAge": "39"
      }
    },
    "title": "Data entry keyers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "272",
        "women": "77.6",
        "white": "13.4",
        "black": "9.1",
        "asian": "20.9"
      }
    },
    "wageData": {
      "2016": {
        "workers": "221",
        "median": "638",
        "maleWorkers": "51",
        "maleMedian": "655",
        "femaleWorkers": "170",
        "femaleMedian": "630"
      }
    }
  },
  "Word processors and typists": {
    "ageData": {
      "2016": {
        "total": "82",
        "16to19": "1",
        "20to24": "7",
        "25to34": "11",
        "35to44": "19",
        "45to54": "23",
        "55to64": "17",
        "65+": "5",
        "medianAge": "17"
      }
    },
    "title": "Word processors and typists",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "82",
        "women": "88.9",
        "white": "14.3",
        "black": "4.1",
        "asian": "11.2"
      }
    },
    "wageData": {
      "2016": {
        "workers": "56",
        "median": "730",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "48",
        "femaleMedian": "-"
      }
    }
  },
  "Desktop publishers": {
    "ageData": {
      "2016": {
        "total": "0",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Desktop publishers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "0",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "0",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Insurance claims and policy processing clerks": {
    "ageData": {
      "2016": {
        "total": "273",
        "16to19": "2",
        "20to24": "17",
        "25to34": "84",
        "35to44": "46",
        "45to54": "55",
        "55to64": "55",
        "65+": "14",
        "medianAge": "55"
      }
    },
    "title": "Insurance claims and policy processing clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "273",
        "women": "85.2",
        "white": "17.9",
        "black": "6.7",
        "asian": "11.4"
      }
    },
    "wageData": {
      "2016": {
        "workers": "251",
        "median": "665",
        "maleWorkers": "40",
        "maleMedian": "-",
        "femaleWorkers": "210",
        "femaleMedian": "658"
      }
    }
  },
  "Mail clerks and mail machine operators, except postal service": {
    "ageData": {
      "2016": {
        "total": "77",
        "16to19": "3",
        "20to24": "9",
        "25to34": "15",
        "35to44": "14",
        "45to54": "14",
        "55to64": "18",
        "65+": "5",
        "medianAge": "18"
      }
    },
    "title": "Mail clerks and mail machine operators, except postal service",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "77",
        "women": "43.5",
        "white": "21.7",
        "black": "1.0",
        "asian": "21.2"
      }
    },
    "wageData": {
      "2016": {
        "workers": "56",
        "median": "619",
        "maleWorkers": "33",
        "maleMedian": "-",
        "femaleWorkers": "23",
        "femaleMedian": "-"
      }
    }
  },
  "Office clerks, general": {
    "ageData": {
      "2016": {
        "total": "1,295",
        "16to19": "48",
        "20to24": "167",
        "25to34": "258",
        "35to44": "197",
        "45to54": "271",
        "55to64": "259",
        "65+": "95",
        "medianAge": "259"
      }
    },
    "title": "Office clerks, general",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "1,295",
        "women": "82.8",
        "white": "14.6",
        "black": "6.9",
        "asian": "16.9"
      }
    },
    "wageData": {
      "2016": {
        "workers": "900",
        "median": "654",
        "maleWorkers": "140",
        "maleMedian": "707",
        "femaleWorkers": "760",
        "femaleMedian": "648"
      }
    }
  },
  "Office machine operators, except computer": {
    "ageData": {
      "2016": {
        "total": "35",
        "16to19": "2",
        "20to24": "9",
        "25to34": "6",
        "35to44": "4",
        "45to54": "8",
        "55to64": "5",
        "65+": "1",
        "medianAge": "5"
      }
    },
    "title": "Office machine operators, except computer",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "35",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "30",
        "median": "-",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "16",
        "femaleMedian": "-"
      }
    }
  },
  "Proofreaders and copy markers": {
    "ageData": {
      "2016": {
        "total": "12",
        "16to19": "0",
        "20to24": "2",
        "25to34": "3",
        "35to44": "2",
        "45to54": "1",
        "55to64": "3",
        "65+": "2",
        "medianAge": "3"
      }
    },
    "title": "Proofreaders and copy markers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "12",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Statistical assistants": {
    "ageData": {
      "2016": {
        "total": "9",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "0",
        "45to54": "1",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Statistical assistants",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "9",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Office and administrative support workers, all other": {
    "ageData": {
      "2016": {
        "total": "551",
        "16to19": "3",
        "20to24": "45",
        "25to34": "127",
        "35to44": "115",
        "45to54": "135",
        "55to64": "97",
        "65+": "29",
        "medianAge": "97"
      }
    },
    "title": "Office and administrative support workers, all other",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "551",
        "women": "75.8",
        "white": "13.6",
        "black": "4.3",
        "asian": "11.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "457",
        "median": "768",
        "maleWorkers": "107",
        "maleMedian": "855",
        "femaleWorkers": "351",
        "femaleMedian": "751"
      }
    }
  },
  "Natural resources, construction, and maintenance occupations": {
    "ageData": {
      "2016": {
        "total": "13,904",
        "16to19": "317",
        "20to24": "1,162",
        "25to34": "3,245",
        "35to44": "3,294",
        "45to54": "3,194",
        "55to64": "2,177",
        "65+": "515",
        "medianAge": "2,177"
      }
    },
    "title": "Natural resources, construction, and maintenance occupations",
    "depth": 1,
    "parent": "",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "13,904",
        "women": "4.7",
        "white": "7.3",
        "black": "2.2",
        "asian": "29.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "11,022",
        "median": "786",
        "maleWorkers": "10,558",
        "maleMedian": "794",
        "femaleWorkers": "464",
        "femaleMedian": "580"
      }
    }
  },
  "Farming, fishing, and forestry occupations": {
    "ageData": {
      "2016": {
        "total": "1,096",
        "16to19": "77",
        "20to24": "112",
        "25to34": "257",
        "35to44": "225",
        "45to54": "229",
        "55to64": "139",
        "65+": "57",
        "medianAge": "139"
      }
    },
    "title": "Farming, fishing, and forestry occupations",
    "depth": 2,
    "parent": "Natural resources, construction, and maintenance occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "1,096",
        "women": "22.3",
        "white": "4.9",
        "black": "1.8",
        "asian": "45.0"
      }
    },
    "wageData": {
      "2016": {
        "workers": "842",
        "median": "520",
        "maleWorkers": "673",
        "maleMedian": "544",
        "femaleWorkers": "169",
        "femaleMedian": "465"
      }
    }
  },
  "First-line supervisors of farming, fishing, and forestry workers": {
    "ageData": {
      "2016": {
        "total": "48",
        "16to19": "0",
        "20to24": "2",
        "25to34": "6",
        "35to44": "16",
        "45to54": "14",
        "55to64": "6",
        "65+": "4",
        "medianAge": "6"
      }
    },
    "title": "First-line supervisors of farming, fishing, and forestry workers",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "48",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "28",
        "median": "-",
        "maleWorkers": "25",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Agricultural inspectors": {
    "ageData": {
      "2016": {
        "total": "19",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "3",
        "45to54": "8",
        "55to64": "4",
        "65+": "3",
        "medianAge": "4"
      }
    },
    "title": "Agricultural inspectors",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "19",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "18",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Animal breeders": {
    "ageData": {
      "2016": {
        "total": "7",
        "16to19": "0",
        "20to24": "2",
        "25to34": "2",
        "35to44": "3",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Animal breeders",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Graders and sorters, agricultural products": {
    "ageData": {
      "2016": {
        "total": "85",
        "16to19": "2",
        "20to24": "7",
        "25to34": "23",
        "35to44": "17",
        "45to54": "18",
        "55to64": "17",
        "65+": "3",
        "medianAge": "17"
      }
    },
    "title": "Graders and sorters, agricultural products",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "85",
        "women": "65.5",
        "white": "20.4",
        "black": "6.3",
        "asian": "40.7"
      }
    },
    "wageData": {
      "2016": {
        "workers": "74",
        "median": "511",
        "maleWorkers": "25",
        "maleMedian": "-",
        "femaleWorkers": "48",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous agricultural workers": {
    "ageData": {
      "2016": {
        "total": "818",
        "16to19": "73",
        "20to24": "92",
        "25to34": "202",
        "35to44": "167",
        "45to54": "159",
        "55to64": "87",
        "65+": "39",
        "medianAge": "87"
      }
    },
    "title": "Miscellaneous agricultural workers",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "818",
        "women": "21.0",
        "white": "2.7",
        "black": "1.3",
        "asian": "52.5"
      }
    },
    "wageData": {
      "2016": {
        "workers": "659",
        "median": "508",
        "maleWorkers": "547",
        "maleMedian": "524",
        "femaleWorkers": "111",
        "femaleMedian": "423"
      }
    }
  },
  "Fishers and related fishing workers": {
    "ageData": {
      "2016": {
        "total": "29",
        "16to19": "2",
        "20to24": "1",
        "25to34": "8",
        "35to44": "5",
        "45to54": "5",
        "55to64": "8",
        "65+": "0",
        "medianAge": "8"
      }
    },
    "title": "Fishers and related fishing workers",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "29",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Hunters and trappers": {
    "ageData": {
      "2016": {
        "total": "1",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "1",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Hunters and trappers",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Forest and conservation workers": {
    "ageData": {
      "2016": {
        "total": "21",
        "16to19": "0",
        "20to24": "3",
        "25to34": "4",
        "35to44": "2",
        "45to54": "5",
        "55to64": "5",
        "65+": "2",
        "medianAge": "5"
      }
    },
    "title": "Forest and conservation workers",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "21",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "17",
        "median": "-",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Logging workers": {
    "ageData": {
      "2016": {
        "total": "68",
        "16to19": "1",
        "20to24": "6",
        "25to34": "12",
        "35to44": "11",
        "45to54": "19",
        "55to64": "13",
        "65+": "6",
        "medianAge": "13"
      }
    },
    "title": "Logging workers",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "68",
        "women": "0.9",
        "white": "11.2",
        "black": "0.0",
        "asian": "5.1"
      }
    },
    "wageData": {
      "2016": {
        "workers": "39",
        "median": "-",
        "maleWorkers": "39",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Construction and extraction occupations": {
    "ageData": {
      "2016": {
        "total": "7,929",
        "16to19": "151",
        "20to24": "665",
        "25to34": "1,889",
        "35to44": "1,984",
        "45to54": "1,773",
        "55to64": "1,201",
        "65+": "266",
        "medianAge": "1,201"
      }
    },
    "title": "Construction and extraction occupations",
    "depth": 2,
    "parent": "Natural resources, construction, and maintenance occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "7,929",
        "women": "3.0",
        "white": "6.8",
        "black": "1.7",
        "asian": "34.0"
      }
    },
    "wageData": {
      "2016": {
        "workers": "5,979",
        "median": "784",
        "maleWorkers": "5,828",
        "maleMedian": "786",
        "femaleWorkers": "151",
        "femaleMedian": "639"
      }
    }
  },
  "First-line supervisors of construction trades and extraction workers": {
    "ageData": {
      "2016": {
        "total": "680",
        "16to19": "1",
        "20to24": "20",
        "25to34": "135",
        "35to44": "186",
        "45to54": "184",
        "55to64": "129",
        "65+": "23",
        "medianAge": "129"
      }
    },
    "title": "First-line supervisors of construction trades and extraction workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "680",
        "women": "2.6",
        "white": "5.5",
        "black": "1.5",
        "asian": "22.5"
      }
    },
    "wageData": {
      "2016": {
        "workers": "558",
        "median": "1,091",
        "maleWorkers": "540",
        "maleMedian": "1,101",
        "femaleWorkers": "18",
        "femaleMedian": "-"
      }
    }
  },
  "Boilermakers": {
    "ageData": {
      "2016": {
        "total": "23",
        "16to19": "0",
        "20to24": "1",
        "25to34": "3",
        "35to44": "8",
        "45to54": "7",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Boilermakers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "23",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "26",
        "median": "-",
        "maleWorkers": "26",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Brickmasons, blockmasons, and stonemasons": {
    "ageData": {
      "2016": {
        "total": "160",
        "16to19": "2",
        "20to24": "12",
        "25to34": "39",
        "35to44": "47",
        "45to54": "38",
        "55to64": "18",
        "65+": "3",
        "medianAge": "18"
      }
    },
    "title": "Brickmasons, blockmasons, and stonemasons",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "160",
        "women": "0.5",
        "white": "6.4",
        "black": "0.5",
        "asian": "45.5"
      }
    },
    "wageData": {
      "2016": {
        "workers": "119",
        "median": "741",
        "maleWorkers": "119",
        "maleMedian": "741",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Carpenters": {
    "ageData": {
      "2016": {
        "total": "1,359",
        "16to19": "20",
        "20to24": "94",
        "25to34": "312",
        "35to44": "359",
        "45to54": "313",
        "55to64": "211",
        "65+": "51",
        "medianAge": "211"
      }
    },
    "title": "Carpenters",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "1,359",
        "women": "2.1",
        "white": "5.4",
        "black": "1.2",
        "asian": "33.9"
      }
    },
    "wageData": {
      "2016": {
        "workers": "839",
        "median": "730",
        "maleWorkers": "828",
        "maleMedian": "733",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "Carpet, floor, and tile installers and finishers": {
    "ageData": {
      "2016": {
        "total": "168",
        "16to19": "2",
        "20to24": "14",
        "25to34": "50",
        "35to44": "45",
        "45to54": "37",
        "55to64": "14",
        "65+": "5",
        "medianAge": "14"
      }
    },
    "title": "Carpet, floor, and tile installers and finishers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "168",
        "women": "1.9",
        "white": "3.5",
        "black": "0.9",
        "asian": "50.8"
      }
    },
    "wageData": {
      "2016": {
        "workers": "93",
        "median": "593",
        "maleWorkers": "91",
        "maleMedian": "597",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Cement masons, concrete finishers, and terrazzo workers": {
    "ageData": {
      "2016": {
        "total": "56",
        "16to19": "0",
        "20to24": "8",
        "25to34": "9",
        "35to44": "14",
        "45to54": "15",
        "55to64": "8",
        "65+": "2",
        "medianAge": "8"
      }
    },
    "title": "Cement masons, concrete finishers, and terrazzo workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "56",
        "women": "2.2",
        "white": "10.5",
        "black": "1.6",
        "asian": "48.8"
      }
    },
    "wageData": {
      "2016": {
        "workers": "47",
        "median": "-",
        "maleWorkers": "47",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Construction laborers": {
    "ageData": {
      "2016": {
        "total": "1,801",
        "16to19": "57",
        "20to24": "200",
        "25to34": "492",
        "35to44": "419",
        "45to54": "366",
        "55to64": "220",
        "65+": "48",
        "medianAge": "220"
      }
    },
    "title": "Construction laborers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "1,801",
        "women": "3.5",
        "white": "8.1",
        "black": "2.2",
        "asian": "45.5"
      }
    },
    "wageData": {
      "2016": {
        "workers": "1,322",
        "median": "692",
        "maleWorkers": "1,281",
        "maleMedian": "697",
        "femaleWorkers": "41",
        "femaleMedian": "-"
      }
    }
  },
  "Paving, surfacing, and tamping equipment operators": {
    "ageData": {
      "2016": {
        "total": "13",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "5",
        "45to54": "2",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Paving, surfacing, and tamping equipment operators",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "13",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "11",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Pile-driver operators": {
    "ageData": {
      "2016": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Pile-driver operators",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Operating engineers and other construction equipment operators": {
    "ageData": {
      "2016": {
        "total": "351",
        "16to19": "6",
        "20to24": "26",
        "25to34": "62",
        "35to44": "83",
        "45to54": "85",
        "55to64": "71",
        "65+": "18",
        "medianAge": "71"
      }
    },
    "title": "Operating engineers and other construction equipment operators",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "351",
        "women": "2.3",
        "white": "8.7",
        "black": "0.2",
        "asian": "19.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "334",
        "median": "920",
        "maleWorkers": "328",
        "maleMedian": "922",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Drywall installers, ceiling tile installers, and tapers": {
    "ageData": {
      "2016": {
        "total": "180",
        "16to19": "4",
        "20to24": "16",
        "25to34": "37",
        "35to44": "61",
        "45to54": "30",
        "55to64": "25",
        "65+": "7",
        "medianAge": "25"
      }
    },
    "title": "Drywall installers, ceiling tile installers, and tapers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "180",
        "women": "1.1",
        "white": "6.7",
        "black": "0.5",
        "asian": "62.7"
      }
    },
    "wageData": {
      "2016": {
        "workers": "138",
        "median": "600",
        "maleWorkers": "137",
        "maleMedian": "601",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Electricians": {
    "ageData": {
      "2016": {
        "total": "774",
        "16to19": "15",
        "20to24": "62",
        "25to34": "179",
        "35to44": "189",
        "45to54": "172",
        "55to64": "133",
        "65+": "23",
        "medianAge": "133"
      }
    },
    "title": "Electricians",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "774",
        "women": "3.0",
        "white": "5.9",
        "black": "3.1",
        "asian": "16.8"
      }
    },
    "wageData": {
      "2016": {
        "workers": "667",
        "median": "952",
        "maleWorkers": "649",
        "maleMedian": "951",
        "femaleWorkers": "18",
        "femaleMedian": "-"
      }
    }
  },
  "Glaziers": {
    "ageData": {
      "2016": {
        "total": "54",
        "16to19": "0",
        "20to24": "7",
        "25to34": "11",
        "35to44": "13",
        "45to54": "10",
        "55to64": "13",
        "65+": "1",
        "medianAge": "13"
      }
    },
    "title": "Glaziers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "54",
        "women": "1.4",
        "white": "5.0",
        "black": "0.0",
        "asian": "21.2"
      }
    },
    "wageData": {
      "2016": {
        "workers": "48",
        "median": "-",
        "maleWorkers": "47",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Insulation workers": {
    "ageData": {
      "2016": {
        "total": "50",
        "16to19": "1",
        "20to24": "4",
        "25to34": "18",
        "35to44": "9",
        "45to54": "13",
        "55to64": "5",
        "65+": "0",
        "medianAge": "5"
      }
    },
    "title": "Insulation workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "50",
        "women": "5.2",
        "white": "7.7",
        "black": "0.5",
        "asian": "33.8"
      }
    },
    "wageData": {
      "2016": {
        "workers": "42",
        "median": "-",
        "maleWorkers": "40",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Painters, construction and maintenance": {
    "ageData": {
      "2016": {
        "total": "612",
        "16to19": "10",
        "20to24": "53",
        "25to34": "142",
        "35to44": "157",
        "45to54": "140",
        "55to64": "94",
        "65+": "16",
        "medianAge": "94"
      }
    },
    "title": "Painters, construction and maintenance",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "612",
        "women": "6.2",
        "white": "5.7",
        "black": "3.6",
        "asian": "50.7"
      }
    },
    "wageData": {
      "2016": {
        "workers": "392",
        "median": "612",
        "maleWorkers": "375",
        "maleMedian": "615",
        "femaleWorkers": "17",
        "femaleMedian": "-"
      }
    }
  },
  "Paperhangers": {
    "ageData": {
      "2016": {
        "total": "4",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Paperhangers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "0",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Pipelayers, plumbers, pipefitters, and steamfitters": {
    "ageData": {
      "2016": {
        "total": "583",
        "16to19": "5",
        "20to24": "43",
        "25to34": "141",
        "35to44": "121",
        "45to54": "145",
        "55to64": "100",
        "65+": "29",
        "medianAge": "100"
      }
    },
    "title": "Pipelayers, plumbers, pipefitters, and steamfitters",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "583",
        "women": "1.4",
        "white": "7.5",
        "black": "0.9",
        "asian": "21.2"
      }
    },
    "wageData": {
      "2016": {
        "workers": "475",
        "median": "927",
        "maleWorkers": "464",
        "maleMedian": "925",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "Plasterers and stucco masons": {
    "ageData": {
      "2016": {
        "total": "34",
        "16to19": "1",
        "20to24": "2",
        "25to34": "7",
        "35to44": "9",
        "45to54": "8",
        "55to64": "4",
        "65+": "2",
        "medianAge": "4"
      }
    },
    "title": "Plasterers and stucco masons",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "34",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "25",
        "median": "-",
        "maleWorkers": "25",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Reinforcing iron and rebar workers": {
    "ageData": {
      "2016": {
        "total": "12",
        "16to19": "1",
        "20to24": "2",
        "25to34": "3",
        "35to44": "2",
        "45to54": "3",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Reinforcing iron and rebar workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "12",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Roofers": {
    "ageData": {
      "2016": {
        "total": "230",
        "16to19": "8",
        "20to24": "31",
        "25to34": "59",
        "35to44": "64",
        "45to54": "47",
        "55to64": "18",
        "65+": "3",
        "medianAge": "18"
      }
    },
    "title": "Roofers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "230",
        "women": "1.7",
        "white": "4.9",
        "black": "1.1",
        "asian": "51.7"
      }
    },
    "wageData": {
      "2016": {
        "workers": "161",
        "median": "623",
        "maleWorkers": "158",
        "maleMedian": "624",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Sheet metal workers": {
    "ageData": {
      "2016": {
        "total": "126",
        "16to19": "1",
        "20to24": "8",
        "25to34": "33",
        "35to44": "36",
        "45to54": "18",
        "55to64": "26",
        "65+": "3",
        "medianAge": "26"
      }
    },
    "title": "Sheet metal workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "126",
        "women": "2.9",
        "white": "6.7",
        "black": "2.1",
        "asian": "16.7"
      }
    },
    "wageData": {
      "2016": {
        "workers": "117",
        "median": "819",
        "maleWorkers": "115",
        "maleMedian": "820",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Structural iron and steel workers": {
    "ageData": {
      "2016": {
        "total": "60",
        "16to19": "0",
        "20to24": "4",
        "25to34": "14",
        "35to44": "14",
        "45to54": "20",
        "55to64": "7",
        "65+": "0",
        "medianAge": "7"
      }
    },
    "title": "Structural iron and steel workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "60",
        "women": "2.0",
        "white": "10.7",
        "black": "1.9",
        "asian": "10.8"
      }
    },
    "wageData": {
      "2016": {
        "workers": "61",
        "median": "976",
        "maleWorkers": "60",
        "maleMedian": "983",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Solar photovoltaic installers": {
    "ageData": {
      "2016": {
        "total": "10",
        "16to19": "0",
        "20to24": "2",
        "25to34": "2",
        "35to44": "4",
        "45to54": "2",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Solar photovoltaic installers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "10",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "10",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Helpers, construction trades": {
    "ageData": {
      "2016": {
        "total": "57",
        "16to19": "8",
        "20to24": "19",
        "25to34": "13",
        "35to44": "8",
        "45to54": "4",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Helpers, construction trades",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "57",
        "women": "6.2",
        "white": "9.2",
        "black": "0.9",
        "asian": "42.5"
      }
    },
    "wageData": {
      "2016": {
        "workers": "42",
        "median": "-",
        "maleWorkers": "40",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Construction and building inspectors": {
    "ageData": {
      "2016": {
        "total": "93",
        "16to19": "0",
        "20to24": "4",
        "25to34": "10",
        "35to44": "20",
        "45to54": "24",
        "55to64": "25",
        "65+": "9",
        "medianAge": "25"
      }
    },
    "title": "Construction and building inspectors",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "93",
        "women": "6.4",
        "white": "11.8",
        "black": "2.4",
        "asian": "9.9"
      }
    },
    "wageData": {
      "2016": {
        "workers": "68",
        "median": "996",
        "maleWorkers": "62",
        "maleMedian": "1,040",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Elevator installers and repairers": {
    "ageData": {
      "2016": {
        "total": "44",
        "16to19": "0",
        "20to24": "2",
        "25to34": "12",
        "35to44": "14",
        "45to54": "7",
        "55to64": "8",
        "65+": "2",
        "medianAge": "8"
      }
    },
    "title": "Elevator installers and repairers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "44",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "41",
        "median": "-",
        "maleWorkers": "41",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Fence erectors": {
    "ageData": {
      "2016": {
        "total": "43",
        "16to19": "3",
        "20to24": "8",
        "25to34": "8",
        "35to44": "12",
        "45to54": "9",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Fence erectors",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "43",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "30",
        "median": "-",
        "maleWorkers": "29",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Hazardous materials removal workers": {
    "ageData": {
      "2016": {
        "total": "34",
        "16to19": "1",
        "20to24": "2",
        "25to34": "8",
        "35to44": "11",
        "45to54": "6",
        "55to64": "4",
        "65+": "2",
        "medianAge": "4"
      }
    },
    "title": "Hazardous materials removal workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "34",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "28",
        "median": "-",
        "maleWorkers": "24",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Highway maintenance workers": {
    "ageData": {
      "2016": {
        "total": "92",
        "16to19": "2",
        "20to24": "4",
        "25to34": "14",
        "35to44": "17",
        "45to54": "31",
        "55to64": "21",
        "65+": "4",
        "medianAge": "21"
      }
    },
    "title": "Highway maintenance workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "92",
        "women": "3.2",
        "white": "9.6",
        "black": "0.2",
        "asian": "15.1"
      }
    },
    "wageData": {
      "2016": {
        "workers": "85",
        "median": "745",
        "maleWorkers": "83",
        "maleMedian": "723",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Rail-track laying and maintenance equipment operators": {
    "ageData": {
      "2016": {
        "total": "10",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "6",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Rail-track laying and maintenance equipment operators",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "10",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Septic tank servicers and sewer pipe cleaners": {
    "ageData": {
      "2016": {
        "total": "10",
        "16to19": "0",
        "20to24": "1",
        "25to34": "2",
        "35to44": "1",
        "45to54": "1",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Septic tank servicers and sewer pipe cleaners",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "10",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "9",
        "median": "-",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous construction and related workers": {
    "ageData": {
      "2016": {
        "total": "34",
        "16to19": "0",
        "20to24": "2",
        "25to34": "8",
        "35to44": "9",
        "45to54": "5",
        "55to64": "6",
        "65+": "3",
        "medianAge": "6"
      }
    },
    "title": "Miscellaneous construction and related workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "34",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "17",
        "median": "-",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Derrick, rotary drill, and service unit operators, oil, gas, and mining": {
    "ageData": {
      "2016": {
        "total": "25",
        "16to19": "0",
        "20to24": "2",
        "25to34": "10",
        "35to44": "5",
        "45to54": "4",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Derrick, rotary drill, and service unit operators, oil, gas, and mining",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "25",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "29",
        "median": "-",
        "maleWorkers": "29",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Earth drillers, except oil and gas": {
    "ageData": {
      "2016": {
        "total": "33",
        "16to19": "0",
        "20to24": "3",
        "25to34": "7",
        "35to44": "9",
        "45to54": "8",
        "55to64": "5",
        "65+": "1",
        "medianAge": "5"
      }
    },
    "title": "Earth drillers, except oil and gas",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "33",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "23",
        "median": "-",
        "maleWorkers": "23",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Explosives workers, ordnance handling experts, and blasters": {
    "ageData": {
      "2016": {
        "total": "11",
        "16to19": "0",
        "20to24": "0",
        "25to34": "4",
        "35to44": "2",
        "45to54": "2",
        "55to64": "1",
        "65+": "2",
        "medianAge": "1"
      }
    },
    "title": "Explosives workers, ordnance handling experts, and blasters",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "11",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "10",
        "median": "-",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Mining machine operators": {
    "ageData": {
      "2016": {
        "total": "55",
        "16to19": "0",
        "20to24": "3",
        "25to34": "17",
        "35to44": "16",
        "45to54": "7",
        "55to64": "10",
        "65+": "3",
        "medianAge": "10"
      }
    },
    "title": "Mining machine operators",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "55",
        "women": "1.5",
        "white": "7.1",
        "black": "0.0",
        "asian": "11.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "48",
        "median": "-",
        "maleWorkers": "48",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Roof bolters, mining": {
    "ageData": {
      "2016": {
        "total": "4",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "1",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Roof bolters, mining",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Roustabouts, oil and gas": {
    "ageData": {
      "2016": {
        "total": "6",
        "16to19": "0",
        "20to24": "1",
        "25to34": "5",
        "35to44": "1",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Roustabouts, oil and gas",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "6",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Helpers--extraction workers": {
    "ageData": {
      "2016": {
        "total": "4",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "1",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Helpers--extraction workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Other extraction workers": {
    "ageData": {
      "2016": {
        "total": "32",
        "16to19": "1",
        "20to24": "4",
        "25to34": "9",
        "35to44": "6",
        "45to54": "8",
        "55to64": "3",
        "65+": "2",
        "medianAge": "3"
      }
    },
    "title": "Other extraction workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "32",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "29",
        "median": "-",
        "maleWorkers": "29",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Installation, maintenance, and repair occupations": {
    "ageData": {
      "2016": {
        "total": "4,879",
        "16to19": "89",
        "20to24": "385",
        "25to34": "1,099",
        "35to44": "1,085",
        "45to54": "1,192",
        "55to64": "837",
        "65+": "193",
        "medianAge": "837"
      }
    },
    "title": "Installation, maintenance, and repair occupations",
    "depth": 2,
    "parent": "Natural resources, construction, and maintenance occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "4,879",
        "women": "3.6",
        "white": "8.8",
        "black": "3.2",
        "asian": "19.0"
      }
    },
    "wageData": {
      "2016": {
        "workers": "4,201",
        "median": "861",
        "maleWorkers": "4,057",
        "maleMedian": "863",
        "femaleWorkers": "144",
        "femaleMedian": "783"
      }
    }
  },
  "First-line supervisors of mechanics, installers, and repairers": {
    "ageData": {
      "2016": {
        "total": "264",
        "16to19": "0",
        "20to24": "4",
        "25to34": "37",
        "35to44": "57",
        "45to54": "96",
        "55to64": "60",
        "65+": "10",
        "medianAge": "60"
      }
    },
    "title": "First-line supervisors of mechanics, installers, and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "264",
        "women": "10.1",
        "white": "10.2",
        "black": "2.0",
        "asian": "10.4"
      }
    },
    "wageData": {
      "2016": {
        "workers": "246",
        "median": "1,020",
        "maleWorkers": "221",
        "maleMedian": "1,016",
        "femaleWorkers": "25",
        "femaleMedian": "-"
      }
    }
  },
  "Computer, automated teller, and office machine repairers": {
    "ageData": {
      "2016": {
        "total": "212",
        "16to19": "5",
        "20to24": "22",
        "25to34": "59",
        "35to44": "45",
        "45to54": "36",
        "55to64": "35",
        "65+": "9",
        "medianAge": "35"
      }
    },
    "title": "Computer, automated teller, and office machine repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "212",
        "women": "9.6",
        "white": "13.0",
        "black": "10.0",
        "asian": "12.0"
      }
    },
    "wageData": {
      "2016": {
        "workers": "165",
        "median": "851",
        "maleWorkers": "150",
        "maleMedian": "861",
        "femaleWorkers": "15",
        "femaleMedian": "-"
      }
    }
  },
  "Radio and telecommunications equipment installers and repairers": {
    "ageData": {
      "2016": {
        "total": "143",
        "16to19": "0",
        "20to24": "8",
        "25to34": "31",
        "35to44": "44",
        "45to54": "31",
        "55to64": "23",
        "65+": "5",
        "medianAge": "23"
      }
    },
    "title": "Radio and telecommunications equipment installers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "143",
        "women": "16.1",
        "white": "16.3",
        "black": "5.3",
        "asian": "15.8"
      }
    },
    "wageData": {
      "2016": {
        "workers": "132",
        "median": "950",
        "maleWorkers": "110",
        "maleMedian": "975",
        "femaleWorkers": "22",
        "femaleMedian": "-"
      }
    }
  },
  "Avionics technicians": {
    "ageData": {
      "2016": {
        "total": "7",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "4",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Avionics technicians",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Electric motor, power tool, and related repairers": {
    "ageData": {
      "2016": {
        "total": "25",
        "16to19": "0",
        "20to24": "0",
        "25to34": "10",
        "35to44": "4",
        "45to54": "6",
        "55to64": "4",
        "65+": "1",
        "medianAge": "4"
      }
    },
    "title": "Electric motor, power tool, and related repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "25",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "26",
        "median": "-",
        "maleWorkers": "23",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Electrical and electronics installers and repairers, transportation equipment": {
    "ageData": {
      "2016": {
        "total": "2",
        "16to19": "0",
        "20to24": "2",
        "25to34": "0",
        "35to44": "1",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Electrical and electronics installers and repairers, transportation equipment",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Electrical and electronics repairers, industrial and utility": {
    "ageData": {
      "2016": {
        "total": "13",
        "16to19": "0",
        "20to24": "1",
        "25to34": "4",
        "35to44": "4",
        "45to54": "2",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Electrical and electronics repairers, industrial and utility",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "13",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "15",
        "median": "-",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Electronic equipment installers and repairers, motor vehicles": {
    "ageData": {
      "2016": {
        "total": "10",
        "16to19": "0",
        "20to24": "1",
        "25to34": "3",
        "35to44": "3",
        "45to54": "2",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Electronic equipment installers and repairers, motor vehicles",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "10",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Electronic home entertainment equipment installers and repairers": {
    "ageData": {
      "2016": {
        "total": "44",
        "16to19": "2",
        "20to24": "6",
        "25to34": "13",
        "35to44": "8",
        "45to54": "8",
        "55to64": "4",
        "65+": "4",
        "medianAge": "4"
      }
    },
    "title": "Electronic home entertainment equipment installers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "44",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "33",
        "median": "-",
        "maleWorkers": "32",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Security and fire alarm systems installers": {
    "ageData": {
      "2016": {
        "total": "79",
        "16to19": "0",
        "20to24": "12",
        "25to34": "11",
        "35to44": "24",
        "45to54": "17",
        "55to64": "12",
        "65+": "2",
        "medianAge": "12"
      }
    },
    "title": "Security and fire alarm systems installers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "79",
        "women": "1.8",
        "white": "5.6",
        "black": "4.2",
        "asian": "24.2"
      }
    },
    "wageData": {
      "2016": {
        "workers": "69",
        "median": "927",
        "maleWorkers": "68",
        "maleMedian": "909",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Aircraft mechanics and service technicians": {
    "ageData": {
      "2016": {
        "total": "140",
        "16to19": "1",
        "20to24": "6",
        "25to34": "28",
        "35to44": "25",
        "45to54": "42",
        "55to64": "37",
        "65+": "2",
        "medianAge": "37"
      }
    },
    "title": "Aircraft mechanics and service technicians",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "140",
        "women": "1.7",
        "white": "3.3",
        "black": "6.0",
        "asian": "18.5"
      }
    },
    "wageData": {
      "2016": {
        "workers": "136",
        "median": "1,047",
        "maleWorkers": "134",
        "maleMedian": "1,053",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Automotive body and related repairers": {
    "ageData": {
      "2016": {
        "total": "147",
        "16to19": "4",
        "20to24": "13",
        "25to34": "33",
        "35to44": "36",
        "45to54": "31",
        "55to64": "21",
        "65+": "9",
        "medianAge": "21"
      }
    },
    "title": "Automotive body and related repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "147",
        "women": "3.6",
        "white": "5.2",
        "black": "3.0",
        "asian": "24.1"
      }
    },
    "wageData": {
      "2016": {
        "workers": "117",
        "median": "761",
        "maleWorkers": "112",
        "maleMedian": "770",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Automotive glass installers and repairers": {
    "ageData": {
      "2016": {
        "total": "22",
        "16to19": "1",
        "20to24": "1",
        "25to34": "2",
        "35to44": "9",
        "45to54": "5",
        "55to64": "1",
        "65+": "3",
        "medianAge": "1"
      }
    },
    "title": "Automotive glass installers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "22",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "14",
        "median": "-",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Automotive service technicians and mechanics": {
    "ageData": {
      "2016": {
        "total": "879",
        "16to19": "21",
        "20to24": "102",
        "25to34": "226",
        "35to44": "189",
        "45to54": "197",
        "55to64": "118",
        "65+": "26",
        "medianAge": "118"
      }
    },
    "title": "Automotive service technicians and mechanics",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "879",
        "women": "1.7",
        "white": "9.6",
        "black": "4.3",
        "asian": "23.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "688",
        "median": "730",
        "maleWorkers": "677",
        "maleMedian": "729",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "Bus and truck mechanics and diesel engine specialists": {
    "ageData": {
      "2016": {
        "total": "342",
        "16to19": "7",
        "20to24": "32",
        "25to34": "77",
        "35to44": "74",
        "45to54": "85",
        "55to64": "55",
        "65+": "12",
        "medianAge": "55"
      }
    },
    "title": "Bus and truck mechanics and diesel engine specialists",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "342",
        "women": "0.8",
        "white": "7.0",
        "black": "1.9",
        "asian": "16.1"
      }
    },
    "wageData": {
      "2016": {
        "workers": "298",
        "median": "874",
        "maleWorkers": "297",
        "maleMedian": "875",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Heavy vehicle and mobile equipment service technicians and mechanics": {
    "ageData": {
      "2016": {
        "total": "203",
        "16to19": "1",
        "20to24": "15",
        "25to34": "42",
        "35to44": "51",
        "45to54": "45",
        "55to64": "41",
        "65+": "8",
        "medianAge": "41"
      }
    },
    "title": "Heavy vehicle and mobile equipment service technicians and mechanics",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "203",
        "women": "0.5",
        "white": "3.8",
        "black": "1.9",
        "asian": "16.9"
      }
    },
    "wageData": {
      "2016": {
        "workers": "198",
        "median": "964",
        "maleWorkers": "198",
        "maleMedian": "964",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Small engine mechanics": {
    "ageData": {
      "2016": {
        "total": "51",
        "16to19": "3",
        "20to24": "3",
        "25to34": "15",
        "35to44": "9",
        "45to54": "10",
        "55to64": "7",
        "65+": "3",
        "medianAge": "7"
      }
    },
    "title": "Small engine mechanics",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "51",
        "women": "3.5",
        "white": "11.0",
        "black": "0.0",
        "asian": "6.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "32",
        "median": "-",
        "maleWorkers": "30",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous vehicle and mobile equipment mechanics, installers, and repairers": {
    "ageData": {
      "2016": {
        "total": "87",
        "16to19": "5",
        "20to24": "15",
        "25to34": "33",
        "35to44": "19",
        "45to54": "8",
        "55to64": "5",
        "65+": "2",
        "medianAge": "5"
      }
    },
    "title": "Miscellaneous vehicle and mobile equipment mechanics, installers, and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "87",
        "women": "1.7",
        "white": "10.8",
        "black": "2.0",
        "asian": "31.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "74",
        "median": "641",
        "maleWorkers": "73",
        "maleMedian": "646",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Control and valve installers and repairers": {
    "ageData": {
      "2016": {
        "total": "33",
        "16to19": "0",
        "20to24": "3",
        "25to34": "6",
        "35to44": "8",
        "45to54": "10",
        "55to64": "5",
        "65+": "1",
        "medianAge": "5"
      }
    },
    "title": "Control and valve installers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "33",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "33",
        "median": "-",
        "maleWorkers": "32",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Heating, air conditioning, and refrigeration mechanics and installers": {
    "ageData": {
      "2016": {
        "total": "427",
        "16to19": "8",
        "20to24": "36",
        "25to34": "115",
        "35to44": "84",
        "45to54": "105",
        "55to64": "62",
        "65+": "16",
        "medianAge": "62"
      }
    },
    "title": "Heating, air conditioning, and refrigeration mechanics and installers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "427",
        "women": "1.4",
        "white": "7.0",
        "black": "1.3",
        "asian": "21.9"
      }
    },
    "wageData": {
      "2016": {
        "workers": "361",
        "median": "864",
        "maleWorkers": "357",
        "maleMedian": "868",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Home appliance repairers": {
    "ageData": {
      "2016": {
        "total": "49",
        "16to19": "0",
        "20to24": "3",
        "25to34": "9",
        "35to44": "9",
        "45to54": "10",
        "55to64": "12",
        "65+": "6",
        "medianAge": "12"
      }
    },
    "title": "Home appliance repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "49",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "25",
        "median": "-",
        "maleWorkers": "25",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Industrial and refractory machinery mechanics": {
    "ageData": {
      "2016": {
        "total": "393",
        "16to19": "4",
        "20to24": "14",
        "25to34": "66",
        "35to44": "90",
        "45to54": "126",
        "55to64": "79",
        "65+": "15",
        "medianAge": "79"
      }
    },
    "title": "Industrial and refractory machinery mechanics",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "393",
        "women": "3.3",
        "white": "8.5",
        "black": "1.9",
        "asian": "15.0"
      }
    },
    "wageData": {
      "2016": {
        "workers": "385",
        "median": "968",
        "maleWorkers": "374",
        "maleMedian": "973",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "Maintenance and repair workers, general": {
    "ageData": {
      "2016": {
        "total": "552",
        "16to19": "9",
        "20to24": "31",
        "25to34": "109",
        "35to44": "102",
        "45to54": "149",
        "55to64": "121",
        "65+": "31",
        "medianAge": "121"
      }
    },
    "title": "Maintenance and repair workers, general",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "552",
        "women": "3.0",
        "white": "10.6",
        "black": "2.2",
        "asian": "19.8"
      }
    },
    "wageData": {
      "2016": {
        "workers": "514",
        "median": "796",
        "maleWorkers": "502",
        "maleMedian": "797",
        "femaleWorkers": "13",
        "femaleMedian": "-"
      }
    }
  },
  "Maintenance workers, machinery": {
    "ageData": {
      "2016": {
        "total": "21",
        "16to19": "2",
        "20to24": "2",
        "25to34": "2",
        "35to44": "2",
        "45to54": "4",
        "55to64": "7",
        "65+": "1",
        "medianAge": "7"
      }
    },
    "title": "Maintenance workers, machinery",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "21",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "20",
        "median": "-",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Millwrights": {
    "ageData": {
      "2016": {
        "total": "40",
        "16to19": "1",
        "20to24": "0",
        "25to34": "8",
        "35to44": "9",
        "45to54": "9",
        "55to64": "9",
        "65+": "2",
        "medianAge": "9"
      }
    },
    "title": "Millwrights",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "40",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "35",
        "median": "-",
        "maleWorkers": "35",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Electrical power-line installers and repairers": {
    "ageData": {
      "2016": {
        "total": "136",
        "16to19": "0",
        "20to24": "8",
        "25to34": "28",
        "35to44": "40",
        "45to54": "31",
        "55to64": "23",
        "65+": "5",
        "medianAge": "23"
      }
    },
    "title": "Electrical power-line installers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "136",
        "women": "0.6",
        "white": "7.7",
        "black": "0.9",
        "asian": "10.5"
      }
    },
    "wageData": {
      "2016": {
        "workers": "126",
        "median": "1,161",
        "maleWorkers": "125",
        "maleMedian": "1,165",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Telecommunications line installers and repairers": {
    "ageData": {
      "2016": {
        "total": "171",
        "16to19": "6",
        "20to24": "13",
        "25to34": "39",
        "35to44": "53",
        "45to54": "43",
        "55to64": "15",
        "65+": "2",
        "medianAge": "15"
      }
    },
    "title": "Telecommunications line installers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "171",
        "women": "2.5",
        "white": "16.1",
        "black": "5.2",
        "asian": "20.9"
      }
    },
    "wageData": {
      "2016": {
        "workers": "172",
        "median": "850",
        "maleWorkers": "168",
        "maleMedian": "824",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Precision instrument and equipment repairers": {
    "ageData": {
      "2016": {
        "total": "66",
        "16to19": "1",
        "20to24": "2",
        "25to34": "9",
        "35to44": "18",
        "45to54": "14",
        "55to64": "19",
        "65+": "4",
        "medianAge": "19"
      }
    },
    "title": "Precision instrument and equipment repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "66",
        "women": "14.9",
        "white": "4.5",
        "black": "3.9",
        "asian": "13.9"
      }
    },
    "wageData": {
      "2016": {
        "workers": "56",
        "median": "910",
        "maleWorkers": "46",
        "maleMedian": "-",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Wind turbine service technicians": {
    "ageData": {
      "2016": {
        "total": "5",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "2",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Wind turbine service technicians",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Coin, vending, and amusement machine servicers and repairers": {
    "ageData": {
      "2016": {
        "total": "38",
        "16to19": "1",
        "20to24": "4",
        "25to34": "10",
        "35to44": "7",
        "45to54": "10",
        "55to64": "5",
        "65+": "2",
        "medianAge": "5"
      }
    },
    "title": "Coin, vending, and amusement machine servicers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "38",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "27",
        "median": "-",
        "maleWorkers": "24",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Commercial divers": {
    "ageData": {
      "2016": {
        "total": "1",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "1",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Commercial divers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Locksmiths and safe repairers": {
    "ageData": {
      "2016": {
        "total": "30",
        "16to19": "1",
        "20to24": "3",
        "25to34": "7",
        "35to44": "6",
        "45to54": "6",
        "55to64": "5",
        "65+": "3",
        "medianAge": "5"
      }
    },
    "title": "Locksmiths and safe repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "30",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Manufactured building and mobile home installers": {
    "ageData": {
      "2016": {
        "total": "10",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "3",
        "45to54": "3",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Manufactured building and mobile home installers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "10",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Riggers": {
    "ageData": {
      "2016": {
        "total": "13",
        "16to19": "0",
        "20to24": "1",
        "25to34": "6",
        "35to44": "3",
        "45to54": "0",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Riggers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "13",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "11",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Signal and track switch repairers": {
    "ageData": {
      "2016": {
        "total": "7",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "1",
        "45to54": "2",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Signal and track switch repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Helpers--installation, maintenance, and repair workers": {
    "ageData": {
      "2016": {
        "total": "19",
        "16to19": "1",
        "20to24": "5",
        "25to34": "6",
        "35to44": "2",
        "45to54": "4",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Helpers--installation, maintenance, and repair workers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "19",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "10",
        "median": "-",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Other installation, maintenance, and repair workers": {
    "ageData": {
      "2016": {
        "total": "198",
        "16to19": "5",
        "20to24": "17",
        "25to34": "50",
        "35to44": "38",
        "45to54": "39",
        "55to64": "40",
        "65+": "8",
        "medianAge": "40"
      }
    },
    "title": "Other installation, maintenance, and repair workers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "198",
        "women": "6.1",
        "white": "5.8",
        "black": "3.9",
        "asian": "22.9"
      }
    },
    "wageData": {
      "2016": {
        "workers": "137",
        "median": "773",
        "maleWorkers": "130",
        "maleMedian": "782",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Production, transportation, and material moving occupations": {
    "ageData": {
      "2016": {
        "total": "17,743",
        "16to19": "500",
        "20to24": "1,687",
        "25to34": "3,681",
        "35to44": "3,547",
        "45to54": "4,138",
        "55to64": "3,249",
        "65+": "942",
        "medianAge": "3,249"
      }
    },
    "title": "Production, transportation, and material moving occupations",
    "depth": 1,
    "parent": "",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "17,743",
        "women": "22.1",
        "white": "15.8",
        "black": "4.8",
        "asian": "22.2"
      }
    },
    "wageData": {
      "2016": {
        "workers": "14,606",
        "median": "665",
        "maleWorkers": "11,661",
        "maleMedian": "704",
        "femaleWorkers": "2,945",
        "femaleMedian": "531"
      }
    }
  },
  "Production occupations": {
    "ageData": {
      "2016": {
        "total": "8,459",
        "16to19": "161",
        "20to24": "760",
        "25to34": "1,851",
        "35to44": "1,735",
        "45to54": "2,026",
        "55to64": "1,559",
        "65+": "367",
        "medianAge": "1,559"
      }
    },
    "title": "Production occupations",
    "depth": 2,
    "parent": "Production, transportation, and material moving occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "8,459",
        "women": "28.2",
        "white": "12.9",
        "black": "5.9",
        "asian": "22.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "7,513",
        "median": "668",
        "maleWorkers": "5,564",
        "maleMedian": "723",
        "femaleWorkers": "1,949",
        "femaleMedian": "527"
      }
    }
  },
  "First-line supervisors of production and operating workers": {
    "ageData": {
      "2016": {
        "total": "763",
        "16to19": "4",
        "20to24": "22",
        "25to34": "139",
        "35to44": "169",
        "45to54": "244",
        "55to64": "157",
        "65+": "27",
        "medianAge": "157"
      }
    },
    "title": "First-line supervisors of production and operating workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "763",
        "women": "18.7",
        "white": "9.7",
        "black": "3.9",
        "asian": "15.0"
      }
    },
    "wageData": {
      "2016": {
        "workers": "729",
        "median": "923",
        "maleWorkers": "599",
        "maleMedian": "972",
        "femaleWorkers": "131",
        "femaleMedian": "723"
      }
    }
  },
  "Aircraft structure, surfaces, rigging, and systems assemblers": {
    "ageData": {
      "2016": {
        "total": "5",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "1",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Aircraft structure, surfaces, rigging, and systems assemblers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Electrical, electronics, and electromechanical assemblers": {
    "ageData": {
      "2016": {
        "total": "117",
        "16to19": "4",
        "20to24": "10",
        "25to34": "26",
        "35to44": "15",
        "45to54": "27",
        "55to64": "29",
        "65+": "7",
        "medianAge": "29"
      }
    },
    "title": "Electrical, electronics, and electromechanical assemblers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "117",
        "women": "49.3",
        "white": "14.1",
        "black": "16.9",
        "asian": "22.5"
      }
    },
    "wageData": {
      "2016": {
        "workers": "105",
        "median": "590",
        "maleWorkers": "55",
        "maleMedian": "618",
        "femaleWorkers": "50",
        "femaleMedian": "554"
      }
    }
  },
  "Engine and other machine assemblers": {
    "ageData": {
      "2016": {
        "total": "9",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "4",
        "45to54": "0",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Engine and other machine assemblers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "9",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Structural metal fabricators and fitters": {
    "ageData": {
      "2016": {
        "total": "22",
        "16to19": "0",
        "20to24": "1",
        "25to34": "5",
        "35to44": "3",
        "45to54": "7",
        "55to64": "5",
        "65+": "1",
        "medianAge": "5"
      }
    },
    "title": "Structural metal fabricators and fitters",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "22",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "22",
        "median": "-",
        "maleWorkers": "22",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous assemblers and fabricators": {
    "ageData": {
      "2016": {
        "total": "1,082",
        "16to19": "21",
        "20to24": "128",
        "25to34": "256",
        "35to44": "220",
        "45to54": "239",
        "55to64": "179",
        "65+": "38",
        "medianAge": "179"
      }
    },
    "title": "Miscellaneous assemblers and fabricators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "1,082",
        "women": "34.4",
        "white": "17.8",
        "black": "7.2",
        "asian": "19.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "990",
        "median": "601",
        "maleWorkers": "645",
        "maleMedian": "625",
        "femaleWorkers": "345",
        "femaleMedian": "559"
      }
    }
  },
  "Bakers": {
    "ageData": {
      "2016": {
        "total": "224",
        "16to19": "11",
        "20to24": "28",
        "25to34": "65",
        "35to44": "30",
        "45to54": "43",
        "55to64": "32",
        "65+": "15",
        "medianAge": "32"
      }
    },
    "title": "Bakers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "224",
        "women": "64.8",
        "white": "13.9",
        "black": "7.2",
        "asian": "27.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "138",
        "median": "494",
        "maleWorkers": "55",
        "maleMedian": "562",
        "femaleWorkers": "83",
        "femaleMedian": "480"
      }
    }
  },
  "Butchers and other meat, poultry, and fish processing workers": {
    "ageData": {
      "2016": {
        "total": "277",
        "16to19": "8",
        "20to24": "26",
        "25to34": "77",
        "35to44": "45",
        "45to54": "65",
        "55to64": "40",
        "65+": "15",
        "medianAge": "40"
      }
    },
    "title": "Butchers and other meat, poultry, and fish processing workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "277",
        "women": "26.9",
        "white": "19.8",
        "black": "9.9",
        "asian": "34.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "244",
        "median": "526",
        "maleWorkers": "183",
        "maleMedian": "571",
        "femaleWorkers": "61",
        "femaleMedian": "449"
      }
    }
  },
  "Food and tobacco roasting, baking, and drying machine operators and tenders": {
    "ageData": {
      "2016": {
        "total": "24",
        "16to19": "0",
        "20to24": "4",
        "25to34": "3",
        "35to44": "3",
        "45to54": "9",
        "55to64": "5",
        "65+": "0",
        "medianAge": "5"
      }
    },
    "title": "Food and tobacco roasting, baking, and drying machine operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "24",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "16",
        "median": "-",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Food batchmakers": {
    "ageData": {
      "2016": {
        "total": "107",
        "16to19": "7",
        "20to24": "16",
        "25to34": "31",
        "35to44": "21",
        "45to54": "18",
        "55to64": "13",
        "65+": "3",
        "medianAge": "13"
      }
    },
    "title": "Food batchmakers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "107",
        "women": "58.7",
        "white": "14.2",
        "black": "3.1",
        "asian": "30.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "81",
        "median": "537",
        "maleWorkers": "40",
        "maleMedian": "-",
        "femaleWorkers": "42",
        "femaleMedian": "-"
      }
    }
  },
  "Food cooking machine operators and tenders": {
    "ageData": {
      "2016": {
        "total": "10",
        "16to19": "1",
        "20to24": "2",
        "25to34": "2",
        "35to44": "2",
        "45to54": "1",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Food cooking machine operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "10",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Food processing workers, all other": {
    "ageData": {
      "2016": {
        "total": "138",
        "16to19": "4",
        "20to24": "11",
        "25to34": "26",
        "35to44": "32",
        "45to54": "33",
        "55to64": "27",
        "65+": "5",
        "medianAge": "27"
      }
    },
    "title": "Food processing workers, all other",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "138",
        "women": "37.4",
        "white": "23.3",
        "black": "7.4",
        "asian": "35.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "129",
        "median": "547",
        "maleWorkers": "88",
        "maleMedian": "579",
        "femaleWorkers": "41",
        "femaleMedian": "-"
      }
    }
  },
  "Computer control programmers and operators": {
    "ageData": {
      "2016": {
        "total": "87",
        "16to19": "0",
        "20to24": "5",
        "25to34": "22",
        "35to44": "22",
        "45to54": "22",
        "55to64": "14",
        "65+": "2",
        "medianAge": "14"
      }
    },
    "title": "Computer control programmers and operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "87",
        "women": "5.2",
        "white": "6.3",
        "black": "4.3",
        "asian": "12.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "85",
        "median": "802",
        "maleWorkers": "80",
        "maleMedian": "843",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Extruding and drawing machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2016": {
        "total": "13",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "2",
        "45to54": "6",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Extruding and drawing machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "13",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Forging machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2016": {
        "total": "5",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "1",
        "45to54": "2",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Forging machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Rolling machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2016": {
        "total": "13",
        "16to19": "0",
        "20to24": "1",
        "25to34": "5",
        "35to44": "4",
        "45to54": "2",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Rolling machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "13",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Cutting, punching, and press machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2016": {
        "total": "83",
        "16to19": "0",
        "20to24": "10",
        "25to34": "23",
        "35to44": "15",
        "45to54": "15",
        "55to64": "17",
        "65+": "4",
        "medianAge": "17"
      }
    },
    "title": "Cutting, punching, and press machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "83",
        "women": "18.3",
        "white": "5.8",
        "black": "0.6",
        "asian": "22.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "86",
        "median": "639",
        "maleWorkers": "68",
        "maleMedian": "672",
        "femaleWorkers": "18",
        "femaleMedian": "-"
      }
    }
  },
  "Drilling and boring machine tool setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2016": {
        "total": "8",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "1",
        "45to54": "2",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Drilling and boring machine tool setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "8",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Grinding, lapping, polishing, and buffing machine tool setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2016": {
        "total": "48",
        "16to19": "1",
        "20to24": "2",
        "25to34": "6",
        "35to44": "16",
        "45to54": "10",
        "55to64": "12",
        "65+": "0",
        "medianAge": "12"
      }
    },
    "title": "Grinding, lapping, polishing, and buffing machine tool setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "48",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "45",
        "median": "-",
        "maleWorkers": "42",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Lathe and turning machine tool setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2016": {
        "total": "14",
        "16to19": "1",
        "20to24": "0",
        "25to34": "4",
        "35to44": "2",
        "45to54": "3",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Lathe and turning machine tool setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "14",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Milling and planing machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2016": {
        "total": "6",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "2",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Milling and planing machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "6",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Machinists": {
    "ageData": {
      "2016": {
        "total": "356",
        "16to19": "3",
        "20to24": "25",
        "25to34": "66",
        "35to44": "60",
        "45to54": "96",
        "55to64": "94",
        "65+": "12",
        "medianAge": "94"
      }
    },
    "title": "Machinists",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "356",
        "women": "3.4",
        "white": "5.4",
        "black": "6.8",
        "asian": "13.0"
      }
    },
    "wageData": {
      "2016": {
        "workers": "326",
        "median": "824",
        "maleWorkers": "313",
        "maleMedian": "844",
        "femaleWorkers": "13",
        "femaleMedian": "-"
      }
    }
  },
  "Metal furnace operators, tenders, pourers, and casters": {
    "ageData": {
      "2016": {
        "total": "31",
        "16to19": "0",
        "20to24": "1",
        "25to34": "9",
        "35to44": "6",
        "45to54": "7",
        "55to64": "6",
        "65+": "2",
        "medianAge": "6"
      }
    },
    "title": "Metal furnace operators, tenders, pourers, and casters",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "31",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "31",
        "median": "-",
        "maleWorkers": "30",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Model makers and patternmakers, metal and plastic": {
    "ageData": {
      "2016": {
        "total": "4",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "3",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Model makers and patternmakers, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Molders and molding machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2016": {
        "total": "33",
        "16to19": "1",
        "20to24": "5",
        "25to34": "7",
        "35to44": "6",
        "45to54": "8",
        "55to64": "4",
        "65+": "1",
        "medianAge": "4"
      }
    },
    "title": "Molders and molding machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "33",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "28",
        "median": "-",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Multiple machine tool setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2016": {
        "total": "3",
        "16to19": "0",
        "20to24": "2",
        "25to34": "0",
        "35to44": "0",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Multiple machine tool setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Tool and die makers": {
    "ageData": {
      "2016": {
        "total": "50",
        "16to19": "1",
        "20to24": "0",
        "25to34": "9",
        "35to44": "7",
        "45to54": "13",
        "55to64": "16",
        "65+": "5",
        "medianAge": "16"
      }
    },
    "title": "Tool and die makers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "50",
        "women": "3.0",
        "white": "2.4",
        "black": "0.0",
        "asian": "12.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "43",
        "median": "-",
        "maleWorkers": "43",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Welding, soldering, and brazing workers": {
    "ageData": {
      "2016": {
        "total": "603",
        "16to19": "10",
        "20to24": "76",
        "25to34": "144",
        "35to44": "148",
        "45to54": "131",
        "55to64": "84",
        "65+": "9",
        "medianAge": "84"
      }
    },
    "title": "Welding, soldering, and brazing workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "603",
        "women": "4.1",
        "white": "10.0",
        "black": "2.8",
        "asian": "23.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "558",
        "median": "743",
        "maleWorkers": "535",
        "maleMedian": "753",
        "femaleWorkers": "23",
        "femaleMedian": "-"
      }
    }
  },
  "Heat treating equipment setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2016": {
        "total": "3",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "0",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Heat treating equipment setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Layout workers, metal and plastic": {
    "ageData": {
      "2016": {
        "total": "7",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "2",
        "45to54": "1",
        "55to64": "0",
        "65+": "1",
        "medianAge": "0"
      }
    },
    "title": "Layout workers, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Plating and coating machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2016": {
        "total": "20",
        "16to19": "0",
        "20to24": "2",
        "25to34": "5",
        "35to44": "2",
        "45to54": "7",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Plating and coating machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "20",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "21",
        "median": "-",
        "maleWorkers": "20",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Tool grinders, filers, and sharpeners": {
    "ageData": {
      "2016": {
        "total": "5",
        "16to19": "0",
        "20to24": "1",
        "25to34": "3",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Tool grinders, filers, and sharpeners",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Metal workers and plastic workers, all other": {
    "ageData": {
      "2016": {
        "total": "379",
        "16to19": "7",
        "20to24": "43",
        "25to34": "84",
        "35to44": "73",
        "45to54": "92",
        "55to64": "64",
        "65+": "16",
        "medianAge": "64"
      }
    },
    "title": "Metal workers and plastic workers, all other",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "379",
        "women": "20.0",
        "white": "15.3",
        "black": "7.3",
        "asian": "22.1"
      }
    },
    "wageData": {
      "2016": {
        "workers": "380",
        "median": "626",
        "maleWorkers": "306",
        "maleMedian": "658",
        "femaleWorkers": "74",
        "femaleMedian": "565"
      }
    }
  },
  "Prepress technicians and workers": {
    "ageData": {
      "2016": {
        "total": "22",
        "16to19": "0",
        "20to24": "1",
        "25to34": "3",
        "35to44": "7",
        "45to54": "6",
        "55to64": "4",
        "65+": "1",
        "medianAge": "4"
      }
    },
    "title": "Prepress technicians and workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "22",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "15",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Printing press operators": {
    "ageData": {
      "2016": {
        "total": "205",
        "16to19": "6",
        "20to24": "11",
        "25to34": "34",
        "35to44": "50",
        "45to54": "49",
        "55to64": "47",
        "65+": "8",
        "medianAge": "47"
      }
    },
    "title": "Printing press operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "205",
        "women": "20.3",
        "white": "6.7",
        "black": "4.7",
        "asian": "15.9"
      }
    },
    "wageData": {
      "2016": {
        "workers": "170",
        "median": "701",
        "maleWorkers": "137",
        "maleMedian": "748",
        "femaleWorkers": "33",
        "femaleMedian": "-"
      }
    }
  },
  "Print binding and finishing workers": {
    "ageData": {
      "2016": {
        "total": "14",
        "16to19": "0",
        "20to24": "4",
        "25to34": "1",
        "35to44": "2",
        "45to54": "2",
        "55to64": "4",
        "65+": "2",
        "medianAge": "4"
      }
    },
    "title": "Print binding and finishing workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "14",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "11",
        "median": "-",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Laundry and dry-cleaning workers": {
    "ageData": {
      "2016": {
        "total": "188",
        "16to19": "2",
        "20to24": "9",
        "25to34": "26",
        "35to44": "41",
        "45to54": "53",
        "55to64": "38",
        "65+": "18",
        "medianAge": "38"
      }
    },
    "title": "Laundry and dry-cleaning workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "188",
        "women": "58.1",
        "white": "21.4",
        "black": "7.9",
        "asian": "34.0"
      }
    },
    "wageData": {
      "2016": {
        "workers": "135",
        "median": "454",
        "maleWorkers": "55",
        "maleMedian": "486",
        "femaleWorkers": "80",
        "femaleMedian": "436"
      }
    }
  },
  "Pressers, textile, garment, and related materials": {
    "ageData": {
      "2016": {
        "total": "36",
        "16to19": "0",
        "20to24": "3",
        "25to34": "5",
        "35to44": "8",
        "45to54": "12",
        "55to64": "4",
        "65+": "2",
        "medianAge": "4"
      }
    },
    "title": "Pressers, textile, garment, and related materials",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "36",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "30",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "20",
        "femaleMedian": "-"
      }
    }
  },
  "Sewing machine operators": {
    "ageData": {
      "2016": {
        "total": "208",
        "16to19": "4",
        "20to24": "11",
        "25to34": "35",
        "35to44": "50",
        "45to54": "54",
        "55to64": "49",
        "65+": "5",
        "medianAge": "49"
      }
    },
    "title": "Sewing machine operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "208",
        "women": "71.5",
        "white": "8.0",
        "black": "12.1",
        "asian": "46.9"
      }
    },
    "wageData": {
      "2016": {
        "workers": "165",
        "median": "437",
        "maleWorkers": "52",
        "maleMedian": "407",
        "femaleWorkers": "114",
        "femaleMedian": "452"
      }
    }
  },
  "Shoe and leather workers and repairers": {
    "ageData": {
      "2016": {
        "total": "8",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "2",
        "45to54": "0",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Shoe and leather workers and repairers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "8",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Shoe machine operators and tenders": {
    "ageData": {
      "2016": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Shoe machine operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Tailors, dressmakers, and sewers": {
    "ageData": {
      "2016": {
        "total": "79",
        "16to19": "1",
        "20to24": "3",
        "25to34": "7",
        "35to44": "12",
        "45to54": "12",
        "55to64": "21",
        "65+": "23",
        "medianAge": "21"
      }
    },
    "title": "Tailors, dressmakers, and sewers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "79",
        "women": "79.7",
        "white": "7.8",
        "black": "22.6",
        "asian": "19.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "32",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "25",
        "femaleMedian": "-"
      }
    }
  },
  "Textile bleaching and dyeing machine operators and tenders": {
    "ageData": {
      "2016": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Textile bleaching and dyeing machine operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "0",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Textile cutting machine setters, operators, and tenders": {
    "ageData": {
      "2016": {
        "total": "9",
        "16to19": "0",
        "20to24": "1",
        "25to34": "0",
        "35to44": "2",
        "45to54": "3",
        "55to64": "1",
        "65+": "2",
        "medianAge": "1"
      }
    },
    "title": "Textile cutting machine setters, operators, and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "9",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Textile knitting and weaving machine setters, operators, and tenders": {
    "ageData": {
      "2016": {
        "total": "13",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "3",
        "45to54": "3",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Textile knitting and weaving machine setters, operators, and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "13",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "Textile winding, twisting, and drawing out machine setters, operators, and tenders": {
    "ageData": {
      "2016": {
        "total": "11",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "3",
        "45to54": "3",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Textile winding, twisting, and drawing out machine setters, operators, and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "11",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Extruding and forming machine setters, operators, and tenders, synthetic and glass fibers": {
    "ageData": {
      "2016": {
        "total": "0",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Extruding and forming machine setters, operators, and tenders, synthetic and glass fibers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "0",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "0",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Fabric and apparel patternmakers": {
    "ageData": {
      "2016": {
        "total": "1",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Fabric and apparel patternmakers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Upholsterers": {
    "ageData": {
      "2016": {
        "total": "36",
        "16to19": "0",
        "20to24": "5",
        "25to34": "5",
        "35to44": "7",
        "45to54": "10",
        "55to64": "8",
        "65+": "2",
        "medianAge": "8"
      }
    },
    "title": "Upholsterers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "36",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "20",
        "median": "-",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Textile, apparel, and furnishings workers, all other": {
    "ageData": {
      "2016": {
        "total": "20",
        "16to19": "2",
        "20to24": "3",
        "25to34": "4",
        "35to44": "4",
        "45to54": "3",
        "55to64": "4",
        "65+": "1",
        "medianAge": "4"
      }
    },
    "title": "Textile, apparel, and furnishings workers, all other",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "20",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Cabinetmakers and bench carpenters": {
    "ageData": {
      "2016": {
        "total": "56",
        "16to19": "1",
        "20to24": "8",
        "25to34": "9",
        "35to44": "5",
        "45to54": "12",
        "55to64": "15",
        "65+": "6",
        "medianAge": "15"
      }
    },
    "title": "Cabinetmakers and bench carpenters",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "56",
        "women": "11.9",
        "white": "6.7",
        "black": "0.0",
        "asian": "15.7"
      }
    },
    "wageData": {
      "2016": {
        "workers": "35",
        "median": "-",
        "maleWorkers": "30",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Furniture finishers": {
    "ageData": {
      "2016": {
        "total": "19",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "6",
        "45to54": "2",
        "55to64": "6",
        "65+": "3",
        "medianAge": "6"
      }
    },
    "title": "Furniture finishers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "19",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "9",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Model makers and patternmakers, wood": {
    "ageData": {
      "2016": {
        "total": "0",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Model makers and patternmakers, wood",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "0",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "0",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Sawing machine setters, operators, and tenders, wood": {
    "ageData": {
      "2016": {
        "total": "35",
        "16to19": "1",
        "20to24": "2",
        "25to34": "13",
        "35to44": "5",
        "45to54": "5",
        "55to64": "9",
        "65+": "1",
        "medianAge": "9"
      }
    },
    "title": "Sawing machine setters, operators, and tenders, wood",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "35",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "31",
        "median": "-",
        "maleWorkers": "27",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Woodworking machine setters, operators, and tenders, except sawing": {
    "ageData": {
      "2016": {
        "total": "23",
        "16to19": "0",
        "20to24": "2",
        "25to34": "7",
        "35to44": "5",
        "45to54": "3",
        "55to64": "4",
        "65+": "1",
        "medianAge": "4"
      }
    },
    "title": "Woodworking machine setters, operators, and tenders, except sawing",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "23",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "23",
        "median": "-",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Woodworkers, all other": {
    "ageData": {
      "2016": {
        "total": "34",
        "16to19": "0",
        "20to24": "2",
        "25to34": "8",
        "35to44": "5",
        "45to54": "5",
        "55to64": "11",
        "65+": "3",
        "medianAge": "11"
      }
    },
    "title": "Woodworkers, all other",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "34",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "17",
        "median": "-",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Power plant operators, distributors, and dispatchers": {
    "ageData": {
      "2016": {
        "total": "44",
        "16to19": "0",
        "20to24": "0",
        "25to34": "9",
        "35to44": "9",
        "45to54": "14",
        "55to64": "11",
        "65+": "1",
        "medianAge": "11"
      }
    },
    "title": "Power plant operators, distributors, and dispatchers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "44",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "39",
        "median": "-",
        "maleWorkers": "37",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Stationary engineers and boiler operators": {
    "ageData": {
      "2016": {
        "total": "78",
        "16to19": "1",
        "20to24": "3",
        "25to34": "11",
        "35to44": "13",
        "45to54": "23",
        "55to64": "23",
        "65+": "4",
        "medianAge": "23"
      }
    },
    "title": "Stationary engineers and boiler operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "78",
        "women": "4.7",
        "white": "9.6",
        "black": "5.0",
        "asian": "14.1"
      }
    },
    "wageData": {
      "2016": {
        "workers": "77",
        "median": "924",
        "maleWorkers": "72",
        "maleMedian": "954",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Water and wastewater treatment plant and system operators": {
    "ageData": {
      "2016": {
        "total": "77",
        "16to19": "0",
        "20to24": "2",
        "25to34": "14",
        "35to44": "20",
        "45to54": "22",
        "55to64": "17",
        "65+": "2",
        "medianAge": "17"
      }
    },
    "title": "Water and wastewater treatment plant and system operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "77",
        "women": "5.2",
        "white": "13.9",
        "black": "2.1",
        "asian": "6.8"
      }
    },
    "wageData": {
      "2016": {
        "workers": "76",
        "median": "883",
        "maleWorkers": "72",
        "maleMedian": "885",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous plant and system operators": {
    "ageData": {
      "2016": {
        "total": "38",
        "16to19": "0",
        "20to24": "3",
        "25to34": "10",
        "35to44": "7",
        "45to54": "13",
        "55to64": "4",
        "65+": "1",
        "medianAge": "4"
      }
    },
    "title": "Miscellaneous plant and system operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "38",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "42",
        "median": "-",
        "maleWorkers": "38",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Chemical processing machine setters, operators, and tenders": {
    "ageData": {
      "2016": {
        "total": "54",
        "16to19": "0",
        "20to24": "3",
        "25to34": "14",
        "35to44": "9",
        "45to54": "18",
        "55to64": "9",
        "65+": "1",
        "medianAge": "9"
      }
    },
    "title": "Chemical processing machine setters, operators, and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "54",
        "women": "10.2",
        "white": "3.7",
        "black": "1.5",
        "asian": "15.8"
      }
    },
    "wageData": {
      "2016": {
        "workers": "50",
        "median": "924",
        "maleWorkers": "46",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Crushing, grinding, polishing, mixing, and blending workers": {
    "ageData": {
      "2016": {
        "total": "94",
        "16to19": "1",
        "20to24": "12",
        "25to34": "21",
        "35to44": "22",
        "45to54": "21",
        "55to64": "14",
        "65+": "4",
        "medianAge": "14"
      }
    },
    "title": "Crushing, grinding, polishing, mixing, and blending workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "94",
        "women": "11.9",
        "white": "12.2",
        "black": "1.5",
        "asian": "35.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "88",
        "median": "643",
        "maleWorkers": "82",
        "maleMedian": "642",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Cutting workers": {
    "ageData": {
      "2016": {
        "total": "67",
        "16to19": "1",
        "20to24": "8",
        "25to34": "17",
        "35to44": "22",
        "45to54": "10",
        "55to64": "7",
        "65+": "2",
        "medianAge": "7"
      }
    },
    "title": "Cutting workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "67",
        "women": "22.3",
        "white": "7.2",
        "black": "4.1",
        "asian": "37.2"
      }
    },
    "wageData": {
      "2016": {
        "workers": "62",
        "median": "576",
        "maleWorkers": "50",
        "maleMedian": "546",
        "femaleWorkers": "13",
        "femaleMedian": "-"
      }
    }
  },
  "Extruding, forming, pressing, and compacting machine setters, operators, and tenders": {
    "ageData": {
      "2016": {
        "total": "29",
        "16to19": "1",
        "20to24": "5",
        "25to34": "4",
        "35to44": "4",
        "45to54": "11",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Extruding, forming, pressing, and compacting machine setters, operators, and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "29",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "24",
        "median": "-",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Furnace, kiln, oven, drier, and kettle operators and tenders": {
    "ageData": {
      "2016": {
        "total": "16",
        "16to19": "0",
        "20to24": "0",
        "25to34": "4",
        "35to44": "2",
        "45to54": "4",
        "55to64": "4",
        "65+": "1",
        "medianAge": "4"
      }
    },
    "title": "Furnace, kiln, oven, drier, and kettle operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "16",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "11",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Inspectors, testers, sorters, samplers, and weighers": {
    "ageData": {
      "2016": {
        "total": "734",
        "16to19": "10",
        "20to24": "55",
        "25to34": "162",
        "35to44": "147",
        "45to54": "170",
        "55to64": "145",
        "65+": "45",
        "medianAge": "145"
      }
    },
    "title": "Inspectors, testers, sorters, samplers, and weighers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "734",
        "women": "37.9",
        "white": "12.8",
        "black": "7.4",
        "asian": "17.7"
      }
    },
    "wageData": {
      "2016": {
        "workers": "686",
        "median": "714",
        "maleWorkers": "445",
        "maleMedian": "834",
        "femaleWorkers": "241",
        "femaleMedian": "584"
      }
    }
  },
  "Jewelers and precious stone and metal workers": {
    "ageData": {
      "2016": {
        "total": "40",
        "16to19": "0",
        "20to24": "3",
        "25to34": "5",
        "35to44": "8",
        "45to54": "7",
        "55to64": "11",
        "65+": "6",
        "medianAge": "11"
      }
    },
    "title": "Jewelers and precious stone and metal workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "40",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "18",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Medical, dental, and ophthalmic laboratory technicians": {
    "ageData": {
      "2016": {
        "total": "85",
        "16to19": "3",
        "20to24": "8",
        "25to34": "17",
        "35to44": "22",
        "45to54": "15",
        "55to64": "13",
        "65+": "7",
        "medianAge": "13"
      }
    },
    "title": "Medical, dental, and ophthalmic laboratory technicians",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "85",
        "women": "51.4",
        "white": "8.4",
        "black": "11.8",
        "asian": "11.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "64",
        "median": "615",
        "maleWorkers": "33",
        "maleMedian": "-",
        "femaleWorkers": "31",
        "femaleMedian": "-"
      }
    }
  },
  "Packaging and filling machine operators and tenders": {
    "ageData": {
      "2016": {
        "total": "236",
        "16to19": "11",
        "20to24": "26",
        "25to34": "53",
        "35to44": "43",
        "45to54": "61",
        "55to64": "36",
        "65+": "6",
        "medianAge": "36"
      }
    },
    "title": "Packaging and filling machine operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "236",
        "women": "50.5",
        "white": "16.1",
        "black": "6.2",
        "asian": "43.1"
      }
    },
    "wageData": {
      "2016": {
        "workers": "224",
        "median": "488",
        "maleWorkers": "112",
        "maleMedian": "574",
        "femaleWorkers": "113",
        "femaleMedian": "425"
      }
    }
  },
  "Painting workers": {
    "ageData": {
      "2016": {
        "total": "173",
        "16to19": "5",
        "20to24": "18",
        "25to34": "47",
        "35to44": "42",
        "45to54": "38",
        "55to64": "19",
        "65+": "4",
        "medianAge": "19"
      }
    },
    "title": "Painting workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "173",
        "women": "8.9",
        "white": "8.8",
        "black": "1.8",
        "asian": "28.4"
      }
    },
    "wageData": {
      "2016": {
        "workers": "158",
        "median": "701",
        "maleWorkers": "147",
        "maleMedian": "713",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "Photographic process workers and processing machine operators": {
    "ageData": {
      "2016": {
        "total": "29",
        "16to19": "2",
        "20to24": "6",
        "25to34": "6",
        "35to44": "5",
        "45to54": "3",
        "55to64": "5",
        "65+": "2",
        "medianAge": "5"
      }
    },
    "title": "Photographic process workers and processing machine operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "29",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "23",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Semiconductor processors": {
    "ageData": {
      "2016": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Semiconductor processors",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Adhesive bonding machine operators and tenders": {
    "ageData": {
      "2016": {
        "total": "11",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "1",
        "45to54": "3",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Adhesive bonding machine operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "11",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Cleaning, washing, and metal pickling equipment operators and tenders": {
    "ageData": {
      "2016": {
        "total": "3",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "1",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Cleaning, washing, and metal pickling equipment operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Cooling and freezing equipment operators and tenders": {
    "ageData": {
      "2016": {
        "total": "0",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Cooling and freezing equipment operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "0",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "0",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Etchers and engravers": {
    "ageData": {
      "2016": {
        "total": "6",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "0",
        "45to54": "0",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Etchers and engravers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "6",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Molders, shapers, and casters, except metal and plastic": {
    "ageData": {
      "2016": {
        "total": "24",
        "16to19": "0",
        "20to24": "2",
        "25to34": "9",
        "35to44": "7",
        "45to54": "2",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Molders, shapers, and casters, except metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "24",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "16",
        "median": "-",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Paper goods machine setters, operators, and tenders": {
    "ageData": {
      "2016": {
        "total": "33",
        "16to19": "0",
        "20to24": "3",
        "25to34": "6",
        "35to44": "5",
        "45to54": "13",
        "55to64": "5",
        "65+": "2",
        "medianAge": "5"
      }
    },
    "title": "Paper goods machine setters, operators, and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "33",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "27",
        "median": "-",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Tire builders": {
    "ageData": {
      "2016": {
        "total": "10",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "4",
        "45to54": "3",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Tire builders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "10",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "11",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Helpers--production workers": {
    "ageData": {
      "2016": {
        "total": "40",
        "16to19": "6",
        "20to24": "12",
        "25to34": "12",
        "35to44": "3",
        "45to54": "4",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Helpers--production workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "40",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "25",
        "median": "-",
        "maleWorkers": "23",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Production workers, all other": {
    "ageData": {
      "2016": {
        "total": "968",
        "16to19": "19",
        "20to24": "98",
        "25to34": "227",
        "35to44": "209",
        "45to54": "223",
        "55to64": "162",
        "65+": "31",
        "medianAge": "162"
      }
    },
    "title": "Production workers, all other",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "968",
        "women": "29.4",
        "white": "17.1",
        "black": "5.4",
        "asian": "22.1"
      }
    },
    "wageData": {
      "2016": {
        "workers": "887",
        "median": "622",
        "maleWorkers": "641",
        "maleMedian": "690",
        "femaleWorkers": "246",
        "femaleMedian": "515"
      }
    }
  },
  "Transportation and material moving occupations": {
    "ageData": {
      "2016": {
        "total": "9,284",
        "16to19": "339",
        "20to24": "928",
        "25to34": "1,829",
        "35to44": "1,812",
        "45to54": "2,113",
        "55to64": "1,690",
        "65+": "574",
        "medianAge": "1,690"
      }
    },
    "title": "Transportation and material moving occupations",
    "depth": 2,
    "parent": "Production, transportation, and material moving occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "9,284",
        "women": "16.5",
        "white": "18.4",
        "black": "3.8",
        "asian": "22.1"
      }
    },
    "wageData": {
      "2016": {
        "workers": "7,093",
        "median": "662",
        "maleWorkers": "6,097",
        "maleMedian": "685",
        "femaleWorkers": "997",
        "femaleMedian": "540"
      }
    }
  },
  "Supervisors of transportation and material moving workers": {
    "ageData": {
      "2016": {
        "total": "190",
        "16to19": "0",
        "20to24": "8",
        "25to34": "42",
        "35to44": "53",
        "45to54": "47",
        "55to64": "31",
        "65+": "10",
        "medianAge": "31"
      }
    },
    "title": "Supervisors of transportation and material moving workers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "190",
        "women": "21.4",
        "white": "13.4",
        "black": "3.2",
        "asian": "21.2"
      }
    },
    "wageData": {
      "2016": {
        "workers": "165",
        "median": "807",
        "maleWorkers": "126",
        "maleMedian": "843",
        "femaleWorkers": "39",
        "femaleMedian": "-"
      }
    }
  },
  "Aircraft pilots and flight engineers": {
    "ageData": {
      "2016": {
        "total": "141",
        "16to19": "0",
        "20to24": "1",
        "25to34": "23",
        "35to44": "32",
        "45to54": "52",
        "55to64": "27",
        "65+": "6",
        "medianAge": "27"
      }
    },
    "title": "Aircraft pilots and flight engineers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "141",
        "women": "5.2",
        "white": "3.0",
        "black": "0.5",
        "asian": "7.7"
      }
    },
    "wageData": {
      "2016": {
        "workers": "119",
        "median": "1,582",
        "maleWorkers": "112",
        "maleMedian": "1,600",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Air traffic controllers and airfield operations specialists": {
    "ageData": {
      "2016": {
        "total": "26",
        "16to19": "1",
        "20to24": "1",
        "25to34": "9",
        "35to44": "5",
        "45to54": "4",
        "55to64": "4",
        "65+": "2",
        "medianAge": "4"
      }
    },
    "title": "Air traffic controllers and airfield operations specialists",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "26",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "28",
        "median": "-",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Flight attendants": {
    "ageData": {
      "2016": {
        "total": "93",
        "16to19": "1",
        "20to24": "4",
        "25to34": "18",
        "35to44": "13",
        "45to54": "34",
        "55to64": "19",
        "65+": "5",
        "medianAge": "19"
      }
    },
    "title": "Flight attendants",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "93",
        "women": "75.8",
        "white": "13.7",
        "black": "6.4",
        "asian": "16.1"
      }
    },
    "wageData": {
      "2016": {
        "workers": "68",
        "median": "873",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "49",
        "femaleMedian": "-"
      }
    }
  },
  "Ambulance drivers and attendants, except emergency medical technicians": {
    "ageData": {
      "2016": {
        "total": "19",
        "16to19": "0",
        "20to24": "2",
        "25to34": "4",
        "35to44": "1",
        "45to54": "2",
        "55to64": "7",
        "65+": "2",
        "medianAge": "7"
      }
    },
    "title": "Ambulance drivers and attendants, except emergency medical technicians",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "19",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "15",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Bus drivers": {
    "ageData": {
      "2016": {
        "total": "553",
        "16to19": "2",
        "20to24": "7",
        "25to34": "54",
        "35to44": "99",
        "45to54": "156",
        "55to64": "152",
        "65+": "82",
        "medianAge": "152"
      }
    },
    "title": "Bus drivers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "553",
        "women": "48.7",
        "white": "29.4",
        "black": "3.3",
        "asian": "11.8"
      }
    },
    "wageData": {
      "2016": {
        "workers": "343",
        "median": "641",
        "maleWorkers": "197",
        "maleMedian": "691",
        "femaleWorkers": "146",
        "femaleMedian": "589"
      }
    }
  },
  "Driver/sales workers and truck drivers": {
    "ageData": {
      "2016": {
        "total": "3,542",
        "16to19": "46",
        "20to24": "227",
        "25to34": "611",
        "35to44": "755",
        "45to54": "920",
        "55to64": "739",
        "65+": "245",
        "medianAge": "739"
      }
    },
    "title": "Driver/sales workers and truck drivers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "3,542",
        "women": "6.0",
        "white": "14.8",
        "black": "2.6",
        "asian": "21.3"
      }
    },
    "wageData": {
      "2016": {
        "workers": "2,809",
        "median": "780",
        "maleWorkers": "2,689",
        "maleMedian": "787",
        "femaleWorkers": "120",
        "femaleMedian": "630"
      }
    }
  },
  "Taxi drivers and chauffeurs": {
    "ageData": {
      "2016": {
        "total": "500",
        "16to19": "3",
        "20to24": "24",
        "25to34": "88",
        "35to44": "100",
        "45to54": "112",
        "55to64": "112",
        "65+": "60",
        "medianAge": "112"
      }
    },
    "title": "Taxi drivers and chauffeurs",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "500",
        "women": "14.3",
        "white": "28.7",
        "black": "16.0",
        "asian": "12.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "261",
        "median": "580",
        "maleWorkers": "222",
        "maleMedian": "595",
        "femaleWorkers": "38",
        "femaleMedian": "-"
      }
    }
  },
  "Motor vehicle operators, all other": {
    "ageData": {
      "2016": {
        "total": "63",
        "16to19": "1",
        "20to24": "5",
        "25to34": "10",
        "35to44": "6",
        "45to54": "10",
        "55to64": "16",
        "65+": "17",
        "medianAge": "16"
      }
    },
    "title": "Motor vehicle operators, all other",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "63",
        "women": "8.8",
        "white": "13.7",
        "black": "2.3",
        "asian": "14.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "25",
        "median": "-",
        "maleWorkers": "23",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Locomotive engineers and operators": {
    "ageData": {
      "2016": {
        "total": "52",
        "16to19": "0",
        "20to24": "0",
        "25to34": "10",
        "35to44": "15",
        "45to54": "15",
        "55to64": "11",
        "65+": "1",
        "medianAge": "11"
      }
    },
    "title": "Locomotive engineers and operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "52",
        "women": "3.2",
        "white": "20.8",
        "black": "0.6",
        "asian": "7.8"
      }
    },
    "wageData": {
      "2016": {
        "workers": "45",
        "median": "-",
        "maleWorkers": "45",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Railroad brake, signal, and switch operators": {
    "ageData": {
      "2016": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Railroad brake, signal, and switch operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Railroad conductors and yardmasters": {
    "ageData": {
      "2016": {
        "total": "55",
        "16to19": "2",
        "20to24": "3",
        "25to34": "9",
        "35to44": "18",
        "45to54": "16",
        "55to64": "5",
        "65+": "1",
        "medianAge": "5"
      }
    },
    "title": "Railroad conductors and yardmasters",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "55",
        "women": "4.7",
        "white": "21.3",
        "black": "4.3",
        "asian": "6.0"
      }
    },
    "wageData": {
      "2016": {
        "workers": "52",
        "median": "1,160",
        "maleWorkers": "51",
        "maleMedian": "1,148",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Subway, streetcar, and other rail transportation workers": {
    "ageData": {
      "2016": {
        "total": "15",
        "16to19": "0",
        "20to24": "2",
        "25to34": "2",
        "35to44": "6",
        "45to54": "4",
        "55to64": "1",
        "65+": "1",
        "medianAge": "1"
      }
    },
    "title": "Subway, streetcar, and other rail transportation workers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "15",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Sailors and marine oilers": {
    "ageData": {
      "2016": {
        "total": "16",
        "16to19": "1",
        "20to24": "2",
        "25to34": "5",
        "35to44": "5",
        "45to54": "2",
        "55to64": "1",
        "65+": "1",
        "medianAge": "1"
      }
    },
    "title": "Sailors and marine oilers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "16",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "15",
        "median": "-",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Ship and boat captains and operators": {
    "ageData": {
      "2016": {
        "total": "31",
        "16to19": "1",
        "20to24": "2",
        "25to34": "5",
        "35to44": "10",
        "45to54": "3",
        "55to64": "7",
        "65+": "2",
        "medianAge": "7"
      }
    },
    "title": "Ship and boat captains and operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "31",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "28",
        "median": "-",
        "maleWorkers": "26",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Ship engineers": {
    "ageData": {
      "2016": {
        "total": "4",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "1",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Ship engineers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Bridge and lock tenders": {
    "ageData": {
      "2016": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "1",
        "45to54": "0",
        "55to64": "1",
        "65+": "1",
        "medianAge": "1"
      }
    },
    "title": "Bridge and lock tenders",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Parking lot attendants": {
    "ageData": {
      "2016": {
        "total": "89",
        "16to19": "6",
        "20to24": "24",
        "25to34": "13",
        "35to44": "12",
        "45to54": "13",
        "55to64": "14",
        "65+": "7",
        "medianAge": "14"
      }
    },
    "title": "Parking lot attendants",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "89",
        "women": "10.7",
        "white": "24.3",
        "black": "7.3",
        "asian": "28.5"
      }
    },
    "wageData": {
      "2016": {
        "workers": "67",
        "median": "519",
        "maleWorkers": "62",
        "maleMedian": "513",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Automotive and watercraft service attendants": {
    "ageData": {
      "2016": {
        "total": "97",
        "16to19": "11",
        "20to24": "27",
        "25to34": "21",
        "35to44": "9",
        "45to54": "9",
        "55to64": "13",
        "65+": "6",
        "medianAge": "13"
      }
    },
    "title": "Automotive and watercraft service attendants",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "97",
        "women": "8.6",
        "white": "9.9",
        "black": "6.4",
        "asian": "18.1"
      }
    },
    "wageData": {
      "2016": {
        "workers": "73",
        "median": "462",
        "maleWorkers": "67",
        "maleMedian": "456",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Transportation inspectors": {
    "ageData": {
      "2016": {
        "total": "26",
        "16to19": "0",
        "20to24": "2",
        "25to34": "4",
        "35to44": "3",
        "45to54": "8",
        "55to64": "9",
        "65+": "1",
        "medianAge": "9"
      }
    },
    "title": "Transportation inspectors",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "26",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "25",
        "median": "-",
        "maleWorkers": "20",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Transportation attendants, except flight attendants": {
    "ageData": {
      "2016": {
        "total": "41",
        "16to19": "1",
        "20to24": "4",
        "25to34": "7",
        "35to44": "8",
        "45to54": "10",
        "55to64": "6",
        "65+": "6",
        "medianAge": "6"
      }
    },
    "title": "Transportation attendants, except flight attendants",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "41",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "20",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Other transportation workers": {
    "ageData": {
      "2016": {
        "total": "34",
        "16to19": "1",
        "20to24": "1",
        "25to34": "10",
        "35to44": "7",
        "45to54": "8",
        "55to64": "7",
        "65+": "1",
        "medianAge": "7"
      }
    },
    "title": "Other transportation workers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "34",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "31",
        "median": "-",
        "maleWorkers": "24",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Conveyor operators and tenders": {
    "ageData": {
      "2016": {
        "total": "3",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "1",
        "45to54": "0",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Conveyor operators and tenders",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Crane and tower operators": {
    "ageData": {
      "2016": {
        "total": "63",
        "16to19": "0",
        "20to24": "1",
        "25to34": "15",
        "35to44": "12",
        "45to54": "23",
        "55to64": "8",
        "65+": "3",
        "medianAge": "8"
      }
    },
    "title": "Crane and tower operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "63",
        "women": "0.8",
        "white": "13.0",
        "black": "0.5",
        "asian": "13.7"
      }
    },
    "wageData": {
      "2016": {
        "workers": "66",
        "median": "909",
        "maleWorkers": "66",
        "maleMedian": "909",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Dredge, excavating, and loading machine operators": {
    "ageData": {
      "2016": {
        "total": "37",
        "16to19": "0",
        "20to24": "1",
        "25to34": "7",
        "35to44": "9",
        "45to54": "9",
        "55to64": "6",
        "65+": "4",
        "medianAge": "6"
      }
    },
    "title": "Dredge, excavating, and loading machine operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "37",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "27",
        "median": "-",
        "maleWorkers": "27",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Hoist and winch operators": {
    "ageData": {
      "2016": {
        "total": "9",
        "16to19": "1",
        "20to24": "1",
        "25to34": "2",
        "35to44": "1",
        "45to54": "1",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Hoist and winch operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "9",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "11",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Industrial truck and tractor operators": {
    "ageData": {
      "2016": {
        "total": "605",
        "16to19": "11",
        "20to24": "57",
        "25to34": "174",
        "35to44": "117",
        "45to54": "129",
        "55to64": "103",
        "65+": "14",
        "medianAge": "103"
      }
    },
    "title": "Industrial truck and tractor operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "605",
        "women": "9.6",
        "white": "26.0",
        "black": "2.3",
        "asian": "28.0"
      }
    },
    "wageData": {
      "2016": {
        "workers": "564",
        "median": "602",
        "maleWorkers": "510",
        "maleMedian": "604",
        "femaleWorkers": "54",
        "femaleMedian": "563"
      }
    }
  },
  "Cleaners of vehicles and equipment": {
    "ageData": {
      "2016": {
        "total": "328",
        "16to19": "37",
        "20to24": "59",
        "25to34": "76",
        "35to44": "59",
        "45to54": "51",
        "55to64": "36",
        "65+": "9",
        "medianAge": "36"
      }
    },
    "title": "Cleaners of vehicles and equipment",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "328",
        "women": "13.0",
        "white": "18.0",
        "black": "2.5",
        "asian": "29.4"
      }
    },
    "wageData": {
      "2016": {
        "workers": "252",
        "median": "491",
        "maleWorkers": "224",
        "maleMedian": "487",
        "femaleWorkers": "28",
        "femaleMedian": "-"
      }
    }
  },
  "Laborers and freight, stock, and material movers, hand": {
    "ageData": {
      "2016": {
        "total": "1,900",
        "16to19": "138",
        "20to24": "357",
        "25to34": "449",
        "35to44": "305",
        "45to54": "331",
        "55to64": "261",
        "65+": "59",
        "medianAge": "261"
      }
    },
    "title": "Laborers and freight, stock, and material movers, hand",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "1,900",
        "women": "18.1",
        "white": "19.9",
        "black": "3.2",
        "asian": "23.4"
      }
    },
    "wageData": {
      "2016": {
        "workers": "1,417",
        "median": "566",
        "maleWorkers": "1,196",
        "maleMedian": "580",
        "femaleWorkers": "221",
        "femaleMedian": "509"
      }
    }
  },
  "Machine feeders and offbearers": {
    "ageData": {
      "2016": {
        "total": "30",
        "16to19": "0",
        "20to24": "4",
        "25to34": "4",
        "35to44": "8",
        "45to54": "5",
        "55to64": "7",
        "65+": "1",
        "medianAge": "7"
      }
    },
    "title": "Machine feeders and offbearers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "30",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "27",
        "median": "-",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "13",
        "femaleMedian": "-"
      }
    }
  },
  "Packers and packagers, hand": {
    "ageData": {
      "2016": {
        "total": "550",
        "16to19": "70",
        "20to24": "85",
        "25to34": "123",
        "35to44": "99",
        "45to54": "99",
        "55to64": "58",
        "65+": "17",
        "medianAge": "58"
      }
    },
    "title": "Packers and packagers, hand",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "550",
        "women": "54.5",
        "white": "18.9",
        "black": "7.1",
        "asian": "43.6"
      }
    },
    "wageData": {
      "2016": {
        "workers": "383",
        "median": "456",
        "maleWorkers": "166",
        "maleMedian": "468",
        "femaleWorkers": "216",
        "femaleMedian": "437"
      }
    }
  },
  "Pumping station operators": {
    "ageData": {
      "2016": {
        "total": "16",
        "16to19": "0",
        "20to24": "0",
        "25to34": "5",
        "35to44": "4",
        "45to54": "2",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Pumping station operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "16",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Refuse and recyclable material collectors": {
    "ageData": {
      "2016": {
        "total": "101",
        "16to19": "2",
        "20to24": "11",
        "25to34": "23",
        "35to44": "20",
        "45to54": "27",
        "55to64": "14",
        "65+": "4",
        "medianAge": "14"
      }
    },
    "title": "Refuse and recyclable material collectors",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "101",
        "women": "8.6",
        "white": "16.4",
        "black": "0.5",
        "asian": "23.5"
      }
    },
    "wageData": {
      "2016": {
        "workers": "68",
        "median": "562",
        "maleWorkers": "64",
        "maleMedian": "584",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Mine shuttle car operators": {
    "ageData": {
      "2016": {
        "total": "0",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Mine shuttle car operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "0",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "0",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Tank car, truck, and ship loaders": {
    "ageData": {
      "2016": {
        "total": "4",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "3",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Tank car, truck, and ship loaders",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Material moving workers, all other": {
    "ageData": {
      "2016": {
        "total": "45",
        "16to19": "1",
        "20to24": "6",
        "25to34": "5",
        "35to44": "14",
        "45to54": "8",
        "55to64": "7",
        "65+": "3",
        "medianAge": "7"
      }
    },
    "title": "Material moving workers, all other",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2016": {
        "total": "45",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2016": {
        "workers": "46",
        "median": "-",
        "maleWorkers": "40",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  }
}