import { random } from '../../utilities/misc.mjs'

let cachedInputs = {}

export function buildMarkovDefinition (inputText) {
  if (!cachedInputs[inputText]) {
    let model = {
      letters: {},
      wordLengths: {},
      numberOfWords: 0
    }

    // for the purpose of name generation, remove symbols
    let normalizedInputText = ' ' + (inputText.toLowerCase().replace(/\W/g, ' ').replace(/\s+/g, ' ').trim())

    let thisWordLength = 0

    normalizedInputText.split('').forEach((letter, index, allLetters) => {
      let nextLetter = (allLetters[index + 1] || ' ')

      // handle word lengths
      if (letter !== ' ') {
        thisWordLength++
      }

      // handle end-of word stuff
      if (nextLetter === ' ' && thisWordLength > 0) {
        if (!model.wordLengths[thisWordLength]) model.wordLengths[thisWordLength] = 0
        model.wordLengths[thisWordLength]++
        model.numberOfWords++
        thisWordLength = 0
      }

      // handle next-letter probability
      // NOTE: "_total" is *NOT* a count of how many `letter` exists in the input string.
      // It is an indicator of how many `nextLetter`s we have found that follow `letter`.
      // This is an important distinction, because it means (for example) that the world
      // `small` will have a `_total` of 1 for the letter `l` and not 2. This is correct.
      // Don't "fix" this.
      if (nextLetter !== ' ') {
        if (!model.letters[letter]) model.letters[letter] = { _total: 0 }
        if (!model.letters[letter][nextLetter]) model.letters[letter][nextLetter] = 0
        model.letters[letter][nextLetter] += 1
        model.letters[letter]._total += 1
      }
    })

    cachedInputs[inputText] = model
  }

  return cachedInputs[inputText]
}

export function markovChain (markovDefinition, baseWord = '', { minLength = 3, maxLength } = {}) {
  const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('')

  let { letters, wordLengths, numberOfWords } = markovDefinition

  // great, so now that we have a chain of likely next characters, make a word out of it
  let word = baseWord
  let wordLength
  let currentLetter = ' '

  if (!maxLength) {
    // determine name length
    // We use the number of times a given word length appears as a weight. For example, if we
    // had 100 four letter words, and 1 five letter word, it's 100x more likely to get a 4 letter
    // word length than 5. We determine the length by determining the total number of words then
    // finding a random number between 0 and that total, then slowly subtracting word lengths
    // until the sum is less than zero.
    let wordLengthIndex = random(numberOfWords)
    Object.entries(wordLengths).sort((a, b) => a[0] - b[0]).find(([length, value]) => {
      wordLengthIndex -= value

      if (wordLengthIndex <= 0) {
        wordLength = Math.max(length, minLength, baseWord.length)
        return true
      }
      return undefined
    })
  } else {
    wordLength = random(maxLength, minLength)
  }

  // run until the internal logic breaks the loop
  while (true) {
    let characterChoice = random(letters[currentLetter]._total)

    if (word.length >= wordLength) break

    for (let index = 0; index < alphabet.length; index++) {
      let aleph = alphabet[index]
      let has = letters[currentLetter][aleph]
      if (has) {
        characterChoice -= has
      }
      if (characterChoice <= 0) {
        word += aleph
        currentLetter = aleph
        break
      }
    }
  }

  return word
}
