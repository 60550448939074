import React from 'react'

import { Page } from '../../utilities/Page'

import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { ConfigProvider, Tabs, theme } from 'antd'

import { css } from '@emotion/css'
const { darkAlgorithm } = theme

export const WriterTools = () => {
  const navigate = useNavigate()
  const loc = useLocation()
  // const page = loc.pathname
  //   .replace('/tabletop-tools/', '')
  //   .replace(/\/.*/, '')

  return (
    <Page expand>
      {/* <Tabs
          className={css`flex-shrink: 0;`} activeKey={page} onChange={(route) => {
            navigate(`/tabletop-tools/${route}`)
          }}
        >
          <Tabs.TabPane tab='Dice Roller' key='dice-roller' />
          <Tabs.TabPane tab='Name Generator' key='name-generator' />
        </Tabs> */}
      <Outlet />
    </Page>
  )
}
