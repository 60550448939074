import React from 'react'
import parse, { attributesToProps, domToReact } from 'html-react-parser'
import _ from 'lodash'
import { css, cx } from '@emotion/css'
import { Page } from '../../utilities/Page'

export const Parser = ({ html, className, ...props }) => {
  return (
    <Page className={cx(className, codeCss)} {...props}>
      {htmlStringToJsx(html)}
    </Page>
  )
}

const codeCss = css`
& {

  *:before,
  *:after {
    color: rgb(90, 155, 230);
  }
  /* generic block-level elements */

  code.language-html,
  code.lang-html {
    display: block;
    white-space: pre;
    overflow: hidden;
    color: rgb(90, 90, 90);
    line-height: 1.2em;
    margin-top: 1.2em;
    margin-bottom: 1.2em;
  }

  /* Generic Inline Elements */
  [data-block-tag] {
    display: block;
  }

  [data-inline-tag],
  [data-self-closing-tag] {
    display: inline;
  }

  [data-block-tag],
  [data-inline-tag],
  [data-self-closing-tag] {
    &[data-inline-tag="header"]>header {
      padding-top: 2.4rem
    }

    [data-self-closing-tag]:before {
      content: "<" attr(data-self-closing-tag) " ";
    }

    [data-self-closing-tag]:after {
      content: " />";
      display: inline-block;
      white-space: pre;
    }

    [data-tag-start]::before {
      content: "<" attr(data-tag-start);
    }

    [data-tag-start]::after {
      content: "> ";
    }

    [data-tag-attr]:before,
    [data-tag-attr]:before {
      color: rgb(110, 192, 56);
      content: ' ' attr(data-tag-attr);
    }

    [data-tag-attr]:after,
    [data-tag-attr]:after {
      color: rgb(198, 198, 198);
      content: '=';
    }

    [data-tag-string] {
      color: orange;
    }

    [data-tag-string]:before,
    [data-tag-string]:after {
      color: orange;
      content: '"';
    }

    [data-tag-string] {
      color: orange;
    }

    [data-tag-string]:before,
    [data-tag-string]:after {
      color: orange;
      content: '"';
    }

    [data-tag-content] {
      display: block;
      padding: 0 2em;
    }

    [data-tag-end]::before {
      content: " </" attr(data-tag-end);
    }

    [data-tag-end]::after {
      content: "> ";
    }
  }

  a {
    display: flex;

    background: transparent;

    &:hover > [data-self-closing-tag="link"] {
        background: #2e5c00;
    }
  }

  .horizontal-rule {
    white-space: nowrap;
    overflow: hidden;
  }

  code {
    font-family: Source Code Pro Woff, Monaco, monospace;
    color: orange;
  }
}
`

export function htmlStringToJsx (string) {
  const options = {
    replace: (domNode) => {
      let { name, children, attribs } = domNode

      // if it doesn't have a name, don't replace it
      if (!name) {
        return
      }

      // yeah, don't do anything with the head tag
      if (name === 'head') {
        return ''
      }

      let ElementToUse = CustomElements[name] || Element

      return <ElementToUse type={name} {...attributesToProps(attribs)}>{domToReact(children, options)}</ElementToUse>
    }
  }

  return parse(
    string.split('\n')
      .map(line => {
        if (line.trim() === '') {
          return '<br>'
        } else {
          return line
        }
      })
      .join('\n'),
    options
  )[1]
}

/*
this is the core of the site.
we take html elements from an index file, turn them into JSX, read that JSX, and turn it into expressive JSX.
example:
  content.html:

  ```
  <header> I'm Nathaniel Hutchins </header>
  ```

  becomes

  ```
  <header-tag data-inline-tag="header">
    <header-start data-tag-start="header"></header-start>
    <header id="im-nathaniel-hutchins">  I'm Nathaniel Hutchins  </header>
    <header-end data-tag-end="header"></header-end>
  </header-tag>
  ```
*/

// some basic defaults

/**
 * Shamelessly stolen from markdown-to-jsx
 *
 * https://github.com/probablyup/markdown-to-jsx/blob/bc2f57412332dc670f066320c0f38d0252e0f057/index.js#L261-L275
 */
function slugify (str = '') {
  return str
    .trim()
    .replace(/[ÀÁÂÃÄÅàáâãäåæÆ]/g, 'a')
    .replace(/[çÇ]/g, 'c')
    .replace(/[ðÐ]/g, 'd')
    .replace(/[ÈÉÊËéèêë]/g, 'e')
    .replace(/[ÏïÎîÍíÌì]/g, 'i')
    .replace(/[Ññ]/g, 'n')
    .replace(/[øØœŒÕõÔôÓóÒò]/g, 'o')
    .replace(/[ÜüÛûÚúÙù]/g, 'u')
    .replace(/[ŸÿÝý]/g, 'y')
    .replace(/[^a-z0-9- ]/gi, '')
    .replace(/ /gi, '-')
    .toLowerCase()
}

const Element = ({ children, type, display = 'block', as, asProps = {}, ...attributes }) => {
  let Tag = `${type}-tag`
  let TagBegin = `${type}-start`
  let AttrKey = `${type}-attrkey`
  let AttrVal = `${type}-attrval`
  let TagAs = as || type
  let TagEnd = `${type}-end`

  if (attributes.htmlFor) {
    attributes.for = attributes.htmlFor
    delete attributes.htmlFor
  }

  if (display === 'inline') {
    if (children) {
      return (
        <Tag data-inline-tag={type}>
          <TagBegin data-tag-start={type}>
            {Object.entries(attributes).map(([key, value]) => (
              <React.Fragment key={key}>
                <AttrKey data-tag-attr={key} />
                <AttrVal data-tag-string>{value}</AttrVal>
              </React.Fragment>
            ))}
          </TagBegin>
          <TagAs {...asProps}> {children} </TagAs>
          <TagEnd data-tag-end={type} />
        </Tag>
      )
    } else {
      return (
        <Tag data-self-closing-tag={type}>
          {Object.entries(attributes).map(([key, value]) => (
            <React.Fragment key={key}>
              <AttrKey data-tag-attr={key} />
              <AttrVal data-tag-string>{value}</AttrVal>
            </React.Fragment>
          ))}
        </Tag>
      )
    }
  } else if (display === 'block') {
    return (
      <Tag data-block-tag={type}>
        <TagBegin data-tag-start={type}>
          {Object.entries(attributes).map(([key, value]) => (
            <React.Fragment key={key}>
              <AttrKey data-tag-attr={key} />
              <AttrVal data-tag-string>{value}</AttrVal>
            </React.Fragment>
          ))}
        </TagBegin>
        <TagAs data-tag-content={type} {...asProps}>
          {children}
        </TagAs>
        <TagEnd data-tag-end={type} />
      </Tag>
    )
  }

  return null
}

function getTreeText (children) {
  let text = ''
  React.Children.forEach(children, child => {
    if (_.get(child, 'props.children')) { text += getTreeText(child.props.children) } else { text += child }
  })
  return text
}

const Header = ({ children, as = 'header' }) => {
  return (
    <Element
      display='inline'
      type='header'
      as={as}
      asProps={{
        id: slugify(getTreeText(children))
      }}
    >
      {children}
    </Element>
  )
}

const Link = ({ url, to, hash }) => {
  if (url && hash) throw new Error('Cannot have both hash and url for a link. hash:', hash, 'url:', url)

  let props = {}

  if (hash) {
    props.href = `#${slugify(hash)}`
  } else {
    props.href = url || to
    if (props.href.startsWith('http')) {
      props.target = '_blank'
    }
  }

  return (
    <a {...props}>
      <Element type='link' display='inline' to={to || url || hash} />
    </a>
  )
}

const BannerImage = ({ children }) => {
  return (
    <pre>
      <code className='lang-html'>
        {'<!--'}
        {children}
        {'-->'}
      </code>
    </pre>
  )
}

const Break = () => {
  return <span className={css` display: block; `}><br /></span>
}

const CustomElements = {
  link: Link,
  'banner-image': BannerImage,
  header: Header,
  br: Break,

  // using a functional component as a function
  // don't do this, probably
  paragraph: (props) => Element({ ...props, as: 'p' }),
  body: (props) => Element({ ...props, as: 'div' }),
  section: (props) => Element({ ...props }),
  navbar: (props) => Element({ ...props, as: 'nav' })
}
