{
  "Total": {
    "ageData": {
      "2011": {
        "total": "139,869",
        "16to19": "4,327",
        "20to24": "13,036",
        "25to34": "30,537",
        "35to44": "30,270",
        "45to54": "32,867",
        "55to64": "22,186",
        "65+": "6,647",
        "medianAge": "22,186"
      }
    },
    "title": "Total",
    "depth": 1,
    "parent": "",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "139,869",
        "women": "46.9",
        "white": "10.8",
        "black": "4.9",
        "asian": "14.5"
      }
    },
    "wageData": {
      "2011": {
        "workers": "100,457",
        "median": "$756",
        "maleWorkers": "55,971",
        "maleMedian": "$832",
        "femaleWorkers": "44,486",
        "femaleMedian": "$684"
      }
    }
  },
  "Management, professional, and related occupations": {
    "ageData": {
      "2011": {
        "total": "52,547",
        "16to19": "290",
        "20to24": "2,611",
        "25to34": "11,643",
        "35to44": "12,574",
        "45to54": "13,277",
        "55to64": "9,383",
        "65+": "2,768",
        "medianAge": "9,383"
      }
    },
    "title": "Management, professional, and related occupations",
    "depth": 1,
    "parent": "",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "52,547",
        "women": "51.4",
        "white": "8.4",
        "black": "6.1",
        "asian": "7.5"
      }
    },
    "wageData": {
      "2011": {
        "workers": "39,791",
        "median": "1,082",
        "maleWorkers": "19,267",
        "maleMedian": "1,269",
        "femaleWorkers": "20,524",
        "femaleMedian": "941"
      }
    }
  },
  "Management, business, and financial operations occupations": {
    "ageData": {
      "2011": {
        "total": "21,589",
        "16to19": "57",
        "20to24": "777",
        "25to34": "4,091",
        "35to44": "5,274",
        "45to54": "6,014",
        "55to64": "4,048",
        "65+": "1,329",
        "medianAge": "4,048"
      }
    },
    "title": "Management, business, and financial operations occupations",
    "depth": 2,
    "parent": "Management, professional, and related occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "21,589",
        "women": "43.1",
        "white": "7.3",
        "black": "5.1",
        "asian": "7.7"
      }
    },
    "wageData": {
      "2011": {
        "workers": "16,061",
        "median": "1,160",
        "maleWorkers": "8,676",
        "maleMedian": "1,370",
        "femaleWorkers": "7,386",
        "femaleMedian": "977"
      }
    }
  },
  "Management occupations": {
    "ageData": {
      "2011": {
        "total": "15,250",
        "16to19": "42",
        "20to24": "451",
        "25to34": "2,574",
        "35to44": "3,724",
        "45to54": "4,437",
        "55to64": "3,003",
        "65+": "1,019",
        "medianAge": "3,003"
      }
    },
    "title": "Management occupations",
    "depth": 3,
    "parent": "Management, business, and financial operations occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "15,250",
        "women": "38.1",
        "white": "6.3",
        "black": "4.6",
        "asian": "7.7"
      }
    },
    "wageData": {
      "2011": {
        "workers": "10,891",
        "median": "1,237",
        "maleWorkers": "6,451",
        "maleMedian": "1,427",
        "femaleWorkers": "4,440",
        "femaleMedian": "1,018"
      }
    }
  },
  "Chief executives": {
    "ageData": {
      "2011": {
        "total": "1,515",
        "16to19": "1",
        "20to24": "8",
        "25to34": "116",
        "35to44": "291",
        "45to54": "556",
        "55to64": "392",
        "65+": "150",
        "medianAge": "392"
      }
    },
    "title": "Chief executives",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "1,515",
        "women": "24.2",
        "white": "2.7",
        "black": "3.0",
        "asian": "4.1"
      }
    },
    "wageData": {
      "2011": {
        "workers": "990",
        "median": "1,963",
        "maleWorkers": "745",
        "maleMedian": "2,122",
        "femaleWorkers": "245",
        "femaleMedian": "1,464"
      }
    }
  },
  "General and operations managers": {
    "ageData": {
      "2011": {
        "total": "978",
        "16to19": "1",
        "20to24": "19",
        "25to34": "175",
        "35to44": "253",
        "45to54": "324",
        "55to64": "182",
        "65+": "25",
        "medianAge": "182"
      }
    },
    "title": "General and operations managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "978",
        "women": "30.4",
        "white": "6.6",
        "black": "2.7",
        "asian": "7.6"
      }
    },
    "wageData": {
      "2011": {
        "workers": "921",
        "median": "1,191",
        "maleWorkers": "651",
        "maleMedian": "1,319",
        "femaleWorkers": "270",
        "femaleMedian": "972"
      }
    }
  },
  "Legislators": {
    "ageData": {
      "2011": {
        "total": "16",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "4",
        "45to54": "3",
        "55to64": "2",
        "65+": "5",
        "medianAge": "2"
      }
    },
    "title": "Legislators",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "16",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Advertising and promotions managers": {
    "ageData": {
      "2011": {
        "total": "87",
        "16to19": "0",
        "20to24": "5",
        "25to34": "28",
        "35to44": "23",
        "45to54": "15",
        "55to64": "11",
        "65+": "3",
        "medianAge": "11"
      }
    },
    "title": "Advertising and promotions managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "87",
        "women": "50.5",
        "white": "1.6",
        "black": "1.5",
        "asian": "5.6"
      }
    },
    "wageData": {
      "2011": {
        "workers": "68",
        "median": "1,164",
        "maleWorkers": "28",
        "maleMedian": "-",
        "femaleWorkers": "40",
        "femaleMedian": "-"
      }
    }
  },
  "Marketing and sales managers": {
    "ageData": {
      "2011": {
        "total": "1,009",
        "16to19": "4",
        "20to24": "36",
        "25to34": "258",
        "35to44": "301",
        "45to54": "262",
        "55to64": "125",
        "65+": "24",
        "medianAge": "125"
      }
    },
    "title": "Marketing and sales managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "1,009",
        "women": "45.8",
        "white": "5.6",
        "black": "5.6",
        "asian": "6.6"
      }
    },
    "wageData": {
      "2011": {
        "workers": "891",
        "median": "1,408",
        "maleWorkers": "499",
        "maleMedian": "1,660",
        "femaleWorkers": "392",
        "femaleMedian": "1,127"
      }
    }
  },
  "Public relations and fundraising managers": {
    "ageData": {
      "2011": {
        "total": "63",
        "16to19": "0",
        "20to24": "1",
        "25to34": "16",
        "35to44": "16",
        "45to54": "20",
        "55to64": "9",
        "65+": "2",
        "medianAge": "9"
      }
    },
    "title": "Public relations and fundraising managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "63",
        "women": "52.4",
        "white": "2.8",
        "black": "0.4",
        "asian": "3.1"
      }
    },
    "wageData": {
      "2011": {
        "workers": "62",
        "median": "1,475",
        "maleWorkers": "33",
        "maleMedian": "-",
        "femaleWorkers": "29",
        "femaleMedian": "-"
      }
    }
  },
  "Administrative services managers": {
    "ageData": {
      "2011": {
        "total": "128",
        "16to19": "2",
        "20to24": "3",
        "25to34": "17",
        "35to44": "25",
        "45to54": "45",
        "55to64": "28",
        "65+": "8",
        "medianAge": "28"
      }
    },
    "title": "Administrative services managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "128",
        "women": "39.5",
        "white": "6.9",
        "black": "2.2",
        "asian": "7.3"
      }
    },
    "wageData": {
      "2011": {
        "workers": "115",
        "median": "1,021",
        "maleWorkers": "71",
        "maleMedian": "1,170",
        "femaleWorkers": "44",
        "femaleMedian": "-"
      }
    }
  },
  "Computer and information systems managers": {
    "ageData": {
      "2011": {
        "total": "553",
        "16to19": "0",
        "20to24": "10",
        "25to34": "103",
        "35to44": "199",
        "45to54": "162",
        "55to64": "74",
        "65+": "6",
        "medianAge": "74"
      }
    },
    "title": "Computer and information systems managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "553",
        "women": "25.3",
        "white": "5.7",
        "black": "12.9",
        "asian": "3.8"
      }
    },
    "wageData": {
      "2011": {
        "workers": "530",
        "median": "1,579",
        "maleWorkers": "392",
        "maleMedian": "1,595",
        "femaleWorkers": "138",
        "femaleMedian": "1,543"
      }
    }
  },
  "Financial managers": {
    "ageData": {
      "2011": {
        "total": "1,107",
        "16to19": "1",
        "20to24": "43",
        "25to34": "242",
        "35to44": "308",
        "45to54": "300",
        "55to64": "176",
        "65+": "39",
        "medianAge": "176"
      }
    },
    "title": "Financial managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "1,107",
        "women": "54.2",
        "white": "6.5",
        "black": "5.8",
        "asian": "8.7"
      }
    },
    "wageData": {
      "2011": {
        "workers": "1,044",
        "median": "1,166",
        "maleWorkers": "477",
        "maleMedian": "1,504",
        "femaleWorkers": "567",
        "femaleMedian": "991"
      }
    }
  },
  "Compensation and benefits managers": {
    "ageData": {
      "2011": {
        "total": "21",
        "16to19": "0",
        "20to24": "0",
        "25to34": "5",
        "35to44": "4",
        "45to54": "7",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Compensation and benefits managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "21",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "21",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "14",
        "femaleMedian": "-"
      }
    }
  },
  "Human resources managers": {
    "ageData": {
      "2011": {
        "total": "243",
        "16to19": "0",
        "20to24": "7",
        "25to34": "39",
        "35to44": "78",
        "45to54": "71",
        "55to64": "44",
        "65+": "4",
        "medianAge": "44"
      }
    },
    "title": "Human resources managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "243",
        "women": "73.6",
        "white": "11.3",
        "black": "2.1",
        "asian": "9.5"
      }
    },
    "wageData": {
      "2011": {
        "workers": "207",
        "median": "1,331",
        "maleWorkers": "58",
        "maleMedian": "1,488",
        "femaleWorkers": "149",
        "femaleMedian": "1,273"
      }
    }
  },
  "Training and development managers": {
    "ageData": {
      "2011": {
        "total": "38",
        "16to19": "0",
        "20to24": "1",
        "25to34": "7",
        "35to44": "10",
        "45to54": "9",
        "55to64": "9",
        "65+": "1",
        "medianAge": "9"
      }
    },
    "title": "Training and development managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "38",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "36",
        "median": "-",
        "maleWorkers": "24",
        "maleMedian": "-",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Industrial production managers": {
    "ageData": {
      "2011": {
        "total": "259",
        "16to19": "1",
        "20to24": "4",
        "25to34": "38",
        "35to44": "76",
        "45to54": "81",
        "55to64": "55",
        "65+": "4",
        "medianAge": "55"
      }
    },
    "title": "Industrial production managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "259",
        "women": "16.5",
        "white": "3.9",
        "black": "5.1",
        "asian": "8.9"
      }
    },
    "wageData": {
      "2011": {
        "workers": "250",
        "median": "1,211",
        "maleWorkers": "204",
        "maleMedian": "1,245",
        "femaleWorkers": "45",
        "femaleMedian": "-"
      }
    }
  },
  "Purchasing managers": {
    "ageData": {
      "2011": {
        "total": "204",
        "16to19": "0",
        "20to24": "2",
        "25to34": "34",
        "35to44": "44",
        "45to54": "73",
        "55to64": "41",
        "65+": "9",
        "medianAge": "41"
      }
    },
    "title": "Purchasing managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "204",
        "women": "47.3",
        "white": "8.8",
        "black": "2.1",
        "asian": "8.8"
      }
    },
    "wageData": {
      "2011": {
        "workers": "183",
        "median": "1,242",
        "maleWorkers": "94",
        "maleMedian": "1,368",
        "femaleWorkers": "89",
        "femaleMedian": "1,026"
      }
    }
  },
  "Transportation, storage, and distribution managers": {
    "ageData": {
      "2011": {
        "total": "254",
        "16to19": "0",
        "20to24": "13",
        "25to34": "52",
        "35to44": "71",
        "45to54": "68",
        "55to64": "42",
        "65+": "8",
        "medianAge": "42"
      }
    },
    "title": "Transportation, storage, and distribution managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "254",
        "women": "14.1",
        "white": "8.7",
        "black": "3.0",
        "asian": "8.9"
      }
    },
    "wageData": {
      "2011": {
        "workers": "229",
        "median": "882",
        "maleWorkers": "196",
        "maleMedian": "861",
        "femaleWorkers": "33",
        "femaleMedian": "-"
      }
    }
  },
  "Farmers, ranchers, and other agricultural managers": {
    "ageData": {
      "2011": {
        "total": "978",
        "16to19": "5",
        "20to24": "28",
        "25to34": "92",
        "35to44": "122",
        "45to54": "214",
        "55to64": "253",
        "65+": "264",
        "medianAge": "253"
      }
    },
    "title": "Farmers, ranchers, and other agricultural managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "978",
        "women": "23.0",
        "white": "0.9",
        "black": "0.8",
        "asian": "4.1"
      }
    },
    "wageData": {
      "2011": {
        "workers": "98",
        "median": "800",
        "maleWorkers": "81",
        "maleMedian": "855",
        "femaleWorkers": "17",
        "femaleMedian": "-"
      }
    }
  },
  "Construction managers": {
    "ageData": {
      "2011": {
        "total": "926",
        "16to19": "0",
        "20to24": "18",
        "25to34": "157",
        "35to44": "235",
        "45to54": "294",
        "55to64": "182",
        "65+": "40",
        "medianAge": "182"
      }
    },
    "title": "Construction managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "926",
        "women": "6.1",
        "white": "3.0",
        "black": "1.5",
        "asian": "9.7"
      }
    },
    "wageData": {
      "2011": {
        "workers": "464",
        "median": "1,268",
        "maleWorkers": "433",
        "maleMedian": "1,325",
        "femaleWorkers": "31",
        "femaleMedian": "-"
      }
    }
  },
  "Education administrators": {
    "ageData": {
      "2011": {
        "total": "853",
        "16to19": "0",
        "20to24": "16",
        "25to34": "146",
        "35to44": "224",
        "45to54": "220",
        "55to64": "202",
        "65+": "45",
        "medianAge": "202"
      }
    },
    "title": "Education administrators",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "853",
        "women": "65.2",
        "white": "13.3",
        "black": "2.9",
        "asian": "6.5"
      }
    },
    "wageData": {
      "2011": {
        "workers": "735",
        "median": "1,228",
        "maleWorkers": "268",
        "maleMedian": "1,532",
        "femaleWorkers": "467",
        "femaleMedian": "1,061"
      }
    }
  },
  "Architectural and engineering managers": {
    "ageData": {
      "2011": {
        "total": "106",
        "16to19": "0",
        "20to24": "1",
        "25to34": "10",
        "35to44": "30",
        "45to54": "39",
        "55to64": "20",
        "65+": "5",
        "medianAge": "20"
      }
    },
    "title": "Architectural and engineering managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "106",
        "women": "8.5",
        "white": "1.3",
        "black": "5.9",
        "asian": "4.7"
      }
    },
    "wageData": {
      "2011": {
        "workers": "98",
        "median": "1,914",
        "maleWorkers": "91",
        "maleMedian": "1,908",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Food service managers": {
    "ageData": {
      "2011": {
        "total": "1,051",
        "16to19": "15",
        "20to24": "118",
        "25to34": "274",
        "35to44": "232",
        "45to54": "255",
        "55to64": "126",
        "65+": "31",
        "medianAge": "126"
      }
    },
    "title": "Food service managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "1,051",
        "women": "45.7",
        "white": "9.2",
        "black": "9.2",
        "asian": "13.9"
      }
    },
    "wageData": {
      "2011": {
        "workers": "675",
        "median": "660",
        "maleWorkers": "361",
        "maleMedian": "734",
        "femaleWorkers": "314",
        "femaleMedian": "599"
      }
    }
  },
  "Funeral service managers": {
    "ageData": {
      "2011": {
        "total": "13",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "2",
        "45to54": "5",
        "55to64": "2",
        "65+": "2",
        "medianAge": "2"
      }
    },
    "title": "Funeral service managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "13",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Gaming managers": {
    "ageData": {
      "2011": {
        "total": "23",
        "16to19": "0",
        "20to24": "1",
        "25to34": "2",
        "35to44": "6",
        "45to54": "6",
        "55to64": "6",
        "65+": "3",
        "medianAge": "6"
      }
    },
    "title": "Gaming managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "23",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "22",
        "median": "-",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Lodging managers": {
    "ageData": {
      "2011": {
        "total": "148",
        "16to19": "1",
        "20to24": "7",
        "25to34": "28",
        "35to44": "32",
        "45to54": "31",
        "55to64": "32",
        "65+": "16",
        "medianAge": "32"
      }
    },
    "title": "Lodging managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "148",
        "women": "52.4",
        "white": "7.0",
        "black": "13.4",
        "asian": "8.1"
      }
    },
    "wageData": {
      "2011": {
        "workers": "91",
        "median": "883",
        "maleWorkers": "36",
        "maleMedian": "-",
        "femaleWorkers": "55",
        "femaleMedian": "841"
      }
    }
  },
  "Medical and health services managers": {
    "ageData": {
      "2011": {
        "total": "529",
        "16to19": "1",
        "20to24": "3",
        "25to34": "73",
        "35to44": "121",
        "45to54": "178",
        "55to64": "134",
        "65+": "19",
        "medianAge": "134"
      }
    },
    "title": "Medical and health services managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "529",
        "women": "71.4",
        "white": "11.2",
        "black": "5.0",
        "asian": "8.4"
      }
    },
    "wageData": {
      "2011": {
        "workers": "450",
        "median": "1,252",
        "maleWorkers": "125",
        "maleMedian": "1,456",
        "femaleWorkers": "325",
        "femaleMedian": "1,166"
      }
    }
  },
  "Natural sciences managers": {
    "ageData": {
      "2011": {
        "total": "16",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "8",
        "45to54": "4",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Natural sciences managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "16",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Postmasters and mail superintendents": {
    "ageData": {
      "2011": {
        "total": "40",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "5",
        "45to54": "18",
        "55to64": "13",
        "65+": "2",
        "medianAge": "13"
      }
    },
    "title": "Postmasters and mail superintendents",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "40",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "29",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "19",
        "femaleMedian": "-"
      }
    }
  },
  "Property, real estate, and community association managers": {
    "ageData": {
      "2011": {
        "total": "587",
        "16to19": "1",
        "20to24": "13",
        "25to34": "95",
        "35to44": "121",
        "45to54": "146",
        "55to64": "126",
        "65+": "86",
        "medianAge": "126"
      }
    },
    "title": "Property, real estate, and community association managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "587",
        "women": "48.0",
        "white": "8.8",
        "black": "3.1",
        "asian": "8.8"
      }
    },
    "wageData": {
      "2011": {
        "workers": "317",
        "median": "921",
        "maleWorkers": "135",
        "maleMedian": "1,201",
        "femaleWorkers": "182",
        "femaleMedian": "728"
      }
    }
  },
  "Social and community service managers": {
    "ageData": {
      "2011": {
        "total": "329",
        "16to19": "1",
        "20to24": "11",
        "25to34": "54",
        "35to44": "72",
        "45to54": "97",
        "55to64": "71",
        "65+": "24",
        "medianAge": "71"
      }
    },
    "title": "Social and community service managers",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "329",
        "women": "71.3",
        "white": "11.3",
        "black": "2.6",
        "asian": "6.9"
      }
    },
    "wageData": {
      "2011": {
        "workers": "277",
        "median": "1,045",
        "maleWorkers": "81",
        "maleMedian": "1,221",
        "femaleWorkers": "196",
        "femaleMedian": "973"
      }
    }
  },
  "Emergency management directors": {
    "ageData": {
      "2011": {
        "total": "5",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "1",
        "45to54": "2",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Emergency management directors",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Managers, all other": {
    "ageData": {
      "2011": {
        "total": "3,173",
        "16to19": "8",
        "20to24": "84",
        "25to34": "509",
        "35to44": "812",
        "45to54": "931",
        "55to64": "638",
        "65+": "191",
        "medianAge": "638"
      }
    },
    "title": "Managers, all other",
    "depth": 4,
    "parent": "Management occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "3,173",
        "women": "33.9",
        "white": "5.9",
        "black": "5.4",
        "asian": "8.6"
      }
    },
    "wageData": {
      "2011": {
        "workers": "2,050",
        "median": "1,265",
        "maleWorkers": "1,313",
        "maleMedian": "1,406",
        "femaleWorkers": "737",
        "femaleMedian": "1,047"
      }
    }
  },
  "Business and financial operations occupations": {
    "ageData": {
      "2011": {
        "total": "6,339",
        "16to19": "15",
        "20to24": "326",
        "25to34": "1,516",
        "35to44": "1,550",
        "45to54": "1,578",
        "55to64": "1,044",
        "65+": "310",
        "medianAge": "1,044"
      }
    },
    "title": "Business and financial operations occupations",
    "depth": 3,
    "parent": "Management, business, and financial operations occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "6,339",
        "women": "55.3",
        "white": "9.8",
        "black": "6.6",
        "asian": "7.8"
      }
    },
    "wageData": {
      "2011": {
        "workers": "5,170",
        "median": "1,038",
        "maleWorkers": "2,225",
        "maleMedian": "1,225",
        "femaleWorkers": "2,946",
        "femaleMedian": "937"
      }
    }
  },
  "Agents and business managers of artists, performers, and athletes": {
    "ageData": {
      "2011": {
        "total": "48",
        "16to19": "0",
        "20to24": "3",
        "25to34": "14",
        "35to44": "10",
        "45to54": "14",
        "55to64": "6",
        "65+": "0",
        "medianAge": "6"
      }
    },
    "title": "Agents and business managers of artists, performers, and athletes",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "48",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "25",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "13",
        "femaleMedian": "-"
      }
    }
  },
  "Buyers and purchasing agents, farm products": {
    "ageData": {
      "2011": {
        "total": "12",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "2",
        "45to54": "2",
        "55to64": "4",
        "65+": "3",
        "medianAge": "4"
      }
    },
    "title": "Buyers and purchasing agents, farm products",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "12",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Wholesale and retail buyers, except farm products": {
    "ageData": {
      "2011": {
        "total": "170",
        "16to19": "2",
        "20to24": "16",
        "25to34": "47",
        "35to44": "36",
        "45to54": "33",
        "55to64": "28",
        "65+": "7",
        "medianAge": "28"
      }
    },
    "title": "Wholesale and retail buyers, except farm products",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "170",
        "women": "46.4",
        "white": "4.2",
        "black": "4.7",
        "asian": "11.8"
      }
    },
    "wageData": {
      "2011": {
        "workers": "130",
        "median": "882",
        "maleWorkers": "68",
        "maleMedian": "928",
        "femaleWorkers": "62",
        "femaleMedian": "849"
      }
    }
  },
  "Purchasing agents, except wholesale, retail, and farm products": {
    "ageData": {
      "2011": {
        "total": "259",
        "16to19": "2",
        "20to24": "11",
        "25to34": "54",
        "35to44": "64",
        "45to54": "70",
        "55to64": "51",
        "65+": "6",
        "medianAge": "51"
      }
    },
    "title": "Purchasing agents, except wholesale, retail, and farm products",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "259",
        "women": "54.0",
        "white": "8.7",
        "black": "3.5",
        "asian": "5.3"
      }
    },
    "wageData": {
      "2011": {
        "workers": "226",
        "median": "1,015",
        "maleWorkers": "108",
        "maleMedian": "1,129",
        "femaleWorkers": "118",
        "femaleMedian": "945"
      }
    }
  },
  "Claims adjusters, appraisers, examiners, and investigators": {
    "ageData": {
      "2011": {
        "total": "296",
        "16to19": "0",
        "20to24": "16",
        "25to34": "71",
        "35to44": "83",
        "45to54": "74",
        "55to64": "44",
        "65+": "9",
        "medianAge": "44"
      }
    },
    "title": "Claims adjusters, appraisers, examiners, and investigators",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "296",
        "women": "58.6",
        "white": "16.5",
        "black": "2.3",
        "asian": "9.5"
      }
    },
    "wageData": {
      "2011": {
        "workers": "271",
        "median": "913",
        "maleWorkers": "110",
        "maleMedian": "1,077",
        "femaleWorkers": "162",
        "femaleMedian": "804"
      }
    }
  },
  "Compliance officers": {
    "ageData": {
      "2011": {
        "total": "198",
        "16to19": "0",
        "20to24": "3",
        "25to34": "31",
        "35to44": "57",
        "45to54": "59",
        "55to64": "42",
        "65+": "7",
        "medianAge": "42"
      }
    },
    "title": "Compliance officers",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "198",
        "women": "43.8",
        "white": "10.9",
        "black": "3.8",
        "asian": "9.4"
      }
    },
    "wageData": {
      "2011": {
        "workers": "176",
        "median": "1,125",
        "maleWorkers": "98",
        "maleMedian": "1,279",
        "femaleWorkers": "78",
        "femaleMedian": "995"
      }
    }
  },
  "Cost estimators": {
    "ageData": {
      "2011": {
        "total": "119",
        "16to19": "0",
        "20to24": "7",
        "25to34": "29",
        "35to44": "23",
        "45to54": "28",
        "55to64": "22",
        "65+": "10",
        "medianAge": "22"
      }
    },
    "title": "Cost estimators",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "119",
        "women": "15.0",
        "white": "2.7",
        "black": "2.3",
        "asian": "6.0"
      }
    },
    "wageData": {
      "2011": {
        "workers": "106",
        "median": "1,080",
        "maleWorkers": "87",
        "maleMedian": "1,117",
        "femaleWorkers": "19",
        "femaleMedian": "-"
      }
    }
  },
  "Human resources workers": {
    "ageData": {
      "2011": {
        "total": "595",
        "16to19": "0",
        "20to24": "30",
        "25to34": "144",
        "35to44": "169",
        "45to54": "146",
        "55to64": "89",
        "65+": "16",
        "medianAge": "89"
      }
    },
    "title": "Human resources workers",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "595",
        "women": "74.5",
        "white": "15.1",
        "black": "4.4",
        "asian": "10.5"
      }
    },
    "wageData": {
      "2011": {
        "workers": "522",
        "median": "938",
        "maleWorkers": "116",
        "maleMedian": "1,053",
        "femaleWorkers": "406",
        "femaleMedian": "912"
      }
    }
  },
  "Compensation, benefits, and job analysis specialists": {
    "ageData": {
      "2011": {
        "total": "65",
        "16to19": "0",
        "20to24": "2",
        "25to34": "15",
        "35to44": "16",
        "45to54": "13",
        "55to64": "16",
        "65+": "2",
        "medianAge": "16"
      }
    },
    "title": "Compensation, benefits, and job analysis specialists",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "65",
        "women": "75.5",
        "white": "10.7",
        "black": "2.3",
        "asian": "5.2"
      }
    },
    "wageData": {
      "2011": {
        "workers": "53",
        "median": "893",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "39",
        "femaleMedian": "-"
      }
    }
  },
  "Training and development specialists": {
    "ageData": {
      "2011": {
        "total": "130",
        "16to19": "1",
        "20to24": "4",
        "25to34": "25",
        "35to44": "36",
        "45to54": "35",
        "55to64": "25",
        "65+": "4",
        "medianAge": "25"
      }
    },
    "title": "Training and development specialists",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "130",
        "women": "57.2",
        "white": "13.6",
        "black": "4.4",
        "asian": "8.5"
      }
    },
    "wageData": {
      "2011": {
        "workers": "117",
        "median": "1,059",
        "maleWorkers": "53",
        "maleMedian": "1,260",
        "femaleWorkers": "64",
        "femaleMedian": "951"
      }
    }
  },
  "Logisticians": {
    "ageData": {
      "2011": {
        "total": "88",
        "16to19": "1",
        "20to24": "10",
        "25to34": "21",
        "35to44": "15",
        "45to54": "26",
        "55to64": "13",
        "65+": "1",
        "medianAge": "13"
      }
    },
    "title": "Logisticians",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "88",
        "women": "35.6",
        "white": "7.3",
        "black": "1.7",
        "asian": "12.3"
      }
    },
    "wageData": {
      "2011": {
        "workers": "77",
        "median": "864",
        "maleWorkers": "50",
        "maleMedian": "938",
        "femaleWorkers": "27",
        "femaleMedian": "-"
      }
    }
  },
  "Management analysts": {
    "ageData": {
      "2011": {
        "total": "707",
        "16to19": "2",
        "20to24": "19",
        "25to34": "148",
        "35to44": "151",
        "45to54": "192",
        "55to64": "138",
        "65+": "57",
        "medianAge": "138"
      }
    },
    "title": "Management analysts",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "707",
        "women": "41.9",
        "white": "6.8",
        "black": "8.5",
        "asian": "4.5"
      }
    },
    "wageData": {
      "2011": {
        "workers": "458",
        "median": "1,355",
        "maleWorkers": "250",
        "maleMedian": "1,514",
        "femaleWorkers": "208",
        "femaleMedian": "1,174"
      }
    }
  },
  "Meeting, convention, and event planners": {
    "ageData": {
      "2011": {
        "total": "109",
        "16to19": "1",
        "20to24": "17",
        "25to34": "37",
        "35to44": "19",
        "45to54": "18",
        "55to64": "13",
        "65+": "3",
        "medianAge": "13"
      }
    },
    "title": "Meeting, convention, and event planners",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "109",
        "women": "76.2",
        "white": "11.6",
        "black": "2.0",
        "asian": "12.4"
      }
    },
    "wageData": {
      "2011": {
        "workers": "87",
        "median": "889",
        "maleWorkers": "16",
        "maleMedian": "-",
        "femaleWorkers": "71",
        "femaleMedian": "892"
      }
    }
  },
  "Fundraisers": {
    "ageData": {
      "2011": {
        "total": "78",
        "16to19": "1",
        "20to24": "10",
        "25to34": "18",
        "35to44": "18",
        "45to54": "18",
        "55to64": "11",
        "65+": "3",
        "medianAge": "11"
      }
    },
    "title": "Fundraisers",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "78",
        "women": "72.0",
        "white": "3.9",
        "black": "3.0",
        "asian": "3.7"
      }
    },
    "wageData": {
      "2011": {
        "workers": "56",
        "median": "1,064",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "42",
        "femaleMedian": "-"
      }
    }
  },
  "Market research analysts and marketing specialists": {
    "ageData": {
      "2011": {
        "total": "205",
        "16to19": "1",
        "20to24": "18",
        "25to34": "83",
        "35to44": "42",
        "45to54": "42",
        "55to64": "14",
        "65+": "3",
        "medianAge": "14"
      }
    },
    "title": "Market research analysts and marketing specialists",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "205",
        "women": "57.5",
        "white": "5.2",
        "black": "7.3",
        "asian": "6.3"
      }
    },
    "wageData": {
      "2011": {
        "workers": "167",
        "median": "1,157",
        "maleWorkers": "66",
        "maleMedian": "1,446",
        "femaleWorkers": "101",
        "femaleMedian": "1,029"
      }
    }
  },
  "Business operations specialists, all other": {
    "ageData": {
      "2011": {
        "total": "281",
        "16to19": "1",
        "20to24": "18",
        "25to34": "58",
        "35to44": "71",
        "45to54": "65",
        "55to64": "52",
        "65+": "15",
        "medianAge": "52"
      }
    },
    "title": "Business operations specialists, all other",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "281",
        "women": "66.9",
        "white": "12.2",
        "black": "4.5",
        "asian": "8.7"
      }
    },
    "wageData": {
      "2011": {
        "workers": "238",
        "median": "991",
        "maleWorkers": "88",
        "maleMedian": "1,185",
        "femaleWorkers": "149",
        "femaleMedian": "908"
      }
    }
  },
  "Accountants and auditors": {
    "ageData": {
      "2011": {
        "total": "1,653",
        "16to19": "1",
        "20to24": "93",
        "25to34": "395",
        "35to44": "408",
        "45to54": "410",
        "55to64": "262",
        "65+": "85",
        "medianAge": "262"
      }
    },
    "title": "Accountants and auditors",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "1,653",
        "women": "61.3",
        "white": "8.5",
        "black": "10.3",
        "asian": "7.6"
      }
    },
    "wageData": {
      "2011": {
        "workers": "1,383",
        "median": "1,056",
        "maleWorkers": "532",
        "maleMedian": "1,250",
        "femaleWorkers": "852",
        "femaleMedian": "956"
      }
    }
  },
  "Appraisers and assessors of real estate": {
    "ageData": {
      "2011": {
        "total": "88",
        "16to19": "0",
        "20to24": "2",
        "25to34": "13",
        "35to44": "17",
        "45to54": "26",
        "55to64": "20",
        "65+": "10",
        "medianAge": "20"
      }
    },
    "title": "Appraisers and assessors of real estate",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "88",
        "women": "34.4",
        "white": "3.7",
        "black": "1.6",
        "asian": "0.0"
      }
    },
    "wageData": {
      "2011": {
        "workers": "50",
        "median": "990",
        "maleWorkers": "30",
        "maleMedian": "-",
        "femaleWorkers": "20",
        "femaleMedian": "-"
      }
    }
  },
  "Budget analysts": {
    "ageData": {
      "2011": {
        "total": "53",
        "16to19": "0",
        "20to24": "2",
        "25to34": "9",
        "35to44": "11",
        "45to54": "17",
        "55to64": "12",
        "65+": "2",
        "medianAge": "12"
      }
    },
    "title": "Budget analysts",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "53",
        "women": "51.8",
        "white": "20.1",
        "black": "8.9",
        "asian": "3.6"
      }
    },
    "wageData": {
      "2011": {
        "workers": "52",
        "median": "1,174",
        "maleWorkers": "22",
        "maleMedian": "-",
        "femaleWorkers": "30",
        "femaleMedian": "-"
      }
    }
  },
  "Credit analysts": {
    "ageData": {
      "2011": {
        "total": "24",
        "16to19": "0",
        "20to24": "3",
        "25to34": "6",
        "35to44": "9",
        "45to54": "5",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Credit analysts",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "24",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "21",
        "median": "-",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Financial analysts": {
    "ageData": {
      "2011": {
        "total": "84",
        "16to19": "0",
        "20to24": "3",
        "25to34": "28",
        "35to44": "29",
        "45to54": "11",
        "55to64": "8",
        "65+": "3",
        "medianAge": "8"
      }
    },
    "title": "Financial analysts",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "84",
        "women": "34.8",
        "white": "6.0",
        "black": "13.4",
        "asian": "2.7"
      }
    },
    "wageData": {
      "2011": {
        "workers": "63",
        "median": "1,737",
        "maleWorkers": "40",
        "maleMedian": "-",
        "femaleWorkers": "22",
        "femaleMedian": "-"
      }
    }
  },
  "Personal financial advisors": {
    "ageData": {
      "2011": {
        "total": "371",
        "16to19": "0",
        "20to24": "14",
        "25to34": "97",
        "35to44": "88",
        "45to54": "86",
        "55to64": "57",
        "65+": "30",
        "medianAge": "57"
      }
    },
    "title": "Personal financial advisors",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "371",
        "women": "31.2",
        "white": "6.8",
        "black": "5.6",
        "asian": "6.7"
      }
    },
    "wageData": {
      "2011": {
        "workers": "270",
        "median": "1,239",
        "maleWorkers": "173",
        "maleMedian": "1,535",
        "femaleWorkers": "97",
        "femaleMedian": "941"
      }
    }
  },
  "Insurance underwriters": {
    "ageData": {
      "2011": {
        "total": "117",
        "16to19": "0",
        "20to24": "0",
        "25to34": "31",
        "35to44": "29",
        "45to54": "33",
        "55to64": "19",
        "65+": "4",
        "medianAge": "19"
      }
    },
    "title": "Insurance underwriters",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "117",
        "women": "62.7",
        "white": "10.7",
        "black": "3.0",
        "asian": "3.0"
      }
    },
    "wageData": {
      "2011": {
        "workers": "108",
        "median": "970",
        "maleWorkers": "44",
        "maleMedian": "-",
        "femaleWorkers": "64",
        "femaleMedian": "939"
      }
    }
  },
  "Financial examiners": {
    "ageData": {
      "2011": {
        "total": "8",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "1",
        "45to54": "4",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Financial examiners",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "8",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Credit counselors and loan officers": {
    "ageData": {
      "2011": {
        "total": "326",
        "16to19": "0",
        "20to24": "16",
        "25to34": "84",
        "35to44": "91",
        "45to54": "82",
        "55to64": "44",
        "65+": "8",
        "medianAge": "44"
      }
    },
    "title": "Credit counselors and loan officers",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "326",
        "women": "53.7",
        "white": "12.1",
        "black": "5.5",
        "asian": "11.4"
      }
    },
    "wageData": {
      "2011": {
        "workers": "306",
        "median": "1,024",
        "maleWorkers": "147",
        "maleMedian": "1,345",
        "femaleWorkers": "159",
        "femaleMedian": "829"
      }
    }
  },
  "Tax examiners and collectors, and revenue agents": {
    "ageData": {
      "2011": {
        "total": "70",
        "16to19": "0",
        "20to24": "1",
        "25to34": "16",
        "35to44": "12",
        "45to54": "25",
        "55to64": "14",
        "65+": "1",
        "medianAge": "14"
      }
    },
    "title": "Tax examiners and collectors, and revenue agents",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "70",
        "women": "56.2",
        "white": "24.2",
        "black": "8.6",
        "asian": "10.8"
      }
    },
    "wageData": {
      "2011": {
        "workers": "67",
        "median": "920",
        "maleWorkers": "27",
        "maleMedian": "-",
        "femaleWorkers": "40",
        "femaleMedian": "-"
      }
    }
  },
  "Tax preparers": {
    "ageData": {
      "2011": {
        "total": "110",
        "16to19": "0",
        "20to24": "4",
        "25to34": "22",
        "35to44": "17",
        "45to54": "23",
        "55to64": "25",
        "65+": "18",
        "medianAge": "25"
      }
    },
    "title": "Tax preparers",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "110",
        "women": "65.8",
        "white": "9.8",
        "black": "5.8",
        "asian": "11.9"
      }
    },
    "wageData": {
      "2011": {
        "workers": "55",
        "median": "701",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "41",
        "femaleMedian": "-"
      }
    }
  },
  "Financial specialists, all other": {
    "ageData": {
      "2011": {
        "total": "77",
        "16to19": "0",
        "20to24": "3",
        "25to34": "16",
        "35to44": "26",
        "45to54": "20",
        "55to64": "11",
        "65+": "1",
        "medianAge": "11"
      }
    },
    "title": "Financial specialists, all other",
    "depth": 4,
    "parent": "Business and financial operations occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "77",
        "women": "61.8",
        "white": "16.4",
        "black": "11.2",
        "asian": "7.5"
      }
    },
    "wageData": {
      "2011": {
        "workers": "72",
        "median": "913",
        "maleWorkers": "30",
        "maleMedian": "-",
        "femaleWorkers": "41",
        "femaleMedian": "-"
      }
    }
  },
  "Professional and related occupations": {
    "ageData": {
      "2011": {
        "total": "30,957",
        "16to19": "234",
        "20to24": "1,834",
        "25to34": "7,552",
        "35to44": "7,301",
        "45to54": "7,262",
        "55to64": "5,335",
        "65+": "1,439",
        "medianAge": "5,335"
      }
    },
    "title": "Professional and related occupations",
    "depth": 2,
    "parent": "Management, professional, and related occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "30,957",
        "women": "57.1",
        "white": "9.1",
        "black": "6.8",
        "asian": "7.4"
      }
    },
    "wageData": {
      "2011": {
        "workers": "23,730",
        "median": "1,029",
        "maleWorkers": "10,592",
        "maleMedian": "1,211",
        "femaleWorkers": "13,139",
        "femaleMedian": "919"
      }
    }
  },
  "Computer and mathematical occupations": {
    "ageData": {
      "2011": {
        "total": "3,608",
        "16to19": "14",
        "20to24": "198",
        "25to34": "1,036",
        "35to44": "1,034",
        "45to54": "845",
        "55to64": "408",
        "65+": "73",
        "medianAge": "408"
      }
    },
    "title": "Computer and mathematical occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "3,608",
        "women": "25.0",
        "white": "6.9",
        "black": "16.6",
        "asian": "5.7"
      }
    },
    "wageData": {
      "2011": {
        "workers": "3,296",
        "median": "1,305",
        "maleWorkers": "2,509",
        "maleMedian": "1,369",
        "femaleWorkers": "787",
        "femaleMedian": "1,126"
      }
    }
  },
  "Computer and information research scientists": {
    "ageData": {
      "2011": {
        "total": "20",
        "16to19": "0",
        "20to24": "3",
        "25to34": "7",
        "35to44": "5",
        "45to54": "3",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Computer and information research scientists",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "20",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "19",
        "median": "-",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Computer systems analysts": {
    "ageData": {
      "2011": {
        "total": "447",
        "16to19": "1",
        "20to24": "12",
        "25to34": "109",
        "35to44": "113",
        "45to54": "133",
        "55to64": "64",
        "65+": "16",
        "medianAge": "64"
      }
    },
    "title": "Computer systems analysts",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "447",
        "women": "33.9",
        "white": "7.1",
        "black": "14.9",
        "asian": "4.4"
      }
    },
    "wageData": {
      "2011": {
        "workers": "373",
        "median": "1,328",
        "maleWorkers": "242",
        "maleMedian": "1,410",
        "femaleWorkers": "132",
        "femaleMedian": "1,144"
      }
    }
  },
  "Information security analysts": {
    "ageData": {
      "2011": {
        "total": "44",
        "16to19": "0",
        "20to24": "3",
        "25to34": "12",
        "35to44": "14",
        "45to54": "11",
        "55to64": "5",
        "65+": "0",
        "medianAge": "5"
      }
    },
    "title": "Information security analysts",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "44",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "45",
        "median": "-",
        "maleWorkers": "38",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Computer programmers": {
    "ageData": {
      "2011": {
        "total": "459",
        "16to19": "4",
        "20to24": "30",
        "25to34": "110",
        "35to44": "131",
        "45to54": "105",
        "55to64": "66",
        "65+": "14",
        "medianAge": "66"
      }
    },
    "title": "Computer programmers",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "459",
        "women": "20.8",
        "white": "4.3",
        "black": "15.4",
        "asian": "5.8"
      }
    },
    "wageData": {
      "2011": {
        "workers": "411",
        "median": "1,277",
        "maleWorkers": "329",
        "maleMedian": "1,330",
        "femaleWorkers": "83",
        "femaleMedian": "1,238"
      }
    }
  },
  "Software developers, applications and systems software": {
    "ageData": {
      "2011": {
        "total": "1,044",
        "16to19": "1",
        "20to24": "44",
        "25to34": "331",
        "35to44": "340",
        "45to54": "230",
        "55to64": "87",
        "65+": "11",
        "medianAge": "87"
      }
    },
    "title": "Software developers, applications and systems software",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "1,044",
        "women": "19.0",
        "white": "4.8",
        "black": "27.1",
        "asian": "4.6"
      }
    },
    "wageData": {
      "2011": {
        "workers": "990",
        "median": "1,558",
        "maleWorkers": "812",
        "maleMedian": "1,606",
        "femaleWorkers": "179",
        "femaleMedian": "1,388"
      }
    }
  },
  "Web developers": {
    "ageData": {
      "2011": {
        "total": "182",
        "16to19": "1",
        "20to24": "20",
        "25to34": "61",
        "35to44": "58",
        "45to54": "28",
        "55to64": "12",
        "65+": "2",
        "medianAge": "12"
      }
    },
    "title": "Web developers",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "182",
        "women": "38.6",
        "white": "5.9",
        "black": "11.1",
        "asian": "6.9"
      }
    },
    "wageData": {
      "2011": {
        "workers": "117",
        "median": "1,017",
        "maleWorkers": "81",
        "maleMedian": "1,033",
        "femaleWorkers": "35",
        "femaleMedian": "-"
      }
    }
  },
  "Computer support specialists": {
    "ageData": {
      "2011": {
        "total": "461",
        "16to19": "3",
        "20to24": "40",
        "25to34": "146",
        "35to44": "112",
        "45to54": "90",
        "55to64": "60",
        "65+": "10",
        "medianAge": "60"
      }
    },
    "title": "Computer support specialists",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "461",
        "women": "26.3",
        "white": "11.8",
        "black": "8.7",
        "asian": "7.5"
      }
    },
    "wageData": {
      "2011": {
        "workers": "434",
        "median": "915",
        "maleWorkers": "326",
        "maleMedian": "896",
        "femaleWorkers": "107",
        "femaleMedian": "951"
      }
    }
  },
  "Database administrators": {
    "ageData": {
      "2011": {
        "total": "134",
        "16to19": "0",
        "20to24": "5",
        "25to34": "34",
        "35to44": "34",
        "45to54": "40",
        "55to64": "21",
        "65+": "1",
        "medianAge": "21"
      }
    },
    "title": "Database administrators",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "134",
        "women": "37.3",
        "white": "9.2",
        "black": "11.8",
        "asian": "9.0"
      }
    },
    "wageData": {
      "2011": {
        "workers": "138",
        "median": "1,238",
        "maleWorkers": "90",
        "maleMedian": "1,470",
        "femaleWorkers": "48",
        "femaleMedian": "-"
      }
    }
  },
  "Network and computer systems administrators": {
    "ageData": {
      "2011": {
        "total": "233",
        "16to19": "0",
        "20to24": "9",
        "25to34": "69",
        "35to44": "77",
        "45to54": "52",
        "55to64": "22",
        "65+": "4",
        "medianAge": "22"
      }
    },
    "title": "Network and computer systems administrators",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "233",
        "women": "22.8",
        "white": "6.2",
        "black": "10.0",
        "asian": "7.2"
      }
    },
    "wageData": {
      "2011": {
        "workers": "221",
        "median": "1,180",
        "maleWorkers": "172",
        "maleMedian": "1,243",
        "femaleWorkers": "49",
        "femaleMedian": "-"
      }
    }
  },
  "Computer network architects": {
    "ageData": {
      "2011": {
        "total": "98",
        "16to19": "0",
        "20to24": "2",
        "25to34": "25",
        "35to44": "29",
        "45to54": "30",
        "55to64": "9",
        "65+": "2",
        "medianAge": "9"
      }
    },
    "title": "Computer network architects",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "98",
        "women": "11.4",
        "white": "3.1",
        "black": "13.9",
        "asian": "7.0"
      }
    },
    "wageData": {
      "2011": {
        "workers": "94",
        "median": "1,441",
        "maleWorkers": "81",
        "maleMedian": "1,585",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Computer occupations, all other": {
    "ageData": {
      "2011": {
        "total": "306",
        "16to19": "4",
        "20to24": "21",
        "25to34": "81",
        "35to44": "74",
        "45to54": "81",
        "55to64": "37",
        "65+": "9",
        "medianAge": "37"
      }
    },
    "title": "Computer occupations, all other",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "306",
        "women": "21.7",
        "white": "8.8",
        "black": "11.9",
        "asian": "6.5"
      }
    },
    "wageData": {
      "2011": {
        "workers": "282",
        "median": "1,127",
        "maleWorkers": "221",
        "maleMedian": "1,165",
        "femaleWorkers": "61",
        "femaleMedian": "998"
      }
    }
  },
  "Actuaries": {
    "ageData": {
      "2011": {
        "total": "18",
        "16to19": "0",
        "20to24": "1",
        "25to34": "7",
        "35to44": "4",
        "45to54": "3",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Actuaries",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "18",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "17",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Mathematicians": {
    "ageData": {
      "2011": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "0",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Mathematicians",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Operations research analysts": {
    "ageData": {
      "2011": {
        "total": "116",
        "16to19": "0",
        "20to24": "6",
        "25to34": "33",
        "35to44": "30",
        "45to54": "28",
        "55to64": "15",
        "65+": "3",
        "medianAge": "15"
      }
    },
    "title": "Operations research analysts",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "116",
        "women": "45.1",
        "white": "14.5",
        "black": "6.5",
        "asian": "4.1"
      }
    },
    "wageData": {
      "2011": {
        "workers": "116",
        "median": "1,273",
        "maleWorkers": "66",
        "maleMedian": "1,258",
        "femaleWorkers": "51",
        "femaleMedian": "1,326"
      }
    }
  },
  "Statisticians": {
    "ageData": {
      "2011": {
        "total": "37",
        "16to19": "0",
        "20to24": "2",
        "25to34": "9",
        "35to44": "14",
        "45to54": "8",
        "55to64": "5",
        "65+": "0",
        "medianAge": "5"
      }
    },
    "title": "Statisticians",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "37",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "30",
        "median": "-",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "13",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous mathematical science occupations": {
    "ageData": {
      "2011": {
        "total": "6",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "0",
        "45to54": "2",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Miscellaneous mathematical science occupations",
    "depth": 4,
    "parent": "Computer and mathematical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "6",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Architecture and engineering occupations": {
    "ageData": {
      "2011": {
        "total": "2,785",
        "16to19": "8",
        "20to24": "139",
        "25to34": "650",
        "35to44": "642",
        "45to54": "755",
        "55to64": "487",
        "65+": "102",
        "medianAge": "487"
      }
    },
    "title": "Architecture and engineering occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "2,785",
        "women": "13.6",
        "white": "5.2",
        "black": "8.8",
        "asian": "6.4"
      }
    },
    "wageData": {
      "2011": {
        "workers": "2,494",
        "median": "1,315",
        "maleWorkers": "2,178",
        "maleMedian": "1,343",
        "femaleWorkers": "316",
        "femaleMedian": "1,140"
      }
    }
  },
  "Architects, except naval": {
    "ageData": {
      "2011": {
        "total": "181",
        "16to19": "0",
        "20to24": "4",
        "25to34": "41",
        "35to44": "36",
        "45to54": "49",
        "55to64": "41",
        "65+": "10",
        "medianAge": "41"
      }
    },
    "title": "Architects, except naval",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "181",
        "women": "20.7",
        "white": "1.6",
        "black": "5.5",
        "asian": "4.1"
      }
    },
    "wageData": {
      "2011": {
        "workers": "113",
        "median": "1,325",
        "maleWorkers": "92",
        "maleMedian": "1,351",
        "femaleWorkers": "21",
        "femaleMedian": "-"
      }
    }
  },
  "Surveyors, cartographers, and photogrammetrists": {
    "ageData": {
      "2011": {
        "total": "42",
        "16to19": "0",
        "20to24": "4",
        "25to34": "13",
        "35to44": "8",
        "45to54": "7",
        "55to64": "10",
        "65+": "2",
        "medianAge": "10"
      }
    },
    "title": "Surveyors, cartographers, and photogrammetrists",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "42",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "31",
        "median": "-",
        "maleWorkers": "26",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Aerospace engineers": {
    "ageData": {
      "2011": {
        "total": "144",
        "16to19": "0",
        "20to24": "6",
        "25to34": "36",
        "35to44": "33",
        "45to54": "37",
        "55to64": "27",
        "65+": "5",
        "medianAge": "27"
      }
    },
    "title": "Aerospace engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "144",
        "women": "12.4",
        "white": "5.7",
        "black": "8.0",
        "asian": "8.2"
      }
    },
    "wageData": {
      "2011": {
        "workers": "134",
        "median": "1,621",
        "maleWorkers": "119",
        "maleMedian": "1,745",
        "femaleWorkers": "16",
        "femaleMedian": "-"
      }
    }
  },
  "Agricultural engineers": {
    "ageData": {
      "2011": {
        "total": "1",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "1",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Agricultural engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Biomedical engineers": {
    "ageData": {
      "2011": {
        "total": "12",
        "16to19": "1",
        "20to24": "3",
        "25to34": "3",
        "35to44": "2",
        "45to54": "3",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Biomedical engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "12",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Chemical engineers": {
    "ageData": {
      "2011": {
        "total": "74",
        "16to19": "0",
        "20to24": "3",
        "25to34": "20",
        "35to44": "19",
        "45to54": "20",
        "55to64": "12",
        "65+": "1",
        "medianAge": "12"
      }
    },
    "title": "Chemical engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "74",
        "women": "22.3",
        "white": "5.5",
        "black": "13.8",
        "asian": "5.1"
      }
    },
    "wageData": {
      "2011": {
        "workers": "73",
        "median": "1,757",
        "maleWorkers": "57",
        "maleMedian": "1,885",
        "femaleWorkers": "16",
        "femaleMedian": "-"
      }
    }
  },
  "Civil engineers": {
    "ageData": {
      "2011": {
        "total": "383",
        "16to19": "0",
        "20to24": "20",
        "25to34": "100",
        "35to44": "87",
        "45to54": "97",
        "55to64": "62",
        "65+": "17",
        "medianAge": "62"
      }
    },
    "title": "Civil engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "383",
        "women": "13.1",
        "white": "4.3",
        "black": "7.8",
        "asian": "4.7"
      }
    },
    "wageData": {
      "2011": {
        "workers": "335",
        "median": "1,398",
        "maleWorkers": "289",
        "maleMedian": "1,436",
        "femaleWorkers": "46",
        "femaleMedian": "-"
      }
    }
  },
  "Computer hardware engineers": {
    "ageData": {
      "2011": {
        "total": "77",
        "16to19": "1",
        "20to24": "4",
        "25to34": "22",
        "35to44": "20",
        "45to54": "20",
        "55to64": "8",
        "65+": "3",
        "medianAge": "8"
      }
    },
    "title": "Computer hardware engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "77",
        "women": "13.2",
        "white": "3.1",
        "black": "19.1",
        "asian": "5.2"
      }
    },
    "wageData": {
      "2011": {
        "workers": "76",
        "median": "1,528",
        "maleWorkers": "66",
        "maleMedian": "1,546",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Electrical and electronics engineers": {
    "ageData": {
      "2011": {
        "total": "309",
        "16to19": "0",
        "20to24": "14",
        "25to34": "73",
        "35to44": "68",
        "45to54": "96",
        "55to64": "47",
        "65+": "12",
        "medianAge": "47"
      }
    },
    "title": "Electrical and electronics engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "309",
        "women": "8.8",
        "white": "4.8",
        "black": "12.0",
        "asian": "7.4"
      }
    },
    "wageData": {
      "2011": {
        "workers": "283",
        "median": "1,442",
        "maleWorkers": "256",
        "maleMedian": "1,455",
        "femaleWorkers": "26",
        "femaleMedian": "-"
      }
    }
  },
  "Environmental engineers": {
    "ageData": {
      "2011": {
        "total": "45",
        "16to19": "0",
        "20to24": "1",
        "25to34": "7",
        "35to44": "11",
        "45to54": "15",
        "55to64": "8",
        "65+": "1",
        "medianAge": "8"
      }
    },
    "title": "Environmental engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "45",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "36",
        "median": "-",
        "maleWorkers": "26",
        "maleMedian": "-",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Industrial engineers, including health and safety": {
    "ageData": {
      "2011": {
        "total": "174",
        "16to19": "0",
        "20to24": "7",
        "25to34": "39",
        "35to44": "46",
        "45to54": "53",
        "55to64": "24",
        "65+": "5",
        "medianAge": "24"
      }
    },
    "title": "Industrial engineers, including health and safety",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "174",
        "women": "17.8",
        "white": "2.3",
        "black": "7.0",
        "asian": "8.6"
      }
    },
    "wageData": {
      "2011": {
        "workers": "178",
        "median": "1,336",
        "maleWorkers": "143",
        "maleMedian": "1,356",
        "femaleWorkers": "34",
        "femaleMedian": "-"
      }
    }
  },
  "Marine engineers and naval architects": {
    "ageData": {
      "2011": {
        "total": "9",
        "16to19": "0",
        "20to24": "1",
        "25to34": "2",
        "35to44": "3",
        "45to54": "1",
        "55to64": "1",
        "65+": "1",
        "medianAge": "1"
      }
    },
    "title": "Marine engineers and naval architects",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "9",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Materials engineers": {
    "ageData": {
      "2011": {
        "total": "32",
        "16to19": "0",
        "20to24": "0",
        "25to34": "6",
        "35to44": "11",
        "45to54": "10",
        "55to64": "5",
        "65+": "0",
        "medianAge": "5"
      }
    },
    "title": "Materials engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "32",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "30",
        "median": "-",
        "maleWorkers": "26",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Mechanical engineers": {
    "ageData": {
      "2011": {
        "total": "322",
        "16to19": "0",
        "20to24": "12",
        "25to34": "83",
        "35to44": "78",
        "45to54": "92",
        "55to64": "43",
        "65+": "14",
        "medianAge": "43"
      }
    },
    "title": "Mechanical engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "322",
        "women": "5.5",
        "white": "5.7",
        "black": "12.0",
        "asian": "3.9"
      }
    },
    "wageData": {
      "2011": {
        "workers": "306",
        "median": "1,374",
        "maleWorkers": "290",
        "maleMedian": "1,399",
        "femaleWorkers": "16",
        "femaleMedian": "-"
      }
    }
  },
  "Mining and geological engineers, including mining safety engineers": {
    "ageData": {
      "2011": {
        "total": "11",
        "16to19": "0",
        "20to24": "2",
        "25to34": "1",
        "35to44": "3",
        "45to54": "2",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Mining and geological engineers, including mining safety engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "11",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "9",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Nuclear engineers": {
    "ageData": {
      "2011": {
        "total": "20",
        "16to19": "0",
        "20to24": "2",
        "25to34": "7",
        "35to44": "1",
        "45to54": "3",
        "55to64": "7",
        "65+": "1",
        "medianAge": "7"
      }
    },
    "title": "Nuclear engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "20",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "23",
        "median": "-",
        "maleWorkers": "20",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Petroleum engineers": {
    "ageData": {
      "2011": {
        "total": "25",
        "16to19": "0",
        "20to24": "3",
        "25to34": "6",
        "35to44": "3",
        "45to54": "6",
        "55to64": "7",
        "65+": "0",
        "medianAge": "7"
      }
    },
    "title": "Petroleum engineers",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "25",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "19",
        "median": "-",
        "maleWorkers": "18",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Engineers, all other": {
    "ageData": {
      "2011": {
        "total": "337",
        "16to19": "0",
        "20to24": "14",
        "25to34": "92",
        "35to44": "74",
        "45to54": "83",
        "55to64": "59",
        "65+": "15",
        "medianAge": "59"
      }
    },
    "title": "Engineers, all other",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "337",
        "women": "10.8",
        "white": "5.7",
        "black": "10.5",
        "asian": "4.7"
      }
    },
    "wageData": {
      "2011": {
        "workers": "310",
        "median": "1,366",
        "maleWorkers": "275",
        "maleMedian": "1,361",
        "femaleWorkers": "35",
        "femaleMedian": "-"
      }
    }
  },
  "Drafters": {
    "ageData": {
      "2011": {
        "total": "147",
        "16to19": "1",
        "20to24": "10",
        "25to34": "31",
        "35to44": "38",
        "45to54": "32",
        "55to64": "31",
        "65+": "5",
        "medianAge": "31"
      }
    },
    "title": "Drafters",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "147",
        "women": "20.8",
        "white": "2.1",
        "black": "3.9",
        "asian": "8.4"
      }
    },
    "wageData": {
      "2011": {
        "workers": "124",
        "median": "941",
        "maleWorkers": "102",
        "maleMedian": "1,052",
        "femaleWorkers": "23",
        "femaleMedian": "-"
      }
    }
  },
  "Engineering technicians, except drafters": {
    "ageData": {
      "2011": {
        "total": "376",
        "16to19": "4",
        "20to24": "28",
        "25to34": "55",
        "35to44": "83",
        "45to54": "113",
        "55to64": "83",
        "65+": "10",
        "medianAge": "83"
      }
    },
    "title": "Engineering technicians, except drafters",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "376",
        "women": "16.3",
        "white": "11.3",
        "black": "6.7",
        "asian": "9.4"
      }
    },
    "wageData": {
      "2011": {
        "workers": "341",
        "median": "954",
        "maleWorkers": "301",
        "maleMedian": "952",
        "femaleWorkers": "40",
        "femaleMedian": "-"
      }
    }
  },
  "Surveying and mapping technicians": {
    "ageData": {
      "2011": {
        "total": "62",
        "16to19": "0",
        "20to24": "3",
        "25to34": "14",
        "35to44": "16",
        "45to54": "18",
        "55to64": "8",
        "65+": "2",
        "medianAge": "8"
      }
    },
    "title": "Surveying and mapping technicians",
    "depth": 4,
    "parent": "Architecture and engineering occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "62",
        "women": "12.2",
        "white": "1.7",
        "black": "1.9",
        "asian": "10.0"
      }
    },
    "wageData": {
      "2011": {
        "workers": "53",
        "median": "777",
        "maleWorkers": "47",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Life, physical, and social science occupations": {
    "ageData": {
      "2011": {
        "total": "1,303",
        "16to19": "5",
        "20to24": "85",
        "25to34": "366",
        "35to44": "294",
        "45to54": "261",
        "55to64": "229",
        "65+": "63",
        "medianAge": "229"
      }
    },
    "title": "Life, physical, and social science occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "1,303",
        "women": "47.3",
        "white": "7.3",
        "black": "9.8",
        "asian": "5.9"
      }
    },
    "wageData": {
      "2011": {
        "workers": "1,043",
        "median": "1,108",
        "maleWorkers": "565",
        "maleMedian": "1,156",
        "femaleWorkers": "479",
        "femaleMedian": "1,038"
      }
    }
  },
  "Agricultural and food scientists": {
    "ageData": {
      "2011": {
        "total": "39",
        "16to19": "0",
        "20to24": "4",
        "25to34": "11",
        "35to44": "4",
        "45to54": "9",
        "55to64": "8",
        "65+": "2",
        "medianAge": "8"
      }
    },
    "title": "Agricultural and food scientists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "39",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "27",
        "median": "-",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Biological scientists": {
    "ageData": {
      "2011": {
        "total": "114",
        "16to19": "0",
        "20to24": "5",
        "25to34": "37",
        "35to44": "23",
        "45to54": "23",
        "55to64": "24",
        "65+": "2",
        "medianAge": "24"
      }
    },
    "title": "Biological scientists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "114",
        "women": "48.2",
        "white": "7.7",
        "black": "13.5",
        "asian": "6.8"
      }
    },
    "wageData": {
      "2011": {
        "workers": "103",
        "median": "1,031",
        "maleWorkers": "53",
        "maleMedian": "1,177",
        "femaleWorkers": "50",
        "femaleMedian": "853"
      }
    }
  },
  "Conservation scientists and foresters": {
    "ageData": {
      "2011": {
        "total": "28",
        "16to19": "0",
        "20to24": "1",
        "25to34": "7",
        "35to44": "2",
        "45to54": "6",
        "55to64": "11",
        "65+": "1",
        "medianAge": "11"
      }
    },
    "title": "Conservation scientists and foresters",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "28",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "22",
        "median": "-",
        "maleWorkers": "18",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Medical scientists": {
    "ageData": {
      "2011": {
        "total": "156",
        "16to19": "0",
        "20to24": "9",
        "25to34": "49",
        "35to44": "46",
        "45to54": "29",
        "55to64": "18",
        "65+": "5",
        "medianAge": "18"
      }
    },
    "title": "Medical scientists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "156",
        "women": "54.4",
        "white": "5.2",
        "black": "25.1",
        "asian": "7.0"
      }
    },
    "wageData": {
      "2011": {
        "workers": "141",
        "median": "1,109",
        "maleWorkers": "64",
        "maleMedian": "1,102",
        "femaleWorkers": "78",
        "femaleMedian": "1,127"
      }
    }
  },
  "Life scientists, all other": {
    "ageData": {
      "2011": {
        "total": "0",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Life scientists, all other",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "0",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    }
  },
  "Astronomers and physicists": {
    "ageData": {
      "2011": {
        "total": "20",
        "16to19": "0",
        "20to24": "0",
        "25to34": "8",
        "35to44": "2",
        "45to54": "5",
        "55to64": "3",
        "65+": "3",
        "medianAge": "3"
      }
    },
    "title": "Astronomers and physicists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "20",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "18",
        "median": "-",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Atmospheric and space scientists": {
    "ageData": {
      "2011": {
        "total": "7",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "2",
        "45to54": "2",
        "55to64": "0",
        "65+": "1",
        "medianAge": "0"
      }
    },
    "title": "Atmospheric and space scientists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Chemists and materials scientists": {
    "ageData": {
      "2011": {
        "total": "88",
        "16to19": "0",
        "20to24": "4",
        "25to34": "25",
        "35to44": "22",
        "45to54": "20",
        "55to64": "13",
        "65+": "4",
        "medianAge": "13"
      }
    },
    "title": "Chemists and materials scientists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "88",
        "women": "45.9",
        "white": "9.5",
        "black": "11.5",
        "asian": "4.6"
      }
    },
    "wageData": {
      "2011": {
        "workers": "83",
        "median": "1,169",
        "maleWorkers": "45",
        "maleMedian": "-",
        "femaleWorkers": "38",
        "femaleMedian": "-"
      }
    }
  },
  "Environmental scientists and geoscientists": {
    "ageData": {
      "2011": {
        "total": "98",
        "16to19": "0",
        "20to24": "3",
        "25to34": "24",
        "35to44": "20",
        "45to54": "28",
        "55to64": "19",
        "65+": "3",
        "medianAge": "19"
      }
    },
    "title": "Environmental scientists and geoscientists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "98",
        "women": "28.9",
        "white": "4.9",
        "black": "0.6",
        "asian": "3.0"
      }
    },
    "wageData": {
      "2011": {
        "workers": "86",
        "median": "1,383",
        "maleWorkers": "61",
        "maleMedian": "1,408",
        "femaleWorkers": "25",
        "femaleMedian": "-"
      }
    }
  },
  "Physical scientists, all other": {
    "ageData": {
      "2011": {
        "total": "152",
        "16to19": "0",
        "20to24": "6",
        "25to34": "52",
        "35to44": "41",
        "45to54": "29",
        "55to64": "19",
        "65+": "5",
        "medianAge": "19"
      }
    },
    "title": "Physical scientists, all other",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "152",
        "women": "39.8",
        "white": "6.7",
        "black": "19.9",
        "asian": "2.9"
      }
    },
    "wageData": {
      "2011": {
        "workers": "133",
        "median": "1,383",
        "maleWorkers": "82",
        "maleMedian": "1,483",
        "femaleWorkers": "51",
        "femaleMedian": "1,167"
      }
    }
  },
  "Economists": {
    "ageData": {
      "2011": {
        "total": "23",
        "16to19": "0",
        "20to24": "1",
        "25to34": "3",
        "35to44": "6",
        "45to54": "4",
        "55to64": "9",
        "65+": "0",
        "medianAge": "9"
      }
    },
    "title": "Economists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "23",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "20",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Survey researchers": {
    "ageData": {
      "2011": {
        "total": "3",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "1",
        "45to54": "1",
        "55to64": "0",
        "65+": "1",
        "medianAge": "0"
      }
    },
    "title": "Survey researchers",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Psychologists": {
    "ageData": {
      "2011": {
        "total": "197",
        "16to19": "0",
        "20to24": "2",
        "25to34": "40",
        "35to44": "46",
        "45to54": "35",
        "55to64": "46",
        "65+": "27",
        "medianAge": "46"
      }
    },
    "title": "Psychologists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "197",
        "women": "71.2",
        "white": "5.1",
        "black": "2.9",
        "asian": "5.9"
      }
    },
    "wageData": {
      "2011": {
        "workers": "104",
        "median": "1,229",
        "maleWorkers": "31",
        "maleMedian": "-",
        "femaleWorkers": "74",
        "femaleMedian": "1,244"
      }
    }
  },
  "Sociologists": {
    "ageData": {
      "2011": {
        "total": "4",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "2",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Sociologists",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Urban and regional planners": {
    "ageData": {
      "2011": {
        "total": "26",
        "16to19": "0",
        "20to24": "0",
        "25to34": "5",
        "35to44": "6",
        "45to54": "8",
        "55to64": "7",
        "65+": "1",
        "medianAge": "7"
      }
    },
    "title": "Urban and regional planners",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "26",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "19",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "9",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous social scientists and related workers": {
    "ageData": {
      "2011": {
        "total": "60",
        "16to19": "0",
        "20to24": "4",
        "25to34": "20",
        "35to44": "10",
        "45to54": "10",
        "55to64": "14",
        "65+": "2",
        "medianAge": "14"
      }
    },
    "title": "Miscellaneous social scientists and related workers",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "60",
        "women": "55.4",
        "white": "10.7",
        "black": "4.4",
        "asian": "9.4"
      }
    },
    "wageData": {
      "2011": {
        "workers": "48",
        "median": "-",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "29",
        "femaleMedian": "-"
      }
    }
  },
  "Agricultural and food science technicians": {
    "ageData": {
      "2011": {
        "total": "24",
        "16to19": "0",
        "20to24": "3",
        "25to34": "6",
        "35to44": "6",
        "45to54": "5",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Agricultural and food science technicians",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "24",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Biological technicians": {
    "ageData": {
      "2011": {
        "total": "21",
        "16to19": "1",
        "20to24": "2",
        "25to34": "7",
        "35to44": "4",
        "45to54": "4",
        "55to64": "1",
        "65+": "1",
        "medianAge": "1"
      }
    },
    "title": "Biological technicians",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "21",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "19",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "9",
        "femaleMedian": "-"
      }
    }
  },
  "Chemical technicians": {
    "ageData": {
      "2011": {
        "total": "77",
        "16to19": "0",
        "20to24": "8",
        "25to34": "19",
        "35to44": "19",
        "45to54": "20",
        "55to64": "10",
        "65+": "1",
        "medianAge": "10"
      }
    },
    "title": "Chemical technicians",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "77",
        "women": "40.3",
        "white": "9.1",
        "black": "5.1",
        "asian": "14.3"
      }
    },
    "wageData": {
      "2011": {
        "workers": "69",
        "median": "788",
        "maleWorkers": "40",
        "maleMedian": "-",
        "femaleWorkers": "29",
        "femaleMedian": "-"
      }
    }
  },
  "Geological and petroleum technicians": {
    "ageData": {
      "2011": {
        "total": "10",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "1",
        "45to54": "2",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Geological and petroleum technicians",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "10",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Nuclear technicians": {
    "ageData": {
      "2011": {
        "total": "3",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "0",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Nuclear technicians",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Social science research assistants": {
    "ageData": {
      "2011": {
        "total": "3",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "0",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Social science research assistants",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous life, physical, and social science technicians": {
    "ageData": {
      "2011": {
        "total": "151",
        "16to19": "3",
        "20to24": "32",
        "25to34": "45",
        "35to44": "32",
        "45to54": "18",
        "55to64": "18",
        "65+": "4",
        "medianAge": "18"
      }
    },
    "title": "Miscellaneous life, physical, and social science technicians",
    "depth": 4,
    "parent": "Life, physical, and social science occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "151",
        "women": "41.9",
        "white": "11.1",
        "black": "9.4",
        "asian": "6.5"
      }
    },
    "wageData": {
      "2011": {
        "workers": "118",
        "median": "763",
        "maleWorkers": "69",
        "maleMedian": "807",
        "femaleWorkers": "49",
        "femaleMedian": "-"
      }
    }
  },
  "Community and social service occupations": {
    "ageData": {
      "2011": {
        "total": "2,352",
        "16to19": "15",
        "20to24": "123",
        "25to34": "536",
        "35to44": "516",
        "45to54": "527",
        "55to64": "489",
        "65+": "146",
        "medianAge": "489"
      }
    },
    "title": "Community and social service occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "2,352",
        "women": "64.5",
        "white": "18.1",
        "black": "2.8",
        "asian": "10.7"
      }
    },
    "wageData": {
      "2011": {
        "workers": "1,931",
        "median": "813",
        "maleWorkers": "728",
        "maleMedian": "906",
        "femaleWorkers": "1,202",
        "femaleMedian": "772"
      }
    }
  },
  "Counselors": {
    "ageData": {
      "2011": {
        "total": "732",
        "16to19": "8",
        "20to24": "45",
        "25to34": "200",
        "35to44": "149",
        "45to54": "141",
        "55to64": "152",
        "65+": "37",
        "medianAge": "152"
      }
    },
    "title": "Counselors",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "732",
        "women": "69.9",
        "white": "18.7",
        "black": "2.8",
        "asian": "11.2"
      }
    },
    "wageData": {
      "2011": {
        "workers": "568",
        "median": "828",
        "maleWorkers": "188",
        "maleMedian": "874",
        "femaleWorkers": "380",
        "femaleMedian": "808"
      }
    }
  },
  "Social workers": {
    "ageData": {
      "2011": {
        "total": "769",
        "16to19": "1",
        "20to24": "28",
        "25to34": "205",
        "35to44": "206",
        "45to54": "174",
        "55to64": "132",
        "65+": "24",
        "medianAge": "132"
      }
    },
    "title": "Social workers",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "769",
        "women": "81.6",
        "white": "22.8",
        "black": "2.4",
        "asian": "11.2"
      }
    },
    "wageData": {
      "2011": {
        "workers": "684",
        "median": "817",
        "maleWorkers": "130",
        "maleMedian": "902",
        "femaleWorkers": "554",
        "femaleMedian": "798"
      }
    }
  },
  "Probation officers and correctional treatment specialists": {
    "ageData": {
      "2011": {
        "total": "94",
        "16to19": "0",
        "20to24": "4",
        "25to34": "30",
        "35to44": "29",
        "45to54": "20",
        "55to64": "9",
        "65+": "2",
        "medianAge": "9"
      }
    },
    "title": "Probation officers and correctional treatment specialists",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "94",
        "women": "56.8",
        "white": "22.5",
        "black": "0.6",
        "asian": "13.5"
      }
    },
    "wageData": {
      "2011": {
        "workers": "86",
        "median": "822",
        "maleWorkers": "41",
        "maleMedian": "-",
        "femaleWorkers": "44",
        "femaleMedian": "-"
      }
    }
  },
  "Social and human service assistants": {
    "ageData": {
      "2011": {
        "total": "131",
        "16to19": "3",
        "20to24": "14",
        "25to34": "29",
        "35to44": "22",
        "45to54": "32",
        "55to64": "27",
        "65+": "5",
        "medianAge": "27"
      }
    },
    "title": "Social and human service assistants",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "131",
        "women": "82.4",
        "white": "18.0",
        "black": "2.6",
        "asian": "19.0"
      }
    },
    "wageData": {
      "2011": {
        "workers": "105",
        "median": "597",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "83",
        "femaleMedian": "597"
      }
    }
  },
  "Miscellaneous community and social service specialists, including health educators and community health workers": {
    "ageData": {
      "2011": {
        "total": "83",
        "16to19": "2",
        "20to24": "10",
        "25to34": "19",
        "35to44": "15",
        "45to54": "20",
        "55to64": "12",
        "65+": "5",
        "medianAge": "12"
      }
    },
    "title": "Miscellaneous community and social service specialists, including health educators and community health workers",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "83",
        "women": "67.5",
        "white": "17.6",
        "black": "2.9",
        "asian": "10.9"
      }
    },
    "wageData": {
      "2011": {
        "workers": "62",
        "median": "676",
        "maleWorkers": "23",
        "maleMedian": "-",
        "femaleWorkers": "39",
        "femaleMedian": "-"
      }
    }
  },
  "Clergy": {
    "ageData": {
      "2011": {
        "total": "414",
        "16to19": "1",
        "20to24": "10",
        "25to34": "39",
        "35to44": "73",
        "45to54": "106",
        "55to64": "128",
        "65+": "58",
        "medianAge": "128"
      }
    },
    "title": "Clergy",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "414",
        "women": "17.7",
        "white": "11.3",
        "black": "4.1",
        "asian": "6.8"
      }
    },
    "wageData": {
      "2011": {
        "workers": "343",
        "median": "945",
        "maleWorkers": "292",
        "maleMedian": "961",
        "femaleWorkers": "51",
        "femaleMedian": "889"
      }
    }
  },
  "Directors, religious activities and education": {
    "ageData": {
      "2011": {
        "total": "44",
        "16to19": "0",
        "20to24": "4",
        "25to34": "8",
        "35to44": "8",
        "45to54": "14",
        "55to64": "9",
        "65+": "2",
        "medianAge": "9"
      }
    },
    "title": "Directors, religious activities and education",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "44",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "34",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "25",
        "femaleMedian": "-"
      }
    }
  },
  "Religious workers, all other": {
    "ageData": {
      "2011": {
        "total": "85",
        "16to19": "0",
        "20to24": "8",
        "25to34": "7",
        "35to44": "14",
        "45to54": "21",
        "55to64": "21",
        "65+": "13",
        "medianAge": "21"
      }
    },
    "title": "Religious workers, all other",
    "depth": 4,
    "parent": "Community and social service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "85",
        "women": "67.9",
        "white": "8.5",
        "black": "3.0",
        "asian": "8.6"
      }
    },
    "wageData": {
      "2011": {
        "workers": "50",
        "median": "654",
        "maleWorkers": "24",
        "maleMedian": "-",
        "femaleWorkers": "26",
        "femaleMedian": "-"
      }
    }
  },
  "Legal occupations": {
    "ageData": {
      "2011": {
        "total": "1,770",
        "16to19": "3",
        "20to24": "41",
        "25to34": "387",
        "35to44": "406",
        "45to54": "425",
        "55to64": "378",
        "65+": "131",
        "medianAge": "378"
      }
    },
    "title": "Legal occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "1,770",
        "women": "49.8",
        "white": "7.3",
        "black": "3.9",
        "asian": "5.4"
      }
    },
    "wageData": {
      "2011": {
        "workers": "1,259",
        "median": "1,277",
        "maleWorkers": "583",
        "maleMedian": "1,758",
        "femaleWorkers": "676",
        "femaleMedian": "1,003"
      }
    }
  },
  "Lawyers": {
    "ageData": {
      "2011": {
        "total": "1,085",
        "16to19": "0",
        "20to24": "2",
        "25to34": "224",
        "35to44": "263",
        "45to54": "255",
        "55to64": "238",
        "65+": "102",
        "medianAge": "238"
      }
    },
    "title": "Lawyers",
    "depth": 4,
    "parent": "Legal occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "1,085",
        "women": "31.9",
        "white": "5.3",
        "black": "4.2",
        "asian": "3.2"
      }
    },
    "wageData": {
      "2011": {
        "workers": "704",
        "median": "1,774",
        "maleWorkers": "462",
        "maleMedian": "1,884",
        "femaleWorkers": "242",
        "femaleMedian": "1,631"
      }
    }
  },
  "Judicial law clerks": {
    "ageData": {
      "2011": {
        "total": "5",
        "16to19": "0",
        "20to24": "1",
        "25to34": "2",
        "35to44": "1",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Judicial law clerks",
    "depth": 4,
    "parent": "Legal occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Judges, magistrates, and other judicial workers": {
    "ageData": {
      "2011": {
        "total": "67",
        "16to19": "0",
        "20to24": "1",
        "25to34": "10",
        "35to44": "10",
        "45to54": "17",
        "55to64": "21",
        "65+": "8",
        "medianAge": "21"
      }
    },
    "title": "Judges, magistrates, and other judicial workers",
    "depth": 4,
    "parent": "Legal occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "67",
        "women": "44.4",
        "white": "11.5",
        "black": "1.1",
        "asian": "8.3"
      }
    },
    "wageData": {
      "2011": {
        "workers": "58",
        "median": "1,655",
        "maleWorkers": "32",
        "maleMedian": "-",
        "femaleWorkers": "26",
        "femaleMedian": "-"
      }
    }
  },
  "Paralegals and legal assistants": {
    "ageData": {
      "2011": {
        "total": "404",
        "16to19": "3",
        "20to24": "26",
        "25to34": "112",
        "35to44": "79",
        "45to54": "99",
        "55to64": "73",
        "65+": "13",
        "medianAge": "73"
      }
    },
    "title": "Paralegals and legal assistants",
    "depth": 4,
    "parent": "Legal occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "404",
        "women": "84.3",
        "white": "11.0",
        "black": "3.0",
        "asian": "8.3"
      }
    },
    "wageData": {
      "2011": {
        "workers": "338",
        "median": "824",
        "maleWorkers": "52",
        "maleMedian": "884",
        "femaleWorkers": "286",
        "femaleMedian": "813"
      }
    }
  },
  "Miscellaneous legal support workers": {
    "ageData": {
      "2011": {
        "total": "209",
        "16to19": "0",
        "20to24": "12",
        "25to34": "39",
        "35to44": "53",
        "45to54": "52",
        "55to64": "45",
        "65+": "7",
        "medianAge": "45"
      }
    },
    "title": "Miscellaneous legal support workers",
    "depth": 4,
    "parent": "Legal occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "209",
        "women": "76.7",
        "white": "9.3",
        "black": "4.3",
        "asian": "10.5"
      }
    },
    "wageData": {
      "2011": {
        "workers": "155",
        "median": "815",
        "maleWorkers": "35",
        "maleMedian": "-",
        "femaleWorkers": "120",
        "femaleMedian": "788"
      }
    }
  },
  "Education, training, and library occupations": {
    "ageData": {
      "2011": {
        "total": "8,619",
        "16to19": "92",
        "20to24": "579",
        "25to34": "1,957",
        "35to44": "1,946",
        "45to54": "2,013",
        "55to64": "1,585",
        "65+": "446",
        "medianAge": "1,585"
      }
    },
    "title": "Education, training, and library occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "8,619",
        "women": "73.6",
        "white": "9.7",
        "black": "3.5",
        "asian": "8.3"
      }
    },
    "wageData": {
      "2011": {
        "workers": "6,518",
        "median": "919",
        "maleWorkers": "1,749",
        "maleMedian": "1,109",
        "femaleWorkers": "4,769",
        "femaleMedian": "869"
      }
    }
  },
  "Postsecondary teachers": {
    "ageData": {
      "2011": {
        "total": "1,355",
        "16to19": "5",
        "20to24": "86",
        "25to34": "299",
        "35to44": "270",
        "45to54": "281",
        "55to64": "279",
        "65+": "135",
        "medianAge": "279"
      }
    },
    "title": "Postsecondary teachers",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "1,355",
        "women": "46.2",
        "white": "7.3",
        "black": "10.1",
        "asian": "4.8"
      }
    },
    "wageData": {
      "2011": {
        "workers": "925",
        "median": "1,209",
        "maleWorkers": "526",
        "maleMedian": "1,358",
        "femaleWorkers": "399",
        "femaleMedian": "1,093"
      }
    }
  },
  "Preschool and kindergarten teachers": {
    "ageData": {
      "2011": {
        "total": "707",
        "16to19": "6",
        "20to24": "92",
        "25to34": "184",
        "35to44": "182",
        "45to54": "142",
        "55to64": "85",
        "65+": "16",
        "medianAge": "85"
      }
    },
    "title": "Preschool and kindergarten teachers",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "707",
        "women": "97.7",
        "white": "14.5",
        "black": "2.8",
        "asian": "12.7"
      }
    },
    "wageData": {
      "2011": {
        "workers": "532",
        "median": "606",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "519",
        "femaleMedian": "603"
      }
    }
  },
  "Elementary and middle school teachers": {
    "ageData": {
      "2011": {
        "total": "2,848",
        "16to19": "7",
        "20to24": "124",
        "25to34": "701",
        "35to44": "741",
        "45to54": "676",
        "55to64": "507",
        "65+": "92",
        "medianAge": "507"
      }
    },
    "title": "Elementary and middle school teachers",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "2,848",
        "women": "81.7",
        "white": "9.8",
        "black": "1.6",
        "asian": "8.0"
      }
    },
    "wageData": {
      "2011": {
        "workers": "2,436",
        "median": "947",
        "maleWorkers": "463",
        "maleMedian": "1,022",
        "femaleWorkers": "1,973",
        "femaleMedian": "933"
      }
    }
  },
  "Secondary school teachers": {
    "ageData": {
      "2011": {
        "total": "1,136",
        "16to19": "5",
        "20to24": "44",
        "25to34": "289",
        "35to44": "279",
        "45to54": "271",
        "55to64": "205",
        "65+": "42",
        "medianAge": "205"
      }
    },
    "title": "Secondary school teachers",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "1,136",
        "women": "58.0",
        "white": "7.4",
        "black": "2.1",
        "asian": "6.9"
      }
    },
    "wageData": {
      "2011": {
        "workers": "1,005",
        "median": "1,015",
        "maleWorkers": "431",
        "maleMedian": "1,049",
        "femaleWorkers": "575",
        "femaleMedian": "989"
      }
    }
  },
  "Special education teachers": {
    "ageData": {
      "2011": {
        "total": "388",
        "16to19": "1",
        "20to24": "11",
        "25to34": "102",
        "35to44": "93",
        "45to54": "104",
        "55to64": "65",
        "65+": "12",
        "medianAge": "65"
      }
    },
    "title": "Special education teachers",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "388",
        "women": "85.4",
        "white": "8.0",
        "black": "1.8",
        "asian": "6.8"
      }
    },
    "wageData": {
      "2011": {
        "workers": "364",
        "median": "939",
        "maleWorkers": "55",
        "maleMedian": "967",
        "femaleWorkers": "308",
        "femaleMedian": "935"
      }
    }
  },
  "Other teachers and instructors": {
    "ageData": {
      "2011": {
        "total": "812",
        "16to19": "37",
        "20to24": "95",
        "25to34": "157",
        "35to44": "133",
        "45to54": "169",
        "55to64": "146",
        "65+": "75",
        "medianAge": "146"
      }
    },
    "title": "Other teachers and instructors",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "812",
        "women": "62.6",
        "white": "8.0",
        "black": "3.9",
        "asian": "7.3"
      }
    },
    "wageData": {
      "2011": {
        "workers": "368",
        "median": "858",
        "maleWorkers": "163",
        "maleMedian": "1,105",
        "femaleWorkers": "205",
        "femaleMedian": "739"
      }
    }
  },
  "Archivists, curators, and museum technicians": {
    "ageData": {
      "2011": {
        "total": "48",
        "16to19": "1",
        "20to24": "3",
        "25to34": "5",
        "35to44": "7",
        "45to54": "13",
        "55to64": "16",
        "65+": "3",
        "medianAge": "16"
      }
    },
    "title": "Archivists, curators, and museum technicians",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "48",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "38",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "26",
        "femaleMedian": "-"
      }
    }
  },
  "Librarians": {
    "ageData": {
      "2011": {
        "total": "198",
        "16to19": "1",
        "20to24": "10",
        "25to34": "27",
        "35to44": "29",
        "45to54": "46",
        "55to64": "64",
        "65+": "22",
        "medianAge": "64"
      }
    },
    "title": "Librarians",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "198",
        "women": "86.2",
        "white": "10.1",
        "black": "2.6",
        "asian": "3.9"
      }
    },
    "wageData": {
      "2011": {
        "workers": "139",
        "median": "850",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "118",
        "femaleMedian": "813"
      }
    }
  },
  "Library technicians": {
    "ageData": {
      "2011": {
        "total": "37",
        "16to19": "6",
        "20to24": "7",
        "25to34": "6",
        "35to44": "5",
        "45to54": "5",
        "55to64": "6",
        "65+": "2",
        "medianAge": "6"
      }
    },
    "title": "Library technicians",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "37",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Teacher assistants": {
    "ageData": {
      "2011": {
        "total": "950",
        "16to19": "23",
        "20to24": "99",
        "25to34": "163",
        "35to44": "175",
        "45to54": "276",
        "55to64": "178",
        "65+": "36",
        "medianAge": "178"
      }
    },
    "title": "Teacher assistants",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "950",
        "women": "92.2",
        "white": "14.3",
        "black": "2.6",
        "asian": "14.9"
      }
    },
    "wageData": {
      "2011": {
        "workers": "605",
        "median": "480",
        "maleWorkers": "39",
        "maleMedian": "-",
        "femaleWorkers": "566",
        "femaleMedian": "471"
      }
    }
  },
  "Other education, training, and library workers": {
    "ageData": {
      "2011": {
        "total": "140",
        "16to19": "2",
        "20to24": "9",
        "25to34": "23",
        "35to44": "32",
        "45to54": "30",
        "55to64": "34",
        "65+": "11",
        "medianAge": "34"
      }
    },
    "title": "Other education, training, and library workers",
    "depth": 4,
    "parent": "Education, training, and library occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "140",
        "women": "71.2",
        "white": "7.7",
        "black": "3.4",
        "asian": "9.2"
      }
    },
    "wageData": {
      "2011": {
        "workers": "94",
        "median": "982",
        "maleWorkers": "23",
        "maleMedian": "-",
        "femaleWorkers": "71",
        "femaleMedian": "979"
      }
    }
  },
  "Arts, design, entertainment, sports, and media occupations": {
    "ageData": {
      "2011": {
        "total": "2,779",
        "16to19": "68",
        "20to24": "273",
        "25to34": "711",
        "35to44": "586",
        "45to54": "563",
        "55to64": "419",
        "65+": "160",
        "medianAge": "419"
      }
    },
    "title": "Arts, design, entertainment, sports, and media occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "2,779",
        "women": "46.1",
        "white": "6.0",
        "black": "3.6",
        "asian": "9.1"
      }
    },
    "wageData": {
      "2011": {
        "workers": "1,464",
        "median": "929",
        "maleWorkers": "850",
        "maleMedian": "995",
        "femaleWorkers": "613",
        "femaleMedian": "856"
      }
    }
  },
  "Artists and related workers": {
    "ageData": {
      "2011": {
        "total": "180",
        "16to19": "1",
        "20to24": "10",
        "25to34": "34",
        "35to44": "49",
        "45to54": "35",
        "55to64": "36",
        "65+": "16",
        "medianAge": "36"
      }
    },
    "title": "Artists and related workers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "180",
        "women": "48.0",
        "white": "3.8",
        "black": "3.8",
        "asian": "2.8"
      }
    },
    "wageData": {
      "2011": {
        "workers": "56",
        "median": "1,115",
        "maleWorkers": "36",
        "maleMedian": "-",
        "femaleWorkers": "21",
        "femaleMedian": "-"
      }
    }
  },
  "Designers": {
    "ageData": {
      "2011": {
        "total": "766",
        "16to19": "5",
        "20to24": "51",
        "25to34": "207",
        "35to44": "170",
        "45to54": "187",
        "55to64": "109",
        "65+": "36",
        "medianAge": "109"
      }
    },
    "title": "Designers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "766",
        "women": "51.3",
        "white": "3.6",
        "black": "3.2",
        "asian": "10.5"
      }
    },
    "wageData": {
      "2011": {
        "workers": "458",
        "median": "950",
        "maleWorkers": "257",
        "maleMedian": "1,045",
        "femaleWorkers": "201",
        "femaleMedian": "816"
      }
    }
  },
  "Actors": {
    "ageData": {
      "2011": {
        "total": "28",
        "16to19": "3",
        "20to24": "4",
        "25to34": "7",
        "35to44": "5",
        "45to54": "6",
        "55to64": "2",
        "65+": "2",
        "medianAge": "2"
      }
    },
    "title": "Actors",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "28",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Producers and directors": {
    "ageData": {
      "2011": {
        "total": "149",
        "16to19": "2",
        "20to24": "15",
        "25to34": "46",
        "35to44": "30",
        "45to54": "31",
        "55to64": "20",
        "65+": "6",
        "medianAge": "20"
      }
    },
    "title": "Producers and directors",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "149",
        "women": "40.7",
        "white": "8.2",
        "black": "3.2",
        "asian": "7.3"
      }
    },
    "wageData": {
      "2011": {
        "workers": "105",
        "median": "1,023",
        "maleWorkers": "58",
        "maleMedian": "1,130",
        "femaleWorkers": "47",
        "femaleMedian": "-"
      }
    }
  },
  "Athletes, coaches, umpires, and related workers": {
    "ageData": {
      "2011": {
        "total": "272",
        "16to19": "39",
        "20to24": "59",
        "25to34": "59",
        "35to44": "41",
        "45to54": "40",
        "55to64": "29",
        "65+": "5",
        "medianAge": "29"
      }
    },
    "title": "Athletes, coaches, umpires, and related workers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "272",
        "women": "34.5",
        "white": "5.4",
        "black": "2.6",
        "asian": "9.7"
      }
    },
    "wageData": {
      "2011": {
        "workers": "109",
        "median": "825",
        "maleWorkers": "84",
        "maleMedian": "919",
        "femaleWorkers": "25",
        "femaleMedian": "-"
      }
    }
  },
  "Dancers and choreographers": {
    "ageData": {
      "2011": {
        "total": "20",
        "16to19": "1",
        "20to24": "8",
        "25to34": "7",
        "35to44": "1",
        "45to54": "2",
        "55to64": "0",
        "65+": "1",
        "medianAge": "0"
      }
    },
    "title": "Dancers and choreographers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "20",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Musicians, singers, and related workers": {
    "ageData": {
      "2011": {
        "total": "191",
        "16to19": "3",
        "20to24": "22",
        "25to34": "40",
        "35to44": "39",
        "45to54": "31",
        "55to64": "38",
        "65+": "18",
        "medianAge": "38"
      }
    },
    "title": "Musicians, singers, and related workers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "191",
        "women": "29.8",
        "white": "15.1",
        "black": "1.2",
        "asian": "6.6"
      }
    },
    "wageData": {
      "2011": {
        "workers": "35",
        "median": "-",
        "maleWorkers": "29",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Entertainers and performers, sports and related workers, all other": {
    "ageData": {
      "2011": {
        "total": "44",
        "16to19": "3",
        "20to24": "7",
        "25to34": "14",
        "35to44": "5",
        "45to54": "3",
        "55to64": "9",
        "65+": "3",
        "medianAge": "9"
      }
    },
    "title": "Entertainers and performers, sports and related workers, all other",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "44",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Announcers": {
    "ageData": {
      "2011": {
        "total": "53",
        "16to19": "0",
        "20to24": "7",
        "25to34": "15",
        "35to44": "12",
        "45to54": "12",
        "55to64": "5",
        "65+": "2",
        "medianAge": "5"
      }
    },
    "title": "Announcers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "53",
        "women": "26.9",
        "white": "8.5",
        "black": "1.1",
        "asian": "24.8"
      }
    },
    "wageData": {
      "2011": {
        "workers": "32",
        "median": "-",
        "maleWorkers": "24",
        "maleMedian": "-",
        "femaleWorkers": "9",
        "femaleMedian": "-"
      }
    }
  },
  "News analysts, reporters and correspondents": {
    "ageData": {
      "2011": {
        "total": "70",
        "16to19": "0",
        "20to24": "5",
        "25to34": "24",
        "35to44": "15",
        "45to54": "13",
        "55to64": "12",
        "65+": "2",
        "medianAge": "12"
      }
    },
    "title": "News analysts, reporters and correspondents",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "70",
        "women": "34.1",
        "white": "9.3",
        "black": "5.0",
        "asian": "11.4"
      }
    },
    "wageData": {
      "2011": {
        "workers": "62",
        "median": "937",
        "maleWorkers": "44",
        "maleMedian": "-",
        "femaleWorkers": "18",
        "femaleMedian": "-"
      }
    }
  },
  "Public relations specialists": {
    "ageData": {
      "2011": {
        "total": "158",
        "16to19": "1",
        "20to24": "14",
        "25to34": "47",
        "35to44": "36",
        "45to54": "29",
        "55to64": "21",
        "65+": "10",
        "medianAge": "21"
      }
    },
    "title": "Public relations specialists",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "158",
        "women": "61.6",
        "white": "6.3",
        "black": "2.7",
        "asian": "9.8"
      }
    },
    "wageData": {
      "2011": {
        "workers": "123",
        "median": "954",
        "maleWorkers": "49",
        "maleMedian": "-",
        "femaleWorkers": "75",
        "femaleMedian": "887"
      }
    }
  },
  "Editors": {
    "ageData": {
      "2011": {
        "total": "166",
        "16to19": "1",
        "20to24": "16",
        "25to34": "49",
        "35to44": "31",
        "45to54": "31",
        "55to64": "27",
        "65+": "11",
        "medianAge": "27"
      }
    },
    "title": "Editors",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "166",
        "women": "54.2",
        "white": "4.6",
        "black": "3.8",
        "asian": "3.4"
      }
    },
    "wageData": {
      "2011": {
        "workers": "132",
        "median": "902",
        "maleWorkers": "58",
        "maleMedian": "933",
        "femaleWorkers": "74",
        "femaleMedian": "892"
      }
    }
  },
  "Technical writers": {
    "ageData": {
      "2011": {
        "total": "60",
        "16to19": "0",
        "20to24": "1",
        "25to34": "8",
        "35to44": "13",
        "45to54": "21",
        "55to64": "12",
        "65+": "5",
        "medianAge": "12"
      }
    },
    "title": "Technical writers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "60",
        "women": "60.2",
        "white": "4.2",
        "black": "4.6",
        "asian": "4.1"
      }
    },
    "wageData": {
      "2011": {
        "workers": "46",
        "median": "-",
        "maleWorkers": "20",
        "maleMedian": "-",
        "femaleWorkers": "26",
        "femaleMedian": "-"
      }
    }
  },
  "Writers and authors": {
    "ageData": {
      "2011": {
        "total": "218",
        "16to19": "1",
        "20to24": "14",
        "25to34": "49",
        "35to44": "40",
        "45to54": "52",
        "55to64": "40",
        "65+": "23",
        "medianAge": "40"
      }
    },
    "title": "Writers and authors",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "218",
        "women": "57.0",
        "white": "4.5",
        "black": "3.1",
        "asian": "3.7"
      }
    },
    "wageData": {
      "2011": {
        "workers": "85",
        "median": "918",
        "maleWorkers": "42",
        "maleMedian": "-",
        "femaleWorkers": "43",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous media and communication workers": {
    "ageData": {
      "2011": {
        "total": "89",
        "16to19": "2",
        "20to24": "9",
        "25to34": "22",
        "35to44": "17",
        "45to54": "18",
        "55to64": "12",
        "65+": "9",
        "medianAge": "12"
      }
    },
    "title": "Miscellaneous media and communication workers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "89",
        "women": "64.9",
        "white": "7.9",
        "black": "12.5",
        "asian": "30.6"
      }
    },
    "wageData": {
      "2011": {
        "workers": "47",
        "median": "-",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "32",
        "femaleMedian": "-"
      }
    }
  },
  "Broadcast and sound engineering technicians and radio operators": {
    "ageData": {
      "2011": {
        "total": "106",
        "16to19": "2",
        "20to24": "13",
        "25to34": "29",
        "35to44": "28",
        "45to54": "18",
        "55to64": "14",
        "65+": "2",
        "medianAge": "14"
      }
    },
    "title": "Broadcast and sound engineering technicians and radio operators",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "106",
        "women": "9.9",
        "white": "9.1",
        "black": "6.0",
        "asian": "10.2"
      }
    },
    "wageData": {
      "2011": {
        "workers": "79",
        "median": "857",
        "maleWorkers": "73",
        "maleMedian": "872",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Photographers": {
    "ageData": {
      "2011": {
        "total": "148",
        "16to19": "2",
        "20to24": "12",
        "25to34": "41",
        "35to44": "37",
        "45to54": "25",
        "55to64": "25",
        "65+": "6",
        "medianAge": "25"
      }
    },
    "title": "Photographers",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "148",
        "women": "51.3",
        "white": "3.5",
        "black": "2.8",
        "asian": "9.6"
      }
    },
    "wageData": {
      "2011": {
        "workers": "45",
        "median": "-",
        "maleWorkers": "29",
        "maleMedian": "-",
        "femaleWorkers": "16",
        "femaleMedian": "-"
      }
    }
  },
  "Television, video, and motion picture camera operators and editors": {
    "ageData": {
      "2011": {
        "total": "57",
        "16to19": "1",
        "20to24": "6",
        "25to34": "16",
        "35to44": "14",
        "45to54": "8",
        "55to64": "9",
        "65+": "3",
        "medianAge": "9"
      }
    },
    "title": "Television, video, and motion picture camera operators and editors",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "57",
        "women": "19.9",
        "white": "8.6",
        "black": "5.7",
        "asian": "4.5"
      }
    },
    "wageData": {
      "2011": {
        "workers": "27",
        "median": "-",
        "maleWorkers": "22",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Media and communication equipment workers, all other": {
    "ageData": {
      "2011": {
        "total": "3",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "2",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Media and communication equipment workers, all other",
    "depth": 4,
    "parent": "Arts, design, entertainment, sports, and media occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Healthcare practitioners and technical occupations": {
    "ageData": {
      "2011": {
        "total": "7,740",
        "16to19": "29",
        "20to24": "395",
        "25to34": "1,908",
        "35to44": "1,877",
        "45to54": "1,873",
        "55to64": "1,341",
        "65+": "318",
        "medianAge": "1,341"
      }
    },
    "title": "Healthcare practitioners and technical occupations",
    "depth": 3,
    "parent": "Professional and related occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "7,740",
        "women": "74.4",
        "white": "10.0",
        "black": "7.8",
        "asian": "6.7"
      }
    },
    "wageData": {
      "2011": {
        "workers": "5,725",
        "median": "995",
        "maleWorkers": "1,429",
        "maleMedian": "1,129",
        "femaleWorkers": "4,296",
        "femaleMedian": "965"
      }
    }
  },
  "Chiropractors": {
    "ageData": {
      "2011": {
        "total": "56",
        "16to19": "0",
        "20to24": "0",
        "25to34": "9",
        "35to44": "13",
        "45to54": "18",
        "55to64": "10",
        "65+": "4",
        "medianAge": "10"
      }
    },
    "title": "Chiropractors",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "56",
        "women": "24.5",
        "white": "1.1",
        "black": "7.9",
        "asian": "1.3"
      }
    },
    "wageData": {
      "2011": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Dentists": {
    "ageData": {
      "2011": {
        "total": "181",
        "16to19": "0",
        "20to24": "0",
        "25to34": "24",
        "35to44": "44",
        "45to54": "44",
        "55to64": "46",
        "65+": "22",
        "medianAge": "46"
      }
    },
    "title": "Dentists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "181",
        "women": "22.2",
        "white": "1.0",
        "black": "11.0",
        "asian": "5.8"
      }
    },
    "wageData": {
      "2011": {
        "workers": "42",
        "median": "-",
        "maleWorkers": "33",
        "maleMedian": "-",
        "femaleWorkers": "9",
        "femaleMedian": "-"
      }
    }
  },
  "Dietitians and nutritionists": {
    "ageData": {
      "2011": {
        "total": "102",
        "16to19": "2",
        "20to24": "5",
        "25to34": "20",
        "35to44": "18",
        "45to54": "32",
        "55to64": "20",
        "65+": "5",
        "medianAge": "20"
      }
    },
    "title": "Dietitians and nutritionists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "102",
        "women": "90.6",
        "white": "15.2",
        "black": "4.5",
        "asian": "8.4"
      }
    },
    "wageData": {
      "2011": {
        "workers": "69",
        "median": "897",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "64",
        "femaleMedian": "906"
      }
    }
  },
  "Optometrists": {
    "ageData": {
      "2011": {
        "total": "28",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "5",
        "45to54": "7",
        "55to64": "8",
        "65+": "5",
        "medianAge": "8"
      }
    },
    "title": "Optometrists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "28",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "9",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Pharmacists": {
    "ageData": {
      "2011": {
        "total": "274",
        "16to19": "1",
        "20to24": "13",
        "25to34": "80",
        "35to44": "77",
        "45to54": "49",
        "55to64": "38",
        "65+": "15",
        "medianAge": "38"
      }
    },
    "title": "Pharmacists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "274",
        "women": "55.7",
        "white": "7.1",
        "black": "15.3",
        "asian": "4.4"
      }
    },
    "wageData": {
      "2011": {
        "workers": "195",
        "median": "1,917",
        "maleWorkers": "85",
        "maleMedian": "1,998",
        "femaleWorkers": "110",
        "femaleMedian": "1,898"
      }
    }
  },
  "Physicians and surgeons": {
    "ageData": {
      "2011": {
        "total": "822",
        "16to19": "1",
        "20to24": "4",
        "25to34": "168",
        "35to44": "209",
        "45to54": "206",
        "55to64": "157",
        "65+": "78",
        "medianAge": "157"
      }
    },
    "title": "Physicians and surgeons",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "822",
        "women": "33.8",
        "white": "5.3",
        "black": "16.1",
        "asian": "6.6"
      }
    },
    "wageData": {
      "2011": {
        "workers": "573",
        "median": "1,860",
        "maleWorkers": "366",
        "maleMedian": "1,935",
        "femaleWorkers": "206",
        "femaleMedian": "1,527"
      }
    }
  },
  "Physician assistants": {
    "ageData": {
      "2011": {
        "total": "82",
        "16to19": "0",
        "20to24": "4",
        "25to34": "31",
        "35to44": "22",
        "45to54": "13",
        "55to64": "10",
        "65+": "1",
        "medianAge": "10"
      }
    },
    "title": "Physician assistants",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "82",
        "women": "69.8",
        "white": "9.6",
        "black": "2.7",
        "asian": "10.1"
      }
    },
    "wageData": {
      "2011": {
        "workers": "69",
        "median": "1,220",
        "maleWorkers": "24",
        "maleMedian": "-",
        "femaleWorkers": "44",
        "femaleMedian": "-"
      }
    }
  },
  "Podiatrists": {
    "ageData": {
      "2011": {
        "total": "6",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "2",
        "45to54": "2",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Podiatrists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "6",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Audiologists": {
    "ageData": {
      "2011": {
        "total": "19",
        "16to19": "0",
        "20to24": "0",
        "25to34": "4",
        "35to44": "3",
        "45to54": "8",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Audiologists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "19",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "14",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Occupational therapists": {
    "ageData": {
      "2011": {
        "total": "112",
        "16to19": "0",
        "20to24": "1",
        "25to34": "30",
        "35to44": "38",
        "45to54": "27",
        "55to64": "12",
        "65+": "4",
        "medianAge": "12"
      }
    },
    "title": "Occupational therapists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "112",
        "women": "92.0",
        "white": "2.7",
        "black": "7.6",
        "asian": "4.6"
      }
    },
    "wageData": {
      "2011": {
        "workers": "72",
        "median": "1,189",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "62",
        "femaleMedian": "1,193"
      }
    }
  },
  "Physical therapists": {
    "ageData": {
      "2011": {
        "total": "222",
        "16to19": "0",
        "20to24": "11",
        "25to34": "60",
        "35to44": "71",
        "45to54": "57",
        "55to64": "19",
        "65+": "4",
        "medianAge": "19"
      }
    },
    "title": "Physical therapists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "222",
        "women": "67.8",
        "white": "5.2",
        "black": "8.9",
        "asian": "5.0"
      }
    },
    "wageData": {
      "2011": {
        "workers": "146",
        "median": "1,322",
        "maleWorkers": "59",
        "maleMedian": "1,522",
        "femaleWorkers": "87",
        "femaleMedian": "1,216"
      }
    }
  },
  "Radiation therapists": {
    "ageData": {
      "2011": {
        "total": "14",
        "16to19": "0",
        "20to24": "1",
        "25to34": "4",
        "35to44": "2",
        "45to54": "5",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Radiation therapists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "14",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Recreational therapists": {
    "ageData": {
      "2011": {
        "total": "14",
        "16to19": "0",
        "20to24": "1",
        "25to34": "2",
        "35to44": "4",
        "45to54": "4",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Recreational therapists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "14",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "10",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Respiratory therapists": {
    "ageData": {
      "2011": {
        "total": "134",
        "16to19": "0",
        "20to24": "6",
        "25to34": "27",
        "35to44": "37",
        "45to54": "37",
        "55to64": "24",
        "65+": "2",
        "medianAge": "24"
      }
    },
    "title": "Respiratory therapists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "134",
        "women": "62.0",
        "white": "8.1",
        "black": "5.6",
        "asian": "8.5"
      }
    },
    "wageData": {
      "2011": {
        "workers": "126",
        "median": "997",
        "maleWorkers": "53",
        "maleMedian": "966",
        "femaleWorkers": "73",
        "femaleMedian": "1,028"
      }
    }
  },
  "Speech-language pathologists": {
    "ageData": {
      "2011": {
        "total": "125",
        "16to19": "0",
        "20to24": "6",
        "25to34": "41",
        "35to44": "38",
        "45to54": "18",
        "55to64": "20",
        "65+": "2",
        "medianAge": "20"
      }
    },
    "title": "Speech-language pathologists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "125",
        "women": "95.6",
        "white": "1.5",
        "black": "1.5",
        "asian": "6.4"
      }
    },
    "wageData": {
      "2011": {
        "workers": "87",
        "median": "1,075",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "84",
        "femaleMedian": "1,076"
      }
    }
  },
  "Exercise physiologists": {
    "ageData": {
      "2011": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Exercise physiologists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Therapists, all other": {
    "ageData": {
      "2011": {
        "total": "138",
        "16to19": "0",
        "20to24": "7",
        "25to34": "46",
        "35to44": "31",
        "45to54": "28",
        "55to64": "20",
        "65+": "5",
        "medianAge": "20"
      }
    },
    "title": "Therapists, all other",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "138",
        "women": "79.9",
        "white": "8.0",
        "black": "3.2",
        "asian": "6.0"
      }
    },
    "wageData": {
      "2011": {
        "workers": "83",
        "median": "885",
        "maleWorkers": "19",
        "maleMedian": "-",
        "femaleWorkers": "64",
        "femaleMedian": "869"
      }
    }
  },
  "Veterinarians": {
    "ageData": {
      "2011": {
        "total": "71",
        "16to19": "1",
        "20to24": "2",
        "25to34": "23",
        "35to44": "16",
        "45to54": "14",
        "55to64": "8",
        "65+": "8",
        "medianAge": "8"
      }
    },
    "title": "Veterinarians",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "71",
        "women": "56.4",
        "white": "1.6",
        "black": "4.0",
        "asian": "5.0"
      }
    },
    "wageData": {
      "2011": {
        "workers": "40",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "28",
        "femaleMedian": "-"
      }
    }
  },
  "Registered nurses": {
    "ageData": {
      "2011": {
        "total": "2,706",
        "16to19": "3",
        "20to24": "110",
        "25to34": "628",
        "35to44": "641",
        "45to54": "679",
        "55to64": "552",
        "65+": "94",
        "medianAge": "552"
      }
    },
    "title": "Registered nurses",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "2,706",
        "women": "91.1",
        "white": "10.4",
        "black": "7.3",
        "asian": "5.1"
      }
    },
    "wageData": {
      "2011": {
        "workers": "2,145",
        "median": "1,039",
        "maleWorkers": "208",
        "maleMedian": "1,081",
        "femaleWorkers": "1,937",
        "femaleMedian": "1,034"
      }
    }
  },
  "Nurse anesthetists": {
    "ageData": {
      "2011": {
        "total": "33",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "8",
        "45to54": "14",
        "55to64": "9",
        "65+": "0",
        "medianAge": "9"
      }
    },
    "title": "Nurse anesthetists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "33",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "26",
        "median": "-",
        "maleWorkers": "16",
        "maleMedian": "-",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Nurse midwives": {
    "ageData": {
      "2011": {
        "total": "5",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "4",
        "45to54": "0",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Nurse midwives",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Nurse practitioners": {
    "ageData": {
      "2011": {
        "total": "100",
        "16to19": "0",
        "20to24": "1",
        "25to34": "13",
        "35to44": "31",
        "45to54": "33",
        "55to64": "21",
        "65+": "1",
        "medianAge": "21"
      }
    },
    "title": "Nurse practitioners",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "100",
        "women": "90.6",
        "white": "7.3",
        "black": "2.4",
        "asian": "8.6"
      }
    },
    "wageData": {
      "2011": {
        "workers": "81",
        "median": "1,461",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "69",
        "femaleMedian": "1,432"
      }
    }
  },
  "Health diagnosing and treating practitioners, all other": {
    "ageData": {
      "2011": {
        "total": "26",
        "16to19": "0",
        "20to24": "0",
        "25to34": "4",
        "35to44": "4",
        "45to54": "9",
        "55to64": "7",
        "65+": "3",
        "medianAge": "7"
      }
    },
    "title": "Health diagnosing and treating practitioners, all other",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "26",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Clinical laboratory technologists and technicians": {
    "ageData": {
      "2011": {
        "total": "321",
        "16to19": "1",
        "20to24": "25",
        "25to34": "80",
        "35to44": "66",
        "45to54": "87",
        "55to64": "56",
        "65+": "6",
        "medianAge": "56"
      }
    },
    "title": "Clinical laboratory technologists and technicians",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "321",
        "women": "73.6",
        "white": "15.3",
        "black": "8.9",
        "asian": "9.4"
      }
    },
    "wageData": {
      "2011": {
        "workers": "282",
        "median": "861",
        "maleWorkers": "77",
        "maleMedian": "888",
        "femaleWorkers": "206",
        "femaleMedian": "845"
      }
    }
  },
  "Dental hygienists": {
    "ageData": {
      "2011": {
        "total": "148",
        "16to19": "0",
        "20to24": "5",
        "25to34": "41",
        "35to44": "41",
        "45to54": "33",
        "55to64": "25",
        "65+": "2",
        "medianAge": "25"
      }
    },
    "title": "Dental hygienists",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "148",
        "women": "97.5",
        "white": "0.5",
        "black": "5.5",
        "asian": "7.2"
      }
    },
    "wageData": {
      "2011": {
        "workers": "61",
        "median": "986",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "59",
        "femaleMedian": "992"
      }
    }
  },
  "Diagnostic related technologists and technicians": {
    "ageData": {
      "2011": {
        "total": "342",
        "16to19": "0",
        "20to24": "21",
        "25to34": "87",
        "35to44": "84",
        "45to54": "97",
        "55to64": "48",
        "65+": "5",
        "medianAge": "48"
      }
    },
    "title": "Diagnostic related technologists and technicians",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "342",
        "women": "73.0",
        "white": "9.0",
        "black": "4.0",
        "asian": "7.2"
      }
    },
    "wageData": {
      "2011": {
        "workers": "273",
        "median": "981",
        "maleWorkers": "82",
        "maleMedian": "1,054",
        "femaleWorkers": "191",
        "femaleMedian": "964"
      }
    }
  },
  "Emergency medical technicians and paramedics": {
    "ageData": {
      "2011": {
        "total": "185",
        "16to19": "2",
        "20to24": "30",
        "25to34": "81",
        "35to44": "34",
        "45to54": "27",
        "55to64": "8",
        "65+": "2",
        "medianAge": "8"
      }
    },
    "title": "Emergency medical technicians and paramedics",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "185",
        "women": "36.0",
        "white": "4.5",
        "black": "0.9",
        "asian": "6.4"
      }
    },
    "wageData": {
      "2011": {
        "workers": "167",
        "median": "715",
        "maleWorkers": "113",
        "maleMedian": "757",
        "femaleWorkers": "54",
        "femaleMedian": "649"
      }
    }
  },
  "Health practitioner support technologists and technicians": {
    "ageData": {
      "2011": {
        "total": "511",
        "16to19": "13",
        "20to24": "86",
        "25to34": "167",
        "35to44": "93",
        "45to54": "96",
        "55to64": "44",
        "65+": "11",
        "medianAge": "44"
      }
    },
    "title": "Health practitioner support technologists and technicians",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "511",
        "women": "78.6",
        "white": "15.1",
        "black": "5.6",
        "asian": "11.0"
      }
    },
    "wageData": {
      "2011": {
        "workers": "374",
        "median": "644",
        "maleWorkers": "91",
        "maleMedian": "682",
        "femaleWorkers": "283",
        "femaleMedian": "632"
      }
    }
  },
  "Licensed practical and licensed vocational nurses": {
    "ageData": {
      "2011": {
        "total": "560",
        "16to19": "4",
        "20to24": "30",
        "25to34": "132",
        "35to44": "144",
        "45to54": "135",
        "55to64": "95",
        "65+": "21",
        "medianAge": "95"
      }
    },
    "title": "Licensed practical and licensed vocational nurses",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "560",
        "women": "90.5",
        "white": "23.7",
        "black": "3.9",
        "asian": "8.3"
      }
    },
    "wageData": {
      "2011": {
        "workers": "424",
        "median": "722",
        "maleWorkers": "39",
        "maleMedian": "-",
        "femaleWorkers": "386",
        "femaleMedian": "720"
      }
    }
  },
  "Medical records and health information technicians": {
    "ageData": {
      "2011": {
        "total": "116",
        "16to19": "1",
        "20to24": "8",
        "25to34": "32",
        "35to44": "26",
        "45to54": "22",
        "55to64": "23",
        "65+": "5",
        "medianAge": "23"
      }
    },
    "title": "Medical records and health information technicians",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "116",
        "women": "89.7",
        "white": "10.6",
        "black": "9.5",
        "asian": "19.6"
      }
    },
    "wageData": {
      "2011": {
        "workers": "107",
        "median": "588",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "96",
        "femaleMedian": "574"
      }
    }
  },
  "Opticians, dispensing": {
    "ageData": {
      "2011": {
        "total": "51",
        "16to19": "1",
        "20to24": "3",
        "25to34": "10",
        "35to44": "12",
        "45to54": "12",
        "55to64": "11",
        "65+": "3",
        "medianAge": "11"
      }
    },
    "title": "Opticians, dispensing",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "51",
        "women": "64.3",
        "white": "9.4",
        "black": "1.8",
        "asian": "4.8"
      }
    },
    "wageData": {
      "2011": {
        "workers": "34",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "26",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous health technologists and technicians": {
    "ageData": {
      "2011": {
        "total": "167",
        "16to19": "0",
        "20to24": "10",
        "25to34": "43",
        "35to44": "41",
        "45to54": "43",
        "55to64": "27",
        "65+": "3",
        "medianAge": "27"
      }
    },
    "title": "Miscellaneous health technologists and technicians",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "167",
        "women": "65.7",
        "white": "16.7",
        "black": "11.8",
        "asian": "9.7"
      }
    },
    "wageData": {
      "2011": {
        "workers": "127",
        "median": "744",
        "maleWorkers": "38",
        "maleMedian": "-",
        "femaleWorkers": "89",
        "femaleMedian": "711"
      }
    }
  },
  "Other healthcare practitioners and technical occupations": {
    "ageData": {
      "2011": {
        "total": "70",
        "16to19": "0",
        "20to24": "4",
        "25to34": "17",
        "35to44": "19",
        "45to54": "14",
        "55to64": "11",
        "65+": "4",
        "medianAge": "11"
      }
    },
    "title": "Other healthcare practitioners and technical occupations",
    "depth": 4,
    "parent": "Healthcare practitioners and technical occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "70",
        "women": "39.5",
        "white": "9.4",
        "black": "5.1",
        "asian": "5.6"
      }
    },
    "wageData": {
      "2011": {
        "workers": "63",
        "median": "870",
        "maleWorkers": "41",
        "maleMedian": "-",
        "femaleWorkers": "22",
        "femaleMedian": "-"
      }
    }
  },
  "Service occupations": {
    "ageData": {
      "2011": {
        "total": "24,787",
        "16to19": "1,829",
        "20to24": "3,788",
        "25to34": "5,515",
        "35to44": "4,722",
        "45to54": "4,794",
        "55to64": "3,072",
        "65+": "1,066",
        "medianAge": "3,072"
      }
    },
    "title": "Service occupations",
    "depth": 1,
    "parent": "",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "24,787",
        "women": "55.9",
        "white": "15.4",
        "black": "4.9",
        "asian": "21.0"
      }
    },
    "wageData": {
      "2011": {
        "workers": "14,378",
        "median": "486",
        "maleWorkers": "7,387",
        "maleMedian": "551",
        "femaleWorkers": "6,991",
        "femaleMedian": "433"
      }
    }
  },
  "Healthcare support occupations": {
    "ageData": {
      "2011": {
        "total": "3,359",
        "16to19": "81",
        "20to24": "478",
        "25to34": "865",
        "35to44": "675",
        "45to54": "698",
        "55to64": "448",
        "65+": "114",
        "medianAge": "448"
      }
    },
    "title": "Healthcare support occupations",
    "depth": 2,
    "parent": "Service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "3,359",
        "women": "87.7",
        "white": "24.6",
        "black": "4.3",
        "asian": "13.9"
      }
    },
    "wageData": {
      "2011": {
        "workers": "2,190",
        "median": "487",
        "maleWorkers": "290",
        "maleMedian": "521",
        "femaleWorkers": "1,900",
        "femaleMedian": "483"
      }
    }
  },
  "Nursing, psychiatric, and home health aides": {
    "ageData": {
      "2011": {
        "total": "1,981",
        "16to19": "47",
        "20to24": "275",
        "25to34": "445",
        "35to44": "401",
        "45to54": "441",
        "55to64": "284",
        "65+": "89",
        "medianAge": "284"
      }
    },
    "title": "Nursing, psychiatric, and home health aides",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "1,981",
        "women": "87.8",
        "white": "33.1",
        "black": "4.3",
        "asian": "13.3"
      }
    },
    "wageData": {
      "2011": {
        "workers": "1,305",
        "median": "453",
        "maleWorkers": "172",
        "maleMedian": "502",
        "femaleWorkers": "1,134",
        "femaleMedian": "446"
      }
    }
  },
  "Occupational therapy assistants and aides": {
    "ageData": {
      "2011": {
        "total": "17",
        "16to19": "0",
        "20to24": "1",
        "25to34": "10",
        "35to44": "4",
        "45to54": "1",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Occupational therapy assistants and aides",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "17",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "Physical therapist assistants and aides": {
    "ageData": {
      "2011": {
        "total": "75",
        "16to19": "1",
        "20to24": "15",
        "25to34": "28",
        "35to44": "16",
        "45to54": "11",
        "55to64": "5",
        "65+": "0",
        "medianAge": "5"
      }
    },
    "title": "Physical therapist assistants and aides",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "75",
        "women": "60.8",
        "white": "10.4",
        "black": "3.5",
        "asian": "9.7"
      }
    },
    "wageData": {
      "2011": {
        "workers": "54",
        "median": "665",
        "maleWorkers": "22",
        "maleMedian": "-",
        "femaleWorkers": "32",
        "femaleMedian": "-"
      }
    }
  },
  "Massage therapists": {
    "ageData": {
      "2011": {
        "total": "146",
        "16to19": "1",
        "20to24": "14",
        "25to34": "44",
        "35to44": "30",
        "45to54": "29",
        "55to64": "24",
        "65+": "3",
        "medianAge": "24"
      }
    },
    "title": "Massage therapists",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "146",
        "women": "86.5",
        "white": "5.5",
        "black": "5.5",
        "asian": "7.0"
      }
    },
    "wageData": {
      "2011": {
        "workers": "34",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "28",
        "femaleMedian": "-"
      }
    }
  },
  "Dental assistants": {
    "ageData": {
      "2011": {
        "total": "307",
        "16to19": "3",
        "20to24": "46",
        "25to34": "92",
        "35to44": "73",
        "45to54": "54",
        "55to64": "33",
        "65+": "5",
        "medianAge": "33"
      }
    },
    "title": "Dental assistants",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "307",
        "women": "97.9",
        "white": "8.3",
        "black": "5.1",
        "asian": "16.3"
      }
    },
    "wageData": {
      "2011": {
        "workers": "191",
        "median": "579",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "184",
        "femaleMedian": "582"
      }
    }
  },
  "Medical assistants": {
    "ageData": {
      "2011": {
        "total": "395",
        "16to19": "5",
        "20to24": "56",
        "25to34": "152",
        "35to44": "73",
        "45to54": "67",
        "55to64": "36",
        "65+": "6",
        "medianAge": "36"
      }
    },
    "title": "Medical assistants",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "395",
        "women": "93.4",
        "white": "14.3",
        "black": "5.2",
        "asian": "20.2"
      }
    },
    "wageData": {
      "2011": {
        "workers": "314",
        "median": "522",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "293",
        "femaleMedian": "518"
      }
    }
  },
  "Medical transcriptionists": {
    "ageData": {
      "2011": {
        "total": "74",
        "16to19": "0",
        "20to24": "3",
        "25to34": "9",
        "35to44": "12",
        "45to54": "25",
        "55to64": "20",
        "65+": "4",
        "medianAge": "20"
      }
    },
    "title": "Medical transcriptionists",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "74",
        "women": "95.4",
        "white": "0.3",
        "black": "0.0",
        "asian": "2.1"
      }
    },
    "wageData": {
      "2011": {
        "workers": "44",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "43",
        "femaleMedian": "-"
      }
    }
  },
  "Pharmacy aides": {
    "ageData": {
      "2011": {
        "total": "48",
        "16to19": "4",
        "20to24": "15",
        "25to34": "6",
        "35to44": "10",
        "45to54": "11",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Pharmacy aides",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "48",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "23",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "14",
        "femaleMedian": "-"
      }
    }
  },
  "Veterinary assistants and laboratory animal caretakers": {
    "ageData": {
      "2011": {
        "total": "38",
        "16to19": "2",
        "20to24": "11",
        "25to34": "15",
        "35to44": "4",
        "45to54": "1",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Veterinary assistants and laboratory animal caretakers",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "38",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "20",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "13",
        "femaleMedian": "-"
      }
    }
  },
  "Phlebotomists": {
    "ageData": {
      "2011": {
        "total": "119",
        "16to19": "2",
        "20to24": "16",
        "25to34": "31",
        "35to44": "29",
        "45to54": "25",
        "55to64": "14",
        "65+": "2",
        "medianAge": "14"
      }
    },
    "title": "Phlebotomists",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "119",
        "women": "81.8",
        "white": "26.7",
        "black": "4.9",
        "asian": "13.7"
      }
    },
    "wageData": {
      "2011": {
        "workers": "94",
        "median": "521",
        "maleWorkers": "16",
        "maleMedian": "-",
        "femaleWorkers": "77",
        "femaleMedian": "516"
      }
    }
  },
  "Miscellaneous healthcare support occupations, including medical equipment preparers": {
    "ageData": {
      "2011": {
        "total": "158",
        "16to19": "16",
        "20to24": "27",
        "25to34": "33",
        "35to44": "22",
        "45to54": "32",
        "55to64": "23",
        "65+": "4",
        "medianAge": "23"
      }
    },
    "title": "Miscellaneous healthcare support occupations, including medical equipment preparers",
    "depth": 3,
    "parent": "Healthcare support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "158",
        "women": "74.0",
        "white": "24.3",
        "black": "2.8",
        "asian": "13.3"
      }
    },
    "wageData": {
      "2011": {
        "workers": "98",
        "median": "487",
        "maleWorkers": "26",
        "maleMedian": "-",
        "femaleWorkers": "72",
        "femaleMedian": "480"
      }
    }
  },
  "Protective service occupations": {
    "ageData": {
      "2011": {
        "total": "3,210",
        "16to19": "90",
        "20to24": "267",
        "25to34": "821",
        "35to44": "806",
        "45to54": "675",
        "55to64": "407",
        "65+": "143",
        "medianAge": "407"
      }
    },
    "title": "Protective service occupations",
    "depth": 2,
    "parent": "Service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "3,210",
        "women": "20.7",
        "white": "17.4",
        "black": "2.1",
        "asian": "12.6"
      }
    },
    "wageData": {
      "2011": {
        "workers": "2,798",
        "median": "757",
        "maleWorkers": "2,287",
        "maleMedian": "797",
        "femaleWorkers": "511",
        "femaleMedian": "602"
      }
    }
  },
  "First-line supervisors of correctional officers": {
    "ageData": {
      "2011": {
        "total": "42",
        "16to19": "0",
        "20to24": "1",
        "25to34": "8",
        "35to44": "10",
        "45to54": "16",
        "55to64": "6",
        "65+": "2",
        "medianAge": "6"
      }
    },
    "title": "First-line supervisors of correctional officers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "42",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "41",
        "median": "-",
        "maleWorkers": "30",
        "maleMedian": "-",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "First-line supervisors of police and detectives": {
    "ageData": {
      "2011": {
        "total": "107",
        "16to19": "0",
        "20to24": "0",
        "25to34": "9",
        "35to44": "37",
        "45to54": "37",
        "55to64": "20",
        "65+": "4",
        "medianAge": "20"
      }
    },
    "title": "First-line supervisors of police and detectives",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "107",
        "women": "17.5",
        "white": "9.3",
        "black": "4.0",
        "asian": "6.7"
      }
    },
    "wageData": {
      "2011": {
        "workers": "101",
        "median": "1,083",
        "maleWorkers": "83",
        "maleMedian": "1,109",
        "femaleWorkers": "18",
        "femaleMedian": "-"
      }
    }
  },
  "First-line supervisors of fire fighting and prevention workers": {
    "ageData": {
      "2011": {
        "total": "44",
        "16to19": "0",
        "20to24": "0",
        "25to34": "4",
        "35to44": "14",
        "45to54": "19",
        "55to64": "7",
        "65+": "0",
        "medianAge": "7"
      }
    },
    "title": "First-line supervisors of fire fighting and prevention workers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "44",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "40",
        "median": "-",
        "maleWorkers": "39",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "First-line supervisors of protective service workers, all other": {
    "ageData": {
      "2011": {
        "total": "111",
        "16to19": "0",
        "20to24": "4",
        "25to34": "21",
        "35to44": "22",
        "45to54": "34",
        "55to64": "25",
        "65+": "7",
        "medianAge": "25"
      }
    },
    "title": "First-line supervisors of protective service workers, all other",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "111",
        "women": "24.2",
        "white": "17.6",
        "black": "1.1",
        "asian": "11.5"
      }
    },
    "wageData": {
      "2011": {
        "workers": "96",
        "median": "716",
        "maleWorkers": "73",
        "maleMedian": "788",
        "femaleWorkers": "23",
        "femaleMedian": "-"
      }
    }
  },
  "Firefighters": {
    "ageData": {
      "2011": {
        "total": "305",
        "16to19": "2",
        "20to24": "21",
        "25to34": "118",
        "35to44": "97",
        "45to54": "55",
        "55to64": "11",
        "65+": "2",
        "medianAge": "11"
      }
    },
    "title": "Firefighters",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "305",
        "women": "4.5",
        "white": "2.9",
        "black": "0.6",
        "asian": "10.0"
      }
    },
    "wageData": {
      "2011": {
        "workers": "291",
        "median": "1,000",
        "maleWorkers": "281",
        "maleMedian": "998",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "Fire inspectors": {
    "ageData": {
      "2011": {
        "total": "20",
        "16to19": "0",
        "20to24": "0",
        "25to34": "5",
        "35to44": "5",
        "45to54": "8",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Fire inspectors",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "20",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "18",
        "median": "-",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Bailiffs, correctional officers, and jailers": {
    "ageData": {
      "2011": {
        "total": "446",
        "16to19": "3",
        "20to24": "29",
        "25to34": "129",
        "35to44": "127",
        "45to54": "99",
        "55to64": "49",
        "65+": "9",
        "medianAge": "49"
      }
    },
    "title": "Bailiffs, correctional officers, and jailers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "446",
        "women": "27.9",
        "white": "25.1",
        "black": "1.9",
        "asian": "13.0"
      }
    },
    "wageData": {
      "2011": {
        "workers": "436",
        "median": "682",
        "maleWorkers": "320",
        "maleMedian": "722",
        "femaleWorkers": "116",
        "femaleMedian": "586"
      }
    }
  },
  "Detectives and criminal investigators": {
    "ageData": {
      "2011": {
        "total": "151",
        "16to19": "0",
        "20to24": "3",
        "25to34": "32",
        "35to44": "52",
        "45to54": "41",
        "55to64": "17",
        "65+": "6",
        "medianAge": "17"
      }
    },
    "title": "Detectives and criminal investigators",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "151",
        "women": "16.7",
        "white": "8.9",
        "black": "1.2",
        "asian": "14.9"
      }
    },
    "wageData": {
      "2011": {
        "workers": "147",
        "median": "1,154",
        "maleWorkers": "125",
        "maleMedian": "1,192",
        "femaleWorkers": "22",
        "femaleMedian": "-"
      }
    }
  },
  "Fish and game wardens": {
    "ageData": {
      "2011": {
        "total": "6",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "2",
        "45to54": "2",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Fish and game wardens",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "6",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Parking enforcement workers": {
    "ageData": {
      "2011": {
        "total": "7",
        "16to19": "0",
        "20to24": "1",
        "25to34": "0",
        "35to44": "2",
        "45to54": "2",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Parking enforcement workers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Police and sheriff's patrol officers": {
    "ageData": {
      "2011": {
        "total": "668",
        "16to19": "2",
        "20to24": "20",
        "25to34": "218",
        "35to44": "234",
        "45to54": "126",
        "55to64": "59",
        "65+": "9",
        "medianAge": "59"
      }
    },
    "title": "Police and sheriff's patrol officers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "668",
        "women": "12.0",
        "white": "11.5",
        "black": "2.0",
        "asian": "12.7"
      }
    },
    "wageData": {
      "2011": {
        "workers": "653",
        "median": "947",
        "maleWorkers": "578",
        "maleMedian": "948",
        "femaleWorkers": "75",
        "femaleMedian": "938"
      }
    }
  },
  "Transit and railroad police": {
    "ageData": {
      "2011": {
        "total": "8",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "3",
        "45to54": "3",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Transit and railroad police",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "8",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Animal control workers": {
    "ageData": {
      "2011": {
        "total": "6",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "1",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Animal control workers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "6",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Private detectives and investigators": {
    "ageData": {
      "2011": {
        "total": "92",
        "16to19": "1",
        "20to24": "7",
        "25to34": "18",
        "35to44": "26",
        "45to54": "20",
        "55to64": "15",
        "65+": "5",
        "medianAge": "15"
      }
    },
    "title": "Private detectives and investigators",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "92",
        "women": "35.3",
        "white": "8.3",
        "black": "1.2",
        "asian": "11.5"
      }
    },
    "wageData": {
      "2011": {
        "workers": "86",
        "median": "869",
        "maleWorkers": "56",
        "maleMedian": "891",
        "femaleWorkers": "30",
        "femaleMedian": "-"
      }
    }
  },
  "Security guards and gaming surveillance officers": {
    "ageData": {
      "2011": {
        "total": "963",
        "16to19": "17",
        "20to24": "137",
        "25to34": "229",
        "35to44": "152",
        "45to54": "181",
        "55to64": "165",
        "65+": "83",
        "medianAge": "165"
      }
    },
    "title": "Security guards and gaming surveillance officers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "963",
        "women": "19.2",
        "white": "27.2",
        "black": "3.6",
        "asian": "15.4"
      }
    },
    "wageData": {
      "2011": {
        "workers": "775",
        "median": "519",
        "maleWorkers": "619",
        "maleMedian": "544",
        "femaleWorkers": "156",
        "femaleMedian": "474"
      }
    }
  },
  "Crossing guards": {
    "ageData": {
      "2011": {
        "total": "59",
        "16to19": "1",
        "20to24": "3",
        "25to34": "6",
        "35to44": "7",
        "45to54": "17",
        "55to64": "14",
        "65+": "11",
        "medianAge": "14"
      }
    },
    "title": "Crossing guards",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "59",
        "women": "64.3",
        "white": "19.7",
        "black": "0.0",
        "asian": "5.5"
      }
    },
    "wageData": {
      "2011": {
        "workers": "23",
        "median": "-",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Transportation security screeners": {
    "ageData": {
      "2011": {
        "total": "27",
        "16to19": "0",
        "20to24": "1",
        "25to34": "9",
        "35to44": "7",
        "45to54": "7",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Transportation security screeners",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "27",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "21",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Lifeguards and other recreational, and all other protective service workers": {
    "ageData": {
      "2011": {
        "total": "146",
        "16to19": "64",
        "20to24": "39",
        "25to34": "12",
        "35to44": "7",
        "45to54": "10",
        "55to64": "12",
        "65+": "4",
        "medianAge": "12"
      }
    },
    "title": "Lifeguards and other recreational, and all other protective service workers",
    "depth": 3,
    "parent": "Protective service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "146",
        "women": "58.1",
        "white": "6.2",
        "black": "0.3",
        "asian": "6.3"
      }
    },
    "wageData": {
      "2011": {
        "workers": "44",
        "median": "-",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "23",
        "femaleMedian": "-"
      }
    }
  },
  "Food preparation and serving related occupations": {
    "ageData": {
      "2011": {
        "total": "7,747",
        "16to19": "1,189",
        "20to24": "1,846",
        "25to34": "1,808",
        "35to44": "1,119",
        "45to54": "1,028",
        "55to64": "557",
        "65+": "201",
        "medianAge": "557"
      }
    },
    "title": "Food preparation and serving related occupations",
    "depth": 2,
    "parent": "Service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "7,747",
        "women": "54.4",
        "white": "12.6",
        "black": "5.9",
        "asian": "21.8"
      }
    },
    "wageData": {
      "2011": {
        "workers": "3,930",
        "median": "409",
        "maleWorkers": "2,021",
        "maleMedian": "429",
        "femaleWorkers": "1,909",
        "femaleMedian": "390"
      }
    }
  },
  "Chefs and head cooks": {
    "ageData": {
      "2011": {
        "total": "347",
        "16to19": "11",
        "20to24": "33",
        "25to34": "100",
        "35to44": "100",
        "45to54": "60",
        "55to64": "35",
        "65+": "8",
        "medianAge": "35"
      }
    },
    "title": "Chefs and head cooks",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "347",
        "women": "18.7",
        "white": "9.1",
        "black": "15.3",
        "asian": "18.0"
      }
    },
    "wageData": {
      "2011": {
        "workers": "299",
        "median": "580",
        "maleWorkers": "245",
        "maleMedian": "601",
        "femaleWorkers": "53",
        "femaleMedian": "502"
      }
    }
  },
  "First-line supervisors of food preparation and serving workers": {
    "ageData": {
      "2011": {
        "total": "505",
        "16to19": "16",
        "20to24": "90",
        "25to34": "131",
        "35to44": "106",
        "45to54": "93",
        "55to64": "56",
        "65+": "13",
        "medianAge": "56"
      }
    },
    "title": "First-line supervisors of food preparation and serving workers",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "505",
        "women": "59.1",
        "white": "18.2",
        "black": "3.2",
        "asian": "15.1"
      }
    },
    "wageData": {
      "2011": {
        "workers": "378",
        "median": "485",
        "maleWorkers": "158",
        "maleMedian": "525",
        "femaleWorkers": "220",
        "femaleMedian": "458"
      }
    }
  },
  "Cooks": {
    "ageData": {
      "2011": {
        "total": "1,990",
        "16to19": "207",
        "20to24": "355",
        "25to34": "480",
        "35to44": "356",
        "45to54": "359",
        "55to64": "174",
        "65+": "57",
        "medianAge": "174"
      }
    },
    "title": "Cooks",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "1,990",
        "women": "39.2",
        "white": "17.9",
        "black": "5.9",
        "asian": "30.4"
      }
    },
    "wageData": {
      "2011": {
        "workers": "1,210",
        "median": "390",
        "maleWorkers": "761",
        "maleMedian": "406",
        "femaleWorkers": "449",
        "femaleMedian": "363"
      }
    }
  },
  "Food preparation workers": {
    "ageData": {
      "2011": {
        "total": "784",
        "16to19": "153",
        "20to24": "187",
        "25to34": "154",
        "35to44": "99",
        "45to54": "110",
        "55to64": "57",
        "65+": "24",
        "medianAge": "57"
      }
    },
    "title": "Food preparation workers",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "784",
        "women": "58.0",
        "white": "14.4",
        "black": "5.7",
        "asian": "23.0"
      }
    },
    "wageData": {
      "2011": {
        "workers": "357",
        "median": "384",
        "maleWorkers": "155",
        "maleMedian": "395",
        "femaleWorkers": "202",
        "femaleMedian": "375"
      }
    }
  },
  "Bartenders": {
    "ageData": {
      "2011": {
        "total": "392",
        "16to19": "4",
        "20to24": "71",
        "25to34": "157",
        "35to44": "72",
        "45to54": "50",
        "55to64": "28",
        "65+": "10",
        "medianAge": "28"
      }
    },
    "title": "Bartenders",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "392",
        "women": "55.7",
        "white": "5.1",
        "black": "2.1",
        "asian": "10.1"
      }
    },
    "wageData": {
      "2011": {
        "workers": "210",
        "median": "545",
        "maleWorkers": "104",
        "maleMedian": "593",
        "femaleWorkers": "106",
        "femaleMedian": "505"
      }
    }
  },
  "Combined food preparation and serving workers, including fast food": {
    "ageData": {
      "2011": {
        "total": "326",
        "16to19": "58",
        "20to24": "85",
        "25to34": "65",
        "35to44": "38",
        "45to54": "47",
        "55to64": "24",
        "65+": "9",
        "medianAge": "24"
      }
    },
    "title": "Combined food preparation and serving workers, including fast food",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "326",
        "women": "66.0",
        "white": "16.3",
        "black": "3.7",
        "asian": "15.4"
      }
    },
    "wageData": {
      "2011": {
        "workers": "161",
        "median": "387",
        "maleWorkers": "46",
        "maleMedian": "-",
        "femaleWorkers": "114",
        "femaleMedian": "385"
      }
    }
  },
  "Counter attendants, cafeteria, food concession, and coffee shop": {
    "ageData": {
      "2011": {
        "total": "255",
        "16to19": "122",
        "20to24": "72",
        "25to34": "23",
        "35to44": "13",
        "45to54": "13",
        "55to64": "9",
        "65+": "4",
        "medianAge": "9"
      }
    },
    "title": "Counter attendants, cafeteria, food concession, and coffee shop",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "255",
        "women": "68.3",
        "white": "9.1",
        "black": "3.5",
        "asian": "16.9"
      }
    },
    "wageData": {
      "2011": {
        "workers": "58",
        "median": "323",
        "maleWorkers": "26",
        "maleMedian": "-",
        "femaleWorkers": "32",
        "femaleMedian": "-"
      }
    }
  },
  "Waiters and waitresses": {
    "ageData": {
      "2011": {
        "total": "2,059",
        "16to19": "309",
        "20to24": "681",
        "25to34": "547",
        "35to44": "222",
        "45to54": "180",
        "55to64": "88",
        "65+": "31",
        "medianAge": "88"
      }
    },
    "title": "Waiters and waitresses",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "2,059",
        "women": "69.5",
        "white": "7.8",
        "black": "6.6",
        "asian": "17.1"
      }
    },
    "wageData": {
      "2011": {
        "workers": "872",
        "median": "407",
        "maleWorkers": "313",
        "maleMedian": "466",
        "femaleWorkers": "559",
        "femaleMedian": "389"
      }
    }
  },
  "Food servers, nonrestaurant": {
    "ageData": {
      "2011": {
        "total": "181",
        "16to19": "28",
        "20to24": "39",
        "25to34": "29",
        "35to44": "18",
        "45to54": "30",
        "55to64": "23",
        "65+": "14",
        "medianAge": "23"
      }
    },
    "title": "Food servers, nonrestaurant",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "181",
        "women": "67.7",
        "white": "21.0",
        "black": "6.1",
        "asian": "17.1"
      }
    },
    "wageData": {
      "2011": {
        "workers": "84",
        "median": "419",
        "maleWorkers": "31",
        "maleMedian": "-",
        "femaleWorkers": "52",
        "femaleMedian": "393"
      }
    }
  },
  "Dining room and cafeteria attendants and bartender helpers": {
    "ageData": {
      "2011": {
        "total": "347",
        "16to19": "87",
        "20to24": "84",
        "25to34": "49",
        "35to44": "42",
        "45to54": "42",
        "55to64": "28",
        "65+": "15",
        "medianAge": "28"
      }
    },
    "title": "Dining room and cafeteria attendants and bartender helpers",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "347",
        "women": "46.8",
        "white": "9.8",
        "black": "4.9",
        "asian": "31.5"
      }
    },
    "wageData": {
      "2011": {
        "workers": "119",
        "median": "383",
        "maleWorkers": "77",
        "maleMedian": "392",
        "femaleWorkers": "42",
        "femaleMedian": "-"
      }
    }
  },
  "Dishwashers": {
    "ageData": {
      "2011": {
        "total": "273",
        "16to19": "64",
        "20to24": "55",
        "25to34": "50",
        "35to44": "39",
        "45to54": "33",
        "55to64": "23",
        "65+": "9",
        "medianAge": "23"
      }
    },
    "title": "Dishwashers",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "273",
        "women": "19.9",
        "white": "11.9",
        "black": "7.4",
        "asian": "34.4"
      }
    },
    "wageData": {
      "2011": {
        "workers": "118",
        "median": "332",
        "maleWorkers": "90",
        "maleMedian": "321",
        "femaleWorkers": "28",
        "femaleMedian": "-"
      }
    }
  },
  "Hosts and hostesses, restaurant, lounge, and coffee shop": {
    "ageData": {
      "2011": {
        "total": "286",
        "16to19": "128",
        "20to24": "92",
        "25to34": "23",
        "35to44": "15",
        "45to54": "12",
        "55to64": "9",
        "65+": "7",
        "medianAge": "9"
      }
    },
    "title": "Hosts and hostesses, restaurant, lounge, and coffee shop",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "286",
        "women": "83.0",
        "white": "6.9",
        "black": "3.3",
        "asian": "16.4"
      }
    },
    "wageData": {
      "2011": {
        "workers": "62",
        "median": "369",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "52",
        "femaleMedian": "356"
      }
    }
  },
  "Food preparation and serving related workers, all other": {
    "ageData": {
      "2011": {
        "total": "4",
        "16to19": "0",
        "20to24": "1",
        "25to34": "0",
        "35to44": "0",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Food preparation and serving related workers, all other",
    "depth": 3,
    "parent": "Food preparation and serving related occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Building and grounds cleaning and maintenance occupations": {
    "ageData": {
      "2011": {
        "total": "5,492",
        "16to19": "174",
        "20to24": "473",
        "25to34": "1,022",
        "35to44": "1,128",
        "45to54": "1,412",
        "55to64": "967",
        "65+": "317",
        "medianAge": "967"
      }
    },
    "title": "Building and grounds cleaning and maintenance occupations",
    "depth": 2,
    "parent": "Service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "5,492",
        "women": "38.8",
        "white": "13.7",
        "black": "3.1",
        "asian": "34.8"
      }
    },
    "wageData": {
      "2011": {
        "workers": "3,339",
        "median": "465",
        "maleWorkers": "2,212",
        "maleMedian": "502",
        "femaleWorkers": "1,128",
        "femaleMedian": "406"
      }
    }
  },
  "First-line supervisors of housekeeping and janitorial workers": {
    "ageData": {
      "2011": {
        "total": "292",
        "16to19": "0",
        "20to24": "3",
        "25to34": "35",
        "35to44": "70",
        "45to54": "98",
        "55to64": "72",
        "65+": "14",
        "medianAge": "72"
      }
    },
    "title": "First-line supervisors of housekeeping and janitorial workers",
    "depth": 3,
    "parent": "Building and grounds cleaning and maintenance occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "292",
        "women": "41.0",
        "white": "17.3",
        "black": "3.5",
        "asian": "16.0"
      }
    },
    "wageData": {
      "2011": {
        "workers": "179",
        "median": "646",
        "maleWorkers": "113",
        "maleMedian": "756",
        "femaleWorkers": "67",
        "femaleMedian": "488"
      }
    }
  },
  "First-line supervisors of landscaping, lawn service, and groundskeeping workers": {
    "ageData": {
      "2011": {
        "total": "274",
        "16to19": "1",
        "20to24": "10",
        "25to34": "59",
        "35to44": "77",
        "45to54": "74",
        "55to64": "44",
        "65+": "9",
        "medianAge": "44"
      }
    },
    "title": "First-line supervisors of landscaping, lawn service, and groundskeeping workers",
    "depth": 3,
    "parent": "Building and grounds cleaning and maintenance occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "274",
        "women": "9.9",
        "white": "4.9",
        "black": "1.4",
        "asian": "19.1"
      }
    },
    "wageData": {
      "2011": {
        "workers": "94",
        "median": "797",
        "maleWorkers": "85",
        "maleMedian": "808",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Janitors and building cleaners": {
    "ageData": {
      "2011": {
        "total": "2,186",
        "16to19": "78",
        "20to24": "189",
        "25to34": "318",
        "35to44": "393",
        "45to54": "586",
        "55to64": "459",
        "65+": "164",
        "medianAge": "459"
      }
    },
    "title": "Janitors and building cleaners",
    "depth": 3,
    "parent": "Building and grounds cleaning and maintenance occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "2,186",
        "women": "30.3",
        "white": "16.6",
        "black": "3.1",
        "asian": "30.4"
      }
    },
    "wageData": {
      "2011": {
        "workers": "1,496",
        "median": "489",
        "maleWorkers": "1,111",
        "maleMedian": "514",
        "femaleWorkers": "384",
        "femaleMedian": "418"
      }
    }
  },
  "Maids and housekeeping cleaners": {
    "ageData": {
      "2011": {
        "total": "1,419",
        "16to19": "29",
        "20to24": "94",
        "25to34": "253",
        "35to44": "311",
        "45to54": "408",
        "55to64": "245",
        "65+": "79",
        "medianAge": "245"
      }
    },
    "title": "Maids and housekeeping cleaners",
    "depth": 3,
    "parent": "Building and grounds cleaning and maintenance occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "1,419",
        "women": "88.6",
        "white": "16.5",
        "black": "4.5",
        "asian": "39.9"
      }
    },
    "wageData": {
      "2011": {
        "workers": "751",
        "median": "399",
        "maleWorkers": "114",
        "maleMedian": "473",
        "femaleWorkers": "638",
        "femaleMedian": "392"
      }
    }
  },
  "Pest control workers": {
    "ageData": {
      "2011": {
        "total": "75",
        "16to19": "0",
        "20to24": "7",
        "25to34": "17",
        "35to44": "22",
        "45to54": "16",
        "55to64": "10",
        "65+": "3",
        "medianAge": "10"
      }
    },
    "title": "Pest control workers",
    "depth": 3,
    "parent": "Building and grounds cleaning and maintenance occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "75",
        "women": "3.7",
        "white": "7.0",
        "black": "0.0",
        "asian": "20.3"
      }
    },
    "wageData": {
      "2011": {
        "workers": "58",
        "median": "575",
        "maleWorkers": "57",
        "maleMedian": "572",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Grounds maintenance workers": {
    "ageData": {
      "2011": {
        "total": "1,247",
        "16to19": "65",
        "20to24": "171",
        "25to34": "341",
        "35to44": "256",
        "45to54": "231",
        "55to64": "136",
        "65+": "48",
        "medianAge": "136"
      }
    },
    "title": "Grounds maintenance workers",
    "depth": 3,
    "parent": "Building and grounds cleaning and maintenance occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "1,247",
        "women": "5.2",
        "white": "7.1",
        "black": "1.8",
        "asian": "45.2"
      }
    },
    "wageData": {
      "2011": {
        "workers": "761",
        "median": "425",
        "maleWorkers": "732",
        "maleMedian": "424",
        "femaleWorkers": "29",
        "femaleMedian": "-"
      }
    }
  },
  "Personal care and service occupations": {
    "ageData": {
      "2011": {
        "total": "4,979",
        "16to19": "296",
        "20to24": "724",
        "25to34": "999",
        "35to44": "995",
        "45to54": "981",
        "55to64": "694",
        "65+": "291",
        "medianAge": "694"
      }
    },
    "title": "Personal care and service occupations",
    "depth": 2,
    "parent": "Service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "4,979",
        "women": "78.4",
        "white": "14.1",
        "black": "7.6",
        "asian": "14.7"
      }
    },
    "wageData": {
      "2011": {
        "workers": "2,121",
        "median": "453",
        "maleWorkers": "578",
        "maleMedian": "562",
        "femaleWorkers": "1,543",
        "femaleMedian": "422"
      }
    }
  },
  "First-line supervisors of gaming workers": {
    "ageData": {
      "2011": {
        "total": "120",
        "16to19": "2",
        "20to24": "14",
        "25to34": "28",
        "35to44": "31",
        "45to54": "25",
        "55to64": "14",
        "65+": "6",
        "medianAge": "14"
      }
    },
    "title": "First-line supervisors of gaming workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "120",
        "women": "46.4",
        "white": "3.7",
        "black": "9.1",
        "asian": "8.4"
      }
    },
    "wageData": {
      "2011": {
        "workers": "90",
        "median": "739",
        "maleWorkers": "51",
        "maleMedian": "876",
        "femaleWorkers": "39",
        "femaleMedian": "-"
      }
    }
  },
  "First-line supervisors of personal service workers": {
    "ageData": {
      "2011": {
        "total": "192",
        "16to19": "0",
        "20to24": "16",
        "25to34": "31",
        "35to44": "61",
        "45to54": "49",
        "55to64": "30",
        "65+": "5",
        "medianAge": "30"
      }
    },
    "title": "First-line supervisors of personal service workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "192",
        "women": "73.5",
        "white": "9.9",
        "black": "13.8",
        "asian": "11.0"
      }
    },
    "wageData": {
      "2011": {
        "workers": "67",
        "median": "613",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "46",
        "femaleMedian": "-"
      }
    }
  },
  "Animal trainers": {
    "ageData": {
      "2011": {
        "total": "49",
        "16to19": "1",
        "20to24": "7",
        "25to34": "9",
        "35to44": "9",
        "45to54": "15",
        "55to64": "7",
        "65+": "1",
        "medianAge": "7"
      }
    },
    "title": "Animal trainers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "49",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "16",
        "median": "-",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Nonfarm animal caretakers": {
    "ageData": {
      "2011": {
        "total": "179",
        "16to19": "15",
        "20to24": "39",
        "25to34": "27",
        "35to44": "34",
        "45to54": "40",
        "55to64": "20",
        "65+": "5",
        "medianAge": "20"
      }
    },
    "title": "Nonfarm animal caretakers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "179",
        "women": "75.9",
        "white": "2.7",
        "black": "1.4",
        "asian": "11.0"
      }
    },
    "wageData": {
      "2011": {
        "workers": "82",
        "median": "419",
        "maleWorkers": "22",
        "maleMedian": "-",
        "femaleWorkers": "60",
        "femaleMedian": "417"
      }
    }
  },
  "Gaming services workers": {
    "ageData": {
      "2011": {
        "total": "113",
        "16to19": "3",
        "20to24": "8",
        "25to34": "31",
        "35to44": "24",
        "45to54": "26",
        "55to64": "15",
        "65+": "6",
        "medianAge": "15"
      }
    },
    "title": "Gaming services workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "113",
        "women": "44.9",
        "white": "5.6",
        "black": "23.3",
        "asian": "16.4"
      }
    },
    "wageData": {
      "2011": {
        "workers": "82",
        "median": "636",
        "maleWorkers": "43",
        "maleMedian": "-",
        "femaleWorkers": "39",
        "femaleMedian": "-"
      }
    }
  },
  "Motion picture projectionists": {
    "ageData": {
      "2011": {
        "total": "8",
        "16to19": "1",
        "20to24": "4",
        "25to34": "1",
        "35to44": "0",
        "45to54": "2",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Motion picture projectionists",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "8",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Ushers, lobby attendants, and ticket takers": {
    "ageData": {
      "2011": {
        "total": "46",
        "16to19": "12",
        "20to24": "11",
        "25to34": "11",
        "35to44": "4",
        "45to54": "2",
        "55to64": "4",
        "65+": "2",
        "medianAge": "4"
      }
    },
    "title": "Ushers, lobby attendants, and ticket takers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "46",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "9",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous entertainment attendants and related workers": {
    "ageData": {
      "2011": {
        "total": "182",
        "16to19": "41",
        "20to24": "46",
        "25to34": "30",
        "35to44": "19",
        "45to54": "17",
        "55to64": "17",
        "65+": "12",
        "medianAge": "17"
      }
    },
    "title": "Miscellaneous entertainment attendants and related workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "182",
        "women": "51.7",
        "white": "13.9",
        "black": "3.8",
        "asian": "11.3"
      }
    },
    "wageData": {
      "2011": {
        "workers": "66",
        "median": "424",
        "maleWorkers": "35",
        "maleMedian": "-",
        "femaleWorkers": "31",
        "femaleMedian": "-"
      }
    }
  },
  "Embalmers and funeral attendants": {
    "ageData": {
      "2011": {
        "total": "8",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "1",
        "45to54": "1",
        "55to64": "0",
        "65+": "4",
        "medianAge": "0"
      }
    },
    "title": "Embalmers and funeral attendants",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "8",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Morticians, undertakers, and funeral directors": {
    "ageData": {
      "2011": {
        "total": "23",
        "16to19": "0",
        "20to24": "0",
        "25to34": "4",
        "35to44": "6",
        "45to54": "3",
        "55to64": "6",
        "65+": "4",
        "medianAge": "6"
      }
    },
    "title": "Morticians, undertakers, and funeral directors",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "23",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "16",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Barbers": {
    "ageData": {
      "2011": {
        "total": "89",
        "16to19": "0",
        "20to24": "7",
        "25to34": "21",
        "35to44": "15",
        "45to54": "13",
        "55to64": "17",
        "65+": "15",
        "medianAge": "17"
      }
    },
    "title": "Barbers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "89",
        "women": "24.0",
        "white": "27.7",
        "black": "2.6",
        "asian": "17.6"
      }
    },
    "wageData": {
      "2011": {
        "workers": "29",
        "median": "-",
        "maleWorkers": "25",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Hairdressers, hairstylists, and cosmetologists": {
    "ageData": {
      "2011": {
        "total": "758",
        "16to19": "7",
        "20to24": "94",
        "25to34": "196",
        "35to44": "174",
        "45to54": "144",
        "55to64": "104",
        "65+": "40",
        "medianAge": "104"
      }
    },
    "title": "Hairdressers, hairstylists, and cosmetologists",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "758",
        "women": "93.0",
        "white": "11.6",
        "black": "4.1",
        "asian": "12.8"
      }
    },
    "wageData": {
      "2011": {
        "workers": "282",
        "median": "453",
        "maleWorkers": "23",
        "maleMedian": "-",
        "femaleWorkers": "259",
        "femaleMedian": "440"
      }
    }
  },
  "Miscellaneous personal appearance workers": {
    "ageData": {
      "2011": {
        "total": "251",
        "16to19": "5",
        "20to24": "13",
        "25to34": "62",
        "35to44": "80",
        "45to54": "50",
        "55to64": "32",
        "65+": "8",
        "medianAge": "32"
      }
    },
    "title": "Miscellaneous personal appearance workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "251",
        "women": "84.1",
        "white": "5.9",
        "black": "55.1",
        "asian": "5.3"
      }
    },
    "wageData": {
      "2011": {
        "workers": "136",
        "median": "441",
        "maleWorkers": "32",
        "maleMedian": "-",
        "femaleWorkers": "104",
        "femaleMedian": "413"
      }
    }
  },
  "Baggage porters, bellhops, and concierges": {
    "ageData": {
      "2011": {
        "total": "81",
        "16to19": "2",
        "20to24": "12",
        "25to34": "20",
        "35to44": "15",
        "45to54": "16",
        "55to64": "12",
        "65+": "4",
        "medianAge": "12"
      }
    },
    "title": "Baggage porters, bellhops, and concierges",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "81",
        "women": "14.0",
        "white": "20.7",
        "black": "9.1",
        "asian": "28.4"
      }
    },
    "wageData": {
      "2011": {
        "workers": "63",
        "median": "545",
        "maleWorkers": "55",
        "maleMedian": "561",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Tour and travel guides": {
    "ageData": {
      "2011": {
        "total": "38",
        "16to19": "5",
        "20to24": "10",
        "25to34": "4",
        "35to44": "4",
        "45to54": "5",
        "55to64": "5",
        "65+": "5",
        "medianAge": "5"
      }
    },
    "title": "Tour and travel guides",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "38",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "9",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Childcare workers": {
    "ageData": {
      "2011": {
        "total": "1,231",
        "16to19": "126",
        "20to24": "219",
        "25to34": "215",
        "35to44": "219",
        "45to54": "229",
        "55to64": "156",
        "65+": "66",
        "medianAge": "156"
      }
    },
    "title": "Childcare workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "1,231",
        "women": "94.5",
        "white": "15.0",
        "black": "3.4",
        "asian": "18.1"
      }
    },
    "wageData": {
      "2011": {
        "workers": "367",
        "median": "382",
        "maleWorkers": "26",
        "maleMedian": "-",
        "femaleWorkers": "342",
        "femaleMedian": "383"
      }
    }
  },
  "Personal care aides": {
    "ageData": {
      "2011": {
        "total": "1,057",
        "16to19": "21",
        "20to24": "129",
        "25to34": "194",
        "35to44": "189",
        "45to54": "252",
        "55to64": "189",
        "65+": "82",
        "medianAge": "189"
      }
    },
    "title": "Personal care aides",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "1,057",
        "women": "85.6",
        "white": "21.9",
        "black": "5.8",
        "asian": "19.3"
      }
    },
    "wageData": {
      "2011": {
        "workers": "549",
        "median": "412",
        "maleWorkers": "94",
        "maleMedian": "454",
        "femaleWorkers": "455",
        "femaleMedian": "407"
      }
    }
  },
  "Recreation and fitness workers": {
    "ageData": {
      "2011": {
        "total": "390",
        "16to19": "38",
        "20to24": "62",
        "25to34": "82",
        "35to44": "74",
        "45to54": "65",
        "55to64": "49",
        "65+": "20",
        "medianAge": "49"
      }
    },
    "title": "Recreation and fitness workers",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "390",
        "women": "64.1",
        "white": "10.5",
        "black": "3.4",
        "asian": "7.2"
      }
    },
    "wageData": {
      "2011": {
        "workers": "177",
        "median": "506",
        "maleWorkers": "76",
        "maleMedian": "575",
        "femaleWorkers": "101",
        "femaleMedian": "494"
      }
    }
  },
  "Residential advisors": {
    "ageData": {
      "2011": {
        "total": "59",
        "16to19": "3",
        "20to24": "11",
        "25to34": "13",
        "35to44": "12",
        "45to54": "11",
        "55to64": "9",
        "65+": "1",
        "medianAge": "9"
      }
    },
    "title": "Residential advisors",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "59",
        "women": "58.3",
        "white": "22.4",
        "black": "5.1",
        "asian": "5.4"
      }
    },
    "wageData": {
      "2011": {
        "workers": "42",
        "median": "-",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "27",
        "femaleMedian": "-"
      }
    }
  },
  "Personal care and service workers, all other": {
    "ageData": {
      "2011": {
        "total": "105",
        "16to19": "14",
        "20to24": "21",
        "25to34": "20",
        "35to44": "22",
        "45to54": "14",
        "55to64": "9",
        "65+": "5",
        "medianAge": "9"
      }
    },
    "title": "Personal care and service workers, all other",
    "depth": 3,
    "parent": "Personal care and service occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "105",
        "women": "48.2",
        "white": "8.1",
        "black": "2.3",
        "asian": "11.5"
      }
    },
    "wageData": {
      "2011": {
        "workers": "34",
        "median": "-",
        "maleWorkers": "23",
        "maleMedian": "-",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "Sales and office occupations": {
    "ageData": {
      "2011": {
        "total": "33,066",
        "16to19": "1,482",
        "20to24": "3,972",
        "25to34": "6,802",
        "35to44": "6,342",
        "45to54": "7,436",
        "55to64": "5,305",
        "65+": "1,727",
        "medianAge": "5,305"
      }
    },
    "title": "Sales and office occupations",
    "depth": 1,
    "parent": "",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "33,066",
        "women": "62.3",
        "white": "11.3",
        "black": "4.4",
        "asian": "13.2"
      }
    },
    "wageData": {
      "2011": {
        "workers": "22,989",
        "median": "638",
        "maleWorkers": "9,012",
        "maleMedian": "738",
        "femaleWorkers": "13,977",
        "femaleMedian": "602"
      }
    }
  },
  "Sales and related occupations": {
    "ageData": {
      "2011": {
        "total": "15,330",
        "16to19": "993",
        "20to24": "2,124",
        "25to34": "3,152",
        "35to44": "2,797",
        "45to54": "3,166",
        "55to64": "2,206",
        "65+": "893",
        "medianAge": "2,206"
      }
    },
    "title": "Sales and related occupations",
    "depth": 2,
    "parent": "Sales and office occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "15,330",
        "women": "49.6",
        "white": "9.8",
        "black": "4.9",
        "asian": "12.6"
      }
    },
    "wageData": {
      "2011": {
        "workers": "9,294",
        "median": "670",
        "maleWorkers": "5,225",
        "maleMedian": "804",
        "femaleWorkers": "4,069",
        "femaleMedian": "549"
      }
    }
  },
  "First-line supervisors of retail sales workers": {
    "ageData": {
      "2011": {
        "total": "3,217",
        "16to19": "17",
        "20to24": "271",
        "25to34": "784",
        "35to44": "676",
        "45to54": "817",
        "55to64": "489",
        "65+": "163",
        "medianAge": "489"
      }
    },
    "title": "First-line supervisors of retail sales workers",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "3,217",
        "women": "43.5",
        "white": "8.8",
        "black": "5.4",
        "asian": "10.9"
      }
    },
    "wageData": {
      "2011": {
        "workers": "2,223",
        "median": "676",
        "maleWorkers": "1,263",
        "maleMedian": "759",
        "femaleWorkers": "959",
        "femaleMedian": "599"
      }
    }
  },
  "First-line supervisors of non-retail sales workers": {
    "ageData": {
      "2011": {
        "total": "1,088",
        "16to19": "2",
        "20to24": "34",
        "25to34": "187",
        "35to44": "276",
        "45to54": "319",
        "55to64": "201",
        "65+": "69",
        "medianAge": "201"
      }
    },
    "title": "First-line supervisors of non-retail sales workers",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "1,088",
        "women": "26.7",
        "white": "5.8",
        "black": "4.9",
        "asian": "10.1"
      }
    },
    "wageData": {
      "2011": {
        "workers": "702",
        "median": "951",
        "maleWorkers": "488",
        "maleMedian": "1,016",
        "femaleWorkers": "214",
        "femaleMedian": "781"
      }
    }
  },
  "Cashiers": {
    "ageData": {
      "2011": {
        "total": "3,158",
        "16to19": "635",
        "20to24": "843",
        "25to34": "565",
        "35to44": "381",
        "45to54": "363",
        "55to64": "265",
        "65+": "106",
        "medianAge": "265"
      }
    },
    "title": "Cashiers",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "3,158",
        "women": "73.6",
        "white": "15.6",
        "black": "6.5",
        "asian": "18.1"
      }
    },
    "wageData": {
      "2011": {
        "workers": "1,343",
        "median": "383",
        "maleWorkers": "381",
        "maleMedian": "411",
        "femaleWorkers": "962",
        "femaleMedian": "373"
      }
    }
  },
  "Counter and rental clerks": {
    "ageData": {
      "2011": {
        "total": "139",
        "16to19": "11",
        "20to24": "29",
        "25to34": "24",
        "35to44": "19",
        "45to54": "26",
        "55to64": "20",
        "65+": "10",
        "medianAge": "20"
      }
    },
    "title": "Counter and rental clerks",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "139",
        "women": "48.6",
        "white": "10.2",
        "black": "8.6",
        "asian": "13.0"
      }
    },
    "wageData": {
      "2011": {
        "workers": "87",
        "median": "603",
        "maleWorkers": "50",
        "maleMedian": "620",
        "femaleWorkers": "38",
        "femaleMedian": "-"
      }
    }
  },
  "Parts salespersons": {
    "ageData": {
      "2011": {
        "total": "131",
        "16to19": "2",
        "20to24": "16",
        "25to34": "31",
        "35to44": "25",
        "45to54": "28",
        "55to64": "23",
        "65+": "6",
        "medianAge": "23"
      }
    },
    "title": "Parts salespersons",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "131",
        "women": "15.5",
        "white": "6.5",
        "black": "0.3",
        "asian": "10.3"
      }
    },
    "wageData": {
      "2011": {
        "workers": "117",
        "median": "610",
        "maleWorkers": "100",
        "maleMedian": "587",
        "femaleWorkers": "16",
        "femaleMedian": "-"
      }
    }
  },
  "Retail salespersons": {
    "ageData": {
      "2011": {
        "total": "3,224",
        "16to19": "273",
        "20to24": "663",
        "25to34": "639",
        "35to44": "471",
        "45to54": "535",
        "55to64": "457",
        "65+": "188",
        "medianAge": "457"
      }
    },
    "title": "Retail salespersons",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "3,224",
        "women": "51.2",
        "white": "11.5",
        "black": "4.4",
        "asian": "14.3"
      }
    },
    "wageData": {
      "2011": {
        "workers": "1,789",
        "median": "545",
        "maleWorkers": "1,056",
        "maleMedian": "620",
        "femaleWorkers": "733",
        "femaleMedian": "466"
      }
    }
  },
  "Advertising sales agents": {
    "ageData": {
      "2011": {
        "total": "254",
        "16to19": "0",
        "20to24": "22",
        "25to34": "82",
        "35to44": "56",
        "45to54": "61",
        "55to64": "24",
        "65+": "9",
        "medianAge": "24"
      }
    },
    "title": "Advertising sales agents",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "254",
        "women": "55.6",
        "white": "10.8",
        "black": "1.6",
        "asian": "8.8"
      }
    },
    "wageData": {
      "2011": {
        "workers": "202",
        "median": "893",
        "maleWorkers": "90",
        "maleMedian": "961",
        "femaleWorkers": "112",
        "femaleMedian": "772"
      }
    }
  },
  "Insurance sales agents": {
    "ageData": {
      "2011": {
        "total": "531",
        "16to19": "4",
        "20to24": "26",
        "25to34": "108",
        "35to44": "112",
        "45to54": "137",
        "55to64": "99",
        "65+": "45",
        "medianAge": "99"
      }
    },
    "title": "Insurance sales agents",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "531",
        "women": "47.3",
        "white": "8.2",
        "black": "3.2",
        "asian": "9.1"
      }
    },
    "wageData": {
      "2011": {
        "workers": "384",
        "median": "807",
        "maleWorkers": "183",
        "maleMedian": "1,033",
        "femaleWorkers": "201",
        "femaleMedian": "665"
      }
    }
  },
  "Securities, commodities, and financial services sales agents": {
    "ageData": {
      "2011": {
        "total": "267",
        "16to19": "0",
        "20to24": "13",
        "25to34": "81",
        "35to44": "63",
        "45to54": "54",
        "55to64": "41",
        "65+": "15",
        "medianAge": "41"
      }
    },
    "title": "Securities, commodities, and financial services sales agents",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "267",
        "women": "29.9",
        "white": "4.7",
        "black": "6.5",
        "asian": "5.6"
      }
    },
    "wageData": {
      "2011": {
        "workers": "222",
        "median": "1,144",
        "maleWorkers": "149",
        "maleMedian": "1,269",
        "femaleWorkers": "73",
        "femaleMedian": "884"
      }
    }
  },
  "Travel agents": {
    "ageData": {
      "2011": {
        "total": "69",
        "16to19": "0",
        "20to24": "5",
        "25to34": "7",
        "35to44": "13",
        "45to54": "19",
        "55to64": "14",
        "65+": "10",
        "medianAge": "14"
      }
    },
    "title": "Travel agents",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "69",
        "women": "79.4",
        "white": "5.9",
        "black": "5.3",
        "asian": "9.5"
      }
    },
    "wageData": {
      "2011": {
        "workers": "49",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "38",
        "femaleMedian": "-"
      }
    }
  },
  "Sales representatives, services, all other": {
    "ageData": {
      "2011": {
        "total": "503",
        "16to19": "5",
        "20to24": "41",
        "25to34": "129",
        "35to44": "122",
        "45to54": "109",
        "55to64": "75",
        "65+": "22",
        "medianAge": "75"
      }
    },
    "title": "Sales representatives, services, all other",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "503",
        "women": "31.2",
        "white": "7.6",
        "black": "4.6",
        "asian": "10.9"
      }
    },
    "wageData": {
      "2011": {
        "workers": "397",
        "median": "887",
        "maleWorkers": "278",
        "maleMedian": "953",
        "femaleWorkers": "119",
        "femaleMedian": "757"
      }
    }
  },
  "Sales representatives, wholesale and manufacturing": {
    "ageData": {
      "2011": {
        "total": "1,297",
        "16to19": "13",
        "20to24": "60",
        "25to34": "266",
        "35to44": "314",
        "45to54": "361",
        "55to64": "212",
        "65+": "71",
        "medianAge": "212"
      }
    },
    "title": "Sales representatives, wholesale and manufacturing",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "1,297",
        "women": "25.9",
        "white": "2.9",
        "black": "3.4",
        "asian": "7.9"
      }
    },
    "wageData": {
      "2011": {
        "workers": "1,091",
        "median": "991",
        "maleWorkers": "829",
        "maleMedian": "1,019",
        "femaleWorkers": "262",
        "femaleMedian": "927"
      }
    }
  },
  "Models, demonstrators, and product promoters": {
    "ageData": {
      "2011": {
        "total": "78",
        "16to19": "9",
        "20to24": "11",
        "25to34": "13",
        "35to44": "13",
        "45to54": "11",
        "55to64": "11",
        "65+": "9",
        "medianAge": "11"
      }
    },
    "title": "Models, demonstrators, and product promoters",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "78",
        "women": "79.2",
        "white": "13.0",
        "black": "0.2",
        "asian": "14.6"
      }
    },
    "wageData": {
      "2011": {
        "workers": "15",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "Real estate brokers and sales agents": {
    "ageData": {
      "2011": {
        "total": "811",
        "16to19": "1",
        "20to24": "24",
        "25to34": "122",
        "35to44": "153",
        "45to54": "203",
        "55to64": "192",
        "65+": "116",
        "medianAge": "192"
      }
    },
    "title": "Real estate brokers and sales agents",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "811",
        "women": "56.8",
        "white": "4.6",
        "black": "4.2",
        "asian": "8.8"
      }
    },
    "wageData": {
      "2011": {
        "workers": "380",
        "median": "812",
        "maleWorkers": "176",
        "maleMedian": "992",
        "femaleWorkers": "204",
        "femaleMedian": "676"
      }
    }
  },
  "Sales engineers": {
    "ageData": {
      "2011": {
        "total": "30",
        "16to19": "0",
        "20to24": "2",
        "25to34": "2",
        "35to44": "7",
        "45to54": "12",
        "55to64": "5",
        "65+": "2",
        "medianAge": "5"
      }
    },
    "title": "Sales engineers",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "30",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "30",
        "median": "-",
        "maleWorkers": "27",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Telemarketers": {
    "ageData": {
      "2011": {
        "total": "108",
        "16to19": "9",
        "20to24": "28",
        "25to34": "32",
        "35to44": "9",
        "45to54": "14",
        "55to64": "11",
        "65+": "5",
        "medianAge": "11"
      }
    },
    "title": "Telemarketers",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "108",
        "women": "59.4",
        "white": "14.9",
        "black": "1.2",
        "asian": "17.4"
      }
    },
    "wageData": {
      "2011": {
        "workers": "69",
        "median": "412",
        "maleWorkers": "29",
        "maleMedian": "-",
        "femaleWorkers": "40",
        "femaleMedian": "-"
      }
    }
  },
  "Door-to-door sales workers, news and street vendors, and related workers": {
    "ageData": {
      "2011": {
        "total": "201",
        "16to19": "8",
        "20to24": "19",
        "25to34": "39",
        "35to44": "34",
        "45to54": "47",
        "55to64": "33",
        "65+": "20",
        "medianAge": "33"
      }
    },
    "title": "Door-to-door sales workers, news and street vendors, and related workers",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "201",
        "women": "59.2",
        "white": "12.6",
        "black": "3.0",
        "asian": "17.8"
      }
    },
    "wageData": {
      "2011": {
        "workers": "55",
        "median": "526",
        "maleWorkers": "35",
        "maleMedian": "-",
        "femaleWorkers": "20",
        "femaleMedian": "-"
      }
    }
  },
  "Sales and related workers, all other": {
    "ageData": {
      "2011": {
        "total": "226",
        "16to19": "4",
        "20to24": "16",
        "25to34": "41",
        "35to44": "53",
        "45to54": "51",
        "55to64": "36",
        "65+": "25",
        "medianAge": "36"
      }
    },
    "title": "Sales and related workers, all other",
    "depth": 3,
    "parent": "Sales and related occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "226",
        "women": "50.7",
        "white": "8.5",
        "black": "3.3",
        "asian": "10.0"
      }
    },
    "wageData": {
      "2011": {
        "workers": "140",
        "median": "781",
        "maleWorkers": "76",
        "maleMedian": "985",
        "femaleWorkers": "64",
        "femaleMedian": "618"
      }
    }
  },
  "Office and administrative support occupations": {
    "ageData": {
      "2011": {
        "total": "17,736",
        "16to19": "489",
        "20to24": "1,848",
        "25to34": "3,650",
        "35to44": "3,546",
        "45to54": "4,270",
        "55to64": "3,099",
        "65+": "835",
        "medianAge": "3,099"
      }
    },
    "title": "Office and administrative support occupations",
    "depth": 2,
    "parent": "Sales and office occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "17,736",
        "women": "73.4",
        "white": "12.6",
        "black": "4.1",
        "asian": "13.7"
      }
    },
    "wageData": {
      "2011": {
        "workers": "13,695",
        "median": "623",
        "maleWorkers": "3,787",
        "maleMedian": "668",
        "femaleWorkers": "9,908",
        "femaleMedian": "615"
      }
    }
  },
  "First-line supervisors of office and administrative support workers": {
    "ageData": {
      "2011": {
        "total": "1,423",
        "16to19": "4",
        "20to24": "49",
        "25to34": "269",
        "35to44": "361",
        "45to54": "391",
        "55to64": "296",
        "65+": "53",
        "medianAge": "296"
      }
    },
    "title": "First-line supervisors of office and administrative support workers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "1,423",
        "women": "68.2",
        "white": "9.6",
        "black": "3.7",
        "asian": "11.7"
      }
    },
    "wageData": {
      "2011": {
        "workers": "1,274",
        "median": "764",
        "maleWorkers": "418",
        "maleMedian": "833",
        "femaleWorkers": "856",
        "femaleMedian": "741"
      }
    }
  },
  "Switchboard operators, including answering service": {
    "ageData": {
      "2011": {
        "total": "39",
        "16to19": "0",
        "20to24": "3",
        "25to34": "12",
        "35to44": "4",
        "45to54": "5",
        "55to64": "12",
        "65+": "4",
        "medianAge": "12"
      }
    },
    "title": "Switchboard operators, including answering service",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "39",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "29",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "25",
        "femaleMedian": "-"
      }
    }
  },
  "Telephone operators": {
    "ageData": {
      "2011": {
        "total": "40",
        "16to19": "0",
        "20to24": "9",
        "25to34": "10",
        "35to44": "7",
        "45to54": "8",
        "55to64": "6",
        "65+": "0",
        "medianAge": "6"
      }
    },
    "title": "Telephone operators",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "40",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "38",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "30",
        "femaleMedian": "-"
      }
    }
  },
  "Communications equipment operators, all other": {
    "ageData": {
      "2011": {
        "total": "7",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "2",
        "45to54": "2",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Communications equipment operators, all other",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Bill and account collectors": {
    "ageData": {
      "2011": {
        "total": "211",
        "16to19": "1",
        "20to24": "27",
        "25to34": "65",
        "35to44": "44",
        "45to54": "42",
        "55to64": "25",
        "65+": "8",
        "medianAge": "25"
      }
    },
    "title": "Bill and account collectors",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "211",
        "women": "70.6",
        "white": "17.3",
        "black": "3.8",
        "asian": "18.4"
      }
    },
    "wageData": {
      "2011": {
        "workers": "178",
        "median": "608",
        "maleWorkers": "48",
        "maleMedian": "-",
        "femaleWorkers": "130",
        "femaleMedian": "597"
      }
    }
  },
  "Billing and posting clerks": {
    "ageData": {
      "2011": {
        "total": "471",
        "16to19": "3",
        "20to24": "35",
        "25to34": "117",
        "35to44": "100",
        "45to54": "134",
        "55to64": "69",
        "65+": "13",
        "medianAge": "69"
      }
    },
    "title": "Billing and posting clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "471",
        "women": "90.1",
        "white": "12.3",
        "black": "4.5",
        "asian": "15.2"
      }
    },
    "wageData": {
      "2011": {
        "workers": "397",
        "median": "607",
        "maleWorkers": "38",
        "maleMedian": "-",
        "femaleWorkers": "359",
        "femaleMedian": "605"
      }
    }
  },
  "Bookkeeping, accounting, and auditing clerks": {
    "ageData": {
      "2011": {
        "total": "1,300",
        "16to19": "4",
        "20to24": "64",
        "25to34": "192",
        "35to44": "278",
        "45to54": "362",
        "55to64": "283",
        "65+": "117",
        "medianAge": "283"
      }
    },
    "title": "Bookkeeping, accounting, and auditing clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "1,300",
        "women": "89.9",
        "white": "7.7",
        "black": "4.1",
        "asian": "8.4"
      }
    },
    "wageData": {
      "2011": {
        "workers": "862",
        "median": "655",
        "maleWorkers": "112",
        "maleMedian": "654",
        "femaleWorkers": "750",
        "femaleMedian": "656"
      }
    }
  },
  "Gaming cage workers": {
    "ageData": {
      "2011": {
        "total": "5",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "1",
        "45to54": "1",
        "55to64": "1",
        "65+": "1",
        "medianAge": "1"
      }
    },
    "title": "Gaming cage workers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Payroll and timekeeping clerks": {
    "ageData": {
      "2011": {
        "total": "168",
        "16to19": "1",
        "20to24": "4",
        "25to34": "20",
        "35to44": "46",
        "45to54": "58",
        "55to64": "32",
        "65+": "6",
        "medianAge": "32"
      }
    },
    "title": "Payroll and timekeeping clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "168",
        "women": "89.3",
        "white": "14.2",
        "black": "3.7",
        "asian": "12.0"
      }
    },
    "wageData": {
      "2011": {
        "workers": "136",
        "median": "710",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "125",
        "femaleMedian": "704"
      }
    }
  },
  "Procurement clerks": {
    "ageData": {
      "2011": {
        "total": "26",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "7",
        "45to54": "8",
        "55to64": "8",
        "65+": "1",
        "medianAge": "8"
      }
    },
    "title": "Procurement clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "26",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "24",
        "median": "-",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "16",
        "femaleMedian": "-"
      }
    }
  },
  "Tellers": {
    "ageData": {
      "2011": {
        "total": "413",
        "16to19": "17",
        "20to24": "106",
        "25to34": "111",
        "35to44": "57",
        "45to54": "59",
        "55to64": "50",
        "65+": "14",
        "medianAge": "50"
      }
    },
    "title": "Tellers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "413",
        "women": "85.3",
        "white": "8.7",
        "black": "3.9",
        "asian": "14.8"
      }
    },
    "wageData": {
      "2011": {
        "workers": "283",
        "median": "492",
        "maleWorkers": "41",
        "maleMedian": "-",
        "femaleWorkers": "241",
        "femaleMedian": "500"
      }
    }
  },
  "Financial clerks, all other": {
    "ageData": {
      "2011": {
        "total": "64",
        "16to19": "1",
        "20to24": "5",
        "25to34": "16",
        "35to44": "16",
        "45to54": "15",
        "55to64": "10",
        "65+": "2",
        "medianAge": "10"
      }
    },
    "title": "Financial clerks, all other",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "64",
        "women": "77.1",
        "white": "10.4",
        "black": "14.1",
        "asian": "14.9"
      }
    },
    "wageData": {
      "2011": {
        "workers": "64",
        "median": "793",
        "maleWorkers": "16",
        "maleMedian": "-",
        "femaleWorkers": "48",
        "femaleMedian": "-"
      }
    }
  },
  "Brokerage clerks": {
    "ageData": {
      "2011": {
        "total": "11",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "4",
        "45to54": "2",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Brokerage clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "11",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "11",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Correspondence clerks": {
    "ageData": {
      "2011": {
        "total": "12",
        "16to19": "0",
        "20to24": "4",
        "25to34": "5",
        "35to44": "2",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Correspondence clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "12",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "10",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Court, municipal, and license clerks": {
    "ageData": {
      "2011": {
        "total": "86",
        "16to19": "0",
        "20to24": "1",
        "25to34": "13",
        "35to44": "20",
        "45to54": "25",
        "55to64": "24",
        "65+": "3",
        "medianAge": "24"
      }
    },
    "title": "Court, municipal, and license clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "86",
        "women": "79.5",
        "white": "16.6",
        "black": "3.5",
        "asian": "7.3"
      }
    },
    "wageData": {
      "2011": {
        "workers": "74",
        "median": "763",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "60",
        "femaleMedian": "738"
      }
    }
  },
  "Credit authorizers, checkers, and clerks": {
    "ageData": {
      "2011": {
        "total": "45",
        "16to19": "0",
        "20to24": "2",
        "25to34": "11",
        "35to44": "14",
        "45to54": "10",
        "55to64": "7",
        "65+": "0",
        "medianAge": "7"
      }
    },
    "title": "Credit authorizers, checkers, and clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "45",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "47",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "34",
        "femaleMedian": "-"
      }
    }
  },
  "Customer service representatives": {
    "ageData": {
      "2011": {
        "total": "1,916",
        "16to19": "90",
        "20to24": "305",
        "25to34": "496",
        "35to44": "378",
        "45to54": "369",
        "55to64": "229",
        "65+": "48",
        "medianAge": "229"
      }
    },
    "title": "Customer service representatives",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "1,916",
        "women": "66.5",
        "white": "15.7",
        "black": "4.5",
        "asian": "16.9"
      }
    },
    "wageData": {
      "2011": {
        "workers": "1,509",
        "median": "588",
        "maleWorkers": "538",
        "maleMedian": "628",
        "femaleWorkers": "972",
        "femaleMedian": "569"
      }
    }
  },
  "Eligibility interviewers, government programs": {
    "ageData": {
      "2011": {
        "total": "85",
        "16to19": "0",
        "20to24": "2",
        "25to34": "27",
        "35to44": "22",
        "45to54": "16",
        "55to64": "15",
        "65+": "4",
        "medianAge": "15"
      }
    },
    "title": "Eligibility interviewers, government programs",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "85",
        "women": "84.2",
        "white": "22.4",
        "black": "3.3",
        "asian": "19.7"
      }
    },
    "wageData": {
      "2011": {
        "workers": "79",
        "median": "632",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "70",
        "femaleMedian": "632"
      }
    }
  },
  "File clerks": {
    "ageData": {
      "2011": {
        "total": "334",
        "16to19": "23",
        "20to24": "48",
        "25to34": "55",
        "35to44": "63",
        "45to54": "77",
        "55to64": "49",
        "65+": "18",
        "medianAge": "49"
      }
    },
    "title": "File clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "334",
        "women": "83.9",
        "white": "13.7",
        "black": "6.4",
        "asian": "15.3"
      }
    },
    "wageData": {
      "2011": {
        "workers": "248",
        "median": "616",
        "maleWorkers": "32",
        "maleMedian": "-",
        "femaleWorkers": "217",
        "femaleMedian": "620"
      }
    }
  },
  "Hotel, motel, and resort desk clerks": {
    "ageData": {
      "2011": {
        "total": "135",
        "16to19": "5",
        "20to24": "38",
        "25to34": "40",
        "35to44": "20",
        "45to54": "13",
        "55to64": "12",
        "65+": "7",
        "medianAge": "12"
      }
    },
    "title": "Hotel, motel, and resort desk clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "135",
        "women": "64.4",
        "white": "19.6",
        "black": "7.8",
        "asian": "16.9"
      }
    },
    "wageData": {
      "2011": {
        "workers": "86",
        "median": "423",
        "maleWorkers": "33",
        "maleMedian": "-",
        "femaleWorkers": "53",
        "femaleMedian": "416"
      }
    }
  },
  "Interviewers, except eligibility and loan": {
    "ageData": {
      "2011": {
        "total": "153",
        "16to19": "6",
        "20to24": "15",
        "25to34": "39",
        "35to44": "24",
        "45to54": "36",
        "55to64": "26",
        "65+": "8",
        "medianAge": "26"
      }
    },
    "title": "Interviewers, except eligibility and loan",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "153",
        "women": "84.4",
        "white": "17.2",
        "black": "4.5",
        "asian": "18.3"
      }
    },
    "wageData": {
      "2011": {
        "workers": "121",
        "median": "546",
        "maleWorkers": "21",
        "maleMedian": "-",
        "femaleWorkers": "99",
        "femaleMedian": "551"
      }
    }
  },
  "Library assistants, clerical": {
    "ageData": {
      "2011": {
        "total": "113",
        "16to19": "7",
        "20to24": "26",
        "25to34": "11",
        "35to44": "15",
        "45to54": "15",
        "55to64": "29",
        "65+": "10",
        "medianAge": "29"
      }
    },
    "title": "Library assistants, clerical",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "113",
        "women": "81.6",
        "white": "8.6",
        "black": "5.2",
        "asian": "8.5"
      }
    },
    "wageData": {
      "2011": {
        "workers": "49",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "38",
        "femaleMedian": "-"
      }
    }
  },
  "Loan interviewers and clerks": {
    "ageData": {
      "2011": {
        "total": "117",
        "16to19": "1",
        "20to24": "6",
        "25to34": "34",
        "35to44": "37",
        "45to54": "26",
        "55to64": "12",
        "65+": "2",
        "medianAge": "12"
      }
    },
    "title": "Loan interviewers and clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "117",
        "women": "82.7",
        "white": "11.9",
        "black": "4.2",
        "asian": "10.4"
      }
    },
    "wageData": {
      "2011": {
        "workers": "110",
        "median": "706",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "93",
        "femaleMedian": "696"
      }
    }
  },
  "New accounts clerks": {
    "ageData": {
      "2011": {
        "total": "33",
        "16to19": "0",
        "20to24": "4",
        "25to34": "11",
        "35to44": "6",
        "45to54": "7",
        "55to64": "5",
        "65+": "0",
        "medianAge": "5"
      }
    },
    "title": "New accounts clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "33",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "27",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "19",
        "femaleMedian": "-"
      }
    }
  },
  "Order clerks": {
    "ageData": {
      "2011": {
        "total": "113",
        "16to19": "4",
        "20to24": "15",
        "25to34": "21",
        "35to44": "20",
        "45to54": "25",
        "55to64": "23",
        "65+": "5",
        "medianAge": "23"
      }
    },
    "title": "Order clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "113",
        "women": "67.1",
        "white": "5.9",
        "black": "5.4",
        "asian": "11.5"
      }
    },
    "wageData": {
      "2011": {
        "workers": "96",
        "median": "586",
        "maleWorkers": "35",
        "maleMedian": "-",
        "femaleWorkers": "60",
        "femaleMedian": "577"
      }
    }
  },
  "Human resources assistants, except payroll and timekeeping": {
    "ageData": {
      "2011": {
        "total": "40",
        "16to19": "1",
        "20to24": "3",
        "25to34": "9",
        "35to44": "11",
        "45to54": "10",
        "55to64": "6",
        "65+": "1",
        "medianAge": "6"
      }
    },
    "title": "Human resources assistants, except payroll and timekeeping",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "40",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "45",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "40",
        "femaleMedian": "-"
      }
    }
  },
  "Receptionists and information clerks": {
    "ageData": {
      "2011": {
        "total": "1,259",
        "16to19": "66",
        "20to24": "232",
        "25to34": "258",
        "35to44": "210",
        "45to54": "227",
        "55to64": "192",
        "65+": "73",
        "medianAge": "192"
      }
    },
    "title": "Receptionists and information clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "1,259",
        "women": "92.1",
        "white": "11.3",
        "black": "3.9",
        "asian": "17.0"
      }
    },
    "wageData": {
      "2011": {
        "workers": "868",
        "median": "520",
        "maleWorkers": "78",
        "maleMedian": "521",
        "femaleWorkers": "790",
        "femaleMedian": "520"
      }
    }
  },
  "Reservation and transportation ticket agents and travel clerks": {
    "ageData": {
      "2011": {
        "total": "99",
        "16to19": "2",
        "20to24": "8",
        "25to34": "21",
        "35to44": "25",
        "45to54": "29",
        "55to64": "12",
        "65+": "3",
        "medianAge": "12"
      }
    },
    "title": "Reservation and transportation ticket agents and travel clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "99",
        "women": "59.5",
        "white": "23.2",
        "black": "5.8",
        "asian": "8.8"
      }
    },
    "wageData": {
      "2011": {
        "workers": "80",
        "median": "649",
        "maleWorkers": "33",
        "maleMedian": "-",
        "femaleWorkers": "47",
        "femaleMedian": "-"
      }
    }
  },
  "Information and record clerks, all other": {
    "ageData": {
      "2011": {
        "total": "118",
        "16to19": "3",
        "20to24": "7",
        "25to34": "27",
        "35to44": "19",
        "45to54": "30",
        "55to64": "25",
        "65+": "7",
        "medianAge": "25"
      }
    },
    "title": "Information and record clerks, all other",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "118",
        "women": "83.6",
        "white": "15.2",
        "black": "2.1",
        "asian": "11.5"
      }
    },
    "wageData": {
      "2011": {
        "workers": "105",
        "median": "744",
        "maleWorkers": "18",
        "maleMedian": "-",
        "femaleWorkers": "86",
        "femaleMedian": "737"
      }
    }
  },
  "Cargo and freight agents": {
    "ageData": {
      "2011": {
        "total": "8",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "2",
        "45to54": "3",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Cargo and freight agents",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "8",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Couriers and messengers": {
    "ageData": {
      "2011": {
        "total": "249",
        "16to19": "3",
        "20to24": "11",
        "25to34": "41",
        "35to44": "57",
        "45to54": "78",
        "55to64": "35",
        "65+": "24",
        "medianAge": "35"
      }
    },
    "title": "Couriers and messengers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "249",
        "women": "16.4",
        "white": "16.7",
        "black": "2.5",
        "asian": "14.4"
      }
    },
    "wageData": {
      "2011": {
        "workers": "164",
        "median": "744",
        "maleWorkers": "145",
        "maleMedian": "785",
        "femaleWorkers": "19",
        "femaleMedian": "-"
      }
    }
  },
  "Dispatchers": {
    "ageData": {
      "2011": {
        "total": "239",
        "16to19": "5",
        "20to24": "13",
        "25to34": "66",
        "35to44": "56",
        "45to54": "54",
        "55to64": "38",
        "65+": "6",
        "medianAge": "38"
      }
    },
    "title": "Dispatchers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "239",
        "women": "57.9",
        "white": "17.8",
        "black": "1.9",
        "asian": "12.0"
      }
    },
    "wageData": {
      "2011": {
        "workers": "207",
        "median": "651",
        "maleWorkers": "81",
        "maleMedian": "728",
        "femaleWorkers": "126",
        "femaleMedian": "626"
      }
    }
  },
  "Meter readers, utilities": {
    "ageData": {
      "2011": {
        "total": "28",
        "16to19": "0",
        "20to24": "6",
        "25to34": "7",
        "35to44": "6",
        "45to54": "6",
        "55to64": "1",
        "65+": "1",
        "medianAge": "1"
      }
    },
    "title": "Meter readers, utilities",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "28",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "21",
        "median": "-",
        "maleWorkers": "18",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Postal service clerks": {
    "ageData": {
      "2011": {
        "total": "146",
        "16to19": "0",
        "20to24": "1",
        "25to34": "8",
        "35to44": "24",
        "45to54": "46",
        "55to64": "60",
        "65+": "6",
        "medianAge": "60"
      }
    },
    "title": "Postal service clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "146",
        "women": "50.9",
        "white": "26.4",
        "black": "10.9",
        "asian": "10.1"
      }
    },
    "wageData": {
      "2011": {
        "workers": "129",
        "median": "978",
        "maleWorkers": "66",
        "maleMedian": "1,014",
        "femaleWorkers": "63",
        "femaleMedian": "892"
      }
    }
  },
  "Postal service mail carriers": {
    "ageData": {
      "2011": {
        "total": "348",
        "16to19": "0",
        "20to24": "9",
        "25to34": "36",
        "35to44": "71",
        "45to54": "151",
        "55to64": "71",
        "65+": "11",
        "medianAge": "71"
      }
    },
    "title": "Postal service mail carriers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "348",
        "women": "36.0",
        "white": "13.7",
        "black": "6.9",
        "asian": "10.0"
      }
    },
    "wageData": {
      "2011": {
        "workers": "303",
        "median": "979",
        "maleWorkers": "205",
        "maleMedian": "1,017",
        "femaleWorkers": "98",
        "femaleMedian": "892"
      }
    }
  },
  "Postal service mail sorters, processors, and processing machine operators": {
    "ageData": {
      "2011": {
        "total": "60",
        "16to19": "0",
        "20to24": "2",
        "25to34": "10",
        "35to44": "12",
        "45to54": "18",
        "55to64": "15",
        "65+": "3",
        "medianAge": "15"
      }
    },
    "title": "Postal service mail sorters, processors, and processing machine operators",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "60",
        "women": "40.5",
        "white": "30.5",
        "black": "8.9",
        "asian": "16.2"
      }
    },
    "wageData": {
      "2011": {
        "workers": "57",
        "median": "918",
        "maleWorkers": "33",
        "maleMedian": "-",
        "femaleWorkers": "24",
        "femaleMedian": "-"
      }
    }
  },
  "Production, planning, and expediting clerks": {
    "ageData": {
      "2011": {
        "total": "236",
        "16to19": "0",
        "20to24": "13",
        "25to34": "48",
        "35to44": "61",
        "45to54": "63",
        "55to64": "45",
        "65+": "6",
        "medianAge": "45"
      }
    },
    "title": "Production, planning, and expediting clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "236",
        "women": "53.5",
        "white": "8.9",
        "black": "2.9",
        "asian": "9.5"
      }
    },
    "wageData": {
      "2011": {
        "workers": "226",
        "median": "812",
        "maleWorkers": "110",
        "maleMedian": "916",
        "femaleWorkers": "116",
        "femaleMedian": "722"
      }
    }
  },
  "Shipping, receiving, and traffic clerks": {
    "ageData": {
      "2011": {
        "total": "559",
        "16to19": "15",
        "20to24": "64",
        "25to34": "132",
        "35to44": "130",
        "45to54": "133",
        "55to64": "70",
        "65+": "14",
        "medianAge": "70"
      }
    },
    "title": "Shipping, receiving, and traffic clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "559",
        "women": "28.5",
        "white": "14.2",
        "black": "3.7",
        "asian": "24.3"
      }
    },
    "wageData": {
      "2011": {
        "workers": "481",
        "median": "562",
        "maleWorkers": "340",
        "maleMedian": "571",
        "femaleWorkers": "141",
        "femaleMedian": "543"
      }
    }
  },
  "Stock clerks and order fillers": {
    "ageData": {
      "2011": {
        "total": "1,503",
        "16to19": "126",
        "20to24": "306",
        "25to34": "351",
        "35to44": "228",
        "45to54": "262",
        "55to64": "187",
        "65+": "43",
        "medianAge": "187"
      }
    },
    "title": "Stock clerks and order fillers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "1,503",
        "women": "34.8",
        "white": "15.5",
        "black": "3.4",
        "asian": "18.8"
      }
    },
    "wageData": {
      "2011": {
        "workers": "992",
        "median": "492",
        "maleWorkers": "655",
        "maleMedian": "488",
        "femaleWorkers": "337",
        "femaleMedian": "501"
      }
    }
  },
  "Weighers, measurers, checkers, and samplers, recordkeeping": {
    "ageData": {
      "2011": {
        "total": "70",
        "16to19": "2",
        "20to24": "2",
        "25to34": "17",
        "35to44": "14",
        "45to54": "14",
        "55to64": "17",
        "65+": "4",
        "medianAge": "17"
      }
    },
    "title": "Weighers, measurers, checkers, and samplers, recordkeeping",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "70",
        "women": "55.7",
        "white": "17.6",
        "black": "5.0",
        "asian": "22.1"
      }
    },
    "wageData": {
      "2011": {
        "workers": "60",
        "median": "596",
        "maleWorkers": "32",
        "maleMedian": "-",
        "femaleWorkers": "28",
        "femaleMedian": "-"
      }
    }
  },
  "Secretaries and administrative assistants": {
    "ageData": {
      "2011": {
        "total": "2,871",
        "16to19": "32",
        "20to24": "150",
        "25to34": "467",
        "35to44": "565",
        "45to54": "803",
        "55to64": "670",
        "65+": "184",
        "medianAge": "670"
      }
    },
    "title": "Secretaries and administrative assistants",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "2,871",
        "women": "95.9",
        "white": "8.2",
        "black": "2.6",
        "asian": "9.7"
      }
    },
    "wageData": {
      "2011": {
        "workers": "2,143",
        "median": "654",
        "maleWorkers": "84",
        "maleMedian": "757",
        "femaleWorkers": "2,059",
        "femaleMedian": "651"
      }
    }
  },
  "Computer operators": {
    "ageData": {
      "2011": {
        "total": "126",
        "16to19": "2",
        "20to24": "9",
        "25to34": "22",
        "35to44": "15",
        "45to54": "41",
        "55to64": "34",
        "65+": "3",
        "medianAge": "34"
      }
    },
    "title": "Computer operators",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "126",
        "women": "49.9",
        "white": "11.1",
        "black": "5.9",
        "asian": "11.1"
      }
    },
    "wageData": {
      "2011": {
        "workers": "116",
        "median": "724",
        "maleWorkers": "59",
        "maleMedian": "853",
        "femaleWorkers": "56",
        "femaleMedian": "651"
      }
    }
  },
  "Data entry keyers": {
    "ageData": {
      "2011": {
        "total": "334",
        "16to19": "6",
        "20to24": "31",
        "25to34": "91",
        "35to44": "75",
        "45to54": "80",
        "55to64": "40",
        "65+": "10",
        "medianAge": "40"
      }
    },
    "title": "Data entry keyers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "334",
        "women": "83.2",
        "white": "16.0",
        "black": "4.9",
        "asian": "12.6"
      }
    },
    "wageData": {
      "2011": {
        "workers": "280",
        "median": "595",
        "maleWorkers": "49",
        "maleMedian": "-",
        "femaleWorkers": "231",
        "femaleMedian": "597"
      }
    }
  },
  "Word processors and typists": {
    "ageData": {
      "2011": {
        "total": "136",
        "16to19": "2",
        "20to24": "9",
        "25to34": "22",
        "35to44": "28",
        "45to54": "37",
        "55to64": "29",
        "65+": "9",
        "medianAge": "29"
      }
    },
    "title": "Word processors and typists",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "136",
        "women": "88.5",
        "white": "18.8",
        "black": "6.2",
        "asian": "10.0"
      }
    },
    "wageData": {
      "2011": {
        "workers": "109",
        "median": "599",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "99",
        "femaleMedian": "594"
      }
    }
  },
  "Desktop publishers": {
    "ageData": {
      "2011": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "1",
        "45to54": "0",
        "55to64": "0",
        "65+": "1",
        "medianAge": "0"
      }
    },
    "title": "Desktop publishers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Insurance claims and policy processing clerks": {
    "ageData": {
      "2011": {
        "total": "246",
        "16to19": "2",
        "20to24": "19",
        "25to34": "66",
        "35to44": "51",
        "45to54": "62",
        "55to64": "42",
        "65+": "4",
        "medianAge": "42"
      }
    },
    "title": "Insurance claims and policy processing clerks",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "246",
        "women": "83.0",
        "white": "17.4",
        "black": "2.1",
        "asian": "10.1"
      }
    },
    "wageData": {
      "2011": {
        "workers": "236",
        "median": "660",
        "maleWorkers": "39",
        "maleMedian": "-",
        "femaleWorkers": "197",
        "femaleMedian": "647"
      }
    }
  },
  "Mail clerks and mail machine operators, except postal service": {
    "ageData": {
      "2011": {
        "total": "93",
        "16to19": "4",
        "20to24": "14",
        "25to34": "16",
        "35to44": "13",
        "45to54": "24",
        "55to64": "13",
        "65+": "10",
        "medianAge": "13"
      }
    },
    "title": "Mail clerks and mail machine operators, except postal service",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "93",
        "women": "53.0",
        "white": "18.0",
        "black": "3.3",
        "asian": "15.0"
      }
    },
    "wageData": {
      "2011": {
        "workers": "79",
        "median": "521",
        "maleWorkers": "38",
        "maleMedian": "-",
        "femaleWorkers": "41",
        "femaleMedian": "-"
      }
    }
  },
  "Office clerks, general": {
    "ageData": {
      "2011": {
        "total": "1,061",
        "16to19": "46",
        "20to24": "124",
        "25to34": "211",
        "35to44": "198",
        "45to54": "255",
        "55to64": "168",
        "65+": "61",
        "medianAge": "168"
      }
    },
    "title": "Office clerks, general",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "1,061",
        "women": "85.4",
        "white": "13.5",
        "black": "5.0",
        "asian": "15.5"
      }
    },
    "wageData": {
      "2011": {
        "workers": "794",
        "median": "604",
        "maleWorkers": "121",
        "maleMedian": "712",
        "femaleWorkers": "673",
        "femaleMedian": "594"
      }
    }
  },
  "Office machine operators, except computer": {
    "ageData": {
      "2011": {
        "total": "45",
        "16to19": "1",
        "20to24": "6",
        "25to34": "11",
        "35to44": "6",
        "45to54": "14",
        "55to64": "6",
        "65+": "2",
        "medianAge": "6"
      }
    },
    "title": "Office machine operators, except computer",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "45",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "31",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "20",
        "femaleMedian": "-"
      }
    }
  },
  "Proofreaders and copy markers": {
    "ageData": {
      "2011": {
        "total": "7",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "1",
        "45to54": "2",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Proofreaders and copy markers",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Statistical assistants": {
    "ageData": {
      "2011": {
        "total": "18",
        "16to19": "0",
        "20to24": "2",
        "25to34": "5",
        "35to44": "3",
        "45to54": "5",
        "55to64": "2",
        "65+": "2",
        "medianAge": "2"
      }
    },
    "title": "Statistical assistants",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "18",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Office and administrative support workers, all other": {
    "ageData": {
      "2011": {
        "total": "513",
        "16to19": "4",
        "20to24": "41",
        "25to34": "124",
        "35to44": "117",
        "45to54": "118",
        "55to64": "91",
        "65+": "20",
        "medianAge": "91"
      }
    },
    "title": "Office and administrative support workers, all other",
    "depth": 3,
    "parent": "Office and administrative support occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "513",
        "women": "74.8",
        "white": "14.2",
        "black": "4.0",
        "asian": "12.6"
      }
    },
    "wageData": {
      "2011": {
        "workers": "387",
        "median": "679",
        "maleWorkers": "101",
        "maleMedian": "792",
        "femaleWorkers": "286",
        "femaleMedian": "645"
      }
    }
  },
  "Natural resources, construction, and maintenance occupations": {
    "ageData": {
      "2011": {
        "total": "13,009",
        "16to19": "278",
        "20to24": "1,146",
        "25to34": "3,237",
        "35to44": "3,069",
        "45to54": "3,144",
        "55to64": "1,766",
        "65+": "369",
        "medianAge": "1,766"
      }
    },
    "title": "Natural resources, construction, and maintenance occupations",
    "depth": 1,
    "parent": "",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "13,009",
        "women": "4.2",
        "white": "6.5",
        "black": "2.0",
        "asian": "25.3"
      }
    },
    "wageData": {
      "2011": {
        "workers": "9,965",
        "median": "732",
        "maleWorkers": "9,574",
        "maleMedian": "740",
        "femaleWorkers": "391",
        "femaleMedian": "515"
      }
    }
  },
  "Farming, fishing, and forestry occupations": {
    "ageData": {
      "2011": {
        "total": "1,001",
        "16to19": "100",
        "20to24": "136",
        "25to34": "228",
        "35to44": "189",
        "45to54": "183",
        "55to64": "117",
        "65+": "48",
        "medianAge": "117"
      }
    },
    "title": "Farming, fishing, and forestry occupations",
    "depth": 2,
    "parent": "Natural resources, construction, and maintenance occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "1,001",
        "women": "21.6",
        "white": "4.3",
        "black": "1.4",
        "asian": "43.0"
      }
    },
    "wageData": {
      "2011": {
        "workers": "775",
        "median": "430",
        "maleWorkers": "625",
        "maleMedian": "445",
        "femaleWorkers": "150",
        "femaleMedian": "371"
      }
    }
  },
  "First-line supervisors of farming, fishing, and forestry workers": {
    "ageData": {
      "2011": {
        "total": "52",
        "16to19": "0",
        "20to24": "2",
        "25to34": "7",
        "35to44": "9",
        "45to54": "14",
        "55to64": "12",
        "65+": "7",
        "medianAge": "12"
      }
    },
    "title": "First-line supervisors of farming, fishing, and forestry workers",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "52",
        "women": "8.4",
        "white": "1.0",
        "black": "0.0",
        "asian": "23.2"
      }
    },
    "wageData": {
      "2011": {
        "workers": "38",
        "median": "-",
        "maleWorkers": "35",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Agricultural inspectors": {
    "ageData": {
      "2011": {
        "total": "22",
        "16to19": "0",
        "20to24": "3",
        "25to34": "5",
        "35to44": "6",
        "45to54": "5",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Agricultural inspectors",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "22",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "23",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Animal breeders": {
    "ageData": {
      "2011": {
        "total": "10",
        "16to19": "2",
        "20to24": "0",
        "25to34": "1",
        "35to44": "1",
        "45to54": "2",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Animal breeders",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "10",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Graders and sorters, agricultural products": {
    "ageData": {
      "2011": {
        "total": "95",
        "16to19": "3",
        "20to24": "9",
        "25to34": "18",
        "35to44": "23",
        "45to54": "19",
        "55to64": "18",
        "65+": "4",
        "medianAge": "18"
      }
    },
    "title": "Graders and sorters, agricultural products",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "95",
        "women": "60.2",
        "white": "12.6",
        "black": "3.6",
        "asian": "51.5"
      }
    },
    "wageData": {
      "2011": {
        "workers": "85",
        "median": "379",
        "maleWorkers": "32",
        "maleMedian": "-",
        "femaleWorkers": "53",
        "femaleMedian": "363"
      }
    }
  },
  "Miscellaneous agricultural workers": {
    "ageData": {
      "2011": {
        "total": "708",
        "16to19": "91",
        "20to24": "112",
        "25to34": "173",
        "35to44": "126",
        "45to54": "112",
        "55to64": "66",
        "65+": "29",
        "medianAge": "66"
      }
    },
    "title": "Miscellaneous agricultural workers",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "708",
        "women": "19.1",
        "white": "2.8",
        "black": "1.3",
        "asian": "49.6"
      }
    },
    "wageData": {
      "2011": {
        "workers": "565",
        "median": "419",
        "maleWorkers": "488",
        "maleMedian": "427",
        "femaleWorkers": "77",
        "femaleMedian": "370"
      }
    }
  },
  "Fishers and related fishing workers": {
    "ageData": {
      "2011": {
        "total": "37",
        "16to19": "0",
        "20to24": "4",
        "25to34": "9",
        "35to44": "8",
        "45to54": "7",
        "55to64": "4",
        "65+": "4",
        "medianAge": "4"
      }
    },
    "title": "Fishers and related fishing workers",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "37",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Hunters and trappers": {
    "ageData": {
      "2011": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Hunters and trappers",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Forest and conservation workers": {
    "ageData": {
      "2011": {
        "total": "14",
        "16to19": "0",
        "20to24": "1",
        "25to34": "5",
        "35to44": "2",
        "45to54": "2",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Forest and conservation workers",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "14",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Logging workers": {
    "ageData": {
      "2011": {
        "total": "61",
        "16to19": "2",
        "20to24": "4",
        "25to34": "10",
        "35to44": "14",
        "45to54": "21",
        "55to64": "9",
        "65+": "1",
        "medianAge": "9"
      }
    },
    "title": "Logging workers",
    "depth": 3,
    "parent": "Farming, fishing, and forestry occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "61",
        "women": "0.9",
        "white": "7.0",
        "black": "0.0",
        "asian": "9.1"
      }
    },
    "wageData": {
      "2011": {
        "workers": "39",
        "median": "-",
        "maleWorkers": "39",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Construction and extraction occupations": {
    "ageData": {
      "2011": {
        "total": "7,125",
        "16to19": "118",
        "20to24": "598",
        "25to34": "1,929",
        "35to44": "1,756",
        "45to54": "1,679",
        "55to64": "883",
        "65+": "161",
        "medianAge": "883"
      }
    },
    "title": "Construction and extraction occupations",
    "depth": 2,
    "parent": "Natural resources, construction, and maintenance occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "7,125",
        "women": "2.3",
        "white": "5.9",
        "black": "1.5",
        "asian": "29.4"
      }
    },
    "wageData": {
      "2011": {
        "workers": "5,031",
        "median": "717",
        "maleWorkers": "4,937",
        "maleMedian": "718",
        "femaleWorkers": "95",
        "femaleMedian": "612"
      }
    }
  },
  "First-line supervisors of construction trades and extraction workers": {
    "ageData": {
      "2011": {
        "total": "634",
        "16to19": "1",
        "20to24": "15",
        "25to34": "130",
        "35to44": "159",
        "45to54": "195",
        "55to64": "117",
        "65+": "16",
        "medianAge": "117"
      }
    },
    "title": "First-line supervisors of construction trades and extraction workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "634",
        "women": "2.2",
        "white": "5.2",
        "black": "0.7",
        "asian": "16.5"
      }
    },
    "wageData": {
      "2011": {
        "workers": "482",
        "median": "992",
        "maleWorkers": "467",
        "maleMedian": "1,001",
        "femaleWorkers": "15",
        "femaleMedian": "-"
      }
    }
  },
  "Boilermakers": {
    "ageData": {
      "2011": {
        "total": "19",
        "16to19": "0",
        "20to24": "1",
        "25to34": "7",
        "35to44": "4",
        "45to54": "4",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Boilermakers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "19",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "18",
        "median": "-",
        "maleWorkers": "18",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Brickmasons, blockmasons, and stonemasons": {
    "ageData": {
      "2011": {
        "total": "146",
        "16to19": "2",
        "20to24": "12",
        "25to34": "39",
        "35to44": "35",
        "45to54": "37",
        "55to64": "19",
        "65+": "2",
        "medianAge": "19"
      }
    },
    "title": "Brickmasons, blockmasons, and stonemasons",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "146",
        "women": "0.9",
        "white": "5.0",
        "black": "1.9",
        "asian": "39.3"
      }
    },
    "wageData": {
      "2011": {
        "workers": "96",
        "median": "710",
        "maleWorkers": "95",
        "maleMedian": "706",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Carpenters": {
    "ageData": {
      "2011": {
        "total": "1,330",
        "16to19": "16",
        "20to24": "94",
        "25to34": "332",
        "35to44": "358",
        "45to54": "338",
        "55to64": "170",
        "65+": "22",
        "medianAge": "170"
      }
    },
    "title": "Carpenters",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "1,330",
        "women": "1.9",
        "white": "5.3",
        "black": "1.6",
        "asian": "26.7"
      }
    },
    "wageData": {
      "2011": {
        "workers": "737",
        "median": "630",
        "maleWorkers": "727",
        "maleMedian": "630",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Carpet, floor, and tile installers and finishers": {
    "ageData": {
      "2011": {
        "total": "189",
        "16to19": "7",
        "20to24": "21",
        "25to34": "61",
        "35to44": "48",
        "45to54": "35",
        "55to64": "14",
        "65+": "4",
        "medianAge": "14"
      }
    },
    "title": "Carpet, floor, and tile installers and finishers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "189",
        "women": "0.5",
        "white": "6.3",
        "black": "2.4",
        "asian": "43.2"
      }
    },
    "wageData": {
      "2011": {
        "workers": "110",
        "median": "579",
        "maleWorkers": "109",
        "maleMedian": "581",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Cement masons, concrete finishers, and terrazzo workers": {
    "ageData": {
      "2011": {
        "total": "70",
        "16to19": "0",
        "20to24": "6",
        "25to34": "22",
        "35to44": "16",
        "45to54": "18",
        "55to64": "6",
        "65+": "1",
        "medianAge": "6"
      }
    },
    "title": "Cement masons, concrete finishers, and terrazzo workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "70",
        "women": "0.0",
        "white": "9.3",
        "black": "0.6",
        "asian": "47.8"
      }
    },
    "wageData": {
      "2011": {
        "workers": "59",
        "median": "651",
        "maleWorkers": "59",
        "maleMedian": "651",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Construction laborers": {
    "ageData": {
      "2011": {
        "total": "1,253",
        "16to19": "39",
        "20to24": "154",
        "25to34": "379",
        "35to44": "291",
        "45to54": "254",
        "55to64": "114",
        "65+": "22",
        "medianAge": "114"
      }
    },
    "title": "Construction laborers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "1,253",
        "women": "2.1",
        "white": "7.3",
        "black": "1.8",
        "asian": "42.9"
      }
    },
    "wageData": {
      "2011": {
        "workers": "862",
        "median": "586",
        "maleWorkers": "846",
        "maleMedian": "587",
        "femaleWorkers": "15",
        "femaleMedian": "-"
      }
    }
  },
  "Paving, surfacing, and tamping equipment operators": {
    "ageData": {
      "2011": {
        "total": "21",
        "16to19": "0",
        "20to24": "2",
        "25to34": "5",
        "35to44": "6",
        "45to54": "4",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Paving, surfacing, and tamping equipment operators",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "21",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "19",
        "median": "-",
        "maleWorkers": "18",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Pile-driver operators": {
    "ageData": {
      "2011": {
        "total": "1",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "1",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Pile-driver operators",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Operating engineers and other construction equipment operators": {
    "ageData": {
      "2011": {
        "total": "369",
        "16to19": "4",
        "20to24": "22",
        "25to34": "88",
        "35to44": "85",
        "45to54": "93",
        "55to64": "62",
        "65+": "15",
        "medianAge": "62"
      }
    },
    "title": "Operating engineers and other construction equipment operators",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "369",
        "women": "0.8",
        "white": "5.0",
        "black": "0.2",
        "asian": "16.6"
      }
    },
    "wageData": {
      "2011": {
        "workers": "345",
        "median": "809",
        "maleWorkers": "343",
        "maleMedian": "809",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Drywall installers, ceiling tile installers, and tapers": {
    "ageData": {
      "2011": {
        "total": "150",
        "16to19": "1",
        "20to24": "12",
        "25to34": "50",
        "35to44": "50",
        "45to54": "20",
        "55to64": "14",
        "65+": "2",
        "medianAge": "14"
      }
    },
    "title": "Drywall installers, ceiling tile installers, and tapers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "150",
        "women": "1.7",
        "white": "2.0",
        "black": "0.6",
        "asian": "59.7"
      }
    },
    "wageData": {
      "2011": {
        "workers": "101",
        "median": "507",
        "maleWorkers": "100",
        "maleMedian": "509",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Electricians": {
    "ageData": {
      "2011": {
        "total": "682",
        "16to19": "6",
        "20to24": "50",
        "25to34": "184",
        "35to44": "162",
        "45to54": "177",
        "55to64": "86",
        "65+": "18",
        "medianAge": "86"
      }
    },
    "title": "Electricians",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "682",
        "women": "1.5",
        "white": "6.1",
        "black": "2.4",
        "asian": "14.0"
      }
    },
    "wageData": {
      "2011": {
        "workers": "542",
        "median": "857",
        "maleWorkers": "539",
        "maleMedian": "855",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Glaziers": {
    "ageData": {
      "2011": {
        "total": "48",
        "16to19": "0",
        "20to24": "3",
        "25to34": "13",
        "35to44": "10",
        "45to54": "13",
        "55to64": "6",
        "65+": "2",
        "medianAge": "6"
      }
    },
    "title": "Glaziers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "48",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "40",
        "median": "-",
        "maleWorkers": "40",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Insulation workers": {
    "ageData": {
      "2011": {
        "total": "47",
        "16to19": "1",
        "20to24": "6",
        "25to34": "15",
        "35to44": "11",
        "45to54": "8",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Insulation workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "47",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "41",
        "median": "-",
        "maleWorkers": "39",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Painters, construction and maintenance": {
    "ageData": {
      "2011": {
        "total": "528",
        "16to19": "12",
        "20to24": "40",
        "25to34": "133",
        "35to44": "140",
        "45to54": "114",
        "55to64": "70",
        "65+": "20",
        "medianAge": "70"
      }
    },
    "title": "Painters, construction and maintenance",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "528",
        "women": "6.8",
        "white": "6.5",
        "black": "2.5",
        "asian": "41.8"
      }
    },
    "wageData": {
      "2011": {
        "workers": "266",
        "median": "544",
        "maleWorkers": "249",
        "maleMedian": "555",
        "femaleWorkers": "17",
        "femaleMedian": "-"
      }
    }
  },
  "Paperhangers": {
    "ageData": {
      "2011": {
        "total": "8",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "1",
        "45to54": "2",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Paperhangers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "8",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Pipelayers, plumbers, pipefitters, and steamfitters": {
    "ageData": {
      "2011": {
        "total": "519",
        "16to19": "5",
        "20to24": "30",
        "25to34": "139",
        "35to44": "138",
        "45to54": "134",
        "55to64": "66",
        "65+": "7",
        "medianAge": "66"
      }
    },
    "title": "Pipelayers, plumbers, pipefitters, and steamfitters",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "519",
        "women": "1.7",
        "white": "6.4",
        "black": "1.4",
        "asian": "22.5"
      }
    },
    "wageData": {
      "2011": {
        "workers": "417",
        "median": "851",
        "maleWorkers": "406",
        "maleMedian": "853",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "Plasterers and stucco masons": {
    "ageData": {
      "2011": {
        "total": "24",
        "16to19": "0",
        "20to24": "0",
        "25to34": "8",
        "35to44": "8",
        "45to54": "6",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Plasterers and stucco masons",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "24",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Reinforcing iron and rebar workers": {
    "ageData": {
      "2011": {
        "total": "7",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "1",
        "45to54": "2",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Reinforcing iron and rebar workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Roofers": {
    "ageData": {
      "2011": {
        "total": "222",
        "16to19": "4",
        "20to24": "31",
        "25to34": "86",
        "35to44": "42",
        "45to54": "39",
        "55to64": "14",
        "65+": "6",
        "medianAge": "14"
      }
    },
    "title": "Roofers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "222",
        "women": "1.1",
        "white": "4.9",
        "black": "0.8",
        "asian": "47.3"
      }
    },
    "wageData": {
      "2011": {
        "workers": "143",
        "median": "523",
        "maleWorkers": "141",
        "maleMedian": "520",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Sheet metal workers": {
    "ageData": {
      "2011": {
        "total": "126",
        "16to19": "5",
        "20to24": "16",
        "25to34": "32",
        "35to44": "21",
        "45to54": "34",
        "55to64": "14",
        "65+": "4",
        "medianAge": "14"
      }
    },
    "title": "Sheet metal workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "126",
        "women": "2.3",
        "white": "2.2",
        "black": "0.6",
        "asian": "18.9"
      }
    },
    "wageData": {
      "2011": {
        "workers": "107",
        "median": "805",
        "maleWorkers": "104",
        "maleMedian": "805",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Structural iron and steel workers": {
    "ageData": {
      "2011": {
        "total": "66",
        "16to19": "0",
        "20to24": "6",
        "25to34": "20",
        "35to44": "21",
        "45to54": "12",
        "55to64": "7",
        "65+": "1",
        "medianAge": "7"
      }
    },
    "title": "Structural iron and steel workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "66",
        "women": "0.0",
        "white": "4.1",
        "black": "0.0",
        "asian": "19.5"
      }
    },
    "wageData": {
      "2011": {
        "workers": "66",
        "median": "870",
        "maleWorkers": "66",
        "maleMedian": "870",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Solar photovoltaic installers": {
    "ageData": {
      "2011": {
        "total": "5",
        "16to19": "0",
        "20to24": "1",
        "25to34": "2",
        "35to44": "1",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Solar photovoltaic installers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Helpers, construction trades": {
    "ageData": {
      "2011": {
        "total": "63",
        "16to19": "5",
        "20to24": "14",
        "25to34": "17",
        "35to44": "13",
        "45to54": "10",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Helpers, construction trades",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "63",
        "women": "8.0",
        "white": "10.5",
        "black": "2.4",
        "asian": "41.1"
      }
    },
    "wageData": {
      "2011": {
        "workers": "50",
        "median": "469",
        "maleWorkers": "47",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Construction and building inspectors": {
    "ageData": {
      "2011": {
        "total": "75",
        "16to19": "0",
        "20to24": "3",
        "25to34": "7",
        "35to44": "19",
        "45to54": "18",
        "55to64": "20",
        "65+": "7",
        "medianAge": "20"
      }
    },
    "title": "Construction and building inspectors",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "75",
        "women": "4.7",
        "white": "5.0",
        "black": "0.2",
        "asian": "11.6"
      }
    },
    "wageData": {
      "2011": {
        "workers": "54",
        "median": "906",
        "maleWorkers": "52",
        "maleMedian": "919",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Elevator installers and repairers": {
    "ageData": {
      "2011": {
        "total": "30",
        "16to19": "0",
        "20to24": "0",
        "25to34": "7",
        "35to44": "9",
        "45to54": "10",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Elevator installers and repairers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "30",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "30",
        "median": "-",
        "maleWorkers": "30",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Fence erectors": {
    "ageData": {
      "2011": {
        "total": "45",
        "16to19": "0",
        "20to24": "10",
        "25to34": "11",
        "35to44": "11",
        "45to54": "6",
        "55to64": "5",
        "65+": "1",
        "medianAge": "5"
      }
    },
    "title": "Fence erectors",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "45",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "32",
        "median": "-",
        "maleWorkers": "32",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Hazardous materials removal workers": {
    "ageData": {
      "2011": {
        "total": "32",
        "16to19": "0",
        "20to24": "6",
        "25to34": "7",
        "35to44": "8",
        "45to54": "7",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Hazardous materials removal workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "32",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "25",
        "median": "-",
        "maleWorkers": "24",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Highway maintenance workers": {
    "ageData": {
      "2011": {
        "total": "105",
        "16to19": "1",
        "20to24": "8",
        "25to34": "17",
        "35to44": "19",
        "45to54": "37",
        "55to64": "18",
        "65+": "5",
        "medianAge": "18"
      }
    },
    "title": "Highway maintenance workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "105",
        "women": "2.5",
        "white": "11.0",
        "black": "0.5",
        "asian": "13.2"
      }
    },
    "wageData": {
      "2011": {
        "workers": "96",
        "median": "708",
        "maleWorkers": "94",
        "maleMedian": "711",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Rail-track laying and maintenance equipment operators": {
    "ageData": {
      "2011": {
        "total": "7",
        "16to19": "0",
        "20to24": "1",
        "25to34": "2",
        "35to44": "1",
        "45to54": "2",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Rail-track laying and maintenance equipment operators",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Septic tank servicers and sewer pipe cleaners": {
    "ageData": {
      "2011": {
        "total": "11",
        "16to19": "0",
        "20to24": "1",
        "25to34": "2",
        "35to44": "6",
        "45to54": "3",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Septic tank servicers and sewer pipe cleaners",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "11",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "9",
        "median": "-",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous construction and related workers": {
    "ageData": {
      "2011": {
        "total": "36",
        "16to19": "1",
        "20to24": "3",
        "25to34": "10",
        "35to44": "10",
        "45to54": "6",
        "55to64": "5",
        "65+": "0",
        "medianAge": "5"
      }
    },
    "title": "Miscellaneous construction and related workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "36",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "28",
        "median": "-",
        "maleWorkers": "28",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Derrick, rotary drill, and service unit operators, oil, gas, and mining": {
    "ageData": {
      "2011": {
        "total": "46",
        "16to19": "0",
        "20to24": "5",
        "25to34": "24",
        "35to44": "7",
        "45to54": "3",
        "55to64": "4",
        "65+": "1",
        "medianAge": "4"
      }
    },
    "title": "Derrick, rotary drill, and service unit operators, oil, gas, and mining",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "46",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "42",
        "median": "-",
        "maleWorkers": "42",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Earth drillers, except oil and gas": {
    "ageData": {
      "2011": {
        "total": "23",
        "16to19": "0",
        "20to24": "1",
        "25to34": "6",
        "35to44": "5",
        "45to54": "4",
        "55to64": "4",
        "65+": "1",
        "medianAge": "4"
      }
    },
    "title": "Earth drillers, except oil and gas",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "23",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "17",
        "median": "-",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Explosives workers, ordnance handling experts, and blasters": {
    "ageData": {
      "2011": {
        "total": "9",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "2",
        "45to54": "4",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Explosives workers, ordnance handling experts, and blasters",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "9",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "10",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Mining machine operators": {
    "ageData": {
      "2011": {
        "total": "78",
        "16to19": "0",
        "20to24": "6",
        "25to34": "22",
        "35to44": "21",
        "45to54": "16",
        "55to64": "11",
        "65+": "1",
        "medianAge": "11"
      }
    },
    "title": "Mining machine operators",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "78",
        "women": "1.6",
        "white": "3.0",
        "black": "0.0",
        "asian": "15.5"
      }
    },
    "wageData": {
      "2011": {
        "workers": "70",
        "median": "1,022",
        "maleWorkers": "69",
        "maleMedian": "1,010",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Roof bolters, mining": {
    "ageData": {
      "2011": {
        "total": "10",
        "16to19": "0",
        "20to24": "3",
        "25to34": "4",
        "35to44": "0",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Roof bolters, mining",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "10",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "12",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Roustabouts, oil and gas": {
    "ageData": {
      "2011": {
        "total": "17",
        "16to19": "0",
        "20to24": "3",
        "25to34": "9",
        "35to44": "2",
        "45to54": "2",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Roustabouts, oil and gas",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "17",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "18",
        "median": "-",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Helpers--extraction workers": {
    "ageData": {
      "2011": {
        "total": "8",
        "16to19": "0",
        "20to24": "2",
        "25to34": "4",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "1",
        "medianAge": "0"
      }
    },
    "title": "Helpers--extraction workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "8",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Other extraction workers": {
    "ageData": {
      "2011": {
        "total": "68",
        "16to19": "6",
        "20to24": "8",
        "25to34": "25",
        "35to44": "14",
        "45to54": "9",
        "55to64": "4",
        "65+": "1",
        "medianAge": "4"
      }
    },
    "title": "Other extraction workers",
    "depth": 3,
    "parent": "Construction and extraction occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "68",
        "women": "1.7",
        "white": "1.1",
        "black": "1.9",
        "asian": "36.1"
      }
    },
    "wageData": {
      "2011": {
        "workers": "49",
        "median": "-",
        "maleWorkers": "49",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Installation, maintenance, and repair occupations": {
    "ageData": {
      "2011": {
        "total": "4,883",
        "16to19": "60",
        "20to24": "411",
        "25to34": "1,080",
        "35to44": "1,123",
        "45to54": "1,283",
        "55to64": "766",
        "65+": "160",
        "medianAge": "766"
      }
    },
    "title": "Installation, maintenance, and repair occupations",
    "depth": 2,
    "parent": "Natural resources, construction, and maintenance occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "4,883",
        "women": "3.5",
        "white": "7.9",
        "black": "2.9",
        "asian": "15.8"
      }
    },
    "wageData": {
      "2011": {
        "workers": "4,159",
        "median": "806",
        "maleWorkers": "4,013",
        "maleMedian": "807",
        "femaleWorkers": "146",
        "femaleMedian": "751"
      }
    }
  },
  "First-line supervisors of mechanics, installers, and repairers": {
    "ageData": {
      "2011": {
        "total": "313",
        "16to19": "0",
        "20to24": "8",
        "25to34": "49",
        "35to44": "57",
        "45to54": "102",
        "55to64": "87",
        "65+": "11",
        "medianAge": "87"
      }
    },
    "title": "First-line supervisors of mechanics, installers, and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "313",
        "women": "6.8",
        "white": "6.4",
        "black": "0.9",
        "asian": "9.7"
      }
    },
    "wageData": {
      "2011": {
        "workers": "327",
        "median": "947",
        "maleWorkers": "306",
        "maleMedian": "936",
        "femaleWorkers": "21",
        "femaleMedian": "-"
      }
    }
  },
  "Computer, automated teller, and office machine repairers": {
    "ageData": {
      "2011": {
        "total": "305",
        "16to19": "3",
        "20to24": "38",
        "25to34": "72",
        "35to44": "70",
        "45to54": "69",
        "55to64": "40",
        "65+": "12",
        "medianAge": "40"
      }
    },
    "title": "Computer, automated teller, and office machine repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "305",
        "women": "9.0",
        "white": "11.0",
        "black": "6.3",
        "asian": "9.9"
      }
    },
    "wageData": {
      "2011": {
        "workers": "237",
        "median": "811",
        "maleWorkers": "215",
        "maleMedian": "812",
        "femaleWorkers": "22",
        "femaleMedian": "-"
      }
    }
  },
  "Radio and telecommunications equipment installers and repairers": {
    "ageData": {
      "2011": {
        "total": "150",
        "16to19": "1",
        "20to24": "9",
        "25to34": "34",
        "35to44": "45",
        "45to54": "35",
        "55to64": "24",
        "65+": "2",
        "medianAge": "24"
      }
    },
    "title": "Radio and telecommunications equipment installers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "150",
        "women": "7.7",
        "white": "10.1",
        "black": "3.2",
        "asian": "10.6"
      }
    },
    "wageData": {
      "2011": {
        "workers": "139",
        "median": "898",
        "maleWorkers": "127",
        "maleMedian": "883",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Avionics technicians": {
    "ageData": {
      "2011": {
        "total": "16",
        "16to19": "0",
        "20to24": "0",
        "25to34": "6",
        "35to44": "4",
        "45to54": "3",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Avionics technicians",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "16",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "16",
        "median": "-",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Electric motor, power tool, and related repairers": {
    "ageData": {
      "2011": {
        "total": "35",
        "16to19": "0",
        "20to24": "1",
        "25to34": "6",
        "35to44": "10",
        "45to54": "9",
        "55to64": "7",
        "65+": "2",
        "medianAge": "7"
      }
    },
    "title": "Electric motor, power tool, and related repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "35",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "26",
        "median": "-",
        "maleWorkers": "25",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Electrical and electronics installers and repairers, transportation equipment": {
    "ageData": {
      "2011": {
        "total": "6",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "1",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Electrical and electronics installers and repairers, transportation equipment",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "6",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Electrical and electronics repairers, industrial and utility": {
    "ageData": {
      "2011": {
        "total": "14",
        "16to19": "0",
        "20to24": "0",
        "25to34": "4",
        "35to44": "5",
        "45to54": "2",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Electrical and electronics repairers, industrial and utility",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "14",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "15",
        "median": "-",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Electronic equipment installers and repairers, motor vehicles": {
    "ageData": {
      "2011": {
        "total": "20",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "4",
        "45to54": "7",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Electronic equipment installers and repairers, motor vehicles",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "20",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "14",
        "median": "-",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Electronic home entertainment equipment installers and repairers": {
    "ageData": {
      "2011": {
        "total": "38",
        "16to19": "1",
        "20to24": "4",
        "25to34": "13",
        "35to44": "8",
        "45to54": "9",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Electronic home entertainment equipment installers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "38",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "32",
        "median": "-",
        "maleWorkers": "30",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Security and fire alarm systems installers": {
    "ageData": {
      "2011": {
        "total": "53",
        "16to19": "0",
        "20to24": "2",
        "25to34": "16",
        "35to44": "16",
        "45to54": "12",
        "55to64": "4",
        "65+": "3",
        "medianAge": "4"
      }
    },
    "title": "Security and fire alarm systems installers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "53",
        "women": "0.0",
        "white": "6.3",
        "black": "1.0",
        "asian": "17.8"
      }
    },
    "wageData": {
      "2011": {
        "workers": "44",
        "median": "-",
        "maleWorkers": "44",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Aircraft mechanics and service technicians": {
    "ageData": {
      "2011": {
        "total": "164",
        "16to19": "0",
        "20to24": "7",
        "25to34": "36",
        "35to44": "40",
        "45to54": "56",
        "55to64": "20",
        "65+": "5",
        "medianAge": "20"
      }
    },
    "title": "Aircraft mechanics and service technicians",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "164",
        "women": "3.1",
        "white": "8.9",
        "black": "3.1",
        "asian": "17.2"
      }
    },
    "wageData": {
      "2011": {
        "workers": "143",
        "median": "988",
        "maleWorkers": "139",
        "maleMedian": "990",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Automotive body and related repairers": {
    "ageData": {
      "2011": {
        "total": "140",
        "16to19": "1",
        "20to24": "17",
        "25to34": "27",
        "35to44": "40",
        "45to54": "34",
        "55to64": "17",
        "65+": "4",
        "medianAge": "17"
      }
    },
    "title": "Automotive body and related repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "140",
        "women": "1.2",
        "white": "6.9",
        "black": "2.9",
        "asian": "18.1"
      }
    },
    "wageData": {
      "2011": {
        "workers": "107",
        "median": "624",
        "maleWorkers": "107",
        "maleMedian": "625",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Automotive glass installers and repairers": {
    "ageData": {
      "2011": {
        "total": "17",
        "16to19": "0",
        "20to24": "1",
        "25to34": "4",
        "35to44": "5",
        "45to54": "5",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Automotive glass installers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "17",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "11",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Automotive service technicians and mechanics": {
    "ageData": {
      "2011": {
        "total": "855",
        "16to19": "12",
        "20to24": "120",
        "25to34": "206",
        "35to44": "189",
        "45to54": "198",
        "55to64": "108",
        "65+": "21",
        "medianAge": "108"
      }
    },
    "title": "Automotive service technicians and mechanics",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "855",
        "women": "1.4",
        "white": "7.8",
        "black": "4.2",
        "asian": "19.7"
      }
    },
    "wageData": {
      "2011": {
        "workers": "653",
        "median": "714",
        "maleWorkers": "645",
        "maleMedian": "718",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Bus and truck mechanics and diesel engine specialists": {
    "ageData": {
      "2011": {
        "total": "312",
        "16to19": "3",
        "20to24": "28",
        "25to34": "75",
        "35to44": "61",
        "45to54": "82",
        "55to64": "53",
        "65+": "10",
        "medianAge": "53"
      }
    },
    "title": "Bus and truck mechanics and diesel engine specialists",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "312",
        "women": "0.7",
        "white": "4.0",
        "black": "2.5",
        "asian": "15.1"
      }
    },
    "wageData": {
      "2011": {
        "workers": "286",
        "median": "801",
        "maleWorkers": "286",
        "maleMedian": "802",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Heavy vehicle and mobile equipment service technicians and mechanics": {
    "ageData": {
      "2011": {
        "total": "199",
        "16to19": "1",
        "20to24": "12",
        "25to34": "49",
        "35to44": "45",
        "45to54": "56",
        "55to64": "29",
        "65+": "7",
        "medianAge": "29"
      }
    },
    "title": "Heavy vehicle and mobile equipment service technicians and mechanics",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "199",
        "women": "1.3",
        "white": "5.0",
        "black": "1.2",
        "asian": "11.1"
      }
    },
    "wageData": {
      "2011": {
        "workers": "182",
        "median": "822",
        "maleWorkers": "179",
        "maleMedian": "822",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Small engine mechanics": {
    "ageData": {
      "2011": {
        "total": "52",
        "16to19": "2",
        "20to24": "8",
        "25to34": "9",
        "35to44": "6",
        "45to54": "10",
        "55to64": "11",
        "65+": "7",
        "medianAge": "11"
      }
    },
    "title": "Small engine mechanics",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "52",
        "women": "0.3",
        "white": "6.4",
        "black": "0.1",
        "asian": "8.6"
      }
    },
    "wageData": {
      "2011": {
        "workers": "32",
        "median": "-",
        "maleWorkers": "31",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous vehicle and mobile equipment mechanics, installers, and repairers": {
    "ageData": {
      "2011": {
        "total": "95",
        "16to19": "10",
        "20to24": "22",
        "25to34": "19",
        "35to44": "17",
        "45to54": "18",
        "55to64": "7",
        "65+": "1",
        "medianAge": "7"
      }
    },
    "title": "Miscellaneous vehicle and mobile equipment mechanics, installers, and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "95",
        "women": "1.6",
        "white": "6.3",
        "black": "0.1",
        "asian": "21.0"
      }
    },
    "wageData": {
      "2011": {
        "workers": "73",
        "median": "463",
        "maleWorkers": "71",
        "maleMedian": "463",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Control and valve installers and repairers": {
    "ageData": {
      "2011": {
        "total": "24",
        "16to19": "0",
        "20to24": "0",
        "25to34": "8",
        "35to44": "4",
        "45to54": "6",
        "55to64": "7",
        "65+": "0",
        "medianAge": "7"
      }
    },
    "title": "Control and valve installers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "24",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "25",
        "median": "-",
        "maleWorkers": "24",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Heating, air conditioning, and refrigeration mechanics and installers": {
    "ageData": {
      "2011": {
        "total": "338",
        "16to19": "5",
        "20to24": "24",
        "25to34": "99",
        "35to44": "82",
        "45to54": "83",
        "55to64": "40",
        "65+": "4",
        "medianAge": "40"
      }
    },
    "title": "Heating, air conditioning, and refrigeration mechanics and installers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "338",
        "women": "0.8",
        "white": "5.8",
        "black": "1.9",
        "asian": "16.9"
      }
    },
    "wageData": {
      "2011": {
        "workers": "281",
        "median": "793",
        "maleWorkers": "279",
        "maleMedian": "795",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Home appliance repairers": {
    "ageData": {
      "2011": {
        "total": "43",
        "16to19": "1",
        "20to24": "4",
        "25to34": "7",
        "35to44": "11",
        "45to54": "8",
        "55to64": "9",
        "65+": "3",
        "medianAge": "9"
      }
    },
    "title": "Home appliance repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "43",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "27",
        "median": "-",
        "maleWorkers": "23",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Industrial and refractory machinery mechanics": {
    "ageData": {
      "2011": {
        "total": "433",
        "16to19": "2",
        "20to24": "11",
        "25to34": "83",
        "35to44": "104",
        "45to54": "135",
        "55to64": "86",
        "65+": "12",
        "medianAge": "86"
      }
    },
    "title": "Industrial and refractory machinery mechanics",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "433",
        "women": "2.5",
        "white": "7.1",
        "black": "3.8",
        "asian": "15.2"
      }
    },
    "wageData": {
      "2011": {
        "workers": "425",
        "median": "868",
        "maleWorkers": "415",
        "maleMedian": "870",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Maintenance and repair workers, general": {
    "ageData": {
      "2011": {
        "total": "422",
        "16to19": "3",
        "20to24": "20",
        "25to34": "61",
        "35to44": "90",
        "45to54": "131",
        "55to64": "93",
        "65+": "23",
        "medianAge": "93"
      }
    },
    "title": "Maintenance and repair workers, general",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "422",
        "women": "3.2",
        "white": "12.2",
        "black": "1.9",
        "asian": "17.4"
      }
    },
    "wageData": {
      "2011": {
        "workers": "359",
        "median": "758",
        "maleWorkers": "347",
        "maleMedian": "756",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "Maintenance workers, machinery": {
    "ageData": {
      "2011": {
        "total": "38",
        "16to19": "1",
        "20to24": "6",
        "25to34": "6",
        "35to44": "5",
        "45to54": "7",
        "55to64": "8",
        "65+": "3",
        "medianAge": "8"
      }
    },
    "title": "Maintenance workers, machinery",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "38",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "34",
        "median": "-",
        "maleWorkers": "31",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Millwrights": {
    "ageData": {
      "2011": {
        "total": "58",
        "16to19": "0",
        "20to24": "3",
        "25to34": "11",
        "35to44": "8",
        "45to54": "23",
        "55to64": "12",
        "65+": "0",
        "medianAge": "12"
      }
    },
    "title": "Millwrights",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "58",
        "women": "2.0",
        "white": "4.7",
        "black": "0.8",
        "asian": "3.0"
      }
    },
    "wageData": {
      "2011": {
        "workers": "57",
        "median": "892",
        "maleWorkers": "56",
        "maleMedian": "897",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Electrical power-line installers and repairers": {
    "ageData": {
      "2011": {
        "total": "124",
        "16to19": "1",
        "20to24": "10",
        "25to34": "39",
        "35to44": "26",
        "45to54": "31",
        "55to64": "17",
        "65+": "1",
        "medianAge": "17"
      }
    },
    "title": "Electrical power-line installers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "124",
        "women": "0.2",
        "white": "8.6",
        "black": "0.4",
        "asian": "11.5"
      }
    },
    "wageData": {
      "2011": {
        "workers": "111",
        "median": "1,116",
        "maleWorkers": "111",
        "maleMedian": "1,116",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Telecommunications line installers and repairers": {
    "ageData": {
      "2011": {
        "total": "201",
        "16to19": "2",
        "20to24": "14",
        "25to34": "52",
        "35to44": "68",
        "45to54": "47",
        "55to64": "16",
        "65+": "2",
        "medianAge": "16"
      }
    },
    "title": "Telecommunications line installers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "201",
        "women": "4.3",
        "white": "12.2",
        "black": "1.1",
        "asian": "17.3"
      }
    },
    "wageData": {
      "2011": {
        "workers": "192",
        "median": "915",
        "maleWorkers": "185",
        "maleMedian": "926",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Precision instrument and equipment repairers": {
    "ageData": {
      "2011": {
        "total": "76",
        "16to19": "2",
        "20to24": "2",
        "25to34": "16",
        "35to44": "16",
        "45to54": "18",
        "55to64": "15",
        "65+": "7",
        "medianAge": "15"
      }
    },
    "title": "Precision instrument and equipment repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "76",
        "women": "16.2",
        "white": "5.0",
        "black": "2.7",
        "asian": "21.6"
      }
    },
    "wageData": {
      "2011": {
        "workers": "62",
        "median": "898",
        "maleWorkers": "52",
        "maleMedian": "914",
        "femaleWorkers": "10",
        "femaleMedian": "-"
      }
    }
  },
  "Wind turbine service technicians": {
    "ageData": {
      "2011": {
        "total": "2",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Wind turbine service technicians",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "2",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Coin, vending, and amusement machine servicers and repairers": {
    "ageData": {
      "2011": {
        "total": "46",
        "16to19": "0",
        "20to24": "4",
        "25to34": "10",
        "35to44": "11",
        "45to54": "11",
        "55to64": "7",
        "65+": "3",
        "medianAge": "7"
      }
    },
    "title": "Coin, vending, and amusement machine servicers and repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "46",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "29",
        "median": "-",
        "maleWorkers": "27",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Commercial divers": {
    "ageData": {
      "2011": {
        "total": "0",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Commercial divers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "0",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Locksmiths and safe repairers": {
    "ageData": {
      "2011": {
        "total": "28",
        "16to19": "0",
        "20to24": "0",
        "25to34": "4",
        "35to44": "6",
        "45to54": "9",
        "55to64": "5",
        "65+": "4",
        "medianAge": "5"
      }
    },
    "title": "Locksmiths and safe repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "28",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "17",
        "median": "-",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Manufactured building and mobile home installers": {
    "ageData": {
      "2011": {
        "total": "7",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "0",
        "45to54": "1",
        "55to64": "1",
        "65+": "1",
        "medianAge": "1"
      }
    },
    "title": "Manufactured building and mobile home installers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Riggers": {
    "ageData": {
      "2011": {
        "total": "14",
        "16to19": "0",
        "20to24": "1",
        "25to34": "2",
        "35to44": "3",
        "45to54": "4",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Riggers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "14",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "15",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Signal and track switch repairers": {
    "ageData": {
      "2011": {
        "total": "5",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "1",
        "45to54": "4",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Signal and track switch repairers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Helpers--installation, maintenance, and repair workers": {
    "ageData": {
      "2011": {
        "total": "24",
        "16to19": "4",
        "20to24": "5",
        "25to34": "4",
        "35to44": "7",
        "45to54": "1",
        "55to64": "1",
        "65+": "1",
        "medianAge": "1"
      }
    },
    "title": "Helpers--installation, maintenance, and repair workers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "24",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "18",
        "median": "-",
        "maleWorkers": "17",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Other installation, maintenance, and repair workers": {
    "ageData": {
      "2011": {
        "total": "215",
        "16to19": "2",
        "20to24": "27",
        "25to34": "43",
        "35to44": "56",
        "45to54": "53",
        "55to64": "25",
        "65+": "10",
        "medianAge": "25"
      }
    },
    "title": "Other installation, maintenance, and repair workers",
    "depth": 3,
    "parent": "Installation, maintenance, and repair occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "215",
        "women": "7.8",
        "white": "9.2",
        "black": "3.0",
        "asian": "23.3"
      }
    },
    "wageData": {
      "2011": {
        "workers": "149",
        "median": "671",
        "maleWorkers": "137",
        "maleMedian": "718",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Production, transportation, and material moving occupations": {
    "ageData": {
      "2011": {
        "total": "16,461",
        "16to19": "447",
        "20to24": "1,519",
        "25to34": "3,341",
        "35to44": "3,563",
        "45to54": "4,215",
        "55to64": "2,659",
        "65+": "717",
        "medianAge": "2,659"
      }
    },
    "title": "Production, transportation, and material moving occupations",
    "depth": 1,
    "parent": "",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "16,461",
        "women": "21.6",
        "white": "13.7",
        "black": "4.3",
        "asian": "21.0"
      }
    },
    "wageData": {
      "2011": {
        "workers": "13,333",
        "median": "609",
        "maleWorkers": "10,730",
        "maleMedian": "651",
        "femaleWorkers": "2,603",
        "femaleMedian": "485"
      }
    }
  },
  "Production occupations": {
    "ageData": {
      "2011": {
        "total": "8,142",
        "16to19": "142",
        "20to24": "712",
        "25to34": "1,685",
        "35to44": "1,812",
        "45to54": "2,185",
        "55to64": "1,346",
        "65+": "260",
        "medianAge": "1,346"
      }
    },
    "title": "Production occupations",
    "depth": 2,
    "parent": "Production, transportation, and material moving occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "8,142",
        "women": "28.4",
        "white": "11.0",
        "black": "5.9",
        "asian": "21.6"
      }
    },
    "wageData": {
      "2011": {
        "workers": "7,058",
        "median": "605",
        "maleWorkers": "5,241",
        "maleMedian": "667",
        "femaleWorkers": "1,817",
        "femaleMedian": "483"
      }
    }
  },
  "First-line supervisors of production and operating workers": {
    "ageData": {
      "2011": {
        "total": "727",
        "16to19": "1",
        "20to24": "20",
        "25to34": "118",
        "35to44": "172",
        "45to54": "251",
        "55to64": "146",
        "65+": "20",
        "medianAge": "146"
      }
    },
    "title": "First-line supervisors of production and operating workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "727",
        "women": "18.6",
        "white": "7.7",
        "black": "5.6",
        "asian": "12.7"
      }
    },
    "wageData": {
      "2011": {
        "workers": "654",
        "median": "888",
        "maleWorkers": "530",
        "maleMedian": "929",
        "femaleWorkers": "124",
        "femaleMedian": "665"
      }
    }
  },
  "Aircraft structure, surfaces, rigging, and systems assemblers": {
    "ageData": {
      "2011": {
        "total": "20",
        "16to19": "0",
        "20to24": "1",
        "25to34": "2",
        "35to44": "3",
        "45to54": "9",
        "55to64": "5",
        "65+": "0",
        "medianAge": "5"
      }
    },
    "title": "Aircraft structure, surfaces, rigging, and systems assemblers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "20",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "19",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Electrical, electronics, and electromechanical assemblers": {
    "ageData": {
      "2011": {
        "total": "156",
        "16to19": "2",
        "20to24": "17",
        "25to34": "21",
        "35to44": "33",
        "45to54": "46",
        "55to64": "30",
        "65+": "7",
        "medianAge": "30"
      }
    },
    "title": "Electrical, electronics, and electromechanical assemblers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "156",
        "women": "51.9",
        "white": "13.5",
        "black": "21.5",
        "asian": "20.4"
      }
    },
    "wageData": {
      "2011": {
        "workers": "148",
        "median": "521",
        "maleWorkers": "75",
        "maleMedian": "591",
        "femaleWorkers": "73",
        "femaleMedian": "449"
      }
    }
  },
  "Engine and other machine assemblers": {
    "ageData": {
      "2011": {
        "total": "34",
        "16to19": "1",
        "20to24": "1",
        "25to34": "7",
        "35to44": "4",
        "45to54": "12",
        "55to64": "8",
        "65+": "0",
        "medianAge": "8"
      }
    },
    "title": "Engine and other machine assemblers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "34",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "30",
        "median": "-",
        "maleWorkers": "25",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Structural metal fabricators and fitters": {
    "ageData": {
      "2011": {
        "total": "32",
        "16to19": "0",
        "20to24": "1",
        "25to34": "8",
        "35to44": "8",
        "45to54": "12",
        "55to64": "1",
        "65+": "1",
        "medianAge": "1"
      }
    },
    "title": "Structural metal fabricators and fitters",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "32",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "33",
        "median": "-",
        "maleWorkers": "31",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous assemblers and fabricators": {
    "ageData": {
      "2011": {
        "total": "860",
        "16to19": "19",
        "20to24": "87",
        "25to34": "190",
        "35to44": "195",
        "45to54": "222",
        "55to64": "124",
        "65+": "23",
        "medianAge": "124"
      }
    },
    "title": "Miscellaneous assemblers and fabricators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "860",
        "women": "38.2",
        "white": "16.3",
        "black": "7.5",
        "asian": "20.3"
      }
    },
    "wageData": {
      "2011": {
        "workers": "758",
        "median": "519",
        "maleWorkers": "473",
        "maleMedian": "566",
        "femaleWorkers": "285",
        "femaleMedian": "479"
      }
    }
  },
  "Bakers": {
    "ageData": {
      "2011": {
        "total": "207",
        "16to19": "8",
        "20to24": "33",
        "25to34": "40",
        "35to44": "36",
        "45to54": "49",
        "55to64": "30",
        "65+": "10",
        "medianAge": "30"
      }
    },
    "title": "Bakers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "207",
        "women": "55.0",
        "white": "13.3",
        "black": "5.0",
        "asian": "32.9"
      }
    },
    "wageData": {
      "2011": {
        "workers": "125",
        "median": "448",
        "maleWorkers": "63",
        "maleMedian": "471",
        "femaleWorkers": "63",
        "femaleMedian": "416"
      }
    }
  },
  "Butchers and other meat, poultry, and fish processing workers": {
    "ageData": {
      "2011": {
        "total": "342",
        "16to19": "11",
        "20to24": "46",
        "25to34": "91",
        "35to44": "67",
        "45to54": "74",
        "55to64": "42",
        "65+": "11",
        "medianAge": "42"
      }
    },
    "title": "Butchers and other meat, poultry, and fish processing workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "342",
        "women": "26.6",
        "white": "17.2",
        "black": "10.6",
        "asian": "38.0"
      }
    },
    "wageData": {
      "2011": {
        "workers": "281",
        "median": "504",
        "maleWorkers": "215",
        "maleMedian": "515",
        "femaleWorkers": "66",
        "femaleMedian": "479"
      }
    }
  },
  "Food and tobacco roasting, baking, and drying machine operators and tenders": {
    "ageData": {
      "2011": {
        "total": "12",
        "16to19": "1",
        "20to24": "2",
        "25to34": "2",
        "35to44": "3",
        "45to54": "1",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Food and tobacco roasting, baking, and drying machine operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "12",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Food batchmakers": {
    "ageData": {
      "2011": {
        "total": "90",
        "16to19": "4",
        "20to24": "10",
        "25to34": "24",
        "35to44": "18",
        "45to54": "19",
        "55to64": "12",
        "65+": "2",
        "medianAge": "12"
      }
    },
    "title": "Food batchmakers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "90",
        "women": "53.3",
        "white": "15.3",
        "black": "4.3",
        "asian": "29.3"
      }
    },
    "wageData": {
      "2011": {
        "workers": "63",
        "median": "554",
        "maleWorkers": "35",
        "maleMedian": "-",
        "femaleWorkers": "28",
        "femaleMedian": "-"
      }
    }
  },
  "Food cooking machine operators and tenders": {
    "ageData": {
      "2011": {
        "total": "24",
        "16to19": "1",
        "20to24": "4",
        "25to34": "5",
        "35to44": "4",
        "45to54": "5",
        "55to64": "2",
        "65+": "2",
        "medianAge": "2"
      }
    },
    "title": "Food cooking machine operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "24",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "17",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Food processing workers, all other": {
    "ageData": {
      "2011": {
        "total": "115",
        "16to19": "1",
        "20to24": "12",
        "25to34": "29",
        "35to44": "29",
        "45to54": "34",
        "55to64": "8",
        "65+": "2",
        "medianAge": "8"
      }
    },
    "title": "Food processing workers, all other",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "115",
        "women": "29.9",
        "white": "14.2",
        "black": "2.5",
        "asian": "39.8"
      }
    },
    "wageData": {
      "2011": {
        "workers": "104",
        "median": "534",
        "maleWorkers": "78",
        "maleMedian": "544",
        "femaleWorkers": "25",
        "femaleMedian": "-"
      }
    }
  },
  "Computer control programmers and operators": {
    "ageData": {
      "2011": {
        "total": "72",
        "16to19": "0",
        "20to24": "5",
        "25to34": "19",
        "35to44": "19",
        "45to54": "18",
        "55to64": "8",
        "65+": "2",
        "medianAge": "8"
      }
    },
    "title": "Computer control programmers and operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "72",
        "women": "5.5",
        "white": "4.5",
        "black": "6.0",
        "asian": "12.9"
      }
    },
    "wageData": {
      "2011": {
        "workers": "70",
        "median": "770",
        "maleWorkers": "65",
        "maleMedian": "781",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Extruding and drawing machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2011": {
        "total": "13",
        "16to19": "0",
        "20to24": "1",
        "25to34": "4",
        "35to44": "4",
        "45to54": "2",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Extruding and drawing machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "13",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "11",
        "median": "-",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Forging machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2011": {
        "total": "6",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "3",
        "55to64": "1",
        "65+": "1",
        "medianAge": "1"
      }
    },
    "title": "Forging machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "6",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Rolling machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2011": {
        "total": "8",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "1",
        "45to54": "3",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Rolling machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "8",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "9",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Cutting, punching, and press machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2011": {
        "total": "100",
        "16to19": "1",
        "20to24": "11",
        "25to34": "22",
        "35to44": "24",
        "45to54": "24",
        "55to64": "16",
        "65+": "2",
        "medianAge": "16"
      }
    },
    "title": "Cutting, punching, and press machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "100",
        "women": "14.8",
        "white": "10.7",
        "black": "3.3",
        "asian": "18.2"
      }
    },
    "wageData": {
      "2011": {
        "workers": "92",
        "median": "591",
        "maleWorkers": "78",
        "maleMedian": "608",
        "femaleWorkers": "15",
        "femaleMedian": "-"
      }
    }
  },
  "Drilling and boring machine tool setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2011": {
        "total": "5",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "1",
        "45to54": "2",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Drilling and boring machine tool setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Grinding, lapping, polishing, and buffing machine tool setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2011": {
        "total": "65",
        "16to19": "3",
        "20to24": "10",
        "25to34": "8",
        "35to44": "14",
        "45to54": "15",
        "55to64": "13",
        "65+": "2",
        "medianAge": "13"
      }
    },
    "title": "Grinding, lapping, polishing, and buffing machine tool setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "65",
        "women": "6.7",
        "white": "10.0",
        "black": "0.2",
        "asian": "29.6"
      }
    },
    "wageData": {
      "2011": {
        "workers": "60",
        "median": "643",
        "maleWorkers": "57",
        "maleMedian": "641",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Lathe and turning machine tool setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2011": {
        "total": "15",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "8",
        "45to54": "3",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Lathe and turning machine tool setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "15",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "15",
        "median": "-",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Milling and planing machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2011": {
        "total": "4",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "0",
        "45to54": "3",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Milling and planing machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Machinists": {
    "ageData": {
      "2011": {
        "total": "419",
        "16to19": "4",
        "20to24": "29",
        "25to34": "73",
        "35to44": "92",
        "45to54": "116",
        "55to64": "85",
        "65+": "19",
        "medianAge": "85"
      }
    },
    "title": "Machinists",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "419",
        "women": "4.4",
        "white": "3.7",
        "black": "4.2",
        "asian": "14.1"
      }
    },
    "wageData": {
      "2011": {
        "workers": "393",
        "median": "755",
        "maleWorkers": "377",
        "maleMedian": "767",
        "femaleWorkers": "16",
        "femaleMedian": "-"
      }
    }
  },
  "Metal furnace operators, tenders, pourers, and casters": {
    "ageData": {
      "2011": {
        "total": "16",
        "16to19": "0",
        "20to24": "0",
        "25to34": "6",
        "35to44": "4",
        "45to54": "3",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Metal furnace operators, tenders, pourers, and casters",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "16",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "19",
        "median": "-",
        "maleWorkers": "18",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Model makers and patternmakers, metal and plastic": {
    "ageData": {
      "2011": {
        "total": "7",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "1",
        "45to54": "1",
        "55to64": "1",
        "65+": "2",
        "medianAge": "1"
      }
    },
    "title": "Model makers and patternmakers, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Molders and molding machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2011": {
        "total": "45",
        "16to19": "1",
        "20to24": "4",
        "25to34": "9",
        "35to44": "6",
        "45to54": "19",
        "55to64": "5",
        "65+": "0",
        "medianAge": "5"
      }
    },
    "title": "Molders and molding machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "45",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "47",
        "median": "-",
        "maleWorkers": "35",
        "maleMedian": "-",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Multiple machine tool setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2011": {
        "total": "5",
        "16to19": "0",
        "20to24": "1",
        "25to34": "0",
        "35to44": "1",
        "45to54": "2",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Multiple machine tool setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Tool and die makers": {
    "ageData": {
      "2011": {
        "total": "66",
        "16to19": "1",
        "20to24": "1",
        "25to34": "5",
        "35to44": "14",
        "45to54": "21",
        "55to64": "19",
        "65+": "5",
        "medianAge": "19"
      }
    },
    "title": "Tool and die makers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "66",
        "women": "2.3",
        "white": "3.7",
        "black": "0.6",
        "asian": "9.4"
      }
    },
    "wageData": {
      "2011": {
        "workers": "61",
        "median": "920",
        "maleWorkers": "59",
        "maleMedian": "930",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Welding, soldering, and brazing workers": {
    "ageData": {
      "2011": {
        "total": "505",
        "16to19": "6",
        "20to24": "53",
        "25to34": "127",
        "35to44": "119",
        "45to54": "118",
        "55to64": "69",
        "65+": "14",
        "medianAge": "69"
      }
    },
    "title": "Welding, soldering, and brazing workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "505",
        "women": "7.8",
        "white": "7.6",
        "black": "3.8",
        "asian": "20.9"
      }
    },
    "wageData": {
      "2011": {
        "workers": "461",
        "median": "679",
        "maleWorkers": "426",
        "maleMedian": "695",
        "femaleWorkers": "36",
        "femaleMedian": "-"
      }
    }
  },
  "Heat treating equipment setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2011": {
        "total": "4",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "0",
        "45to54": "2",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Heat treating equipment setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Layout workers, metal and plastic": {
    "ageData": {
      "2011": {
        "total": "6",
        "16to19": "0",
        "20to24": "0",
        "25to34": "3",
        "35to44": "1",
        "45to54": "2",
        "55to64": "0",
        "65+": "1",
        "medianAge": "0"
      }
    },
    "title": "Layout workers, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "6",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Plating and coating machine setters, operators, and tenders, metal and plastic": {
    "ageData": {
      "2011": {
        "total": "15",
        "16to19": "0",
        "20to24": "1",
        "25to34": "3",
        "35to44": "5",
        "45to54": "4",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Plating and coating machine setters, operators, and tenders, metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "15",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "15",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Tool grinders, filers, and sharpeners": {
    "ageData": {
      "2011": {
        "total": "7",
        "16to19": "1",
        "20to24": "1",
        "25to34": "1",
        "35to44": "0",
        "45to54": "3",
        "55to64": "0",
        "65+": "1",
        "medianAge": "0"
      }
    },
    "title": "Tool grinders, filers, and sharpeners",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "7",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Metal workers and plastic workers, all other": {
    "ageData": {
      "2011": {
        "total": "368",
        "16to19": "5",
        "20to24": "33",
        "25to34": "83",
        "35to44": "95",
        "45to54": "90",
        "55to64": "55",
        "65+": "7",
        "medianAge": "55"
      }
    },
    "title": "Metal workers and plastic workers, all other",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "368",
        "women": "22.0",
        "white": "10.3",
        "black": "7.2",
        "asian": "24.3"
      }
    },
    "wageData": {
      "2011": {
        "workers": "356",
        "median": "561",
        "maleWorkers": "275",
        "maleMedian": "583",
        "femaleWorkers": "81",
        "femaleMedian": "517"
      }
    }
  },
  "Prepress technicians and workers": {
    "ageData": {
      "2011": {
        "total": "41",
        "16to19": "2",
        "20to24": "4",
        "25to34": "9",
        "35to44": "6",
        "45to54": "13",
        "55to64": "7",
        "65+": "1",
        "medianAge": "7"
      }
    },
    "title": "Prepress technicians and workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "41",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "30",
        "median": "-",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "16",
        "femaleMedian": "-"
      }
    }
  },
  "Printing press operators": {
    "ageData": {
      "2011": {
        "total": "217",
        "16to19": "4",
        "20to24": "20",
        "25to34": "41",
        "35to44": "45",
        "45to54": "69",
        "55to64": "33",
        "65+": "6",
        "medianAge": "33"
      }
    },
    "title": "Printing press operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "217",
        "women": "18.7",
        "white": "12.0",
        "black": "2.2",
        "asian": "16.9"
      }
    },
    "wageData": {
      "2011": {
        "workers": "186",
        "median": "613",
        "maleWorkers": "155",
        "maleMedian": "646",
        "femaleWorkers": "31",
        "femaleMedian": "-"
      }
    }
  },
  "Print binding and finishing workers": {
    "ageData": {
      "2011": {
        "total": "16",
        "16to19": "0",
        "20to24": "0",
        "25to34": "4",
        "35to44": "2",
        "45to54": "6",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Print binding and finishing workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "16",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Laundry and dry-cleaning workers": {
    "ageData": {
      "2011": {
        "total": "174",
        "16to19": "5",
        "20to24": "19",
        "25to34": "30",
        "35to44": "33",
        "45to54": "39",
        "55to64": "37",
        "65+": "11",
        "medianAge": "37"
      }
    },
    "title": "Laundry and dry-cleaning workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "174",
        "women": "60.7",
        "white": "15.2",
        "black": "10.4",
        "asian": "33.8"
      }
    },
    "wageData": {
      "2011": {
        "workers": "115",
        "median": "412",
        "maleWorkers": "52",
        "maleMedian": "447",
        "femaleWorkers": "64",
        "femaleMedian": "386"
      }
    }
  },
  "Pressers, textile, garment, and related materials": {
    "ageData": {
      "2011": {
        "total": "48",
        "16to19": "0",
        "20to24": "0",
        "25to34": "15",
        "35to44": "13",
        "45to54": "8",
        "55to64": "9",
        "65+": "2",
        "medianAge": "9"
      }
    },
    "title": "Pressers, textile, garment, and related materials",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "48",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "37",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "24",
        "femaleMedian": "-"
      }
    }
  },
  "Sewing machine operators": {
    "ageData": {
      "2011": {
        "total": "169",
        "16to19": "0",
        "20to24": "4",
        "25to34": "29",
        "35to44": "40",
        "45to54": "48",
        "55to64": "36",
        "65+": "11",
        "medianAge": "36"
      }
    },
    "title": "Sewing machine operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "169",
        "women": "78.5",
        "white": "9.7",
        "black": "12.0",
        "asian": "36.9"
      }
    },
    "wageData": {
      "2011": {
        "workers": "112",
        "median": "403",
        "maleWorkers": "28",
        "maleMedian": "-",
        "femaleWorkers": "84",
        "femaleMedian": "398"
      }
    }
  },
  "Shoe and leather workers and repairers": {
    "ageData": {
      "2011": {
        "total": "11",
        "16to19": "0",
        "20to24": "1",
        "25to34": "1",
        "35to44": "2",
        "45to54": "4",
        "55to64": "1",
        "65+": "1",
        "medianAge": "1"
      }
    },
    "title": "Shoe and leather workers and repairers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "11",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Shoe machine operators and tenders": {
    "ageData": {
      "2011": {
        "total": "8",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "3",
        "45to54": "4",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Shoe machine operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "8",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Tailors, dressmakers, and sewers": {
    "ageData": {
      "2011": {
        "total": "94",
        "16to19": "0",
        "20to24": "3",
        "25to34": "9",
        "35to44": "18",
        "45to54": "29",
        "55to64": "24",
        "65+": "11",
        "medianAge": "24"
      }
    },
    "title": "Tailors, dressmakers, and sewers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "94",
        "women": "74.9",
        "white": "4.2",
        "black": "10.7",
        "asian": "27.5"
      }
    },
    "wageData": {
      "2011": {
        "workers": "46",
        "median": "-",
        "maleWorkers": "13",
        "maleMedian": "-",
        "femaleWorkers": "33",
        "femaleMedian": "-"
      }
    }
  },
  "Textile bleaching and dyeing machine operators and tenders": {
    "ageData": {
      "2011": {
        "total": "1",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "0",
        "45to54": "0",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Textile bleaching and dyeing machine operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Textile cutting machine setters, operators, and tenders": {
    "ageData": {
      "2011": {
        "total": "5",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "2",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Textile cutting machine setters, operators, and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Textile knitting and weaving machine setters, operators, and tenders": {
    "ageData": {
      "2011": {
        "total": "14",
        "16to19": "1",
        "20to24": "0",
        "25to34": "0",
        "35to44": "3",
        "45to54": "6",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Textile knitting and weaving machine setters, operators, and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "14",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "8",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Textile winding, twisting, and drawing out machine setters, operators, and tenders": {
    "ageData": {
      "2011": {
        "total": "16",
        "16to19": "0",
        "20to24": "0",
        "25to34": "6",
        "35to44": "3",
        "45to54": "4",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Textile winding, twisting, and drawing out machine setters, operators, and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "16",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "19",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "11",
        "femaleMedian": "-"
      }
    }
  },
  "Extruding and forming machine setters, operators, and tenders, synthetic and glass fibers": {
    "ageData": {
      "2011": {
        "total": "1",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "0",
        "45to54": "0",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Extruding and forming machine setters, operators, and tenders, synthetic and glass fibers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "2",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Fabric and apparel patternmakers": {
    "ageData": {
      "2011": {
        "total": "5",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "2",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Fabric and apparel patternmakers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Upholsterers": {
    "ageData": {
      "2011": {
        "total": "47",
        "16to19": "1",
        "20to24": "4",
        "25to34": "11",
        "35to44": "9",
        "45to54": "7",
        "55to64": "14",
        "65+": "1",
        "medianAge": "14"
      }
    },
    "title": "Upholsterers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "47",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "34",
        "median": "-",
        "maleWorkers": "30",
        "maleMedian": "-",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Textile, apparel, and furnishings workers, all other": {
    "ageData": {
      "2011": {
        "total": "22",
        "16to19": "1",
        "20to24": "1",
        "25to34": "3",
        "35to44": "5",
        "45to54": "5",
        "55to64": "5",
        "65+": "2",
        "medianAge": "5"
      }
    },
    "title": "Textile, apparel, and furnishings workers, all other",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "22",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "15",
        "median": "-",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Cabinetmakers and bench carpenters": {
    "ageData": {
      "2011": {
        "total": "66",
        "16to19": "0",
        "20to24": "5",
        "25to34": "12",
        "35to44": "13",
        "45to54": "18",
        "55to64": "17",
        "65+": "2",
        "medianAge": "17"
      }
    },
    "title": "Cabinetmakers and bench carpenters",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "66",
        "women": "2.3",
        "white": "0.3",
        "black": "1.5",
        "asian": "8.0"
      }
    },
    "wageData": {
      "2011": {
        "workers": "42",
        "median": "-",
        "maleWorkers": "41",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Furniture finishers": {
    "ageData": {
      "2011": {
        "total": "17",
        "16to19": "0",
        "20to24": "0",
        "25to34": "2",
        "35to44": "3",
        "45to54": "6",
        "55to64": "6",
        "65+": "0",
        "medianAge": "6"
      }
    },
    "title": "Furniture finishers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "17",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "11",
        "median": "-",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Model makers and patternmakers, wood": {
    "ageData": {
      "2011": {
        "total": "1",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Model makers and patternmakers, wood",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "1",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Sawing machine setters, operators, and tenders, wood": {
    "ageData": {
      "2011": {
        "total": "32",
        "16to19": "2",
        "20to24": "7",
        "25to34": "6",
        "35to44": "6",
        "45to54": "7",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Sawing machine setters, operators, and tenders, wood",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "32",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "31",
        "median": "-",
        "maleWorkers": "30",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Woodworking machine setters, operators, and tenders, except sawing": {
    "ageData": {
      "2011": {
        "total": "20",
        "16to19": "0",
        "20to24": "2",
        "25to34": "6",
        "35to44": "2",
        "45to54": "6",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Woodworking machine setters, operators, and tenders, except sawing",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "20",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "17",
        "median": "-",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Woodworkers, all other": {
    "ageData": {
      "2011": {
        "total": "21",
        "16to19": "1",
        "20to24": "2",
        "25to34": "5",
        "35to44": "2",
        "45to54": "5",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Woodworkers, all other",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "21",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "10",
        "median": "-",
        "maleWorkers": "8",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Power plant operators, distributors, and dispatchers": {
    "ageData": {
      "2011": {
        "total": "50",
        "16to19": "0",
        "20to24": "0",
        "25to34": "10",
        "35to44": "12",
        "45to54": "19",
        "55to64": "7",
        "65+": "2",
        "medianAge": "7"
      }
    },
    "title": "Power plant operators, distributors, and dispatchers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "50",
        "women": "1.8",
        "white": "5.9",
        "black": "2.0",
        "asian": "6.5"
      }
    },
    "wageData": {
      "2011": {
        "workers": "53",
        "median": "1,091",
        "maleWorkers": "52",
        "maleMedian": "1,085",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Stationary engineers and boiler operators": {
    "ageData": {
      "2011": {
        "total": "95",
        "16to19": "1",
        "20to24": "1",
        "25to34": "9",
        "35to44": "18",
        "45to54": "31",
        "55to64": "30",
        "65+": "5",
        "medianAge": "30"
      }
    },
    "title": "Stationary engineers and boiler operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "95",
        "women": "2.2",
        "white": "13.5",
        "black": "3.4",
        "asian": "17.4"
      }
    },
    "wageData": {
      "2011": {
        "workers": "93",
        "median": "855",
        "maleWorkers": "92",
        "maleMedian": "851",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Water and wastewater treatment plant and system operators": {
    "ageData": {
      "2011": {
        "total": "71",
        "16to19": "0",
        "20to24": "1",
        "25to34": "12",
        "35to44": "13",
        "45to54": "25",
        "55to64": "18",
        "65+": "1",
        "medianAge": "18"
      }
    },
    "title": "Water and wastewater treatment plant and system operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "71",
        "women": "5.2",
        "white": "11.1",
        "black": "0.8",
        "asian": "10.8"
      }
    },
    "wageData": {
      "2011": {
        "workers": "75",
        "median": "780",
        "maleWorkers": "70",
        "maleMedian": "788",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Miscellaneous plant and system operators": {
    "ageData": {
      "2011": {
        "total": "39",
        "16to19": "0",
        "20to24": "1",
        "25to34": "7",
        "35to44": "9",
        "45to54": "10",
        "55to64": "10",
        "65+": "2",
        "medianAge": "10"
      }
    },
    "title": "Miscellaneous plant and system operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "39",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "37",
        "median": "-",
        "maleWorkers": "34",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Chemical processing machine setters, operators, and tenders": {
    "ageData": {
      "2011": {
        "total": "65",
        "16to19": "1",
        "20to24": "5",
        "25to34": "18",
        "35to44": "12",
        "45to54": "16",
        "55to64": "12",
        "65+": "0",
        "medianAge": "12"
      }
    },
    "title": "Chemical processing machine setters, operators, and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "65",
        "women": "16.4",
        "white": "9.8",
        "black": "2.4",
        "asian": "11.3"
      }
    },
    "wageData": {
      "2011": {
        "workers": "66",
        "median": "853",
        "maleWorkers": "52",
        "maleMedian": "947",
        "femaleWorkers": "14",
        "femaleMedian": "-"
      }
    }
  },
  "Crushing, grinding, polishing, mixing, and blending workers": {
    "ageData": {
      "2011": {
        "total": "94",
        "16to19": "2",
        "20to24": "9",
        "25to34": "29",
        "35to44": "20",
        "45to54": "21",
        "55to64": "13",
        "65+": "1",
        "medianAge": "13"
      }
    },
    "title": "Crushing, grinding, polishing, mixing, and blending workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "94",
        "women": "13.5",
        "white": "8.1",
        "black": "3.9",
        "asian": "21.0"
      }
    },
    "wageData": {
      "2011": {
        "workers": "94",
        "median": "659",
        "maleWorkers": "82",
        "maleMedian": "702",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Cutting workers": {
    "ageData": {
      "2011": {
        "total": "84",
        "16to19": "3",
        "20to24": "9",
        "25to34": "19",
        "35to44": "19",
        "45to54": "17",
        "55to64": "16",
        "65+": "1",
        "medianAge": "16"
      }
    },
    "title": "Cutting workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "84",
        "women": "22.5",
        "white": "9.8",
        "black": "1.2",
        "asian": "28.5"
      }
    },
    "wageData": {
      "2011": {
        "workers": "69",
        "median": "518",
        "maleWorkers": "55",
        "maleMedian": "540",
        "femaleWorkers": "13",
        "femaleMedian": "-"
      }
    }
  },
  "Extruding, forming, pressing, and compacting machine setters, operators, and tenders": {
    "ageData": {
      "2011": {
        "total": "34",
        "16to19": "0",
        "20to24": "3",
        "25to34": "7",
        "35to44": "8",
        "45to54": "12",
        "55to64": "4",
        "65+": "0",
        "medianAge": "4"
      }
    },
    "title": "Extruding, forming, pressing, and compacting machine setters, operators, and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "34",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "31",
        "median": "-",
        "maleWorkers": "28",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Furnace, kiln, oven, drier, and kettle operators and tenders": {
    "ageData": {
      "2011": {
        "total": "11",
        "16to19": "0",
        "20to24": "1",
        "25to34": "6",
        "35to44": "1",
        "45to54": "1",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Furnace, kiln, oven, drier, and kettle operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "11",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "11",
        "median": "-",
        "maleWorkers": "11",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Inspectors, testers, sorters, samplers, and weighers": {
    "ageData": {
      "2011": {
        "total": "647",
        "16to19": "6",
        "20to24": "54",
        "25to34": "135",
        "35to44": "145",
        "45to54": "176",
        "55to64": "116",
        "65+": "15",
        "medianAge": "116"
      }
    },
    "title": "Inspectors, testers, sorters, samplers, and weighers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "647",
        "women": "34.9",
        "white": "8.1",
        "black": "6.0",
        "asian": "16.4"
      }
    },
    "wageData": {
      "2011": {
        "workers": "608",
        "median": "675",
        "maleWorkers": "396",
        "maleMedian": "792",
        "femaleWorkers": "212",
        "femaleMedian": "541"
      }
    }
  },
  "Jewelers and precious stone and metal workers": {
    "ageData": {
      "2011": {
        "total": "41",
        "16to19": "0",
        "20to24": "2",
        "25to34": "3",
        "35to44": "8",
        "45to54": "10",
        "55to64": "14",
        "65+": "5",
        "medianAge": "14"
      }
    },
    "title": "Jewelers and precious stone and metal workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "41",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "14",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "7",
        "femaleMedian": "-"
      }
    }
  },
  "Medical, dental, and ophthalmic laboratory technicians": {
    "ageData": {
      "2011": {
        "total": "87",
        "16to19": "1",
        "20to24": "12",
        "25to34": "15",
        "35to44": "23",
        "45to54": "21",
        "55to64": "11",
        "65+": "3",
        "medianAge": "11"
      }
    },
    "title": "Medical, dental, and ophthalmic laboratory technicians",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "87",
        "women": "53.1",
        "white": "7.3",
        "black": "9.8",
        "asian": "13.0"
      }
    },
    "wageData": {
      "2011": {
        "workers": "68",
        "median": "612",
        "maleWorkers": "34",
        "maleMedian": "-",
        "femaleWorkers": "34",
        "femaleMedian": "-"
      }
    }
  },
  "Packaging and filling machine operators and tenders": {
    "ageData": {
      "2011": {
        "total": "288",
        "16to19": "10",
        "20to24": "36",
        "25to34": "69",
        "35to44": "60",
        "45to54": "70",
        "55to64": "37",
        "65+": "6",
        "medianAge": "37"
      }
    },
    "title": "Packaging and filling machine operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "288",
        "women": "50.6",
        "white": "13.3",
        "black": "5.6",
        "asian": "40.9"
      }
    },
    "wageData": {
      "2011": {
        "workers": "259",
        "median": "455",
        "maleWorkers": "120",
        "maleMedian": "505",
        "femaleWorkers": "139",
        "femaleMedian": "421"
      }
    }
  },
  "Painting workers": {
    "ageData": {
      "2011": {
        "total": "120",
        "16to19": "2",
        "20to24": "9",
        "25to34": "29",
        "35to44": "34",
        "45to54": "30",
        "55to64": "13",
        "65+": "3",
        "medianAge": "13"
      }
    },
    "title": "Painting workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "120",
        "women": "13.4",
        "white": "10.8",
        "black": "3.1",
        "asian": "23.1"
      }
    },
    "wageData": {
      "2011": {
        "workers": "111",
        "median": "602",
        "maleWorkers": "99",
        "maleMedian": "608",
        "femaleWorkers": "12",
        "femaleMedian": "-"
      }
    }
  },
  "Photographic process workers and processing machine operators": {
    "ageData": {
      "2011": {
        "total": "43",
        "16to19": "3",
        "20to24": "13",
        "25to34": "14",
        "35to44": "5",
        "45to54": "5",
        "55to64": "3",
        "65+": "0",
        "medianAge": "3"
      }
    },
    "title": "Photographic process workers and processing machine operators",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "43",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "24",
        "median": "-",
        "maleWorkers": "10",
        "maleMedian": "-",
        "femaleWorkers": "13",
        "femaleMedian": "-"
      }
    }
  },
  "Semiconductor processors": {
    "ageData": {
      "2011": {
        "total": "3",
        "16to19": "0",
        "20to24": "1",
        "25to34": "0",
        "35to44": "1",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Semiconductor processors",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "4",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Adhesive bonding machine operators and tenders": {
    "ageData": {
      "2011": {
        "total": "9",
        "16to19": "0",
        "20to24": "1",
        "25to34": "0",
        "35to44": "5",
        "45to54": "2",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Adhesive bonding machine operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "9",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "10",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Cleaning, washing, and metal pickling equipment operators and tenders": {
    "ageData": {
      "2011": {
        "total": "11",
        "16to19": "0",
        "20to24": "0",
        "25to34": "4",
        "35to44": "4",
        "45to54": "2",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Cleaning, washing, and metal pickling equipment operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "11",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Cooling and freezing equipment operators and tenders": {
    "ageData": {
      "2011": {
        "total": "3",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "1",
        "45to54": "1",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Cooling and freezing equipment operators and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Etchers and engravers": {
    "ageData": {
      "2011": {
        "total": "10",
        "16to19": "0",
        "20to24": "1",
        "25to34": "0",
        "35to44": "4",
        "45to54": "3",
        "55to64": "1",
        "65+": "1",
        "medianAge": "1"
      }
    },
    "title": "Etchers and engravers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "10",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "4",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Molders, shapers, and casters, except metal and plastic": {
    "ageData": {
      "2011": {
        "total": "37",
        "16to19": "2",
        "20to24": "7",
        "25to34": "7",
        "35to44": "4",
        "45to54": "7",
        "55to64": "6",
        "65+": "4",
        "medianAge": "6"
      }
    },
    "title": "Molders, shapers, and casters, except metal and plastic",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "37",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "21",
        "median": "-",
        "maleWorkers": "15",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Paper goods machine setters, operators, and tenders": {
    "ageData": {
      "2011": {
        "total": "37",
        "16to19": "0",
        "20to24": "1",
        "25to34": "7",
        "35to44": "10",
        "45to54": "14",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Paper goods machine setters, operators, and tenders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "37",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "31",
        "median": "-",
        "maleWorkers": "23",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Tire builders": {
    "ageData": {
      "2011": {
        "total": "20",
        "16to19": "0",
        "20to24": "1",
        "25to34": "5",
        "35to44": "6",
        "45to54": "7",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Tire builders",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "20",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "17",
        "median": "-",
        "maleWorkers": "16",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Helpers--production workers": {
    "ageData": {
      "2011": {
        "total": "54",
        "16to19": "6",
        "20to24": "15",
        "25to34": "10",
        "35to44": "10",
        "45to54": "7",
        "55to64": "5",
        "65+": "1",
        "medianAge": "5"
      }
    },
    "title": "Helpers--production workers",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "54",
        "women": "25.6",
        "white": "11.2",
        "black": "3.4",
        "asian": "30.6"
      }
    },
    "wageData": {
      "2011": {
        "workers": "45",
        "median": "-",
        "maleWorkers": "40",
        "maleMedian": "-",
        "femaleWorkers": "6",
        "femaleMedian": "-"
      }
    }
  },
  "Production workers, all other": {
    "ageData": {
      "2011": {
        "total": "777",
        "16to19": "16",
        "20to24": "70",
        "25to34": "166",
        "35to44": "185",
        "45to54": "205",
        "55to64": "115",
        "65+": "20",
        "medianAge": "115"
      }
    },
    "title": "Production workers, all other",
    "depth": 3,
    "parent": "Production occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "777",
        "women": "26.2",
        "white": "14.7",
        "black": "5.3",
        "asian": "21.5"
      }
    },
    "wageData": {
      "2011": {
        "workers": "652",
        "median": "579",
        "maleWorkers": "502",
        "maleMedian": "612",
        "femaleWorkers": "151",
        "femaleMedian": "483"
      }
    }
  },
  "Transportation and material moving occupations": {
    "ageData": {
      "2011": {
        "total": "8,318",
        "16to19": "305",
        "20to24": "807",
        "25to34": "1,655",
        "35to44": "1,751",
        "45to54": "2,030",
        "55to64": "1,313",
        "65+": "456",
        "medianAge": "1,313"
      }
    },
    "title": "Transportation and material moving occupations",
    "depth": 2,
    "parent": "Production, transportation, and material moving occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "8,318",
        "women": "14.9",
        "white": "16.3",
        "black": "2.7",
        "asian": "20.3"
      }
    },
    "wageData": {
      "2011": {
        "workers": "6,275",
        "median": "614",
        "maleWorkers": "5,489",
        "maleMedian": "634",
        "femaleWorkers": "786",
        "femaleMedian": "490"
      }
    }
  },
  "Supervisors of transportation and material moving workers": {
    "ageData": {
      "2011": {
        "total": "228",
        "16to19": "0",
        "20to24": "15",
        "25to34": "43",
        "35to44": "57",
        "45to54": "72",
        "55to64": "35",
        "65+": "6",
        "medianAge": "35"
      }
    },
    "title": "Supervisors of transportation and material moving workers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "228",
        "women": "22.1",
        "white": "16.5",
        "black": "2.3",
        "asian": "13.7"
      }
    },
    "wageData": {
      "2011": {
        "workers": "208",
        "median": "761",
        "maleWorkers": "170",
        "maleMedian": "773",
        "femaleWorkers": "38",
        "femaleMedian": "-"
      }
    }
  },
  "Aircraft pilots and flight engineers": {
    "ageData": {
      "2011": {
        "total": "121",
        "16to19": "0",
        "20to24": "3",
        "25to34": "16",
        "35to44": "24",
        "45to54": "45",
        "55to64": "28",
        "65+": "5",
        "medianAge": "28"
      }
    },
    "title": "Aircraft pilots and flight engineers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "121",
        "women": "4.3",
        "white": "2.9",
        "black": "2.4",
        "asian": "3.9"
      }
    },
    "wageData": {
      "2011": {
        "workers": "93",
        "median": "1,461",
        "maleWorkers": "89",
        "maleMedian": "1,466",
        "femaleWorkers": "4",
        "femaleMedian": "-"
      }
    }
  },
  "Air traffic controllers and airfield operations specialists": {
    "ageData": {
      "2011": {
        "total": "43",
        "16to19": "0",
        "20to24": "1",
        "25to34": "12",
        "35to44": "11",
        "45to54": "10",
        "55to64": "7",
        "65+": "1",
        "medianAge": "7"
      }
    },
    "title": "Air traffic controllers and airfield operations specialists",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "43",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "40",
        "median": "-",
        "maleWorkers": "35",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Flight attendants": {
    "ageData": {
      "2011": {
        "total": "88",
        "16to19": "0",
        "20to24": "1",
        "25to34": "14",
        "35to44": "24",
        "45to54": "30",
        "55to64": "15",
        "65+": "4",
        "medianAge": "15"
      }
    },
    "title": "Flight attendants",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "88",
        "women": "77.4",
        "white": "9.3",
        "black": "3.2",
        "asian": "10.4"
      }
    },
    "wageData": {
      "2011": {
        "workers": "52",
        "median": "791",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "38",
        "femaleMedian": "-"
      }
    }
  },
  "Ambulance drivers and attendants, except emergency medical technicians": {
    "ageData": {
      "2011": {
        "total": "13",
        "16to19": "0",
        "20to24": "2",
        "25to34": "5",
        "35to44": "0",
        "45to54": "3",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Ambulance drivers and attendants, except emergency medical technicians",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "13",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "9",
        "median": "-",
        "maleWorkers": "9",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Bus drivers": {
    "ageData": {
      "2011": {
        "total": "573",
        "16to19": "3",
        "20to24": "5",
        "25to34": "46",
        "35to44": "98",
        "45to54": "171",
        "55to64": "158",
        "65+": "92",
        "medianAge": "158"
      }
    },
    "title": "Bus drivers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "573",
        "women": "43.4",
        "white": "24.6",
        "black": "1.6",
        "asian": "12.7"
      }
    },
    "wageData": {
      "2011": {
        "workers": "310",
        "median": "608",
        "maleWorkers": "188",
        "maleMedian": "645",
        "femaleWorkers": "122",
        "femaleMedian": "579"
      }
    }
  },
  "Driver/sales workers and truck drivers": {
    "ageData": {
      "2011": {
        "total": "3,059",
        "16to19": "43",
        "20to24": "167",
        "25to34": "550",
        "35to44": "716",
        "45to54": "858",
        "55to64": "547",
        "65+": "178",
        "medianAge": "547"
      }
    },
    "title": "Driver/sales workers and truck drivers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "3,059",
        "women": "4.8",
        "white": "13.7",
        "black": "1.6",
        "asian": "17.6"
      }
    },
    "wageData": {
      "2011": {
        "workers": "2,439",
        "median": "705",
        "maleWorkers": "2,337",
        "maleMedian": "712",
        "femaleWorkers": "102",
        "femaleMedian": "511"
      }
    }
  },
  "Taxi drivers and chauffeurs": {
    "ageData": {
      "2011": {
        "total": "342",
        "16to19": "0",
        "20to24": "15",
        "25to34": "64",
        "35to44": "63",
        "45to54": "92",
        "55to64": "67",
        "65+": "41",
        "medianAge": "67"
      }
    },
    "title": "Taxi drivers and chauffeurs",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "342",
        "women": "12.5",
        "white": "26.7",
        "black": "15.6",
        "asian": "16.0"
      }
    },
    "wageData": {
      "2011": {
        "workers": "207",
        "median": "553",
        "maleWorkers": "178",
        "maleMedian": "576",
        "femaleWorkers": "29",
        "femaleMedian": "-"
      }
    }
  },
  "Motor vehicle operators, all other": {
    "ageData": {
      "2011": {
        "total": "62",
        "16to19": "0",
        "20to24": "3",
        "25to34": "9",
        "35to44": "11",
        "45to54": "11",
        "55to64": "8",
        "65+": "21",
        "medianAge": "8"
      }
    },
    "title": "Motor vehicle operators, all other",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "62",
        "women": "5.6",
        "white": "13.5",
        "black": "3.4",
        "asian": "21.2"
      }
    },
    "wageData": {
      "2011": {
        "workers": "28",
        "median": "-",
        "maleWorkers": "26",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Locomotive engineers and operators": {
    "ageData": {
      "2011": {
        "total": "45",
        "16to19": "0",
        "20to24": "1",
        "25to34": "6",
        "35to44": "12",
        "45to54": "13",
        "55to64": "11",
        "65+": "2",
        "medianAge": "11"
      }
    },
    "title": "Locomotive engineers and operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "45",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "43",
        "median": "-",
        "maleWorkers": "42",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Railroad brake, signal, and switch operators": {
    "ageData": {
      "2011": {
        "total": "5",
        "16to19": "0",
        "20to24": "1",
        "25to34": "2",
        "35to44": "0",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Railroad brake, signal, and switch operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Railroad conductors and yardmasters": {
    "ageData": {
      "2011": {
        "total": "52",
        "16to19": "1",
        "20to24": "4",
        "25to34": "11",
        "35to44": "12",
        "45to54": "14",
        "55to64": "9",
        "65+": "2",
        "medianAge": "9"
      }
    },
    "title": "Railroad conductors and yardmasters",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "52",
        "women": "6.6",
        "white": "27.2",
        "black": "2.7",
        "asian": "4.7"
      }
    },
    "wageData": {
      "2011": {
        "workers": "48",
        "median": "-",
        "maleWorkers": "45",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Subway, streetcar, and other rail transportation workers": {
    "ageData": {
      "2011": {
        "total": "11",
        "16to19": "0",
        "20to24": "1",
        "25to34": "3",
        "35to44": "1",
        "45to54": "4",
        "55to64": "2",
        "65+": "0",
        "medianAge": "2"
      }
    },
    "title": "Subway, streetcar, and other rail transportation workers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "11",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "14",
        "median": "-",
        "maleWorkers": "12",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Sailors and marine oilers": {
    "ageData": {
      "2011": {
        "total": "22",
        "16to19": "0",
        "20to24": "5",
        "25to34": "9",
        "35to44": "3",
        "45to54": "1",
        "55to64": "3",
        "65+": "1",
        "medianAge": "3"
      }
    },
    "title": "Sailors and marine oilers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "22",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "23",
        "median": "-",
        "maleWorkers": "23",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Ship and boat captains and operators": {
    "ageData": {
      "2011": {
        "total": "37",
        "16to19": "0",
        "20to24": "1",
        "25to34": "9",
        "35to44": "5",
        "45to54": "11",
        "55to64": "9",
        "65+": "3",
        "medianAge": "9"
      }
    },
    "title": "Ship and boat captains and operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "37",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "35",
        "median": "-",
        "maleWorkers": "35",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Ship engineers": {
    "ageData": {
      "2011": {
        "total": "8",
        "16to19": "1",
        "20to24": "0",
        "25to34": "3",
        "35to44": "3",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Ship engineers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "8",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "6",
        "median": "-",
        "maleWorkers": "6",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Bridge and lock tenders": {
    "ageData": {
      "2011": {
        "total": "6",
        "16to19": "0",
        "20to24": "0",
        "25to34": "1",
        "35to44": "1",
        "45to54": "2",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Bridge and lock tenders",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "6",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "7",
        "median": "-",
        "maleWorkers": "7",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Parking lot attendants": {
    "ageData": {
      "2011": {
        "total": "73",
        "16to19": "6",
        "20to24": "17",
        "25to34": "16",
        "35to44": "13",
        "45to54": "12",
        "55to64": "6",
        "65+": "2",
        "medianAge": "6"
      }
    },
    "title": "Parking lot attendants",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "73",
        "women": "13.2",
        "white": "26.6",
        "black": "4.1",
        "asian": "28.7"
      }
    },
    "wageData": {
      "2011": {
        "workers": "46",
        "median": "-",
        "maleWorkers": "40",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Automotive and watercraft service attendants": {
    "ageData": {
      "2011": {
        "total": "76",
        "16to19": "11",
        "20to24": "18",
        "25to34": "16",
        "35to44": "12",
        "45to54": "8",
        "55to64": "6",
        "65+": "4",
        "medianAge": "6"
      }
    },
    "title": "Automotive and watercraft service attendants",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "76",
        "women": "10.6",
        "white": "7.1",
        "black": "2.3",
        "asian": "17.1"
      }
    },
    "wageData": {
      "2011": {
        "workers": "42",
        "median": "-",
        "maleWorkers": "40",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Transportation inspectors": {
    "ageData": {
      "2011": {
        "total": "32",
        "16to19": "0",
        "20to24": "2",
        "25to34": "7",
        "35to44": "5",
        "45to54": "11",
        "55to64": "6",
        "65+": "1",
        "medianAge": "6"
      }
    },
    "title": "Transportation inspectors",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "32",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "32",
        "median": "-",
        "maleWorkers": "28",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Transportation attendants, except flight attendants": {
    "ageData": {
      "2011": {
        "total": "32",
        "16to19": "0",
        "20to24": "3",
        "25to34": "6",
        "35to44": "3",
        "45to54": "7",
        "55to64": "10",
        "65+": "3",
        "medianAge": "10"
      }
    },
    "title": "Transportation attendants, except flight attendants",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "32",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "13",
        "median": "-",
        "maleWorkers": "5",
        "maleMedian": "-",
        "femaleWorkers": "8",
        "femaleMedian": "-"
      }
    }
  },
  "Other transportation workers": {
    "ageData": {
      "2011": {
        "total": "16",
        "16to19": "0",
        "20to24": "1",
        "25to34": "4",
        "35to44": "3",
        "45to54": "4",
        "55to64": "2",
        "65+": "1",
        "medianAge": "2"
      }
    },
    "title": "Other transportation workers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "16",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "15",
        "median": "-",
        "maleWorkers": "14",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Conveyor operators and tenders": {
    "ageData": {
      "2011": {
        "total": "5",
        "16to19": "1",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "2",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Conveyor operators and tenders",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "5",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Crane and tower operators": {
    "ageData": {
      "2011": {
        "total": "63",
        "16to19": "0",
        "20to24": "5",
        "25to34": "12",
        "35to44": "20",
        "45to54": "17",
        "55to64": "7",
        "65+": "2",
        "medianAge": "7"
      }
    },
    "title": "Crane and tower operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "63",
        "women": "0.4",
        "white": "13.7",
        "black": "1.9",
        "asian": "21.8"
      }
    },
    "wageData": {
      "2011": {
        "workers": "63",
        "median": "739",
        "maleWorkers": "62",
        "maleMedian": "736",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Dredge, excavating, and loading machine operators": {
    "ageData": {
      "2011": {
        "total": "51",
        "16to19": "0",
        "20to24": "6",
        "25to34": "7",
        "35to44": "10",
        "45to54": "16",
        "55to64": "9",
        "65+": "1",
        "medianAge": "9"
      }
    },
    "title": "Dredge, excavating, and loading machine operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "51",
        "women": "0.7",
        "white": "0.0",
        "black": "0.0",
        "asian": "17.2"
      }
    },
    "wageData": {
      "2011": {
        "workers": "37",
        "median": "-",
        "maleWorkers": "37",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Hoist and winch operators": {
    "ageData": {
      "2011": {
        "total": "4",
        "16to19": "0",
        "20to24": "1",
        "25to34": "2",
        "35to44": "1",
        "45to54": "1",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Hoist and winch operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "4",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "5",
        "median": "-",
        "maleWorkers": "3",
        "maleMedian": "-",
        "femaleWorkers": "2",
        "femaleMedian": "-"
      }
    }
  },
  "Industrial truck and tractor operators": {
    "ageData": {
      "2011": {
        "total": "528",
        "16to19": "9",
        "20to24": "55",
        "25to34": "142",
        "35to44": "130",
        "45to54": "124",
        "55to64": "57",
        "65+": "12",
        "medianAge": "57"
      }
    },
    "title": "Industrial truck and tractor operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "528",
        "women": "6.7",
        "white": "22.7",
        "black": "1.3",
        "asian": "27.9"
      }
    },
    "wageData": {
      "2011": {
        "workers": "491",
        "median": "562",
        "maleWorkers": "456",
        "maleMedian": "556",
        "femaleWorkers": "35",
        "femaleMedian": "-"
      }
    }
  },
  "Cleaners of vehicles and equipment": {
    "ageData": {
      "2011": {
        "total": "331",
        "16to19": "43",
        "20to24": "62",
        "25to34": "75",
        "35to44": "70",
        "45to54": "42",
        "55to64": "32",
        "65+": "7",
        "medianAge": "32"
      }
    },
    "title": "Cleaners of vehicles and equipment",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "331",
        "women": "12.0",
        "white": "14.2",
        "black": "1.3",
        "asian": "33.4"
      }
    },
    "wageData": {
      "2011": {
        "workers": "230",
        "median": "465",
        "maleWorkers": "206",
        "maleMedian": "458",
        "femaleWorkers": "25",
        "femaleMedian": "-"
      }
    }
  },
  "Laborers and freight, stock, and material movers, hand": {
    "ageData": {
      "2011": {
        "total": "1,787",
        "16to19": "161",
        "20to24": "323",
        "25to34": "423",
        "35to44": "321",
        "45to54": "322",
        "55to64": "188",
        "65+": "49",
        "medianAge": "188"
      }
    },
    "title": "Laborers and freight, stock, and material movers, hand",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "1,787",
        "women": "16.9",
        "white": "17.4",
        "black": "3.1",
        "asian": "21.8"
      }
    },
    "wageData": {
      "2011": {
        "workers": "1,260",
        "median": "509",
        "maleWorkers": "1,085",
        "maleMedian": "520",
        "femaleWorkers": "175",
        "femaleMedian": "416"
      }
    }
  },
  "Machine feeders and offbearers": {
    "ageData": {
      "2011": {
        "total": "36",
        "16to19": "3",
        "20to24": "5",
        "25to34": "10",
        "35to44": "6",
        "45to54": "7",
        "55to64": "4",
        "65+": "1",
        "medianAge": "4"
      }
    },
    "title": "Machine feeders and offbearers",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "36",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "32",
        "median": "-",
        "maleWorkers": "27",
        "maleMedian": "-",
        "femaleWorkers": "5",
        "femaleMedian": "-"
      }
    }
  },
  "Packers and packagers, hand": {
    "ageData": {
      "2011": {
        "total": "393",
        "16to19": "16",
        "20to24": "63",
        "25to34": "98",
        "35to44": "77",
        "45to54": "78",
        "55to64": "50",
        "65+": "11",
        "medianAge": "50"
      }
    },
    "title": "Packers and packagers, hand",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "393",
        "women": "53.5",
        "white": "14.1",
        "black": "3.6",
        "asian": "45.8"
      }
    },
    "wageData": {
      "2011": {
        "workers": "300",
        "median": "397",
        "maleWorkers": "135",
        "maleMedian": "396",
        "femaleWorkers": "165",
        "femaleMedian": "397"
      }
    }
  },
  "Pumping station operators": {
    "ageData": {
      "2011": {
        "total": "21",
        "16to19": "0",
        "20to24": "3",
        "25to34": "6",
        "35to44": "4",
        "45to54": "3",
        "55to64": "5",
        "65+": "0",
        "medianAge": "5"
      }
    },
    "title": "Pumping station operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "21",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "26",
        "median": "-",
        "maleWorkers": "26",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Refuse and recyclable material collectors": {
    "ageData": {
      "2011": {
        "total": "92",
        "16to19": "2",
        "20to24": "12",
        "25to34": "22",
        "35to44": "16",
        "45to54": "24",
        "55to64": "12",
        "65+": "4",
        "medianAge": "12"
      }
    },
    "title": "Refuse and recyclable material collectors",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "92",
        "women": "7.7",
        "white": "17.1",
        "black": "0.7",
        "asian": "30.0"
      }
    },
    "wageData": {
      "2011": {
        "workers": "58",
        "median": "541",
        "maleWorkers": "56",
        "maleMedian": "580",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  },
  "Mine shuttle car operators": {
    "ageData": {
      "2011": {
        "total": "1",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "0",
        "55to64": "1",
        "65+": "0",
        "medianAge": "1"
      }
    },
    "title": "Mine shuttle car operators",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "1",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "1",
        "median": "-",
        "maleWorkers": "0",
        "maleMedian": "-",
        "femaleWorkers": "1",
        "femaleMedian": "-"
      }
    }
  },
  "Tank car, truck, and ship loaders": {
    "ageData": {
      "2011": {
        "total": "3",
        "16to19": "0",
        "20to24": "0",
        "25to34": "0",
        "35to44": "0",
        "45to54": "2",
        "55to64": "0",
        "65+": "0",
        "medianAge": "0"
      }
    },
    "title": "Tank car, truck, and ship loaders",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "3",
        "women": "-",
        "white": "-",
        "black": "-",
        "asian": "-"
      }
    },
    "wageData": {
      "2011": {
        "workers": "3",
        "median": "-",
        "maleWorkers": "2",
        "maleMedian": "-",
        "femaleWorkers": "0",
        "femaleMedian": "-"
      }
    }
  },
  "Material moving workers, all other": {
    "ageData": {
      "2011": {
        "total": "62",
        "16to19": "3",
        "20to24": "7",
        "25to34": "6",
        "35to44": "19",
        "45to54": "12",
        "55to64": "13",
        "65+": "2",
        "medianAge": "13"
      }
    },
    "title": "Material moving workers, all other",
    "depth": 3,
    "parent": "Transportation and material moving occupations",
    "sexRaceEthnicityData": {
      "2011": {
        "total": "62",
        "women": "10.3",
        "white": "16.4",
        "black": "0.4",
        "asian": "24.0"
      }
    },
    "wageData": {
      "2011": {
        "workers": "45",
        "median": "-",
        "maleWorkers": "42",
        "maleMedian": "-",
        "femaleWorkers": "3",
        "femaleMedian": "-"
      }
    }
  }
}