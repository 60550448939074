import React, { useState } from 'react'

import { css } from '@emotion/css'

import AMERICAN_NEWS from './markovs/american-news.json'
import { markovChain } from './markov.mjs'

import { Button, Divider, Input } from 'antd'

import _ from 'lodash'
import { convertToInputState, useResizeRerender } from '../../utilities/misc.mjs'
import { Label } from '../../utilities/Label'
import { useListState } from '../../utilities/useListState'

function makeName (nameStart, minLength, maxLength) {
  return _.capitalize(markovChain(
    AMERICAN_NEWS,
    nameStart,
    {
      minLength: Number(minLength) || undefined,
      maxLength: Number(maxLength) || undefined
    }))
}

export const NameGenerator = () => {
  const [generatedNames, setGeneratedNames] = useState([])

  const [state, eachItem, addItem] = useListState([{
    nameStart: '',
    minLength: 5,
    maxLength: 9
  }], () => ({
    nameStart: '',
    minLength: 5,
    maxLength: 9
  }))

  let columns = Math.floor(window.innerWidth / 200)
  let frs = new Array(columns).fill('1fr').join(' ')

  useResizeRerender()

  return (
    <div className={css`font-family: Arial; font-weight: 100;`}>
      <div className={css`display: grid; gap: 60px; grid-template-columns: 4fr 1fr;`}>

        <div>
          <div className={css`display: grid; gap: 20px; grid-template-columns: 4fr 2fr 2fr 1fr;`}>
            <Label title='Name starts with...' />
            <Label title='Min length' />
            <Label title='Max length' />
          </div>
          {eachItem(({ removeItem, getItemState }) => {
            let nameStart = convertToInputState(getItemState('nameStart'))
            let minLength = convertToInputState(getItemState('minLength'))
            let maxLength = convertToInputState(getItemState('maxLength'))
            return (
              <div className={css`display: grid; gap: 20px; grid-template-columns: 4fr 2fr 2fr 1fr; margin-bottom: 10px;`}>
                <Input {...nameStart.input} />
                <Input {...minLength.input} />
                <Input {...maxLength.input} />
                <Button onClick={removeItem}>Remove</Button>
              </div>
            )
          })}
          <Button onClick={addItem}>Add name</Button>
        </div>

        <div className={css`display: flex; gap: 10px; flex-direction: row; flex: 1 1 auto; padding-top: 23px;`}>
          <Button onClick={() => {
            setGeneratedNames((names) => {
              return [
                ...names,
                state.map(nameConfig => makeName(nameConfig.nameStart, nameConfig.minLength, nameConfig.maxLength)).join(' ')
              ]
            })
          }}
          >
            Generate
          </Button>
          <Button onClick={() => setGeneratedNames([])}>Clear</Button>
        </div>
      </div>
      <Divider />
      <div className={css`
        display: grid;
        grid-template-columns: repeat( auto-fill, minmax(max-content, 200px) );
        column-gap: 40px;
      `}
      >
        {generatedNames.map((name, index) => <span key={name + index} className={css`white-space: nowrap;`}>{name}</span>)}
      </div>
    </div>
  )
}
